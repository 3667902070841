// Angular
import {Component, OnInit, Input, SimpleChanges} from '@angular/core';

//Project
import { AdvisorService } from '../../../../../../shared/services/advisor.service';
import { Router } from '@angular/router';
import { MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'precisefp-integration-component',
  templateUrl: './precisefp-integration.component.html'
})

export class PrecisefpIntegrationComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }

  // @ViewChild('stepper', { static: false }) private stepper: MatStepper;


  ngOnInit() {
    if(sessionStorage.getItem('comesFromIntegration') === 'precisefp' && this.comeFromLogin){
      this.importMoreClients();
    }

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectPrecisefp(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = '30'
      redirectUri = 'https://api.fpalpha.app/v1/devtools/precisefp/callback'
    }else if(this.isProduction){
      //Production
      client_id = '30'
      redirectUri = 'https://app.fpalpha.com/precisefp/pending'
    }else{
       //Testing
      client_id = '30'
      redirectUri = 'https://app.fpalpha.app/precisefp/pending'
    }

    window.location.replace(`https://app.precisefp.com/oauth/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirectUri}&grant_type=authorization_code`);
  }

  goBack() {
  }

  goForward() {

  }

  importMoreClients(){
    this.clientListView = true;

    /*if(typeof this.dialogRef.close === 'function'){
      this.dialogRef.close('cancelled');
    }

    this.router.navigate(['precisefp/success']);*/
  }

  disconnectPrecisefp() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiPreciseFPDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'PreciseFP disconnected');
          this.snackBarSuccess.open("Your PreciseFP account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          console.log('Error: PreciseFP disconnect');
          this.snackBarError.open("Couldn't disconnect your PreciseFP account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }

}

