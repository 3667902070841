import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-advisor-info-modal',
  templateUrl: './advisor-info-modal.component.html',
  styleUrls: ['./advisor-info-modal.component.scss']
})
export class AdvisorInfoModalComponent extends CloseDialogAtLogOut implements OnInit {

  public advisorRole: any;

  constructor(
    public dialogRef: MatDialogRef<AdvisorInfoModalComponent>,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      super(dialogRef, authService);
      this.advisorRole = data.advisorData.role || '';
     }

  ngOnInit() {
  }

  cancel(){
    this.dialogRef.close('cancel');
  }

  saveChanges(){
    this.dialogRef.close('cancel');
  }

  deleteAdvisor(){
    this.dialogRef.close('cancel');
  }

}
