import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AddChildComponent } from '../../dialogs/add-child/add-child.component';
import { processResponse, buildFormByType } from '../../general-questionnaire-catalog';
import { clientFamilyDTO, tableClientFamilyDTO, rowTableToForm, formToRowTable, childFormToRelative, modalRelativeTitles } from './utils';
import { GeneralQuestionnaireService } from 'app/shared/services/questionnaires/general-questionnaire.service';
import { QuestionnaireResponse } from 'app/shared/model/questionnaires/Questionnaires.model';
import { QuestionnaireHubService } from 'app/shared/services/questionnaires/questionnaire-hub.service';
import { clientPersonalInformationDTO, maritalStatusesWithCoClient } from '../personal-information/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageManager } from 'app/shared/classes/LocalStorageManager';
import { ClientService } from 'app/shared/services/client/client.service';

const ELEMENT_DATA = [
  {fullname: "", birthdate: '', parents: '', fullTimeCollageStudent: 'No', hasDisability: 'No', editIcon: ''},
  {fullname: "", birthdate: '',},
  {fullname: "", birthdate: '',},
];

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent implements OnInit, AfterViewInit {
  //todo: son las mismas que estan en index.js?

  displayedColumns: string[] = ['fullname', 'birthdate', 'parents', 'fullTimeCollageStudent', 'hasDisability', 'editIcon'];
  displayedColumnsG: string[] = ['fullname', 'birthdate', 'editIcon'];
  displayedColumnsB: string[] = ['fullname', 'relation', 'editIcon'];
  dataSource = ELEMENT_DATA;

  @Output('handleNextStep') handleNextStep = new EventEmitter();

  @Output('handlePrevStep') handlePrevStep = new EventEmitter();

  @Output('onFormReady') onFormReady = new EventEmitter<FormGroup>()

  familyTables: any;

  familyForm: FormGroup;

  relatives: any[];

  relationships: any[];

  rawResponse: any;

  hasPreviousGQData: boolean = false;

  questionnaireHub: QuestionnaireHubService;
  
  curentClientId;

  modalConfiguration = {
    width: '1240px',
    disableClose: true,
    panelClass: 'general-questionnaire-theme',
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public generalQuestionnaireService: GeneralQuestionnaireService,
    public snackBar: MatSnackBar,
    public currentClientService: ClientService,
  ) {    
    this.resetFamilyForm()
  }

  ngAfterViewInit(): void {
    const localStorage = new LocalStorageManager();
    this.curentClientId = localStorage.getItem('clientIDX')
    this.loadAnswers()
  }

  loadAnswers() {
    this.questionnaireHub = new QuestionnaireHubService(this.snackBar);
    this.questionnaireHub.getAnswers(this.generalQuestionnaireService, 'family', null, this.curentClientId).subscribe((response: QuestionnaireResponse) => {

      this.onFormReady.emit(this.familyForm)
      
      this.hasPreviousGQData = true;

      const answers = response.data

      const selectFields = response.fields

      const dto = clientFamilyDTO(answers)

      const tableDto = tableClientFamilyDTO(answers, selectFields)

      this.familyTables = tableDto;
      this.relatives = dto;
      this.relationships = response.fields
      this.rawResponse = answers;

      this.patchFamilyForm()

    })
  }

  ngOnInit(): void {
  }

  resetFamilyForm() {
    this.familyForm = this.formBuilder.group({
    });  
  }

  patchFamilyForm() {
    const hasChilds = this.relatives[0].forms.length > 0 ? 'Yes' : 'No';
    const hasGrandchilds = this.relatives[1].forms.length > 0 ? 'Yes' : 'No';
    const hasBeneficiaries = this.relatives[2].forms.length > 0 ? 'Yes' : 'No';
    this.familyForm = this.formBuilder.group({
      clientHasChilds: [hasChilds, []],
      clientHasGrandchilds: [hasGrandchilds, []],
      clientHasMoreBeneficiaries: [hasBeneficiaries, []],
    });  
  }

  addBothParentsOption(options){
    const bothParents = {
      key: [],
      relation: '',
      text : ''
    }
    const newOptions = [...options]
    options.forEach((option) => {
      bothParents.key.push(option.key)
      bothParents.text += bothParents.text == '' ?
        `${option.text.split(' ')[0]}` : 
        ` and ${option.text.split(' ')[0]}`
    })
    newOptions.push(bothParents)
    return newOptions;
  }

  addRelative(relativeType){
    const relativeByIndex = {}
    relativeByIndex['children'] = 0
    relativeByIndex['grandchildren'] = 1
    relativeByIndex['beneficiary'] = 2
    const childForm = rowTableToForm(this.dataSource[relativeByIndex[relativeType]], relativeType);
    const dialogRef = this.dialog.open(AddChildComponent, this.modalConfiguration)
    let relativeOptionsToUse 
    if ( relativeType == 'children') {
      relativeOptionsToUse = this.relationships[1].options
      if (this.relationships[1].options.length == 2) {
        relativeOptionsToUse = relativeByIndex[relativeType] === 0 ?
          this.addBothParentsOption(this.relationships[1].options) :
            this.relationships[0].options
      }
    }
    else{
      relativeOptionsToUse = this.relationships[0].options
    }
    dialogRef.componentInstance.childDataForm = childForm;
    dialogRef.componentInstance.relationshipType = relativeType;
    dialogRef.componentInstance.validRelationships = relativeOptionsToUse;
    dialogRef.componentInstance.title = modalRelativeTitles[relativeType];
    dialogRef.componentInstance.handleOnSave.subscribe((child) => {
      const relative = childFormToRelative(child, relativeType)
      this.questionnaireHub.postAnswers(this.generalQuestionnaireService, 'family', relative, null, this.curentClientId ).subscribe((response) => {
    
        this.currentClientService.refreshAll()
        this.loadAnswers()
  
      })
    })
  }

  openRelative(child, index, relativeType){
    const relativeByIndex = {}
    relativeByIndex['children'] = 0
    relativeByIndex['grandchildren'] = 1
    relativeByIndex['beneficiary'] = 2
    const relativeNames= {}
    relativeNames['children'] = 'child'
    relativeNames['grandchildren'] = 'grandchild'
    relativeNames['beneficiary'] = 'beneficiary'
    const rawChild = this.rawResponse[relativeByIndex[relativeType]].data[index];
    const selectedName = relativeNames[relativeType]
    const newChild = {...child}
    const dialogRef = this.dialog.open(AddChildComponent, this.modalConfiguration);
    let relativeOptionsToUse = this.relationships[1].options
    if (this.relationships[1].options.length == 2) {
      newChild['parents'] = rawChild.addons.parents
      relativeOptionsToUse = relativeByIndex[relativeType] === 0 ?
        this.addBothParentsOption(this.relationships[1].options) :
          this.relationships[0].options
    }
    else{
      newChild['parents'] = rawChild.addons.parents[0]
    }
    const childForm = rowTableToForm(newChild, relativeType);
    
    dialogRef.componentInstance.childDataForm = childForm;
    dialogRef.componentInstance.relationshipType = relativeType;
    dialogRef.componentInstance.relationshipName = selectedName;
    dialogRef.componentInstance.canBeDeleted = child['canDelete'];
    dialogRef.componentInstance.validRelationships = relativeOptionsToUse;
    dialogRef.componentInstance.title = modalRelativeTitles[relativeType];
    dialogRef.componentInstance.handleOnSave.subscribe((childFromModal: FormGroup) => {
      
      const relative = childFormToRelative(childFromModal, relativeType)
      relative["relativeId"] = child['_id'] 
      this.questionnaireHub.putAnswers(this.generalQuestionnaireService, 'family', relative, this.curentClientId).subscribe((response) => {
        this.currentClientService.refreshAll()
      
        console.log(response)
    
        this.loadAnswers()
  
      })

    })
    dialogRef.componentInstance.handleOnDelete.subscribe((childFromModal: FormGroup) => {
      
      const relative = childFormToRelative(childFromModal, relativeType)
      relative["relativeId"] = child['_id'] 
      this.questionnaireHub.deleteAnswers(this.generalQuestionnaireService, 'family', relative, this.curentClientId).subscribe((response) => {
        this.currentClientService.refreshAll()
      
        console.log(response)
    
        this.loadAnswers()
  
      })
    })
  }

  nextSection(){
    
    this.handleNextStep.emit()

  }

  prevSection(){
    
    this.handlePrevStep.emit()

  }

}
