import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
     template: `
        <h2 class="mt-0" style="color: #365abd">Tip!</h2>
        <mat-dialog-content style="word-break: break-word;">
            <h5 class="mt-12 mb-1">Your document is valid and you have two options:</h5>
            1. Click <strong>"Continue"</strong> to answer the full questionnaire and get more specific recommendations.
            <br><br>
            2. Click <strong>"Submit"</strong> and get high level recommendations. You can always return to complete the questionnaire.
        </mat-dialog-content>

        <mat-dialog-actions class="mt-24" align="end">
            <button class="basic-button mr-12" [mat-dialog-close]="true" (click)="onContinue()">Continue</button>
            <button class="basic-button action" (click)="onSubmit()" cdkFocusInitial>Submit</button>
        </mat-dialog-actions>
    `
})
export class DialogValidDocument extends CloseDialogAtLogOut {

    constructor(
        public dialogRef: MatDialogRef<DialogValidDocument>,
        @Inject(MAT_DIALOG_DATA) public data:string,
        private authService: AuthenticationService
        ) {
          super(dialogRef, authService);
         }

    onContinue(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close('submit');
    }



}
