import { Questionnaire } from '../Questionnaire.model';

export class property_questions extends Questionnaire {

    public clientIDHomewoner: string = '';
    public clientIDCheckedMakValue: string = '';
    public clientIDChallengePropTax: string = '';
    public clientIDLookTaxExemptions: string = '';
    public clientIDRentingHouse: string = '';
    public clientIDRentingApartment: string = '';
    public clientIDOwnCondo: string = '';
    public clientIDOwnRental: string = '';
    public clientIDBusinessPropTax: string = '';
    public clientIDLessorRealEstate: string = '';
    public clientIDLesseeRealEstate: string = '';
    public clientIDPersonalAndBusiness: string = '';
    public clientIDReviewTaxRecord: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
        super()
    }

    getQName(): string {
        return 'property';
    }
}