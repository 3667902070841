import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-roth-name-edit",
  templateUrl: "./roth-name-edit.component.html",
  styleUrls: ["./roth-name-edit.component.scss"],
})
export class RothNameEditComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RothNameEditComponent>
  ) {}

  ngOnInit(): void {}

  close(){
    this.dialogRef.close('cancel');
  }
}
