import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-full-page-spinner',
  templateUrl: './full-page-spinner.component.html',
  styleUrls: ['./full-page-spinner.component.scss']
})
export class FullPageSpinnerComponent extends CloseDialogAtLogOut implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FullPageSpinnerComponent>,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
  }

}
