import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from '../../../../shared/helpers/CloseDialogAtLogOut.class';
import { readFromStoragedObject } from '../../../../shared/helpers/utils';
import { AdvisorService } from '../../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../../shared/services/auth/authentication.service';

@Component({
  selector: 'app-rename-scenario-modal',
  templateUrl: './rename-scenario-modal.component.html',
  styleUrls: ['./rename-scenario-modal.component.scss']
})
export class RenameScenarioModalComponent extends CloseDialogAtLogOut implements OnInit {

  public clientId: string = '';

  constructor(
    public dialogRef: MatDialogRef<RenameScenarioModalComponent>,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data,
    public advisorService: AdvisorService

    ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');

  }

  /**
   * The closeDialog function closes a dialog and optionally returns a response.
   * @param {any} [resp] - The "resp" parameter is an optional parameter that represents the response
   * or data that will be passed back to the component or code that opened the dialog.
   */
  closeDialog(resp?: any) {
    this.dialogRef.close(resp);
  }

  /**
   * The function updates the information of a scenario using `updateInfoScenario` and closes a dialog.
   * @constant body -  Is the body to be updated
   */
  updateScenario(){
    const body = {
      _id: this.data.scenario._id,
      name: this.data.scenario.name,
      description: this.data.scenario.description
    }

    this.advisorService.updateInfoScenario(this.clientId, body).then(response => {
     this.closeDialog('renamed!');
    })
  }

}
