import { Questionnaire } from '../Questionnaire.model';

//Sub class home

export class home {
   public clientIDRecentRenovation1: string = '';
   public clientIDDetachedStructures1: string = '';
   public clientIDInspectorEnteredHome1: string = '';
   public clientIDContentsPhotographed1: string = '';
   public clientIDHouseOneDetachedStructuresCoverageAmount1: number = 0;
   public clientIDImprovements1: string = '';
}

//Main class home questionnaire
export class home_questions extends Questionnaire {
  
   public clientIDWantsToInputPolicyDataHome: string = 'Yes';

   public clientIDHomeInsuranceCompany: string = '';
   public clientIDAutoInsuranceCompany: string = '';
   public clientIDHouseOrApartment: string = '';
   public clientIDClientRents: string = '';
   public clientIDLossPayee: string = '';
   public clientIDWidowed: string = '';
   public clientIDDivorced: string = '';
   public clientIDLastTimeShopped: number = 0;
   public clientIDHouseOneAboveStandardFinishes: string = '';
   public clientIDTotalAssetValue: number = 0;
   public clientIDAccessToGroupUmbrellaThroughEmployer: string = '';
   public clientIDEnrolledToGroupUmbrella: string = '';
   public clientIDHouseOneEstimatedContentsValue: number = 0;
   public clientIDHouseOneDetachedStructuresCoverageAmount: number = 0;
   public clientIDJewelryValue: number = 0;
   public clientIDSilverwareValue: number = 0;
   public clientIDFineArtValue: number = 0;
   public clientIDFurs: number = 0;
   public clientIDWineValue: number = 0;
   public clientIDAntiquesValue: number = 0;
   public clientIDMusicalInstrumentsValue: number = 0;
   public clientIDOtherCollectibles: number = 0;
   public clientIDValuePhysicalCash: number = 0;
   public clientIDApartmentLossAssessment: string = '';
   public clientIDApartmentFloor: string = '';
   public clientIDItemsForExhibiting: string = '';
   public clientIDLastTimeReappraised: number = 0;
   public clientIDExtraItemsForHomeInsurance: string = '';
   public clientIDHasSeparatePolicyForBoatsJetskisEtc: string = '';
   public clientIDDomesticHelp: string = '';
   public clientIDPOperatingBusiness: string = '';
   public clientIDOnAnyBoards: string = '';
   public clientIDCredits: string = '';
   public clientIDHomePolicy: string = '';
   public clientIDClientHasUmbrellaDocument: string = '';
   //Save the submodels data into an array
   //public homes: home = new home;
   //Save submodels count
   public clientIDHomesOwned: number = 0;

   //Submodel values for main model
   public clientIDImprovements1: string = "";
   public clientIDRecentRenovation1: string = '';
   public clientIDDetachedStructures1: string = '';
   public clientIDInspectorEnteredHome1: string = '';
   public clientIDContentsPhotographed1: string = '';
   public clientIDHouseOneDetachedStructuresCoverageAmount1: number = 0;

   public clientIDtotalPremiumHome: number = 0;
   public clientIDactualCashValue: string = '';
   public clientIDbasedeductible: number = 0;
   public clientIDlargeLossDeductibleWaiver: string = '';
   public clientIDhouseOneEstimatedContentsValueFromDocs: number = 0;
   public clientIDhouseOneDetachedStructuresCoverageAmountFromDocs: number = 0;
   public clientIDlookingForLossCoverageDueToFlood: string = '';
   public clientIDlookingForEquipmentBreakdown: string = '';
   public clientIDoffPremiseTheftCoverageFromDocs: string = '';
   public clientIDdwellingInsuredValue: number = 0;
   public clientIDcashsettlementOptionFromDocs: string = '';
   public clientIDrebuildingToCode: string = '';
   public clientIDwaterAndSewerBackup: string = '';
   public clientIDbasicOrBroadCoverage: string = '';
   public clientIDwindDamage: string = '';
   public clientIDlossOfUse: string = '';
   public clientIDlossPayeeFromPolicy: string = '';
   public clientIDumbrellaCoverage: number = 0;
   public clientIDpropertiesAutoLinkedToUmbrella: string = '';
   public clientIDjewelryValueFromDocs: number = 0;
   public clientIDsilverwareValueFromDocs: number = 0;
   public clientIDfineArtValueFromDocs: number = 0;
   public clientIDfursFromDocs: number = 0;
   public clientIDwineValueFromDocs: number = 0;
   public clientIDantiquesValueFromDocs: number = 0;
   public clientIDmusicalInstrumentsValueFromsDocs: number = 0;
   public clientIDvaluePhysicalCashFromDocs: number = 0;
   public clientIDotherCollectiblesFromDocs: number = 0;
   public clientIDriderOrFloaterOnPolicy: string = '';
   public clientIDlookingForEarthquakeCoverage: string = '';
   public clientIDoffPremiseTheftCoverageAmountFromDocs: number = 0;
   // Specific var for multiquest types "asset name"
   public clientIDAssetName: string = '';

   // Progress Bar
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;

   //No Insurance
   public clientIDClientHasHomeInsurance: string = '';
   public clientIDClientOwnsAdditionalRealProperty: string = '';
   public clientIDClientIsConsideringPolicyOffering: string = '';
   public clientIDPolicyBeingConsideredNoWaterAndSewerCov = '';
   public clientIDConsideringBasicorBroadCoverage:string = '';
   public clientIDPolicyConsideredHasHurricaneDamageDed:string = '';
   public clientIDWillHaveAMortgage:string = '';
   public clientIDIsConsideringPurchasingUmbrellaLiabilityPolicy: string = '';
   // public clientIDIsConsideringUmbrellaLiabilityCoverage: string = '';
   public clientIDIsConsideringUmbrellaLiabilityCoverageWithEnhancedCov: string = '';
   public clientIDLegalCostsMightBeincludedOutsidePolicyLimit: string = '';
   public clientIDOwnsValuableArticlesJewelrySilverwareETC: string = '';
   public clientIDClientUmbrellaInsuranceCompany: string = '';


   constructor() {
      super();
      this._classInitialState = Object.assign({}, this);
   }

   getQName(): string {
      return 'home';
   }

   getIgnoredAttributesForSave(): string[] {
      return [
         'clientIDHomePolicy',
         'clientIDClientHasUmbrellaDocument',
         'homes',
      ];
   }

   getAttributesToClearCommas(): string[] {
      return [
         'clientIDTotalAssetValue',
         'clientIDHouseOneEstimatedContentsValue',
         'clientIDhouseOneEstimatedContentsValueFromDocs',
         'clientIDhouseOneDetachedStructuresCoverageAmountFromDocs',
         'clientIDDetachedStructures',
         'clientIDHouseOneDetachedStructuresCoverageAmount',
         'clientIDJewelryValue',
         'clientIDSilverwareValue',
         'clientIDFineArtValue',
         'clientIDFurs',
         'clientIDWineValue',
         'clientIDAntiquesValue',
         'clientIDMusicalInstrumentsValue',
         'clientIDOtherCollectibles',
         'clientIDValuePhysicalCash',
         'cientIDHouseOneDetachedStructuresCoverageAmount1',
         'clientIDjewelryValueFromDocs',
         'clientIDsilverwareValueFromDocs',
         'clientIDfineArtValueFromDocs',
         'clientIDfursFromDocs',
         'clientIDwineValueFromDocs',
         'clientIDantiquesValueFromDocs',
         'clientIDmusicalInstrumentsValueFromsDocs',
         'clientIDvaluePhysicalCashFromDocs',
         'clientIDotherCollectiblesFromDocs',
         'clientIDdwellingInsuredValue',
         'clientIDbasedeductible',
         'clientIDtotalPremiumHome',
         'clientIDdwellingInsuredValue',
         'clientIDumbrellaCoverage',
         'clientIDoffPremiseTheftCoverageAmountFromDocs'
      ];
   }

   public getExtractionsAtributes(): string[] {
      return [
         'clientIDtotalPremiumHome',
         'clientIDactualCashValue',
         'clientIDbasedeductible',
         'clientIDlargeLossDeductibleWaiver',
         'clientIDhouseOneEstimatedContentsValueFromDocs',
         'clientIDhouseOneDetachedStructuresCoverageAmountFromDocs',
         'clientIDlookingForLossCoverageDueToFlood',
         'clientIDlookingForEquipmentBreakdown',
         'clientIDoffPremiseTheftCoverageFromDocs',
         'clientIDdwellingInsuredValue',
         'clientIDcashsettlementOptionFromDocs',
         'clientIDdwellingInsuredValue',
         'clientIDcashsettlementOptionFromDocs',
         'clientIDrebuildingToCode',
         'clientIDwaterAndSewerBackup',
         'clientIDbasicOrBroadCoverage',
         'clientIDwindDamage',
         'clientIDlossOfUse',
         'clientIDlossPayeeFromPolicy',
         'clientIDumbrellaCoverage',
         'clientIDpropertiesAutoLinkedToUmbrella',
         'clientIDjewelryValueFromDocs',
         'clientIDsilverwareValueFromDocs',
         'clientIDfineArtValueFromDocs',
         'clientIDfursFromDocs',
         'clientIDwineValueFromDocs',
         'clientIDantiquesValueFromDocs',
         'clientIDmusicalInstrumentsValueFromsDocs',
         'clientIDvaluePhysicalCashFromDocs',
         'clientIDotherCollectiblesFromDocs',
         'clientIDriderOrFloaterOnPolicy',
         'clientIDlookingForEarthquakeCoverage',
         'clientIDoffPremiseTheftCoverageAmountFromDocs',
         'clientIDClientUmbrellaInsuranceCompany',
      ]
   }

   /*beforeToSaveFormat() {
      this.saveSubmodelArrayToModelProp(this.homes);
   }

   beforeMappingLoad(args: any[]) {
      this.loadSubmodelFromRawArgs(args, home, this.homes, 'clientIDHomesOwned');
   }*/

}
