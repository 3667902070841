import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AdvisorService } from 'app/shared/services/advisor.service';
import {DataSummarized} from '../commons/data-summarize/data-summarize.component';
import { EXAMPLE_BALANCE } from 'app/shared/balanceSheetInterface';
import { balanceSheetCutter } from './components/balanceSheetCutter';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.scss']
})
export class BalanceSheetComponent implements OnInit {
  @Input() isPrintable: boolean = false;
  @Input() formattedToPdf: boolean = false;
  @Input() dataFromExternal: any;
  @Input() scenario: any;
  @Output() goToFactsFromBS: EventEmitter<any> = new EventEmitter<any>()

  public client;

  public dataBalance: any;
  //Assets
  public dataAssetsGraph: any;
  public dataAssetsHeaders: any;
  public dataAssets: any;
  public dataAssetsTotals: any;

  //liabilities
  public liabilitiesHeaders: any;
  public liabilitiesItems: any;
  public liabilitiesGraph: any;
  public liabilitiesTotals: any;

  //total
  public totalBS: any;

  public buttonsAssets = [
    {
      title: 'Today',
      key: 'today'
    },
    {
      title: 'Projected assets',
      key: 'projectedAssets'
    }
  ]

  public assetActive = 'today';

  public popOverData: DataSummarized[] = [];
  public loading: boolean = false;

  //dummyData v3
  // public responseBalanceV3 = EXAMPLE_BALANCE;
  public responseBalanceV3: any;;
  public selections: any[] = [
    {
      title:'Today',
      key: 'today'
    },
    {
      title: 'Projected Assets',
      key: 'projected'
    }
  ];

  public typeSelection: ('today' | 'projected') = 'today';
  public scenarioSelected: ('scenario1' | 'scenario2' | 'scenario3') = 'scenario1';

  public ownerShipBreakDown: TableDoughnut | undefined;
  public estateBreakdown: TableDoughnut | undefined;
  public tableEstateAssetsInside: MainTable | undefined;
  public tableEstateAssetsOutside: MainTable | undefined;
  public tableLiabilities: MainTable | undefined;
  public tableTotals: MainTable | undefined;
  public doughnutOutsideEstate: TableDoughnut | undefined;
  public doughnutInsideEstate: TableDoughnut | undefined;
  public doughnutLiabilitiesBreakdown: TableDoughnut | undefined;
  public showScenariosInMainTable: boolean = false;
  public scenarioSelectedToChilds: string;
  public localStatusProjected: ('scenario1' | 'scenario2' | 'scenario3') = 'scenario1';
  public isTabProjected: boolean = false;
  public firstTimeInBalance: boolean = true;
  public resetGraphSignal: boolean = false;

  public withStrategies: boolean = false;

  constructor(
    public advisorService: AdvisorService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    if(this.dataFromExternal != undefined){

      // this.responseBalanceV3 = this.dataFromExternal.components.find(component => component.sectionId == 'BALANCE_SHEET').data[0];
      console.log('this.dataFromExternal', this.dataFromExternal);
      if(this.formattedToPdf){
        this.responseBalanceV3 = this.dataFromExternal;
      } else{
        this.client = this.dataFromExternal.client;
        this.responseBalanceV3 = this.dataFromExternal.components.find(data => data.sectionId === 'BALANCE_SHEET').data[0];
      }
      console.log('this.responseBalanceV3', this.responseBalanceV3);
      this.updateData();
      this.loading = false;

    }else{

      this.client = JSON.parse(sessionStorage.getItem('currentClient'))

    try {
      this.advisorService.getEstateLabBalanceSheetV3(this.client.clientId).then((response:any) => {
        console.log('responseEstateLab***', response);
        this.responseBalanceV3 = response;
        this.updateData();
        // balanceSheetCutter(this.responseBalanceV3);
        this.loading = false;
      })
      // this.updateData();

      // this.loading = false;
    }catch (e) {
      this.loading = false;
      console.log(e)
    }

    }



  }


  updatePopOverData(data){
    this.popOverData = JSON.parse(JSON.stringify(data));
    // this.popOverData = data;
    console.log('this.popOverData**', this.popOverData);
  }

  changeSelectionTab(value: any){
    this.firstTimeInBalance = false;
    console.log('valueSelection*', value)
    this.typeSelection = value;
    this.showScenariosInMainTable = (value != 'today');
    this.changeScenarioSection(value);
  }

  getDataBySection(sectionId: string){
    let directData: string[] = ['ownerShipBreakDown', 'estateBreakdown'];
    if(this.formattedToPdf){
      return this.responseBalanceV3[sectionId]
    }
    if(directData.includes(sectionId)){
      console.log('getDataBySection',this.responseBalanceV3[sectionId]);

      return this.responseBalanceV3[sectionId]
    }
    /* if(this.typeSelection === 'today'){
      return this.responseBalanceV3[this.typeSelection][sectionId];
    } */
      console.log('getDataBySection',(this.firstTimeInBalance ? this.typeSelection : this.scenarioSelected), this.responseBalanceV3[this.firstTimeInBalance ? this.typeSelection : this.scenarioSelected][sectionId]);
      this.responseBalanceV3[this.firstTimeInBalance ? this.typeSelection : this.scenarioSelected][sectionId]['isAssumedAnualGrowthRateEnabled'] = false;
      this.responseBalanceV3[this.firstTimeInBalance ? this.typeSelection : this.scenarioSelected][sectionId]['isAssumedAnualGrowthRateEnabled'] = this.responseBalanceV3['isAssumedAnualGrowthRateEnabled'];
    return this.responseBalanceV3[this.firstTimeInBalance ? this.typeSelection : this.scenarioSelected][sectionId];
  }

  updateData(){
    this.ownerShipBreakDown = this.getDataBySection('ownerShipBreakDown');
    this.estateBreakdown = this.getDataBySection('estateBreakdown');
    this.tableEstateAssetsInside = this.getDataBySection('tableEstateAssetsInside');
    this.tableEstateAssetsOutside = this.getDataBySection('tableEstateAssetsOutside');
    this.tableLiabilities = this.getDataBySection('tableLiabilities');
    this.tableTotals = this.getDataBySection('tableTotals');
    this.doughnutOutsideEstate = this.getDataBySection('doughnutOutsideEstate');
    this.doughnutInsideEstate = this.getDataBySection('doughnutInsideEstate');
    this.doughnutLiabilitiesBreakdown = this.getDataBySection('doughnutLiabilitiesBreakdown');
  }

  changeScenarioSection(event: any){
    this.scenarioSelectedToChilds = event;
    if(event != 'projected'){
      this.scenarioSelected = event;
      this.updateData();
    }else{
      this.isTabProjected = true;
      this.scenarioSelected = this.localStatusProjected;
      this.scenarioSelectedToChilds = this.scenarioSelected;
      this.updateData();
    }
    if(event.includes('scenario')){
      this.localStatusProjected = event;
    }

    this.resetGraphSignal = true;
    setTimeout(()=>{
      this.resetGraphSignal = false;
    }, 100);
    // console.log('changeScenarioSection*', this.scenarioSelectedToChilds);
    // console.log('eventChangeScenario', event, this.scenarioSelected, this.localStatusProjected);
  }

  getSettings(){
    this.advisorService.getEstateLabLifeExpectancy(this.client, '1').then((response: any) => {
      this.withStrategies = response;
      console.log('this.withStrategies**', this.withStrategies);
    })
  }


  goToFactsBS(event){
    console.log('facts event', event);
    this.goToFactsFromBS.emit(event);
  }

}
