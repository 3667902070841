import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-dialog-generic-notice',
  templateUrl: './dialog-generic-notice.component.html',
  styleUrls: ['./dialog-generic-notice.component.scss']
})
export class DialogGenericNoticeComponent extends CloseDialogAtLogOut implements OnInit {

  public showPasword: boolean = false;
  public keysToValidate: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogGenericData,
  public dialogRef: MatDialogRef<DialogGenericNoticeComponent>,
  private authService: AuthenticationService,
  ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
    if(this.data.outputFieldsData === undefined){ this.data.outputFieldsData = {}}

    this.data.actionButtons.forEach(({ requiresValidInputFieldWithKey: disable }) => {
      if (disable) {
        this.keysToValidate.push(disable);
      }
    });
  }

  viewPassword() {
    this.showPasword = !this.showPasword;
  }

  updateField(event: any) {
    const { target: { id, value } } = event;
    const indexOfElement = this.keysToValidate.findIndex((element) => id === element);
    if (indexOfElement > -1 && value) {
      this.keysToValidate.splice(indexOfElement, 1);
    } else if (indexOfElement === -1 && !value) {
      this.keysToValidate.push(id);
    }
  }

}

interface DialogGenericData{
  title: string;
  titleClass?: string,
  body: string;
  bodyClass?: string,
  bodyStyles?: string,
  buttonsContainerClass: string,
  buttonsContainerStyles: object,
  actionButtons: {text: string, class: string, action: any, requiresValidInputFieldWithKey?: string, style?: string}[],
  inputFields?: {type?: string, key: string, text: string, class: string},
  outputFieldsData?: any
}

