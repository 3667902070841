import { Questionnaire } from '../Questionnaire.model'
export class estate_questions extends Questionnaire {

	//Files
	public clientIDWill: string ="";
	public clientIDWillStatus: string ="";
	public clientIDPOA: string ="";
	public clientIDRevocableTrust: string ="";
	public clientIDRevocableTrustStatus: string ="";
	public clientIDIrrevocableTrust: string ="";
	public clientIDLifeInsurancePolicy: string ="";
	public cliendIDExtraDocuments: string = "";
	public cliendIDReviewDocuments: string = "No";

	//Predictions type change (15/Jan/2021 - RT)
	public clientIDTypeOfAnalysis: string = 'Indepth analysis';
	//Predictions (15/0ct/2020 - RT)
	public clientIDEstatePredictions: string="No";
	public clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction: string= "";
	public clientIDPoliciesOwnedByInsured: string= "";

  //Manual input
	public clientIDWantsToInputDataManually: string = "Yes";

  /*********************************************************** 
   * Will Extractions
  ************************************************************/

  // COMMON EXTRACTIONS FOR WILLS
	public clientIDHasWill: string = "";
	public clientIDsearchWillCreationDatePrior2010: string = '';
	public clientIDbusinessInWill: string = '';
	public clientIDresiduaryClauseInWill: string = '';
	public clientIDcontingentBeneficiaryInWill: string = '';
	public clientIDuseOfHEMSProvisions: string = '';
	public clientIDtestamentaryTrustInWill: string = '';
	public clientIDDisclaimerOrBypassTrustInWill: string = '';
	public clientIDguardiansInWill: string = '';
	public clientIDsearchPPG: string = '';
	public clientIDsearchPersonalGuardian: string = '';
	public clientIDsearchMultipleTrusts: string = '';
	public clientIDgiftConditionsInWill: string = '';
	public clientIDsharedGiftInWill: string = '';
	public clientIDmortgageInWill: string = '';
	public clientIDsearchCharity: string = '';
	public clientIDclientHasPropertyMemorandum: string = '';
	public clientIDCapitalGainsDistributionWill: string = '';
	public clientIDCapitalGainsDistributionRevTrust: string = '';

  // EXTRACTIONS FOR INSTANT ANALYSIS
  public clientIDwillCreationDatePrior5years: string = '';
  public clientIDpersonalRepresentative: string = '';
  public clientIDreplacementExecutor: string = '';
  public clientIDwillProvideSpouse: string = '';
  public clientIDwillPersonalPropertySpouse: string = '';
  public clientIDisPouroverWill: string = '';
  public clienIDwillResidualSpouse: string = '';
  public clientIDtrusteeTestamentaryTrustInWill: string = '';
  public clientIDspouseBeneficiaryTestamentary: string = '';
  public clientIDwillRetirementAccount: string = '';
  public clientIDtrustProvisionChildrenInWill: string = '';
  public clientIDburialInsutructionsInWill: string = '';

  /*********************************************************** 
   * Revocable trust Extractions
  ************************************************************/

	//COMMON EXTRACTIONS FOR REV TRUST
  public clientIDHasRevocableTrust: string = '';
	public clientIDpouroverWill: string = '';
	public clientIDsearchRevocableTrustCreationDatePrior2010: string = '';
	public clientIDpowerOfAppointment: string = '';
	public clientIDsuccessorTrusteeR: string = '';
	public clientIDmortgageInRevocable: string = '';
	public clientIDsearchTrustInstrumentRequiresTrusteeToPayExecutor: string = '';
	public clientIDcontingentBeneficiaryInRevocableTrust: string = '';
	public clientIDuseOfHEMSProvisionsRevocableTrust: string = '';
	public clientIDtestamentaryTrustInRevocableTrust:string = '';
	public clientIDDisclaimerOrBypassTrustInRevocableTrust: string = '';
	public clientIDsearchPersonalGuardianRevocableTrust: string = '';
	public clientIDsearchMultipleTrustsRevocableTrust: string = '';
	public clientIDsharedGiftInRevocableTrust: string = '';
	public clientIDsearchDisclaimerProvisionRevocableTrust: string = '';
	public clientIDsearchMedicalCare: string = '';

  // EXTRACTIONS FOR INSTANT ANALYSIS
  public clientIDrevocableTrustCreationDatePrior5years: string = '';
  public clientIDjointTrust: string = '';
  public clientIDGoverningLaw: string = '';
  public clientIDSpecificBequests: string = '';
  public clientIDTrustDistributionToSpouse: string = '';
  public clientIDABTrust: string = '';
  public clientIDTrustStateEstateTaxes: string = '';
  public clientIDchildrenTrustInTrust: string = '';
  public clientIDageDistribution: string = '';
  public clientIDtrustRetirementAccount: string = '';
  public clientIDTrustInvestmentManagement: string = '';

		//Irrevocable Trust
	public clientIDqprt: string = '';
	public clientIDsuccessorTrustee: string = '';
		//POA
	public clientIDHasPOA: string = 'No';
	public clientIDadressedElderAbuse: string = '';
		//Life Insurance
	public clientIDtotalLifeInsuranceCoverage: number = 0;
	public clientIDprimaryBeneficiaryInLifeInsurance: string = '';
	public clientIDcontingentBeneficiaryInLifeInsurance: string = '';

	//Personal Information
	public clientIDHasIrrevocableTrust: string = "";
	public clientIDHasLifeInsurancePolicy: string = "";
	public clientIDClientHasRetirementAccount: string = "";
	// public clientIDJointAccount: string = ""; Removed on 9/11/2020 by request of DB (RT)
	public clientIDAssetUnderstanding: string = "";
	public clientIDAdvancedMedicalDirectives: string = "";

	public clientIDBusinessSetToPassThroughProbate: string = "";
	public clientIDIntentionOfPartnersToTakeOverBusinessUponDeath: string = "";
	public clientIDFamilySuccessionPlanning: string = "";
	public clientIDIsBusinessLLC: string = "";
	public clientIDCorpCOntrolShares: string = "";

	public clientIDClientSavingBonds: string = "";
	public clientIDHasSafeDepositBox: string = "";
	public clientIDLettersOfInstructionAndIntent: string = "";
	public clientIDUGMAOrUTMAAccount: string = "";
	public clientIDOtherHeirOne: string = "";
	public clientIDOtherHeirTwo: string = "";
	public clientIDOtherHeirThree: string = "";
	public clientIDJointTenancy: string = "";
	public clientIDclientHasBypassTrust: string = "";
	public clientIDBusinessLeftToCapableManagement: string = "";
	public clientIDTrustTaxPlan: string = "";

	public clientIDFileDeceasedSpouse: string = "";

	public clientIDISInAHighIndividualIncomeTaxBraket: string = "";

	//Checking Whether to Update Estate	Plan
	public clientIDHadMajorLifeEvent: string = "";
	public clientIDClientHasHadMoreChildrenThatMovedOutOrIntoHome: string = "";
	public clientIDClientHasBoughtSoldOrMortgagedABusiness: string = "";
	public clientIDClientHasAcquiredMajorAssets: string = "";
	public clientIDChangeInEstatePlanningGoals: string = "";
	public clientIDAttorneyCompetence: string = "";
	public clientIDAttorneyCompetenceAndConflictOfInterest: string = "";

	//Choosing Between Will and Revocable Trust
	public clientIDAssetsOwnedInMultipleStates: string = "";
	public clientDIResidencesInMultipleStates: string = "";
	public clientIDClientWantsToDisinherit: string = "";
	public clientIDExcludingChildrenFromTrustOrWill: string = "";
	public clientIDExcludingSpouseFromTrustOrWill: string = "";
	public clientIDEstateAnticipateWill: string = "";
	public clientIDClientValuesPrivacy: string = "";
	public clientIDClientWantsMoreProtectionFromCreditors: string = "";
	public clientIDHasDeceasedStatutoryHeirs: string = "";
	public clientIDClientIsInAContentiousSituation: string = "";
	public clientIDExecutorAttorney: string = "";
	public clientIDExecutorNonUSOrFelon: string = "";
	// public clientIDClientValuesAditionalFlexibility: string = "";
	public clientIDAnotherChildSinceLastUpdated: string = "";
	public clientIDConcernAboutAppreciationOfEstateAfterSpouseDeath: string = "";
	public clientIDMortgageOnePersonsName: string = "";

	//Evaluating Wills
	public clientIDUsedAnAttorneyAdmittedToPracticeInState: string = "";
	public clientIDAssetsUpToDate: string = "";
	public clientIDExecutorLivesInState: string = "";
	public clientIDChangeWillCodicil: string = "";

	//Evaluating Revocable Trusts
	public clientIDRevocableLivingTrust: string = "";
	public clientIDBeneficiaryTrustee: string = "";
	public clientIDOnlyTrustee: string = "";
	public clientIDTrustAgreementWrittenByLawyer: string = "";
	public clientIDAssetsBeenTitledToTrust: string = "";
	public clientIDRevocableTrustCoordinatedWithPOA: string = "";
	public clientIDPuttingInLandSubjectToEnvoronmentalCleanUp: string = "";
	public clientIDAmendmentsInRevocableTrust: string = "";
	public clientIDAssetsAddedSinceTrustInception: string = "";
	public clientIDCoupleHasSeparateWill: string = "";
	public clientIDApportionedTaxableTransfers: string = "";
	public clientIDCapitalGainsDistribution: string = "";
	public clientIDLeavingTaxFreeResiduaryLegacyToSpouseOrCharity: string = "";
	public clientIDIsLegacySmallAndToAMinor: string = "";
	public clientIDUTMAOrUGMAAccount: string = "";
	public clientIDContingentTrustsForChildren: string = "";
	public clientIDMovedToNewStateSinceTrustWasDrafted: string = "";
	public clientIDHasSeparateRevocable: string = "";

	//Evaluating Whether to Use Irrevocable Trusts
	public clientIDBlendedFamily: string = "";
	public clientIDRestrictFunds: string = "";
	public clientIDLimitAccess: string = "";
	public clientIDProtectionBeneficiariesDivorce: string = "";
	public clientIDClientSupportsParents: string = "";

	public clientIDParentsheirs: string = "";
	public clientIDMoneyGrandchildren: string = "";

	public clientIDHardDivideProperty: string = "";
	public clientIDHaveChildWithSpecialNeeds: string = "";
	public clientIDChildWithSpecialNeedsIsBeneficiary: string = "";
	public clientIDSpecialNeeds: string = "";
	public clientIDCreditorProtection: string = "";
	public clientIDDistrustSpouse: string = "";

	public clientIDParentsListedPlan: string = "";

	//Choosing/Evaluating an Executor
	public clientIDAbilityDealForms: string = "";

	//Choosing/Evaluating a Trustee
	public clientIDBeneficiaryComfortableTrustee: string = "";
	public clientIDStrongTrustee: string = "";
	public clientIDTrusteesGetAlong: string = "";

	//Planning for Minor Children
	public clientIDRetirementAccountBeneficiariesAreChildren: string = "";
	public clientIDHasStepchildren: string = "";
	public clientIDClientHasAdoptedChildren: string = "";
	public clientIDClientHasIligitimateChildren: string = "";
	public clientIDClientHasGrownWealthyChildren: string = "";

	//Evaluating Powers of Attorney
	public clientIDIsAttorneyMoneyWise: string = "";
	public clientIDClientHasMultipleAttorneys: string = "";
	public clientIDPOAFormsOfFinancialInstitutions: string = "";
	public clientIDMinimizedChancesOfTaxPlanningOpportunities: string = "";

	//Miscellaneous
	public clientIDBeneficiaryTrust: string = "";
	public clientIDComfortableDistributions: string = "";
	public clientIDPlannedForDigitalAssets: string = "";
	public clientIDIsSurvivingSpouse: string = "";
	public clientIDAssetsOutsideTheUS: string = "";
	public clientIDExpectingLargeInheritance: string = "";
	public clientIDWantsToProvideForPet: string = "";
	public clientIDSpouseWithTenantsInCommon: string = "";

	//Evaluating Directed Medical Care
	public clientIDDocumentsShares: string = "";
	public clientIDMedicalWishes: string = "";
	public clientIDMedicaid: string = "";
	public clientIDsearchLivingWill: string = '';

	//Retirement Account Beneficiaries
	public clientIDBeneficiariesDifferentBrackets: string = "";
	public clientIDLeavingMoneyToNonProfit: string = "";
	public clientIDTrustBeneficiaryRetirementPlan: string = "";

	//Life Insurance & Annuities
	public clientIDHasLongTermInsurance: string = "";
	public clientIDLifeInsurancePrimarilyUsedForEstatePlanning: string = "";
	public clientIDHasALifePartner: string = "";
	public clientIDchildrenPrimaryOrConditionBeneficiaries: string = "";
	public clientIDEstatePrimaryOrConditionBeneficiaries: string = "";
	public clientIDTrustBeneficiaryInLifeInsurance: string = "";

	//Asset Titling
	public clientIDEverLivedWhileMarriedInCommunityPropertyState: string = "";
	public clientdIDSpouseHealthProblems: string = "";
	public clientIDTerminalIllness: string = "";
	public clientIDHeirsHaveHighIncomeAndLowerTaxBraquets: string = "";
	public clientIDTenantsDoneForCobvenience: string = "";
	public clientIDFederalOrStateTaxLawsChangeSinceLastUpdate: string = "";
	public clientIDClientWillPutHomeInRevocableTrust: string = "";

	//Planned Gifts
	public clientIDGiftsPartOfTaxReductionStrategy: string = "";
	// public clientIDGiftsCashOrSecurities: string = "";
	public clientIDHasGiftedSharesToTrust: string = "";
	public clientIDAmountOfUnrealizedInvestmentGains: number = 0;
	public clientIDLifetimeGift: string = "";
	public clientIDGiftGRAT: string = "";

	//Making your wished better known and making things easy for others
	public clientIDLetterOfInstructionBeenCirculated: string = "";
	public clientIDClientHasPickedFuneralHome: string = "";
	public clientIDEmergencyFundForSurvivors: string = "";
	public clientIDCreditCardsInJointNames: string = "";
	public clientIDLetterInstructionUpdate: string = "";

	//Complex Estate Planning
	public clientIDConcernLTHealth: string = "";
	public clientIDLiveMore1Year: string = "";
	public clientIDIlliquidHighPotentialAsset: string = "";
	public clientIDTransferContinueBusiness: string = "";
	public clientIDWillingSCINTransaction: string = "";
	public clientIDPersonsGrantPrivateAnnuity: string = "";
	public clientIDReliencGrantAnnuity: string = "";
	public clientIDUnusedTransferExclusion: string = "";
	public clientIDStrongMarriage: string = "";
	public clientIDConcernReductionTransferTax: string = "";
	public clientIDLifetimeSupport: string = "";
	public clientIDConcerSpouseHealth: string = "";
	public clientIDProperRemainBenef: string = "";
	public clientIDProvideForGenerations: string = "";
	public clientIDReallocateAssetState: string = "";
	public clientIDPersonalResidence: string = "";
	public clientIDLifeResidence: string = "";
	public clientIDTransferHomeTrust: string = "";
	public clientIDRelinquishAtDeath: string = "";
	public clientIDGiftTaxExclusion: string = "";
	public clientIDOutlivingQPRT: string = "";
	public clientIDSellHomeQRPT: string = "";
	public clientIDHomeOwnJoint: string = "";
	public clientIDTemporarilyTransferAsset: string = "";
	public clientIDContemplatedAssetHighPotential: string = "";
	public clientIDFixedAnnuityPmt: string = "";
	public clientIDPropertyIntestestSCorp: string = "";
	public clientIDIntendProperBenef: string = "";
	public clientIDFreezeEstate: string = "";
	public clientIDIncomePreducingAsset: string = "";
	public clientIDPayTrustIncomeTax: string = "";
	public clientIDGrantorBeneficiary: string = "";
	public clientIDDissatisfiedTrustTerm: string = "";
	public clientIDAmendTrust: string = "";
	public clientIDTrustDecanting: string = "";
	public clientIDTrustMoveToDecanting: string = "";
	//New as Dec 30 2019
	public clientIDCharitableAspirations: string = "";
	public clientIDAvoidTaxDeathGiftLife: string = "";
	public clientIDReduceIncomeTaxLiab: string = "";
	public clientIDReceiveIncomeFromAsset: string = "";

	// Progress Bar
	public progressBar: number = 0;
	public progressCompleted: boolean = false;
	public progressTab: number = 0;

	//New questions
	public clientIDFillingStatus: string = "";
	public isTaxReturnAnswered: string = "";
	public clientIDHasEstatePlan: string = "Yes";
	public clientIDIntendChildrenBusiness: string = "";

  // Other
  public requestContainsOtherDocs: string = "No";

    //Aset name for multiquest areas
    public clientIDAssetName: string = '';

	constructor() {
		super();
	}

	getQName(): string {
		// throw new Error("estate");
		return 'estate';
	}

	getAttributesToClearCommas(): string[] {
      return [
		  'clientIDtotalLifeInsuranceCoverage',
		  'clientIDAmountOfUnrealizedInvestmentGains'
      ];
   }

	getIgnoredAttributesForSave():string[]{
		return [
			'clientIDWill',
			'clientIDWillStatus',
			'clientIDPOA',
			'clientIDRevocableTrust',
			'clientIDRevocableTrustStatus',
			'clientIDIrrevocableTrust',
			'clientIDLifeInsurancePolicy'
		];
	  }

	  public getExtractionsAtributes(): string[] {
		return [
      //Will
      'clientIDsearchWillCreationDatePrior2010',
      'clientIDbusinessInWill',
      'clientIDresiduaryClauseInWill',
      'clientIDcontingentBeneficiaryInWill',
      'clientIDuseOfHEMSProvisions',
      'clientIDtestamentaryTrustInWill',
      'clientIDDisclaimerOrBypassTrustInWill',
      'clientIDguardiansInWill',
      'clientIDsearchPPG',
      'clientIDsearchPersonalGuardian',
      'clientIDsearchMultipleTrusts',
      'clientIDgiftConditionsInWill',
      'clientIDsharedGiftInWill',
      'clientIDmortgageInWill',
      'clientIDsearchCharity',
      'clientIDclientHasPropertyMemorandum',
      'clientIDCapitalGainsDistributionWill',
      'clientIDCapitalGainsDistributionRevTrust',
      'clientIDwillCreationDatePrior5years',
      'clientIDpersonalRepresentative',
      'clientIDreplacementExecutor',
      'clientIDwillProvideSpouse',
      'clientIDwillPersonalPropertySpouse',
      'clientIDisPouroverWill',
      'clienIDwillResidualSpouse',
      'clientIDtrusteeTestamentaryTrustInWill',
      'clientIDspouseBeneficiaryTestamentary',
      'clientIDwillRetirementAccount',
      'clientIDtrustProvisionChildrenInWill',
      'clientIDburialInsutructionsInWill',

      //Rev trust
      'clientIDHasRevocableTrust',
      'clientIDpouroverWill',
      'clientIDsearchRevocableTrustCreationDatePrior2010',
      'clientIDpowerOfAppointment',
      'clientIDsuccessorTrusteeR',
      'clientIDmortgageInRevocable',
      'clientIDsearchTrustInstrumentRequiresTrusteeToPayExecutor',
      'clientIDcontingentBeneficiaryInRevocableTrust',
      'clientIDuseOfHEMSProvisionsRevocableTrust',
      'clientIDtestamentaryTrustInRevocableTrust',
      'clientIDDisclaimerOrBypassTrustInRevocableTrust',
      'clientIDsearchPersonalGuardianRevocableTrust',
      'clientIDsearchMultipleTrustsRevocableTrust',
      'clientIDsharedGiftInRevocableTrust',
      'clientIDsearchDisclaimerProvisionRevocableTrust',
      'clientIDsearchMedicalCare',
      'clientIDrevocableTrustCreationDatePrior5years',
      'clientIDjointTrust',
      'clientIDGoverningLaw',
      'clientIDSpecificBequests',
      'clientIDTrustDistributionToSpouse',
      'clientIDABTrust',
      'clientIDTrustStateEstateTaxes',
      'clientIDchildrenTrustInTrust',
      'clientIDageDistribution',
      'clientIDtrustRetirementAccount',
      'clientIDTrustInvestmentManagement',

			//Other
			'clientIDqprt',
			
	  ]
	}
}
