import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {

  public ssoLoginForm: FormGroup;
  public ieSniffer: boolean = false;
  public showError: boolean = false;
  constructor(
    public fb: FormBuilder,
    private advisorService: AdvisorService,
  ) { }

  ngOnInit(): void {
    let ua = window.navigator.userAgent;
    let isIE = /MSIE|Trident/.test(ua);

    if ( isIE ) {
      this.ieSniffer = true;
    }
    this.ssoLoginForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email])
    })
  }

  reEnableLiginButton(event: any) {
    if (this.showError && event?.target?.value) {
      this.showError = false;
    }
  }

  async signin() {
    try {
      const { email } = this.ssoLoginForm.value;
      const { advisorExists, companyExists, hasSSOLogin, integrations }: any = await this.advisorService.userHasSSO(email);
      if (advisorExists && companyExists && hasSSOLogin) {
        const integration = integrations[0];
        window.location.replace(integration?.urlToLogin);
      } else {
        this.showError = true;
      }

    } catch(e) {
      console.log(e);
    }
  }

}
