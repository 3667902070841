import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_UNSURE,
  CONST_MONTHS,
  CONST_STUDENT_LOAN_STATUS,
  CONST_STUDENT_LOAN_TYPE,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { Student } from './student.model';


@Component({
  selector: 'app-q-student',
  templateUrl: './q-student.component.html',
})
export class QStudentComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: Student;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public whoAnswered: string;
  public answers: Array<string>[] = [];
  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_STUDENT_LOAN_STATUS, CONST_STUDENT_LOAN_TYPE, CONST_MONTHS };
  public validations = Validations;
  public yearsList:number[];
  public isAdvisor: boolean = false;
  public minDate: Date;
  public maxDate: Date;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public loanName: string = '';
  public questIndex: number;
  public toggleSearchBar: boolean = false;

  public bulletColor;

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,

    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {

    this.minDate = new Date(2000, 1, 1);
    this.maxDate = new Date();
    

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new Student();

    this.model.afterMappingLoad = () => {
     this.model.clientIDdisbursementDateYear = Number(this.model.clientIDdisbursementDateYear);
    }

    //Load session storage data from questionnare
    let loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('student');
    this.questIndex = loadedStoragedQuestionnaireData.index != null ? loadedStoragedQuestionnaireData.index : 1;
    this.loanName = (loadedStoragedQuestionnaireData.name != null) ? loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = this.loanName;
    

    //Init years list
    this.yearsList = this.getYearsArraySince(1950).reverse();

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansStudent != null ? clientHasAnswered.ansStudent : false;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'student', this.model, this.questIndex).then(response => {
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  getYearsArraySince(startYear:number):number[] {
    let length = new Date().getFullYear() - startYear;
    return [startYear].concat(Array.from({length:length},(v,k)=> startYear + k+1));
  }

  showUploader(prop: string) {
    this.model[prop] = '';
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {

    if (this.model.wantsToShowStartDateOrNot === 'N/A') this.model.clientIDStartDateMonthsQualifyEmployment = new Date(2000, 1, 1);
    if (this.model.wantsToShowEndDateOrNot === 'N/A') this.model.clientIDEndDateMonthsQualifyEmployment = new Date();

    if(this.model.clientIDStartDateMonthsQualifyEmployment > this.model.clientIDEndDateMonthsQualifyEmployment){
      QuestionnaireController.throwErrorInteraction(this, "Please be sure that the end date is more recent than the begin date");
    }else{

      if(saveForLater == false){
        this.model.progressBar = 100;
        this.model.progressCompleted = true;
        this.model.progressTab = 0;
      }

      this.qController.submitForm(this, this.model, this.clientData.clientId, 'student', saveForLater, null, silentSave);
    }

  }

  ngAfterViewInit() {
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    //this.selected = tabChangeEvent.index;
    this.model.progressTab = tabChangeEvent.index;
    //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
    if(this.model.progressCompleted == true){
      this.model.progressBar = 100;
    }else{
      this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
    }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };


      this.http.get(`${this.pdfUrl}${'student'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'student-questionnaire.pdf');
      });

  }

}
