import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationStepService } from 'app/shared/services/integration/integration-step.service';
import { INTEGRATION_STEPS } from 'app/shared/services/integration/utils';
import { environment } from 'environments/environment';
import { IntegrationsImportModalComponent } from '../../../interactions/integrations-import-modal/integrations-import-modal.component';

@Component({
  selector: 'app-redtail-clients',
  templateUrl: './redtail-clients.component.html',
  styleUrls: ['./redtail-clients.component.scss']
})
export class RedtailClientsComponent implements OnInit {
  @Input() currentIntegration: any;
  public socketMessage: string = "Nothing haha";
  public timeToImportSalesforceContacts: number = 1;

  public contactsIDs: string[] = [];

  public disableImportBtn: boolean = true;
  public loadingSalesforceProfile: boolean = false;

  displayedColumns: string[] = [
    "select",
    "clientIDFirstName",
    "clientIDLastName",
    "email",
  ];
  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  //public currentIntegration = '';

  public clientType = "";
  public searchInput = "";
  public actualPageSize: number = 10;
  public pageIndex: number = 0;
  public totalItems: number = 0;
  public clientTypes = [];

  public isLoadingRequest: boolean = false;

  private timeout: any = "";

  public firstNameFinder: string = "";
  public lastNameFinder: string = "";
  public activeIntegrationStep = "";

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    public dialog: MatDialog,
    public integrationStepService: IntegrationStepService
  ) {}

  ngOnInit() {
    let integrations = JSON.parse(
      localStorage.getItem("external-providers-interactions")
    );

    let pathname = window.location.pathname;

    this.getRedtailClientTypes();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = 10
    this.loadRedtailClients();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data.filter(
      (row) => !row._fpalpha.isImported
    ).length;
    return numSelected === numRowsMinusExcluded;
  }

  clearClientIDs() {
    this.selection.clear();
    this.dataSource.data.forEach((row) => {
      this.contactsIDs.splice(this.contactsIDs.indexOf(row.id), 1);
    });
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.clearClientIDs()
      : this.dataSource.data.forEach((row) => {
          if (
            !row._fpalpha.isImported &&
            row._fpalpha.email !== "No information" &&
            !this.contactsIDs.includes(row.id)
          ) {
            this.contactsIDs.push(row.id);
            this.selection.select(row);
          }
        });

    if (this.contactsIDs.length === 0) {
      this.disableImportBtn = true;
    } else {
      this.disableImportBtn = false;
    }
  }

  addClient(row) {
    if (this.selection.selected.includes(row)) {
      this.selection.deselect(row);
      this.contactsIDs.splice(this.contactsIDs.indexOf(row.id), 1);
    } else {
      this.selection.select(row);
      this.contactsIDs.push(row.id);
    }

    if (this.contactsIDs.length === 0) {
      this.disableImportBtn = true;
    } else {
      this.disableImportBtn = false;
    }
  }

  loadSelectedRows() {
    this.dataSource.data.forEach((row) => {
      if (this.contactsIDs.includes(row.id)) {
        this.selection.select(row);
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goToClientList() {
    this.router.navigate(["advisor/clients"]);
  }

  /*-------*/
  /*REDTAIL*/

  /*-------*/

  loadRedtailClients() {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    let query = {
      per_page: this.paginator.pageSize,
      page: this.paginator.pageIndex + 1,
    };

    if (this.clientType !== "") {
      query["type"] = this.clientType;
    }

    if (this.searchInput.length > 0) {
      /*if(this.searchInput.includes('@')){
        query['email'] = this.searchInput;
      }else{
        query['name'] = this.searchInput;
      }*/
      query["intellisearch"] = this.searchInput;
    }

    const requestQuery = Object.keys(query)
      .map((item) => `${item}=${query[item]}`)
      .join("&");

    this.http
      .get<any>(
        `${environment.apiRedtailGetClientsV2}?${requestQuery}`,
        options
      )
      .subscribe(
        (success) => {
          let cleanContacts = success.contacts.filter((contact) => {
            if (contact.first_name && contact.last_name) {
              return true;
            } else {
              return false;
            }
          });
          this.dataSource.data = cleanContacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        (error) => {
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
        }
      );
  }

  getRedtailClientTypes() {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    this.http
      .get<any>(`${environment.apiRedtailGetClientTypes}`, options)
      .subscribe(
        (success) => {
          this.clientTypes = [...success];
        },
        (error) => {
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve clients types. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
        }
      );
  }

  importRedtailClients() {
    this.isLoadingRequest = true;

    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    let contactsToImportList = [];
    this.contactsIDs.map((id) => {
      let user = this.selection.selected.find((user) => user.id == id);
      contactsToImportList.push(user);
    });

    this.http
      .post<any>(
        `${environment.apiRedtailImportClientsV2}`,
        contactsToImportList,
        options
      )
      .subscribe(
        (success) => {
          if (success.warnings.length > 0 || success.failed.length > 0) {
            this.openModal(success);
          } else {
            let snackBarRef = this.snackBarSuccess.open(
              "Contacts imported successfully! You will be redirected to your client list.",
              "See my contacts",
              {
                duration: 5000,
                panelClass: "success-snackbar",
              }
            );

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });
          }

          this.isLoadingRequest = false;
        },
        (error) => {
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
          this.isLoadingRequest = false;
        }
      );
  }

  importAllRedtailClients() {
    this.isLoadingRequest = true;

    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    let query = {};

    if (this.clientType !== "") {
      query["type"] = this.clientType;
    }

    if (this.searchInput.length > 0) {
      query["intellisearch"] = this.searchInput;
    }

    query["totalItems"] = this.totalItems;

    const requestQuery = Object.keys(query)
      .map((item) => `${item}=${query[item]}`)
      .join("&");

    this.http
      .post<any>(
        `${environment.apiRedtailImportAllClientsV2}?${requestQuery}`,
        options
      )
      .subscribe(
        (success) => {
          if (success.warnings.length > 0 || success.failed.length > 0) {
            this.openModal(success);
          } else {
            let snackBarRef = this.snackBarSuccess.open(
              "Contacts imported successfully! You will be redirected to your client list.",
              "See my contacts",
              {
                duration: 5000,
                panelClass: "success-snackbar",
              }
            );

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });
          }

          this.isLoadingRequest = false;
        },
        (error) => {
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );

          this.isLoadingRequest = false;
        }
      );
  }

  //Open modal
  openModal(data) {
    const dialogRef = this.dialog.open(IntegrationsImportModalComponent, {
      disableClose: true,
      panelClass: "modal-dialog-with-form",
      height: "470px",
      width: "500px",
      data: data,
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data == "gotit") {
        setTimeout(() => {
          this.goToClientList();
        }, 1000);
      }
    });
  }

  onChange(event: Event, type: string) {
    this.clientType = type;
    this.applyFilters();
  }

  applyFilters() {
    this.pageIndex = 0;
    this.loadRedtailClients();
  }

  cleanFilters() {
    this.clientType = "";
    this.searchInput = "";
    this.loadRedtailClients();
  }

  getPage(event) {
    this.loadRedtailClients();
  }

  cleanFilter() {
    this.clientType = "";
    this.applyFilters();
  }

  cleanInput() {
    this.searchInput = "";
    this.applyFilters();
  }

  searchByInput() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.applyFilters();
      clearTimeout(this.timeout);
    }, 1000);
  }

  findByNameOrion() {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    this.http.get<any>(`${environment.apiOrionGetClientTypes}`, options);
  }

}
