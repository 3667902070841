import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { CalculaatorService } from 'app/shared/services/calculaator.service';
import { ChartDataSets, ChartOptions, ChartType, } from 'chart.js'
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RothCalculatorV1 } from 'app/shared/classes/roth-calculator'
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { ESettingsService } from '../../../services/e-settings.service';

@Component({
  selector: 'app-roth-calculator-pdf',
  templateUrl: './roth-calculator-pdf.component.html',
  styleUrls: ['./roth-calculator-pdf.component.scss']
})
export class RothCalculatorPdfComponent implements OnInit {

  @Input() newData: any;
  @Input() displayTitle?: boolean = true;
  @Input() inputsF: any;
  @Input() assumptionsF: any;
  @Input() taxAssumptionsF: any;
  @Input() isFromRoth: boolean = false;

  public isDataAvailable: boolean = true;

  public assumptionsList = {};

  public clientFullName: string = '';

  private idClient: string;
  private area: string = 'fulltax';

  public assumptions:any;
  public inputs: any;
  public taxAssumptions: any;
  public calcFixedInputs: any = [];

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: any[] = [];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartColors: any[] = [
    {
      backgroundColor: 'rgba(74, 122, 255, 0.5)',
      hoverBackgroundColor: 'rgba(74, 122, 255, 0.5)',
      borderColor: 'rgba(74, 122, 255, 1)',
      hoverBorderColor: 'rgba(74, 122, 255, 1)',
    },
    {
      backgroundColor: 'rgba(255, 147, 30, 0.5)',
      hoverBackgroundColor: 'rgba(255, 147, 30, 0.5)',
      borderColor: 'rgba(255, 147, 30, 0.5)',
      hoverBorderColor: 'rgba(255, 147, 30, 0.5)',
    }
  ];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return "$" + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
              return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          });
      }
      }
  },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        gridLines: {
          display: true,
          color: '#e3e3e3',
          zeroLineColor: 'rgb(242, 242, 242)',
        },
        ticks: {
          fontColor: 'black',
        },
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            display: false,
            color: 'rgb(62, 64, 66)',
            zeroLineColor: 'rgb(242, 242, 242)',
          },
          ticks: {
            beginAtZero: true,
            fontColor: 'black',
            callback: (value, index, values) => {
              return `$${this.nFormatter(value, 1)}`;
            },
          },
        },
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
    colours: [
      {
        fillColor: 'rgba(33, 139, 253, 1)', strokeColor: 'rgba(33, 139, 253, 1)', highlightFill: 'rgba(33, 139, 253, 1)', highlightStroke: 'rgba(33, 139, 253, 1)',
      },
      {
        fillColor: 'rgba(215, 234, 255, 1)', strokeColor: 'rgba(215, 234, 255, 1)', highlightFill: 'rgba(215, 234, 255, 1)', highlightStroke: 'rgba(215, 234, 255, 1)',
      },
    ],
  };
  public assumptionsLineChartData: ChartDataSets [] = [];
  public assumptionsLineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return "$" + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
              return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          });
      }
      }
  },
  scales: {
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{
      gridLines: {
        display: false,
        color: '#e3e3e3',
        zeroLineColor: 'rgb(255, 255, 255)',
      },
      ticks: {
        fontColor: 'black',
      },
    }],
    yAxes: [
      {
        id: 'y-axis-0',
        position: 'left',
        gridLines: {
          display: true,
          color: '#e3e3e3',
          zeroLineColor: 'rgb(255, 255, 255)',
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          callback: (value, index, values) => {
            return `$${this.nFormatter(value, 1)}`;
          },
        },
      },
    ]
  },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
    colours: [
      {
        fillColor: 'rgba(33, 139, 253, 1)', strokeColor: 'rgba(33, 139, 253, 1)', highlightFill: 'rgba(33, 139, 253, 1)', highlightStroke: 'rgba(33, 139, 253, 1)',
      },
      {
        fillColor: 'rgba(215, 234, 255, 1)', strokeColor: 'rgba(215, 234, 255, 1)', highlightFill: 'rgba(215, 234, 255, 1)', highlightStroke: 'rgba(215, 234, 255, 1)',
      },
    ],
  };

  graphData: any;
  form: FormGroup;
  group:any = {};
  assumptionsForm: FormGroup;
  inputsForm: FormGroup;
  yearsForm: FormGroup;
  taxAssumptionsForm: FormGroup;
  buildedFirstForm: boolean = false;
  buildedSecondForm: boolean = false;
  buildTaxAssumptionsForm: boolean = false;
  step: Number = 1;
  finalData: any = null;
  withConversion: any = null;
  noConversion: any = null;
  displayedColumns: string[] = ['year', 'age', 'taxBracket', 'noConversion', 'withConversion', 'savingsImpact']
  isMiniCalculator: Boolean = false;
  needGraph = false;
  customErrorValidation = false;
  currentAge: any;
  taxBracket: any;
  conversionTaxBracket: any;
  currentTaxableTaxBracket: any;
  retiremenIncomeTaxBracket: any;
  iraDistributionTaxBracket: any;
  auxMaxNumOfYearsToConvert: any;
  iraMax: any = 1000;
  giftResult: any = {
    current: {
      giftTax: "$0.00",
    },
    new: {
      giftTax: "$0.00",
      taxSaving: "$0.00",
    },
  };
  rothCalculator: RothCalculatorV1;
  readySecondForm= false;
  public isProduction: any;
  public fullDisclaimerData: any;

  constructor(
    private calculatorService: CalculaatorService,
    private appLoaderService: AppLoaderService,
    public dialog: MatDialog,
    public snackBarWarning: MatSnackBar,
    public rothCons: RothCalculatorV1,
    public settingsService: ESettingsService
  ) {
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    this.idClient = storagedClientID.clientId;
    this.currentAge = Math.floor(parseInt(storagedClientID.age));
    this.rothCalculator = rothCons;
  }

  buildLabelsFromObject (obj: any, prefix: any = null) {
    this.group = {};
    return Object.keys(obj).map((key) => {
      if (key === 'beginningRothIRAAssetValue' || key === 'currentTaxableIncome' || key === 'withdrawalAmountGross' || key === 'retirementTaxableIncome') {
        const auxValidators =           [
          Validators.required,
          Validators.min(0),
        ];
        if (obj[key].max) {
          auxValidators.push(Validators.max(obj[key].max))
        }
        if (key === 'currentTaxableIncome') {
          if (obj[key].value > 630000) {
            obj[key].value = 630000;
          }
        }
        if (key === 'retirementTaxableIncome') {
          if (obj[key].value > 500000) {
            obj[key].value = 500000;
          }
        }
        this.group[key] = new FormControl(
          obj[key].value,
          auxValidators,
        );

      } else {
        const auxValidators = [
          Validators.required,
        ]
        if (key === 'annualConversionAmount') {
          // auxValidators.push(Validators.min(1))
          if (obj[key].value > 250000) {
            obj[key].value = 250000;
          }
          obj[key].max = 250000
        } else if (key === 'currentAge') {
          auxValidators.push(Validators.min(18));
          auxValidators.push(Validators.max(100));
          obj[key].max = 100;
          obj[key].min = 16;
        } else {
          // auxValidators.push(Validators.min(key === 'lifeExpectancy' ? this.assumptionsForm.controls.currentAge.value + 1 : 1))
          if (key === 'lifeExpectancy') {
            auxValidators.push(Validators.max(99));
          }
        }
        this.group[key] = new FormControl(
          !obj[key].value && key === 'currentAge'
          ? this.currentAge
          :  obj[key].value,
          auxValidators,
        );
      }

      if (key === 'currentAge') {
        this.group.currentAge.value = Math.floor(parseFloat(this.group.currentAge.value));
      }

      return {
        label: obj[key].label || 'Gift ammount',
        value: obj[key].value,
        key,
        max: obj[key].max || null,
        min: obj[key].min || null,
        step: obj[key].step || null,
        prefix: obj[key].prefix === 'percentage' ? '%' : obj[key].prefix === 'money' ? '$' : null,
        inputType: obj[key].prefix === 'percentage' ? 'text' : obj[key].prefix === 'money' ? 'currency' : (obj[key].prefix === 'money-slider' || obj[key].prefix === 'number-slider') ? 'money-slider' : 'text',
      };
    });
  }


  //UNICAMENTE SE USAN CUANDO SE GENERA EN REPORTE
  async getAssumptionsFieldsV2() {
    try {
      const response = await this.calculatorService.getAssumptionsV2('roth-conversion-v2', this.idClient, this.area, 1);
      this.assumptions =  this.buildLabelsFromObject(response);
      Object.assign(this.assumptionsList, response);
      this.assumptionsForm = new FormGroup({
        ...this.group,
      });
      // this.buildedFirstForm = true;
      return true
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  async getTaxAssumptionsFieldsV2() {
    try {
      const response = await this.calculatorService.getTaxAssumptionsV2('roth-conversion-v2', this.idClient, this.area, 1);
      this.taxAssumptions = this.buildLabelsFromObject(response);
      this.taxAssumptionsForm = new FormGroup({
        ...this.group,
      });
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  async getInputsFieldsV2() {
    try {
      const response = await this.calculatorService.getInputsV2('roth-conversion-v2', this.idClient, this.area, 1);
      let tempNumOfYearsToConvert = response.numOfYearsToConvert;
      delete response.numOfYearsToConvert;
      this.inputs = this.buildLabelsFromObject(response);
      Object.assign(this.assumptionsList, response);
      this.inputsForm = new FormGroup({
        ...this.group,
      });
      this.buildedSecondForm = true;
      this.newData.numOfYearsToConvert = tempNumOfYearsToConvert.value;
      this.yearsForm = new FormGroup({
        numOfYearsToConvert: new FormControl(tempNumOfYearsToConvert.value, [ Validators.required, Validators.min(1), Validators.max(this.auxMaxNumOfYearsToConvert) ]),
      });
      return true;
    } catch(e) {
      console.log(e);
      return false;
    }
  }

  calculateTaxes() {
    this.conversionTaxBracket = this.calculateTaxBracket(this.inputsForm.controls.annualConversionAmount.value + this.inputsForm.controls.currentTaxableIncome.value, new Date().getFullYear(), this.assumptionsForm.controls.rothCalcFilingStatus.value, this.calculatorTables);
    this.currentTaxableTaxBracket = this.calculateTaxBracket(this.inputsForm.controls.currentTaxableIncome.value, new Date().getFullYear(), this.assumptionsForm.controls.rothCalcFilingStatus.value, this.calculatorTables);
    this.retiremenIncomeTaxBracket = this.calculateTaxBracket(this.inputsForm.controls.retirementTaxableIncome.value, 2017, this.assumptionsForm.controls.rothCalcFilingStatus.value, this.calculatorTables);
    this.iraDistributionTaxBracket = this.calculateTaxBracket(this.inputsForm.controls.retirementTaxableIncome.value + this.inputsForm.controls.withdrawalAmountGross.value, 2017, this.assumptionsForm.controls.rothCalcFilingStatus.value, this.calculatorTables);
  }

  onSliderChange (e : any) {
    this.calculateTaxes();

    const dataset: any = {
      ...this.assumptionsForm.value,
      ...this.taxAssumptionsForm.value,
      ...this.inputsForm.value,

    };

    const {
      graph: {
        labels,
        conversion,
        noConversion,
      },
      ...aux
    }: any = this.rothCalculator.exec(dataset,this.yearsForm.controls.numOfYearsToConvert.value,'multi_year');

    this.finalData = aux;
    this.withConversion = this.finalData.rothConversion;
    this.noConversion = this.finalData.noConversion;
    this.lineChartLabels = labels;
    this.graphData = new MatTableDataSource(this.finalData.periods);
    this.assumptionsLineChartData = [
      {
        data: conversion, label: 'Conversion', pointRadius: 0, pointBackgroundColor: '#f2f2f2', pointBorderColor: 'rgb(74, 122, 255)'
      },
      {
        data: noConversion, label: 'No conversion', pointRadius: 0, pointBackgroundColor: '#f2f2f2', pointBorderColor: 'rgb(25547, 30)'
      },
    ];
  }

  onYearsChange (e : any) {

    if (this.newData !== '' && this.assumptionsForm && this.taxAssumptionsForm && this.inputsForm) {
      let dataset: any = {};
      if (this.isFromRoth) {
        dataset = {
          ...this.assumptionsF,
          ...this.taxAssumptionsF,
          ...this.inputsF,
          ...this.newData,
        };
        this.assumptionsList = {
          ...this.assumptionsF,
          ...this.taxAssumptionsF,
          ...this.inputsF,
          ...this.newData,
        };
      } else {
        dataset = {
          ...this.assumptionsForm.value,
          ...this.taxAssumptionsForm.value,
          ...this.inputsForm.value,
          ...this.newData,
        };
        this.assumptionsList = {
          ...this.assumptionsForm.value,
          ...this.taxAssumptionsForm.value,
          ...this.inputsForm.value,
          ...this.newData,
        }
      }

      this.finalData = this.rothCalculator.exec(dataset, this.newData.numOfYearsToConvert, 'multi_year');
      this.createDataSource();
    }
  }

  async createDataSource () {
    this.lineChartData = [];
    this.lineChartLabels = [];

    this.graphData = new MatTableDataSource(this.finalData.periods);
    const {
      graph: {
        labels,
        conversion: graphCon,
        noConversion: graphNo,
      },
    } = this.finalData;
    this.withConversion = this.finalData.rothConversion;
    this.noConversion = this.finalData.noConversion;
    this.lineChartLabels = labels;

    this.assumptionsLineChartData = []

    this.assumptionsLineChartData.push({
      data: graphCon, label: 'Conversion', pointRadius: 0, pointBackgroundColor: '#f2f2f2', pointBorderColor: 'rgb(74, 122, 255)'
    });
    this.assumptionsLineChartData.push({
      data: graphNo, label: 'No conversion', pointRadius: 0, pointBackgroundColor: '#f2f2f2', pointBorderColor: 'rgb(25547, 30)',
    });

  }

  nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  calculatorTables: any;

  async getTables () {
    try {
      const ans = await this.calculatorService.getCalculatorTables();
      this.calculatorTables = ans;
      this.rothCalculator.setTaxTables(this.calculatorTables);
      return true;
    } catch (e) {
      return false;
    }
  }

  async buildAllForms () {
    try {
      const tables = await this.getTables();
      const assumptions = await this.getAssumptionsFieldsV2();
      const taxAss = await this.getTaxAssumptionsFieldsV2();
      const inputs = await this.getInputsFieldsV2();
      if (!tables) {
        await this.getTables();
      }
      if (!assumptions) {
        await this.getAssumptionsFieldsV2();
      } else {
        this.buildedFirstForm = true;

      }
      if (!taxAss) {
        await this.getTaxAssumptionsFieldsV2();
      } else {
        this.buildTaxAssumptionsForm = true;

      }
      if(!inputs) {
        await this.getInputsFieldsV2();
      } else {
        this.buildedSecondForm = true;
      }
      this.onYearsChange({});
    } catch (e) {
      console.log(e);
    }

  }

  ngOnInit() {

      let currentClient: string = sessionStorage.getItem('currentClient');
      this.clientFullName = JSON.parse(currentClient).name + ' ' + JSON.parse(currentClient).lastName;

      this.buildAllForms();

    this.settingsService.getCompanyData().toPromise().then(response => {
      this.fullDisclaimerData = response;
    })
  }



   /**
   *
   * @param changes
   * On data change, make internall calculations to display columns, lines and colors.
   */
  ngOnChanges() {
    if(this.newData){
      this.onYearsChange({})
    }
  }

  /**
   * Marginal Income Tax Bracket Calculation
   */
  calculateTaxBracket(income: number, year: number, filingStatus: string, { OrdinaryIncomeTaxBracketTables, StandardDeductionTables }) {
    const ordinaryIncomeTable = OrdinaryIncomeTaxBracketTables[year][filingStatus];
    const standardDeduction = StandardDeductionTables[year][filingStatus];
    const marginalIncomeTableRates = Object.keys(ordinaryIncomeTable);
    if (income >= standardDeduction) {
        for (let i = 0; i < marginalIncomeTableRates.length; i++) {
            const rate = marginalIncomeTableRates[i];
            const topThreshold = ordinaryIncomeTable[rate] || Infinity;
            if (income <= topThreshold) {
                return rate;
            }
        }
    }
    return '0%';
  }

  getAssumptionsValue(value){
    let object = this.assumptions.find(element => element.key === value);
    return object.value
  }

  generateCanvas(){

    const yearOverYear = <HTMLElement> document.getElementById('yearOverYear');
    yearOverYear.style.marginTop = this.displayTitle ? '0px' : '135px';

    const content = <HTMLCanvasElement> document.getElementById('roth-calculator-pdf');
    const content2 = <HTMLCanvasElement> content.cloneNode(true);

    const graph = <HTMLCanvasElement> document.getElementById('graph');
    const graph2 = this.cloneCanvas(graph);
    graph2.style.width = this.displayTitle ? '100%' : '100%';
    graph2.style.height = this.displayTitle ? '260px' : '350px';
    graph2.style.margin = this.displayTitle ? '0px' : '0%';


    const title = <HTMLCanvasElement> document.getElementById('title');
    const title2 = <HTMLCanvasElement> title.cloneNode(true);

    const years = <HTMLCanvasElement> document.getElementById('years');
    const years2 = <HTMLCanvasElement> years.cloneNode(true);

    content2.insertAdjacentElement('afterbegin', graph2);
    content2.insertAdjacentElement('afterbegin', years2);
    return content2;

  }

  cloneCanvas(oldCanvas) {

    //create a new canvas
    var newCanvas = document.createElement('canvas');
    var context = newCanvas.getContext('2d');

    //set dimensions
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;

    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0);

    //return the new canvas
    return newCanvas;
  }


}
