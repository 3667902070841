import { Injectable, OnDestroy } from '@angular/core';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { QuestionnaireController } from '../Questionnaire.controller';
import { home_questions } from '../q-home/home.model';

@Injectable({
  providedIn: 'root'
})
export class InsuranceServiceService implements OnDestroy {

  public modelUploaders: { home: home_questions } = {
    home: new home_questions(),
  };
  public filesToUpload: any = {};
  public type: any = 'home';
  public list: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public clientData: any;
  public clientHasAnswered: any;
  public clientID: any;
  public area: string = 'home';
  public resourceId: any;
  public typeQuestions: any = {
    home: new home_questions(),
  }
  public addedFiles: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public _credits: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public companyId: string;
  constructor(
    private advisorService: AdvisorService,
    private authService: AuthenticationService,
    public qController: QuestionnaireController
  ) {}

  setClientID(clientId: string) {
    this.clientID = clientId;
    this.advisorService.getCompanyData().subscribe(data => {
      this.companyId = data?._id;
      this.getUserCredits();
    });
  }
  getModel() {
    return this.modelUploaders[this.type];
  }

  getType() {
    return this.type;
  }

  get clientId() {
    return this.clientID;
  }

  get getList() {
    return this.list.asObservable();
  }

  async getDataFromService(area: string) {
    try {
      const data = await this.advisorService.getHubSubQuestionnares(this.clientID, area);
      const aux = [];
      data.forEach((policy) => {
        const infoBody = {
          name: policy?.title,
          policyNumber: policy?.policyNumber || '',
          status: policy?.documentStatus || 'No status',
          recommendations: policy?.documentStatus === 'Not uploaded' ? '' : policy?.documentStatus,
          insuranceCompany: policy?.insuranceCompany || '../../../../../../assets/images/other-insurance-provider.png',
          _id: policy?._id,
          index: policy?.index,
          answered: policy?.answered,
          area,
        };
        aux.push(infoBody);
      });
      this.list.next(aux);
      return;
    } catch(e) {
      console.log(e);
    }
  }

  resetInformation() {
    this.list.next([]);
    this.filesToUpload = {};
    this.modelUploaders[this.type] = null;
    this.addedFiles.next(null);
  }

  setModelData(udpatedModel: any, last = false, reset = false) {
    if(udpatedModel?.files && last) {
      this.filesToUpload = udpatedModel?.files;
      delete udpatedModel.files;
    }
    this.filesToUpload = udpatedModel?.files;
    this.addedFiles.next(udpatedModel?.files);
    this.modelUploaders[this.type] = udpatedModel;
  }

  filesAdded() {
    return this.addedFiles.asObservable();
  }

  set resource(value: any) {
    if (value) {
      sessionStorage.setItem(this.area + 'QuestIndex', String(value?.index));
      sessionStorage.setItem(this.area + 'Name', value?.name);
      sessionStorage.setItem(this.area + 'HasBeenAnswered', String(value?.answered));
    } else {
      sessionStorage.removeItem(this.area + 'QuestIndex');
      sessionStorage.removeItem(this.area + 'Name');
      sessionStorage.removeItem(this.area + 'HasBeenAnswered');
    }
    this.resourceId = value;
  }

  get resource() {
    return this.resourceId;
  }

  async loadQuestionnaireData(index: number) {
    try {
      await this.qController.loadQuestionnaireDataAsync(this.clientID, this.type, this.modelUploaders[this.type], this.resource?.index);
      return this.modelUploaders[this.type];
    } catch(e) {
      console.log(e);
    }
  }

  async loadMultipleQuestionnaireData(indexes: any) {
    try {
      console.log(this.modelUploaders[this.type]);
      const promises = indexes.map((innerIndex) => new Promise(async (resolve, reject) => {
        try {
          const auxModel: home_questions = new home_questions();
          const ansres = await this.qController.loadQuestionnaireDataAsync(this.clientID, this.type, auxModel, innerIndex);
          resolve(ansres);
        } catch(e) {
          reject(e)
        }
      }));
      const ans = await Promise.all(promises);
      return ans;
    } catch(e) {
      console.log(e);
    }
  }

  async saveAnsweredFormQuestions(window: any, newModel: boolean = false, otherIndex: any = null, currentModel: any = null) {
    try {
      const innerModel = newModel && currentModel;
      const ans = await this.qController.submitForm(window, innerModel ? currentModel : newModel ? new home_questions() : this.modelUploaders[this.type], this.clientId, this.type, true, null, true, null, false, null, otherIndex);
    } catch(e) {
      console.log(e);
    }
  }

  async getUserCredits() {
    try {
      const ans2 = await this.advisorService.getCompanyCreditsV2(this.companyId, this.type);
      this._credits.next(ans2[this.type]);
    } catch(e) {
      console.log(e);
    }
  }

  get credits() {
    return this._credits.asObservable();
  }

  get files() {
    return this.filesToUpload;
  }

  setAreaModel(value: string) {
    this.area = value;
    this.type = value;
    if (this.type !== 'life') {
      this.getDataFromService(this.area);
    }
  }

  ngOnDestroy(): void {
      console.log('Destruyendo el servicio');
  }


}
