// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';

// Project
@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent implements OnInit {

  constructor(
    private http: HttpClient,
  ) {}

  ngOnInit() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.http.post<any>(`${environment.apiNewReleases}`, {}, options)
      .subscribe(
        success => {
          // console.logsuccess, 'Releases notes noted');
        },
        error => {
          // console.log'Error!!!');
        }
      );
  }

}
