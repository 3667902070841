import { Directive, ElementRef, AfterViewInit, ContentChildren, QueryList, Renderer2, Input, ViewContainerRef, ComponentFactoryResolver, Injector, ComponentRef } from '@angular/core';
import { QuestionnaireManagerService, StepForm } from '../services/questionnaire-manager/questionnaire-manager.service'
import { MatTabGroup } from '@angular/material/tabs';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule, MatTooltip } from '@angular/material/tooltip';
import { InfoTooltipComponent } from '../components/info-tooltip/info-tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[fpTabQuestionnaire]'
})
export class FpTabQuestionnaireDirective implements AfterViewInit {

  @ContentChildren(MatTabGroup, { descendants: true }) tabs: QueryList<MatTabGroup>;

  @Input('fpTabQuestionnaireDisableClick') fpTabDisableTabClick = true;

  @Input('fpTooltipOnEmpty') fpTooltipOnEmpty = false;

  private nextButton: HTMLButtonElement | HTMLInputElement;

  private prevButton: HTMLButtonElement | HTMLInputElement;

  private submitButton: HTMLButtonElement | HTMLInputElement;

  private nextStepAttributeName = 'fpQuestionnaireNext';

  private prevStepAttributeName = 'fpQuestionnairePrev';

  private submitAttributeName = 'fpQuestionnaireSubmit';

  private activeStep: number;

  private lastStepForms: StepForm[];

  private changeHandler: () => void;

  private nextStepHandler: Function;

  private prevStepHandler: Function;

  queryForms: HTMLFormElement[];

  buttonToogleForms: HTMLButtonElement[];

  constructor(
    public questionnaireManagerService: QuestionnaireManagerService,
    private elementRef: ElementRef, 
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.activeStep = 0;
  }

  ngAfterViewInit(): void {
    
    this.questionnaireManagerService.onValidQuestionnaire.subscribe((questionnaire: FormGroup) => {
      if(questionnaire){
        this.disableSubmitButton(false);
      }
    })
    
    this.questionnaireManagerService.formsAreReadySubject.subscribe((arg) => {
      if (arg) {
        this.initQueryListTabEvents()

        this.initStructureListeners()

        this.initFormListeners()
      }
    })

    this.questionnaireManagerService.onFormChange.subscribe((stepForms:StepForm[]) => {
      if (stepForms.length>0) {
        this.lastStepForms = stepForms;
        const activeFormIsValid = !(stepForms.length && stepForms[this.activeStep].valid)
        this.disableSubmitButton(true);
        this.disableNextButton(activeFormIsValid)  
      }
    })

  }

  initTooltipOnEmpty(){

    setTimeout(() => {

      this.removeTooltips()
      const formFields = this.elementRef.nativeElement.querySelectorAll('.mat-form-field');
  
      formFields.forEach((field) => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoTooltipComponent);
        let inputValue = ''
        if (field.querySelector('input')) {
          inputValue = field.querySelector('input').value
        }
        if(!inputValue){
          const componentRef = this.viewContainerRef.createComponent(componentFactory);
          field.insertBefore(componentRef.location.nativeElement, field.firstChild) 
          this.renderer.addClass(field, 'form-field-void-tooltip');
        }
      })

      const formButtonGroups = this.elementRef.nativeElement.querySelectorAll('.mat-button-toggle-group');
  
      formButtonGroups.forEach((field) => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoTooltipComponent);
        let buttonValue = false
        if (field.querySelectorAll('.mat-button-toggle').length>0) {
          field.querySelectorAll('.mat-button-toggle').forEach((button) => {
            if(button.classList.contains('mat-button-toggle-checked')){
              buttonValue = true;
            }
          })
        }
        if(!buttonValue){
          const componentRef = this.viewContainerRef.createComponent(componentFactory);
          field.insertBefore(componentRef.location.nativeElement, field.firstChild) 
          this.renderer.addClass(field, 'form-field-void-tooltip');
        }
      })
      
    }, 1000);

  }

  initStructureListeners() {
    let form = this.elementRef.nativeElement.querySelector('form');
    const observer = new MutationObserver(mutations => {
      this.initFormListeners()
    });
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe( form, config);
  }

  initQueryListTabEvents() {

    if (this.fpTabDisableTabClick) {
      this.disableClickOnTabs()
    }

    this.tabs.forEach(formContainer => {
      formContainer.selectedTabChange.subscribe((arg) => {
       this.updateTab(arg.index)
      })
      
      this.initNavigationButtonReferences();
      this.disableNextButton(true);
      this.disablePrevButton(true);
      this.disableSubmitButton(true);
      
      const nextStepHandler = () => {
        formContainer.selectedIndex += 1;
      };
      this.nextButton.addEventListener('click', nextStepHandler);
      this.nextStepHandler = nextStepHandler;
      
      const prevStepHandler = () => {
        formContainer.selectedIndex -= 1;
      };
      this.prevButton.addEventListener('click', prevStepHandler);
      this.prevStepHandler = prevStepHandler;
    });

  }

  disableClickOnTabs() {
    const tabRefs = this.elementRef.nativeElement.querySelectorAll('.mat-tab-label')
    tabRefs.forEach((tab) => {
      this.renderer.setStyle(tab , 'pointer-events', 'none');  
    })
  }

  updateTab(indexTab){
    this.disableNextButton(true);
    this.disablePrevButton(this.activeStep !== 0);
    this.activeStep = indexTab;
    const isCurrentFormValid = this.lastStepForms[this.activeStep].valid
    this.disableNextButton(!isCurrentFormValid)
    this.initFormListeners()
  }

  initNavigationButtonReferences() {
    this.prevButton = this.elementRef.nativeElement.querySelector(`[${this.prevStepAttributeName}]`);
    this.nextButton = this.elementRef.nativeElement.querySelector(`[${this.nextStepAttributeName}]`);
    this.submitButton = this.elementRef.nativeElement.querySelector(`[${this.submitAttributeName}]`);
  }

  disableNextButton(value: boolean){
    if (this.nextButton) {
      this.nextButton.disabled = value
    } else {
      console.error(`No se encontró ningún elemento con el atributo [${this.nextStepAttributeName}]`);
    }
  }

  disablePrevButton(value: boolean){
    if (this.prevButton) {
      this.prevButton.disabled = value
    } else {
      console.error(`No se encontró ningún elemento con el atributo [${this.prevStepAttributeName}]`);
    }
  }

  disableSubmitButton(value: boolean){
    if (this.submitButton) {
      this.submitButton.disabled = value
    } else {
      console.error(`No se encontró ningún elemento con el atributo [${this.submitAttributeName}]`);
    }
  }

  initFormListeners() {

    this.removeFormListeners()

    this.queryForms = this.elementRef.nativeElement.querySelectorAll('form');

    this.buttonToogleForms = this.elementRef.nativeElement.querySelectorAll('.mat-button-toggle-button');
    
    const changeHandler = (from?) => {
      this.questionnaireManagerService.evaluateChanges();
    };

    if (this.queryForms) {
      this.queryForms.forEach((form) => {
        const inputs = form.querySelectorAll('input');

        const { get, set } = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');

        inputs.forEach((input) => {
          input.addEventListener('input', changeHandler);
          try {
            if (input.value) {
              delete input.value;
            }
            
            Object.defineProperty(input, 'value', {
              get() {
                return get.call(this);
              },
              set(newVal) {
                console.log('New value assigned to input: ' + newVal);
                setTimeout(() => {
                  changeHandler('DEFINE PROP')
                }, 400);
                return set.call(this, newVal);
              }
            });
          } catch (error) {
            
          }
          
        })
      });
      this.changeHandler = changeHandler;
    } else {
      console.error('No se encontró ningún elemento con el atributo "form"');
    }

    if (this.buttonToogleForms) {
      this.buttonToogleForms.forEach((form) => {
        form.addEventListener('click', changeHandler);
      });
      this.changeHandler = changeHandler;
    } 

  }

  removeFormListeners() {
    if (this.queryForms) {
      this.queryForms.forEach((form) => {
        const inputs = form.querySelectorAll('input');
        inputs.forEach((input) => {
          input.removeEventListener('blur', this.changeHandler);
        })
      });
    }
  }

  removeTooltips(){
    const tooltips = this.elementRef.nativeElement.querySelectorAll('app-info-tooltip')
    tooltips.forEach((el) => {
      el.remove()
    })
  }

}
