import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { getRandomInt, readFromStoragedObject } from "app/shared/helpers/utils";
import { AdvisorService } from "app/shared/services/advisor.service";
import { PageData } from "app/views/reports/estateSnapshot/EstateSnapshotTemplateReport.class";
import { GraphMap } from "../graph-tool/GraphTool.class";
//import { GRAPH_RESPONSE_V2 } from './response_v2';

export interface Section {
  htmlId: string;
  title: string;
  sectionColor: string;
  components: Array<{
    htmlId: string;
    componentType: number;
    title?: string;
    displayBorder?: boolean;
    printInOnePage?: boolean;
    extraStyles?: any;
    content:
      | Component1
      | Component2
      | Component4
      | Component5
      | Component6
      | Component7
      | Component10
      | Component11
      | Component12
      | GraphMap;
  }>;
}

export interface Component1 {
  title?: string;
  imageUrl?: string;
  rows?: Array<Array<string | number>>;
}

export interface Component2 {
  title?: string;
  paragraph?: string;
  class?: string;
}

export interface Component4 {
  title: string;
  description: string;
  subsections: Array<{ title: string; description: string }>;
}

export interface Component5 {
  title?: string;
  rows: Array<{
    title: string;
    rows: Array<{ money: number; name: string }>;
  }>;
}

export interface Component6 {
  title: string;
  cells: Array<{ title: string; description: string }>;
}

export interface Component7 {
  title: string;
  sections: Array<
    Array<{
      title: string;
      sectionColor?: string;
      descriptionPoints?: Array<{ point: string }>;
      description?: string;
      rows?: Array<{ money: number; name: string }>;
      percentageRows?: Array<{ name: string; quantity: number }>;
    }>
  >;
}
// New top summary section
export interface Component10 {
  title: string;
  align?: "left" | "center" | "right";
  globalClass?: string;
  sectionGroups: Array<
    Array<{
      iconUrl?: string;
      iconPosition?: "start" | "end";
      iconClass?: string;
      title: string;
      titleClass?: string;
      titleStyle?: any;
      content: string;
      contentStyle?: any;
      contentClass?: string;
    }>
  >;
}

export interface Component11 {
  title: string;
  globalClass?: string;
  sections: (
    | Component1
    | Component2
    | Component4
    | Component5
    | Component6
    | Component7
    | Component10
    | GraphMap
  )[];
}

export interface Component12 {
  title: string;
  globalClass?: string;
  sections: GraphMap[];
}

@Component({
  selector: "estate-snapshot",
  templateUrl: "./estate-snapshot.component.html",
  styleUrls: ["./estate-snapshot.component.scss"],
})
export class EstateSnapshot implements OnInit, AfterViewInit {
  public responseFromServer: { documents: Array<Section>; status: string };
  public snapshotId: number = getRandomInt(1, 100);
  public isLoading: boolean = true;
  public status: string = "Not requested";
  public spiningNoSenseText: string = "Please wait ...";

  @Input() applyPrintableStyle: boolean = false;
  @Input() dataIndex: number = 1;
  @Input() setData: any = null;
  @Input() estateSnapshotStatus: string = "";

  @Output() onUpdateStatus: EventEmitter<string> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<string> = new EventEmitter();

  constructor(
    public advisorService: AdvisorService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    //this.onUpdateStatus.emit('Not requested');
    this.loadData();

    // Change text for setInterval(doStuff, 5000);
    let someDummyTexts: string[] = [
      "Gathering data...",
      "Generating family map...",
      "Downloading images...",
      "Compiling summary...",
    ];
    let i: number = 0;
    let doStuff = () => {
      this.spiningNoSenseText = someDummyTexts[i];
      i++;
      if (i > someDummyTexts.length - 1) {
        i = 0;
      }
    };
    setInterval(doStuff, 3000);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log("this.ESS**", this.estateSnapshotStatus);
    }, 1000);
  }

  /**
   * loadData()
   * Load all the required data for the estate snapshot component
   * Reads from: SessionStorage, EstateSnapshot, EstateSnapshotMetadata
   * Emits: onUpdateStatus
   */
  async loadData() {
    this.updateIsLoadingStatusTo(true);
    this.responseFromServer = undefined;

    let clientId = readFromStoragedObject(
      "currentClient",
      "clientId",
      "Session"
    );
    let assetId: number = this.dataIndex;

    // If the data is loaded from the component input, as in the external snapshot.
    if (this.setData != null) {
      this.responseFromServer = this.setData;
      //this.isLoading = false;
      this.updateIsLoadingStatusTo(false);
      this.onUpdateStatus.emit("Ready");
    } else {
      if (clientId && assetId) {
        //Load data, considering the scenario where the snapshot data is on the second index, but not in the first.

        let snapshotMetadataIndex1: any =
          await this.advisorService.getEstateSnapshotMetaData(
            clientId,
            assetId
          );
        let snapshotMetadataIndex2: any =
          await this.advisorService.getEstateSnapshotMetaData(
            clientId,
            assetId == 1 ? 2 : 1
          );
        let selectedSnapshotMetadata: any = undefined;

        //First case, the data is on the first index
        if (
          snapshotMetadataIndex1 &&
          snapshotMetadataIndex1.status &&
          snapshotMetadataIndex1.status != "Not requested"
        ) {
          selectedSnapshotMetadata = snapshotMetadataIndex1;
          assetId = 1;
          //Second case the data is on the second index, but not in the first one.
        } else if (
          selectedSnapshotMetadata == undefined &&
          snapshotMetadataIndex2 &&
          snapshotMetadataIndex2.status &&
          snapshotMetadataIndex2.status != "Not requested"
        ) {
          selectedSnapshotMetadata = snapshotMetadataIndex2;
          assetId = 2;
        }

        //There is metadadata related to some index
        if (selectedSnapshotMetadata != undefined) {
          const { status }: any = selectedSnapshotMetadata;
          this.status = status;
          // The estatus is sent to other components from here
          this.onUpdateStatus.emit(this.status);
          if (
            this.status === "Ready" ||
            this.status === "Pending" ||
            this.status === "In review" ||
            this.status === "On Hold"
          ) {
            this.advisorService
              .getEstateSnapshotData(clientId, assetId)
              .then((response: any) => {
                //Comment the next line to read from a local file response.
                this.responseFromServer = response;
                //this.isLoading = false;
                this.updateIsLoadingStatusTo(false);
              });
          } else {
            //this.isLoading = false;
            this.updateIsLoadingStatusTo(false);
          }
        } else {
          // The estatus is sent to other components from here
          this.onUpdateStatus.emit("Not requested");
        }
      }
    }

    // *** START Debug or testing lines
    //this.responseFromServer = GRAPH_RESPONSE_V2;
    //this.isLoading = false;
    // ** END Debug or testing lines
  }

  updateIsLoadingStatusTo(isLoading: boolean) {
    this.isLoading = isLoading;
    setTimeout(() => {
      this.onLoaderStatusChange.emit(String(isLoading));
    }, 5500);
  }

  afterRedraw() {
    console.log("afterRedraw 3");
    //this.onLoaderStatusChange.emit(String(true));
  }

  // Detect a change on index asset
  ngOnChanges(changes: SimpleChanges) {
    if (
      Boolean(changes.setData) &&
      changes.setData.currentValue != changes.setData.previousValue
    ) {
      this.setData = changes.setData.currentValue;
      this.loadData();
    }

    if (
      Boolean(changes.dataIndex) &&
      changes.dataIndex.currentValue != changes.dataIndex.previousValue
    ) {
      this.loadData();
    }
  }

  /**
   * Maps the backend response, each document into one big page.
   */

  async responseToPagesData(): Promise<PageData[]> {
    let pagesData: PageData[] = [];

    this.responseFromServer.documents.map((responsePage) => {
      let canvases = responsePage.components.map((component) => {
        return document.getElementById(
          this.snapshotId + "_" + component.htmlId
        );
      });

      pagesData.push({ pageTitle: responsePage.title, canvases: canvases });
    });

    return pagesData;
  }

  public getSectionsTitle(): string[] {
    return this.responseFromServer.documents.map((section) => {
      return section.title;
    });
  }
}
