import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { readFromStoragedObject } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-estate-success-dialog',
  templateUrl: './estate-success-dialog.component.html',
  styleUrls: ['./estate-success-dialog.component.scss']
})
export class EstateSuccessDialogComponent implements OnInit {

  public info: any = {};
  public hasGeneralQuest: Boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EstateSuccessDialogComponent>
  ) {
    this.info = data;
    this.hasGeneralQuest = readFromStoragedObject('advisorPreferences', 'hasGeneralQuest');
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(() => {});
  }

  hub() {
    this.dialogRef.close('to opportunities');
  }
  tryAgain() {
    this.dialogRef.close('to retry');
  }

}
