import { getElementHeight, getRandomStr } from "app/shared/helpers/utils";
import { table } from "console";
import { dataBalance } from "../dataBalance";

export function balanceSheetCutter(balanceData: any, scenario: any = 'today') {
  let pages: any = [];

  let dictionaryScenarios = {
    'today': 'today',
    1: 'scenario1',
    2: 'scenario2',
    3: 'scenario3'
  }

  let dictionarySectionsIds = {
    tableEstateAssetsInside: {
      primaryId: scenario + '_main_section_inside',
      graphId: '',
      tableId: scenario + '_table_inside',
      graphKey: '',
      keyRow: 'inside'
    },
    tableEstateAssetsOutside: {
      primaryId: scenario + '_main_section_outside',
      graphId: scenario + '_graph_outside',
      tableId: scenario + '_table_outside',
      graphKey: 'doughnutOutsideEstate',
      keyRow: 'outside'
    },
    tableLiabilities: {
      primaryId: scenario + '_main_section_liabilities',
      graphId: scenario + '_graph_liabilities',
      tableId: scenario + '_table_liabilities',
      graphKey: 'doughnutLiabilitiesBreakdown',
      keyRow: 'liebilities'
    },
    tableTotals: {
      primaryId: scenario + '_main_section_totals',
      graphId: '',
      tableId: scenario + '_table_totals',
      keyRow: 'totals'
    }
  };

  let addPage = () =>{
    let page = {
      title: '',
      data: {},
      uid: getRandomStr(12),
      maxHeigth: 531,
      sectionName: ''
    }
    pages.push(page);
  };

  addPage();


  let getDataOfSection = (section) => {
    let directData = ['estateBreakdown', 'ownerShipBreakDown'];
    if(directData.includes(section)){
      return balanceData[section]
    }
    return balanceData[dictionaryScenarios[scenario]][section];
  };


  let getItemsOfSection = (sectionData, remaingSpace, sectionName, firstPageBySection) => {
    //console.log('paginas actuales', JSON.parse(JSON.stringify(pages)), pages.length);
    // let spaceHeader = firstPageBySection ? 24 + 30 + 32 + 32 + 54 : 0;
    let spaceHeader =  24 + 30 + 32 + 32 + 54;
    remaingSpace = remaingSpace - spaceHeader;
    console.log('sectionData -> ', remaingSpace, sectionName, sectionData);
    let nodeHTMLElement;
    let node_height = 0;

    let copySectionData = JSON.parse(JSON.stringify(sectionData));


    let heigthRows = [];
    let rowsPerPage = [];
    if(firstPageBySection){
      console.log('Primer parte de la sección')
      firstPageBySection = false;
      nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
      if(!nodeHTMLElement) {console.log('undefined elemnt with Id: ', dictionarySectionsIds[sectionName].primaryId)}
      node_height = getElementHeight(nodeHTMLElement);
      //console.log('node', dictionarySectionsIds[sectionName].primaryId, node_height, remaingSpace);
      if(node_height <= remaingSpace){
        pages[pages.length - 1].data[sectionName] = sectionData;
        pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
        // addPage();
        remaingSpace -= node_height;
        pages[pages.length - 1].maxHeigth = remaingSpace;
        console.log('Entra todo el nodo', sectionName, dictionarySectionsIds[sectionName].graphKey, pages);

        return;
      }
      nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].graphId);
      let graph_height;
      if(nodeHTMLElement){
        graph_height = getElementHeight(nodeHTMLElement);
      }
      // //console.log('graph_height', dictionarySectionsIds[sectionName].graphId, graph_height, remaingSpace);
      if(graph_height <= remaingSpace){
        console.log('Entra solo la grafica', sectionName, dictionarySectionsIds[sectionName].graphKey);
        pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
      }else{
        console.log('No entra la gráfica')
        if(graph_height){
          addPage();
          remaingSpace = 531 - spaceHeader;
          console.log("se añade pag para grafica", remaingSpace);

          nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
          node_height = getElementHeight(nodeHTMLElement);
          //console.log("otra vez", node_height);
          if (node_height <= remaingSpace) {
            console.log('entra todo después de añadir pag');
            remaingSpace -= node_height;
            pages[pages.length - 1].maxHeigth = remaingSpace;
            // //console.log( "section in page", remaingSpace, sectionName, dictionarySectionsIds[sectionName].graphKey);
            pages[pages.length - 1].data[sectionName] = sectionData;
            pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
            // //console.log("pages***", pages);
            return;
          } else {
            console.log('Solo entra gráfica después de añadir pag.');

            pages[pages.length - 1].data[dictionarySectionsIds[sectionName].graphKey] = getDataOfSection(dictionarySectionsIds[sectionName].graphKey);
          }
        }
      }
    }
    if(sectionData.dataRow.length){
      console.log('hay rows por ingresar en ', remaingSpace);
      sectionData.dataRow.map((row: any, idx_row) => {
        nodeHTMLElement = document.getElementById('today_' + dictionarySectionsIds[sectionName].keyRow + '_row_' + idx_row);
        if(nodeHTMLElement){
          node_height = getElementHeight(nodeHTMLElement);
          heigthRows.push(node_height);
          remaingSpace -= node_height;
          pages[pages.length - 1].maxHeigth = remaingSpace;
        }
        if(remaingSpace >= 0){
          console.log('hay espacio para row', idx_row, remaingSpace);
          pages[pages.length - 1].maxHeigth = remaingSpace;
          rowsPerPage.push(row);
          if(sectionData.dataRow.length === idx_row + 1){
            if(!pages[pages.length - 1].data.hasOwnProperty(sectionName)){
              rowsPerPage = [];
              pages[pages.length - 1].data[sectionName] = JSON.parse(JSON.stringify(sectionData));
              console.log('entraron en pag.', pages.length - 1, JSON.parse(JSON.stringify(pages)));
            }else{
              //console.log('ya existían');
            }
          }
        }else{
          console.log('NOOO hay espacio para row', idx_row, remaingSpace);
          copySectionData.dataRow = JSON.parse(JSON.stringify(rowsPerPage));
          if(!sectionData){
            return;
          }
          sectionData.dataRow.splice(0, idx_row);
          copySectionData.totals = [];
          copySectionData.continue = true;
          rowsPerPage = [];
          pages[pages.length - 1].data[sectionName] = JSON.parse(JSON.stringify(copySectionData));
          addPage();
          pages[pages.length - 1].data['hasPreviousPage'] = true;

          //console.log('pages -> ', JSON.parse(JSON.stringify(pages)), pages[pages.length - 1].maxHeigth);
          //console.log('seccion cortada', JSON.parse(JSON.stringify(copySectionData.dataRow)));
          //console.log('seccion a enviar otra vez', JSON.parse(JSON.stringify(sectionData.dataRow)));
          let dataToSend = JSON.parse(JSON.stringify(sectionData));
          sectionData = null;
          getItemsOfSection(JSON.parse(JSON.stringify(dataToSend)), pages[pages.length - 1].maxHeigth, sectionName, false);
        }
      });
    }else{
      nodeHTMLElement = document.getElementById(dictionarySectionsIds[sectionName].primaryId);
        if(nodeHTMLElement){
          node_height = getElementHeight(nodeHTMLElement);
          heigthRows.push(node_height);
          remaingSpace -= node_height;
          pages[pages.length - 1].maxHeigth = remaingSpace;
        }
        if(remaingSpace > 0){
          pages[pages.length - 1].maxHeigth = remaingSpace;
          pages[pages.length - 1].data[sectionName] = sectionData;
        }else{
          addPage();
          getItemsOfSection(sectionData, pages[pages.length - 1].maxHeigth, sectionName, false);
        }
    }
    return;
  };


  let cutterBalance = (section?: any) => {
    let spaceInFirstPage = 300;
    if(pages.length === 1 && !section){
      pages[pages.length - 1].sectionName = 'Balance Sheet - Today';
      ['estateBreakdown', 'ownerShipBreakDown','doughnutInsideEstate' ].map(section => {
        pages[pages.length - 1].data[section] = getDataOfSection(section);
      });
    }else{
      // //console.log('espacio en pag', pages[pages.length - 1].maxHeigth, section);
      getItemsOfSection(getDataOfSection(section), section === 'tableEstateAssetsInside' ? spaceInFirstPage : pages[pages.length - 1].maxHeigth, section, true);
    }
  };

  cutterBalance();
  Object.keys(dictionarySectionsIds).map(section => {
    cutterBalance(section);
  });

  //console.log('balanceData cutted', JSON.parse(JSON.stringify(balanceData)));
  console.log('cutBalancePages', JSON.parse(JSON.stringify(pages)));


  return pages;
}
