import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-precisefp-instructions',
  templateUrl: './precisefp-instructions.component.html',
  styleUrls: ['./precisefp-instructions.component.css']
})
export class PrecisefpInstructionsComponent implements OnInit {

  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }

  // @ViewChild('stepper', { static: false }) private stepper: MatStepper;


  ngOnInit() {

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  connectPrecisefp(){

    let client_id = '';
    let redirectUri = '';

    if(this.location == 'localhost:4200'){
      //Local
      client_id = '30'
      redirectUri = 'https://api.fpalpha.app/v1/devtools/precisefp/callback'
    }else if(this.isProduction){
      //Production
      client_id = '30'
      redirectUri = 'https://app.fpalpha.com/precisefp/pending'
    }else{
       //Testing
      client_id = '30'
      redirectUri = 'https://app.fpalpha.app/precisefp/pending'
    }

    window.location.replace(`https://app.precisefp.com/oauth/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirectUri}&grant_type=authorization_code`);
  }
}
