import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { RoleModalComponent } from 'app/views/enterprise-settings/company/modals/role-modal/role-modal.component';
import { AdvisorInfoModalComponent } from 'app/views/enterprise-settings/company/modals/advisor-info-modal/advisor-info-modal.component';
import { InviteAdvisorModalComponent } from 'app/views/enterprise-settings/company/modals/invite-advisor-modal/invite-advisor-modal.component';
import { fpAnimations } from 'app/shared/animations/fp-animations';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  animations: fpAnimations
})
export class CompanyComponent implements OnInit {

  public imageURL: string = '';
  public imageBG: File | string;
  public isImageUploading: boolean = false;
  public ascendingOrderName: boolean = false;
  public ascendingOrderRole: boolean = false;
  public fetching: boolean = false;
  public advisorIDList: Array<string> = [];
  public initialAdvisorList: Array<any> = [];
  public filterAdvisorList: Array<any> = [];
  public rolesIDList: Array<string> = [];
  public rolesList: Array<any> = [];
  public initialRolesList: Array<any> = [];
  public permissionsList: Array<any> = [];
  public companyID: string = '';
  public isLoading: boolean = true;
  public isProduction: boolean = true;
  public term: string;
  public isMasterAccount: boolean = false;

  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  @ViewChild('mainstepper', { static: true }) private mainstepper: MatStepper;

  constructor(private settingsService: ESettingsService,
              public dialog: MatDialog,
              public snackBarSuccess: MatSnackBar,
              public snackBarError: MatSnackBar,) { }

  ngOnInit() {
    this.getCompanyData();
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
  }

  getCompanyData(){
    this.settingsService.getCompanyData().subscribe(data => {
      
      this.imageBG = data.logo && 'uploaded';
      this.imageURL = data.logo ? data.logo : '';
      this.advisorIDList = data.advisorList;
      this.rolesIDList = data.roles;
      this.companyID = data._id;
      //TEAM DATA
      // this.getTeamData();
      this.initialAdvisorList = data.advisorList;
      this.filterAdvisorList = this.initialAdvisorList;
      this.isLoading = false;
      // this.getRolesData();
      this.isMasterAccount = data.isMasterAccount;
    }, error => {
      console.log('Error', error);
      this.isLoading = false;
    })
  }

  // getTeamData(){
  //   this.settingsService.getTeamData(this.advisorIDList).subscribe(data => {
  //     this.initialAdvisorList = data.advisorList;
  //     this.filterAdvisorList = this.initialAdvisorList;
  //     this.isLoading = false;
  //   }, error => {
  //     console.log("Error team data", error);
  //     this.isLoading = false;
  //   })
  // }

  async updateLogo($event){

    this.imageBG = $event;

    if (this.imageBG !== 'uploaded' && this.imageBG !== '' && this.imageBG !== undefined && this.imageBG !== 'undefined') {
      this.isImageUploading = true;
      const formData = new FormData();
      formData.append('file', this.imageBG);
      this.settingsService.updateCompanyLogo(formData).then(data => {
        this.isImageUploading = false;
      })
    }
  }

  onSearchInputChange(e) {
    let searchValue = e.srcElement.value.toLowerCase();
    
    this.filterAdvisorList = this.initialAdvisorList.filter((advisor) => {
      return advisor.fullName.toLowerCase().includes(searchValue) || advisor.email.toLowerCase().includes(searchValue);
    })
  }

  onSearchInputChangeRoles(e){
    let searchValue = e.srcElement.value.toLowerCase();
    
    this.rolesList = this.initialRolesList.filter((advisor) => {
      return advisor.name.toLowerCase().includes(searchValue);
    })
  }

  /**
  * @name sortBy
  * @description Sorts the advisor list by the term provided.
  * @param term Can be Name and Status 
  */

  sortBy(term) {

    this.term = term;
    this.fetching = true;
    this.settingsService.getTeamData(this.advisorIDList).subscribe(data =>{
          let assortedAdvisors = data.advisorList;
          if (this.term === 'name') {

            this.ascendingOrderName = !this.ascendingOrderName;

            let sortedAdvisors = assortedAdvisors.sort((a, b) => {
              a = a.fullName.toLowerCase();
              b = b.fullName.toLowerCase();
              /*a = a.fullName.split(' ')[1].toLowerCase();
              b = b.fullName.split(' ')[1].toLowerCase();*/
              if (this.ascendingOrderName == true) {
                return (a > b) ? -1 : (a == b) ? 0 : 1
              } else {
                return (a > b) ? 1 : (a == b) ? 0 : -1
              }
            });

            this.filterAdvisorList = sortedAdvisors;
            this.fetching = false;

          }
        },
        error => {
          console.log(error);
        });
  }

  //*** =====> IMPORTANT <===== */
  //*** ALL FUNCTIONS BELOW BELONGS TO ENTERPRISE SETTINGS STUFF WE DID IN THE PAST AND WE NEED TO SEE IF THEY ARE USEFUL ACCORDING TO THE NEW SCOPE*/

  // getRolesData(){
  //   this.settingsService.getCompanyRoles(this.companyID).subscribe(data => {
      
  //     this.initialRolesList = data.roles;
  //     this.rolesList = this.initialRolesList;
  //     this.permissionsList = data.permissions;
  //   }, error => {
  //     console.log("Error roles", error);
  //   })
  // }

  // addNewRole(body){
  //   this.settingsService.addNewRol(this.companyID, body).then(data => {
  //     this.snackBarSuccess.open("Role created successfully", "Ok", {
  //       duration: 5000,
  //       panelClass: 'success-snackbar'
  //     });
  //     this.getRolesData();
  //   }).catch(error => {
  //     console.log("Error", error);
  //     this.snackBarError.open("Cannot create role.", "Dismiss", {
  //       duration: 5000,
  //       panelClass: 'error-snackbar'
  //     });
  //   });
  // };

  // updateRole(roleId, body){
  //   this.settingsService.editRol(this.companyID, roleId, body).then(data => {
  //     this.snackBarSuccess.open("Role edited successfully", "Ok", {
  //       duration: 5000,
  //       panelClass: 'success-snackbar'
  //     });
  //     this.getRolesData();
  //   }).catch(error => {
  //     this.snackBarError.open("Cannot edit role.", "Dismiss", {
  //       duration: 5000,
  //       panelClass: 'error-snackbar'
  //     });
  //   });
  // };

  //Open edit member modal
  // openEditMemberModal(memberData){
  //   const dialogRef = this.dialog.open(AdvisorInfoModalComponent, {
  //     // disableClose: true,
  //     panelClass: 'modal-dialog-with-form',
  //     height: '525px',
  //     width: '800px',
  //     data: {advisorData: memberData, rolesList: this.rolesList},
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
      
  //   });
  // }

  //Open roles modal
  // openRoleModal(roleData = null){
  //   const dialogRef = this.dialog.open(RoleModalComponent, {
  //     // disableClose: true,
  //     panelClass: 'modal-dialog-with-form',
  //     height: '490px',
  //     width: '750px',
  //     data: {
  //       permissions: this.permissionsList,
  //       roleData
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
      
  //     if(data.type == 'create'){
  //       this.addNewRole(data.body);
  //     }
  //     if (data.type === 'edit'){
  //       this.updateRole(data.roleId, data.body);
  //     }
  //   });
  // }

  //Open invite member modal
  // openInviteMemberModal(){
  //   const dialogRef = this.dialog.open(InviteAdvisorModalComponent, {
  //     // disableClose: true,
  //     panelClass: 'modal-dialog-with-form',
  //     height: '570px',
  //     width: '650px',
  //     data: {rolesList: this.rolesList},
  //   });

  //   dialogRef.afterClosed().subscribe(data => {
      
  //   });
  // }

}
