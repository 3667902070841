import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { getRandomInt } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-medicare-premiums',
  templateUrl: './medicare-premiums.component.html',
  styleUrls: ['./medicare-premiums.component.scss']
})
export class MedicarePremiumsComponent implements OnInit {

  public topRowValues: string[] = ['$0','$54', '$135', '$217', '$298', '$325'];
  public bottomRowValues: string[] = ['$0','$12', '$32', '$51', '$70', '$76'];
  public labels: string[] = ['$0','$85k', '$107k', '$133.5k', '$160k', '$500k', '$600k'];
  public currentSector: number;
  public myId: number

  @Input() data: LineDataInterface | any;
  @Input() pdfColor: any;

  constructor() {
    this.myId = getRandomInt(1, 300)
   }

  ngOnInit() {
    this.data = {
      dotIndicator: {value:0, legend: ''},
      dotPositionX: 21.5,
      taxReturnYear: '2019',
      filingStatus: 'Single'
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }

    let data: LineDataInterface = changes.data.currentValue;
    let sectorInitialPos = 27.5;
    let sectorJump = 12.85;
    let coverageYear = Number(data.taxReturnYear) + 2;
    let isSingle: boolean = (data.filingStatus == 'Single' || data.filingStatus == 'Other')
    let sector: number = this.obtainSectorForCoverageYear(data.dotIndicator.value, coverageYear, isSingle);
    this.data.dotPositionX = sectorInitialPos + (sector * sectorJump);

    this.currentSector = sector;
  }

  obtainSectorForCoverageYear(dotValue: number, coverageYear: number = 2021, isSingle: boolean = true): number {
    let sector: number = 0;
    if(coverageYear == 2021){

      this.topRowValues = ['$0','$54', '$135', '$217', '$298', '$325'];

      if(isSingle){
        sector =
          dotValue <=  88000 ? 0 :
            dotValue <= 111000 ? 1 :
              dotValue <= 138000 ? 2 :
                dotValue <= 165000 ? 3 :
                  dotValue <= 500000 ? 4 : 5;
        this.labels = ['$0','$88k', '$111k', '$138k', '$165k', '$500k+', '',/*'$600k+'*/];
      }else{
        sector =
          dotValue <= 176000 ? 0 :
            dotValue <= 222000 ? 1 :
              dotValue <= 276000 ? 2 :
                dotValue <= 330000 ? 3 :
                  dotValue <= 750000 ? 4 : 5;
        this.labels = ['$0','$176k', '$222k', '$276k', '$330k', '$750k+', ''/*'$900k+'*/];
      }

    }else if(coverageYear == 2022){

      this.topRowValues = ['$0','$68', '$170', '$272', '$374', '$408'];
      this.bottomRowValues = ['$0','$12', '$32', '$52', '$71', '$78'];

      if(isSingle){
        sector =
          dotValue <=  91000 ? 0 :
            dotValue <= 114000 ? 1 :
              dotValue <= 142000 ? 2 :
                dotValue <= 170000 ? 3 :
                  dotValue <= 500000 ? 4 : 5;
        this.labels = ['$0','$91k', '$114k', '$142k', '$170k', '$500k+', '' /* '$600k+'*/];
      }else{
        sector =
          dotValue <= 182000 ? 0 :
            dotValue <= 228000 ? 1 :
              dotValue <= 284000 ? 2 :
                dotValue <= 340000 ? 3 :
                  dotValue <= 750000 ? 4 : 5;
        this.labels = ['$0','$182k', '$228k', '$284k', '$340k', '$750k+', '' /*'$900k+'*/];
      }

    }else if(coverageYear == 2023){

      this.topRowValues = ['$0','$65.90', '$164.80', '$263.70', '$362.60', '$395.60'];
      this.bottomRowValues = ['$0','$12.20', '$31.50', '$50.70', '$70.00', '$76.40'];

      if(isSingle){
        sector =
          dotValue <=  97000 ? 0 :
            dotValue <= 122000 ? 1 :
              dotValue <= 153000 ? 2 :
                dotValue <= 183000 ? 3 :
                  dotValue <= 500000 ? 4 : 5;
        this.labels = ['$0', '$97k', '$122k', '$153k', '$183k', '$500k+', '' /*'$600k+'*/];
      }else{
        sector =
          dotValue <= 194000 ? 0 :
            dotValue <= 244000 ? 1 :
              dotValue <= 306000 ? 2 :
                dotValue <= 366000 ? 3 :
                  dotValue <= 750000 ? 4 : 5;
        this.labels = ['$0', '$194k', '$246k', '$306k', '$366k', '$750k+', '' /*'$900k+'*/];
      }

    }
    else if(coverageYear == 2024){

      this.topRowValues = ['$0','$69.90', '$174.70', '$279.50', '$384.30', '$419.30'];
      this.bottomRowValues = ['$0','$12.90', '$33.30', '$53.80', '$74.20', '$81.00'];

      if(isSingle){
        sector =
          dotValue <=  97000 ? 0 :
            dotValue <= 122000 ? 1 :
              dotValue <= 153000 ? 2 :
                dotValue <= 183000 ? 3 :
                  dotValue <= 500000 ? 4 : 5;
        this.labels = ['$0', '$97k', '$123k', '$153k', '$183k', '$500k+', '' /*'$600k+'*/];
      }else{
        sector =
          dotValue <= 194000 ? 0 :
            dotValue <= 244000 ? 1 :
              dotValue <= 306000 ? 2 :
                dotValue <= 366000 ? 3 :
                  dotValue <= 750000 ? 4 : 5;
        this.labels = ['$0', '$194k', '$246k', '$306k', '$366k', '$750k+', '' /*'$900k+'*/];
      }

    }
    else if(coverageYear == 2025){

      this.topRowValues = ['$0','$69.90', '$174.70', '$279.50', '$384.30', '$419.30'];
      this.bottomRowValues = ['$0','$12.90', '$33.30', '$53.80', '$74.20', '$81.00'];

      if(isSingle){
        sector =
          dotValue <=  97000 ? 0 :
            dotValue <= 122000 ? 1 :
              dotValue <= 153000 ? 2 :
                dotValue <= 183000 ? 3 :
                  dotValue <= 500000 ? 4 : 5;
        this.labels = ['$0', '$97k', '$123k', '$153k', '$183k', '$500k+', '' /*'$600k+'*/];
      }else{
        sector =
          dotValue <= 194000 ? 0 :
            dotValue <= 244000 ? 1 :
              dotValue <= 306000 ? 2 :
                dotValue <= 366000 ? 3 :
                  dotValue <= 750000 ? 4 : 5;
        this.labels = ['$0', '$194k', '$246k', '$306k', '$366k', '$750k+', '' /*'$900k+'*/];
      }

    }
    else {
      console.log('The medicare coverage year case is undefined for: ' + coverageYear)
    }

    return sector

  }

}


interface LineDataInterface {

  componentType?: string,
  dotIndicator: {value:number, legend: string},
  dotPositionX: number,
  taxReturnYear: string,
  filingStatus: string,

}


