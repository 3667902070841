// Angular
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";
import { Router } from '@angular/router';

// Project
import { AdvisorService } from '../../../../../../shared/services/advisor.service';
import { AppSocketIoService } from '../../../../../../shared/services/socket.io.service';
import { NOTIFY_STYLE_ALERT, NOTIFY_STYLE_ERROR, NOTIFY_STYLE_SUCCESFULL } from '../../../../../../shared/components/notifications/notifications.constants';
import { environment } from "../../../../../../../environments/environment";
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION, getEndPointUrlByContext,urlPrependRootContext } from '../../../../../../shared/helpers/url.helper';
import { IntegrationsImportModalComponent } from 'app/views/enterprise-settings/integrations/templates/interactions/integrations-import-modal/integrations-import-modal.component';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, AfterViewInit {

  public isSalesforceConnected: boolean;
  public socketMessage: string = 'Nothing haha';
  public timeToImportSalesforceContacts: number = 1;

  public contactsIDs: string[] = [];

  public disableImportBtn:boolean = true;
  public loadingSalesforceProfile:boolean = false;

  displayedColumns: string[] = ['select','clientIDFirstName', 'clientIDLastName', 'email'];
  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public currentIntegration = '';

  public clientType = '';
  public searchInput = '';
  public actualPageSize: number = 10;
  public pageIndex: number = 0;
  public totalItems: number = 0;
  public clientTypes = []

  public isLoadingRequest: boolean = false;

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

    let integrations = JSON.parse(localStorage.getItem('external-providers-interactions'));
    this.isSalesforceConnected = integrations.salesforce;

    let pathname = window.location.pathname;

    if(pathname == '/salesforce/success'){
      this.currentIntegration = 'salesforce';
      this.loadSalesforceClients();
    }

    if(pathname == '/riskalyze/success'){
      this.currentIntegration = 'riskalyze';
      this.loadRiskalyzeClients();
    }

    if(pathname == '/wealthbox/success'){
      this.currentIntegration = 'wealthbox'

      this.loadWealthboxClients();
      this.getWealthboxClientTypes();
    }

    if(pathname == '/redtail/success'){
      this.currentIntegration = 'redtail'
      this.loadRedtailClients();
      this.getRedtailClientTypes();
    }

    if(pathname == '/precisefp/success'){
      this.currentIntegration = 'precisefp'

      this.loadPreciseFPClients();
      this.getPreciseFPClientTypes();
    }

  }

  ngAfterViewInit(){
    //Paginator
    this.dataSource.paginator = this.paginator;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRowsMinusExcluded = this.dataSource.data
    .filter(row => !row._fpalpha.isImported)
    .length;
    return numSelected === numRowsMinusExcluded;

  }

  clearClientIDs(){
    this.selection.clear()
    this.dataSource.data.forEach(row => {
        this.contactsIDs.splice(this.contactsIDs.indexOf(row.id),1);
    });
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

    this.isAllSelected()
      ? this.clearClientIDs()
      : this.dataSource.data.forEach(row => {

        if (!row._fpalpha.isImported && row._fpalpha.email !== 'No information' && !this.contactsIDs.includes(row.id)) {
          this.contactsIDs.push(row.id);
          this.selection.select(row)
        }

      });

    if(this.contactsIDs.length === 0){
      this.disableImportBtn = true;
    }else{
      this.disableImportBtn = false;
    }
  }

  addClient(row){

    if(this.selection.selected.includes(row)){
      this.selection.deselect(row);
      this.contactsIDs.splice(this.contactsIDs.indexOf(row.id), 1);
    }else{
      this.selection.select(row);
      this.contactsIDs.push(row.id);
    }
    if(this.contactsIDs.length === 0){
      this.disableImportBtn = true;
    }else{
      this.disableImportBtn = false;
    }
  }

  loadSelectedRows(){
    this.dataSource.data.forEach(row => {
          if(this.contactsIDs.includes(row.id)){
            this.selection.select(row)
          }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  goToClientList(){
    this.router.navigate(['advisor/clients']);
  }

  /*---------*/
  /*WEALTHBOX*/
  /*---------*/

  loadWealthboxClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'per_page': this.actualPageSize,
      'page': this.pageIndex + 1,
      'type': 'person'
    }

    if(this.clientType == 'Client' || this.clientType == 'Prospect'){
      query['contactType'] = this.clientType;
    }

    if(this.searchInput.includes('@')){
      query['email'] = this.searchInput;
    }else{
      query['name'] = this.searchInput;
    }

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiWealthboxGetClients}?${requestQuery}`, options)
      .subscribe(
        success => {
          console.log('Wealthbox contacts', success);
          this.dataSource.data = success.contacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );



  }

  getWealthboxClientTypes(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.http.get<any>(`${environment.apiWealthboxGetClientTypes}`, options)
    .subscribe(
      success => {
        console.log('Wealthbox client types', success);
        this.clientTypes = ['All', ...success];

      },
      error => {
        console.log('Error!!!');
        this.snackBarError.open("Cannot retrieve clients types. " + error.error.message, "Dismiss", {
          duration: 9000,
          panelClass: 'error-snackbar'
        });
      }
    );

  }

  importWealthboxClients(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })


    this.http.post<any>(`${environment.apiWealthboxImportClients}`, contactsToImportList ,options)
      .subscribe(
        success => {

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  /*-------*/
  /*REDTAIL*/
  /*-------*/

  loadRedtailClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'per_page': this.actualPageSize,
      'page': this.pageIndex + 1,
    }

    if(this.clientType !== ''){
      query['type'] = this.clientType;
    }

    if(this.searchInput.length > 0){
      /*if(this.searchInput.includes('@')){
        query['email'] = this.searchInput;
      }else{
        query['name'] = this.searchInput;
      }*/
      query['intellisearch'] = this.searchInput;
    }

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiRedtailGetClientsV2}?${requestQuery}`, options)
      .subscribe(
        success => {
          let cleanContacts = success.contacts.filter(contact =>{
            if(contact.first_name && contact.last_name){
              return true;
            }else{
              return false;
            }
          })
          this.dataSource.data = cleanContacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  getRedtailClientTypes(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.http.get<any>(`${environment.apiRedtailGetClientTypes}`, options)
    .subscribe(
      success => {
        this.clientTypes = [...success];

      },
      error => {
        console.log('Error!!!');
        this.snackBarError.open("Cannot retrieve clients types. " + error.error.message, "Dismiss", {
          duration: 9000,
          panelClass: 'error-snackbar'
        });
      }
    );

  }

  importRedtailClients(){

    this.isLoadingRequest = true;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })

    this.http.post<any>(`${environment.apiRedtailImportClientsV2}`, contactsToImportList ,options)
      .subscribe(
        success => {
          console.log('Redtail contacts', success);

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }

          this.isLoadingRequest = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
          this.isLoadingRequest = false;
        }
      );
  }

  importAllRedtailClients(){

    this.isLoadingRequest = true;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {}

    if(this.clientType !== ''){
      query['type'] = this.clientType;
    }

    if(this.searchInput.length > 0){
      query['intellisearch'] = this.searchInput;
    }

    query['totalItems'] = this.totalItems;

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.post<any>(`${environment.apiRedtailImportAllClientsV2}?${requestQuery}` ,options)
      .subscribe(
        success => {

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }

          this.isLoadingRequest = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });

          this.isLoadingRequest = false;

        }
      );
  }

  /*----------*/
  /*SALESFORCE*/
  /*----------*/

  loadSalesforceClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'size': this.actualPageSize,
      'page': this.pageIndex + 1,
    }

    if(this.searchInput.length > 0){
      if(this.searchInput.includes('@')){
        query['search'] = this.searchInput;
      }else{
        query['search'] = this.searchInput;
      }
    }

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiSalesforceGetClients}?${requestQuery}`, options)
      .subscribe(
        success => {
          this.dataSource.data = success.contacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );



  }

  importSalesforceClients(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })


    this.http.post<any>(`${environment.apiSalesforceImportClients}`, contactsToImportList ,options)
      .subscribe(
        success => {
          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  /*----------*/
  /*Riskalyze*/
  /*----------*/

  loadRiskalyzeClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'size': this.actualPageSize,
      'page': this.pageIndex + 1,
    }

    if(this.searchInput.length > 0){
      if(this.searchInput.includes('@')){
        query['q'] = this.searchInput;
      }else{
        query['q'] = this.searchInput;
      }
    }

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiRiskalyzeGetClients}?${requestQuery}`, options)
      .subscribe(
        success => {
          this.dataSource.data = success.contacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );



  }

  importRiskalyzeClients(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })


    this.http.post<any>(`${environment.apiRiskalyzeImportClients}`, contactsToImportList ,options)
      .subscribe(
        success => {

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  /*----------*/
  /*Precise FP*/
  /*----------*/
  loadPreciseFPClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'per_page': this.actualPageSize,
      'page': this.pageIndex + 1,
    }

    query['type'] = this.clientType;
    query['query'] = this.searchInput;

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiPreciseFPGetClients}?${requestQuery}`, options)
      .subscribe(
        success => {
          this.dataSource.data = success.contacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  getPreciseFPClientTypes(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.http.get<any>(`${environment.apiPreciseFPGetClientTypes}`, options)
    .subscribe(
      success => {
        this.clientTypes = [...success];

      },
      error => {
        console.log('Error!!!');
        this.snackBarError.open("Cannot retrieve clients types. " + error.error.message, "Dismiss", {
          duration: 9000,
          panelClass: 'error-snackbar'
        });
      }
    );

  }

  importPreciseFPClients(){
    this.disableImportBtn = true;
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })

    this.http.post<any>(`${environment.apiPreciseFPImportClients}`, contactsToImportList ,options)
      .subscribe(
        success => {

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }
          this.disableImportBtn = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        this.disableImportBtn = false;

        }
      );
  }


  /*----------*/
  /*   Orion  */
  /*----------*/
  loadOrionClients(){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let query = {
      'per_page': this.actualPageSize,
      'page': this.pageIndex + 1,
    }

    query['type'] = this.clientType;
    query['query'] = this.searchInput;

    const requestQuery = Object.keys(query).map(item => `${item}=${query[item]}`).join('&');

    this.http.get<any>(`${environment.apiOrionGetClients}?${requestQuery}`, options)
      .subscribe(
        success => {
          this.dataSource.data = success.contacts;
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  getOrionClientTypes(){

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    this.http.get<any>(`${environment.apiPreciseFPGetClientTypes}`, options)
    .subscribe(
      success => {
        this.clientTypes = [...success];

      },
      error => {
        console.log('Error!!!');
        this.snackBarError.open("Cannot retrieve clients types. " + error.error.message, "Dismiss", {
          duration: 9000,
          panelClass: 'error-snackbar'
        });
      }
    );

  }

  importOrionClients(){
    this.disableImportBtn = true;
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let contactsToImportList = [];
    this.contactsIDs.map(id =>{
      let user = this.selection.selected.find(user => user.id == id);
      contactsToImportList.push(user);
    })

    this.http.post<any>(`${environment.apiPreciseFPImportClients}`, contactsToImportList ,options)
      .subscribe(
        success => {

          if(success.warnings.length > 0 || success.failed.length > 0 ){
            this.openModal(success);
          }else{
            let snackBarRef = this.snackBarSuccess.open("Contacts imported successfully! You will be redirected to your client list.", "See my contacts", {
              duration: 5000,
              panelClass: 'success-snackbar'
            });

            setTimeout(() => {
              this.goToClientList();
            }, 5000);

            snackBarRef.onAction().subscribe(() => {
              this.goToClientList();
            });

          }
          this.disableImportBtn = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot retrieve contacts. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        this.disableImportBtn = false;

        }
      );
  }


 //Open modal
  openModal(data){
    const dialogRef = this.dialog.open(IntegrationsImportModalComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-with-form',
      height: '470px',
      width: '500px',
      data: data
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data == 'gotit') {
        setTimeout(() => {
          this.goToClientList();
        }, 1000);
      }
    });
  }

  onChange(event){
  }

  applyFilters(){

    this.pageIndex = 0;

    if(this.currentIntegration == 'wealthbox'){
      this.loadWealthboxClients();
    }
    if(this.currentIntegration == 'redtail'){
      this.loadRedtailClients();
    }
    if(this.currentIntegration == 'salesforce'){
      this.loadSalesforceClients();
    }
    if(this.currentIntegration == 'riskalyze'){
      this.loadRiskalyzeClients();
    }
    if(this.currentIntegration == 'precisefp'){
      this.loadPreciseFPClients();
    }
    if(this.currentIntegration == 'orion'){
      this.loadOrionClients();
    }


  }

  cleanFilters(){
    this.clientType = ''
    this.searchInput = ''

    if(this.currentIntegration == 'wealthbox'){
      this.loadWealthboxClients();
    }
    if(this.currentIntegration == 'redtail'){
      this.loadRedtailClients();
    }
    if(this.currentIntegration == 'salesforce'){
      this.loadSalesforceClients();
    }
    if(this.currentIntegration == 'riskalyze'){
      this.loadRiskalyzeClients();
    }
    if(this.currentIntegration == 'precisefp'){
      this.loadPreciseFPClients();
    }
    if(this.currentIntegration == 'orion'){
      this.loadOrionClients();
    }

  }

  getPage(event){
    this.pageIndex = event.pageIndex;
    this.actualPageSize = event.pageSize;

    if(this.currentIntegration == 'wealthbox'){
      this.loadWealthboxClients();
    }
    if(this.currentIntegration == 'redtail'){
      this.loadRedtailClients();
    }
    if(this.currentIntegration == 'salesforce'){
      this.loadSalesforceClients();
    }
    if(this.currentIntegration == 'riskalyze'){
      this.loadRiskalyzeClients();
    }
    if(this.currentIntegration == 'precisefp'){
      this.loadPreciseFPClients();
    }
    if(this.currentIntegration == 'orion'){
      this.loadOrionClients();
    }

  }

  goToIntegrations(){
    this.router.navigate(['/settings/external-providers-interactions']);
  }

}
