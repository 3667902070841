import { getSideByContextInPriority, SIDE_CLIENT } from '../../shared/helpers/url.helper';
import { FileUploaderOptions } from './file-uploader-component/file-uploader-component.component';
import { QuestionnaireController } from './Questionnaire.controller';

export abstract class Validations {
    public static validateNumber(e, max: number = null) {
        if (e.key == '.' || e.key == 'e' || e.key == 'E' || e.key == '+' || e.key == '-' || e.key == ',')
            e.preventDefault();
        if (max != null && Number(e.srcElement.value + e.key) > max)
            e.preventDefault();
    }
}

export interface validatorStructure {
    validationPremise: () => boolean,
    onFalse: Function,
    onValid?: Function,
}

export function uploadersValidator(window:any, uploaders: FileUploaderOptions[]): validatorStructure {
    let isClient:boolean = getSideByContextInPriority() == SIDE_CLIENT;
    return {
        validationPremise: (): boolean => {
            // The validation dosn't apply on client side
            if(isClient) return true;
            return uploaders.map(uploaderOptions => uploaderOptions.isAllValid()).reduce((a, b) => a && b);
        },
        onFalse: () => {
            QuestionnaireController.throwErrorInteraction(window, "You have an upload element that is not valid or is required. Please make sure all your uploaded elements are valid");
        }
    }
}


export function propStringValidator(window:any, props: ({ value: string, name: string })[]): validatorStructure {

    let inavlidNames: string[] = [];

    let validationPremise = (): boolean => {
        let isValid = true;
        props.forEach(param => {
            if (param.value == '' || param.value == 'null' || param.value == 'undefined') {
                isValid = false;
                inavlidNames.push(param.name);
            }
        });
        return isValid
    }

    let onFalse = () => {
        QuestionnaireController.throwErrorInteraction(window, "The next fields are required: " + inavlidNames.reduce((a, b) => {return a + " ," + b} ) );
    }

    return {
        validationPremise: validationPremise,
        onFalse: onFalse
    }

}

// This validator always return valid and don't validate anything
export const fakeValidatorAllTrue: validatorStructure = {
  validationPremise: function (): boolean {
    return true;
  },
  onFalse: ()=>{;}
}

export function validateValidators(validators: validatorStructure[]): boolean {
    let isAllValid = true;

    validators.forEach(validator => {
        let isPremiseValid = validator.validationPremise();
        if (!isPremiseValid) validator.onFalse();
        isAllValid = isAllValid && isPremiseValid;
    });

    return isAllValid;
}

export function validateAndSubmit(validators: validatorStructure[], submitFunction: (() => void), saveForLater: boolean = false) {
    let areValidationsValid = true;

    if (!saveForLater) {
        areValidationsValid = validateValidators(validators);
    }
    if (areValidationsValid) {
        submitFunction();
    }

}
