import { ViewChild } from '@angular/core';
import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'simple-table-component',
  templateUrl: 'simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})

export class SimpleDataComponent implements OnInit, AfterViewInit{

  @Input() data: TableDataInterface | any;
  @Input() pdfColor: any;
  @Input() colorWhite: any;

  @ViewChild('canvassimpletable',{static:false}) canvases: HTMLCanvasElement;
  public fullCanvas;
  public table;
  public fontSizeDivs;
  public value;

  public internalData: TableDataInterface | any;

  public dataTemplate: TableDataInterface = {
    title: "",
    columns: 0,
    componentType: "",
    data: [],
    id: "",
    rows: 0
  }

  public alertExtractions = [
    "Taxes Paid '19",
    "Effective Federal Income Tax Rate",
    "Effective State Income Tax Rate",
    "Taxable Income '19"
  ]

  constructor() {
  }

  ngOnInit() {
    if (this.data === undefined) this.data = this.dataTemplate;
  }


  ngAfterViewInit(){

    this.fullCanvas = <HTMLElement> this.canvases['nativeElement'] as HTMLElement;

    if(this.pdfColor && this.fullCanvas){
      this.table = this.fullCanvas.getElementsByClassName('extractions-table-row') as HTMLCollectionOf<HTMLElement>;
      this.fontSizeDivs = this.fullCanvas.getElementsByClassName('fz-1') as HTMLCollectionOf<HTMLElement>;
      this.value = this.fullCanvas.getElementsByClassName('value') as HTMLCollectionOf<HTMLElement>;

      setTimeout(()=>{
        for(var i = 0; i<this.table.length;i++){
          this.table.item(i).classList.add('extractions-table-row-pdf')
        }

        for(var i = 0; i<this.fontSizeDivs.length; i++){
          this.fontSizeDivs.item(i).style.setProperty("font-size", "1.25rem", "important");
        }

        for(var i = 0; i<this.value.length; i++){
          this.value.item(i).style.setProperty("color", "#000", "important");
        }

      }, 1000)

    }

  }



   /**
   *
   * @param changes
   * On data change, make internall calculations to display columns, lines and colors.
   */
  ngOnChanges(changes: SimpleChanges) {

    if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }

    // Copy the incoming data into a new object to not modify the original data source.
    this.internalData = JSON.parse(JSON.stringify(changes.data.currentValue))
    //this.internalData = JSON.parse(JSON.stringify(changes.data && changes.data.currentValue || {}))

    if(this.pdfColor){
      setTimeout(() => {
        let canvas: NodeListOf<HTMLElement> = document.querySelectorAll('#extractions-full-container');
        for(let i = 2; i < canvas.length; i++){
          let textExtractions: NodeListOf<HTMLElement> = canvas[i].querySelectorAll('.extractions-table-row');
          let fontSizeDivs: NodeListOf<HTMLElement> = canvas[i].querySelectorAll('.fz-1');
          let value: NodeListOf<HTMLElement> = canvas[i].querySelectorAll('.value');

          /*for(let j = 0; j<textExtractions.length;j++){
              textExtractions[j].classList.add('extractions-table-row-pdf');
          }*/

          for(let i = 0; i< fontSizeDivs.length; i++){
            fontSizeDivs.item(i).style.setProperty("font-size", "1.25rem", "important");
          }

          for(let i = 0; i<value.length; i++){
            value.item(i).style.setProperty("color", "#000", "important");
          }
        }
      }, 4000);



      // this.table = this.fullCanvas.getElementsByClassName('extractions-table-row') as HTMLCollectionOf<HTMLElement>;
      // this.fontSizeDivs = this.fullCanvas.getElementsByClassName('fz-1') as HTMLCollectionOf<HTMLElement>;
      // this.value = this.fullCanvas.getElementsByClassName('value') as HTMLCollectionOf<HTMLElement>;

      // setTimeout(()=>{
      //   for(var i = 0; i<this.table.length;i++){
      //     this.table.item(i).classList.add('extractions-table-row-pdf')
      //   }

      //   for(var i = 0; i<this.fontSizeDivs.length; i++){
      //     this.fontSizeDivs.item(i).style.setProperty("font-size", "1.25rem", "important");
      //   }

      //   for(var i = 0; i<this.value.length; i++){
      //     this.value.item(i).style.setProperty("color", "#1B7F22", "important");
      //   }

      // }, 1000)


    }
  }

  generateCanvas(width): HTMLElement {

    //Creo div container
    var div = document.createElement('div');
    div.id = 'container';
    div.className = 'border pad';

    div.style.width = width + 'px';

    //Obtengo elemento de extractions y hago una copia
    const content: Element = document.getElementById('extractions-full-container');
    var content2 = <HTMLElement> content.cloneNode(true);

    //Se lo agrego al div container
    div.appendChild(content2);

    //Cambio font size de extractions
    let textExtractions: NodeListOf<HTMLElement> = content2.querySelectorAll('.extractions-table-row');
    for(var i = 0; i<textExtractions.length;i++){
      textExtractions[i].style.setProperty("font-size", "11px", "important");
      textExtractions[i].style.color = '#555'
    }

    return content2;

  }

}

interface TableDataInterface {
    title?: string,
    columns?: number,
    componentType?: string,
    data?: { subtitle?: string, value?: string }[],
    id: string,
    rows: number
  }
