import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { readFromStoragedObject } from 'app/shared/helpers/utils';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { PreviewReportOutput } from 'app/views/reports/previewReportOutput';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public reportsForm: FormGroup;
  public state: any = '#fff';
  public showPicker: boolean = false;
  public newColor: String = '';
  public outsideEnable: boolean = false;
  public selectedColor: String = '#365ABD';
  public suggestedColors: Array<String> = [];
  public customColors: Array<String> = [];
  public fixedColors: Array<String> = [
    // '#365ABD',
    '#95C0E0',
    '#C8C8C8',
  ];
  public auxiliarTexts: Array<String> = [
    'Classic',
    'Neutral',
  ];
  public imageURL: string = '';
  public imageBG: File | string;
  public isImageUploading: boolean = false;
  public isLoading: boolean = true;
  public companyID: string = '';
  public logo: string = '';
  public disablePreviewButton: boolean = false;
  public backupColors: any = {};

  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  @ViewChild('pdfViewer', { static: false }) private pdfViewer: HTMLObjectElement;
  public pdfReadyToDisplay = false;
  public pdfLoadingPreview = false;
  public pdfDataBlob: Blob;

  public reportPreviewColor: any = '';

  public showPdf: boolean = false;
  color: any = '#247730';

  fullDisclaimer: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private settingsServive: ESettingsService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public eSettingsService: ESettingsService,
    private http: HttpClient,
  ) {
    this.reportsForm = this.formBuilder.group({
      color: ['#365ABD'],
      disclaimers: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.getCompanyData();
    this.fullDisclaimer = new FormGroup({
      fullDisclaimerTitle: new FormControl(),
      fullDisclaimerBody: new FormControl(),
      fullDisclaimerIsActive: new FormControl(),
    })
  }

  changeComplete (e: any) {
    
    const { color } = e;
    this.newColor = color.hex;
  }

  closePicker() {
    this.showPicker = false;
  }

  closeAndAdd() {
    this.showPicker = false;
    this.reportsForm.controls.color.setValue(this.newColor);
    this.reportPreviewColor = this.newColor;
    let counter = 1;
    for(let innerKey in this.backupColors) {
      if (innerKey.includes('customColor')) {
        counter += 1;
      }
    }
    const key = `customColor_${counter}`
    this.settingsServive.updateCompanyData({
      colors: {
        reportCoverColor: this.newColor,
        [key]: this.newColor,
      },
    }).then(r => {
      
      this.getColors();
    });
    this.newColor = '';
  }

  toggleShowPicker() {
    if (this.customColors.length < 5) {
      this.showPicker = !this.showPicker;
      this.outsideEnable = true;
    } else {
      this.snackBarError.open('Remove a custom color to add more', 'Dismiss', {
        duration: 5000,
        panelClass: 'error-snackbar'
      });
    }
  }

  addEmptyDisclaimer() {
    this.disclaimers.push(this.formBuilder.group({
      title: [this.disclaimers.controls.length === 0 ? 'Default disclaimer' : '', Validators.required],
      disclaimer: ['', Validators.required],
    }));
  }

  removeDisclaimer(index) {
    this.disclaimers.removeAt(index);
  }

  get disclaimers() {
    return this.reportsForm.get('disclaimers') as FormArray;
  }

  async updateBackground($event){
    this.imageBG = $event;
    this.disablePreviewButton = true;
    if (this.imageBG !== 'uploaded' && this.imageBG !== '' && this.imageBG !== undefined && this.imageBG !== 'undefined') {
      this.isImageUploading = true;
      const formData = new FormData();
      formData.append('file', this.imageBG);
      this.settingsServive.updateReportBackground(formData).then(data => {
        this.isImageUploading = false;
        this.getCompanyDataJustReport();
        this.disablePreviewButton = false;
      })
    }
  }

  selectColor (color) {
    this.reportsForm.controls.color.setValue(color);
    this.reportPreviewColor = color;
    this.settingsServive.updateCompanyData({
      colors: {
        reportCoverColor: color
      },
    }).then(r => {
      
    });
  }

  getCompanyData(){
    this.settingsServive.getCompanyData().subscribe(data => {
      
      this.imageBG = data.backgroundImage && 'uploaded';
      this.imageURL = data.backgroundImage ? `${data.backgroundImage}`: '';
      this.companyID = data._id;
      this.logo = data.logo;
      this.backupColors = data.colors;
      Object.keys(data.colors).forEach((key) => {
        if (key !== 'reportCoverColor' && !key.includes('custom') && !this.suggestedColors.includes(data.colors[key])) {
          this.suggestedColors.push(data.colors[key]);
        } else if (key !== 'reportCoverColor' && key.includes('custom') && !this.customColors.includes(data.colors[key])) {
          this.customColors.push(data.colors[key]);
        }
      });
      this.reportsForm.controls.color.setValue(data.colors.reportCoverColor)
      this.selectedColor = data.colors.reportCoverColor;
      this.reportPreviewColor = data.colors.reportCoverColor;
      if(data.disclaimers.length > 0){
        data.disclaimers.forEach((element, index) => {
          this.disclaimers.push(this.formBuilder.group({
            title: [element.title, Validators.required],
            disclaimer: [element.disclaimer, Validators.required],
          }));
        })
      }

      this.fullDisclaimer.controls.fullDisclaimerTitle.setValue(data.fullDisclaimer.title);
      this.fullDisclaimer.controls.fullDisclaimerBody.setValue(data.fullDisclaimer.disclaimer);
      this.fullDisclaimer.controls.fullDisclaimerIsActive.setValue(data.settings.isFullDisclaimerActive);

    }, error => {
      console.log('Error', error);
      this.isLoading = false;
    })
  }

  getColors() {
    this.settingsServive.getCompanyData().subscribe(data => {
      this.backupColors = data.colors;
      Object.keys(data.colors).forEach((key) => {
        if (key !== 'reportCoverColor' && !key.includes('custom') && !this.suggestedColors.includes(data.colors[key])) {
          this.suggestedColors.push(data.colors[key]);
        } else if (key !== 'reportCoverColor' && key.includes('custom') && !this.customColors.includes(data.colors[key])) {
          this.customColors.push(data.colors[key]);
        } else if(key === 'reportCoverColor') {
          this.reportsForm.controls.color.setValue(data.colors.reportCoverColor)
          this.selectedColor = data.colors.reportCoverColor;
        }
      });
    }, error => {
      console.log('Error', error);
      this.isLoading = false;
    })
  }

  saveReportData(){
    let optionsPath = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    const body = {
      disclaimers: this.disclaimers.value,
      fullDisclaimer: {
        title: this.fullDisclaimer.value.fullDisclaimerTitle,
        disclaimer: this.fullDisclaimer.value.fullDisclaimerBody
      }
    }
    

    const readedPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    readedPreferences.disclaimer = this.disclaimers.value[0].disclaimer;
    localStorage.setItem("advisorPreferences", JSON.stringify(readedPreferences));

    this.settingsServive.updateReportData(this.companyID, body).then(data => {
      this.snackBarSuccess.open("Report preferences updated successfully", "Ok", {
        duration: 5000,
        panelClass: 'success-snackbar'
      });
    }).catch(error => {
      console.log("Error", error);
      this.snackBarError.open("Cannot update report preferences.", "Dismiss", {
        duration: 5000,
        panelClass: 'error-snackbar'
      });
    });

    let isFullDisclaimerActiveBody = {
      isFullDisclaimerActive: this.fullDisclaimer.value.fullDisclaimerIsActive
    }
    this.http.patch<any>(`${environment.apiCompanySettings}`, isFullDisclaimerActiveBody, optionsPath).toPromise().then()
  }

  async generateReport() {


    this.pdfLoadingPreview = true;
    this.pdfReadyToDisplay = false;

    let template = 'Template name';
    let clientName = 'Client name';
    let advisorFullName: string = readFromStoragedObject('advisorInfo', 'fullName');
    let reportData = {
      logoCompany: this.logo
    };

    let reportImageBackground = this.imageURL;
    
    let reportColor = this.reportPreviewColor;

    this.pdfDataBlob = await PreviewReportOutput.generatePDFReportData(reportData, template, clientName, advisorFullName, reportImageBackground, reportColor, this.settingsServive);
    // If no data form report, display dialog
    
    // Activate the view to be able to edit any inside props!!
    this.pdfReadyToDisplay = true;
    this.pdfLoadingPreview = false;
    //Load the pdf on the previsualization
    let htmlObject: any = document.getElementById('pdfViewer');
    let blobDataAsUrl = URL.createObjectURL(this.pdfDataBlob);
    
    setTimeout(function(){ htmlObject.src = blobDataAsUrl }, 200);
  }

  previewReport(){
    //this.showPdf = true;
    //this.stepper.selectedIndex = 1
    this.generateReport();
  }

  getCompanyDataJustReport(){
    this.settingsServive.getCompanyData().subscribe(data => {
      
      this.imageBG = data.backgroundImage && 'uploaded';
      this.imageURL = data.backgroundImage ? `${data.backgroundImage}`: '';
    }, error => {
      console.log('Error', error);
      this.isLoading = false;
    })
  }

  async downloadReport() {
    let fileName: string = (`Report template`).substring(0, 255).replace(/ /g, '_');
    
    if (!this.pdfDataBlob) await this.generateReport();
    saveAs(this.pdfDataBlob, fileName);
  }

  goBackBtn(){
    //this.stepper.selectedIndex = 0;
    //Clean pdf viewer
    this.showPdf = false;
    let htmlObject: any = document.getElementById('pdfViewer');
    htmlObject.src = ''
  }

  deleteColor(index: number) {
    const color = this.customColors[index];
    
    let key = `customColor_${color}`;
    for (let innerKey in this.backupColors) {
      if(innerKey !== 'reportCoverColor' && innerKey.includes('customColor') && this.backupColors[innerKey] === color) {
        key = innerKey;
        break;
      }
    }
    let reportCoverColor = this.reportsForm.value.color;
    if (this.reportsForm.value.color === color) {
      this.reportsForm.controls.color.setValue(this.fixedColors[0]);
      reportCoverColor = this.fixedColors[0];
    }
    this.settingsServive.updateCompanyData({
      colors: {
        reportCoverColor,
        [key]: null,
      },
    }).then(r => {
      
      this.getColors();
    });
    this.customColors.splice(index, 1);
  }
}
