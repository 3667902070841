import { Component, OnInit, ViewChild } from '@angular/core';
import { TEST_1, TEST_X, TEST_DEREK } from './dummyData';
import { TEST_ESTATE_SNAPSHOT, TEST_ESTATE_SNAPSHOT_FIDUCIARIES, TEST_EXAMPLE_3, TEST_EXAMPLE_MATERIAL_INFORMATION_TRUST, TEST_FAMILY_GRAPH, TEST_FAMILY_GRAPH_2, TEST_UPDATED } from './estateSnapshotExample';
import { updateFamilyGraph } from '../family-box/updateFamilyGraph';
import { GraphToolComponent } from '../../graph-tool/graph-tool.component';
import { graphVerticalCutter } from '../../graph-tool-iterative-cutter/graphVerticalCutter';


@Component({
  selector: 'app-graph-test',
  templateUrl: './graph-test.component.html',
  styleUrls: ['./graph-test.component.css']
})
export class GraphTestComponent implements OnInit {

  @ViewChild("graphInstance") graphInstance: GraphToolComponent;
  public graph: any; //TEST_ESTATE_SNAPSHOT_FIDUCIARIES; //TEST_ESTATE_SNAPSHOT;
  public graph2 = TEST_UPDATED;
  public graphDataX = TEST_X;

  public newMaps = [];

  constructor() { }

  ngOnInit(): void {
    // this.graph = TEST_UPDATED;
    this.graph = updateFamilyGraph(TEST_DEREK)
   /*  this.graph = {
      "title": "Family Map",
      "nodes": [
          {
              "id": "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
              "content": {
                  "name": "Derek  Anthony Terry",
                  "relation": "Client"
              },
              "metaData": {
                  "class": "client graph-node box-node"
              }
          },
          {
              "id": "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
              "content": {
                  "name": "Annalia  Terry",
                  "relation": "Co-Client"
              },
              "metaData": {
                  "class": "client graph-node box-node"
              }
          },
          {
              "id": "47774f70-0a34-44df-80bb-4a7881f59724",
              "content": {
                  "name": "Tristan  Nolan Terry",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child graph-node box-node"
              }
          },
          {
              "id": "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
              "content": {
                  "name": "McKenna  Noelle Terry",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child graph-node box-node"
              }
          },
          {
              "id": "8bdf07b1-6218-4bde-a799-e047bb1871ef",
              "content": {
                  "name": "Nicholas  Paolo Leardini",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child graph-node box-node"
              }
          },
          {
              "id": "DunFeiwdks",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": true
              }
          },
          {
              "id": "x7v1n0XjyE",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": true
              }
          },
          {
              "id": "BSQUZq9vYI",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": true
              }
          },
          {
              "id": "LLNOdjFUH9",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": true
              }
          },
          {
              "id": "Wc4wPWg11H",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": false
              }
          },
          {
              "id": "k0kOd7bOyb",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": false
              }
          },
          {
              "id": "eyfLOZ15m8",
              "content": {},
              "metaData": {
                  "class": "box-node",
                  "pathBlocker": false
              }
          }
      ],
      "connections": [
          {
              "sourceNodeId": [
                  "DunFeiwdks",
                  "x7v1n0XjyE"
              ],
              "nodeConnections": [
                  {
                      "type": "INDIRECT",
                      "pointsToNodeId": "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
                      "anchorOrigin": "R",
                      "anchorDestination": "L"
                  }
              ],
              "isForced": true
          },
          {
              "sourceNodeId": "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
              "nodeConnections": [
                  {
                      "type": "INDIRECT",
                      "pointsToNodeId": "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
                      "anchorOrigin": "R",
                      "anchorDestination": "L"
                  }
              ],
              "isForced": true
          },
          {
              "sourceNodeId": [
                  "DunFeiwdks",
                  "x7v1n0XjyE"
              ],
              "nodeConnections": [
                  {
                      "type": "INDIRECT",
                      "pointsToNodeId": "47774f70-0a34-44df-80bb-4a7881f59724",
                      "anchorOrigin": "L",
                      "anchorDestination": "T"
                  }
              ],
              "isForced": false
          },
          {
              "sourceNodeId": [
                  "DunFeiwdks",
                  "x7v1n0XjyE"
              ],
              "nodeConnections": [
                  {
                      "type": "INDIRECT",
                      "pointsToNodeId": "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
                      "anchorOrigin": "L",
                      "anchorDestination": "T"
                  }
              ],
              "isForced": false
          },
          {
              "nodeConnections": [
                  {
                      "type": "INDIRECT",
                      "pointsToNodeId": "8bdf07b1-6218-4bde-a799-e047bb1871ef",
                      "anchorOrigin": "R",
                      "anchorDestination": "T"
                  }
              ],
              "isForced": false
          }
      ],
      "grid": {
          "rowsNodesById": [
              [
                  "DunFeiwdks",
                  "x7v1n0XjyE",
                  "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
                  "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
                  "BSQUZq9vYI",
                  "LLNOdjFUH9"
              ],
              [
                  "47774f70-0a34-44df-80bb-4a7881f59724",
                  "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
                  "Wc4wPWg11H",
                  "k0kOd7bOyb",
                  "8bdf07b1-6218-4bde-a799-e047bb1871ef",
                  "eyfLOZ15m8"
              ]
          ],
          "rowsMetadata": [
              {
                  "style": {
                      "display": "flex",
                      "align-items": "center"
                  }
              },
              {
                  "style": {
                      "display": "flex",
                      "align-items": "center"
                  }
              }
          ]
      },
      "dataType": "graph",
      "nodesType": "FamilyBox"
    } */
    console.log('this.graph after updated**', this.graph);



    /*
    setTimeout(()=>{
      let splitedGraph = graphVerticalCutter(this.graphInstance, 630);
      this.newMaps = [...splitedGraph];
    }, 3000)
    */

  }

}
