import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
// import { HttpClient } from '@angular/common/http';
import { AdvisorService } from '../../../shared/services/advisor.service';

// Delete Client Dialog
@Component({
  selector: 'delete-area-dialog',
  templateUrl: 'delete-area-dialog.html',
})

export class DeleteAreaDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  constructor(
    // private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<DeleteAreaDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DeleteAreaDialog,
    private authService: AuthenticationService,
    ) {
      super(dialogRef, authService);
     }

  ngOnInit() {}

  banishAreaFromExistence() {
    this.dialogRef.close('deleted');
  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
