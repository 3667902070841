import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CloseDialogAtLogOut } from '../../../shared/helpers/CloseDialogAtLogOut.class';
import { readFromStoragedObject } from '../../../shared/helpers/utils';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { RenameScenarioModalComponent } from './rename-scenario-modal/rename-scenario-modal.component';
import {
  WarningDeleteScenarioModalComponent,
} from './warning-delete-scenario-modal/warning-delete-scenario-modal.component';

@Component({
  selector: 'app-scenarios-list-modal',
  templateUrl: './scenarios-list-modal.component.html',
  styleUrls: ['./scenarios-list-modal.component.scss']
})

export class ScenariosListModalComponent extends CloseDialogAtLogOut implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = ['scenarioName', 'year', 'filingStatus', 'state', 'date', 'description', 'options'];
  clientId = '';

  public dataResponse: any = [];
  public idScenarioDeleted = '';

  constructor(
    public advisorService: AdvisorService,
    public dialogRef: MatDialogRef<ScenariosListModalComponent>,
    public dialog: MatDialog,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);

  }

  ngOnInit() {
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
  }

  ngAfterViewInit() {
    this.getShortInfoScenarios(this.clientId);
  }


  /**
   * The closeModal function closes the current modal dialog.
   */
  closeModal() {
    this.dialogRef.close();
  }

  /**
   * The function retrieves short info about the scenarios for a given client ID and hides the first scenario.
   * @param {string} clientid - The clientid parameter is a string that represents the unique identifier of a client.
   */
  getShortInfoScenarios(clientid: string) {
    this.advisorService.getProjectionsScenarios(clientid, 'short').then(response => {

      // hide scenario 0
      this.dataResponse = response.slice(1);
      this.dataSource = this.dataResponse;
    });
  }

  /**
   * The function opens a dialog box with a form to rename a scenario and waits for the dialog to close.
   * @param {any} scenario - The scenario parameter is of type any, which means it can accept any data type. It is used to pass the scenario object to the dialog component.
   */
  openEditScenarioDialog(scenario: any): void {
    const dialogRef = this.dialog.open(RenameScenarioModalComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-with-form',
      width: '652px',
      minHeight: '553px',
      data:{
        scenario: scenario
      }
    });

    dialogRef.afterClosed().subscribe(data => {

    });
  }

  /**
   * The function `openDeleteScenarioDialog` opens a dialog box to confirm the deletion of a scenario,
   * and if the scenario is successfully deleted, it updates the list of scenarios.
   * @param {string} scenarioId - A string representing the ID of the scenario to be deleted.
   * @param {number} [idx] - The "idx" parameter is an optional parameter of type number. It is used to specify the index of the scenario.
   */
  openDeleteScenarioDialog(scenarioId: string, idx?: number): void {
    const dialogRef = this.dialog.open(WarningDeleteScenarioModalComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-with-form',
      width: '724px',
      minHeight: '530px',
      data: { clientId: this.clientId, scenarioId: scenarioId }
    });

    dialogRef.afterClosed().subscribe(data => {


      if (data === 'scenarioDeleted!') {
        this.idScenarioDeleted = scenarioId;
        this.getShortInfoScenarios(this.clientId);
      }
    });
  }

  /**
   * The closeDialog function closes a dialog and returns the id of the deleted scenario.
   */
  closeDialog() {
    this.dialogRef.close(this.idScenarioDeleted);
  }

}
