import { Questionnaire } from '../Questionnaire.model';

export class elder_questions extends Questionnaire {

    
    public clientIDNursingHome: string = ''; 
    public clientIDMonthlyIncome: number = 0;
    public clientIDPlanNursingHome: string = ''; 
    public clientIDNursingHomeNoSpouse: string = ''; 
    public clientIDHealthySpouse: string = ''; 
    public clientIDSpouseFutureMedicaid: string = ''; 
    public clientIDTotalAssetValue: number = 0; 
    public clientIDHouseholdGoodsValue: number = 0;
    public clientIDTransferAssetsOut: string = ''; 
    public clientIDCreateJointAccount: string = ''; 
    public clientIDReceiveInherit: string = ''; 
    public clientIDProveNecessity: string = ''; 
    public clientIDRepairResidence: string = ''; 
    public clientIDResidenceSibling: string = ''; 
    public clientIDNeedSellResidence: string = ''; 
    public clientIDResidenceChild: string = ''; 
    public clientIDChildCareYou: string = '';
    public clientIDChildLiveWith: string = '';
    public clientIDHomeEquity: number = 0; 
    public clientIDLeaveHome: string = ''; 
    public clientIDRemainHome: string = '';
    public clientIDKeepsake: string = ''; 
    public clientIDMedicEquip: string = ''; 
    public clientIDBurialFund: string = ''; 
    public clientIDBurialExpense: string = ''; 
    public clientIDHasLifeInsurance: string = ''; 
    public clientIDHasRetAcc: string = ''; 
    public clientTypeRetAcc: string = ''; 
    public clientIDReceiveDist: string = ''; 
    public clientIDBuyProperties: string = ''; 
    public clientIDConsiderAnnuity: string = ''; 
    public clientIDLargeGift: string = ''; 
    public clientIDGiftsSpendDown: string = '';
    public clientIDPromissoryNote: string = ''; 
    public clientIDDisabled: string = ''; 
    public clientIDSpecialNeeds: string = ''; 
    public clientIDTrusteeManage: string = ''; 
    public clientIDHasRevocableTrust: string = ''; 
    public clientIDCreateIrrevTrust: string = ''; 
    public clientIDTrustNoDIst: string = ''; 
    public clientIDMuchIncome: string = ''; 
    public clientIDAssetCommunityAllow: string = ''; 
    public clientIDIncomeMaritalProp: string = '';  
    public clientIDOutstandDebt: string = ''; 
    // public clientIDSubstantialEstate: string = '';
    public clientIDHasRealProperty: string = '';
    public clientIDInterestAssetsDeath: string = ''; 
    public clientIDPolicyPlanCashValue: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
      super()
    }

    getQName(): string {
      return 'elder';
    }

    getAttributesToClearCommas():string[] {
       return [
        'clientIDMonthlyIncome',
        'clientIDTotalAssetValue',
        'clientIDHouseholdGoodsValue',
        'clientIDHomeEquity',
        'clientIDPolicyPlanCashValue'
      ];
    }

}


