export const DICTIONARY_COLUMNS_TOOLTIP = {
  "Net Portfolio Balance":
    "This column shows the client’s estimated Total Portfolio Balance on an after-tax basis year by year. This amount is calculated by taking the portfolio’s end of year balance, plus growth less any distributions each year.",
  "Traditional IRA Balance":
    "This column shows the client’s estimated Traditional IRA Balance on a year by year basis. This column will include all of the client’s tax-deferred money.",
  "Roth IRA Balance":
    "This column shows the client’s estimated Roth IRA Balance. As funds are converted to the Roth IRA, this balance will increase.  Any distributions (principal and interest) from the Roth IRA are received by the client on a tax-free basis.",
  "Hypothetical Taxable Account":
    "This column shows the client’s estimated Taxable Account Balance on a year by year basis (a hypothetical account we create within the simulator that starts at $0.). Keep in mind the taxable account will capture all RMD distributions (and future growth of those RMDs) from the Traditional IRA.",
  Withdrawals:
    "This column shows the total net amount withdrawn each year (after taxes) and accumulating (and growing by investment rate of return) over time.  If you would like to adjust the annual gross supplemental withdrawal amount, click Go Back until you arrive at the initial input screen.",
  "Taxes Paid":
    "This column shows the client’s estimated Taxes Paid each year. Keep in mind this includes all taxes paid on liquidations such as the conversion, RMD’s and additional IRA distributions.",
  "Conversion Amount":
    "This column shows the amount of Roth Conversion that is completed and the year in which the conversion(s) take place.",
  "Income Amount":
    "This column shows the amount of income received by the client on a year by year basis (uses income inputs on Page 1). This figure also includes all Roth conversions, RMDs, and supplemental withdrawals.",
  Medicare:
    "This column shows the additional Medicare Part B and D Premium for the client. This is a relevant consideration as the additional premium can be triggered once the client’s income hits certain thresholds.",
  "Value to Heir":
    "This column shows the total amount of all accounts that will be passed on to the client’s Heirs at the end of the simulation. Keep in mind that if you need to adjust the Heir’s Tax Rate, you can click Go Back until you arrive at the initial input screen.",
  "Marginal Tax Rate":
    "This column shows the client’s marginal tax rate percentage based on income, Roth conversions and supplemental withdrawals",
};

export const COLUMNS_KEYS = {
  "Net Portfolio Balance": "totalAfterTaxPortfolioBalance",
  "Traditional IRA Balance": "traditionalIRABalance",
  "Roth IRA Balance": "rothIRABalance",
  "Hypothetical Taxable Account": "taxableAcctEOY",
  Withdrawals: "withdrawalAccountEOY",
  "Taxes Paid": "taxesPaid",
  "Conversion Amount": "conversionAmount",
  "Income Amount":
    "taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal",
  Medicare: "medicarePartBPremium",
  "Value to Heir": "valueToHeir",
  "Marginal Tax Rate": "",
  "Required Minimum Distribution (RMD)": "RMDAfterWithdrawal",
};
