import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_NOTSURE, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { divorce_questions } from './divorce.model';

@Component({
  selector: 'app-q-divorce',
  templateUrl: './q-divorce.component.html',
  styleUrls: ['./q-divorce.component.scss']
})
export class QDivorceComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('divorceform', { static: true }) divorceform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: divorce_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientAge: number;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];
  public isAdvisor: boolean = false;

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_NOTSURE, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;
  public clientIsRetired: boolean = false;

  public bulletColor;

  divorceSituations = [
    'Keep your marital and your separate property disjointed.',
    'Protect your inherited or family property separate from your new spouse.',
    'Protect business interests acquired BEFORE the marriage.',
    'Protect savings acquired before marriage.',
    'Protect property purchased before marriage.',
    'Remain responsible or not responsible for any premarital debt.',
    'Protect retirement assets accumulated before marriage.',
    'Protect all of your assets in the event of a divorce.',
    'Distribute assets to people other than your future spouse at death (must be consistent with your will).',
    'Divide up marital bills and household expenses during the marriage.',
    'Monitor and manage the acquisition of debt during the mariage.',
    'Identify the responsibility of post-marital education debt and other financial decisions.',
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientAge = this.clientData.age;
    if (this.clientData.isRetired === 'Yes') this.clientIsRetired = true;

    

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new divorce_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansDivorce;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, the load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'divorce', this.model).then(response => {
        
        // Wait for the data before starting the autosave function
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {

    if(this.model.clientIDMonitorAccounts === "undefined") this.model.clientIDMonitorAccounts = 'Yes';
    if(this.model.clientIDClaimTaxBenefit === "undefined") this.model.clientIDClaimTaxBenefit = 'Yes';
    if(this.model.clientIDDivorceDecree === "undefined") this.model.clientIDDivorceDecree = 'Yes';
    if(this.model.clientIDTrackSupport === "undefined") this.model.clientIDTrackSupport = 'Yes';
    if(this.model.clientIDUpdatePolicies === "undefined") this.model.clientIDUpdatePolicies = 'Yes';
    if(this.model.clientIDTaxEffectMarriage === "undefined") this.model.clientIDTaxEffectMarriage = 'Yes';
    if(this.model.clientIDEstateDocumentsInPlace === "undefined") this.model.clientIDEstateDocumentsInPlace = 'Yes';
    if(this.model.clientIDRefinanceMortgage === "undefined") this.model.clientIDRefinanceMortgage = 'Yes';
    if(this.model.clientIDEduNotUsed === "undefined") this.model.clientIDEduNotUsed = 'Yes';
    if(this.model.clientIDLifeInsurancePayor === "undefined") this.model.clientIDLifeInsurancePayor = 'Yes';
    if(this.model.clientIDLIValue === "undefined") this.model.clientIDLIValue = 'Yes';
    if(this.model.clientIDLIRidPolicy === "undefined") this.model.clientIDLIRidPolicy = 'Yes';
    if(this.model.clientIDDetailsAgreement === "undefined") this.model.clientIDDetailsAgreement = 'Yes';
    if(this.model.clientIDAssetBalance === "undefined") this.model.clientIDAssetBalance = 'Yes';
    if(this.model.clientIDFinPlanChanged === "undefined") this.model.clientIDFinPlanChanged = 'Yes';
    if(this.model.clientIDReviewPlan === "undefined") this.model.clientIDReviewPlan = 'Yes';
    if(this.model.clientIDMixAssets === "undefined") this.model.clientIDMixAssets = 'Yes';
    if(this.model.clientIDGoals === "undefined") this.model.clientIDGoals = 'Yes';
    if(this.model.clientIDTaxEffectDivorce === "undefined") this.model.clientIDTaxEffectDivorce = 'Yes';
    if(this.model.clientIDRemarry === "undefined") this.model.clientIDRemarry = 'Yes';
    if(this.model.clientIDHighBusiness === "undefined") this.model.clientIDHighBusiness = 'Yes';
    if(this.model.clientIDChangeBeneficiary === "undefined") this.model.clientIDChangeBeneficiary = 'Yes';
    if(this.model.clientIDDuplicateStatements === "undefined") this.model.clientIDDuplicateStatements = 'Yes';
    if(this.model.clientIDFundsUnused === "undefined") this.model.clientIDFundsUnused = 'Yes';
    if(this.model.clientIDReviewdBeneficiaries === "undefined") this.model.clientIDReviewdBeneficiaries = 'Yes';



    this.qController.submitForm(this, this.model, this.clientData.clientId, 'divorce', saveForLater, null, silentSave);
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    let divorcePDFtype = this.model.clientIDCurrentState.substr(0, this.model.clientIDCurrentState.indexOf(' '));

    this.http.get(`${this.pdfUrl}${'divorce'}/?options=${divorcePDFtype}`, options)
      .subscribe(blob => {
        saveAs(blob, 'divorce-questionnaire.pdf');
    });
  }

}
