import { Questionnaire } from '../Questionnaire.model';

export class expense_questions extends Questionnaire {

    public clientIDFrequentlyWatchesTV: string = "";
    public clientIDFrequentlyUsesLandlineTelephone: string = "";
    public clientIDIsRegularClientForTVServiceCompany: string = "";
    public clientIDWillRenewContract: string = "";
    public clientIDHasCar: string = "";
    public clientIDSellingCar: string = "";
    public clientIDHasYoungChildren: string = "";
    public clientIDIsInterestedInAttendingFamilyActivities: string = "";
    public clientIDHasClothesHeWantsToSell: string = "";
    public clientIDHasCreditCardDebt: string = "";
    public clientIDLikesToPlayVideogames: string = "";
    public clientIDVisitsThemeParks: string = "";
    public clientIDHasFSAAccount: string = "";
    public clientIDHasHealthInsurance: string = "";
    public clientIDIsFlexibleWhenUsingMostEnergyInADay: string = "";
    public clientIDOftenFindsPriceSameBetweenOnlineShopsAndLocalShops: string = "";
    public clientIDISRegularClientOfCellPhoneCompany: string = "";
    public clientIDBuysOrSellsRealEstate: string = "";
    public clientIDBuysOrSellsCar: string = "";

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
        super()
    }

    getQName(): string {
        return 'expense';
    }
}