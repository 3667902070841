import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AddPropertyDialogComponent } from '../../hub-multi-area/add-property-dialog/add-property-dialog.component';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { InsuranceServiceService } from '../insurance-service.service';
import { InsuranceListComponent } from '../insurance-list/insurance-list.component';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { HIDE_TABS_FROM_SECTIONS } from '../insurance-constants';

@Component({
  selector: 'app-insurance-hub',
  templateUrl: './insurance-hub.component.html',
  styleUrls: ['./insurance-hub.component.scss']
})
export class InsuranceHubComponent implements OnInit, OnDestroy {
  public hideButton: boolean = false;
  public hideExtraQuestionnarieTabs: boolean = false;
  public data: any = {};
  public type: any = 'home';
  public clientData: any;
  public clientHasAnswered: any;
  public clientID: any;
  public model: any = {
    haveHomeInsurance: '',
    insuranceCompany: '',
    enterManual: '',
  };
  public routerSub: any;
  public titles: any = {
    home: 'Home Insurance',
    auto: 'Auto Insurance',
    life: 'Life Insurance',
    student: 'Student Loan',
    disability: 'Disability',
  }
  public titlesClasses: any = {
    home: 'text-home-insurance',
    auto: 'text-auto-insurance',
    life: 'text-life-insurance',
    student: 'text-student-insurance',
    disability: 'text-disability-insurance'

  }
  public hideContinue: any = true;
  public hideSkip: any = false;
  public continueDirection: any = 'request-snapshot';

  constructor(
    private activatedRoute: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private advisorService: AdvisorService,
    private insuranceService: InsuranceServiceService,
    private authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    console.log(this.clientData);
    this.clientID = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');
    this.insuranceService.setClientID(this.clientID);
    if (this.route.snapshot?.data && this.route.snapshot?.data?.type) {
      this.type = this.route.snapshot.data.type;
      this.insuranceService.setAreaModel(this.type);
    } else {
      this.type = 'home';
      this.insuranceService.setAreaModel('home');
    }
    if(HIDE_TABS_FROM_SECTIONS.includes(this.type)) {
      this.hideExtraQuestionnarieTabs = true 
    }
    this.checkActivatedRoute();
    this.activatedRoute.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkActivatedRoute();
      }
    });
  }

  ngOnDestroy(): void {
      // this.insuranceService.resetInformation();
  }


  private checkActivatedRoute() {
    const ACTIVATED_ROUTE = this.activatedRoute.url;
    if (
      ACTIVATED_ROUTE.includes('form') ||
      ACTIVATED_ROUTE.includes('request-snapshot') ||
      ACTIVATED_ROUTE.includes('questionnaire')
    ) {
      this.hideButton = true;
    } else {
      this.hideButton = false;
    }
    if (ACTIVATED_ROUTE.includes('request-snapshot')) {
      this.continueDirection = 'questionnaire';
    } else {
      this.continueDirection = 'request-snapshot';
    }
    if (ACTIVATED_ROUTE.includes('questionnaire')) {
      this.hideContinue = true;
      this.hideSkip = true;
    } else if (ACTIVATED_ROUTE.includes('request-snapshot')) {
      this.hideSkip = true;
      this.hideContinue = true;
    } else {
      this.hideContinue = false;
      this.hideSkip = false;
    }
    if (      !ACTIVATED_ROUTE.includes('form') &&
    !ACTIVATED_ROUTE.includes('request-snapshot') &&
    !ACTIVATED_ROUTE.includes('questionnaire')) {
      this.insuranceService.resetInformation();
    }
  }

  public handleHideTabsByIndex(componentRef: HTMLElement) {
    if (!(componentRef instanceof InsuranceListComponent)) return;
    componentRef.selectedCardIndex.subscribe((index: number) => {
      console.log(index);
      if (index != 1) {
        this.hideExtraQuestionnarieTabs = true;
      } else {
        this.hideExtraQuestionnarieTabs = false;
      }
    });
  }

  public foundArea: any = {
    id: 'home',
    name: 'Home Insurance',
    isActive: true,
    colorHex: '#F2B620',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/03_areas_home.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg',
    mainObjectDescription: 'property'
  };
  async addItemToList() {
    const dialogRef = this.dialog.open(AddPropertyDialogComponent, {
      width: '250px',
      data: {
        mainObjectDescription: this.foundArea.mainObjectDescription,
        title: '',
        area: this.type,
      }
    })

    dialogRef.afterClosed().subscribe(async result => {
      const ans = await this.advisorService.addHubSubQuestionnares(this.insuranceService.clientId, this.type, result);
      await this.insuranceService.saveAnsweredFormQuestions(this, true, ans[ans?.length - 1].index);
      await this.insuranceService.getDataFromService(this.type);
    });
  }

  verifyIfFilesExist() {
    let validFiles = true;
    if (Object.keys(this.insuranceService.filesToUpload || {}).length === 0) {
      return true;
    }
    if (this.type === 'home') {
      Object.keys(this.insuranceService.filesToUpload).forEach((key) => {
        if (this.insuranceService.filesToUpload[key].clientIDClientUmbrellaInsuranceCompany) {
          validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDHomeInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDHomePolicy && this.insuranceService.filesToUpload[key].clientIDHomePolicy?.uploader?.queue?.length > 0 && !!this.insuranceService.filesToUpload[key].clientIDClientUmbrellaInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDClientHasUmbrellaDocument && this.insuranceService.filesToUpload[key].clientIDClientHasUmbrellaDocument?.uploader?.queue?.length > 0
        } else {
          validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDHomeInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDHomePolicy && this.insuranceService.filesToUpload[key].clientIDHomePolicy?.uploader?.queue?.length > 0
        }

      });
    } else {
      Object.keys(this.insuranceService.filesToUpload).forEach((key) => {
        validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDAutoInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDAutoPolicy && this.insuranceService.filesToUpload[key].clientIDAutoPolicy?.uploader?.queue?.length > 0
      });
    }

    return !validFiles
  }

}
