import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CloseDialogAtLogOut } from "app/shared/helpers/CloseDialogAtLogOut.class";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";

export interface FirstTimeInterface {
    hasUploader: boolean;
}

@Component({
    selector: 'dialog-firstTime',
    templateUrl: 'dialog-firstTime.html',
})

export class DialogFirstTime extends CloseDialogAtLogOut {

    constructor(
        public dialogRef: MatDialogRef<DialogFirstTime>,
        @Inject(MAT_DIALOG_DATA) public data:FirstTimeInterface,
        private authService: AuthenticationService
        ) {
          super(dialogRef, authService);
         }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
