import { Component, Input, OnInit } from '@angular/core';
import { ESettingsService } from 'app/shared/services/e-settings.service';

@Component({
  selector: 'format-page-pdf',
  templateUrl: './format-page-pdf.component.html',
  styleUrls: ['./format-page-pdf.component.scss']
})
export class FormatPagePdfComponent implements OnInit {

  @Input() type: string = '';
  @Input() listDocsPerPage: any[] = [];
  @Input() indexPage: number = 0;
  @Input() totalPage: number = 0;
  @Input() fiduciariesSection: string = '';
  @Input() clientName: string = '';
  @Input() advisorComments: any;
  @Input() disclaimer: any;
  @Input() grapMixData: any;
  @Input() imageURL: any;
  public currentDateFormated: string;

  constructor(private settingsService: ESettingsService) { }

  ngOnInit(): void {
    this.currentDateFormated = new Date().toLocaleDateString();
    console.log('fiduciariesSection*', this.fiduciariesSection);
    /*if(this.advisorComments){
      let lastChange = this.advisorComments.historyChange.slice(-1)[0].timeStamp;
      if(lastChange){
        this.advisorComments['lastChange'] = lastChange;
      }
      console.log('format page advisorComments*', lastChange, this.advisorComments);
    }
    if(this.disclaimer){
      console.log('disclaimer*', this.disclaimer, this.type);
    }*/

    /* if(this.listDocsPerPage){
      console.log('this.listDocsPerPage format-page*', this.listDocsPerPage);
    } */
  }
}
