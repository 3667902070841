export type FoundAreasObject = {
  home: FoundArea;
  auto:FoundArea;
  life: FoundArea;
  student: FoundArea;
  disability: FoundArea
}

type FoundArea = {
  id: string;
  name: string;
  isActive: boolean;
  colorHex: string;
  isMultiple: boolean;
  iconUrl: string;
  iconColorUrl: string;
  mainObjectDescription: string;
}

export const homeInsuranceCompanies = [
    "N/A",
    "AIG",
    "Allstate",
    "Amica",
    "Argo Group",
    "Chubb",
    "Citizens",
    "Erie",
    "Fireman’s Fund",
    "Geico",
    "Kemper Preferred",
    "Liberty Mutual",
    "Metlife",
    "Narragansett Bay",
    "One Choice",
    "Progressive",
    "Pure",
    "State Farm",
    "Travelers",
    "Universal Property P&C",
    "USAA",
    "Wells Fargo",
    "Other"
];

export const agreements: any = {
    home: {
        showPrice: true,
        typeOfAgreement: 'home',
        title: "Home Snapshot Request",
        priceline: '$29/household case, billed at the end of each month.',
        titleClass: 'text-home-insurance title fp-font-bold',
        disclosure: 'As user of FP Alpha, I acknowledge and agree that the use of the Insurance Snapshot tool is subject to and forms a part of the agreement between FP Alpha and my employer and/or the entity that provides me with access to the FP Alpha platform, and I represent that I have the authority to bind such employer and/or entity to such agreement with respect to my use of the Insurance Snapshot tool.',
        list: [
          {
            type: 'string',
            label: 'All documents for all assets should be uploaded and are considered as only one household case (You can include multiple policies within one case)',
          },
          {
            type: 'string',
            label: 'Please upload the full policy document as a native pdf (often the client can download the policy via the carrier mobile application or website).',
          }
        ]
    },
    auto: {
      showPrice: true,
      typeOfAgreement: 'auto',
      title: "Auto Snapshot Request",
      priceline: '$29/household case, billed at the end of each month.',
      titleClass: 'text-auto-insurance title fp-font-bold',
      disclosure: 'As user of FP Alpha, I acknowledge and agree that the use of the Insurance Snapshot tool is subject to and forms a part of the agreement between FP Alpha and my employer and/or the entity that provides me with access to the FP Alpha platform, and I represent that I have the authority to bind such employer and/or entity to such agreement with respect to my use of the Insurance Snapshot tool.',
      list: [
        {
          type: 'string',
          label: 'All documents for all assets should be uploaded and are considered as only one household case (You can include multiple policies within one case)',
        },
        {
          type: 'string',
          label: 'Please upload the full policy document as a native pdf (often the client can download the policy via the carrier mobile application or website).',
        }
      ]
    }
}
export const FOUND_AREAS: FoundAreasObject = {
  home: {
    id: 'home',
    name: 'Home Insurance',
    isActive: true,
    colorHex: '#F2B620',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/03_areas_home.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg',
    mainObjectDescription: 'property'
  },
  auto:     {
    id: 'auto',
    name: 'Auto Insurance',
    isActive: true,
    colorHex: '#CC521E',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg',
    mainObjectDescription: 'car'
  },
  life: {
    id: 'life',
    name: 'Life Insurance',
    isActive: true,
    colorHex: '#CC521E',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg',
    mainObjectDescription: 'policy'
  },
  student: {
    id: 'student',
    name: 'Student Debt',
    isActive: true,
    colorHex: '#63a38a',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg',
    mainObjectDescription: 'policy'
  },
  disability: {
    id: 'disability',
    name: 'Disability',
    isActive: true,
    colorHex: '#cb8693',
    isMultiple: true,
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg',
    iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg',
    mainObjectDescription: 'policy'
  },
}

export const HIDE_TABS_FROM_SECTIONS: string[] = ['life', 'student', 'disability'];
export const NEW_QUEST_AREAS: string[] = ['disability', 'life', 'home', 'auto', 'student'];
export const NEW_QUEST_AREAS_MAIN_ROUTE: any = {
  home: '/insurance-hub/form',
  auto: '/auto-insurance-hub/form',
  life: '/life-insurance-hub/questionnaire',
  student: '/student-insurance-hub/questionnaire',
  disability: '/disability-insurance-hub/questionnaire',
}