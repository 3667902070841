import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { FileUploaderOptions } from '../file-uploader-component/file-uploader-component.component';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import { getYearsArraySince } from '../HelperFunctions';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_LI_COMPANIES } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { life_questions } from './life.model';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-q-life',
  templateUrl: './q-life.component.html',
  styleUrls: ['./q-life.component.scss']
})
export class QLifeComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: false }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: life_questions
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;
  public clientAge: number;

  public haveFilePDF: string;
  public uploader: FileUploaderExtended;
  public hasBaseDropZoneOver: boolean = false;
  public whoAnswered: string;

  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_LI_COMPANIES };
  public validations = Validations;
  public fileUploaderArray: FileUploadersArrayHelper;
  public policyUploaderOptions: FileUploaderOptions;
  public extractionResults: any[];
  public yearsList:  number[] = getYearsArraySince(1950).reverse();

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public clientHasMinorChildren: boolean = false;
  public toggleSearchBar: boolean = false;
  public hasInsuranceBetaAccess: boolean = true;
  public insuranceProviders = [];

  public bulletColor;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public isAdvisor: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthenticationService,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,

    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    this.tabRef = null

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientAge = this.clientData.age;

    
    if(this.clientHasChildren == 'Yes'){
      if(this.clientData.childOneAge < 18 || this.clientData.childTwoAge <18 || this.clientData.childThreeAge<18){
        this.clientHasMinorChildren = true;
      }else{
        this.clientHasMinorChildren = false;
      }
    }else{
      this.clientHasMinorChildren = false;
    }

    //Initialize the questionnaire model
    this.model = new life_questions();

    this.advisorService.getInsuranceProviders().then((res: any) => {
      this.insuranceProviders = res.map(({ showName }) => showName);
    });
    this.model.afterMappingLoad = () => {
     this.model.clientIDPolicyYear = Number(this.model.clientIDPolicyYear);
    }
    const lifeIndexQuest = sessionStorage.getItem('lifeQuestIndex') || '1';
    console.log('LIFE QUEST: ', lifeIndexQuest);
    //Load extraction results for file uploaders components
    this.qController.loadExtractionsResults(this.clientData.clientId, 'life', lifeIndexQuest ).then(result => {
      this.extractionResults = result;
    });

    this.policyUploaderOptions = new FileUploaderOptions(
      /* 'clientIDLIPolicy',
      this.model.clientIDLIPolicy,
      this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'life'),
      'policy' */
      {
        fileID: 'clientIDLIPolicy',
        fileVal: this.model.clientIDLIPolicy,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'life'),
        documentReadableName: 'policy',
        actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'life', Number(lifeIndexQuest), 'clientIDLIPolicy', ()=>{this.model.clientIDWantsToInputPolicyDataLI = 'Yes'} )} }
      }
    );

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputPolicyDataLI = 'No';

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansLife || JSON.parse(sessionStorage.getItem('lifeHasBeenAnswered'));

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'life', this.model, Number(lifeIndexQuest)).then(response => {
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {

    if(typeof this.tabRef !== "undefined" || this.tabRef === null){
      this.ntabs = parseInt(this.tabRef._tabs.length);
      this.countTabs(this.ntabs);
    }


  }

  onSaveForLater() {
    this.submit(true);
  }

  showUploader(prop: string) {
    this.model[prop] = '';
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {

    if (this.model.clientIDLIPolicy == '') this.model.clientIDLIPolicy = 'No';



      validateAndSubmit(
        [
          uploadersValidator(this, [this.policyUploaderOptions])
        ],
        () => this.qController.submitForm(this, this.model, this.clientData.clientId, 'life', saveForLater, null, silentSave),
        saveForLater
      )

  }

  onAnyUploaderReset($event:string) {
    if($event == 'clientIDLIPolicy') this.model.clientIDLIInsuranceCompany = '';

    this.model[$event] = '';
    this.extractionResults[$event] = '';
  }

  ngAfterViewInit() {
  }


  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  isInsurancePolicyCompatible(insuranceCompany: string): boolean {
    return [''].includes(insuranceCompany)
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'life'}/?options=${this.model.clientIDClientInsured}`, options)
      .subscribe(blob => {
        saveAs(blob, 'life-insurance-questionnaire.pdf');
      });
  }

}
