import { Injectable } from "@angular/core";
import { Area } from "../helpers/areas.catalog";

interface IMenuItem {
    type: string,       // Possible values: link/dropDown/icon/separator/extLink
    name?: string,      // Used as display text for item and title for separator type
    state?: string,     // Router state
    icon?: string,      // Material icon name
    tooltip?: string,   // Tooltip text 
    disabled?: boolean // If true, item will not appear in sidenav.
  }

@Injectable()
export class EducationService {
  public ltcArea:  Area;
  public disabilityArea:  Area;
  public propertyArea:  Area;
  constructor() {
  }

  private educationAssets: any[] = [
  {
    "title": "Estate Snapshot",
    "hexColor": Area.findAreaById('estate').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "",
        "videoUrl": "714521630",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section2": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section3": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section4": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      }
  },
  {
    "title": "Disability Insurance",
    "hexColor": Area.findAreaById('disability').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Laura Parker, Disability Insurance expert.",
        "videoUrl": "389501478",
        "section": [{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Expert introduction",
          "description": "The basics explained by Laura Parker, Disability Insurance expert.",
          "videoUrl": "389501478"
        },{
          "title": "Introduce the financial planning topic: Disability Insurance",
          "shortTitle": "Introduce the topic",
          "description": "The basics explained by Laura Parker, Disability Insurance expert.",
          "videoUrl": "389502364"
        },{
          "title": "Provide examples of key challenges/opportunities/areas to highlight when working on this financial planning topic",
          "shortTitle": "Examples",
          "description": "The basics explained by Laura Parker, Disability Insurance expert.",
          "videoUrl": "389503566"
        }]
      },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Intro to high-level sections",
          "shortTitle": "Intro",
          "description": "",
          "videoUrl": "388347465"
        },
        {
          "title": "Key challenge/opportunity 1 – Why you need it",
          "shortTitle": "Why you need it",
          "description": "",
          "videoUrl": "388347618"
        },
        {
          "title": "What you need",
          "shortTitle": "What you need",
          "description": "",
          "videoUrl": "388348116"
        },
        {
          "title": "How to get disability insurance",
          "shortTitle": "Get disability insurance",
          "description": "",
          "videoUrl": "388343536"
        },
        {
          "title": "How to maintain/monitor what you have for coverage",
          "shortTitle": "Maintain what you have",
          "description": "",
          "videoUrl": "388347249"
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Challenge 1: Not having enough insurance",
          "shortTitle": "Not having enough insurance",
          "description": "",
          "videoUrl": "388341556"
        },
        {
          "title": "Challenge 2: Not having specialty insurance",
          "shortTitle": "Not having specialty insurance",
          "description": "",
          "videoUrl": "388341734"
        },
        {
          "title": "Challenge 3: Working in another occ but not being able to receive your prior income from your prior occ/specialty",
          "shortTitle": "Working in another occ",
          "description": "",
          "videoUrl": "388341938"
        },
        {
          "title": "Challenge 4: Client is in poor health and has inadequate coverage",
          "shortTitle": "Client is in poor health",
          "description": "",
          "videoUrl": "388342185"
        },
        {
          "title": "Challenge 5:  Client does not have adequate business insurance",
          "shortTitle": "Don't have business insurance",
          "description": "",
          "videoUrl": "388342473"
        },
        {
          "title": "Challenge 6:  Client has in force coverage but not appropriate for circumstances",
          "shortTitle": "Client has in force coverage",
          "description": "",
          "videoUrl": "388342968"
        },
        {
          "title": "COLA",
          "shortTitle": "COLA",
          "description": "",
          "videoUrl": "388343332"
        },
        {
          "title": "Retirement",
          "shortTitle": "Retirement",
          "description": "",
          "videoUrl": "389829144"
        },
        {
          "title": "Business Considerations",
          "shortTitle": "Business Considerations",
          "description": "",
          "videoUrl": "389828595"
        },
        {
          "title": "Taxes",
          "shortTitle": "Taxes",
          "description": "",
          "videoUrl": "389835608"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Case studies 1A",
          "shortTitle": "Case studies 1A",
          "description": "",
          "videoUrl": "388340592"
        },{
          "title": "Case studies 2A",
          "shortTitle": "Case studies 2A",
          "description": "",
          "videoUrl": "388341092"
        }]
      }
  },
  {
    "title": "Long Term Care",
    "hexColor": Area.findAreaById('ltc').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Robert M. Barnes, Long Term Care expert.",
        "videoUrl": "389514315"
      },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Coverage",
          "shortTitle": "Coverage",
          "description": "",
          "videoUrl": "388333364"
        },
        {
          "title": "Financial limitations and best way to pay",
          "shortTitle": "Financial limitations",
          "description": "",
          "videoUrl": "388333583"
        },
        {
          "title": "Inflation riders",
          "shortTitle": "Inflation riders",
          "description": "",
          "videoUrl": "388334125"
        },
        {
          "title": "Unique design considerations",
          "shortTitle": "Unique design considerations",
          "description": "",
          "videoUrl": "388334627"
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Shared care rider",
          "shortTitle": "Shared care rider",
          "description": "",
          "videoUrl": "388334252"
        },
        {
          "title": "How elimination period affects premium cost",
          "shortTitle": "How elimination period affects premium cost",
          "description": "",
          "videoUrl": "388333970"
        },
        {
          "title": "Calculating coverage levels - Rule of thumb: cover 80% of daily nursing home care cost in your area for 4 years of coverage",
          "shortTitle": "Calculating coverage levels",
          "description": "",
          "videoUrl": "388332809"
        },
        {
          "title": "Tax deduction options",
          "shortTitle": "Tax deduction options",
          "description": "",
          "videoUrl": "388334350"
        },
        {
          "title": "Advising Existing Clients - Intro",
          "shortTitle": "Advising Existing Clients 1",
          "description": "",
          "videoUrl": "388331025"
        },
        {
          "title": "Advising Existing Clients - Is the current coverage amount appropriate?",
          "shortTitle": "Advising Existing Clients 2",
          "description": "",
          "videoUrl": "388331775"
        },
        {
          "title": "Advising Existing Clients - What is the current financial and future outlook of the carrier?",
          "shortTitle": "Advising Existing Clients 3",
          "description": "",
          "videoUrl": "388332496"
        },
        {
          "title": "Advising Existing Clients - Is there a better option available in the marketplace?",
          "shortTitle": "Advising Existing Clients 4",
          "description": "",
          "videoUrl": "388332422"
        },
        {
          "title": "Advising Existing Clients - How is your clients’ current health?",
          "shortTitle": "Advising Existing Clients 5",
          "description": "",
          "videoUrl": "388330956"
        },
        {
          "title": "Advising Existing Clients – Carrier concerns",
          "shortTitle": "Advising Existing Clients 6",
          "description": "",
          "videoUrl": "388332562"
        },
        {
          "title": "Advising Existing Clients – Future is less certain",
          "shortTitle": "Advising Existing Clients 7",
          "description": "",
          "videoUrl": "388332700"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Clients with fewer assets",
          "shortTitle": "Clients with fewer assets",
          "description": "",
          "videoUrl": "388333212"
        },
        {
          "title": "High net worth clients",
          "shortTitle": "High net worth clients",
          "description": "",
          "videoUrl": "388333787"
        },
        {
          "title": "Clients in between LNW-HNW",
          "shortTitle": "Clients in between LNW-HNW",
          "description": "",
          "videoUrl": "388332988"
        }]
      }
  },
  {
    "title": "Property Tax",
    "hexColor": Area.findAreaById('property').colorHex,
    "section1": {
      "title": "Introduction",
      "description": "The basics explained by Franck L. Buettner, Property Tax expert.",
      "videoUrl": "389516917",
      "section": [{
        "title": "Speaker background",
        "shortTitle": "Speaker background",
        "description": "",
        "videoUrl": "389516917"
      },{
        "title": "Assessed Value vs Fair Mkt",
        "shortTitle": "Assessed Value vs Fair Mkt",
        "description": "",
        "videoUrl": "389516222"
      },{
        "title": "Financial Planner & Review Process",
        "shortTitle": "Financial Planner & Review Process",
        "description": "",
        "videoUrl": "389516482"
      }]
    },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Intro to high-level sections",
          "shortTitle": "Intro to high-level sections",
          "description": "",
          "videoUrl": "388336285"
        },
        {
          "title": "Property tax revaluation",
          "shortTitle": "Property tax revaluation",
          "description": "",
          "videoUrl": "388337584"
        },
        {
          "title": "Condominiums",
          "shortTitle": "Condominiums",
          "description": "",
          "videoUrl": "388335857"
        },
        {
          "title": "Income producing property",
          "shortTitle": "Income producing property",
          "description": "",
          "videoUrl": "388336162"
        },
        {
          "title": "Business owner",
          "shortTitle": "Business owner",
          "description": "",
          "videoUrl": "388335620"
        },
        {
          "title": "Review property for possible errors",
          "shortTitle": "Review property for possible errors",
          "description": "",
          "videoUrl": "388337731"
        },
        {
          "title": "Changes in property",
          "shortTitle": "Changes in property",
          "description": "",
          "videoUrl": "388335760"
        },
        {
          "title": "Property tax exemptions",
          "shortTitle": "Property tax exemptions",
          "description": "",
          "videoUrl": "388337487"
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Property Review",
          "shortTitle": "Property Review",
          "description": "",
          "videoUrl": "388337064"
        },
        {
          "title": "Appeal Process",
          "shortTitle": "Appeal Process",
          "description": "",
          "videoUrl": "388335491"
        },
        {
          "title": "Evidence",
          "shortTitle": "Evidence",
          "description": "",
          "videoUrl": "388335929"
        },
        {
          "title": "Personal Property",
          "shortTitle": "Personal Property",
          "description": "",
          "videoUrl": "388336538"
        },
        {
          "title": "Obsolescence",
          "shortTitle": "Obsolescence",
          "description": "",
          "videoUrl": "388336430"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Case Study Intro",
          "shortTitle": "Case Study Intro",
          "description": "",
          "videoUrl": "390029946"
        },
        {
          "title": "Real Estate Income Producing",
          "shortTitle": "Real Estate Income Producing",
          "description": "",
          "videoUrl": "390047819"
        },
        {
          "title": "Personal Property",
          "shortTitle": "Personal Property",
          "description": "",
          "videoUrl": "390029517"
        }]
      }
  },
  {
    "title": "Home Insurance",
    "hexColor": Area.findAreaById('home').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Marnie Everline, Home Insurance expert.",
        "videoUrl": "392958812",
        "section": [{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Home Insurance Planning",
          "description": "Part 2",
          "videoUrl": "392958848"
        },{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Considerations",
          "description": "Part 3",
          "videoUrl": "392958909"
        }]
      },
      "section2": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Challenge 1",
          "shortTitle": "Ensuring Appropriate Coverage",
          "description": "",
          "videoUrl": "392958994"
        },
        {
          "title": "Challenge 2",
          "shortTitle": "Premium Savings",
          "description": "",
          "videoUrl": "392959078"
        },
        {
          "title": "Challenge 3",
          "shortTitle": "Importance of Coverage Review",
          "description": "",
          "videoUrl": "392959156"
        },
        {
          "title": "Challenge 4",
          "shortTitle": "Collectibles Coverage",
          "description": "",
          "videoUrl": "393993897"
        },
        {
          "title": "Challenge 5",
          "shortTitle": "Umbrella Coverage",
          "description": "",
          "videoUrl": "393994920"
        },
        {
          "title": "Case Study",
          "shortTitle": "Case Study",
          "description": "",
          "videoUrl": "393029391"
        }]
      },
      "section4": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      }
  }, 
  {
    "title": "Auto",
    "hexColor": Area.findAreaById('auto').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Marnie Everline.",
        "videoUrl": "393999316",
        "section": [{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 1",
          "description": "Part 1",
          "videoUrl": "393999316"
        },{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 2",
          "description": "Part 2",
          "videoUrl": "393998258"
        },{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 3",
          "description": "Part 3",
          "videoUrl": "393997280"
        }]
      },
      "section2": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Challenge 1",
          "shortTitle": "Challenge 1",
          "description": "",
          "videoUrl": "394001763"
        },
        {
          "title": "Challenge 2",
          "shortTitle": "Challenge 2",
          "description": "",
          "videoUrl": "394000942"
        },
        {
          "title": "Challenge 3",
          "shortTitle": "Challenge 3",
          "description": "",
          "videoUrl": "395010830"
        },
        {
          "title": "Challenge 4",
          "shortTitle": "Challenge 4",
          "description": "",
          "videoUrl": "395013159"
        },
        {
          "title": "Challenge 5",
          "shortTitle": "Challenge 5",
          "description": "",
          "videoUrl": "393999929"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Case studies 1A",
          "shortTitle": "Case studies 1A",
          "description": "",
          "videoUrl": "395064599"
        }]
      }
  }
  , 
  {
    "title": "Divorce",
    "hexColor": Area.findAreaById('divorce').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Bonnie A. Sewell.",
        "videoUrl": "394485556",
        "section": [{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 1",
          "description": "Part 1",
          "videoUrl": "394485556"
        },{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 2",
          "description": "Part 2",
          "videoUrl": "394486261"
        },{
          "title": "Expert introduction and expertise in the area",
          "shortTitle": "Introduction 3",
          "description": "Part 3",
          "videoUrl": "394986259"
        }]
      },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Intro to High Level Sections",
          "shortTitle": "Introduction",
          "description": "",
          "videoUrl": "395006107"
        },
        {
          "title": "Challenge 1",
          "shortTitle": "Challenge 1",
          "description": "",
          "videoUrl": "395000465"
        },
        {
          "title": "Challenge 2",
          "shortTitle": "Challenge 2",
          "description": "",
          "videoUrl": "395501831"
        },
        {
          "title": "Challenge 3",
          "shortTitle": "Challenge 3",
          "description": "",
          "videoUrl": "395502937"
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [{
          "title": "Introduction",
          "shortTitle": "Introduction",
          "description": "",
          "videoUrl": "395500086"
        },
        {
          "title": "Challenge 1",
          "shortTitle": "Challenge 1",
          "description": "",
          "videoUrl": "395500654"
        },
        {
          "title": "Challenge 2",
          "shortTitle": "Challenge 2",
          "description": "",
          "videoUrl": "395500999"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Case studies 1A",
          "shortTitle": "Case studies 1A",
          "description": "",
          "videoUrl": "395799430"
        }]
      }
  }
  ,/* 
  {
    "title": "Elder care",
    "hexColor": Area.findAreaById('elder').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Steven G. Siegel",
        "videoUrl": "430790553",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Strategies to consider for medicaid eligibility",
          "shortTitle": "Strategies for medicaid eligibility",
          "description": "",
          "videoUrl": "431505685"
        },
        {
          "title": "Resource limitations",
          "shortTitle": "Resource limitations",
          "description": "",
          "videoUrl": "431505271"
        },
        {
          "title": "Income-only trust",
          "shortTitle": "Income-only trust",
          "description": "",
          "videoUrl": "431505089"
        },
        {
          "title": "Income limitations",
          "shortTitle": "Income limitations",
          "description": "",
          "videoUrl": "431504292"
        },
        {
          "title": "Income and resource limitations",
          "shortTitle": "Income and resource limitations",
          "description": "",
          "videoUrl": "431502539"
        },
        {
          "title": "Exempt assets",
          "shortTitle": "Exempt assets",
          "description": "",
          "videoUrl": "430792512"
        },
        {
          "title": "Asset transfers",
          "shortTitle": "Asset transfers",
          "description": "",
          "videoUrl": "431529359"
        }]
      },
      "section3": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      },
      "section4": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      }
  }
  , */
  {
    "title": "Tax planning",
    "hexColor": Area.findAreaById('fulltax').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Mark Fichtenbaum, Clinical Assistant Professor",
        "videoUrl": "430759359",
        "section": [{
          "title": "Tax Expert Information",
          "shortTitle": "Tax Expert Information",
          "description": "The basics explained by",
          "videoUrl": "430759359"
        },
        {
          "title": "Introducing the financial planning topic: Tax Planning",
          "shortTitle": "Introducing the financial planning topic",
          "description": "",
          "videoUrl": "430759733"
        }]
      },
      "section2": {
        "title": "Recommendations",
        "section": [{
          "title": "Incentive stock options",
          "shortTitle": "Incentive stock options",
          "description": "",
          "videoUrl": "430760592"
        },
        {
          "title": "Investment Income",
          "shortTitle": "Investment Income",
          "description": "",
          "videoUrl": "430761633"
        },
        {
          "title": "Investment interest expense",
          "shortTitle": "Investment interest expense",
          "description": "",
          "videoUrl": "430762699"
        },
        {
          "title": "Related party transactions",
          "shortTitle": "Related party transactions",
          "description": "",
          "videoUrl": "430763845"
        },
        {
          "title": "Shifting income to children",
          "shortTitle": "Shifting income to children",
          "description": "",
          "videoUrl": "430763397"
        }]
      },
      "section3": {
        "title": "High Level Sections",
        "section": [{
          "title": "Incentive stock options",
          "shortTitle": "Incentive stock options",
          "description": "",
          "videoUrl": "430758253"
        },
        {
          "title": "Investment interest expense",
          "shortTitle": "Investment interest expense",
          "description": "",
          "videoUrl": "430755712"
        },
        {
          "title": "Planning for investment income",
          "shortTitle": "Planning for investment income",
          "description": "",
          "videoUrl": "430755090"
        },
        {
          "title": "Related party transactions",
          "shortTitle": "Related party transactions",
          "description": "",
          "videoUrl": "430757095"
        },
        {
          "title": "Shifting income to children",
          "shortTitle": "Shifting income to children",
          "description": "",
          "videoUrl": "430756335"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Case study 1A",
          "shortTitle": "Case study 1A",
          "description": "",
          "videoUrl": "430753419"
        }]
      }
  }
  , 
  {
    "title": "Creditor",
    "hexColor": Area.findAreaById('creditor').colorHex,
      "section1": {
        "title": "Introduction",
        "description": "The basics explained by Douglass S. Loadmell, Managing Partner",
        "videoUrl": "430449820",
        "section": [{
          "title": "The creditor protection expert information",
          "shortTitle": "The creditor protection information",
          "description": "The basics explained by",
          "videoUrl": "430449820"
        },
        {
          "title": "Introduce the financial planning topic: creditor protection planning",
          "shortTitle": "Creditor protection planning",
          "description": "",
          "videoUrl": "430450140"
        },
        {
          "title": "Examples of key challenges opportunities",
          "shortTitle": "Key challenges opportunities",
          "description": "",
          "videoUrl": "430450664"
        }]
      },
      "section2": {
        "title": "Recommendations",
        "section": [
        {
          "title": "Introuction to the complicated recommendation topic",
          "shortTitle": "Introuction to recommendation topic",
          "description": "",
          "videoUrl": "430459153"
        },
        {
          "title": "Cash flow crunch",
          "shortTitle": "Cash flow crunch",
          "description": "",
          "videoUrl": "430460208"
        },
        {
          "title": "Complicated estate planning",
          "shortTitle": "Complicated estate planning",
          "description": "",
          "videoUrl": "430459784"
        },
        {
          "title": "Creditor pursuing personal assets",
          "shortTitle": "Creditor pursuing personal assets",
          "description": "",
          "videoUrl": "430461082"
        },
        {
          "title": "If client is illiquid or unable to meet existing liabilities",
          "shortTitle": "Liquidity and existing liabilities",
          "description": "",
          "videoUrl": "430590044"
        }]
      },
      "section3": {
        "title": "High Level Sections",
        "section": [{
          "title": "Annuities",
          "shortTitle": "Annuities",
          "description": "",
          "videoUrl": "430516515"
        },
        {
          "title": "Creating an LLC for Business",
          "shortTitle": "Creating an LLC for Business",
          "description": "",
          "videoUrl": "430461199"
        },
        {
          "title": "Dealing with debt collectors",
          "shortTitle": "Dealing with debt collectors",
          "description": "",
          "videoUrl": "430591037"
        },
        {
          "title": "Debt form student loans",
          "shortTitle": "Debt form student loans",
          "description": "",
          "videoUrl": "430590465"
        },
        {
          "title": "Debt from credit cards",
          "shortTitle": "Debt from credit cards",
          "description": "",
          "videoUrl": "430590239"
        },
        {
          "title": "Debt from unpaid taxes",
          "shortTitle": "Debt from unpaid taxes",
          "description": "",
          "videoUrl": "430590577"
        },
        {
          "title": "Education funding accounts for beneficiaries",
          "shortTitle": "Education funding accounts",
          "description": "",
          "videoUrl": "430516640"
        },
        {
          "title": "High-risk situations",
          "shortTitle": "High-risk situations",
          "description": "",
          "videoUrl": "430527163"
        },
        {
          "title": "If client doesn't have an umbrella policy",
          "shortTitle": "No umbrella policy",
          "description": "",
          "videoUrl": "430515494"
        },
        {
          "title": "If client doesn't own real property as 'tenancy by the entirety'",
          "shortTitle": "No own real property",
          "description": "",
          "videoUrl": "430526695"
        },
        {
          "title": "If client generally title assets in his name",
          "shortTitle": "Assets with client name",
          "description": "",
          "videoUrl": "430515906"
        },
        {
          "title": "If client has an LLC but doesnt have a legitimate business purpose",
          "shortTitle": "LLC but no a business purpose",
          "description": "",
          "videoUrl": "430462023"
        },
        {
          "title": "If client has an S-Corporation",
          "shortTitle": "S-Corporation",
          "description": "",
          "videoUrl": "430462149"
        },
        {
          "title": "If client has an umbrella insurance policy",
          "shortTitle": "Umbrella insurance policy",
          "description": "",
          "videoUrl": "430515305"
        },
        {
          "title": "If client is interested in protection from creditors",
          "shortTitle": "Protection from creditors",
          "description": "",
          "videoUrl": "430460513"
        },
        {
          "title": "If client is married",
          "shortTitle": "Married",
          "description": "",
          "videoUrl": "430516321"
        },
        {
          "title": "If client operates a high risk business",
          "shortTitle": "High risk business",
          "description": "",
          "videoUrl": "430515138"
        },
        {
          "title": "If client owns a business",
          "shortTitle": "Owns a business",
          "description": "",
          "videoUrl": "430460646"
        },
        {
          "title": "If client owns a single-member LLC",
          "shortTitle": "Single-member LLC",
          "description": "",
          "videoUrl": "430461740"
        },
        {
          "title": "If client owns real property as 'tenancy by the entirety'",
          "shortTitle": "Own real property",
          "description": "",
          "videoUrl": "430526893"
        },
        {
          "title": "If client reside in a state that allows tenancy by the entirety trust",
          "shortTitle": "State that allows tenancy",
          "description": "",
          "videoUrl": "430527056"
        },
        {
          "title": "If client's business isn't structures as an entity",
          "shortTitle": "Business isn't structures as an entity",
          "description": "",
          "videoUrl": "430460936"
        },
        {
          "title": "If client's debt is lawsuit liability",
          "shortTitle": "Debt is lawsuit liability",
          "description": "",
          "videoUrl": "430590132"
        },
        {
          "title": "If clients business is structured as an entity",
          "shortTitle": "Business is structured as an entity",
          "description": "",
          "videoUrl": "430460771"
        },
        {
          "title": "Inherited IRA",
          "shortTitle": "Inherited IRA",
          "description": "",
          "videoUrl": "430526223"
        },
        {
          "title": "Inherited IRA held in a see-through or conduit trust",
          "shortTitle": "Inherited IRA held in a see-through or conduit trust",
          "description": "",
          "videoUrl": "430526339"
        },
        {
          "title": "Intro to High-Level section",
          "shortTitle": "Intro to High-Level section",
          "description": "",
          "videoUrl": "430454551"
        },
        {
          "title": "Investments in real estate",
          "shortTitle": "Investments in real estate",
          "description": "",
          "videoUrl": "430516050"
        },
        {
          "title": "Necessary corporate formalities",
          "shortTitle": "Necessary corporate formalities",
          "description": "",
          "videoUrl": "430461447"
        },
        {
          "title": "New York City co-op apartment purcahsed prior 1996",
          "shortTitle": "New York City co-op apartment purcahsed prior 1996",
          "description": "",
          "videoUrl": "430526595"
        },
        {
          "title": "Personal real estate",
          "shortTitle": "Personal real estate",
          "description": "",
          "videoUrl": "430526451"
        },
        {
          "title": "Protect assets without the use of a prenuptial agreement",
          "shortTitle": "Protect assets without the use of a prenuptial agreement",
          "description": "",
          "videoUrl": "430527618"
        },
        {
          "title": "Qualified retirement assets",
          "shortTitle": "Qualified retirement assets",
          "description": "",
          "videoUrl": "430526079"
        },
        {
          "title": "Revocable trust",
          "shortTitle": "Revocable trust",
          "description": "",
          "videoUrl": "430589921"
        },
        {
          "title": "Rolling over a retirement plan to a Rollover IRA due to the risk of bankruptcy",
          "shortTitle": "Rolling over a retirement plan to a Rollover IRA due to the risk of bankruptcy",
          "description": "",
          "videoUrl": "430590788"
        },
        {
          "title": "States with homestead exemption",
          "shortTitle": "States with homestead exemption",
          "description": "",
          "videoUrl": "430515671"
        },
        {
          "title": "Student loans",
          "shortTitle": "Student loans",
          "description": "",
          "videoUrl": "430516248"
        }]
      },
      "section4": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": ""
        }]
      }
  }
  ,/*{
    "title": "Estate Planning",
    "hexColor": Area.findAreaById('estate').colorHex,
    "section1": {
      "title": "Introduction",
      "description": "The basics explained by Steven G. Siegel, Estate expert.",
      "videoUrl": "432213103"
    },
      "section2": {
        "title": "High Level Sections",
        "section": [{
          "title": "Liquidity",
          "shortTitle": "Liquidity",
          "description": "",
          "videoUrl": "429890362"
        },
        {
          "title": "Home in Revocable trust",
          "shortTitle": "Home in Revocable trust",
          "description": "",
          "videoUrl": "429889858"
        },
        {
          "title": "Disinherit a relative",
          "shortTitle": "Disinherit a relative",
          "description": "",
          "videoUrl": "429889269"
        },
        {
          "title": "Deceased statutory",
          "shortTitle": "Deceased statutory",
          "description": "",
          "videoUrl": "429889113"
        },
        {
          "title": "Contentious Situation",
          "shortTitle": "Contentious Situation",
          "description": "",
          "videoUrl": "429888234"
        }]
      },
      "section3": {
        "title": "Recommendations",
        "section": [
        {
          "title": "Amendments in Revocable Trust",
          "shortTitle": "Amendments in Revocable Trust",
          "description": "",
          "videoUrl": "429890749"
        },
        {
          "title": "Apportioned taxable transfers",
          "shortTitle": "Apportioned taxable transfers",
          "description": "",
          "videoUrl": "429890866"
        },
        {
          "title": "Appreciation of the estate after spouse's death",
          "shortTitle": "Appreciation of the estate after spouse's death",
          "description": "",
          "videoUrl": "429891241"
        },
        {
          "title": "Assets in multiple estates",
          "shortTitle": "Assets in multiple estates",
          "description": "",
          "videoUrl": "432211975"
        },
        {
          "title": "Assets in Revocable Trust",
          "shortTitle": "Assets in Revocable Trust",
          "description": "",
          "videoUrl": "429891680"
        },
        {
          "title": "Assets outside of the revocable trust",
          "shortTitle": "Assets outside of the revocable trust",
          "description": "",
          "videoUrl": "429891888"
        },
        {
          "title": "Being a Beneficiary of an irrevocable Trust",
          "shortTitle": "Being a Beneficiary of an irrevocable Trust",
          "description": "",
          "videoUrl": "429892056"
        },
        {
          "title": "Blended Family",
          "shortTitle": "Blended Family",
          "description": "",
          "videoUrl": "429892240"
        },
        {
          "title": "Business in will",
          "shortTitle": "Business in will",
          "description": "",
          "videoUrl": "429892723"
        },
        {
          "title": "Bypass Trust",
          "shortTitle": "Bypass Trust",
          "description": "",
          "videoUrl": "429892994"
        },
        {
          "title": "Capital Gains distribution",
          "shortTitle": "Capital Gains distribution",
          "description": "",
          "videoUrl": "429893366"
        },
        {
          "title": "Children-Issues and complications that may arise",
          "shortTitle": "Children-Issues and complications that may arise",
          "description": "",
          "videoUrl": "429893731"
        },
        {
          "title": "Client has relocated",
          "shortTitle": "Client has relocated",
          "description": "",
          "videoUrl": "429894384"
        },
        {
          "title": "Comfortable Distributions",
          "shortTitle": "Comfortable Distributions",
          "description": "",
          "videoUrl": "430000051"
        },
        {
          "title": "Contingent Beneficiaries",
          "shortTitle": "Contingent Beneficiaries",
          "description": "",
          "videoUrl": "430000338"
        },
        {
          "title": "Contingent trust for children",
          "shortTitle": "Contingent trust for children",
          "description": "",
          "videoUrl": "430001056"
        },
        {
          "title": "Digital Assets",
          "shortTitle": "Digital Assets",
          "description": "",
          "videoUrl": "430001803"
        },
        {
          "title": "Disclaimer or bypass trust in will",
          "shortTitle": "Disclaimer or bypass trust in will",
          "description": "",
          "videoUrl": "430002102"
        },
        {
          "title": "Disclaimer provision in will",
          "shortTitle": "Disclaimer provision in will",
          "description": "",
          "videoUrl": "430002787"
        },
        {
          "title": "Distributions",
          "shortTitle": "Distributions",
          "description": "",
          "videoUrl": "430003321"
        },
        {
          "title": "Estate privacy and pour-over will",
          "shortTitle": "Estate privacy and pour-over will",
          "description": "",
          "videoUrl": "430003517"
        },
        {
          "title": "Flexibility",
          "shortTitle": "Flexibility",
          "description": "",
          "videoUrl": "430007425"
        },
        {
          "title": "Generation-Skipping transfer tax",
          "shortTitle": "Generation-Skipping transfer tax",
          "description": "",
          "videoUrl": "430011319"
        },
        {
          "title": "Guardians in will",
          "shortTitle": "Guardians in will",
          "description": "",
          "videoUrl": "430011676"
        },
        {
          "title": "HEMS provisions",
          "shortTitle": "HEMS provisions",
          "description": "",
          "videoUrl": "430013077"
        },
        {
          "title": "If client's attorney is not money-wise",
          "shortTitle": "Client's attorney is not money-wise",
          "description": "",
          "videoUrl": "430023770"
        },
        {
          "title": "If client's executor is an attorney",
          "shortTitle": "Client's executor is an attorney",
          "description": "",
          "videoUrl": "430023984"
        },
        {
          "title": "If the client anticipates a will contest",
          "shortTitle": "Client anticipates a will contest",
          "description": "",
          "videoUrl": "430024815"
        },
        {
          "title": "If the named executor is not a US citizen",
          "shortTitle": "Named executor is not a US citizen",
          "description": "",
          "videoUrl": "430025225"
        },
        {
          "title": "If there is not a replacement executor",
          "shortTitle": "Not a replacement executor",
          "description": "",
          "videoUrl": "430025578"
        },
        {
          "title": "If trust agreement was not written by lawyer",
          "shortTitle": "Trust agreement was not written by lawyer",
          "description": "",
          "videoUrl": "430025907"
        },
        {
          "title": "Irrevocable trust",
          "shortTitle": "Irrevocable trust",
          "description": "",
          "videoUrl": "430026140"
        },
        {
          "title": "Joint wills and joint revocable trusts",
          "shortTitle": "Joint wills and joint revocable trusts",
          "description": "",
          "videoUrl": "430027433"
        },
        {
          "title": "Land subject to environmental cleanup",
          "shortTitle": "Land subject to environmental cleanup",
          "description": "",
          "videoUrl": "430027761"
        },
        {
          "title": "Mortgage Issues",
          "shortTitle": "Mortgage Issues",
          "description": "",
          "videoUrl": "430028830"
        },
        {
          "title": "Moved to a New State since trust was drafted",
          "shortTitle": "Moved to a New State since trust was drafted",
          "description": "",
          "videoUrl": "430029491"
        },
        {
          "title": "Multiple attorneys",
          "shortTitle": "Multiple attorneys",
          "description": "",
          "videoUrl": "430029688"
        },
        {
          "title": "Multiple trusts",
          "shortTitle": "Multiple trusts",
          "description": "",
          "videoUrl": "430030053"
        },
        {
          "title": "Name a replacement executor",
          "shortTitle": "Name a replacement executor",
          "description": "",
          "videoUrl": "430030383"
        },
        {
          "title": "New child since the last update of the will",
          "shortTitle": "New child since the last update of the will",
          "description": "",
          "videoUrl": "430030690"
        },
        {
          "title": "Power of appointment",
          "shortTitle": "Power of appointment",
          "description": "",
          "videoUrl": "430030931"
        },
        {
          "title": "Power of attorney and revocable trust",
          "shortTitle": "Power of attorney and revocable trust",
          "description": "",
          "videoUrl": "430031856"
        },
        {
          "title": "QPRT",
          "shortTitle": "QPRT",
          "description": "",
          "videoUrl": "430032168"
        },
        {
          "title": "Restricting funds & QTIP",
          "shortTitle": "Restricting funds & QTIP",
          "description": "",
          "videoUrl": "430033171"
        },
        {
          "title": "Tax free legacy to spouse or charity",
          "shortTitle": "Tax free legacy to spouse or charity",
          "description": "",
          "videoUrl": "430073585"
        },
        {
          "title": "Tax planning opportunities",
          "shortTitle": "Tax planning opportunities",
          "description": "",
          "videoUrl": "430074297"
        },
        {
          "title": "Testamentary trust",
          "shortTitle": "Testamentary trust",
          "description": "",
          "videoUrl": "430074541"
        },
        {
          "title": "UTMA or UGMA account",
          "shortTitle": "UTMA or UGMA account",
          "description": "",
          "videoUrl": "430074868"
        },
        {
          "title": "Will and revocable trust with different beneficiaries",
          "shortTitle": "Will and revocable trust with different beneficiaries",
          "description": "",
          "videoUrl": "430075200"
        },
        {
          "title": "Will and revocable trusts created pre-2010",
          "shortTitle": "Will and revocable trusts created pre-2010",
          "description": "",
          "videoUrl": "430075484"
        },
        {
          "title": "Wills with gift conditions",
          "shortTitle": "Wills with gift conditions",
          "description": "",
          "videoUrl": "430076029"
        }]
      },
      "section4": {
        "title": "",
        "section": [{
          "title": "",
          "shortTitle": "",
          "description": "",
          "videoUrl": "388340592"
        }]
      }
  },*/
  {
    "title": "Mortgage",
    "hexColor": Area.findAreaById('mortgage').colorHex,
    "section1": {
      "title": "Introduction",
      "description": "The basics explained by Michael Conrath, Senior Mortgage Consultant",
      "videoUrl": "432652288",
      "section": [{
        "title": "Advisor guidance",
        "shortTitle": "Advisor guidance",
        "description": "",
        "videoUrl": "432652288"
      },{
        "title": "Clients living off of assests andor passive income",
        "shortTitle": "Clients living off of assests andor passive income",
        "description": "",
        "videoUrl": "432652543"
      },{
        "title": "Introduce the financial planning topic: Mortgage",
        "shortTitle": "Introduce the financial planning topic",
        "description": "",
        "videoUrl": "432652088"
      },{
        "title": "Mortgage approval",
        "shortTitle": "Mortgage approval",
        "description": "",
        "videoUrl": "432652421"
      },{
        "title": "Mortgage expert information",
        "shortTitle": "Mortgage expert information",
        "description": "",
        "videoUrl": "432605250"
      },{
        "title": "Mortgage product options",
        "shortTitle": "Mortgage product options",
        "description": "",
        "videoUrl": "432652673"
      }]
    },
      "section2": {
        "title": "Recommendations",
        "section": [{
          "title": "Purchase mortgage",
          "shortTitle": "Purchase mortgage",
          "description": "",
          "videoUrl": "432652820"
        },
        {
          "title": "Refinance mortgage",
          "shortTitle": "Refinance mortgage",
          "description": "",
          "videoUrl": "432653044"
        }]
      },
      "section3": {
        "title": "High Level Sections",
        "section": [
        {
          "title": "Close a new house before selling current home",
          "shortTitle": "Close a new house before selling current home",
          "description": "",
          "videoUrl": "432604199"
        },
        {
          "title": "General qualifications",
          "shortTitle": "General qualifications",
          "description": "",
          "videoUrl": "432602158"
        },
        {
          "title": "Government GSE determinations",
          "shortTitle": "Government GSE determinations",
          "description": "",
          "videoUrl": "432604703"
        },
        {
          "title": "Interest rate adjustments",
          "shortTitle": "Interest rate adjustments",
          "description": "",
          "videoUrl": "432604983"
        },
        {
          "title": "Lender decisions",
          "shortTitle": "Lender decisions",
          "description": "",
          "videoUrl": "432603058"
        },
        {
          "title": "Mortgage programs",
          "shortTitle": "Mortgage programs",
          "description": "",
          "videoUrl": "432603950"
        },
        {
          "title": "Options to buy a house",
          "shortTitle": "Options to buy a house",
          "description": "",
          "videoUrl": "432601803"
        },
        {
          "title": "Private mortgage insurance",
          "shortTitle": "Private mortgage insurance",
          "description": "",
          "videoUrl": "432604553"
        },
        {
          "title": "Property types",
          "shortTitle": "Property types",
          "description": "",
          "videoUrl": "432603650"
        },
        {
          "title": "General qualifications",
          "shortTitle": "General qualifications",
          "description": "",
          "videoUrl": "432602567"
        },
        {
          "title": "Government GSE determinations",
          "shortTitle": "Government GSE determinations",
          "description": "",
          "videoUrl": "432603129"
        },
        {
          "title": "Interest rate adjustments",
          "shortTitle": "Interest rate adjustments",
          "description": "",
          "videoUrl": "432603502"
        },
        {
          "title": "Mortgage programs",
          "shortTitle": "Mortgage programs",
          "description": "",
          "videoUrl": "432604084"
        },
        {
          "title": "Refinancing and elimination of PMI",
          "shortTitle": "Refinancing and elimination of PMI",
          "description": "",
          "videoUrl": "433694470"
        },
        {
          "title": "Private mortgage insurance",
          "shortTitle": "Private mortgage insurance",
          "description": "",
          "videoUrl": "432603823"
        },
        {
          "title": "Property classifications",
          "shortTitle": "Property classifications",
          "description": "",
          "videoUrl": "432603207"
        },
        {
          "title": "Property classifications",
          "shortTitle": "Property classifications",
          "description": "",
          "videoUrl": "432604869"
        },
        {
          "title": "Residencial landing",
          "shortTitle": "Residencial landing",
          "description": "",
          "videoUrl": "432604467"
        }]
      },
      "section4": {
        "title": "Case studies",
        "section": [{
          "title": "Credit score",
          "shortTitle": "Credit score",
          "description": "",
          "videoUrl": "432601299"
        },
        {
          "title": "Software",
          "shortTitle": "Software",
          "description": "",
          "videoUrl": "432601685"
        }]
      }
  }
  ]
;

getAssets(){
    return this.educationAssets;
}
  
}
