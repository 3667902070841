import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flowchart-box',
  templateUrl: './flowchart-box.component.html',
  styleUrls: ['./flowchart-box.component.scss']
})
export class FlowchartBoxComponent implements OnInit {

  @Input() platformView: boolean = false;
  @Input() dataNode: any;
  @Input() id: string;

  constructor() { }

  ngOnInit(): void {
    if(this.dataNode){
    }
  }

}
