import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

/**
 * FileUploaderExtended
 * The same as FileUploader with the added propertie required, when the FileUploader works as single file upload, to set if is a requiered file.
 */
export class FileUploaderExtended extends FileUploader {
    required: boolean = true;
    currentUrl: string = '';
    private originalUrl:string;

    constructor(options: FileUploaderOptions) {
        super(options);
        this.currentUrl = options.url;
    }

    setUrl(url:string){
        this.currentUrl = url;
        this.setOptions({
            url: url
        })
    }

    refreshTokenUrl(token:string){
        this.setOptions({
            authToken: `Bearer ${token}`,
        })
    }

    urlAppend(appendData: string, isFirstAppend: boolean = false){
        this.setUrl(this.currentUrl + (isFirstAppend? '?' : '&') + appendData);
    }

    hasBeenFirstElementUploaded(): boolean {
        let isUploaded: boolean = false;
        if(this.queue[0] != undefined) isUploaded = this.queue[0].isSuccess;
        return isUploaded;
    }

    async uploadAllFiles() {

        var xhr = new XMLHttpRequest();
        var sendable = new FormData();
        var fakeitem: FileItem = null;
        this.onBuildItemForm(fakeitem, sendable);
        let item: File | any;
        for (item of this.queue) {
          item.isReady = true;
          item.isUploading = true;
          item.isUploaded = false;
          item.isSuccess = false;
          item.isCancel = false;
          item.isError = false;
          item.progress = 0;
          if (typeof item._file.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
          }
          if (item?._file?.rawFile) {
            sendable.append("clientIDFiles", item._file.rawFile);
          } else {
            sendable.append("clientIDFiles", item._file);
          }

        }

        if (this.options.additionalParameter !== undefined) {
          Object.keys(this.options.additionalParameter).forEach((key) => {
            sendable.append(key, this.options.additionalParameter[key]);
          });
        }

        xhr.onload = () => {
          var gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
          var method = 'on' + gist + 'Item';
          this[method](fakeitem, null, xhr.status, null);

        };

        xhr.onerror = (request) => {
          console.log('ERROR SOMEWHERE UPLOADER EXTENDED :P', request);
          this.onErrorItem(fakeitem, null, xhr.status, null);
        };

        xhr.onabort = () => {
          this.onErrorItem(fakeitem, null, xhr.status, null);
        };

        xhr.onprogress = () => {

        }

        xhr.addEventListener('loadend', (e) => {

          if(e.loaded === e.total) {

            this.onCompleteAll();
          }
        });

        xhr.open("POST", this.options.url, true);
        xhr.withCredentials = false;
        if (this.options.headers) {
          for (var _i = 0, _a = this.options.headers; _i < _a.length; _i++) {
            var header = _a[_i];
            xhr.setRequestHeader(header.name, header.value);
          }
        }
        if (this.authToken) {
          xhr.setRequestHeader(this.authTokenHeader, this.authToken);
        }

        await xhr.send(sendable);
    };
}
