import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';


export interface StepForm{
  form: FormGroup,
  step: number,
  valid: boolean
}

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireManagerService {

  private questionnaire: FormGroup;

  private forms: FormGroup[];

  public activeStep: number;

  public formsAreReadySubject: BehaviorSubject<boolean>;

  public onValidQuestionnaire: BehaviorSubject<FormGroup>;

  public onFormChange: BehaviorSubject<StepForm[]>;

  public onValidForm: BehaviorSubject<StepForm | undefined>;

  constructor(private formBuilder: FormBuilder) {
    this.onFormChange = new BehaviorSubject([]);
    this.onValidForm = new BehaviorSubject(undefined);
    this.onValidQuestionnaire = new BehaviorSubject(this.questionnaire);
    this.formsAreReadySubject= new BehaviorSubject(false);
    this.activeStep = 0;
  }

  initQuestionnaire(
    forms: FormGroup[]
  ){
    this.updateQuestionnaire(forms)
    this.formsAreReadySubject.next(true)
  }

  async evaluateChanges(){
    this.emitStepForms()
    if (this.allFormsAreValid()) {
      await this.updateQuestionnaire();
      this.emitQuestionnaire()
    }
  }

  emitStepForms() {
    let stepForms:StepForm[] = []
    this.forms.forEach((form, index) => {
      stepForms[index] = {
        form: form,
        step: index,
        valid: this.isFormValid(form)
      }
      if (stepForms[index].valid) {
        this.onValidForm.next(stepForms[index])
      }
    })
    this.onFormChange.next(stepForms);
  }

  emitQuestionnaire() {
    this.onValidQuestionnaire.next(this.questionnaire);
  }

  updateQuestionnaire(forms?: FormGroup[]){
    if (forms) {
      this.forms = forms;
    }
    this.questionnaire = this.formBuilder.group({});
    this.forms.forEach((formGroup, index) => {
      Object.keys(formGroup.controls).forEach(controlName => {
        this.questionnaire.addControl(`${index}_${controlName}`, formGroup.get(controlName));
      });
    });
  }

  allFormsAreValid(){
    let allFormsAreValid = true;
    this.forms.forEach((form) => {
      allFormsAreValid = allFormsAreValid && this.isFormValid(form)
    })
    return allFormsAreValid
  }

  isFormValid(form: FormGroup){
    let isValid = true;
    Object.keys(form.controls).forEach(controlName => {
      const control = form.get(controlName);
      isValid = isValid && control.status === "VALID";
    });
    return isValid;
  }

  getActiveForm(){
    return this.forms[this.activeStep]
  }

}
