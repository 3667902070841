import { Component, Input, ViewChild, forwardRef, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { get } from 'http';

@Component({
  selector: 'amount-or-range',
  templateUrl: './amount-or-range.component.html',
  styleUrls: ['./amount-or-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountOrRangeComponent),
      multi: true
    }
  ]
})
export class AmountOrRangeComponent implements ControlValueAccessor, AfterViewInit{

  @ViewChild('input') input: any;

  @Input() ranges: number[];

  private _manualValue: string;

  @Input()
  get manualValue(): string {
    return this._manualValue;
  }
  
  set manualValue(value: string) {
    this._manualValue = value;
    if (value.includes('-')) {
      this.updateValue('range', value) 
    }
    else{
      this.updateValue('manually', value) 
    }
  }

  selectedRange: string = '0';

  inputValue: string = '';

  disabled: boolean;

  constructor(
    private elementRef: ElementRef, 
    private renderer: Renderer2
  ) { 
  }

  ngAfterViewInit(): void {
   
  }


  writeValue(value: string): void {
    this.updateValue('manually', value)
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => {};

  onTouch: any = () => {};
  
  updateValue(type, newValue: string) {
    if (!this.disabled) {
      this.onChange(newValue);
      if (type === 'manually') {
        this.inputValue = newValue == '0' ? '' : newValue
        this.selectedRange = '0'
      }
      if (type === 'range' || ( typeof newValue == 'string' && newValue.includes('-'))) {
        this.inputValue = ''
        this.selectedRange = newValue
      }
    }
  }

}
