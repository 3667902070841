import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { delay_ms, getRandomInt } from 'app/shared/helpers/utils';

@Component({
  selector: 'snapshot-components',
  templateUrl: './snapshot-components.component.html',
  styleUrls: ['./snapshot-components.component.scss']
})
export class SnapshotComponents implements AfterViewInit {

  @Input() pageObject: any;
  @Input() sectionColor: any;
  @Input() applyPrintableStyle: boolean = false;
  @Output() onGraphRedraw: EventEmitter<any> = new EventEmitter();
  public snapshotUid: number = getRandomInt(1, 100);
  public allReady: EventEmitter<any> = new EventEmitter<any>();

  constructor() { };

  handleSectionLines(sections: any, subSection: HTMLElement, limit: string | boolean, subSectionIndex?: number) {
    /* Section line Elements */
    const BEFORE_ELEMENT: HTMLElement = subSection.querySelector('.section-before');
    const AFTER_ELEMENT: HTMLElement = subSection.querySelector('.section-after');

    if (sections.current.length === 1) {
      const FIRST_ROW = document.getElementById(`${this.snapshotUid}-first-row-${sections.current[0].title.replaceAll(' ', '-')}`);

      if (sections.prev) {
        if (sections.prev.length === 1) {
          FIRST_ROW.style.top = "-2.1rem";
          FIRST_ROW.style.left = "calc(50% - 23px)";
          FIRST_ROW.style.transform = "rotate(-90deg)";
        } else if (sections.prev.length === 2) { /* Prev section (2:1) (-:.) */
          const FIRST_ROW = document.getElementById(`${this.snapshotUid}-first-row-${sections.current[0].title.replaceAll(' ', '-')}`);
          const LAST_ROW = document.getElementById(`${this.snapshotUid}-last-row-${sections.current[0].title.replaceAll(' ', '-')}`);

          for (let i = 0; i < sections.prev.length; i++) {
            BEFORE_ELEMENT.style.height = "2px";
            BEFORE_ELEMENT.style.left = - (document.getElementById(`${this.snapshotUid}-subrow-${sections.prev[0].title.replaceAll(' ', '-')}-${0}`).getBoundingClientRect().width / 2) + "px";
            BEFORE_ELEMENT.style.width = (
              (document.getElementById(`${this.snapshotUid}-subrow-${sections.prev[0].title.replaceAll(' ', '-')}-${0}`).getBoundingClientRect().width / 2) +
              subSection.getBoundingClientRect().width +
              (document.getElementById(`${this.snapshotUid}-subrow-${sections.prev[1].title.replaceAll(' ', '-')}-${1}`).getBoundingClientRect().width / 2) + 1)
              + "px";

            FIRST_ROW.style.left = '-2.04rem';
            FIRST_ROW.style.transform = 'rotate(180deg)';
            LAST_ROW.style.right = '-2.04rem';
          }
        }
      }

      if (sections.next) {
        if (sections.next.length === 1) {
          AFTER_ELEMENT.style.width = "2px";
          AFTER_ELEMENT.style.height = "80px";
          AFTER_ELEMENT.style.left = "50%";
          AFTER_ELEMENT.style.top = "100%";

        } else if (sections.next.length === 2) {
          for (let i = 0; i < sections.next.length; i++) {
            const SUB_SECTION = document.getElementById(`${this.snapshotUid}-subrow-${sections.next[i].title.replaceAll(' ', '-')}-${i}`);
            const POST_NEXT_SECTION = sections.postNext && document.getElementById(`${this.snapshotUid}-row-${sections.postNext[0].title.replaceAll(' ', '-')}`);

            if (i === 0) {
              BEFORE_ELEMENT.style.top = "50%";
              BEFORE_ELEMENT.style.width = (SUB_SECTION.getBoundingClientRect().width / 2) + (sections.postNext ? (POST_NEXT_SECTION.getBoundingClientRect().width / 2) : 0) + "px";
              BEFORE_ELEMENT.style.height = "2px";
            } else if (i === 1) {
              AFTER_ELEMENT.style.top = "50%";
              AFTER_ELEMENT.style.width = (SUB_SECTION.getBoundingClientRect().width / 2) + (sections.postNext ? (POST_NEXT_SECTION.getBoundingClientRect().width / 2) : 0) + "px";
              AFTER_ELEMENT.style.height = "2px";
            }

              BEFORE_ELEMENT.style.right = "50%";
              AFTER_ELEMENT.style.left = "calc(50% + 2px)"
          }
        }
      }

    } else if (sections.current.length === 2) {
      let maxHeight = 0;
      /* Prev and post sections */
      const PREV_ELEMENT = document.getElementById(`${this.snapshotUid}-row-${sections.prev[0].title.replaceAll(' ', '-')}`);
      const POST_ELEMENT = document.getElementById(`${this.snapshotUid}-row-${sections.next[0].title.replaceAll(' ', '-')}`);
      const FIRST_SECTION = document.getElementById(`${this.snapshotUid}-subrow-${sections.current[0].title.replaceAll(' ', '-')}-${0}`);
      const SECOND_SECTION = document.getElementById(`${this.snapshotUid}-subrow-${sections.current[1].title.replaceAll(' ', '-')}-${1}`);

      for (let i = 0; i < sections.current.length; i++) {
        const CURRENT_SECTION = document.getElementById(`${this.snapshotUid}-subrow-${sections.current[i].title.replaceAll(' ', '-')}-${i}`);

        /* Section line Elements */
        const BEFORE_ELEMENT: HTMLElement = CURRENT_SECTION.querySelector('.section-before');
        const AFTER_ELEMENT: HTMLElement = CURRENT_SECTION.querySelector('.section-after');

        const FIRST_ROW = document.getElementById(`${this.snapshotUid}-first-row-${sections.current[i].title.replaceAll(' ', '-')}`);
        maxHeight = (CURRENT_SECTION.getBoundingClientRect().height > maxHeight) ? CURRENT_SECTION.getBoundingClientRect().height : maxHeight;

        BEFORE_ELEMENT.style.width = "2px";
        if (FIRST_SECTION.getBoundingClientRect().height > SECOND_SECTION.getBoundingClientRect().height) {
          if (i === 0) {
            BEFORE_ELEMENT.style.height = maxHeight + (PREV_ELEMENT.getBoundingClientRect().height / 2) + 80 + "px";
            AFTER_ELEMENT.style.height = maxHeight + (POST_ELEMENT.getBoundingClientRect().height / 2) + 80 + "px";
          } else if (i === 1) {
            BEFORE_ELEMENT.style.height = maxHeight + (PREV_ELEMENT.getBoundingClientRect().height / 2) - ((FIRST_SECTION.getBoundingClientRect().height - SECOND_SECTION.getBoundingClientRect().height) / 2) + 80 + "px";
            AFTER_ELEMENT.style.height = maxHeight + (POST_ELEMENT.getBoundingClientRect().height / 2) - ((FIRST_SECTION.getBoundingClientRect().height - SECOND_SECTION.getBoundingClientRect().height) / 2) + 80 + "px";
          };
        } else {
          if (i === 0) {
            BEFORE_ELEMENT.style.height = maxHeight + (PREV_ELEMENT.getBoundingClientRect().height / 2) - ((FIRST_SECTION.getBoundingClientRect().height - SECOND_SECTION.getBoundingClientRect().height) / 2) + 80 + "px";
            AFTER_ELEMENT.style.height = maxHeight + (POST_ELEMENT.getBoundingClientRect().height / 2) - ((FIRST_SECTION.getBoundingClientRect().height - SECOND_SECTION.getBoundingClientRect().height) / 2) + 80 + "px";
          } else if (i === 1) {
            BEFORE_ELEMENT.style.height = maxHeight + (PREV_ELEMENT.getBoundingClientRect().height / 2) + 80 + "px";
            AFTER_ELEMENT.style.height = maxHeight + (POST_ELEMENT.getBoundingClientRect().height / 2) + 80 + "px";
          };
        }
        BEFORE_ELEMENT.style.bottom = "0%";
        BEFORE_ELEMENT.style.left = "50%";
        BEFORE_ELEMENT.style.minHeight = "254px";

        AFTER_ELEMENT.style.width = "2px";
        AFTER_ELEMENT.style.top = "0%";
        AFTER_ELEMENT.style.minHeight = "254px";

        FIRST_ROW.style.top = "-2.04rem";
        FIRST_ROW.style.left = "calc(50% - 23px)";
        FIRST_ROW.style.transform = "rotate(-90deg)";
      }
    }
  };

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.pageObject.componentType === 7)
        for (
          let sectionIndex = 0;
          sectionIndex < this.pageObject.content.sections.length;
          sectionIndex++
        ) {
          const sections = {
            sectionIndex,
            prev: this.pageObject.content.sections[sectionIndex - 1],
            current: this.pageObject.content.sections[sectionIndex],
            next: this.pageObject.content.sections[sectionIndex + 1],
            postNext: this.pageObject.content.sections[sectionIndex + 2]
          }

          if (sections.current.length === 2) {
            let towRowElementHeight = 0;
            for (
              let subSectionIndex = 0;
              subSectionIndex < sections.current.length;
              subSectionIndex++
            ) {
              /* Getting 2 row section */
              const SUBROW_SECTION = document.getElementById(`${this.snapshotUid}-subrow-${sections.current[subSectionIndex].title.replaceAll(' ', '-')}-${subSectionIndex}`);
              this.handleSectionLines(sections, SUBROW_SECTION, false, subSectionIndex);

              towRowElementHeight = (SUBROW_SECTION.getBoundingClientRect().height > towRowElementHeight) ? SUBROW_SECTION.getBoundingClientRect().height : towRowElementHeight;

              /* Getting height and with from section to set padding */
              document.getElementById(`${this.snapshotUid}-section-container-${sections.current[0].title.replaceAll(' ', '-')}`)
                .style.paddingTop = (towRowElementHeight / 2) - 48 + "px";
              document.getElementById(`${this.snapshotUid}-section-container-${sections.current[0].title.replaceAll(' ', '-')}`)
                .style.paddingBottom = (towRowElementHeight / 2) - 48 + "px";

              /* Next section 1 row elements 2:1 */
              if (sections.next) {
                if (sections.next.length === 1) {
                  const NEXT_ONE_SECTION = document.getElementById(`${this.snapshotUid}-row-${sections.next[0].title.replaceAll(' ', '-')}`);

                  if (subSectionIndex === 0) /* Move current section based on the next section 1 row elements */
                    SUBROW_SECTION.style.right = "calc(50% + " + (NEXT_ONE_SECTION.getBoundingClientRect().width / 2) + "px)";
                  else
                    SUBROW_SECTION.style.left = "calc(50% + " + (NEXT_ONE_SECTION.getBoundingClientRect().width / 2) + "px)";
                } else if (sections.next.length === 2) {
                  /* Elements 2:2 */
                }
              }
            }
          } else { /* Rows with 1 element */
            const SECTION = document.getElementById(`${this.snapshotUid}-row-${sections.current[0].title.replaceAll(' ', '-')}`);
            this.handleSectionLines(sections, SECTION, false);
          }
        }

        delay_ms(400, this).then((instance: SnapshotComponents) => {
          instance.allReady.emit(true);
        })

    }, 1000)
  };

  afterGraphRedraw(){
    console.log('afterRedraw 2');
    this.onGraphRedraw.emit(true);
  }

  getPrintableUrl(url: string): string{
    return url.replace('.png', '_printable.png');
  }
};
