import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatStepper} from '@angular/material/stepper';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { Area } from '../../../shared/helpers/areas.catalog';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss'],
})
export class ProspectComponent implements OnInit {

  public isLoading: boolean = true;
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;

  public leadURL: string;
  public advisorData;
  public location: any;
  public isProduction: boolean;
  prospectingToolForm: FormGroup;
  public readProspectingPreferences;
  public advisorInfo: any;
  public leadPageCustomQuestions: string[] = [];
  public areasFromCatalogToDisplay;
  public leadPageAreas: string[] = [];
  public areasInDisplayFormat: any[];
  public questionCount: number = 0;
  public imageURL: string = '';
  public imageBG: File | string;
  public isImageUploading: boolean = false;
  public disclaimersList: any = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarError: MatSnackBar,
    private settingsServive: ESettingsService,
    public snackBarWarning: MatSnackBar,
    public snackBarSuccess: MatSnackBar,) { }

  ngOnInit() {
    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;

    //Read Prospecting Preferences from session storage (RT)
    this.readProspectingPreferences = JSON.parse(localStorage.getItem('prospectingPreferences'));
    

    //Advisor account info
    this.advisorInfo = JSON.parse(localStorage.getItem('advisorInfo'));

    for (let area of ['estate', 'fulltax', 'ltc', 'disability', 'life', 'home', 'auto', 'investment']) {
      this.leadPageAreas.push(area);
    }
    this.areasFromCatalogToDisplay = Area.getAreasByIds(this.leadPageAreas);
    this.areasInDisplayFormat = Area.getAreasInDisplayArrayFormatFixedColumn(this.areasFromCatalogToDisplay, 3, false);

    let autoGenerateFormControlsByArea = this.areasFromCatalogToDisplay.map(area => {
      return { [area.id]: new FormControl(this.readProspectingPreferences.leadPageAreas[area.id]) };
    });

    // Custom questions controls
    let customQuestionsToDisplay = this.readProspectingPreferences.leadPageCustomQuestions;

    for (let question of Object.keys(customQuestionsToDisplay)) {
      this.leadPageCustomQuestions.push(customQuestionsToDisplay[question]);
    }

    this.questionCount = this.leadPageCustomQuestions.length;

    /*** Prospect Accelerator ***/
    this.prospectingToolForm = new FormGroup(
      Object.assign({
        leadAdvisorName: new FormControl(this.readProspectingPreferences.leadAdvisorName, Validators.required),
        leadAdvisorEmail: new FormControl(this.readProspectingPreferences.leadAdvisorEmail, [Validators.email, Validators.required]),
        leadAdvisorPhone: new FormControl(this.readProspectingPreferences.leadAdvisorPhone, Validators.required),
        leadAdvisorCompany: new FormControl({value: this.advisorInfo.company, disabled: true}),
        leadMeetingLink: new FormControl(this.readProspectingPreferences.leadMeetingLink),
        leadDisclaimer: new FormControl(this.readProspectingPreferences.leadDisclaimer),
        leadPageHeader: new FormControl(this.readProspectingPreferences.leadPageHeader),
        leadPageDescription: new FormControl(this.readProspectingPreferences.leadPageDescription),
        leadThanksPageHeader: new FormControl(this.readProspectingPreferences.leadThanksPageHeader),
        leadThanksPageDescription: new FormControl(this.readProspectingPreferences.leadThanksPageDescription),
        clientIDCustomQuestion1: new FormControl(this.leadPageCustomQuestions[0]),
        clientIDCustomQuestion2: new FormControl(this.leadPageCustomQuestions[1]),
        clientIDCustomQuestion3: new FormControl(this.leadPageCustomQuestions[2]),
        clientIDCustomQuestion4: new FormControl(this.leadPageCustomQuestions[3]),
        clientIDCustomQuestion5: new FormControl(this.leadPageCustomQuestions[4]),
        clientIDCustomQuestion6: new FormControl(this.leadPageCustomQuestions[5]),
        clientIDCustomQuestion7: new FormControl(this.leadPageCustomQuestions[6]),
        clientIDCustomQuestion8: new FormControl(this.leadPageCustomQuestions[7]),
        clientIDCustomQuestion9: new FormControl(this.leadPageCustomQuestions[8]),
        clientIDCustomQuestion10: new FormControl(this.leadPageCustomQuestions[9]),
      }, ...autoGenerateFormControlsByArea
      )
    );

    this.getAdvisorData();
    this.getCompanyData();
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.isLoading = false;
          this.advisorData = data;
          
          this.leadURL = this.advisorData.leadURL;
          this.imageBG = this.advisorData.leadPageBackground && 'uploaded';
          this.imageURL = this.advisorData.leadPageBackground ? data.leadPageBackground : '';
        },
        error => {
          console.error(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        });
  }

  getCompanyData(){
    this.settingsServive.getCompanyData().subscribe(data => {
      
      if(data.disclaimers.length > 0){
        data.disclaimers.forEach(element => {
          this.disclaimersList.push(element);
        })
      }

    }, error => {
      console.log('Error', error);
      this.isLoading = false;
    })
  }

  copyToClipboard() {
    let advisorURL = 'https://' + this.location + '/join?advisor=' + this.leadURL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = advisorURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBarSuccess.open("Link copied to the clipboard!", "Ok", {
      duration: 3000,
      panelClass: 'success-snackbar'
    });
  }

    //Add or remove custom questions in prospecting tool
    addQuestion(): void {
      if (this.questionCount < 11) {
        this.questionCount++;
      }
      //this.childrenCount++;
      
    }

    removeQuestion(e): void {
      if (this.questionCount > 0) {
        this.questionCount--;
        this.prospectingToolForm.controls['clientIDCustomQuestion' + e].reset();
      }
      //this.childrenCount--;
      
    }

    handleChangeDisclaimer(e){
      this.prospectingToolForm.patchValue({
        leadDisclaimer: e.value
      })
    }

    reload() {
      window.location.reload();
    }

    updateProspectingToolPreferences() {

      let saveAdvisorPreferences = this.prospectingToolForm.value;

      

      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      };

      let desiredAreas = {};
      this.leadPageAreas.forEach(area => {
        desiredAreas[area] = saveAdvisorPreferences[area];
      });

      let updatedPreferences = {
        leadAdvisorName: saveAdvisorPreferences.leadAdvisorName,
        leadAdvisorEmail: saveAdvisorPreferences.leadAdvisorEmail,
        leadAdvisorPhone: saveAdvisorPreferences.leadAdvisorPhone,
        leadAdvisorCompany: saveAdvisorPreferences.leadAdvisorCompany,
        leadMeetingLink: saveAdvisorPreferences.leadMeetingLink,
        leadDisclaimer: saveAdvisorPreferences.leadDisclaimer,
        leadPageHeader: saveAdvisorPreferences.leadPageHeader,
        leadPageDescription: saveAdvisorPreferences.leadPageDescription,
        leadThanksPageHeader: saveAdvisorPreferences.leadThanksPageHeader,
        leadThanksPageDescription: saveAdvisorPreferences.leadThanksPageDescription,
        clientIDCustomQuestion1: saveAdvisorPreferences.clientIDCustomQuestion1,
        clientIDCustomQuestion2: saveAdvisorPreferences.clientIDCustomQuestion2,
        clientIDCustomQuestion3: saveAdvisorPreferences.clientIDCustomQuestion3,
        clientIDCustomQuestion4: saveAdvisorPreferences.clientIDCustomQuestion4,
        clientIDCustomQuestion5: saveAdvisorPreferences.clientIDCustomQuestion5,
        clientIDCustomQuestion6: saveAdvisorPreferences.clientIDCustomQuestion6,
        clientIDCustomQuestion7: saveAdvisorPreferences.clientIDCustomQuestion7,
        clientIDCustomQuestion8: saveAdvisorPreferences.clientIDCustomQuestion8,
        clientIDCustomQuestion9: saveAdvisorPreferences.clientIDCustomQuestion9,
        clientIDCustomQuestion10: saveAdvisorPreferences.clientIDCustomQuestion10,
        leadPageAreas: desiredAreas
      }

      const formData = new FormData();
      formData.append('img', this.imageBG);

      

      if (this.imageBG !== 'uploaded' && this.imageBG !== '' && this.imageBG !== undefined && this.imageBG !== 'undefined') {

        this.isImageUploading = true;

        this.http.post<any>(`${environment.apiAdvisorProspectImage}`, formData)
          .subscribe(() => {
            this.isImageUploading = false;
            this.http.put<any>(`${environment.apiAdvisorAdvisorProspecting}`, updatedPreferences, options)
              .subscribe(() => {
                localStorage.setItem("prospectingPreferences", JSON.stringify(updatedPreferences));
                let snackBarRef = this.snackBarSuccess.open("Prospecting accelerator updated, the app will reload to apply changes", "Ok", {
                  duration: 4000,
                  panelClass: 'success-snackbar'
                });

                this.router.navigate(['/advisor/clients']);

                setTimeout(() => {
                  this.reload();
                }, 3000);

                snackBarRef.onAction().subscribe(() => this.reload());

              },
                error => {
                  console.log('Error!!!');
                  this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
                    duration: 9000,
                    panelClass: 'error-snackbar'
                  });
                }
              );
          },
            error => {
              console.error('Error!!!');
              this.snackBarError.open("Cannot update your preferences.", "Dismiss", {
                duration: 9000,
                panelClass: 'error-snackbar'
              });
            }
          );
      } else {
        this.http.put<any>(`${environment.apiAdvisorAdvisorProspecting}`, updatedPreferences, options)
          .subscribe(
            success => {
              
              
              localStorage.setItem("prospectingPreferences", JSON.stringify(updatedPreferences));
              let snackBarRef = this.snackBarSuccess.open("Prospecting accelerator updated, the app will reload to apply changes", "Ok", {
                duration: 10000,
                panelClass: 'success-snackbar'
              });

              this.router.navigate(['/advisor/clients']);

              setTimeout(() => {
                this.reload();
              }, 3000);

              snackBarRef.onAction().subscribe(() => {
                
                this.reload();
              });

            },
            error => {
              console.log('Error!!!');
              this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
                duration: 9000,
                panelClass: 'error-snackbar'
              });
            }
          );
      }

    }

}
