import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { FileUploaderOptions, UPLOADER_STATUS } from '../file-uploader-component/file-uploader-component.component';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import { getYearsArraySince } from '../HelperFunctions';
import { general_questions } from '../q-general/general.model';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_NOTSURE,
  CONST_HOME_AUTOCOMPANIES,
  CONST_HOME_BUILDINGS,
  CONST_HOME_INSURANCECOMPANIES,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { propStringValidator, uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { home_questions } from './home.model';
import { InsuranceServiceService } from '../insurance-hub/insurance-service.service';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
  selector: "app-q-home",
  templateUrl: "./q-home.component.html",
  styleUrls: ["./q-home.component.scss"]
})
export class QHomeComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild("homeform", { static: true }) homeform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: home_questions;
  public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientHasAnsweredQuestionnaireAuto: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public haveFilePDF: string;
  public uploader: FileUploaderExtended;
  public clientHasUmbrellaDocument: FileUploaderExtended;
  public hasBaseDropZoneOver: boolean = false;
  public whoAnswered: string;
  public answers: Array<string>[] = [];
  public CONSTANTS = { CONST_ABSOLUTES, CONST_HOME_INSURANCECOMPANIES, CONST_HOME_AUTOCOMPANIES, CONST_HOME_BUILDINGS, CONST_ABSOLUTES_NOTSURE };
  public validations = Validations;
  public fileUploaderArray: FileUploadersArrayHelper;
  public propName: string = '';
  public policyUploaderOptions: FileUploaderOptions;
  public umbrellaUploaderOptions: FileUploaderOptions;
  public extractionResults: any[] = [];
  public yearsList:  number[] = getYearsArraySince(2000).reverse();
  public isAdvisor: boolean = false;
  public uploaderStatus: any = UPLOADER_STATUS
  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  // public isFirstTimeHere: string;
  public generalData: general_questions = new general_questions();
  public bulletColor;
  public isManualInputEnabled: boolean = true;
  public questIndex: number;
  public toggleSearchBar: boolean = false;

  public dialogRefs: any[] = [];
  public hideTop: boolean = false;
  public hasInsuranceBetaAccess: boolean = true;
  public isReadyForSubmit_: boolean = false;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private insuranceHubService: InsuranceServiceService,
    private currentClientService: ClientService,
    @Inject(WINDOW) private window: Window
  ) { }

  private commonAutoSaveFunction = () =>{

    console.log("AUTO SAVE");

    // Autosave only if there is no data waiting

    if(this.isReadyForSubmit()){
      this.submit(true, true);
    }else{
      console.log("WARNING. Waiting for extractions. Autosave disabled");
    }

  }

  async getInsuranceCompanies() {
    const ans: any = await this.advisorService.getInsuranceProviders();
    console.log(ans);
    this.CONSTANTS.CONST_HOME_INSURANCECOMPANIES = ans.map(({ showName }) => showName);
  }

  ngOnInit() {
    this.getInsuranceCompanies();
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    //Initialize the questionnaire model
    this.model = new home_questions();
    const auxBetaInfo = JSON.parse(localStorage.getItem('betaAccess'));
    console.log(auxBetaInfo);
    /*
    if (auxBetaInfo.includes('insuranceSnapshot') || !urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION)) {
      this.hasInsuranceBetaAccess = true;
    } else {
      this.hasInsuranceBetaAccess = false;
    }*/

    console.log('ACCESO A BETA: ', this.hasInsuranceBetaAccess);
    //Load session storage data from questionnare
    let loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('home');
    let index = loadedStoragedQuestionnaireData.index != null ? loadedStoragedQuestionnaireData.index : 1;
    this.questIndex = index;
    this.propName = (loadedStoragedQuestionnaireData.name != null) ? loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = loadedStoragedQuestionnaireData.name;

    //Is the first time here? Show modal
    // this.isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));

    /*if (this.isFirstTimeHere === 'home') {
        this.dialog.open(DialogFirstTime, {
            panelClass: 'modal-dialog-questionnaire',
            height: '250px',
            width: '500px',
            data: {hasUploader: true}
        });
    }*/

    if(!loadedStoragedQuestionnaireData.answered){
      //Make a forced autosave to create a fresh questionnaire where the extractions can live.
      if(this.model) this.submit(true, true);
    }

    //Load General questionnaire info
    if(clientHasAnswered.ansGeneral){
      this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
        console.log('DATA: ', data);
        if (data?.personalInformation) {
          const answers = data?.personalInformation?.data;
          answers.forEach(({ name, answer }) => {
            this.generalData[name] = answer === 'undefined' || !answer ? '' : answer
          })
        }
        if (data?.family) {
          const assetsData = data?.family?.data;
          const realEstate = assetsData.real_estate;
          this.generalData.clientIDOutisdePrimaryResidence = realEstate.some(({ subtype }) => subtype === 'Investment Property') ? 'Yes' : 'No';
        }
      })
    }

    this.isAdvisor = this.authService.isAdvisor();

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientMaritalStatus = this.clientData.isMarried;

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputPolicyDataHome = 'No';

    //Load extraction results for file uploaders components
    this.qController.loadExtractionsResults(this.clientData.clientId, 'home', String(index)).then(result => {
      this.extractionResults = result;
      console.log(this.extractionResults);
    });

    //Initialze the File Uploader Objects
    /* this.fileUploaderArray = new FileUploadersArrayHelper([
      this.uploader = this.qController.fileUploaderFactory(this.window, this.clientData.clientId, '/fileuploadhome/clientIDHomePolicy', true, index),
      this.clientHasUmbrellaDocument = this.qController.fileUploaderFactory(this.window, this.clientData.clientId, '/fileuploadhome/clientIDClientHasUmbrellaDocument', true, index)
    ]);
    this.uploader.required = false; //By default is required, here is explict to remind that it could be false.
    this.clientHasUmbrellaDocument.required = false; */

    this.policyUploaderOptions = new FileUploaderOptions(
      /* 'clientIDHomePolicy',
      this.model.clientIDHomePolicy,
      this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'home', false, index, 'clientIDHomePolicy'),
      'policy' */
      {
        fileID: 'clientIDHomePolicy',
        fileVal: this.model.clientIDHomePolicy,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'home', false, index, 'clientIDHomePolicy'),
        documentReadableName: 'policy',
        actionsAfterResponse: {
          //validatedValid : ()=>{ this.openNoticeNeedsReview('clientIDHomePolicy') },
          validatedVoid  : ()=>{ this.openNoticeNeedsReview('clientIDHomePolicy') },
          //validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'home', index, 'clientIDHomePolicy', ()=>{this.model.clientIDWantsToInputPolicyDataHome = 'Yes'} )}
        }
      }
    );


    this.umbrellaUploaderOptions = new FileUploaderOptions(
      {
        fileID: 'clientIDClientHasUmbrellaDocument',
        fileVal:  this.model.clientIDClientHasUmbrellaDocument,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'home', false, index, 'clientIDClientHasUmbrellaDocument'),
        documentReadableName: 'umbrella document',
        validationRule: () => { if (this.umbrellaUploaderOptions.uploader.queue.length <= 0 || this.model.clientIDWantsToInputPolicyDataHome == 'Yes') return true; else return this.policyUploaderOptions.status == 'validatedValid' },
        actionsAfterResponse: {
          //validatedValid : ()=>{ this.openNoticeNeedsReview('clientIDClientHasUmbrellaDocument') },
          validatedVoid  : ()=>{ this.openNoticeNeedsReview('clientIDClientHasUmbrellaDocument') },
          //validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'home', index, 'clientIDClientHasUmbrellaDocument', ()=>{this.model.clientIDWantsToInputPolicyDataHome = 'Yes'} )}
        }
      }
    );



    //Get context, clientHasAnswered?
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansHome;
    this.clientHasAnsweredQuestionnaireAuto = clientHasAnswered.ansAuto;

    //Load questionnarie data, if clientHasAnswered
    if(loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'home', this.model, index).then(response => {

        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        //this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      //this.qController.initAutoSave2(commonAutoSaveFunction);
    }


    if (this.clientHasAnsweredQuestionnaireAuto === true) {
      //Get the data from auto quest if has been answered

      this.advisorService.getClientQuestionnaire(this.clientData.clientId, 'auto')
        .subscribe(
          data => {
            let rawAutoAnswers = data;
            for (let key of Object.keys(rawAutoAnswers)) {
              if(this.model.clientIDHomeInsuranceCompany === ''){
                this.model.clientIDHomeInsuranceCompany = rawAutoAnswers[key][1].answer;
              }
              this.model.clientIDAutoInsuranceCompany = rawAutoAnswers[key][2].answer;

            }
          },
          error => { console.log(error); }
        );
    }

    //Always load a new home, only for an empty quest
    if (this.clientHasAnsweredQuestionnaire == false && this.model.clientIDHomesOwned < 1) {
      this.onAddHome();
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    //this.model.clientIDHomesOwned = 1;
    const insuranceModel = this.insuranceHubService.getModel();
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
    if (insuranceModel) {
      this.model.clientIDClientHasHomeInsurance = 'Yes';
      this.model.clientIDWantsToInputPolicyDataHome === 'Yes';
      this.hideTop = true;
    }
    if (this.hasInsuranceBetaAccess) {
      this.getActivityFromHome();
    }
  }

  async getActivityFromHome() {
    const ans: any = await this.advisorService.getInsuranceActivity(this.clientData.clientId || localStorage.getItem('userId'), 'home');
    console.log('getActivityFromHome', ans);
    if (ans[0] != undefined && ans[0].data[`${this.questIndex}`]) {
      this.model.clientIDClientHasHomeInsurance = !!ans[0].data[`${this.questIndex}`].clientIDHomePolicy ? 'Yes' : 'No';
      this.model.clientIDHomeInsuranceCompany = ans[0].data[`${this.questIndex}`].clientIDHomeInsuranceCompany;
      this.model.clientIDWantsToInputPolicyDataHome = 'No';
      this.hideTop = true;
    }

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
    //console.log(this.policyUploaderOptions);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silenSave: boolean = false) {

    let hasManualInput = this.model.clientIDWantsToInputPolicyDataHome === 'Yes';

    validateAndSubmit(
      [
        propStringValidator(this, [{value: this.model.clientIDHomeInsuranceCompany, name: 'insurance company'}])
        //uploadersValidator(this, [this.policyUploaderOptions, this.umbrellaUploaderOptions])
      ],
      () => {
        this.qController.submitForm(this, this.model, this.clientData.clientId, 'home', saveForLater, null, silenSave, hasManualInput);
        //Set file uploader status to valid if the file is under review and manual opt input is enabledç
        let filesUnderReview =
          [this.policyUploaderOptions, this.umbrellaUploaderOptions]
          .filter(uploader => (Boolean(uploader) && uploader.status == UPLOADER_STATUS.FILE_UNDER_REVIEW));

        if(filesUnderReview.length > 0 && hasManualInput){
          filesUnderReview.map(uploader => {
            this.qController.advisorService.setTaxUploaderStatusToSubmitted(this.clientData.clientId, 'home', uploader.fileID, String(this.questIndex));
          });
        };
      },
      saveForLater
    )

  }

  onAddHome() {
    if (this.model.clientIDHomesOwned < 1) {
      this.model.clientIDHomesOwned++;
      //this.model.homes.push(new home());
      this.auxModel = {
        ...(this.auxModel ? { ...this.auxModel } : {}),
        ...this.model,
      };
    }
  }

  onSubstarctHome() {
    if (this.model.clientIDHomesOwned > 0) {
      this.model.clientIDHomesOwned--;
      //this.model.homes.pop();
    }
  }

  onAnyUploaderReset($event: string) {
    // Reset the policy selector to force the selection of one.
    // if ($event == 'clientIDHomePolicy') this.model.clientIDHomeInsuranceCompany = '';

    this.model[$event] = '';
    this.extractionResults[$event] = '';
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    this.commonAutoSaveFunction();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    this.commonAutoSaveFunction();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(Math.abs(tabChangeEvent.index) * this.progress);
	  }
    this.commonAutoSaveFunction();
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    console.log('focus change');
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  blockManualInput() {
    this.isManualInputEnabled = false;
  }

  onFileUploaderResponse() {



    if (this.policyUploaderOptions.status == UPLOADER_STATUS.FILE_VALID || this.umbrellaUploaderOptions.status == UPLOADER_STATUS.FILE_VALID) {
      //Load the extractions data

      this.qController.loadExtractionsDataToModel(this.model, home_questions,  this.clientData.clientId, 'home', this.questIndex);
      // If first time in the quest, display visual aid
      // if (this.isFirstTimeHere === 'home') this.qController.openModalWhatToDoNext(this);
    }

    let endOfInteractionStates  = [UPLOADER_STATUS.INITIAL, UPLOADER_STATUS.FILE_INVALID, UPLOADER_STATUS.FILE_VALID];

    // After both uploaders are on a valid end of interacion status, re-enable the manual input option
    if(endOfInteractionStates.includes(this.policyUploaderOptions.status) && endOfInteractionStates.includes(this.umbrellaUploaderOptions.status)){
      this.isManualInputEnabled = true;
    }

  }

  ngOnDestroy(){
    this.qController.deleteStoragedQuestionnareDatByArea('home');
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
      this.dialogRefs.map(dialogRef => {
        try{
          dialogRef.close();
        }catch(error){
          //Nothing
        }
      })
  }


  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

      this.http.get(`${this.pdfUrl}${'home'}/?options=${this.model.clientIDClientHasHomeInsurance}`, options)
      .subscribe(blob => {
        saveAs(blob, 'home-questionnaire.pdf');
      });
  }

  /**
   * @function isInsurancePolicyCompatible
   * @param insurnaceCompany
   * @returns boolean
   * @description Compares if the insurnace company is in the compatibility list
   */

  isInsurancePolicyCompatible(insurnaceCompany: string) : boolean{
      return ['Chubb'].includes(insurnaceCompany)
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

    //Solve bug. When uploader resets by manual/auto selection, verify if is a prevoius extractions result
    onManuallyInputPolicyChange(){
      if(this.model.clientIDWantsToInputPolicyDataHome == 'No'){
        //Load extraction results for file uploaders components

        this.qController.loadExtractionsResults(this.clientData.clientId, 'home', String(this.questIndex)).then(result => {
          this.extractionResults = result;
        });
      }
    }

    openNoticeNeedsReview(documentName: string){
       const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Notice',
          body:
          `<strong>Based on our initial reading of your document, our proprietary validation algorithm is raising a flag.<br>
          It may be the quality of the document, or inconsistencies in the numbers extracted</strong><br><br>
          Our team will make the extractions accessible in under one business hour (CT). A confirmation email
          will be sent to you when the process is complete.<br>
          If you are receiving this notice after business hours the extractions will be accessible by the morning of the next business day.
          <br><br>
          `,
          actionButtons: [
            {
              text: "Notify Me When Ready",
              class: 'button-primary',
              action: () => {
                dialogRef.close();
                this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'home', this.questIndex, documentName, 'manual').then(response => {

                  this.snackBarSuccess.open("We have received the return and the data will be available shortly..", "OK", {
                    duration: 3000,
                    panelClass: 'success-snackbar'
                  });
                  //Redirect, save for later
                  this.submit(true);
              });

              }
            }
          ]
        }
      });
      this.dialogRefs.push(dialogRef);
    }


    isReadyForSubmit(): boolean {
      let isReady: boolean = (
        this.policyUploaderOptions?.status === UPLOADER_STATUS.FILE_VALID ||
        (this.extractionResults['clientIDHomePolicy'] != undefined && (this.extractionResults['clientIDHomePolicy'] === 'INITIAL' || this.extractionResults['clientIDHomePolicy'] === 'EMPTY'))
      );

      return isReady

    }

}
