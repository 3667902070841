import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { HttpClient} from '@angular/common/http';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { environment } from '../../../../environments/environment';
import { WINDOW } from "../../../shared/helpers/window.provider";

import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

import { AddClientDialogData } from '../clients.interfaces';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'bulk-client-dialog',
  templateUrl: 'bulk-client-dialog.html',
})

export class BulkClientDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  addClientForm: FormGroup;

  private batchUpload = environment.apiBatchUpload;
  public uploader: FileUploader;
  private uploadUrl: string;
  public hasBaseDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<BulkClientDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(WINDOW) private window: Window,
    @Inject(MAT_DIALOG_DATA) public data: AddClientDialogData,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
     }

  ngOnInit() {

    let sessionToken = localStorage.getItem("sessionToken");

    this.uploadUrl = `${this.batchUpload}`;

    this.uploadUrl = this.advisorService.dynamicIPAddress(
      this.window,
      this.uploadUrl
    );

    this.uploader = new FileUploader({
      url: this.uploadUrl,
      authTokenHeader: "Authorization",
      authToken: `Bearer ${sessionToken}`,
      itemAlias: "clientIDFiles"
    });

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      

      if (status === 400) {
        this.snackBarError.open("Some clients are already registered, check the list please", "Ok", {
          duration: 3000,
          verticalPosition: "top",
          panelClass: "error-snackbar"
        });
      } else {
        this.snackBarSuccess.open("Clients added successfully", "Ok", {
          duration: 3000,
          verticalPosition: "top",
          panelClass: "success-snackbar"
        });
      }

    };

  }

  addBulkClients() {

    if (this.uploader.queue.length == 0) {
      
      this.snackBarError.open("Please upload a file to continue.", "Ok", {
        duration: 3000,
        verticalPosition: "top",
        panelClass: "error-snackbar"
      });
    } else {
      this.dialogRef.close();
      this.uploader.uploadAll();
      this.uploader.onErrorItem = (item, response, status, headers) =>
        this.onErrorItem(item, response, status, headers);
      this.uploader.onSuccessItem = (item, response, status, headers) =>
        this.onSuccessItem(item, response, status, headers);
    }
  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

  onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let output = JSON.parse(response);
    
  }

  onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let error = JSON.parse(response);
    console.log(error);
  }

}
