import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatButton} from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AddNewClientDialog } from 'app/views/clients/add-new-client/add-new-client-dialog.component';
import { environment } from 'environments/environment';
import { CustomValidators } from 'ng2-validation';

import { Area, PROSPECT_AREAS } from '../../../../shared/helpers/areas.catalog';

//Angular
//3rd Party
//Project
@Component({
  selector: 'app-create-external-prospect',
  templateUrl: './create-external-prospect.component.html',
  styleUrls: ['./create-external-prospect.component.scss'],
})
export class CreateExternalProspectComponent implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  addClientForm: FormGroup;

  public mailExistsAlready: boolean;
  public prospectAreaNames: string[] = PROSPECT_AREAS.map(area => { return area.id });
  public areasToDisplay: Area[] = [];
  public areasInDisplayFormat: any[] = [];
  public temporalPassword: string;
  public isChecked: boolean = false;
  private validateClientEmail = environment.apiValidateEmail;
  public advisorData: any = null;

  public readProspectingPreferences;
  public areasFromCatalogToDisplay;
  public leadPageAreas : string[] = [];
  public hasCustomQuestions : boolean;


  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {

    // 1. Validate prospect join id and retrieve the advisor data.

    let prospectJoinId = this.route.snapshot.queryParamMap.get('advisor');
    let validateJoinIdUrl = environment.prospectValidate + `?advisor=${prospectJoinId}`;
    this.http.get(validateJoinIdUrl).toPromise().then(response => {
      this.advisorData = response;
      localStorage.setItem('advisorResponse', JSON.stringify(response));
      

      // Continue with the component init.
      this.readProspectingPreferences = JSON.parse(localStorage.getItem('advisorResponse'));
      

     Object.keys(this.readProspectingPreferences.customQuestions).length === 0 ? this.hasCustomQuestions = false : this.hasCustomQuestions = true;

      for (let area of Object.keys(this.readProspectingPreferences.leadPageAreas)) {
        if(this.readProspectingPreferences.leadPageAreas[area] === true) {
          this.leadPageAreas.push(area);
        }
      }

      this.areasFromCatalogToDisplay = Area.getAreasByIds(this.leadPageAreas);
      this.areasInDisplayFormat = Area.getAreasInDisplayArrayFormatFixedColumn(this.areasFromCatalogToDisplay, 3, false);

      const email = new FormControl('', [Validators.required, Validators.email]);
      const confirmEmail = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);

      let autoGeneratedFormControlsByArea = this.areasFromCatalogToDisplay.map(area => {
        return { [area.id]: new FormControl(false) };
      });

      this.addClientForm = new FormGroup(
        // Concatenate objects into one with Object.assign and pass it to FormGroup instanciation.
        Object.assign({ //First object
          clientIDFirstName: new FormControl('', Validators.required),
          clientIDLastName: new FormControl('', Validators.required),
          email: email,
          confirmEmail: confirmEmail,
          isProspect: new FormControl(false),
          checkboxmail: new FormControl(false),
          checkboxallareas: new FormControl(false)
        }, // Multiple objects, one for each area in AREAS
          ...autoGeneratedFormControlsByArea
        )
      );
    }, error => {
      // 1.2 If isn't valid, redirect to the "No valid screen"
      this.router.navigate(['prospect/alert']);
    })

    this.temporalPassword = AddNewClientDialog.generateTempPassword();

  }

  selectAllAreas(event) {
    this.isChecked = event.checked;
    this.areasFromCatalogToDisplay.forEach(area => {
      this.addClientForm.controls[area.id].setValue(this.isChecked);
    });
  }


  // 2. Create the new client.

  save(){

    

    const addClientData = this.addClientForm.value;

    let newClientData = {
      email: addClientData.email,
      clientIDFirstName: addClientData.clientIDFirstName,
      clientIDLastName: addClientData.clientIDLastName,
      isProspect: addClientData.isProspect,
      checkboxmail: addClientData.checkboxmail,
      password: this.temporalPassword,
      ma: this.advisorData.ma
    }

    let areasRequired:string[] = [];
    // Autocomplete values of areas.
    this.areasFromCatalogToDisplay.forEach(area => {
      newClientData[area.id] = addClientData[area.id];

      if (addClientData[area.id]) {
        areasRequired.push(area.id);
      }

    });

    
    
    sessionStorage.setItem('areasToAnalyze', JSON.stringify(areasRequired));

     // 3. If success at create, continue to external prospect flow.

   AddNewClientDialog.createNewClient(this.http, newClientData, true, environment.prospectLead).then(
     response => {
       
       localStorage.setItem('prospectResponse', JSON.stringify(response));
       this.router.navigate(['prospect/form'], {queryParams: { cli: response.idHash }});
     },
     error => {
       this.snackBarError.open("Something went wrong! " + error.error.message, "Dismiss", {
         duration: 9000,
         panelClass: 'error-snackbar'
       });
     }
   )
  }

}
