import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { environment } from '../../../../environments/environment';

import { CustomValidators } from 'ng2-validation';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AddClientDialogData } from '../clients.interfaces';

import { CONST_ABSOLUTES, CONST_GENDERS, CONST_MARRIAGESTATUS, CONST_STATES, CONST_AUTOCOMPANIES, CONST_HOMEINSURANCECOMPANIES } from '../../questionnaires/Questionnaire.constants';
import { Questionnaire } from '../../questionnaires/Questionnaire.model';
import { getYearsArraySince } from '../../questionnaires/HelperFunctions';
import { DataTransformation } from '../../questionnaires/DataTransformation.class';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'add-new-prospect-dialog',
  templateUrl: 'add-new-prospect-dialog.html',
})

export class AddNewProspectDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  addClientForm: FormGroup;

  private createNewClient = environment.apiCreateClient;
  public advisorData: any;
  public CONSTANTS = { CONST_ABSOLUTES, CONST_GENDERS, CONST_MARRIAGESTATUS, CONST_STATES, CONST_AUTOCOMPANIES, CONST_HOMEINSURANCECOMPANIES };
  public areasFromCatalogToDisplay = Area.getAreasByIds(['estate', 'fulltax', 'auto', 'home', 'ltc', 'life']); //When they are ready we will add 'em:'divorce', 'disability'
  public areasInDisplayFormat: any[] = Area.getAreasInDisplayArrayFormatFixedColumn(this.areasFromCatalogToDisplay);
  public yearsList:  number[] = getYearsArraySince(2000).reverse();


  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<AddNewProspectDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: AddClientDialogData,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
     }


     async getInsuranceCompanies() {
      const ans: any = await this.advisorService.getInsuranceProviders(); 
      console.log(ans);
      this.CONSTANTS.CONST_HOMEINSURANCECOMPANIES = ans.map(({ showName }) => showName);
      this.CONSTANTS.CONST_AUTOCOMPANIES = ans.map(({ showName }) => showName);
    }
  ngOnInit() {
    this.getInsuranceCompanies();
    const email = new FormControl('', [Validators.required, Validators.email]);
    const confirmEmail = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);

    let autoGeneratedFormControlsByArea = this.areasFromCatalogToDisplay.map(area => {
      return { [area.id]: new FormControl(false) };
    });

    this.addClientForm = new FormGroup(
      // Concatenate objects into one with Object.assign and pass it to FormGroup instanciation.
      Object.assign({ //First object
        clientIDFirstName: new FormControl('', Validators.required),
        clientIDLastName: new FormControl('', Validators.required),
        email: email,
        confirmEmail: confirmEmail,
        isProspect: new FormControl(true),
        //prospect part ii
        clientIDGender: new FormControl(false),
        clientIDAge: new FormControl(),
        clientIDLivingState: new FormControl(false),
        clientIDOccupation: new FormControl(false),
        clientIDNetWorth: new FormControl(),
        clientIDHasABusiness: new FormControl(false),
        clientIDMaritalStatus: new FormControl(false),
        clientIDChildren: new FormControl(false),
        //Estate
        clientIDHasOneorMoreLifeInsurancePolicies: new FormControl(false),
        clientIDPoliciesOwnedByInsured: new FormControl(false),
        clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction: new FormControl(false),
        clientIDThereIsABlendedFamily : new FormControl(false),
        clientIDMinorChild: new FormControl(false),
        clientIDMultipleChild: new FormControl(false),
        //Tax
        clientIDAnyChildrenUnder13: new FormControl(false),
        clientIDChildrenAttendingCollege: new FormControl(false),
        clientIDHasASmallBusiness: new FormControl(false),
        clientIDClientIsEmployed: new FormControl(false),
        clientIDWishesToMakeLoanForResidence: new FormControl(false),
        //Auto
        clientIDAutoInsuranceCompany: new FormControl(),
        clientIDAutoPolicyLastShopped: new FormControl(),
        clientIDLowMileage: new FormControl(),
        //Home
        clientIDHomeValue: new FormControl(),
        clientIDHouseorApartment: new FormControl(false),
        clientIDOwnsHouse: new FormControl(false),
        clientIDHomeInsuranceCompany: new FormControl(),
        clientIDAwareofDiscountsAndCredits: new FormControl(false),
        //LTC
        clientIDExistingPermanentLifeInsuranceOrAnnuity: new FormControl(false),
        //Divorce
        clientIDDivorceStatus: new FormControl(false),
        clientIDHasPrenup: new FormControl(false),
        clientIDIsReceivingOrPayingSpousalSupport: new FormControl(false),
        clientIDHasLifeInsurance: new FormControl(false),
        clientIDOwnsHouseDivorce: new FormControl(false),
        clientIDEstatePlanComplete: new FormControl(false),
        //Disability
        clientIDHasLifeInsuranceDisability: new FormControl(false),
        clientIDLivingExpenses: new FormControl(),
        clientIDEmergencyFundSavings: new FormControl(),
        clientIDOwnsOrRents: new FormControl(false),

      }, // Multiple objects, one for each area in AREAS
        ...autoGeneratedFormControlsByArea
      )
    )

    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorData = data;
          
        },
        error => {
          console.log(error);
        });
  }

  save() {
    this.dialogRef.close(this.addClientForm.value);
    const addClientData = this.addClientForm.value;
    

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('email', addClientData.email);
    body.set('clientIDFirstName', addClientData.clientIDFirstName);
    body.set('clientIDLastName', addClientData.clientIDLastName);
    body.set('isProspect', addClientData.isProspect);

    // Autocomplete values of areas.
    Area.getActiveAreas().forEach(area => {
      body.set(area.id, addClientData[area.id]);
    })

    

    this.http.post<any>(`${environment.apiCreateProspectIandII}`, body.toString(), options)
      .subscribe(
        success => {
          
          savePartII(success.idClient).subscribe(successResponse => {
            
          })
        },
        error => {
          console.log('Error!!!', error);
          this.snackBarError.open("Something went wrong", "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );

    let savePartII = (newClientId) => {

      let prospect = new Prospect();
      prospect.clientIDGender = addClientData.clientIDGender;
      prospect.clientIDAge = addClientData.clientIDAge;
      prospect.clientIDLivingState = addClientData.clientIDLivingState;
      prospect.clientIDOccupation = addClientData.clientIDOccupation;
      prospect.clientIDNetWorth = addClientData.clientIDNetWorth;
      prospect.clientIDHasABusiness = addClientData.clientIDHasABusiness;
      prospect.clientIDMaritalStatus = addClientData.clientIDMaritalStatus;
      prospect.clientIDChildren = addClientData.clientIDChildren;
      //Estate
      prospect.clientIDHasOneorMoreLifeInsurancePolicies  = addClientData.clientIDHasOneorMoreLifeInsurancePolicies;
      prospect.clientIDPoliciesOwnedByInsured  = addClientData.clientIDPoliciesOwnedByInsured;
      prospect.clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction  = addClientData.clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction;
      prospect.clientIDThereIsABlendedFamily  = addClientData.clientIDThereIsABlendedFamily;
      prospect.clientIDMinorChild = addClientData.clientIDMinorChild;
      prospect.clientIDMultipleChild = addClientData.clientIDMultipleChild;
      //Tax
      prospect.clientIDAnyChildrenUnder13 = addClientData.clientIDAnyChildrenUnder13;
      prospect.clientIDChildrenAttendingCollege = addClientData.clientIDChildrenAttendingCollege;
      prospect.clientIDHasASmallBusiness = addClientData.clientIDHasASmallBusiness;
      prospect.clientIDClientIsEmployed = addClientData.clientIDClientIsEmployed;
      prospect.clientIDWishesToMakeLoanForResidence = addClientData.clientIDWishesToMakeLoanForResidence;
      //Auto
      prospect.clientIDAutoInsuranceCompany = addClientData.clientIDAutoInsuranceCompany;
      prospect.clientIDAutoPolicyLastShopped = addClientData.clientIDAutoPolicyLastShopped;
      prospect.clientIDLowMileage = addClientData.clientIDLowMileage;
      //Home
      prospect.clientIDHomeValue = addClientData.clientIDHomeValue;
      prospect.clientIDHouseorApartment = addClientData.clientIDHouseorApartment;
      prospect.clientIDOwnsHouse = addClientData.clientIDOwnsHouse;
      prospect.clientIDHomeInsuranceCompany = addClientData.clientIDHomeInsuranceCompany;
      prospect.clientIDAwareofDiscountsAndCredits = addClientData.clientIDAwareofDiscountsAndCredits;
      //LTC
      prospect.clientIDExistingPermanentLifeInsuranceOrAnnuity = addClientData.clientIDExistingPermanentLifeInsuranceOrAnnuity;
      //Divorce
      prospect.clientIDDivorceStatus = addClientData.clientIDDivorceStatus;
      prospect.clientIDHasPrenup = addClientData.clientIDHasPrenup;
      prospect.clientIDIsReceivingOrPayingSpousalSupport = addClientData.clientIDIsReceivingOrPayingSpousalSupport;
      prospect.clientIDHasLifeInsurance = addClientData.clientIDHasLifeInsurance;
      prospect.clientIDOwnsHouseDivorce = addClientData.clientIDOwnsHouseDivorce;
      prospect.clientIDEstatePlanComplete = addClientData.clientIDEstatePlanComplete;
      //Disability
      prospect.clientIDHasLifeInsuranceDisability = addClientData.clientIDHasLifeInsuranceDisability;
      prospect.clientIDLivingExpenses = addClientData.clientIDLivingExpenses;
      prospect.clientIDEmergencyFundSavings = addClientData.clientIDEmergencyFundSavings;
      prospect.clientIDOwnsOrRents = addClientData.clientIDOwnsOrRents;

      prospect.clientIDRecsEstatePlanning = this.addClientForm.value.estate ? 'Yes' : 'No';
      prospect.clientIDRecsTaxPlanning = this.addClientForm.value.fulltax ? 'Yes' : 'No';
      prospect.clientIDRecsHomeInsurance = this.addClientForm.value.home ? 'Yes' : 'No';
      prospect.clientIDRecsAutoInsurance = this.addClientForm.value.auto ? 'Yes' : 'No';
      prospect.clientIDRecsDisabilityInsurance = this.addClientForm.value.disability ? 'Yes' : 'No';
      prospect.clientIDRecsLTCInsurance = this.addClientForm.value.ltc ? 'Yes' : 'No';
      prospect.clientIDRecsDivorcePlanning = this.addClientForm.value.divorce ? 'Yes' : 'No';
      prospect.clientIDRecsDisabilityInsurance = this.addClientForm.value.disability ? 'Yes' : 'No';
      prospect.clientIDRecsInvestment = this.addClientForm.value.investment ? 'Yes' : 'No';

      

      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      };

      return this.http.post<any>(`${environment.apiCreateProspectIII}\\${newClientId}`, prospect.toSaveFormat(), options);
    }
  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}

class Prospect extends Questionnaire {

  getQName(): string {
    return "";
  }

  clientIDGender: string = "";
  clientIDAge: number = 0;
  clientIDLivingState: string = "";
  clientIDOccupation: string = "";
  clientIDNetWorth: number = 0;
  clientIDHasABusiness: string = "";
  clientIDMaritalStatus: string = "";
  clientIDChildren: string = "";

  clientIDRecsEstatePlanning : string = "";
  clientIDRecsTaxPlanning : string = "";
  clientIDRecsHomeInsurance : string = "";
  clientIDRecsAutoInsurance : string = "";
  clientIDRecsDisabilityInsurance : string = "";
  clientIDRecsLTCInsurance : string = "";
  clientIDRecsDivorcePlanning : string = "";

  //Estate
  clientIDHasOneorMoreLifeInsurancePolicies: string = "";
  clientIDPoliciesOwnedByInsured : string = "";
  clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction: string = "";
  clientIDThereIsABlendedFamily : string = "";
  clientIDMinorChild: string = "";
  clientIDMultipleChild: string = "";

  //Tax
  clientIDAnyChildrenUnder13: string = "";
  clientIDChildrenAttendingCollege: string = "";
  clientIDHasASmallBusiness: string = "";
  clientIDClientIsEmployed: string = "";
  clientIDWishesToMakeLoanForResidence: string = "";

  //Auto
  clientIDAutoInsuranceCompany: string = '';
  clientIDAutoPolicyLastShopped: string = '';
  clientIDLowMileage: number = 0;

  //Home
  clientIDHomeValue: number = 0;
  clientIDHouseorApartment: string = '';
  clientIDOwnsHouse: string = '';
  clientIDHomeInsuranceCompany: string = '';
  clientIDAwareofDiscountsAndCredits: string = '';

  //LTC
  clientIDExistingPermanentLifeInsuranceOrAnnuity: string = '';

  //Divorce
  clientIDDivorceStatus: string = '';
  clientIDHasPrenup: string = '';
  clientIDIsReceivingOrPayingSpousalSupport: string = '';
  clientIDHasLifeInsurance: string = '';
  clientIDOwnsHouseDivorce: string = '';
  clientIDEstatePlanComplete: string = '';

  //Disability
  clientIDHasLifeInsuranceDisability: string = '';
  clientIDLivingExpenses: number = 0;
  clientIDEmergencyFundSavings: number = 0;
  clientIDOwnsOrRents: string = '';

  //Investment
  clientIDRecsInvestment: string = '';

  getAttributesToClearCommas(): string[] {
    return [
       'clientIDNetWorth',
       'clientIDHomeValue',
       'clientIDLowMileage',
       'clientIDLivingExpenses',
       'clientIDEmergencyFundSavings'
       ];
 }

 getDataTransformations(): DataTransformation[] {
    return [
       // Transform any negative net worth to positive
       new DataTransformation(
          [
             'clientIDNetWorth',
             'clientIDAge'
          ],
          (data: any) => { return Math.abs(data); }
       )
    ]
 }


}
