import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { getEndPointUrlByContext } from '../helpers/url.helper';

@Injectable({
  providedIn: 'root'
})
export class EstateLabV2Service {

  private idcli = JSON.parse(sessionStorage.getItem('currentClient'))?.clientId;
  public onBoxButtonClickEvent: EventEmitter<{source?: string, loadResourceOfType: string}> = new EventEmitter();

  constructor(
    private httpClient: HttpClient
  ) {
    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    this.idcli = storagedClientID.clientId;
  }

  async initEstateLab() {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      }
    }).toPromise();
  }

  /* getFactsDataForOverview(target) {
    console.log('TARGET: ', target);
    const exclude = ['charities', 'liabilities', 'life_insurance', 'beneficiaries'];
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/facts/`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target: !exclude.includes(target) ? 'assets' : target,
        ...(target !== 'charities' && target !== 'liabilities' && target !== 'life_insurance' &&  target !== 'beneficiaries' ? { key: target } : {}),
        itemsPerPage: 'all',
        page: '1',
        sort: 'name,asc',
        withPagination: 'false',
      }
    }).toPromise();
  } */


  /* getFatcsDataFromSection(target, itemsPerPage, page, sort, withPagination) {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/facts/`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target,
        itemsPerPage,
        page,
        sort,
        withPagination,
      }
    }).toPromise();
  } */

  getStrategiesByType(target, scenario = null, status = null) {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/strategies/`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target,
        ...(status ? { status } : {}),
        scenario
      }
    }).toPromise();
  }


  getStrategybyId(target, id, withFields: any = true,) {
    return this.httpClient.get(`${environment.apiEstateLab_2}/strategies/${target}`, {
      params: {
        ...(id ? { targetId: id } : {}),
        withFields,
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      }
    }).toPromise();
  }

  /* getFactsData() {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/facts/`).toPromise();
  } */

  /* getFactsItemWithFields(key, withFields, target, targetId = null, isAsset = false) {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/facts/${!isAsset ? target : 'assets'}`, {
      params: {
        withFields,
        ...(targetId ? { targetId } : {}),
        ...(isAsset ? { key } : {}),
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      },
    }).toPromise();
  } */

  /* verifyAsset(target, targetId = null, isAsset = false) {
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/facts/verify-dependency`, {
      params: {
        ...(isAsset ? { target: 'assets' } : { target }),
        ...(targetId ? { targetId } : {}),
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      },
    }).toPromise();
  }
 */
  /* postAsset(body, target, targetId = null) {
    const keysForAsset = {
      personal_property: 'assets',
      real_estate: 'assets',
      traditional_ira: 'assets',
      roth_ira: 'assets',
      '401k_plan': 'assets',
      '457_plan': 'assets',
      brockerage_account: 'assets',
      '529_plan': 'assets',
    };
    return this.httpClient.put<any>(`${environment?.apiEstateLab_2}/facts/`, body, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target: keysForAsset[target] || target,
        ...(keysForAsset[target] ? { key: target } : {}),
      },
    }).toPromise();
  } */

  /* deleteAsset(target, targetId = null) {
    const keysForAsset = {
      personal_property: 'assets',
      real_estate: 'assets',
      traditional_ira: 'assets',
      roth_ira: 'assets',
      '401k_plan': 'assets',
      '457_plan': 'assets',
      brockerage_account: 'assets',
      '529_plan': 'assets',
    };
    return this.httpClient.delete<any>(`${environment?.apiEstateLab_2}/facts/`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target: keysForAsset[target] || target,
        targetId,
      },
    }).toPromise();
  } */

  deleteStrategy(target, targetId = null) {
    const keysForAsset = {
      contract: 'contracts',
      will: 'wills',
      gift: 'gifts',
      trust: 'trusts'
    };
    return this.httpClient.delete<any>(`${environment?.apiEstateLab_2}/strategies/${keysForAsset[target]}/${targetId}`, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      }
    }).toPromise();
  }

  postStrategy(body, target, targetId = null) {
    const keysForAsset = {
      contract: 'contracts',
      will: 'wills',
      gift: 'gifts',
    };
    return this.httpClient.put<any>(`${environment?.apiEstateLab_2}/strategies/`, body, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
        target: keysForAsset[target] || target,
        ...(targetId ? { targetId } : {}),
      },
    }).toPromise();
  }

  toggleStrategy(scenario, target, targetId = null) {
    const keysForAsset = {
      contract: 'contracts',
      will: 'wills',
      gift: 'gifts',
      trust: 'trusts',
    };
    const body = {
      target: keysForAsset[target],
      scenario,
      targetId,
    }
    console.log('Target; ', target);
    return this.httpClient.post<any>(`${environment?.apiEstateLab_2}/strategies/toggle`, body, {
      params: {
        idcli: JSON.parse(sessionStorage.getItem('currentClient'))?.clientId,
      },
    }).toPromise();
  }

  /**
   * @function getTrustAssets
   *
   * Get global assets and beneficiaries for a given resource.
   */

  getTrustAssets(clientID: string, resourceType: ('wills'|'trusts'|'contracts'), resourceID: string = 'empty', documentType: ('revocable' | 'irrevocable') = 'revocable'){
    let documentTypeSegment = (resourceType === 'trusts') ? `&documentType=${documentType}` : '';
    return this.httpClient.get<any>(`${environment.apiEstateLab_2}/strategies/${resourceType}?idcli=${clientID}&targetId=${resourceID}&withFields=true${documentTypeSegment}`).toPromise();
  }



  updateTrust(clientID: string, resourceID: string, body){
    return this.httpClient.put(
      `${environment.apiEstateLab_2}/strategies?idcli=${clientID}&target=trusts&targetId=${resourceID}`,
      body)
      .toPromise();
  }



}
