import { GraphConnectionNode } from './graph-tool.component';

export class Bfs{

  private agenda: AgendaElement[] = [];
  private expandedNodes: AgendaElement[] = [];
  public solution: Node[] = [];
  private graph: Node[];
  private destDirections:({x: string, y: string});
  private originNodeId: string;
  private destNodeId: string;

  public constructor(originNodeId, destNodeId, graph: Node[]){

    this.graph = graph;
    this.originNodeId = originNodeId;
    this.destNodeId = destNodeId;
    this.destDirections = this.calculateDestDirections();

    let initalNode = this.graph.find(node => node.id == originNodeId);

    this.agenda.push({node: initalNode, from: null});

    while(this.agenda.length){

      // 1. Take element from agenda
      let agendaElement: AgendaElement = this.agenda.shift();

      //
      if(this.expandedNodes.find(n => {return n.node.id == agendaElement.node.id})){
        continue;
      }

      // 2. Add element to the list of expanded.
      this.expandedNodes.push(agendaElement);

      // 3. Expand element
      let exploringNodeVertices = this.expand(agendaElement.node);

      // 4. Any of the verices is the destination node
      if(exploringNodeVertices.find(vertice => {return vertice.node.id == this.destNodeId}) || agendaElement.node.id == this.destNodeId){
        this.solution = this.retrieveRouteFrom(agendaElement.node);
        break;
      }

      // 5. Add to agenda the expanded elements that are not inside the list of expanded.


      let uniqueNodesToAgend = exploringNodeVertices.filter(vertice => {return this.expandedNodes.find(expNode => {return expNode.node.id == vertice.node.id}) == undefined});

      this.agenda.push( ... uniqueNodesToAgend);

    }
  }

  private calculateDestDirections(){
    let originNode = this.graph.find(node => node.id == this.originNodeId);
    let destNode = this.graph.find(node => node.id == this.destNodeId);

    let xDirection: string = (destNode.coordinates.x > originNode.coordinates.x ) ? 'R' : 'L';
    let yDirection: string = (destNode.coordinates.y > destNode.coordinates.y) ? 'B' : 'T';
    return {x: xDirection, y: yDirection}
  }

  /**
   * Get the the ordered vertexes nodes of the current node.
   * @param node
   * @returns Node[]
   */
  private getNodeOrderedVertices(node: Node): Node[]{

    if(!Boolean(node)){return []};

    // Set the order using the calculateDestDirections criteria

    let directionsInPreferedOrder: string[] = [
      this.destDirections.x,
      oppositeDirection(this.destDirections.x),
      this.destDirections.y,
      oppositeDirection(this.destDirections.y)
    ];

    return directionsInPreferedOrder.map(direction => node.links[direction]) // Por cada direccion a explorar en orden
    .filter(nodeId => {return nodeId != null}) //Obtener los ids de los nodos a explorar, distintos de null
    .map(nodeId => {return this.findNodeById(nodeId)}); // Obtener el nodo del grafo.

  }

  // Expand validating that the node hasn't been previously expanded.
  private expand(node: Node): AgendaElement[]{
    let nodeVertices = this.getNodeOrderedVertices(node);
    return nodeVertices.map(expNode=>{return {node: expNode, from: node}});
  }

  public retrieveRouteFrom(node: Node): Node[]{


    let route: Node[] = [this.findNodeById(this.destNodeId)];
    let currentNode = this.expandedNodes[this.expandedNodes.length - 1];

    while(Boolean(currentNode.from)){
      route.push(currentNode.node);
      currentNode = this.expandedNodes.find(n => {return n.node == currentNode.from })
    }
    route.push(this.findNodeById(this.originNodeId))
    return route;

  }



  private findNodeById(nodeId: string): Node{
    return this.graph.find(node => {return node.id == nodeId});
  }
}


function oppositeDirection(direction: string): string{
  return (direction == 'R') ?  'L' : (direction == 'L') ? 'R' : (direction == 'T') ? 'B' : 'T';
}


export interface Node extends GraphConnectionNode{
  from?: Node
}

export interface AgendaElement{
  node: Node,
  from: Node
}
