import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-formatted-page',
  templateUrl: './pdf-formatted-page.component.html',
  styleUrls: ['./pdf-formatted-page.component.scss']
})
export class PdfFormattedPageComponent implements OnInit {

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() sectionName: string = '';
  @Input() logoURL: string = undefined;
  @Input() footerNote: string = '';
  @Input() currentPage: number = 0;
  @Input() totalPages: number = 0;
  @Input() fullHeight: boolean = false;
  @Input() showTags: boolean = false;
  @Input() pageConfig: PdfFormattedPageConfig;

  constructor() { }

  ngOnInit(): void {

    if(this.pageConfig != undefined){
      this.pageConfig.totalPages += 1;
      this.subtitle =  this.subtitle || this.pageConfig.subtitle;
      this.footerNote =  this.footerNote || this.pageConfig.footerNote;
      this.currentPage =  JSON.parse(JSON.stringify(this.pageConfig)).totalPages;
      console.log('this.pageConfig', JSON.parse(JSON.stringify(this.pageConfig)));
    }

  }

}

export class PdfFormattedPageConfig{
  public title: string = '';
  public subtitle: string = '';
  public logoURL: string;
  public footerNote: string = '';
  public currentPage: number = 0;
  public totalPages: number = 0;
}
