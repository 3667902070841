import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {example_insurance, InsuranceReportSection, InsuranceResponse, response_example} from '../../components/insurance-snapshot/dataInsurance';
import { testingData_home } from './insurance-testing-data';
import { InsuranceSnapshotComponent } from '../../components/insurance-snapshot/insurance-snapshot.component';
import { PageData, TaxProjectionstReportTemplate } from 'app/views/tax-projections/pdf-report/PdfReport.class';
import { cleanNameForPDF, readFromStoragedObject } from 'app/shared/helpers/utils';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdvisorService } from 'app/shared/services/advisor.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-insurance-home-umbrella',
  templateUrl: './insurance-home-umbrella.component.html',
  styleUrls: ['./insurance-home-umbrella.component.scss']
})
export class InsuranceHomeUmbrellaComponent implements OnInit {

  @ViewChild('reportSnapshot') reportSnapshot: InsuranceSnapshotComponent;
  public disablePDFbutton: boolean = false;
  public isLoadingPDF: boolean = false;
  public tabSelected: string = '';
  public houseSelected: string = '';
  public responseDataInsurance: InsuranceResponse = testingData_home;
  public platformDataResponse = [];
  public reportDataResponse = [];
  public allDataResponse: any;
  public housesArray = [];
  public umbrellasArray = []
  public isLoading: boolean = false;
  public isLoadingPdfBtn: boolean = false;


  constructor(
    private router: Router,
    public settingsService: ESettingsService,
    public snackBar: MatSnackBar,
    public advisorService: AdvisorService
  ) { }

  ngOnInit(): void {

    this.loadBackendData_();

  }

  goBack() {
    this.router.navigate(['/advisor/summary_new']);
  }

  changeTab(tab: string){

    let asset = this.responseDataInsurance.platformContent.find(asset => asset.title === tab);
    if(asset){
      this.tabSelected = asset.title;
      this.platformDataResponse = asset.sections;
    }

    console.log('TabSelected*', this.tabSelected);

  }



  //**************+ */

  async getReport(){
    this.isLoadingPdfBtn = true;
    console.log(this.reportSnapshot);
    console.log(this.reportSnapshot.sectionHtmlIds);

    try{

      //let canvases = await this.estateSnapshotPrintableRender.sectionsToCanvas();
      let pagesData: PageData[] = await this.responseToPagesData();

      // await delay_ms(1500);

      if(pagesData.length == 0) {
        throw("No data to be printed");
      }

      let clientsName = readFromStoragedObject('currentClient', 'fullName', 'Session');
      
      let companyData = await this.settingsService.getCompanyData().toPromise();
      let fullPageDisclaimer = (companyData.settings.isFullDisclaimerActive === true) ? companyData.fullDisclaimer : undefined;


      await TaxProjectionstReportTemplate.generateReport({
        clientName: clientsName,
        disclaimer: readFromStoragedObject('advisorPreferences', 'disclaimer'),
        logoUrl: companyData.logo,
        reportTitle: "Home Insurance Snapshot",
        //canvases: canvases,
        //canvasesTitle: this.estateSnapshotPrintableRender.getSectionsTitle(),
        resolved: {},
        pagesData: pagesData,
        AuxHTMLContainerID: 'tempForCanvasAutoInsurance',
        fullPageDisclaimer,
      }).then(report => {
        saveAs(report.doc.output('blob'), `${cleanNameForPDF(clientsName)} - Home Insurance Snapshot Report`);
        this.isLoadingPDF = false;
        this.isLoadingPdfBtn = false;
      })
    }catch(error){
      this.isLoadingPDF = false;
      this.isLoadingPdfBtn = false;
      console.log(error);
      this.snackBar.open("Unavailable report", "Ok", {
        duration: 7000,
        panelClass: 'error-snackbar'
      });
    }


  }


  async responseToPagesData(): Promise<PageData[]>{  //
    let maxHeightPerPage: number = 1000;
    let auxHeight: number = 0;

    let pagesData: PageData[] = [{pageTitle: '', canvases:[]}];

    if(this.reportSnapshot.sectionHtmlIds === undefined){
      return []
    }

    this.reportSnapshot.sectionHtmlIds.map((sectionHtmlID, index)=> {

      let htmlElement = document.getElementById(sectionHtmlID);
      let printInNewPage = htmlElement.classList.contains('pdf_print_new_page');
      auxHeight += htmlElement.clientHeight;

      // if(printInNewPage ){
      if(printInNewPage || (auxHeight > maxHeightPerPage) ){
        auxHeight = htmlElement.clientHeight;
        pagesData.push({pageTitle: '', canvases:[]});
      }
      pagesData[pagesData.length - 1].canvases.push(htmlElement);


    })

    // Avoid blank pages
    pagesData = pagesData.filter(pageData => pageData.canvases.length >= 1);

    console.log(pagesData);

    return pagesData;
  }



  /*async loadBackendData_(){
    let clientID = readFromStoragedObject('currentClient', 'clientId', 'Session');

    //Load data from backend
    await this.advisorService.loadSnapshotData(clientID, 'home-summary').then((response: InsuranceResponse) => {
      console.log(response);
      //this.responseDataInsurance = response;
    }).catch(error => {
      console.log(error);
    });


    // Load the assets list/names
    let assetsList = this.responseDataInsurance.platformContent.map(asset=>{
      return {name: asset.title, id: asset.title, type: asset.type}
    });

    this.housesArray = assetsList.filter(asset => asset.type == 'asset');
    this.umbrellasArray = assetsList.filter(asset => asset.type == 'umbrella');


    //Load sections from the first asset.
    this.platformDataResponse = this.responseDataInsurance.platformContent[0].sections;
    // Select the first element as the selected tab.
    this.tabSelected = this.responseDataInsurance.platformContent[0].title;

    // Load all the asset sections for the report
    let allAssetSections = [];

    console.log(this.responseDataInsurance.platformContent);

    this.responseDataInsurance.platformContent.map(asset => {
      allAssetSections.push(...asset.sections);
    });

    // Assemble the report sections
    this.reportDataResponse = [...this.responseDataInsurance.reportContent, ...allAssetSections];

  }*/

  async loadBackendData_(){
    this.isLoading = true;

    let clientID = readFromStoragedObject('currentClient', 'clientId', 'Session');

    return InsuranceHomeUmbrellaComponent.loadBackendData(this.advisorService, 'home-summary', clientID).then(response =>{

      this.responseDataInsurance = response.responseDataInsurance;

      // Load the assets list/names
    let assetsList = response.responseDataInsurance.platformContent.map(asset=>{
      return {name: asset.title, id: asset.title, type: asset.type}
    });

    this.housesArray = assetsList.filter(asset => asset.type == 'asset');
    this.umbrellasArray = assetsList.filter(asset => asset.type == 'umbrella');


    //Load sections from the first asset.
    this.platformDataResponse = response.responseDataInsurance.platformContent[0].sections;
    // Select the first element as the selected tab.
    this.tabSelected = response.responseDataInsurance.platformContent[0].title;
    //Load sections for report
    this.reportDataResponse = response.reportDataResponse;

    this.isLoading = false;

    })

  }

  static async loadBackendData(advisorService: AdvisorService, area: string, clientID: string, token?: string){

    let responseDataInsurance: any;
    let reportDataResponse = [];

    //Load data from backend
    await advisorService.loadSnapshotData(clientID, area/*'home-summary'*/, token).then((response: InsuranceResponse) => {
      console.log('response loadBackendData', response);
      responseDataInsurance = response;
    }).catch(error => {
      console.log(error);
    });


    // Load all the asset sections for the report
    let allAssetSections = [];

    console.log(responseDataInsurance.platformContent);

    responseDataInsurance.platformContent.map(asset => {
      allAssetSections.push(...asset.sections);
    });

    // Assemble the report sections
    reportDataResponse = [...responseDataInsurance.reportContent, ...allAssetSections];

    return{
      responseDataInsurance,
      reportDataResponse
    }

  }



}
