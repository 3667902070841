import { Questionnaire } from '../Questionnaire.model';

export class identity_questions extends Questionnaire {

      public clientIDCheckStatements: string= '';
      public clientIDShredStatements: string= '';
      public clientIDJunkMail: string= '';
      public clientIDFireProofLockbox: string= '';
      public clientIDLendCreditCards: string= '';
      public clientIDGiveSSN: string= '';
      public clientIDLendMoney: string= '';
      public clientIDPINTaxReturn: string= '';
      public clientIDFirmUpdatePersonalInfo: string= '';
      public clientIDVerbalPWBank: string= '';
      public clientIDBankChallengeQuestion: string= '';
      public clientIDPhoneAntivirus: string= '';
      public clientIDGivePhoneInfo: string= '';
      public clientIDPhonePINPW: string= '';
      public clientIDAppAutologin: string= '';
      public clientIDPhoneTurnOff: string= '';
      public clientIDPhoneData: string= '';
      public clientIDWalletBlockRFID: string= '';
      public clientIDThingsWallet: string= '';
      public clienIDReplaceWallet: string= '';
      public clientIDOverdraftProtection: string= '';
      public clientIDDifferenceCCandDC: string= '';
      public clientIDATMCoverPIN: string= '';
      public clientIDATMSkimming: string= '';
      public clientIDATMKeypads: string= '';
      public clientIDNonBankATM: string= '';
      public clientIDGetCreditReport: string= '';
      public clientIDCreditFrozen: string= '';
      public clientIDInsuranceIdentityTheft: string= '';
      public clientIDSamePWSites: string= '';
      public clientIDCreatePW: string= '';
      public clientIDPWManager: string= '';
      public clientIDAlertWithdrawnBank: string= '';
      public clientIDAlertWithdrawnBrokerage: string= '';
      public clienIDAlertEmailLogIn: string= '';
      public clientIDAlertFBLogIn: string= '';
      public clientIDSpamLooksLike: string= '';
      public clientIDAttachmentPW: string= '';
      public clientIDUrgentInfoRequest: string= '';
      public clientIDEmailConfidentialInfo: string= '';
      public clientIDDifferenceWireless: string= '';
      public clientIDBusinessPublicWiFi: string= '';
      public clientIDPCAntivirus: string= '';
      public clientIDLockPC: string= '';
      public clientIDUpdatePrograms: string= '';
      public clientIDBluetoothEnabled: string= '';
      public clientIDEncryptedHardDrive: string= '';
      public clientIDPCSpyware: string= '';
      public clientIDTwoFactorAutentic: string= '';
      public clientIDPCBackUp: string= '';
      public clientIDUsesPersonalDigitalAssistant: string = '';
      public clientIDWebNavigator: string= '';
      public clientIDAddBlocker: string= '';
      public clienIDBlockTrackersCookies: string= '';
      public clientIDBlockPopUps: string= '';
      public clientIDCheckLink: string= '';
      public clientIDTrustMediaPost: string= '';
      public clientIDTrustEmail: string= '';
      public clientIDGoogleAds: string= '';
      public clientIDSearchAds: string= '';
      public clientIDCoverCamera: string= '';
      public clienIDCoverMic: string= '';
      public clientIDDiffHttps: string= '';
      public clientIDShoppingPCI: string= '';
      public clientIDFreeUSB: string= '';
      public clientIDSecureFileTransfer: string= '';
      public clientIDPersonalDisasterRecoveryPlan: string= '';
      public clientIDAppliancesInternet: string= '';
      public clientIDApplianceSecurity: string= '';
      public clientIDCaregiversBackground: string= '';
      public clientIDShredChildFinancial: string= '';
      public clientIDChildInfoSecurePlace: string= '';
      public clientIDFamEducRights: string= '';
      public clientIDChildCreditReport: string= '';
      public clientIDKidsBefriendOnline: string= '';

      //New as May 22 2019
      public clientIDCreditMonitor: string= '';
      public clientIDForeignCall: string= '';
      //New as Aug 29 2019
      public clientIDCollectMail: string = '';
      public clientIDSharePW: string = '';
      public clientIDTwoFactorAutenticFin: string = '';
      public clientIDPurchaseOnPhone: string = '';
      public clientIDPassphrase: string = '';
      public clientIDAlertCreditCard: string = '';
      public clientIDUnknownSender: string = '';
      public clientIDUnexpectAttach: string = '';
      public clientIDHomeNet: string = '';
      public clientIDStorePW: string = '';
      public clientIDWebPort: string = '';
      public clientIDStoreInfoShop: string = '';
      public clientIDEncryptUSB: string = '';
      public clientIDMedContainer: string = '';
      public clientIDShareMedInfo: string = '';
      public clientIDReviewBenCharges: string = '';
      public clientIDReviewMedRecord: string = '';
      public clientIDCheckMail: string = '';
      public clientIDUSBPublicDevice: string= '';

      // Progress Bar
      public progressBar: number = 0;
      public progressCompleted: boolean = false;
      public progressTab: number = 0;

   constructor() {
      super()
   }


   beforeToSaveFormat(){
      
   }

   getQName(): string {
      return 'identity';
   }
}