import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UploadConfirmV2Component } from 'app/shared/components/dialogs/upload-confirm-v2/upload-confirm-v2.component';
import { UploadHistoryDialogComponent } from 'app/shared/components/dialogs/upload-history-dialog/upload-history-dialog.component';
import { InsuranceServiceService } from '../insurance-service.service';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { FirstEstateRequestDialogComponent } from 'app/shared/components/first-estate-request-dialog/first-estate-request-dialog.component';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EstateSuccessDialogComponent } from '../../../../../../estate-success-dialog/estate-success-dialog.component';
import { Router } from '@angular/router';
import { delay_ms } from 'app/shared/helpers/utils';
import { agreements } from '../insurance-constants';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';

@Component({
  selector: 'app-insurance-request',
  templateUrl: './insurance-request.component.html',
  styleUrls: ['./insurance-request.component.scss']
})
export class InsuranceRequestComponent implements OnInit, OnDestroy {

  public needSnapshot: string = "No";
  public credits: any;
  public hasAgreement: any = false;
  public clientData: any;
  public clientHasAnswered: any;
  public clientID: any;
  public area: string = 'home';
  public type: string = 'home';
  public filesToUpload: any;
  public uploading: boolean = false;
  public activityBody: any;
  public requestStatus: string = ''
  public indexesToRequestManualInput: any = [];
  public titles: any = {
    home: 'Home and Umbrella',
    auto: 'Auto'
  };
  public disabledRadio: boolean = false;
  public hasFiles: boolean = true;
  public modalUploadingDocs: MatDialogRef<DialogGenericNoticeComponent>;
  public companyType = 'legacy';
  constructor(
    private dialog: MatDialog,
    private insuranceService: InsuranceServiceService,
    private advisorService: AdvisorService,
    private authService: AuthenticationService,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.uploading = true;
    //console.log(this.insuranceService.files, Object.keys(this.insuranceService.files));
   setTimeout(() =>  this.checkFiles(), 100);
    this.type = this.insuranceService.getType();
    this.area = this.type;
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    // Get client data
    this.clientID = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');
    this.insuranceService.getUserCredits();
    this.insuranceService.credits.subscribe((value) => {
      this.credits = value;
      this.companyType = value.uploadModelType || 'legacy';
    });
    this.checkForAgreement('initial');
    this.getSnapshotStatus();
  }

  checkFiles() {
    this.insuranceService.addedFiles.subscribe((response) => {
      //console.log(response);
      if (!response || Object.keys(response).length === 0) {
        this.hasFiles = false;
      } else {
        this.hasFiles = true;
      }
    })
  }

  ngOnDestroy(): void {
  }

  async openUploadHistory() {
    try {
      // Mandar a llamar el servicio para obtener el historial
      const dialogRef = this.dialog.open(UploadHistoryDialogComponent, {
        panelClass: 'modal-dialog-white',
        width: '90vw',
        height: '80vh',
        data: {
          title: 'History of Home and Umbrella Uploads',
          credits: 2,
          creditsClass: 'text-home-insurance'
        }
      });


      dialogRef.afterClosed().subscribe((res) => {
        console.log('Se cerró')
      })
    } catch(e) {
      console.log(e);
    }
  }


  requestSnapshot() {
    if (this.hasFiles) {
      const dialogRef = this.dialog.open(UploadConfirmV2Component, {
        panelClass: ['modal-dialog-white'],
        width: '90vw',
        height: '85%',
        data: {
          title: 'History of Home and Umbrella Uploads',
          credits: 2,
          textClass: this.type === 'home' ? 'text-home-insurance' : 'text-auto-insurance',
          files: this.insuranceService.files,
          type: this.type,
          keys: {
            insurance: this.type === 'home' ? 'clientIDHomePolicy' : 'clientIDAutoPolicy',
            umbrella: 'clientIDClientHasUmbrellaDocument'
          }
        }
      });

      dialogRef.afterClosed().subscribe(async (submitConfirmed) => {
        try {
          if (submitConfirmed) {
            this.uploading = true;
            this.activityBody = this.buildActivityBody();
            this.submitFiles(this.filesToUpload);
          }
        } catch(e) {
          console.log(e);
        }
      });
    } else {
      this.router.navigate([`/${this.type === 'auto' ? 'auto-' : ''}insurance-hub/form`]);
    }
  }

  async submitFiles(files) {
    this.openModalUploadingDocs();
    try {
      this.filesToUpload = files;
      this.uploading = true;
      this.authService.refreshTokenJob.reset(); // Reset the renew token timer
      await this.authService.renewToken();
      await delay_ms(500); // Wait for everything to settle down
      // this.loadedFiles.clientIDWill = false;
      // this.loadedFiles.clientIDRevocableTrust = false;
      let uploadersOptionsKeys: any[] = this.filesToUpload;
      console.log(this.insuranceService.files);



      uploadersOptionsKeys.map(uploaderOptions => {

        let url = new URL(uploaderOptions.uploader.currentUrl);
        let searchParams = new URLSearchParams(url.search);
        let indexFromInsuranceServiceFile =  this.insuranceService.files[searchParams.get('index')];

       if(indexFromInsuranceServiceFile &&
        !(
          searchParams.get('nameOfInsuranceCompany') === indexFromInsuranceServiceFile['clientIDHomeInsuranceCompany'] &&
          searchParams.get('policyFilename') === 'clientIDHomePolicy'
        ) ||
        !(
          searchParams.get('nameOfInsuranceCompany') === indexFromInsuranceServiceFile['clientIDClientUmbrellaInsuranceCompany'] &&
          searchParams.get('policyFilename') === 'clientIDClientHasUmbrellaDocument'
        )
        ){
          uploaderOptions.uploader.currentUrl = searchParams.set('nameOfInsuranceCompany', indexFromInsuranceServiceFile['clientIDHomeInsuranceCompany'] || indexFromInsuranceServiceFile['clientIDClientUmbrellaInsuranceCompany'])
        }

        //console.log('uploaderOptions.uploader.currentUrl', uploaderOptions.uploader.currentUrl);
      })

      await this.consumeFileUploader();
      this.requestStatus = 'noFiles';

    } catch (error) {
      console.log('Error. submitFiles: ', error);
      this.uploading = false;
      this.modalUploadingDocs.close();
      this.openModalErrorUploadingDocs();
    }
  }

  buildActivityBody() {
    this.filesToUpload = this.insuranceService.files;
    const filesArr = Object.entries(this.insuranceService.files);
    const activityBody = {};
    for (const [key, value] of filesArr) {
      this.indexesToRequestManualInput.push(key);
      const {
        clientIDClientHasUmbrellaDocument,
        clientIDHomeInsuranceCompany,
        clientIDHomePolicy,
        clientIDClientUmbrellaInsuranceCompany,
        clientIDAutoPolicy,
        clientIDAutoInsuranceCompany
      }: any = value;
      const aux: any = {
        index: key,
        clientIDClientUmbrellaInsuranceCompany,
        clientIDHomeInsuranceCompany,
        clientIDAutoPolicy,
        clientIDAutoInsuranceCompany
      };
      if (clientIDClientHasUmbrellaDocument && clientIDClientHasUmbrellaDocument?.uploader) {
        const umbrellaQueue = clientIDClientHasUmbrellaDocument?.uploader?.queue;
        aux.clientIDClientHasUmbrellaDocument = umbrellaQueue[0]?.file?.name;
        this.filesToUpload[key].clientIDClientHasUmbrellaDocument.uploader.onCompleteAll = async () => await this.consumeFileUploader();
      }
      if (clientIDHomePolicy && clientIDHomePolicy?.uploader) {
        const homeQueue = clientIDHomePolicy?.uploader?.queue;
        aux.clientIDHomePolicy = homeQueue[0]?.file?.name;
        this.filesToUpload[key].clientIDHomePolicy.uploader.onCompleteAll = async () => await this.consumeFileUploader();
      }
      if (clientIDAutoPolicy && clientIDAutoPolicy?.uploader) {
        const homeQueue = clientIDAutoPolicy?.uploader?.queue;
        aux.clientIDAutoPolicy = homeQueue[0]?.file?.name;
        this.filesToUpload[key].clientIDAutoPolicy.uploader.onCompleteAll = async () => await this.consumeFileUploader();
      }
      activityBody[key] = aux;
    }
    const aux = [];
    Object.keys(this.filesToUpload).map((key) => {
      if (this.filesToUpload[key].clientIDClientHasUmbrellaDocument?.uploader) {
        aux.push(this.filesToUpload[key].clientIDClientHasUmbrellaDocument);
      }
      if (this.filesToUpload[key].clientIDHomePolicy?.uploader) {
        aux.push(this.filesToUpload[key].clientIDHomePolicy);
      }
      if (this.filesToUpload[key].clientIDAutoPolicy?.uploader) {
        aux.push(this.filesToUpload[key].clientIDAutoPolicy);
      }
    });
    this.filesToUpload = aux;
    console.log('Ac')
    return activityBody;
  }

  async sendActivity() {
    try {
      await this.advisorService.postInsuranceActivity(this.clientID, this.type, this.activityBody);
    } catch(e) {
      console.log(e);
    }
  }

  async sendActivityCredits() {
    try {
      await this.advisorService.postCreditsActivity(this.clientID, this.type, this.activityBody, this.needSnapshot !== 'Yes');
    } catch(e) {
      console.log(e);
    }
  }


  async getSnapshotStatus() {
    try {
      this.uploading = true;
      const ans: any = await this.advisorService.statusSnapshotData(this.clientID, this.type);
      const { status }: any = ans;
      if (status === 'Pending') {
        this.requestStatus = 'validating'
        this.needSnapshot = 'Yes';
      } else {
        this.requestStatus = status
      }
      if (status !== 'Not requested') {
        this.needSnapshot = 'Yes';
      }
      this.disabledRadio = this.requestStatus !== 'Not requested' && this.requestStatus !== 'Ready';
      this.uploading = false;
    } catch(e) {
      this.uploading = false;
      console.log(e);
    }
  }

  async consumeFileUploader() {
    try {

    let nextUploader = this.filesToUpload.shift();
    if (nextUploader) {
      //Use current sessin token
      nextUploader.uploader.refreshTokenUrl(localStorage.getItem("sessionToken"));
      nextUploader.uploader.uploadAllFiles()
    } else {

      this.uploading = false;
      await this.sendActivity();
      //await this.sendActivityCredits();
      if (this.needSnapshot === 'Yes' && this.requestStatus !== 'requested') {
        await this.requestInsuranceSnapshot();
        this.openEstateSnapshotModal();
        await this.insuranceService.getUserCredits();
      } else {
        this.openEstateSnapshotModal(false, false);
      }
      await this.sendActivityCredits();
      await this.requestManualInput();
      //
    }
    } catch(e) {
      this.openEstateSnapshotModal(false, true, e);
    }
  }

  async requestManualInput() {
    try {
      const variables = {
        home: 'clientIDHomePolicy',
        auto: 'clientIDAutoPolicy',
      };
      const promises = this.indexesToRequestManualInput.map((index) => {
        this.advisorService.retainAreaFile(this.clientID, this.type, index, variables[this.type], 'manual')
      });

      await Promise.all(promises).then(response => {
        this.modalUploadingDocs.close();
        console.log('Prueeeeba');
      });

    } catch(e) {
      console.log(e);
    }
  }

  openModalUploadingDocs(){
    console.log('Hellooo');
    this.modalUploadingDocs = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-uploading-files',
      width: '450px',
      data:{
        titleClass: 'title',
        title: 'Loading Documents...',
        body: `<strong>Please wait</strong><br><br>
              <img width="55px" src="/assets/images/fp-alpha-blue.gif" />`,
        actionButtons: []
      }
      }
    );
  }

  openModalErrorUploadingDocs(){
    console.log('Hellooo');
    let modalErrorUploadingDocs = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-uploading-files',
      width: '450px',
      data:{
        titleClass: 'title',
        title: 'Error',
        body: `We detected an error while the system attempted to upload your files.<br>
              Please contact support.`,
        buttonsContainerClass: 'buttons-centered',
        actionButtons: [
          {
            text: 'Ok',
            class:'button-primary',
            action: () => {
              modalErrorUploadingDocs.close();
            }
          }
        ]
      }
      }
    );
    return modalErrorUploadingDocs;
  }

  async requestInsuranceSnapshot() {
    try {
      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const res = await this.http.post<any>(`${environment.apiInsuranceRequestSummary.replace('__type__', this.type)}/?idcli=${this.clientID}`, {}, options).toPromise();
    } catch (e) {
      if (e.status === 409) {
        localStorage.setItem('estate-error', 'Yes');
      }
      throw e;
    }
  }

  openAgreementDialog() {
    const dialogRef = this.dialog.open(FirstEstateRequestDialogComponent, {
      width: '804px',
      panelClass: 'modal-dialog-questionnaire-estate',
      data: {
        ...agreements[this.area],
        ...(this.companyType !== 'legacy' ? { priceline: 'hide' } : {})
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.hasAgreement = true;
      } else {
        this.needSnapshot = 'No';
      }
    })
  }

  async checkForAgreement(e = null) {
    const ans: any = await this.advisorService.getActivity('home');
    const ansLength = ans?.length;
    if (this.needSnapshot === 'Yes' && e !== 'initial') {
      if (ansLength === 0) {
        this.hasAgreement = false;
        this.openAgreementDialog();
      } else {
        this.hasAgreement = true;
      }
    } else {
      this.hasAgreement = ansLength > 0;
    }
  }

  openEstateSnapshotModal(estateRequested: Boolean = true, isError: boolean = false, error: any = null) {
    let dialogRef;
    const titles = {
      home: 'Home',
      auto: 'Auto',
    }
    const commonData = {
      title: estateRequested ? "We have received your request! " : "We are processing your documents",
      advise: estateRequested ? `The ${titles[this.type]} Snapshot is typically delivered between 1-3 business days but could take longer depending on case length and complexity.` : 'It can take up to 5 minutes to receive your extractions.',
    }
    const errorData = {
      title: "Error",
      advise: isError ? error.message : '',
      error: true,
    }
    dialogRef = this.dialog.open(EstateSuccessDialogComponent, {
      data: isError ? errorData : commonData,
      panelClass: `modal-dialog-questionnaire-estate`,
      width: '804px',
    });
    dialogRef.afterClosed().subscribe(async result => {
      this.insuranceService.setModelData({}); //aqui puede que pase algo extraño
        if (result === 'to opportunities' && !isError) {
          this.router.navigate([this.type === 'auto' ? '/auto-insurance-hub/questionnaire' : '/insurance-hub/questionnaire']);
        } else if (result === 'to retry') {
          this.router.navigate([this.type === 'auto' ? '/auto-insurance-hub/form' : '/insurance-hub/form']);
        } else if (result === 'to opportunities' && isError) {
          this.router.navigate(['/hub']);
        } else if (result === 'to opportunities' && isError) {
          this.router.navigate(['/hub']);
        } else {
          this.router.navigate(['/hub']);
        }
    });
  }

  verifyIfFilesExist() {
    let allHasCarrier = true;
    let validFiles = true;
    if (Object.keys(this.insuranceService.filesToUpload || {}).length === 0) {
      return true;
    }
    if (this.type === 'home') {
      Object.keys(this.insuranceService.filesToUpload).forEach((key) => {
        if (!!this.insuranceService.filesToUpload[key].clientIDClientUmbrellaInsuranceCompany || !!this.insuranceService.filesToUpload[key].clientIDClientHasUmbrellaDocument) {
          validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDHomeInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDHomePolicy && !!this.insuranceService.filesToUpload[key].clientIDClientUmbrellaInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDClientHasUmbrellaDocument
        } else {
          validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDHomeInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDHomePolicy
        }

      });
    } else {
      Object.keys(this.insuranceService.filesToUpload).forEach((key) => {
        validFiles = validFiles && !!this.insuranceService.filesToUpload[key].clientIDAutoInsuranceCompany && !!this.insuranceService.filesToUpload[key].clientIDAutoPolicy
      });
    }

    return !validFiles
  }

  cantRequestSnapshot():boolean {
    return (this.requestStatus !== 'Ready' && this.requestStatus !== 'Not requested') || this.verifyIfFilesExist() || this.credits?.remaining === 0
  }

}
