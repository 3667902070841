import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-methods-list',
  templateUrl: './methods-list.component.html',
  styleUrls: ['./methods-list.component.scss']
})
export class MethodsListComponent implements OnInit {

  @Input() paymentMethods: any = [];
  @Output() onEnd: EventEmitter<any> = new EventEmitter(); 

  constructor(
    private advisorService: AdvisorService
  ) { }

  ngOnInit(): void {
  }

  changeView(view: string) {
    this.onEnd.emit({
      view: view,
      getMethods: false,
    })
  }

  async setDefaultCard (stripeId) {
    await this.advisorService.setDefaultPaymentMethod(stripeId);
    this.onEnd.emit({
      view: 'payment',
      getMethods: true,
    })
  }

  async deletePaymentMethod (stripeId: any) {
    await this.advisorService.deletePaymentMethod(stripeId);
    this.onEnd.emit({
      view: 'payment',
      getMethods: true,
    })
  }

}
