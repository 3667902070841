import { Directive, AfterViewInit, Renderer2, ElementRef, HostListener } from '@angular/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';

@Directive({
  selector: '[datepickerWithAge]'
})
export class DatepickerWithAgeDirective  implements AfterViewInit {
  
  @HostListener('dateChange', ['$event.target.value']) onInputChange(value: string) {
    this.initAgeState(value)
    this.removeLoadingIcon(); 
  }

  age = 0;
  private iconElement: any;

  constructor(
      private elementRef: ElementRef, 
      private renderer: Renderer2
    ) { }
  
  ngAfterViewInit(): void {
    this.modifyDatepickerInput()
    this.addLoadingIcon();
    setTimeout(() => {
      const firstDate = new Date(this.elementRef.nativeElement.value)
      if (this.isValidDate(firstDate)) {
        this.initAgeState(firstDate)
        this.removeLoadingIcon(); 
      }
      else{
        this.removeLoadingIcon(); 
        this.restaureDatepickerInput();
      }
    }, 1600);
  }

  private addLoadingIcon(): void {
    this.iconElement = this.renderer.createElement('mat-icon');
    this.renderer.addClass(this.iconElement, 'material-icons-outlined');
    this.renderer.addClass(this.iconElement, 'animation-rotate');
    this.renderer.addClass(this.iconElement, 'date-loader');
    const text = this.renderer.createText('sync');
    this.renderer.appendChild(this.iconElement, text);
    const wrapper = this.elementRef.nativeElement.parentElement.parentElement.parentElement;
    const lastChild = wrapper.lastElementChild;
    this.renderer.insertBefore(wrapper, this.iconElement, lastChild);
  }

  private removeLoadingIcon(): void {
    if (this.iconElement) {
      const wrapper = this.elementRef.nativeElement.parentElement.parentElement.parentElement;
      this.renderer.removeChild(wrapper, this.iconElement);
      this.iconElement = null;
    }
  }


  initAgeState(age){
    const isFirstUpdate = this.age === 0;
    if (this.age === this.calculateAge(age) && this.age !==0) {
      return
    }
    this.age = this.calculateAge(age);
    if (this.age < 0) {
      return
    }
    this.modifyDatepickerInput()
    this.removeAgeElement()
    this.appendAgeElement(isFirstUpdate)
  }

  isValidDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  modifyDatepickerStructure() {
    this.modifyDatepickerInput()
    this.appendAgeElement()
  }
  
  appendAgeElement(isFirstUpdate = false) {
    const divElement = this.renderer.createElement('label'); 
    this.renderer.setStyle(divElement , 'float', 'right');  
    this.renderer.setStyle(divElement , 'line-height', '45px');  
    this.renderer.addClass(divElement, 'mat-select-value-text');
    const timeout = isFirstUpdate ? 400 : 0
    setTimeout(() => {
      const text = this.renderer.createText(`Age ${this.age}`); 
      this.renderer.appendChild(divElement, text);
      const wrapper = this.elementRef.nativeElement.parentElement.parentElement.parentElement;
      const lastChild = wrapper.lastElementChild;
      this.renderer.insertBefore(wrapper, divElement, lastChild);
      this.renderer.addClass(divElement, 'show');
    }, timeout);
  }

  removeAgeElement() {
    const ref = this.elementRef.nativeElement.parentElement.parentElement.parentElement.querySelector('.mat-select-value-text')
    const wrapper = this.elementRef.nativeElement.parentElement.parentElement.parentElement;
    if (ref && wrapper) {
      this.renderer.removeChild(wrapper, ref);
    }
  }

  restaureDatepickerInput(){
    const ref = this.elementRef.nativeElement.parentElement.parentElement;
    this.renderer.setStyle(ref , 'width', '100%');  
  }

  modifyDatepickerInput(){
    const ref = this.elementRef.nativeElement.parentElement.parentElement;
    this.renderer.setStyle(ref , 'width', 'calc(100% - 70px)');  
  }

  calculateAge(date){
    if (!date) {
      return 0;
    }
    const today = new Date();
    const birth = new Date(date);
    let age = today.getFullYear() - birth.getFullYear();
    const mes = today.getMonth() - birth.getMonth();
    if (mes < 0 || (mes === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
  }

}
