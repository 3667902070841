import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-review-payment-method',
  templateUrl: './review-payment-method.component.html',
  styleUrls: ['./review-payment-method.component.scss']
})
export class ReviewPaymentMethodComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReviewPaymentMethodComponent>,

  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
