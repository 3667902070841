import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InsuranceHubComponent } from './insurance-hub/insurance-hub.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { InsuranceFormComponent } from './insurance-form/insurance-form.component';
import { InsuranceRequestComponent } from './insurance-request/insurance-request.component';
import { QHomeComponent } from '../q-home/q-home.component';
import { QuestionnairesModule } from '../questionnaires.module';


const allChildrenRoutes = [
  {
    path: 'form',
    component: InsuranceFormComponent,
  },
  {
    path: 'request-snapshot',
    component: InsuranceRequestComponent,
  },
  {
    path: 'questionnaire',
    component: InsuranceListComponent,
  },
];

const questChildrenRoute = [
  {
    path: 'questionnaire',
    component: InsuranceListComponent,
  },
];

const routes = [
  {
    path: 'insurance-hub',
    component: InsuranceHubComponent,
    children: [
      ...allChildrenRoutes,
      {
        path: '**',
        redirectTo: 'insurance-hub/form'
      }
    ]
  },
  {
    path: 'auto-insurance-hub',
    component: InsuranceHubComponent,
    data: {
      type: 'auto'
    },
    children: [
      ...allChildrenRoutes,
      {
        path: '**',
        redirectTo: 'auto-insurance-hub/form'
      }
    ]
  },
  {
    path: 'life-insurance-hub',
    component: InsuranceHubComponent,
    data: {
      type: 'life'
    },
    children: [
      ...questChildrenRoute,
      {
        path: '**',
        redirectTo: 'life-insurance-hub/form'
      }
    ]
  },
  {
    path: 'student-insurance-hub',
    component: InsuranceHubComponent,
    data: {
      type: 'student'
    },
    children: [
      ...questChildrenRoute,
      {
        path: '**',
        redirectTo: 'life-insurance-hub/form'
      }
    ]
  },
  {
    path: 'disability-insurance-hub',
    component: InsuranceHubComponent,
    data: {
      type: 'disability'
    },
    children: [
      ...questChildrenRoute,
      {
        path: '**',
        redirectTo: 'life-insurance-hub/form'
      }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [
    RouterModule
  ]
})
export class InsuranceHubRoutingModule { }
