import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';

@Component({
  selector: 'app-retirement-calculator',
  templateUrl: './retirement-calculator.component.html',
  styleUrls: ['./retirement-calculator.component.scss']
})
export class RetirementCalculatorComponent implements OnInit, AfterViewInit {

  @Input() type: string;

  public statusColorProbability: string;
  public statusColorGradient: string;
  public probabilityOfSuccessAux: number;
  public probabilityOfSuccess: number;
  public prospectResponse: any = {};

  constructor(public http: HttpClient) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // document.getElementById('gradient-bg').style.width = document.getElementById('gradient-bar').getBoundingClientRect().width + 'px';
    // document.getElementById('bar-indicator').style.marginLeft = 'calc(' + this.probabilityOfSuccessAux + '%)';
    // document.getElementById('gradient-bg').style.left = -((document.getElementById('gradient-bar').getBoundingClientRect().width / 100) * (this.probabilityOfSuccessAux)) + 'px';
    // if (this.probabilityOfSuccessAux >= 88) {
    //   document.getElementById('bar-indicator').style.marginLeft = 'calc(' + document.getElementById('gradient-bar').getBoundingClientRect().width + 'px - 3rem)';
    // }

    let gradientBg: NodeListOf<HTMLElement> = document.querySelectorAll('#gradient-bg');
    let gradientBar: NodeListOf<HTMLElement> = document.querySelectorAll('#gradient-bar');
    let barIndicator: NodeListOf<HTMLElement> = document.querySelectorAll('#bar-indicator');
    for(var i = 0; i<gradientBg.length;i++){
      gradientBg[i].style.width = gradientBar[i].getBoundingClientRect().width + 'px';
      gradientBg[i].style.left = -((gradientBar[i].getBoundingClientRect().width / 100) * (this.probabilityOfSuccessAux)) + 'px';
      barIndicator[i].style.marginLeft = 'calc(' + this.probabilityOfSuccessAux + '%)';
      if (this.probabilityOfSuccessAux >= 88) {
        barIndicator[i].style.marginLeft = 'calc(' + gradientBar[i].getBoundingClientRect().width + 'px - 3rem)';
      }
    }

  };

  ngOnInit() {
  }

  ngAfterViewInit() {

    let baseURl: string = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://api.fpalpha.com' : 'https://api.fpalpha.app';
    this.prospectResponse = JSON.parse(localStorage.getItem('prospectResponse'));
    this.http.post(`${baseURl}/v1/advisor/calculator/successful-retirement`, {clientHash: this.prospectResponse.idHash}).subscribe(response => {
      
      this.probabilityOfSuccess = response['probabilityOfSuccess'] * 100;
      this.probabilityOfSuccessAux = this.probabilityOfSuccess; /* this.areaScore */;
      this.probabilityOfSuccessAux <= 2 && (this.probabilityOfSuccessAux = 2);
      this.probabilityOfSuccessAux >= 88 && (this.probabilityOfSuccessAux = 88);
      // document.getElementById('gradient-bg').style.width = document.getElementById('gradient-bar').getBoundingClientRect().width + 'px';
      // document.getElementById('bar-indicator').style.marginLeft = 'calc(' + this.probabilityOfSuccessAux + '%)';
      // document.getElementById('gradient-bg').style.left = -((document.getElementById('gradient-bar').getBoundingClientRect().width / 100) * (this.probabilityOfSuccessAux)) + 'px';
      // if (this.probabilityOfSuccessAux >= 88) {
      //   document.getElementById('bar-indicator').style.marginLeft = 'calc(' + document.getElementById('gradient-bar').getBoundingClientRect().width + 'px - 3rem)';
      // }

      //Make new calculations
      let gradientBg: NodeListOf<HTMLElement> = document.querySelectorAll('#gradient-bg');
      let gradientBar: NodeListOf<HTMLElement> = document.querySelectorAll('#gradient-bar');
      let barIndicator: NodeListOf<HTMLElement> = document.querySelectorAll('#bar-indicator');
      for(var i = 0; i<gradientBg.length;i++){
        gradientBg[i].style.width = gradientBar[i].getBoundingClientRect().width + 'px';
        gradientBg[i].style.left = -((gradientBar[i].getBoundingClientRect().width / 100) * (this.probabilityOfSuccessAux)) + 'px';
        barIndicator[i].style.marginLeft = 'calc(' + this.probabilityOfSuccessAux + '%)';
        if (this.probabilityOfSuccessAux >= 88) {
          barIndicator[i].style.marginLeft = 'calc(' + gradientBar[i].getBoundingClientRect().width + 'px - 3rem)';
        }
      }

      if (Number(this.probabilityOfSuccess) >= 0 && Number(this.probabilityOfSuccess) <= 9.9999) this.statusColorProbability = 'danger-bar';
      if (Number(this.probabilityOfSuccess) >= 10 && Number(this.probabilityOfSuccess) <= 19.9999) this.statusColorProbability = 'warn-bar-10';
      if (Number(this.probabilityOfSuccess) >= 20 && Number(this.probabilityOfSuccess) <= 29.9999) this.statusColorProbability = 'warn-bar-20';
      if (Number(this.probabilityOfSuccess) >= 30 && Number(this.probabilityOfSuccess) <= 39.9999) this.statusColorProbability = 'warn-bar-30';
      if (Number(this.probabilityOfSuccess) >= 40 && Number(this.probabilityOfSuccess) <= 49.9999) this.statusColorProbability = 'warn-bar-40';
      if (Number(this.probabilityOfSuccess) >= 50 && Number(this.probabilityOfSuccess) <= 59.9999) this.statusColorProbability = 'warn-bar';
      if (Number(this.probabilityOfSuccess) >= 60 && Number(this.probabilityOfSuccess) <= 69.9999) this.statusColorProbability = 'ok-bar-60';
      if (Number(this.probabilityOfSuccess) >= 70 && Number(this.probabilityOfSuccess) <= 79.9999) this.statusColorProbability = 'ok-bar-70';
      if (Number(this.probabilityOfSuccess) >= 80 && Number(this.probabilityOfSuccess) <= 89.9999) this.statusColorProbability = 'ok-bar-80';
      if (Number(this.probabilityOfSuccess) >= 90 && Number(this.probabilityOfSuccess) <= 99) this.statusColorProbability = 'ok-bar-90';
      if (Number(this.probabilityOfSuccess) === 100) this.statusColorProbability = 'ok-bar-90';

      // let body = {
      //   probabilityOfSuccess: this.probabilityOfSuccess,
      //   probabilityOfSuccessColor: this.getScoreColor(this.statusColorProbability)
      // }

      // localStorage.setItem('probabilityOfSuccess', JSON.stringify(body));

    });
  }

  getScoreColor(score){
    let statusColor = [];
    if(Number(score) > 1 && Number(score) <= 9.9999) statusColor = [255, 104, 89];
    if(Number(score) >= 10 && Number(score) <= 19.9999) statusColor = [255, 89, 38];
    if(Number(score) >= 20 && Number(score) <= 29.9999) statusColor = [255, 119, 28];
    if(Number(score) >= 30 && Number(score) <= 39.9999) statusColor = [255, 146, 19];
    if(Number(score) >= 40 && Number(score) <= 49.9999) statusColor = [255, 177, 9];
    if(Number(score) >= 50 && Number(score) <= 59.9999) statusColor = [255, 208, 68];
    if(Number(score) >= 60 && Number(score) <= 69.9999) statusColor = [212, 205, 13];
    if(Number(score) >= 70 && Number(score) <= 79.9999) statusColor = [168, 204, 26];
    if(Number(score) >= 80 && Number(score) <= 89.9999) statusColor = [124, 204, 39];
    if(Number(score) >= 90 && Number(score) <= 99) statusColor = [81, 205, 53];
    if(Number(score) === 100) statusColor = [81, 205, 53];
    return statusColor;
  }

}
