import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-two-factor-auth-modal',
  templateUrl: './two-factor-auth-modal.component.html',
  styleUrls: ['./two-factor-auth-modal.component.scss']
})
export class TwoFactorAuthModalComponent extends CloseDialogAtLogOut implements OnInit {

  public resendAuthCode = environment.apiResendTwoFactorAuthCode;

  constructor(
    private http: HttpClient,
    private snackBarSuccess: MatSnackBar,
    private snackBarError: MatSnackBar,
    public dialogRef: MatDialogRef<TwoFactorAuthModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resendCode(){

    let idAdvisor = this.data.idAdvisor;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body;

    this.http.post<any>(`${this.resendAuthCode}/${idAdvisor}`, body.toString(), options)
    .subscribe(
        success => {
          
          this.snackBarSuccess.open(
              "New code sent.",
              "Dismiss",
              {
                duration: 3000,
                verticalPosition: "bottom",
                panelClass: "success-snackbar"
              }
            );
        },
        error => {
          console.log('Error!!!', error);
          this.snackBarError.open(
              "Something went wrong.",
              "Dismiss",
              {
                duration: 9000,
                verticalPosition: "bottom",
                panelClass: "error-snackbar"
              }
            );
        }
      );
  }

  ngOnInit() {}

}
