import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { estate_questions } from '../q-estate/estate.model';
import { general_questions } from '../q-general/general.model';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { elder_questions } from './elder.model';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
  selector: 'app-q-elder',
  templateUrl: './q-elder.component.html',
  styleUrls: ['./q-elder.component.scss']
})
export class QElderComponent implements AfterViewChecked, OnInit, AfterViewInit {

  @ViewChild('tabRef', { static: true }) tabRef;


  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: elder_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: boolean = false;
  public clientMaritalStatus: boolean;
  public clientHasChildren: boolean = false;
  public clientIsRetired: string;
  public clientAge: number;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public bulletColor;

  public estateData: estate_questions = new estate_questions();
  public generalData: general_questions = new general_questions();

  public hasRetirementAccount: boolean = false;
  public hasLifeInsurance: boolean = false;
  public hasRevocableTrust: boolean = false;
  public primaryResidence: string;

  public clientID: string;
  public areasInHub;
  public isLoading: boolean;
  public toggleSearchBar: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public  authService: AuthenticationService,
    public  qController: QuestionnaireController,
    private advisorService: AdvisorService,
    private currentClientService: ClientService
    ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried == "Married" || this.clientData.isMarried == "Domestic Partner";
    if (this.clientData.hasBusiness === 'Yes') this.clientHasBusiness = true;
    if (this.clientData.hasChildren === 'Yes') this.clientHasChildren = true;
    this.clientIsRetired = this.clientData.isRetired;
    this.clientAge = this.clientData.age;
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new elder_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansElder;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'elder', this.model).then(response => {
        
        // Wait for the data before starting the autosave function
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;

     //Read questionnaire list
     let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
     this.clientID = this.authService.isAdvisor() ? storagedClient.clientId : localStorage.getItem('userId');

     this.advisorService.getHubAreas(this.clientID)
       .subscribe(
         data => {
             this.areasInHub = this.mergAreaData(data);
             
             for(let i = 0; i< this.areasInHub.length;i++){
               if(this.areasInHub[i].nameOfAreaComplex == "Life Insurance"){
                 this.model.clientIDHasLifeInsurance = 'Yes';
                 this.hasLifeInsurance = true;
               }
             }
             this.auxModel = {
              ...(this.auxModel ? { ...this.auxModel } : {}),
              ...this.model,
            };
           },
           error => {
             console.log(error);
             this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
               duration: 6000,
               panelClass: 'error-snackbar'
             });
             this.isLoading = false;

           }
       );
       this.auxModel = {
        ...(this.auxModel ? { ...this.auxModel } : {}),
        ...this.model,
      };
  }

  async ngAfterViewInit() {

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    //Load Estate questionnaire info
    if(clientHasAnswered.ansEstate){
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'estate', this.estateData, 1);
    }

    //Load General questionnaire info
    if(clientHasAnswered.ansGeneral){
      // await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'general', this.generalData, 1);
      this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
        console.log('DATA: ', data);
        if (data?.personalInformation) {
          const answers = data?.personalInformation?.data;
          answers.forEach(({ name, answer }) => {
            this.generalData[name] = answer === 'undefined' || !answer ? '' : answer
          })
        }
      })
    }

    if(this.estateData.clientIDClientHasRetirementAccount == 'Yes'){
      this.model.clientIDHasRetAcc = 'Yes';
      this.hasRetirementAccount = true;
    }

    if(this.estateData.clientIDHasLifeInsurancePolicy == 'Yes'){
      this.model.clientIDHasLifeInsurance = 'Yes';
      this.hasLifeInsurance = true;
    }

    if(this.estateData.clientIDHasRevocableTrust == 'Yes'){
      this.model.clientIDHasRevocableTrust = 'Yes';
      this.hasRevocableTrust = true;
    }

    // if(this.generalData.clientIDPrimaryResidence == 'Own'){
    //   this.primaryResidence = 'Own';
    // }

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {



    this.qController.submitForm(this, this.model, this.clientData.clientId, 'elder', saveForLater, null, silentSave);
  }


  countTabs(tabs:number){
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  this.model.progressTab = tabChangeEvent.index;
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
  }

  mergAreaData(areasFromBack:any[]){
    let mergedData = [];
    areasFromBack.forEach(areaFromBack => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

      this.http.get(`${this.pdfUrl}${'elder'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'elderCare-questionnaire.pdf');
      });
  }

}
