import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const TYPE_ERROR = 'error';
const TYPE_ALERT = 'alert';
const TYPE_OK = 'ok';

@Component({
  selector: 'app-generic-screen-component',
  templateUrl: './generic-screen.component.html',
  styleUrls: ['./generic-screen.component.scss']
})
export class GenericScreenComponent implements OnInit {

public screenType: string = '';

  constructor(
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.screenType = this.route.snapshot.queryParamMap.get('t');
    this.screenType = this.screenType ? this.screenType : TYPE_ALERT;
  }

}

/* class Button {

  constructor(
    public text: string,
    public route: string
  ) { }

} */
