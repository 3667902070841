import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationStart, Router } from "@angular/router";
import { ConfirmLeavingComponent } from "../../../views/enterprise-settings/integrations/templates/external-providers-interactions/confirm-leaving/confirm-leaving.component";
import { ConfirmMappingComponent } from "../../../views/enterprise-settings/integrations/templates/external-providers-interactions/confirm-mapping/confirm-mapping.component";
import { INTEGRATION_STEPS, STEPS_WITH_CONFIRMATION } from "./utils";
import { BehaviorSubject, Subscription } from "rxjs";
import { Observable } from "rxjs-compat";

@Injectable({
  providedIn: "root",
})
export class IntegrationStepService {
  public activeIntegrationStep = INTEGRATION_STEPS.instructions ;
  public hasUnsavedData = true;
  public isValidCompany = false;
  public hasMapper = false;
  public activatedRedirection = false
  public confirmationSuscription: Subscription
  public isLoaded = true
  public stepsChanges: BehaviorSubject<any> = new BehaviorSubject(INTEGRATION_STEPS.instructions);

  constructor(private dialog: MatDialog, private router: Router) {
  }

  public changeStep(step: string, scapeValidation = false) {
    if ( STEPS_WITH_CONFIRMATION.includes(this.activeIntegrationStep) && !scapeValidation) {
      this.confirmLeaving().afterClosed().subscribe((status)=>{
        if (status) {
          if ( this.activeIntegrationStep != step && step == INTEGRATION_STEPS.preferences) {
            this.stepsChanges.next(step)
          }
          this.activeIntegrationStep = step;
        }
      })
    }
    else{
      if ( this.activeIntegrationStep != step && step == INTEGRATION_STEPS.preferences) {
        this.stepsChanges.next(step)
      }
      this.activeIntegrationStep = step;
    }
  }

//SI
  public confirmLeaving() {
    return this.dialog.open(ConfirmLeavingComponent, {
      panelClass: "modal-dialog-black",
      width: "520px",
    });
  }
//SI
  public confirmMapping() {
    return this.dialog.open(ConfirmMappingComponent, {
      panelClass: "modal-dialog-black",
      width: "520px",
    });
  }

}
