import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import { AdvisorService } from '../../../../../../shared/services/advisor.service';

@Component({
  selector: 'app-moneyguid-integration',
  templateUrl: './moneyguid-integration.component.html',
  styleUrls: ['./moneyguid-integration.component.scss']
})
export class MoneyguidIntegrationComponent implements OnInit {

  @Input() isIntegrated: Boolean;
  @Input() connectedAppId: any;

  constructor(private advisorService: AdvisorService,
    private http: HttpClient,
    public snackBarError: MatSnackBar,
    public snackBarSuccess: MatSnackBar,) { }

  disconnectMGP() {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiMoneyguideDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Moneyguide disconnected');
          this.snackBarSuccess.open("Your Moneyguide account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Something went wrong disconnecting your Moneyguide account. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }
  ngOnInit() {
  }

}
