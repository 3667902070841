import { Component, Input, OnInit } from "@angular/core";
import { IconsSVGPaths } from "./utils/data";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent implements OnInit {
  @Input() name = "";
  @Input() source = "";
  @Input() color = "#fff";
  @Input() size = 34;
  private icon: string[] = [];

  constructor() {}

  ngOnInit(): void {
  }
}
