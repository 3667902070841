export const NOTIFY_STYLE_SUCCESFULL = 'SUCCESSFUL';
export const NOTIFY_STYLE_ERROR= 'ERROR';
export const NOTIFY_STYLE_ALERT= 'ALERT';
export function correctNotificationStyle(obj: any): any{
  // Validate interaction constants
  let admitedInteractionConstants = [NOTIFY_STYLE_ERROR, NOTIFY_STYLE_ALERT, NOTIFY_STYLE_SUCCESFULL];
  if (obj.metadata == undefined || obj.metadata.interactionStyle == undefined || !admitedInteractionConstants.includes(obj['interactionStyle'])) {
    obj.metadata.interactionStyle = NOTIFY_STYLE_SUCCESFULL;
  }


  //#OLD_NOTIFICATIONS
  /* 
    if (obj['interactionStyle'] == undefined || !admitedInteractionConstants.includes(obj['interactionStyle'])) {
    obj['interactionStyle'] = NOTIFY_STYLE_SUCCESFULL;
  }
  */
 
  return obj
}
