import { Component, OnInit } from "@angular/core";
import { IntegrationStepService } from "../../../../../../shared/services/integration/integration-step.service";

@Component({
  selector: "app-preferences",
  templateUrl: "./preferences.component.html",
  styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent implements OnInit {
  constructor(public integrationStepService: IntegrationStepService) {}

  ngOnInit(): void {}

  changeStep(step: string) {
    this.integrationStepService.changeStep(step);
  }
}
