interface DataTransformable {
    data: any,
    transformationRule: any
    transform(): any[];
}

export class DataTransformation implements DataTransformable {
    constructor(
        public data: any[],
        public transformationRule: (dataElement:any) => any,
    ){}

    transform(): any[] {
        return this.data.map(dataElement => this.transformationRule(dataElement));
    }

}

export class DataTransformationMultiple implements DataTransformable {
    constructor(
        public data: any[],
        public transformationRule: ((dataElement:any) => any)[],
    ){}

    transform(): any[] {
        let data = this.data;
        this.transformationRule.forEach(rule=>{
            data = data.map(dataElement => rule(dataElement));
        });
        return data;
    }
}

export function CommaFormatedNumberToJSNumber(dataElement: any) : number{
    let numericValue = Number(String(dataElement).replace(/,/g, ''));
    return (isNaN(numericValue) || numericValue == undefined || numericValue == Infinity) ? 0 : numericValue;
}

export function DateToNumber(dataElement: Date) : number{
    return Number(dataElement);
}

export function NumberToDate(dataElement: number, defaultDate: Date = null) : Date{
    let dateNumber = Number(dataElement);
    if(isNaN(dateNumber)) return defaultDate;
    return new Date(Number(dateNumber));
}
