import { Component, OnInit, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-select-with-search-field',
  templateUrl: './select-with-search-field.component.html',
  styleUrls: ['./select-with-search-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWithSearchFieldComponent),
      multi: true
    }
  ]
})
export class SelectWithSearchFieldComponent implements ControlValueAccessor{

  value: string

  searchValue: string;

  private _options = [];

  @Input() 
  get options() {
    return this._options;
  }

  set options(value: any[]) {
    this._options = value;
    this.filteredOptions = value;
  }

  @Input() placeholder = ''

  @Input() theme = "general-questionnaire-theme";

  @Input() multiselect = false

  @ViewChild('inputSearchRef', { static: true }) inputSearchRef: MatInput;

  filteredOptions = []

  isDisabled: boolean;

  onChange: (value: any) => void;

  onTouch = () => { }

  constructor() { }

  search(value){
    this.searchValue = value
    this.filterValues()
  }

  filterValues() {
    this.filteredOptions = this.options.filter((item) => {
      return item.text.toLowerCase().includes(this.searchValue.toLowerCase())
    })
  }

  notifyChange(newValue: any): void {
    this.value = newValue.value;
    this.onChange(this.value);
  }

  openedChange(event){
    this.inputSearchRef.value = ''
    this.searchValue = ''
    this.filterValues()
  }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
  }

}
