export class Calculatron {

    public cola: number = 0;
    public filingStatus_nextyear: string = '';
    public clientIDTaxYear: string ='';
    public reportableIncome: number = 0;
    public taxExemptInterest: number = 0;
    public adjustmentsToIncome: number = 0;
    public capitalGainOrLoss: number = 0;
    public taxableInterest: number = 0;
    public qualifiedDividends: number = 0;
    public ordinaryDividends: number = 0;
    public taxableIra: number = 0;
    public socialSecurity: number = 0;
    public taxableSocialSecurity: number = 0;
    public alimonyReceived: number = 0;
    public businessIncome: number = 0;
    public stcg: number = 0;
    public ltcg: number = 0;
    public rentalRealEstate: number = 0;
    public totalIncome: number = 0;
    public additionalCreditsAndTaxes: number = 0;
    
    ////// Above the Line Deduction Adjustments 
    public hsaDeduction: number = 0;
    public selfEmploymentTax50: number = 0;
    public selfEmploymentSep: number = 0;
    public selfEmploymentHealth: number = 0;
    public alimonyPaid: number = 0;
    public iraDeduction: number = 0;
    public studentLoanInterestDeduction: number = 0;
    public agiValue: number = 0;
    
    ////// Itemized deductions
    public medicalAfterIncome: number = 0;
    public taxesValue: number = 0;
    public qualifiedMortgage: number = 0;
    public charitableContributions: number = 0;
    public summationsItemizedDeductions: number = 0;
    public standardDeductions: number = 0;
    public greaterDeductions: number = 0;
    public ayudaDeduction: number = 0;
    public totalTaxableIncome: number = 0;
    public standardIncomeTax: number = 0;
    // ////// 2020 fields
    public additionalStandardDeduction: number = 0;
    
    ////// Other taxes
    public selfEmploymentTax: number = 0;
    public netInvestmentTax: number = 0;
    public otherTax: number = 0;

    public otherIncome: number = 0;
    public otherIncome_currentyear: number = 0;
    public otherIncome_adjusted: number = 0;
    public otherIncome_total: number = 0;

    //CURRENT YEAR
    public reportableIncome_currentyear: number = 0;
    public taxExemptInterest_currentyear: number = 0;
    public adjustmentsToIncome_currentyear: number = 0;
    public capitalGainOrLoss_currentyear: number = 0;
    public taxableInterest_currentyear: number = 0;
    public qualifiedDividends_currentyear: number = 0;
    public ordinaryDividends_currentyear: number = 0;
    public taxableIra_currentyear: number = 0;
    public taxablePensionAndAnnuities_currentyear: number = 0;
    public socialSecurity_currentyear: number = 0;
    public taxableSocialSecurity_currentyear: number = 0;
    public taxableSocialSecurityPercentage_currentyear: number = 0;
    public alimonyReceived_currentyear: number = 0;
    public businessIncome_currentyear: number = 0;
    public stcg_currentyear: number = 0;
    public ltcg_currentyear: number = 0;
    public rentalRealEstate_currentyear: number = 0;
    public totalIncome_currentyear: number = 0;
    public additionalTax_currentyear: number = 0;
    public additionalTax_adjusted: number = 0;
    public additionalTax_total: number = 0;
    public qualifyingDependentCreditClaimed_currentyear: number = 0;
    public qualifyingDependentCreditClaimed_adjusted: number = 0;
    public additionalCreditsAndTaxes_currentyear:number = 0; 
    public additionalCreditsAndTaxes_adjusted:number = 0; 
    public additionalCreditsAndTaxes_total:number = 0; 
    public qualifyingDependentCreditClaimed_total:number = 0; 

    ////// Above the Line Deduction Adjustments 
    public hsaDeduction_currentyear: number = 0;
    public selfEmploymentTax50_currentyear: number = 0;
    public selfEmploymentSep_currentyear: number = 0;
    public selfEmploymentHealth_currentyear: number = 0;
    public alimonyPaid_currentyear: number = 0;
    public iraDeduction_currentyear: number = 0;
    public studentLoanInterestDeduction_currentyear: number = 0;
    public agiValue_currentyear: number = 0;
    
    ////// Itemized deductions
    public medicalAfterIncome_currentyear: number = 0;
    public taxesValue_currentyear: number = 0;
    public qualifiedMortgage_currentyear: number = 0;
    public charitableContributions_currentyear: number = 0;
    public summationsItemizedDeductions_currentyear: number = 0;
    public charitableContributionIfStandard_currentyear: number = 0;
    public standardDeductions_currentyear: number = 0;
    public greaterDeductions_currentyear: number = 0;
    public ayudaDeduction_currentyear: number = 0;
    public totalTaxableIncome_currentyear: number = 0;
    public standardIncomeTax_currentyear: number = 0;

    // ////// 2020 fields
    public additionalStandardDeduction_currentyear: number = 0;
    
    ////// Other taxes
    public selfEmploymentTax_currentyear: number = 0;
    public netInvestmentTax_currentyear: number = 0;
    public otherTax_currentyear: number = 0;
    
    //ADJUSTMENTS
    
    ////// Filing status
    public reportableIncome_adjusted: number = 0;
    public taxExemptInterest_adjusted: number = 0;
    public adjustmentsToIncome_adjusted: number = 0;
    public capitalGainOrLoss_adjusted: number = 0;
    public taxableInterest_adjusted: number = 0;
    public qualifiedDividends_adjusted: number = 0;
    public ordinaryDividends_adjusted: number = 0;
    public taxableIra_adjusted: number = 0;
    public taxablePensionAndAnnuities_adjusted: number = 0;
    public socialSecurity_adjusted: number = 0;
    public taxableSocialSecurity_adjusted: number = 0;
    public alimonyReceived_adjusted: number = 0;
    public businessIncome_adjusted: number = 0;
    public ltcg_adjusted: number = 0;
    public stcg_adjusted: number = 0;
    public rentalRealEstate_adjusted: number = 0;
    public totalIncome_adjusted: number = 0;
    
    ////// Above the Line Deduction Adjustments 
    public hsaDeduction_adjusted: number = 0;
    public selfEmploymentTax50_adjusted: number = 0;
    public selfEmploymentSep_adjusted: number = 0;
    public selfEmploymentHealth_adjusted: number = 0;
    public alimonyPaid_adjusted: number = 0;
    public iraDeduction_adjusted: number = 0;
    public studentLoanInterestDeduction_adjusted: number = 0;
    
    ////// Itemized deductions
    public medicalAfterIncome_adjusted: number = 0;
    public taxesValue_adjusted: number = 0;
    public qualifiedMortgage_adjusted: number = 0;
    public charitableContributions_adjusted: number = 0;
    public summationsItemizedDeductions_adjusted: number = 0;
    public charitableContributionIfStandard_adjusted: number = 0;
    public standardDeductions_adjusted: number = 0;
    public greaterDeductions_adjusted: number = 0;
    public ayudaDeduction_adjusted: number = 0;
    public totalTaxableIncome_adjusted: number = 0;
    public standardIncomeTax_adjusted: number = 0;
    
    ////// Other taxes
    public selfEmploymentTax_adjusted: number = 0;
    public netInvestmentTax_adjusted: number = 0;
    public otherTax_adjusted: number = 0;
    
    //TOTALS
    
    ////// Filing status
    public reportableIncome_total: number = 0;
    public taxExemptInterest_total: number = 0;
    public adjustmentsToIncome_total: number = 0;
    public capitalGainOrLoss_total: number = 0;
    public taxableInterest_total: number = 0;
    public qualifiedDividends_total: number = 0;
    public ordinaryDividends_total: number = 0;
    public taxableIra_total: number = 0;
    public taxablePensionAndAnnuities_total: number = 0;
    public socialSecurity_total: number = 0;
    public taxableSocialSecurity_total: number = 0;
    public taxableSocialSecurityPercentage_total: number = 0;
    public alimonyReceived_total: number = 0;
    public businessIncome_total: number = 0;
    public ltcg_total: number = 0;
    public stcg_total: number = 0;
    public rentalRealEstate_total: number = 0;
    public totalIncome_total: number = 0;
    
    ////// Above the Line Deduction Adjustments 
    public hsaDeduction_total: number = 0;
    public selfEmploymentTax50_total: number = 0;
    public selfEmploymentSep_total: number = 0;
    public selfEmploymentHealth_total: number = 0;
    public alimonyPaid_total: number = 0;
    public iraDeduction_total: number = 0;
    public studentLoanInterestDeduction_total: number = 0;
    public agiValue_total: number = 0;
    
    ////// Itemized deductions
    public medicalAfterIncome_total: number = 0;
    public taxesValue_total: number = 0;
    public qualifiedMortgage_total: number = 0;
    public charitableContributions_total: number = 0;
    public summationsItemizedDeductions_total: number = 0;
    public charitableContributionIfStandard_total: number = 0;
    public standardDeductions_total: number = 0;
    public greaterDeductions_total: number = 0;
    public ayudaDeduction_total: number = 0;
    public totalTaxableIncome_total: number = 0;
    public standardIncomeTax_total: number = 0;
    
    ////// Other taxes
    public selfEmploymentTax_total: number = 0;
    public netInvestmentTax_total: number = 0;
    public otherTax_total: number = 0;
    
    public totalTax_currentyear: number = 0;
    public totalTax: number = 0;
    
    }
    