export const TEST_1 = {
  "nodesType": "BoxNode",
  "nodes": [
      {
          "id": "014d334c-6844-465d-8e40-7d52b13fc3a9",
          "content": {
              "title": ""
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "10b62128-b6a2-4058-99b6-63a874dca26e",
          "content": {
              "title": ""
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "29078585-63f9-4a21-bc95-9afff05d2fdd",
          "content": {
              "title": ""
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "68075151-5968-4325-a048-052f6bc8cfbe",
          "content": {
              "title": ""
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "6d88e1a1-8c29-47cb-90d5-46508a43d974",
          "content": {
              "smallDescription": "Self",
              "title": "Deana Goad",
              "subtitle": "$0",
              "isCollapsedOverviewWithText": "Assets",
              "class": "overview-node-dark"
          },
          "metaData": {
              "widthInNodes": 2,
              "class": "box-node",
              "id": "65319bcc235d8f044d36d5b7",
              "relation": "Self",
              "depth": 0
          }
      },
      {
          "id": "31477db9-909d-4efb-9d45-73fe7242d76f",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Trust",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "depth": 1
          }
      },
      {
          "id": "07f2d2f6-e1b6-4880-bfb8-cc51ebc1da4b",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Will",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "id": "SelfWill",
              "type": "document",
              "isForPlanning": false,
              "depth": 1,
              "clientId": "65319bcc235d8f044d36d5b7",
              "docType": "wills"
          }
      },
      {
          "id": "9e059c9d-e567-49ea-9b4a-8de11ce06773",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Contract",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "id": "SelfContract",
              "type": "document",
              "isForPlanning": false,
              "depth": 1,
              "clientId": "65319bcc235d8f044d36d5b7"
          }
      },
      {
          "id": "f44a4b89-5f1d-4c18-97f3-e9ffe4c0101a",
          "content": {
              "smallDescription": "Joint",
              "title": "Joint Assets",
              "subtitle": "$0",
              "class": "main-node-black autoMargin",
              "isCollapsedOverviewWithText": "Assets"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "id": "Joint",
              "relation": "Joint",
              "depth": 0
          }
      },
      {
          "id": "ec4d8ea4-dc63-4264-a6c3-8d96c9702cc5",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Trust",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "depth": 1
          }
      },
      {
          "id": "299ad249-6ae8-4cd7-8df2-161e2608f7c7",
          "content": {
              "smallDescription": "Spouse",
              "title": "Fred Goad",
              "subtitle": "$0",
              "isCollapsedOverviewWithText": "Assets",
              "class": "overview-node-dark"
          },
          "metaData": {
              "widthInNodes": 2,
              "class": "box-node",
              "id": "65319cbc235d8f044d36f79b",
              "relation": "Spouse",
              "depth": 0
          }
      },
      {
          "id": "935b1c04-9413-4ef5-b35c-888a721211bd",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Trust",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "depth": 1
          }
      },
      {
          "id": "19b5583c-d76b-47bb-a500-4f28e7b20044",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Will",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "id": "SpouseWill",
              "type": "document",
              "isForPlanning": false,
              "depth": 1,
              "clientId": "65319cbc235d8f044d36f79b",
              "docType": "wills"
          }
      },
      {
          "id": "8a21d73f-58e0-4a5e-a170-5c0d2a86e4ca",
          "content": {
              "title": "",
              "subtitle": "$0",
              "displayUpperOrBottonOnly": "Bottom",
              "isActionButtonWithText": "+ Contract",
              "class": "main-node-black"
          },
          "metaData": {
              "class": "box-node",
              "id": "SpouseContract",
              "type": "document",
              "isForPlanning": false,
              "depth": 1,
              "clientId": "65319cbc235d8f044d36f79b"
          }
      },
      {
          "id": "ea011ad5-8f3a-4b85-85f4-82ce53998b2f",
          "content": {
              "title": "Frances Goad Johnson",
              "smallDescription": "Child",
              "class": "overview-node-dark"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "2b34d0d8-e97f-467a-909a-ec44cde3c01a",
          "content": {
              "title": "Rebecca Elizabeth Johnson",
              "smallDescription": "Grandchild",
              "class": "overview-node-dark"
          },
          "metaData": {
              "class": "box-node"
          }
      },
      {
          "id": "1142dc8e-05dd-4d9e-8726-dda94d71327f",
          "content": {
              "title": "Sarah Ann Johnson",
              "smallDescription": "Grandchild",
              "class": "overview-node-dark"
          },
          "metaData": {
              "class": "box-node"
          }
      },
      {
          "id": "3066adb4-7c18-4f8b-ba86-a1a07c26e2ec",
          "content": {
              "title": "Curtis Johnson",
              "smallDescription": "Grandchild",
              "class": "overview-node-dark"
          },
          "metaData": {
              "class": "box-node"
          }
      }
  ],
  "connections": [
      {
          "sourceNodeId": "6d88e1a1-8c29-47cb-90d5-46508a43d974",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "31477db9-909d-4efb-9d45-73fe7242d76f",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "07f2d2f6-e1b6-4880-bfb8-cc51ebc1da4b",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "9e059c9d-e567-49ea-9b4a-8de11ce06773",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              }
          ]
      },
      {
          "sourceNodeId": "f44a4b89-5f1d-4c18-97f3-e9ffe4c0101a",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "6d88e1a1-8c29-47cb-90d5-46508a43d974",
                  "anchorOrigin": "L",
                  "anchorDestination": "R"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "ec4d8ea4-dc63-4264-a6c3-8d96c9702cc5",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "299ad249-6ae8-4cd7-8df2-161e2608f7c7",
                  "anchorOrigin": "R",
                  "anchorDestination": "L"
              },
              {
                  "pointsToNodeId": "ea011ad5-8f3a-4b85-85f4-82ce53998b2f",
                  "anchorOrigin": "B",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "sourceNodeId": "299ad249-6ae8-4cd7-8df2-161e2608f7c7",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "935b1c04-9413-4ef5-b35c-888a721211bd",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "19b5583c-d76b-47bb-a500-4f28e7b20044",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "8a21d73f-58e0-4a5e-a170-5c0d2a86e4ca",
                  "anchorOrigin": "B",
                  "anchorDestination": "T",
                  "metaData": {
                      "class": "dashed"
                  }
              }
          ]
      },
      {
          "sourceNodeId": "31477db9-909d-4efb-9d45-73fe7242d76f",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "07f2d2f6-e1b6-4880-bfb8-cc51ebc1da4b",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "9e059c9d-e567-49ea-9b4a-8de11ce06773",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "ec4d8ea4-dc63-4264-a6c3-8d96c9702cc5",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "935b1c04-9413-4ef5-b35c-888a721211bd",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "19b5583c-d76b-47bb-a500-4f28e7b20044",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "8a21d73f-58e0-4a5e-a170-5c0d2a86e4ca",
          "nodeConnections": []
      },
      {
        "sourceNodeId": ["1142dc8e-05dd-4d9e-8726-dda94d71327f", "3066adb4-7c18-4f8b-ba86-a1a07c26e2ec"],
        "nodeConnections": [
          {
            "type": "INDIRECT",
            "pointsToNodeId": "2b34d0d8-e97f-467a-909a-ec44cde3c01a",
            "anchorOrigin": "B",
            "anchorDestination": "T",
            "metaData": {
                "class": "dashed"
            }
        },
        ]
    }
  ],
  "firstDeath": "Fred at age 95",
  "grid": {
      "fixedNodeWidthInPx": 170,
      "maxGridCols": 5,
      "rowsNodesById": [
          [
              "014d334c-6844-465d-8e40-7d52b13fc3a9",
              "014d334c-6844-465d-8e40-7d52b13fc3a9",
              "68075151-5968-4325-a048-052f6bc8cfbe",
              "29078585-63f9-4a21-bc95-9afff05d2fdd",
              "29078585-63f9-4a21-bc95-9afff05d2fdd"
          ],
          [
              "6d88e1a1-8c29-47cb-90d5-46508a43d974",
              "f44a4b89-5f1d-4c18-97f3-e9ffe4c0101a",
              "299ad249-6ae8-4cd7-8df2-161e2608f7c7"
          ],
          [
              "31477db9-909d-4efb-9d45-73fe7242d76f",
              "07f2d2f6-e1b6-4880-bfb8-cc51ebc1da4b",
              "9e059c9d-e567-49ea-9b4a-8de11ce06773",
              "ec4d8ea4-dc63-4264-a6c3-8d96c9702cc5",
              "8a21d73f-58e0-4a5e-a170-5c0d2a86e4ca",
              "19b5583c-d76b-47bb-a500-4f28e7b20044",
              "935b1c04-9413-4ef5-b35c-888a721211bd"
          ],
          [
              "ea011ad5-8f3a-4b85-85f4-82ce53998b2f"
          ],
          [
              "1142dc8e-05dd-4d9e-8726-dda94d71327f",
              "3066adb4-7c18-4f8b-ba86-a1a07c26e2ec"
          ],
          [
              "2b34d0d8-e97f-467a-909a-ec44cde3c01a"
          ],
          [
              "014d334c-6844-465d-8e40-7d52b13fc3a9"
          ]
      ]
  },
  "pdfComponents": {}
}

export const TEST_X = {
  "dataType": "graph",
  "nodesType": "FlowChartBox",
  "nodes": [
      {
          "id": "31cbb451-8e05-4ca2-927b-89fff7d8424e",
          "content": {
              "containerClass": " annotation accent-orange-top orange",
              "content": [
                  {
                      "text": "Credit Shelter Trust (David's Will)",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "mb-1"
                  },
                  {
                      "text": "Co-trustee",
                      "class": "",
                      "textB": [
                          "Vanessa Bossard and Giti Baghban"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "isHeaderNode": true,
              "widthInNodes": 1,
              "type": "trust"
          }
      },
      {
          "id": "b0340421-9f39-46b9-9eb6-05bcab0c8cdf",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "During Vanessa's lifetime"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "2959fbf8-7d4d-4ae8-b6ed-a0c7107b2ae2",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Net Income and principal  - All of the net income and any part or all of the principal as the Trustee determines"
                  },
                  {
                      "text": "Vanessa Bossard",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "0c20f3f0-5677-4be5-9b31-4a1a348ca11e",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Upon Vanessa's death"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "0e8d1250-4232-473c-9ebf-c651a7934b45",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Residuary Estate 30% - Per stirpes"
                  },
                  {
                      "text": "Sarah Sloss a/k/a Sadie Jean Sloss",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate 20% - Per stirpes"
                  },
                  {
                      "text": "Warwick Sloss",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate 7.5% - 15% in equal shares to Joseph and Alexander, or all to the survivor of them, per stirpes (subject to be held in trust as per Article Fifth)"
                  },
                  {
                      "text": "Joseph Jaffe",
                      "class": "bold"
                  },
                  {
                      "text": "Alexander Jaffe",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate 7.5% - 15% in equal shares to Viktoriya and each child of the marriage of Peter Bossard, Sr. and Diana Bossard, per stirpes (subject to be the trust provisions of Article Sixth)"
                  },
                  {
                      "text": "Viktoriya Bossard",
                      "class": "bold"
                  },
                  {
                      "text": "Each child of the marriage of Peter Bossard, Sr. and Diana Bossard ",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate 10% - Per stirpes (subject to the trust provisions of Article Sixth)"
                  },
                  {
                      "text": "Isabella Aritan",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate 10% - If she survives"
                  },
                  {
                      "text": "Amanda Aritan",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      }
  ],
  "connections": [
      {
          "sourceNodeId": "2959fbf8-7d4d-4ae8-b6ed-a0c7107b2ae2",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "b0340421-9f39-46b9-9eb6-05bcab0c8cdf",
          "nodeConnections": [
              {
                  "pointsToNodeId": "2959fbf8-7d4d-4ae8-b6ed-a0c7107b2ae2"
              }
          ]
      },
      {
          "sourceNodeId": "0e8d1250-4232-473c-9ebf-c651a7934b45",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "0c20f3f0-5677-4be5-9b31-4a1a348ca11e",
          "nodeConnections": [
              {
                  "pointsToNodeId": "0e8d1250-4232-473c-9ebf-c651a7934b45"
              }
          ]
      },
      {
          "sourceNodeId": "31cbb451-8e05-4ca2-927b-89fff7d8424e",
          "nodeConnections": [
              {
                  "pointsToNodeId": "b0340421-9f39-46b9-9eb6-05bcab0c8cdf"
              },
              {
                  "pointsToNodeId": "0c20f3f0-5677-4be5-9b31-4a1a348ca11e"
              }
          ]
      }
  ],
  "grid": {
      "rowsNodesById": [
          [
              "31cbb451-8e05-4ca2-927b-89fff7d8424e"
          ],
          [
              "b0340421-9f39-46b9-9eb6-05bcab0c8cdf",
              "0c20f3f0-5677-4be5-9b31-4a1a348ca11e"
          ],
          [
              "2959fbf8-7d4d-4ae8-b6ed-a0c7107b2ae2",
              "0e8d1250-4232-473c-9ebf-c651a7934b45"
          ]
      ]
  }
}

export const TEST_DEREK = {
  "title": "Family Map",
  "nodes": [
      {
          "id": "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
          "content": {
              "name": "Derek  Anthony Terry",
              "relation": "Client"
          },
          "metaData": {
              "class": "client"
          }
      },
      {
          "id": "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
          "content": {
              "name": "Annalia  Terry",
              "relation": "Co-Client"
          },
          "metaData": {
              "class": "client"
          }
      },
      {
          "id": "47774f70-0a34-44df-80bb-4a7881f59724",
          "content": {
              "name": "Tristan  Nolan Terry",
              "relation": "Child"
          },
          "metaData": {
              "class": "child"
          }
      },
      {
          "id": "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
          "content": {
              "name": "McKenna  Noelle Terry",
              "relation": "Child"
          },
          "metaData": {
              "class": "child"
          }
      },
      {
          "id": "8bdf07b1-6218-4bde-a799-e047bb1871ef",
          "content": {
              "name": "Nicholas  Paolo Leardini",
              "relation": "Child"
          },
          "metaData": {
              "class": "child"
          }
      }
  ],
  "connections": [
      {
          "id": "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
          "sourceNodeId": [
              "ec32f170-66c0-45b4-ab78-ad8316f3eff7"
          ],
          "nodeConnections": [
              {
                  "pointsToNodeId": "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
                  "anchorOrigin": "R",
                  "anchorDestination": "L"
              },
              {
                  "pointsToNodeId": "47774f70-0a34-44df-80bb-4a7881f59724",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "pointsToNodeId": "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "id": "310e63ae-5ad0-49fb-b7d1-a1ef542840c8",
          "sourceNodeId": [
              "310e63ae-5ad0-49fb-b7d1-a1ef542840c8"
          ],
          "nodeConnections": [
              {
                  "pointsToNodeId": "8bdf07b1-6218-4bde-a799-e047bb1871ef",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              }
          ]
      }
  ],
  "grid": {
      "rowsNodesById": [
          [
              "ec32f170-66c0-45b4-ab78-ad8316f3eff7",
              "310e63ae-5ad0-49fb-b7d1-a1ef542840c8"
          ],
          [
              "47774f70-0a34-44df-80bb-4a7881f59724",
              "c79677eb-5e5c-416a-a08f-5fa9af0c85c1",
              "8bdf07b1-6218-4bde-a799-e047bb1871ef"
          ]
      ]
  },
  "dataType": "graph",
  "nodesType": "FamilyBox"
}
