// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { environment } from '../../../../environments/environment';
import { AdvisorService } from '../../services/advisor.service';

export interface AgreementDialogData {
  agreed: boolean;
  company: string;0
}

@Component({
  selector: 'agreement-dialog',
  templateUrl: 'agreement-dialog.html',
})

export class AgreementDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  agreementForm: FormGroup;
  public isChecked: boolean = false;
  public trialDuration: number;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<AgreementDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: AgreementDialogData,
    private authService: AuthenticationService,
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {

    this.agreementForm = new FormGroup(
      Object.assign({
        agreed: new FormControl(false),
      })
    );

    if (this.data.company === 'LPL' || this.data.company === 'SEI') {
      this.trialDuration === 60
    } else {
      this.trialDuration === 14;
    }


  }

  agree() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('agree', "true");

    this.http.put<any>(`${environment.apiAdvisorBetaAgreement}`, body.toString(), options)
      .subscribe(
        result => {
          this.dialogRef.close();
          this.snackBarSuccess.open("Thank you, and welcome! ", "OK", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
        },
        error => {
          this.snackBarError.open("Something went wrong! " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
