import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-leaving-confirmation',
  templateUrl: './leaving-confirmation.component.html',
  styleUrls: ['./leaving-confirmation.component.scss']
})
export class LeavingConfirmationComponent extends CloseDialogAtLogOut implements OnInit {
  
  @Output() handleOnSave: EventEmitter<any> = new EventEmitter()
  
  @Output() handleOnLeave: EventEmitter<any> = new EventEmitter()

  @Input() isSaveDisabled: Boolean = true;

  constructor(
    public dialogRef: MatDialogRef<LeavingConfirmationComponent>,
    private authService: AuthenticationService) {

      super(dialogRef, authService); 
  }

  ngOnInit(): void {
  }

  leaveWithoutSaving(){
    this.handleOnLeave.next(true)
  }

  save(){
    this.handleOnSave.next(true)
  }

}
