import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-year-year-comparison-modal',
  templateUrl: './year-year-comparison-modal.component.html',
  styleUrls: ['./year-year-comparison-modal.component.scss']
})
export class YearYearComparisonModalComponent implements OnInit {
  public graphData: any;
  public tooltips: any;
  public data2: any;
  public displayedColumns: string[] = ['year', 'age', 'taxBracket', 'noConversion', 'withConversion', 'savingsImpact']
  constructor(
    public dialogRef: MatDialogRef<YearYearComparisonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tooltips = data.tooltips;
    this.data2 = data;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.graphData = new MatTableDataSource(this.data2.graphData);
  }

}
