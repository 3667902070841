import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { general_questions } from '../q-general/general.model';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { expense_questions } from './expense.model';

@Component({
  selector: 'app-q-expense',
  templateUrl: './q-expense.component.html',
  styleUrls: ['./q-expense.component.scss']
})
export class QExpenseComponent implements AfterViewChecked, OnInit, AfterViewInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('expenseform', { static: true }) expenseform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: expense_questions;
  public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public clientHasYoungChildren: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public bulletColor;

  public generalData: general_questions = new general_questions();

  public clientID: string;
  public areasInHub;
  public isLoading: boolean;
  public deleteCarQuestion: boolean;
  public toggleSearchBar: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public advisorService: AdvisorService,
  ) { }

    ngOnInit() {


    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new expense_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansExpense;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'expense', this.model).then(response => {
        

        //We need this because a question asking for young children
        if (this.clientIDChildOneAge <= 14 || this.clientIDChildTwoAge <= 14 || this.clientIDChildThreeAge <= 14) this.clientHasYoungChildren = true;
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }


    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;


    //Read questionnaire list
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = this.authService.isAdvisor() ? storagedClient.clientId : localStorage.getItem('userId');

    this.advisorService.getHubAreas(this.clientID)
      // .then(areas => this.areasInHub = areas, error => console.log(error));
      .subscribe(
        data => {
            //this.areasInHub = data;
            this.areasInHub = this.mergAreaData(data);
            
            for(let i = 0; i< this.areasInHub.length;i++){
              if(this.areasInHub[i].nameOfAreaComplex == "Auto Insurance"){
                this.deleteCarQuestion = true;
                this.model.clientIDHasCar = 'Yes';
              }
            }
            this.auxModel = {
              ...(this.auxModel ? { ...this.auxModel } : {}),
              ...this.model,
            };
          },
          error => {
            console.log(error);
            this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
            this.isLoading = false;

          }
      );
      this.auxModel = {
        ...(this.auxModel ? { ...this.auxModel } : {}),
        ...this.model,
      };
  }

  async ngAfterViewInit() {

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    //Load General questionnaire info
    if(clientHasAnswered.ansGeneral){
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'general', this.generalData, 1);
    }

    if(this.generalData.clientIDChildren == 'No'){
      this.model.clientIDHasYoungChildren = 'No';
    }

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);


  }

  onSaveForLater() {
    this.submit(true);
  }


  submit(saveForLater: boolean = false, silentSave:boolean = false) {



    this.qController.submitForm(this, this.model, this.clientData.clientId, 'expense', saveForLater, null, silentSave);
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  mergAreaData(areasFromBack:any[]){
    let mergedData = [];
    areasFromBack.forEach(areaFromBack => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

}
