import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CloseDialogAtLogOut } from "app/shared/helpers/CloseDialogAtLogOut.class";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";

@Component({
  selector: 'app-dialog-invalid-document',
  templateUrl: './dialog-invalid-document.component.html',
})
export class DialogInvalidDocumentComponent extends CloseDialogAtLogOut {

  public inConfirmInteraction: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogInvalidDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);
   }

  ngOnInit() {
  }

  onRetainFile(): void {
    if (this.inConfirmInteraction) this.dialogRef.close('retainFile');
  }

  displayConfirmSend(){
    this.inConfirmInteraction = !this.inConfirmInteraction;
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onManualInput() {
    this.dialogRef.close('manualInput');
  }

}
