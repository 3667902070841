import { Pipe, PipeTransform } from '@angular/core';
import { urlPrependRootContext } from '../helpers/url.helper'

@Pipe({
  name: 'appendContextUrl'
})
export class AppendContextUrlPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return urlPrependRootContext(value);
  }

}
