//Angular
import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Project
import { environment } from "../../../environments/environment";
import { getEndPointUrlByContext } from '../helpers/url.helper'
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ESettingsService {

  constructor(
    private http: HttpClient,
  ) { }

  updateCompanyLogo(body){
    return this.http.post<any>(`${environment.apiCompanyLogo}`, body).toPromise();
  };

  getCompanyData(){
    return this.http.get<any>(environment.apiCompanyData);
  };

  updateCompanyData(body){
    return this.http.patch<any>(environment.apiCompanyData, body).toPromise();
  };

  getTeamData(advisorIDList){
    return this.http.get<any>(`${environment.apiCompanyAdvisorsData}?ids=${advisorIDList}&page=1&perPage=${advisorIDList.length}`);
  };

  getCompanyRoles(companyId){
    return this.http.get<any>(`${environment.apiAdvisorRol}?companyId=${companyId}`);
  }

  addNewRol(companyId, body){
    return this.http.post<any>(`${environment.apiAdvisorRol}?companyId=${companyId}`, body).toPromise();
  }

  editRol(companyId, roleId, body){
    return this.http.patch<any>(`${environment.apiAdvisorRol}/${roleId}?companyId=${companyId}`, body).toPromise();
  }

  // addPermissions(roleId, companyId, body){
  //   return this.http.patch<any>(`${environment.apiAdvisorRol}/${roleId}/add-permissions?companyId=${companyId}`, body).toPromise();
  // }

  // removePermissions(roleId, companyId, body){
  //   return this.http.patch<any>(`${environment.apiAdvisorRol}/${roleId}/remove-permissions?companyId=${companyId}`, body).toPromise();
  // }

  assignRole(roleId, companyId, body){
    return this.http.post<any>(`${environment.apiAdvisorRol}/${roleId}/assign?companyId=${companyId}`, body).toPromise();
  }

  updateReportData(companyId, body){
    return this.http.patch<any>(`${environment.apiReportsData}/?companyId=${companyId}`, body).toPromise();
  }

  getDefaultDisclaimer(companyId){
    return this.http.get<any>(`${environment.apiReportsCompany}${companyId}?fields=default-disclaimer`).toPromise();
  }

  updateReportBackground(body){
    return this.http.post<any>(`${environment.apiReportsBackground}`, body).toPromise();
  }

  updateSettings(body){
    return this.http.patch<any>(`${environment.apiCompanyData}`, body).toPromise();
  }
}
