//Angular
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { FullPageSpinnerComponent } from 'app/shared/components/full-page-spinner/full-page-spinner.component';
import {
  ReviewExtractionsDialogComponent,
} from 'app/shared/components/review-extractions-dialog/review-extractions-dialog.component';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment, UrlParamEditor } from 'app/shared/helpers/url.helper';
import { cleanNameForPDF } from 'app/shared/helpers/utils';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { fpAnimations } from '../../../shared/animations/fp-animations';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CommaFormatedNumberToJSNumber } from '../DataTransformation.class';
import { FileUploaderOptions, UPLOADER_STATUS } from '../file-uploader-component/file-uploader-component.component';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import { general_questions } from '../q-general/general.model';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_UNSURE,
  CONST_ABSOLUTES_UNSURE_TAX_ONLY,
  CONST_FILLING_STATUSES,
  CONST_FILLING_TAX_GENDERS,
  CONST_STATES_KEY,
  CONST_TAX_SALARY_INCREMENTS,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Questionnaire } from '../Questionnaire.model';
import { uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { FullValidationFlag, taxfull_questions } from './taxfull-model';

//3rd party
//Project
const UPDATE = true;

@Component({
  selector: 'app-q-taxfull',
  templateUrl: './q-taxfull.component.html',
  styleUrls: ['./q-taxfull.component.scss'],
  animations: fpAnimations
})
export class QTaxfullComponent implements AfterViewChecked, OnInit {

  @ViewChild("taxform", { static: true }) autoform;
  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;
  public auxModel: any;
  public model: taxfull_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientTypeOfBusiness: string;
  public clientMaritalStatus: string;
  public clientAge: number;
  public clientHasChildren: string;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;
  public clientIsRetired: boolean = false;
  public clientSpouseIsRetired: boolean = false;

  public showChildrenSection: boolean;

  public haveFilePDF: string;
  public uploader: FileUploaderExtended;
  public hasBaseDropZoneOver: boolean = false;
  public whoAnswered: string;

  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_STATES_KEY, CONST_FILLING_STATUSES, CONST_FILLING_TAX_GENDERS, CONST_TAX_SALARY_INCREMENTS, CONST_ABSOLUTES_UNSURE_TAX_ONLY };
  public validations = Validations;
  public fileUploaderArray: FileUploadersArrayHelper;
  public policyUploaderOptions2019: FileUploaderOptions;
  public policyUploaderOptions2020: FileUploaderOptions;
  public policyUploaderOptions2021: FileUploaderOptions;
  public extractionResults: any[] = [];

  public isAdvisor: boolean = false;
  public isManualInputEnabled = true;
  public isProduction:boolean;

  public ntabs: number;
  public progress: number;
  public progressnumber: number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  // private isFirstTimeHere: string;
  public isLoading: boolean;
  public bulletColor;
  public generalData: general_questions = new general_questions();
  public childCount: number;
  public occupation: string[] = ["health", "law", "accounting", "actuarial science", "performing arts", "consulting", "athletics", "financial services", "investing", "investment management", "trading"];
  public removeIRAq: boolean = false;
  public joinIncome: number;
  public clientIDNetWorth: number;
  public clientIDSpouseNetWorth: number;
  public clientHasSCorp: boolean;
  public clientLivingState: string;
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public toggleSearchBar: boolean = false;
  public documentIsValid: boolean = false;

  public isBetaTester: boolean = false;
  public taxYearsAvaliable: any = ['2020', '2021'];
  public disableQuest: boolean = false;

  public commaFormattedToNumber = CommaFormatedNumberToJSNumber;


  public filingStatusRequired: boolean = false;

  public listOfActiveValidationFlags: FullValidationFlag[] = [];
  public isModalOpenNoticeNeedsReview: boolean = false;

  // Listo to verify that only one modal of the same type.
  public listOfOpenModals: string[] = [];

  // For front end flag validations

  public extendedFilingStatus: string = '';

  public isToggleDisabled: boolean = false;

  public fullPageSpinnerModal;

  public isFirstTabLoad: boolean = true;

  public advisorPreferences: any;

  public schCFormData: any;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public taxYearsAvailable: any = [];

  retirements = [
    { value: "Me", display: "Client has it" },
    { value: "Spouse", display: "Spouse has it" },
    { value: "No", display: "No" }
  ];

  retirementsingle = [
    { value: "Me", display: "Yes" },
    { value: "notsure", display: "Not sure" },
    { value: "No", display: "No" }
  ];

  couples = [
    { value: "Spouse", display: "Yes" },
    { value: "No", display: "No" }
  ];

  iras = [
    { value: "By IRA/Roth IRA account", display: "By IRA account" },
    { value: "By the beneficiary", display: "By the owner" }
  ];

  analysis = [
    { value: "Instant tax analysis", display: "Instant tax analysis" },
    { value: "Indepth analysis", display: "In-depth analysis" }
  ];

  scheduleb_inital_indexes:number[] = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14];

  showSchBpart1: boolean = false;
  showSchBpart2: boolean = false;
  showSchA: boolean = false;
  showSchC: boolean = false;
  showSchD: boolean = false;
  showSchE: boolean = false;
  showSchOne: boolean = false;
  showSchThree: boolean = false;
  // showForm1040: boolean = false;
  showForm8995: boolean = false;
  showForm5498: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {
    this.getTaxYearsAvailable();
    this.isAdvisor = this.authService.isAdvisor();
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientTypeOfBusiness = this.clientData.typeOfBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientAge = parseInt(this.clientData.age);
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;
    this.childCount = this.clientData.childrenCount + 1;
    if (this.clientData.isRetired === 'Yes') this.clientIsRetired = true;
    if (this.clientData.spouseIsRetired === 'Yes') this.clientSpouseIsRetired = true;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    this.advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));

    // Loading screen ON
    this.isLoading = true;

    //Initialize the questionnaire model
    this.model = new taxfull_questions();


    this.model.afterMappingLoad = (model) => {
      if (this.clientIDChildOneAge < 19 || this.clientIDChildTwoAge < 19 || this.clientIDChildThreeAge < 19) this.model.clientHas1040Box6c = 'Yes';
      if (this.clientMaritalStatus === 'Single') this.model.advisorIDOneSpouseWorks = 'Yes';
      //if(this.clientMaritalStatus === 'Single') this.model.clientIDFillingStatus = 'Single';
      //Update the schedule B collapsables
      this.showSchBpart1 = (model.clientHasScheduleB === 'Yes');
      this.showSchBpart2 = (model.clientHasScheduleB === 'Yes');
      this.showSchA= (model.clientHasScheduleA === 'Yes');
      this.showSchC= (model.clientHasScheduleC === 'Yes');
      this.showSchD= (model.clientHasScheduleD === 'Yes');
      this.showSchE= (model.clientHasScheduleE === 'Yes');
      this.showSchOne= (model.clientHasSchedule1 === 'Yes');
      this.showSchThree= (model.clientHasSchedule3 === 'Yes');
      // this.showForm1040= (model.clientHasForm1040 === 'Yes');
      this.showForm8995= (model.clientHasForm8995 === 'Yes');
      this.showForm5498= (model.clientHasForm5498 === 'Yes');
    };

    // this.clientAge > 65 ? this.model.clientIDIsOnMedicare = 'Yes' : this.model.clientIDIsOnMedicare = 'No';
    if (this.clientMaritalStatus === 'Single') this.model.advisorIDOneSpouseWorks = 'Yes';

    this.clientTypeOfBusiness === 'S Corp' || this.clientTypeOfBusiness === 'Partnership' ? this.model.clientHasForm1120S = 'Yes' : this.model.clientHasForm1120S = 'No';
    this.clientData.isRetired === 'Yes' && this.clientData.spouseIsRetired === 'Yes' ? this.model.advisorIDOneSpouseWorks = 'No' : this.model.advisorIDOneSpouseWorks = 'Yes';

    //Show sections with complex conditionals

    if (this.clientHasChildren === 'Yes' && this.clientIDChildOneAge < 13 && this.clientIsRetired === false || this.clientHasChildren === 'Yes' && this.clientIDChildTwoAge < 13 && this.clientIsRetired === false || this.clientHasChildren === 'Yes' && this.clientIDChildThreeAge < 13 && this.clientIsRetired === false) {
      this.showChildrenSection = true;

    } else {
      this.showChildrenSection = false;

    }

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.saveAllFlags();
      this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
    }

    // Does the questionnaire exist in DB?
    let isQuestionnaireInDB: boolean = false;
    this.qController.advisorService.getClientQuestionnaireAsyncV2(this.clientData.clientId, 'fulltax', 1).then(
      async response => {

        if(response.status == 204){
          console.log('top','No questionnare in DB');
          isQuestionnaireInDB = false;
          this.isLoading = false;
          // Get client filing status before flags validations
          let filinStatusResponse = await this.qController.advisorService.getClientFilingStatus(this.clientData.clientId);
          this.extendedFilingStatus = filinStatusResponse.filingStatus;

          this.completeBaseFilingStatusCase();
          if(this.model) this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);

        }else {
          console.log('Questionnare found in DB');
          this.isLoading = false;
          isQuestionnaireInDB = true;
          this.model.populateWithArrayOfObjects(response.answers);
          let selectedYearFileResponse = null;
          this.auxModel = {
            ...(this.auxModel ? { ...this.auxModel } : {}),
            ...this.model,
          };
          await this.qController.loadExtractionsResults(this.clientData.clientId, 'fulltax', '1').then(result => {
            this.extractionResults = result;

            //Read and set the file validation status for the currently selected year
            selectedYearFileResponse = result['clientIDFulltaxPolicy' + this.model.clientIDTaxYear];
            this.documentIsValid = Boolean(selectedYearFileResponse != undefined && selectedYearFileResponse == 'COMPLETE');

            if(!Boolean(this.model.clientIDFillingStatus)){
              this.completeBaseFilingStatusCase();
            }
          });
          // Get client filing status before flags validations
          let filinStatusResponse = await this.qController.advisorService.getClientFilingStatus(this.clientData.clientId);
          this.extendedFilingStatus = filinStatusResponse.filingStatus;

          this.listOfActiveValidationFlags = this.getListOfActiveFlags();

          //Display review modals only if you are an advisor
          if(this.isAdvisor){
            // Open the Review flags modal if there is any pending flag and status isn’t under review or invalid
            if(!(selectedYearFileResponse == 'UNDER_REVIEW' || selectedYearFileResponse == 'INVALID') && this.listOfActiveValidationFlags.length > 0 ){
              this.openNoticeValidationsDocumentReview();
            } // If there is a INVALID status at loading, display the needs review modal.
            if(selectedYearFileResponse == 'INVALID'){
              this.openNoticeNeedsReview()
            }
          }

        }

        // Don't init the autosave routine
        //this.qController.initAutoSave2(commonAutoSaveFunction);


      },
      async error => {
        console.log('Hay error!');
        // Don't init the autosave routine
        //this.qController.initAutoSave2(commonAutoSaveFunction);
      });


    //Is the first time here? Show modal
    /*this.isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    if (this.isFirstTimeHere === 'fulltax') {
      //Make a forced autosave to create a fresh questionnaire where the extractions can live.
      //if(this.model) this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);
      // Open first time dialog
      this.dialog.open(DialogFirstTime, {
        panelClass: 'modal-dialog-questionnaire',
        height: '250px',
        width: '500px',
        data: {hasUploader: true}
      });
    }*/

    //Load extraction results for file uploaders components
    /* this.qController.loadExtractionsResults(this.clientData.clientId, 'fulltax', '1').then(result => {
      this.extractionResults = result;
    }); */

    this.policyUploaderOptions2019 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2019',
        fileVal: this.model.clientIDFulltaxPolicy2019,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2019'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview() },
          validatedValid: ()=>{ this.onUploaderValidResponse() },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2019') }
        }

      }
    );

    this.policyUploaderOptions2020 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2020',
        fileVal: this.model.clientIDFulltaxPolicy2020,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2020'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview() },
          validatedValid: ()=>{ this.onUploaderValidResponse() },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2020') }
        }
      }
    );

    this.policyUploaderOptions2021 = new FileUploaderOptions(
      {
        fileID: 'clientIDFulltaxPolicy2021',
        fileVal: this.model.clientIDFulltaxPolicy2021,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'fulltax'),
        documentReadableName: 'tax return',
        blockPasswordProtectedPDF: true,
        extraQueryParams: {year: '2021'},
        actionsAfterResponse: {
          validatedVoid : ()=>{ this.openNoticeNeedsReview() },
          validatedValid: ()=>{ this.onUploaderValidResponse() },
          uploadedPasswordProtected: ()=>{ this.openModalInputPassword('2021') }
        }
      }
    );

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansFulltax;

    //Load questionnarie data, if clientHasAnswered
    //this.qController.loadQuestionnaireData(this.clientData.clientId, 'fulltax', this.model, this.clientHasAnsweredQuestionnaire);

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputTaxData = 'No';

    //Debug

    this.bulletColor = Area.findAreaById("fulltax").colorHex;
    this.auxModel = {...this.model};

    // Load sch C data to generate questionnaire
    let schCFormDataKeys = Object.keys(this.model.flagsMetadata).filter(key => { return (this.model.flagsMetadata[key].section != undefined && this.model.flagsMetadata[key].section == 'schC')});
    this.schCFormData = schCFormDataKeys.map(key => {
      return {key: key, ...this.model.flagsMetadata[key]}
    });
  }

    //Open review extractions dialog
    async openReviewExtractionsDialog(modelRef: string, description?: string){
      if(this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent') && this.listOfActiveValidationFlags.length > 0){

        this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent', UPDATE);

        //let imageUrl =  URL.createObjectURL(image);
        let hasScheduleB = false;
          for (let i = 0; i < this.listOfActiveValidationFlags.length; i++) {
            if (this.listOfActiveValidationFlags[i].keyFlag.includes('scheduleB_1') || this.listOfActiveValidationFlags[i].keyFlag.includes('scheduleB_5')) {
              hasScheduleB = true;
              break;
            }
          }

        const dialogRef = this.dialog.open(ReviewExtractionsDialogComponent, {
          panelClass: 'modal-dialog-review-extractions',
          height: '710px',
          // width: '650px',
          maxWidth: '90vw',
          maxHeight: '710px',
          disableClose: true,
          data: {
            hasScheduleB,
            modelRef: this.model,
            flagKey: modelRef,
            listOfValidationFlagsRef: this.listOfActiveValidationFlags,
            isNextYearSelected: this.model.clientIDTaxYear == '2021'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          this.isModalUniqueAddOrDelete('ReviewExtractionsDialogComponent', UPDATE);
          //Set the internal flag (metadata) to validated
          this.model.allFlagsValidatedByUser = true;

          //Submit form
          this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true);

        });
      }
    }

  ngAfterViewChecked(): void {
    if (this.isLoading === false){
      this.ntabs = parseInt(this.tabRef._tabs.length);
      this.countTabs(this.ntabs);
    }

    if(this.model.clientIDFillingStatus !== ''){
      this.filingStatusRequired = false;
    }
  }

  redirectOnManualValidation() {
    this.submit(true, true);
  }

  openNoticeValidationsDocumentReview(){
    if(this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview')){

      this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview', UPDATE);
      let fileName = this['policyUploaderOptions'+this.model.clientIDTaxYear].fileID;

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: `<img src='/assets/images/ok_completed.svg' width='35'> Success`,
          titleClass: 'text-ok h1 fp-font-bold',
          body:
            `<span class="fp-font-bold fz-24">Your file is valid. </span> <br>
             <span class="fp-font-bold"> For 100% accuracy we detected ${this.listOfActiveValidationFlags.length} items for you to review.</span>
            <br><br>
            <span class="text-gray-2">Alternatively, to have our team verify the extractions in under one business hour (CT),
            select “Notify Me When Ready”. If this option is selected, a confirmation email will be sent to you when the process
            is complete.</span><br><br>`,
          actionButtons: [
            {
              text: "Notify Me When Ready",
              class: 'button-secondary-on-white',
              action: () => {
                dialogRef.close();
                this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'fulltax', 1, fileName).then(response => {
                  console.log("Retain file.", response);
                  this.snackBarSuccess.open("We have received the return and the data will be available shortly.", "OK", {
                    duration: 3000,
                    panelClass: 'success-snackbar'
                  });
                  this.redirectOnManualValidation();

              })
              }
            },

            {
              text: "Approve Now",
              class: 'button-primary mat-elevation-z4',
              action: () => {
                dialogRef.close();
                this.model.clientIDWantsToInputTaxData = 'Yes';
                this.openReviewExtractionsDialog(this.listOfActiveValidationFlags[0].keyFlag);
              }
            }
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.isModalUniqueAddOrDelete('openNoticeValidationsDocumentReview', UPDATE);
      })
    }

  }

  openModalInputPassword(year: string){
    if(this.isModalUniqueAddOrDelete('openModalInputPassword')){

      this.isModalUniqueAddOrDelete('openModalInputPassword', UPDATE);

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Password required',
          buttonsContainerClass: 'align-left',
          body:
            `<strong>Please input your file's password.</strong><br>`,
          inputFields: [
            {type: 'password', key: 'password', text: "File's password",}
          ],
          actionButtons: [
            {
              text: 'Cancel',
              class: 'button-secondary-on-white',
              action: () => {
                dialogRef.componentInstance.data.outputFieldsData['password'] = '';
                dialogRef.close();
              }
            },
            {
              text: "Submit",
              class: 'button-primary',
              action: () => {
                // Find the uploader url
                let uploaderRef: FileUploaderOptions;
                switch(year){
                  case '2019':
                    uploaderRef = this.policyUploaderOptions2019;
                    break;
                  case '2020':
                    uploaderRef = this.policyUploaderOptions2020;
                    break;
                  case '2021':
                    uploaderRef = this.policyUploaderOptions2021;
                    break;
                }

                //let uploaderRef: FileUploaderOptions = year == '2020' ? this.policyUploaderOptions2020 : this.policyUploaderOptions2019;
                // Append the password to the url
                let uploaderUrl = UrlParamEditor(uploaderRef.uploader.currentUrl, 'password', dialogRef.componentInstance.data.outputFieldsData['password']);
                uploaderUrl = UrlParamEditor(uploaderUrl, 'incorrectPasswordOption', 'true');

                this.qController.advisorService.genericPost(uploaderUrl);
                dialogRef.close();
              }
            }
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.isModalUniqueAddOrDelete('openModalInputPassword', UPDATE);
      })

    }
  }

  openModalLoadinError(){
    if(this.isModalUniqueAddOrDelete('openModalLoadinError')){

      this.isModalUniqueAddOrDelete('openModalLoadinError', UPDATE);

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Warning',
          buttonsContainerClass: 'align-left',
          body:
            `<strong>The system couldn't retrieve the expected data.</strong><br><br>
            Please try again later
            <br><br>
            `,

          inputFields: [],
          actionButtons: [
            {
              text: 'Ok',
              class: 'button-secondary-on-white',
              action: () => {
                dialogRef.close();
              }
            },
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
          this.isModalUniqueAddOrDelete('openModalLoadinError', UPDATE);
          this.qController.deleteAllStoragedQuestionnareData();
          this.router.navigate(['/hub']);
      })

    }
  }

  async openNoticeNeedsReview(){
    if(this.isModalUniqueAddOrDelete('openNoticeNeedsReview')){
      let correctYear = 'Yes';
      let realYear = '';

      if (!this.isProduction) {
        const ansTax: any = await this.qController.advisorService.getClientQuestionnaireAsyncV2(this.clientData.clientId, 'fulltax', 1);

        if (ansTax && ansTax.answers && !this.isProduction) {
          for (const answer of ansTax.answers) {
            if (answer && answer.name === 'correctYear') {
                correctYear = answer.answer;
            }
            if (answer && correctYear === 'No' && answer.name === 'realYear') {
              realYear = answer.answer;
              break;
            }
          }
        }
      }

      if (correctYear === 'No' && !this.isProduction) {
        let uploaderUrl = UrlParamEditor(this[`policyUploaderOptions${this.model.clientIDTaxYear}`].uploader.currentUrl, 'correctYear', correctYear);
        uploaderUrl = UrlParamEditor(uploaderUrl, 'realYear', realYear);
        await this.qController.advisorService.genericPost(uploaderUrl);
        const auxYear = this.model.clientIDTaxYear;
        this.model.clientIDTaxYear = realYear;
        this.loadExtractionsFromSelectedYear({ setYes: true });
        this.snackBarSuccess.open(`We have detected that you uploaded a tax return for the year ${realYear} in ${auxYear}. We have automatically made the change to the correct year.`, "OK", {
          duration: 3000,
          panelClass: 'success-snackbar'
        });
      } else {
        let auxInterval = null;
      this.isModalUniqueAddOrDelete('openNoticeNeedsReview', UPDATE);

      let fileName = this['policyUploaderOptions'+this.model.clientIDTaxYear].fileID;

      this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'fulltax', 1, fileName, 'manual').then(response => {
        console.log("Retain file.", response);
        this.snackBarSuccess.open("We have received the return and the data will be available shortly.", "OK", {
          duration: 3000,
          panelClass: 'success-snackbar'
        });
    })

    let dialogRef;

    auxInterval = setInterval(() => {
      dialogRef.close();
      this.redirectOnManualValidation();
    }, 10000);

    dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '55vw',
      data: {
        title: 'Notice',
        body:
          `<strong>Based on our initial reading of your document, our proprietary validation algorithm is raising a flag.<br>
          It may be the quality of the document, or inconsistencies in the numbers extracted</strong><br><br>
          Our team will make the extractions accessible in under one business hour (CT). A confirmation email
          will be sent to you when the process is complete.<br>
          If you are receiving this notice after business hours the extractions will be accessible by the morning of the next business day.
          <br><br>
          `,
        actionButtons: [
          {
            text: "Dismiss",
            class: 'button-primary',
            action: () => {
              dialogRef.close();
              this.redirectOnManualValidation();
              clearInterval(auxInterval);
            }
          },

          /* {
            text: "Approve Now",
            class: 'button-primary mat-elevation-z4',
            action: () => {
              dialogRef.close();
              this.model.clientIDWantsToInputTaxData = 'Yes';
              this.openReviewExtractionsDialog(this.listOfActiveValidationFlags[0].keyFlag);
            }
          } */
        ]

      }
    });

      dialogRef.afterClosed().subscribe(response => {
        this.isModalUniqueAddOrDelete('openNoticeNeedsReview', UPDATE);
        clearInterval(auxInterval);
      })
    }
   }
  }

  openFullPageLoader(){
    if(this.isModalUniqueAddOrDelete('openFullPageLoader')){

      this.isModalUniqueAddOrDelete('openFullPageLoader', UPDATE);
      this.fullPageSpinnerModal = this.dialog.open(FullPageSpinnerComponent, {
        disableClose: true,
        width: '170px',
        height: '170px',
      });
    }

    this.fullPageSpinnerModal.afterClosed().subscribe(response => {
      this.isModalUniqueAddOrDelete('openFullPageLoader', UPDATE);
    })

  }

  openNoticeSubmitWarning(message: string){
    if(this.isModalUniqueAddOrDelete('openNoticeSubmitWarning')){

      this.isModalUniqueAddOrDelete('openNoticeSubmitWarning', UPDATE);

    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '500px',
      data: {
        title: 'Notice',
        body:
          `<strong>To submit, please review the next:</strong><br><br>
            ${message}
          `,
        actionButtons: [
          {
            text: "Ok",
            class: 'button-blue',
            action: () => {
              dialogRef.close();
            }
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      this.isModalUniqueAddOrDelete('openNoticeSubmitWarning', UPDATE);
    })
  }
  }

  async ngAfterViewInit() {

    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    //Load general questionnaire
    if (clientHasAnswered.ansGeneral) {
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'general', this.generalData, 1);


      this.generalData.clientIDBusinessEntity_1 === 'S Corp' || this.generalData.clientIDBusinessEntity_2 === 'S Corp' || this.generalData.clientIDBusinessEntity_3 === 'S Corp' ?
        this.clientHasSCorp = true : this.clientHasSCorp = false;
      // If the current questionnaire instance has no data, see if general has the data.
      this.model.clientIDHasAccessTo457Plan = Boolean(this.model.clientIDHasAccessTo457Plan) ? this.model.clientIDHasAccessTo457Plan : this.generalData.clientIDHasAccessTo457Plan;
      this.model.clientIDHasAccessTo401KPlan = Boolean(this.model.clientIDHasAccessTo401KPlan) ? this.model.clientIDHasAccessTo401KPlan : this.generalData.clientIDHasAccessTo401KPlan;

      //If you have any retirement account in the GQ then populate the question: Retirement/Do you have a traditional IRA, rollover IRA, or inherited IRA account?
      let hasRetirementAccountOnGQ = false;

      if (
        this.generalData.clientIDHasTraditionalIRA == 'Traditional IRA' ||
        this.generalData.clientIDHasRoth == 'Roth IRA' ||
        this.generalData.clientIDHasAccessTo401KPlan == '401(k)' ||
        this.generalData.clientIDHasAccessTo457Plan == '457 Plan'
      ){
        hasRetirementAccountOnGQ = true
      }
      hasRetirementAccountOnGQ ? this.model.clientIDHasTraditionalOrRolloverIRA = 'Yes' : this.model.clientIDHasTraditionalOrRolloverIRA = 'No';
    }

    if (this.occupation.includes(this.generalData.clientIDOccupation.toLowerCase())) {
      this.removeIRAq = true;
    }

    this.clientIDNetWorth = this.generalData.clientIDNetWorth;
    this.clientIDSpouseNetWorth = this.generalData.clientIDSpouseNetWorth
    this.joinIncome = this.generalData.clientIDNetWorth + this.generalData.clientIDSpouseNetWorth;
    this.clientLivingState = this.generalData.clientIDLivingState;


  }

  onSaveForLater() {
    this.submit(true);
  }

  showUploader(prop: string) {
    this.model[prop] = '';
    this.uploader.clearQueue();
  }

  onAnyUploaderReset($event: string) {
    this.model[$event] = '';
    this.extractionResults[$event] = '';
    this.model.allFlagsValidatedByUser = false;
     //If the flags hasn't be validated then validate.

  }

  submit(saveForLater: boolean = false, isManualValidation: boolean = false) {

    if (saveForLater == false) {
      if(this.model.clientIDFillingStatus === '' || this.model.clientIDFillingStatus === undefined){
        this.qController.validateRequiredQuestions(this);
        this.model.clientIDFillingStatus == '' ? this.filingStatusRequired = true : this.filingStatusRequired = false;
        return;
      }
      this.model.progressBar = 100;
      this.model.progressCompleted = true;
      this.model.progressTab = 0;



    }

    this.saveAllFlags();

    let beforeModifications = new taxfull_questions();
    Object.assign(beforeModifications, this.model);

    for (let value of Object.keys(this.model)) {
      if (value.endsWith('_2')) {
        if (this.model[value.substring(0, value.length - 2)] == 'No' && this.model[value] == 'Yes') {
          this.model[value.substring(0, value.length - 2)] = 'Yes'
        }
      }

      if ((value.startsWith('scheduleb_1') || value.startsWith('scheduleb_5')) && value.endsWith('_text')){
        let questionLower = '';
        questionLower = this.model[value]
        this.model[value]=questionLower.toUpperCase();
      }
    }

    this.model.clientIDTypeOfAnalysis === 'Instant tax analysis' ? this.model.clientIDUploadTaxReturnOnly = 'Yes' : this.model.clientIDUploadTaxReturnOnly = 'No';

    if(this.model.clientIDIncomeExpectationsFollowingYear === 'No'){
      this.model.clientID2018Income = this.model.taxableIncome;
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'Yes' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'Yes') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Me'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'Yes' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'No') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Me'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'No' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'Yes') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'Spouse'
    }

    if (this.model.clientIDHasAccessToWorkplaceRetirementPlan == 'No' && this.model.clientIDHasAccessToWorkplaceRetirementPlan_2 == 'No') {
      this.model.clientIDHasOrSpouseAccessToRoth401k = 'No'
    }

    if (this.model.clientIDHouseOrFarmToCharity === 'House or farm') this.model.clientIDHouseOrFarmToCharity = 'Yes';
    if (this.model.clientIDHouseOrFarmToCharity === 'Other property') this.model.clientIDOtherThanHouseOrFarmToCharity = 'Yes';
    if (this.model.clientIDHouseOrFarmToCharity === 'None') this.model.clientIDHouseOrFarmToCharity = 'No';

    validateAndSubmit(
      [
        uploadersValidator(this, [this.policyUploaderOptions2019, this.policyUploaderOptions2020, this.policyUploaderOptions2021]),
      ],
      () => {
        let hasManualInput = this.model.clientIDWantsToInputTaxData === 'Yes';
        let afterSent = () => {
          // Set uploader status to Submitted if current estatis is UNDER_REVIEW
          let currentYear = this.model.clientIDTaxYear;


          if( saveForLater == false && this.extractionResults['clientIDFulltaxPolicy' + currentYear] === 'UNDER_REVIEW'){
            this.qController.advisorService.setTaxUploaderStatusToSubmitted(this.clientData.clientId, 'fulltax', '', '1', currentYear).then(response => {

            });
          }

          // Send questionnaire data to save without internall modifications
          this.qController.submitForm(this, beforeModifications, this.clientData.clientId, 'fulltax', true, null, true, hasManualInput, false, this.auxModel);
        }

        // Send questionnaire to proccess with the internall modifications
        this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', saveForLater, afterSent, false, hasManualInput, isManualValidation, this.auxModel);
      },
      saveForLater
    )

  }

  countTabs(tabs: number) {
    this.progress = 100 / tabs;
  }

  nextTab(index: number) {
    this.model.progressTab = index + 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index: number) {
    this.model.progressTab = index - 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
    if (this.model.progressCompleted == true) {
      this.model.progressBar = 100;
    } else {
      this.model.progressBar = Math.round(tabChangeEvent.index * this.progress);
    }



    // Save tab progess due autosave is inactive

    this.isFirstTabLoad ? this.isFirstTabLoad = false : this.autosaveNow(null);

  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
  }

  blockManualInput() {
    this.isManualInputEnabled = false;
  }

  onUploaderValidResponse(){
    //Load the extractions data
    this.qController.loadExtractionsDataToModel(this.model, taxfull_questions,  this.clientData.clientId, 'fulltax').then(response => {

      ReviewExtractionsDialogComponent.deleteBufferedImages();
      this.listOfActiveValidationFlags = this.getListOfActiveFlags();
      if(this.listOfActiveValidationFlags.length > 0) {
        this.openNoticeValidationsDocumentReview();
      }
      //Display collapsed sections sch B if clientHasScheduleB.
      this.showSchBpart1 = (this.model.clientHasScheduleB === 'Yes');
      this.showSchBpart2 = (this.model.clientHasScheduleB === 'Yes');
      this.showSchA = (this.model.clientHasScheduleA === 'Yes');
      this.showSchC = (this.model.clientHasScheduleC === 'Yes');
      this.showSchD = (this.model.clientHasScheduleD === 'Yes');
      this.showSchE = (this.model.clientHasScheduleE === 'Yes');
      this.showSchOne= (this.model.clientHasSchedule1 === 'Yes');
      this.showSchThree= (this.model.clientHasSchedule3 === 'Yes');
      // this.showForm1040= (this.model.clientHasForm1040 === 'Yes');
      this.showForm8995= (this.model.clientHasForm8995 === 'Yes');
      this.showForm5498= (this.model.clientHasForm5498 === 'Yes');
    });
  }

  onFileUploaderResponse() {

    if (this.policyUploaderOptions2019.status == UPLOADER_STATUS.FILE_VALID || this.policyUploaderOptions2020.status == UPLOADER_STATUS.FILE_VALID) {
      /* //Load the extractions data
      this.qController.loadExtractionsDataToModel(this.model, taxfull_questions,  this.clientData.clientId, 'fulltax').then(response => {
        ReviewExtractionsDialogComponent.deleteBufferedImages();
        this.listOfActiveValidationFlags = this.getListOfActiveFlags();
        if(this.model.clientIDWantsToInputTaxData == 'No'){ // The uploader is visible
          if(this.listOfActiveValidationFlags.length == 0){
            // If first time in the quest, display visual aid
            if (this.isFirstTimeHere === 'fulltax') this.qController.openModalWhatToDoNext(this);
          }else if(this.listOfActiveValidationFlags.length > 0 && this.listOfActiveValidationFlags.length <= 4 ) {
            this.openNoticeValidationsReview();
          }else if(this.listOfActiveValidationFlags.length > 4){
            this.openNoticeValidationsDocumentReview()
          }
        }
      }) */

      // Enable questions related to a valid file
      this.documentIsValid = true;

    }

    // After the file uploader is ready, re-enable the manual input option
    this.isManualInputEnabled = true;
  }

  public isInternallChange: boolean = false;

  onManualUserSelectionChange(event) {
  }

  ngOnDestroy() {
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  resetQuestion(event, el) {

    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
    this.debug();
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

      let fillingStatus = this.model.clientIDFillingStatus;
      if(fillingStatus == 'Other' || fillingStatus == 'Joint'){
        fillingStatus = 'JointOther'
      }

      this.http.get(`${this.pdfUrl}${'fulltax/?options='+fillingStatus}`, options)
      .subscribe(blob => {
        saveAs(blob, 'tax-questionnaire.pdf');
      });
  }

  getListOfActiveFlags(): FullValidationFlag[]{

    let listOfFlags: FullValidationFlag[]  = [] ;

    let tempFlagsMetadata = Object.keys(this.model.flagsMetadata);
    //If user has activated general quest we remove living state validation
    if(this.clientData.hasGeneralQuest){
      tempFlagsMetadata = tempFlagsMetadata.filter(element => element !== 'livingState');
    }
    tempFlagsMetadata.map(flag => {

      let fullValidationFlag: FullValidationFlag = this.model.readFlag(flag);
      //If the flags hasn't be validated then validate.

      if(!this.model.allFlagsValidatedByUser){ this.flagApplyAditionalValidations(fullValidationFlag); }
      //
      if(fullValidationFlag.isActive){ listOfFlags.push(fullValidationFlag) }
    })

    if(this.isProduction){
      let flagB1 = listOfFlags.findIndex(flag => (flag.keyFlag == 'flag_scheduleb_1' ));
      if(flagB1 >= 0){
        listOfFlags.splice(flagB1, 1);
      }
      let flagB5 = listOfFlags.findIndex(flag => (flag.keyFlag == 'flag_scheduleb_5'));
      if(flagB5 >= 0){
        listOfFlags.splice(flagB5, 1);
      }
    }
    return listOfFlags;
  }

  saveAllFlags(){



    this.listOfActiveValidationFlags.map(flag => {
      this.model.saveFlag(flag)
    });

  }

  isFlagActive(keyFlag:string): boolean{
    let flag = this.listOfActiveValidationFlags.find(flag => flag.keyFlag == keyFlag);
    return flag != undefined && flag.isActive;
  }

  /**
   * Extra validations that need to be executed on the front.
   */
  flagApplyAditionalValidations(flag: FullValidationFlag){
    //
    if(flag.url != 'url') return; // A reviewed flag will be different from 'url', don't review or change an already reviewd flag.
    switch(flag.keyFlag){
      case 'wages': //1. Ask for validation if, wages = 0 && employed = true;

        if(this.generalData.clientIDRetiredOrNotWorking == 'No' && this.model.wages == 0){

          flag.isActive = true;
          flag.url = 'form1040_1_1.jpg';
          this.model.saveFlag(flag);
        }
      break;
      case 'socialSecurityBenefits': //2. Ask for validation if, client age > 65 && socialSecurityBenfits = 0;
        if(this.clientAge > 65  && this.model.socialSecurityBenefits == 0){
          flag.isActive = true;
          flag.url = 'form1040_1_3.jpg';
          this.model.saveFlag(flag);
        }
      break;
      case 'iraDistribution': //3. Ask for validation if, client age > 72 && iraDistribution = 0;
        if(this.clientAge > 72  && this.model.iraDistribution == 0){
          flag.isActive = true;
          flag.url = 'form1040_1_3.jpg';
          this.model.saveFlag(flag);
        }
      break;
      //taxInterest
      case 'taxExemptInterest': //4. activar flagTaxExemptInterest  si: TaxExemptInterest(2a) == 0 && taxInterest(2b) > 0 && ((taxableIncome > 160k && unmarried) || (taxableIncome > 320k && (MFJ || widower)))
        if(this.model.taxExemptInterest == 0  && this.model.taxInterest > 0 &&
          ((this.model.taxableIncome > 160000 && this.clientData.isMarried=='Single') ||
           (this.model.taxableIncome > 320000 && (
             (this.model.clientIDFillingStatus=='Joint' && this.clientData.isMarried=='Married')
             || this.clientData.isMarried=='Widowed')))
          ){
          flag.isActive = true;
          flag.url = 'form1040_1_3.jpg';
          this.model.saveFlag(flag);
        }
      break;
      /* 5.
      activate standarDeductionFromTable flag if:
        standar deductions from table != input standar deductions
        OR
        input summationsItemizedDeductions = input standardDeductions
      */
      case 'standardDeductions':


        let standarDeductionFromTable: number =
          this.model.clientIDTaxYear == '2021' ?
            STANDAR_DEDUCTIONS_TABLE_2021[this.model.clientIDFillingStatus] :
          this.model.clientIDTaxYear == '2020' ?
            STANDAR_DEDUCTIONS_TABLE_2020[this.model.clientIDFillingStatus] :
          this.model.clientIDTaxYear == '2019' ?
            STANDAR_DEDUCTIONS_TABLE_2019[this.model.clientIDFillingStatus] :
          undefined;

        let flagImageUrl: string = '';
        switch (this.model.clientIDTaxYear) {
          case '2019':
            flagImageUrl = 'form1040_1_2.jpg';
            break;
          case '2020':
            flagImageUrl = this.model.isFormSR == 'Yes' ? 'form1040_2_1.jpg' : 'form1040_1_1_val.jpg';
            break;
          case '2021':
            flagImageUrl = this.model.isFormSR == 'Yes' ? 'cutfor12aSR.jpg' : 'cutfor12a_val.jpg';
            break;
        }


        //Debug line
        console.log(
          'flag review: standardDeductions:',
          {
            taxYear: CommaFormatedNumberToJSNumber(this.model.clientIDTaxYear),
            standarDeductionFromTable: standarDeductionFromTable,
            standardDeductions: CommaFormatedNumberToJSNumber(this.model.standardDeductions),
            summationsItemizedDeductions: CommaFormatedNumberToJSNumber(this.model.standardDeductions),
            selectedFlagImageUrl: flagImageUrl
          }
        )

        if(
          (standarDeductionFromTable != (CommaFormatedNumberToJSNumber(this.model.standardDeductions) + (this.clientAge > 65 ? STANDAR_DEDUCTIONS_AGE_DEDUCTIONS[this.model.clientIDFillingStatus] : 0) ) ||
          CommaFormatedNumberToJSNumber(this.model.summationsItemizedDeductions) == CommaFormatedNumberToJSNumber(this.model.standardDeductions)) &&
          !(this.model.clientIDTaxYear == '2021' && CommaFormatedNumberToJSNumber(this.model.standardDeductions) == 25100) //Deactivate the flag if 2021 standardDeductions == 25100, this overrides all previous conditions
          ){
          flag.isActive = true;
          flag.url = flagImageUrl;
          this.model.saveFlag(flag);
        }

      break;
    }

  }


  isAnyFlagActive(): boolean{
    return this.listOfActiveValidationFlags.find(flag => flag.isActive) != undefined;
  }

  isUploaderNotReady2019(): boolean{
    return this.policyUploaderOptions2019.isComponentAlive && this.policyUploaderOptions2019.status != UPLOADER_STATUS.FILE_VALID
  }

  isUploaderNotReady2020(): boolean{
    return this.policyUploaderOptions2020.isComponentAlive && this.policyUploaderOptions2020.status != UPLOADER_STATUS.FILE_VALID
  }

  isSubmitDisabled(): boolean{
    return  this.isAnyFlagActive() || this.isUploaderNotReady2019() || this.isUploaderNotReady2020() || this.model.clientIDTaxYear === '2019'
  }

  isSaveForLaterDisabled(): boolean{
    return this.model.clientIDTaxYear === '2019'
  }

  submitOrDisplayWarning(){
    let warningMessage: string = '';
    if(this.isSubmitDisabled()){
      warningMessage =
      this.isAnyFlagActive() ? "You have some flags that need to be reviewed":
      this.isUploaderNotReady2019()  ? "Your 2019 tax return should be submitted":
      this.isUploaderNotReady2020()  ? "Your 2020 tax return should be submitted":
      '';
      this.openNoticeSubmitWarning(warningMessage);
    }else{
      this.submit();
    }
  }

  debug(){
    console.log({
      allFlagsClear: this.isAnyFlagActive() ? 'No': 'Yes',
      policyUploaderIsReady2019: this.isUploaderNotReady2019() ? 'No': 'Yes',
      policyUploaderIsReady2020: this.isUploaderNotReady2020() ? 'No': 'Yes',
      /* policyUploaderOptions2019: this.policyUploaderOptions2019,
      policyUploaderOptions2020: this.policyUploaderOptions2020 */
    })
  }


  removeCommas(string:string) :number {
    return CommaFormatedNumberToJSNumber(string);
  }

  autosaveNow($event){
    // Autosave only if there is no data waiting

    let currentTaxYear: string = this.model.clientIDTaxYear;
    let isUploaderAlive: boolean = Boolean (this['policyUploaderOptions' + currentTaxYear].isComponentAlive);
    let currentUploaderStatus: string = String(this['policyUploaderOptions' + currentTaxYear].status);



    // Save if uploader isn't alive or if is alive validate that the uploader status isn't validating and isn't progress
    if(!isUploaderAlive || (currentUploaderStatus != UPLOADER_STATUS.FILE_VALIDATING && currentUploaderStatus != UPLOADER_STATUS.FILE_PROGRESS)){
      this.qController.submitForm(this, this.model, this.clientData.clientId, 'fulltax', true, null, true, false, false, this.auxModel);
    }else{
      console.log("WARNING. Waiting for extractions. Autosave disabled");
    }

  }


  /**
   *
   * @param year Ex.: 2020
   */

  async loadExtractionsFromSelectedYear(event): Promise<void>{
    let year = this.model.clientIDTaxYear;
    this.disableQuest = year === '2019' ? true : false;
    if (event && event.setYes && !this.isProduction) {
      this.model.clientIDWantsToInputTaxData = 'No';
    } else {
      this.model.clientIDWantsToInputTaxData = this.taxYearsAvailable.years.includes(year) ? 'Yes' : 'No';
    }
    if(year === '2019'){
      this.model.clientIDWantsToInputTaxData = 'Yes';
    }


    let tempQuest = new taxfull_questions();

    /* if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = false; } //isToggleDisabled
    let response: any = await this.qController.advisorService.getTaxToggleYearQuestions(this.clientData.clientId, year);
    tempQuest.populateWithArrayOfObjects(response);
    console.log(tempQuest);
    this.model.clearAllFlags();
    Questionnaire.updateModelFromModel(this.model, tempQuest, this.model.getExtractionsAtributes());
    this.listOfActiveValidationFlags = this.getListOfActiveFlags();
    if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = true; } */

    this.isToggleDisabled = true;
    this.openFullPageLoader();

    let onErrorBehaviour = (error)=>{

      let errorData = error.error;
      console.log('ERROR Retrieving data from toggle year', errorData);

      if(errorData.message != undefined && errorData.message == 'no existing questionnaire!!!' && this.model){



        let cleanQuestionnaire = new taxfull_questions();
        cleanQuestionnaire.clientIDTaxYear = this.model.clientIDTaxYear;
        cleanQuestionnaire.clientIDFillingStatus = this.model.clientIDFillingStatus;

        this.qController.submitForm(this, cleanQuestionnaire, this.clientData.clientId, 'fulltax', true, null, true, false, false, this.auxModel);

      }else{
        this.openModalLoadinError();
      }

      this.isToggleDisabled = false;
      if(this.fullPageSpinnerModal){
        this.fullPageSpinnerModal.close();
        if (event && event.setYes && !this.isProduction) {

          this[`policyUploaderOptions${this.model.clientIDTaxYear}`].status = 'validating';
        }
      }
    }


    //Wait before gathering data
    this.window.setTimeout(()=>{

      this.qController.advisorService.getTaxToggleYearQuestions(this.clientData.clientId, year, this.model.clientIDFillingStatus).then((response: any) => {
        if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = false; }
        tempQuest.populateWithArrayOfObjects(response);

        this.model.clearAllFlags();
        Questionnaire.updateModelFromModel(this.model, tempQuest, this.model.getExtractionsAtributes());
        this.listOfActiveValidationFlags = this.getListOfActiveFlags();
        this.isToggleDisabled = false;
        if(this.fullPageSpinnerModal){ this.fullPageSpinnerModal.close(); }

        if(!Boolean(this.model.clientIDFillingStatus)){
          this.completeBaseFilingStatusCase();
        }
        if( this.qController.autoSaveJob ) { this.qController.autoSaveJob.isActive = true; }
        //Update the schedule B collapsables
        this.showSchBpart1 = (this.model.clientHasScheduleB === 'Yes');
        this.showSchBpart2 = (this.model.clientHasScheduleB === 'Yes');
        this.showSchA = (this.model.clientHasScheduleA === 'Yes');
        this.showSchC = (this.model.clientHasScheduleC === 'Yes');
        this.showSchD = (this.model.clientHasScheduleD === 'Yes');
        this.showSchE = (this.model.clientHasScheduleE === 'Yes');
        this.showSchOne= (this.model.clientHasSchedule1 === 'Yes');
        this.showSchThree= (this.model.clientHasSchedule3 === 'Yes');
        // this.showForm1040= (this.model.clientHasForm1040 === 'Yes');
        this.showForm8995= (this.model.clientHasForm8995 === 'Yes');
        this.showForm5498= (this.model.clientHasForm5498 === 'Yes');


        if (event && event.setYes && !this.isProduction) {

          this[`policyUploaderOptions${this.model.clientIDTaxYear}`].status = 'validating';
        }

      }, onErrorBehaviour
      /* (error => {

         console.log('ERROR Retrieving data from toggle year', error)
        this.isToggleDisabled = false;
        if(this.fullPageSpinnerModal){ this.fullPageSpinnerModal.close(); }
        this.openModalLoadinError();
      }) */
      );

    }, 800)


  }

  isModalUniqueAddOrDelete(modalName: string, update: boolean = false): boolean{
    let openModalIndex: number = this.listOfOpenModals.findIndex(modalName_ => modalName_ == modalName)
    if(openModalIndex >= 0){ // The modal name is in the list: return true, and delete.
      if(update){this.listOfOpenModals.splice(openModalIndex, 1)}  // Delete from list
      return false; //It's in  the list, not unique
    }else{
      if(update){this.listOfOpenModals.push(modalName);} // Add to the list
      return true; //Isn't in the list, is unique
    }
  }

  private completeBaseFilingStatusCase(){
      this.model.clientIDFillingStatus = (this.clientMaritalStatus === 'Married') ? 'Joint' : 'Single';
  }

  async getTaxYearsAvailable(){
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientId = storagedClient.clientId;
    const response = await this.http.get(`${environment.apiGetTaxYears}?idcli=${clientId}`).toPromise();
    this.taxYearsAvailable = response;
      //this.taxYearsAvaliable = response.years;
      response['current'] === '2019' ? this.disableQuest = true : this.disableQuest = false;
      if(response['years'].includes('2019')){
        this.taxYearsAvaliable.unshift('2019');
      }
    this.findSchedBModel(1,1,'amount')
  }

  public findSchedBModel(section: (1 | 2), index:number, type:('text' | 'amount')): Object{
    return this.model[`scheduleb_${section}_${index}_${type}`];
  }

  async downloadCSV(event){

    event.preventDefault();
    let CSVfile = await this.qController.advisorService.getTaxExtractionsAsCSV(this.clientData.clientId, this.model.clientIDTaxYear);
    if(CSVfile) saveAs(new Blob([CSVfile], {type: 'text/csv;charset=utf-8;'}), `${cleanNameForPDF(this.clientData.fullName)}_taxExtractions${this.model.clientIDTaxYear}.csv`);

  }



}

const STANDAR_DEDUCTIONS_TABLE_2020 = {
  'Single' : 12400,
  'Married Filing Jointly': 24800,
  'Married Filing Separately': 12400,
  'Head of Houseold': 18650,
  'Qualifying Widow(er)': 24800
}

const STANDAR_DEDUCTIONS_TABLE_2019 = {
  'Single' : 12200,
  'Married Filing Jointly': 24400,
  'Married Filing Separately': 12200,
  'Head of Houseold': 18350,
  'Qualifying Widow(er)': 24400
}

const STANDAR_DEDUCTIONS_TABLE_2021 = {
  'Single': 12550,
  'Married Filing Jointly': 24800,
  'Married Filing Separately': 12550,
  'Head of Household': 18800,
  'Qualifying Widow(er)': 24800,
}


const STANDAR_DEDUCTIONS_AGE_DEDUCTIONS = {
  'Single': 1750,
  'Married Filing Jointly': 1400,
  'Married Filing Separately': 1400,
  'Head of Household': 1750,
  'Qualifying Widow(er)': 300,
}
