import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {example_insurance, InsuranceReportSection } from './dataInsurance';
import { getRandomStr } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-insurance-snapshot',
  templateUrl: './insurance-snapshot.component.html',
  styleUrls: ['./insurance-snapshot.component.scss']
})
export class InsuranceSnapshotComponent implements OnInit {

  //@Input() typeInsuranceSnapshot: string = '';
  @Input() platformView: boolean = true;
  @Input() responseInsurance: InsuranceReportSection[];

  public innerResponseData: InsuranceReportSection[] = [];
  public sectionHtmlIds: string[] = [];
  public classToTitle: string = '';

  constructor() { }

  ngOnInit(){

  }

  ngOnChanges(changes: SimpleChanges) {

    /*if(Boolean(changes.typeInsuranceSnapshot) && changes.typeInsuranceSnapshot.currentValue != changes.typeInsuranceSnapshot.previousValue){
      console.log('typeInsuranceSnapshot', this.typeInsuranceSnapshot)
      this.typeInsuranceSnapshot = changes.typeInsuranceSnapshot.currentValue;
    }*/

    if((changes.platformView !== undefined) && changes.platformView.currentValue != changes.platformView.previousValue){
      this.platformView = changes.platformView.currentValue;
      console.log('platformView changes', this.platformView )
    }

    if(Boolean(changes.responseInsurance) && changes.responseInsurance.currentValue != changes.responseInsurance.previousValue){

      this.responseInsurance =  JSON.parse(JSON.stringify(changes.responseInsurance.currentValue));

      console.log('-- platformView --', this.platformView )
      console.log('responseInsurance changes', this.responseInsurance);
      let findHeader = this.responseInsurance.find((section: InsuranceReportSection) =>  section.sectionID === 'header');
      if (findHeader){
        findHeader.columns[0]['columnTitle'].map((itemTitle: any) =>{
          if (itemTitle.hasOwnProperty('class') && itemTitle['class'] != ''){
            this.classToTitle = itemTitle.class;
          }
        })
      }
      console.log('this.classToTitle;', this.classToTitle);

      if(this.platformView === false){
        console.log('platformView build ids');
        this.sectionHtmlIds = ['titleReport'];
        let sectionHeader = document.getElementById('titleReport');
        this.responseInsurance.map(section => {
          section['_htmlID'] = section.sectionID + '_' + getRandomStr(8);
          this.sectionHtmlIds.push(section['_htmlID']);
        })
        console.log(this.sectionHtmlIds);
      }
      /*this.responseInsurance.map(section=>{
        // section['_backgroundStyle'] = `linear-gradient(to right,${this.platformView ? '#3E4042, #3E4042, #3E40427E':'white, white, transparent'}), url('${section.backgroundImageUrl}')`;
      })*/

    }

  }



}
