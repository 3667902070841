//Angular
import { Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";

//Project
import { getEndPointUrlByContext } from '../helpers/url.helper'
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CalculaatorService {

  private selectedCalculatorSource: any = new Subject<any>();

  selectedCalculator = this.selectedCalculatorSource.asObservable();
  step = this.selectedCalculatorSource.asObservable();


  constructor(
    private http: HttpClient,
  ) { }

  getCalculatorList() {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/list`).toPromise();
  }

  getAssumptions (calculator: string, idClient: any, idArea: any, assetIndex) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`).toPromise();
  }

  selectCalculatorService(value: any) {
    this.selectedCalculatorSource.next(value);
  }

  setCalculatorStep(value: any) {
    this.step.next(value);
  }

  get getSelectedCalculator(): any {
    return this.selectedCalculator;
  }

  updateAssumptions(calculator: string, idClient:any, idArea: any, assetIndex: any, body:any) {
    return this.http.put<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/assumptions?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`, body).toPromise();
  }

  updateInputs(calculator: string, idClient:any, idArea: any, assetIndex: any, body:any) {
    return this.http.put<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/inputs?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`, body).toPromise();
  }

  getFullInfo (calculator: string, idClient: any, idArea: any, assetIndex) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/calculate?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`).toPromise();
  }

  /**
   * Roth calculator V2
   * @param value
   */

  getAssumptionsV2(calculator: string, idClient: any, idArea: any, assetIndex) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/assumptions?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`).toPromise();
  }

  getInputsV2(calculator: string, idClient: any, idArea: any, assetIndex, scenario: any = 1) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/inputs?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}&scenario=${scenario}`).toPromise();
  }

  getTaxAssumptionsV2(calculator: string, idClient: any, idArea: any, assetIndex) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/tax_assumptions?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`).toPromise();
  }

  updateAssumptionsV2(calculator: string, idClient:any, idArea: any, assetIndex: any, body:any) {
    return this.http.put<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/assumptions?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`, body).toPromise();
  }

  updateTaxAssumptionsV2(calculator: string, idClient:any, idArea: any, assetIndex: any, body:any) {
    return this.http.put<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/tax_assumptions?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`, body).toPromise();
  }

  updateInputsV2(calculator: string, idClient:any, idArea: any, assetIndex: any, body:any, scenario: number = 1) {
    return this.http.put<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/inputs?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}&scenario=${scenario}`, body).toPromise();
  }

  getCalculatorTables() {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/tables/`).toPromise();
  }

  getFullInfoV2 (calculator: string, idClient: any, idArea: any, assetIndex) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/calculate?idClient=${idClient}&area=${idArea}&assetIndex=${assetIndex}`).toPromise();
  }

  getReportInfo(calculator: string, idClient: any, idArea: any, assetIndex = 1) {
    return this.http.get<any>(`${getEndPointUrlByContext('Calculator')}/${calculator}/report?idClient=${idClient}&area=fulltax&scenario=${assetIndex}`).toPromise()
  }
}
