import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-modal-charitable-contr-deduct',
  templateUrl: './modal-charitable-contr-deduct.component.html',
  styleUrls: ['./modal-charitable-contr-deduct.component.scss']
})
export class ModalCharitableContrDeductComponent extends CloseDialogAtLogOut implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalCharitableContrDeductComponent>,
    private authService: AuthenticationService,
  ) {
    super(dialogRef, authService);
   }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
