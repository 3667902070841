import { Component, Input, OnInit } from '@angular/core';
import { readFromStoragedObject } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { CONST_MARRIAGESTATUS } from '../../../questionnaires/Questionnaire.constants';
import { CALCULATORS_CATALOG, calculatorTransformable } from '../Calculators.class';


@Component({
  selector: 'app-calc-schedule-d',
  templateUrl: './calc-schedule-d.component.html',
  styleUrls: ['./calc-schedule-d.component.scss']
})
export class CalcScheduleDComponent implements OnInit, calculatorTransformable {

  @Input() public response: any = {};

  public CONSTANTS = {CONST_MARRIAGESTATUS}
  public clientId: string = '';
  public stcglSection: any = {};
  public ltgclSection: any = {};
  public summarySection: any = {};
  public howManyScenarios: number = 0;
  public calculator: any;
  public scheduleDCalculator: any = [];
  public loading = true;

  public openScenarios: any = {};
  public openSections: any = [true, true, true];
  public columns: any = ['proceeds','cost', 'adjustments', 'gainOrLoss'];
  public columnsLabels: any = ['Proceeds','Cost', 'Adjustments', 'Gain or Loss'];

  constructor(
    public advisorService: AdvisorService,
  ) { }


  ngOnInit() {
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    this.calculator = CALCULATORS_CATALOG.find(catalog => catalog.id == 'scheduleDCalculator');

    this.handleData(this.clientId);
  }

  /**
   * This function set initial styles for gridElements and gridTitles.
   */
  setInitialStyles() {
    const mainContainer: any = document.getElementById('calculatorScenarios');
    const gridElements: any = document.getElementsByClassName('base-grid');
    const gridTitles: any = document.getElementsByClassName('grid-title');
    for (let element of gridElements) {
      let width = 242;
      element.style.gridTemplateColumns = `242px ${Object.keys(this.openScenarios).map((key) => {

        width += this.openScenarios[key] ? 728 : 194;
        return this.openScenarios[key] ? '728px' : '194px'
      }).join(' ')}`;
      element.style.width = `${width}px`;
    }
    for (let element of gridTitles) {
      element.style.gridColumn = `1 / span ${this.howManyScenarios + 1}`;
    }
  }

  /**
 * Toggles the state of a section identified by its index.
 * Additionally, it invokes a service method named saveCollapsableElements from advisorService to save the collapsible state of the section.
 * @param {number} index - The index of the section to toggle.
 */
  async toggleSection(index: number) {
    this.openSections[index] = !this.openSections[index];
    await this.advisorService.saveCollapsableElements(this.clientId, {
      components: [`scheduleDCalculator_${index}`],
    }, 'projections-calculators');
  }

  /**
 * Toggles the state of a scenario identified by its index.
 * that toggles the state of a scenario identified by its index.
 * Also invokes a service method named saveCollapsableElements from advisorService to save the collapsible state of the scenario.
 * @param {number} index - The index of the scenario to toggle.
 */
  async toggleScenario(index: number) {
    this.openScenarios[`scenario_${index}`] = !this.openScenarios[`scenario_${index}`];
    await this.advisorService.saveCollapsableElements(this.clientId, {
      components: [`scheduleDCalculator_scenario-${index}`],
    }, 'projections-calculators');
    this.setInitialStyles();
  }

  /**
 * Asynchronously retrieves collapsible elements information from the advisor service,
 * updates internal state variables based on the retrieved data, and sets initial styles.
 * @name getCollapsableElements
 */
  async getCollapsableElements() {
    try {
      const { projectionsCalculators: { hiddenComponents } }: any = await this.advisorService.getCollapsableElements(this.clientId);

      const hidenComponentsIds = [];
      hiddenComponents.map((element) => {
        const [calculatorId, idx] = element.split('_');
        if (calculatorId === 'scheduleDCalculator' && !idx.includes('scenario')) {
          hidenComponentsIds.push(parseInt(idx, 10));
        } else if (calculatorId === 'scheduleDCalculator') {
          hidenComponentsIds.push(idx.replace('-', '_'));
        }
      });
      hidenComponentsIds.forEach((hiddenElement) => {
        if (typeof hiddenElement === 'string') {
          this.openScenarios[hiddenElement] = false;
        } else {
          this.openSections[hiddenElement] = false;
        }
      });


      this.setInitialStyles();
    } catch(e) {
      console.log('ERROR: ', e);
    }
  }

  /**
 * handleData updating internal state variables
 * based on the retrieved data, and triggering additional actions such as getting collapsible elements.
 * @param {string} clientId - The unique identifier of the client.
 */
  async handleData(clientId: string) {
    try {
      this.loading = true;
      if (this.response.scheduleDCalculator) {
        this.scheduleDCalculator = this.response.scheduleDCalculator;
        this.loading = false;
        this.stcglSection = this.scheduleDCalculator[0];
        this.ltgclSection = this.scheduleDCalculator[1];
        this.summarySection = this.scheduleDCalculator[2];
        this.howManyScenarios = this.stcglSection.scenariosData.length;
        this.stcglSection.scenariosData.forEach((somProps ,index) => {
          this.openScenarios[`scenario_${index}`] = true;
        });

        setTimeout(() => {
          this.getCollapsableElements();
        }, 50);
      }

    } catch(e) {
      console.log('Error: ', e);
    }
  }

  /**
 * Scrolls the horizontal scrollbar of a specified element based on the given identifier.
 * @param {string} id - The identifier indicating the direction of horizontal scrolling ('lastScenario' or 'firstScenario').
 */
  scrollHorizontal(id: string) {
    const calculatorScenarios = document.getElementById('calculatorScenarios');
    if (id === 'lastScenario'){
      calculatorScenarios.scrollLeft += 370;
    } else if (id === 'firstScenario'){
      calculatorScenarios.scrollLeft -= 370;
    }
  }

  seeValues(event: any) {

  }

  currentDataToStandarCalcData() {
  }
}
