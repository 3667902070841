import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar} from '@angular/material/snack-bar';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../../../shared/services/auth/authentication.service';
import {getEndPointUrlByContext} from '../../../shared/helpers/url.helper';
import {CloseDialogAtLogOut} from 'app/shared/helpers/CloseDialogAtLogOut.class';
import {environment} from 'environments/environment';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
  selector: 'client-data-dialog',
  templateUrl: 'client-data-dialog.html',
})

export class ClientDataDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, {static: true}) submitButton: MatButton;

  ClientDataForm: FormGroup;

  public clientID: string;
  public updateClientInfo = getEndPointUrlByContext('ClientProfile');
  private clientOngingPlanning = getEndPointUrlByContext('OngoingPlanning');
  public services: string[] = ['Accountant', 'Assistant', 'Attorney', 'Broker', 'Finantial Advisor'];
  public model: any = {
    clientIDFirstName: "",
    clientIDLastName: "",
    clientIDAge: "",
    email: "",
    // address:"",
    clientIDOccupation: "",
    clientIDNetWorth: ""
  };

  public isAdvisor: boolean = false;
  public mailExistsAlready: boolean = false;
  private validateClientEmail = environment.apiValidateEmail;
  public clientHasAnswered: any;
  public submitting: boolean = false;
  public advisorPreferences: any;

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<ClientDataDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public currentClientService: ClientService
  ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
    this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.isAdvisor = this.authService.isAdvisor();
    //Load client ID form local storage
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = storagedClient.clientId;

    
    this.data.clientIDAge ? '' : this.data.clientIDAge = 0;

    this.model = {
      clientIDFirstName: this.data.clientIDFirstName,
      clientIDLastName: this.data.clientIDLastName,
      email: this.data.email,
      clientIDAge: this.data.clientIDAge,
      // address: this.data.clientIDAddress,
      clientIDOccupation: this.data.clientIDOccupation,
      clientIDNetWorth: this.data.clientIDNetWorth,
    }

    
    let clientAgeWODecimals = Math.floor(this.model.clientIDAge);

    this.ClientDataForm = new FormGroup({
      clientIDFirstName: new FormControl(this.model.clientIDFirstName, [Validators.required, this.noWhitespaceValidator]),
      clientIDLastName: new FormControl(this.model.clientIDLastName, [Validators.required, this.noWhitespaceValidator]),
      email: new FormControl(this.model.email, [Validators.required, Validators.email]),
      clientIDAge: new FormControl({value: clientAgeWODecimals, disabled: true}, Validators.required),
      // address: new FormControl(this.model.address, Validators.required),
      clientIDOccupation: new FormControl({value: this.model.clientIDOccupation, disabled: !this.clientHasAnswered['ansGeneral']}, [Validators.required, this.noWhitespaceValidator]),
      clientIDNetWorth: new FormControl({value: this.model.clientIDNetWorth, disabled: !this.clientHasAnswered['ansGeneral']}, Validators.required),
    });

    this.advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    console.log('this.advisorPreferences*', this.advisorPreferences);
  }

  save() {

    this.dialogRef.close(this.ClientDataForm.value);
    this.submitting = true;
    // this.submitButton.disabled = true;

    //Load data from form and update model
    for (let prop in this.ClientDataForm.value) {
      this.model[prop] = this.ClientDataForm.value[prop].trim();
    }

    let savePropV2 = (prop: string) => {
      let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
      let url = `${this.updateClientInfo}${this.clientID}/${this.data['id' + prop]}`;
      let ongoingUrl = `${this.clientOngingPlanning}${this.clientID}`;
      let data: any = {};
      data[prop] = this.model[prop];
      
      this.http.patch<any>(`${environment.apiV2ClientProfile}/${this.clientID}`, JSON.stringify(data), options).subscribe(
        success => {
          
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Something wrong happened!.", "Dismiss", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        }
      );

      if (this.isAdvisor) { //This is only for advisors

        this.http.put<any>(ongoingUrl, JSON.stringify(data), options).subscribe(
          success => {
            
          },
          error => {
            console.log('Error!!!');
            this.snackBarError.open("Something wrong happened!.", "Dismiss", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
          }
        );

      }
    };

    let saveData = () => {
      let options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
      let url = `${this.updateClientInfo}${this.clientID}`;
      let ongoingUrl = `${this.clientOngingPlanning}${this.clientID}`;
      let body = {
        clientIDFirstName: this.model.clientIDFirstName,
        clientIDLastName: this.model.clientIDLastName,
        clientIDAge: this.model.clientIDAge,
        // address: this.model.clientIDAddress,
        clientIDOccupation: this.model.clientIDOccupation,
        clientIDNetWorth: this.model.clientIDNetWorth,
      }

      this.http.put<any>(url, JSON.stringify(body), options).subscribe(
        success => {
          
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Something wrong happened!.", "Dismiss", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        }
      );

      if (this.isAdvisor) { //This is only for advisors

        this.http.put<any>(ongoingUrl, JSON.stringify(body), options).subscribe(
          success => {
            
          },
          error => {
            console.log('Error!!!');
            this.snackBarError.open("Something wrong happened!.", "Dismiss", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
          }
        );

      }
    };

    //If is there a change in data, update
    saveData();
    if (this.model.email !== this.data.email) savePropV2('email');

    
    

    //Update current client local Storage
    let localStorageClientData = JSON.parse(sessionStorage.currentClient);
    localStorageClientData.name = this.model.clientIDFirstName;
    localStorageClientData.lastName = this.model.clientIDLastName;
    localStorageClientData.email = this.model.email;
    
    sessionStorage.setItem("currentClient", JSON.stringify(localStorageClientData));
    this.submitting = false;
    this.currentClientService.refreshAll()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSearchInputChange(e) {
    let searchValue = e.srcElement.value;
    this.doEmailValidation(searchValue);
  }

  doEmailValidation(searchValue: string) {

    
    let options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    let body = new URLSearchParams();
    body.set('email', searchValue);

    this.http.post<any>(this.validateClientEmail, body.toString(), options).subscribe(
      success => {
        this.mailExistsAlready = false;
      },
      error => {
        this.mailExistsAlready = searchValue !== this.model.email ?  true : false;
        console.log(error, 'ERROR. didEmailExist', this.mailExistsAlready);
      }
    )

  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
