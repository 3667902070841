import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class SnackbarUtil {
    constructor(
        private snackbar: MatSnackBar,
        private errorSnackbar: MatSnackBar,
    ) {}

    success(message: any = '', action: any = '') {
        this.snackbar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: 'success-snackbar'
          });
    }


    error(message: any = '', action: any = '') {
        this.errorSnackbar.open(message, action, {
            duration: 10000,
            verticalPosition: 'bottom',
            panelClass: 'error-snackbar'
          });
    }
    
}