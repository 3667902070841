//Angular
import { Component, OnInit, ViewChild, Inject, AfterViewChecked, ElementRef } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';

//3rd Party
import { FileUploaderOptions, UPLOADER_STATUS } from "../file-uploader-component/file-uploader-component.component";
import { saveAs } from 'file-saver';

//Project
import { WINDOW } from "../../../shared/helpers/window.provider";
import { AdvisorService } from "../../../shared/services/advisor.service";
import { auto_questions } from "./auto.model";
import { QuestionnaireController } from '../Questionnaire.controller';
import { CONST_ABSOLUTES, CONST_BOOLEANS, CONST_HOMEINSURANCECOMPANIES, CONST_AUTOCOMPANIES, CONST_AUTOPOLICYVENDORS, CONST_STATES } from '../Questionnaire.constants'
import { AuthenticationService } from "../../../shared/services/auth/authentication.service";
import { propStringValidator, uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { getYearsArraySince } from '../HelperFunctions';
import { Area } from '../../../shared/helpers/areas.catalog';
import { environment } from "../../../../environments/environment";
import { DialogGenericNoticeComponent } from "app/shared/components/dialog-generic-notice/dialog-generic-notice.component";
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from "app/shared/helpers/url.helper";

@Component({
  selector: "app-q-auto",
  templateUrl: "./q-auto.component.html",
  styleUrls: ["./q-auto.component.scss"]
})
export class QAutoComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild("autoform", { static: true }) autoform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: auto_questions;
  public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientHasAnsweredQuestionnaireHome: boolean;
  public clientData: any;
  public extractionResults: any[] = [];
  public uploaderStatus: any = UPLOADER_STATUS;
  public fileUploadersOptionsArray: FileUploaderOptions[];
  public carPolicyUploaderOptions: FileUploaderOptions;
  public whoAnswered: string;
  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_BOOLEANS, CONST_HOMEINSURANCECOMPANIES, CONST_AUTOCOMPANIES, CONST_AUTOPOLICYVENDORS, CONST_STATES };
  public validations = Validations;

  // public yearsList: any[];
  public yearsList: number[] = getYearsArraySince(2000).reverse();

  public carName: string = '';

  public isAdvisor: boolean = false;

  public ntabs: number;
  public progress: number;
  public progressnumber: number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public clientLivesIn: string = '';

  public bulletColor;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  public questIndex: number;
  // public isFirstTimeHere: string;
  public isManualInputEnabled: boolean = true;

  public toggleSearchBar: boolean = false;
  public hasInsuranceBetaAccess: boolean = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private advisorService: AdvisorService,
    public dialog: MatDialog,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    @Inject(WINDOW) private window: Window
  ) {}

  private commonAutoSaveFunction = () =>{



    // Autosave only if there is no data waiting
    let uploaders = [this.carPolicyUploaderOptions];
    let areAnyUploaderAlive: boolean = uploaders.find(uploader => uploader.isComponentAlive) != undefined;
    let areAnyUploadBeeingExtracted: boolean = uploaders.find(uploader => {return uploader.status == UPLOADER_STATUS.FILE_VALIDATING  || uploader.status == UPLOADER_STATUS.FILE_PROGRESS}) != undefined;
    // Save if uploader isn't alive or any aploader is beeing extracted.
    if(!areAnyUploaderAlive || !areAnyUploadBeeingExtracted){
      this.submit(true, true);
    }else{
      console.log("WARNING. Waiting for extractions. Autosave disabled");
    }

  }

  async getInsuranceCompanies() {
    const ans: any = await this.advisorService.getInsuranceProviders();
    console.log(ans);
    this.CONSTANTS.CONST_AUTOCOMPANIES = ans.map(({ showName }) => showName);
    this.CONSTANTS.CONST_HOMEINSURANCECOMPANIES = ans.map(({ showName }) => showName);
  }

  ngOnInit() {
    this.getInsuranceCompanies();
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));

    this.clientLivesIn = this.clientData.livesIn;


    const auxBetaInfo = JSON.parse(localStorage.getItem('betaAccess'));
    /*
    if (auxBetaInfo.includes('insuranceSnapshot') || !urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION)) {
      this.hasInsuranceBetaAccess = true;
    } else {
      this.hasInsuranceBetaAccess = false;
    }
    */
    //Initialize the questionnaire model
    this.model = new auto_questions();

    this.isAdvisor = this.authService.isAdvisor();

    //Load session storage data from questionnare
    let loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('auto');
    this.questIndex = loadedStoragedQuestionnaireData.index != null ? loadedStoragedQuestionnaireData.index : 1;
    this.carName = (loadedStoragedQuestionnaireData.name != null) ? loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = this.carName;

    console.log('LOADED. ', loadedStoragedQuestionnaireData);

    /*this.isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    if (this.isFirstTimeHere === 'auto') {

      //Is the first time here? Show modal
      this.dialog.open(DialogFirstTime, {
        panelClass: 'modal-dialog-questionnaire',
        height: '250px',
        width: '500px',
        data: {hasUploader: true}
      });
    }*/

    if (!loadedStoragedQuestionnaireData.answered) {
      //Make a forced autosave to create a fresh questionnaire where the extractions can live.
      if (this.model) this.submit(true, true);
    }

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputPolicyData = 'No'

    //Load extraction results for file uploaders components
    this.reloadExtractions();

    this.carPolicyUploaderOptions = new FileUploaderOptions(
      {
        fileID: 'clientIDAutoPolicy',
        fileVal: this.model.clientIDAutoPolicy,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'auto', false, this.questIndex, 'clientIDAutoPolicy'),
        documentReadableName: 'policy',
        actionsAfterResponse: {
          //validatedValid : ()=>{ this.openNoticeNeedsReview() },
          validatedVoid : ()=>{ this.openNoticeNeedsReview() }
          //validatedVoid: () => { this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'auto', this.questIndex, 'clientIDAutoPolicy', () => { this.model.clientIDWantsToInputPolicyData = 'Yes' }) }
        }
      }
    )



    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansAuto;


    //Load questionnarie data, if clientHasAnswered
    if(loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, the load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'auto', this.model, this.questIndex).then(response => {


        // Wait for the data before starting the autosave function
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        //this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      //this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    //Get home data to load cross insurance company questions
    this.clientHasAnsweredQuestionnaireHome = clientHasAnswered.ansHome;
    //Always load a new asset, only for an empty quest
    this.model.clientIDCarsOwned = 1;

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...this.model,
    };
    if (this.hasInsuranceBetaAccess) {
      this.getActivityFromHome();
    }
  }

  async getActivityFromHome() {
    const ans: any = await this.advisorService.getInsuranceActivity(this.clientData.clientId || localStorage.getItem('userId'), 'auto');
    console.log(ans);
    if (ans[0].data[`${this.questIndex}`]) {
      this.model.clientIDClientHasAutoInsurance = !!ans[0].data[`${this.questIndex}`].clientIDAutoPolicy ? 'Yes' : 'No';
      this.model.clientIDAutoInsuranceCompany = ans[0].data[`${this.questIndex}`].clientIDAutoInsuranceCompany;
      this.model.clientIDWantsToInputPolicyData = 'Yes';
    }

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  ngOnDestroy() {
    this.qController.deleteStoragedQuestionnareDatByArea('auto');
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  onAnyUploaderReset($event: string) {
    //Reset the policy selector to force the selection of one.
    if ($event == 'clientIDAutoPolicy') this.model.clientIDAutoInsuranceCompany = '';
    this.model[$event] = '';
    this.extractionResults[$event] = '';
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false, isManualValidation: boolean = false) {

    let hasManualInput = this.model.clientIDWantsToInputPolicyData === 'Yes';

    validateAndSubmit(
      [
        propStringValidator(this, [{ value: this.model.clientIDAutoInsuranceCompany, name: 'insurance company' }]),
        //uploadersValidator(this, [this.carPolicyUploaderOptions])
      ],
      () => {
        this.qController.submitForm(this, this.model, this.clientData.clientId, 'auto', saveForLater, null, silentSave, hasManualInput, isManualValidation);
        //Set file uploader status to valid if the file is under review and manual opt input is enabled
        if(Boolean(this.carPolicyUploaderOptions)  && this.carPolicyUploaderOptions.status == UPLOADER_STATUS.FILE_UNDER_REVIEW && hasManualInput){
          this.qController.advisorService.setTaxUploaderStatusToSubmitted(this.clientData.clientId, 'auto', '', String(this.questIndex));
        };
      },
      saveForLater
    )
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  getYearsArraySince(startYear: number): number[] {
    let length = new Date().getFullYear() - startYear;
    return [startYear].concat(Array.from({ length: length }, (v, k) => startYear + k + 1));
  }

  countTabs(tabs: number) {
    //tabs+=1;
    this.progress = 100 / tabs;
  }

  blockManualInput() {
    this.isManualInputEnabled = false;
  }

  onFileUploaderResponse() {

    if (this.carPolicyUploaderOptions.status == UPLOADER_STATUS.FILE_VALID) {
      //Load the extractions data

      this.qController.loadExtractionsDataToModel(this.model, auto_questions, this.clientData.clientId, 'auto', this.questIndex);
      // If first time in the quest, display visual aid
      // if (this.isFirstTimeHere === 'auto') this.qController.openModalWhatToDoNext(this);
    }

    let endOfInteractionStates = [UPLOADER_STATUS.INITIAL, UPLOADER_STATUS.FILE_INVALID, UPLOADER_STATUS.FILE_VALID];

    // After both uploaders are on a valid end of interacion status, re-enable the manual input option
    if (endOfInteractionStates.includes(this.carPolicyUploaderOptions.status)) {
      this.isManualInputEnabled = true;
    }

  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'auto'}/?options=${this.model.clientIDClientHasAutoInsurance}`, options)
      .subscribe(blob => {
        saveAs(blob, 'auto-questionnaire.pdf');
      });
  }

  /**
   * @function isInsurancePolicyCompatible
   * @param insurnaceCompany
   * @returns boolean
   * @description Compares if the insurnace company is in the compatibility list
   */

  isInsurancePolicyCompatible(insurnaceCompany: string): boolean {
    return ['Geico'].includes(insurnaceCompany)
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  //Solve bug. When uploader resets by manual/auto selection, load the prevoius extractions state.
  onManuallyInputPolicyChange(){

    if(this.model.clientIDWantsToInputPolicyData == 'No'){
      this.reloadExtractions();
    }

    /* if(this.model.clientIDWantsToInputPolicyData == 'No'){
      this.qController.throwConfirmationInteraction(
        "Attention. All your file-related input will be replaced by the document content.",
        () => { this.reloadExtractions(); },
        () => { this.model.clientIDWantsToInputPolicyData = 'Yes'; }
      );
    }else{
      this.qController.throwConfirmationInteraction(
        "Attention. Any change in data will be automatically saved. All the file-related data will be replaced with your manual input.",
        () => { ; },
        () => { this.model.clientIDWantsToInputPolicyData = 'No'; }
      );
    } */

  }

  reloadExtractions() {
    //Load extraction results for file uploaders components

    this.qController.loadExtractionsResults(this.clientData.clientId, 'auto', String(this.questIndex)).then(result => {
      this.extractionResults = result;
    });
  }

  openNoticeNeedsReview(){
    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-review-extractions',
      width: '55vw',
      data: {
        title: 'Notice',
        body:
          `<strong>Based on our initial reading of your document, our proprietary validation algorithm is raising a flag.<br>
          It may be the quality of the document, or inconsistencies in the numbers extracted</strong><br><br>
          Our team will make the extractions accessible in under one business hour (CT). A confirmation email
          will be sent to you when the process is complete.<br>
          If you are receiving this notice after business hours the extractions will be accessible by the morning of the next business day.
          <br><br>
          `,
        actionButtons: [
          {
            text: "Notify Me When Ready",
            class: 'button-primary',
            action: () => {
              dialogRef.close();
              this.qController.advisorService.retainAreaFile(this.clientData.clientId, 'auto', this.questIndex, 'clientIDAutoPolicy', 'manual').then(response => {

                this.snackBarSuccess.open("We have received the return and the data will be available shortly.", "OK", {
                  duration: 3000,
                  panelClass: 'success-snackbar'
                });
                //Redirect, save for later
                this.submit(true);
            })
            }
          }
        ]
      }
    });
  }

}
