import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesforce-mapper',
  templateUrl: './salesforce-mapper.component.html',
  styleUrls: ['./salesforce-mapper.component.css']
})
export class SalesforceMapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
