import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuestionnaireResponse, RelativeGruop } from 'app/shared/model/questionnaires/Questionnaires.model';
import { beneficiaries } from 'app/shared/components/estate-lab-v2/facts/dataFacts';

export const relationshipFieldsToRenderize = {
    Children: [
        'birthdate', 'fullname', 'parents', 'fullTimeCollageStudent', 'hasDisability', '_id', 'canDelete'  
    ],
    Grandchildren: [
        'birthdate', 'fullname', '_id', 'canDelete'
    ],
    Beneficiaries: [
        'fullname', 'relation', '_id', 'canDelete'
    ]
}

export const relativesCatalog = {
    children: {
        fullname : {
            label: "Name",
            placeholder: "Enter name",
            key: "fullname",
            type: "text",
            defaultValue: "",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
        birthdate: {
            label: "Date of birth",
            key: "birthdate",
            type: "calendar",
            placeholder: "MM/DD/YYYY",
            defaultValue: "",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                },
                {
                    key: 'matDatepickerMax' ,
                    message: 'Clients must be 18 years old or older'
                },
                {
                    key: 'matDatepickerMin' ,
                    message: 'Invalid field '
                }
            ]
        },
        parents: {
            label: "Parent(s)",
            key: "parents",
            type: "select",
            placeholder: "Select parents",
            options: [],
            defaultValue: "",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
        fullTimeCollageStudent: {
            label: "Full time college student",
            key: "fullTimeCollageStudent",
            type: "radio",
            defaultValue: "",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
        hasDisability: {
            label: "Disability?",
            key: "hasDisability",
            type: "radio",
            defaultValue: "",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
    },
    grandchildren :   {
        fullname: {
            label: "Name",
            placeholder: "Enter name",
            key: "fullname",
            type: "text",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
        birthdate: {
            label: "Date of birth",
            key: "birthdate",
            type: "calendar",
            placeholder: "MM/DD/YYYY",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
    },
    beneficiary :   {
        fullname: {
            label: "Name",
            placeholder: "Enter name",
            key: "fullname",
            type: "text",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
        relation: {
            label: "Relationship",
            placeholder: "Relationship",
            key: "relation",
            type: "select-with-open-field",
            errors: [
                {
                    key: 'required' ,
                    message: ' Required field'
                }
            ]
        },
    },
}

export const relationshipFieldsToRenderizeForGrandchildren = [
    'birthdate', 'fullname', 'parents', 'fullTimeCollageStudent', 'hasDisability'
]

export const modalRelativeTitles = {
    children: 'Add child',
    grandchildren: 'Add grandchild',
    beneficiary: 'Add beneficiary',
}

//TODO: resolver para grandchildren y para otros
export const clientFamilyDTO = (familyFromService: RelativeGruop[]):any[] =>{
    const formGroup = [];
    familyFromService.forEach((group, index) => {
        formGroup[index] = {
            type: group.type,
            forms: []
        }
        group.data.forEach((relative, jndex) => {
            const form: FormGroup = new FormGroup({});
            const keys = Object.keys(relative) 
            keys.forEach((key) => {
                if(relationshipFieldsToRenderize[group.type].includes(key)){
                    form.addControl(key, new FormControl(relative[key] || "", Validators.required));
                }
                if(typeof relative[key] == 'object' && relative[key] != null){
                    const internalKeys = Object.keys(relative[key]) 
                    internalKeys.forEach((jey) => {
                        if(relationshipFieldsToRenderize[group.type].includes(jey)){
                            form.addControl(jey, new FormControl(relative[key][jey] || ""));
                        }
                    })
                }
            })
            formGroup[index].forms.push(form)
            
        })
    })
    return formGroup
}

export const tableClientFamilyDTO = (familyFromService: RelativeGruop[], validRelationships: any[]) => {
    const tableGroup = [];
    familyFromService.forEach((group, index) => {
        tableGroup[index] = {
            type: group.type,
            table: []
        }
        const table  = []
        group.data.forEach((relative, jndex) => {
            const tableRow = {}
            const keys = Object.keys(relative) 
            keys.forEach((key) => {
                if(relationshipFieldsToRenderize[group.type].includes(key)){
                    tableRow[key] = relative[key];
                }
                if(typeof relative[key] == 'object' && relative[key] != null){
                    const internalKeys = Object.keys(relative[key]) 
                    internalKeys.forEach((jey) => {
                        if(relationshipFieldsToRenderize[group.type].includes(jey)){
                            if(jey === 'parents'){
                                tableRow[jey] = buildParentsText(validRelationships[1].options, relative[key][jey])
                                tableRow['parents_ids'] = buildParentsText(validRelationships[1].options, relative[key][jey])
                            }
                            else{
                                tableRow[jey] = relative[key][jey];
                            }
                            
                        }
                    })
                }
            })
            tableGroup[index].table.push(tableRow)
        })
    })
    return tableGroup
}


export const  validateDate = (dateString: string): Date | string => {
    let date;

    if(dateString == '0'){
        return ''
    }
    
    // Intenta parsear como fecha ISO
    date = new Date(dateString);
    if (!isNaN(date.getTime())) {
        return date;
    }

    // Intenta parsear como timestamp
    const timestamp = parseInt(dateString, 10);
    if (!isNaN(timestamp)) {
        date = new Date(timestamp);
        if (!isNaN(date.getTime())) {
            return date;
        }
    }

    // Intenta parsear como fecha completa
    date = new Date(Date.parse(dateString));
    if (!isNaN(date.getTime())) {
        return date;
    }

    return '';
}

export const buildParentsText = (options: any[], values: string[]) => {
    let parentsText = ''
    options.forEach((option) => {
        const newText = parentsText ? `${parentsText} and ${option.text}` : option.text
        parentsText = values.includes(option.key) ? newText : parentsText;
    })
    return parentsText
}

export const rowTableToForm = (rowTable, type: string) => {
    const form: FormGroup = new FormGroup({}); 
    Object.keys(relativesCatalog[type]).forEach((key, index) => { 
        if (key == 'birthdate') {
            form.addControl(key, new FormControl(validateDate(rowTable[key]) || "",  Validators.required));
        }
        else{
            form.addControl(key, new FormControl(rowTable[key] || "",  Validators.required));
        }
    })
    return form;
}

export const rawChildToForm = (rawChild, type: string) => {
    const form: FormGroup = new FormGroup({}); 
    Object.keys(relativesCatalog[type]).forEach((key, index) => {
        form.addControl(key, new FormControl(rawChild[key] || "",  Validators.required));
    })
    return form;
}

export const formToRowTable = (form :FormGroup) => {
    const tableRow = {}
    Object.keys(form.controls).forEach((key, index) => {
        tableRow[key] = form.controls[key].value
    })
    return tableRow;
}

export const childFormToRelative = (form :FormGroup, relationshipType: string) => {
    if (relationshipType == 'children') {
        const relative = {
            "fullname": form.controls['fullname'].value,
            "relation": 'Child',
            "birthdate": form.controls['birthdate'].value,
            "type": relationshipType.charAt(0).toUpperCase() + relationshipType.slice(1),
            "addons": {
            fullTimeCollageStudent: form.controls['fullTimeCollageStudent'].value,
            hasDisability: form.controls['hasDisability'].value,
              "parents": typeof form.controls['parents'].value == 'object' ?
                form.controls['parents'].value : 
                [form.controls['parents'].value]
            }
          }
        return relative;
    }
    if (relationshipType == 'grandchildren') {
        const relative = {
            "fullname": form.controls['fullname'].value,
            "relation": 'Grandchild',
            "birthdate": form.controls['birthdate'].value,
            "type": relationshipType.charAt(0).toUpperCase() + relationshipType.slice(1),
          }
        return relative;
    }
    if (relationshipType == 'beneficiary') {
        const relative = {
            "fullname": form.controls['fullname'].value,
            "relation": form.controls['relation'].value,
            "type": "Beneficiaries",
          }
        return relative;
    }
    
}