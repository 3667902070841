import { BoxNodeInterface } from "../estate-lab-v2/commons/boxes/BoxeNode.interface";
import { FlowChartBoxNodeInterface } from "../estate-snapshot-v3/flowchart-box/flowchart-box.interface";

export const MARGIN_PX = 40;
export interface GraphMap{
   nodes: GraphNode[],
   grid: GraphMapGrid,
   connections?: GraphMapConnection[],
   nodesType?: string

}

export interface GraphMapGrid {
  rowsNodesById: string[][],
  rowsMetadata?: any[],
  maxGridCols?: number,
  fixedNodeWidthInPx?: number
}

export interface GraphMapConnection {
  sourceNodeId: string | string[],
  nodeConnections: GraphConnection[],
  isForced?: boolean //Used to display connections without caring about other nodeBoxes.
}

export interface GraphNode {
  id: string,
  content: string|GraphContent|BoxNodeInterface,
  coordinates?: Point,
  metaData?: any
}

export interface GraphConnection{
  pointsToNodeId: string,
  anchorOrigin?: ("T" | "R" | "B" | "L"), //Top, Right, Bottom, Left
  anchorDestination?: ("T" | "R" | "B" | "L"), //Top, Right, Bottom, Left
  description?: string,
  type?: ("INFLOW" | "OUTFLOW" | "INDIRECT"),
  vertices?: Point[],
  metaData?: any
}

export interface Point {
  x: number,
  y: number
}

export interface NodeAnchors{
  T: Point,
  R: Point,
  B: Point,
  L: Point
}

export interface GraphContent{
  title: string,
  subtitle?: string,
  table?: (string[][])
  htmlBody?: string
}

export class GraphMapClass implements GraphMap {

  public nodes: GraphNode[];
  public grid: GraphMapGrid;
  public connections?: GraphMapConnection[];

  constructor(graph: GraphMap){
    this.nodes = graph.nodes;
    this.grid = graph.grid;
    this.connections = graph.connections;
  }

  findNodeById(nodeId: string){
    return this.nodes.find(grapNode => {return grapNode.id == nodeId});
  }

  public handleNodeMetadata(node: GraphNode, metaKey: string, metaValue?: any): any {

    let nodeMetadata = {}
    if (Boolean(node.metaData)) { nodeMetadata = node.metaData };
    if (Boolean(metaValue)) {
      nodeMetadata[metaKey] = metaValue;
      node.metaData = nodeMetadata;
    }

    return nodeMetadata[metaKey];

  }

  getNodeHtmlId(nodeId: string){

  }

}

/**
 * Represnet a boxNode and anchor or a graph node.
 * If anchor is set, then the case is a boxNode.
 * Used for generateAlternativePath function
 */
export interface ConnectionNode{
  nodeID: string,
  anchor?: ("T" | "R" | "B" | "L"),
}
