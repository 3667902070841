import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { prospect_questions } from 'app/views/questionnaires/q-new-prospect/prospect.model';
import { QuestionnaireController } from 'app/views/questionnaires/Questionnaire.controller';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'prospect-dashboard-data-component',
  templateUrl: 'prospect-dashboard-data.component.html',
  styleUrls: ['prospect-dashboard-data.component.scss']
})

export class ProspectDashboardDataComponent implements OnInit{

  constructor(
    private qController: QuestionnaireController,
    public authService: AuthenticationService,) {
  }

  public clientGeneralQuestionnaireData: prospect_questions;
  public clientData: any;
  public isDataAvailable: boolean = true;
  public prospectingQuestions: any = {};
  public prospectingQuestionsKeys: string[] = [];
  public formattedPhoneNumber: string = '';

  public prospectName: string = '';

  @Input() view: string = '';

  ngOnInit() {
    let prospectingPreferences = JSON.parse(localStorage.getItem('prospectingPreferences'));
    this.prospectingQuestions = prospectingPreferences ? prospectingPreferences.leadPageCustomQuestions : {};
    this.prospectingQuestionsKeys = this.prospectingQuestions ? Object.keys(this.prospectingQuestions) : [];

    let currentClient: string = sessionStorage.getItem('currentClient');
    this.prospectName = JSON.parse(currentClient).name;

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientId = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');
    this.clientGeneralQuestionnaireData = new prospect_questions();

    this.qController.loadQuestionnaireDataAsync(clientId, 'general', this.clientGeneralQuestionnaireData).then(response => {

      if(Boolean(this.clientGeneralQuestionnaireData.clientIDPhoneNumber)){
        let phonePartOne   =  this.clientGeneralQuestionnaireData.clientIDPhoneNumber.substring(0, 3);
        let phonePartTwo   =  this.clientGeneralQuestionnaireData.clientIDPhoneNumber.substring(3, 6);
        let phonePartThree =  this.clientGeneralQuestionnaireData.clientIDPhoneNumber.substring(6, 10);
        this.formattedPhoneNumber = `(${phonePartOne}) ${phonePartTwo}-${phonePartThree}`;
      }

    })
  }

  downloadPdf(){

    const options = {
      margin: 15,
      filename: 'prospect-dashboard.pdf',
      image: {type: 'jpeg', quality: 1},
      html2canvas: {allowTaint: false,
                    useCORS: true,
                    scale: 4 },
      jsPDF: {orientation: 'portrait'}
    }


    let prospectCanvas = this.generateCanvas();

    html2pdf()
    .from(prospectCanvas)
    .set(options)
    .save().catch((error)=>{
      console.log("ERROR", error);
    });

  }


  generateCanvas() {

    const content: Element = document.getElementById('prospect_dash_content');
    var content2 = <HTMLElement> content.cloneNode(true);

    let item: NodeListOf<HTMLElement> = content2.querySelectorAll('#items-content');
    for(var i = 0; i<item.length;i++){
        //item[i].style.setProperty("border-bottom", "0px solid #3e4042", "important");
        item[i].style.borderBottom = '0px solid #3e4042';
    }

    let data: NodeListOf<HTMLElement> = content2.querySelectorAll('.prospect-data');
    for(var i = 0; i<data.length;i++){
        data[i].style.fontSize = '18px';
        data[i].style.fontWeight = '600';
        data[i].style.color = 'black';
    }

    let titleData: NodeListOf<HTMLElement> = content2.querySelectorAll('.title-prospect-data');
    for(var i = 0; i<titleData.length;i++){
        titleData[i].style.fontSize = '12px';
        titleData[i].style.color = '#555555';
        titleData[i].style.fontSize = '12px';
        titleData[i].style.setProperty("line-height", "35px", "important");
    }

    let titleExtra: NodeListOf<HTMLElement> = content2.querySelectorAll('.title-extra-info');
    for(var i = 0; i<titleExtra.length;i++){
        titleExtra[i].style.setProperty("margin-bottom", "0px", "important");
    }

    /* let imageNetworth: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-networdth');
    imageNetworth[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/01_gd_networthgray.svg'; */

    // let imageGender: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-gender');
    // imageGender[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/02_gd_gendergray.svg';

    // let imageAge: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-age');
    // imageAge[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/03_gd_birthdaygray.svg';

    // let imageStatus: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-status');
    // imageStatus[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/04_gd_martialstatusgray.svg';

    // let imageState: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-state');
    // imageState[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/05_gd_stategray.svg';

    // let imageEmployment: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-employment');
    // imageEmployment[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/06_gd_employmentgray.svg';

    // let imageBusiness: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-business');
    // imageBusiness[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/07_gd_businessgray.svg';

    // let imageChildren: NodeListOf<HTMLImageElement> = content2.querySelectorAll('#image-children');
    // imageChildren[0].src = 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/08_General_Data/08_gd_childrengray.svg';

    return content2;

  }


}
