import { JwtHelperService } from '@auth0/angular-jwt';

export class SessionToken {

    public userId: string = '';
    public encodedToken = '';
    public static helper: JwtHelperService;

    constructor(
        public exp: number,
        public iat: number,
        public type: string,
        public userType: string,
        public idAdvisor?: string,
        public idClient?: string,
    ) {
        this.userId = this.userType == 'advisor' ? this.idAdvisor : (this.userType == 'client' ? this.idClient : '');
        if(SessionToken.helper == undefined){
          SessionToken.helper = new JwtHelperService();
        }

    }

    public static sessionTokenFromEncoded(encodedToken: string): SessionToken {
        let helper = new JwtHelperService();
        let decodedTokenData = helper.decodeToken(encodedToken);
        let sessionToken = new SessionToken(
            decodedTokenData.exp,
            decodedTokenData.iat,
            decodedTokenData.type,
            decodedTokenData.userType,
            decodedTokenData.idAdvisor ? decodedTokenData.idAdvisor : undefined,
            decodedTokenData.idClient ? decodedTokenData.idClient : undefined,
        );
        sessionToken.encodedToken = encodedToken;
        return sessionToken;
    }

    public static plainDecode(encodedToken: string): Object{
        let helper = new JwtHelperService();
        return helper.decodeToken(encodedToken);
    }

    public static getFromLocalStorage(){
        let localToken = localStorage.getItem('sessionToken');
        if(localToken){
            return SessionToken.sessionTokenFromEncoded(localToken);
        }else{
            console.log("ERROR. No token in local storage");
            return null;
        }
    }

    isValid():boolean{
      return SessionToken.helper.isTokenExpired(this.encodedToken);
    }

}
