import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';

const URL = 'http://localhost:3000/fileupload/';

@Component({
    selector: 'image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploader implements OnInit {

    @Output() sendData: EventEmitter<any> = new EventEmitter();
    @Input() title: string;
    @Input() minWidth: number = 1; //(in pixels)
    @Input() minHeight: number;
    @Input() isImageUploading: boolean = false;
    @Input() isImageSaved: boolean | string;
    @Input() imageURL: string;
    @Input() advisorName: string;
    @Input() advisorEmail: string;
    @Input() view: string;

    private imageTitle: string;
    private imageWidth: string;
    private imageHeight: string;
    private imageError: string;
    private cardImageBase64: string;
    public uploader: FileUploader;
    public localImageUrl: any = '';

    constructor(
        public snackBarError: MatSnackBar,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit() {

        setTimeout(()=>{
          console.log("imageURL", this.imageURL);
        },200)
        if(this.imageURL){
            this.localImageUrl = this.imageURL;
        }

        this.uploader = new FileUploader({
            //url: URL,
            disableMultipart: false,
            //autoUpload: true,
            //method: 'post',
            itemAlias: 'attachment',
            allowedFileType: ['image']
        });


        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
        };

    }

    // All changes to input data will be processed here.
    ngOnChanges(changes: SimpleChanges) {
        this.localImageUrl = changes.imageURL.currentValue;
    }


    public onFileSelected(fileInput: EventEmitter<File[]>) {
        this.imageError = null;

        if (fileInput && fileInput[0]) {
            // Size Filter Bytes
            /* const max_size = 20971520; */
            const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
            const min_width = this.minWidth;
            const min_height = this.minHeight;

            /* if (fileInput[0].size > max_size) {
                this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                console.error(this.imageError);
                return false;
            } */

            if (!allowed_types.includes(fileInput[0].type)) {
                this.imageError = 'Only Images are allowed ( JPEG | JPG | PNG )';
                this.snackBarError.open(`File format not supported. Please upload a JPEG, JPG or PNG image.`, "Dismiss", {
                    duration: 4000,
                    panelClass: 'error-snackbar'
                  });
                console.error(this.imageError);
                return false;
            }

            this.imageTitle = fileInput[0].name;

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();

                image.src = e.target.result;
                image.onload = rs => {
                    const img_width = rs.currentTarget['width'];
                    const img_height = rs.currentTarget['height'];

                    this.imageWidth = img_width;
                    this.imageHeight = img_height;

                    if (img_height < min_height || img_width < min_width) {
                        this.imageError = `Minimum image dimentions: ${min_width} x ${min_height}`;
                        this.snackBarError.open(`Minimum image dimentions: ${min_width} x ${min_height}.\n Selected image(${img_width} x ${img_height})`, "Dismiss", {
                            duration: 4000,
                            panelClass: 'error-snackbar'
                          });
                        console.error(this.imageError);
                        return false;
                    } else {
                        this.localImageUrl = image.src;
                        const imgBase64Path = e.target.result;
                        this.cardImageBase64 = imgBase64Path;
                        setTimeout(() => this.isImageSaved = true);

                        this.sendData.emit(fileInput[0]);
                    }
                };
            };

            reader.readAsDataURL(fileInput[0]);
        }
    }

    public onFileDrop(event: EventEmitter<File[]>) {
        const file: File = event[0];
    }

    resetUploader() {
        //this.localImageUrl = '';
        // this.imageTitle = null;
        // this.imageWidth = null;
        // this.imageHeight = null;
        // this.imageError = null;
        // this.isImageSaved = null;
        // this.cardImageBase64 = null;
    }

}
