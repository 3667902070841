import { readFromStoragedObject } from "./utils";

declare var pendo: any;

export function initPendo(){
  console.log("pendo init");
  try{
    let pendoInit: PendoInitializer = {
      visitor:{
        id: readFromStoragedObject('AnalyticsData','id'),
        fullName: readFromStoragedObject('AnalyticsData','fullName'),
        company: readFromStoragedObject('AnalyticsData','company'),
        type: readFromStoragedObject('AnalyticsData','type'),
        email: readFromStoragedObject('AnalyticsData','email'),
        phone: readFromStoragedObject('AnalyticsData','phone'), 
        has2FA: readFromStoragedObject('AnalyticsData','has2FA'),
        creationDate: readFromStoragedObject('AnalyticsData','creationDate'),
        renewalDate: readFromStoragedObject('AnalyticsData','renewalDate'),
        firstLogin: readFromStoragedObject('AnalyticsData','firstLogin'),
        lastLogin: readFromStoragedObject('AnalyticsData','lastLogin'),
        timesLoggedIn: readFromStoragedObject('AnalyticsData','timesLoggedIn'),
        onTrial: readFromStoragedObject('AnalyticsData','onTrial'),
        trialExpirationDate: readFromStoragedObject('AnalyticsData','trialExpirationDate'),
        numberOfClients: readFromStoragedObject('AnalyticsData','numberOfClients'),
        betaAccess: readFromStoragedObject('AnalyticsData','betaAccess'),
        accountStatus: readFromStoragedObject('AnalyticsData','accountStatus'),
        //Integrations
        activeIntegrations: readFromStoragedObject('AnalyticsData','activeIntegrations'),
        numberOfIntegrations: readFromStoragedObject('AnalyticsData','numberOfIntegrations'),
        //Snapshots
        autoSnapshots: readFromStoragedObject('AnalyticsData','autoSnapshots'),
        homeSnapshots: readFromStoragedObject('AnalyticsData','homeSnapshots'),
        estateSnapshots: readFromStoragedObject('AnalyticsData','estateSnapshots'),
        fulltaxSnapshots: readFromStoragedObject('AnalyticsData','fulltaxSnapshots'),
        totalSnapshots: readFromStoragedObject('AnalyticsData','totalSnapshots')
      },
      account:{
        id: readFromStoragedObject('AnalyticsData','companyId'),
        company_name: readFromStoragedObject('AnalyticsData','company_name'),
        poc: readFromStoragedObject('AnalyticsData','poc'),
        billing_advisor: readFromStoragedObject('AnalyticsData','billing_advisor'),
        subscription_type: readFromStoragedObject('AnalyticsData','subscription_type'),
        company_size: readFromStoragedObject('AnalyticsData','company_size'),
        creation_date: readFromStoragedObject('AnalyticsData','creation_date'),
        business_category: readFromStoragedObject('AnalyticsData','business_category'),
        uploadModelType: readFromStoragedObject('AnalyticsData','uploadModelType')
      }
    }
    pendo.initialize(pendoInit);
    console.log('PENDO INITIALIZED')
  }catch(error){
    console.log('ERROR. initPendo', error);
  }
}

export interface PendoInitializer {
  visitor: {
    //Personal
    id: string,
    fullName: string,
    company: string,
    type: string,
    email: string,
    phone: string, 
    has2FA: string,
    creationDate: string,
    renewalDate: string,
    firstLogin: string,
    lastLogin: string,
    timesLoggedIn: string,
    onTrial: string,
    trialExpirationDate: string,
    numberOfClients: string,
    betaAccess: string,
    accountStatus: string,
    //Integrations
    activeIntegrations: string,
    numberOfIntegrations: string,
    //Snapshots
    autoSnapshots: string,
    homeSnapshots: string,
    estateSnapshots: string,
    fulltaxSnapshots: string,
    totalSnapshots: string
  },
  account: {
    id: string,
    company_name: string,
    poc: string,
    billing_advisor: string,
    subscription_type: string,
    company_size: string,
    creation_date: string,
    business_category: string,
    uploadModelType: string
  }
}
