import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getEndPointUrlByContext } from 'app/shared/helpers/url.helper';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { CommaFormatedNumberToJSNumber } from 'app/views/questionnaires/DataTransformation.class';
import { environment } from 'environments/environment';
import * as html2pdf from 'html2pdf.js';

import { ESettingsService } from '../../services/e-settings.service';
import { Calculatron } from './calculatron.model';

@Component({
  selector: 'tax-calculator-v2-component',
  templateUrl: 'tax-calculator-v2.component.html',
  styleUrls: ['./tax-calculator-v2.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TaxCalculatorV2Component implements OnInit{

  @Input() isPrintable: boolean;

  public clientData: any;
  public clientMaritalStatus: string;
  public clientName: string;
  public clientLastName: string;
  public clientTaxStats;
  public clientId: any;
  public clientAge: number;
  public spouseAge: number;
  public model: Calculatron;

  private idClient: string;
  public filingStatus: string = '';
  public calculationsPerformed: boolean = false;
  public routerUrl: string = '';
  public isInPlanningOp: boolean = false;
  public isDataAvailable: boolean = true;

  public fullDisclaimerData: any;

  constructor(
            private advisorService: AdvisorService,
            private http: HttpClient,
            public snackBarSuccess: MatSnackBar,
            public snackBarError: MatSnackBar,
            public settingsService: ESettingsService
            ) {
    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    this.idClient = storagedClientID.clientId;
    this.routerUrl = window.location.href;
  }

  ngOnInit() {

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientName = this.clientData.name;
    this.clientLastName = this.clientData.lastName;
    this.clientAge = parseInt(this.clientData.age,10);
    this.spouseAge = parseInt(this.clientData.spouseAge,10);
    this.clientId = this.clientData.clientId;
    this.model = new Calculatron();
    this.isInPlanningOp = this.routerUrl.includes('areas/fulltax/projections');
    this.getTaxProjections();

    this.settingsService.getCompanyData().toPromise().then(response => {
      this.fullDisclaimerData = response;
    })

  }

  getTaxProjections(){

    this.advisorService.getTaxProjections(this.idClient).then(projections => {
      this.filingStatus = projections.filingStatus;
      this.model.clientIDTaxYear = projections.clientIDTaxYear;
      if (this.filingStatus === 'Other') {this.filingStatus = 'Head of Household'}

      this.model.greaterDeductions_total = projections.greaterDeductions;
      this.model.totalTaxableIncome_total = projections.totalTaxableIncome;

      for(let propName in this.model){
        if(projections[propName] != undefined) this.model[propName] = projections[propName];
      }

      this.model.cola = projections.cola * 100;

      this.calculatronCalculations(false);

    }, error => {
      console.log(error);
    });

  }

  calculatronCalculations(displayMessage: boolean){

    for(let propValue of Object.keys(this.model)){
      if(propValue !== 'filingStatus_nextyear'){
        this.model[propValue] = CommaFormatedNumberToJSNumber(this.model[propValue]);
      }
    }
    const auxBody: any = {};
    Object.keys(this.model).forEach((key) => {
      if (key.includes('_currentyear')) {
        const [newKey, ] = key.split('_');
        auxBody[newKey] = this.model[key];
      } else if (!auxBody[key]) {
        auxBody[key] = this.model[key];
      }
    });

    let body = {
      "assumptions": {
        "taxableYear": this.model.clientIDTaxYear,
        "clientAge": this.clientAge,
        "spouseAge": this.spouseAge,
        "filingStatus": this.filingStatus,
        "filingStatus_nextyear": this.model.filingStatus_nextyear,
        "cola": this.model.cola / 100
      },
      "inputs":{
        ...this.model,
      }
    }

    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    this.http.post(`${environment.apiTaxProjectionsCalculator}?idClient=${this.clientId}&area=fulltax`, body, options)
      .subscribe(
        projections => {

          for(let propName in this.model){
            if(projections[propName] != undefined) this.model[propName] = projections[propName];
          }

          this.calculationsPerformed = true;

          this.saveTaxProjection(displayMessage)

        },
        error => {
          console.log(error);
          this.snackBarError.open("Unable to perform projections, please contact support. [" + error.error.msg + ']', "", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });

        }
      );

  } //@End Calculatron

  saveTaxProjection(displayMessage: boolean){

    this.calculationsPerformed = false;

    for(let propValue of Object.keys(this.model)){
      if(propValue !== 'filingStatus_nextyear'){
        this.model[propValue] = CommaFormatedNumberToJSNumber(this.model[propValue]);
      }
    }

    this.model.cola = this.model.cola / 100;

    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let url = getEndPointUrlByContext('Projections') + 'fulltax/' + this.idClient ;

    this.http.post(url, this.model, options)
      .subscribe(
        response => {
          if(displayMessage){
            this.snackBarSuccess.open('Projections performed and saved successfully', "Ok", {
              duration: 3000,
              panelClass: 'success-snackbar'
            });
          }

          this.model.cola = this.model.cola * 100;

        },
        error => {
          console.log(error);
          this.snackBarError.open("Can't save projections due an error, please try again. [" + error.error.msg + ']', "", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });

          this.model.cola = this.model.cola * 100;

        }
      );

  }

  async pdfTaxProjections(){
    const options = {
        margin: 15,
        filename: 'tax-projections.pdf',
        image: {type: 'jpeg', quality: 1},
        html2canvas: {allowTaint: false,
                      useCORS: true,
                      scale: 4 },
        pagebreak: {
                     after: '#pagebreak'
                    },
        jsPDF: {orientation: 'landscape'}
      }

      let logo;
      await this.http.get<any>(`${environment.apiAdvisorLogo}`).toPromise()
      .then(
        success => {
          logo = success.logo;
        },
        error => {
          console.log('Error!!!');
        }
      );

      let html = `<div style="height: 90px; display: flex; justify-content: space-between;">
                    <div> <span style="font-size: 24px; color: #3f5daa;">${this.clientName} ${this.clientLastName} </span><br>
                          <span style="font-size: 24px;">Tax projections</span>
                    </div>
                    <div> <img style="max-width: 180px; max-height: 50px;" #logoImageId src="${logo}"> </div>
                  </div>
                  <div style="margin-bottom: 10px;">
                    <span style="margin: 0px!important; display: flex; font-size: 20px;"> <span class="hexColor" style="margin-top: 5px; background-color: #C93939;"></span> Tax Planning </span>
                  </div>`

      let taxProjectionsCavas = this.generateTaxProjectionsCanvas(html);
      let advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
      let disclaimer = advisorPreferences.disclaimer ? advisorPreferences.disclaimer : 'Disclaimer: Information provided is for educational purposes. Your advisor does not provide tax, legal, or accounting advice. In considering this material, you should discuss your individual circumstances with professionals in those areas before making any decisions. Further, your advisor makes no warranties with regard to such information or a result obtained by its use, and disclaims any liability arising out of your use of, or any tax position taken in reliance on, such information.';

      let settingsCompanyData = await this.settingsService.getCompanyData().toPromise();
      let fullDisclaimerData = {
        isActive: settingsCompanyData.settings.isFullDisclaimerActive,
        title: settingsCompanyData.fullDisclaimer.title,
        body: settingsCompanyData.fullDisclaimer.disclaimer
      }
      localStorage.setItem('fullDisclaimerData', JSON.stringify(fullDisclaimerData));
      html2pdf().from(taxProjectionsCavas).set(options).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        let text = pdf.splitTextToSize(disclaimer,pdf.internal.pageSize.getWidth()+200);
        //Set document fonts
        /*pdf.addFileToVFS('value-sans-regular-pro-normal.ttf', font);
        pdf.addFileToVFS('value-sans-medium-pro-bold.ttf', fontBold);
        pdf.addFont('value-sans-regular-pro-normal.ttf', 'value-sans-regular-pro', 'normal');
        pdf.addFont('value-sans-medium-pro-bold.ttf', 'value-sans-medium-pro', 'normal');*/

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          // pdf.setFont('value-sans-medium-pro');
          pdf.setTextColor(100);
          pdf.setLineWidth(100)
          for(let j = 0; j < text.length; j++){
            pdf.text(text[j], 15, pdf.internal.pageSize.getHeight() - (30-((j*3)+18)));
          }

        }

        let fullDisclaimer = JSON.parse(localStorage.getItem('fullDisclaimerData'));
        if (fullDisclaimer.isActive){
          pdf.addPage();
          pdf.setPage(totalPages  + 1);
          //Set format to full disclaimer
          //header
          pdf.setFontSize(24);
          pdf.setFont('helvetica');
          pdf.setTextColor(54, 90, 189);
          pdf.setLineWidth(100)
          pdf.text(fullDisclaimer.title, 15, 20);
          //body
          pdf.setFontSize(11);
          pdf.setFont('helvetica');
          pdf.setTextColor(0, 0, 0);
          let paragraph = pdf.splitTextToSize(fullDisclaimer.body,pdf.internal.pageSize.getWidth() - 30);
          pdf.text(paragraph, 15, 30);
        }

        totalPages = fullDisclaimer.isActive ? totalPages + 1: totalPages;
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setFont('helvetica');
          pdf.setTextColor(100);
          pdf.setLineWidth(100);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth()-15, pdf.internal.pageSize.getHeight() - 12);
        }
      }).save();

  }

  public generateTaxProjectionsCanvas(adjacentHtml: string = null): HTMLElement {


    const content: Element = document.getElementById('tax-projections');
    var content2 = <HTMLElement> content.cloneNode(true);

    //Title background
    let titleDetailBackground: NodeListOf<HTMLElement> = content2.querySelectorAll('th');
    for(var i = 0; i<titleDetailBackground.length;i++){
      titleDetailBackground[i].style.background = '#365abd';
      titleDetailBackground[i].style.borderRight = '1px solid #365abd'
      titleDetailBackground[i].style.color = 'white';
    }

    //Total section background
    let trSection: NodeListOf<HTMLElement> = content2.querySelectorAll('tr');
    for(var i = 0; i<trSection.length;i++){
      trSection[i].style.color = 'black';
    }

    //Projection div inpput
    let tdTable: NodeListOf<HTMLElement> = content2.querySelectorAll('.projection-input');
    for(var i = 0; i<tdTable.length;i++){
      tdTable[i].style.border = 'none'
    }

    //Pinto el fondo de blanco
    let projectionInputSection: NodeListOf<HTMLElement> = content2.querySelectorAll('#projection-input-section');
    for(var i = 0; i<projectionInputSection.length;i++){
      projectionInputSection[i].style.background = 'white';
    }

    let extraInfo: NodeListOf<HTMLElement> = content2.querySelectorAll('#extra-info');
    for(var i = 0; i<extraInfo.length;i++){
      extraInfo[i].style.setProperty("display", "flex", "important");
      extraInfo[i].style.color = 'black';
    }

    let projectionInputSectionTotal: NodeListOf<HTMLElement> = content2.querySelectorAll('#projection-input-section-total');
    for(var i = 0; i<projectionInputSectionTotal.length;i++){
      projectionInputSectionTotal[i].style.background = '#C8C8C8';
    }

    //Borrar secciones de explicacion para sectionOtherIncome.
    let sectionOtherIncome: NodeListOf<HTMLElement> = content2.querySelectorAll('#sectionOtherIncome');
    for(var i = 0; i < sectionOtherIncome.length;i++){
      sectionOtherIncome[i].style.setProperty('visibility','collapse');
    }

    //Borrar secciones de explicacion para sectionAdjustmentsToIncome.
    let sectionAdjustmentsToIncome: NodeListOf<HTMLElement> = content2.querySelectorAll('#sectionAdjustmentsToIncome');
    for(var i = 0; i < sectionOtherIncome.length;i++){
      sectionAdjustmentsToIncome[i].style.setProperty('visibility','collapse');
    }
    //Borrar secciones de explicacion para sectionAdjustmentsToIncome.
    let titleDetail: NodeListOf<HTMLElement> = content2.querySelectorAll('#titleDetail');
    for(var i = 0; i < sectionOtherIncome.length;i++){
      titleDetail[i].style.setProperty('padding-top','25px');
    }

    //Pinto el fondo de blanco y texto en negro
    let projectionInput: NodeListOf<HTMLInputElement> = content2.querySelectorAll('.projections-input');
    for(var i = 0; i<projectionInput.length;i++){
      //projectionInput[i].outerHTML = projectionInput[i].outerHTML.replace(/input/g,"span");
      projectionInput[i].style.background = 'white';
      projectionInput[i].style.setProperty("color", "#212121", "important");
      //projectionInput[i].style.color = '#212121';
      projectionInput[i].style.fontSize = '16px';

      //Replace input tag to span
      var elementToChange = projectionInput[i];
      var elementValue = elementToChange.value;
      var newElement = document.createElement("span");
      newElement.innerHTML = elementValue;
      elementToChange.outerHTML = newElement.innerHTML;

    }

    // let totalFinalInput: NodeListOf<HTMLInputElement> = content2.querySelectorAll('.total-tax');
    // for(var i = 0; i<totalFinalInput.length;i++){
    //   totalFinalInput[i].style.setProperty("fontSize", "18px", "important");
    //   totalFinalInput[i].style.setProperty("fontWeight", "bold", "important");
    // }

    //Money symbol in black
    let moneySymbol: NodeListOf<HTMLElement> = content2.querySelectorAll('#money-symbol');
    for(var i = 0; i<moneySymbol.length;i++){
      moneySymbol[i].style.color = '#212121';
    }

    //Total section background
    let totalSection: NodeListOf<HTMLElement> = content2.querySelectorAll('.total-row');
    for(var i = 0; i<totalSection.length;i++){
      totalSection[i].style.setProperty("background-color", "#C8C8C8", "important");
      totalSection[i].style.color = 'black';
    }

    let titleRow: NodeListOf<HTMLElement> = content2.querySelectorAll('.title-row');
    for(var i = 0; i<titleRow.length;i++){
      titleRow[i].style.background = '#365abd';
      titleRow[i].style.borderRight = '1px solid #365abd'
      titleRow[i].style.color = 'white';
    }

    let tdTitleRow: NodeListOf<HTMLElement> = content2.querySelectorAll('.td-title-row');
    for(var i = 0; i<tdTitleRow.length;i++){
      tdTitleRow[i].style.borderRight = '1px solid #365abd'
    }

    let totalInput: NodeListOf<HTMLElement> = content2.querySelectorAll('.total-input');
    for(var i = 0; i<totalInput.length;i++){
      totalInput[i].style.setProperty("background-color", "#C8C8C8", "important");

    }

    if (adjacentHtml){
        //Insert page break with new rows
        let tableRef = content2.querySelector('#table-projections') as HTMLTableElement;
        let newRow   = tableRef.insertRow(7);
        let newRow2 = tableRef.insertRow(17);
        let newRow5 = tableRef.insertRow(17);
        let newRow3 = tableRef.insertRow(27);
        let newRow4 = tableRef.insertRow(tableRef.rows.length - 1);
        newRow.insertCell(0);
        newRow.insertCell(1);
        newRow.insertCell(2);
        newRow.insertCell(2);

        newRow2.insertCell(0);
        newRow2.insertCell(1);
        newRow2.insertCell(2);
        newRow2.insertCell(3);

        newRow3.insertCell(0);
        newRow3.insertCell(1);
        newRow3.insertCell(2);
        newRow3.insertCell(3);

        newRow4.insertCell(0);
        newRow4.insertCell(1);
        newRow4.insertCell(2);
        newRow4.insertCell(3);

        newRow5.insertCell(0);
        newRow5.insertCell(1);
        newRow5.insertCell(2);
        newRow5.insertCell(3);

        //Total section background
        let tdSection: NodeListOf<HTMLElement> = content2.querySelectorAll('td');
        tdSection[24].style.borderRight = 'none';
        tdSection[25].style.borderRight = 'none';
        tdSection[26].style.borderRight = 'none';

        tdSection[64].style.borderRight = 'none';
        tdSection[65].style.borderRight = 'none';
        tdSection[66].style.borderRight = 'none';

        tdSection[67].style.borderRight = 'none';
        tdSection[68].style.borderRight = 'none';
        tdSection[69].style.borderRight = 'none';
        tdSection[70].style.borderRight = 'none';


        tdSection[105].style.borderRight = 'none';
        tdSection[106].style.borderRight = 'none';
        tdSection[107].style.borderRight = 'none';

        if (tdSection[144]) tdSection[144].style.borderRight = 'none';
        if (tdSection[145]) tdSection[145].style.borderRight = 'none';
        if (tdSection[146]) tdSection[146].style.borderRight = 'none';

        content2.insertAdjacentHTML('afterbegin', adjacentHtml)
    }else{
        //Insert page break with new rows
        let tableRef = content2.querySelector('#table-projections') as HTMLTableElement;
        let newRow   = tableRef.insertRow(13);
        let newRow2 = tableRef.insertRow(28);
        newRow.insertCell(0);
        newRow.insertCell(1);
        newRow.insertCell(2);
        newRow.insertCell(3);
        newRow2.insertCell(0);
        newRow2.insertCell(1);
        newRow2.insertCell(2);
        newRow2.insertCell(3);

        //Total section background
        let tdSection: NodeListOf<HTMLElement> = content2.querySelectorAll('td');
        tdSection[48].style.borderRight = 'none';
        tdSection[49].style.borderRight = 'none';
        tdSection[50].style.borderRight = 'none';
        tdSection[108].style.borderRight = 'none';
        tdSection[109].style.borderRight = 'none';
        tdSection[110].style.borderRight = 'none';
    };

    return content2;

  }

}
