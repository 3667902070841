import { Questionnaire } from '../Questionnaire.model';
import { DataTransformation } from '../DataTransformation.class';
import { utilsCalculateAge } from 'app/shared/helpers/utils';

export class prospect_questions extends Questionnaire {


   //Initial data
   public clientIDFirstName: string = "";
   public clientIDLastName: string = "";
   public email: string = "";
   public emailConfirm: string = "";

   //General
   public clientIDGender: string = "";
   public clientIDBirthday: any = 0;
   public clientIDAge: number = 0;
   public clientIDSpouseName: string = "";
   public clientIDSpouseBirthday: any = 0;
   public clientIDSpouseHasABusiness: string = "";
   public clientIDSpouseAge: number = 0;
   public clientIDSpouseGender: string = '';
   public clientIDLivingState: string = "";
   public clientIDOccupation: string = "";
   public clientIDNetWorth: number = 0;
   public clientIDHasABusiness: string = "";
   public clientSpouseHasABusiness: string = '';
   public clientIDSmallBusiness: string = "";
   public clientIDMaritalStatus: string = "";
   public clientIDChildren: string = "";
   public clientIDGrandchildren: string = '';
   public clientIDAdditionalInformation: string = "";
   public clientIDHome: string = "";
   public clientIDPhoneNumber: string = "";
   public cliendIDContactWay: string = "";

   //Children & Grandchildren ages added (RT on 8 sep 2020)
   public clientIDChildOneName: string = '';
   public clientIDChildOneAge: number = 0;
   public clientIDChildTwoName: string = '';
   public clientIDChildTwoAge: number = 0;
   public clientIDChildThreeName: string = '';
   public clientIDChildThreeAge: number = 0;
   public clientIDChildFourName: string = '';
   public clientIDChildFourAge: number = 0;
   public clientIDChildFiveName: string = '';
   public clientIDChildFiveAge: number = 0;

   public clientIDGrandchildOneName: string = '';
   public clientIDGrandchildOneAge: number = 0;
   public clientIDGrandchildTwoName: string = '';
   public clientIDGrandchildTwoAge: number = 0;
   public clientIDGrandchildThreeName: string = '';
   public clientIDGrandchildThreeAge: number = 0;
   public clientIDGrandchildFourName: string = '';
   public clientIDGrandchildFourAge: number = 0;
   public clientIDGrandchildFiveName: string = '';
   public clientIDGrandchildFiveAge: number = 0;

   public clientIDChildOneBirthday: Date = new Date();
   public clientIDChildTwoBirthday: Date = new Date();
   public clientIDChildThreeBirthday: Date = new Date();
   public clientIDChildFourBirthday: Date = new Date();
   public clientIDChildFiveBirthday: Date = new Date();
   public clientIDGrandChildOneBirthday: Date = new Date();
   public clientIDGrandChildTwoBirthday: Date = new Date();
   public clientIDGrandChildThreeBirthday: Date = new Date();
   public clientIDGrandChildFourBirthday: Date = new Date();
   public clientIDGrandChildFiveBirthday: Date = new Date();

   public clientIDHasMinorChildren: string = "";

   public clientIDChildrenCount: any = 0;

   //Estate
   public clientIDHasEstatePlanInPlace: string = "";
   public clientIDHasOneorMoreLifeInsurancePolicies: string = "";
   public clientIDNumberofLifePolicies: number = 0;
   public clientIDPoliciesOwnedByInsured : string = "";
   public clientIDPolicyBeneficiariesAreMinorOrDisabilityOrAddiction: string = "";
   public cliendIDPolicyBeneficiariesWithDisability: string = "";
   public cliendIDPolicyBeneficiariesDontTrust: string = "";
   public clientIDThereIsABlendedFamily : string = "";
   public clientIDMultipleChild: string = "";

   //Tax
   public clientIDWorkingAndReceivingIncome: string = "";
   public clientIDHasAnyOtherIncomeAsEmployee: string = "";

   public clientIDHasAnyOtherIncomeAsEmployeeNA: number = 0;
   public clientIDHasAnyOtherIncomeAsEmployeeRentalIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsEmployeePortfolioIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsEmployeeSideJob: number = 0;

   public clientIDHasAnyOtherIncomeAsSelfEmployed: string = "";

   public clientIDHasAnyOtherIncomeAsSelfEmployeeNA: number = 0;
   public clientIDHasAnyOtherIncomeAsSelfEmployeeRentalIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsSelfEmployeePortfolioIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsSelfEmployeeSideJob: number = 0;

   public clientIDHasAnyOtherIncomeAsUnemployed: string = "";

   public clientIDHasAnyOtherIncomeAsUnemployedeNA: number = 0;
   public clientIDHasAnyOtherIncomeAsUnemployedRentalIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsUnemployedPortfolioIncome: number = 0;
   public clientIDHasAnyOtherIncomeAsUnemployedSideJob: number = 0;

   public clientIDLastYearIncome: number = 0;
   public clientIDFilingStatus: string = "";
   public clientIDFilingStatusWillStayTheSame: string = "";
   public clientIDSupportsAnAdultWhoIsOver18: string = "";
   public clientIDCharitableContributions: number = 0;
   public clientIDHasGrandchild: string = "";
   public clientIDAnyGrandChildrenUnder22: string = "";
   public clientIDHasEmployees: string = "";
   public clientIDHasStockOptions: string = "";
   public clientIDHasRestrictedStock: string = "";

   //Auto (new)
   public clientIDOwnOrLease: string = "";
   public clientIDCarsOwned: number = 0;
   public clientIDClientDrivesAndDoesNotOwnCar: string = "";
   public clientIDClientOwns1: string = "";
   public clientIDCarValue1: string = "";
   public clientIDClientHasAutoInsurance1: string = "";
   public clientIDAutoInsuranceCompany1: string = "";

   public clientIDClientOwns2: string = "";
   public clientIDCarValue2: string = "";
   public clientIDClientHasAutoInsurance2: string = "";
   public clientIDAutoInsuranceCompany2: string = "";

   public clientIDClientOwns3: string = "";
   public clientIDCarValue3: string = "";
   public clientIDClientHasAutoInsurance3: string = "";
   public clientIDAutoInsuranceCompany3: string = "";

   public clientIDClientOwns4: string = "";
   public clientIDCarValue4: string = "";
   public clientIDClientHasAutoInsurance4: string = "";
   public clientIDAutoInsuranceCompany4: string = "";

   public clientIDClientOwns5: string = "";
   public clientIDCarValue5: string = "";
   public clientIDClientHasAutoInsurance5: string = "";
   public clientIDAutoInsuranceCompany5: string = "";

   //Home (new)
   public clientIDOwnsPrimaryResidence: string = "";
   public clientIDHomesOwned: number = 0;
   public clientIDOwnsInvestmentProperties: string = "";
   public clientIDInvestmentProperty: string = "";

   public clientIDHouseOrApartment1: string = '';
   public clientIDClientHasHomeInsurance1: string = '';
   public clientIDHomeInsuranceCompany1: string = '';

   public clientIDHouseOrApartment2: string = '';
   public clientIDClientHasHomeInsurance2: string = '';
   public clientIDHomeInsuranceCompany2: string = '';

   public clientIDHouseOrApartment3: string = '';
   public clientIDClientHasHomeInsurance3: string = '';
   public clientIDHomeInsuranceCompany3: string = '';

   public clientIDHouseOrApartment4: string = '';
   public clientIDClientHasHomeInsurance4: string = '';
   public clientIDHomeInsuranceCompany4: string = '';

   public clientIDHouseOrApartment5: string = '';
   public clientIDClientHasHomeInsurance5: string = '';
   public clientIDHomeInsuranceCompany5: string = '';

   //LTC
   public clientIDHasLTCInsurancePolicy: string = '';
   public clientIDNonProfessionalCareYou: string = '';
   public clientIDdailyBenefit: number = 0;
   public clientIDsearchNYPartnership: string = '';
   public clientIDLTCAccessAssociation: string = '';
   public clientIDsearchWaiverEliminationPeriodRider: string = '';
   public clientIDsearchFullRestoration: string = '';
   public clientIDFamilyIllnesRecovery: string = '';
   public clientIDVeteran: string = '';
   public clientIDAverageCostOfNursingPerDay: number = 0;

   //Life
   public clientIDClientInsured: string = '';
   public clientIDLifePoliciesOwned: number = 0;
   public clientIDTotalAmountDeathBenefit: number = 0;
   public clientIDReview: string = '';
   public clientIDMedCondition: string = '';
   public clientIDMoreInsuranceEmployer: string = '';
   public clientIDIncomeNeedFunding: string = '';
   public clientIDSpouseInsured: string = '';

   public clientIDKindOfPolicy1: string = '';
   public clientIDCashValue1: string = '';
   public clientIDYoungBeneficiaries1: string = '';

   public clientIDKindOfPolicy2: string = '';
   public clientIDCashValue2: string = '';
   public clientIDYoungBeneficiaries2: string = '';

   public clientIDKindOfPolicy3: string = '';
   public clientIDCashValue3: string = '';
   public clientIDYoungBeneficiaries3: string = '';

   public clientIDKindOfPolicy4: string = '';
   public clientIDCashValue4: string = '';
   public clientIDYoungBeneficiaries4: string = '';

   public clientIDKindOfPolicy5: string = '';
   public clientIDCashValue5: string = '';
   public clientIDYoungBeneficiaries5: string = '';

   //Disability
   public clientIDBaseSalary: number = 0;
   public clientIDSpousalBaseSalary: number = 0;
   public clientIDIsFinanciallyIndependent: string = '';
   public clientIDIsFinanciallyIndependentSpouse: string = '';
   public clientIDIsFinanciallyIndependent65: string = '';
   public clientIDIsFinanciallyIndependent65Spouse: string = '';
   public clientIDExpensesMonthlyBasis: string = '';
   public clientIDExpensesMonthlyBasisSpouse: string = '';
   public clientIDClientHasBusinessAndPartners: string = '';
   public clientIDClientHasBusinessAndPartnersSpouse: string = '';
   public clientIDsearchPolicyForOverheadCoverage: string = '';
   public clientIDsearchPolicyForOverheadCoverageSpouse: string = '';
   public clientIDHasPolicyOne: string = '';
   public clientIDHasPolicyOneSpouse: string = '';
   public clientIDTypeOfPolicy: string = '';
   public clientIDTypeOfPolicySpouse: string = '';
   public clientIDHasSecondPolicy: string = '';
   public clientIDHasSecondPolicySpouse: string = '';
   public clientIDTypeOfPolicy2: string = '';
   public clientIDTypeOfPolicy2Spouse: string = '';
   public clientIDCoverageScope: string = '';
   public clientIDCoverageScopeSpouse: string = '';

   //Divorce
   public clientIDDivorceStatus: string = '';
   public clientIDHasPrenup: string = '';
   public clientIDIsReceivingOrPayingSpousalSupport: string = '';
   public clientIDHasLifeInsurance: string = '';
   public clientIDOwnsHouseDivorce: string = '';
   public clientIDEstatePlanComplete: string = '';

   //Investment
   public clientIDPortfolioSize: string = '';
   public clientIDHasRetirementAccount: string = '';
   public clientIDStocks: number = 0;
   public clientIDBonds: number = 0;
   public clientIDCash: number = 0;
   public clientIDDiversificatesPortfolio: string = '';
   public clientIDHasAnalyzedPortfolio: string = '';
   public clientIDPortfolioManagementWhishes: string = '';

   //Retirement
   public clientIDPlannedRetAge: string = '';
   public clientIDPlannedRetAgeSpouse: string = '';
   public clientIDPlansActivityAfterRet: string = '';
   public clientIDPlansActivityAfterRetSpouse: string = '';
   public clientIDExpectedIncomeFromActivity: number = 0;
   public clientIDExpectedIncomeFromActivitySpouse: number = 0;
   public clientIDClientConsidersRelocation: string = '';
   public clientIDPreferredRelocationSite: string = '';
   public clientIDPAnticipatesLargePurchasesInRet: string = '';
   public clientIDHealthRating: string = '';
   public clientIDHealthRatingSpouse: string = '';
   public clientIDHealthRatingReason: string = '';
   public clientIDHealthRatingReasonSpouse: string = '';
   public clientIDMonthlyExpenses: number = 0;
   public clientIDNonDiscretionaryExpenditures: string = '';
   public clientIDLifeExpensesRemainSameAfterRet: string = '';
   public clientIDLifeExpensesNotRemainSameAfterRetReason: string = '';
   public clientIDCurrentTypesOfLifeInsurance: string = '';

   //Custom questions
   public clientIDCustomQuestion1 : string = '';
   public clientIDCustomQuestion2 : string = '';
   public clientIDCustomQuestion3 : string = '';
   public clientIDCustomQuestion4 : string = '';
   public clientIDCustomQuestion5 : string = '';
   public clientIDCustomQuestion6 : string = '';
   public clientIDCustomQuestion7 : string = '';
   public clientIDCustomQuestion8 : string = '';
   public clientIDCustomQuestion9 : string = '';
   public clientIDCustomQuestion10 : string = '';

   //New Ret&Inv
   public clientIDHasInvestmentPortfolio: string = '';
   public clientIDInvestmentSize: number = 0;
   public clientIDInvestmentManaged: string = '';
   public clientIDInvestmentOptions: string = ''; //array
   public clientIDInvestmentRate: string = '';
   public clientIDInvestmentWish: string = '';
   public clientIDInvestmentManageCashflow: string = ''; //array

   public clientIDPercentageIncomeSaved: any = undefined;
   public clientIDIncludeRetirementAccounts: string = '';
   public clientIDIncomeOptions: string = ''; //array
   public clientIDRetirementAccountsList: string = ''; //array

   public clientIDYearsToRetire: number = 0;
   public clientIDSpouseYearsToRetire: number = 0;

   public clientIDTotalNonDiscretionaryExpenses: number = 0;
   public clientIDTotalDiscretionaryExpenses: number = 0;
   public clientIDNonDiscretionaryMortgage: string = '';
   public clientIDNonDiscretionaryMortgageSize: number = 0;
   public clientIDNonDiscretionaryMortgageYearsLeft1: number = 0;
   public clientIDNonDiscretionaryMortgageYearsLeft2: number = 0;
   public clientIDNonDiscretionaryMortgageYearsLeft3: number = 0;
   public clientIDNonDiscretionaryMortgageYearsLeft4: number = 0;
   public clientIDNonDiscretionaryMortgageYearsLeft5: number = 0;

   public clientIDAnticipateChangesRetirement: string = ''; //array
   public clientIDAnticipateChangesRetirementExpectedIncome: number = 0;
   public clientIDAnticipateChangesRetirementHome: number = 0;
   public clientIDAnticipateChangesRetirementBoat: number = 0;
   public clientIDAnticipateChangesRetirementPlane: number = 0;

   public clientIDMaintainLifestyle: string = '';
   public clientIDMaintainLifestyleLavish: number = 0;
   public clientIDMaintainLifestyleDownsize: number = 0;

   public clientIDHealthConditionRate: string = '';
   public clientIDSpouseHealthConditionRate: string = '';

   public clientIDHasDebt: string = '';
   public clientIDDebtList: string = ''; //array
   public clientIDTotalDebt: number = 0;

   //NEWWW JSON FEEDBACK
   public clientIDEstatePlanInPlaceNeedsReview: string = '';

   // Progress Bar
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;

   constructor() {
      super();
      this._classInitialState = Object.assign({}, this);
  }

   getQName(): string {
      return '';
   }

   getIgnoredAttributesForSave(): string[] {
      return ['clientIDAutoPolicy', 'cars'];
   }

   getAttributesToClearCommas(): string[] {
      return [
         'clientIDNetWorth',
         'clientIDInvestmentSize',
         'clientIDHomeValue',
         'clientIDLowMileage',
         'clientIDLivingExpenses',
         'clientIDEmergencyFundSavings',
         'clientIDLastYearIncome',
         'clientIDCharitableContributions',
         'clientIDGifts',
         'clientIDTotalAmountDeathBenefit',
         'clientIDdailyBenefit',
         'clientIDAverageCostOfNursingPerDay',
         'clientIDBaseSalary',
         'clientIDSpousalBaseSalary',
         'clientIDAnticipateChangesRetirementExpectedIncome',
         'clientIDAnticipateChangesRetirementHome',
         'clientIDAnticipateChangesRetirementBoat',
         'clientIDAnticipateChangesRetirementPlane'
         ];
   }

   getDataTransformations(): DataTransformation[] {
      return [
         // Transform any negative net worth to positive
         new DataTransformation(
            [
               'clientIDNetWorth',
               'clientIDInvestmentSize',
               'clientIDAge',
               'clientIDSpouseAge',
               'clientIDLastYearIncome',
               'clientIDCharitableContributions',
               'clientIDGifts'
            ],
            (data: any) => { return Math.abs(data); }
         )
      ]
   }


   beforeToSaveFormat() {
     let max
      this.clientIDAge = this.calculateAge(this.clientIDBirthday);
      this.clientIDSpouseAge = this.calculateAge(this.clientIDSpouseBirthday);
      this.clientIDChildOneAge =  this.clientIDChildOneName.trim() ?  this.calculateAge(this.clientIDChildOneBirthday) : 0;
      this.clientIDChildTwoAge = this.clientIDChildTwoName.trim() ? this.calculateAge(this.clientIDChildTwoBirthday) : 0;
      this.clientIDChildThreeAge = this.clientIDChildThreeName.trim() ? this.calculateAge(this.clientIDChildThreeBirthday) : 0;
      this.clientIDChildFourAge = this.clientIDChildFourName.trim() ? this.calculateAge(this.clientIDChildFourBirthday) : 0;
      this.clientIDGrandchildFiveAge = this.clientIDChildFiveName.trim() ? this.calculateAge(this.clientIDChildFiveBirthday) : 0;
      //Clear the unused houses data
      for(let i=1; i<=5; i++){
        if(i > this.clientIDHomesOwned){
          this.clearHomeData(i);
          this.clearAutoData(i);
        }
      }
   }

   beforeMappingLoad(args: any[]) {
      //
   }

   private calculateAge(DOB: Date = this.clientIDBirthday): number {
      return utilsCalculateAge(DOB)
   }

   private clearHomeData(index){
     /*if(this['clientIDHouseOrApartment' + index] != undefined) this['clientIDHouseOrApartment' + index] = '';
     if(this['clientIDClientHasHomeInsurance' + index] != undefined) this['clientIDClientHasHomeInsurance' + index] = '';
     if(this['clientIDHomeInsuranceCompany' + index] != undefined) this['clientIDHomeInsuranceCompany' + index] = '';*/
   }

   private clearAutoData(index){
   /*
    if(this['clientIDClientOwns' + index] != undefined) this['clientIDClientOwns' + index] = '';
    if(this['clientIDClientLeases' + index] != undefined) this['clientIDClientLeases' + index] = '';
    if(this['clientIDCarValue' + index] != undefined) this['clientIDCarValue' + index] = '';
    if(this['clientIDClientHasAutoInsurance' + index] != undefined) this['clientIDClientHasAutoInsurance' + index] = '';
    if(this['clientIDAutoInsuranceCompany' + index] != undefined) this['clientIDAutoInsuranceCompany' + index] = '';*/
  }



}
