import { GraphMap } from "../../graph-tool/GraphTool.class"

export interface FlowChartBoxNodeInterface{

  containerClass: string,
  content: FlowChartBoxContentRow[]
}

export interface FlowChartBoxContentRow{
  text: string,
  textB?: string[],
  class?: string,
  classB?: string,
}

export  const example: FlowChartBoxContentRow[] = [
  {text: 'Personal Tangible Property', class: 'textSimple'}, //title subtitle
  {text: 'Phoebe G. Marcus', class: 'bold,'},
  {text: 'Josua W. Marcus', class: 'bold'},
  {text: '', class: 'separator'},
  {text: 'Residuary Estate', class: 'textSimple'},
  {text: 'Phoebe G. Marcus', textB: ['50%'],  class: 'bold'},
  {text: 'Josua W. Marcus', textB: ['50%'],  class: 'bold'},
]

export const exampleFamily: FlowChartBoxNodeInterface[] = [
  {
    containerClass: 'border-accent center top blue',
    content: [{text: 'Michael Marcus\'s Will', class: 'bold'}]
  },
  {
    containerClass: 'border-accent center blue top',
    content: [{text: 'Tobi K. Marcus\'s Will', class: 'bold'}]
  },
  {
    containerClass: 'border-accent center orange top',
    content: [{text: 'Marcus Family Trust', class: 'bold'}]
  },
  {
    containerClass: 'border-accent center left orange',
    content: [{text: 'Survivor\'s Trust', class: 'bold'}]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'During the lifetime of Surviving Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Net Income and Principal '},
      {text: 'Surviving Settlor', class:'bold'},
     ]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'Upon the death of one Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Personal Tangible Property'},
      {text: 'Phoebe G. Marcus', class:'bold'},
      {text: 'Josua W. Marcus', class:'bold'},
      {text: '', class: 'separator'},
      {text: 'Residuary Estate'},
      {text: 'Phoebe G. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
      {text: 'Josua W. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
    ]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'During the lifetime of Surviving Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Net Income and Principal '},
      {text: 'Surviving Settlor', class:'bold'},
     ]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'Upon the death of one Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Residuary Estate'},
      {text: 'Phoebe G. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
      {text: 'Josua W. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
    ]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'During the lifetime of Surviving Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Net Income and Principal '},
      {text: 'Surviving Settlor', class:'bold'},
      {text: 'Josua W. Marcus', class:'bold'},
      {text: 'Phoebe G. Marcus', class:'bold'},
     ]
  },
  {
    containerClass: 'border-simple',
    content: [{text: 'Upon the death of one Settlor'}]
  },
  {
    containerClass: 'border-none',
    content: [
      {text: 'Residuary Estate'},
      {text: 'Phoebe G. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
      {text: 'Josua W. Marcus', class:'bold', textB: ['50%'], classB:'bold'},
    ]
  },
]

export const exampleTitle: FlowChartBoxNodeInterface = {
  containerClass: 'border-accent orange top',
  content: [
    {text: 'Michael E. Marcus', class: 'bold'},
    {text: 'Signed  01/03/2019', class: 'date'},
    {text: '', class: 'separator-invisible'},
    {
      text: 'Co-trustees',
      class: 'textSimple',
      textB: ['Michael Marcus and Tobi K. Marcus (Survivor may continue as sole trustee)'],
      classB: 'bold'
    },
    {
      text: '',
      class: 'separator',
    },
    {
      text: 'Successor Trustee',
      class: 'textSimple',
      textB: ['David Klein'],
      classB: 'bold'
    },
    {
      text: 'Successor Co-trustees',
      class: 'textSimple',
      textB: ['Northern Trust Bank Of California', 'Brian Marcus and Beth Klein Seligman'],
      classB: 'bold'
    }

  ]

}

export const FLOWCHART_EXAMPLE: any = {
  nodesType: 'FlowChartBoxContentRow',
  nodes: [
    exampleTitle,
    ...exampleFamily
  ],
  grid:{
    rowsNodesById: []
  }
}
