import { Component, OnInit, ViewChild } from "@angular/core";
import { IntegrationField, Mapper, Tab, TabField } from "./utils/Tabs.model";
import { longMockCatalog, mockCatalog } from "./utils/mock";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { IntegrationStepService } from "../../../../../../shared/services/integration/integration-step.service";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { INTEGRATION_STEPS } from "app/shared/services/integration/utils";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IntegrationMapperService } from "app/shared/services/integration/integration-mapper.service";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  public mapperData: Tab[] = []

  public flattedMapperData: Tab[] = this.mapperData;

  public dropdowns: IntegrationField[] = [];

  public form: FormGroup;

  @ViewChild('mapForm') mapForm; 

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public integrationStepService: IntegrationStepService,
    public integrationMapperService: IntegrationMapperService,
    private dialog: MatDialog,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
  ) {
    this.initFormListener();
    this.getFields()
  }
  
  getFields(){
    this.integrationStepService.isLoaded = false
    this.integrationMapperService.getFields().subscribe(
        (success) => {
          this.integrationStepService.isLoaded = true
          this.mapperData = success.fpalphaMapper.map((arg) => {
            return {
              ...arg,
              level: 1,
            };
          });
          this.dropdowns = success.fields
          this.integrationMapperService.currentMapping = this.mapperData
        },
        (error) => {
          this.integrationStepService.isLoaded = true
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
        }
      );
  }

/*   backToPreferences(){
    const scapeValidation = this.mapForm.form.pristine
    this.integrationStepService.changeStep(INTEGRATION_STEPS.preferences, scapeValidation)
  } */

  doneMapping() {
    this.integrationStepService.confirmMapping()
      .afterClosed()
      .subscribe((arg) => {
        if (arg) {
          this.saveForm(this.mapperData).subscribe(
            (success) => {
              this.snackBarSuccess.open(success.message, "Ok", {
                duration: 3000,
                panelClass: 'success-snackbar'
              })
              this.router.navigate(['/settings/external-providers-interactions/salesforce/client-list'])
            },
            (error) => {
              console.log("Error!!!");
              this.snackBarError.open(
                error.error.message,
                "Dismiss",
                {
                  duration: 9000,
                  panelClass: "error-snackbar",
                }
              );
            }
          );
        }
      });
  }

  saveMapping() {
    this.integrationStepService.confirmMapping()
      .afterClosed()
      .subscribe((arg) => {
        if (arg) {
          this.saveForm(this.mapperData).subscribe(
            (success) => {
              this.snackBarSuccess.open(success.message, "Ok", {
                duration: 3000,
                panelClass: 'success-snackbar'
              });
            },
            (error) => {
              console.log("Error!!!");
              this.snackBarError.open(
                error.error.message,
                "Dismiss",
                {
                  duration: 9000,
                  panelClass: "error-snackbar",
                }
              );
            }
          );
        }
      });
  }

  ngOnInit(): void {
  }

  initFormListener() {
    this.form = this.formBuilder.group({
      firstName: [""],
      lastName: [""],
      address: this.formBuilder.group({
        street: [""],
        city: [""],
        state: [""],
        zip: [""],
      }),
    });
  }

  flatenningMapper() {
    this.flattedMapperData[0].content = this.flattenObj(
      this.mapperData[0].content
    );
    this.flat = [];
    this.currentLevel = 0;
    this.flattedMapperData[1].content = this.flattenObj(
      this.mapperData[1].content
    );
    this.flat = [];
    this.currentLevel = 0;
    this.flattedMapperData[2].content = this.flattenObj(
      this.mapperData[2].content
    );
    console.log( this.flattedMapperData)
  }

  public flat: any[] = [];
  public currentLevel = 0;

  flattenObj(obj, startingLevel = 0) {
    obj.forEach((item, index) => {
      this.flat.push({
        ...item,
        level: startingLevel,
      });
      if (item.children.length > 0) {
        if (startingLevel == 0) {
          this.currentLevel = 0;
        }
        this.currentLevel += 1;
        this.flattenObj(item.children, this.currentLevel);
      }
    });
    return this.flat;
  }

  calculatePaddingByLevel(level: number) {
    const padding = 15;
    return `${level * padding}px`;
  }

  saveForm(mapperData) {
    return this.integrationMapperService.saveForm(mapperData)
  }

  /* changeStep(step: string) {
    this.integrationStepService.changeStep(step);
  } */
}
