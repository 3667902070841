import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogConfirm } from 'app/shared/components/dialog-confirm/dialog-confirm.component';
import { SnackbarUtil } from 'app/shared/helpers/snackbar.util';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { InsuranceServiceService } from 'app/views/questionnaires/insurance-hub/insurance-service.service';

@Component({
  selector: 'app-hub-item-card',
  templateUrl: './hub-item-card.component.html',
  styleUrls: ['./hub-item-card.component.scss']
})
export class HubItemCardComponent implements OnInit, OnChanges {

  @Input() public index: any;
  @Input() public id: any;
  @Input() public area: any;
  @Input() public name: string = '';
  @Input() public insuranceCompany: string = '';
  @Input() public policyNumber: string = '';
  @Input() public recommendations: any = 'Yet to generate recommendations';
  @Input() public status: string = 'Snapshot not requested';
  @Input() public snapshotStatus: string = 'validating';
  @Input() public answered: any = false;
  @Output() public selectedItemCardIndex = new EventEmitter<string>();

  public clientData: any;
  public clientID: any;
  public clientHasAnswered: any;
  public deleting: any = false;
  public areaClass: string;

  constructor(
    private advisorService: AdvisorService,
    private authService: AuthenticationService,
    private insuranceService: InsuranceServiceService,
    public snackbar: SnackbarUtil,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    // Get client data
    this.clientID = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');

    // Change card class for each area
    this.areaClass = this.area === 'home' ? 'hub-item-card home' : this.area === 'auto' ? 'hub-item-card auto' : this.area === 'student' ? 'hub-item-card student' : this.area === 'disability' ? 'hub-item-card disability' :'hub-item-card umbrella';
  }

  deleteConfirmation() {
    const dialogRef = this.dialog.open(DialogConfirm, {
      data: 'The questionnaire and all the related data (recommendations) will be deleted. Are you sure you want to remove this asset? This is an irreversible action.'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteItem();
      }
    });
  }
  async deleteItem() {
    try {
      this.deleting = true;
      await this.advisorService.deleteHubSubQuestionnares(this.area, this.clientID, this.index);
      await this.insuranceService.getDataFromService(this.area);
      this.deleting = false;
      this.snackbar.success('Policy deleted correctly', 'Ok');
    } catch(e) {
      console.log(e);
      this.deleting = false;
      this.snackbar.error('An error ocurred. Try again later', 'Dismiss');
    }
  }

  async goToQuestionnaire() {
    try {
      this.insuranceService.resource = {
        index: this.index,
        name: this.name,
        answered: this.answered
      };
      this.selectedItemCardIndex.emit(this.index); /* Emmit card index to remove questionnarie tabs */
      this.router.navigate([`/questionnaire-${this.area}`]);
    } catch(e) {
      console.log(e);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.area = changes.area.currentValue;
  }
}
