import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-status',
  templateUrl: './confirm-status.component.html',
  styleUrls: ['./confirm-status.component.scss']
})
export class ConfirmStatusComponent implements OnInit {

  @Output() handleOnNext: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  cancel(){
    this.handleOnNext.emit(false);
  }

  continue(){
    this.handleOnNext.emit(true);
  }

}
