// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';

import { environment } from '../../../../../../../environments/environment';
import { AdvisorService } from '../../../../../../shared/services/advisor.service';
import { IntegrationStepService } from 'app/shared/services/integration/integration-step.service';
import { INTEGRATION_STEPS } from 'app/shared/services/integration/utils';

// Project
@Component({
  selector: 'app-salesforce-integration',
  templateUrl: './salesforce-integration.component.html',
  styleUrls: ['./salesforce-integration.component.scss']
})
export class SalesforceIntegrationComponent implements OnInit {


  private _isIntegrated: any;
  @Input() set isIntegrated(value: any) {
    this._isIntegrated = value;
    if (value) {
      this.checkSalesforceMappings()
    }
    else{
      this.integrationStepService.changeStep(INTEGRATION_STEPS.instructions)
    }
  }
  
  get isIntegrated(): any {
    return this._isIntegrated;
  }
  @Input() stepper: any;
  @Input() comeFromLogin: any;

  public isLoaded = false

  public isSalesforceConnected: boolean;

  public isCheckedTaskSync:boolean = false;
  public isDisabledCheckedTaskSync: boolean = false;
  public clientListView: boolean = false;
  public isProduction: any;
  public location: any;
  public integrationSteps = INTEGRATION_STEPS

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar,
    public integrationStepService: IntegrationStepService
  ) {}

  ngOnInit() {
    this.integrationStepService.changeStep(INTEGRATION_STEPS.instructions)
    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
    let integrations = JSON.parse(localStorage.getItem('external-providers-interactions'));
    this.isSalesforceConnected = integrations.salesforce;
    this.getAdvisorData();
    
  }

  // When back to itegrations list hide client view
  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  checkSalesforceMappings(){
    this.integrationStepService.isLoaded = false
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    this.http
      .get<any>(
        `${environment.apiSalesforceConfigurations}`,
        options
      )
      .subscribe(
        (success) => {
          this.integrationStepService.hasMapper =  success.savedMapper
          this.integrationStepService.isValidCompany = Object.values(success.showMapper).includes(true)
          this.integrationStepService.isLoaded = true
          this.changeView()
        },
        (error) => {
          this.integrationStepService.isLoaded = true
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
        }
      );
  }

  changeView() {
    if (!this.integrationStepService.isValidCompany) {
      if(sessionStorage.getItem('comesFromIntegration') === 'salesforce' && this.comeFromLogin){
        this.importMoreClients();
      }
      else{
        this.integrationStepService.changeStep(this.integrationSteps.preferences)
      }
    }
    else{
      if (this.integrationStepService.hasMapper  && sessionStorage.getItem('comesFromIntegration') === 'salesforce' && this.comeFromLogin) {
        this.importMoreClients()
        setTimeout(() => {
          sessionStorage.setItem('comesFromIntegration', '');
        }, 1000);
        return
      }
      if (this.integrationStepService.hasMapper  && !(sessionStorage.getItem('comesFromIntegration') === 'salesforce' && this.comeFromLogin)) {
        this.integrationStepService.changeStep(this.integrationSteps.preferences)
      }
      if (!this.integrationStepService.hasMapper && !(sessionStorage.getItem('comesFromIntegration') === 'salesforce' && this.comeFromLogin)){  
        this.integrationStepService.changeStep(this.integrationSteps.preferences)
      }
      if (!this.integrationStepService.hasMapper && (sessionStorage.getItem('comesFromIntegration') === 'salesforce' && this.comeFromLogin)){
        this.openMapper();
        setTimeout(() => {
          sessionStorage.setItem('comesFromIntegration', '');
        }, 1000);
        return
      }
    }
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          data.settings.syncTaskmanagerAndSalesforce === true ? this.isCheckedTaskSync = true : this.isCheckedTaskSync = false;
          localStorage.setItem("external-providers-interactions", JSON.stringify(data.integrations));
          this.isSalesforceConnected = data.integrations.salesforce;
          if (data.integrations.salesforce) {
            this.integrationStepService.stepsChanges.subscribe((step)=>{
              this.checkSalesforceMappings()
            })
          }
        },
        error => {
          console.log(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        });
  }

  connectSalesforce(){

      let client_id = '';
      let redirectUri = '';

      if(this.location == 'localhost:4200'){
        //Local
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsizUKrafz9qYDyjhcV1ClaimPxWd7b3AfSEwvoyvGJs.uzV53Iqd5q1ek9Iku&'
        redirectUri = 'https://api.fpalpha.app/v1/devtools/salesforce/callback&'
      }else if(this.isProduction){
        //Production
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsi09B1P80fkx1fr.frSAG.8jFypwJNdqJPtu7JZkGgP0u8A8Mu6u1Z3o4zRyP'
        redirectUri = 'https://app.fpalpha.com/salesforce/pending'
      }else{
         //Testing
        client_id = '3MVG9cHH2bfKACZbB8Nt_VtGsizUKrafz9qYDyjhcV1ClaimPxWd7b3AfSEwvoyvGJs.uzV53Iqd5q1ek9Iku&'
        redirectUri = 'https://app.fpalpha.app/salesforce/pending'
      }

      window.location.replace(`https://login.salesforce.com/services/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirectUri}&response_type=code`)

  }

  disconnectSalesforce(){
    this.integrationStepService.isLoaded = false;
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiSalesforceDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          this.integrationStepService.isLoaded = true;
          this.integrationStepService.changeStep(INTEGRATION_STEPS.instructions, true);
          console.log(success, 'Salesforce disconnected');
          this.snackBarSuccess.open("Your Salesforce account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
        },
        error => {
          this.integrationStepService.isLoaded = true;
          console.log('Error!!!');
          this.snackBarError.open("Something went wrong disconnecting your Salesforce account. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }

  onNoClick(): void {
    console.log('Nay');
  }

  importMoreClients(){
    this.clientListView = true;
    this.integrationStepService.changeStep(this.integrationSteps.import)
    // this.dialogRef.close('cancelled');
    //this.router.navigate(['salesforce/success']);
  }

  openMapper(){
    this.integrationStepService.changeStep(this.integrationSteps.mapper)
  }

  syncTaskManager(e){

    this.isDisabledCheckedTaskSync = true;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('syncTaskmanagerAndSalesforce', e.checked);

    this.http.post<any>(`${environment.apiAdvisorAdvisorPreferences}`, body.toString(), options)
      .subscribe(
        success => {
          console.log(success, 'Preferences updated');
          this.isDisabledCheckedTaskSync = false;

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
            duration: 5000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

}
