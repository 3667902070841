import { SequentialJob } from './../helpers/SequentialJobsTimerDispatcher';
import { HttpClient } from "@angular/common/http";
import { SequentialJobsDispatcher } from "../helpers/SequentialJobsTimerDispatcher";
import { WINDOW } from '../helpers/window.provider';
import { Inject } from '@angular/core';
//import { SocketResponse } from './socket.io.service';

export class SocketFP {

  public static instance: SocketFP;
  public static onConnectionAction: any;
  public static onDisconnectionAction: any;

  private channelsEventsList: onEvent[] = [];
  public lastMessage: any;
  public poolingTimeInMilliSeconds: number = 5000;
  public unMatchedEventDetected: boolean = false;
  public connected: boolean = false; // For universal (socket.io) compatibility
  public isConnected: boolean = false;



  private constructor(
    public http: HttpClient,
    private serverURL: string,
    private sequentialJobsDispatcherInstance: SequentialJobsDispatcher
  ){}

  public static onConnection(action: ()=>void){
    SocketFP.onConnectionAction = action;
  }

  public static onDisconnection(action: ()=>void){
    SocketFP.onDisconnection = action;
  }

  public static init(
    http: HttpClient,
    serverURL: string,
    sequentialJobsDispatcherInstance: SequentialJobsDispatcher,
  ){
    if (!SocketFP.instance || !SocketFP.instance.isConnected) {
      SocketFP.instance = null;
      SocketFP.instance = new SocketFP(http, serverURL, sequentialJobsDispatcherInstance);
      sequentialJobsDispatcherInstance.updateOrInstertSequentialJob(new SequentialJob(SocketFP.instance.poolingTimeInMilliSeconds, SocketFP.instance.serverRequest, 'FPSocket'));
      SocketFP.instance.connected = true;
    }
    return SocketFP.instance

  }

  // Request to server and process the income data

  public serverRequest(){
    try{
      let prevConnectionStatus: boolean = this.isConnected;
      SocketFP.instance.http.get<any>(SocketFP.instance.serverURL).toPromise()
      .then(async response =>{
        this.isConnected = true;
        let notification = response;
        if(notification != null){ // Only in case of a response
          SocketFP.instance.lastMessage = notification;
          let foundChannelEvent = SocketFP.instance.channelsEventsList.find(event => {return event.channelKey == notification.tag});
          if(foundChannelEvent){
            SocketFP.instance.unMatchedEventDetected = false;
            foundChannelEvent.action(notification);
          }else{
          SocketFP.instance.unMatchedEventDetected = true;
          }
          //await SocketFP.instance.http.delete<any>(SocketFP.instance.serverURL).toPromise();
        }else{
          // No data response, do something.
        }

        if(prevConnectionStatus != this.isConnected){

          if(SocketFP.onConnectionAction == undefined) SocketFP.onConnectionAction = ()=>{};
          if(SocketFP.onDisconnectionAction == undefined) SocketFP.onDisconnectionAction = ()=>{};

          this.isConnected ? SocketFP.onConnectionAction() : SocketFP.onDisconnectionAction();

        }

      })
    }catch(error){
      this.isConnected = false;
      console.log('SocketFP Error:', error);
    }

  }


  // Register new channel events
  public on(channelKey: string, action: (socketResponse: SocketResponse | any)=>void){
    this.channelsEventsList.push({channelKey: channelKey, action: action});
  }

  public disconnect(){
    this.sequentialJobsDispatcherInstance.stopOneJob('FPSocket');
    SocketFP.instance = null;
    this.isConnected = false;
  }

  public close(){
    this.disconnect();
  }

}

// OLD Socket generic response
 interface SocketResponse{
  eventName: string;
  interactionStyle: string;
  message: string;
  type: string;
  id: string
}

interface onEvent{
  channelKey: string;
  action: ( (socketResponse?: SocketResponse)=>void );
}



