import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {environment } from '../../../../environments/environment'
import { Observable } from 'rxjs';
import { QuestionnaireResponse, AbstractQuestionnaire, Question } from 'app/shared/model/questionnaires/Questionnaires.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralQuestionnaireService implements AbstractQuestionnaire {

  constructor(public httpClientService: HttpClient) { }


  postAnswers(questionnaireType: string, clientId: any, answers: any, questionnaireSubtype?: string): Observable<any> {
    if(questionnaireType == 'personal'){
      return this.postPersonalInformationAnswers(clientId, answers)
    }
    if(questionnaireType == 'family'){
      return this.postFamilyAnswers(clientId, answers)
    }
    if(questionnaireType == 'assets'){
      return this.postAssetsAnswers(clientId, answers, questionnaireSubtype)
    }
  }

  putAnswers(questionnaireType: string, clientId: any, answers: any): Observable<any> {
    if(questionnaireType == 'family'){
      return this.putFamilyAnswers(clientId, answers)
    }
    if(questionnaireType == 'assets'){
      return this.putAssetsAnswers(clientId, answers)
    }
  }

  deleteAnswers(questionnaireType: string, clientId: any, answers: any): Observable<any> {
    if(questionnaireType == 'family'){
      return this.deleteFamilyAnswers(clientId, answers)
    }
    if(questionnaireType == 'assets'){
      return this.deleteAssetsAnswers(clientId, answers)
    }
  }

  getAnswers(questionnaireType: string, clientId: any, questionnaireSubtype?: string): Observable<any> {
    if(questionnaireType == 'personal'){
      return this.getPersonalInformationAnswers(clientId)
    }
    if(questionnaireType == 'family'){
      return this.getFamilyAnswers(clientId)
    }
    if(questionnaireType == 'assets'){
      return this.getAssetsAnswers(clientId, questionnaireSubtype)
    }
  }
  

  getPersonalInformationAnswers(clientId: string):Observable<QuestionnaireResponse> {
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('index', '1');
    params = params.append('area', 'general');

    return this.httpClientService.get<QuestionnaireResponse>(environment.apiGQPersonalInformation, {
      params: params
    })
  }

  getFamilyAnswers(clientId: string):Observable<QuestionnaireResponse> {
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('index', '1');

    return this.httpClientService.get<QuestionnaireResponse>(environment.apiGQFamily, {
      params: params
    })
  }

  getAssetsAnswers(clientId: string, assetListType = 'general_questionnaire'):Observable<QuestionnaireResponse> {
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('asset_type', 'all');
    params = params.append('source', assetListType);
    return this.httpClientService.get<QuestionnaireResponse>(environment.apiGQAssets, {
      params: params
    })
  }

  postAssetsAnswers(clientId: string, answers: Question[], assetListType = 'general_questionnaire'){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('source', assetListType);
    return this.httpClientService.post(environment.apiGQAssets, answers, {
      params: params
    })
  }

  postFamilyAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    return this.httpClientService.post(environment.apiGQFamily, answers, {
      params: params
    })
  }

  postPersonalInformationAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('index', '1');
    params = params.append('area', 'general');
    return this.httpClientService.post(environment.apiGQPersonalInformation, answers, {
      params: params
    })
  }

  putFamilyAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('clientBeneficiaryId', answers['relativeId']);
    let ans = {
      clientId: clientId,
      ...answers,
    }
    return this.httpClientService.put(environment.apiGQFamily, ans, {
      params: params
    })
  }

  putAssetsAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('clientAssetId', answers['_id']);
    return this.httpClientService.put(environment.apiGQAssets, answers, {
      params: params
    })
  }

  deleteFamilyAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('clientBeneficiaryId', answers['relativeId']);
    return this.httpClientService.delete(environment.apiGQFamily, {
      params: params
    })
  }

  deleteAssetsAnswers(clientId: string, answers: Question[]){
    let params = new HttpParams();
    params = params.append('idcli', clientId);
    params = params.append('clientAssetId', answers['_id']);
    return this.httpClientService.delete(environment.apiGQAssets, {
      params: params
    })
  }

}
