import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { ClientService } from './client/client.service';
import { AdvisorService } from './advisor.service';
import { EventEmitter } from '@angular/core';
import { AREAS, Area } from '../helpers/areas.catalog';
import { getRandomStr, utilsCalculateAge } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  private clientData: ClientData;
  private clientAuxData: any
  private static subscriber: Subscription;
  private static clientEmitter: EventEmitter<any>;
  private static subscribeCount: number = 0;
  private static subscribeFromCount = [];

  constructor(
    public currentClientService: ClientService,
    public advisorService: AdvisorService
  ){

  }

  afterSetFullCurrentClient(from?: string): EventEmitter<any>{

    /*
    //For debugging subscriptions
    if(ClientDataService.subscribeFromCount[from] == undefined){
      ClientDataService.subscribeFromCount[from] = 0;
    }
    ClientDataService.subscribeFromCount[from]++
    ClientDataService.subscribeCount ++;
    console.log(`afterSetFullCurrentClient. subscribe from: ${from} -> ${ClientDataService.subscribeFromCount[from]} `);
    */

    if(ClientDataService.clientEmitter == undefined){
      ClientDataService.clientEmitter = new EventEmitter();
    }

    return ClientDataService.clientEmitter;
  }



  async setFullCurrentClient(client: any) {

    return new Promise(async(resolve, reject)=>{
      this.clientAuxData = client;

      if(ClientDataService.subscriber == undefined){
        ClientDataService.subscriber = await this.currentClientService.data.generalQuestionnaire.subscribe(data =>{
          this.clientData = this.constructCurrentClient(data) as ClientData;
          this.updateClientData();
          console.log('Update from susbcription', JSON.parse(JSON.stringify(this.clientData)));
          resolve(true)
        })
      }else{
        this.updateClientData();
        console.log('Update from static data', JSON.parse(JSON.stringify(this.clientData)));
        resolve(true)
      }
    })

  }

  private updateClientData(){
    //console.log('updateClientData', this.clientData, this.clientAuxData);
      //console.log('client', client);
      this.clientData['clientId'] = this.clientAuxData.id;
      this.clientData['isProspect'] = this.clientAuxData.isProspect;
      this.clientData['importedFromRedtail'] = this.clientAuxData.importedFromRedtail;
      this.clientData['comesFromAnyIntegration'] = this.clientAuxData["fromIntegration"] != undefined;
      this.clientData['prospectProgress'] = this.clientAuxData.prospectProgress;
      this.clientData['hasGeneralQuest'] = this.clientAuxData.hasGeneralQuest;
      this.clientData['hasEstateDemoAccess'] = (this.clientAuxData.demoAccess != undefined && this.clientAuxData.demoAccess.includes("estateSnapshot"));
      //this.clientData['fullName'] = this.clientAuxData.name === undefined ? this.clientData['fullName'] : this.clientAuxData.name;
      sessionStorage.setItem("currentClient", JSON.stringify(this.clientData));

      //Assign questionnaire data
      sessionStorage.setItem("clientHasAnsweredQuestionnaires", JSON.stringify(this.clientData.clientHasAnsweredQuestionnaires));

      console.log('afterSetFullCurrentClient. emmit ' + getRandomStr(6));
      ClientDataService.clientEmitter.emit(this.clientData);
  }


 constructCurrentClient(obj){

  console.log('obj', obj)

    const names = ['personalInformation', 'assets', 'family', 'dashboardData'];
    const sections = {}
    Object.keys(obj).forEach((key, index) => {
      sections[names[index]] = (index == 3) ? obj[key] : obj[key].data;
    })

    console.log('reducerClientData obj', obj);

    let childrens = sections['family'].find(family => family.type == 'Children');

    let clientHasAnsweredQuestionnaires = (response) => {
      return Area.mapAreasToObject(
        (area) => {
          return {
            ["ans" + area.id.charAt(0).toUpperCase() + area.id.slice(1)]:
              response.clientHasAnsweredQuestionnaires[area.id],
          };
        },
        AREAS
      );
  }


  let clientData = {
      clientId: '',
      name: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDFirstName'),
      lastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDLastName'),
      fullName: (sections['dashboardData']['fullName']),
      photo: sections['dashboardData']['clientIDPhoto'],
      age: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDAge'),
      level: sections['dashboardData']['optLevel'],
      email: sections['dashboardData']['email'],
      isMarried: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDMaritalStatus'),
      hasChildren: childrens != undefined ? childrens.data.length > 0: false,
      hasGrandChildren: (sections['family'].find(family => family.type == 'Grandchildren').data.length) > 0,
      hasBusiness: sections['assets'].business.find(business => business.ownerData.key == 'Client') != undefined,
      //typeOfBusiness: sections['dashboardData']['clientIDHasABusiness'] == 'Yes' : sections['dashboardData']['optLevel'],,
      isRetired: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDRetiredOrNotWorking'),
      livesIn: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDLivingState'),
      isProspect: '',
      childsAreOver18: childrens != undefined ? childrens.data.every(child => {return utilsCalculateAge(new Date(Number(child.birthdate))) >= 18}) : false,
      //childOneAge: this.clientIDChildOneAge,
      //childTwoAge: this.clientIDChildTwoAge,
      //childThreeAge: this.clientIDChildThreeAge,
      //childFourAge: this.clientIDChildFourAge,
      //childFiveAge: this.clientIDChildThreeAge,
      netWorth: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDNetWorth'),
      spouse: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseName'),
      spouseLastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseLastName'),
      spouseAge: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseAge'),
      spouseNetWorth: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseNetWorth'),
      spouseIsRetired: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseRetired'),
      spouseHasBusiness: sections['assets'].business.find(business => business.ownerData.key == 'Co-Client') != undefined,
      spouseUsesOwnLastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseUsesLastName'),
      childrenCount: childrens != undefined ? childrens.data.length : 0,
      hasAssetProperties: sections['assets'].real_estate.length > 0,
      importedFromRedtail: '',
      comesFromAnyIntegration: '',
      prospectProgress: '',
      hasGeneralQuest: '',
      hasEstateDemoAccess: '',
      clientHasAnsweredQuestionnaires: clientHasAnsweredQuestionnaires(sections['dashboardData'])

  }

  return clientData

  }

  static unsubscribe(){

    if(ClientDataService.subscriber){
      ClientDataService.subscriber.unsubscribe();
      ClientDataService.subscriber = undefined;
    }

  }

}



export interface ClientData{
    clientId: string,
    name: string,
    lastName: string
    photo: string,
    age: string,
    level: string,
    email: string,
    isMarried: string, //marital status
    hasChildren: boolean,
    hasGrandChildren: boolean,
    hasBusiness: boolean,
    //typeOfBusiness: sections['dashboardData']['clientIDHasABusiness'] == 'Yes' : sections['dashboardData']['optLevel'],,
    isRetired: string //Yes No,
    livesIn: string,
    isProspect: string,
    childsAreOver18: boolean,
    //childOneAge: this.clientIDChildOneAge,
    //childTwoAge: this.clientIDChildTwoAge,
    //childThreeAge: this.clientIDChildThreeAge,
    //childFourAge: this.clientIDChildFourAge,
    //childFiveAge: this.clientIDChildThreeAge,
    netWorth: string,
    spouse: string, //Spouse name
    spouseLastName: string,
    spouseAge: string,
    spouseNetWorth: string,
    spouseIsRetired: string, //Yes No
    spouseHasBusiness: boolean,
    spouseUsesOwnLastName: string, // Yes No
    childrenCount: number,
    hasAssetProperties: boolean,
    importedFromRedtail: string,
    comesFromAnyIntegration: any,
    prospectProgress: string,
    hasGeneralQuest: string,
    hasEstateDemoAccess: string,
    clientHasAnsweredQuestionnaires: any[],
    fullName: string
}

function readAnswerFromGeneralQuestFormat(generalQuestions: any[], key){
  let question = generalQuestions.find(question =>  question.name == key)
  if(question){
    return question.answer
  }
  return null;
}
