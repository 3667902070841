import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { GestureConfig } from '@angular/material/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { rootRouterConfig } from './app.routing';
import { AuthenticationService } from './shared/services/auth/authentication.service';
import { SharedModule } from './shared/shared.module';
import { WindowRef } from './shared/wrappers/window.service';
import { ESettingsModule } from './views/enterprise-settings/e.settings.module';
import { ExternalSnapshotReviewComponent } from './views/external-snapshot-review/external-snapshot-review.component';
import { EstateFeaturesModalComponent } from './views/new-summary/areas/estate/estate-features-modal/estate-features-modal.component';
import { QuestionnairesModule } from './views/questionnaires/questionnaires.module';
import { ChangePasswordComponent } from './views/sessions/change-password/change-password.component';
import { ConcurrentSessionModalComponent } from './views/sessions/concurrent-session-modal/concurrent-session-modal.component';
import { ExpirationSessionComponentComponent } from './views/sessions/expiration-session-component/expiration-session-component.component';
import { ExpiredSessionModalComponent } from './views/sessions/expired-session-modal/expired-session-modal.component';
import { ForgotPasswordComponent } from './views/sessions/forgot-password/forgot-password.component';
import { ProspectSessionModalComponent} from './views/sessions/prospect-current-session-modal/prospect-session-modal.component';
import { SigninComponent } from './views/sessions/signin/signin.component';
import { TwoFactorAuthModalComponent } from './views/sessions/two-factor-auth-modal/two-factor-auth-modal.component';
import { CalcScheduleDComponent } from './views/tax-projections/calculators/calc-schedule-d/calc-schedule-d.component';
import { CalcTypeOneComponent } from './views/tax-projections/calculators/calc-type-one/calc-type-one.component';
import { CalcTypeTwoComponent } from './views/tax-projections/calculators/calc-type-two/calc-type-two.component';
import { CalculatorsComponent } from './views/tax-projections/calculators/calculators.component';
import { RenameScenarioModalComponent } from './views/tax-projections/scenarios-list-modal/rename-scenario-modal/rename-scenario-modal.component';
import { ScenariosListModalComponent } from './views/tax-projections/scenarios-list-modal/scenarios-list-modal.component';
import { WarningDeleteScenarioModalComponent } from './views/tax-projections/scenarios-list-modal/warning-delete-scenario-modal/warning-delete-scenario-modal.component';
//import { ScenariosComponent } from './views/tax-projections/scenarios/scenarios.component';
import { WarningSaveBeforeComponent } from './views/tax-projections/scenarios/warning-save-before/warning-save-before.component';
import { TaxProjectionsComponent } from './views/tax-projections/tax-projections.component';
import { InsuranceHubModule } from './views/questionnaires/insurance-hub/insurance-hub.module';
import { SnackbarUtil } from './shared/helpers/snackbar.util';
import { SsoLoginComponent } from './views/sessions/sso-login/sso-login.component';

//MSAL

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        QuestionnairesModule,
        ESettingsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule,
        MatCardModule,
        MatOptionModule,
        MatSelectModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(rootRouterConfig, {useHash: false}),
        CurrencyMaskModule,
        NgxMaskModule.forRoot(),
        InsuranceHubModule,
    ],
    declarations: [
      AppComponent,
      ExpirationSessionComponentComponent,
      ExpiredSessionModalComponent,
      SigninComponent,
      ForgotPasswordComponent,
      ChangePasswordComponent,
      ConcurrentSessionModalComponent,
      ProspectSessionModalComponent,
      TwoFactorAuthModalComponent,
      ExternalSnapshotReviewComponent,
      TaxProjectionsComponent,
      CalculatorsComponent,
      /*ScenariosComponent,*/
      ScenariosListModalComponent,
      WarningDeleteScenarioModalComponent,
      RenameScenarioModalComponent,
      WarningSaveBeforeComponent,
      CalcTypeOneComponent,
      CalcTypeTwoComponent,
      CalcScheduleDComponent,
      EstateFeaturesModalComponent,
      SsoLoginComponent,
    ],
    providers: [
        CookieService,
        AuthenticationService,
        WindowRef,
        {provide: LOCALE_ID, useValue: 'en-US'},
        // ANGULAR MATERIAL SLIDER FIX
        {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
        {provide: MatDialogRef, useValue: {}},
        SnackbarUtil
    ],
    entryComponents: [
        ExpirationSessionComponentComponent,
        ExpiredSessionModalComponent,
        ConcurrentSessionModalComponent,
        ProspectSessionModalComponent,
        TwoFactorAuthModalComponent,
        ScenariosListModalComponent,
        RenameScenarioModalComponent,
        WarningDeleteScenarioModalComponent,
        WarningSaveBeforeComponent,
        EstateFeaturesModalComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
    ]
})
export class AppModule { }
