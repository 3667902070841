import { Component, forwardRef, Input, AfterViewInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-select-with-open-field',
  templateUrl: './select-with-open-field.component.html',
  styleUrls: ['./select-with-open-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectWithOpenFieldComponent),
      multi: true
    }
  ]
})
export class SelectWithOpenFieldComponent implements ControlValueAccessor, AfterViewInit{

  value: string;

  @Input() options = []

  @Input() theme = ''

  onChange: (value: any) => void;
  
  onTouch: () => void;

  @ViewChild('select', { static: true }) select: MatSelect;

  constructor() { }

  ngAfterViewInit(): void {
    this.disableKeydownEvents()
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  notifyChange(newValue: any): void {
    this.value = newValue.value;
    this.onChange(this.value);
  }

  customChange(newValue: any){
    if(newValue.target.value === ' ' || (newValue.target.value.length + 1 === newValue.target.value.split(' ').length) ){
      return
    }
    this.value = newValue.target.value;
    this.onChange(this.value);
  }

  getCustomValue(){
    const optionsHasValue = this.options.filter((option) => {
      return option.value == this.value;
    }).length > 0
    if (optionsHasValue) {
      return ''
    }
    else{
      return this.value
    }
  }

  disableKeydownEvents(){
    if (this.select) {
      this.select._handleKeydown = (event: KeyboardEvent) => {
        if (event.keyCode)
          return
        if (!this.select.disabled) {
          this.select.panelOpen
            ? this.select['_handleOpenKeydown'](event)
            : this.select['_handleClosedKeydown'](event);
        }
      };
    }
  }

}
