import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { creditor_questions } from './creditor.model';

@Component({
  selector: 'app-q-creditor',
  templateUrl: './q-creditor.component.html',
  styleUrls: ['./q-creditor.component.scss']
})
export class QCreditorComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  public model: creditor_questions;
  public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: boolean;
  public clientMaritalStatus: boolean;
  public clientHasChildren: string;
  public clientIsRetired: string;
  public clientAge: number;
  public clientLivesInNY: boolean;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public bulletColor;

  public clientID: string;
  public areasInHub;
  public isLoading: boolean;
  public deleteStudentQuestion: boolean;
  public toggleSearchBar: boolean = false;


  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public  authService: AuthenticationService,
    public qController: QuestionnaireController,
    public advisorService: AdvisorService
    ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried == "Married" || this.clientData.isMarried == "Domestic Partner";
    this.clientData.hasBusiness === 'Yes' ? this.clientHasBusiness = true : this.clientHasBusiness = false;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientIsRetired = this.clientData.isRetired;
    this.clientAge = this.clientData.age;
    this.clientData.livesIn === 'New York' ? this.clientLivesInNY = true : this.clientLivesInNY = false;
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    

    //Initialize the questionnaire model
    this.model = new creditor_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansCreditor;

    // Declare the auto save logic
    let comonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, the load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'creditor', this.model).then(response => {
        
        
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(comonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(comonAutoSaveFunction);
    }


    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;

    //Read questionnaire list
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = this.authService.isAdvisor() ? storagedClient.clientId : localStorage.getItem('userId');

    this.advisorService.getHubAreas(this.clientID)
      .subscribe(
        data => {
            this.areasInHub = this.mergAreaData(data);
            
            for(let i = 0; i< this.areasInHub.length;i++){
              if(this.areasInHub[i].nameOfAreaComplex == "Student Debt"){
                this.deleteStudentQuestion = true;
                this.model.clientIDHasStudentLoan = 'Yes';
              }
            }
            this.auxModel = {
              ...(this.auxModel ? { ...this.auxModel } : {}),
              ...this.model,
            };
          },
          error => {
            console.log(error);
            this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
            this.isLoading = false;

          }
      );
      this.auxModel = {
        ...(this.auxModel ? { ...this.auxModel } : {}),
        ...this.model,
      };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {
    this.qController.submitForm(this, this.model, this.clientData.clientId, 'creditor', saveForLater, null, silentSave);
  }

  countTabs(tabs:number){
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  this.model.progressTab = tabChangeEvent.index;
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
  }

  mergAreaData(areasFromBack:any[]){
    let mergedData = [];
    areasFromBack.forEach(areaFromBack => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'creditor'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'creditor-questionnaire.pdf');
      });
  }

}
