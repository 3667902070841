import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css']
})
export class AppLoaderComponent extends CloseDialogAtLogOut implements OnInit {
  title;
  message;
  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>,
  private authService: AuthenticationService
  ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
  }

}
