import { DataPipe, DataManagerSubjects } from '../model/DataPipe.model';
import { LocalStorageManager } from './LocalStorageManager';
import { forkJoin, timer, BehaviorSubject, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

export class DataManager{

    pipes: DataPipe;

    data: DataManagerSubjects;

    localStorageManager: LocalStorageManager;

    clientId: string;

    private CLIENT_STORAGE_KEY = 'clientIDX';

    private DELAY_THRESHOLD = 300;

    constructor(dataPipe: DataPipe){
        this.pipes = dataPipe;
        this.localStorageManager = new LocalStorageManager();
        this.data = this.getSubjects();
    }

    async initManager(clientId?: string){
      return new Promise((resolve, reject) => {
        if (clientId) {
          this.clearAllLocalData(this.clientId)
        }
        this.clientId = this.findSomeClientId(clientId);
        this.localStorageManager.setItem(this.CLIENT_STORAGE_KEY, this.clientId)
        timer(this.DELAY_THRESHOLD).subscribe(async() => {
            this.emitLocalData(this.clientId);
            await this.runAllEtl(this.clientId).then(r=>{
              resolve(true)
            })

       });
      })
    }

    findSomeClientId(clientId?: string){
        if (clientId) {
            return clientId;
        }
        let id = this.localStorageManager.getItem(this.CLIENT_STORAGE_KEY)
        if (id) {
            return id;
        }
        else{
            throw new Error(" ---- Client ID was not found ----");
        }
    }

    emitLocalData(clientId: string){
        if (this.data) {
            Object.keys(this.pipes).forEach((pipesKey, pipesIndex) => {
                const clientDataForKey = this.localStorageManager.getItem(`${pipesKey}:${clientId}`);
                if (clientDataForKey) {
                    this.data[pipesKey].next(clientDataForKey)
                }
                else{
                    //TODO: Este error no se emite porque está dentro del for loop.
                    //Hay que sacarlo para manejar el caso de que no encuentre información local
                    //throw new Error(" ---- No local data for Client ID ----");
                }
            })
        }
    }

    getData(){
        return (() => {
            return this.data
        })()
    }

    saveLocalData(clientId: string, data: any){
        this.localStorageManager.setItem(clientId, data);
    }

    clearAllLocalData(clientId: string){
        Object.keys(this.pipes).forEach((pipesKey, pipesIndex) => {
            this.localStorageManager.removeItem(`${pipesKey}:${clientId}`)
        })

    }

    updateAll(){
        this.runAllEtl(this.clientId)
    }

    runAllEtl(clientId: string){
      let promises = [];

        Object.keys(this.pipes).forEach((pipesKey, pipesIndex) => {
          promises.push(new Promise((resolve, reject) => {
              // 1. get the data from the sources
              let compositeResponse = []
              forkJoin(this.pipes[pipesKey].sources.map(item => item(clientId))).subscribe((forkResults) => {
                forkResults.forEach((arg) => {
                    compositeResponse.push(arg)
                })
                //2. transform the responses using its own DTO
                let transformedResponse = {}
                this.pipes[pipesKey].reducers.forEach((reducer) => {
                    transformedResponse = reducer(compositeResponse)
                })
                //3. Emit the value of each property
                this.data[pipesKey].next(transformedResponse)
                //4. Save on local storage
                this.saveLocalData(`${pipesKey}:${clientId}`, transformedResponse);
                resolve(true);
            })
          }))
        })

        return Promise.all(promises);

    }

    getSubjects(): DataManagerSubjects{
        const subjects = {}
        Object.keys(this.pipes).forEach((key)=> {
            subjects[key] = new BehaviorSubject<any>(null);
        });
        return subjects
    }

    createObservableProperty( propExecutor: (data:any) => unknown, pipeSource: string ): Observable<any>{
        const observable = this.data[pipeSource].asObservable()
        return observable.pipe(
            map((arg) => propExecutor(arg)),
            distinctUntilChanged((prev, curr) => this.deepEqual(prev, curr))
        );
    }

    deepEqual(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) return true;
        if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
            if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
            for (const key in obj1) {
                if (obj1.hasOwnProperty(key)) {
                    if (!obj2.hasOwnProperty(key)) return false;
                    if (!this.deepEqual(obj1[key], obj2[key])) return false;
                }
            }
            return true;
        }
        return false;
    }
}
