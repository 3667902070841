import {Component, OnInit} from '@angular/core';
import {AdvisorService} from "../../../shared/services/advisor.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { Router } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public isImageUploading: boolean = false;

  public advisorData;
  public advisorPreferences;
  public imageBG: File | string;
  public userImage: File | string;
  public firmLogo: File | string;
  public imageURL: string = '';
  public profileImageURL: string = '';
  public trialExpiresIn: any;
  public leadURL: string;
  public enabled2FA: boolean;
  public redtailIntegrated: boolean = false;
  public email: string = '';
  public advisorFullName: string = '';
  public advisorLastName: string = '';
  public officePhone: string = '';
  public phone: string = '';
  public wantsToChangeEmail: boolean = true;


  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarError: MatSnackBar,
    public snackBarSuccess: MatSnackBar,
  ) {
  }

  updateAdvisorInfoForm = new FormGroup({
    newFullName: new FormControl(this.advisorFullName, Validators.required),
    newLastName: new FormControl(this.advisorLastName, Validators.required),
    updateEmail: new FormControl(),
    newEmail: new FormControl(this.email, [Validators.email, Validators.required],),
    newPhone: new FormControl(this.officePhone)
  });

  async ngOnInit() {
    await this.getAdvisorData();
    this.updateAdvisorInfoForm.controls['newFullName'].setValue(this.advisorFullName);
    this.updateAdvisorInfoForm.controls['newEmail'].disable();
    this.updateAdvisorInfoForm.controls['newEmail'].setValue(this.email);
    this.updateAdvisorInfoForm.controls['newPhone'].setValue(this.officePhone);
  }

  getAdvisorData() {
    return new Promise((resolve, reject) => {
      this.advisorService.getAdvisorData().toPromise().then(
        data => {
          this.imageBG = data.leadPageBackground && 'uploaded';
          this.userImage = (!data.advIDPhoto.includes('/fpalpha-assets/iconos') && data.advIDPhoto !== '') ? 'uploaded' : '';
          this.firmLogo = '';
          this.imageURL = data.leadPageBackground ? data.leadPageBackground : '';

          //this.userImage = (!data.advIDPhoto.includes('/fpalpha-assets/iconos') && data.advIDPhoto) && 'uploaded';
          this.profileImageURL = (!data.advIDPhoto.includes('/fpalpha-assets/iconos') && data.advIDPhoto) ? data.advIDPhoto : 'https://storage.googleapis.com/fpalpha-assets/iconos/02_Avatar/02_Clients/fpalpha3.svg';
          //https://storage.googleapis.com/fpalpha-assets/iconos/02_Avatar/02_Clients/fpalpha3.svg

          this.advisorData = data;

          this.advisorFullName = this.advisorData.fullName;
          this.email = this.advisorData.email;
          this.officePhone = this.advisorData.officePhone;

          if (this.advisorData.hasEnabled2FA === true) this.enabled2FA = true;
          this.advisorData.integrations.redtail === true ? this.redtailIntegrated = true : this.redtailIntegrated = false;

          let today = new Date();
          let endTrialDate = new Date(this.advisorData.freeTrialExpiresOn);
          const diffTime = endTrialDate.getTime() - today.getTime();
          this.trialExpiresIn = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          this.advisorPreferences = this.advisorData.settings;

          this.leadURL = this.advisorData.leadURL;

          resolve('data-loaded')

        },
        error => {
          console.error(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
          reject('error');
        }).catch(
          error => console.log('Error on getting advisor data', error)
        );
    })
  }

  allowEmailUpdate() {
    this.wantsToChangeEmail = !this.wantsToChangeEmail

    !this.wantsToChangeEmail ? this.updateAdvisorInfoForm.get('newEmail').enable() : this.updateAdvisorInfoForm.get('newEmail').disable();
  }

  async updateAdvisorInfo() {

    let saveAdvisorData = this.updateAdvisorInfoForm.value;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };


    let body = new URLSearchParams();
    body.set('fullName', saveAdvisorData.newFullName);
    // body.set('email', saveAdvisorData.newEmail);
    body.set('email', this.email);
    body.set('lastName', saveAdvisorData.newLastName);
    body.set('officePhone', saveAdvisorData.newPhone);


    if (this.userImage !== 'uploaded' && this.userImage !== '' && this.userImage !== undefined && this.userImage !== 'undefined') {
      this.isImageUploading = true;
      const formData = new FormData();
      formData.append('img', this.userImage);
      await this.http.post<any>(`${environment.apiAdvisorImage}/${localStorage.getItem('userId')}`, formData).toPromise();
      this.isImageUploading = false;
    }


    this.http.put<any>(`${environment.apiAdvisorAdvisorProfile}`, body.toString(), options)
      .subscribe(() => {
          let snackBarRef = this.snackBarSuccess.open("Information updated, the app will reload to apply changes", "Ok", {
            duration: 10000,
            panelClass: 'success-snackbar'
          });

          this.router.navigate(['/advisor/clients']);

          setTimeout(() => {
            this.reload();
          }, 3000);

          snackBarRef.onAction().subscribe(() => this.reload());

          this.advisorService.getAdvisorData()
            .subscribe(
              data => {
                this.advisorData = data;
                this.advisorData.hasEnabled2FA === true ? this.enabled2FA = true : this.enabled2FA = false;
              },
              error => {
                console.log(error);
              });

        },
        error => {
          console.log(error);
          this.snackBarError.open("Cannot update your data. " + error.error.message, "Dismiss", {
            duration: 4000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }


  reload() {
    window.location.reload();
  }

}
