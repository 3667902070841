import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss']
})

export class RoleModalComponent extends CloseDialogAtLogOut implements OnInit {

  public roleName: string = '';
  public roleId: string = '';
  public permissionListSelected: Array<string> = [];
  public objectPermissionListSelected: any = {};
  public permissionList: Array<any> = [];

  public groupPermissions: Array<any> = ['Client', 'Recommendation', 'General'];

  constructor(
    public dialogRef: MatDialogRef<RoleModalComponent>,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) {
      super(dialogRef, authService);
      this.permissionList = data.permissions;
      if(data.roleData){
        this.roleId = data.roleData._id;
        this.roleName = data.roleData.name;
        this.permissionListSelected = data.roleData.permissions;
        for(const key of this.permissionList){
          this.objectPermissionListSelected[key.id] = false;
        }
        this.permissionListSelected.forEach(element => {
          this.objectPermissionListSelected[element] = true;
        })
      }
    }

  ngOnInit() {

  }


  cancel(){
    this.dialogRef.close('cancel');
  }

  createRole(){
    const body = {
      name: this.roleName,
      description: '',
      permissions: this.permissionListSelected
    }

    if(this.roleId === ''){
      //Create role
      this.dialogRef.close({type: 'create', body});
    }else{
      //Edit role
      this.dialogRef.close({type: 'edit', roleId: this.roleId, body});
    }
  }

  handleChangePermission(event){
    if(event.checked){
      this.permissionListSelected.push(event.source.name);
    }else{
      this.permissionListSelected.splice(this.permissionListSelected.indexOf(event.source.name), 1);
    }
  }

} //@end
