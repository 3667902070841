import { getRandomStr } from "app/shared/helpers/utils";

export function updateFamilyGraph(graphFromBack: any){
  let originalFlow = JSON.parse(JSON.stringify(graphFromBack));


  let addBoxNodeClass = () => {
    originalFlow['nodesType'] = 'FamilyBox';
    originalFlow.nodes.map((node: any) => {
      if(!node['metaData']['class'].includes('box-node')){
        node['metaData']['class']= node['metaData']['class'] + ' box-node';
      }
    });
  }
  let centerNodes = () => {
    originalFlow['grid']['rowsMetadata'] = [];
    originalFlow["grid"]["rowsNodesById"].map(() => {
      let rowMetadata = {
        style: {
          display: "flex",
          "align-items": "center",
        },
      };
      originalFlow["grid"]["rowsMetadata"].push(rowMetadata);
    });
  }

  let gridToOthers = []
  let separateGrid = (rowGrid) => {
    let newGrid = [];

    let chunk = rowGrid.slice(0, 6);
    if(chunk.length < 6){
      for(let i = chunk.length; i < 6; i++){
        let rndStg = getRandomStr(10);
        chunk.push(rndStg);
        originalFlow.nodes.push(createNode(rndStg, false));
      }
    }
    newGrid.push(chunk);
    rowGrid = JSON.parse(JSON.stringify(rowGrid.filter(node => !chunk.includes(node))));
    if(rowGrid.length){
      separateGrid(rowGrid);
    }
    gridToOthers.push(...newGrid);
    console.log('grid others**', gridToOthers);
    //return newGrid;
  }

  addBoxNodeClass();
  centerNodes();
  console.log('originalFlow**', originalFlow);
  let parents = graphFromBack?.grid?.rowsNodesById[0];
  let parentsCopy = JSON.parse(JSON.stringify(parents));
  let nodesByGroup = [];

  // console.log('parents**', parents);
  if(parents.length === 1 && originalFlow.connections.length && originalFlow.nodes.length > 6){
    let auxGrid = JSON.parse(JSON.stringify(originalFlow.grid.rowsNodesById[1]));
    separateGrid(auxGrid);
    originalFlow.grid.rowsNodesById = gridToOthers.reverse();
    originalFlow.grid.rowsNodesById.unshift(parents);
    addBoxNodeClass();
    centerNodes();
    console.log('Pasa por aquí**', originalFlow);
    return originalFlow;
  }else if(parents.length !== 2 && originalFlow.connections.length) {
    console.log('retorna aquí**', originalFlow);
    return originalFlow;
  }
  if(parents.length !== 2 && !originalFlow.connections.length && originalFlow.nodes.length < 7){
    return originalFlow;
  }


  // Get children nodes of parents
  let findChildrenParents = graphFromBack.connections.filter(
    connection => connection.sourceNodeId.includes(parents[0]) && connection.sourceNodeId.includes(parents[1]));

  //if findChildrenParents has data, filter this data to can get the id nodes
  let nodesChildrenParents = [];
  if(findChildrenParents.length){
    findChildrenParents.map((obj) => {
      obj.nodeConnections.map(node => {
        nodesChildrenParents.push(node.pointsToNodeId);
      })
    });
  }

  //filter parents to know if they have children separately
  let nodesOfParents = [];
  parents.map((node) => {
    let nodesByOneParent = []
    let findConectionsNodes = graphFromBack.connections.filter(connection => {
      let sourceNode = Array.isArray(connection.sourceNodeId) && connection.sourceNodeId.length === 1 ? connection.sourceNodeId[0]:connection.sourceNodeId;
      if(sourceNode === node){
        return connection;
      }
    });
    findConectionsNodes.map((objConnection) => {
      objConnection.nodeConnections.map((connection) => {
        if(!parents.includes(connection.pointsToNodeId)){
          nodesByOneParent.push(connection.pointsToNodeId);
        }
      });
    });
    nodesOfParents.push(nodesByOneParent);
  });


  nodesByGroup = [[...nodesOfParents[0]], [...nodesChildrenParents], [...nodesOfParents[1]]];
  // console.log('nodesByGroup**', JSON.parse(JSON.stringify(nodesByGroup)));

  let newConnections = [];
  let newGrid = [];
  let gosthNodes = [];

  let reformatFlow = (nodesByGroup, firstTime?) => {
    let newRowGrid = [];
    nodesByGroup.map((sectionParent, index) => {
      let nodesFromSection = sectionParent.splice(0, 2);
      // console.log('nodesFromSection**', nodesFromSection, 'parent**', index, 'gridParents**', gridParents);
      nodesFromSection.map((childNode: any) => {
        let source = index === 0 ? gridParents.slice(0, 2) : index === 2 ? gridParents.slice(4, 6) : parentsCopy;
        let origin = index === 0 ? 'L' : index === 2 ? 'R' : 'B';
        if((index === 0 || index === 2) && firstTime && nodesFromSection.length === 1){
          source = parentsCopy[index === 0 ? 0 : 1];
          origin= index === 0 ? 'L' : 'R';
        }
        createConnection(source, childNode, origin, 'T', false);
      })
      if(nodesFromSection.length < 2){
        for(let i = nodesFromSection.length; i < 2; i++){
          let rndStg = getRandomStr(10);
          // nodesFromSection.push(rndStg);
          index === 0 && firstTime && nodesFromSection.length === 1 ? nodesFromSection.unshift(rndStg) : nodesFromSection.push(rndStg);
          gosthNodes.push(createNode(rndStg, false));

        }
      }
      newRowGrid.push(...nodesFromSection);
    });
    newGrid.push(newRowGrid);
    newRowGrid = [];

    let hasElements = (arr) => arr.length;
    if (nodesByGroup.some(hasElements)){
      reformatFlow(nodesByGroup);
    }
  }
  let gridParents = [];

  let createConnection = (source, pointsToNodeId, anchorOrigin, anchorDestionation, isForced = true) => {
    let connection = {
      sourceNodeId: source,
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: pointsToNodeId,
          anchorOrigin: anchorOrigin,
          anchorDestination: anchorDestionation,
        },
      ],
      isForced: isForced
    };
    //console.log('connection created**', connection);
    newConnections.push(connection);
  }

  let updateOnlyParents = (gridParentsArray) => {
    for(let i = 0; i < 4; i++){
      let rndStg = getRandomStr(10);
      gridParents.push(rndStg);
      gosthNodes.push(createNode(rndStg, true));
    }
    gridParents.splice(2, 0, gridParentsArray[0], gridParentsArray[1]);
  }

  let updateParentsGraph = (gridParentsArray) => {
    for(let i = 0; i < 4; i++){
      let rndStg = getRandomStr(10);
      gridParents.push(rndStg);
      gosthNodes.push(createNode(rndStg, true));
    }
    gridParents.splice(2, 0, gridParentsArray[0], gridParentsArray[1]);
    gridParentsArray.map((parent, index) => {
      if(index === 0 && nodesByGroup[0].length > 1){
        createConnection([gridParents[0], gridParents[1]], parent, 'R', 'L');
      }else if (index === 1 && nodesByGroup[2].length > 1){
        createConnection([gridParents[4], gridParents[5]], parent, 'L', 'R');
      }
      originalFlow.connections.map((connection: any) => {
        let sourceNoode = Array.isArray(connection.sourceNodeId) ? connection.sourceNodeId[0] : connection.sourceNodeId;
        if(sourceNoode === parent && connection.nodeConnections[0].pointsToNodeId !== gridParentsArray[index + 1]){
          // console.log('parent**', parent);
          if(index === 0){
            connection.sourceNodeId = [gridParents[0], gridParents[1]];
            connection.nodeConnections.map((connect: any) => {
              connect.anchorOrigin = "B";
            })
          }else if(index === 1){
            connection.sourceNodeId = [gridParents[4], gridParents[5]];
            connection.nodeConnections.map((connect: any) => {
              connect.anchorOrigin = "B";
            })
          }
          // console.log('connection**', JSON.parse(JSON.stringify(connection)));
        }else if(sourceNoode === parent && connection.nodeConnections[0].pointsToNodeId == gridParentsArray[index + 1]) {
          createConnection(sourceNoode, gridParentsArray[1], 'R', 'L')
        }
      });
    });
  }



  //verify if only exist two nodes (client and co-client) to can update the view
  if(originalFlow.connections.length === 1 && originalFlow.grid.rowsNodesById[0].length === 2 && originalFlow.nodes.length === 2){
    let newGridNodes = [];
    for(let i = 0; i<4 ; i++){
      let rndStg = getRandomStr(10);
      newGridNodes.push(rndStg);
      originalFlow.nodes.push(createNode(rndStg, false));
    }
    newGridNodes.splice(2, 0, ...originalFlow.grid.rowsNodesById[0]);
    originalFlow.grid.rowsNodesById[0] = newGridNodes;
    console.log('only client and coclient**', originalFlow);
    return originalFlow;
  }

  // this sections checks if there are more of 6 nodes in the first row and returns an array of array that not has more of six nodes per row
  if(!originalFlow.connections.length && originalFlow.nodes.length > 6){
    if (originalFlow.grid.rowsNodesById[0].length > 6){
      let auxGrid = JSON.parse(JSON.stringify(originalFlow.grid.rowsNodesById[0]));
      separateGrid(auxGrid);
    }
    originalFlow.grid.rowsNodesById = gridToOthers.reverse();
    centerNodes();
    console.log('Hola flow**', originalFlow);
    return originalFlow;
  }

  if(originalFlow.connections.length && nodesByGroup[1].length > 6 && !nodesByGroup[0].length && !nodesByGroup[2].length){
    if (originalFlow.grid.rowsNodesById[1].length > 6){
      let auxGrid = JSON.parse(JSON.stringify(originalFlow.grid.rowsNodesById[1]));
      separateGrid(auxGrid);
    }
    updateOnlyParents(parentsCopy);
    originalFlow['nodes'].push(...gosthNodes);
    originalFlow.grid.rowsNodesById = gridToOthers.reverse();
    originalFlow.grid.rowsNodesById.unshift(gridParents);
    centerNodes();
    console.log('originalFlow compartidos**', originalFlow);
    return originalFlow;
  }

  if(nodesByGroup[0].length || nodesByGroup[2].length){
    updateParentsGraph(parentsCopy);
    reformatFlow(nodesByGroup, true);
    originalFlow['grid']['rowsNodesById'] = [gridParents, ...newGrid];
    originalFlow['nodes'].push(...gosthNodes);
    originalFlow['connections'] = newConnections;
    originalFlow['grid']['rowsMetadata'] = [];
    originalFlow["grid"]["rowsNodesById"].map(() => {
      let rowMetadata = {
        style: {
          display: "flex",
          "align-items": "center",
        },
      };
      originalFlow["grid"]["rowsMetadata"].push(rowMetadata);
    });
    console.log(
      "originalFlow con hijos**",
      JSON.parse(JSON.stringify(originalFlow))
    );

  }else{
    console.log('No hay hijos exclusivos**');
    originalFlow['grid']['rowsMetadata'] = [];
    originalFlow["grid"]["rowsNodesById"].map(() => {
      let rowMetadata = {
        style: {
          display: "flex",
          "align-items": "center",
        },
      };
      originalFlow["grid"]["rowsMetadata"].push(rowMetadata);
    });
    console.log(
      "originalFlow sin hijos**",
      JSON.parse(JSON.stringify(originalFlow))
    );
    return originalFlow;
  }
  console.log('sigue retornando**');


  return originalFlow;

}

export function createNode (id, pathBlocker = true) {
  let node = {
    id: id,
    content: {},
    metaData: {
      class: "box-node",
      pathBlocker: pathBlocker,
    }
  }
  return node;
}
