import { Component, Inject, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SnackbarUtil } from 'app/shared/helpers/snackbar.util';
import { WINDOW } from 'app/shared/helpers/window.provider';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { FileUploaderExtended } from 'app/views/questionnaires/FileUploaderExtended.model';
import { QuestionnaireController } from 'app/views/questionnaires/Questionnaire.controller';
import { FileUploaderOptions } from 'app/views/questionnaires/file-uploader-component/file-uploader-component.component';
import { environment } from 'environments/environment';

export const REGEX_PDF = /([a-zA-Z0-9\s_\\.\-\(\):])+(.pdf|.PDF)$/;

@Component({
  selector: 'app-file-uploader-v2',
  templateUrl: './file-uploader-v2.component.html',
  styleUrls: ['./file-uploader-v2.component.scss']
})
export class FileUploaderV2Component implements OnInit, OnChanges {

  @ViewChild('fileElement', { static: false }) fileElement;
  @Input() public documentType: string;
  @Input() public fileID: string;
  @Input() public name: string;
  @Input() public allowMultiple: boolean = false;
  @Input() public defaultValue: any = null;
  @Input() public previousData: any = null;
  @Input() public date: any = null;
  @Input() public disabled: any = false;
  @Input() public index: number = 1;
  @Input() public subType: string = '';
  @Input() public textClass: string = '';
  @Input() public whereToUpload: string = 'estate';
  @Input() public nameOfInsuranceCompany = '1';
  @Input() public policyFileName = 'clientIDOtherEstate';
  @Output() public updateUploaderStatus: any = new EventEmitter();
  @Input() public showWarning: boolean = false;
  public uploader: FileUploaderOptions;
  public clientData: any;

  private batchUpload = environment.apiAdvisor;
  private uploadUrl: string;
  public dragging: Boolean = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    public qController: QuestionnaireController,
    public advisorService: AdvisorService,
    private snackbarUtil: SnackbarUtil
  ) {}

  addFiles() {
    this.fileElement.nativeElement.click();
  }

  handleChangeFile(event) {
    this.uploader.uploader.clearQueue();
    this.uploader.uploader.addToQueue(event);
  }

  logQueue(e: any = null) {
        const allowedUnits = ['Bytes', 'KB', 'MB'];
    if (e && !this.allowMultiple) {
      const file = e.item(0);
        if (!REGEX_PDF.test(file.name)) {
          this.snackbarUtil.error('Only PDF files are allowed. Please, try again.', 'Dismiss');
          this.removeFromQueue(0);
        } else {
          if (file) {
            const { size, unit }: any = this.getFileSize(file?.size, true);
            if (!allowedUnits.includes(unit) || (allowedUnits.includes(unit) && unit === 'MB' && size > 200)) {
              this.snackbarUtil.error('File upload limit is up to 200 MB. Try with a different file.', 'Dismiss');
              this.removeFromQueue(0);
            } else {
              this.handleChangeFile(e || file);
                this.updateUploaderStatus.emit({
                  uploader: this.uploader,
                  fileKey: this.fileID,
                  index: this.index
                })
            }
          }
        }
    }

    if (this.allowMultiple && e) {
      const notUpload = [];
      let notUploadBySize = false;
      for (let i = 0; i < e.length; i++) {
        const file = e.item(i)
        if (!REGEX_PDF.test(file.name)) {
          this.snackbarUtil.error('Only PDF files are allowed. Please, try again.', 'Dismiss');
          notUpload.push(file.name);
        } else {
          const { size, unit }: any = this.getFileSize(file?.size, true);
          console.log(file);
          if (!allowedUnits.includes(unit) || (allowedUnits.includes(unit) && unit === 'MB' &&size > 200)) {
            notUploadBySize = true;
            notUpload.push(file.name);
          }
        }

      }
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file = this.uploader.uploader.queue[i]?.file;
        if (notUpload.includes(file?.name)) {
          this.removeFromQueue(i);
        }
      }
      if (notUploadBySize) {
        this.snackbarUtil.error('Some files won\'t be uploaded. Upload file size limit is up to 200 MB. Try with a different file.', 'Dismiss');
      }
    }

    if (!this.allowMultiple && !e) {
      const individualFile = this.uploader.uploader.queue[0];
      console.log(individualFile, 'HOLAAAAAAAAAAAA NO MULTIPLE y NO E');
      if (individualFile) {
        if (!REGEX_PDF.test(individualFile?.file.name)) {
          this.snackbarUtil.error('Only PDF files are allowed. Please, try again.', 'Dismiss');
          this.removeFromQueue(0);
        } else {
            const { size, unit }: any = this.getFileSize(individualFile?.file?.size, true);
            console.log('Size', size)
            if (!allowedUnits.includes(unit) || (allowedUnits.includes(unit) && unit === 'MB' && size > 200)) {
              this.snackbarUtil.error('File upload limit is up to 200 MB. Try with a different file.', 'Dismiss');
              this.removeFromQueue(0);
            } else {
              this.handleChangeFile([individualFile?.file]);
              this.updateUploaderStatus.emit({
                uploader: this.uploader,
                fileKey: this.fileID,
                index: this.index
              })
            }
        }
      }
    }
    if (this.allowMultiple && !e) {
      const notUpload = [];
      let notUploadBySize = false;
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file = this.uploader.uploader.queue[i]?.file
        if (!REGEX_PDF.test(file.name)) {
          this.snackbarUtil.error('Only PDF files are allowed. Please, try again.', 'Dismiss');
          notUpload.push(file.name);
        } else {
          const { size, unit }: any = this.getFileSize(file?.size, true);
          console.log(file);
          if (!allowedUnits.includes(unit) || (allowedUnits.includes(unit) && unit === 'MB' && size > 200)) {
            notUploadBySize = true;
            notUpload.push(file.name);
          }
        }
      }
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file = this.uploader.uploader.queue[i]?.file;
        if (notUpload.includes(file?.name)) {
          this.removeFromQueue(i);
        }
      }
      if (notUploadBySize) {
        this.snackbarUtil.error('Some files won\'t be uploaded. Upload file size limit is up to 200 MB. Try with a different file.', 'Dismiss');
      }
      if (notUpload.length === 0) {
        this.updateUploaderStatus.emit({
          uploader: this.uploader,
          fileKey: this.fileID,
          index: this.index
        })
      }
    }
  }

  public isLoading: boolean = true;

  ngOnInit() {
    console.log(this.whereToUpload, this.previousData);
    let sessionToken = localStorage.getItem("sessionToken");
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.uploadUrl = `${this.batchUpload}/area/${this.clientData.clientId}/fileupload/${this.whereToUpload}?index=${this.index}&policyFilename=${this.policyFileName}&nameOfInsuranceCompany=${this.nameOfInsuranceCompany}`;

    this.uploadUrl = this.advisorService.dynamicIPAddress(
      this.window,
      this.uploadUrl
    );
    this.uploader = new FileUploaderOptions({
      fileID: this.fileID,
      fileVal: 'No',
      uploader: this.fileID === 'clientIDFiles' || this.fileID === 'clientIDClientHasUmbrellaDocument' || this.fileID === 'clientIDHomePolicy'
        ?
        new FileUploaderExtended({
          url: this.uploadUrl,
          authTokenHeader: "Authorization",
          authToken: `Bearer ${sessionToken}`,
          itemAlias: "clientIDFiles"
        })
        : this.qController.fileUploaderFactoryAutoUpload(
            this.window,
            this.clientData.clientId,
            this.whereToUpload,
            false,
            this.index,
            this.fileID === 'clientIDFiles'
              ? 'cientIDOtherEstate'
              : this.fileID,
            this.allowMultiple
              ? 20
              : 1,
              true,
              this.nameOfInsuranceCompany
          ),
      documentReadableName: this.documentType,
      validationRule: () => { return true /* return this.uploaderWill.uploader.queue.length <= 0 ? true : this.uploaderWill.status == UPLOADER_STATUS.FILE_VALID */ },
      actionsAfterResponse: {
        validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, this.fileID, ()=>{} )},
        validating: () => {

        }
      },
      ignoreIsComponentAlive: true,
    });
    console.log('Default: ', this.index, this.defaultValue, this.fileID, this.previousData);
    if (this.defaultValue && this.fileID !== 'clientIDFiles') {
      console.log('PRIMER COSA');
      this.uploader.uploader.addToQueue([this.defaultValue?.uploader?.queue[0]?.file?.rawFile || this.defaultValue]);
      console.log('UPLOADER: ', this.uploader);
    } else if (this.defaultValue) {
      console.log('ES EL ELSE');
      this.uploader.uploader.addToQueue(this.defaultValue);
    }
    this.isLoading = false;
  }

  getFileSize(size, internal = false) {
    let auxSize;
    let unit;
    if (size < 1000) {
      auxSize = size;
      unit = "Bytes";
    } else if (size < 1000*1000) {
      auxSize = size / 1000;
      unit = "KB";
    } else if (size < 1000*1000*1000) {
      auxSize = size / 1000 / 1000;
      unit = "MB";
    } else {
      auxSize = size / 1000 /1000 /1000;
      unit = "GB";
    }
    if (internal) {
      return {size: parseInt(auxSize, 10), unit};
    }
    return `${parseFloat(auxSize).toFixed(1)} ${unit}`;
  }

  fileOverBase(e:any) {
    this.dragging = e;
  }

  removeFromQueue(index) {
    this.uploader.uploader.removeFromQueue(index);
    this.logQueue();
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    console.log('CHANGES: ', changes, changes.nameOfInsuranceCompany);
      if (changes.nameOfInsuranceCompany && changes.nameOfInsuranceCompany.currentValue && this.clientData) {
        this.nameOfInsuranceCompany = changes.nameOfInsuranceCompany.currentValue;
        this.uploadUrl = `${this.batchUpload}/area/${this.clientData.clientId}/fileupload/${this.whereToUpload}?index=${this.index}&policyFilename=${encodeURIComponent(this.policyFileName)}&nameOfInsuranceCompany=${encodeURIComponent(this.nameOfInsuranceCompany)}`;

        this.uploadUrl = this.advisorService.dynamicIPAddress(
          this.window,
          this.uploadUrl
        );
        this.uploader.uploader.setUrl(this.uploadUrl)
      }
  }


  previousDataIsObject(objectToTest) {
    const ans = typeof objectToTest === 'object'
    if (ans) {
      this.previousData = null;
    }
    return ans;
  }
}


