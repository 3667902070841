import { Component, Input, OnInit } from '@angular/core';
import { AdvisorService } from '../../../../../services/advisor.service';

@Component({
  selector: 'app-taxes-pdf',
  templateUrl: './taxes-pdf.component.html',
  styleUrls: ['./taxes-pdf.component.scss']
})
export class TaxesPdfComponent implements OnInit {

  @Input() currentScenario: number;
  @Input() withStrategies: any;
  @Input() data: any;

  public clientId: string;
  public responseBackEnd: any;
  public responseStatusStrategies: any;
  public lastExemptionFederal: number;
  public totalTax: any;
  public example =  [
    [
      {
        "title":"Edward Cullen",
        "items":[

        ],
        "total":0,
        "detail":[

        ]
      },
      {
        "title":"Federal Taxes",
        "items":[
          {
            "title":"State",
            "key":"state",
            "total":"Federal Taxes",
            "isInput":false
          },
          {
            "title":"Total Estate",
            "key":"totalEstate",
            "total":126212,
            "isInput":false
          },
          {
            "title":"Gift Strategy",
            "key":"giftStrategy",
            "total":10700,
            "isInput":false
          },
          {
            "title":"State Estate Tax",
            "key":"stateEstateTax",
            "total":0,
            "isInput":false
          },
          {
            "title":"Federal Exemption",
            "key":"federalExemption",
            "total":12920000,
            "isInput":true
          },
          {
            "title":"Taxable Estate",
            "key":"taxableEstate",
            "total":-12804488,
            "isInput":false
          },
          {
            "title":"Federal Estate Tax Rate",
            "key":"federalEstateTaxRate",
            "total":"40.00",
            "isInput":false
          }
        ],
        "total":0,
        "detail":[
          {
            "textA":"Assets",
            "textAColor":"#FFFFFF",
            "textB":"",
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":true
          },
          {
            "textA":"Children Plan",
            "textAColor":"#FFFFFF",
            "textB":121212,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"401 K",
            "textAColor":"#FFFFFF",
            "textB":5000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Total",
            "textAColor":"#FFFFFF",
            "textB":126212,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":false
          }
        ]
      },
      {
        "title":"Estate Taxes",
        "items":[
          {
            "title":"State",
            "key":"state",
            "total":"Federal Taxes",
            "isInput":false
          },
          {
            "title":"Total Estate",
            "key":"totalEstate",
            "total":126212,
            "isInput":false
          },
          {
            "title":"Gift Strategy",
            "key":"giftStrategy",
            "total":10700,
            "isInput":false
          },
          {
            "title":"State Estate Tax",
            "key":"stateEstateTax",
            "total":0,
            "isInput":false
          },
          {
            "title":"Federal Exemption",
            "key":"federalExemption",
            "total":12920000,
            "isInput":true
          },
          {
            "title":"Taxable Estate",
            "key":"taxableEstate",
            "total":-12804488,
            "isInput":false
          },
          {
            "title":"Federal Estate Tax Rate",
            "key":"federalEstateTaxRate",
            "total":"40.00",
            "isInput":false
          }
        ],
        "total":0,
        "detail":[
          {
            "textA":"Assets",
            "textAColor":"#FFFFFF",
            "textB":"",
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":true
          },
          {
            "textA":"Children Plan",
            "textAColor":"#FFFFFF",
            "textB":121212,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"401 K",
            "textAColor":"#FFFFFF",
            "textB":5000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Total",
            "textAColor":"#FFFFFF",
            "textB":126212,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":false
          }
        ]
      },
    ],
    [
      {
        "title":"Isabella Swan",
        "items":[

        ],
        "total":28277120.4,
        "detail":[

        ]
      },
      {
        "title":"Federal Taxes",
        "items":[
          {
            "title":"State",
            "key":"state",
            "total":"Federal Taxes",
            "isInput":false
          },
          {
            "title":"Total Estate",
            "key":"totalEstate",
            "total":60059234,
            "isInput":false
          },
          {
            "title":"Gift Strategy",
            "key":"giftStrategy",
            "total":10700,
            "isInput":false
          },
          {
            "title":"State Estate Tax",
            "key":"stateEstateTax",
            "total":0,
            "isInput":false
          },
          {
            "title":"Federal Exemption",
            "key":"federalExemption",
            "total":12920000,
            "isInput":true
          },
          {
            "title":"Taxable Estate",
            "key":"taxableEstate",
            "total":47128534,
            "isInput":false
          },
          {
            "title":"Federal Estate Tax Rate",
            "key":"federalEstateTaxRate",
            "total":"40.00",
            "isInput":false
          }
        ],
        "total":28277120.4,
        "detail":[
          {
            "textA":"Assets",
            "textAColor":"#FFFFFF",
            "textB":"",
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":true
          },
          {
            "textA":"Cullen\\'s House",
            "textAColor":"#FFFFFF",
            "textB":20000000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Traditional IRA",
            "textAColor":"#FFFFFF",
            "textB":3000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"401 K",
            "textAColor":"#FFFFFF",
            "textB":5000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"457",
            "textAColor":"#FFFFFF",
            "textB":9000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Traditional IRA",
            "textAColor":"#FFFFFF",
            "textB":3000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Cullen\\'s House",
            "textAColor":"#FFFFFF",
            "textB":20000000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Traditional IRA",
            "textAColor":"#FFFFFF",
            "textB":3000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Cullen\\'s House",
            "textAColor":"#FFFFFF",
            "textB":20000000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Roth IRA",
            "textAColor":"#FFFFFF",
            "textB":5000,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Brokerage account ",
            "textAColor":"#FFFFFF",
            "textB":31234,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":false,
            "hasBottomBorder":false
          },
          {
            "textA":"Total",
            "textAColor":"#FFFFFF",
            "textB":60059234,
            "textBColor":"#FFFFFF",
            "iconUrl":"",
            "isBold":true,
            "hasBottomBorder":false
          }
        ]
      },
      {

      }
    ],
    [
      {
        "title":"Federal Taxes",
        "items":[

        ],
        "total":28277120.4
      },
      {
        "title":"Total",
        "items":[

        ],
        "total":28277120.4
      }
    ]
  ]

  constructor(
    private advisorSrv: AdvisorService
  ) { }

  ngOnInit(): void {
    console.log('OnInit taxes pdf');
    console.log('currentScenario', this.currentScenario);
    console.log('withStrategies', this.withStrategies);
    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    this.responseBackEnd = this.data;

    this.clientId = storagedClientID.clientId;
    console.log('clientId taxes init pdf*', this.clientId);
    /* this.advisorSrv.getEstateLabLifeExpectancy(this.clientId, this.currentScenario).then((response: any) => {
      console.log('getEstateLabLifeExpectancy', response);
      this.withStrategies = response['settings']['strategies'];
      this.getTaxes();
    }); */


  }

  getTaxes(){
    this.advisorSrv.getTaxes(this.clientId, this.currentScenario, this.withStrategies).then((response) => {
      this.responseBackEnd = JSON.parse(JSON.stringify(response));
      // this.responseBackEnd = this.example;
      this.totalTax = this.responseBackEnd[this.responseBackEnd.length - 1];
      // console.log('getTaxes from pdf*', this.responseBackEnd);
      // console.log('this.totalTax pdf*', this.totalTax);
    });
  }


}
