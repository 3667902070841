// Angular
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { log } from 'console';


@Component({
  selector: 'external-providers-interactions-modal',
  templateUrl: './integrations-modal.component.html'
})

export class IntegrationsModalComponent implements OnInit, AfterViewInit {

  constructor(public dialogRef: MatDialogRef<IntegrationsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) {
              }

  ngOnInit() {
    this.data.origin
    console.log('▶️▶️ Client is coming from ', this.data.origin);
  }

  ngAfterViewInit() {

  }

  closeModal(){
    this.dialogRef.close('gotit');
  }

}
