//Angular
import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AdvisorService } from 'app/shared/services/advisor.service';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
//Project
import { environment } from "../../../../environments/environment";
import { isDisabledAdvisorFeature } from 'app/shared/helpers/storageControllers';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

  preferencesForm: FormGroup;
  public readedPreferences;
  public advisorData;
  public companyData: any;
  public isEarlyTester: boolean = false;
  public isEarlyTesterCostSavings: boolean = false;
  color: any = '#247730';
  public isFeatureEnabled_clientPortal: boolean = false;
  public isProduction: boolean = false;

  constructor(
    private settingsService: ESettingsService,
    private router: Router,
    private http: HttpClient,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private advisorService: AdvisorService,
  ) { }

  async ngOnInit() {
    //Read Advisor Preferences from local storage (RT)
    this.readedPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));

    //Get actual advisor data
    this.getAdvisorData();

    await this.getCompanyData();

    /*** Preferences ***/
    this.updateForm();

    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    (localStorage.getItem('betaAccess').includes('noGeneralQuest')) ? this.isEarlyTester = true : this.isEarlyTester = false;
    if (this.isProduction && localStorage.getItem('betaAccess').includes('costSavings')) {
      this.isEarlyTesterCostSavings = true;
    }
    if (!this.isProduction) {
      this.isEarlyTesterCostSavings = true;
    }
    this.isFeatureEnabled_clientPortal = !isDisabledAdvisorFeature('clientPortal');

  }

  updateForm(){
    if (this.readedPreferences){
      this.preferencesForm = new FormGroup({
        hasGeneralQuest: new FormControl(!this.readedPreferences.hasGeneralQuest),
        sendAccessToClient: new FormControl(this.readedPreferences.sendAccessToClient),
        shareClients: new FormControl(this.readedPreferences.shareClients),
        showActionsOnReport: new FormControl(this.readedPreferences.showActionsOnReport),
        visibleTaskManager: new FormControl(this.readedPreferences.visibleTaskManager),
        saveHistory: new FormControl(this.readedPreferences.saveHistory),
        hasActiveCostSavings: new FormControl(this.readedPreferences.hasActiveCostSavings),
        clientPageSize: new FormControl(this.readedPreferences.clientPageSize),
        generateRandomEmail: new FormControl(this.readedPreferences.generateRandomEmail? this.readedPreferences.generateRandomEmail : false),
        // isShareWithAllEnabled: new FormControl(this.companyData.settings.isShareWithAllEnabled || true),
        //disclaimer: new FormControl(this.readedPreferences.disclaimer),
      });
    }
  }

  updateAdvisorPreferences() {

    let saveAdvisorPreferences = this.preferencesForm.value;

    let updatedPreferences = {
      hasGeneralQuest: saveAdvisorPreferences.hasGeneralQuest,
      sendAccessToClient: saveAdvisorPreferences.sendAccessToClient,
      visibleTaskManager: saveAdvisorPreferences.visibleTaskManager,
      shareClients: saveAdvisorPreferences.shareClients,
      saveHistory: saveAdvisorPreferences.saveHistory,
      showActionsOnReport: saveAdvisorPreferences.showActionsOnReport,
      hasActiveCostSavings: saveAdvisorPreferences.hasActiveCostSavings,
      clientPageSize: saveAdvisorPreferences.clientPageSize,
      // isShareWithAllEnabled: saveAdvisorPreferences.isShareWithAllEnabled,
      //disclaimer: saveAdvisorPreferences.disclaimer,
      generateRandomEmail: saveAdvisorPreferences.generateRandomEmail
    }

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let optionsPath = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    let body = new URLSearchParams();
    body.set('hasGeneralQuest', saveAdvisorPreferences.hasGeneralQuest == true ? 'false' : 'true');
    body.set('sendAccessToClient', saveAdvisorPreferences.sendAccessToClient);
    body.set('visibleTaskManager', saveAdvisorPreferences.visibleTaskManager);
    body.set('shareClients', saveAdvisorPreferences.shareClients);
    body.set('saveHistory', saveAdvisorPreferences.saveHistory);
    body.set('showActionsOnReport', saveAdvisorPreferences.showActionsOnReport);
    body.set('hasActiveCostSavings', saveAdvisorPreferences.hasActiveCostSavings);
    body.set('clientPageSize', saveAdvisorPreferences.clientPageSize);
    body.set('generateRandomEmail', saveAdvisorPreferences.generateRandomEmail);
    //body.set('disclaimer', saveAdvisorPreferences.disclaimer);


    let bodyCompanySettings = {
      // 'settings': {
      //   'isShareWithAllEnabled': saveAdvisorPreferences.isShareWithAllEnabled
      // }
    }

    this.http.post<any>(`${environment.apiAdvisorAdvisorPreferences}`, body.toString(), options)
      .subscribe(
        success => {

          this.settingsService.updateSettings(bodyCompanySettings).then(res => {

            let snackBarRef = this.snackBarSuccess.open("Preferences updated, the app will reload to apply changes", "Ok", {
              duration: 10000,
              panelClass: 'success-snackbar'
            });

            this.router.navigate(['/advisor/clients']);

            setTimeout(() => {
              this.reload();
            }, 3000);

            snackBarRef.onAction().subscribe(() => {

              this.reload();
            });

            //Set the new values in storage so we can read the right values after the changes (RT)
            localStorage.setItem("advisorPreferences", JSON.stringify(updatedPreferences));

            this.advisorService.getAdvisorData()
              .subscribe(
                data => {
                  this.advisorData = data;
                  localStorage.setItem("advisorPreferences", JSON.stringify(this.advisorData['settings']));
                },
                error => {
                  console.log(error);
                });
          }).catch(error => {
            console.log('Error!!!');
            this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
              duration: 9000,
              panelClass: 'error-snackbar'
            });
          })
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );

    localStorage.setItem("temporalClientPageSize", saveAdvisorPreferences.clientPageSize);
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorData = data;

          this.readedPreferences = this.advisorData.settings;
          this.updateForm();


        },
        error => {
          console.error(error);
          this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        });
  }

  getCompanyData(){
    return new Promise((resolve, reject) => {
      this.settingsService.getCompanyData().subscribe(data => {

        this.companyData = data;
        resolve('get-company-data')
      }, error => {
        console.log('Error', error);
        reject('error')
      })
    })
  }

  reload() {
    window.location.reload();
  }

  getNumber(e) {
    console.log(e);

    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)) {

        return false;
    }
}

}
