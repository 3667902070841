import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fpAnimations } from "app/shared/animations/fp-animations";

@Component({
  selector: "app-top-insights",
  templateUrl: "./top-insights.component.html",
  styleUrls: ["./top-insights.component.scss"],
  animations: fpAnimations,
})
export class TopInsightsComponent implements OnInit {
  public objectKeys = Object.keys;
  public area;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.area = this.data;
  }

  ngOnInit(): void {}

  public deleteFirstLine(text: string) {
    let newText = text.split("\n");
    newText.shift();
    return newText.join("\n");
  }
}
