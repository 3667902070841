//Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import {
    Router,
    RouteConfigLoadStart,
    RouteConfigLoadEnd,
    ResolveStart,
    ResolveEnd
  } from '@angular/router';

@Component({
  selector: 'modals-component',
  templateUrl: './e.settings.component.html',
})

export class ESettingsComponent implements OnInit, OnDestroy{
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;

  constructor(private router: Router,) {}

  ngOnInit() {
    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
        if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.isModuleLoading = true;
        }
        if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
          this.isModuleLoading = false;
        }
      });
  }

  ngOnDestroy() {
    if(this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe()
    }
  }

}
