import { CommonModule, CurrencyPipe, PercentPipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule, MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FileUploadModule } from "ng2-file-upload";
import { NgxCleaveDirectiveModule } from "ngx-cleave-directive";
import { IConfig, NgxMaskModule } from "ngx-mask";

import { DialogConfirm } from "../../shared/components/dialog-confirm/dialog-confirm.component";
import { SharedModule } from "../../shared/shared.module";
import { DialogConfirmSend } from "./dialog-confirm-send/dialog-confirm.component";
import { DialogValidDocument } from "./dialog-doc-valid/dialog-doc-valid.component";
import { showValidationWarningomponent } from "./dialog-doc-warning/showValidationWarning.component";
import { DialogFirstTime } from "./dialog-firstTime/dialog-firstTime.component";
import { DialogInvalidDocumentComponent } from "./dialog-invalid-document/dialog-invalid-document.component";
import { DialogMultipleAssets } from "./dialog-multiple-assets/dialog-multipleAssets.component";
import { FileUploaderComponentComponent } from "./file-uploader-component/file-uploader-component.component";
import { GenericScreenComponent } from "./generic-screen-component/generic-screen.component";
import { AddPropertyDialogComponent } from "./hub-multi-area/add-property-dialog/add-property-dialog.component";
import { ChangePropertyDialogComponent } from "./hub-multi-area/change-property-name-dialog/change-property-name-dialog.component";
import { EstateSnapshotSubmitButtonComponent } from "./hub-multi-area/estate-hub/components/estate-snapshot-submit-button/estate-snapshot-submit-button/estate-snapshot-submit-button.component";
import { EstateColumnElementComponent } from "./hub-multi-area/estate-hub/estate-column-element/estate-column-element.component";
import { EstateHubComponent } from "./hub-multi-area/estate-hub/estate-hub.component";
import { EstateUploaderComponent } from "./hub-multi-area/estate-hub/estate-uploader/estate-uploader.component";
import { HubMultiAreaComponent } from "./hub-multi-area/hub-multi-area.component";
import { HubComponent } from "./hub/hub.component";
import { QAutoComponent } from "./q-auto/q-auto.component";
import { QCreditorComponent } from "./q-creditor/q-creditor.component";
import { QCrossborderComponent } from "./q-crossborder/q-crossborder.component";
import { QDisabilityComponent } from "./q-disability/q-disability.component";
import { QDivorceComponent } from "./q-divorce/q-divorce.component";
import { QElderComponent } from "./q-elder/q-elder.component";
import { QEstateComponent } from "./q-estate/q-estate.component";
import { QExpenseComponent } from "./q-expense/q-expense.component";
import { QGeneralComponent } from "./q-general/q-general.component";
import { QHomeComponent } from "./q-home/q-home.component";
import { QIdentityComponent } from "./q-identity/q-identity.component";
import { QLifeComponent } from "./q-life/q-life.component";
import { QLtcComponent } from "./q-ltc/q-ltc.component";
import { QMedicalComponent } from "./q-medical/q-medical.component";
import { QMortgageComponent } from "./q-mortgage/q-mortgage.component";
import { QNewProspectComponent } from "./q-new-prospect/q-new-prospect.component";
import { ModalAdvisorSubmittedComponent } from "./q-new-prospect/submitted/modal-advisor/modal-advisor.component";
import { ModaProspectRecsComponent } from "./q-new-prospect/submitted/modal-recs/modal-recs.component";
import { QNewProspectSubmittedComponent } from "./q-new-prospect/submitted/submitted.component";
import { QPropertyComponent } from "./q-property/q-property.component";
import { CreateExternalProspectComponent } from "./q-prospect/create-external-prospect/create-external-prospect.component";
import { QProspectComponent } from "./q-prospect/q-prospect.component";
import { SubmittedMessageComponent } from "./q-prospect/submitted-message/submitted-message.component";
import { QStudentComponent } from "./q-student/q-student.component";
import { QTaxComponent } from "./q-tax/q-tax.component";
import { QTaxfullComponent } from "./q-taxfull/q-taxfull.component";
import { showUploadGuideComponent } from "./uploader/showUploadGuide.component";
import { showValidationErrorComponent } from "./uploader/showValidationError.component";
import { UploaderComponent } from "./uploader/uploader.component";
import { InsuranceHubComponent } from "./insurance-hub/insurance-hub/insurance-hub.component";
import { InsuranceFormComponent } from "./insurance-hub/insurance-form/insurance-form.component";
import { InsuranceListComponent } from "./insurance-hub/insurance-list/insurance-list.component";
import { InsuranceHubModule } from "./insurance-hub/insurance-hub.module";
import { QuestionnaireCreditBoxComponent } from "app/shared/components/pricing-strategy/questionnaire-credit-box/questionnaire-credit-box.component";
import { PurchaseModalComponent } from "app/shared/components/pricing-strategy/purchase-modal/purchase-modal/purchase-modal.component";
import { GeneralQuestionnaireComponent } from './general-questionnaire/general-questionnaire.component';
import { FpTabQuestionnaireDirective } from 'app/shared/directives/fp-tab-questionnaire.directive';
import { DatepickerWithAgeDirective } from 'app/shared/directives/datepicker-with-age.directive';
import { AmountOrRangeComponent } from 'app/shared/components/amount-or-range/amount-or-range.component';
import { PersonalInformationComponent } from './general-questionnaire/templates/personal-information/personal-information.component';
import { FamilyComponent } from './general-questionnaire/templates/family/family.component';
import { AssetsComponent } from './general-questionnaire/templates/assets/assets.component';
import { InfoTooltipComponent } from 'app/shared/components/info-tooltip/info-tooltip.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTableModule } from '@angular/material/table';
import { AddChildComponent } from './general-questionnaire/dialogs/add-child/add-child.component';
import { MatSortModule } from '@angular/material/sort';
import { AddAssetComponent } from './general-questionnaire/dialogs/add-asset/add-asset.component';
import { LeavingConfirmationComponent } from './general-questionnaire/dialogs/leaving-confirmation/leaving-confirmation.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmStatusComponent } from './general-questionnaire/dialogs/confirm-status/confirm-status.component';

// ALL QUESTIONNAIRES
//External
const classesToInclude = [
  GeneralQuestionnaireComponent,
  PersonalInformationComponent,
  FamilyComponent,
  AssetsComponent,
  QGeneralComponent,
  QAutoComponent,
  QDisabilityComponent,
  QEstateComponent,
  QHomeComponent,
  QTaxfullComponent,
  QIdentityComponent,
  QMedicalComponent,
  QStudentComponent,
  QLtcComponent,
  QMortgageComponent,
  QLifeComponent,
  QDivorceComponent,
  QPropertyComponent,
  QExpenseComponent,
  QCreditorComponent,
  QElderComponent,
  QCrossborderComponent,
  QProspectComponent,
  QNewProspectComponent,
  QTaxComponent,
  HubComponent,
  HubMultiAreaComponent,
  DialogConfirm,
  DialogConfirmSend,
  DialogMultipleAssets,
  DialogInvalidDocumentComponent,
  showValidationWarningomponent,
  DialogFirstTime,
  DialogValidDocument,
  AddPropertyDialogComponent,
  ChangePropertyDialogComponent,
  showUploadGuideComponent,
  showValidationErrorComponent,
  UploaderComponent,
  FileUploaderComponentComponent,
  GenericScreenComponent,
  CreateExternalProspectComponent,
  SubmittedMessageComponent,
  QNewProspectSubmittedComponent,
  ModalAdvisorSubmittedComponent,
  ModaProspectRecsComponent,
  EstateHubComponent,
  EstateUploaderComponent,
  EstateColumnElementComponent,
  EstateSnapshotSubmitButtonComponent,
  QuestionnaireCreditBoxComponent,
  FpTabQuestionnaireDirective,
  DatepickerWithAgeDirective,
  AmountOrRangeComponent,
  InfoTooltipComponent,
  AddChildComponent,
  AddAssetComponent,
  LeavingConfirmationComponent,
  ConfirmStatusComponent
  // InsuranceHubComponent,
  // InsuranceFormComponent,
  // InsuranceListComponent
];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    SharedModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    FileUploadModule,
    NgxCleaveDirectiveModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedModule,
    MatTabsModule,
    MatDividerModule,
    MatExpansionModule,
    MatButtonToggleModule,
    CurrencyMaskModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule
  ],
  entryComponents: [
    DialogConfirm,
    DialogConfirmSend,
    ModalAdvisorSubmittedComponent,
    ModaProspectRecsComponent,
    DialogMultipleAssets,
    DialogInvalidDocumentComponent,
    showValidationWarningomponent,
    DialogFirstTime,
    DialogValidDocument,
    showUploadGuideComponent,
    showValidationErrorComponent,
    AddPropertyDialogComponent,
    ChangePropertyDialogComponent,
    PurchaseModalComponent,
    InfoTooltipComponent,
    MatIcon,
    AddChildComponent,
    AddAssetComponent,
    ConfirmStatusComponent,
    LeavingConfirmationComponent
  ],
  providers: [
    CurrencyPipe,
    PercentPipe
  ],
  declarations: classesToInclude,
  exports: classesToInclude,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QuestionnairesModule {}
