import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from '../../../../shared/helpers/CloseDialogAtLogOut.class';
import { AdvisorService } from '../../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../../shared/services/auth/authentication.service';

@Component({
  selector: 'app-warning-save-before',
  templateUrl: './warning-save-before.component.html',
  styleUrls: ['./warning-save-before.component.scss']
})
export class WarningSaveBeforeComponent extends CloseDialogAtLogOut implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WarningSaveBeforeComponent>,
    private advisorService: AdvisorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);

  }

  ngOnInit() {
  }

  /**
   * The closeDialog function closes a dialog and passes an instruction as a parameter.
   * @param {string} instruction - The "instruction" parameter is a string that represents the instruction or action to be performed when closing the dialog.
   */
  closeDialog(instruction: string) {
    this.dialogRef.close(instruction);
  }
}
