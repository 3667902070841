import { Questionnaire } from '../Questionnaire.model';

export class Mortgage extends Questionnaire {
    
    //Main quest
        //Internal
    public clientIDKindOfMortgage: string = '';

    public clientIDNewFinancing: string = '';
    // public clientIDExtenuatingcircumstancesBankrupt: string = '';
    // public clientIDExtenuatingcircumstancesForeclosure: string = '';
    public clientIDDelinquent: string = '';
    public clientIDUSCitizen: string = '';
    public clientIDPropertyType: string = '';
    public clientIDCreditScore: number = 0;
    public clientIDQualifyVeteranAdminMort: string = '';
    public clientIDPropOwnYears: number = 0;
    public clientIDSellToPurchase: string = '';
    public clientIDPropertyDesignation: string = '';
    public clientIDPropStateQualify: string = '';
    public clientIDbankruptcyInLast7Years: string = '';
    public clientIDforeclosureInLast7Years: string = '';
    public clientIDForeclosureCompletedYears: number = 0;
    public clientIDbankruptcyDischargeYears: number = 0;
    public clientIDbankruptcyDismissedYears: number = 0;
    public clientIDbankruptcyChapter: string = '';
    public clientIDdeedLieuPreChargeOffLast7Years: string = '';
    public clientIDcompletionYears: number = 0;
    public clientIDSalesPrice: number = 0;
    public clientIDBorrowingPercentage: number = 0;
    public clientIDFirstMortgagesCreditReport: number = 0;
    public clientIDNewMontlyPayment: number = 0;
    public clientIDCurrentMonthlyPayment: number = 0;
    public clientIDCashOutRefinance: string = '';
    public clientIDMortgageYearsDecreasing: string = '';
    public clientIDRefinanceFirstMortgage: string = '';
    public clientIDstateConformingLoanLimit: number = 0;
    public clientIDDebtToIncome: number = 0;
    public clientIDgrossIncome: number = 0;
    public clientIDallowableLimit: number = 0;
    public clientIDSecondOrEquityAlsoRefinanced: string = '';

    //This one is for internal use only
    public clientIDIsInArmedForces: string = '';

    //From general quest and client data
    public clientIDFirstName: string = '';
    public clientIDGender: string = '';
    public clientIDAge: number = 0;
    public clientIDLivingState: string = '';
    public clientIDHasABusiness: string = '';
    public clientIDMaritalStatus: string = '';
    public clientIDSpouseAge: number = 0;

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;
    
    constructor() {
        super();
    }

    getIgnoredAttributesForSave(){
        return [
        ];
    }

    getAttributesToClearCommas(){
        return [
            'clientIDallowableLimit',
            'clientIDgrossIncome',
            'clientIDstateConformingLoanLimit',
            'clientIDNewMontlyPayment',
            'clientIDCurrentMonthlyPayment',
            'clientIDSalesPrice',

        ];
    }

    getQName(): string {
        return 'mortgage';
    }
}