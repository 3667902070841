import { AfterViewInit } from '@angular/core';
//Angular
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common'

// Project
import { Area } from '../../../shared/helpers/areas.catalog';
import { FullPageSpinnerComponent } from 'app/shared/components/full-page-spinner/full-page-spinner.component';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_PROSPECT, CONST_GENDERS, CONST_GENDERS_V2, CONST_MARRIAGESTATUS, CONST_EMPLOYMENT_STATUS, CONST_STATES, CONST_HOME_OWNRENT, CONST_HOMEINSURANCECOMPANIES, CONST_AUTOCOMPANIES, CONST_INVESTMENTMANAGED, CONST_INVESTMENTOPTIONS, CONST_INVESTMENTRATE, CONST_INVESTMENTMANAGEDCAHSFLOW, CONST_INCOMEOPTIONS, CONST_ANTICIPATECHANGESRETIREMENT, CONST_LIFESTYLE, CONST_HEALTHCONDITIONRATE, DEBIT } from '../Questionnaire.constants';
import { environment } from 'environments/environment';
import { getYearsArraySince } from '../HelperFunctions';
import { prospect_questions } from './prospect.model';
import { QuestionnaireController } from '../Questionnaire.controller';
import { utilsCalculateAge } from 'app/shared/helpers/utils';
import { AddNewClientDialog } from 'app/views/clients/add-new-client/add-new-client-dialog.component';
import { ProspectSessionModalComponent } from 'app/views/sessions/prospect-current-session-modal/prospect-session-modal.component';
import { CONST_DISCLAIMER } from 'app/views/reports/stringConstants';
import { AdvisorService } from 'app/shared/services/advisor.service';


@Component({
  selector: 'app-q-new-prospect',
  templateUrl: './q-new-prospect.component.html',
  styleUrls: ['./q-new-prospect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QNewProspectComponent implements OnInit, AfterViewInit {

  @ViewChild('stepper', { static: true }) private stepper: MatStepper;

  public mask = {
    guide: true,
    showMask : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/,/\d/, /\d/]
  };

  public progressNumber: number = 0;
  public totalProgressTabs: number = 0;

  public model: prospect_questions;

  public clientData: any = {};
  public whoAnswered: string;
  public isAdvisor: boolean = false;
  public objectKeys = Object.keys;

  public advisorLogo: string;
  public advisorCompany: string;
  public bulletColor: string = '#C93939';
  public questUrl: string = '';
  public prospectAreas: string[] = [];
  public areasToShow: string[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_PROSPECT, CONST_GENDERS, CONST_GENDERS_V2, CONST_MARRIAGESTATUS, CONST_EMPLOYMENT_STATUS, CONST_STATES, CONST_HOME_OWNRENT, CONST_HOMEINSURANCECOMPANIES, CONST_AUTOCOMPANIES, CONST_INVESTMENTMANAGED, CONST_INVESTMENTOPTIONS, CONST_INVESTMENTRATE, CONST_INVESTMENTMANAGEDCAHSFLOW, CONST_INCOMEOPTIONS, CONST_ANTICIPATECHANGESRETIREMENT, CONST_LIFESTYLE, CONST_HEALTHCONDITIONRATE, DEBIT  };
  public yearsList: number[] = getYearsArraySince(2000).reverse();
  public startDate = new Date(1980, 0, 1);
  public minDate: Date;
  public maxDate: Date;
  public advisorName: string = '';
  public advisorEmail: string = '';
  public advisorPhone: string = '';
  public advisorDisclaimer: string = '';
  public prospectHash: string;
  public hasCustomQuestions: boolean;
  public customQuestions: any;

  public areasToDisplayArray = [
    {id: "estate",
    name: "Estate planning"
    },
    {id: "fulltax",
    name: "Tax planning"
    },
    ,
    {id: "auto",
    name: "Auto"
    },
    {id: "home",
    name: "Home"
    },
    {id: "ltc",
    name: "LTC"
    },
    {id: "life",
    name: "Life insurance"
    },
    {id: "disability",
    name: "Disability"
    },
    {id: "investment",
    name: "Investment and Retirement"
    }
  ]

  public areasIds = {
    'Personal information': 'general',
    'Estate planning': 'estate',
    'Tax planning': 'fulltax',
    'Auto': 'auto',
    'Home':'home',
    'LTC': 'ltc',
    'Life insurance': 'life',
    'Disability': 'disability',
    'Investment and Retirement': 'investment'
  }

  public finalArrayAreas = ['Personal information']
  public tempCurrentSection = 'Personal information';

  //EXTERNAL PROS
  public advisorData: any = null;
  public readProspectingPreferences;
  public areasFromCatalogToDisplay;
  public leadPageAreas : string[] = [];
  public selectedAreas: any = [];
  public temporalPassword: string;

  public backgroundImage: string = '';

  public initialAreas: any = {
    estate: false,
    fulltax: false,
    auto: false,
    home: false,
    ltc: false,
    disability: false,
    investment: false,
    retirement: false,
    life: false
  }

  public isFirstTime: boolean = true;
  public totalSteps: number = 100;
  public phoneStep: number = 0;

  public disabled: boolean = false;
  public sectionName = '';

  public currentSection: string = '';

  public taxFillingStatusOptions = {
    'Single': ['Single', 'Head of Household'],
    'Married': ['Married Filing Jointly', 'Married Filing Separately'],
    'Widowed': ['Widowed', 'Single', 'Head of Household'],
    'Divorced': ['Single', 'Head of Household'],
    'Domestic Partner': ['Married Filing Jointly', 'Married Filing Separately']
  }

  public tempclientIDDebtList: any = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public authService: AuthenticationService,
    public qController: QuestionnaireController,
    public route: ActivatedRoute,
    private location: Location,
    private advisorService: AdvisorService,
    public dialog: MatDialog,
  ) {
  }
  ngAfterViewInit(): void {

    setTimeout(()=>{
      let matHeaderElement = document.querySelector('.mat-horizontal-stepper-header-container');
      if(matHeaderElement){
        matHeaderElement.setAttribute('style', 'display:none');
      }
    }, 2000)

  }

  async getInsuranceCompanies() {
    const ans: any = await this.advisorService.getInsuranceProviders(); 
    console.log(ans);
    this.CONSTANTS.CONST_HOMEINSURANCECOMPANIES = ans.map(({ showName }) => showName);
    this.CONSTANTS.CONST_AUTOCOMPANIES = ans.map(({ showName }) => showName);
  }

  async ngOnInit() {
    document.title = "Discover Your Planning Score";
    this.isAdvisor = this.authService.isAdvisor();

    this.whoAnswered = this.isAdvisor ? 'Advisor' : 'Client';

    //Initialize the questionnaire model
    this.model = new prospect_questions();

    this.minDate = new Date(1900, 1, 1);
    this.maxDate = new Date();

    this.route.queryParams.subscribe(params => {
        if (params['advisor']) {
          this.isFirstTime = true;
        }

        if(params['cli']){
          this.isFirstTime = false;
        }
    });

    if(this.isFirstTime){

      this.loadAdvisorProspectData();
    }else{

      //this.stepper.selectedIndex = 2;
      this.loadSecondTime();
    }

    await this.calculateProgressNumber();
  }

  loadAdvisorProspectData(){
    //EXTERNAL PROSPECTS CREATE
    // 1. Validate prospect join id and retrieve the advisor data.
    let prospectJoinId = this.route.snapshot.queryParamMap.get('advisor');
    let validateJoinIdUrl = environment.prospectValidate + `?advisor=${prospectJoinId}`;
    this.http.get(validateJoinIdUrl).toPromise().then(response => {
      this.advisorData = response;
      localStorage.setItem('advisorResponse', JSON.stringify(response));

      this.backgroundImage = `url(${this.advisorData.leadPageBackground})`;

      // Continue with the component init.
      this.readProspectingPreferences = JSON.parse(localStorage.getItem('advisorResponse'));
      this.advisorDisclaimer = Boolean(this.readProspectingPreferences.leadDisclaimer) ? this.readProspectingPreferences.leadDisclaimer : CONST_DISCLAIMER;


      Object.keys(this.readProspectingPreferences.customQuestions).length === 0 ? this.hasCustomQuestions = false : this.hasCustomQuestions = true;

      for (let area of Object.keys(this.readProspectingPreferences.leadPageAreas)) {
        if(this.readProspectingPreferences.leadPageAreas[area] === true) {
          this.leadPageAreas.push(area);
        }
      }


      this.areasFromCatalogToDisplay = Area.getAreasByIds(this.leadPageAreas);


    }, error => {
      // 1.2 If isn't valid, redirect to the "No valid screen"
      this.router.navigate(['prospect/alert']);
    })

    this.temporalPassword = AddNewClientDialog.generateTempPassword();
  }

  // 2. Create the new client.
  createProspect(){
    this.openFullPageLoader();

    let newClientData = {
      email: this.model.email,
      clientIDFirstName: this.model.clientIDFirstName,
      clientIDLastName: this.model.clientIDLastName,
      isProspect: false,
      password: this.temporalPassword,
      ma: this.advisorData.ma,
      estate: this.selectedAreas.includes('estate'),
      fulltax: this.selectedAreas.includes('fulltax'),
      auto: this.selectedAreas.includes('auto'),
      home: this.selectedAreas.includes('home'),
      ltc: this.selectedAreas.includes('ltc'),
      disability: this.selectedAreas.includes('disability'),
      investment: this.selectedAreas.includes('investment'),
      retirement: this.selectedAreas.includes('retirement'),
      life: this.selectedAreas.includes('life')
    }

    let areasRequired:string[] = this.selectedAreas;


    sessionStorage.setItem('areasToAnalyze', JSON.stringify(areasRequired));

    // 3. If success at create, continue to external prospect flow.

    AddNewClientDialog.createNewClient(this.http, newClientData, true, environment.prospectLead).then(
      response => {

        localStorage.setItem('prospectResponse', JSON.stringify(response));
        //this.location.go(`prospect/form?cli=${response.idHash}`)
        this.router.navigate(['prospect/form'], {queryParams: { cli: response.idHash }});
        setTimeout(()=>{
          this.fullPageSpinnerModal.close();
          this.isFirstTime = false;
          //this.loadSecondTime();
        },700)
      },
      error => {
        this.snackBarError.open("Something went wrong! " + error.error.message, "Dismiss", {
          duration: 9000,
          panelClass: 'error-snackbar'
        });
        this.fullPageSpinnerModal.close();
        this.stepper.selectedIndex = 0;
      }
    )
  }

  loadSecondTime(){
    if (this.isAdvisor) {

      const dialogRef = this.dialog.open(ProspectSessionModalComponent, {
        data: {}
      });

      // Subscribe to result
      dialogRef.afterClosed().subscribe(result => {

        if (result === 'refresh') {

          location.reload();
          //this.openFullPageLoader();
          //this.authService.logout('prospect');

          /*setTimeout(()=>{
            this.loadDataSecondTime();
            this.fullPageSpinnerModal.close();
            this.isAdvisor = false;
          },1200)*/

        }else{
          //Open incognito
          let prospectHashId = this.route.snapshot.queryParamMap.get('cli');
          this.prospectHash = prospectHashId
          this.copyToClipboard();
          this.router.navigate(['/advisor/clients']);
        }
      });


    } else { // The prospect is answering the questionnaire
      this.loadDataSecondTime();
    }
  }


  loadDataSecondTime(){

    this.openFullPageLoader();



    let prospectHashId = this.route.snapshot.queryParamMap.get('cli');

    // Load prospect areas
    this.http.get<any>(environment.prospectAdvisor + prospectHashId).subscribe(prospectResponse => {
      // Case. The info isn't on localStorage. The user has accessed by a direct prospect url.
      if (!Boolean(localStorage.getItem('advisorResponse'))) {
        //let advisorDataResponse = await this.http.get<any>(environment.prospectValidate + `?advisor=${prospectResponse.prospectJoinId}`).toPromise();
        // Save the response on local storage to be compatible with the next screens
        localStorage.setItem('advisorResponse', JSON.stringify(prospectResponse.advisorData));
      }

      localStorage.setItem('prospectResponse', JSON.stringify({ idClient: '', idHash: prospectHashId, clientIDFirstName: prospectResponse.clientIDFirstName, clientIDLastName: prospectResponse.clientIDLastName }));
      let localAdvisorData = JSON.parse(localStorage.getItem('advisorResponse'));

      this.advisorLogo = localAdvisorData.companyLogo;
      this.advisorCompany = localAdvisorData.companyName;
      this.advisorDisclaimer = Boolean(localAdvisorData.leadDisclaimer) ? localAdvisorData.leadDisclaimer : CONST_DISCLAIMER;

      this.backgroundImage = `url(${localAdvisorData.leadPageBackground})`;

      let areasFromStorage: string[] = prospectResponse.areas //JSON.parse(localStorage.getItem('areasToAnalyze'));

      areasFromStorage.map(area => {
        this.areasToShow.push(area);
      })


      this.advisorName = localAdvisorData.fullName;
      this.advisorEmail = localAdvisorData.email;
      this.advisorPhone = localAdvisorData.phone;
      this.prospectHash = prospectHashId
      this.clientData['clientId'] = prospectHashId
      this.clientData['name'] = prospectResponse.clientIDFirstName;


      this.prospectAreas = Object.keys(localAdvisorData.leadPageAreas);

      //Load and save in the model the client data
      this.model.clientIDFirstName =  prospectResponse.clientIDFirstName;
      this.model.clientIDLastName =  prospectResponse.clientIDLastName;
      this.model.email =  prospectResponse.email;



      this.areasToDisplayArray.forEach(area =>{
        if(this.areasToShow.includes(area.id)){
          this.finalArrayAreas.push(area.name)
        }
      })

      //Questionnaire hasn't be answered

      //Check if have custom questions
      Object.keys(localAdvisorData.customQuestions).length === 0 ? this.hasCustomQuestions = false : this.hasCustomQuestions = true;
      this.customQuestions = localAdvisorData.customQuestions;

      // This assignment depends on the response data
      this.questUrl = environment.prospectAdvisor + 'allareasquestionnaire/' + this.clientData.clientId;

      this.fullPageSpinnerModal.close();

    }, //Prospect doesn't exist
      error => {

        this.router.navigate(['prospect/alert']);
      });
  }

  ngAfterViewChecked(): void {
    //Autofill home questions if rental property is selected on Tax
    if (this.model.clientIDHasAnyOtherIncomeAsEmployee === 'Rental income' || this.model.clientIDHasAnyOtherIncomeAsSelfEmployed === 'Rental income' || this.model.clientIDHasAnyOtherIncomeAsUnemployed === 'Rental income') {
      this.model.clientIDHome = 'Yes'
      this.model.clientIDInvestmentProperty = 'Yes'
    }

  }

  calculateAge(DOB) {
    return utilsCalculateAge(DOB);
  }

  addChildren(): void {
    if (this.model.clientIDChildrenCount < 5) {
      this.model.clientIDChildrenCount++;
    }
    //this.childrenCount++;

  }

  removeChildren(): void {
    if (this.model.clientIDChildrenCount > 0) {
      this.model.clientIDChildrenCount--;
    }
    //this.childrenCount--;

  }

  submit(saveForLater: boolean = false, isFirstGeneral: boolean = false) {
    this.disabled = true;

    this.modifySpecialCasesQuest();

    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    if(this.model.clientIDTotalNonDiscretionaryExpenses > 0){
      this.model.clientIDTotalDiscretionaryExpenses = 100 - this.model.clientIDTotalNonDiscretionaryExpenses;
    }

    if (this.model.clientIDOccupation == 'Self-employed') {
      this.model.clientIDHasABusiness = 'Yes';
    }
    if (this.model.clientIDMaritalStatus === 'Single') {
      this.model.clientIDFilingStatus = 'Single'
    }

    if (this.model.clientIDOccupation === 'Employed') {
      this.model.clientIDWorkingAndReceivingIncome = 'Yes'
    }

    if (this.model.clientIDChildren === 'Yes' && this.model.clientIDChildrenCount > 1) {
      this.model.clientIDMultipleChild = 'Yes'
    } else {
      this.model.clientIDMultipleChild = 'No'
    }

    this.model.clientIDBaseSalary = this.model.clientIDLastYearIncome;

    if (this.model.clientIDCarsOwned === 0) { this.model.clientIDClientDrivesAndDoesNotOwnCar = 'Yes' };

    this.model.clientIDHasABusiness === 'Yes' && this.model.clientIDSmallBusiness === 'None' ? this.model.clientIDExpensesMonthlyBasis = 'No' : this.model.clientIDExpensesMonthlyBasis = 'Yes';

    if(saveForLater){
      this.http.post(`${this.questUrl}?saveLater=true${isFirstGeneral ? '&personalInfo=true' : ''}`, this.model.toSaveFormat(this.whoAnswered), options).subscribe(response => {
        // If successfully saved

        //Save progress
        let options = {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        };

        let body = {
          'areaId': this.areasIds[this.currentSection],
          'percentage': this.progressNumber,
        }

        this.http.post<any>(`${environment.prospectProgress}/${this.prospectHash}`, body, options).subscribe(res => {

        })

      },
        error => {
          console.log("Error save for later", error);
        });
        this.disabled = false;
        return;
    }
    this.qController.throwConfirmationInteraction(
      'Please, confirm to submit the questionnaire.',
      () => {
        this.http.post(this.questUrl, this.model.toSaveFormat(this.whoAnswered), options).subscribe(response => {
          // If successfully saved:
          this.openFullPageLoader();
          setTimeout(() => {
            this.router.navigate(['/prospect/form-new/submitted']);
            //this.router.navigate(['prospect/submitted-success']);
            this.fullPageSpinnerModal.close();
            this.disabled = false;
          }, 2000);
        },
          error => {
            console.log('top', 'UNEXPECTED ERROR', error);
            this.disabled = false;
          });
      }, () => {
        this.disabled = false;
    })
  }

  modifySpecialCasesQuest(){
    this.model.clientIDDebtList = this.tempclientIDDebtList.join();
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  factoryNumericArray(maxIndex = 1): number[] {
    return new Array(maxIndex).fill(true).map((value, index) => index + 1)
  }

  //NEW STUFF
  async goForward() {
    if(this.stepper.selectedIndex == 1 && this.isFirstTime){

      this.createProspect();
      //this.stepper.selectedIndex = 0;
    }else{

      this.stepper.next();
    }

    if(!this.isFirstTime){

      await this.calculateCurrentSection();
      this.saveProgress();
    }

    await this.calculateProgressNumber();
  }

  goForwardSlow(event){
    if(event){
      if(event.value == 'No'){
        setTimeout(()=>{
          this.stepper.next();
          this.calculateCurrentAndProgress();
          this.saveProgress();
        },300)
      }
    }else{
      setTimeout(()=>{
        this.stepper.next();
        this.calculateCurrentAndProgress();
        this.saveProgress();
      },300)
    }

  }

  saveProgress(){
    //Enter when only new section
    if(this.currentSection !== this.tempCurrentSection){

      const auxTemp = this.tempCurrentSection;
      this.tempCurrentSection = this.currentSection;
      this.submit(true, this.currentSection !== 'Personal information' && auxTemp === 'Personal information');
    }
  }

  async calculateCurrentAndProgress(){
    if(!this.isFirstTime){
      await this.calculateCurrentSection();
    }
    await this.calculateProgressNumber();
  }

  async goBack() {
    this.stepper.previous();
    await this.calculateProgressNumber();

    if(!this.isFirstTime){
      await this.calculateCurrentSection();
    }
  }

  calculateCurrentSection(){
    return new Promise((resolve, reject) => {
      let matStepperTotal: NodeListOf<HTMLElement> = document.querySelectorAll('.codeProspect');
      this.currentSection = matStepperTotal[this.stepper.selectedIndex].innerHTML;
      if(matStepperTotal[this.stepper.selectedIndex].nextElementSibling.className == 'phoneSection'){

        this.phoneStep = this.stepper.selectedIndex;
      }else{
        this.phoneStep = 100;
      }
      this.totalSteps = (matStepperTotal.length-1);
      resolve('calculate-current-section');
    })

  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `http://app.fpalpha.com/prospect/form?cli=${this.prospectHash}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.snackBarSuccess.open("Copied to clipboad", 'Ok', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });

  }

  changePrimaryColor() {
    var all = Array.from(document.getElementsByClassName('mat-form-field-infix') as HTMLCollectionOf<HTMLElement>);
    for (var i = 0; i < all.length; i++) {
      all[i].style.color = 'red';
    }
  }

  mortgageQuestion(event, button){

    if(button._value == 'Yes'){
      if(!this.tempclientIDDebtList.includes('Mortgage')){
        this.tempclientIDDebtList.push('Mortgage');
      }

      this.model.clientIDHasDebt = 'Yes';

    }else{
      const index = this.tempclientIDDebtList.indexOf('Mortgage');
      if (index > -1) {
        this.tempclientIDDebtList.splice(index, 1);
      }

      this.model.clientIDHasDebt = 'No';
    }
  }

  autoFillQuestions(event, button){
    if(button._value == 'Yes'){
      this.model.clientIDClientInsured = 'Yes';
    }else{
      this.model.clientIDClientInsured = 'No';
    }
  }

  checkIfNeedTwoColumns(){
    let temp = ['Getting a part-time job', 'Buying a vacation home', 'Buying a boat', 'Buying a plane'];
    let n = 0;
    temp.forEach(element =>{
      if(this.model.clientIDAnticipateChangesRetirement.includes(element)){
        n++;
      }
    })

    if(n > 1){
      return true;
    }else{
      return false;
    }

  }



  /*---------------
  EXTRA FUNCTIONS
  ----------------*/
  public fullPageSpinnerModal;

  openFullPageLoader(){
      this.fullPageSpinnerModal = this.dialog.open(FullPageSpinnerComponent, {
      disableClose: true,
      width: '170px',
      height: '170px',
      });
      this.fullPageSpinnerModal.afterClosed().subscribe(response => {

      })
  }

  calculateProgressNumber() {
    return new Promise((resolve, reject) => {
      let matStepper: NodeListOf<HTMLElement> = document.querySelectorAll('.mat-horizontal-stepper-content');
      this.totalProgressTabs = matStepper.length - 1;
      this.progressNumber = Math.round((this.stepper.selectedIndex) * 100 / this.totalProgressTabs)
      resolve('calculate-progress-number');
    })

  }

}
