import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[beneficiaryList]'
})
export class BeneficiaryListDirective implements AfterViewInit {
  private observer: MutationObserver;

  constructor(private elemRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.filterChilds();

    // Configurar el MutationObserver para observar cambios en el contenido del <td>
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          this.filterChilds();
        }
      });
    });

    // Configurar el observer para observar cambios en el nodo actual
    this.observer.observe(this.elemRef.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy() {
    // Desconectar el observer cuando se destruya la directiva
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private filterChilds() {
    const nativeElement: HTMLElement = this.elemRef.nativeElement;

    // Verificar si el <td> tiene la clase 'mat-column-beneficiaries'
    if (!nativeElement.classList.contains('mat-column-beneficiaries') &&
      !nativeElement.classList.contains('mat-column-contingentBeneficiaries')) {
      return;
    }

    const lines = nativeElement.querySelectorAll('span');

    if (lines.length>3) {
      const filteredLines =  Array.from(lines).slice(0, 3);

      // Eliminar contenido existente
      while (nativeElement.firstChild) {
        nativeElement.removeChild(nativeElement.firstChild);
      }

      // Agregar las líneas filtradas
      filteredLines.forEach(line => {
        nativeElement.appendChild(line)
        const br = document.createElement("br");
        nativeElement.appendChild(br)
      });
      const strong = document.createElement("strong");
      strong.innerText = lines.length - filteredLines.length + ' more...'
      nativeElement.appendChild(strong)
    }
    
  }
}
