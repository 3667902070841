import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';


@Component({
  selector: 'app-concurrent-session-modal',
  templateUrl: './prospect-session-modal.component.html',
  styleUrls: ['./prospect-session-modal.component.scss']
})
export class ProspectSessionModalComponent extends CloseDialogAtLogOut implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProspectSessionModalComponent>,
    private authService: AuthenticationService,
    ) {
      super(dialogRef, authService);
    }

  onNoClick(text): void {
    this.dialogRef.close(text);
  }

  ngOnInit() {

  }

}
