import { Component, Input, OnInit } from '@angular/core';
import { CONST_STATES_KEY } from 'app/views/questionnaires/Questionnaire.constants';
import { element } from 'protractor';

export interface TableFiduciaryPDF {
  sectionTitle: ('Will' | 'Revocable' | 'POA' | 'Healthcare');
  type: ('Client' | 'Coclient' | 'Joint');
  title: string;
  titleClass?: string;
  date?: (Date | 'Unknown' | null);
  rows: Row[];
  materialInformation?: string;
}

interface Row {
  textA: string;
  classA: string;
  textB?: string[];
  classB?: string;
  displayBorder?: boolean
}

export interface DataTablePDF {
  name: string;
  tablesData: TableFiduciaryPDF[];
}
@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  @Input() dataTable: any = [];
  @Input() platformView: boolean = false;

  public dictionaryStates: any = {
    'Alabama' :'AL',
    'Alaska' :'AK',
    'American Samoa' :'AS',
    'Arizona':'AZ',
    'Arkansas':'AR',
    'California':'CA',
    'North Carolina':'NC',
    'South Carolina':'SC',
    'Colorado':'CO',
    'Connecticut':'CT',
    'Disctrict of Columbia':'DC',
    'North Dakota':'ND',
    'South Dakota':'SD',
    'Delaware':'DE',
    'Florida':'FL',
    'Georgia':'GA',
    'Hawaii':'HI',
    'Idaho':'ID',
    'Illinois':'IL',
    'Indiana':'IN',
    'Iowa':'IA',
    'Kansas':'KS',
    'Kentucky':'KY',
    'Louisana':'LA',
    'Maine':'ME',
    'Maryland':'MD',
    'Massachusetts':'MA',
    'Michigan':'MI',
    'Minnesota':'MN',
    'Mississippi':'MS',
    'Missouri':'MO',
    'Montana':'MT',
    'Nebraska':'NE',
    'Nevada':'NV',
    'New Jersey':'NJ',
    'New York':'NY',
    'New Hampshire':'NH',
    'New Mexico':'NM',
    'Ohio':'OH',
    'Oklahoma':'OK',
    'Oregon':'OR',
    'Pennsylvania':'PA',
    'Rhode Island':'RI',
    'Tennessee':'TN',
    'Texas':'TX',
    'Utah':'UT',
    'Vermont':'VT',
    'Virginia':'VA',
    'West Virginia':'WV',
    'Washington':'WA',
    'Wisconsin':'WI',
    'Wyoming':'WY',
  };

  public dataResponse: TableFiduciaryPDF[] = [
    {
      sectionTitle: 'POA',
      type: 'Client',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      rows: [
        {
          textA: 'Co-Trustees',
          classA: 'titleRow',
          textB: ['Eric C. Gaertner'],
          classB:'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'Successor Executors',
          classA: 'titleRow',
          textB: ['U.S. Bank', 'N.A.'],
          classB: 'fp-font-os-bold'
        },
        {
          textA: 'Legal Guardians',
          classA: 'titleRow',
          textB: ['Kevin Gaertner', 'Tyler Caswell', 'Paula Caswell'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'State',
          classA: 'titleRow',
          textB: ['Florida'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
      ]
    },
    {
      sectionTitle: 'POA',
      type: 'Client',
      title: 'The Franz J. Gaertner’s Will',
      date: 'Unknown',
      rows: []
    },
    {
      sectionTitle: 'Will',
      type: 'Client',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      materialInformation: 'Two addtional documents - Durable Power of Attorney naming Carol A. Sullivan and Fiduciary Partners Trust Company respectively have also been provided.',
      rows: [
        {
          textA: 'Executor',
          classA: 'titleRow',
          textB: ['Eric C. Gaertner'],
          classB:'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'Successor Executors',
          classA: 'titleRow',
          textB: ['U.S. Bank', 'N.A.'],
          classB: 'fp-font-os-bold'
        },
        {
          textA: 'Legal Guardians',
          classA: 'titleRow',
          textB: ['Kevin Gaertner', 'Tyler Caswell', 'Paula Caswell'],
          classB: 'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'State',
          classA: 'titleRow',
          textB: ['Florida'],
          classB: 'fp-font-os-bold',
          displayBorder: true
        },
      ]
    },
    {
      sectionTitle: 'Will',
      type: 'Coclient',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      rows: [
        {
          textA: 'Executor',
          classA: 'titleRow',
          textB: ['Eric C. Gaertner'],
          classB:'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'Successor Executors',
          classA: 'titleRow',
          textB: ['U.S. Bank', 'N.A.'],
          classB: 'fp-font-os-bold'
        },
        {
          textA: 'Legal Guardians',
          classA: 'titleRow',
          textB: ['Kevin Gaertner', 'Tyler Caswell', 'Paula Caswell'],
          classB: 'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'State',
          classA: 'titleRow',
          textB: ['Florida'],
          classB: 'fp-font-os-bold',
          displayBorder: true
        },
      ]
    },
    {
      sectionTitle: 'Revocable',
      type: 'Coclient',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      rows: [
        {
          textA: 'Co-Trustees',
          classA: 'titleRow',
          textB: ['Eric C. Gaertner'],
          classB:'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'Successor Executors',
          classA: 'titleRow',
          textB: ['U.S. Bank', 'N.A.'],
          classB: 'fp-font-os-bold'
        },
        {
          textA: 'Legal Guardians',
          classA: 'titleRow',
          textB: ['Kevin Gaertner', 'Tyler Caswell', 'Paula Caswell'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'State',
          classA: 'titleRow',
          textB: ['Florida'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
      ]
    },
    {
      sectionTitle: 'Revocable',
      type: 'Client',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      rows: []
    },
    {
      sectionTitle: 'Revocable',
      type: 'Joint',
      title: 'The Franz J. Gaertner’s Will',
      date: new Date(),
      materialInformation:
      'Two addtional documents - Durable Power of Attorney naming Carol A. Sullivan and Fiduciary Partners Trust Company respectively have also been provided.' +
      'Two addtional documents - Durable Power of Attorney naming Carol A. Sullivan and Fiduciary Partners Trust Company respectively have also been provided.',
      rows: [
        {
          textA: 'Co-Trustees',
          classA: 'titleRow',
          textB: ['Eric C. Gaertner'],
          classB:'fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'Successor Executors',
          classA: 'titleRow',
          textB: ['U.S. Bank', 'N.A.'],
          classB: 'fp-font-os-bold'
        },
        {
          textA: 'Legal Guardians',
          classA: 'titleRow',
          textB: ['Kevin Gaertner', 'Tyler Caswell', 'Paula Caswell'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
        {
          textA: 'State',
          classA: 'titleRow',
          textB: ['Florida'],
          classB: 'fp-font-os-fp-font-os-bold',
          displayBorder: true
        },
      ]
    }
  ];

  public isLoading: boolean = false;

  public statesArray = CONST_STATES_KEY;

  constructor() { }

  ngOnInit(): void {
    this.isLoading = true;
    console.log('data-table*', this.dataTable);
    this.isLoading = false;
  }

  getTablesByType(type: string, tables: TableFiduciaryPDF[]): TableFiduciaryPDF[] {
    if (tables) {
      return tables.filter(table_ => table_.type === type);
    }
  }

  findState(keyState: string): string{
    let state: any = this.statesArray.find(element => element.key === keyState);
    return state ? state.value : 'State not found';
  }

}
