import { SafeStylePipe } from "./pipes/safeStyle.pipe";
import { GraphToolComponent } from "./components/graph-tool/graph-tool.component";
import { ModuleWithProviders } from "@angular/core";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSliderModule } from "@angular/material/slider";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatRippleModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatOptionModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSortModule } from '@angular/material/sort';

import { ChartsModule } from "ng2-charts";

// REQUIRED FOR SIDE NAVIGATION
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { SidebarSideComponent } from "./components/sidebar-side/sidebar-side.component";
import { HorizontalSearchComponentComponent } from "./components/horizontal-search-component/horizontal-search-component.component";
//import { ExpirationSessionComponentComponent } from '../views/sessions/expiration-session-component/expiration-session-component.component';

// ALL TIME REQUIRED
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { AppComfirmComponent } from "./services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";
import { AnnouncementModalComponent } from "./components/announcement-modal/announcement-modal.component";

// DIRECTIVES
import { FontSizeDirective } from "./directives/font-size.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AppDropdownDirective } from "./directives/dropdown.directive";
import { DropdownAnchorDirective } from "./directives/dropdown-anchor.directive";
import { DropdownLinkDirective } from "./directives/dropdown-link.directive";
import { FPAlphaSideNavToggleDirective } from "./directives/fp-alpha-side-nav-toggle.directive";
import { ImagePreloadDirective } from "./directives/default-image.directive";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { DndDirective } from "./directives/dnd.directive";
// PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { AppendContextUrlPipe } from "./pipes/append-context-url.pipe";
import { DomseguroPipe } from "./pipes/domseguro.pipe";
import { RemoveDecimalsPipe } from "./pipes/decimal-remover.pipe";
import { SafeHtmlPipe } from "./pipes/safeHtml.pipe";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { LayoutService } from "./services/layout.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { AuthGuardAdvisor } from "./services/auth/authAdvisor.guard";
import { AuthGuardClient } from "./services/auth/authClient.guard";
import { EducationService } from "./services/education.service";
//import { AuthenticationService } from './services/auth/authentication.service';
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { AppSocketIoService } from "./services/socket.io.service";

// HELPERS
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ErrorInterceptor } from "./helpers/error.interceptor";
import { APInterceptor } from "./helpers/api.interceptor";
import { WINDOW_PROVIDERS } from "./helpers/window.provider";

import { AgreementDialog } from "./components/agreement-dialog/agreement-dialog.component";
import { EndTrialDialog } from "./components/end-trial-dialog/end-trial-dialog.component";

// Components
import { ScoreCardComponent } from "../views/questionnaires/q-new-prospect/submitted/score-card/score-card.component";
import { RetirementCalculatorComponent } from "app/views/questionnaires/q-new-prospect/submitted/components/retirement-calculator/retirement-calculator.component";
import { TaxPyramidComponent } from "./components/taxpyramid/tax-pyramid.component";
import { TaxCalculatorV2Component } from "./components/tax-calculator-v2/tax-calculator-v2.component";
import { NgxCleaveDirectiveModule } from "ngx-cleave-directive";
import { ReleasesComponent } from "./components/releases/releases.component";
import { EstateSnapshot } from "./components/estate-snapshot/estate-snapshot.component";
import { ProspectAreaDescription } from "../views/questionnaires/q-new-prospect/submitted/area-description/prospect-area-description.component";

import { MatStepperModule } from "@angular/material/stepper";
import { FileUploadModule } from "ng2-file-upload";
import { SnapshotComponents } from "./components/estate-snapshot/estate-snapshot-components/snapshot-components.component";
import { EstateMemberComponent } from "./components/estate-snapshot/estate-snapshot-components/estate-member/estate-member.component";
import { EstateSnapshotStatusComponent } from "./components/estate-snapshot/estate-snapshot-status/estate-snapshot-status.component";
import { FilePreviewComponentComponent } from "../../../estate-success-dialog/file-preview-component/file-preview-component.component";
import { RothV2PdfComponent } from "./components/roth/roth-v2-pdf/roth-v2-pdf.component";

//Summary
import { SimpleDataComponent } from "./components/tax-snapshot-components/tax-summary/simple-table.compoment";
import { BarChartHorizontalComponent } from "./components/tax-snapshot-components/bar-chart-horizontal/bar-chart-horizontal.component";
import { BarChartVerticalComponent } from "./components/tax-snapshot-components/bar-chart-vertical/bar-chart-vertical.component";
import { BarChartPhaseComponent } from "./components/tax-snapshot-components/bar-chart-phase/bar-chart-phase.component";
import { MedicarePremiumsComponent } from "./components/tax-snapshot-components/medicare-premiums/medicare-premiums.component";
import { MultiTableDataComponent } from "./components/tax-snapshot-components/multi-table-summary/multi-table.component";
import { TextTableDataComponent } from "./components/tax-snapshot-components/text-table-summary/text-table.component";
import { RothCalculatorPdfComponent } from "./components/roth/roth-calculator-pdf/roth-calculator-pdf.component";
import { TaxSnapshotPdfComponent } from "./components/tax-snapshot-pdf/tax-snapshot-pdf.component";

//Prospects
import { AreaCardsComponent } from "../views/questionnaires/q-new-prospect/areas/area_cards.component";
import { ImageUploader } from "../shared/components/Image-uploader/image-uploader.component";

//INTEGRATIONS

/* Riskalyze */
import { RiskalyzeIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/riskalyze-integration/riskalyze-integration.component";

/* Salesforce */
import { SalesforceIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/salesforce-integration/salesforce-integration.component";
import { FailComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/fail/fail.component";
import { SuccessComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/success/success.component";
import { PendingComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/pending/pending.component";
import { ResourcesComponent } from "app/views/assets/resources/resources.component";
import { CalculatorDialogComponent } from "./components/roth/calculator-dialog/calculator-dialog.component";

// Color picker
import { ColorPickerModule } from "ngx-color-picker";

// Prospect Dashboard
import { ProspectDashboardDataComponent } from "./components/prospect-dashboard-data/prospect-dashboard-data.component";

// Dialogs
import { ShareClientDialog } from "../views/clients/share-client/share-client.component";
import { ShareClientMultipleDialog } from "./components/share-client-multiple/share-client-multiple.component";
import { RoleModalComponent } from "../views/enterprise-settings/company/modals/role-modal/role-modal.component";
import { AdvisorInfoModalComponent } from "../views/enterprise-settings/company/modals/advisor-info-modal/advisor-info-modal.component";
import { InviteAdvisorModalComponent } from "../views/enterprise-settings/company/modals/invite-advisor-modal/invite-advisor-modal.component";
import { EstatePlanningUploadDialogComponent } from "./components/estate-planning-upload-dialog/estate-planning-upload-dialog.component";

//Integrations

import { IntegrationsHomeComponent } from "../views/enterprise-settings/integrations/views/home/integrations-home.component";
import { IntegrationsModalComponent } from "../views/enterprise-settings/integrations/templates/interactions/integrations-modal/integrations-modal.component";

import { SsoComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/sso/sso.component";

//Redtail
import { RedtailIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/redtail-integration/redtail-integration.component";

//Wealthbox
import { WealthboxIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/wealthbox-integration/wealthbox-integration.component";
import { IntegrationsImportModalComponent } from "../views/enterprise-settings/integrations/templates/interactions/integrations-import-modal/integrations-import-modal.component";
import { RothCalculator } from "./classes/roth-calculator";

//Review Extractions Dialog

import { ReviewExtractionsDialogComponent } from "./components/review-extractions-dialog/review-extractions-dialog.component";
import { DialogGenericNoticeComponent } from "./components/dialog-generic-notice/dialog-generic-notice.component";
import { ModalCharitableContrDeductComponent } from "./components/tax-snapshot-components/modal-charitable-contr-deduct/modal-charitable-contr-deduct.component";

import { EstateLabModalComponent } from "./components/estate-lab-modal/estate-lab-modal.component";
//Roth calc
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CalculatorModifyYearAmountModalComponent } from "./components/calculator-modify-year-amount-modal/calculator-modify-year-amount-modal.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { FullPageSpinnerComponent } from "./components/full-page-spinner/full-page-spinner.component";
import { RothGraphModalComponent } from "./components/roth/roth-graph-modal/roth-graph-modal.component";
import { YearYearComparisonModalComponent } from "./components/year-year-comparison-modal/year-year-comparison-modal.component";
import { RothYearGraphModalComponent } from "./components/roth/roth-year-graph-modal/roth-year-graph-modal.component";
import { SnapshotFamilyFlowchartComponent } from "./components/estate-snapshot/estate-snapshot-components/snapshot-family-flowchart/snapshot-family-flowchart.component";
import { MoneyguidIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/moneyguid-integration/moneyguid-integration.component";
import { PrecisefpIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/precisefp-integration/precisefp-integration.component";
import { OrionIntegrationComponent } from "../views/enterprise-settings/integrations/templates/providers/orion-integration/orion-integration.component";

import { NgxMaskModule } from "ngx-mask";
import { SuccessIntegrationsComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/success-integration/success-integration.component";
import { PaymentDetailDialogComponent } from "./components/payment-detail-dialog/payment-detail-dialog.component";
import { FirstEstateRequestDialogComponent } from "./components/first-estate-request-dialog/first-estate-request-dialog.component";
import { EstateSuccessDialogComponent } from "../../../estate-success-dialog/estate-success-dialog.component";
import { EstateInfoDialogComponent } from "./components/estate-info-dialog/estate-info-dialog.component";
import { ReviewPaymentMethodComponent } from "./components/review-payment-method/review-payment-method.component";

//Tax projector
import { ScenariosComponent } from "../views/tax-projections/scenarios/scenarios.component";

//animated loader
import { AnimatedLoaderComponent } from "./components/animated-loader/animated-loader.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { PopoverModule } from "./components/estate-lab-v2/commons/popover/popover.module";
//
import { BoxesComponent } from "./components/estate-lab-v2/commons/boxes/boxes.component";
import { DataSummarizeComponent } from "./components/estate-lab-v2/commons/data-summarize/data-summarize.component";
import { HubNavbarComponent } from "./components/questionnaires-hub/commons/hub-navbar/hub-navbar.component";
import { HubItemCardComponent } from "./components/questionnaires-hub/commons/hub-item-card/hub-item-card.component";
import { UploadHistoryDialogComponent } from "./components/dialogs/upload-history-dialog/upload-history-dialog.component";
import { FileUploaderV2Component } from "./components/file-uploader-v2/file-uploader-v2.component";
import { UploadConfirmV2Component } from "./components/dialogs/upload-confirm-v2/upload-confirm-v2.component";
import { InsuranceSnapshotComponent } from "app/views/new-summary/components/insurance-snapshot/insurance-snapshot.component";
import { PurchaseModalComponent } from "./components/pricing-strategy/purchase-modal/purchase-modal/purchase-modal.component";
import { PaymentCardComponent } from "./components/payment-card/payment-card/payment-card.component";
import { TabsComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/tabs/tabs.component";
import { PreferencesComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/preferences/preferences.component";
import { IntegrationStepService } from "./services/integration/integration-step.service";
import { ImportContactsComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/import-contacts/import-contacts.component";
import { ConfirmLeavingComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/confirm-leaving/confirm-leaving.component";
import { ConfirmMappingComponent } from "../views/enterprise-settings/integrations/templates/external-providers-interactions/confirm-mapping/confirm-mapping.component";
import { IconComponent } from "./components/icon/icon.component";
import { AddPersonnelDialog } from "app/views/clientprofile/add-personnel-dialog/add-personnel-dialog";
import { ClientDataDialog } from "app/views/clientprofile/client-data-dialog/client-data-dialog.component";
import { TopInsightsComponent } from "app/views/dashboard/templates/top-insights/top-insights.component";
import { AreasDialog } from "app/views/clientprofile/areas-dialog/areas-dialog.component";
import { DeleteAreaDialog } from "app/views/clientprofile/delete-area/delete-area.component";
import { GeneralDialogV2Component } from "./components/general-dialog-v2/general-dialog-v2.component";
import { IntegrationMapperService } from "./services/integration/integration-mapper.service";
import { SelectWithOpenFieldComponent } from './components/select-with-open-field/select-with-open-field.component';
import { TableComponent } from './components/table/assets-table.component';

import { EstateSnapshotV3Component } from "./components/estate-snapshot-v3/estate-snapshot-v3.component";
import { DataTableComponent } from "./components/estate-snapshot-v3/data-table/data-table.component";
import { ListDocumentsComponent } from "./components/estate-snapshot-v3/list-documents/list-documents.component";
import { FormatPagePdfComponent } from "./components/estate-snapshot-v3/format-page-pdf/format-page-pdf.component";
import { FamilyBoxComponent } from "./components/estate-snapshot-v3/family-box/family-box.component";

import { IntegrationAvailabilityService } from './services/integration/integration-availability.service';
import { IntegrationsComponent } from "app/views/enterprise-settings/integrations/integrations.component";
import { EditEstateSnapshotComponent } from "app/views/new-summary/areas/estate/edit-estate-snapshot/edit-estate-snapshot.component";
import { FlowchartBoxComponent } from './components/estate-snapshot-v3/flowchart-box/flowchart-box.component';
import { GraphTestComponent } from './components/estate-snapshot-v3/graph-test/graph-test.component';
import { MaterialInfoComponent } from './components/estate-snapshot-v3/material-info/material-info.component';
import { MagicStickyTableComponent } from "./components/magic-sticky-table/magic-sticky-table.component";
import { RothNameEditComponent } from "./components/roth/roth-name-edit/roth-name-edit.component";
import { RothConversionOverrideModalComponent } from "./components/roth/roth-conversion-override-modal/roth-conversion-override-modal.component";
import { EstateSnapshotReportComponent } from './components/estate-snapshot-v3/estate-snapshot-report/estate-snapshot-report.component';
import { EstateComponent } from "app/views/new-summary/areas/estate/estate.component";
import { BeneficiaryListDirective } from './directives/beneficiary-list.directive';
import { GraphToolIterativeCutterComponent } from './components/graph-tool-iterative-cutter/graph-tool-iterative-cutter.component';
import { ClusterActionWrapperComponent } from './components/cluster-action-wrapper/cluster-action-wrapper.component';
import { PercentageMaskDirective } from './directives/percentage-mask.directive';
import { TableEstateComponent } from "./components/estate-lab-v2/balance-sheet/components/table-estate/table-estate.component";
import { GraphEstateComponent } from "./components/estate-lab-v2/balance-sheet/components/graph-estate/graph-estate.component";
import { CardEstateComponent } from "./components/estate-lab-v2/balance-sheet/components/card-estate/card-estate.component";
import { SelectWithSearchFieldComponent } from './components/select-with-search-field/select-with-search-field.component';
import { MainTableEstateComponent } from "./components/estate-lab-v2/balance-sheet/components/main-table-estate/main-table-estate.component";
import { PdfFormattedPageComponent } from "./components/pdf-formatted-page/pdf-formatted-page.component";
import { ReportViewComponent } from "./components/estate-lab-v2/report-view/report-view.component";
import { RelationTablePdfComponent } from "./components/estate-lab-v2/pdf/componentSections/relation-table-pdf/relation-table-pdf.component";
import { BalanceSheetComponent } from "./components/estate-lab-v2/balance-sheet/balance-sheet.component";
import { TaxesPdfComponent } from "./components/estate-lab-v2/pdf/componentSections/taxes-pdf/taxes-pdf.component";

const classesToInclude = [
  IconComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  FPAlphaSideNavToggleDirective,
  SsoComponent,
  ImagePreloadDirective,
  AutofocusDirective,
  DndDirective,
  PercentageMaskDirective,
  BeneficiaryListDirective,
  RelativeTimePipe,
  AppendContextUrlPipe,
  DomseguroPipe,
  SafeHtmlPipe,
  SafeStylePipe,
  ExcerptPipe,
  RemoveDecimalsPipe,
  HorizontalSearchComponentComponent,
  FullPageSpinnerComponent,
  AnnouncementModalComponent,
  IntegrationsImportModalComponent,
  IntegrationsModalComponent,
  ReviewExtractionsDialogComponent,
  DialogGenericNoticeComponent,
  ModalCharitableContrDeductComponent,
  AgreementDialog,
  EndTrialDialog,
  TaxPyramidComponent,
  TaxCalculatorV2Component,
  SimpleDataComponent,
  TaxSnapshotPdfComponent,
  BarChartHorizontalComponent,
  BarChartVerticalComponent,
  BarChartPhaseComponent,
  MedicarePremiumsComponent,
  MultiTableDataComponent,
  TextTableDataComponent,
  RothCalculatorPdfComponent,
  ReleasesComponent,
  SalesforceIntegrationComponent,
  RiskalyzeIntegrationComponent,
  RedtailIntegrationComponent,
  WealthboxIntegrationComponent,
  EstateLabModalComponent,
  FailComponent,
  PendingComponent,
  SuccessComponent,
  IntegrationsComponent,
  SuccessIntegrationsComponent,
  CalculatorDialogComponent,
  CalculatorModifyYearAmountModalComponent,
  ProspectDashboardDataComponent,
  ShareClientDialog,
  ShareClientMultipleDialog,
  IntegrationsHomeComponent,
  AreaCardsComponent,
  ScoreCardComponent,
  RetirementCalculatorComponent,
  ProspectAreaDescription,
  ClickOutsideDirective,
  ImageUploader,
  RothGraphModalComponent,
  YearYearComparisonModalComponent,
  RothYearGraphModalComponent,
  RoleModalComponent,
  AdvisorInfoModalComponent,
  InviteAdvisorModalComponent,
  EstateSnapshot,
  SnapshotComponents,
  SnapshotFamilyFlowchartComponent,
  EstateMemberComponent,
  MoneyguidIntegrationComponent,
  PrecisefpIntegrationComponent,
  OrionIntegrationComponent,
  PaymentDetailDialogComponent,
  EstateSnapshotStatusComponent,
  FirstEstateRequestDialogComponent,
  GraphToolComponent,
  EstateSuccessDialogComponent,
  EstatePlanningUploadDialogComponent,
  FilePreviewComponentComponent,
  EstateInfoDialogComponent,
  ReviewPaymentMethodComponent,
  AnimatedLoaderComponent,
  RothV2PdfComponent,
  SelectWithSearchFieldComponent,
  TableComponent,
  ScenariosComponent,
  BoxesComponent,
  DataSummarizeComponent,
  HubNavbarComponent,
  HubItemCardComponent,
  UploadHistoryDialogComponent,
  FileUploaderV2Component,
  UploadConfirmV2Component,
  InsuranceSnapshotComponent,
  PurchaseModalComponent,
  PaymentCardComponent,
  TabsComponent,
  PreferencesComponent,
  ImportContactsComponent,
  ConfirmLeavingComponent,
  ConfirmMappingComponent,
  AddPersonnelDialog,
  ClientDataDialog,
  TopInsightsComponent,
  AreasDialog,
  DeleteAreaDialog,
  GeneralDialogV2Component,
  SelectWithOpenFieldComponent,
  EstateSnapshotV3Component,
  ReportViewComponent,
  DataTableComponent,
  ListDocumentsComponent,
  FormatPagePdfComponent,
  FamilyBoxComponent,
  FlowchartBoxComponent,
  RothNameEditComponent,
  RothConversionOverrideModalComponent,
  MagicStickyTableComponent,
  EditEstateSnapshotComponent,
  FlowchartBoxComponent,
  GraphTestComponent,
  MaterialInfoComponent,
  EstateSnapshotReportComponent,
  EstateComponent,
  TableEstateComponent,
  GraphEstateComponent,
  CardEstateComponent,
  MainTableEstateComponent,
  PdfFormattedPageComponent,
  RelationTablePdfComponent,
  BalanceSheetComponent,
  TaxesPdfComponent
];

@NgModule({
  imports: [
    MatStepperModule,
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule,
    MatExpansionModule,
    NgxCleaveDirectiveModule,
    MatChipsModule,
    MatSliderModule,
    CurrencyMaskModule,
    ColorPickerModule,
    DragDropModule,
    FileUploadModule,
    NgxMaskModule.forRoot(),
    PopoverModule,
  ],
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    AnnouncementModalComponent,
    IntegrationsImportModalComponent,
    IntegrationsModalComponent,
    ReviewExtractionsDialogComponent,
    EstateLabModalComponent,
    DialogGenericNoticeComponent,
    ModalCharitableContrDeductComponent,
    AgreementDialog,
    EndTrialDialog,
    CalculatorDialogComponent,
    CalculatorModifyYearAmountModalComponent,
    ShareClientDialog,
    ShareClientMultipleDialog,
    FullPageSpinnerComponent,
    ShareClientMultipleDialog,
    RothGraphModalComponent,
    YearYearComparisonModalComponent,
    RothYearGraphModalComponent,
    RoleModalComponent,
    AdvisorInfoModalComponent,
    InviteAdvisorModalComponent,
    PaymentDetailDialogComponent,
    FirstEstateRequestDialogComponent,
    EstateSuccessDialogComponent,
    EstatePlanningUploadDialogComponent,
    EstateInfoDialogComponent,
    ReviewPaymentMethodComponent,
    AnimatedLoaderComponent,
    UploadHistoryDialogComponent,
    UploadConfirmV2Component,
    ConfirmLeavingComponent,
    ConfirmMappingComponent,
    GeneralDialogV2Component,
    TopInsightsComponent,
    AddPersonnelDialog,
    ClientDataDialog,
    TopInsightsComponent,
    AreasDialog,
    DeleteAreaDialog,
    GeneralDialogV2Component,
    EditEstateSnapshotComponent,
    MagicStickyTableComponent,
    RothNameEditComponent,
    RothConversionOverrideModalComponent,
    MagicStickyTableComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ThemeService,
    LayoutService,
    NavigationService,
    EducationService,
    RoutePartsService,
    AuthGuard,
    AuthGuardAdvisor,
    AuthGuardClient,
    AppConfirmService,
    AppLoaderService,
    IntegrationStepService,
    IntegrationMapperService,
    IntegrationAvailabilityService,
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APInterceptor, multi: true },
  ],
  declarations: [classesToInclude, GraphToolIterativeCutterComponent, ClusterActionWrapperComponent, BeneficiaryListDirective, PercentageMaskDirective, SelectWithSearchFieldComponent],
  exports: classesToInclude,
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [AppSocketIoService],
    };
  }
}
