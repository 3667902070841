import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { crossborder_questions } from './crossborder.model';

@Component({
  selector: 'app-q-crossborder',
  templateUrl: './q-crossborder.component.html',
  styleUrls: ['./q-crossborder.component.scss']
})

export class QCrossborderComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: crossborder_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: boolean = false;
  public clientMaritalStatus: boolean;
  public clientHasChildren: string;
  public clientIsRetired: string;
  public clientAge: number;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;


  public bulletColor;

  public relocationPlaces = [
    { value: 'Bermuda', display: 'Bermuda' },
    { value: 'Cayman Islands', display: 'Cayman Islands' },
    { value: 'Jersey', display: 'Isle of Jersey' },
    { value: 'Alaska', display: 'Alaska' },
    { value: 'Delaware', display: 'Delaware' },
    { value: 'Hawaii', display: 'Hawaii' },
    { value: 'Nevada', display: 'Nevada' },
    { value: 'Oklahoma', display: 'Oklahoma' },
    { value: 'South Dakota', display: 'South Dakota' }
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public  authService: AuthenticationService,
    public qController: QuestionnaireController,
  ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried == "Married" || this.clientData.isMarried == "Domestic Partner";
    if (this.clientData.hasBusiness === 'Yes') this.clientHasBusiness = true;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientIsRetired = this.clientData.isRetired;
    this.clientAge = this.clientData.age;
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    

    //Initialize the questionnaire model
    this.model = new crossborder_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansCrossborder;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, the load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'crossborder', this.model).then(response => {
        
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {
    this.qController.submitForm(this, this.model, this.clientData.clientId, 'crossborder', saveForLater, null, silentSave);
  }

  countTabs(tabs:number){
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
    if(this.model.progressCompleted == true){
      this.model.progressBar = 100;
    }else{
      this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
    }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'crossborder'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'crossborder-questionnaire.pdf');
      });
  }


}
