import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { EstateSnapshot } from 'app/shared/components/estate-snapshot/estate-snapshot.component';
import { EstateSnapshotReportTemplate, PageData } from '../reports/estateSnapshot/EstateSnapshotTemplateReport.class';
import { saveAs } from 'file-saver';
import { cleanNameForPDF, delay, readFromStoragedObject } from 'app/shared/helpers/utils';
import { InsuranceHomeUmbrellaComponent } from '../new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.component';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { FormatPagePdfComponent } from 'app/shared/components/estate-snapshot-v3/format-page-pdf/format-page-pdf.component';
import { responseExample } from 'app/shared/components/estate-snapshot-v3/responseExample';
import { EstateSnapshotV3Component } from 'app/shared/components/estate-snapshot-v3/estate-snapshot-v3.component';
import { truncateSync } from 'fs';
import { ReportViewComponent } from 'app/shared/components/estate-lab-v2/report-view/report-view.component';


@Component({
  selector: 'app-external-snapshot-review',
  templateUrl: './external-snapshot-review.component.html',
  styleUrls: ['./external-snapshot-review.component.scss']
})
export class ExternalSnapshotReviewComponent implements OnInit {

  public isLogedIn: boolean = false;
  public estateSnapshotIndex: number = 1;
  public snapshotData: any;
  public displayDownloadPDFButton: boolean = false;
  public isLoadingPdf: boolean = false;
  public area: ('estate' | 'home' | 'auto' | 'estatev2' | 'estatelab');
  public x: EstateSnapshotV3Component;
  public y: ReportViewComponent;
  public allPagesData;
  public estateSnapshotTestData = responseExample;

  @ViewChild('estateSnapshotPrintableRender', { static: true }) public estateSnapshotPrintableRender: EstateSnapshot;
  public anyError: boolean = false;
  public domain: string;


  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private advisorService: AdvisorService
  ) { }

  ngOnInit() {

    let testingToken = '9c26e4e3626732cbf55eecf891961d360ca844aa16e0bf49f4dbec7ea0a00feddf2ab9d441cc5ab413ff2f5af9e4df98beb4502fb07cfb1e4b573dc95f2649aa9fdb1f817b6698bc594efc91c6968e8def78b6b86b21b395906dd1395eb6840dcc594943a2aea4684dc54c76688552e2d52c862f6bf05e9d439ea51873c87536';
    this.domain = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://auth.fpalpha.com' : 'https://auth.fpalphatesting.com'

      this.route.queryParams.subscribe(async _queryParams => {

        //Get the area from query param
        this.area = _queryParams['area'];
        console.log('area', this.area);
        let tempToken: string = '';

        //Validate the token param vs endpoint
        this.tokenValidationRequest(_queryParams['token']/* testingToken */).then(async(validationResponse: any) => {

          //Request data upon area param
          if(this.area === 'auto'){

            await InsuranceHomeUmbrellaComponent.loadBackendData(this.advisorService, 'auto-summary', validationResponse.data.clientId, validationResponse.access_token).then(response =>{
              this.snapshotData = response.reportDataResponse;
            })

          }else if(this.area === 'home'){

            await InsuranceHomeUmbrellaComponent.loadBackendData(this.advisorService, 'home-summary', validationResponse.data.clientId, validationResponse.access_token).then(response =>{
              console.log('response', response);
              this.snapshotData = response.reportDataResponse;
            })

          }else if(this.area === 'estatev2'){

            await this.advisorService.loadSnaphotDataEstateV2(validationResponse.data.clientId, validationResponse.access_token).then(response => {
              console.log('response', response);
              this.snapshotData = response;
            })

          }else if(this.area === 'estatelab'){

            await this.advisorService.getExternalEstateLab(validationResponse.data.clientId, [1,2,3], validationResponse.access_token).then((response: any) => {
              console.log('response', response);
              this.snapshotData = response;
            })


          }else if(this.area === 'estate'){

            await this.advisorService.loadSnaphotDataEstateV2(validationResponse.data.clientId, validationResponse.access_token, validationResponse.data.index, true).then(response => {
              console.log('response', response);
              this.snapshotData = response;
            })

          }else{  //Estate is the default case

            await this.estateSnapshotRequest(validationResponse.access_token, validationResponse.data.clientId, validationResponse.data.index).then(response => {
              this.snapshotData = response;
            }).catch(error_ => { this.anyError = true; throw(error_)});
          }

          console.log('snapshotData', this.snapshotData);


        }).catch(error => { this.anyError = true; throw(error)});

    })



   this.displayDownloadPDFButton = (sessionStorage.getItem('_PDF') === 'true');
  }

  readPages(data){
    this.allPagesData = data;
    console.log('allPagesData', this.allPagesData);
  }

  async tokenValidationRequest(token: string): Promise<Object>{
    let body = {
      token: token
    };
    let options = {
    };

    let isBetaEnv: boolean = (window.location.hostname == "app.beta.fpalpha.app" || window.location.port === "4300");

    let domain: string = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://auth.fpalpha.com' : 'https://auth.fpalphatesting.com'


    return this.http.post(`${domain}/single-token/validate`, body).toPromise();

  }

  async estateSnapshotRequest(token: string, idClient: string,  index: number = 1, version: number = 1): Promise<Object>{

    let headers = new HttpHeaders({
      'Content-Type': 'app/json',
      'Authorization': `Bearer ${token}`
    });

    let options = {
      headers: headers
    };

      return this.http.get(`PROTOCOL//DOMAINPORT/v2/docs/estate-snapshot?clientId=${idClient}&index=${Number(index)}`, options).toPromise();

  }

  async getTokenForTest(auth_token): Promise<string>{

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${auth_token}`,
      }),
      responseType: 'text' as 'text'
    };

    let body = {
        "clientId": "6462c65037e9c071d60a6079",
        "reviewer": {
            "tags": [
                "reviewerGroup:*"
            ],
            "_id": "611d43a4a111647256cb7afa",
            "email": "pdavila@fpalpha.com",
            "firstName": "Patricia",
            "lastName": "Davila"
        },
        "advisor": {
            "_id": "60f5902811ed372b3fdb3f90",
            "email": "pdavila@fpalpha.com",
            "fullName": "Patricia Davila"
        },
        "index": "1"
    }

    return this.http.post(`https://auth.fpalphatesting.com/single-token`, body, options).toPromise();

  }



  async printEstateSnapshotReport(){
    this.isLoadingPdf = true;
    try{

      //let canvases = await this.estateSnapshotPrintableRender.sectionsToCanvas();
      let pagesData: PageData[] = await this.estateSnapshotPrintableRender.responseToPagesData();
      if(pagesData.length == 0) {
        throw("No data to be printed");
       }



      let clientsName = this.snapshotData.client.fullName;


      EstateSnapshotReportTemplate.generateReport({
        clientName: clientsName,
        disclaimer: '',
        logoUrl: 'https://storage.googleapis.com/fpalpha-semiproduction-public/fpalpha-assets/companies/fpalpha/logo.png?i=0.805668719821675',
        //canvases: canvases,
        //canvasesTitle: this.estateSnapshotPrintableRender.getSectionsTitle(),
        resolved: {},
        pagesData: pagesData
      }).then(report => {
        saveAs(report.doc.output('blob'), `${cleanNameForPDF(clientsName)} - Estate Snapshot Report`);
        this.isLoadingPdf = false;
      })
    }catch(error){
      this.isLoadingPdf = false;
      console.log(error);
    }
  }

}

