import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-estate-member',
  templateUrl: './estate-member.component.html',
  styleUrls: ['./estate-member.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EstateMemberComponent implements OnInit {

  @Input() data: any;
  public relations;
  public relation;
  public name;
  public lastname;
  public gender;

  constructor() { }

  ngOnInit() {
      const { relation, gender, name, lastname } = this.data;
      let relations;
      if (this.data.relations) {
        relations = this.data.relations;
      }
      this.relation = relation;
      this.gender = gender;
      this.name = name;
      this.lastname = lastname;
  }
}
