//Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { utilsCalculateAge } from 'app/shared/helpers/utils';
import { environment } from 'environments/environment';

import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { getYearsArraySince } from '../HelperFunctions';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_PROSPECT,
  CONST_ANTICIPATECHANGESRETIREMENT,
  CONST_AUTOCOMPANIES,
  CONST_EMPLOYMENT_STATUS,
  CONST_GENDERS,
  CONST_GENDERS_V2,
  CONST_HEALTHCONDITIONRATE,
  CONST_HOME_OWNRENT,
  CONST_HOMEINSURANCECOMPANIES,
  CONST_INCOMEOPTIONS,
  CONST_INVESTMENTMANAGED,
  CONST_INVESTMENTMANAGEDCAHSFLOW,
  CONST_INVESTMENTOPTIONS,
  CONST_LIFESTYLE,
  CONST_MARRIAGESTATUS,
  CONST_STATES,
  CONST_INVESTMENTRATE,
  DEBIT,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { Prospect } from './prospect.model';
import { AdvisorService } from 'app/shared/services/advisor.service';

// Project
@Component({
  selector: 'app-q-prospect',
  templateUrl: './q-prospect.component.html',
  styleUrls: ['./q-prospect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QProspectComponent implements OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: Prospect;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any = {};
  public whoAnswered: string;
  public answers: Array<string>[] = [];
  public validations = Validations;
  public isAdvisor: boolean = false;
  public objectKeys = Object.keys;

  public ntabs: number;
  public progress: number;
  public progressnumber: number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;

  public advisorLogo: string;
  public advisorCompany: string;
  public bulletColor: string = '#C93939';
  public questUrl: string = '';
  public prospectAreas: string[] = [];
  public areasToShow: string[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_PROSPECT,CONST_INVESTMENTMANAGED, CONST_INCOMEOPTIONS, DEBIT, CONST_HEALTHCONDITIONRATE, CONST_LIFESTYLE, CONST_ANTICIPATECHANGESRETIREMENT, CONST_INVESTMENTMANAGEDCAHSFLOW, CONST_INVESTMENTOPTIONS, CONST_EMPLOYMENT_STATUS, CONST_GENDERS_V2, CONST_GENDERS, CONST_MARRIAGESTATUS, CONST_STATES, CONST_HOME_OWNRENT, CONST_HOMEINSURANCECOMPANIES, CONST_AUTOCOMPANIES, CONST_INVESTMENTRATE };
  public yearsList: number[] = getYearsArraySince(2000).reverse();
  public startDate = new Date(1980, 0, 1);
  public minDate:Date;
  public maxDate:Date;
  public advisorName: string = '';
  public prospectHash: string;
  public hasCustomQuestions : boolean;
  public customQuestions: any;

  public tempclientIDHouseOrApartment1: any = [];
  public tempclientIDInvestmentOptions: any = [];
  public tempclientIDInvestmentManageCashflow: any = [];
  public tempclientIDIncomeOptions: any = [];
  public tempclientIDAnticipateChangesRetirement: any = [];
  public tempclientIDRetirementAccountsList: any = [];
  public tempclientIDDebtList: any = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public authService: AuthenticationService,
    public qController: QuestionnaireController,
    public route: ActivatedRoute,
    private advisorService: AdvisorService
  ) { }

  async getInsuranceCompanies() {
    const ans: any = await this.advisorService.getInsuranceProviders(); 
    console.log(ans);
    this.CONSTANTS.CONST_HOMEINSURANCECOMPANIES = ans.map(({ showName }) => showName);
    this.CONSTANTS.CONST_AUTOCOMPANIES = ans.map(({ showName }) => showName);
  }

  async ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.whoAnswered = this.isAdvisor ? 'Advisor' : 'Client';

    //Initialize the questionnaire model
    this.model = new Prospect();

    this.minDate = new Date(1900, 1, 1);
    this.maxDate = new Date();

     // 1. Validate prospect hash id and retrieve data

    if (this.isAdvisor) {

      this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));


      // Load prospect areas *** NOT WORKING MUST SEE WITH BACKEND ***
      this.http.get<any>(environment.apiClientProfile + this.clientData.clientId).subscribe(response => {


        this.prospectAreas = Object.keys(response.areas);

        this.prospectAreas.forEach(area => {
          this.areasToShow.push(area);
        });
      });

      let localStoragedProspectData = JSON.parse(localStorage.getItem('prospectingPreferences'));
      // this.prospectAreas = Object.keys(localStoragedProspectData.leadPageAreas);

      //Load questionnarie data, if clientHasAnswered (The general quest)
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'general', this.model).then(response => {

        this.tempclientIDHouseOrApartment1 = this.model.clientIDHouseOrApartment1.split(',');
        this.tempclientIDInvestmentOptions = this.model.clientIDInvestmentOptions.split(',');
        this.tempclientIDInvestmentManageCashflow = this.model.clientIDInvestmentManageCashflow.split(',');
        this.tempclientIDIncomeOptions = this.model.clientIDIncomeOptions.split(',');
        this.tempclientIDAnticipateChangesRetirement = this.model.clientIDAnticipateChangesRetirement.split(',');
        this.tempclientIDRetirementAccountsList = this.model.clientIDRetirementAccountsList.split(',');
        this.tempclientIDDebtList = this.model.clientIDDebtList.split(',');
      });
      //Define the procesiing url
      this.questUrl = environment.prospectAdvisor + this.clientData.clientId + '/allareasquestionnaire';

      //Check if have custom questions
      Object.keys(localStoragedProspectData.leadPageCustomQuestions).length === 0 ? this.hasCustomQuestions = false : this.hasCustomQuestions = true;
      this.customQuestions = localStoragedProspectData.leadPageCustomQuestions;

    } else { // The prospect is answering the questionnaire



      let prospectHashId = this.route.snapshot.queryParamMap.get('cli');

      // Load prospect areas
      this.http.get<any>(environment.prospectAdvisor + prospectHashId).subscribe(prospectResponse => {
        // Case. The info isn't on localStorage. The user has accessed by a direct prospect url.
        if(!Boolean(localStorage.getItem('advisorResponse'))){
          //let advisorDataResponse = await this.http.get<any>(environment.prospectValidate + `?advisor=${prospectResponse.prospectJoinId}`).toPromise();
          // Save the response on local storage to be compatible with the next screens
          localStorage.setItem('advisorResponse', JSON.stringify(prospectResponse.advisorData));
          localStorage.setItem('prospectResponse', JSON.stringify({idClient: '', idHash: prospectHashId, clientIDFirstName: prospectResponse.clientIDFirstName, clientIDLastName: prospectResponse.clientIDLastName}));
        }

        let localAdvisorData = JSON.parse(localStorage.getItem('advisorResponse'));

        this.advisorLogo = localAdvisorData.companyLogo;
        this.advisorCompany = localAdvisorData.companyName;

        let areasFromStorage: string[] = prospectResponse.areas //JSON.parse(localStorage.getItem('areasToAnalyze'));

        areasFromStorage.map(area => {
          this.areasToShow.push(area);
        })


        this.advisorName = localAdvisorData.fullName;
        this.prospectHash = prospectHashId
        this.clientData['clientId'] = prospectHashId
        this.clientData['name'] = prospectResponse.clientIDFirstName;


        this.prospectAreas = Object.keys(localAdvisorData.leadPageAreas);


        //Questionnaire hasn't be answered


        //Check if have custom questions
        Object.keys(localAdvisorData.customQuestions).length === 0 ? this.hasCustomQuestions = false : this.hasCustomQuestions = true;
        this.customQuestions = localAdvisorData.customQuestions;

        // This assignment depends on the response data
        this.questUrl = environment.prospectAdvisor + 'allareasquestionnaire/' + this.clientData.clientId;

      }, //Prospect doesn't exist
        error => {
          console.log('No prospect match, redirect to no match or error page');
          this.router.navigate(['prospect/alert']);
        });

    }

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);

    //Autofill home questions if rental property is selected on Tax
    if(this.model.clientIDHasAnyOtherIncomeAsEmployee === 'Rental income' || this.model.clientIDHasAnyOtherIncomeAsSelfEmployed === 'Rental income' || this.model.clientIDHasAnyOtherIncomeAsUnemployed === 'Rental income') {
      this.model.clientIDHome = 'Yes'
      this.model.clientIDInvestmentProperty = 'Yes'
    }

  }


  calculateAge(DOB) {
    return utilsCalculateAge(DOB);
  }

  addChildren(): void {
    if(this.model.clientIDChildrenCount < 5){
      this.model.clientIDChildrenCount++;
    }
    //this.childrenCount++;

  }

  removeChildren(): void {
    if(this.model.clientIDChildrenCount > 0){
      this.model.clientIDChildrenCount--;
    }
    //this.childrenCount--;

  }

  addGrandChildren(): void {
    // this.grandChildrenCount++;
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

    this.modifySpecialCasesQuest();

    if(this.model.clientIDTotalNonDiscretionaryExpenses > 0){
      this.model.clientIDTotalDiscretionaryExpenses = 100 - this.model.clientIDTotalNonDiscretionaryExpenses;
    }

    if(this.model.clientIDOccupation == 'Self-employed'){
      this.model.clientIDHasABusiness = 'Yes';
    }
    if(this.model.clientIDMaritalStatus === 'Single') {
      this.model.clientIDFilingStatus = 'Single'
    }

    if(this.model.clientIDOccupation === 'Employed'){
      this.model.clientIDWorkingAndReceivingIncome = 'Yes'
    }

    if(this.model.clientIDChildren === 'Yes' && this.model.clientIDChildrenCount > 1) {
      this.model.clientIDMultipleChild = 'Yes'
    } else {
      this.model.clientIDMultipleChild = 'No'
    }

    this.model.clientIDBaseSalary = this.model.clientIDLastYearIncome;

    if(this.model.clientIDCarsOwned === 0) { this.model.clientIDClientDrivesAndDoesNotOwnCar = 'Yes'};

    this.model.clientIDHasABusiness === 'Yes' && this.model.clientIDSmallBusiness === 'None' ? this.model.clientIDExpensesMonthlyBasis = 'No' : this.model.clientIDExpensesMonthlyBasis = 'Yes';

    this.qController.throwConfirmationInteraction(
      'Please, confirm to submit the questionnaire.',
      () => {
        this.http.post(this.questUrl, this.model.toSaveFormat(this.whoAnswered), options).subscribe(response => {
          // If successfully saved:
            if (this.isAdvisor){
              this.router.navigate(['/advisor/dashboard']);
            } else {
              setTimeout(()=>{
                this.router.navigate(['prospect/submitted-success']);
              }, 2000);
            }
        },
          error => {
            console.log('top', 'UNEXPECTED ERROR', error);
          });
      })
  }

  countTabs(tabs: number) {
    this.progress = 100 / tabs;
  }

  nextTab(index: number) {
    this.model.progressTab = index + 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index: number) {
    this.model.progressTab = index - 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
    if (this.model.progressCompleted == true) {
      this.model.progressBar = 100;
    } else {
      this.model.progressBar = Math.round(tabChangeEvent.index * this.progress);
    }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  ngOnDestroy() {
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  factoryNumericArray(maxIndex = 1): number[] {
     return new Array(maxIndex).fill(true).map( (value, index) => index + 1)
  }

  modifySpecialCasesQuest(){
    this.model.clientIDHouseOrApartment1 = this.tempclientIDHouseOrApartment1.join();
    this.model.clientIDInvestmentOptions = this.tempclientIDInvestmentOptions.join();
    this.model.clientIDInvestmentManageCashflow = this.tempclientIDInvestmentManageCashflow.join();
    this.model.clientIDIncomeOptions = this.tempclientIDIncomeOptions.join();
    this.model.clientIDAnticipateChangesRetirement = this.tempclientIDAnticipateChangesRetirement.join();
    this.model.clientIDRetirementAccountsList = this.tempclientIDRetirementAccountsList.join();
    this.model.clientIDDebtList = this.tempclientIDDebtList.join();
  }
}
