import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../../../../environments/environment";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectionModel } from "@angular/cdk/collections";
import { IntegrationStepService } from "../../../../../../shared/services/integration/integration-step.service";

@Component({
  selector: "app-import-contacts",
  templateUrl: "./import-contacts.component.html",
  styleUrls: [
    "./import-contacts.component.scss",
    "../success-integration/success-integration.component.scss",
  ],
})
export class ImportContactsComponent implements OnInit {
  private timeout: any = "";
  public pageIndex: number = 0;
  public actualPageSize: number = 10;
  public searchInput = "";
  dataSource: any[] = [
    {
      _fpalpha: {
        isImported: false,
        firstName: "Fer",
        lastName: "Lopez",
        email: "mail@mail.com",
      },
    },
    {
      _fpalpha: {
        isImported: false,
        firstName: "Monn",
        lastName: "Lopez",
        email: "mail@mail.com",
      },
    },
    {
      _fpalpha: {
        isImported: false,
        firstName: "Esther",
        lastName: "Lopez",
        email: "mail@mail.com",
      },
    },
  ];
  public totalItems: number = 0;
  public contactsIDs: string[] = [];
  selection = new SelectionModel(true, []);
  public disableImportBtn: boolean = true;

  displayedColumns: string[] = [
    "select",
    "clientIDFirstName",
    "clientIDLastName",
    "email",
  ];

  constructor(
    private http: HttpClient,
    public snackBarError: MatSnackBar,
    public integrationStepService: IntegrationStepService
  ) {}

  ngOnInit(): void {}

  goBack() {
    this.integrationStepService
      .confirmLeaving()
      .afterClosed()
      .subscribe((res) => {
        this.changeStep("preferences");
      });
  }

  searchByInput() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      /*  this.applyFilters();
      clearTimeout(this.timeout); */
    }, 1000);
  }

  applyFilters() {
    this.pageIndex = 0;

    this.loadSalesforceClients();
  }

  loadSalesforceClients() {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };

    let query = {
      size: this.actualPageSize,
      page: this.pageIndex + 1,
    };

    if (this.searchInput.length > 0) {
      if (this.searchInput.includes("@")) {
        query["search"] = this.searchInput;
      } else {
        query["search"] = this.searchInput;
      }
    }

    const requestQuery = Object.keys(query)
      .map((item) => `${item}=${query[item]}`)
      .join("&");

    this.http
      .get<any>(
        `${environment.apiSalesforceGetClients}?${requestQuery}`,
        options
      )
      .subscribe(
        (success) => {
          this.dataSource = success.contacts;
          //Dummy data
          this.dataSource = [
            {
              select: false,
              clientIDFirstName: "Gio",
              clientIDLastName: "Less",
              email: "mail@mail.com",
            },
          ];
          this.totalItems = success.metadata.totalItems;
          this.loadSelectedRows();
        },
        (error) => {
          console.log("Error!!!");
          this.snackBarError.open(
            "Cannot retrieve contacts. " + error.error.message,
            "Dismiss",
            {
              duration: 9000,
              panelClass: "error-snackbar",
            }
          );
        }
      );
  }

  loadSelectedRows() {
    this.dataSource.forEach((row) => {
      if (this.contactsIDs.includes(row.id)) {
        this.selection.select(row);
      }
    });
  }

  addClient(row) {
    if (this.selection.selected.includes(row)) {
      this.selection.deselect(row);
      this.contactsIDs.splice(this.contactsIDs.indexOf(row.id), 1);
    } else {
      this.selection.select(row);
      this.contactsIDs.push(row.id);
    }

    if (this.contactsIDs.length === 0) {
      this.disableImportBtn = true;
    } else {
      this.disableImportBtn = false;
    }
  }

  changeStep(step: string) {
    this.integrationStepService.changeStep(step);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }
}
