import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';

import { Questionnaire } from '../Questionnaire.model';

export interface FullValidationFlag{
  keyFlag: string,
  url: any,
  isActive: boolean,
  metadata: {description:string, line: string, lineNextYear?: string} | any
}

export class taxfull_questions extends Questionnaire {


  // *** Questionnaire header *** //
  public clientIDTaxYear: string = '2021';
  public clientIDFillingStatus: string= "Single";
  public clientIDWantsToInputTaxData: string = 'No';
  public clientIDUploadTaxReturnOnly: string = 'No';

  // --> Uploaded documents
  public clientIDFulltaxPolicy2019: string = '';
  public clientIDFulltaxPolicy2020: string = '';
  public clientIDFulltaxPolicy2021: string = '';

  //public clientIDFulltaxPolicy2019Status: string = '';
  //public clientIDFulltaxPolicy2020Status: string = '';

  // --> Type of analysis
  public clientIDTypeOfAnalysis: string = 'Instant tax analysis';

  // --> Derived from extractions
  public scheduleCDetected: string = '';
  public taxableIRAdistForRoth: number = 0;
  public passiveIncomeK1: string = '';

  // *** Progress bar *** //
  public progressBar: number = 0;
  public progressCompleted: boolean = false;
  public progressTab: number = 0;

  // *** Extractions *** //                         // 2019  -----  2020
  // --> Form 1040
  public clientHasForm1040: string = '';           // Check if sch exists
  public wages: number = 0;                         // Line 1
  public taxExemptInterest: number = 0;             // Line 2a
  public taxInterest: number = 0;                   // Line 2b
  public qualifiedDividends: number = 0;            // Line 3a
  public totalDividends: number = 0;                // Line 3b
  public iraDistribution: number = 0;               // Line 4a
  public taxableIRA: number = 0;                    // Line 4b
  public pension: number = 0;                       // Line 4c      Line 5a
  public taxablePension: number = 0;                // Line 4d      Line 5b
  public socialSecurityBenefits: number = 0;        // Line 5a      Line 6a
  public socialSecurity: number = 0;                // Line 5b      Line 6b
  public capitalGainOrLoss: number = 0;             // Line 6       Line 7
  public hasTotalIncome: string = '';               // Check if has line
  public totalIncome: number = 0;                   // Line 7b      Line 9
  public otherIncome: number = 0;
  public adjustedIncome: number = 0;                // Line 8a      Line 10c
  public adjustedGrossIncome: number = 0;           // Line 8b      Line 11
  public standardDeductions: number = 0;            // Line 9       Line 12
  public qbIncome: number = 0;                      // Line 10      Line 13
  public taxableIncome: number = 0;                 // Line 11b     Line 15
  public taxes: number = 0;                         // Line 12a     Line 16
  public totalTax: number = 0;                      // Line 16      Line 24
  public charitableContributions1040: number = 0;   // Line 12b
  public sumStandardAndCharitable: number = 0
  public sumDeductions: number = 0
  public line17_1040: number = 0
  public line18_1040: number = 0
  public qualifyingDependentCreditClaimed: number = 0
  public line20_1040: number = 0
  public line21_1040: number = 0
  public line22_1040: number = 0
  public otherTaxesWithSelfEmployment: number = 0
  //Added Aug 8 2022 for tax projections
  public federalIncomeTaxWithheld: number = 0;                  // Line 25d
  public estimatedTaxPaymentsAmountForLastYear: number = 0;     // Line 26
  public earnedIncomeCredit: number = 0;                        // Line 27
  public additionalChildTaxCredit: number = 0;                  // Line 28
  public americanOpportunityCreditTaken: number = 0;            // Line 29
  public recoveryRebateCredit: number = 0;                      // Line 30
  public amountfromSchedule3line15: number = 0;                 // Line 31
  public totalOtherPayments: number = 0;                        // Line 32
  public totalPayments: number = 0;                             // Line 33

  // --> Schedule 1
  public clientHasSchedule1: string = '';           // Check if sch exists
  public alimonyReceived: number = 0;               // Line 2a
  public businessIncome: number = 0;                // Line 3
  public rentalRealEstate: number = 0;              // Line 5
  public healthSavings: number = 0;                 // Line 12
  public selfEmployedSEP: number = 0;               // Line 15
  public alimonyPaid: number = 0;                   // Line 18a
  public iraDeduction: number = 0;                  // Line 19
  public studentLoanInterestDeduction: number = 0;  // Line 20
  public adjustmentToIncome: number = 0;            // Line 22

  // --> Schedule 3
  public clientHasSchedule3: string = '';           // Check if sch exists
  public creditForChildCareTF: string = '';         // Check if has line
  public retiramentSavings: number = 0;
  public creditForChildCare: number = 0;            // Line 2
  public educationCredits: number = 0;              // Line 3
  public energyCredits: number = 0;                 // Line 5
  public estimatedTax: number = 0;                  // Line 8       Line ?

  // --> Schedule A
  public clientHasScheduleA: string = '';           // Check if sch exists
  public medicalExpenses: number = 0;               // Line 1
  public medicalExpenses2: number = 0;              // Line 3
  public medicalExpenses3: number = 0;              // Line 4
  public taxesYouPaid5d: number=0;                  // Line 5d      Line ?
  public taxesYouPaid: number = 0;                  // Line 7
  public investmentInterest: number = 0;            // Line 9
  public interestYouPaid: number = 0;               // Line 10
  public giftsByCash: number = 0;                   // Line 11
  public giftsByLand: number = 0;                   // Line 12
  public giftsToCharityTF: string = '';             // Check if has line
  public giftsToCharity: number = 0;                // Line 14
  public casualtyLosses: number = 0;                // Line 15
  public summationsItemizedDeductions: number = 0;  // Line 17
  //Added Aug 8 2022 for tax projections
  public taxesYouPaid5a: number = 0;                // Line 5
  public taxesYouPaid5b: number = 0;                // Line 5b
  public taxesYouPaid5c: number = 0;                // Line 5c
  public taxesYouPaid5e: number = 0;                // Line 5e
  public mortgageInterests: number = 0;             // Line 8a
  public mortgageInterestsNotReported: number = 0;  // Line 8b
  public pointsNotReported: number = 0;             // Line 8c
  public mortgageInsurancePremiums: number = 0;     // Line 8d
  public sumOfLines8: number = 0;                   // Line 8e

  // --> Schedule C
  // public clientHasScheduleC: string = '';           // Check if sch exists
  // public contractLabor: number = 0;;
  // public employeeBenefit: number = 0;;
  // public wagesLessEmployeeCredits: number = 0;;
  // public expensesForHomeBusinessTF: string = '';    // Check if has line
  // public expensesForHomeBusiness: number = 0;       // Line 30
  // public netProfitOrLoss: number = 0;               // Line 31


  // --> Schedule C v2.0
                                                // LINE
  public grossReceipts: number = 0;             // 1
  public returns: number = 0;                   // 2
  public line3SchC: number = 0;                 // 3
  public costOfGoods: number = 0;               // 4
  public grossProfit: number = 0;               // 5
  public otherIncomeSchC: number = 0;           // 6
  public grossIncome: number = 0;               // 7
  public advertising: number = 0;               // 8
  public carExpenses: number = 0;               // 9
  public commissions: number = 0;               // 10
  public contractLabor: number = 0;             // 11
  public depletion: number = 0;                 // 12
  public depreciation: number = 0;              // 13
  public employeeBenefit: number = 0;           // 14
  public insurance: number = 0;                 // 15
  public interestMortgage: number = 0;          // 16a
  public interestOther: number = 0;             // 16b
  public legal: number = 0;                     // 17
  public officeExpenses: number = 0;            // 18
  public pensionPlans: number = 0;              // 19
  public vehiclesMachinery: number = 0;         // 20a
  public otherBusinessProperty: number = 0;     // 20b
  public repairs: number = 0;                   // 21
  public supplies: number = 0;                  // 22
  public taxesAndLicenses: number = 0;          // 23
  public travel: number = 0;                    // 24a
  public meals: number = 0;                     // 24b
  public utilities: number = 0;                 // 25
  public wagesLessEmployeeCredits: number = 0;  // 26
  public otherExpenses_schC: number = 0;        // 27a
  public totalExpenses: number = 0;             // 28
  public tentativeProfit: number = 0;           // 29
  public expensesForHomeBusiness: number = 0;   // 30
  public netProfitOrLoss: number = 0;           // 31
  public expensesForHomeBusinessTF: string = '';
  public clientHasScheduleC: string = '';

  // --> Schedule D
  public shortTermLossCarryover: number = 0;
  public clientHasScheduleD: string = '';
  public longTermLossCarryover: number = 0;         // Check if sch exists
  public netShortTermCapitalGains: number = 0;      // Line 7
  public netLongTermCapitalGains: number = 0;       // Line 15

  // --> Other
  public clientHasScheduleE: string = '';
  public clientHasScheduleSE: string = '';
  public clientHas1040Box6c: string =  '';
  public clientHasForm1120: string = '';
  public clientHasForm1120S: string = '';
  public clientHasForm2441: string = '';
  public line3form2441: number = 0;
  public clientHasForm5695: string = '';
  public clientHasForm6251: string = '';
  public clientHasForm8863: string = '';
  public clientHasForm8839: string = '';

  // --> Legacy extractions
  public scheduleSELine2:string = '';
  public clientHasSchedALine1Q1:string= '';
  public clientHasSchedALine1Q3:string= '';
  public clientHasForm1040Line29:string= '';
  public clientHasForm1040SchedCLine9PartIVForm4562:string= '';

  // *** Extraction flags ***
  // reference1: {answer: "Yes,schedule1.jpg", whoAnswered: "Advisor", type: "string"}
  // reference2: {answer: "No,url", whoAnswered: "Advisor", type: "string"}
  public flag_livingState:string= '';
  public flag_wages:string= '';
  public flag_taxExemptInterest:string= '';
  public flag_taxInterest:string= '';
  public flag_qualifiedDividends:string = '';
  public flag_totalDividends:string = '';
  public flag_taxableIRA:string = '';
  public flag_taxablePension:string = '';
  public flag_socialSecurityBenefits:string = '';
  public flag_socialSecurity:string = '';
  public flag_iraDistribution:string = '';
  public flag_pension:string = '';
  public flag_capitalGainOrLoss:string = '';
  public flag_totalIncome:string = '';
  public flag_adjustedIncome:string = '';
  public flag_adjustedGrossIncome:string = '';
  public flag_standardDeductions:string = '';
  public flag_qbIncome:string = '';
  public flag_taxableIncome:string = '';
  public flag_taxes:string = '';
  public flag_totalTax:string = '';
  public flag_medicalExpenses:string = '';
  public flag_medicalExpenses2:string = '';
  public flag_medicalExpenses3:string = '';
  public flag_taxesYouPaid5d:string = '';
  public flag_investmentInterest:string = '';
  public flag_interestYouPaid:string = '';
  public flag_giftsToCharity:string = '';
  public flag_casualtyLosses:string = '';
  public flag_summationsItemizedDeductions:string = '';
  public flag_netProfitOrLoss:string = '';
  public flag_netShortTermCapitalGains:string = '';
  public flag_netLongTermCapitalGains:string = '';
  public flag_alimonyReceived:string = '';
  public flag_businessIncome:string = '';
  public flag_rentalRealEstate:string = '';
  public flag_healthSavings:string = '';
  public flag_selfEmployedSEP:string = '';
  public flag_alimonyPaid:string = '';
  public flag_iraDeduction:string = '';
  public flag_studentLoanInterestDeduction:string = '';
  public flag_adjustmentToIncome:string = '';
  public flag_creditForChildCare:string = '';
  public flag_educationCredits:string = '';
  public flag_energyCredits:string = '';
  public flag_estimatedTax:string = '';
  public flag_otherIncome:string = '';
  public flag_taxesYouPaid: string = '';
  public flag_scheduleb_2: string = '';
  public flag_scheduleb_4: string = '';
  public flag_scheduleb_6: string = '';
  public flag_scheduleB_1: string = ''; // Activates the flags subcomponent
  public flag_scheduleB_5: string = ''; // Activates the flags subcomponent
  public flag_shortTermLossCarryover: string = '';

  public flag_additionalChildTaxCredit: string = '';
  public flag_amountfromSchedule3line15: string = '';
  public flag_totalPayments: string = '';

  public flag_advertising: string = '';
  public flag_carExpenses: string = '';
  public flag_legal: string = '';






  // *** Questionnaire tabs *** //
  // --> IRA
  public clientIDIfIsSelfEmployedOrBusinessOwnerAndExpectsIncomeOf200000ForNextThreeYears: string= '';
  public clientIDContributingToSEPIRA: string= '';

  // --> Income
  public clientIDIncomeExpectationsFollowingYear: string= '';
  public clientIDExpectsToHaveLargerIncomeGoingForward: string= '';
  public clientIDExpectsToHaveWayLowerIncomeGoingForward: string= '';
  public clientID2018Income: number = 0;
  public clientIDIncomeREITorQPTP: string = '';
  public clientIDIncomeREITorQPTP_2: string = '';
  /// -> Internal use only no rec triggered
  public clientIDHasQuestionsAboutTaxRecordRetention: string = '';
  public clientIDOwnAnyStocks: string = '';

  // --> Business Owner
  public clientPrincipalAssetIsEmpoyees: string =  '';
  public clientIDBusinessProvideProfessionalConsultingServices: string = '';
  public clientIDWorksAsAProfessionalInYourOwnSCorporation: string = '';
  public clientIDWorksInOwnBusinessWhichRetainsIncome: string = '';
  public clientIDEmployerDomestic: string = '';
  public clientIDSelfEmployedOrSCorp: string = '';
  public clientIDSelfEmployedOrSCorp_2: string = '';
  public clientIDBusinessProvidesMoreThan1OtherBusinessActivity: string = '';
  public clientIDValuableAssetsToSellIn5YearsOrMore: string = '';
  public clientIDFormingBusinessLeverageRealEstate: string = '';
  public clientIDFormingInvestorsCTrustForeign: string = '';
  public clientIDFutureExpectedLosses: string = '';
  public clientIDHomeOfficeSqft: string = '';
  public clientIDHomeOffice: string = '';
  public clientIDClubOrg: string = '';
  public clientIDMantainRetPlanWithholdWage: string = '';
  public clientIDSelfEMployedAlone: string = '';
  public clientIDExpensesMealsEntertain: string = '';
  public clientIDHiredEmployees: string = '';
  public clientIDIncomeProfession: string = '';
  public clientIDCapitalizingBusiness: string = '';
  public clientIDCCorpCapitalizingBusiness: string = '';
  public clientIDCarPersonalBusiness: string = '';
  public clientIDExpenseCarriedFuture: string = '';
  /// -> New questions Cares act (Aug 10 2020 - RT)
  public clientIDEmployeePayrollTaxes: string = '';
  public clientIDEmployeeCovidExpenses: string = '';
  public clientIDEmployeeCovidWages: string = '';

  // --> Charity and gifts
  public clientIDHasQuestionsAboutCharitableGifting: string = ''; /// -> Internal use only no rec triggered
  public clientIDWillingToDonate5000: string = '';
  public clientIDCharitableGiftsTicketsorMembership: string = '';
  public clientIDHouseOrFarmToCharity: string = '';
  public clientIDGiftToPayEducation: string = '';
  public clientIDGiftToDisabled: string = '';
  public clientIDFinanciallySupportsToAdultWhoIsOver18: string= '';
  public clientIDFinanciallySupportsToAdultWhoIsOver18_2: string =  '';
  public clientID2020ItemizedCharitableDonations: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)
  public clientIDSignificantCharitableDonations: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)

  // --> Investment
  public clientIDShortTermCapGainAndUnrealizedLosses: string = '';
  public clientIDShortTermCapGainAndUnrealizedLosses_2: string = '';
  public clientIDNetLossesCapGainLosses: string = '';
  public clientIDNetLossesCapGainLosses_2: string = '';
  public clientIDHasUnrealizedLosses: string = '';
  public clientIDHasUnrealizedLosses_2: string = '';
  public clientIDSellAtLoss: string = '';
  public clientIDSellAtLoss_2: string = '';
  public clientIDLongTimeHorizon: string = '';
  public clientIDLongTimeHorizon_2: string = '';
  public clientIDHasOldTradeBusinessStock: string = '';
  public clientIDHasOldTradeBusinessStock_2: string = '';
  public clientIDLittleValueNonLiquidAssets: string = '';
  public clientIDLittleValueNonLiquidAssets_2: string = '';
  public clientHasForm1040SchedCLineG: string = '';
  public clientIDHasForm1040SchedCLineG_2: string = '';
  public clientIDHasInvestmentIncome: string = '';
  public clientIDHasInvestmentIncome_2: string = '';
  public clientIDInvestmentInterestExpenseCarryover: string = '';
  public clientIDInvestmentInterestExpenseCarryover_2: string = '';
  public clientIDPurchaseInvestmentNotSold: string = '';
  public clientIDPurchaseInvestmentNotSold_2: string = '';
  public clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture: string= '';
  public clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture_2: string =  '';

  // --> Employer
  public clientIDEmployerDependentCareBenefit: string = '';
  public clientIDEmployerDependentCareBenefit_2: string = '';
  public clientIDEmployerHealthCareBenefit: string = '';
  public clientIDEmployerHealthCareBenefit_2: string = '';
  public clientIDEmployerFSA: string = '';
  public clientIDEmployerFSA_2: string = '';
  public clientIDEmployerProvidesStockOptions: string = ''; /// -> Internal use only no rec triggered
  public clientIDStocOptCompPack: string = '';
  public clientIDStocOptCompPack_2: string = '';
  public clientIDStockOptCorpEmployer: string = '';
  public clientIDStockOptCorpEmployer_2: string = '';
  public clientIDISOEmployer: string = '';
  public clientIDISOEmployer_2: string = '';
  public clientIDExerciseISO: string = '';
  public clientIDExerciseISO_2: string = '';
  public clientIDExcersiceISOPrior: string = '';
  public clientIDExcersiceISOPrior_2: string = '';
  public clientIDTerminateEmployISO: string = '';
  public clientIDTerminateEmployISO_2: string = '';
  public clientIDVestProperty: string = '';
  public clientIDVestProperty_2: string = '';
  public clientIDIndependentContractorISO: string = '';
  public clientIDIndependentContractorISO_2: string = '';
  public clientIDIsOnMedicare: string= '';
  public clientIDIsOnMedicare_2: string= '';
  public clientIDHasOptionToChooseHighDeductibleMedicalPlan: string= '';
  public clientIDHasOptionToChooseHighDeductibleMedicalPlan_2: string= '';
  public clientIDIsOnHighDeductibleMedicanPlan: string= '';
  public clientIDIsOnHighDeductibleMedicanPlan_2: string =  '';
  public clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount: string= '';
  public clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount_2: string =  '';

  // --> Retirement
  public clientIDAboutToRetire: string = '';
  public clientIDAboutToRetire_2: string = '';
  public clientIDHasAccessToWorkplaceRetirementPlan: string= '';
  public clientIDHasAccessToWorkplaceRetirementPlan_2: string= '';
  public clientIDIsMaximizingContributions: string= '';
  public clientIDIsMaximizingContributions_2: string= '';
  public clientIDHasOrSpouseAccessToRoth401k: string= '';
  public clientIDPlanPermitsToMakeAfterTaxContributionsTo401k: string= '';
  public clientIDPlanPermitsToMakeAfterTaxContributionsTo401k_2: string= '';
  public clientIDHasAccessToFlexibleSpendingAccount: string= '';
  public clientIDHasAccessToFlexibleSpendingAccount_2: string =  '';
  public clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses: string= '';
  public clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses_2: string =  '';
  public clientIDWorksForNonProfitOrGovernmentalEntity: string= '';
  public clientIDWorksForNonProfitOrGovernmentalEntity_2: string =  '';
  public clientIDHasAccessTo457Plan: string= '';
  public clientIDHasAccessTo457Plan_2: string =  '';
  public clientIDIsMaximizingContributionsOn457Plan: string= '';
  public clientIDIsMaximizingContributionsOn457Plan_2: string =  '';
  public clientIDHasTraditionalOrRolloverIRA: string= '';
  public clientIDHasTraditionalOrRolloverIRA_2: string =  '';
  public clientIDWithdrawNoPenalty: string = '';
  public clientIDWithdrawNoPenalty_2: string = '';
  public clientIDDistPrincipalResidence: string = '';
  public clientIDDistPrincipalResidence_2: string = '';
  public clientIDDistMedExpenses: string = '';
  public clientIDDistMedExpenses_2: string = '';
  public clientIDDistHealthInsurance: string = '';
  public clientIDDistHealthInsurance_2: string = '';
  public clientIDDistEducExpense: string = '';
  public clientIDDistEducExpense_2: string = '';
  public clientIDTakeDist: string = '';
  public clientIDTakeDist_2: string = '';
  public clientID70WorkNoOwner: string = '';
  public clientID70WorkNoOwner_2: string = '';
  public clientIDExpensesIRA: string = '';
  public clientIDExpensesIRA_2: string = '';
  public clientIDBorrowFromRetPlan: string = '';
  public clientIDBorrowFromRetPlan_2: string = '';
  public clientIDConsideringContributionIRA: string = '';
  public clientIDConsideringContributionIRA_2: string = '';
  public clientIDIncomeHighToRothIRA: string = '';
  public clientIDIncomeHighToRothIRA_2: string = '';
  public clientIDContributionNonWorkingSpouseIRA: string = '';
  public clientIDContributionNonWorkingSpouseIRA_2: string = '';
  public clientIDIRAInvestCollectibles: string = '';
  public clientIDIRAInvestCollectibles_2: string = '';
  public clientIDIRAInvestMasterLtdPartner: string = '';
  public clientIDIRAInvestMasterLtdPartner_2: string = '';
  public clientIDTrusteeOwnRetPlan: string = '';
  public clientIDTrusteeOwnRetPlan_2: string = '';
  public clientIDIRAInvestIliquidAssets: string = '';
  public clientIDIRAInvestIliquidAssets_2: string = '';
  public clientIDSEPSameRulesPSP: string = '';
  public clientIDSEPSameRulesPSP_2: string = '';
  public clientIDSetRAForYearEnded: string = '';
  public clientIDSetRAForYearEnded_2: string = '';
  public clientIDDistDeathDisab: string = '';
  public clientIDDistDeathDisab_2: string = '';
  public clientIDRMDRetPlan: string = ''; /// -> New question Cares act (Aug 10 2020 - RT)
  public clientIDHasAccessTo401KPlan: string = '';

  // --> Social Security
  public clientIDPayMedicare: string = '';
  public clientIDPayMedicare_2: string = '';
  public clientIDMoreMinimumMedicare: string = '';
  public clientIDMoreMinimumMedicare_2: string = '';
  public clientIDOutBD: string = '';
  public clientIDOutBD_2: string = '';
  public clientIDWorkProvideMedicare: string = '';
  public clientIDWorkProvideMedicare_2: string = '';
  public clientIDSSBenDefer: string = '';
  public clientIDSSBenDefer_2: string = '';

  // --> Family
  public clientIDChildrenCreditAllowed: string = '';
  public clientIDChildrenCreditAllowed_2: string = '';
  public clientIDSellingExchangeProperty: string = '';
  public clientIDSellingExchangeProperty_2: string = '';
  public clientIDQualifyingExpensesIncurred: string = '';
  public clientIDQualifyingExpensesIncurred_2: string = '';
  public clientIDNewChildCredit2000: string = '';
  public clientIDNewChildCredit2000_2: string = '';
  public clientIDIncomeNetInvestmentTaxLowChildIncome: string = '';
  public clientIDIncomeNetInvestmentTaxLowChildIncome_2: string = '';
  public clientIDSavingsLowRateChildHighInterest: string = '';
  public clientIDSavingsLowRateChildHighInterest_2: string = '';
  public clientIDHaveBusinessChildWOrk: string = '';
  public clientIDHaveBusinessChildWOrk_2: string = '';
  public clientIDSelfEmployChildAbleWork: string = '';
  public clientIDSelfEmployChildAbleWork_2: string = '';

  // --> Children
  public clientIDHasAccessThroughEmployerToDependentCareAccount: string= '';
  public clientIDHasAccessThroughEmployerToDependentCareAccount_2: string =  '';
  public clientIDIsMaximizzingContributionsDependentCareAccount: string= '';
  public clientIDIsMaximizzingContributionsDependentCareAccount_2: string =  '';

  // --> Tuition
  public clientIDWillBePayingForTuitionOfChildrenOrGrandchildren: string= '';
  public clientIDWillBePayingForPrivateTuitionOfChildrenOrGrandchildren: string= '';
  public clientIDIsTheirTuitionFundedFullyFundedThrough529PlanAccounts: string= '';
  public clientIDHasMoneyBeenContributedTo529PlanToFundUpTo10000AYearOfExpenses: string= '';

  // --> Lending
  public clientIDPayStudentForDeductionJointly: string = '';
  public clientIDPayStudentForDeductionJointly_2: string = '';
  public clientIDNotgiftButLoan: string = '';
  public clientIDNotgiftButLoan_2: string = '';
  public clientIDLoanForResidence: string = '';
  public clientIDLoanForResidence_2: string = '';
  public clientIDForgiveLoanAsGift: string = '';
  public clientIDForgiveLoanAsGift_2: string = '';
  public clientIDPayStudentLoanInterestsOtherIncurred: string = '';
  public clientIDPayStudentLoanInterestsOtherIncurred_2: string = '';
  public clientIDInterestDebtBuyInv: string = '';
  public clientIDInterestDebtBuyInv_2: string = '';
  public clientIDBorrowInvestPersonal: string = '';
  public clientIDBorrowInvestPersonal_2: string = '';
  public clientIDBorrowBuySecuritiesInvestLtdPart: string = '';
  public clientIDBorrowBuySecuritiesInvestLtdPart_2: string = '';
  public clientIDInvestmentIncomePayPersonalDebt: string = '';
  public clientIDInvestmentIncomePayPersonalDebt_2: string = '';
  public clientIDInterestExpenseInvestmentMunicipal: string = '';
  public clientIDInterestExpenseInvestmentMunicipal_2: string = '';
  public clientIDBadDebtLosses: string = '';
  public clientIDBadDebtLosses_2: string = '';

  // --> Other
  public clientIDDoYouHaveAnInterestInRentalProperty: string = '';
  public clientIDWorthlessInvestments: string = '';
  public clientIDSpouseUnknowledgeableOtherIncomeOrReporting: string = '';
  public clientIDSpouseUnknowledgeableOtherIncomeOrReporting_2: string = '';
  public clientIDMoveToLowerTaxState: string = '';
  public clientIDABLEAccountAGI: string = '';
  public clientIDBuyElectirCar: string = '';
  public clientIDSubjectToAMT: string = '';
  public clientIDTheftCasualtyLosses: string = '';
  public clientIDPayTaxPreparationPlanning: string = '';
  public clientIDUSInvestmentIncomeLimitedIncome: string = '';
  public clientIDSeparateMAGI: string = '';
  public clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS: string = ''; /// -> Internal use only no rec triggered
  public clientIDForeignResident: string = '';
  public clientIDIncomeForeignAssets: string = '';
  public clientIDForeignBalance: string = '';
  public clientIDMarriedAlien: string = '';
  public clientIDImproveNoResidential: string = '';
  public clientIDInvestmentExpenses: string = '';
  public clientIDHasLTMedInsurance: string = '';

  // --> Advisor section
  public advisorIDYearToDateRealizedGains: number = 0;
  public clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis: string= '';
  public clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis_2: string =  '';
  public clientIDRealizedLongTermCapitalGains: number = 0;
  public advisorIDUnrealizedLongTermCapitalGains: string= '';
  public advisorIDUnrealizedLongTermCapitalGains_2: string =  '';
  public advisorIDUnrealizedLongTermCapitalGainsAmount: number = 0;
  public advisorIDUnrealizedLongTermCapitalLosses: string= '';
  public advisorIDUnrealizedLongTermCapitalLosses_2: string =  '';
  public advisorIDClientsHasSeparatelyManagedTaxableInvAccount: string= '';
  public advisorIDClientsHasSeparatelyManagedTaxableInvAccount_2: string =  '';
  public advisorIDOneSpouseWorks: string= '';
  public advisorIDClientHasSubstantialPortfolioLargerThanNeeds: string= '';
  public advisorIDClientHasSubstantialPortfolioLargerThanNeeds_2: string =  '';
  public clientIDDualResident: string = '';
  public clientIDResidentAlien: string = '';
  public advisorIDDoesClientHasMunicipalBonds: string= '';
  public advisorIDDoesClientHasMunicipalBonds_2: string =  '';
  public clientIDBusinessNY: string = '';
  public clientIDCorporationqualifiesAsSCorp: string = '';
  public clientIDEmployeeBenefitsAsC: string = '';
  public clientIDLossesWagesControlledCorp: string = '';
  public clientIDPersonalPropertyBusiness: string = '';
  public clientIDBusinessMaintainInventory: string = '';
  public clientIDLineBusiness: string = '';
  public clientIDNOLCarryover: string = '';
  public clientIDRDExpenses: string = '';
  public clientIDOperatesBusinessTaxedAsCCorpAndNotLikelyToBeInHighTaxBraket: string = '';
  public clientIDPartnerOfLLCAndReceiveAGuaranteedPaymentForServices: string = '';
  public clientIDBuyingAssetsUnincorporatedBusiness: string = '';
  public clientIDPartnershipsDepreciate: string = '';
  public clientIDBusinessLoss: string = '';
  public clientIDRelatedLendBorrow: string = '';

  // --> Stranded
  public clientIDOtherThanHouseOrFarmToCharity: string = '';

  // --> Schedule B
  public clientHasScheduleB: string = '';

  // Schedule B. Common
  public scheduleb_2: number = 0;
  public scheduleb_3: number = 0;
  public scheduleb_4: number = 0;
  public scheduleb_6: number = 0;

  //Schedule B. Part I

  public scheduleb_1_0_text: string = '';
  public scheduleb_1_0_amount: number = 0;
  public scheduleb_1_1_text: string = '';
  public scheduleb_1_1_amount: number = 0;
  public scheduleb_1_2_text: string = '';
  public scheduleb_1_2_amount: number = 0;
  public scheduleb_1_3_text: string = '';
  public scheduleb_1_3_amount: number = 0;
  public scheduleb_1_4_text: string = '';
  public scheduleb_1_4_amount: number = 0;
  public scheduleb_1_5_text: string = '';
  public scheduleb_1_5_amount: number = 0;
  public scheduleb_1_6_text: string = '';
  public scheduleb_1_6_amount: number = 0;
  public scheduleb_1_7_text: string = '';
  public scheduleb_1_7_amount: number = 0;
  public scheduleb_1_8_text: string = '';
  public scheduleb_1_8_amount: number = 0;
  public scheduleb_1_9_text: string = '';
  public scheduleb_1_9_amount: number = 0;
  public scheduleb_1_10_text: string = '';
  public scheduleb_1_10_amount: number = 0;
  public scheduleb_1_11_text: string = '';
  public scheduleb_1_11_amount: number = 0;
  public scheduleb_1_12_text: string = '';
  public scheduleb_1_12_amount: number = 0;
  public scheduleb_1_13_text: string = '';
  public scheduleb_1_13_amount: number = 0;
  public scheduleb_1_14_text: string = '';
  public scheduleb_1_14_amount: number = 0;


  //Schedule B. Part II

  public scheduleb_5_0_text: string = '';
  public scheduleb_5_0_amount: number = 0;
  public scheduleb_5_1_text: string = '';
  public scheduleb_5_1_amount: number = 0;
  public scheduleb_5_2_text: string = '';
  public scheduleb_5_2_amount: number = 0;
  public scheduleb_5_3_text: string = '';
  public scheduleb_5_3_amount: number = 0;
  public scheduleb_5_4_text: string = '';
  public scheduleb_5_4_amount: number = 0;
  public scheduleb_5_5_text: string = '';
  public scheduleb_5_5_amount: number = 0;
  public scheduleb_5_6_text: string = '';
  public scheduleb_5_6_amount: number = 0;
  public scheduleb_5_7_text: string = '';
  public scheduleb_5_7_amount: number = 0;
  public scheduleb_5_8_text: string = '';
  public scheduleb_5_8_amount: number = 0;
  public scheduleb_5_9_text: string = '';
  public scheduleb_5_9_amount: number = 0;
  public scheduleb_5_10_text: string = '';
  public scheduleb_5_10_amount: number = 0;
  public scheduleb_5_11_text: string = '';
  public scheduleb_5_11_amount: number = 0;
  public scheduleb_5_12_text: string = '';
  public scheduleb_5_12_amount: number = 0;
  public scheduleb_5_13_text: string = '';
  public scheduleb_5_13_amount: number = 0;
  public scheduleb_5_14_text: string = '';
  public scheduleb_5_14_amount: number = 0;

  // --> NO general quest
  public filingStatus: string = '';
  public livingState: string = '';
  public hasChildren: string = '';
  public clientHasForm8880: string = '';
  public clientHasForm1099: string = '';

  // Form 8995
  public clientHasForm8995: string = '';           // Check if sch exists
  public totalReit: number = 0;

  // Form 5498
  public clientHasForm5498: string = '';           // Check if sch exists
  public contributionsMade: number = 0;
  public currentContributionToWorkplaceRetirementPlanSEP: number = 0;
  public currentContributionToWorkplaceRetirementPlanSIMPLE: number = 0;

  // Other from extractions
  public isFormSR: string = '';

  // *** Extractions aux data *** //
  public ignoreExtractionsData: boolean = false;

  // Metadata for the front
  public allFlagsValidatedByUser: boolean = false;


  public getExtractionsAtributes(includeFlags: boolean = true): string[] {
    let extractions = [
        //-->Form1040
        'clientHasForm1040',
        'wages',
        'taxExemptInterest',
        'taxInterest',
        'qualifiedDividends',
        'totalDividends',
        'iraDistribution',
        'taxableIRA',
        'pension',
        'taxablePension',
        'socialSecurityBenefits',
        'socialSecurity',
        'capitalGainOrLoss',
        'hasTotalIncome',
        'totalIncome',
        'adjustedIncome',
        'adjustedGrossIncome',
        'standardDeductions',
        'qbIncome',
        'taxableIncome',
        'taxes',
        'totalTax',
        'otherIncome',
        'charitableContributions1040',
        'sumStandardAndCharitable',
        'sumDeductions',
        'line17_1040',
        'line18_1040',
        'qualifyingDependentCreditClaimed',
        'line20_1040',
        'line21_1040',
        'line22_1040',
        'otherTaxesWithSelfEmployment',
        //
        'federalIncomeTaxWithheld',
        'estimatedTaxPaymentsAmountForLastYear',
        'earnedIncomeCredit',
        'additionalChildTaxCredit',
        'americanOpportunityCreditTaken',
        'recoveryRebateCredit',
        'amountfromSchedule3line15',
        'totalOtherPayments',
        'totalPayments',

        //-->Schedule1
        'clientHasSchedule1',
        'alimonyReceived',
        'businessIncome',
        'rentalRealEstate',
        'healthSavings',
        'selfEmployedSEP',
        'alimonyPaid',
        'iraDeduction',
        'studentLoanInterestDeduction',
        'adjustmentToIncome',

        //-->Schedule3
        'clientHasSchedule3',
        'creditForChildCareTF',
        'retiramentSavings',
        'creditForChildCare',
        'educationCredits',
        'energyCredits',
        'estimatedTax',

        //-->ScheduleA
        'clientHasScheduleA',
        'medicalExpenses',
        'medicalExpenses2',
        'medicalExpenses3',
        'taxesYouPaid5d',
        'taxesYouPaid',
        'investmentInterest',
        'interestYouPaid',
        'giftsByCash',
        'giftsByLand',
        'giftsToCharityTF',
        'giftsToCharity',
        'casualtyLosses',
        'summationsItemizedDeductions',
        //
        'taxesYouPaid5a',
        'taxesYouPaid5b',
        'taxesYouPaid5c',
        'taxesYouPaid5e',
        'mortgageInterests',
        'mortgageInterestsNotReported',
        'pointsNotReported',
        'mortgageInsurancePremiums',
        'sumOfLines8',

        //-->Schedule B
        'clientHasScheduleB',
        'scheduleB_1',
        'scheduleB_5',
        'scheduleb_2',
        'scheduleb_3',
        'scheduleb_4',
        'scheduleb_6',

        'scheduleb_1_0_text',
        'scheduleb_1_1_text',
        'scheduleb_1_2_text',
        'scheduleb_1_3_text',
        'scheduleb_1_4_text',
        'scheduleb_1_5_text',
        'scheduleb_1_6_text',
        'scheduleb_1_7_text',
        'scheduleb_1_8_text',
        'scheduleb_1_9_text',
        'scheduleb_1_10_text',
        'scheduleb_1_11_text',
        'scheduleb_1_12_text',
        'scheduleb_1_13_text',
        'scheduleb_1_14_text',

        'scheduleb_1_0_amount',
        'scheduleb_1_1_amount',
        'scheduleb_1_2_amount',
        'scheduleb_1_3_amount',
        'scheduleb_1_4_amount',
        'scheduleb_1_5_amount',
        'scheduleb_1_6_amount',
        'scheduleb_1_7_amount',
        'scheduleb_1_8_amount',
        'scheduleb_1_9_amount',
        'scheduleb_1_10_amount',
        'scheduleb_1_11_amount',
        'scheduleb_1_12_amount',
        'scheduleb_1_13_amount',
        'scheduleb_1_14_amount',

        'scheduleb_5_0_text',
        'scheduleb_5_1_text',
        'scheduleb_5_2_text',
        'scheduleb_5_3_text',
        'scheduleb_5_4_text',
        'scheduleb_5_5_text',
        'scheduleb_5_6_text',
        'scheduleb_5_7_text',
        'scheduleb_5_8_text',
        'scheduleb_5_9_text',
        'scheduleb_5_10_text',
        'scheduleb_5_11_text',
        'scheduleb_5_12_text',
        'scheduleb_5_13_text',
        'scheduleb_5_14_text',

        'scheduleb_5_0_amount',
        'scheduleb_5_1_amount',
        'scheduleb_5_2_amount',
        'scheduleb_5_3_amount',
        'scheduleb_5_4_amount',
        'scheduleb_5_5_amount',
        'scheduleb_5_6_amount',
        'scheduleb_5_7_amount',
        'scheduleb_5_8_amount',
        'scheduleb_5_9_amount',
        'scheduleb_5_10_amount',
        'scheduleb_5_11_amount',
        'scheduleb_5_12_amount',
        'scheduleb_5_13_amount',
        'scheduleb_5_14_amount',


        //-->ScheduleC
        'clientHasScheduleC',
        'grossReceipts',
        'returns',
        'line3SchC',
        'costOfGoods',
        'grossProfit',
        'otherIncomeSchC',
        'grossIncome',
        'advertising',
        'carExpenses',
        'commissions',
        'contractLabor',
        'depletion',
        'depreciation',
        'employeeBenefit',
        'insurance',
        'interestMortgage',
        'interestOther',
        'legal',
        'officeExpenses',
        'pensionPlans',
        'vehiclesMachinery',
        'otherBusinessProperty',
        'repairs',
        'supplies',
        'taxesAndLicenses',
        'travel',
        'meals',
        'utilities',
        'wagesLessEmployeeCredits',
        'otherExpenses_schC',
        'totalExpenses',
        'tentativeProfit',
        'expensesForHomeBusiness',
        'netProfitOrLoss',
        'expensesForHomeBusinessTF',


        //-->ScheduleD
        'clientHasScheduleD',
        'shortTermLossCarryover',
        'netShortTermCapitalGains',
        'longTermLossCarryover',
        'netLongTermCapitalGains',

        //-->Other
        'clientHasScheduleE',
        'clientHasScheduleSE',
        'clientHas1040Box6c',
        'clientHasForm1120',
        'clientHasForm1120S',
        'clientHasForm2441',
        'line3form2441',
        'clientHasForm5695',
        'clientHasForm6251',
        'clientHasForm8863',
        'clientHasForm8839',

        //-->Legacyextractions
        'scheduleSELine2',
        'clientHasSchedALine1Q1',
        'clientHasSchedALine1Q3',
        'clientHasForm1040Line29',
        'clientHasForm1040SchedCLine9PartIVForm4562',

        //Others
        'isFormSR',

        // Form 8995
        'clientHasForm8995',
        'totalReit',

        // Form 5498
        'clientHasForm5498',
        'contributionsMade',
        'currentContributionToWorkplaceRetirementPlanSEP',
        'currentContributionToWorkplaceRetirementPlanSIMPLE',

        //-->No general quest
        'filingStatus',
        'livingState',
        'hasChildren',
        'clientIDFillingStatus',
        'clientHasForm1099',
        'clientHasForm8880',
        'advisorIDUnrealizedLongTermCapitalGainsAmount'
  ]

  //-->Flags
  //Obtained from flags metadata
  let flags = Object.keys(this.flagsMetadata).map(extraction => 'flag_' + extraction);

  return includeFlags ? extractions.concat(flags): extractions;

}

  /**
   * This object acts as reference from the extractions that have flags,
   * and describes the metadata for each flag.
   */

  /*
  public flagsMetadata = {
      wages: {description: 'Wages', line: 'Form 1040, Line 1'},//
      taxExemptInterest: {description: 'Tax-exempt interest', line: 'Form 1040, Line 2a'},//
      taxInterest: {description:'Taxable interest', line: 'Form 1040, Line 2b'},//
      qualifiedDividends: {description:'Qualified dividends', line: 'Form 1040, Line 3a'},//
      totalDividends: {description:'Ordinary dividends', line: 'Form 1040, Line 3b'},//
      taxableIRA: {description:'IRA distributions taxable amount', line: 'Form 1040, Line 4b'},//
      taxablePension: {description:'Pensions and annuities taxable amount', line: 'Form 1040, Line 4d', lineNextYear: 'Form 1040, Line 5b'},//
      socialSecurity: {description:'Social security benefits taxable amount', line: 'Form 1040, Line 5b', lineNextYear: 'Form 1040, Line 6b'},//
      //* 1 /
      iraDistribution: {description:'IRA distributions', line: 'Form 1040, Line 4a '},//
      //* 2 /
      pension: {description:'Pensions and annuities', line: 'Form 1040, Line 4c', lineNextYear: 'Form 1040, Line 5a'},//
      //* 3 /
      socialSecurityBenefits: {description:'Social security benefits', line: 'Form 1040, Line 5a ', lineNextYear: 'Form 1040, Line 6a'},//
      capitalGainOrLoss: {description:'Capital gain or (loss)', line: 'Form 1040, Line 6', lineNextYear: 'Form 1040, Line 7'},//
      totalIncome: {description:'Total income', line: 'Form 1040, Line 7b', lineNextYear: 'Form 1040, Line 9'},
      adjustedIncome: {description:'Adjustments to income', line: 'Form 1040, Line 8a', lineNextYear: 'Form 1040, Line 10c'},
      adjustedGrossIncome: {description:'Adjusted gross income', line: 'Form 1040, Line 8b', lineNextYear: 'Form 1040, Line 11'},
      standardDeductions: {description:'Standard deductions or itemized deductions', line: 'Form 1040, Line 9', lineNextYear: 'Form 1040, Line 12'},//
      qbIncome: {description:'Qualified business income deductions', line: 'Form 1040, Line 10', lineNextYear: 'Form 1040, Line 13'},//
      taxableIncome: {description:'Taxable income', line: 'Form 1040, Line 11b', lineNextYear: 'Form 1040, Line 15'},//
      taxes: {description:'Taxes', line: 'Form 1040, Line 12a', lineNextYear: 'Form 1040, Line 16'},//
      totalTax: {description:'Total tax', line: 'Form 1040, Line 16', lineNextYear: 'Form 1040, Line 24'},//
      alimonyReceived: {description:'Alimony received', line: 'Schedule 1, Line 2a'}, //
      businessIncome: {description:'Business income/loss', line: 'Schedule 1, Line 3'}, //
      rentalRealEstate: {description:'Rental real estate, royalties, partnerships, S corporations, trusts, etc', line: 'Schedule 1, Line 5'},//
      healthSavings: {description:'Health savings', line: 'Schedule 1, Line 12'}, //
      selfEmployedSEP: {description:'Self employed SEP', line: 'Schedule 1, Line 15'}, //
      alimonyPaid: {description:'Alimony paid', line: 'Schedule 1, Line 18a'},//
      iraDeduction: {description:'IRA deduction', line: 'Schedule 1, Line 19'},//
      studentLoanInterestDeduction: {description:'Student loan interest deduction', line: 'Schedule 1, Line 20'},//
      adjustmentToIncome: {description:'Adjustments to income', line: 'Schedule 1, Line 22'},//
      creditForChildCare: {description:'Credit for child/dependent care', line: 'Schedule 3, Line 2'},//
      educationCredits: {description:'Education credits', line: 'Schedule 3, Line 3'}, //
      energyCredits: {description:'Residential energy credits', line: 'Schedule 3, Line 5'}, //
      estimatedTax: {description:'2019 estimated tax payments', line: 'Schedule 3, Line 8'},
      medicalExpenses: {description:'Medical and dental expenses', line: 'Schedule A, Line 1'},//
      medicalExpenses2: {description:'Multiply line 2 by 7.5%', line: 'Schedule A, Line 3'},//
      medicalExpenses3: {description:'Line 3 minus line 1', line: 'Schedule A, Line 4'},//
      taxesYouPaid5d: {description:'Taxes you paid', line: 'Schedule A, Line 5d'},//
      investmentInterest: {description:'Investment interest', line: 'Schedule A, Line 9'},//
      interestYouPaid: {description:'Interest you paid (total)', line: 'Schedule A, Line 10'},//
      giftsToCharity: {description:'Gifts to charity', line: 'Schedule A, Line 14'},//
      casualtyLosses: {description:'Casualty and theft losses', line: 'Schedule A, Line 15'}, //
      summationsItemizedDeductions: {description:'Total itemized deductions', line: 'Schedule A, Line 17'},//
      netProfitOrLoss: {description:'Net profit or (loss) from business', line: 'Schedule C, Line 31'},//
      netShortTermCapitalGains: {description:'Net short-term capital gain or (loss)', line: 'Schedule D, Line 7'},//
      netLongTermCapitalGains: {description:'Net long-term capital gain (or loss)', line: 'Schedule D, Line 15'},//
      otherIncome: {description:'Other income', line: 'Form 1040, Line 7a', lineNextYear: 'Form 1040, Line 8'},//
  }
  */

    //line 2019
  //lineNextYear 2020
  //------
  //previousYear 2019
  //line 2020
  //lineNextYear 2021



  public flagsMetadata = {
    livingState: {description: 'State of residence', line: ''},
    wages: {description: 'Wages', line: 'Form 1040, Line 1'},//
    taxExemptInterest: {description: 'Tax-exempt interest', line: 'Form 1040, Line 2a'},//
    taxInterest: {description:'Taxable interest', line: 'Form 1040, Line 2b'},//
    qualifiedDividends: {description:'Qualified dividends', line: 'Form 1040, Line 3a'},//
    totalDividends: {description:'Ordinary dividends', line: 'Form 1040, Line 3b'},//
    taxableIRA: {description:'IRA distributions taxable amount', line: 'Form 1040, Line 4b'},//
    taxablePension: {description:'Pensions and annuities taxable amount', previousYear: 'Form 1040, Line 4d', line: 'Form 1040, Line 5b', lineNextYear: 'Form 1040, Line 5b'},//
    socialSecurity: {description:'Social security benefits taxable amount', previousYear: 'Form 1040, Line 5b', line: 'Form 1040, Line 6b'},//
    /* 1 */iraDistribution: {description:'IRA distributions', line: 'Form 1040, Line 4a '},//
    /* 2 */pension: {description:'Pensions and annuities', previousYear: 'Form 1040, Line 4c', line: 'Form 1040, Line 5a', lineNextYear: 'Form 1040, Line 5a'},//
    /* 3 */socialSecurityBenefits: {description:'Social security benefits', previousYear: 'Form 1040, Line 5a', line: 'Form 1040, Line 6a'},//
    capitalGainOrLoss: {description:'Capital gain or (loss)', previousYear: 'Form 1040, Line 6', line: 'Form 1040, Line 7'},//
    totalIncome: {description:'Total income', previousYear: 'Form 1040, Line 7b', line: 'Form 1040, Line 9'},
    adjustedIncome: {description:'Adjustments to income', previousYear: 'Form 1040, Line 8a', line: 'Form 1040, Line 10c', lineNextYear: 'Form 1040, Line 10'},
    adjustedGrossIncome: {description:'Adjusted gross income', previousYear: 'Form 1040, Line 11', line: 'Form 1040, Line 11'},
    standardDeductions: {description:'Standard deductions or itemized deductions', previousYear: 'Form 1040, Line 9', line: 'Form 1040, Line 12', lineNextYear: 'Form 1040, Line 12a'},//
    qbIncome: {description:'Qualified business income deductions', previousYear: 'Form 1040, Line 10', line: 'Form 1040, Line 13'},//
    taxableIncome: {description:'Taxable income', previousYear: 'Form 1040, Line 11b', line: 'Form 1040, Line 15'},//
    taxes: {description:'Taxes', previousYear: 'Form 1040, Line 12a', line: 'Form 1040, Line 16'},//
    totalTax: {description:'Total tax', previousYear: 'Form 1040, Line 16', line: 'Form 1040, Line 24'},//
    otherIncome: {description:'Other income', previousYear: 'Form 1040, Line 7a', line: 'Form 1040, Line 8'},//
    alimonyReceived: {description:'Alimony received', line: 'Schedule 1, Line 2a'}, //
    businessIncome: {description:'Business income/loss', line: 'Schedule 1, Line 3'}, //
    rentalRealEstate: {description:'Rental real estate, royalties, partnerships, S corporations, trusts, etc', line: 'Schedule 1, Line 5'},//
    healthSavings: {description:'Health savings', line: 'Schedule 1, Line 12', lineNextYear: 'Schedule 1, Line 13'}, //
    selfEmployedSEP: {description:'Self employed SEP', line: 'Schedule 1, Line 15', lineNextYear: 'Schedule 1, Line 16'}, //
    alimonyPaid: {description:'Alimony paid', line: 'Schedule 1, Line 18a', lineNextYear: 'Schedule 1, Line 19a'},//
    iraDeduction: {description:'IRA deduction', line: 'Schedule 1, Line 19', lineNextYear: 'Schedule 1, Line 20'},//
    studentLoanInterestDeduction: {description:'Student loan interest deduction', line: 'Schedule 1, Line 20', lineNextYear: 'Schedule 1, Line 21'},//
    adjustmentToIncome: {description:'Adjustments to income', line: 'Schedule 1, Line 22', lineNextYear: 'Schedule 1, Line 26'},//
    creditForChildCare: {description:'Credit for child/dependent care', line: 'Schedule 3, Line 2'},//
    educationCredits: {description:'Education credits', line: 'Schedule 3, Line 3'}, //
    energyCredits: {description:'Residential energy credits', line: 'Schedule 3, Line 5'}, //
    estimatedTax: {description:'2019 estimated tax payments', line: 'Schedule 3, Line 8'},
    medicalExpenses: {description:'Medical and dental expenses', line: 'Schedule A, Line 1'},//
    medicalExpenses2: {description:'Multiply line 2 by 7.5%', line: 'Schedule A, Line 3'},//
    medicalExpenses3: {description:'Line 3 minus line 1', line: 'Schedule A, Line 4'},//
    taxesYouPaid5d: {description:'Taxes you paid', line: 'Schedule A, Line 5d'},//
    taxesYouPaid: {description:'Deductions allowed for taxes paid', line: 'schedule A line 7'},
    investmentInterest: {description:'Investment interest', line: 'Schedule A, Line 9'},//
    interestYouPaid: {description:'Interest you paid (total)', line: 'Schedule A, Line 10'},//
    giftsToCharity: {description:'Gifts to charity', line: 'Schedule A, Line 14'},//
    casualtyLosses: {description:'Casualty and theft losses', line: 'Schedule A, Line 15'}, //
    summationsItemizedDeductions: {description:'Total itemized deductions', line: 'Schedule A, Line 17'},//
    additionalChildTaxCredit: {description:'Additional child tax credit', line: 'Form 1040, Line 28'},
    amountfromSchedule3line15: {description:'Amount from Schedule 3, line 15', line: 'Form 1040, Line 31'},
    totalPayments: {description:'Add lines 25d, 26, and 32. These are your total payments', line: 'Form 1040, Line 33'},

    netShortTermCapitalGains: {description:'Net short-term capital gain or (loss)', line: 'Schedule D, Line 7'},//
    netLongTermCapitalGains: {description:'Net long-term capital gain (or loss)', line: 'Schedule D, Line 15'},//
    scheduleB_1: {description:'Schedule B. Part I - Interest', line: ''},//
    scheduleb_2: {description:'Schedule B. Part I - Interest', line: 'Schedule B, Line 2'},//
    scheduleb_4: {description:'Schedule B. Part I - Interest', line: 'Schedule B, Line 4'},//
    scheduleB_5: {description:'Schedule B. Part II - Ordinary Dividends', line: ''},//
    scheduleb_6: {description:'Schedule B. Part II - Interest', line: 'Schedule B, Line 6'},//
    // SCH C
    grossReceipts: {description: 'Gross Receipts', line: 'Schedule C, Line 1', section: 'schC'},
    returns: {description: 'Returns', line: 'Schedule C, Line 2', section: 'schC'},
    line3SchC: {description: 'Subtract line 2 from line 1', line: 'Schedule C, Line 3', section: 'schC'},
    costOfGoods: {description: 'Cost of Goods', line: 'ScheduleC, Line 4', section: 'schC'},
    grossProfit: {description: 'Gross Profit', line: 'Schedule C, Line 5', section: 'schC'},
    otherIncomeSchC: {description: 'Other Income', line: 'Schedule C, Line 6', section: 'schC'},
    grossIncome: {description: 'Gross Income', line: 'Schedule C, Line 7', section: 'schC'},
    advertising: {description: 'Advertising', line: 'Schedule C, Line 8', section: 'schC'},
    carExpenses: {description: 'Car Expenses', line: 'Schedule C, Line 9', section: 'schC'},
    commissions: {description: 'Commissions', line: 'Schedule C, Line 10', section: 'schC'},
    contractLabor: {description: 'Contract Labor', line: 'Schedule C, Line 11', section: 'schC'},
    depletion: {description: 'Depletion', line: 'Schedule C, Line 12', section: 'schC'},
    depreciation: {description: 'Depreciation', line: 'Schedule C, Line 13', section: 'schC'},
    employeeBenefit: {description: 'Employee Benefit', line: 'Schedule C, Line 14', section: 'schC'},
    insurance: {description: 'Insurance', line: 'Schedule C, Line 15', section: 'schC'},
    interestMortgage: {description: 'Interest Mortgage', line: 'Schedule C, Line 16a', section: 'schC'},
    interestOther: {description: 'Interest Other', line: 'Schedule C, Line 16b', section: 'schC'},
    legal: {description: 'Legal and Professionals Services', line: 'Schedule C, Line 17', section: 'schC'},
    officeExpenses: {description: 'Office Expenses', line: 'Schedule C, Line 18', section: 'schC'},
    pensionPlans: {description: 'Pension Plans', line: 'Schedule C, Line 19', section: 'schC'},
    vehiclesMachinery: {description: 'Vehicles Machinery', line: 'Schedule C, Line 20a', section: 'schC'},
    otherBusinessProperty: {description: 'Other Business Property', line: 'Schedule C, Line 20b', section: 'schC'},
    repairs: {description: 'Repairs', line: 'Schedule C, Line 21', section: 'schC'},
    supplies: {description: 'Supplies', line: 'Schedule C, Line 22', section: 'schC'},
    taxesAndLicenses: {description: 'Taxes and Licenses', line: 'Schedule C, Line 23', section: 'schC'},
    travel: {description: 'Travel', line: 'Schedule C, Line 24a', section: 'schC'},
    meals: {description: 'Meals', line: 'Schedule C, Line 24b', section: 'schC'},
    utilities: {description: 'Utilities', line: 'Schedule C, Line 25', section: 'schC'},
    wagesLessEmployeeCredits: {description: 'Wages less employee credits', line: 'Schedule C, Line 26', section: 'schC'},
    otherExpenses_schC: {description: 'Other Expenses', line: 'Schedule C, Line 27a', section: 'schC'},
    totalExpenses: {description: 'Total Expenses', line: 'Schedule C, Line 28', section: 'schC'},
    tentativeProfit: {description: 'Tentative Profit', line: 'Schedule C, Line 29', section: 'schC'},
    //expensesForHomeBusinessTF: {description: 'Expenses for business use of your home', line: 'Schedule C, Line 30', section: 'schC', type:'yesNo'},
    expensesForHomeBusiness: {description: 'Expenses for business use of your home', line: 'Schedule C, Line 30', section: 'schC'},
    netProfitOrLoss: {description: 'Net profit or (loss) from business', line: 'Schedule C, Line 31', section: 'schC', },
    //SCH D
    shortTermLossCarryover: {description: 'Short term capital loss carryover.', line: 'Schedule D, Line 6' },

  }

 constructor(
  private isProduction: boolean = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION)
 ) {
   super();
   this._classInitialState = Object.assign({}, this);
 }

  getQName(): string {
    return 'taxfull';
  }

  getIgnoredAttributesForSave():string[]{

    let ignoreExtractionFlags = []; //this.getExtractionsAtributes().map( extraction => 'flag_' + extraction);
    let ignoreOthersAttributes = ['clientIDFulltaxPolicy', 'ignoreExtractionsData', 'clientIDFulltaxPolicy2019', 'clientIDFulltaxPolicy2020', 'clientIDFulltaxPolicy2021','saveFlag', 'readFlag', 'isProduction', 'flagsMetadata'].concat(ignoreExtractionFlags);
    //let ignoreOthersAttributes = ['clientIDFulltaxPolicy', 'ignoreExtractionsData', 'clientIDFulltaxPolicy2019']
    return ignoreOthersAttributes;

    /*
    let ignoredAttributes =  this.ignoreExtractionsData ?  ignoreOthersAttributes.concat(this.getExtractionsAtributes()) : ignoreOthersAttributes;
    return ignoredAttributes;
    */
  }

  getAttributesToClearCommas(): string[] {
    return [
      //-->Form1040
      'wages',
      'taxExemptInterest',
      'taxInterest',
      'qualifiedDividends',
      'totalDividends',
      'iraDistribution',
      'taxableIRA',
      'pension',
      'taxablePension',
      'socialSecurityBenefits',
      'socialSecurity',
      'capitalGainOrLoss',
      'totalIncome',
      'adjustedIncome',
      'adjustedGrossIncome',
      'standardDeductions',
      'qbIncome',
      'taxableIncome',
      'taxes',
      'totalTax',
      'otherIncome',
      'charitableContributions1040',
      'sumStandardAndCharitable',
      'sumDeductions',
      'line17_1040',
      'line18_1040',
      'qualifyingDependentCreditClaimed',
      'line20_1040',
      'line21_1040',
      'line22_1040',
      'otherTaxesWithSelfEmployment',
      'federalIncomeTaxWithheld',
      'estimatedTaxPaymentsAmountForLastYear',
      'earnedIncomeCredit',
      'additionalChildTaxCredit',
      'americanOpportunityCreditTaken',
      'recoveryRebateCredit',
      'amountfromSchedule3line15',
      'totalOtherPayments',
      'totalPayments',

      //-->Schedule1
      'alimonyReceived',
      'businessIncome',
      'rentalRealEstate',
      'healthSavings',
      'selfEmployedSEP',
      'alimonyPaid',
      'iraDeduction',
      'studentLoanInterestDeduction',
      'adjustmentToIncome',

      //-->Schedule3
      'creditForChildCare',
      'educationCredits',
      'energyCredits',
      'estimatedTax',
      'retiramentSavings',

      //-->ScheduleA
      'medicalExpenses',
      'medicalExpenses2',
      'medicalExpenses3',
      'taxesYouPaid5d',
      'taxesYouPaid',
      'investmentInterest',
      'interestYouPaid',
      'giftsByCash',
      'giftsByLand',
      'giftsToCharity',
      'casualtyLosses',
      'summationsItemizedDeductions',

      'taxesYouPaid5a',
      'taxesYouPaid5b',
      'taxesYouPaid5c',
      'taxesYouPaid5e',
      'mortgageInterests',
      'mortgageInterestsNotReported',
      'pointsNotReported',
      'mortgageInsurancePremiums',
      'sumOfLines8',

      //-->ScheduleB

      'scheduleb_2',
      'scheduleb_3',
      'scheduleb_4',
      'scheduleb_6',

      'scheduleb_1_0_amount',
      'scheduleb_1_1_amount',
      'scheduleb_1_2_amount',
      'scheduleb_1_3_amount',
      'scheduleb_1_4_amount',
      'scheduleb_1_5_amount',
      'scheduleb_1_6_amount',
      'scheduleb_1_7_amount',
      'scheduleb_1_8_amount',
      'scheduleb_1_9_amount',
      'scheduleb_1_10_amount',
      'scheduleb_1_11_amount',
      'scheduleb_1_12_amount',
      'scheduleb_1_13_amount',
      'scheduleb_1_14_amount',

      'scheduleb_5_0_amount',
      'scheduleb_5_1_amount',
      'scheduleb_5_2_amount',
      'scheduleb_5_3_amount',
      'scheduleb_5_4_amount',
      'scheduleb_5_5_amount',
      'scheduleb_5_6_amount',
      'scheduleb_5_7_amount',
      'scheduleb_5_8_amount',
      'scheduleb_5_9_amount',
      'scheduleb_5_10_amount',
      'scheduleb_5_11_amount',
      'scheduleb_5_12_amount',
      'scheduleb_5_13_amount',
      'scheduleb_5_14_amount',

      //-->ScheduleC
      'grossReceipts',
      'returns',
      'line3SchC',
      'costOfGoods',
      'grossProfit',
      'otherIncomeSchC',
      'grossIncome',
      'advertising',
      'carExpenses',
      'commissions',
      'contractLabor',
      'depletion',
      'depreciation',
      'employeeBenefit',
      'insurance',
      'interestMortgage',
      'interestOther',
      'legal',
      'officeExpenses',
      'pensionPlans',
      'vehiclesMachinery',
      'otherBusinessProperty',
      'repairs',
      'supplies',
      'taxesAndLicenses',
      'travel',
      'meals',
      'utilities',
      'wagesLessEmployeeCredits',
      'otherExpenses_schC',
      'totalExpenses',
      'tentativeProfit',
      'expensesForHomeBusiness',
      'netProfitOrLoss',

      //-->ScheduleD
      'netShortTermCapitalGains',
      'netLongTermCapitalGains',
      'shortTermLossCarryover',
      'longTermLossCarryover',

      //-->Form 8995
      'totalReit',

      //-->Form 5498
      'contributionsMade',
      'currentContributionToWorkplaceRetirementPlanSEP',
      'currentContributionToWorkplaceRetirementPlanSIMPLE',


      //-->Legacyextractions
      'scheduleSELine2',

      // --> Questionnaire
      'taxableIRAdistForRoth',
      'clientID2018Income',
      'advisorIDYearToDateRealizedGains',
      'clientIDRealizedLongTermCapitalGains',
      'line3form2441',
      'advisorIDUnrealizedLongTermCapitalGainsAmount'
    ];
  }


/*   setFlag(extraction:string, isActive: boolean): boolean{
    let selectedFlag: string = this['flag_' + extraction];
    let flagValues = selectedFlag.split(',');
    if(selectedFlag && flagValues.length == 2){

      // Set value as a ques reponse of type: "Yes,schedule1.jpg"
      selectedFlag = `${isActive ? 'Yes' : 'No'},${flagValues[1]}`
      return true;
    }else{
      console.log('important', 'WARNING: Flag value not found or wrong format.' + extraction);
      return false;
    }
  } */

  saveFlag(flag: FullValidationFlag): boolean{
    let flagFromBackend: string = this['flag_' + flag.keyFlag];
    if(flagFromBackend){

      // Set value as a ques reponse of type: "Yes,schedule1.jpg"
      this['flag_' + flag.keyFlag] = `${flag.isActive ? 'Yes' : 'No'},${flag.url ? flag.url: 'url'}`


      return true;
    }else{
      console.log('important', 'WARNING: Flag value not found: ' + flag.keyFlag);
      return false;
    }
  }

  readFlag(extraction:string): FullValidationFlag | null{
    let flagFromBackend = this['flag_' + extraction];
    let fullValidationFlag: FullValidationFlag = {
      keyFlag: extraction,
      isActive: false,
      url: '',
      metadata: {description:'', line: ''}
    }

    // Mini hack for testing / prod env.
    // Don't get the flag for people on production who aren't early access || get the flag for testing and production early access users
    //if(this.isProduction && !taxEarlyAccess.includes(localStorage.getItem('userId'))) return fullValidationFlag;

    let printError = () => {
      let message = 'WARNING: Flag value not found: ' + extraction;
      //throw(message);

    }
    if(Boolean(flagFromBackend)){
      // The backend data could be presented initially as an array, after save it's passed as string
      let flagValues;
      if(typeof(flagFromBackend) === 'string' && flagFromBackend.includes('[') && flagFromBackend.includes(']')){
        flagValues = JSON.parse(flagFromBackend);
      }else if(typeof(flagFromBackend) === 'string' && flagFromBackend.includes(',')){
        flagValues = flagFromBackend.split(',');
      }else{
        flagValues = flagFromBackend;
      }

      if(flagValues && flagValues.length == 2){
        fullValidationFlag.url = flagValues[1];
        fullValidationFlag.isActive = flagValues[0] === 'Yes';
        fullValidationFlag.metadata = this.flagsMetadata[extraction];
      }else{
        //printError();
      }
    }else{
      printError();
    }

    return fullValidationFlag;
  }

  clearAllFlags(){
    Object.keys(this.flagsMetadata).map(flagKey => {
      this['flag_' + flagKey] = 'No,url';
    })
  }

}

interface FlagsMetadata {
  line: string,
  previousYear?: string,
  lineNextYear?: string

}

