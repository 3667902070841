export class Page {
  jointSpace: number = 0;
  clientSpace: number = 0;
  'co-clientSpace': number = 0;
  data: any[] = [];
  maxHeigth = 620 - 55;
  section: string;
  sectionTitle: string;

  getAvailableSpace(type: ('joint' | 'client' | 'co-client' | string)){
    let spaceOfType = type !== 'joint' ? this.jointSpace + this[type + 'Space'] : this.jointSpace;
    return this.maxHeigth - spaceOfType;
  }

  updateSpace(type: ('joint' | 'client' | 'co-client' | string), space: number){
    this[type + 'Space'] = this[type + 'Space'] + space;
  }
}
