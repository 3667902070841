import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  assetsCatalog,
  columnsToRenderizeByAssetType,
  columnsToShowAsPercent,
} from "../../templates/assets/utils";
import {
  distinctUntilChanged,
  map,
  filter,
  startWith,
  pairwise,
} from "rxjs/operators";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ConfirmStatusComponent } from "../confirm-status/confirm-status.component";
import { Validations } from "app/views/questionnaires/Questionnaire.validations";
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: "app-add-asset",
  templateUrl: "./add-asset.component.html",
  styleUrls: ["./add-asset.component.scss"],
})
export class AddAssetComponent extends CloseDialogAtLogOut implements OnInit {
  @Output() handleOnSave: EventEmitter<any> = new EventEmitter();

  @Output() handleOnDelete: EventEmitter<any> = new EventEmitter();

  @Output() handleOnClose: EventEmitter<any> = new EventEmitter();

  @Input() dataForm: FormGroup;

  @Input() validRelationships: any;

  @Input() dependencies: any;

  @Input() crossFields: any;

  @Input() crossCheckRelationships: any;

  @Input() theme: string = "general-questionnaire-theme";

  @Input() title: string = "";

  @Input() assetType: string = "";

  @Input() canBeDeleted: boolean;

  @Input() columnsToRenderize;

  @Input() beneficiariesHasPercentages: boolean = false;

  @Input() cantDeleteMessage = ''

  crossChecks: any = [];

  minDateForBirthdate: Date;

  maxDateForBirthdate: Date;

  form: FormGroup;

  childrenCatalog = assetsCatalog.business;

  isNewRelative: boolean = true;

  previousFormValue: any;

  statusSubscription;

  firstValidStatus;

  constructor(
    private dialogRef: MatDialogRef<AddAssetComponent>,
    private confirmationStatusDialog: MatDialog,
    private authService: AuthenticationService) { 

    super(dialogRef, authService);

  }

  ngOnInit(): void {
    this.initBirthRanges();
    this.initForm();
    this.initCrossChecks();
    this.initFirstStatusListener();
  }
  
  initFirstStatusListener() {
    if ( this.form.controls[
      "status"
    ]) {
      this.statusSubscription = this.form.controls[
        "status"
      ].valueChanges.subscribe((value) => {
        if (
          this.firstValidStatus === undefined &&
          value != null &&
          value != undefined &&
          value.trim() !== ""
        ) {
          this.firstValidStatus = value;
          this.statusSubscription.unsubscribe();
        }
      });
    }
  }

  initCrossChecks() {
    this.crossChecks = this.validRelationships;
  }

  getCustomClasses(controlName: string) {
    const classes = [];
    if (this.isDisabled(controlName)) {
      classes.push("disabled");
    }
    classes.push(this.theme);
    return classes;
  }

  getRelationshipsFilteredByValidAsset(relationships, assetKey, controlName) {
    return relationships;
  }

  compareArrays = (arr1: any, arr2: any): boolean => {
    if (!Array.isArray(arr1)) {
      arr1 = [arr1];
    }
    if (!Array.isArray(arr2)) {
      arr2 = [arr2];
    }

    if (arr1 === arr2) {
      return true;
    }
    if (arr1 == null || arr2 == null) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; ++i) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  };

  isDisabled(controlName: string) {
    if (!this.dependencies) {
      return;
    }

    if (this.dependencies[controlName]?.onDifferentValue) {
      return (
        this.dependencies[controlName] &&
        this.dependencies[controlName].action === "disabled" &&
        this.form.controls[this.dependencies[controlName].dependency].value !==
          this.dependencies[controlName].onDifferentValue
      );
    }

    if (this.dependencies[controlName]?.onSameValue || this.dependencies[controlName]?.onSameValue === 0) {
      return (
        this.dependencies[controlName] &&
        this.dependencies[controlName].action === "disabled" &&
        Boolean(this.form.controls[this.dependencies[controlName].dependency].value) ==
          this.dependencies[controlName].onSameValue
      );
    }

    if (this.dependencies[controlName]?.isEmpty) {
      return (
        this.dependencies[controlName] &&
        this.dependencies[controlName].action === "disabled" &&
        this.form.controls[this.dependencies[controlName].dependency].value
          .length === 0
      );
    }
  }

  isOptionDisabled(controlName, item){
    if (this.dependencies[controlName] && this.dependencies[controlName]['onSelectValue']) {
      
      return this.form.controls[this.dependencies[controlName]['dependency']].value == item.relation
    }
    if (controlName == 'contingentBeneficiaries' && this.dependencies['beneficiaries']&& this.dependencies['beneficiaries']['onSelectValue']) {
      return this.form.controls[this.dependencies['beneficiaries']['dependency']].value == item.relation
    }
    return
  }

  isCrossField(controlName: string) {
    if (!this.crossFields) {
      return;
    }

    return this.crossFields[controlName];
  }

  beneficiariesForm: FormGroup = new FormGroup({});

  beneficiariesPercentageSum: number = 0;

  contingentBeneficiariesForm: FormGroup = new FormGroup({});

  contingentBeneficiariesPercentageSum: number = 0;

  @Input() percentages;

  calcRemainingPercentageText(sum) {
    let remaining = this.calcAdjustedPercentage(sum);
    if (isNaN(remaining) || remaining == undefined) {
      return "  ";
    }
    return remaining.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "% remaining";
  }

  calcAdjustedPercentage(sum) {
    let adjustedSum = 100 - sum;
    if (adjustedSum < 0.4 && adjustedSum > -0.4) {
      return 0;
    }
    return adjustedSum;
  }

  isPercentageField(name) {
    return name.includes("_percentaje");
  }

  initBeneficiariesPercentages() {
    if (this.beneficiariesPercentageSum == 0) {
      this.percentages['beneficiaries'].forEach((item) => {
        this.beneficiariesPercentageSum += item
      })
    }
    if (this.contingentBeneficiariesPercentageSum == 0) {
      this.percentages['contingentBeneficiaries'].forEach((item) => {
        this.contingentBeneficiariesPercentageSum += item
      })
    }
    this.beneficiariesForm.valueChanges.subscribe((items) => {
      let sum = 0;
      const filteredPercentages = Object.keys(items).filter((item, index) => {
        return item.includes("_percentaje");
      });
      Object.values(items).forEach((item) => {
        sum += Number(item);
      });
      this.beneficiariesPercentageSum =
        typeof sum == "number" ? sum : this.beneficiariesPercentageSum;
    });

    this.contingentBeneficiariesForm.valueChanges.subscribe((items) => {
      let sum = 0;
      const filteredPercentages = Object.keys(items).filter((item, index) => {
        return item.includes("_percentaje");
      });
      Object.values(items).forEach((item) => {
        sum += Number(item);
      });
      this.contingentBeneficiariesPercentageSum =
        typeof sum == "number"
          ? sum
          : this.contingentBeneficiariesPercentageSum;
    });
  }

  calcCrossFields(
    controlName: string,
    changedField?,
    newValue?,
    removedValues?
  ) {
    if (!this.crossFields) {
      return;
    }

    const dependencyName = this.crossFields[controlName].dependency;

    const dependencyOriginalFields = this.validRelationships[dependencyName];

    const dependencySelectedFields = this.form.controls[dependencyName].value;

    const controlValidFields = dependencyOriginalFields.filter((item1) => {
      return !dependencySelectedFields.some((item2) => item1.value === item2);
    });

    this.validRelationships[controlName] = dependencyOriginalFields;

    this.validRelationships[controlName] = controlValidFields;

    if (this.form.controls['contingentBeneficiaries'] && this.form.controls['contingentBeneficiaries'].value.length) {

      const newFilteredValue = controlValidFields.filter((item) => {
        return this.form.controls['contingentBeneficiaries'].value.includes(item.value)
      }).map((arg) => {
        return arg.value
      })
      if (newFilteredValue.length ||( newValue.length ==0 && newFilteredValue.length>0)) {
        this.form.controls['contingentBeneficiaries'].setValue(newFilteredValue)
      }
      else{
        this.form.controls['contingentBeneficiaries'].setValue([])
      }
    }

    if (
      Object.keys(this.beneficiariesForm.controls).length == 0 &&
      changedField == "beneficiaries"
    ) {
      dependencySelectedFields.forEach((item, index) => {
        const selected = this.validRelationships["beneficiaries"].filter(
          (relation) => {
            return relation.value == item;
          }
        )[0];
        this.beneficiariesForm.addControl(
          selected.relation + selected.text,
          new FormControl(selected.text)
        );
        this.beneficiariesForm.controls[
          selected.relation + selected.text
        ].disable();
        const firstValue = this.percentages["beneficiaries"];
        this.beneficiariesForm.addControl(
          selected.relation + selected.text + "_percentaje",
          new FormControl(firstValue[index] || "", Validators.required)
        );
      });
      if (this.form.controls["contingentBeneficiaries"].value) {
        this.form.controls["contingentBeneficiaries"].value.forEach(
          (item, index) => {
            const selected = this.validRelationships[
              "contingentBeneficiaries"
            ].filter((relation) => {
              return relation.value == item;
            })[0];
            this.contingentBeneficiariesForm.addControl(
              selected.relation + selected.text,
              new FormControl(selected.text)
            );
            this.contingentBeneficiariesForm.controls[
              selected.relation + selected.text
            ].disable();
            const firstValue = this.percentages["contingentBeneficiaries"];
            this.contingentBeneficiariesForm.addControl(
              selected.relation + selected.text + "_percentaje",
              new FormControl(firstValue[index] || "", Validators.required)
            );
          }
        );
      }

      this.initBeneficiariesPercentages();
    }
    if (newValue && newValue.length > 0 && changedField == "beneficiaries") {
      const selected = this.validRelationships["beneficiaries"].filter(
        (relation) => {
          return relation.value == newValue[0];
        }
      )[0];
      this.beneficiariesForm.addControl(
        selected.relation + selected.text,
        new FormControl(selected.text)
      );
      this.beneficiariesForm.controls[
        selected.relation + selected.text
      ].disable();
      this.beneficiariesForm.addControl(
        selected.relation + selected.text + "_percentaje",
        new FormControl("", Validators.required)
      );
      if (
        this.contingentBeneficiariesForm.controls[
          selected.relation + selected.text
        ]
      ) {
        this.contingentBeneficiariesForm.removeControl(
          selected.relation + selected.text
        );
        this.contingentBeneficiariesForm.removeControl(
          selected.relation + selected.text + "_percentaje"
        );
      }
    }
    if (
      removedValues &&
      removedValues.length > 0 &&
      changedField == "beneficiaries"
    ) {
      const selected = this.validRelationships["beneficiaries"].filter(
        (relation) => {
          return relation.value == removedValues[0];
        }
      )[0];
      this.beneficiariesForm.removeControl(selected.relation + selected.text);
      this.beneficiariesForm.removeControl(
        selected.relation + selected.text + "_percentaje"
      );
    }

    if (
      newValue &&
      newValue.length > 0 &&
      changedField == "contingentBeneficiaries"
    ) {
      const selected = this.validRelationships[
        "contingentBeneficiaries"
      ].filter((relation) => {
        return relation.value == newValue[0];
      })[0];
      if (selected) {
        this.contingentBeneficiariesForm.addControl(
          selected.relation + selected.text,
          new FormControl(selected.text)
        );
        this.contingentBeneficiariesForm.controls[
          selected.relation + selected.text
        ].disable();
        this.contingentBeneficiariesForm.addControl(
          selected.relation + selected.text + "_percentaje",
          new FormControl("", Validators.required)
        );
      }
      
    }
    if (
      removedValues &&
      removedValues.length > 0 &&
      changedField == "contingentBeneficiaries"
    ) {
      const selected = this.validRelationships[
        "contingentBeneficiaries"
      ].filter((relation) => {
        return relation.value == removedValues[0];
      })[0];
      if (selected) {
        this.contingentBeneficiariesForm.removeControl(
          selected.relation + selected.text
        );
        this.contingentBeneficiariesForm.removeControl(
          selected.relation + selected.text + "_percentaje"
        );
      }
    }

    return;
  }

  get hasBeneficiaryControls(): boolean {
    return Object.keys(this.beneficiariesForm.controls).length > 0;
  }

  get hasContingentBeneficiaryControls(): boolean {
    return Object.keys(this.contingentBeneficiariesForm.controls).length > 0;
  }

  get formBeneficiaryControlNames(): string[] {
    return Object.keys(this.beneficiariesForm.controls);
  }

  get formContingentBeneficiaryControlNames(): string[] {
    return Object.keys(this.contingentBeneficiariesForm.controls);
  }

  initForm() {
    if (this.dataForm) {
      this.form = this.dataForm;
      setTimeout(() => {
        const val = {
          value: this.form.controls['owner'].value
        }
        this.onSelectionChange(val, 'owner')
      }, 700);
      this.childrenCatalog = assetsCatalog[this.assetType].assets;
      this.initToggleFields();
      this.form.valueChanges
        .pipe(
          startWith(this.form.value),
          pairwise(),
          map(([prev, curr]) => {
            const changedField = Object.keys(curr).find(
              (key) => JSON.stringify(prev[key]) !== JSON.stringify(curr[key])
            );
            if (changedField && Array.isArray(curr[changedField])) {
              const addedValues = curr[changedField].filter(
                (item) => !prev[changedField].includes(item)
              );
              const removedValues = prev[changedField]
                ? prev[changedField].filter(
                    (item) => !curr[changedField].includes(item)
                  )
                : [];
              return {
                hasValueChanged:
                  addedValues.length > 0 || removedValues.length > 0,
                changedField,
                addedValues,
                removedValues,
              };
            }
            return {
              hasValueChanged: changedField !== undefined,
              changedField,
              newValue: curr[changedField],
            };
          }),
          filter(({ hasValueChanged }) => hasValueChanged)
        )
        .subscribe(({ changedField, addedValues, removedValues }) => {
          this.checkDependencies(changedField, addedValues, removedValues);
        });
    }
    setTimeout(() => {
      this.checkDependencies();
      this.checkDependencies("beneficiaries");
      this.isNewRelative = this.dataForm.status === "VALID";
    }, 200);
  }

  initToggleFields(): void {
    const data = this.childrenCatalog;
    for (const key in data) {
      if (data[key].type === "toggle") {
        if (this.form.controls[key] && !this.form.controls[key].value) {
          this.form.controls[key].setValue(
            this.childrenCatalog[key].toggleValues[0]
          );
        }
        if (this.form.controls[key]) {
          this.childrenCatalog[key]["firstValue"] =
            this.form.controls[key].value;
        }
      }
    }
  }

  checkDependencies(changedField?, newValue?, removedValues?) {
    Object.keys(this.form.controls).forEach((control) => {
      if (this.isDisabled(control)) {
        this.form.controls[control].setValue("");
        this.form.controls[control].disable();
      } else {
        this.form.controls[control].enable();
      }
      if (this.isCrossField(control)) {
        this.calcCrossFields(control, changedField, newValue, removedValues);
      }
    });
  }

  get formControlNames(): string[] {
    return Object.keys(this.form.controls).filter((arg) => {
      return !arg.includes('Percentages')
    }).sort((a, b) => {
      return (
        this.columnsToRenderize[this.assetType].indexOf(a) -
        this.columnsToRenderize[this.assetType].indexOf(b)
      );
    });
  }

  getBeneficiaryById(id: string) {
    this.validRelationships.beneficiaries.filter((item) => {
      return item.value == id;
    })[0].text;
  }

  initBirthRanges() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDateForBirthdate = new Date(currentYear - 120, 0, 1);
    this.maxDateForBirthdate = new Date(
      currentYear ,
      currentMonth,
      currentDay + 1
    );
  }

  toggleFormValue(formItemName, newValue) {
    this.form.controls[formItemName].setValue(newValue);
  }

  isDisabledForm() {
    const hasBeneficiaries = Object.keys(this.beneficiariesForm.controls).length > 0 
    const hasContingentBeneficiaries = Object.keys(this.contingentBeneficiariesForm.controls).length > 0 

    if (!this.form.valid) {
      return true
    }

    const validBeneficiaries =  (
      ( hasBeneficiaries ? !this.beneficiariesForm.valid: false) ||
      ( hasContingentBeneficiaries ? !this.contingentBeneficiariesForm.valid: false) ||
      ( hasBeneficiaries ? this.calcAdjustedPercentage(this.beneficiariesPercentageSum) != 0 : false) ||
      ( hasContingentBeneficiaries ?  this.calcAdjustedPercentage(this.contingentBeneficiariesPercentageSum) != 0 : false )
    )

    if (validBeneficiaries && this.theme != 'general-questionnaire-theme') {
      return true
    }

    return false
  }

  onSelectionChange(value, controlName){
    if(controlName === 'owner' && this.assetType === 'life_insurance'){
      const beneficiaries = this.form.controls['beneficiaries'].value
      const cbeneficiaries = this.form.controls['contingentBeneficiaries'].value
      let selected = this.validRelationships.beneficiaries.filter((item) => {
        return item.text.includes(`[${value.value}]` )
      })[0]
      if (selected) {
        const selectedId = selected.value
        const newBeneficiaries = beneficiaries ? beneficiaries.filter((item)=> {
          return item!= selectedId
        }) : []
        const newCBeneficiaries = cbeneficiaries? cbeneficiaries.filter((item)=> {
          return item!= selectedId
        }) : []
        setTimeout(() => {
          this.form.controls['beneficiaries'].setValue(newBeneficiaries)
          this.form.controls['contingentBeneficiaries'].setValue(newCBeneficiaries)
          this.validRelationships.beneficiaries = this.validRelationships.beneficiaries.map((item) => {
            return {
                ...item,
                disabled: selectedId == item.value
            }
          })
          this.validRelationships.contingentBeneficiaries = this.validRelationships.contingentBeneficiaries.map((item) => {
            return {
                ...item,
                disabled: selectedId == item.value
            }
          })
        }, 400);
        return
       
      }
      if (value.value.includes('Joint')) {
        const idsParents = this.validRelationships.beneficiaries.filter((item, index) => {
          return index==0 || index==1
        }).map((arg) => {
          return arg.value
        })
        const newBeneficiaries = beneficiaries.filter((item, index)=> {
          return !idsParents.includes(item)
        })

        const newCBeneficiaries = cbeneficiaries? cbeneficiaries.filter((item)=> {
          return !idsParents.includes(item)
        }) : []
        setTimeout(() => {
          this.form.controls['beneficiaries'].setValue(newBeneficiaries)
          if (newBeneficiaries.length) {
            this.form.controls['contingentBeneficiaries'].setValue(newCBeneficiaries)
          }
          else{
            this.form.controls['contingentBeneficiaries'].setValue([])
          }
          this.validRelationships.beneficiaries = this.validRelationships.beneficiaries.map((item) => {
            return {
                ...item,
                disabled: idsParents.includes(item.value)
            }
          })
          this.validRelationships.contingentBeneficiaries = this.validRelationships.contingentBeneficiaries.map((item) => {
            return {
                ...item,
                disabled: idsParents.includes(item.value)
            }
          })
        }, 400);
        
        return
      }
      const truncatedValue = value.value.split('\'')[0]
      if(truncatedValue){
        selected = this.validRelationships.beneficiaries.filter((item) => {
          return item.text.includes(`[${truncatedValue}]` )
        })[0]
        if (selected) {
          const selectedId = selected.value
          const newBeneficiaries = beneficiaries.filter((item)=> {
            return item!= selectedId
          })

          const newCBeneficiaries = cbeneficiaries? cbeneficiaries.filter((item)=> {
            return item!= selectedId
          }) : []
          this.form.controls['beneficiaries'].setValue(newBeneficiaries)
          if (newBeneficiaries.length) {
            this.form.controls['contingentBeneficiaries'].setValue(newCBeneficiaries)
          }
          else{
            this.form.controls['contingentBeneficiaries'].setValue([])
          }
          this.validRelationships.beneficiaries = this.validRelationships.beneficiaries.map((item) => {
            return {
                ...item,
                disabled: selectedId == item.value
            }
          })
          this.validRelationships.contingentBeneficiaries = this.validRelationships.contingentBeneficiaries.map((item) => {
            return {
                ...item,
                disabled: selectedId == item.value
            }
          })
          return
        }
      }
      
    }
  }

  removePercentages(){
    if(this.form.controls['interest_rate'] && this.form.controls['interest_rate'].value.includes('%')){
      const newValue = this.form.controls['interest_rate'].value.substring(0, this.form.controls['interest_rate'].value.length-1)
      this.form.controls['interest_rate'].setValue(newValue)
    }
  }

  save() {
    this.removePercentages()
    const hasStatus = this.form.controls["status"];
    const status = hasStatus ? this.form.controls["status"].value : "";
    const beneficiariesPercentages = Object.values(
      this.beneficiariesForm.value
    );
    const contingentBeneficiariesPercentages = Object.values(
      this.contingentBeneficiariesForm.value
    );
    this.form.addControl(
      "beneficiariesPercentages",
      new FormControl(beneficiariesPercentages)
    );
    this.form.addControl(
      "contingentBeneficiariesPercentages",
      new FormControl(contingentBeneficiariesPercentages)
    );
    columnsToShowAsPercent.forEach(element => {
      if(this.form.controls[element]){
        const percentIndex = this.form.controls[element].value.indexOf('%')
        if (percentIndex !== -1) {
          const newValue = this.form.controls[element].value.substring(0, this.form.controls[element].value.length-1)
          this.form.controls[element].setValue(newValue)
        }
      }
    });
    if (
      hasStatus &&
      status == "In Place" &&
      this.firstValidStatus != "In Place"
    ) {
      const dialogRef = this.confirmationStatusDialog.open(
        ConfirmStatusComponent,
        {
          width: "600px",
          disableClose: true,
          panelClass: this.theme,
        }
      );
      dialogRef.componentInstance.handleOnNext.subscribe((res) => {
        if (res) {
          this.handleOnSave.next(this.form);
        }
        this.dialogRef.close();
      });
    } else {
      this.handleOnSave.next(this.form);
      this.dialogRef.close();
    }
  }

  delete() {
    this.handleOnDelete.next(this.form);
  }
}
