import { Routes } from '@angular/router';
import { ESettingsComponent } from './e.settings.component';
import { CompanyComponent } from './company/company.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PaymentComponent } from './payment/payment.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ProfileComponent } from './profile/profile.component';
import { ProspectComponent } from './prospect/prospect.component';
import { ReportsComponent } from './reports/reports.component';
import { SecurityComponent } from './security/security.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { AuthGuardAdvisor } from '../../shared/services/auth/authAdvisor.guard';
import { ProviderComponent } from './integrations/views/provider/provider.component';
import { IntegrationsHomeComponent } from './integrations/views/home/integrations-home.component';
import { SalesforceIntegrationComponent } from './integrations/templates/providers/salesforce-integration/salesforce-integration.component';
import { RedtailIntegrationComponent } from './integrations/templates/providers/redtail-integration/redtail-integration.component';
import { OrionIntegrationComponent } from './integrations/templates/providers/orion-integration/orion-integration.component';
import { SalesforceHomeComponent } from './integrations/templates/providers/salesforce-integration/salesforce-home/salesforce-home.component';
import { SalesforceMapperComponent } from './integrations/templates/providers/salesforce-integration/salesforce-mapper/salesforce-mapper.component';
import { SalesforceInstructionsComponent } from './integrations/templates/providers/salesforce-integration/salesforce-instructions/salesforce-instructions.component';
import { SuccessIntegrationsComponent } from './integrations/templates/external-providers-interactions/success-integration/success-integration.component';
import { deepStrictEqual } from 'assert';
import { SalesforceClientsComponent } from './integrations/templates/providers/salesforce-integration/salesforce-clients/salesforce-clients.component';
import { RedtailHomeComponent } from './integrations/templates/providers/redtail-integration/redtail-home/redtail-home.component';
import { RedtailInstructionsComponent } from './integrations/templates/providers/redtail-integration/redtail-instructions/redtail-instructions.component';
import { RedtailClientsComponent } from './integrations/templates/providers/redtail-integration/redtail-clients/redtail-clients.component';
import { WealthboxIntegrationComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-integration.component';
import { WealthboxHomeComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-home/wealthbox-home.component';
import { WealthboxInstructionsComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-instructions/wealthbox-instructions.component';
import { WealthboxClientsComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-clients/wealthbox-clients.component';
import { MoneyguidIntegrationComponent } from './integrations/templates/providers/moneyguid-integration/moneyguid-integration.component';
import { MoneyguideHomeComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-home/moneyguide-home.component';
import { MoneyguideInstructionsComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-instructions/moneyguide-instructions.component';
import { MoneyguideClientsComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-clients/moneyguide-clients.component';
import { RiskalyzeIntegrationComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-integration.component';
import { RiskalyzeHomeComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-home/riskalyze-home.component';
import { RiskalyzeInstructionsComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-instructions/riskalyze-instructions.component';
import { RiskalyzeClientsComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-clients/riskalyze-clients.component';
import { OrionInstructionsComponent } from './integrations/templates/providers/orion-integration/orion-instructions/orion-instructions.component';
import { OrionClientsComponent } from './integrations/templates/providers/orion-integration/orion-clients/orion-clients.component';
import { PrecisefpIntegrationComponent } from './integrations/templates/providers/precisefp-integration/precisefp-integration.component';
import { PrecisefpHomeComponent } from './integrations/templates/providers/precisefp-integration/precisefp-home/precisefp-home.component';
import { PrecisefpInstructionsComponent } from './integrations/templates/providers/precisefp-integration/precisefp-instructions/precisefp-instructions.component';
import { PrecisefpClientsComponent } from './integrations/templates/providers/precisefp-integration/precisefp-clients/precisefp-clients.component';
import { OrionHomeComponent } from './integrations/templates/providers/orion-integration/orion-home/orion-home.component';
import { ProviderGuard } from 'app/shared/services/integration/utils/provider.guard';

export const ESettingsRoutes: Routes = [
  {
    path: 'settings',
    component: ESettingsComponent,
    data: { title: 'Settings', breadcrumb: 'settings' },
    children: [
    {
      path: 'profile',
      component: ProfileComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'company',
      component: CompanyComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'preferences',
      component: PreferencesComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'recommendations',
      component: RecommendationsComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'reports',
      component: ReportsComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'security',
      component: SecurityComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'notifications',
      component: NotificationsComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'billing',
      component: PaymentComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'prospect',
      component: ProspectComponent,
      canActivate: [AuthGuardAdvisor]
    },
    {
      path: 'external-providers-interactions',
      component: IntegrationsComponent,
      canActivate: [AuthGuardAdvisor],
      children: [
        {
          path: '',
          component: IntegrationsHomeComponent,
          canActivate: [AuthGuardAdvisor]
        },
        {
          path: 'salesforce',
          component: SalesforceIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: SalesforceHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: SalesforceInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'mapper',
              component: SalesforceMapperComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'client-list',
              component: SalesforceClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'redtail',
          component: RedtailIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: RedtailHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: RedtailInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: RedtailClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'wealthbox',
          component: WealthboxIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: WealthboxHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: WealthboxInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: WealthboxClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'moneyguide',
          component: MoneyguidIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: MoneyguideHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: MoneyguideInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: MoneyguideClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'riskalyze',
          component: RiskalyzeIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: RiskalyzeHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: RiskalyzeInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: RiskalyzeClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'orion',
          component: OrionIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: OrionHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: OrionInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: OrionClientsComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
          ]
        },
        {
          path: 'precisefp',
          component: PrecisefpIntegrationComponent,
          canActivate: [AuthGuardAdvisor],
          children: [
            {
              path: '',
              component: PrecisefpHomeComponent,
              canActivate: [AuthGuardAdvisor, ProviderGuard]
            },
            {
              path: 'instructions',
              component: PrecisefpInstructionsComponent,
              canActivate: [AuthGuardAdvisor]
            },
            {
              path: 'client-list',
              component: PrecisefpClientsComponent,
              canActivate: [AuthGuardAdvisor,ProviderGuard]
            },
          ]
        }
      ]
    }]
  }
];
