import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';


export interface DataSummarized {
  textA: string,
  textAColor: string,
  textB?: string | null,
  textBColor?: string | null,
  iconUrl?: string | null,
  isBold: boolean,
  hasBottomBorder: boolean,
  dotColor?: string | null,
  percent?: boolean
}

@Component({
  selector: 'data-summarize',
  templateUrl: './data-summarize.component.html',
  styleUrls: ['./data-summarize.component.scss']
})
export class DataSummarizeComponent implements OnInit, OnChanges {

  @Input() dataSections: DataSummarized[] = []
  @Input() isFixed: boolean = true;
  @Input() isPrintable: boolean = false;
  @Input() borderColor: string = '';
  @Input() fromTaxes: boolean = false;

  constructor() { }

  public dataSections_example: DataSummarized[] = [
    {
      textA: 'List of assets',
      textAColor: '#AC8E68',
      iconUrl: '',
      isBold: true,
      hasBottomBorder: false
    },
    {
      textA: 'Classic Ferrari',
      textAColor: '',
      iconUrl: 'parking',
      textB: '10000',
      isBold: false,
      hasBottomBorder: false
    },
    {
      textA: 'By Contract',
      textAColor: '#AC8E68',
      iconUrl: '',
      isBold: true,
      hasBottomBorder: false
    },
    {
      textA: 'John\'s Roth IRA',
      textAColor: '#FFFFFF',
      textB: '1200000',
      isBold: false,
      hasBottomBorder: true
    },
    {
      textA: 'John\'s IRA',
      textAColor: '#FFFFFF',
      textB: '1200000',
      isBold: false,
      hasBottomBorder: false
    },
    {
      textA: 'By Trust',
      textAColor: '#AC8E68',
      textB: '',
      textBColor: '',
      iconUrl: '',
      isBold: true,
      hasBottomBorder: false
    },
    {
      textA: 'Brokerage Account',
      textAColor: '#FFFFFF',
      textB: '1200000',
      isBold: false,
      hasBottomBorder: true
    },
    {
      textA: 'Savings Account',
      textAColor: '#FFFFFF',
      textB: '1200000',
      isBold: false,
      hasBottomBorder: true
    },
    {
      textA: 'Total estate',
      textAColor: '#FFFFFF',
      textB: '1200000',
      isBold: true,
      hasBottomBorder: false
    }
  ]


  ngOnInit(): void {
    //console.log('dataSections**', this.dataSections, this.borderColor)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSections = changes.dataSections.currentValue;
    // console.log('dataSectionsChanges**', this.dataSections)
  }

  isNumber(value: any){
    return typeof value === 'number'
  }

}
