//Angular
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { urlPrependRootContext } from 'app/shared/helpers/url.helper';

//RX
import { map, take } from 'rxjs/operators';

//Project
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardAdvisor implements CanActivate {
    public authToken;

    constructor(
      private router: Router,
      private authService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      console.log('state*', state.url);
        if(this.authService.isSessionUserTypeValid('advisor')){
          if (this.authService.getCurrentUserRole() === 'support_account'){
            let restrictions = this.authService.getCurrentRoleRestrictions();
            switch (state.url) {
              case '/settings/company':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('settings:company');
                break;
              case '/settings/preferences':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('settings:preferences');
                break;
              case '/settings/reports':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('settings:reports');
                break;
              case '/settings/prospect':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('settings:prospectaccelerator');
                break;
              /*case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('clientlist:add_prospect');
                break;
              case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('clientlist:delete_client');
                break;
              case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('profile:professionals');
                break;
              case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('profile:goals');
                break;
              case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('profile:clientinfo');
                break;
              case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('profile:areasanalysis');
                break;
                */
                case '/advisor/areas':
                  console.log('**No tienes acceso a : ', state.url);
                  return !restrictions.includes('planningopps:opportunities');
                  break;
              case '/advisor/areas/fulltax/undefined': //(undefined) url not found
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('planningopps:historicalopps');
                break;
              case '/advisor/areas/fulltax/taxbracket':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('planningopps:bracketpyramid');
                break;
              case '/advisor/assets/tax-projections':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('planningopps:taxprojections');
                break;
              case '/advisor/taskmanager':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('taskmanager:recommendations');
                break;
              /*case '':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('taskmanager:actions');
                break;*/
              case '/advisor/summary_new/estate':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('snaphsot:estate');
                break;
              case '/advisor/summary_new/tax':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('snaphsot:tax');
                break;
              case '/advisor/summary_new/insurance-auto':
                console.log('**No tienes acceso a : ', state.url);
                // return !restrictions.includes('snaphsot:tax');
                return true;
                break;
              case '/advisor/summary_new/insurance-home-umbrella':
                console.log('**No tienes acceso a : ', state.url);
                // return !restrictions.includes('snaphsot:tax');
                return true;
                break;
              case '/advisor/assets':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('simulators');
                break;
              case '/advisor/assets/estate-lab':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('simulators:estatelab');
                break;
              case '/advisor/assets/tax-projections':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('simulators:taxprojections');
                break;
              case '/advisor/assets/roth-conversion':
                console.log('**No tienes acceso a : ', state.url);
                return !restrictions.includes('simulators:roth');
                break;

            }

            let opportunitiesRegex = /\/advisor\/areas\/(\w)+\/opportunities/gm
            if(state.url.match(opportunitiesRegex)){
              console.log('**No tienes acceso a : ', state.url);
              return !restrictions.includes('planningopps:opportunities');
            }

            return true
          }
            return true
        } else if(localStorage.getItem('mgp') === 'true'){
            this.router.navigate([urlPrependRootContext('/signin')], { queryParams: { originUrl: location.href } });
        } else if(localStorage.getItem('orion') === 'true'){
            this.router.navigate([urlPrependRootContext('/signin')], { queryParams: { originUrl: location.href } });
        }
        return false;

    }
}
