import { Component, Input, OnInit } from '@angular/core';

export interface DocumentType {
  section: ('Will' | 'Revocable' | 'Irrevocable' | 'POA' | 'Healthcare' | 'Bundled' | 'Other');
  nameDocument: string;
  date: (Date | 'Unknown' | null);
  type: ('Client' | 'Coclient' | 'Joint');
  iconType: string;
}

interface FrontDocs {
  sectionName: string;
  documents: DocumentType[]
}
@Component({
  selector: 'list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss']
})

export class ListDocumentsComponent implements OnInit {

  @Input() documentList: DocumentType[] = []; //
  @Input() idxPage: number = 0;
  @Input() totalPages: number = 0;
  @Input() platformView: boolean = false;

  public sectionsDocuments: any;

  constructor() { }

  ngOnInit(): void {
    this.sectionsDocuments = this.compileDocumentList(this.documentList);
    console.log('compileDocumentList*', this.documentList);
  }

  compileDocumentList(documentList: DocumentType[]): ({firstSection: FrontDocs[], secondSection: FrontDocs[]}) {
    let sectionsOrdered: string[] = ['Will', 'POA', 'Healthcare', 'Revocable', 'Irrevocable', 'Bundled', 'Other'];
    let doubleColumnElements = documentList.filter(section => section.type != 'Joint');
    let oneColumnElements = documentList.filter(section => section.type === 'Joint');

    let firstSection = [];
    let secondSection = [];

    let sectionPrintableName = {
      'Will': 'Will',
      'Revocable': 'Revocable Trust',
      'Irrevocable': 'Irrevocable Trust',
      'POA': 'POA',
      'Healthcare':'Healthcare Directive',
      'Bundled':'Bundled',
      'Other': 'Other'
    }

    sectionsOrdered.map(sectionName => {
      let firstSectionDocs = doubleColumnElements.filter( element => element.section === sectionName);
      if(firstSectionDocs.length > 0){
        firstSection.push({
          sectionName: sectionPrintableName[sectionName],
          documents: firstSectionDocs
        });
      }

      let secondSectionDocs = oneColumnElements.filter( element => element.section === sectionName);
      if(secondSectionDocs.length > 0){
        secondSection.push({
          sectionName: sectionPrintableName[sectionName],
          documents: secondSectionDocs
        });
      }
    });
    console.log('secondSection', secondSection);

    return {firstSection, secondSection};
  }

  getColumnsByType(type: string, documents: DocumentType[]){
    return documents.filter(document => document.type === type);
  }

}
