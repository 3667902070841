import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import { getEndPointUrlByContext } from '../../../shared/helpers/url.helper';

@Component({
  selector: 'add-personnel-dialog',
  templateUrl: 'add-personnel-dialog.html',
})

export class AddPersonnelDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: true }) submitButton: MatButton;

  addPersonnelForm: FormGroup;

  private clientID: string;
  private createNewPersonnel =  getEndPointUrlByContext('ClientProfile');
  public model: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<AddPersonnelDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {

    

    let getDataIfExist = (field) => {
      if (this.data['isContactData']) {
        return (field != null) ? field : '';
      }
      return '';
    }

    

    this.model = {
      personaName:    getDataIfExist(this.data['name']),
      personaService: getDataIfExist(this.data['phone']),
      personaPhone:   getDataIfExist(this.data['service']),
      personaEmail:   (getDataIfExist(this.data['email'])),
    }

    

    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = storagedClient.clientId;
    //The contact data has been injected?
    if (this.data['isContactData']) {

    }
    this.addPersonnelForm = new FormGroup({
      personaName: new FormControl(getDataIfExist(this.data['name']), [Validators.required, this.noWhitespaceValidator]),
      personaService: new FormControl(getDataIfExist(this.data['service'])),
      personaPhone: new FormControl(getDataIfExist(this.data['phone']), [ Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      personaEmail: new FormControl(getDataIfExist(this.data['email']), [Validators.email ]),
    })
  }

  save() {
    this.dialogRef.close(this.addPersonnelForm.value);

    let addPersonnelData = this.addPersonnelForm.value;

    this.submitButton.disabled = true;

    let contactData = {
      name: addPersonnelData.personaName.trim(),
      service: addPersonnelData.personaService.trim(),
      email: addPersonnelData.personaEmail.trim(),
      phone: addPersonnelData.personaPhone
    }

    let contactDataJson = JSON.stringify(contactData);
    

    let options = this.data['isContactData'] ? { headers: new HttpHeaders().set('Content-Type', 'application/json') } : { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let url: string = this.data['isContactData'] ? `${this.createNewPersonnel}${this.clientID}/contact/${this.data['_id']}` : `${this.createNewPersonnel}${this.clientID}/contact`;
    let action = this.data['isContactData'] ? this.http.put<any>(url, contactDataJson, options) : this.http.post<any>(url, contactDataJson, options);
    

    action.subscribe(
      success => {
        
        // this.data.contacts.push(success);
      },
      error => {
        console.log('Error!!!');
        this.snackBarError.open("Something wrong happened!.", "Dismiss", {
          duration: 6000,
          panelClass: 'alert-snackbar'
        });
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
