import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';

@Component({
  selector: 'app-expiration-session-component',
  templateUrl: './expiration-session-component.component.html',
  styleUrls: ['./expiration-session-component.component.scss']
})
export class ExpirationSessionComponentComponent extends CloseDialogAtLogOut implements OnInit  {

  public tMinus: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ExpirationSessionComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(dialogRef, data.authService)
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.tMinus = this.data.time;

    let updateClock = () => {
      if (this.tMinus <= 1) {
        this.dialogRef.close();
      } else {
        this.tMinus -= 1;
        setTimeout(updateClock, 1000);
      }
    }

    updateClock();
  }

}

