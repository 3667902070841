//Angular
import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

//RX
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

//3rd party
import { CookieService } from 'ngx-cookie-service';

//Project
import { environment } from '../../../../environments/environment';
import { AdvisorService } from '../advisor.service';
import { ExpirationSessionComponentComponent } from '../../../views/sessions/expiration-session-component/expiration-session-component.component';
import { MINUTES_TO_MS, SequentialJob, SequentialJobsDispatcher } from '../../helpers/SequentialJobsTimerDispatcher';
import { SessionToken } from './SessionToken.class';
import { getFirstUrlSegemnt, getEndPointUrlByContext, urlPrependRootContext, urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION }  from '../../helpers/url.helper';
import { WINDOW } from '../../helpers/window.provider';
import { AppSocketIoService } from '../socket.io.service';
import { ClientDataService } from '../client-data-service';

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {

  @Output() sessionClosed = new EventEmitter<any>();

    isLoggedIn = false;
    userType = null;
    userId = null;

    // Initialize counters

    public sequentialJobWorker: SequentialJobsDispatcher;
    public refreshTokenJob: SequentialJob;
    public autoExpireJob: SequentialJob;
    public autoExpireAlertJob: SequentialJob;
    public keepSocketAliveJob: SequentialJob;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
        private advisorService: AdvisorService,
        private cookieService: CookieService,
        private socketService: AppSocketIoService,
        @Inject(WINDOW) private window: Window,
    ) {
        eval("window.myService=this;"); //For debug only
        this.sequentialJobWorker = SequentialJobsDispatcher.getInstance();
        this.refreshTokenJob = new SequentialJob(9.5 * MINUTES_TO_MS, this.refreshTokenCounterFunction, 'refreshTokenJob');
        this.autoExpireJob = new SequentialJob(50 * MINUTES_TO_MS, this.sesionAutoExpireFunction, 'autoExpireJob');
        this.autoExpireAlertJob = new SequentialJob(49.5 * MINUTES_TO_MS, this.sessionAutoExpireAlertFunction, 'autoExpireAlertJob');
        this.keepSocketAliveJob = new SequentialJob(0.25 * MINUTES_TO_MS, this.keepSocketAliveFunction, 'keepSocketAlive');
    }

    // *********************************************************************

    login(email: string, password: string) {
        email = email.toLowerCase();
        this._commonLogoutBehaviour();
        if(! this.getCookieClientDevice()) this.setCookieInClientDevice();
        return this.http.post<any>(getEndPointUrlByContext('Login', this), { email: email, password: password })
            .pipe(
                map(data => {
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('socketType', 'WS');
                    this._commonSessionLoginBehaviour(data.token);
                })
            );
    }

    // *********************************************************************

    async logout(afterLogoutAction:string = null, reason?: string) {

        //An attempt to call logout requires a current valid session
        if (localStorage.sessionToken) {
          let body = (reason != undefined) ? {logoutReason: reason} : {logoutReason: 'undetermined'};
            await this.http.post<any>(getEndPointUrlByContext('Logout', this), body).subscribe(response => {
                console.log("Backend logged out successfully. Response: ", response);
            }, error => {
                console.log("ERROR. Backend logout response: ", error);
            });
        }

        //Redirect
        //[urlPrependRootContext('/signin')

        if (!afterLogoutAction) this.router.navigate([urlPrependRootContext('/signin')]); // this.router.navigate(['advisor/signin']);
        if (afterLogoutAction == 'inactivity'){
            this.router.navigate([urlPrependRootContext('/signin')], { queryParams: { a: 'inactivity' } });
          //  this.router.navigate(['advisor/signin'], { queryParams: { a: 'inactivity' } });
        }

        //Always clear the local data related to the session
        this._commonLogoutBehaviour();
    }


    // *********************************************************************

    forceLogout(token, email) {
        return this.http.post<any>(`${getEndPointUrlByContext("ForceLogout")}`, { token: token, email: email }).pipe(
            map(data => {
                this._commonLogoutBehaviour();
            })
        );
    }

    // *********************************************************************

    twoFactorAuth(idAdvisor, code) {
        return this.http.post<any>(`${environment.apiTwoFactorAuth}${idAdvisor}`, { verificationCode: code })
    }

    // *********************************************************************

    renewToken() {
        localStorage.setItem('renewingToken', 'true');
        let refreshToken: string = localStorage['refreshToken'] ? localStorage.refreshToken : '';
        return this.http.post<any>(getEndPointUrlByContext('RenewToken', this), { refreshToken: refreshToken }).pipe(
            map(data => {
                //Set the new token
                if(!Boolean(data.token)){
                  this.logout('inactivity', 'renewToken');
                }else{

                  localStorage.setItem('sessionToken', data.token);
                  localStorage.removeItem('renewingToken');
              /*  this.socketService.initSocketAndSubscribeToDefaultEvents(); */
                }

            })
        );

        //Logica en caso de error, que pasa si no hay conexion? R: No intentar nuevamente!
    }

    // *********************************************************************

    isSessionActive(): boolean {
        if (this.isLoggedIn == true) return true
        if (localStorage.getItem('sessionToken')) {
            let token = localStorage.getItem('sessionToken');
            this._commonSessionLoginBehaviour(token);
            return true;
        }
        return false;
    }


    // *********************************************************************

    isSessionUserTypeValid(userType: string): boolean {
        // Load session if previously, and make session data avilable to this.
        let isSessionActive = this.isSessionActive();
        //Validate
        if (isSessionActive && (this.userType == userType || userType == 'ANY')) return true;
        return false;
    }

    // *********************************************************************

    isAdvisor(): boolean {
        let result = false;
        if(this.isSessionActive()){
            result =  (this.userType == 'advisor');
        }else{
            result =  (getFirstUrlSegemnt(this.router.url) == 'advisor');
        }
        return result;
    }

    // *********************************************************************

    isClient(): boolean {
        let result = false;
        if(this.isSessionActive()){
            result =  (this.userType == 'client');
        }else{
            result =  (getFirstUrlSegemnt(this.router.url) == 'client');
        }
        return result;
    }

    public getTokenDecodedData(){
        return this._decodedJwtToken(localStorage.getItem('sessionToken'));
    }

    // *********************************************************************

    private _commonLogoutBehaviour(): void {

      console.log('Event emit: Session close');
        this.sessionClosed.emit({message: 'session closed'});
        this.sequentialJobWorker.stopAllJobs();
        this.socketService.socketClose();
        ClientDataService.unsubscribe();
        sessionStorage.clear();
        //let mgp = localStorage.getItem('mgp');
        //let mgpData = localStorage.getItem('mgpData');
        localStorage.clear();
        //mgp !== null && localStorage.setItem("mgp",mgp);
        //mgpData !== null && localStorage.setItem("mgpData",mgpData);
        this.userType = null;
        this.isLoggedIn = false;


    }

    private _decodedJwtToken(token: string): any {
        let helper = new JwtHelperService();
        let decodedTokenData = helper.decodeToken(token);
        return decodedTokenData;
    }

    /**
     * @returns The current user's role as a string. null if no session is active
    */
    getCurrentUserRole(): (string | null){
      if(!this.isSessionActive()){ return undefined }
      let decodedToken = this.getTokenDecodedData();
      if(this.isAdvisor()){
        return (decodedToken.role != undefined) ? decodedToken.role : "none"
        return "support_account"; //"full_account";
      }else if(this.isClient()){
        return "none";
      }
    }

    /**
     *
     * @returns Array of strings with the user's role permissions (blacklist). null if no session is active.
     *
     * Restricted sections for the role, if the array is empty the user has all access
     *
     * Format: section:resource@create|retrieve|update|delete|others
     */
    getCurrentRoleRestrictions(): (string[] | null){
      if(!this.isSessionActive()){ return undefined }
      let decodedToken = this.getTokenDecodedData();
      if(this.isAdvisor()){
        /*return [
          "settings:preferences",
          "settings:company",
          "settings:reports",
          "settings:prospectaccelerator",
          "clientlist:add_prospect",
          "clientlist:delete_client",
          "profile:professionals",
          "profile:goals",
          "profile:clientinfo",
          "profile:areasanalysis",
          "planningopps:opportunities",
          "planningopps:historicalopps",
          "planningopps:bracketpyramid",
          "planningopps:taxprojections",
          "taskmanager:recommendations",
          "taskmanager:actions",
          "snapshots",
          "snaphsot:estate",
          "snaphsot:tax",
          "simulators",
          "simulators:estatelab",
          "simulators:taxprojections",
          "simulators:roth",
          "reports"
        ]*/
        return (decodedToken.restrictions != undefined) ? decodedToken.restrictions : []
      }else if(this.isClient()){
        return []
      }
    }

    currentRoleHasAccessTo(restriction: string): boolean{
      return !this.getCurrentRoleRestrictions().some(restriction_ => restriction_.includes(restriction))
    }

    private _commonSessionLoginBehaviour(sessionTokenEncoded: any) {
      //Initilalize list of jobs in the worker.
        this.sequentialJobWorker.updateOrInstertSequentialJob(this.refreshTokenJob);
        this.sequentialJobWorker.updateOrInstertSequentialJob(this.autoExpireJob);
        this.sequentialJobWorker.updateOrInstertSequentialJob(this.autoExpireAlertJob);
        this.sequentialJobWorker.updateOrInstertSequentialJob(this.keepSocketAliveJob);

        let decodedToken = SessionToken.sessionTokenFromEncoded(sessionTokenEncoded)
        this.userType = decodedToken.userType;
        this.userId = decodedToken.userId;
        localStorage.setItem('sessionToken', sessionTokenEncoded);
        localStorage.setItem('userId', decodedToken.userId);
        if( !urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ){ localStorage.setItem('DISPLAY_LOGS', 'true'); }
        this.sequentialJobWorker.resumeAllJobs();
        this.isLoggedIn = true;

        this.advisorService.getAdvisorData().subscribe(advisorData =>{
          localStorage.setItem("betaAccess", JSON.stringify(advisorData.betaAccess));
          //Find and load the disabledFeatures in the session storage
          if(advisorData.disabledFeatures != undefined){
            localStorage.setItem("dFeatures", JSON.stringify(advisorData.disabledFeatures));
            console.log("authService: dFeatures detected");
          }else{
            localStorage.setItem("dFeatures", JSON.stringify([]));
            console.log("authService: NO dFeatures detected");
          }
        });

        this.http.post(environment['apiFlushNotifications'], {} ).subscribe();

    }

    // *********************************************************************

    refreshTokenCounterFunction = () => {
        this.renewToken().subscribe(data => {
            console.log(new Date(), " Current session token refreshed by frontend jobsTimerDispatcher");
        });
    };

    sesionAutoExpireFunction = () => {
        //if(!this.conetxtInProduction()) return;
        console.log("Logged out by: sessionAutoExpireFunction");
        this.logout('inactivity', 'inactivity. Logged out by: sessionAutoExpireFunction');
    };

    sessionAutoExpireAlertFunction = () => {
        //(if(!this.conetxtInProduction()) return;
        if(localStorage.getItem('sessionToken') != null){
          let expirationDialog = this.dialog.open(ExpirationSessionComponentComponent, {
            data: {
                time: 30,
                authService: this
            }
        });
        expirationDialog.afterClosed().subscribe(result => {
            if (result) {
                this.autoExpireJob.reset();
                this.autoExpireAlertJob.reset();
            }
        });
        }
    }

    keepSocketAliveFunction = () => {
      this.socketService.vertifySocketOrReconnect();
    }

    // *********************************************************************

    setCookieInClientDevice() {
        this.cookieService.set( 'clidev',
        this. generateCookieClientDevice(),
        new Date(new Date().setFullYear(new Date().getFullYear() + 10))
        );
    }

    renewCookieClientDevice() {

    }

    generateCookieClientDevice(): string {
        return this.userId + String(Number(new Date()));
    }

    getCookieClientDevice(): string{
        return this.cookieService.get('clidev');
    }

    //

    public conetxtInProduction():boolean{
        // return true
        return this.window.location.hostname == 'app.fpalpha.com';
    }


}
