import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { ReleasesComponent } from './shared/components/releases/releases.component';
import { TaxCalculatorV2Component } from './shared/components/tax-calculator-v2/tax-calculator-v2.component';
import { FailComponent } from './views/enterprise-settings/integrations/templates/external-providers-interactions/fail/fail.component';
import { PendingComponent } from './views/enterprise-settings/integrations/templates/external-providers-interactions/pending/pending.component';
import { SsoComponent } from './views/enterprise-settings/integrations/templates/external-providers-interactions/sso/sso.component';
import { SuccessComponent } from './views/enterprise-settings/integrations/templates/external-providers-interactions/success/success.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { AuthGuardAdvisor } from './shared/services/auth/authAdvisor.guard';
import { AuthGuardClient } from './shared/services/auth/authClient.guard';
import { ExternalSnapshotReviewComponent } from './views/external-snapshot-review/external-snapshot-review.component';
import { GenericScreenComponent } from './views/questionnaires/generic-screen-component/generic-screen.component';
import { EstateHubComponent } from './views/questionnaires/hub-multi-area/estate-hub/estate-hub.component';
import { HubMultiAreaComponent } from './views/questionnaires/hub-multi-area/hub-multi-area.component';
import { HubComponent } from './views/questionnaires/hub/hub.component';
import { QAutoComponent } from './views/questionnaires/q-auto/q-auto.component';
import { QCreditorComponent } from './views/questionnaires/q-creditor/q-creditor.component';
import { QCrossborderComponent } from './views/questionnaires/q-crossborder/q-crossborder.component';
import { QDisabilityComponent } from './views/questionnaires/q-disability/q-disability.component';
import { QDivorceComponent } from './views/questionnaires/q-divorce/q-divorce.component';
import { QElderComponent } from './views/questionnaires/q-elder/q-elder.component';
import { QEstateComponent } from './views/questionnaires/q-estate/q-estate.component';
import { QExpenseComponent } from './views/questionnaires/q-expense/q-expense.component';
import { QGeneralComponent } from './views/questionnaires/q-general/q-general.component';
import { QHomeComponent } from './views/questionnaires/q-home/q-home.component';
import { QIdentityComponent } from './views/questionnaires/q-identity/q-identity.component';
import { QLifeComponent } from './views/questionnaires/q-life/q-life.component';
import { QLtcComponent } from './views/questionnaires/q-ltc/q-ltc.component';
import { QMedicalComponent } from './views/questionnaires/q-medical/q-medical.component';
import { QMortgageComponent } from './views/questionnaires/q-mortgage/q-mortgage.component';
import { QNewProspectComponent } from './views/questionnaires/q-new-prospect/q-new-prospect.component';
import { ProspectAreaDescription } from './views/questionnaires/q-new-prospect/submitted/area-description/prospect-area-description.component';
import { QNewProspectSubmittedComponent } from './views/questionnaires/q-new-prospect/submitted/submitted.component';
import { QPropertyComponent } from './views/questionnaires/q-property/q-property.component';
import { QProspectComponent } from './views/questionnaires/q-prospect/q-prospect.component';
import { SubmittedMessageComponent } from './views/questionnaires/q-prospect/submitted-message/submitted-message.component';
import { QStudentComponent } from './views/questionnaires/q-student/q-student.component';
import { QTaxfullComponent } from './views/questionnaires/q-taxfull/q-taxfull.component';
import { ChangePasswordComponent } from './views/sessions/change-password/change-password.component';
import { ForgotPasswordComponent } from './views/sessions/forgot-password/forgot-password.component';
import { SigninComponent } from './views/sessions/signin/signin.component';
import { TaxProjectionsComponent } from './views/tax-projections/tax-projections.component';
import { QTaxComponent } from './views/questionnaires/q-tax/q-tax.component';
import {InsuranceHomeUmbrellaModule} from './views/new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.module';
import { SsoLoginComponent } from './views/sessions/sso-login/sso-login.component';
import { GeneralQuestionnaireComponent } from './views/questionnaires/general-questionnaire/general-questionnaire.component';
import { EstateSnapshotV3Component } from './shared/components/estate-snapshot-v3/estate-snapshot-v3.component';
import { GraphTestComponent } from './shared/components/estate-snapshot-v3/graph-test/graph-test.component';
import { EstateSnapshotReportComponent } from './shared/components/estate-snapshot-v3/estate-snapshot-report/estate-snapshot-report.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'advisor/signin',
    pathMatch: 'full'
  },
  {
    path: 'advisor/signin',
    component: SigninComponent,
  },
  {
    path: 'advisor/signin/sso',
    component: SsoLoginComponent
  },
  {
    path: 'client/signin',
    component: SigninComponent,
  },
  {
    path: 'advisor/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'advisor/change-password',
    component: ChangePasswordComponent,
  },
  {
    path: 'client/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path:'join',
    component: QNewProspectComponent,
  },
  {
    path:'prospect/submitted-success',
    component: SubmittedMessageComponent,
  },
  {
    path: 'prospect/form',
    component: QNewProspectComponent,
  },
  {
    path: 'external-snapshot-preview',
    component: ExternalSnapshotReviewComponent,
  },
  {
    path: 'prospect/form-new',
    children: [
      {
        path: 'submitted',
        component: QNewProspectSubmittedComponent,
      },
      {
        path: 'submitted/area/:area',
        component: ProspectAreaDescription,
      },
    ],
  },
  {
    path: 'prospect/alert',
    component: GenericScreenComponent,
  },
  {
    path: 'advisor/sso',
    component: SsoComponent,
  },
  {
    path: 'advisor',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardAdvisor],
    children: [
      {
        path: 'clients',
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule),
        data: { title: 'Clients', breadcrumb: 'clients' }
      },

      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'dashboard' }
      },

      {
        path: 'areas',
        loadChildren: () => import('./views/areas/areas.module').then(m => m.AreasModule),
        data: { title: 'Areas', breadcrumb: 'areas' }
      },

      {
        path: 'areas/:area',
        loadChildren: () => import('./views/areas/areas.module').then(m => m.AreasModule),
        data: { title: 'Areas', breadcrumb: 'areas' }
      },

      {
        path: 'areas/:area/:section',
        loadChildren: () => import('./views/areas/areas.module').then(m => m.AreasModule),
        data: { title: 'Areas', breadcrumb: 'areas' },
        canActivate: [AuthGuardAdvisor]
      },

      {
        path: 'taskmanager',
        loadChildren: () => import('./views/taskmanager/taskmanager.module').then(m => m.TaskmanagerModule),
        data: { title: 'Task Manager', breadcrumb: 'Task Manager' },
        canActivate: [AuthGuardAdvisor]
      },

      {
        path: 'assets',
        loadChildren: () => import('./views/assets/assets.module').then(m => m.AssetsModule),
        data: { title: 'Assets', breadcrumb: 'assets' }
      },

      {
        path: 'assets/roth-conversion',
        loadChildren: () => import('./shared/components/roth/roth-v2/roth-v2.module').then(m => m.RothConversionModule),
        data: { title: 'Roth Conversion', breadcrumb: 'Roth Conversion' }
      },

      {
        path: 'assets/roth-conversionv1',
        loadChildren: () => import('./shared/components/roth/roth-conversion/roth-conversion.module').then(m => m.RothConversionModule),
        data: { title: 'Roth Conversion', breadcrumb: 'Roth Conversion' }
      },


      /*{
        path: 'assets/estate-lab',
        loadChildren: () => import('./shared/components/estate-lab/estate-lab.module').then(m => m.EstateLabModule),
        data: { title: 'Estate lab', breadcrumb: 'Estate lab' },
        canActivate: [AuthGuardAdvisor]
      },*/

      {
        path: 'assets/estate-lab-2',
        loadChildren: () => import('./shared/components/estate-lab-v2/estate-lab-v2.module').then(m => m.EstateLabV2Module),
        data: { title: 'Estate lab', breadcrumb: 'Estate lab' }
      },

      {
        path: 'assets/tax-projections',
        component: TaxCalculatorV2Component,
        canActivate: [AuthGuardAdvisor]
      },

      {
        path: 'assets/tax-projections2.0',
        component: TaxProjectionsComponent,
      },

      {
        path: 'client-profile',
        loadChildren: () => import('./views/clientprofile/clientprofile.module').then(m => m.ClientprofileModule),
        data: { title: 'Client Profile', breadcrumb: 'Client Profile' }
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/complex-reports/complex-reports.module').then(m => m.ComplexReportsModule),
        data: { title: 'Reports', breadcrumb: 'Reports' }
      },

      {
        path: 'summary_new',
        children: [
          {
            path: '',
            loadChildren: () => import('./views/new-summary/new-summary.module').then(m => m.NewSummaryModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' }
          },
          {
            path: 'tax',
            loadChildren: () => import('./views/new-summary/areas/fulltax/fulltax.module').then(m => m.FulltaxModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor]
          },
          /* {
            path: 'estate',
            loadChildren: () => import('./views/new-summary/areas/estate/estate.module').then(m => m.EstateModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor]
          }, */
          {
            path: 'insurance-auto',
            loadChildren: () => import('./views/new-summary/areas/insurance-auto/insurance-auto.module').then(m => m.InsuranceAutoModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor]
          },
          {
            path: 'insurance-home-umbrella',
            loadChildren: () => import('./views/new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.module').then(m => m.InsuranceHomeUmbrellaModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor]
          },
          {
            path: 'estate-v3',
            component: EstateSnapshotV3Component,
            canActivate: [AuthGuardAdvisor]
            /* loadChildren: () => import('./views/new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.module').then(m => m.InsuranceHomeUmbrellaModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor] */
          },
          {
            path: 'estate-pdf-pdf',
            component: EstateSnapshotReportComponent,
            canActivate: [AuthGuardAdvisor]
            /* loadChildren: () => import('./views/new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.module').then(m => m.InsuranceHomeUmbrellaModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor] */
          },
          {
            path: 'graph-test',
            component: GraphTestComponent,
            canActivate: [AuthGuardAdvisor]
            /* loadChildren: () => import('./views/new-summary/areas/insurance-home-umbrella/insurance-home-umbrella.module').then(m => m.InsuranceHomeUmbrellaModule),
            data: { title: 'Snapshot', breadcrumb: 'snapshot' },
            canActivate: [AuthGuardAdvisor] */
          }
        ]
      }
    ]
  },
  {
    path: 'client',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardClient],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'dashboard' }
      },

      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'dashboard' }
      },

      {
        path: 'areas',
        loadChildren: () => import('./views/areas/areas.module').then(m => m.AreasModule),
        data: { title: 'Areas', breadcrumb: 'areas' }
      },

      {
        path: 'areas/:area',
        loadChildren: () => import('./views/areas/areas.module').then(m => m.AreasModule),
        data: { title: 'Areas', breadcrumb: 'areas' }
      },

      {
        path: 'taskmanager',
        loadChildren: () => import('./views/taskmanager/taskmanager.module').then(m => m.TaskmanagerModule),
        data: { title: 'Task Manager', breadcrumb: 'Task Manager' }
      },

      {
        path: 'client-profile',
        loadChildren: () => import('./views/clientprofile/clientprofile.module').then(m => m.ClientprofileModule),
        data: { title: 'Client Profile', breadcrumb: 'Client Profile' }
      }
    ]
  },
  // Common space with auth guard
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'questionnaire-general',
        component: GeneralQuestionnaireComponent,
      },
      {
        path: 'questionnaire-home',
        component: QHomeComponent,
      },

      {
        path: 'questionnaire-auto',
        component: QAutoComponent,
      },

      {
        path: 'questionnaire-disability',
        component: QDisabilityComponent,
      },

      {
        path: 'questionnaire-fulltax',
        component: QTaxComponent,
      },

      {
        path: 'questionnaire-estate',
        component: QEstateComponent,
      },

      {
        path: 'questionnaire-identity',
        component: QIdentityComponent,
      },

      {
        path: 'questionnaire-medical',
        component: QMedicalComponent,
      },

      {
        path: 'questionnaire-student',
        component: QStudentComponent,
      },

      {
        path: 'questionnaire-ltc',
        component: QLtcComponent,
      },

      {
        path: 'questionnaire-mortgage',
        component: QMortgageComponent,
      },

      {
        path: 'questionnaire-life',
        component: QLifeComponent,
      },

      {
        path: 'questionnaire-divorce',
        component: QDivorceComponent,
      },

      {
        path: 'questionnaire-expense',
        component: QExpenseComponent,
      },

      {
        path: 'questionnaire-elder',
        component: QElderComponent,
      },

      {
        path: 'questionnaire-creditor',
        component: QCreditorComponent,
      },

      {
        path: 'questionnaire-crossborder',
        component: QCrossborderComponent,
      },

      {
        path: 'questionnaire-prospect',
        component: QProspectComponent,
      },
    ]
  },
  {
    path: 'questionnaire-property',
    canActivate: [AuthGuard],
    component: QPropertyComponent
  },
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      colorMode: 'light'
    },
    children: [
      {
        path: 'hub',
        canActivate: [AuthGuard],
        component: HubComponent,
      },
    ]
  },
  {
    path: 'hub/:area',
    canActivate: [AuthGuard],
    component: HubMultiAreaComponent
  },
  {
    path: 'estate-documents',
    canActivate: [AuthGuard],
    component: EstateHubComponent,
  },
  {
    path: 'releases',
    canActivate: [AuthGuard],
    component: ReleasesComponent
  },
  {
    path: 'salesforce/success',
    canActivate: [AuthGuard],
    component: SuccessComponent
  },
  {
    path: 'salesforce/pending',
    canActivate: [AuthGuard],
    component: PendingComponent
  },
  {
    path: 'salesforce/fail',
    canActivate: [AuthGuard],
    component: FailComponent
  },
  {
    path: 'wealthbox/success',
    canActivate: [AuthGuard],
    component: SuccessComponent
  },
  {
    path: 'wealthbox/pending',
    canActivate: [AuthGuard],
    component: PendingComponent
  },
  {
    path: 'redtail/success',
    canActivate: [AuthGuard],
    component: SuccessComponent
  },
  {
    path: 'riskalyze/success',
    canActivate: [AuthGuard],
    component: SuccessComponent
  },
  {
    path: 'riskalyze/pending',
    canActivate: [AuthGuard],
    component: PendingComponent
  },
  {
    path: 'riskalyze/fail',
    canActivate: [AuthGuard],
    component: FailComponent
  },

  {
    path: 'precisefp/success',
    canActivate: [AuthGuard],
    component: SuccessComponent
  },
  {
    path: 'precisefp/pending',
    canActivate: [AuthGuard],
    component: PendingComponent
  },
  {
    path: 'precisefp/fail',
    canActivate: [AuthGuard],
    component: FailComponent
  },
  {
    path: 'orion/pending',
    canActivate: [AuthGuard],
    component: PendingComponent
  },
  {
    path: 'orion/fail',
    canActivate: [AuthGuard],
    component: FailComponent
  },
  {
    path: 'insurance-hub',
    canActivate: [AuthGuard],
    pathMatch: 'prefix',
    loadChildren: () => import('./views/questionnaires/insurance-hub/insurance-hub.module').then((m) => m.InsuranceHubModule),
  },
  {
    path: 'auto-insurance-hub',
    canActivate: [AuthGuard],
    pathMatch: 'prefix',
    loadChildren: () => import('./views/questionnaires/insurance-hub/insurance-hub.module').then((m) => m.InsuranceHubModule),
    data: {
      type: 'auto'
    },
  },
  {
    path: 'life-insurance-hub',
    canActivate: [AuthGuard],
    pathMatch: 'prefix',
    loadChildren: () => import('./views/questionnaires/insurance-hub/insurance-hub.module').then((m) => m.InsuranceHubModule),
    data: {
      type: 'life'
    },
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];
