import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { identity_questions } from './identity.model';


@Component({
  selector: 'app-q-identity',
  templateUrl: './q-identity.component.html',
  styleUrls: ['./q-identity.component.scss']
})
export class QIdentityComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('identityform', { static: true }) identityform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: identity_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;

  public whoAnswered: string;
  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;

  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;


  public bulletColor;

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public authService: AuthenticationService,
    public qController: QuestionnaireController,
    public dialog: MatDialog,

  ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientHasChildren = this.clientData.hasChildren;

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Is the first time here? Show modal
    // let isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    /*if (isFirstTimeHere === 'identity') {
        this.dialog.open(DialogFirstTime, {
            panelClass: 'modal-dialog-questionnaire',
            height: '250px',
            width: '500px',
            data: {hasUploader: false}
        });
    }*/

    //Initialize the questionnaire model
    this.model = new identity_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansIdentity;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'identity', this.model).then(response => {
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentModeActived: boolean = false) {

    let beforeModifications = new identity_questions();
    Object.assign(beforeModifications, this.model);

    //Apply python required transformations
    if (this.model.clientIDShredStatements == '') this.model.clientIDShredStatements = 'Yes';
    if (this.model.clientIDCollectMail == '') this.model.clientIDCollectMail = 'Yes';
    if (this.model.clientIDSharePW == '') this.model.clientIDSharePW = 'Yes';
    if (this.model.clientIDTwoFactorAutenticFin == '') this.model.clientIDTwoFactorAutenticFin = 'No';
    if (this.model.clientIDPurchaseOnPhone == '') this.model.clientIDPurchaseOnPhone = 'Yes';
    if (this.model.clientIDPassphrase == '') this.model.clientIDPassphrase = 'No';
    if (this.model.clientIDAlertCreditCard == '') this.model.clientIDAlertCreditCard = 'No';
    if (this.model.clientIDUnknownSender == '') this.model.clientIDUnknownSender = 'Yes';
    if (this.model.clientIDUnexpectAttach == '') this.model.clientIDUnexpectAttach = 'Yes';
    if (this.model.clientIDHomeNet == '') this.model.clientIDHomeNet = 'No';
    if (this.model.clientIDStorePW == '') this.model.clientIDStorePW = 'Yes';
    if (this.model.clientIDWebPort == '') this.model.clientIDWebPort = 'Yes';
    if (this.model.clientIDStoreInfoShop == '') this.model.clientIDStoreInfoShop = 'Yes';
    if (this.model.clientIDEncryptUSB == '') this.model.clientIDEncryptUSB = 'No';
    if (this.model.clientIDMedContainer == '') this.model.clientIDMedContainer = 'No';
    if (this.model.clientIDShareMedInfo == '') this.model.clientIDShareMedInfo = 'Yes';
    if (this.model.clientIDReviewBenCharges == '') this.model.clientIDReviewBenCharges = 'No';
    if (this.model.clientIDReviewMedRecord == '') this.model.clientIDReviewMedRecord = 'No';
    if (this.model.clientIDCheckMail == '') this.model.clientIDCheckMail = 'Yes';
    if (this.model.clientIDUSBPublicDevice == '') this.model.clientIDUSBPublicDevice = 'Yes';

    //Save to process with transformations, then store it without transformations.
    let afterSent = () =>{
      // Send questionnaire data to save without internall modifications
      this.qController.submitForm(this, beforeModifications, this.clientData.clientId, 'identity', true, null, true);
    }

    this.qController.submitForm(this, this.model, this.clientData.clientId, 'identity', saveForLater, afterSent, silentModeActived);
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'identity'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'identity-questionnaire.pdf');
      });
  }

}
