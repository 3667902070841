import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const integrations = JSON.parse(localStorage.getItem("external-providers-interactions"))
    const providerName = this.getProviderName(state.url)
    console.log(providerName)
    if(!integrations){
      return false;
    }
    if (integrations[providerName]) {
      return true
    }
    else{
      this.router.navigate(['settings/external-providers-interactions/'+providerName+'/instructions']);
      return false
    }
  }

  getProviderName(url){
    const validPaths = ['instructions', 'client-list', 'mapper']
    let split = url.split('/')
    let isFinalPath = validPaths.includes(split[split.length-1])
    if (!isFinalPath) {
      return split[split.length-1]
    }
    else{
      return split[split.length-2]
    }
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
