import { Questionnaire } from '../Questionnaire.model';

export class disability_questions extends Questionnaire {

   public clientIDDisabilityPolicy: string= '';
   public clientIDDisabilityPolicy2: string= '';

   public cliendIDTypeOfPolicy: string = 'Individual';
   public clientIDNumberOfPolicies: string = '';

   public clientIDHasPolicyOne: string = '';
   public clientIDHasSecondPolicy: string = '';

   public clientIDDisabilityInsuranceCompany: string = '';
   public clientIDDisabilityInsuranceCompany2: string = '';
   public clientIDHaveDisabilityEmployerSponsoredPlan: string = '';
   public clientIDPaysDisabilityPremiumWithPreTaxIncomeOrAfterTaxIncome: string= '';
   public clientIDPolicyThroughAssociation: string= '';
   public clientIDPolicyThroughAssociation2: string = '';
   public clientIDHasEmergencyFund: string= '';
   public clientIDAmountOfEmergencyFund: number= 0;
   public clientIDInForceCoverage: string= '';

   public clientIDIsFinanciallyIndependent: string= '';
   public clientIDSmallBusiness: string='';
   public clientIDPlansReturnToWork: string= '';
   public clientIDClientHasBusinessAndPartners: string= '';
   public clientIDDailyBusinessExpenses: number= 0;
   public clientIDIsKeyEmployeeofBusiness: string= '';
   public clientIDExpensesMonthlyBasis: string= '';
   public clientIDAblePayMonthlyDisabilityEvent: string= '';
   public clientIDBelongsToProfessionalAssociation: string= '';
   public clientIDBaseSalary: number= 0;
   public clientIDExpectsIncomeToIncreaseDramaticallyOrSteadily: string= '';
   public clientIDIncomeProjection: string = '';
   public clientIDSpouseBonusOrComp: number= 0;
   public clientIDSpousalBaseSalary: number= 0;
   public clientIDCurrentLivingExpenses: number= 0;

   // Manually insert policy data
   public clientIDWantsToInputPolicyDataDisability: string= 'Yes';
   public clientIDincomeCoverage: number= 0;
   public clientIDincomeCoverage2: number = 0;
   public clientIDsearchPolicyForCOLA: string= '';
   public clientIDsearchPolicyForCOLA2: string = '';
   public clientIDsearchPolicyForOwnOccupation: string= '';
   public clientIDsearchPolicyForOwnOccupation2: string = '';
   public clientIDsearchForBenefitPeriod: string= '';
   public clientIDsearchForBenefitPeriod2: string = '';
   public clientIDsearchPolicyForEliminationDays: number= 0;
   public clientIDsearchPolicyForEliminationDays2: number= 0;
   public clientIDpolicyHasResiduaryPolicy: string= '';
   public clientIDpolicyHasResiduaryPolicy2: string = '';
   public clientIDsearchPolicyForCATRider: string= '';
   public clientIDsearchPolicyForCATRider2: string = '';
   public clientIDHasABE: string = '';
   public clientIDHasABE2: string = '';
   public clientIDHasFIO: string = '';
   public clientIDHasFIO2: string = '';
   public clientIDsearchPolicyForOverheadCoverage: string= '';
   public clientIDsearchPolicyForOverheadCoverage2: string = '';
   public clientIDsearchPolicyForReplacementRider: string= '';
   public clientIDsearchPolicyForReplacementRider2: string = '';
   public clientIDsearchPolicyForCostIncreaseRider: string= '';
   public clientIDsearchPolicyForCostIncreaseRider2: string = '';
   public clientIDsearchPolicyForDisabilityBuyoutCoverage: string= '';
   public clientIDsearchPolicyForDisabilityBuyoutCoverage2: string = '';
   public clientIDDisabilityAnnualPremium: number = 0;
   public clientIDDisabilityAnnualPremium2: number = 0;
   public clientIDBusinessEmergencyFunds: number = 0;
   // public clientIDOverheadEliminationDays: number = 0;
   public clientIDAssetName: string = '';
   
	// Progress Bar
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;

   constructor() {
      super()
   }

   getQName(): string {
      return 'disability';
   }

   getIgnoredAttributesForSave():string[]{
      return [''];
   }

   getAttributesToClearCommas(): string[] {
      return [
         'clientIDAmountOfEmergencyFund',
         'clientIDBaseSalary',
         'clientIDSpousalBaseSalary',
         'clientIDSpouseBonusOrComp',
         'clientIDCurrentLivingExpenses',
         'clientIDDailyBusinessExpenses',
         'clientIDincomeCoverage',
         'clientIDincomeCoverage2',
         'clientIDBusinessEmergencyFunds',
         'clientIDDisabilityAnnualPremium',
         'clientIDDisabilityAnnualPremium2'
      ];
   }
}
