import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { CustomValidators } from 'ng2-validation';

import { environment } from '../../../../environments/environment';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { ShareClientDialogData } from '../clients.interfaces';

// Share Client Dialog
@Component({
  selector: 'share-client-dialog',
  templateUrl: 'share-client-dialog.html',
})

export class ShareClientDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  shareClientForm: FormGroup;

  private shareThisClient = environment.apiShareClient;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<ShareClientDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ShareClientDialogData,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {

    const email = new FormControl('', [Validators.required, Validators.email]);
    const confirmEmail = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);

    this.shareClientForm = new FormGroup(
      Object.assign({
        email: email,
        confirmEmail: confirmEmail,
      })
    );
  }

  save() {
    this.dialogRef.close(this.shareClientForm.value);
    const sharedClientData = this.shareClientForm.value;
    
    let id = this.data.id;

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('email', sharedClientData.email);

    this.http.post<any>(`${this.shareThisClient}${id}`, body.toString(), options)
      .subscribe(
        success => {
          
          this.snackBarSuccess.open("Client shared succesfully with " + sharedClientData.email, "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot share this client. " + error.error.message  + " in your company.", "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
