//Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { getEndPointUrlByContext, getQueryParam, urlPrependRootContext } from '../../../shared/helpers/url.helper';

// Project
//3rd parties
@Component({
  selector: 'app-forgot-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  public hasChangedNewPassword: boolean = false;

  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  updateAdvisorInfoForm: FormGroup;

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarError: MatSnackBar,
  ) { }

  ngOnInit() {
    /*** Profile info ***/
    const password = new FormControl('', [
        Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&-_.])[A-Za-z\d$@$!%*?&-_.]/),
        Validators.minLength(8)
        ]);
    const confirmPassword = new FormControl('', [CustomValidators.equalTo(password)]);

    this.updateAdvisorInfoForm = new FormGroup({
        newPassword: password,
        confirmPassword: confirmPassword,
    });

  }

  submitPassword() {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let token = getQueryParam('token');

    let body = new URLSearchParams();
    body.set('token', token);
    body.set('newPassword', this.updateAdvisorInfoForm.value.newPassword);

    this.http.post<any>(getEndPointUrlByContext('ChangePass'), body.toString(), options)
      .subscribe(
        response => {
          this.hasChangedNewPassword = true;
        },
        error => {
          console.log('Nay!', error);
          this.hasChangedNewPassword = false;
          this.snackBarError.open(
            `${error.error.message}`,
            "Dismiss",
            {
              duration: 9000,
              verticalPosition: "top",
              panelClass: "error-snackbar"
            }
          );
        }
      );
  }

  returnToSignIn(){
    this.router.navigate([urlPrependRootContext('/signin')]);
  }
}
