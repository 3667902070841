import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { BoxNodeInterface } from './BoxeNode.interface';
import { EstateLabV2Service } from 'app/shared/services/estate-lab-v2.service';

@Component({
  selector: 'app-boxes-node',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss']
})
export class BoxesComponent implements OnInit {

  @Input() public nodeData: BoxNodeInterface;
  @Input() public genericStatus: string;
  @Input() public nodeID: string = '';
  @Input() public showAssets: boolean = false;
  @Input() public isPrintable: boolean = false;
  @Input() public metaData: any;
  @Output() public openBuilderFromFlowchart = new EventEmitter<any>();

  public isEditable: boolean = false;


  constructor(
    public estateLabService: EstateLabV2Service
  ) { }

  ngOnInit(): void {
    //console.log('Is printable pdf*', this.isPrintable);
    if (this.metaData.hasOwnProperty('docType')){
      this.isEditable = true;
      // console.log('this.metadata.docType*', this.metaData.docType);
    }
    if (this.nodeData.smallDescription === 'Gift'){
      if (this.nodeData.secondColumn.includes('<b>')){
        this.nodeData.secondColumn = this.nodeData.secondColumn.replace('<b>', '<b class="fp-font-mono">')
      }
      this.nodeData = JSON.parse(JSON.stringify(this.nodeData));
    }
    this.nodeData = (this.nodeData === undefined ? this.items_v2[0] : this.nodeData);
  }


  public items_v2: BoxNodeInterface[] = [
    {
      smallDescription: 'Father',
      title: 'John Doe',
      class: 'simple-node-black'
    },
    {
      smallDescription: 'Mother',
      title: 'Mary Doe',
      class: 'simple-node-black'
    },
    {
      smallDescription: 'Client',
      title: 'John Johnson',
      subtitle: '1000000',
      class: 'main-node-black',
      isCollapsedOverviewWithText: 'Assets',
      isProbateAndRefersToResourceId: 'id_summarizeContent'
    },
    {
      iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/00_General/20_g_edit.svg',
      title: 'John\'s will',
      class: 'action-node-black',
      isActionButtonWithText: '+ Will',
      actionButtonFunction: () => {}
    },
    {
      title: '',
      class: 'button-action-node-black',
      isActionButtonWithText: '+ Trust',
      actionButtonFunction: () => {},
      displayUpperOrBottonOnly: 'Bottom'
    },
    {
      iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/00_General/20_g_edit.svg',
      title: 'John Johnson',
      subtitle: '1000000',
      class: 'overview-node-gold',
      isCollapsedOverviewWithText: 'Assets'
    },
  ]

  onClickEmit(data){
    console.log('boxes.onClickEmit', data);
    if(data.loadResourceOfType === '+ Will'){
      data.loadResourceOfType = 'will';
    }else if(data.loadResourceOfType === '+ Trust'){
      data.loadResourceOfType = 'trust';
    }else if(data.loadResourceOfType === '+ Contract'){
      data.loadResourceOfType = 'contract';
    }else {
      data.loadResourceOfType = 'will';
    }
    this.estateLabService.onBoxButtonClickEvent.emit(data);
  }


}

