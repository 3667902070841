import {Scenario} from "./Scenario.class";

export const RESPONSE: Scenario[] = [
  {
    _id: '62f14550b2ae4811a3d2b429',
    name: 'Base scenario',
    description: 'Base scenario',
    dateCreated: '2022-08-08T17:18:08.278Z',
    dateUpdated: '2022-08-08T17:18:08.278Z',
    position: 0,
    overwriteList: [],
    sections: [
      {
        id: 'summaryInformation',
        data: {
          year: 2021,
          filingStatus: 'Single',
          state: 'Florida',
          dependentFilers: 0,
          olderFilers: 0,
          disabledFilers: 0
        }
      },
      {
        id: 'grossIncome',
        data: {
          wages: 10,
          wages_cola: 2.25,
          taxableInterest: 2761,
          qualifiedDividends: 25427,
          ordinaryDividends: 75100,
          taxableIRA: 545,
          taxablePensionsAndAnnuities: 0,
          socialSecurityBenefit: 1520,
          socialSecurityBenefit_cola: 1.54,
          taxableSocialSecurity: 11823,
          taxableSocialSecurity_percentage: 0.5,
          capitalGainOrLoss: 50961,
          otherIncome: 796,
          grossIncome: 141986
        }
      },
      {
        id: 'agi',
        data: {
          adjustmentsToIncome: 0,
          agi: 141986
        }
      },
      {
        id: 'deductions',
        data: {
          standardOrItemizedDeductions: 0,
          charitableDeductionsIfStandard: 0,
          qbiPreDeduction: 0,
          qbiDeduction: 0,
          deductions: 0,
          taxableIncome: 0
        }
      },
      {
        id: 'taxes',
        data: {
          marginalTaxBracket: '0%',
          tax: 0,
          taxesPaod: 0,
          nonrefundableChildTaxCredit: 0,
          nonrefundableCredits: 0,
          otherTaxes: 0,
          totalTax: 0
        }
      },
      {
        id: 'totalPayments',
        data: {
          estimatedPayments: 0,
          pyRefundAplied: 0,
          eic: 0,
          refundableChildTaxCredit: 0,
          americanOpportunityCredit: 0,
          recoveryRebateCredit: 0,
          totalOtherPaymentsAndRefundableCredits_line15: 0,
          totalOtherPaymentsAndRefundableCredits: 0,
          totalPayments: 0,
          fedTaxOwed: 0
        }
      },
      {
        id: 'estateTaxes',
        data: {
          stagAdjustmentseAgi: 0,
          stateAgi: 0,
          stateStandardDeduction: 0,
          statePersonalExemption: 0,
          stateTaxableIncome: 0,
          stateMarginalTaxBracket: 0,
          stateTaxAmount: 0,
          stateTaxPayments: 0,
          stateStandardCredits: 0,
          statePersonalCredit: 0,
          stateTaxOwed: 0
        }
      },
      {
        id: 'medicare',
        data: {
          medicareBPremium: 0,
          medicareDPremium: 0,
          alternativeMinimumTax: 0
        }
      },
      {
        id: 'magiTiers',
        data: {
          coverdellESAEligible: '',
          eligibilityCredit: '',
          lifetimeLearning: '',
          studentLoanDeduction: '',
          americanOpportunityCredit: '',
          childTaxCredit: '',
          qualifiedAdoptionExpensesCredit: '',
          saversCredit: '',
          iraContributionDeductibility: '',
          rothIraContribution: '',
          taxableSocialSecurity: ''
        }
      }
    ]
  },
  {
    _id: '62f145b7b2ae4811a3d2b000',
    dateCreated: '2022-08-08T17:19:51.441Z',
    dateUpdated: '2022-08-08T17:19:51.441Z',
    name: 'Scenario' ,
    description: null,
    position: 1,
    overwriteList: [],
    sections: [
      {
        id: 'summaryInformation',
        data: {
          year: 2021,
          filingStatus: 'Single',
          state: 'Arizona',
          dependentFilers: 0,
          olderFilers: 0,
          disabledFilers: 0
        }
      },
      {
        id: 'grossIncome',
        data: {
          wages: 0,
          wages_cola: 2.25,
          taxableInterest: 2761,
          qualifiedDividends: 25427,
          ordinaryDividends: 75100,
          taxableIRA: 545,
          taxablePensionsAndAnnuities: 0,
          socialSecurityBenefit: 157,
          socialSecurityBenefit_cola: 3.54,
          taxableSocialSecurity: 11823,
          taxableSocialSecurity_percentage: 1.2,
          capitalGainOrLoss: 50961,
          otherIncome: 796,
          grossIncome: 141986
        }
      },
      {
        id: 'agi',
        data: {
          adjustmentsToIncome: 0,
          agi: 0
        }
      },
      {
        id: 'deductions',
        data: {
          standardOrItemizedDeductions: 0,
          charitableDeductionsIfStandard: 0,
          qbiPreDeduction: 0,
          qbiDeduction: 0,
          deductions: 0,
          taxableIncome: 0
        }
      },
      {
        id: 'taxes',
        data: {
          marginalTaxBracket: '0%',
          tax: 0,
          taxesPaod: 0,
          nonrefundableChildTaxCredit: 0,
          nonrefundableCredits: 0,
          otherTaxes: 0,
          totalTax: 0
        }
      },
      {
        id: 'totalPayments',
        data: {
          estimatedPayments: 0,
          pyRefundAplied: 0,
          eic: 0,
          refundableChildTaxCredit: 0,
          americanOpportunityCredit: 0,
          recoveryRebateCredit: 0,
          totalOtherPaymentsAndRefundableCredits_line15: 0,
          totalOtherPaymentsAndRefundableCredits: 0,
          totalPayments: 0,
          fedTaxOwed: 0
        }
      },
      {
        id: 'estateTaxes',
        data: {
          stagAdjustmentseAgi: 0,
          stateAgi: 0,
          stateStandardDeduction: 0,
          statePersonalExemption: 0,
          stateTaxableIncome: 0,
          stateMarginalTaxBracket: 0,
          stateTaxAmount: 0,
          stateTaxPayments: 0,
          stateStandardCredits: 0,
          statePersonalCredit: 0,
          stateTaxOwed: 0
        }
      },
      {
        id: 'medicare',
        data: {
          medicareBPremium: 0,
          medicareDPremium: 0,
          alternativeMinimumTax: 0
        }
      },
      {
        id: 'magiTiers',
        data: {
          coverdellESAEligible: '',
          eligibilityCredit: '',
          lifetimeLearning: '',
          studentLoanDeduction: '',
          americanOpportunityCredit: '',
          childTaxCredit: '',
          qualifiedAdoptionExpensesCredit: '',
          saversCredit: '',
          iraContributionDeductibility: '',
          rothIraContribution: '',
          taxableSocialSecurity: ''
        }
      }
    ]
  },
  {
    _id: '62f145b7b2ae4811a3d2b494',
    dateCreated: '2022-08-09T17:20:51.441Z',
    dateUpdated: '2022-08-09T17:20:51.441Z',
    name: 'Scenario 2' ,
    description: null,
    position: 2,
    overwriteList: [],
    sections: [
      {
        id: 'summaryInformation',
        data: {
          year: 2021,
          filingStatus: 'Single',
          state: 'Arizona',
          dependentFilers: 0,
          olderFilers: 0,
          disabledFilers: 0
        }
      },
      {
        id: 'grossIncome',
        data: {
          wages: 0,
          wages_cola: 2.25,
          taxableInterest: 2761,
          qualifiedDividends: 25427,
          ordinaryDividends: 75100,
          taxableIRA: 545,
          taxablePensionsAndAnnuities: 0,
          socialSecurityBenefit: 1720,
          socialSecurityBenefit_cola: 5.4,
          taxableSocialSecurity: 11823,
          taxableSocialSecurity_percentage: 5.3,
          capitalGainOrLoss: 50961,
          otherIncome: 796,
          grossIncome: 141986
        }
      },
      {
        id: 'agi',
        data: {
          adjustmentsToIncome: 0,
          agi: 0
        }
      },
      {
        id: 'deductions',
        data: {
          standardOrItemizedDeductions: 0,
          charitableDeductionsIfStandard: 0,
          qbiPreDeduction: 0,
          qbiDeduction: 0,
          deductions: 0,
          taxableIncome: 0
        }
      },
      {
        id: 'taxes',
        data: {
          marginalTaxBracket: '0%',
          tax: 0,
          taxesPaod: 0,
          nonrefundableChildTaxCredit: 0,
          nonrefundableCredits: 0,
          otherTaxes: 0,
          totalTax: 0
        }
      },
      {
        id: 'totalPayments',
        data: {
          estimatedPayments: 0,
          pyRefundAplied: 0,
          eic: 0,
          refundableChildTaxCredit: 0,
          americanOpportunityCredit: 0,
          recoveryRebateCredit: 0,
          totalOtherPaymentsAndRefundableCredits_line15: 0,
          totalOtherPaymentsAndRefundableCredits: 0,
          totalPayments: 0,
          fedTaxOwed: 0
        }
      },
      {
        id: 'estateTaxes',
        data: {
          stagAdjustmentseAgi: 0,
          stateAgi: 0,
          stateStandardDeduction: 0,
          statePersonalExemption: 0,
          stateTaxableIncome: 0,
          stateMarginalTaxBracket: 0,
          stateTaxAmount: 0,
          stateTaxPayments: 0,
          stateStandardCredits: 0,
          statePersonalCredit: 0,
          stateTaxOwed: 0
        }
      },
      {
        id: 'medicare',
        data: {
          medicareBPremium: 0,
          medicareDPremium: 0,
          alternativeMinimumTax: 0
        }
      },
      {
        id: 'magiTiers',
        data: {
          coverdellESAEligible: '',
          eligibilityCredit: '',
          lifetimeLearning: '',
          studentLoanDeduction: '',
          americanOpportunityCredit: '',
          childTaxCredit: '',
          qualifiedAdoptionExpensesCredit: '',
          saversCredit: '',
          iraContributionDeductibility: '',
          rothIraContribution: '',
          taxableSocialSecurity: ''
        }
      }
    ]
  }
]
