import { Injectable, Inject } from '@angular/core';
import { GeneralQuestionnaireService } from './general-questionnaire.service';
import { Observable, throwError } from 'rxjs';
import { AbstractQuestionnaire } from 'app/shared/model/questionnaires/Questionnaires.model';
import { tap, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';


export class QuestionnaireHubService {

  questionnaire: AbstractQuestionnaire

  curentClientId: string

  constructor(
    public snackBar: MatSnackBar,
    public snackBarTheme: string = 'general-questionnaire-theme',
  ) {
    /* this.getClientId() */
  }

  getAnswers(questionnaire, questionnaireType, questionnaireSubtype?, clientId?): Observable<any>{
    this.questionnaire = questionnaire;
    return this.questionnaire.getAnswers(questionnaireType, clientId , questionnaireSubtype)
  }

  postAnswers(questionnaire, questionnaireType, answers, questionnaireSubtype?, clientId?): Observable<any>{
    this.questionnaire = questionnaire;
    return this.questionnaire.postAnswers(questionnaireType,  clientId, answers, questionnaireSubtype).pipe(
      tap((arg) => {
        this.showMessage(arg.message);
      }),
      catchError(error => {
        this.showMessage(error?.error?.message, 'error');
        return throwError(error);
      })
    )
  }

  putAnswers(questionnaire, questionnaireType, answers, clientId?): Observable<any>{
    this.questionnaire = questionnaire;
    return this.questionnaire.putAnswers(questionnaireType,  clientId, answers).pipe(
      tap((arg) => {
        this.showMessage(arg.message);
      }),
      catchError(error => {
        this.showMessage(error?.error?.message, 'error');
        return throwError(error);
      })
    )
  }

  deleteAnswers(questionnaire, questionnaireType, answers, clientId?): Observable<any>{
    this.questionnaire = questionnaire;
    return this.questionnaire.deleteAnswers(questionnaireType,  clientId, answers).pipe(
      tap((arg) => {
        this.showMessage(arg.message);
      }),
      catchError(error => {
        this.showMessage(error.error.message, 'error');
        return throwError(error);
      })
    )
  }

  getClientId() {
    this.curentClientId = JSON.parse(sessionStorage.getItem('currentClient')).clientId
  }

  showMessage(message: string, type = 'snack'): void {
    const classes = [type]
    classes.push(this.snackBarTheme)
    this.snackBar.open(message, '', {
      duration: 5000,
      panelClass: classes
    });
  }

}
