import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { environment } from 'environments/environment';
import { CustomValidators } from 'ng2-validation';


@Component({
  selector: 'app-invite-advisor-modal',
  templateUrl: './invite-advisor-modal.component.html',
  styleUrls: ['./invite-advisor-modal.component.scss']
})
export class InviteAdvisorModalComponent extends CloseDialogAtLogOut implements OnInit {

  public addAdvisorForm: FormGroup;
  public mailExistsAlready: boolean;
  private validateClientEmail = environment.apiValidateEmail;

  constructor(
    public dialogRef: MatDialogRef<InviteAdvisorModalComponent>,
    private authService: AuthenticationService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      super(dialogRef, authService);
     }

  ngOnInit() {

    const email = new FormControl('', [Validators.required, Validators.email]);
    const confirmEmail = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);

    this.addAdvisorForm = new FormGroup(
      // Concatenate objects into one with Object.assign and pass it to FormGroup instanciation.
      Object.assign({ //First object
        advisorFirstName: new FormControl('', Validators.required),
        advisorLastName: new FormControl('', Validators.required),
        email: email,
        confirmEmail: confirmEmail,
        role: new FormControl('', Validators.required),
      })
    );
  }

  onSearchInputChange(e) {
    let searchValue = e.srcElement.value;
    this.doEmailValidation(searchValue);
  }

  doEmailValidation(searchValue: string) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('email', searchValue);

    this.http.post<any>(this.validateClientEmail, body.toString(), options).subscribe(
      success => {
        this.mailExistsAlready = false;
      },
      error => {
        this.mailExistsAlready = true;
      }
    )

  }

  cancel(){
    this.dialogRef.close('cancel');
  }

  sendInvite(){
    this.dialogRef.close('cancel');
  }

}
