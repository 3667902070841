import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-preview-component',
  templateUrl: './file-preview-component.component.html',
  styleUrls: ['./file-preview-component.component.scss']
})
export class FilePreviewComponentComponent implements OnInit {

  @Input() public name: string;
  @Input() public size: any;
  @Input() public key: any;
  
  public measuredSize: any;

  constructor() { }

  ngOnInit() {
    this.measuredSize = this.measureFile();
  }

  measureFile() {
    let auxSize; 
    let unit;
    if (parseInt(this.size, 10) < 1000) {
      auxSize = parseInt(this.size, 10);
      unit = "Bytes";
    } else if (parseInt(this.size, 10) < 1000*1000) {
      auxSize = parseInt(this.size, 10) / 1000;
      unit = "KB";
    } else if (parseInt(this.size, 10) < 1000*1000*1000) {
      auxSize = parseInt(this.size, 10) / 1000 / 1000;
      unit = "MB";
    } else {
      auxSize = parseInt(this.size, 10) / 1000 /1000 /1000;
      unit = "GB";
    }
    
    return `${parseFloat(auxSize).toFixed(1)} ${unit}`;
  }

}
