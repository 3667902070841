import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-precisefp-home',
  templateUrl: './precisefp-home.component.html',
  styleUrls: ['./precisefp-home.component.css']
})
export class PrecisefpHomeComponent implements OnInit {


  @Input() stepper: any;
  @Input() isIntegrated: any;
  @Input() comeFromLogin: any;

  public isProduction: any;
  public location: any;
  public clientListView: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public snackBarWarning: MatSnackBar
    ) { }

  // @ViewChild('stepper', { static: false }) private stepper: MatStepper;


  ngOnInit() {

    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.stepper && changes.stepper.currentValue === 0){
      this.clientListView = false;
    }
  }

  disconnectPrecisefp() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiPreciseFPDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'PreciseFP disconnected');
          this.snackBarSuccess.open("Your PreciseFP account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });

          this.isIntegrated = false;
          this.router.navigate(['/settings/external-providers-interactions']);
        },
        error => {
          console.log('Error: PreciseFP disconnect');
          this.snackBarError.open("Couldn't disconnect your PreciseFP account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }
}
