//Angular
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ModaProspectRecsComponent } from '../modal-recs/modal-recs.component';

@Component({
  selector: 'score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.scss'],
})
export class ScoreCardComponent implements OnInit {

  @Input() areaScore: number;
  @Input() colorBadge: string;
  @Input() area: string;
  @Input() title: string;
  @Input() recs: any;

  public statusColor: string;

  constructor(private router: Router, public dialog: MatDialog, public http: HttpClient) { }

  ngOnInit() {
    if (Number(this.areaScore) > 1 && Number(this.areaScore) <= 9.9999) this.statusColor = 'danger-bar';
    if (Number(this.areaScore) >= 10 && Number(this.areaScore) <= 19.9999) this.statusColor = 'warn-bar-10';
    if (Number(this.areaScore) >= 20 && Number(this.areaScore) <= 29.9999) this.statusColor = 'warn-bar-20';
    if (Number(this.areaScore) >= 30 && Number(this.areaScore) <= 39.9999) this.statusColor = 'warn-bar-30';
    if (Number(this.areaScore) >= 40 && Number(this.areaScore) <= 49.9999) this.statusColor = 'warn-bar-40';
    if (Number(this.areaScore) >= 50 && Number(this.areaScore) <= 59.9999) this.statusColor = 'warn-bar';
    if (Number(this.areaScore) >= 60 && Number(this.areaScore) <= 69.9999) this.statusColor = 'ok-bar-60';
    if (Number(this.areaScore) >= 70 && Number(this.areaScore) <= 79.9999) this.statusColor = 'ok-bar-70';
    if (Number(this.areaScore) >= 80 && Number(this.areaScore) <= 89.9999) this.statusColor = 'ok-bar-80';
    if (Number(this.areaScore) >= 90 && Number(this.areaScore) <= 99) this.statusColor = 'ok-bar-90';
    if (Number(this.areaScore) === 100) this.statusColor = 'ok-bar-90';
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  showAreaInfo() {
    let data = {
      recs: this.recs,
      title: this.title,
      areaScore: this.areaScore
    }
    const dialogRef = this.dialog.open(ModaProspectRecsComponent, {
      disableClose: false,
      panelClass: 'modal-dialog-questionnaire',
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      data: data
    });
  }

}
