// Angular
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrls: ["./announcement-modal.component.scss"]
})

export class AnnouncementModalComponent extends CloseDialogAtLogOut implements OnInit, AfterViewInit {

  constructor(
    public dialogRef: MatDialogRef<AnnouncementModalComponent>,
    private authService: AuthenticationService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  closeAnnouncement(){
    this.dialogRef.close('gotit');
  }

  seeReleaseNotes(){
    this.closeAnnouncement();
    setTimeout(() => {
      this.router.navigate(['/releases']);
    }, 500);

  }

} //@end
