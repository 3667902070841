import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AdvisorService} from "../../../shared/services/advisor.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public readedPreferences;

  //Form group initializers
  preferencesForm: FormGroup;

  public advisorData;
  color: any = '#247730';

  constructor(
    private router: Router,
    private http: HttpClient,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private advisorService: AdvisorService,
  ) { }

  ngOnInit() {
    this.readedPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    /*** Preferences ***/
    this.preferencesForm = new FormGroup({
      seeNotificationsPlatform: new FormControl(this.readedPreferences.notificationsWhereSeePlatform),
      seeNotificationsEmail: new FormControl(this.readedPreferences.notificationsWhereSeeEmail),
      notifications: new FormControl(this.readedPreferences.notifications),
    });
    if (this.readedPreferences.notifications === 'Email'){
      this.preferencesForm.controls['seeNotificationsEmail'].setValue(true);
    }else if (this.readedPreferences.notifications === 'Platform'){
      this.preferencesForm.controls['seeNotificationsPlatform'].setValue(true);
    }if (this.readedPreferences.notifications === 'Both'){
      this.preferencesForm.controls['seeNotificationsEmail'].setValue(true);
      this.preferencesForm.controls['seeNotificationsPlatform'].setValue(true);
    }
  }

  showValue(value: any){
    let saveAdvisorPreferences = this.preferencesForm.value;




  }

  updateAdvisorPreferences() {
    if (this.preferencesForm.value.seeNotificationsEmail && this.preferencesForm.value.seeNotificationsPlatform){
      this.preferencesForm.value.notifications = 'Both'
    }else if (this.preferencesForm.value.seeNotificationsEmail){
      this.preferencesForm.value.notifications = 'Email'
    }else if(this.preferencesForm.value.seeNotificationsPlatform){
      this.preferencesForm.value.notifications = 'Platform'
    }if (!this.preferencesForm.value.seeNotificationsEmail && !this.preferencesForm.value.seeNotificationsPlatform){
      this.preferencesForm.value.notifications = 'None'
    }


    let saveAdvisorPreferences = this.preferencesForm.value;

    let updatedPreferences = {
      notifications: saveAdvisorPreferences.notifications
    }

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();
    body.set('notifications', saveAdvisorPreferences.notifications);

    this.http.post<any>(`${environment.apiAdvisorAdvisorPreferences}`, body.toString(), options)
      .subscribe(
        success => {

          let snackBarRef = this.snackBarSuccess.open("Preferences updated, the app will reload to apply changes", "Ok", {
            duration: 10000,
            panelClass: 'success-snackbar'
          });

          this.router.navigate(['/advisor/clients']);

          setTimeout(() => {
            this.reload();
          }, 3000);

          snackBarRef.onAction().subscribe(() => {

            this.reload();
          });

          //Set the new values in storage so we can read the right values after the changes (RT)
          localStorage.setItem("advisorPreferences", JSON.stringify(updatedPreferences));

          this.advisorService.getAdvisorData()
            .subscribe(
              data => {
                this.advisorData = data;

              },
              error => {
                console.log(error);
              });

        },
        error => {
          console.log('Error!!!');
          this.snackBarError.open("Cannot update your preferences. " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      );
  }

  reload() {
    window.location.reload();
  }

}
