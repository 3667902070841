export const emptyFamiliarRelationship = {
    "lastName": " ",
    "_id": " ",
    "firstName": " ",
    "relation": " ",
    "type": " ",
    "middleName": " ",
}

export const emptyAgentRelationship = {
    "datapointKey": " ",
    "relationshipType": " ",
    "value": " ",
    "index": " ",
}

export const forbiddenRelationshipsToEdit = [
    'Child',
    'Spouse',
    'Self'
]

export const familiarObjectKeys = [
    "familyTree",
    "familyOther",
    "advisorComments"
]

export const legalObjectKeys = [
    "powerOfAttorney",
    "healthCareDeclaration",
]