import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-estate-info-dialog',
  templateUrl: './estate-info-dialog.component.html',
  styleUrls: ['./estate-info-dialog.component.scss']
})
export class EstateInfoDialogComponent implements OnInit {

  public info: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EstateInfoDialogComponent>,
  ) {
    this.info = data;
  }

  ngOnInit() {
  }

  close() {
    if (this.info.actions.close) {
      this.dialogRef.close(this.info.actions.close);
    } else {
      this.dialogRef.close();
    }
  }

  confirm(e: any = null) {
    if (e && e.target.id === 'custom') {
      this.dialogRef.close(this.info.customAction.custom);
    } else {
      this.dialogRef.close(this.info.actions.confirm);
    }
  }


}
