import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnInit, ViewChildren, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClientsComponent } from '../../../../views/clients/clients.component';
import { LayoutService } from '../../../services/layout.service';
import { ThemeService } from '../../../services/theme.service';

//Childs
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html'
})

export class AdminLayoutComponent implements OnInit, AfterViewInit {
  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;
  private mediaSub: Subscription;
  // private sidebarPS: PerfectScrollbar;
  private bodyPS: PerfectScrollbar;
  private headerFixedBodyPS: PerfectScrollbar;
  public layoutConf: any = {};
  public activeDownTimeAlert: boolean = false;
  public downTimeAlertMessage: string = '';
  public pendingNotificationsCount: number = 0;
  public colorMode = 'dark'

  constructor(
    private http: HttpClient,
    private router: Router,
    public themeService: ThemeService,
    private layout: LayoutService,
    private media: MediaObserver,
    public activatedRoute: ActivatedRoute
  ) {
    // Close sidenav after route change in mobile
    this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange: NavigationEnd) => {
      this.layout.adjustLayout({ route: routeChange.url });
    });

  }
  ngOnInit() {
    this.initColorMode()
    this.layoutConf = this.layout.layoutConf;
    // this.layout.adjustLayout();

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });

    this.loadStatusDownTime();

  }

  initColorMode() {
    this.activatedRoute
      .data
      .subscribe((v) => {
        this.colorMode = v.colorMode
      });
  }

  onNotificationsCountUpdate(count){
    this.pendingNotificationsCount = count;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let windowSize:number = window.innerWidth;
    this.layout.adjustLayout(event);
  }

  @ViewChildren(ClientsComponent) child: ClientsComponent;

  public message: any;

  ngAfterViewInit() {

    //this.message = this.child.message;
    this.layoutConfSub = this.layout.layoutConf$.subscribe(change => {
      this.initBodyPS(change)
    })


  }

  initBodyPS(layoutConf:any = {}) {
    if(layoutConf.navigationPos === 'side' && layoutConf.topbarFixed) {
      if (this.bodyPS) this.bodyPS.destroy();
      if (this.headerFixedBodyPS) this.headerFixedBodyPS.destroy();
      this.headerFixedBodyPS = new PerfectScrollbar('.rightside-content-hold', {
        suppressScrollX: true
      });
      this.scrollToTop('.rightside-content-hold');
    } else {
      if (this.bodyPS) this.bodyPS.destroy();
      if (this.headerFixedBodyPS) this.headerFixedBodyPS.destroy();
      this.bodyPS = new PerfectScrollbar('.main-content-wrap', {
        suppressScrollX: true
      });
      this.scrollToTop('.main-content-wrap');
    }
  }

  scrollToTop(selector: string) {
    if(document) {
      let element = <HTMLElement>document.querySelector(selector);
      element.scrollTop = 0;
    }
  }

  ngOnDestroy() {
    if(this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe()
    }
    if(this.layoutConfSub) {
      this.layoutConfSub.unsubscribe()
    }
    if(this.routerEventSub) {
      this.routerEventSub.unsubscribe()
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  loadStatusDownTime(){
    this.http.get(`${environment.apiStatusServices}?q=downtime`).toPromise().then(response => {
        //First time
        let responseHasData = (response['downtime'] != undefined && Object.keys(response['downtime']).length > 0)
        let responseHasDownTimeAlert = (responseHasData && response['downtime']['isActive'] != undefined);
        let responseHasMessage = (responseHasData && response['downtime']['message'] != undefined);

        if(responseHasData && responseHasDownTimeAlert && responseHasMessage){
          if(!localStorage.getItem('activeDownTimeAlert') || localStorage.getItem('activeDownTimeAlert') === 'null' || localStorage.getItem('activeDownTimeAlert') === ''){
            this.activeDownTimeAlert = response['downtime']['isActive'];
            this.downTimeAlertMessage = response['downtime']['message'];
          }
        }
    });
  }

  hideDownTime(){
    this.activeDownTimeAlert = false;
    localStorage.setItem('activeDownTimeAlert', 'true');
  }

}
