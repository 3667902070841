import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'area-cards',
  templateUrl: './area_cards.html',
  styleUrls: ['./area_cards.scss']
})
export class AreaCardsComponent implements OnInit {

  public areasDataObjetc: any = [
    {id: "estate",
    name: "Estate Planning",
    classCardColor: 'brown-card',
    classColor: 'brown',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/02_Taxw.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/01_Estate.svg'
    },
    {id: "fulltax",
    name: "Tax Planning",
    classCardColor: 'red-card',
    classColor: 'red',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/01_Estatew.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/02_Tax.svg'
    },
    {id: "home",
    name: "Home Insurance",
    classCardColor: 'yellow-card',
    classColor: 'yellow',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Homew.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg'
    },
    {id: "auto",
    name: "Auto Insurance",
    classCardColor: 'orange-card',
    classColor: 'orange',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Autow.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg'
    },
    {id: "disability",
    name: "Disability Insurance",
    classCardColor: 'pink-card',
    classColor: 'pink',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/05_Disabilityw.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/05_Disability.svg'
    },
    {id: "ltc",
    name: "Long Term Care",
    classCardColor: 'black-card',
    classColor: 'black',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/07_LTCw.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/07_LTC.svg'
    },
    {id: "life",
    name: "Life Insurance",
    classCardColor: 'gray-card',
    classColor: 'gray',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/06_Lifew.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/06_Life.svg'
    },
    {id: "investment",
    name: "Investment and Retirement",
    classCardColor: 'green-card',
    classColor: 'green',
    iconUrlWhite: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/08_RetirementInvestmentw.svg",
    iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/08_RetirementInvestment.svg'
    }
  ]

  @Input() data: any;
  @Input() selectedAreas: any;
  public availableAreas: any = [];

  constructor() { }

  ngOnInit() {
  }

  // All changes to input data will be processed here.
  ngOnChanges(changes: SimpleChanges) {
    this.data = JSON.parse(JSON.stringify(changes.data.currentValue))
    this.data.forEach(area=>{
      this.availableAreas.push(area.id)
    })
    this.areasDataObjetc = this.areasDataObjetc.filter(area =>{ return this.availableAreas.includes(area.id)})
  }

  changCardColor(colorCard, areaId) {
    const cardElement = document.getElementById(colorCard);
    if (cardElement.classList.contains('card-active')) {
      document.getElementById(colorCard).classList.remove('card-active');
      const index = this.selectedAreas.indexOf(areaId);
      if (index > -1) {
        this.selectedAreas.splice(index, 1);
      }
    } else {
      document.getElementById(colorCard).classList.add('card-active');
      this.selectedAreas.push(areaId)
    }
  }

  getCardStatus(colorCard) {
    const cardElement = document.getElementById(colorCard);
    if (cardElement && cardElement.classList.contains('card-active')) {
      return true;
    } else {
      return false;
    }
  }

}
