import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-concurrent-session-modal',
  templateUrl: './concurrent-session-modal.component.html',
  styleUrls: ['./concurrent-session-modal.component.scss']
})
export class ConcurrentSessionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConcurrentSessionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

}
