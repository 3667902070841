import { ChartDataSets, ChartOptions } from "chart.js";

export interface YearByYearComparisionTable1 {
  age: string;
  year: string;
  conversion: {
    rothIRABalance: string;
    totalAfterTaxPortfolioBalance: string;
    traditionalIRABalance: string;
    taxableAcctEOY: string;
    taxesPaid: string;
    conversionAmount: string;
    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: string;
  };
  noConversion: {
    totalAfterTaxPortfolioBalance: string;
    traditionalIRABalance: string;
    taxableAcctEOY: string;
    rothIRABalance: string;
    taxesPaid: string;
    conversionAmount: string;
    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: string;
  };
}

export interface YearByYearComparisionTable2 {
  age: string;
  year: string;
  conversion: {
    marginalIncomeTaxRateString: string;
    withdrawalAccountEOY: string;
    valueToHeir: string;
    medicarePartBPremium: string;
    conversionAmount: string;
    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: string;
  };
  noConversion: {
    marginalIncomeTaxRateString: string;
    withdrawalAccountEOY: string;
    valueToHeir: string;
    medicarePartBPremium: string;
    conversionAmount: string;
    taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal: string;
  };
}

export interface RothV2ReportDataInterface {
  content?: any;
  isOptimized?: boolean;
  scenarioNumber: number;
  optimizationType: string;
  optimizationAmount: string;
  conversionAmountPerYear: number;
  numberOfYearsToConvert: number;
  kickOffAge: number;
  totalConversionAmount: string;
  totalTaxesPaidWithConversion: string;
  totalTaxesPaidWithoutConversion: string;
  //
  conversionGraphDatasets: ChartDataSets | any;
  conversionGraphLabels: any;
  conversionGraphOptions: ChartOptions;
  conversionGraphColors: any;
  conversionGraphLegends: any;

  //
  totalConversionAmount_: string;
  clientBenefit: string;
  savingsToHeir: string;
  taxesForConversions: string;
  taxesForDistributionsRMDs: string;
  totalTax: string;

  //PAGE Assumptions
  fillingStatus: string;
  currentAge: number;
  retirementAge: number;
  lifeExpectancy: number;
  // IRA Inputs
  beginningTraditionalIRABalance: number;
  beginningRothIRAAssetBalance: number;
  investmentRateOfReturn: number;
  IRADistributionsAfterRetirement: number;
  // Income
  currentOrdinaryIncome: number;
  currentIncomeGrowthRate: number;
  retirementTaxableIncome: number;
  retirementIncomeGrowthRate: number;
  //Tax Treatment
  sunset2026: "Yes" | "No";
  payConversionTaxesFromAlternateAccount: "Yes" | "No";
  //Input for heirs
  heirsMarginalOrdinayTaxRateAtDeath: number;

  // Page year by year comparision table

  yearByYearComparisionTable1: YearByYearComparisionTable1[];
  yearByYearComparisionTable2: YearByYearComparisionTable2[];

  //PAGE Other scenarios

  scenarioResume: {
    scenarioName: string;
    optimizationType: string;
    totalConversionAmount: string;
    numberOfYearsToConvert: string;
    kickOffAge: string;
    clientBenefit: string;
    savingsToHeir: string;
    totalTaxesPaid: string;
  }[];
}

const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const ROTH_PDF_DATA: RothV2ReportDataInterface = {
  scenarioNumber: 1,
  optimizationType: "Client Max Benefit",
  optimizationAmount: "3017713",
  conversionAmountPerYear: 20000,
  numberOfYearsToConvert: 10,
  kickOffAge: 55,
  totalConversionAmount: "2000000",
  totalTaxesPaidWithConversion: "4000",
  totalTaxesPaidWithoutConversion: "2000",
  //
  conversionGraphDatasets: [
    {
      data: [
        1714675, 1834703, 1963132, 2371601, 2830835, 3388674, 3689204, 3947448,
        4151271, 4441860, 4752790, 5085485, 5441469, 5822372, 6229938, 6666034,
        7132120, 7630258, 8162654, 8731664, 9339805, 9989773, 10684445,
        11426901, 12220432, 13068559, 13975045, 14943913, 15979465, 17086307,
        18269369, 19533921, 20885601, 22330439, 23874899, 25525901, 27290847,
        29177659, 31194838, 33351480, 35657326, 38122807, 40759087,
      ],
      label: "With Conversion",
      pointRadius: 3,
      pointBackgroundColor: "rgba(74, 122, 255, 1)",
      pointBorderColor: "#000000",
    },
    {
      data: [
        1714675, 1834703, 1963132, 2100551, 2317717, 2479957, 2653554, 2839303,
        2734709, 2926138, 3130968, 3350135, 3584645, 3835570, 4104060, 4391344,
        4696494, 5020606, 5364843, 5730440, 6118704, 6531037, 6968911, 7433908,
        7927705, 8452087, 9008948, 9600305, 10228299, 10895243, 11603602,
        12356004, 13155254, 14004346, 14906533, 15865298, 16884372, 17967755,
        19119814, 20345243, 21649087, 23036770, 24514126,
      ],
      label: "Without Conversion",
      pointRadius: 3,
      pointBackgroundColor: "rgba(255, 147, 30, 0.5)",
      pointBorderColor: "#000000",
    },
  ],
  conversionGraphLabels: [
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
  ],
  conversionGraphOptions: {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return (
            "$" +
            Number(tooltipItem.yLabel)
              .toFixed(0)
              .replace(/./g, function (c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0
                  ? "," + c
                  : c;
              })
          );
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: { fontColor: "#000000" },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#000000",
            callback: (value, index, values) => {
              return `$${nFormatter(value, 1)}`;
            },
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: { enabled: true, fontColor: "orange", content: "LineAnno" },
        },
      ],
    },
    colours: [
      {
        fillColor: "rgba(33, 139, 253, 1)",
        strokeColor: "rgba(33, 139, 253, 1)",
        highlightFill: "rgba(33, 139, 253, 1)",
        highlightStroke: "rgba(33, 139, 253, 1)",
        backgroundColor: "rgba(33, 139, 253, 1)",
        borderColor: "rgba(33, 139, 253, 1)",
      },
      {
        fillColor: "rgba(215, 234, 255, 1)",
        strokeColor: "rgba(215, 234, 255, 1)",
        highlightFill: "rgba(215, 234, 255, 1)",
        highlightStroke: "rgba(215, 234, 255, 1)",
        backgroundColor: "rgba(215, 234, 255, 1)",
        borderColor: "rgba(215, 234, 255, 1)",
      },
    ],
  },
  conversionGraphColors: [
    {
      backgroundColor: "rgba(74, 122, 255, 0.5)",
      hoverBackgroundColor: "rgba(74, 122, 255, 0.5)",
      borderColor: "rgba(74, 122, 255, 1)",
      hoverBorderColor: "rgba(74, 122, 255, 1)",
    },
    {
      backgroundColor: "rgba(255, 147, 30, 0.5)",
      hoverBackgroundColor: "rgba(255, 147, 30, 0.5)",
      borderColor: "rgba(255, 147, 30, 0.5)",
      hoverBorderColor: "rgba(255, 147, 30, 0.5)",
    },
  ],
  conversionGraphLegends: true,

  //
  totalConversionAmount_: "2000000",
  clientBenefit: "3017713",
  savingsToHeir: "509002",
  taxesForConversions: "60982",
  taxesForDistributionsRMDs: "12345",
  totalTax: "250000",

  //PAGE Assumptions
  fillingStatus: "Single",
  currentAge: 45,
  retirementAge: 50,
  lifeExpectancy: 70,
  // IRA Inputs
  beginningTraditionalIRABalance: 46537,
  beginningRothIRAAssetBalance: 87362,
  investmentRateOfReturn: 46573,
  IRADistributionsAfterRetirement: 72842,
  // Income
  currentOrdinaryIncome: 27472,
  currentIncomeGrowthRate: 98273,
  retirementTaxableIncome: 299810,
  retirementIncomeGrowthRate: 192830,
  //Tax Treatment
  sunset2026: "Yes",
  payConversionTaxesFromAlternateAccount: "No",
  //Input for heirs
  heirsMarginalOrdinayTaxRateAtDeath: 539823,

  // Page year by year comparision table

  yearByYearComparisionTable1: [],
  yearByYearComparisionTable2: [],

  //PAGE Other scenarios

  scenarioResume: [
    {
      scenarioName: "Scenario 1",
      optimizationType: "Client Max Benefit",
      totalConversionAmount: "3017713",
      numberOfYearsToConvert: "10",
      kickOffAge: "55",
      clientBenefit: "3017713",
      savingsToHeir: "509002",
      totalTaxesPaid: "2000",
    },
    {
      scenarioName: "Scenario 2",
      optimizationType: "Custom",
      totalConversionAmount: "4017713",
      numberOfYearsToConvert: "15",
      kickOffAge: "55",
      clientBenefit: "4017713",
      savingsToHeir: "983493",
      totalTaxesPaid: "103498",
    },
  ],
};
