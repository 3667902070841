import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-expired-session-modal',
  templateUrl: './expired-session-modal.component.html',
  styleUrls: ['./expired-session-modal.component.scss']
})
export class ExpiredSessionModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExpiredSessionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

  }

}

