//Angular
import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ModalAdvisorSubmittedComponent } from '../modal-advisor/modal-advisor.component';

@Component({
  selector: 'modal-advisor',
  templateUrl: './modal-recs.component.html',
  styleUrls: ['./modal-recs.component.scss'],
})
export class ModaProspectRecsComponent implements OnInit {

    public areaScore: number;
    public area: string;
    public title: string = '';
    public recs: any;
    public statusColor: string;
    public icon: string = '';

    constructor(
        public dialogRef: MatDialogRef<ModaProspectRecsComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any,
        private route: ActivatedRoute,
        public dialog: MatDialog,
    ) {
        this.areaScore = data.areaScore;
        this.title = data.title;
        this.recs = data.recs;
    }


    ngOnInit() {

        if(this.title.includes('Tax')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/02_Tax.svg"
        }

        if(this.title.includes('Estate')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/01_Estate.svg"
        }

        if(this.title.includes('Home')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg"
        }

        if(this.title.includes('Auto')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg"
        }

        if(this.title.includes('Disability')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/05_Disability.svg"
        }

        if(this.title.includes('Long Term Care')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/07_LTC.svg"
        }

        if(this.title.includes('Life')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/06_Life.svg"
        }

        if(this.title.includes('Investment')){
            this.icon = "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/08_RetirementInvestment.svg"
        }


        if(Number(this.areaScore) > 1 && Number(this.areaScore) <= 9.9999) this.statusColor = 'danger-bar';
        if(Number(this.areaScore) >= 10 && Number(this.areaScore) <= 19.9999) this.statusColor = 'warn-bar-10';
        if(Number(this.areaScore) >= 20 && Number(this.areaScore) <= 29.9999) this.statusColor = 'warn-bar-20';
        if(Number(this.areaScore) >= 30 && Number(this.areaScore) <= 39.9999) this.statusColor = 'warn-bar-30';
        if(Number(this.areaScore) >= 40 && Number(this.areaScore) <= 49.9999) this.statusColor = 'warn-bar-40';
        if(Number(this.areaScore) >= 50 && Number(this.areaScore) <= 59.9999) this.statusColor = 'warn-bar';
        if(Number(this.areaScore) >= 60 && Number(this.areaScore) <= 69.9999) this.statusColor = 'ok-bar-60';
        if(Number(this.areaScore) >= 70 && Number(this.areaScore) <= 79.9999) this.statusColor = 'ok-bar-70';
        if(Number(this.areaScore) >= 80 && Number(this.areaScore) <= 89.9999) this.statusColor = 'ok-bar-80';
        if(Number(this.areaScore) >= 90 && Number(this.areaScore) <= 99) this.statusColor = 'ok-bar-90';
        if(Number(this.areaScore) === 100) this.statusColor = 'ok-bar';
      }



      contactMeModal(){
        const dialogRef = this.dialog.open(ModalAdvisorSubmittedComponent, {
          disableClose: false,
          panelClass: 'modal-dialog-questionnaire',
          height: '490px',
          width: '750px',
        });
      }

      ngOnChanges(changes: SimpleChanges): void {
          //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
          //Add '${implements OnChanges}' to the class.

      }

      closeOpportunities(){
        this.dialogRef.close();
      }

}
