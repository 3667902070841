import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadHistoryDialogComponent } from 'app/shared/components/dialogs/upload-history-dialog/upload-history-dialog.component';
import { PurchaseModalComponent } from 'app/shared/components/pricing-strategy/purchase-modal/purchase-modal/purchase-modal.component';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-credits-section',
  templateUrl: './credits-section.component.html',
  styleUrls: ['./credits-section.component.scss']
})
export class CreditsSectionComponent implements OnInit {

  public activeInfo: string = 'estate';
  public advisorInfo: any;
  public creditsDetail: any = {};
  public credits: any = {};
  public displayedColumns: string[] = ['title', 'stripeId', 'creditsQuantity', 'total', 'advisorName', 'creationDate', 'paymentMethod'];
  public loading: boolean = true;
  public historyTitles: any = {
    home: 'Home and Umbrella',
    auto: 'Auto',
    estate: 'Estate'
  }
  constructor(
    private dialog: MatDialog,
    private advisorService: AdvisorService
  ) { 

    this.advisorInfo = JSON.parse(localStorage.getItem('advisorInfo'));
  }

  changeInfo(info: string) {
    this.activeInfo = info;
  }

  ngOnInit(): void {
    this.creditsInfo();
  }

  async creditsInfo() {
    try {
      const ans = await this.advisorService.getCompanyCreditsV2(this.advisorInfo.companyId);
      Object.keys(ans).forEach((key) => {
        this.credits[key] = ans[key];
      });
      this.getPaymentsInfo();
    } catch(e) {
      console.log(e);
    }
  }

  async getPaymentsInfo() {
    try {
      const ans = await this.advisorService.getCreditsPaymentDetail(this.advisorInfo.companyId);
      Object.keys(ans).forEach((key) => {
        this.creditsDetail[key] = ans[key].map(({ title, creditsQuantity, total, advisorName, creationDate, paymentMethod, stripeId }) => ({
          title, creditsQuantity, total, advisorName, creationDate, paymentMethod, stripeId
        }));
      });
      this.loading = false;
      console.log(ans);
    } catch(e) {
      this.loading = false;
      console.log(e);
    }
  }

  async openUploadHistory() {
    try {
      // Mandar a llamar el servicio para obtener el historial
      const dialogRef = this.dialog.open(UploadHistoryDialogComponent, {
        panelClass: 'modal-dialog-black',
        width: '70vw',
        height: '80vh',
        maxHeight: '800px',
        data: {
          black: true,
          title: `History of ${this.historyTitles[this.activeInfo]} Uploads`,
          credits: this.credits[this.activeInfo].remaining,
          creditsClass: 'text-home-insurance',
          area: this.activeInfo,
          companyId: this.advisorInfo.companyId
        }
      });
      

      dialogRef.afterClosed().subscribe((res) => {
        console.log('Se cerró')
      })
    } catch(e) {
      console.log(e);
    }
  }

  async openPurchaseCredits() {
    try {
      // Mandar a llamar el servicio para obtener el historial
      const dialogRef = this.dialog.open(PurchaseModalComponent, {
        panelClass: 'modal-dialog-black',
        width: '800px',
        height: '90vh',
        maxHeight: '716px',
        data: {
          black: true,
          title: 'History of Home and Umbrella Uploads',
          credits: 2,
          creditsClass: 'text-home-insurance',
          type: this.activeInfo,
          creditsAvailable: this.credits[this.activeInfo].remaining,
          creditsUsed:  this.credits[this.activeInfo].total -  this.credits[this.activeInfo].remaining,
        },
        disableClose: true,
      });
      

      dialogRef.afterClosed().subscribe((res) => {
        this.creditsInfo();
      })
    } catch(e) {
      console.log(e);
    }
  }

}
