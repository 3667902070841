import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'text-table-component',
  templateUrl: 'text-table.component.html',
  styleUrls: ['./text-table.component.scss']
})

export class TextTableDataComponent implements OnInit{

  @Input() data: TextTableDataInterface | any;
  
  public dataTemplate: TextTableDataInterface = {
    title: "",
    columns: 0,
    componentType: "",
    data: [],
    id: "",
    rows: 0
  }

  constructor() {
  }

  ngOnInit() {
    this.data = this.dataTemplate;
  }


  generateCanvas(width): HTMLElement {

    //Creo div container
    var div = document.createElement('div');
    div.id = 'container';
    div.className = 'border pad';

    div.style.width = width + 'px';

    //Obtengo elemento de extractions y hago una copia
    const content: Element = document.getElementById('table-container');
    var content2 = <HTMLElement> content.cloneNode(true);

    //Se lo agrego al div container
    div.appendChild(content2);    

    //Cambio font size de extractions
    let notes: NodeListOf<HTMLElement> = content2.querySelectorAll('p');
    for(var i = 0; i<notes.length;i++){
      notes[i].style.setProperty("color", "black", "important");
      notes[i].style.fontSize = '10px';
      notes[i].className = 'fp-font';
    }    
 
    return content2;

  }
 
}

interface TextTableDataInterface {
    title?: string,
    columns?: number,
    componentType?: string, 
    data?: { subtitle?: string, value?: string }[],
    id: string,
    rows: number  
  }
  