import { imageToCanvasFromUrl } from 'app/shared/helpers/utils';
import * as jsPDF from 'jspdf';

import { font, fontBold } from './fonts.constants';

export const MM_TO_POINT = 2.83465;
export const PX_TO_MM = 0.264583333;

export abstract class PdfCore {

  public doc: jsPDF;

  public currentPosY: number = 0;
  public defaultFont: FontStyle;
  public lineSpace: number = .95;
  private currentFont: FontStyle;
  private lineHeight: number = 0;
  private formatedPageCount: number = 1;
  public isDebugEnabled = false;

  constructor(
    public pageDimensions: PageConfiguration = new PageConfiguration(PAPER_SIZES.LETTER),
    currentFont: FontStyle = new FontStyle('value-sans-regular-pro', [0, 0, 0], 12)
    ) {
    this.currentFont = currentFont;
    this.doc = new jsPDF({
      orientation: this.pageDimensions.isPortrait ? 'p' : 'l',
      unit: "mm",
      format: [this.pageDimensions.width * MM_TO_POINT, this.pageDimensions.height * MM_TO_POINT] //In SI units 1 point (PostScript) is 0.000352777... meters
    });
    //Set document fonts
    this.doc.addFileToVFS('value-sans-regular-pro-normal.ttf', font);
    this.doc.addFileToVFS('value-sans-medium-pro-bold.ttf', fontBold); // It is value sans pro bold
    this.doc.addFont('value-sans-regular-pro-normal.ttf', 'value-sans-regular-pro', 'normal');
    this.doc.addFont('value-sans-medium-pro-bold.ttf', 'value-sans-medium-pro', 'normal');
  }

  public getPageCount(){
    return this.formatedPageCount;
  }

  public setFont(fontStyle: FontStyle): void {
    this.currentFont = fontStyle;
    this.doc.setFont(fontStyle.font);
    this.doc.setFontSize(fontStyle.size);
    this.doc.setTextColor(...fontStyle.color);
    this.lineHeight = fontStyle.lineHeight();
    this.doc.setLineHeightFactor(fontStyle.lineSpacing);
  };

  public getPrintableSpaceSize() {

  }

  public paragraphFitsInPage(paragraphHeight: number): boolean {

    let newPosY = this.currentPosY + paragraphHeight;
    let endOfCanvasPosY = this.pageDimensions.getCanvasCoordinatesEnd().y;
    return (newPosY <= endOfCanvasPosY);
  }

  public remainingPageLines(paragraph: Paragraph): number {
    let remaninigSpace = this.remainingVerticalSpace();
    return (parseInt(String(remaninigSpace / paragraph.fontStyle.lineHeight())));
  }

  public remainingVerticalSpace(): number {
    let canvasEndCoordinates = this.pageDimensions.getCanvasCoordinatesEnd();
    return canvasEndCoordinates.y - this.currentPosY;
  }

  public addFormatToPage() {
    this.formatedPageCount ++;
    let currentFont = this.currentFont;
    this.setHeaderFormat();
    this.setFooterFormat();
    this.setFont(currentFont);
  }

  public abstract setHeaderFormat();

  public abstract setFooterFormat();

  public getPargraphLineHeight(text: string, fontStyle: FontStyle = this.currentFont, width?: number) {
    if (width == undefined) width = this.pageDimensions.getCanvasWitdth();
    let originalFont = this.currentFont;
    this.setFont(fontStyle);
    let lines = this.doc.splitTextToSize(text, width);
    let _lineHeight = (lines.length * this.lineHeight);
    this.setFont(originalFont);
    return _lineHeight;
  }

  /**
   * Counts the lines of text inside the paragraph using it's dimensions.
   * @param paragraph
   */
  public paragraphCountLines(paragraph: Paragraph): number {
    let width_mm = paragraph.width ? paragraph.width : this.pageDimensions.getCanvasWitdth();
    let originalFont = this.currentFont;
    this.setFont(paragraph.fontStyle);
    let lines: string[] = this.doc.splitTextToSize(paragraph.text, width_mm);
    this.setFont(originalFont);
    return lines.length;
  }

  public paragraphFitsHorizontally(paragraph: Paragraph): boolean {
    if (!paragraph.width) return true;
    let pagePrintableWidth = this.pageDimensions.getCanvasWitdth();
    let remainingHorizontalSpace = pagePrintableWidth - this.currentPosY;
    return remainingHorizontalSpace >= paragraph.width;
  }

  public printParagraph = (text: string, fontStyle: FontStyle = this.currentFont, width?: number, xPoint?: number, splitParagraph: boolean = true) => {
    this.printParagraphv2(new Paragraph(text, fontStyle, width, xPoint));
  }

  public printParagraphv2(paragraph: Paragraph, useGlobalCoordinates: boolean = true) {

    let isRawPrint = (paragraph.xPoint >= 0 && paragraph.yPoint >= 0);
    let scaleFactor = (72/25.4);
    let fontHeight = paragraph.fontStyle.size / scaleFactor;
    let descender = fontHeight * (paragraph.fontStyle.lineSpacing - 1)
    //let rowPadding = descender / 1.5;
    let rowPadding = descender //(paragraph.fontStyle.lineHeight() - fontHeight) / 2;
    let debugThis: boolean = false; //paragraph.text.includes('Consider keeping the policy.');

    //rowPadding = (paragraph.fontStyle.lineSpacing - 1) * (paragraph.fontStyle.lineHeight() + .5)
    if (!paragraph.width) paragraph.width = this.pageDimensions.getCanvasWitdth();
    if (!paragraph.xPoint) paragraph.xPoint = this.pageDimensions.getCanvasCoordinatesStart().x;
    if (!paragraph.yPoint) paragraph.yPoint = this.pageDimensions.getCanvasCoordinatesStart().y;

    let prevFontStyle = this.currentFont;
    this.setFont(paragraph.fontStyle);
    let textLines: string[] = this.doc.splitTextToSize(paragraph.text || '', paragraph.width);

    if (isRawPrint) {

      this.doc.text(textLines, paragraph.xPoint, paragraph.yPoint + rowPadding, {baseline: 'top'});
      if(this.isDebugEnabled) this.doc.rect(paragraph.xPoint, paragraph.yPoint, paragraph.width, textLines.length * paragraph.fontStyle.lineHeight());
      if(useGlobalCoordinates) {
        this.currentPosY = paragraph.yPoint + (textLines.length * paragraph.fontStyle.lineHeight());
      }

    } else {
      // Group the text lines in chunks that will fit on one page each.

      let chunksOfTextsLines = [];
      let remainingPageSpace = this.remainingPageLines(paragraph); //Current page remaining space in text lines
      let textLinesOnBlankPage = this.pageDimensions.getCanvasHeight() / paragraph.fontStyle.lineHeight(); // Calculate how many text lines can fit on a blank page

      while (textLines.length) { // While text lines available
        chunksOfTextsLines.push(textLines.splice(0, remainingPageSpace));
        remainingPageSpace = textLinesOnBlankPage; // Considering a new page will be added
      }



      //Print the chunks
      while (chunksOfTextsLines.length) {

        let currentChunk = chunksOfTextsLines.shift()
        this.doc.text(currentChunk, paragraph.xPoint, this.currentPosY + rowPadding, {baseline: 'top'});
        //Debug rectangle
        if(this.isDebugEnabled) this.doc.rect(paragraph.xPoint, this.currentPosY, paragraph.width, currentChunk.length * paragraph.fontStyle.lineHeight());
        this.currentPosY += currentChunk.length * paragraph.fontStyle.lineHeight();
        if (chunksOfTextsLines.length > 0) this.addFormattedPage(); // Add a page for the next chunk
      }
    }

    this.setFont(prevFontStyle);
  }

  public printRawParagraph(paragraph: Paragraph) {
    this.printParagraphv2(paragraph, false);
  }

  public printParagraphsInRow(paragraphs: Paragraph[], spaceBetween: number = 5, rowLenght?: number) {

    if (!rowLenght) rowLenght = this.pageDimensions.getCanvasWitdth();

    //Compensate the last space
    spaceBetween = spaceBetween + (spaceBetween / paragraphs.length);

    let yPositionBeforeActions = this.currentPosY;
    let greaterPosY: number = 0;
    let currPosX: number = this.pageDimensions.getCanvasCoordinatesStart().x;
    let defaultParagraphWidth = (rowLenght / paragraphs.length) - spaceBetween;

    paragraphs.forEach(paragraph => {
      // The paragraph width can be ommited, if so, the value will be the default.
      let paragraphWidth: number = paragraph.width > 0 ? paragraph.width : defaultParagraphWidth;
      paragraph.width = paragraphWidth;
      // Restablish yPos to be constant, reseted every for cycle
      this.currentPosY = yPositionBeforeActions;
      // If paragraph dosn't fit in the current page, the globlal yPos is on the new page line
      if (!this.paragraphFitsInPage(this.getPargraphLineHeight(paragraph.text, paragraph.fontStyle, paragraphWidth))) {
        yPositionBeforeActions = this.pageDimensions.getCanvasCoordinatesStart().y;
      }
      // Print paragraph in yPos, width as the paragrap specific, new x pos will be the prev paragraph width + space between
      //this.printParagraph(paragraph.text, paragraph.fontStyle, paragraphWidth, currPosX, false);
      paragraph.xPoint = currPosX;
      this.printParagraphv2(paragraph, false);
      paragraph.yPoint = this.currentPosY;
      currPosX += paragraphWidth + spaceBetween;
      // Get record of the longest paragraph to be the new global y pos
      greaterPosY = (paragraph.yPoint >= greaterPosY) ? paragraph.yPoint : greaterPosY;
    });
    // Set global yPos to be to yPos of the longest paragraph
    this.currentPosY = greaterPosY;

  }

  public addFormattedPage(): void {
    this.doc.addPage();
    this.addFormatToPage();
    // Start position for new page
    this.currentPosY = this.pageDimensions.getCanvasCoordinatesStart().y;
  }

  public addImage(image: ImagePDF){
    if(image.width != null && image.height != null){
      try{
        this.doc.addImage(image.image, image.extension, image.xPos, image.yPos, image.width, image.height,'','FAST');
      }catch(error){
        console.log('Pdf Core. Error adding image: ', error);+
        console.log(image.image);
      }

      if(this.isDebugEnabled) this.doc.rect(image.xPos, image.yPos, image.width, image.height);
    }else{
      this.doc.addImage(image.image, image.extension, image.xPos, image.yPos);
    }

  }

  public addImageFull(image: ImagePDF){
    this.doc.addImage(image.image, image.extension, image.xPos, image.yPos, this.pageDimensions.getCanvasWitdth(), this.pageDimensions.getCanvasHeight());
  }

}

export class FontStyle {
  public lineSpacing = 1.5
  constructor(
    public font: string,
    public color: number[],
    public size: number
  ) { }
  lineHeight() {
    let fontInmm = this.size / MM_TO_POINT;
    var descent = fontInmm * (this.lineSpacing - 1);
    return (fontInmm * this.lineSpacing) //+ descent;
  }
}

export class ImagePDF {
  public width: number = null;
  public height: number = null;
  constructor(
    public image: any,
    public xPos: number,
    public yPos: number,
    public extension: string = 'png',
  ) {
      if(typeof image === 'string'){
        console.log('SPLIT IMAGE: ', this.image, image)
        let imageMetaSections = this.image.split(',');
        let imageTypeID: string = imageMetaSections[1].charAt(0);
        switch(imageTypeID){
          case '/': extension = 'jpg'; break;
          case 'i': extension = 'png'; break;
          case 'R': extension = 'gif'; break;
          case 'U': extension = 'webp'; break;
        }
      }else{
        console.log(`ImagePDF. Image is not a B64 string. Default to png`);
      }
   }

  public halfSize(): ImagePDF {
    this.width = this.width / 2;
    this.height= this.height / 2;
    return this;
  }
}

export class Paragraph {
  constructor(
    public text: string,
    public fontStyle: FontStyle,
    public width?: number,
    public xPoint?: number,
    public yPoint?: number
  ) { }
}

export class PageDimensions {
  constructor(
    public width: number,
    public height: number,
    public margin?: Margin
  ) {
    
    //if(this.margin === undefined) this.margin = new Margin();
  }


  getCanvasWitdth(): number {
    return this.width - (this.margin.right + this.margin.left);
  }

  getCanvasHeight(): number {
    return this.height - (this.margin.top + this.margin.bottom);
  }

  getCanvasCoordinatesStart(): ({ x: number, y: number }) {
    return {
      x: this.margin.left,
      y: this.margin.top
    }
  }

/**
 * getCanvasCoordinatesEnd
 * @returns The ending coordinages considering margins
 */
  getCanvasCoordinatesEnd(): ({ x: number, y: number }) {
    return {
      x: this.width - this.margin.right,
      y: this.height - this.margin.bottom
    }
  }
}

export class PageConfiguration extends PageDimensions{
  constructor(
    public dimensionsWxH: number[],
    public margin: Margin = new Margin(),
    public isPortrait: boolean = true
  ){
    // Invert the dimensions for a non portrait mode or landscape mode
    super(dimensionsWxH[isPortrait ? 0 : 1], dimensionsWxH[isPortrait ? 1 : 0], margin);
  }
}

export const PAPER_SIZES = {
  A2: [420, 594],
  A3: [297, 420],
  A4: [210, 297],
  A5: [148, 210],
  A6: [105, 148],
  LETTER: [215.9, 279.4],
  LEGAL: [215.9, 355.6],
}

export class Margin {
  static readonly initialValue = 0;
  constructor(
    public top: number = Margin.initialValue,
    public right: number = Margin.initialValue,
    public bottom: number = Margin.initialValue,
    public left: number = Margin.initialValue,
  ) { }
}

function arrayToChunks(sourceArray: any[], chunkSize: number): any[] {
  let result = [];
  while (sourceArray.length) {
    result.push(sourceArray.splice(0, chunkSize));
  }

  return result;
}

export async function  getImageFromUrlAndFitToBox(imgUrl: string, boxWidth: number, boxHeight, unit: string = 'mm'): Promise <ImagePDF> {
  if(unit == 'mm'){ // If mm, convert to px
    boxHeight = boxHeight / PX_TO_MM;
    boxWidth = boxWidth / PX_TO_MM;
  }
  let image = await imageToCanvasFromUrl(imgUrl);
  let imagePdf: ImagePDF = new ImagePDF(image.toDataURL("image/png"), 0, 0);
  let scale = (image.width > 0 && image.height > 0) ? Math.min(boxWidth/ image.width, boxHeight / image.height) : 1;
  imagePdf.width = image.width * PX_TO_MM * scale;
  imagePdf.height = image.height * PX_TO_MM * scale;
  
  return imagePdf;
}


export function calculateCanvasAdjustedSize(canvas: any, boxWidth: number, boxHeight: number, unit: string = 'mm'){
  
  if(unit == 'mm'){ // If mm, convert to px
    boxHeight = boxHeight / PX_TO_MM;
    boxWidth = boxWidth / PX_TO_MM;
    let canvasWidth = Boolean(canvas.offsetWidth) ? canvas.offsetWidth :  parseFloat(canvas.style.width) //+ parseFloat(canvas.style.marginLeft) + parseFloat(canvas.style.marginRight);
    let canvasHeight = Boolean(canvas.offsetHeight) ? canvas.offsetHeight :  parseFloat(canvas.style.height) //+ parseFloat(canvas.style.marginTop) + parseFloat(canvas.style.marginBottom);
    let scale = (canvasWidth > 0 && canvasHeight > 0) ? Math.min(boxWidth/ canvasWidth, boxHeight / canvasHeight) : 1;
    
    return{
      width: canvasWidth * PX_TO_MM * scale,
      height: canvasHeight * PX_TO_MM * scale,
      scale: scale
    }
  }
}
