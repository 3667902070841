import { AREAS, Area } from "app/shared/helpers/areas.catalog"
import { readFromStoragedObject, utilsCalculateAge } from "app/shared/helpers/utils"

export const reducerDashboard = (obj)=>{
    return {
      ...obj
    }
  }
export const reducerGeneralQuestionnaire = (obj)=>{
    const names = ['personalInformation', 'family', 'assets', 'dashboardData']
    const unifiedSections = {}
    obj.forEach((section, index) => {
      unifiedSections[names[index]] = section
    })
    return unifiedSections
}

export const reducerClientData = (obj)=>{
  const names = ['personalInformation', 'assets', 'family', 'dashboardData'];
  const sections = {}
  obj.forEach((section, index) => {
    sections[names[index]] = (index == 3) ? section : section.data;
  })

  console.log('reducerClientData obj', obj);

  let childrens = sections['family'].find(family => family.type == 'Children');

  let clientHasAnsweredQuestionnaires = (response) => {
    return Area.mapAreasToObject(
      (area) => {
        return {
          ["ans" + area.id.charAt(0).toUpperCase() + area.id.slice(1)]:
            response.clientHasAnsweredQuestionnaires[area.id],
        };
      },
      AREAS
    );
  }


  let clientData = {
      clientId: '',
      name: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDFirstName'),
      lastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDLastName'),
      photo: sections['dashboardData']['clientIDPhoto'],
      age: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDAge'),
      level: sections['dashboardData']['optLevel'],
      email: sections['dashboardData']['email'],
      isMarried: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDMaritalStatus'),
      hasChildren: childrens != undefined ? childrens.data.length > 0: false,
      hasGrandChildren: (sections['family'].find(family => family.type == 'Grandchildren').data.length) > 0,
      hasBusiness: sections['assets'].business.find(business => business.ownerData.key == 'Client') != undefined,
      //typeOfBusiness: sections['dashboardData']['clientIDHasABusiness'] == 'Yes' : sections['dashboardData']['optLevel'],,
      isRetired: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDRetiredOrNotWorking'),
      livesIn: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDLivingState'),
      isProspect: '',
      childsAreOver18: childrens != undefined ? childrens.data.every(child => {return utilsCalculateAge(new Date(Number(child.birthdate))) >= 18}) : false,
      //childOneAge: this.clientIDChildOneAge,
      //childTwoAge: this.clientIDChildTwoAge,
      //childThreeAge: this.clientIDChildThreeAge,
      //childFourAge: this.clientIDChildFourAge,
      //childFiveAge: this.clientIDChildThreeAge,
      netWorth: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDNetWorth'),
      spouse: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseName'),
      spouseLastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseLastName'),
      spouseAge: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseAge'),
      spouseNetWorth: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseNetWorth'),
      spouseIsRetired: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseRetired'),
      spouseHasBusiness: sections['assets'].business.find(business => business.ownerData.key == 'Co-Client') != undefined,
      spouseUsesOwnLastName: readAnswerFromGeneralQuestFormat(sections['personalInformation'], 'clientIDSpouseUsesLastName'),
      childrenCount: childrens != undefined ? childrens.data.length : 0,
      hasAssetProperties: sections['assets'].real_estate.length > 0,
      importedFromRedtail: '',
      comesFromAnyIntegration: '',
      prospectProgress: '',
      hasGeneralQuest: '',
      hasEstateDemoAccess: '',
      clientHasAnsweredQuestionnaires: clientHasAnsweredQuestionnaires(sections['dashboardData'])

  }

  return clientData
}

function readAnswerFromGeneralQuestFormat(generalQuestions: any[], key){
  let question = generalQuestions.find(question =>  question.name == key)
  if(question){
    return question.answer
  }
  return null;
}
