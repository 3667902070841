import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class IntegrationMapperService {

  public currentMapping = []

  constructor(private http: HttpClient) {}
t
  saveForm(mapperData) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    let body = {
      mapper: mapperData
    }
    return this.http
      .post<any>(
        `${environment.apiSalesforceCustomFields}`,
        body,
        options
      )
  }

  getFields(){
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .get<any>(
        `${environment.apiSalesforceGetFields}`,
        options
      )
  }
}
