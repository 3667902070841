import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-estate-planning-upload-dialog',
  templateUrl: './estate-planning-upload-dialog.component.html',
  styleUrls: ['./estate-planning-upload-dialog.component.scss']
})
export class EstatePlanningUploadDialogComponent implements OnInit {

  public acceptedConditions: any = '';

  constructor(
    public dialogRef: MatDialogRef<EstatePlanningUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }


  closeModal() {
    this.dialogRef.close();
  }

  submitForm() {
    this.dialogRef.close(true);
  }

}
