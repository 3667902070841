import html2canvas from 'html2canvas';

import {
  calculateCanvasAdjustedSize,
  FontStyle,
  getImageFromUrlAndFitToBox,
  ImagePDF,
  Margin,
  PageConfiguration,
  PAPER_SIZES,
  Paragraph,
  PdfCore,
} from '../PdfCore.class';


const FONT_RESUME_AREA_NAME = new FontStyle('value-sans-regular-pro', [0, 0, 0], 11);
const FONT_FOOTER = new FontStyle('value-sans-regular-pro', [85, 85, 85], 7);
const FONT_FOOTER_BOLD = new FontStyle('value-sans-medium-pro', [85, 85, 85], 7);
const FONT_CLIENT_NAME = new FontStyle('value-sans-regular-pro', [54, 90, 189], 16);
const FONT_FULL_DISCLAIMER_TITLE = new FontStyle('value-sans-medium-pro', [54, 90, 189], 16);
const FONT_FULL_DISCLAIMER_BODY = new FontStyle('value-sans-regular-pro', [0, 0, 0], 11);
const LATERAL_MARGIN = 10;
const TOP_MARGIN = 28;
const PAGE_CONFIG = new PageConfiguration(PAPER_SIZES.LETTER, new Margin(TOP_MARGIN, LATERAL_MARGIN, 16, LATERAL_MARGIN));

export class EstateSnapshotReportTemplate extends PdfCore {

  private constructor(
    private data: Data
  ) {

    super(PAGE_CONFIG);

    this.isDebugEnabled = false;



  }

  public setHeaderFormat() {
    this.data.resolved.logo.xPos = this.pageDimensions.getCanvasCoordinatesEnd().x - this.data.resolved.logo.width;
    this.data.resolved.logo.yPos = LATERAL_MARGIN;
    this.addImage(this.data.resolved.logo);
    // Print client name
    this.printParagraphv2(new Paragraph(this.data.clientName, FONT_CLIENT_NAME, null, LATERAL_MARGIN, LATERAL_MARGIN));
  }

  public setFooterFormat() {

    FONT_FOOTER.lineSpacing = 1;
    FONT_FOOTER_BOLD.lineSpacing = 1;

    let additionToDisclaimer: string = "This is not meant to capture every nuance of the document. Please refer back to the original documents.";

    this.printParagraphv2(new Paragraph(
      additionToDisclaimer,
      FONT_FOOTER_BOLD,
      this.pageDimensions.getCanvasWitdth(),
      this.pageDimensions.margin.left,
      this.pageDimensions.getCanvasCoordinatesEnd().y)
    );

    this.printParagraphv2(new Paragraph(
      this.data.disclaimer,
      FONT_FOOTER,
      this.pageDimensions.getCanvasWitdth(),
      this.pageDimensions.margin.left,
      this.pageDimensions.getCanvasCoordinatesEnd().y + 2.8)
      );
  }

  public setFirstPageFormat() {

  }

/**
 *
 */

  async html2canvas2PdfImage(htmlElement, canvasWidth, canvasHeight): Promise<ImagePDF>{
    return new Promise<ImagePDF>(
      (resolve, reject)=>{
        html2canvas(htmlElement, { scale: 2, useCORS: true }).then(canvas => {
          getImageFromUrlAndFitToBox(canvas.toDataURL(), canvasWidth, canvasHeight).then(pdfImage => {
            resolve(pdfImage);
          })
        })
      }
    )
  }

  /**
   * Takes an array of pages and generates the main content for the report.
   * @returns Promise:void
   * @param pagesData The pages to be printed. The amount of canvases per page needs to fit in one page.
   */
 /* public async constructReport(pagesData: PageData[]): Promise<void> {

    let fixedCoordinatesStart = this.pageDimensions.getCanvasCoordinatesStart();
    let pageCanvasWidth = this.pageDimensions.getCanvasWitdth();
    let pageCanvasHeight = this.pageDimensions.getCanvasHeight();
    let tempHtmlToTakeACanvas = document.getElementById('tempForCanvas');




    this.addFormatToPage();

    let promisesSlot: ImagePDF[] = [];
    //Inicializar arreglo con nulls de dimension requerida;
    pagesData.map((pageData)=>{
      promisesSlot.push(null);
    })

    // Codigo paralelo
    await Promise.all(pagesData.map((pageData, index, array) => {
      return new Promise<ImagePDF>(
        (resolve, reject)=>{
          let tempHtmlToCanvas = document.createElement("div");
          //Print each canvas on the html
          pageData.canvases.map(canvas => {
            tempHtmlToCanvas.append(canvas.cloneNode(true));
            tempHtmlToTakeACanvas.append(tempHtmlToCanvas);
          })

          html2canvas(tempHtmlToCanvas, { scale: 2, useCORS: true }).then(canvas => {
            getImageFromUrlAndFitToBox(canvas.toDataURL(), pageCanvasWidth, pageCanvasHeight).then(pdfImage => {


              promisesSlot[index] = pdfImage;
              resolve(pdfImage);
            })
          })
      })
    })) //Despues de la ejecucicion paralela ordenar y utilizar lo obtenido
    .then((data) => {
      promisesSlot.map((pdfImage, index, slotArray) => {
        let pageData = pagesData[index];
        let fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 6);
        const marginDefault = 12.7;
        pdfImage.xPos = fixedCoordinatesStart.x + (pageCanvasWidth - pdfImage.width) / 2;
        pdfImage.yPos = fixedCoordinatesStart.y;
        //Print page title
        this.printParagraphv2(new Paragraph(pageData.pageTitle, FONT_RESUME_AREA_NAME, null, LATERAL_MARGIN, 18));
        this.addImage(pdfImage);
        if(Boolean(slotArray[index + 1])) this.addFormattedPage();
        this.printParagraphv2(new Paragraph(String(this.getPageCount() - 1), fontFooter, null, this.pageDimensions.width - marginDefault - 2, 261.7));

      })
    })

    //Añadir pagina de full disclaimer.
    if(this.data.fullPageDisclaimer != undefined){
      let fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 6);
      const marginDefault = 12.7;
      this.doc.addPage();
      this.setHeaderFormat();
      this.currentPosY = this.pageDimensions.getCanvasCoordinatesStart().y;

      this.printParagraphv2(new Paragraph(this.data.fullPageDisclaimer.title, FONT_FULL_DISCLAIMER_TITLE));
      this.currentPosY += 2;
      this.printParagraphv2(new Paragraph(this.data.fullPageDisclaimer.disclaimer, FONT_FULL_DISCLAIMER_BODY));
      this.printParagraphv2(new Paragraph(String(this.getPageCount()), fontFooter, null, this.pageDimensions.width - marginDefault - 2, 261.7));

    }

  } */

  public async constructReport(pagesData: PageData[]): Promise<void> {

    let fixedCoordinatesStart = this.pageDimensions.getCanvasCoordinatesStart();
    let pageCanvasWidth = this.pageDimensions.getCanvasWitdth();
    let pageCanvasHeight = this.pageDimensions.getCanvasHeight();
    const fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 6);
    const marginDefault = 12.7;

    //Inicializar arreglo con nulls de dimension requerida;
    let promisesSlot: Promise<ImagePDF>[] = [];
    pagesData.map((pageData, pageIndex)=>{ pageData.canvases.map(canvas=>{
        promisesSlot.push(null);
      })})


    //Generar arreglo ordenado de promesas html2canvas2PdfImage
    let initCanvasesIndex = 0;
    pagesData.map((pageData)=>{
      pageData.canvases.map((canvas)=>{
        promisesSlot[initCanvasesIndex++]=(this.html2canvas2PdfImage(canvas, pageCanvasWidth, pageCanvasHeight));
    })})




    // Codigo paralelo
    await Promise.all(promisesSlot).then(pdfImages =>{

      console.log(pdfImages);
      this.addFormatToPage();
      let canvasIndex = 0;

      pagesData.map((pageData, pageIndex)=>{

        this.printParagraphv2(new Paragraph(pageData.pageTitle, FONT_RESUME_AREA_NAME, null, LATERAL_MARGIN, 18));

        pageData.canvases.map(canvas=>{

          let pdfImage = pdfImages[canvasIndex]
          pdfImage.xPos = fixedCoordinatesStart.x + (pageCanvasWidth - pdfImage.width) / 2;
          pdfImage.yPos = this.currentPosY;
          this.addImage(pdfImage);
          this.currentPosY += pdfImage.height;
          canvasIndex++;

        })

        this.printParagraphv2(new Paragraph(String(this.getPageCount() - 1), fontFooter, null, this.pageDimensions.width - marginDefault - 2, 261.7));
        if(Boolean(pagesData[pageIndex + 1])) this.addFormattedPage();
      })
    })

    //Añadir pagina de full disclaimer.
    if(this.data.fullPageDisclaimer != undefined){
      let fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 6);
      const marginDefault = 12.7;
      this.doc.addPage();
      this.setHeaderFormat();
      this.currentPosY = this.pageDimensions.getCanvasCoordinatesStart().y;

      this.printParagraphv2(new Paragraph(this.data.fullPageDisclaimer.title, FONT_FULL_DISCLAIMER_TITLE));
      this.currentPosY += 2;
      this.printParagraphv2(new Paragraph(this.data.fullPageDisclaimer.disclaimer, FONT_FULL_DISCLAIMER_BODY));
      this.printParagraphv2(new Paragraph(String(this.getPageCount()), fontFooter, null, this.pageDimensions.width - marginDefault - 2, 261.7));

    }

  }

  /**
   * Distribute a Page Data into as many pages as required to fit all canvases properly.
   * @param notOptimizedPage PageData. A PageData that could contain more canvases than the space allowed per page
   * @param maxHeightmm Number [mm]. The space allowed per page to fit canvases. Given in [mm]
   * @returns PageData[]. An array of PageData
   */
  static distributePageCanvasesIntoMultiplePages(notOptimizedPage: PageData, maxWidthmm, maxHeightmm, ): PageData[] {


    let pagesData: PageData[] = [{ pageTitle: notOptimizedPage.pageTitle, canvases: [] }];
    let currentPage: number = 0;
    let usedPageHeight: number = 0;


    notOptimizedPage.canvases.map(canvas => {

      let canvasAdjustedDimesions = calculateCanvasAdjustedSize(canvas, maxWidthmm, maxHeightmm);

      usedPageHeight = (usedPageHeight + canvasAdjustedDimesions.height);

      if ((usedPageHeight > maxHeightmm) || canvas.classList.contains('print-single-page')) {
        currentPage++;
        usedPageHeight = canvasAdjustedDimesions.height;
        pagesData[currentPage] = { pageTitle: notOptimizedPage.pageTitle, canvases: [] };
      }
      pagesData[currentPage].canvases.push(canvas);
    })

    return pagesData;

  }

  /**
   * From data, generate the canvases, transforms and call the report generator. Returns pdfReport
   * @param data Contains the full pages (before being adjusted), disclaimer and logo.
   * @returns PdfReport
   */

  static async generateReport(data: Data): Promise<PdfCore> {



    // Solve all the requierd data
    //data.resolved.logo = await getImageFromUrlAndFitToBox(data.logoUrl, 29.5, 6);
    data.resolved.logo = await getImageFromUrlAndFitToBox(data.logoUrl, 41, 15)
    //data.disclaimer = Boolean(data.disclaimer) ? data.disclaimer : CONST_DISCLAIMER;
    //let additionToDisclaimer: string = "";//This is not meant to capture every nuance of the document. Please refer back to the original documents.";
    //data.disclaimer = additionToDisclaimer + ' ' + (Boolean(data.disclaimer) ? data.disclaimer : CONST_DISCLAIMER);
    //
    let pagesToPrint: PageData[] = [];
    let report: EstateSnapshotReportTemplate = new EstateSnapshotReportTemplate(data);

    const ADJUST_FOR_HEIGHT = -15;
    let maxWidth = report.pageDimensions.getCanvasWitdth();
    let maxHeight = report.pageDimensions.getCanvasHeight();


    data.pagesData.map(pageData => {
      pagesToPrint = pagesToPrint.concat(
        EstateSnapshotReportTemplate.distributePageCanvasesIntoMultiplePages(
          pageData,
          maxWidth,
          maxHeight
          )
      )
    });



    await report.constructReport(pagesToPrint);

    return report
  }

}

interface Data {
  clientName: string,
  disclaimer: string,
  logoUrl: string,
  pagesData?: PageData[],
  canvases?: any,
  canvasesTitle?: string[],
  fullPageDisclaimer?: ({title: string, disclaimer: string}),
  resolved?: {
    logo?: ImagePDF,
    canvases?: []
  }
}

export interface PageData {
  pageTitle: string;
  canvases: any[]
}
