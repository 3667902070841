import { GraphNode, GraphMap, GraphMapConnection } from "../graph-tool/GraphTool.class";
import { GraphToolComponent, deleteNodesRowWitNoConnections } from "../graph-tool/graph-tool.component";
import { getElementHeight, pushIntoArrayIfObjectIsUnique, pushIntoArrayIfUnique } from "app/shared/helpers/utils";
import { GraphToolIterativeCutterComponent } from "./graph-tool-iterative-cutter.component";

/**
 * Cut a rendered graph in two parts if the maxHieght is reached or returns the same graph.
 * Returns array of graphMaps
 */

export function graphVerticalCutter( graphInstance: GraphToolComponent, maxHeigth: number): GraphMap[] {

  let answ = {
    partA: JSON.parse(JSON.stringify(graphInstance.currentGraph)) as GraphMap,
    partB: {} as GraphMap
  }

  let newGraphs: GraphMap[] = [];

  /**
   * I. FIND THE OVERFLOW ROW. (excedentRowIndex)
   */

  let rowsHeight = [];
  //console.log("graphInstance", graphInstance.internalCurrentGraph);
  graphInstance.internalCurrentGraph.grid.rowsNodesById.map((row) => {
    let nodesHeight = [];
    row.map((nodeId) => {
      //console.log("nodeId", nodeId);
      let node = graphInstance.internalCurrentGraph.nodes.find(
        (node) => node.id == nodeId
      );
      //console.log("node", node);
      let nodeHTMLElement = document.getElementById(
        graphInstance.getHtmlNodeId(nodeId)
      );
      //console.log('element', nodeHTMLElement);
      let node_height = getElementHeight(nodeHTMLElement);
      nodesHeight.push(node_height);
      GraphToolComponent.getOrUpdateNodeMetadata(node, "_height", node_height);
    });
    rowsHeight.push(Math.max(...nodesHeight));
  });

  let currentRowsHeight: number = 0;
  let excedentRowIndex: number = null; //Row where an overflow exists.

  //console.log('rowsHeight', rowsHeight);

  rowsHeight.map((rowHeight, index) => {
    currentRowsHeight += rowHeight;
    if (currentRowsHeight > maxHeigth) {
      excedentRowIndex = index; //We found an overflow row (column bigger than the max);
    }
  });

  //console.log("rowsHeight", rowsHeight, 'currentRowsHeight', currentRowsHeight);
  //console.log("excedentRowIndex", excedentRowIndex, 'maxHeight', maxHeigth);

  // Ther is no overflow in any row.
  if(excedentRowIndex == null){
    return [answ.partA];
  }

  /**
   * II. FIND THE OVERFLOW NODES INSIDE THE OVERFLOW ROW (excedentNodesInRow)
   */

  let excedentNodesInRow = [];
  let heightBeforeOverflow = 0;

  if (excedentRowIndex != null) {
    for (let i = 0; i < excedentRowIndex; i++) {
      heightBeforeOverflow += rowsHeight[i];
    }

    graphInstance.internalCurrentGraph.grid.rowsNodesById[excedentRowIndex].map(
      (nodeID) => {
        let node = graphInstance.internalCurrentGraph.nodes.find(
          (node) => node.id == nodeID
        );
        let nodeHeight = GraphToolComponent.getOrUpdateNodeMetadata(node,"_height");

        if (nodeHeight + heightBeforeOverflow > maxHeigth) {
          excedentNodesInRow.push(node);
        }
      }
    );
  }

  //console.log("excedentNodesInRow", excedentNodesInRow);
  //console.log("heightBeforeOverflow", heightBeforeOverflow);

  /**
   * III. FIND THE SECTIONS INSIDE THE NODE THAT OVERFLOWED (excedentSubElementsInNode)
   */

  excedentNodesInRow.map((node) => {
    let excedentSubElementsInNode = [];
    let nodeHeightAccumulator = heightBeforeOverflow + 25; //Add the outter margin
    let nodeHTMLElement = document.getElementById(graphInstance.getHtmlNodeId(node.id));
    let subElementsInNode = nodeHTMLElement.getElementsByClassName("cont");

    //console.log("subElements", subElementsInNode);

    for (let i = 0; i < subElementsInNode.length; i++) {
      let subElementHeight = getElementHeight(subElementsInNode[i]);
      //console.log('subElementHeight', subElementsInNode[i], subElementHeight);
      nodeHeightAccumulator += subElementHeight;
      if (nodeHeightAccumulator > maxHeigth) {
        excedentSubElementsInNode.push(subElementsInNode[i]);
      }
    }


    //console.log('excedentSubElementsInNode', excedentSubElementsInNode);

    //If the excedent elements is a divider, the ignore this column for a new map.
    if(excedentSubElementsInNode.length == 1 && excedentSubElementsInNode[0].classList.contains('separator')){
      //excedentSubElementsInNode[0].classList.includes('separator')
      //console.log("Simple divider case", excedentSubElementsInNode[0]);
      return
    }

    //console.log("excedentNodeSubElements", excedentSubElementsInNode);
    let config = { refResultingConnetionMap: [] };
    let traverseResult = traverse(graphInstance.currentGraph, node.id, config);
    //console.log("traverse", traverseResult, config.refResultingConnetionMap);



    /**
     * IV. MAKE A NEW GRAPH WITH THE OVERFLOWED SECTIONS
     */
    if (excedentSubElementsInNode.length > 0) {
      let newMap = newGraphFromTraverse(
        graphInstance.currentGraph,
        config.refResultingConnetionMap,
        traverseResult
      );

      let indexOfNode = newMap.nodes.findIndex((_node) => _node.id === node.id);
      let newGraphNodeContent: any = newMap.nodes[indexOfNode].content;
      let partAGraphNodeContent: any = answ.partA.nodes[indexOfNode].content;

      //console.log('excedentSubElementsInNode', excedentSubElementsInNode);
      //console.log('newGraphNodeContent.content', newGraphNodeContent.content);

      newGraphNodeContent.content = newGraphNodeContent.content.slice(-1 * excedentSubElementsInNode.length);
      partAGraphNodeContent.content = partAGraphNodeContent.content.slice(0, subElementsInNode.length - excedentSubElementsInNode.length);

      //console.log('AFTER: newGraphNodeContent, partAGraphNodeContent', newGraphNodeContent, partAGraphNodeContent);



      //console.log("newMap", newMap);
      newGraphs.push(newMap);

    }
  });

  //console.log('newGraphs', JSON.parse(JSON.stringify(newGraphs)));

  if(newGraphs.length > 0){
    //console.log(newGraphs)
    answ.partB = jointGraphsToTheRight(newGraphs);
    answ.partB = deleteNodesRowWitNoConnections(answ.partB);
  }

  console.log('has parts A & B', answ.partA, answ.partB);
  return [answ.partA, answ.partB].filter(data => data != undefined && Object.keys(data).length);
}


/**
 * Finds the path from the node to the roth node that contains the stopKey
 * @param graph
 * @param initialNodeID
 * @param config
 * @returns
 */
function traverse(
  graph: GraphMap,
  initialNodeID: string,
  config?: { stopKey?: string; refResultingConnetionMap: any }
) {
  let upperNodes: any[] = [initialNodeID];
  let upperConnectionNodes = [];

  //Find initial node in the connections map

  let getParentNodeID = (childNodeID) => {
    let connection = graph.connections.find((connection) =>
      connection.nodeConnections.find(
        (toConnections) => toConnections.pointsToNodeId == childNodeID
      )
    );
    if (connection) {
      let auxConnection = JSON.parse(JSON.stringify(connection));
      let toInitalNodeConnection = auxConnection.nodeConnections.find(
        (toConnections) => toConnections.pointsToNodeId == childNodeID
      );
      //Delete other connections from parent to the current level.
      auxConnection.nodeConnections = [toInitalNodeConnection];
      upperConnectionNodes.push(auxConnection);
    }

    return connection != undefined ? connection.sourceNodeId : undefined;
  };

  let upperNodeID = getParentNodeID(initialNodeID);
  let isUpperNodeRootHeader: boolean = false;

  while (upperNodeID != null && !isUpperNodeRootHeader) {
    Array.isArray(upperNodeID)
      ? upperNodes.push(...upperNodeID)
      : upperNodes.push(upperNodeID);

    upperNodeID = getParentNodeID(upperNodeID);
    let upperNode = findNode(
      graph,
      Array.isArray(upperNodeID) ? upperNodeID[0] : upperNodeID
    );
    isUpperNodeRootHeader =
      upperNode != undefined &&
      upperNode.metaData != undefined &&
      Boolean(upperNode.metaData["isHeaderNode"]);
    //Add last node in this iteration, there will not be a next one.
    if (isUpperNodeRootHeader) {
      Array.isArray(upperNodeID)
        ? upperNodes.push(...upperNodeID)
        : upperNodes.push(upperNodeID);
    }
  }

  //console.log("upperConnectionNodes", upperConnectionNodes);
  if (config != undefined && config.refResultingConnetionMap != undefined) {
    //console.log("AQUIII");
    config.refResultingConnetionMap = upperConnectionNodes;
  }
  return upperNodes;
}

/**
 * Copiar el nuevo objeto de conexiones.
 * Cambiar estilos de los nodos que no deben aparecer a invisibles y pathbloquer.
 */

function newGraphFromTraverse(
  graph: GraphMap,
  newConnections: GraphMapConnection[],
  involvedNodesIDs: string[]
) {
  let _graph: GraphMap = JSON.parse(JSON.stringify(graph));

  //Partially delete unused nodes
  let unusedNodes = _graph.nodes.filter(
    (node) => !involvedNodesIDs.includes(node.id)
  );

  unusedNodes.map((node) => {
    if (node.metaData["class"]) {
      node.metaData["class"] += " hidden";
    }
  });

  _graph.connections = newConnections;

  return _graph;
}

/**
 * Find and return a node object inside the graph.
 * @param graph
 * @param nodeID
 * @returns
 */
function findNode(graph: GraphMap, nodeID: string): GraphNode | undefined {
  return graph.nodes.find((node) => node.id === nodeID);
}

/**
 * Joins partial sections to the right of the same Graph .
 * @param graphsToJoin
 * @returns
 */
export function jointGraphsToTheRight(graphsToJoin: GraphMap[]): GraphMap {

  let carryGraph = JSON.parse(JSON.stringify(graphsToJoin[0]));

  //console.log('graphsToJoin', graphsToJoin);

  let jointGraph = (graphA: GraphMap, graphB: GraphMap): GraphMap => {

    //Join nodes. In theory all the graphs contains the same nodes with different connections.

    //Join connections
    graphB.connections.map(graphBConnection => {

      let graphAConnection = graphA.connections.find(_graphAConnection => {
        return Array.isArray(_graphAConnection.sourceNodeId)
          ? _graphAConnection.sourceNodeId[0] === graphBConnection.sourceNodeId[0]
          : _graphAConnection.sourceNodeId === graphBConnection.sourceNodeId

      });

      graphBConnection.nodeConnections.map(graphBConnectionTo => {
        let updateNodeID = graphBConnectionTo.pointsToNodeId;
        findNode(graphA, updateNodeID).content = findNode(graphB, updateNodeID).content;
      })

      //console.log('graphAConnection, graphBConnection', graphAConnection, graphBConnection);

      if(graphAConnection !== undefined){
        graphBConnection.nodeConnections.map(graphBConnectionTo =>{
          pushIntoArrayIfObjectIsUnique(graphAConnection.nodeConnections, graphBConnectionTo, 'pointsToNodeId');
        })
      }else{
        graphA.connections.push(graphBConnection);
      }

    })

    //Join the grid
    graphB.grid.rowsNodesById.map((graphB_rows, rowIndex) => {
      graphB_rows.map(nodeIDinRowOfGraphB=>{
        pushIntoArrayIfUnique(graphA.grid.rowsNodesById[rowIndex], nodeIDinRowOfGraphB)
      })
    })

    //Set nodes metadata.
    graphB.nodes.map(graphBNode => {
      let nodeInGraphA = graphA.nodes.find(nodeA => nodeA.id === graphBNode.id);
      if(nodeInGraphA && graphBNode.metaData && graphBNode.metaData["class"] && !graphBNode.metaData["class"].includes('hidden')){
        nodeInGraphA.metaData = graphBNode.metaData;
      }
    })

    //Shrink header nodes

    graphA.nodes.map(node => {
      if(node.metaData && node.metaData['isHeaderNode'] != undefined && node.metaData['isHeaderNode'] == true){
        node.content['content'] = node.content['content'].slice(0, 1);
      }
    })


    return graphA;

  }

  graphsToJoin.map(graph=>{
    jointGraph(carryGraph, graph);
  })



  return carryGraph;
}

export function graphIterativeVerticalCutter(grapIterativeVerticalCutterInstance: GraphToolIterativeCutterComponent,  graphData: GraphMap, maxHeigth: number, ): GraphMap[]{

  grapIterativeVerticalCutterInstance.onGraphCutResponse.subscribe()
  //Render instances until there is no remaining.

  //Rener graph Data.
  //If render graph > max Height: cut. else: return graphData.
  // Render cuttedGraph
  //Repeat.

  return
}
