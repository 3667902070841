import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Area, AREAS } from 'app/shared/helpers/areas.catalog';
import { utilsCalculateAge } from 'app/shared/helpers/utils';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';
import {
  ENVIRONMENT_URL_PRODUCTION,
  getEndPointUrlByContext,
  urlHelperIsInEnvironment,
} from '../../../shared/helpers/url.helper';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CommaFormatedNumberToJSNumber } from '../DataTransformation.class';
import {
  CONST_ABSOLUTES,
  CONST_GENDERS,
  CONST_HOME_OWNRENT,
  CONST_MARRIAGESTATUS,
  CONST_STATES,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { general_questions } from './general.model';


@Component({
  selector: 'app-q-general',
  templateUrl: './q-general.component.html',
  styleUrls: ['./q-general.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class QGeneralComponent implements AfterViewChecked, OnInit, OnDestroy {

  @ViewChild('generalform', { static: true }) generalform;
  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;
  @ViewChild('spouseLastNameField') spouseLastNameField: ElementRef;

  public model: general_questions;
  public auxModel: any;
  public modelOutput: any;
  public idClient: string;
  public clientName: string;
  public clientLastName: string;
  public currentClient: any;
  public currentClientInfo: any[];
  public sendQuestionnaire = environment.apiQuestionnaire;
  public clientAns = null;
  public clientHasAnsweredQuestionnaire: boolean;
  private clientIsImportedFromRedtail : boolean;
  //public childrenCount: number = 0;
  // public grandChildrenCount: number = 0;
  public clientData: any;
  public whoAnswered: string;
  public CONSTANTS = { CONST_ABSOLUTES, CONST_GENDERS, CONST_MARRIAGESTATUS, CONST_STATES, CONST_HOME_OWNRENT };
  public validations = Validations;
  public clientAnsweredArray;
  public minDate:Date;
  public maxDate:Date;

  public realEstateList = ['Primary Residence', 'Residential Property for Investment', 'Commercial Property', 'Land', 'Other'];
  public disablePrimaryResidence = false;
  public familyBundle: any[] = [];
  public availableFamilyMembers: any[] = [];
  public beneficiaries529: any;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public disabled: boolean = false;

  public isAdvisor: boolean = false;

  public genderRequired: boolean = false;
  public dobRequired: boolean = false;
  public netWorthRequired: boolean = false;
  public livingStateRequired: boolean = false;
  public maritalStatusRequired: boolean = false;
  public hasBusinessRequired: boolean = false;
  public toggleSearchBar: boolean = false;
  public spouseNameRequired: boolean = false;

  public tempclientIDRealEstateList: any = [];

  public pdfUrl: string = environment.apiPDFQuestionnaire;

  public isEarlyTester: boolean = false;
  public isProduction: any;
  public deactivateByTimer: boolean = false;

  public readonly initialModel: general_questions = new general_questions();

  public maxPositions = 10;

  public dobInput = new Date();

  public loading = false;

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
  ) {
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
  }

  async ngOnInit() {


    this.isAdvisor = this.authService.isAdvisor();

    this.minDate = new Date(1900, 1, 1);
    this.maxDate = new Date();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));

    this.idClient = this.clientData.clientId;
    this.currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientName = this.clientData.name;
    this.clientLastName = this.clientData.lastName;
    this.clientIsImportedFromRedtail = this.clientData.importedFromRedtail;

    this.whoAnswered = 'Advisor';
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new general_questions();
    this.model.clientIDFirstName = this.clientName;
    this.model.clientIDLastName = this.clientLastName;

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    this.clientHasAnsweredQuestionnaire = clientHasAnswered && clientHasAnswered.ansGeneral;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    /**
     * Complete data for clients that doesn't have 10 properties.
     */
    let completeActivePositions = () =>{

      let activePositionsArrayProperty = this.model.activePositions_property.split(',')
      if(activePositionsArrayProperty.length < this.maxPositions) {
        for(let i = activePositionsArrayProperty.length; i<this.maxPositions; i++){
          activePositionsArrayProperty[i] = 'false'
        }
        this.model.activePositions_property = activePositionsArrayProperty.join(', ')

      }

      let activePositionsArrayPlan529 = this.model.activePositions_plan529Account.split(',')
      if(activePositionsArrayPlan529.length < this.maxPositions) {
        for(let i = activePositionsArrayPlan529.length; i<this.maxPositions; i++){
          activePositionsArrayPlan529[i] = 'false'
        }
        this.model.activePositions_plan529Account = activePositionsArrayPlan529.join(', ')

      }

      let activePositionsArrayTradIRA = this.model.activePositions_tradIRAAccount.split(',')
      if(activePositionsArrayTradIRA.length < this.maxPositions) {
        for(let i = activePositionsArrayTradIRA.length; i<this.maxPositions; i++){
          activePositionsArrayTradIRA[i] = 'false'
        }
        this.model.activePositions_tradIRAAccount = activePositionsArrayTradIRA.join(', ')

      }

      let activePositionsArrayRoth = this.model.activePositions_rothAccount.split(',')
      if(activePositionsArrayRoth.length < this.maxPositions) {
        for(let i = activePositionsArrayRoth.length; i<this.maxPositions; i++){
          activePositionsArrayRoth[i] = 'false'
        }
        this.model.activePositions_rothAccount = activePositionsArrayRoth.join(', ')

      }

      let activePositionsArrayPlan401 = this.model.activePositions_plan401KAccount.split(',')
      if(activePositionsArrayPlan401.length < this.maxPositions) {
        for(let i = activePositionsArrayPlan401.length; i<this.maxPositions; i++){
          activePositionsArrayPlan401[i] = 'false'
        }
        this.model.activePositions_plan401KAccount = activePositionsArrayPlan401.join(', ')

      }

      let activePositionsArrayPlan457 = this.model.activePositions_plan457Account.split(',')
      if(activePositionsArrayPlan457.length < this.maxPositions) {
        for(let i = activePositionsArrayPlan457.length; i<this.maxPositions; i++){
          activePositionsArrayPlan457[i] = 'false'
        }
        this.model.activePositions_plan457Account = activePositionsArrayPlan457.join(', ')

      }

      let activePositionsArrayBrokerage = this.model.activePositions_brokerage.split(',')
      if(activePositionsArrayBrokerage.length < this.maxPositions) {
        for(let i = activePositionsArrayBrokerage.length; i<this.maxPositions; i++){
          activePositionsArrayBrokerage[i] = 'false'
        }
        this.model.activePositions_brokerage = activePositionsArrayBrokerage.join(', ')

      }

      let activePositionsArrayBusiness = this.model.activePositions_business.split(',')
      if(activePositionsArrayBusiness.length < this.maxPositions) {
        for(let i = activePositionsArrayBusiness.length; i<this.maxPositions; i++){
          activePositionsArrayBusiness[i] = 'false'
        }
        this.model.activePositions_business = activePositionsArrayBusiness.join(', ')

      }

      let activePositionsArrayLifeInsurance = this.model.activePositions_lifeInsurance.split(',')
      if(activePositionsArrayLifeInsurance.length < this.maxPositions) {
        for(let i = activePositionsArrayLifeInsurance.length; i<this.maxPositions; i++){
          activePositionsArrayLifeInsurance[i] = 'false'
        }
        this.model.activePositions_lifeInsurance = activePositionsArrayLifeInsurance.join(', ')

      }

      let activePositionsArrayStudentLoan = this.model.activePositions_studentLoan.split(',')
      if(activePositionsArrayStudentLoan.length < this.maxPositions) {
        for(let i = activePositionsArrayStudentLoan.length; i<this.maxPositions; i++){
          activePositionsArrayStudentLoan[i] = 'false'
        }
        this.model.activePositions_studentLoan = activePositionsArrayStudentLoan.join(', ')

      }

      // Date of birth Fix
      const isoDate = this.model.clientIDBirthday.toISOString().split('T')[0];
      this.model.clientIDBirthday = moment(isoDate).toDate();
    }

    //Load questionnarie data, if clientHasAnswered OR clientIsImportedFromRedtail
    //if(this.clientHasAnsweredQuestionnaire || this.currentClient['comesFromAnyIntegration'] === true){ // The questionnaire has been answered, then load the data
      await this.qController.loadQuestionnaireDataAsync(this.idClient, 'general', this.model).then(response => {


        //Update age related to birthday at load
        this.model.clientIDChildOneAge = this.calculateAge(this.model.clientIDChildOneBirthday);
        this.model.clientIDChildTwoAge = this.calculateAge(this.model.clientIDChildTwoBirthday);
        this.model.clientIDChildThreeAge = this.calculateAge(this.model.clientIDChildThreeBirthday);
        this.model.clientIDGrandchildOneAge = this.calculateAge(this.model.clientIDGrandChildOneBirthday);
        this.model.clientIDGrandchildTwoAge = this.calculateAge(this.model.clientIDGrandChildTwoBirthday);
        this.model.clientIDGrandchildThreeAge = this.calculateAge(this.model.clientIDGrandChildThreeBirthday);

        //tempclientIDRealEstateList
        this.tempclientIDRealEstateList = this.model.clientIDRealEstateList.split(',');
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };

        completeActivePositions();
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      },
      (error) => {
        completeActivePositions();
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })


    //Set primary residence default value
    this.model.cliendIDRealEstateType_1 = 'Primary Residence';


    // this.inputChangeRealEstate();

    //if (labEarlyAccess.includes(localStorage.getItem('userId'))){
    if (localStorage.getItem('betaAccess').includes('labEarlyAccess')){
      this.isEarlyTester = true;
    }else{
      this.isEarlyTester = false;
    }

    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  /*
  changeBdate(event) {
    console.log('cambiandoooooooo:')
    console.log(typeof event);
    console.log(event);
    return moment(event).subtract(1, 'year').toISOString();
  }
  */

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  getAvailableFamilyMembers(){

    this.familyBundle = [
      {
        'value': this.model.clientIDFirstName,
        'sufix': 'Client'
      },
      {
        'value': this.model.clientIDSpouseName,
        'sufix': 'Spouse'
      },
      {
        'value': this.model.clientIDChildOneName,
        'sufix': 'Child'
      },
      {
        'value': this.model.clientIDChildTwoName,
        'sufix': 'Child'
      },
      {
        'value': this.model.clientIDChildThreeName,
        'sufix': 'Child'
      },
      {
        'value': this.model.clientIDChildFourName,
        'sufix': 'Child'
      },
      {
        'value': this.model.clientIDChildFiveName,
        'sufix': 'Child'
      },
      {
        'value': this.model.clientIDSiblingOneName,
        'sufix': 'Sibling'
      },
      {
        'value': this.model.clientIDSiblingTwoName,
        'sufix': 'Sibling'
      },
      {
        'value': this.model.clientIDSiblingThreeName,
        'sufix': 'Sibling'
      },
      {
        'value': this.model.clientIDSiblingFourName,
        'sufix': 'Sibling'
      },
      {
        'value': this.model.clientIDFatherName,
        'sufix': 'Father'
      },
      {
        'value': this.model.clientIDMotherName,
        'sufix': 'Mother'
      },
      {
        'value': this.model.clientIDGrandchildOneName,
        'sufix': 'Grandchild'
      },
      {
        'value': this.model.clientIDGrandchildTwoName,
        'sufix': 'Grandchild'
      },
      {
        'value': this.model.clientIDGrandchildThreeName,
        'sufix': 'Grandchild'
      },
      {
        'value': this.model.clientIDGrandchildFourName,
        'sufix': 'Grandchild'
      },
      {
        'value': this.model.clientIDGrandchildFiveName,
        'sufix': 'Grandchild'
      }
    ];
    this.availableFamilyMembers = [];
    this.familyBundle.forEach(member =>{
      if (member.value != ''){
        let tempMemberName = `${member.value} [${member.sufix}]`
        this.availableFamilyMembers.push(tempMemberName);
      }
    })
    return this.availableFamilyMembers
  }

  valuechange(newValue) {
    this.model.clientIDAge = newValue;
    this.model.clientIDSpouseAge = newValue;

    if (this.model.clientIDSpouseAge == undefined) {
      this.model.clientIDSpouseAge = newValue;
    }
  }

  calculateAge(DOB){
    return utilsCalculateAge(DOB);
  }

  addAsset(key):void {
    if(this.model[key] < 4){
      this.model[key]++;
    }
  }

  removeAsset(key):void {
    if(this.model[key] > 0){
      this.model[key]--;
    }
  }

  addChildren(): void {
    if(this.model.clientIDChildrenCount < 4){
      this.model.clientIDChildrenCount++;
    }
    //this.childrenCount++;

  }

  removeChildren(): void {
    if(this.model.clientIDChildrenCount > 0){
      this.model.clientIDChildrenCount--;
    }
    //this.childrenCount--;

  }

  addGrandChildren(): void {
    if(this.model.clientIDGrandChildrenCount < 5){
      this.model.clientIDGrandChildrenCount++;
    }
  }

  removeGrandChildren(): void {
    if(this.model.clientIDGrandChildrenCount > 0){
      this.model.clientIDGrandChildrenCount--;
    }
  }

  onSaveForLater() {
    this.submit(true);
  }

  showUploader(prop: string) {
    this.model[prop] = '';
  }

  evalSpouseLastName(item){
    if (item == 'No') {
      this.model.clientIDSpouseLastName = ''
    }
    setTimeout(() => {
      if(this.spouseLastNameField && item == 'Yes'){
        this.spouseLastNameField.nativeElement.focus();
      }
    }, 100);
  }

  radioChange(value){
    switch(value){
      case 'clientIDGender':
        this.genderRequired = false;
        break;
      case 'clientIDHasABusiness':
        this.hasBusinessRequired = false;
        break;
      case 'clientIDMaritalStatus':
        this.maritalStatusRequired = false;
        break;
    }
  }

  inputChange(value){
    switch(value){
      case 'clientIDBirthday':
        this.dobRequired = false;
        break;
      case 'clientIDLivingState':
        this.livingStateRequired = false;
        break;
      case 'clientIDNetWorth':
        this.netWorthRequired = false;
        break;
      case 'clientIDSpouseName':
        this.spouseNameRequired = false;
        break;
    }
  }


  inputChangeRealEstate(){
    if(this.model.cliendIDRealEstateType_1 == 'Primary Residence' || this.model.cliendIDRealEstateType_2 == 'Primary Residence' || this.model.cliendIDRealEstateType_3 == 'Primary Residence' || this.model.cliendIDRealEstateType_4 == 'Primary Residence'){
      this.disablePrimaryResidence = true;
    }else{
      this.disablePrimaryResidence = false;
    }
  }

  initializeQuestion(classType: any, value) {
    if (value === 'Yes') {
      this.activateEmptyPositionFromType(classType);
    } else {

      this.getPostionsArrayFromType(classType).forEach((elem, index) => {
        if (elem) {

          this.deleteAsset(index, classType);
        }
      })
    }
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;


      switch(el.name) {
        case 'clientIDHasTraditionalIRA':
          this.activateEmptyPositionFromType('tradIRAAccount');
          break;
        case 'clientIDHasRoth':
          this.activateEmptyPositionFromType('rothAccount');
          break;
        case 'clientIDHasAccessTo401KPlan':
          this.activateEmptyPositionFromType('plan401KAccount');
          break;
        case 'clientIDHasAccessTo457Plan':
          this.initializeQuestion('plan457Account', el.value);
          break;
        case 'clientIDHasBrokerageAcct':
          this.initializeQuestion('brokerage', el.value);
          break;
        case 'clientIDHasLTCInsurance':
          this.initializeQuestion('lifeInsurance', el.value);
          break;
        case 'clientIDHasStudentLoan':
          this.initializeQuestion('studentLoan', el.value);
          break;
        case 'clientIDHas529Plan':
          this.initializeQuestion('plan529Account', el.value);
          break;
        default:
          break;

      }
    }
  }

  validateDynamicInformation () {
    let ans = true;
    const auxVariables = [
      'cliendIDRealEstateValue_',
      'clientIDPropertyTitling_',
      'clientIDHas529Plan_AccountValue_',
      'clientIDHas529Plan_Custodian_',
      'clientIDHasTraditionalIRA_AccountValue_',
      'clientIDHasTraditionalIRA_Custodian_',
      'clientIDHasRoth_AccountValue_',
      'clientIDHasRoth_AccountTitle_',
      'clientIDHasAccessTo401KPlan_AccountValue_',
      'clientIDHasAccessTo401KPlan_AccountTitle_',
      'clientIDHasAccessTo457Plan_AccountValue_',
      'clientIDHasAccessTo457Plan_AccountTitle_',
      'clientIDHasBrokerageAcct_AccountBalance_',
      'clientIDHasBrokerageAcct_HowIsTitled_',
      'clientIDHasLTCInsurance_DeathBenefit_',
      'clientIDHasLTCInsurance_Beneficiaries_',
      'clientIDHasStudentLoan_LoanAmount_',
      'clientIDHasStudentLoan_WhoLoanIsIt_',
    ]
    for (let i = 0; i < auxVariables.length; ) {
      for (let j = 1; j <= 4; j++) {
        if (this.model[`${auxVariables[i+1]}`] && (this.model[`${auxVariables[i+1]}${j}`] !== undefined && this.model[`${auxVariables[i+1]}${j}`] !== '') && this.model[`${auxVariables[i]}${j}`] == 0) {
          ans = ans && false;
        }
      }
      i += 2;
    }
    return ans;
  }

  verifyMemberExistance(member) {
    const lowerMember = member.toLowerCase();
    const [father, mother, siblingOne, siblingTwo, siblingThree, siblingFour] = [this.model.clientIDFatherName, this.model.clientIDMotherName, this.model.clientIDSiblingOneName, this.model.clientIDSiblingTwoName, this.model.clientIDSiblingThreeName, this.model.clientIDSiblingFourName];
    if(member !== 'undefined') {
      return true;
    }
    if (lowerMember.includes('father') && !father) {

      return false;
    }
    if (lowerMember.includes('mother') && !mother) {

      return false;
    }
    if (lowerMember.includes('sibling') && !siblingOne) {

      return false;
    }
    if (lowerMember.includes('sibling') && !siblingTwo) {

      return false;
    }
    if (lowerMember.includes('sibling') && !siblingThree) {

      return false;
    }
    if (lowerMember.includes('sibling') && !siblingFour) {

      return false;
    }
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {
    this.loading = true;
    this.checkedFamilyQuestions();
    let auxModel: general_questions = new general_questions();
    auxModel = this.model;
    let clientHasAnsweredQuestionnaires;

    const isoDate = this.model.clientIDBirthday.toISOString().split('T')[0];
    this.model.clientIDBirthday = moment.utc(isoDate).toDate();

    if(saveForLater == false){
      /**
       * Validations for submit
       */
      let tempAge = this.calculateAge(this.model.clientIDBirthday);
      if(
        this.model.clientIDMaritalStatus == '' ||
        this.model.clientIDHasABusiness == '' ||
        this.model.clientIDGender == '' ||
        this.model.clientIDLivingState == ''||
        this.model.clientIDNetWorth <=0 ||
        tempAge <=0 ||
        this.model.clientIDMaritalStatus == 'Married' && this.model.clientIDSpouseName == '' ||
        this.model.clientIDMaritalStatus == 'Domestic Partner' && this.model.clientIDSpouseName == ''){
          this.qController.validateRequiredQuestions(this);
          tempAge <= 0 ? this.dobRequired = true : this.dobRequired = false;
          this.model.clientIDNetWorth <= 0 ? this.netWorthRequired = true : this.netWorthRequired = false;
          this.model.clientIDGender == '' ? this.genderRequired = true : this.genderRequired = false;
          this.model.clientIDLivingState === '' ? this.livingStateRequired = true : this.livingStateRequired = false;
          this.model.clientIDMaritalStatus == '' ? this.maritalStatusRequired = true : this.maritalStatusRequired = false;
          this.model.clientIDHasABusiness == '' ? this.hasBusinessRequired = true : this.hasBusinessRequired = false;
          this.model.clientIDMaritalStatus == 'Married' && this.model.clientIDSpouseName == '' || this.model.clientIDMaritalStatus == 'Domestic Partner' && this.model.clientIDSpouseName == '' ? this.spouseNameRequired = true : this.spouseNameRequired = false;
          return;
      }

      if (!this.validateDynamicInformation()) {
        this.snackBarError.open('You need to answer the required questions', "Ok", {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: 'error-snackbar'
        });

        return;
      }
      const keysToCheckBeneficiaries = ['clientIDHas529Plan_Beneficiaries_', 'clientIDHasTraditionalIRA_Beneficiaries_', 'clientIDHasRoth_Beneficiaries_', 'clientIDHasAccessTo401KPlan_Beneficiaries_', 'clientIDHasAccessTo457Plan_Beneficiaries_', 'clientIDHasBrokerageAcct_Beneficiaries_', 'clientIDHasLTCInsurance_Beneficiaries_']
      //       Mother
      // Client
      // Spouse
      // Child
      // Sibling
      // Father
      // Grandchild

      keysToCheckBeneficiaries.forEach((key) => {
        const arrayOne = this.model[`${key}1`];
        let resultArrayOne = [];
        const arrayTwo = this.model[`${key}2`];
        let resultArrayTwo = [];
        const arrayThree = this.model[`${key}3`];
        let resultArrayThree = [];

        if (arrayOne && arrayOne.length > 0) {
          arrayOne.map((member) => {
            if (this.verifyMemberExistance(member)) {
              resultArrayOne.push(member);
            }
          });
        }
        if (arrayTwo && arrayTwo.length > 0) {
          arrayTwo.map((member) => {
            if (this.verifyMemberExistance(member)) {
              resultArrayTwo.push(member);
            }
          });
        }
        if (arrayThree && arrayThree.length > 0) {
          arrayThree.map((member) => {
            if (this.verifyMemberExistance(member)) {
              resultArrayThree.push(member);
            }
          });
        }
          this.model[`${key}1`] = resultArrayOne.join(',');
          this.model[`${key}2`] = resultArrayTwo.join(',');
          this.model[`${key}3`] = resultArrayThree.join(',');
      })
      this.model.progressBar = 100;
      this.model.progressCompleted = true;
      this.model.progressTab = 0;
      this.disabled = true;
    }

    if (JSON.stringify(this.model) === JSON.stringify(this.auxModel) && this.model.progressBar === 0) {
      /*
      clientHasAnsweredQuestionnaires = {ansGeneral: false};
      sessionStorage.setItem("clientHasAnsweredQuestionnaires", JSON.stringify(clientHasAnsweredQuestionnaires));
      */
    } else {
      /*
      clientHasAnsweredQuestionnaires = {ansGeneral: true};
      sessionStorage.setItem("clientHasAnsweredQuestionnaires", JSON.stringify(clientHasAnsweredQuestionnaires));
      */
    }

    if (this.model.clientIDRetiredOrNotWorking === 'Yes') this.model.clientIDOccupation = 'Retired'

    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    let url = getEndPointUrlByContext('Questionnaire') + this.idClient + "/generalquest" +  (saveForLater ? '?saveLater=true' : '?saveLater=false') + '&index=1';
    // Set age by DOB
    this.model.clientIDAge = this.calculateAge(this.model.clientIDBirthday);

    this.http.post(url, this.model.toSaveFormat(), options)
      .subscribe(
        response => {
          this.loading = false;
          this.updateAnsweredQuestionnairesData();
          if(!saveForLater) this.updateSessionStorageData();
          if(!silentSave) this.qController.onSuccess(this);
          if(!silentSave) this.qController.toHub();

          //update session storage write hasAssetProperties
          let activePropertiesObj = this.model.activePositions_property;
          let currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
          currentClient['hasAssetProperties'] = activePropertiesObj.split(',').some(val => val === 'true');
          console.log('CURRENT CLIENT: ', currentClient);
          if (currentClient.isMarried !== 'Married') {
            currentClient.fullName = `${currentClient.name} ${currentClient.lastName}`;
          } else {
            if (this.model.clientIDSpouseUsesLastName === 'No') {
              currentClient.fullName = `${currentClient.name} & ${currentClient.spouse} ${currentClient.lastName}`;
            }
            else if (this.model.clientIDSpouseUsesLastName === 'Yes'){
              currentClient.fullName = `${currentClient.name} & ${currentClient.spouse} ${this.model.clientIDSpouseLastName || ''}`;
            }
          }
          sessionStorage.setItem('currentClient', JSON.stringify(currentClient));

        },
        error => {
          this.loading = false;
          if(!silentSave) this.qController.onError(this);
          this.disabled = false;
        }
      );
  }

  updateAnsweredQuestionnairesData(){
    //Update the list of answered questionnaires using the backend data and Areas list
    this.advisorService.getClientDashboardData(this.idClient).subscribe(response => {

      let clientHasAnsweredQuestionnaires = Area.mapAreasToObject(area => {
        return { ['ans' + area.id.charAt(0).toUpperCase() + area.id.slice(1)]: response.clientHasAnsweredQuestionnaires[area.id] }
      }, AREAS);
      sessionStorage.setItem('clientHasAnsweredQuestionnaires', JSON.stringify(clientHasAnsweredQuestionnaires));
    })
  }

  checkedFamilyQuestions(){
    if(this.model.clientIDMaritalStatus === 'Single' || this.model.clientIDMaritalStatus === 'Widowed' || this.model.clientIDMaritalStatus === 'Divorce'){
      this.model.clientIDSpouseName = '';
      this.model.clientIDSpouseUsesLastName = '';
      this.model.clientIDSpouseLastName = '';
      this.model.clientIDSpouseGender = '';
      this.model.clientIDSpouseBirthday = new Date();
      this.model.clientIDSpouseNetWorth = 0;
      this.model.clientIDSpouseRetired = '';
      this.model.clientIDSpouseHasABusiness = '';
      this.model.clientIDSpouseAdressSame = '';
      this.model.clientIDSpouseNonResidentAlien = '';
    }

    if(this.model.clientIDChildren === 'No'){
      this.model.clientIDChildOneName = '';
      this.model.clientIDChildOneBirthday = new Date();
      this.model.clientIDChildOneAge = 0;

      this.model.clientIDChildTwoName = '';
      this.model.clientIDChildTwoBirthday = new Date();
      this.model.clientIDChildTwoAge = 0;

      this.model.clientIDChildThreeName = '';
      this.model.clientIDChildThreeBirthday = new Date();
      this.model.clientIDChildThreeAge = 0;

      this.model.clientIDChildFourName = '';
      this.model.clientIDChildFourBirthday = new Date();
      this.model.clientIDChildFourAge = 0;

      this.model.clientIDChildFiveName = '';
      this.model.clientIDChildFiveBirthday = new Date();
      this.model.clientIDChildFiveAge = 0;
    }
  }

  updateSessionStorageData() {

    if(this.model.clientIDChildrenCount == 0){
      this.model.clientIDChildOneAge = this.calculateAge(this.model.clientIDChildOneBirthday);
    }


    if(this.model.clientIDChildrenCount == 1){
      this.model.clientIDChildOneAge = this.calculateAge(this.model.clientIDChildOneBirthday);
      this.model.clientIDChildTwoAge = this.calculateAge(this.model.clientIDChildTwoBirthday);
    }

    if(this.model.clientIDChildrenCount >= 2){
      this.model.clientIDChildOneAge = this.calculateAge(this.model.clientIDChildOneBirthday);
      this.model.clientIDChildTwoAge = this.calculateAge(this.model.clientIDChildTwoBirthday);
      this.model.clientIDChildThreeAge = this.calculateAge(this.model.clientIDChildThreeBirthday);
    }

    this.clientData['age'] = this.model.clientIDAge;
    this.clientData['childOneAge'] = this.model.clientIDChildOneAge;
    this.clientData['childTwoAge'] = this.model.clientIDChildTwoAge;
    this.clientData['childThreeAge'] = this.model.clientIDChildThreeAge;
    this.clientData['childrenCount'] = this.model.clientIDChildrenCount;
    this.clientData['hasChildren'] = this.model.clientIDChildren;
    this.clientData['hasBusiness'] = this.model.clientIDHasABusiness;
    this.clientData['typeOfBusiness'] = this.model.clientIDBusinessEntity_1;
    this.clientData['isMarried'] = this.model.clientIDMaritalStatus;
    this.clientData['isRetired'] = this.model.clientIDRetiredOrNotWorking;
    this.clientData['spouse'] = this.model.clientIDSpouseName;
    this.clientData['spouseHasBusiness'] = this.model.clientIDSpouseHasABusiness;
    this.clientData['spouseIsRetired'] = this.model.clientIDSpouseRetired;
    this.clientData['spouseLastName'] = this.model.clientIDSpouseLastName;
    this.clientData['spouseUsesOwnLastName'] = this.model.clientIDSpouseUsesLastName;
    this.clientData['spouseNetWorth'] = CommaFormatedNumberToJSNumber(this.model.clientIDSpouseNetWorth);
    this.clientData['netWorth'] = CommaFormatedNumberToJSNumber(this.model.clientIDNetWorth);
    this.clientData['livesIn'] = this.model.clientIDLivingState;
    this.clientData['isRetired'] = this.model.clientIDRetiredOrNotWorking;

    sessionStorage.setItem("currentClient", JSON.stringify(this.clientData));

    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));

    this.clientAnsweredArray = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    //this.selected = tabChangeEvent.index;
    this.model.progressTab = tabChangeEvent.index;
    //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
    if(this.model.progressCompleted == true){
      this.model.progressBar = 100;
    }else{
      this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
    }


    if(tabChangeEvent.index === 2) {
      this.getAvailableFamilyMembers();
    }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(): void {
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'general'}`, options)
    .subscribe(blob => {
      saveAs(blob, 'general-questionnaire.pdf');
    });
  }

  getPostionsArrayFromType(classType: string): Boolean[]{
    return this.model['activePositions_' + classType].split(',').map( val => val === 'true');
  }

  setPostionsArrayFromType(classType: string, activePositions: Boolean[]): string{
    this.model['activePositions_' + classType] = String(activePositions);
    return this.model['activePositions_' + classType];
  }

  activateEmptyPositionFromType(classType: string){

    this.deactivateByTimer = true;




    let positions = this.getPostionsArrayFromType(classType);
    let index = positions.findIndex(val => val == false);
    positions[index] = true;
    let after = this.setPostionsArrayFromType(classType, positions);
    this.deactivateByTimer = false;

  }

  areAllPositionsActiveForType(classType: string): Boolean{
    return this.getPostionsArrayFromType(classType).reduce((accumulator, currentValue) => accumulator && currentValue);
  }

  deleteAsset(index: number, classType: string): void{
    let positionsArray: Boolean[] = this.getPostionsArrayFromType(classType);
    // let positionsArray: Boolean[] = this.model['activePositions_' + classType].split(',');

    let indexPlusOne = index + 1;

    // Liberate the position
    positionsArray[index] = false;
    // Save changes
    this.setPostionsArrayFromType(classType, positionsArray);


    // Set the inital values for the selected resource
    if(classType == 'property'){

      this.model['cliendIDRealEstateType_' + indexPlusOne] = this.initialModel['cliendIDRealEstateType_' + indexPlusOne];
      this.model['cliendIDRealEstateValue_' + indexPlusOne] = this.initialModel['cliendIDRealEstateValue_' + indexPlusOne];
      this.model['clientIDRealEstateLocation_' + indexPlusOne] = this.initialModel['clientIDRealEstateLocation_' + indexPlusOne];
      this.model['clientIDCostBasisOfProperty_' + indexPlusOne] = this.initialModel['clientIDCostBasisOfProperty_' + indexPlusOne];
      this.model['clientIDPropertyTitling_' + indexPlusOne] = this.initialModel['clientIDPropertyTitling_' + indexPlusOne];
      this.model['clientIDLoansAgainstProperty_' + indexPlusOne] = this.initialModel['clientIDLoansAgainstProperty_' + indexPlusOne];
      this.model['clientIDCurrentLoanBalance_' + indexPlusOne] = this.initialModel['clientIDCurrentLoanBalance_' + indexPlusOne];
      this.model['clientIDPropertyNickname_' + indexPlusOne] = this.initialModel['clientIDCurrentLoanBalance_' + indexPlusOne];



    }else if(classType == 'plan529Account'){

      this.model['clientIDHas529Plan_AccountValue_' + indexPlusOne] = this.initialModel['clientIDHas529Plan_AccountValue_' + indexPlusOne];
      this.model['clientIDHas529Plan_Custodian_' + indexPlusOne] = this.initialModel['clientIDHas529Plan_Custodian_' + indexPlusOne];

    }else if(classType == 'tradIRAAccount'){

      this.model['clientIDHasTraditionalIRA_AccountValue_' + indexPlusOne] = this.initialModel['clientIDHasTraditionalIRA_AccountValue_' + indexPlusOne];
      this.model['clientIDHasTraditionalIRA_Custodian_' + indexPlusOne] = this.initialModel['clientIDHasTraditionalIRA_Custodian_' + indexPlusOne];
      this.model['clientIDHasTraditionalIRA_Nickname_' + indexPlusOne] = this.initialModel['clientIDHasTraditionalIRA_Nickname_' + indexPlusOne];
      this.model['clientIDHasTraditionalIRA_Beneficiaries_' + indexPlusOne] = this.initialModel['clientIDHasTraditionalIRA_Beneficiaries_' + indexPlusOne];

    }else if(classType == 'rothAccount'){

      this.model['clientIDHasRoth_AccountValue_' + indexPlusOne] = this.initialModel['clientIDHasRoth_AccountValue_' + indexPlusOne];
      this.model['clientIDHasRoth_AccountTitle_' + indexPlusOne] = this.initialModel['clientIDHasRoth_AccountTitle_' + indexPlusOne];

    }else if(classType == 'plan401KAccount'){

      this.model['clientIDHasAccessTo401KPlan_AccountValue_' + indexPlusOne] = this.initialModel['clientIDHasAccessTo401KPlan_AccountValue_' + indexPlusOne];
      this.model['clientIDHasAccessTo401KPlan_AccountTitle_' + indexPlusOne] = this.initialModel['clientIDHasAccessTo401KPlan_AccountTitle_' + indexPlusOne];

    }else if(classType == 'plan457Account'){

      this.model['clientIDHasAccessTo457Plan_AccountValue_' + indexPlusOne] = this.initialModel['clientIDHasAccessTo457Plan_AccountValue_' + indexPlusOne];
      this.model['clientIDHasAccessTo457Plan_AccountTitle_' + indexPlusOne] = this.initialModel['clientIDHasAccessTo457Plan_AccountTitle_' + indexPlusOne];

    }else if(classType == 'brokerage'){

      this.model['clientIDHasBrokerageAcct_HowIsTitled_' + indexPlusOne] = this.initialModel['clientIDHasBrokerageAcct_HowIsTitled_' + indexPlusOne];
      this.model['clientIDHasBrokerageAcct_AccountBalance_' + indexPlusOne] = this.initialModel['clientIDHasBrokerageAcct_AccountBalance_' + indexPlusOne];

    }else if(classType == 'business'){

      this.model['clientIDBusinessName_' + indexPlusOne] = this.initialModel['clientIDBusinessName_' + indexPlusOne];
      this.model['clientIDBusinessEntity_' + indexPlusOne] = this.initialModel['clientIDBusinessEntity_' + indexPlusOne];
      this.model['clientIDBusinessLocation_' + indexPlusOne] = this.initialModel['clientIDBusinessLocation_' + indexPlusOne];
      this.model['clientIDBusinessValue_' + indexPlusOne] = this.initialModel['clientIDBusinessValue_' + indexPlusOne];
      this.model['clientIDBusinessOwnershipPercentage_' + indexPlusOne] = this.initialModel['clientIDBusinessOwnershipPercentage_' + indexPlusOne];


    }else if(classType == 'lifeInsurance'){

      this.model['clientIDHasLTCInsurance_Owner_' + indexPlusOne] = this.initialModel['clientIDHasLTCInsurance_Owner_' + indexPlusOne];
      this.model['clientIDHasLTCInsurance_DeathBenefit_' + indexPlusOne] = this.initialModel['clientIDHasLTCInsurance_DeathBenefit_' + indexPlusOne];

    }else if(classType == 'studentLoan'){

      this.model['clientIDHasStudentLoan_LoanAmount_' + indexPlusOne] = this.initialModel['clientIDHasStudentLoan_LoanAmount_' + indexPlusOne];
      this.model['clientIDHasStudentLoan_WhoLoanIsIt_' + indexPlusOne] = this.initialModel['clientIDHasStudentLoan_WhoLoanIsIt_' + indexPlusOne];
      this.model['clientIDHasStudentLoan_InterestRate_' + indexPlusOne] = this.initialModel['clientIDHasStudentLoan_InterestRate_' + indexPlusOne];
      this.model['clientIDHasStudentLoan_LenderName_' + indexPlusOne] = this.initialModel['clientIDHasStudentLoan_LenderName_' + indexPlusOne];

    }



  }

  activeAssets(){
    this.model.clientIDactiveAddAssets = true;
  }


}

/* public activePositions_properties: string = 'false, false, false';
public activePositions_plan529Acount: string = 'false, false, false';
public activePositions_retirementAcount: string = 'false, false, false';
public activePositions_brokerage: string = 'false, false, false';
public activePositions_business: string = 'false, false, false';
public activePositions_lifeInsurance: string = 'false, false, false';
public activePositions_studentLoan: string = 'false, false, false'; */
