import { Component, OnInit, QueryList, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { GraphToolComponent } from '../graph-tool/graph-tool.component';
import { GraphMap } from '../graph-tool/GraphTool.class';
import { graphVerticalCutter } from './graphVerticalCutter';
import { getRandomStr } from 'app/shared/helpers/utils';
import { Action } from 'app/shared/model/Edit-Snapshot.model';
import { ClusterAction } from '../estate-snapshot-v3/estate-snapshot-report/estate-snapshot-report.component';

@Component({
  selector: 'app-graph-tool-iterative-cutter',
  templateUrl: './graph-tool-iterative-cutter.component.html',
  styleUrls: ['./graph-tool-iterative-cutter.component.css']
})
export class GraphToolIterativeCutterComponent implements OnInit, ClusterAction {


  @Input() action_uid: string;
  @Input() action_tags: string[] = [];
  @Input() graphData: GraphMap;
  @Input() graphMetadata: GraphVerticalCutterMetadata;
  @Output() onGraphCutResponse = new EventEmitter<GraphMap[]>();

  @ViewChildren(GraphToolComponent) graphToolInstances!: QueryList<GraphToolComponent>;
  public renderedGraphs: GraphMap[] = [];
  public cuttedSections: GraphMap[] = [];
  public hasFinised: boolean = false;
  private lastIterationCutted: boolean = false;


  constructor() { }
  finalState: any;
  action_event: EventEmitter<any> = new EventEmitter<GraphMap[]>();
  action_allDone: boolean;


  ngOnInit(): void {

    if(this.action_uid == undefined){
      this.action_uid = getRandomStr(9);
    }

    console.log('iterativeCutter start', this.action_uid);

    if(this.graphMetadata == undefined){
      this.graphMetadata = {
        conatinerWidthPx: 960,
        conatinerHeightPx: 630,
        isPrintable: true
      }
    }

    this.action_event = this.onGraphCutResponse;

  }

  ngAfterViewInit(){

    if(this.graphToolInstances) this.graphToolInstances.changes.subscribe((r) => {
      console.log('iterativeCutter change detected');
      setTimeout(()=>{
      this.iterativeCutter();
      }, 800)
    })

    this.renderedGraphs.push(this.graphData);

  }

  iterativeCutter(){
      console.log('iterativeCutter');
      console.log('graph Data:', this.graphToolInstances.last);
      let cuttedGraph = graphVerticalCutter(this.graphToolInstances.last, this.graphMetadata.conatinerHeightPx);
      console.log('cuttedGraph result', JSON.parse(JSON.stringify(cuttedGraph)));
      cuttedGraph.map(graph => graph['cuttedID'] = this.action_uid)

      if(this.lastIterationCutted){
        this.cuttedSections.pop() //Remove last elemenet from response
      }

      if(cuttedGraph.length > 1){
        console.log('iterativeCutter CUT', this.action_uid);

        this.renderedGraphs.push(cuttedGraph[1]);
        this.lastIterationCutted = true;

      }else{
        this.lastIterationCutted = false;
      }

      this.cuttedSections.push(...cuttedGraph);

      if(cuttedGraph.length == 1){ //No more subdivision, return response
        console.log('iterativeCutter final product', this.cuttedSections)
        this.hasFinised = true;
        this.action_allDone = true;
        this.finalState = this.cuttedSections;
        this.onGraphCutResponse.emit(this.cuttedSections);
        console.log('iterativeCutter END', this.action_uid);
      }


      /**
       *
       * Render 1 -> cut(render1)-> 1 o 2
       *
       * Si cut lenght 2: Render N -> cut(renderN), Repeat
       * [A*]
       * [A][B*]
       * [A][B1][B2*]
       * [A][B1][C1][C2]
       */

  }

}

export interface GraphVerticalCutterMetadata{
  conatinerWidthPx: number,
  conatinerHeightPx: number,
  isPrintable?: boolean
  tag?: string,
  secondaryTag?: string,
}
