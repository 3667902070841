export interface Scenario {
  _id: string,
  name: string,
  position?: number | null,
  description?: string,
  dateCreated?: string, //timestamp
  dateUpdated?: string, ////timestamp
  scenariosNames?: scenarioData[],
  sections?: Section[],
  overwriteList: string[],
}

export interface Section {
  id: string
  data?: any
}

export interface scenarioData {
  id: string,
  name: string,
  edit: boolean,
  position?: number,
  overwriteList?: any[]
}

export interface Data{
  id: string
  title: string,
  type: ('text' | 'number' | 'money' | 'stepperNumber' | 'staticText' | 'year' | 'status' | 'state' | 'wagesCola' | 'socialSecurityBenefitCola' |'taxableSocialSecurityPercentage' | 'magiTiersText' | 'name'),
  isEditable: boolean,
  calculatorId?: (string | null),
  alwaysVisible?: boolean,
  value?: (string | number),
  defaultValue?: (string | number),
  revertNumber?: boolean,
  fromSource?: string
}

export interface CatalogSection extends Section {
  id: string,
  title: string,
  data: Data[],
  onlyPrintable?: boolean
}

export interface ArtificialScenario extends Scenario{
  sections:({
    id: string,
    data?: any,
    sectionsData: Data[][],
  })[]
}


export const SECTIONS_CATALOG: CatalogSection[] = [
  {
    id: 'summaryOverview',
    title: 'Overview Information',
    onlyPrintable: true,
    data: [
      { id: 'year', title: 'Year', type: 'year', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: ''},
      { id: 'filingStatus', title: 'Filing Status', isEditable: true, type: 'status', calculatorId: null, alwaysVisible: false, defaultValue: ''},
      { id: 'state', title: 'State', type: 'state', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: ''},
    ]
  },
  {
    id: 'federalOverview',
    title: 'Federal Overview',
    onlyPrintable: true,
    data: [
      { id: 'grossIncome', title: 'Gross Income (subtotal)', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0' },
      { id: 'agi', title: 'AGI', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0' },
      { id: 'deductions', title: 'Deduction Subtotal', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'taxableIncome', title: 'Taxable Income', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'marginalTaxBracket', title: 'Federal Tax Bracket', type: 'text', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'totalTax', title: 'Total Tax', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'totalPayments', title: 'Total Payments', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'fedTaxOwed', title: 'Fed Tax Owed/Refunded if negative', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
    ]
  },
  {
    id: 'stateOverview',
    title: 'State Overview',
    onlyPrintable: true,
    data: [
      { id: 'stateAgi', title: 'State AGI', type: 'staticText', isEditable: true, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateTaxableIncome', title: 'State Taxable Income', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateMarginalTaxBracket', title: 'State Marginal Tax Bracket', type: 'text', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateTaxAmount', title: 'State Tax Amount', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true , defaultValue: 0},
      { id: 'stateTaxOwed', title: 'State Tax Owed/Refunded if negative', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},

    ]
  },
  {
    id: 'summaryInformation',
    title: 'Summary Information',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'year', title: 'Year', type: 'year', isEditable: true, calculatorId: null, alwaysVisible: false },
      { id: 'filingStatus', title: 'Filing Status', isEditable: true, type: 'status', calculatorId: null, alwaysVisible: false },
      { id: 'state', title: 'State', type: 'state', isEditable: true, calculatorId: null, alwaysVisible: false},
      { id: 'olderFilers', title: '# of filers Age 65 or Older? ', isEditable: true, type: 'stepperNumber', alwaysVisible: false, defaultValue: 0},
      { id: 'dependentFilers', title: '# of Dependent Filers ', type: 'stepperNumber', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0},
      { id: 'disabledFilers', title: '# of Filers Blind or Permanently', type: 'stepperNumber', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0},
    ]
  },
  {
    id: 'grossIncome',
    title: 'Gross Income',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'wages', title: 'W-2 Wages, Salary, tips', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0},
      { id: 'wages_cola', title: '', type: 'wagesCola', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'nontaxableInterest', title: 'Nontaxable Interest', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0},
      { id: 'taxableInterest', title: 'Taxable Interest', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleBCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'qualifiedDividends', title: 'Qualified Dividends', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'ordinaryDividends', title: 'Ordinary Dividends', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleBCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'taxableIRA', title: 'Taxable IRA Distributions', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'taxablePensionsAndAnnuities', title: 'Taxable Pensions & Annuities', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'socialSecurityBenefit', title: 'Social Security Benefit', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'socialSecurityBenefit_cola', title: '', type: 'socialSecurityBenefitCola', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'taxableSocialSecurity', title: 'Taxable Social Security', type: 'staticText', isEditable: false, revertNumber: false, calculatorId: 'scheduleDCalculator', alwaysVisible: false, defaultValue: '0' },
      { id: 'taxableSocialSecurity_percentage', title: 'Taxable Social Security', type: 'taxableSocialSecurityPercentage', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'ltcgl', title: 'Long-Term Capital Gain or Loss', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleDCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'stcgl', title: 'Short-Term Capital Gain or Loss', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleDCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'capitalGainOrLoss', title: 'Capital Gain or Loss', type: 'staticText', isEditable: false, revertNumber: false, calculatorId: 'scheduleDCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'otherIncome', title: 'Other Income (Sch 1)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleOneCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'grossIncome', title: 'Gross Income (subtotal)', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0' },
    ]
  },
  {
    id: 'agi',
    title: 'AGI',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'adjustmentsToIncome', title: 'Adjustments to Income (Sch 1)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleOneCalculator', alwaysVisible: false, defaultValue: 0},
      { id: 'agi', title: 'AGI', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0' },
    ]
  },
  {
    id: 'deductions',
    title: 'Deductions',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'standardOrItemizedDeductions', title: 'Standard or Itemized Deduction', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleACalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'charitableDeductionsIfStandard', title: 'Charitable Deductions (if taking Standard Deduction) - 2021 only', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'qbiPreDeduction', title: 'Pre-QBI Deduction Subtotal', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'qbiDeduction', title: 'QBI Deduction', type: 'money', isEditable: true, revertNumber: true, calculatorId: 'qbidCalculator', alwaysVisible: false , defaultValue: 0},
      { id: 'deductions', title: 'Deduction Subtotal', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'taxableIncome', title: 'Taxable Income', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
    ]
  },
  {
    id: 'taxes',
    title: 'Taxes',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'marginalTaxBracket', title: 'Federal Tax Bracket', type: 'text', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'tax', title: 'Tax', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'taxesPaid', title: 'Add\'l Taxes Paid Subtotal (Sch 2 Line 3)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleTwoCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'nonrefundableChildTaxCredit', title: 'Nonrefundable Child Tax Credit', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'nonrefundableCredits', title: 'Total Nonrefundable Credits (Sch 3 Line 8)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleThreeCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'otherTaxes', title: 'Total Other Taxes (Sch 2 Line 21)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleTwoCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'totalTax', title: 'Total Tax', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
    ]
  },
  {
    id: 'totalPayments',
    title: 'Total Payments',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'estimatedPayments', title: 'Estimated Payments', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'taxWithheld', title: 'Tax Withheld', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'eic', title: 'EIC', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0},
      { id: 'refundableChildTaxCredit', title: 'Refundable or Additional Child Tax Credit', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'americanOpportunityCredit', title: 'American Opportunity Credit', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'recoveryRebateCredit', title: 'Recovery Rebate Credit', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'totalOtherPaymentsAndRefundableCredits_line15', title: 'Total Other Pmts & Refundable Credits (Amount from Sch 3, Line 15)', type: 'money', isEditable: true, revertNumber: false, calculatorId: 'scheduleThreeCalculator', alwaysVisible: false, defaultValue: 0 },
      { id: 'totalOtherPaymentsAndRefundableCredits', title: 'Total Other Payments & Refundable Credits', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false, defaultValue: 0 },
      { id: 'totalPayments', title: 'Total Payments', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'fedTaxOwed', title: 'Fed Tax Owed/Refunded if negative', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
    ]
  },
  {
    id: 'stateTaxes',
    title: 'State Taxes',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      // { id: 'stagAdjustmentseAgi', title: 'State Adj\'s to/Ded\'s from Fed AGI', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'stateFederalAgi', title: 'Federal AGI', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: '0'},
      { id: 'stateDeductionsToFederalAgi', title: 'State Deductions to Federal AGI', type: 'money', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: '0'},
      { id: 'stateTaxableSS', title: 'Taxable Social Security (Deduction)', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: '0'},
      { id: 'stateAgi', title: 'State AGI', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateStandardDeduction', title: 'State Standard Deduction', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'stateItemizedDeduction', title: 'State Itemized Deduction(s)', type: 'money', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: '0'},
      { id: 'statePersonalExemption', title: 'State Personal & Dependency Exemption', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'stateTaxableIncome', title: 'State Taxable Income', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateMarginalTaxBracket', title: 'State Marginal Tax Bracket', type: 'text', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
      { id: 'stateTaxAmount', title: 'State Tax Amount', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true , defaultValue: 0},
      { id: 'stateTaxPayments', title: 'State Tax Payments & Refundable Credits', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'stateStandardCredits', title: 'State Standard Credit (if applicable)', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'statePersonalCredit', title: 'State Personal & Dependency Credit (if applicable)', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'stateTaxOwed', title: 'State Tax Owed/Refunded if negative', type: 'staticText', isEditable: false, calculatorId: null, alwaysVisible: true, defaultValue: '0'},
    ]
  },
  {
    id: 'medicare',
    title: 'Medicare ',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'medicareBPremium', title: 'Medicare Part B Premium', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'medicareDPremium', title: 'Medicare Part D Premium', type: 'money', isEditable: true, calculatorId: null, alwaysVisible: false , defaultValue: 0},
      { id: 'alternativeMinimumTax', title: 'Alternative Minimum Tax', type: 'staticText', isEditable: true, calculatorId: null, alwaysVisible: false , defaultValue: 0},
    ]
  },
  {
    id: 'magiTiers',
    title: 'MAGI Tiers',
    data: [
      { id: 'name', title:'', type: 'name', isEditable: false, alwaysVisible: true},
      { id: 'additionalMedicareTax', title:'Additional Medicare Tax', type: 'magiTiersText', isEditable: false, alwaysVisible: false},
      { id: 'coverdellESAEligible', title: 'Coverdell ESA', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'lifetimeLearning', title: 'Lifetime Learning Credit', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'studentLoanDeduction', title: 'Student Loan Interest Deduction', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'americanOpportunityCredit', title: 'American Opportunity Credit', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'childTaxCredit', title: 'Child Tax Credit', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'qualifiedAdoptionExpensesCredit', title: 'Qualified Adoption Expenses Credit', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'saversCredit', title: 'Saver\'s Credit', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false, defaultValue: ''},
      { id: 'iraContributionDeductibility', title: 'IRA Contribution Deductibility - Covered by Qualified Plan', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
      { id: 'rothIraContribution', title: 'Roth IRA Contribution', type: 'magiTiersText', isEditable: false, calculatorId: null, alwaysVisible: false , defaultValue: ''},
    ]
  },
]



export const  _CALCULATORS_CATALOG = {
  id: 'scheduleBCalculator',
  sections: [
      {
          id: 'interest',
          data: {
              interestAssets: [
                  {
                      name: '',
                      amount: 0,
                  }
              ],
              subtotal: 0,
              includableInterest: 0
          }
      },
      {
          id: 'ordinaryDividends',
          data: {
              ordinaryDividendsAssets: [
                  {
                      name: '',
                      amount: 0,
                  }
              ],
              subtotal: 0,
              includableOrdinaryDividends: 0
          }
      }
  ]
}

// Cada escenario tiene sus propios assets, pero en este caso todos los escenarios deben compartir los mismos assets. Ok?
// Como se va a Eliminar un asset?

export const  CALCULATORS_CATALOG_SCHB = {
  id: 'scheduleBCalculator',
  sections: [
      {
          id: 'interest',
          scenariosData:[
            {
              scenarioId: 'labels',
              title : '',
              lines: [
                { assetId: 'bankOfAmerica0', value: 'Bank of america' },
                { assetId: 'bankOfAmerica1', value: 'Bank of america' },
                { assetId: 'bankOfAmerica2', value: 'Bank of america' },
                { assetId: 'subtotal', value: 'subtotal' },
                { assetId: 'includableInterest', value: 'Includable Interest net' },
              ],
            },
            {
              scenarioId: 'inital',
              title : '2020 Tax Return',
              lines: [
                { assetId: 'bankOfAmerica0', value: 0 },
                { assetId: 'bankOfAmerica1', value: 0 },
                { assetId: 'bankOfAmerica2', value: 0 },
                { assetId: 'subtotal', value: 0 },
                { assetId: 'includableInterest', value: 0 },
              ],
            },
            {
              scenarioId: 'scenario1',
              title : 'Scenario I',
              lines: [
                { assetId: 'bankOfAmerica0', value: 0 },
                { assetId: 'bankOfAmerica1', value: 1 },
                { assetId: 'bankOfAmerica2', value: 2 },
                { assetId: 'subtotal', value: 2 },
                { assetId: 'includableInterest', value: 2 },
              ],
            },
          ]

      },
      {
        id: 'ordinaryDividends',
        title : 'Scenario I',
        scenariosData:[
          {
            scenarioId: 'labels',
            title : '',
            lines: [
              { assetId: 'Apple0', value: 'Apple' },
              { assetId: 'Apple1', value: 'Apple' },
              { assetId: 'Apple2', value: 'Apple' },
              { assetId: 'subtotal', value: 'Subtotals' },
            ],
          },
          {
            scenarioId: 'inital',
            title : '2020 Tax Return',
            lines: [
              { assetId: 'Apple0', value: 523 },
              { assetId: 'Apple1', value: 523 },
              { assetId: 'Apple2', value: 523 },
              { assetId: 'subtotal', value: 523 },
            ],
          },
          {
            scenarioId: 'scenario1',
            title : 'Scenario I',
            lines: [
              { assetId: 'Apple0', value: 0 },
              { assetId: 'Apple1', value: 0 },
              { assetId: 'Apple2', value: 0 },
              { assetId: 'subtotal', value: 0 },
            ],
          },
        ]

    },
  ]
}
