import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { saveAs } from 'file-saver';
import { element } from 'protractor';

import { cleanNameForPDF, delay_ms, pushIntoArrayIfObjectIsUnique, readFromStoragedObject } from '../../../shared/helpers/utils';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { CONST_STATES } from '../../questionnaires/Questionnaire.constants';
import { CONST_FILING_STATUS } from '../calculators/Calculators.class';
import { PageData, TaxProjectionstReportTemplate } from '../pdf-report/PdfReport.class';
import { ScenariosListModalComponent } from '../scenarios-list-modal/scenarios-list-modal.component';
import { ArtificialScenario, Scenario, scenarioData, Section, SECTIONS_CATALOG } from './Scenario.class';
import { RESPONSE } from './scenarioTesting.class';

@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent implements OnInit, AfterViewInit, OnChanges  {

  @Output() onLoaded = new EventEmitter<any>();
  @Output() calculatorId = new EventEmitter<string>();
  @Output() onClosedGetScenarios = new EventEmitter<Scenario[]>();
  @Output() onDirty = new EventEmitter<boolean>();
  @Output() updatePrintableCollapsed = new EventEmitter<any>();
  @Output() onDataChange = new EventEmitter<any>();
  @Input() printable: boolean = false;
  @Input() flagToGetCollapsed: boolean;
  @Input() collapsedElements: Set<any>= new Set([]);
  @Input() listenToScenarioChanges: EventEmitter<any>;

  public CONSTANTS = {CONST_STATES};
  public CONSTANT_FE = {CONST_FILING_STATUS}
  public CONST_SECTIONS_CATALOG = SECTIONS_CATALOG;
  public backendResponse: any = [];
  public compareResponse: any[] = [];
  public yearsAvailable = [];
  public noRowsId = ['socialSecurityBenefitCola', 'wagesCola', 'taxableSocialSecurityPercentage', 'magiTiersText', 'text'];
  public scenarios = RESPONSE;
  public addScenarioView = false;
  public clientId = '';
  public nameNewScenario = '';
  public nextRoute = '';
  public activateRedirection: boolean = false;
  public hiddenScenarios: any = [];
  public scenarioCurrentTaxYear: any = {};
  public currentYear: any = '';
  public isLoading: Boolean = true;
  public isLoadingPDF: boolean = false;
  public isCalculationsDisabled: Boolean = true;
  public noChangeType: string[] = ['disabledFilers', 'dependentFilers', 'olderFilers', 'year', 'state', 'filingStatus']
  public saveAndPerformVisible: boolean = false;
  public conflictWith2020: Boolean = false;
  public buttonSaveAndCalculate: boolean = false;
  public goingBack: boolean = false;
  public isProduction: boolean = false;
  public closedSectionsCounter: number = 0;
  public typeToPDF: boolean = false;
  public hiddenComponentsSaved: any = [];
  public isLoadingBackendData: Boolean = true;

  constructor(
    public advisorService: AdvisorService,
    public dialog: MatDialog,
    public render: Renderer2,
    public router: Router,
    public settingsServive: ESettingsService,
    public snackBar: MatSnackBar,
  ) {
  }


  ngOnInit() {

    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    // Add listener to collapse the scenario
    this.render.listen('window', 'click', (event: any)=>{
      let cardScenarios = document.getElementById('cardsScenarios')
      let addScenario = document.getElementById('addScenario')
      if (!((cardScenarios && cardScenarios.contains(event.target)) || (addScenario && addScenario.contains(event.target)))){
        this.addScenarioView = false;
        if (this.backendResponse.hasOwnProperty('scenariosNames')){
          this.backendResponse.scenariosNames.forEach(scenario => {
            scenario.edit = false;
          });
        }
      }
    });

    this.loadData().then(resp => {

      this.saveAndPerformVisible = true;

      if(this.listenToScenarioChanges != undefined){
        this.listenToScenarioChanges.subscribe(value => {
          if(value === true){
            this.loadData();
          }
        })
      }

    })

  }

  async loadData(){

    this.isLoadingBackendData = true;
    //getting data
    await this.getDataFromBack();

    // get short data of scenarios
    if (!this.conflictWith2020) {
      await this.getDataFromBackShort();
    }
    //

    this.checkYearInScenarios();
    this.isLoadingBackendData = false;

  }


  /**
   * The function "goBack" sets a flag indicating that the user is going back, navigates to a specific
   * route using the Angular router, and then resets the flag.
   */
  goBack() {
    this.goingBack = true;
    this.router.navigate(['advisor', 'assets']);
    this.goingBack = false;
  }

  /**
   * The function toggles the visibility of a section, updates the counter for closed sections, saves
   * the changes to the backend, emits an event to notify data change, and emits an event to update the
   * printable collapsed state.
   * @param {any} idx - The parameter "idx" is used to specify the index of the section in the
   * "backendResponse.sections" array.
   */
  async setSectionVisible(idx: any) {
    const visible = this.backendResponse.sections[idx].visible;
    if (visible) {
      this.closedSectionsCounter += 1;
    } else {
      this.closedSectionsCounter -= 1;
    }


    this.backendResponse.sections[idx].visible = !visible;
    await this.advisorService.saveCollapsableElements(this.clientId, {
      components: [this.backendResponse.sections[idx].id],
    });
    this.onDataChange.emit(true);
    this.updatePrintableCollapsed.emit({ idx, value: !this.flagToGetCollapsed});
  }

  /**
   * This function allows to change the visibility of the section
   * @param hiddenElement is the id of element
   */
  setVisiblePropertyFromSaved(hiddenElement) {
    let index = 0;
    for (let section of this.backendResponse.sections) {
      if (section.id === hiddenElement) {
        this.backendResponse.sections[index].visible = false;
      }
      index++;
    }
  }

  /**
   * The function `getCollapsableElements` retrieves collapsible elements from a service, updates the
   * visibility of these elements based on saved data, and saves any changes made.
   * @param [update=false] - The `update` parameter is a boolean value that determines whether to
   * update the collapsible elements or not. If `update` is `true`, the collapsible elements will be
   * updated. If `update` is `false`, the collapsible elements will not be updated.
   */
  async getCollapsableElements(update = false) {
    try {
      const { projectionsScenarios: { hiddenComponents } }: any = await this.advisorService.getCollapsableElements(this.clientId);

        this.closedSectionsCounter = hiddenComponents.length - 3 >= 9 ? 9 : hiddenComponents.length - 3;
        this.hiddenComponentsSaved = hiddenComponents;
        const deleteElements = [];
        // this sections must always be opene (PDF sections)
        const alwaysOpenElements = ['summaryOverview', 'federalOverview', 'stateOverview'];
        const alwaysOpenElementsToOpen = [];
        hiddenComponents.forEach((hiddenElement) => {
          if (typeof hiddenElement === 'number') {

            deleteElements.push(hiddenElement);
          } else if (alwaysOpenElements.includes(hiddenElement)) {
            alwaysOpenElementsToOpen.push(hiddenElement);
          }
        });
        if (deleteElements.length === 0) {

          hiddenComponents.forEach((hiddenElement) => {
            this.setVisiblePropertyFromSaved(hiddenElement);
          });
          if (alwaysOpenElementsToOpen.length > 0) {
            await this.advisorService.saveCollapsableElements(this.clientId, {
              components: alwaysOpenElementsToOpen,
            });
          }
        } else {

          await this.advisorService.saveCollapsableElements(this.clientId, {
            components: deleteElements,
          });
          const newCollapsedElements = [];
          deleteElements.forEach((element) => {
            if (element > 2 && element + 3 < 12) {
              newCollapsedElements.push(this.backendResponse.sections[element+3].id);
            } else {
              newCollapsedElements.push(this.backendResponse.sections[element].id);
            }
          });

          await this.advisorService.saveCollapsableElements(this.clientId, {
            components: newCollapsedElements,
          });
          this.getCollapsableElements();
        }
    } catch(e) {
      console.log('ERROR: ', e);
    }
  }

  ngAfterViewInit() {
    /*
    setTimeout(() => {
      this.saveAndPerformVisible = true;
      this.checkYearInScenarios();

      if(this.listenToScenarioChanges != undefined){
        this.listenToScenarioChanges.subscribe(value => {
          if(value === true){

            this.typeToPDF = true;
            this.loadData();
          }
        })

        this.typeToPDF = true;
        this.ngOnInit();
      }else{

      }
    }, 2000)
    */

    //console.log("ngAfterViewInit");

    //this.checkYearInScenarios();

  }

  /**
   * Make a request to obtain complete data of scenarios,
   * this response is sent to other functions to create a model to can show in view,
   * and it's assigned to variable backendResponse
   */
  async getDataFromBack() {
    this.buttonSaveAndCalculate = true;
    // getting data from backend
    await this.advisorService.getProjectionsScenarios(this.clientId, 'full').then(response => {

      this.compareResponse = JSON.parse(JSON.stringify(response));

      //Calculate years available. Current base + 5
      let baseYear: number = Number(response[0].sections[0].data.year);
      let nYears = 4;
      //this.yearsAvailable = ['2022', '2023', '2024', '2025'];
      this.yearsAvailable = Array.apply(null, {length: nYears}).map((current, idx) => String(baseYear + idx + 1));
      if(!this.yearsAvailable.includes(baseYear)){
        this.yearsAvailable.unshift(String(baseYear));
      }

      /**
       * combine scenarios function returns an object of data with all scenarios, include the first scenario (labels scenario) and scenarios with data
       */
      this.backendResponse = this.combineScenarios([this.buildLabelsScenario(), ...(response.map(scenario => {let response = this.rawScenarioToScenario(scenario); return response}))]);


      if (this.backendResponse.scenariosNames.length > 5 && this.printable) {
        this.backendResponse.scenariosNames.splice(5);

      }

      /*Set all sections as opened*/
      this.backendResponse.sections.forEach(section => section.visible = true);
      /*save scenario base to use in view*/
      this.scenarioCurrentTaxYear = this.backendResponse.scenariosNames[1];
      /*Remove headers from the first two columns*/
      this.backendResponse.scenariosNames = this.backendResponse.scenariosNames.slice(2);

      if (this.printable) {
        this.backendResponse.sections.forEach((section, index) => {
          section.sectionsData.splice(5);
        });

      }
      this.obtainRevertChanges();



      this.currentYear = this.printable ?
      this.backendResponse.sections[3].sectionsData[1][0].value
      :
      this.backendResponse.sections[3].sectionsData[1][0].value;
      this.backendResponse.sections[1].sectionsData.forEach((data, index) => {
        if (data.id === 'socialSecurityBenefit_cola' || data.id === 'wages_cola'){
          data.value = data.value * 100;
          data.value = Number.parseInt(data.value, 10);
        }
      })
      this.onLoaded.emit(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);

      this.buttonSaveAndCalculate = false;
      this.getCollapsableElements();
      this.checkYearInScenarios();

    }).catch((e) => {
      console.log('ERROR EN EL LARGO: ', e);
      if (e.status === 409) {
        this.conflictWith2020 = true;
        this.isLoading = false;
      }
    });
  }

  /**
   *This function search in all backend response if some value is contained in overwrite list array
   *if some row is contained in overwrite list, the function enable the button to revert changes in this row if it's possible
   */
  obtainRevertChanges(){

    this.backendResponse.sections.forEach(section => {
      section.sectionsData.forEach((scenario, index) => {

        if (index > 1){

          let scenarioOverwriteList = this.backendResponse.scenariosNames[index - 2 ].overwriteList;
          let found = scenarioOverwriteList.find(elmnt => elmnt.key === 'capitalGainOrLoss')

          scenario.forEach(row => {
            if (row.calculatorId !== null && row.calculatorId !== undefined){
              if (this.backendResponse.scenariosNames[index - 2] && this.backendResponse.scenariosNames[index - 2].overwriteList && this.backendResponse.scenariosNames[index - 2].overwriteList.length > 0){
                this.backendResponse.scenariosNames[index - 2].overwriteList.forEach(item => {
                  if(item.section === section.id && item.key === row.id){
                    row.revertNumber = true;
                  }
                })
              }
            }
          })
        }

      })
    })
  }

  /**
   * Make a request to obtain short data of scenarios,
   * this response is assigned to variable hiddenScenarios,
   * hiddenScenarios is used to show a list of scenarios to show again
   */
  async getDataFromBackShort(){
    this.hiddenScenarios = [];
    await this.advisorService.getProjectionsScenarios(this.clientId, 'short').then(shortResponse => {
      shortResponse.map(scenario => {
        if (scenario.position === null) {
          this.hiddenScenarios.push(scenario);
        }
      });
    }).catch((e) => {
      console.log('ERROR EN EL CORTO: ', e);
      if (e.status === 409) {
        this.conflictWith2020 = true;
        this.isLoading = false;
      }
    });
  }

  /**
   * Transform structure of a scenario "natural (model response of backend)" in a structure of scenario artificial (model used in html view)
   * sections catalog model (Scenario.class.ts) is used to can create the new structure
   * @param rawScenario is provided by backend
   * @param setAsStaticText is the type of input
   */

  rawScenarioToScenario(rawScenario: any, setAsStaticText: boolean = false): Scenario {
    const scenarioSections: Section[] = rawScenario.sections;
    const response: Scenario = {
      _id: rawScenario._id,
      name: rawScenario.name,
      description: rawScenario.description,
      dateCreated: rawScenario.dateCreated,
      dateUpdated: rawScenario.dateUpdated,
      position: rawScenario.position,
      sections: [],
      overwriteList: rawScenario.overwriteList
    };
    rawScenario.sections.map(section => {
      section.data['name'] = rawScenario.name;
    })
    SECTIONS_CATALOG.map((catalogSection) => {
      const responseSection = scenarioSections.find((scenarioSection) => {
        return scenarioSection.id === catalogSection.id;
      });
      if (responseSection !== undefined) {
        const auxSection = {id: catalogSection.id, data: [], title: catalogSection.title};
        catalogSection.data.map(catalogSectionRow => {
          let setType = () => {
            if (catalogSectionRow.type === 'name') return 'name';
            if (catalogSectionRow.id === 'capitalGainOrLoss' || catalogSectionRow.id === 'nontaxableInterest') return 'staticText';
            if (catalogSectionRow.id === 'wages_cola' || catalogSectionRow.id === 'socialSecurityBenefit_cola') return 'other';
            if (catalogSectionRow.id === 'wages' || catalogSectionRow.id === 'socialSecurityBenefit') return 'pdfCola';
            if (catalogSectionRow.type === 'magiTiersText') return 'magiTiersText';
            if (catalogSectionRow.type === 'stepperNumber' || catalogSectionRow.type === 'status' || catalogSectionRow.type === 'text') return 'text';
            if (catalogSectionRow.type === 'money' || catalogSectionRow.type === 'staticText') return 'staticText';
            if (catalogSectionRow.type === 'wagesCola' || catalogSectionRow.type === 'socialSecurityBenefitCola' || catalogSectionRow.type === 'taxableSocialSecurityPercentage') return 'other';
            if (this.noChangeType.includes(catalogSectionRow.type)){
              return 'text';
            }else{
              console.log('Type not found *****', catalogSectionRow.type);
            }
          }
          auxSection.data.push({
            id: catalogSectionRow.id,
            title: catalogSectionRow.title,
            type: this.printable ? setType() : catalogSectionRow.type === 'name' ? 'name' : ((this.noChangeType.includes(catalogSectionRow.id)) && rawScenario.position === 0) ? 'text' : (setAsStaticText || rawScenario.position === 0) && !this.noRowsId.includes(catalogSectionRow.type) ? 'staticText' : catalogSectionRow.type,
            isEditable: catalogSectionRow.isEditable,
            calculatorId: catalogSectionRow.calculatorId,
            alwaysVisible: Boolean(catalogSectionRow.alwaysVisible) ? true : false,
            value: responseSection.data[catalogSectionRow.id] !== undefined ? responseSection.data[catalogSectionRow.id] : '',
            defaultValue: catalogSectionRow.defaultValue
          });
          if (responseSection.data[catalogSectionRow.id] === undefined) {
            console.log(`Data not found in the response in section: ${catalogSection.id}, row: ${catalogSectionRow.id}`);
          }
        });
        response.sections.push(auxSection);
      } else {
        console.log('Section not found in response: ', catalogSection.id);
      }
    });
    return response;
  }

  /**
   * create a scenario artificial to show text labels in the
   * beginning of the row. All rows has input type text to can show labels in the beginning
   * @return response that is a scenario artificial created
   */
  buildLabelsScenario(): Scenario {
    let response: Scenario = {
      _id: 'Label scenario',
      name: 'scenario',
      description: 'scenario',
      dateCreated: 'scenario',
      dateUpdated: 'scenario',
      position: 0,
      sections: [],
      overwriteList:[]
    };

    SECTIONS_CATALOG.map(section => {
      const auxSection = {id: section.id, data: [], title: section.title};
      section.data.map((sectionData) => {
        // if (this.noRowsId.includes(sectionData.type)) return
        auxSection.data.push({
          id: sectionData.id,
          title: sectionData.title,
          type: sectionData && !this.noRowsId.includes(sectionData.type) ? 'nameRow' : sectionData.type,
          isEditable: sectionData.isEditable,
          calculatorId: sectionData.calculatorId,
          alwaysVisible: Boolean(sectionData.alwaysVisible) ? true : false,
          value: sectionData.title,
          defaultValue: sectionData.defaultValue
        });
      });
      response.sections.push(auxSection);
    });
    return response;
  }

  /**
   * The function combines multiple scenarios that contains in each section an array of "data" where each element of the array is
   * the data corresponding to that section
   * @param {Scenario[]} arrayScenarios - An array of Scenario objects.
   *@return response that is an array formed by all scenarios (labels scenario and artificial scenarios)
   */
  combineScenarios(arrayScenarios: Scenario[]): any {
    let response = this.buildLabelsScenario();

    response.scenariosNames = [];
    arrayScenarios.forEach(scenario => response.scenariosNames.push({
      name: scenario.name,
      id: scenario._id,
      edit: false,
      position: scenario.position,
      overwriteList: (scenario.overwriteList === undefined || scenario.overwriteList === null) ? [] : scenario.overwriteList
    }));
    response.scenariosNames;
    response.sections.map((section, index) => {
      const sectionsData = [];
      arrayScenarios.map((scenario) => {
        let scenarioSection = scenario.sections.find(_section => _section.id === section.id);
        if(scenarioSection){
          sectionsData.push(scenarioSection.data);
          response.sections[index]['sectionsData'] = sectionsData;
        }else{
          console.log("Value unavailable: " + section.id);
        }

      });
    });

    return response;
  }


  /**
   * Transform an Artificial scenario to an array of raw scenarios to can save changes
   * This to be processed by the backend
   * @param artificialScenario by default is the current response.
   * @returns Array of raw scenarios.
   */
  artificialScenarioToScenario(artificialScenario: ArtificialScenario = this.backendResponse): Scenario[] {

    const rawScenarios = [];

    // Use the names as the list of scenarios to be transformed.
if (artificialScenario.scenariosNames) {
  artificialScenario.scenariosNames.map((scenarioShortData, scenarioShortDataIndex) => {

    // Set the base for a raw scenario.
    const rawScenario: Scenario = {
      _id: scenarioShortData.id,
      name: scenarioShortData.name,
      description: '', //No incluir.
      position: scenarioShortDataIndex + 1,
      sections: [],
      overwriteList: scenarioShortData.overwriteList
    };

    // Iterate over the sections from the artificial scenario to build the raw scenario sections
    artificialScenario.sections.map(artificialScenarioSection => {

      const index = scenarioShortDataIndex + 2; // In the list of names we delete the labels scenario and base scenario, thats why is +2

      if (artificialScenarioSection.sectionsData[index] !== undefined) { // Exist the sectionsData that match the index
        let auxData = {};
        artificialScenarioSection.sectionsData[index].map(section => {

          if (section.id === 'wages_cola' || section.id === 'socialSecurityBenefit_cola'){
            section.value = Number(section.value) / 100;
          }
          auxData[section.id] = section.value;
        });
        // Push the section
        rawScenario.sections.push({
          id: artificialScenarioSection.id,
          data: auxData
        });
      } else {
        // console.warn(`artificialScenarioToScenario. There is no such index inside the sectionsData. Section ${artificialScenarioSection.id}, index: ${index}`);
      }
    });
    // Add the raw scenario
    rawScenarios.push(rawScenario);
  });
}

    return rawScenarios;

  }

  /**
   * Retrieves one scenario from the current response given an index;
   * @param idx is the index of scenario selected
   * @return scenario data
   */
  getScenarioData(idx: number, response = this.backendResponse): Scenario {
    const allScenarios = this.artificialScenarioToScenario(response);
    const scenario = allScenarios[idx];
    return scenario;
  }

  /**
   * sum one to the value
   * @param idx id of section data
   * @param i number of column of data
   * @param j number of row
   */
  sumQuantity(idx: number, i: number, j: number) {
    this.onDirty.emit(true);
    this.backendResponse.sections[idx].sectionsData[i][j].value += 1;
  }

  /**
   * rest one to the value
   * @param idx id of section data
   * @param i number of column of data
   * @param j number of row
   */
  restQuantity(idx: number, i: number, j: number) {
    this.onDirty.emit(true);
    const data = this.backendResponse.sections[idx].sectionsData[i][j].value;
    if (data > 0) {
      this.backendResponse.sections[idx].sectionsData[i][j].value -= 1;
    }
  }

  /**
   * do scroll horizontal in view
   * @param id is used by the conditional to do scroll, it's provided by html element
   */
  scrollProjections(id) {
    const element = document.getElementById('projections');
    if (id === 'addProjection') {
      element.scrollLeft += 250;
    } else {
      element.scrollLeft -= 250;
    }
  }


  /**
   * Allows to show actions in the scenario selected
   * @param i is teh index of scenario
   */
  editDataScenario(i: number) {
    this.backendResponse.scenariosNames[i].edit = !this.backendResponse.scenariosNames[i].edit;
  }



  /**
   * Update information of scenario
   * @param id is the id of scenario
   * @param index
   */
  saveScenario(id: string, index: number, bodyModified?: any) {

    let scenario = this.currentScenariosToSaveFormat()[index];
    let body = {}
    if (bodyModified){
      body = {
        bodyModified
      };
    }else{
      body = {
        scenario
      };
    }

    this.advisorService.updateScenario(this.clientId, body).then((response: any) => {

      this.backendResponse.scenariosNames.forEach((scenario, index) => {
        scenario.saved = false;
        if (scenario.id === response['_id']){
          this.backendResponse.sections.map((section, idx) => {
            let sectionResponse = response.sections.find(section_ => section_.id === section.id);
            section.sectionsData[index + 2].map(row => {
              row.value = sectionResponse.data[row.id];
              if (row.id === 'name'){
                row.value = response.name;
              }
            });
          });
          scenario.saved = true;
        }
      })
    });

    setTimeout(() => {
      this.backendResponse.scenariosNames[index].saved = false
      this.onDataChange.emit(true);
    }, 2000)
  }

  /**
   * Save all scenarios before leaving
   * @param id
   * @param index
   */
  async saveAllScenarios() {
    try {
      let promisesToSolve: any = [];
      this.backendResponse.scenariosNames.forEach(async (innerScenario, index) => {
        let scenario = this.currentScenariosToSaveFormat()[index];
        const body = {
          scenario
        };

        promisesToSolve.push(this.advisorService.updateScenario(this.clientId, body));
      });
      const ans = await Promise.all(promisesToSolve);

      this.onDataChange.emit(true);
    } catch(e) {
      console.log(e);
    }
  }


  /**
   * hide scenario data to not show in the view
   * @param id is the scenario id
   * @param index is provided by backend
   */
  hideScenario(id: string, index: number) {
    let positions = {};
    this.backendResponse.scenariosNames.map(scenario => {
      if (scenario.id !== id) {
        positions[scenario.id] = (scenario.position > index) ? scenario.position - 1 : scenario.position;
      }
    });
    this.advisorService.updateScenarioPositions(this.clientId, {positions}).then(response => {
      this.removeScenarioFromResponse(id);
      this.getDataFromBackShort();
      this.checkYearInScenarios();
      this.onDataChange.emit(true);
    });
  }


  /**
   * Allows show again a scenario that before was hide
   * Update positions to can show
   * @param id is the scenario id
   */
  async showHideScenario(id: string) {
    let positions = {};
    this.backendResponse.scenariosNames.map(scenario => {
      positions[scenario.id] = scenario.position;
    });
    positions[id] = this.countOpenedScenarios() + 1;
    await this.advisorService.updateScenarioPositions(this.clientId, {positions})
    .then(response => this.getDataFromBack())
    .then(response_ => this.getDataFromBackShort());

  }

  /**
   * The function `duplicateScenario` creates a new scenario based in a scenario selected, create a copy.
   * @param {string} id - The `id` parameter is a string that represents the reference ID of the
   * scenario that you want to duplicate.
   */
  async duplicateScenario(id: string) {

    let body = {
      reference: id,
      scenario: {
        description: null,
        position: this.backendResponse.scenariosNames.length + 1
      }
    };
    // create new scenario using existing scenario (scenario base)
    await this.advisorService.createProjectionScenario(this.clientId, 'reference', body).then(resp => {
      this.compareResponse.push(resp);
    // response is added to current array of scenarios
      this.addScenarioToCurrentResponse(this.rawScenarioToScenario(resp));
    });
  }

  /**
   * Fill current scenario with data of current tax year
   * @param id is the current scenario id
   * @param index is provided by html
   */
  fillWithCurrentTax(id: string, index) {

    this.fillWithCurrentTaxYear(index + 2);
    this.checkYearInScenarios();
    this.saveScenario(id, index);
  }


  /**
   * Current scenario data is set to default value
   * @param id is the scenario id
   * @param index is provided by html to know position in array
   */
  clearScenarioData(id: string, index: number) {

    this.setDataToDefaultValue(index + 2);
    this.saveScenario(id, index);
  }

  /**
   * This function change the view to can load or create a new scenario,
   * also do scroll to show panel to add scenario correctly
   */
  addScenario() {
    this.addScenarioView = !this.addScenarioView;
    if (this.addScenarioView) {
      setTimeout(() => {
        this.scrollProjections('addProjection');
      }, 300);
    }
  }

  /**
   * Show modal that contains short info of scenarios
   */
  goToScenariosTable() {
    this.openScenariosListDialog();
  }

  /**
   * Set data of scenario to default value
   * @param idx is the position in array, is provided by html
   */
  setDataToDefaultValue(idx: number) {
    this.backendResponse.sections.forEach(section => {
      if (section.id === 'magiTiers') { return }
      section.sectionsData[idx].forEach(data => {
        // The year and fillings status cant be deleted, preserve
        if(data.id == 'year'  || data.id == 'filingStatus' || data.id == 'state' || data.id == 'name') { return }
        data.value = data.defaultValue;
      });
    });
  }

  /**
   * Fill current scenario with data of current tax year
   * @param idx is the position of scenario in the array
   */
  fillWithCurrentTaxYear(idx: number) {

    for (let i = 0; i < this.backendResponse.sections.length; i++) {
      for (let j = 0; j < this.backendResponse.sections[i].sectionsData[idx].length; j++) {
        this.backendResponse.sections[i].sectionsData[idx][j].value = this.backendResponse.sections[i].sectionsData[1][j].value;
      }
    }

  }

  /**
   * Load or create scenario, its depend on the id that is sent to this function
   * The function `loadScenario` takes an `idScenario` as input and performs different actions based on
   * its value, such as creating a new scenario, duplicating the current tax year scenario, or
   * showing/hiding a scenario.
   * @param idScenario is the id, and is provided by html
   */
  async loadScenario(idScenario: string) {
    this.isLoading = true;
    idScenario === 'blank' ? await this.createScenario(idScenario) :
     (idScenario === 'currentTaxYear') ? await this.duplicateScenario(this.scenarioCurrentTaxYear.id) :
     await this.showHideScenario(idScenario);
     this.isLoading = false;

    this.addScenarioView = false;
    this.scrollProjections('addProjection');
    this.checkYearInScenarios();
    this.onDataChange.emit(true);


  }

  /**
   * When a scenario is created or loaded, this is added to array to can show its information
   * @param scenarioToBeAdded
   */
  addScenarioToCurrentResponse(scenarioToBeAdded: Scenario) {
    this.backendResponse.sections.map(section => {
      const sectionToBeAdded = scenarioToBeAdded.sections.find(section_ => section_.id === section.id);
      if (sectionToBeAdded){
        section.sectionsData.push(sectionToBeAdded.data);
      }
    });

    //Prepare the scenario to be added.
    let newScenario = {
      name: scenarioToBeAdded.name,
      id: scenarioToBeAdded._id,
      edit: false,
      position: scenarioToBeAdded.position,
      overwriteList: scenarioToBeAdded.overwriteList
    }

    //Upadte the internal scenaros data and the comparative object.
    this.backendResponse.scenariosNames.push(newScenario);

    this.obtainRevertChanges();
  }

  /**
   * Create a new scenario, and then the response is added to array global to show their information
   * @param name is the name of the new scenario
   * @param description is the description of the new scenario
   */
  async createScenario(name: string, description?: string) {

    const auxScenario = {
      'scenario': {
        'name': name,
        'description': description ? description : null,
        'position': this.countOpenedScenarios() + 1
      }
    };

    await this.advisorService.createProjectionScenario(this.clientId, 'blank', auxScenario).then(newRawScenario => {
      this.compareResponse.push(newRawScenario);
      this.addScenarioToCurrentResponse(this.rawScenarioToScenario(newRawScenario));
    });
    this.addScenario();
  }

  /**
   * Count the number of scenarios that can be displayed in the view
   * @return a number
   */
  countOpenedScenarios(): number {
    return this.backendResponse.scenariosNames.length;
  }

  /**
   * Open modal to can see all scenarios (hidden and shown)
   */
  openScenariosListDialog(): void {
    const dialogRef = this.dialog.open(ScenariosListModalComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-with-form',
      width: '80%',
      height: '70%',
    });

    dialogRef.afterClosed().subscribe(data => {
      this.getDataFromBack();
      this.getDataFromBackShort();
      this.onDataChange.emit(true);
    });
  }

  /**
   * Serves to can move of position a scenario, it can be shifted to left or right
   * @param scenarioId is the scenario id
   * @param idx is the number of position in the array
   * @param direction is the direction the stage moves
   */
  moveScenario(scenarioId: string, idx: number, direction: ('l' | 'r')) {
    if ((direction === 'l' && idx > 0) || (direction === 'r' && idx < this.backendResponse.scenariosNames.length - 1)) {
      this.moveArrayPosition(this.backendResponse.scenariosNames, idx, direction);
      this.backendResponse.scenariosNames.map((scenario, index) => {
        scenario.position = index + 1;
      });

      this.backendResponse.sections.map(section => {
        this.moveArrayPosition(section.sectionsData, idx + 2, direction);
      });
      //Save positions
      let positions = {};
      this.backendResponse.scenariosNames.map(scenario => {
        positions[scenario.id] = scenario.position;
      });
      this.advisorService.updateScenarioPositions(this.clientId, {positions}).then(response => {
        this.onDataChange.emit(true);

      });
    }

  }

  /**
   *
   * @param array is the array in which the positions are be changed
   * @param idx is the current index of the scenario
   * @param direction is where the scenario is going to move
   */
  moveArrayPosition(array: any[], idx: number, direction: ('l' | 'r')) {
    if (direction === 'l' && idx > 0) {
      let tempArray = array.slice(idx - 1, idx + 1);
      array[idx - 1] = tempArray[1];
      array[idx] = tempArray[0];
      this.scrollProjections('left');
    } else if (direction === 'r' && idx < array.length - 1) {
      let tempArray = array.slice(idx, idx + 2);
      array[idx] = tempArray[1];
      array[idx + 1] = tempArray[0];
      this.scrollProjections('addProjection');
    } else {

    }
  }

  /**
   * The function removes a scenario from the backend response by finding its index and then removing
   * it from the scenariosNames array and the sectionsData array.
   * When a scenario is deleted, also is removed from the array
   * @param idScenarioDeleted is the id of the scenario deleted
   */
  removeScenarioFromResponse(idScenarioDeleted) {
    let index = undefined;
    this.backendResponse.scenariosNames.forEach((scenario, i) => {
      if (scenario.id === idScenarioDeleted) {
        index = i;
      }
    });
    if (index !== undefined){
      this.backendResponse.scenariosNames.splice(index, 1);
      this.backendResponse.sections.forEach(section => {
        section.sectionsData.splice(index + 2, 1);
      });
    }
  }

  /**
   * when button is clicked, this function navigate to calculator corresponding
   * @param calculatorId is the id of the calculator to wants show
   */
  async goToCalculator(calculatorId: string){
    await this.saveAllScenarios();
    this.calculatorId.emit(calculatorId);
  }

  setDirty(e: any) {
    this.onDirty.emit(true);
  }

  /**
   * The function compares two scenarios and returns an array of differences between
   * them.
   * @param {Scenario} scenarioA - The parameter `scenarioA` is an object representing a scenario. It
   * contains a `sections` property, which is an array of sections. Each section has an `id` property
   * and a `data` property. The `data` property is an object that contains key-value pairs representing
   * the lines in
   * @param {Scenario} scenarioB - The parameter `scenarioB` is a Scenario object that represents the
   * second scenario to compare.
   * @returns returns an array of objects representing the differences
   * between two scenarios. Each object in the array contains the section ID, line ID, previous value,
   * and new value for a difference found between the two scenarios.
   */
  scenariosDiff(scenarioA: Scenario, scenarioB: Scenario): any[]{

    let diferences: any[] = [];
    scenarioA.sections.map((sectionA, sectionIndex) =>{

      let sectionB = scenarioB.sections.find(section => section.id == sectionA.id); //scenarioB.sections[sectionIndex];
      if(sectionB == undefined) {
        console.warn(`scenariosDiff. The section: ${sectionA.id}, in scenarioA isn't available in scenarioB`);
        return;
      }

      let sectionInCatalog = SECTIONS_CATALOG.find(section => section.id == sectionB.id);
      if(sectionB == undefined) {
        console.warn(`scenariosDiff. The section: ${sectionB.id}, isn't available on the catalog`);
        return;
      }

      sectionInCatalog.data.map(catalogLine => {

        let valA = sectionA.data[catalogLine.id];
        let valB = sectionB.data[catalogLine.id];

        if(valA == undefined || valB == undefined){
          console.warn(`scenariosDiff. The scenarios don't contain the line: ${catalogLine.id}`);
          return;
        }

        if(valA != valB && catalogLine.isEditable) {
          diferences.push({section: sectionInCatalog.id, key: catalogLine.id, prevVal: valA,  newVal: valB});
          this.onDirty.emit(true);

        }

      })
    })
    return diferences;
  }

  /**
   * Takes the currently opened scenarios, compares them
   * with a reference set of scenarios, and adds any differences to each scenario's overwriteList.
   * @returns an array of Scenario objects.
   */
  currentScenariosToSaveFormat(): Scenario[]{
    //Get all currently opened scenairos and transform to scenario
    let allScenarios = this.artificialScenarioToScenario(this.backendResponse);
    let refComparaScenarios = this.compareResponse;

    // Find and add differences to each scenario
    allScenarios.map((scenario) => {
      let refScenario = refComparaScenarios.find(refScenario => refScenario._id == scenario._id);

      if(refScenario === undefined){

        return;
      }

      let overwriteList = this.scenariosDiff(refScenario, scenario); //puede ser vacio

      overwriteList.map(line => {
          let catalogSection = SECTIONS_CATALOG.find(section => section.id == line.section);
          let catalogSectionDataLine = catalogSection.data.find(sectionLine => sectionLine.id == line.key);
          if(catalogSectionDataLine.isEditable){
            let pushed = pushIntoArrayIfObjectIsUnique(scenario.overwriteList, line, 'key', true);
          }else{
            console.warn('Cant add to the overwriteList a non editable section: ', line);
          }
      })
    })

    return allScenarios;
  }


  /**
   * Saves and calculates scenarios, with an optional parameter to delete a specific row from the overwrite list.
   * @param deleteFromOverwrite parameter is an optional object
   * */
  saveAndCalculate(deleteFromOverwrite?: ({scenarioData: any, sectionId: string, rowId: string})){

    this.isCalculationsDisabled = true;

    let scenariosToSave = this.currentScenariosToSaveFormat();
    if (deleteFromOverwrite !== undefined){
      let scenarioEdited = scenariosToSave.find(scenario => scenario._id === deleteFromOverwrite.scenarioData.id);
      scenarioEdited.overwriteList.forEach((item: any, index) => {
          if (item.section === deleteFromOverwrite.sectionId && item.key === deleteFromOverwrite.rowId){
            deleteFromOverwrite.scenarioData.overwriteList.splice(index, 1);
          }
      })
    }

    this.advisorService.scenariosSaveAndCalculate(this.clientId, scenariosToSave).then(async response => {
      await this.getDataFromBack();
      this.onDirty.emit(false);
      this.onDataChange.emit(true); //isCalculationsDisabled will be enabled again here.
    }).catch(error => {
      console.warn("Couldn't save and calculate scenarios: ", error);
    })

  }

  /**
   * This function can closes or opens all sections based on the provided status, updates the visibility of each section accordingly, saves the
   * changes to the backend, and emits a data change event.
   * @param status is a string that indicates whether to close or open all sections. It can have two possible values: "close" or "open".
   */
  async closeOpenAllSections(status: string) {
    const sectionsToSend = [];
    if (status === 'close'){
      this.backendResponse.sections.forEach((section, idx) => {

        if (section.visible) {
          sectionsToSend.push(section.id);
        }
        section.visible = false
      });
      this.closedSectionsCounter = 9;
    }
    if (status === 'open'){
      this.backendResponse.sections.forEach((section, idx) => {

        if (!section.visible) {
          sectionsToSend.push(section.id);
        }
        section.visible = true
      });
      this.closedSectionsCounter = 0;
    }


    await this.advisorService.saveCollapsableElements(this.clientId, {
      components: sectionsToSend,
    });
    this.onDataChange.emit(true);
  }

  /**
   * Checks the status of sections in a backend response and returns a boolean value
   * indicating whether there are both open and closed sections, only open sections, only closed
   * sections, or no sections
   * @returns a boolean
   */
  checkSectionsStatus(): boolean {
    let open = 0;
    let close = 0;
    const auxArr = ['summaryOverview', 'federalOverview', 'stateOverview'];
    this.backendResponse.sections.forEach(section => {
      //
      section.visible && !auxArr.includes(section.id) ? open = 1 : close = 1;
    })
    return ( open > 0 && close > 0) ? false : (open > 0 && close === 0) ? false : (open === 0 && close > 0) ? true : false;
  }

  /**
   * Before the scenarios are destroyed, pass the scenarios data to the parent component
   */
  ngOnDestroy(){
     this.onClosedGetScenarios.emit(this.currentScenariosToSaveFormat());
  }

  /**
   * The function calculates the value of a line in a scenario based on a base value and a multiplier.
   * @param  $event -  is an event object that is passed to the function when it is triggered. It
   * contains information about the event that occurred, such as the target element that triggered the
   * event and the value of that element.
   * @param scenarioIdx - The `scenarioIdx` parameter is used to specify the index of the scenario in
   * the `sectionsData` array. It is used to access the correct scenario data for calculations.
   * @param lineId - The `lineId` parameter is a unique identifier for a specific line in the
   * `sectionsData` array. It is used to locate the line that needs to be updated with the calculated
   * value.
   */
  calculateCola($event, scenarioIdx, lineId){

    let lineToUpdate = this.backendResponse.sections.find(section => section.id === "grossIncome").sectionsData[scenarioIdx].find(line => line.id == lineId);
    let base = this.backendResponse.sections.find(section => section.id === "grossIncome").sectionsData[1].find(line => line.id == lineId.replace('_cola','')).value;
    let multiplier = Number($event.srcElement.value);
    multiplier = isNaN(multiplier) ? 0 : (multiplier / 100) + 1;

    lineToUpdate.value = base * multiplier;
  }

  /**
   * this function get year of scenarios and verify that values are included in yearsAvailable array
   * @returns a boolean value indicating if button Perform and save is enable or disable
   */
  checkYearInScenarios(): boolean {
    let disable = true;
    if (this.backendResponse !== undefined && this.backendResponse.sections !== undefined){
      let section = this.backendResponse.sections.find(section => section.id === 'summaryInformation');
      console.log('years availables', this.yearsAvailable, 'section*****', section);

      let counter = 0;

      for (const scenario of section.sectionsData) {
        if (scenario !== undefined && section.sectionsData.length > 2){
          //obtain year value
          let yearValue = scenario.find(line => line.id === 'year').value;
          // if year value is different of label "year", then parse the value to int and verify if year exists in yearsAvailable array
          if (yearValue !== 'Year') {
            const auxYear = parseInt(yearValue, 10);
            if (Number.isNaN(auxYear) || !auxYear || (counter > 1 && !this.yearsAvailable.includes(yearValue))) {
              //if year is not included disable the button
              disable = true;
              break;
            } else {
              //if year is included enable the button
              disable = disable && false;
            }
          }
        }
        counter++;
      }
    }
    if (disable) {
      this.isCalculationsDisabled = true;
    } else {
      this.isCalculationsDisabled = false;
    }
    return ( disable ) ? true : false
  }


  checkYears(event: any) {
    //
    this.onDirty.emit(true);
    this.checkYearInScenarios();
  }

  /**
   * The function changes the value of a specific line in a scenario's sections data to 0.
   * @param $event - The  parameter is typically used in event handling functions to represent
   * the event that triggered the function. It can contain information about the event, such as the
   * target element or the event type. In this case, it is not being used in the function, so it can be
   * ignored.
   * @param scenarioIdx - The scenarioIdx parameter is the index of the scenario in the sectionsData
   * array. It is used to locate the specific scenario within the sectionsData array.
   * @param lineId - The lineId parameter is the identifier for a specific line in the backend
   * response.
   */
  changeValueCola($event, scenarioIdx, lineId){
   this.backendResponse.sections.find(section => section.id === "grossIncome").sectionsData[scenarioIdx].find(line => line.id == lineId+'_cola').value = 0;
  }

  /**
   * The function disables the revertNumber property of a row object if it matches the sectionId and key provided.
   * @param {any} scenarioData - The `scenarioData` parameter is an object that contains data related
   * to the scenario. It likely includes an `overwriteList` property, which is an array of objects
   * representing the data to be overwritten.
   * @param {string} sectionId - The sectionId parameter is a string that represents the ID of a section in the scenarioData object.
   * @param {any} row - The `row` parameter is an object that represents a row in a table or a list. It contains various properties, including an `id` property that uniquely identifies the row.
   */
  disableRevertNumber(scenarioData: any, sectionId: string, row: any) {
    scenarioData.overwriteList.forEach((item: any) => {
      if (item.section === sectionId && item.key ===row.id){
        row.revertNumber = true;
      }
    });
  }

  /**
   * This function updates the value of printableCollapsed and then calls the printReport function.
   */
  async downloadPDF() {
    this.updatePrintableCollapsed.emit({ value: !this.flagToGetCollapsed});
    this.printReport();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if((changes.flagToGetCollapsed.previousValue !== changes.flagToGetCollapsed.currentValue) && changes.flagToGetCollapsed.previousValue) {

      if (this.collapsedElements.size > 0) {
        this.collapsedElements.forEach((idx) => {
          this.backendResponse.sections[idx].visible = false;
        })
      } else {
        this.backendResponse.sections.forEach((section) => {
          section.visible = true;
        })
      }
    }
  }

  /**
   * The `printReport` function generates a tax projections report in PDF format and saves it to the user's device.
   */
  async printReport(){

    this.isLoadingPDF = true;

    this.onDataChange.emit(true);
    await delay_ms(4000);

    try{

      //let canvases = await this.estateSnapshotPrintableRender.sectionsToCanvas();
      let pagesData: PageData[] = await this.responseToPagesData();
      if(pagesData.length == 0) {
        throw("No data to be printed");
       }



      let clientsName = readFromStoragedObject('currentClient', 'fullName', 'Session');

      let companyData = await this.settingsServive.getCompanyData().toPromise();
      let fullPageDisclaimer = (companyData.settings.isFullDisclaimerActive === true) ? companyData.fullDisclaimer : undefined;



      TaxProjectionstReportTemplate.generateReport({
        clientName: clientsName,
        disclaimer: readFromStoragedObject('advisorPreferences', 'disclaimer'),
        logoUrl: companyData.logo,
        //canvases: canvases,
        //canvasesTitle: this.estateSnapshotPrintableRender.getSectionsTitle(),
        resolved: {},
        pagesData: pagesData,
        fullPageDisclaimer,
        AuxHTMLContainerID: 'tempForCanvasTaxProjections'
      }).then(report => {
        saveAs(report.doc.output('blob'), `${cleanNameForPDF(clientsName)} - Tax Projections Report`);
        this.isLoadingPDF = false;
      })
    }catch(error){
      this.isLoadingPDF = false;
      console.log(error);
      this.snackBar.open("Unavailable report", "Ok", {
        duration: 7000,
        panelClass: 'error-snackbar'
      });
    }
  }

  /**
   * The function `responseToPagesData` returns an array of `PageData` objects based on the
   * `backendResponse` and the visibility of sections.
   * @returns a Promise that resolves to an array of PageData objects.
   */
  async responseToPagesData(): Promise<PageData[]>{  //

    //let pagesData: PageData[] = [];

    if(this.backendResponse.sections === undefined){
      return []
    }

    let pagesData: PageData[] = [
      {pageTitle: '', canvases: ['header', 'summaryOverview', 'federalOverview', 'stateOverview']},
      {pageTitle: '', canvases: ['header', 'summaryInformation', 'grossIncome']},
      {pageTitle: '', canvases: ['header', 'agi', 'deductions', 'taxes']},
      {pageTitle: '', canvases: ['header', 'totalPayments']},
      {pageTitle: '', canvases: ['header', 'stateTaxes', 'medicare']},
      {pageTitle: '', canvases: ['header', 'magiTiers']},
    ];

    pagesData.map(pageData => {
      //Filter for the sections that are visible (not collpsed)
      const removeSectionsIfCollapsed = ['summaryInformation', 'magiTiers', 'medicare'];
      pageData.canvases = pageData.canvases.filter((canvasId) => {
        let section = this.backendResponse.sections.find(section => section.id == canvasId) //Here!

        if (section && !section.visible && removeSectionsIfCollapsed.includes(section.id)) {
          return;
        }
        return section || canvasId == 'header';
      }).map(canvasId => { //Map to the correspondent element in the DOM
        return document.getElementById('printable_' + canvasId);
      });
    })

    // Avoid blank pages
    pagesData = pagesData.filter(pageData => pageData.canvases.length > 1);

    return pagesData;
  }

  /**
   * The function ensures that the value of the object property "capitalGainOrLoss" is not less than -3000.
   * @param obj - The parameter `obj` is an object that contains properties such as `id` and `value`.
   */
  getMinValue(obj){
    if (obj.id === 'capitalGainOrLoss'){
      if (obj.value < -3000){
        obj.value = -3000
      }
    }
  }

}
