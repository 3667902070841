import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CloseDialogAtLogOut } from "app/shared/helpers/CloseDialogAtLogOut.class";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";

@Component({
    selector: 'dialog-multipleAssets',
    templateUrl: 'dialog-multipleAssets.html',
})
export class DialogMultipleAssets extends CloseDialogAtLogOut {

    constructor(
        public dialogRef: MatDialogRef<DialogMultipleAssets>,
        @Inject(MAT_DIALOG_DATA) public data:string,
        private authService: AuthenticationService
        ){
          super(dialogRef, authService);
         }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
