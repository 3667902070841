import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { PopoverService } from './popover.service';
import { PopoverDirective } from './popover.directive';



@NgModule({
  declarations: [
    PopoverDirective,
  ],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  providers: [
    Overlay,
    PopoverService
  ],
  exports: [
    PopoverDirective,
  ]
})
export class PopoverModule { }
