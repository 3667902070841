//Angular
import { Component, OnInit, OnDestroy, Inject, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router, NavigationStart } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

//3rd party
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { FileUploaderOptions, UPLOADER_STATUS } from "../../../views/questionnaires/file-uploader-component/file-uploader-component.component";
import { saveAs } from 'file-saver';
import { FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

//Project
import { WINDOW } from "../../../shared/helpers/window.provider";
import { AdvisorService } from "../../../shared/services/advisor.service";
import { AuthenticationService } from "../../../shared/services/auth/authentication.service";
import { QuestionnaireController } from '../Questionnaire.controller';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_ABSOLUTES_NOTSURE } from '../Questionnaire.constants';
import { uploadersValidator, validateAndSubmit } from '../Questionnaire.validations';
import { Area } from '../../../shared/helpers/areas.catalog';
import { estate_questions } from "./estate.model";
import { Mortgage } from '../q-mortgage/mortgage.model';
import { home_questions } from '../q-home/home.model';
import { elder_questions } from '../q-elder/elder.model';
import { general_questions } from '../q-general/general.model';
import { taxfull_questions } from '../q-taxfull/taxfull-model';
import { environment } from "../../../../environments/environment";
import { fpAnimations } from "../../../shared/animations/fp-animations";
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from "app/shared/helpers/url.helper";
import { FirstEstateRequestDialogComponent } from "app/shared/components/first-estate-request-dialog/first-estate-request-dialog.component";
import { _collapseOp } from "app/shared/helpers/utils";
import { DialogGenericNoticeComponent } from "app/shared/components/dialog-generic-notice/dialog-generic-notice.component";
import { EstateInfoDialogComponent } from "app/shared/components/estate-info-dialog/estate-info-dialog.component";
import { AppSocketIoService } from "app/shared/services/socket.io.service";
import { ClientService } from "app/shared/services/client/client.service";

@Component({
  selector: "app-q-estate",
  templateUrl: "./q-estate.component.html",
  styleUrls: ["./q-estate.component.scss"],
  animations: fpAnimations
})
export class QEstateComponent implements AfterViewChecked, OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tabRef', { static: false }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: estate_questions;
  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_ABSOLUTES_NOTSURE };
  public auxModel: any;
  public clientData: any;
  public extractionResults: any[] = [];

  public whoAnswered: string;
  public answers: Array<string>[] = [];

  public clientNetWorth: number;
  public clientIDSpouseNetWorth: number;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;
  public clientHasGrandChildren: string;
  public clientChildrenOneAge: number;
  public clientChildrenTwoAge: number;
  public clientChildrenThreeAge: number;
  public clientChildrenFourAge: number;
  public clientChildrenFiveAge: number;
  public clientLivesIn: string;
  public clientSpouseHasBusiness: string;
  public clienteIsRetired: string;
  public clientSpouseIsRetired: string;
  public jointNetWorth: number;
  public clientAge: number;
  public childCount: number;

  public uploaderWill: FileUploaderOptions;
  public uploaderRevocable: FileUploaderOptions;
  public uploaderIrrevocable: FileUploaderOptions;
  public uploaderPOA: FileUploaderOptions;
  public uploaderLifeInsurance: FileUploaderOptions;

  public fileUploaders: FileUploaderOptions[];

  public uploaderExtraDocuments: FileUploaderExtended;

  private uploadUrl: string;
  private batchUpload = environment.apiFilesUpload;
  public submittedDocuments: boolean = false;

  public isAdvisor: boolean = false;

  public showComplexEstatePlanning: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;

  public bulletColor;

  public mortgageData: Mortgage = new Mortgage();
  public homeData: home_questions = new home_questions();
  public elderData: elder_questions = new elder_questions();
  public generalData: general_questions = new general_questions();
  public fullTaxData: taxfull_questions = new taxfull_questions();

  public hasRetirementAccount: boolean = false;
  public hasLifeInsurance: boolean = false;
  public hasRevocableTrust: boolean = false;

  public clientID: string;
  public areasInHub;
  public isLoading: boolean;

  public fillingStatus: string;

  public isManualInputEnabled = true;
  public clientTypeOfBusiness: string;
  // public isFirstTimeHere: string;
  public toggleSearchBar: boolean = false;
  public documentIsValid: boolean = false;

  public isSpouseQuestionnaire: boolean = false;

  public isSnapshotReady: boolean = false;
  public saveButtonsToolTip: string = '';


  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  //Multiple assets
  public propName: string = '';
  public index: number;
  public loadedStoragedQuestionnaireData: any;

  //First time
  public isFirstTime: boolean = false;

  // Credits
  public creditsInformation: CreditsInformation = {
    hasCredits: false,
    remaining: 0,
    availableFreePackages: 0,
    freePackages: 0
  };
  public companyId: string;
  public monthRequests: any = [];
  public hasAgreement: Boolean = false;
  public hasBetaAccess: Boolean = false;

  public isProduction:boolean;
  public isEarlyTester: boolean = false;
  public isAdvisorOnTrial:boolean = false;
  public isFirstTabLoad: boolean = true;

  // Global conditional
  public isLoadingExtractions: boolean = false;
  public loadingDialog: MatDialogConfig;
  public dialogRef: any;
  public uploadedTrust: boolean = false;

  analysis = [
    { value: "Instant analysis", display: "Instant analysis" },
    { value: "Indepth analysis", display: "In-depth analysis" }
  ];

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private socketIO: AppSocketIoService,
    @Inject(WINDOW) private window: Window,
    private currentClientService: ClientService
  ) {
    this.loadingDialog = new MatDialogConfig();
    this.loadingDialog.panelClass = 'modal-dialog-questionnaire-estate';
    this.loadingDialog.width = '804px';
  }

  private commonAutoSaveFunction = () =>{

    if(!this.waitingForExtractions()){
      this.submit(true, true);
    }else{
      console.log("WARNING. Waiting for extractions. Autosave disabled");
    }

  }

  public waitingForExtractions(): boolean{
    let areAnyUploaderAlive: boolean = this.fileUploaders.find(uploader => uploader.isComponentAlive) != undefined;
    let areAnyUploadBeeingExtracted: boolean = this.fileUploaders.find(uploader => {return uploader.status == UPLOADER_STATUS.FILE_VALIDATING  || uploader.status == UPLOADER_STATUS.FILE_PROGRESS}) != undefined;
    let waiting = (areAnyUploaderAlive && areAnyUploadBeeingExtracted);
    this.saveButtonsToolTip = waiting ? "You will be able to submit the questionnaire in a few minutes." : '';
    return waiting;
  }

  ngOnInit() {

   /***
    * Catch the on leaving event by route and switch WS socket
    */
   let routerEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {

        if (!event.url.includes('questionnaire-estate')) {
          if(localStorage.getItem('socketType') === 'HTTP'){

            localStorage.removeItem('socketSilentMode');
            this.socketIO.shiftSocketMode();
          }
          routerEvent.unsubscribe();
        }
      }
    });

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let sessionToken = localStorage.getItem("sessionToken");

    this.isAdvisor = this.authService.isAdvisor();

    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    //this.isProduction && willSummaryEarlyAccess.includes(localStorage.getItem('userId')) ? this.isEarlyTester = true : this.isEarlyTester = false;
    this.isEarlyTester = (localStorage.getItem('betaAccess').includes('willSummaryEarlyAccess') || localStorage.getItem('betaAccess').includes('estateSnapshot')) ? true : false;

    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientHasGrandChildren = this.clientData.hasGrandChildren;
    this.clientNetWorth = Number(this.clientData.netWorth);
    this.clientIDSpouseNetWorth = Number(this.clientData.spouseNetWorth);
    this.clientChildrenOneAge = this.clientData.childOneAge;
    this.clientChildrenTwoAge = this.clientData.childTwoAge;
    this.clientChildrenThreeAge = this.clientData.childThreeAge;
    this.clientChildrenFourAge = this.clientData.childFourAge;
    this.clientChildrenFiveAge = this.clientData.childFiveAge;
    this.clientLivesIn = this.clientData.livesIn;
    this.clientSpouseHasBusiness = this.clientData.spouseHasBusiness;
    this.clienteIsRetired = this.clientData.isRetired;
    this.clientSpouseIsRetired = this.clientData.spouseIsRetired;
    let ageToNumber = Number(this.clientData.age);
    this.clientAge = ageToNumber;
    this.childCount = this.clientData.childrenCount + 1;
    this.jointNetWorth = this.clientNetWorth + this.clientIDSpouseNetWorth;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.clientTypeOfBusiness = this.clientData.typeOfBusiness;

    this.isLoading = true;


    //Initialize the questionnaire model
    this.model = new estate_questions();

    this.model.afterMappingLoad = (model) => {
      if(this.model.cliendIDExtraDocuments == undefined || this.model.cliendIDExtraDocuments == '' || this.model.cliendIDExtraDocuments == 'undefined'){
        this.model.cliendIDExtraDocuments = '';
        this.model.cliendIDReviewDocuments = 'No';
        this.model.requestContainsOtherDocs = 'No';
      }else{
        this.submittedDocuments = true;
        this.model.requestContainsOtherDocs = 'Yes';
      }
      if (model.clientIDWillStatus || model.clientIDRevocableTrustStatus || this.model.clientIDHasEstatePlan === 'Yes') {
        this.model.clientIDHasEstatePlan = 'Yes';
      } else {
        this.model.clientIDHasEstatePlan = 'No';
      }
      if (
        (model.clientIDWillStatus || model.clientIDRevocableTrust) &&
        (model.clientIDWillStatus !== 'COMPLETE'|| model.clientIDRevocableTrustStatus !== 'COMPLETE')
        && model.clientIDWillStatus !== 'INVALID'
        && model.clientIDRevocableTrustStatus !== 'INVALID'
        && model.clientIDWillStatus !== 'EMPTY'
        && model.clientIDRevocableTrustStatus !== 'EMPTY'
      ) {

      } else if (model.clientIDWillStatus === 'INVALID' || model.clientIDRevocableTrustStatus === 'INVALID') {
        this.loadingDialog.data = {
          title: 'Error',
          subtitle: `The document${model.clientIDWillStatus === 'INVALID' && model.clientIDRevocableTrustStatus === 'INVALID' ? 's' : ''} you uploaded is invalid as it did not meet certain criteria`,
          isError: true,
          advise: `The reasons include embedded encryption or unusual formatting (e.g. wide margins). There are three options for you:`,
          list: [
            'Click <b>"Send document"</b> to let FP Alpha team to upload the document on your behalf within 24 hours.',
            'Click <b>"Manual Input"</b> to enter the information manually, or',
            'Click <b>"Review documents"</b> to upload another document(s)',
          ],
          actions: {
            close: 'go to estate hub',
            closePrimary: false,
            closeLabel: 'Review documents',
            confirm: 'close',
            confirmPrimary: false,
            confirmLabel: 'Manual Input',
          },
          customAction: {
            custom: 'send doc',
            customPrimary: true,
            customLabel: 'Send document',
          }
        }
        this.loadingDialog.disableClose = true;
        this.dialogRef = this.dialog.open(EstateInfoDialogComponent, this.loadingDialog);
        this.dialogRef.afterClosed().subscribe(async (result) => {
          this.handleResultFromModal(result);
        });
      } else {

      }
    }
    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    let clientHasAnsweredQuestionnaire = clientHasAnswered.ansEstate;

    //Multiple Assets
    //Load session storage data from questionnare
    this.loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('estate');
    this.index = this.loadedStoragedQuestionnaireData.index != null ? this.loadedStoragedQuestionnaireData.index : 1;

    this.propName = (this.loadedStoragedQuestionnaireData.name != null) ? this.loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = this.loadedStoragedQuestionnaireData.name;


    this.index === 2 ? this.isSpouseQuestionnaire = true : this.isSpouseQuestionnaire = false;

    //File uploader
    this.uploadUrl = `${this.batchUpload}/estate/${this.clientData.clientId}?index=${this.index}&policyFileName=clientIDOtherEstate&nameOfInsuranceCompany=1`;

    this.uploadUrl = this.advisorService.dynamicIPAddress(
      this.window,
      this.uploadUrl
    );

    this.uploaderExtraDocuments = new FileUploaderExtended({
      url: this.uploadUrl,
      authTokenHeader: "Authorization",
      authToken: `Bearer ${sessionToken}`,
      itemAlias: "clientIDFiles"
    });

    this.uploaderExtraDocuments.onAfterAddingFile = file => {
      file.withCredentials = false;
    };



    //Load extraction results for file uploaders components
    this.qController.loadExtractionsResults(this.clientData.clientId, 'estate', String(this.index)).then(result => {
      this.extractionResults = result;
      if (this.extractionResults['clientIDOtherEstate'] === 'EMPTY') {
        this.model.requestContainsOtherDocs = 'No';
      } else {
        this.model.requestContainsOtherDocs = 'Yes';
        this.submittedDocuments = true;
      }
    });


    // Declare the auto save logic


    //Load questionnarie data, if clientHasAnswered
    if(this.loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'estate', this.model, this.index).then(response => {


        //this.model.clientIDEstatePredictions === 'No' ? this.model.clientIDTypeOfAnalysis = 'Indepth analysis' : this.model.clientIDTypeOfAnalysis = 'Instant analysis';
        if(this.model.clientIDTypeOfAnalysis !== 'Instant analysis'){ this.model.clientIDTypeOfAnalysis = 'Indepth analysis' };


        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
          ...(this.model.cliendIDReviewDocuments === 'undefined' ? { cliendIdReviewDocuments: 'No' } : {})
        };
        // Wait for the data before starting the autosave function
        //this.qController.initAutoSave2(this.commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      //this.qController.initAutoSave2(this.commonAutoSaveFunction);
    }

    //Load mortgage data if mortgage has been answered.
    if(clientHasAnswered.ansMortgage){
      this.qController.loadQuestionnaireData(this.clientData.clientId, 'mortgage', this.mortgageData, true);
    }

    //Load home data if mortgage has been answered.
    if(clientHasAnswered.ansHome){
        this.qController.loadQuestionnaireData(this.clientData.clientId, 'home', this.homeData, true);
    }

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputDataManually = 'No';
    console.log('Modelo cool: ', this.model);
    if (this.model.clientIDHasWill === 'Yes' || this.model.clientIDHasRevocableTrust === 'Yes') {
      this.model.clientIDHasEstatePlan = 'Yes';
    }
    this.fileUploaders = [
      this.uploaderWill = new FileUploaderOptions({
        fileID: 'clientIDWill',
        fileVal: this.model.clientIDWill,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'estate', false, this.index, 'clientIDWill'),
        documentReadableName: 'will',
        validationRule: () => { return true /* return this.uploaderWill.uploader.queue.length <= 0 ? true : this.uploaderWill.status == UPLOADER_STATUS.FILE_VALID */ },
        actionsAfterResponse: {
          validatedVoid : ()=>{
            this.qController
              .openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, 'clientIDWill', ()=>{} )
            },
          validating: () => {

            this.isLoadingExtractions = true;
          },
          progress: () => {


            this.isLoadingExtractions = true;
          },
          validatedValid: () => {

            if (
              this.uploaderWill.status !== 'uploading'
              && this.uploaderWill.status !== 'validating'
              && this.uploaderWill.status !== 'progress'
            ) {
              this.model.clientIDHasWill = 'Yes';

              this.isLoadingExtractions = false;
              this.dialog.closeAll();
              if(this.dialogRef){
                this.dialogRef.afterClosed().subscribe((result) => {
                  this.handleResultFromModal(result);
                })
              }

            }
          }
        }
      }

      ),
      this.uploaderRevocable = new FileUploaderOptions(
        {
          fileID: 'clientIDRevocableTrust',
          fileVal: this.model.clientIDRevocableTrust,
          uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'estate', false, this.index, 'clientIDRevocableTrust'),
          documentReadableName: 'revocable living trust',
          validationRule: () => { return true /* return this.uploaderRevocable.uploader.queue.length <= 0 ? true : this.uploaderRevocable.status == UPLOADER_STATUS.FILE_VALID */ },
          actionsAfterResponse: {
            validatedVoid : ()=>{
              this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, 'clientIDRevocableTrust', ()=>{} )
            },
            validating: () => {
              this.isLoadingExtractions = true;
            },
            progress: () => {
              this.isLoadingExtractions = true;
            },
            validatedValid: () => {
              if (
                this.uploaderRevocable.status !== 'uploading'
                && this.uploaderRevocable.status !== 'validating'
                && this.uploaderRevocable.status !== 'progress'
              ) {
                this.model.clientIDHasRevocableTrust = 'Yes';

                this.isLoadingExtractions = false;
                this.dialog.closeAll();
                if(this.dialogRef){
                  this.dialogRef.afterClosed().subscribe((result) => {
                    this.handleResultFromModal(result);
                  })
                }
              }
            }
            }
        }
      ),
      this.uploaderIrrevocable = new FileUploaderOptions(
        {
          fileID: 'clientIDIrrevocableTrust',
          fileVal: this.model.clientIDIrrevocableTrust,
          uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'estate', false, this.index, 'clientIDIrrevocableTrust'),
          documentReadableName: 'irrevocable trust',
          validationRule: () => {return this.uploaderIrrevocable.uploader.queue.length <= 0 ? true : this.uploaderIrrevocable.status == UPLOADER_STATUS.FILE_VALID },
          actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, 'clientIDIrrevocableTrust', ()=>{} )} }
        }
      ),
      this.uploaderPOA = new FileUploaderOptions(
        {
          fileID: 'clientIDPOA',
          fileVal: this.model.clientIDPOA,
          uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'estate', false, this.index, 'clientIDPOA'),
          documentReadableName: 'power of attorney',
          validationRule: () => {return this.uploaderPOA.uploader.queue.length <= 0 ? true : this.uploaderPOA.status == UPLOADER_STATUS.FILE_VALID },
          actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, 'clientIDPOA', ()=>{} )} }
        }
      ),
      this.uploaderLifeInsurance = new FileUploaderOptions(
        {
          fileID: 'clientIDLifeInsurancePolicy',
          fileVal: this.model.clientIDLifeInsurancePolicy,
          uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'estate', false, this.index, 'clientIDLifeInsurancePolicy'),
          documentReadableName: 'life insurance policy',
          validationRule: () => {return this.uploaderLifeInsurance.uploader.queue.length <= 0 ? true : this.uploaderLifeInsurance.status == UPLOADER_STATUS.FILE_VALID },
          actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'estate', this.index, 'clientIDLifeInsurancePolicy', ()=>{} )} }
        }
      )
    ];

    // This is for questions that requires to know if the client has any of the 5 documents
    this.uploaderWill.status == UPLOADER_STATUS.FILE_VALID ? this.model.clientIDHasWill = 'Yes' : this.model.clientIDHasWill = 'No';
    this.uploaderRevocable.status == UPLOADER_STATUS.FILE_VALID ? this.model.clientIDHasRevocableTrust = 'Yes' : this.model.clientIDHasRevocableTrust = 'No';
    this.uploaderIrrevocable.status == UPLOADER_STATUS.FILE_VALID ? this.model.clientIDHasIrrevocableTrust = 'Yes' : this.model.clientIDHasIrrevocableTrust = 'No';
    this.uploaderLifeInsurance.status == UPLOADER_STATUS.FILE_VALID ? this.model.clientIDHasLifeInsurancePolicy = 'Yes' : this.model.clientIDHasLifeInsurancePolicy = 'No';
    this.uploaderPOA.status == UPLOADER_STATUS.FILE_VALID ? this.model.clientIDHasPOA = 'Yes' : this.model.clientIDHasPOA = 'No';




    let combinedNetWorth = this.clientNetWorth + this.clientIDSpouseNetWorth;

    if(
      this.clientMaritalStatus === 'Single' && this.clientNetWorth > 11580000 ||
      this.clientMaritalStatus === 'Divorced' && this.clientNetWorth > 11580000 ||
      this.clientMaritalStatus === 'Widowed' && this.clientNetWorth > 11580000 ||
      this.clientMaritalStatus === 'Domestic partnership' && this.clientNetWorth > 11580000
      ) this.showComplexEstatePlanning = true;
    if(this.clientMaritalStatus === 'Married' && combinedNetWorth > 23160000) this.showComplexEstatePlanning = true;

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;

    //Read questionnaire list
    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientID = this.authService.isAdvisor() ? storagedClient.clientId : localStorage.getItem('userId');

    this.advisorService.getHubAreas(this.clientID)
      // .then(areas => this.areasInHub = areas, error => console.log(error));
      .subscribe(
        data => {
            //this.areasInHub = data;
            this.areasInHub = this.mergAreaData(data);

            for(let i = 0; i< this.areasInHub.length;i++){
              if(this.areasInHub[i].nameOfAreaComplex == "Life Insurance"){
                //this.model.clientIDHasLifeInsurancePolicy = 'Yes';
                this.hasLifeInsurance = true;
              }
            }

          },
          error => {
            console.log(error);
            this.snackBarError.open("Can't get Hub data. [" + error.error.message + ']', "", {
              duration: 6000,
              panelClass: 'error-snackbar'
            });
            this.isLoading = false;

          }
      );
      this.auxModel = {
        ...(this.auxModel ? { ...this.auxModel } : {}),
        ...this.model,
      };

      // Set the 'generate an Estate Snapshot' to No if there is already a snapshot or it hasn't be requested.
      //this.model.cliendIDReviewDocuments = 'No';
      this.advisorService.getEstateSnapshotMetaData(this.clientID, this.index).then((response: any) => {
        this.isSnapshotReady = (response.status != 'Not requested');
        let isFirstTimeSnapshot: boolean = (response.status === "Not requested");
        if(this.isSnapshotReady || isFirstTimeSnapshot){
          this.model.cliendIDReviewDocuments = 'No';
        }

      })

  }

  async _onFileLoad() {
    this.submittedDocuments = false;
    this.uploaderExtraDocuments.queue.forEach(doc =>{

      if(this.model.cliendIDExtraDocuments == ''){
        this.model.cliendIDExtraDocuments = doc.file.name;
      }else{
        this.model.cliendIDExtraDocuments += ',' + doc.file.name;
      }

    })

    this.submitExtraFiles();
  }


  submitExtraFiles() {
    if (this.uploaderExtraDocuments.queue.length > 0) {
      this.uploaderExtraDocuments.uploadAllFiles();
      this.uploaderExtraDocuments.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
      this.uploaderExtraDocuments.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    }
  }

  async handleResultFromModal(result: string) {
    switch(result) {
      case 'go back':

        this.router.navigate(['/hub']);
        break;
      case 'go to estate hub':
        this.router.navigate(['estate-documents']);
        break;
      case 'close':

        break;
      case 'manualInput':
        break;
      case 'send doc':
        let response = {};
        const whichInvalid = {
          clientIDWillStatus: this.model.clientIDWillStatus === 'INVALID',
          clientIDRevocableTrustStatus: this.model.clientIDRevocableTrustStatus === 'INVALID'
        }
        if (whichInvalid.clientIDWillStatus) {
          response = await this.advisorService.retainAreaFile(this.clientData.clientId, 'estate', this.index, 'clientIDWill');
          console.log("Retain file. WILL", response);
        }
        if (whichInvalid.clientIDRevocableTrustStatus) {
          response = await this.advisorService.retainAreaFile(this.clientData.clientId, 'estate', this.index, 'clientIDRevocableTrust');
          console.log("Retain file. REVOCABLE", response);
        }
        break;
      default:
        break;
    }
  }

  onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let output = JSON.parse(response);
    this.submittedDocuments = true;
    this.model.requestContainsOtherDocs = 'Yes';
  }

  onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ): any {
    let error = JSON.parse(response);
    console.log(error);
  }

  resetUploader() {
    if (this.uploaderExtraDocuments.queue[0] != undefined) {
      this.uploaderExtraDocuments.queue[0].remove();
    }
    this.uploaderExtraDocuments.clearQueue();
    this.model.cliendIDExtraDocuments = '';
    this.submittedDocuments = false;

  }

  async ngAfterViewInit() {

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    //Load Elder questionnaire info
    if(clientHasAnswered.ansElder){
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'elder', this.elderData, this.index);
    }

    if(this.elderData.clientIDHasRetAcc == 'Yes'){
      this.hasRetirementAccount = true;
    }

    if(this.elderData.clientIDHasLifeInsurance == 'Yes'){
      this.hasLifeInsurance = true;
    }

    if(this.elderData.clientIDHasRevocableTrust == 'Yes'){
      this.hasRevocableTrust = true;
    }

    //Load General questionnaire info
    if(clientHasAnswered.ansGeneral){
      this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
        console.log('DATA: ', data);
        if (data?.personalInformation) {
          const answers = data?.personalInformation?.data;
          answers.forEach(({ name, answer }) => {
            this.generalData[name] = answer === 'undefined' || !answer ? '' : answer
          })
        }
        if (data?.family) {
          const assetsData = data?.family?.data;
          const business = assetsData.business;
          this.generalData.clientIDHasABusiness = business?.length >= 1 ? 'Yes' : 'No';
          this.model.clientIDIsBusinessLLC = business?.some(({ subtype }) => subtype === 'LLC') ? 'Yes' : 'No';
        }
      })
    }

    //Load fulltax questionnaire info
    if(clientHasAnswered.ansFulltax){
      await this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'fulltax', this.fullTaxData, 1);
      this.model.clientIDFillingStatus = this.fullTaxData.clientIDFillingStatus;
    }

    if(this.generalData.clientIDHasABusiness == 'Yes'){
      this.model.clientIDIlliquidHighPotentialAsset = 'Yes';
    }

    let taxQuestionnaireAnswered = false;
    if(clientHasAnswered.ansFulltax == true || clientHasAnswered.ansTax == true){
      taxQuestionnaireAnswered = true;
      this.model.isTaxReturnAnswered = "Yes";
    }else{
      this.model.isTaxReturnAnswered = "No";
    }


    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
    if (this.model.clientIDHasWill == 'Yes' || this.model.clientIDHasRevocableTrust == 'Yes' || this.model.clientIDHasEstatePlan === 'Yes') {
      this.model.clientIDHasEstatePlan = 'Yes';
    } else {
      this.model.clientIDHasEstatePlan = 'No';
    }
    await this.getAdvisorData();
    this.getEstateSnapshotCredits();

  }

  ngAfterViewChecked(): void {
    if(typeof this.tabRef !== "undefined"){
      this.ntabs = parseInt(this.tabRef._tabs.length);
      this.countTabs(this.ntabs);
    }
  }

  onSaveForLater() {
    this.submit(true);
  }

  async submit(saveForLater: boolean = false, silentSave:boolean = false) {

    this.clientTypeOfBusiness === 'LLC' ? this.model.clientIDIsBusinessLLC = 'Yes' : this.model.clientIDIsBusinessLLC = 'No';
    let hasManualInput = this.model.clientIDWantsToInputDataManually === 'Yes';

    this.model.clientIDTypeOfAnalysis === 'Instant analysis' ? this.model.clientIDEstatePredictions = 'Yes' : this.model.clientIDEstatePredictions = 'No';

    this.model.clientIDAssetName = this.loadedStoragedQuestionnaireData.name;




    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };


    if ((this.creditsInformation.remaining === 0 || this.model.cliendIDReviewDocuments === 'No' || this.model.cliendIDReviewDocuments === 'undefined' || !this.model.cliendIDReviewDocuments) && !saveForLater) {

      localStorage.setItem('estate-error', 'Yes');
    } else {
      localStorage.removeItem('estate-error');
    }


    if(this.model.cliendIDReviewDocuments == 'Yes' && saveForLater == false){
      await this.http.post<any>(`${environment.apiRequestSummary}/?idcli=${this.clientID}&index=${this.index}`, {}, options).toPromise().then(res => {

      }).catch(err => {
        if (err.status === 409) {
          console.log('Hubo un error feo');
          localStorage.setItem('estate-error', 'Yes');
        }
      });
    }

    this.model.requestContainsOtherDocs = this.submittedDocuments || this.extractionResults['clientIDOtherEstate'] !== 'EMPTY' ? 'Yes' : 'No';


    validateAndSubmit(
      [uploadersValidator(this, this.fileUploaders)],
      () => this.qController.submitForm(this, this.model, this.clientData.clientId, 'estate', saveForLater, null, silentSave, hasManualInput, false),
      saveForLater,
    )
  }

  onAnyUploaderReset($event: string) {
    this.model[$event] = '';
    this.extractionResults[$event] = '';
  }

  countTabs(tabs:number){
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
  }


  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  this.model.progressTab = tabChangeEvent.index;
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index * this.progress);
	  }

    this.commonAutoSaveFunction();


  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
  }

  mergAreaData(areasFromBack:any[] = []){
    let mergedData = [];
    areasFromBack.forEach(areaFromBack => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }

  blockManualInput() {
    this.isManualInputEnabled = false;
  }

  async onFileUploaderResponse() {

    let completedStates = [UPLOADER_STATUS.FILE_INVALID, UPLOADER_STATUS.FILE_VALID];

    if (this.uploaderWill.status == UPLOADER_STATUS.FILE_VALID) {
      //Load the extractions data
      await this.qController.loadExtractionsDataToModel(this.model, estate_questions,  this.clientData.clientId, 'estate', this.index);
      this.documentIsValid = true;
      //Set model clientIDHasWill to Yes.
       this.model.clientIDHasWill = 'Yes';
       if(this.model.clientIDHasRevocableTrust == 'undefined'){
        this.model.clientIDHasRevocableTrust = 'No'
      }
      // If first time in the quest, display visual aid
      // if (this.isFirstTimeHere === 'estate' && !completedStates.includes(this.uploaderWill.status)) this.qController.openModalWhatToDoNext(this);
    }

    //If is valid revocable trust set clientIDHasRevocableTrust to Yes to see extractions
    if(this.uploaderRevocable.status == UPLOADER_STATUS.FILE_VALID){
      //Load the extractions data
      await this.qController.loadExtractionsDataToModel(this.model, estate_questions,  this.clientData.clientId, 'estate', this.index);
      this.documentIsValid = true;
      //Set model clientIDHasRevocableTrust to Yes.
      this.model.clientIDHasRevocableTrust = 'Yes';
      if(this.model.clientIDHasWill == 'undefined'){
        this.model.clientIDHasWill = 'No'
      }
      // If first time in the quest, display visual aid, only if the other uploader isnt
      // if (this.isFirstTimeHere === 'estate' && !completedStates.includes(this.uploaderRevocable.status) ) this.qController.openModalWhatToDoNext(this);
    }else{
      this.model.clientIDHasRevocableTrust = 'No'
    }


    let endOfInteractionStates  = [UPLOADER_STATUS.INITIAL, UPLOADER_STATUS.FILE_INVALID, UPLOADER_STATUS.FILE_VALID];

    // After both uploaders are on a valid end of interacion status, re-enable the manual input option
    if(endOfInteractionStates.includes(this.uploaderWill.status) && endOfInteractionStates.includes(this.uploaderRevocable.status)){
      this.isManualInputEnabled = true;
    }
  }


  ngOnDestroy(){
    localStorage.removeItem('estate-error');
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  resetQuestion(event, el) {
    event.preventDefault();
    const target = el.name;

    if (this.model[target] && this.model[target] === el.value) {
      el.checked = false;
      this.model[target] = '';
    } else {
      this.model[target] = el.value
      el.checked = true;
    }
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    let pdfVersion = '';
    this.model.clientIDHasEstatePlan === 'Yes' ? pdfVersion = 'Plan' : pdfVersion = 'NoPlan'

      this.http.get(`${this.pdfUrl}${'estate'}/?options=${pdfVersion}`, options)
      .subscribe(blob => {
        saveAs(blob, 'estate-questionnaire.pdf');
      });
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  async getEstateSnapshotCredits() {
    try {
      const { availableFreePackages, hasCredits, remaining, freePackages, isOnFreeTrialPeriod, hasBetaAccess }: any = await this.advisorService.getEstateSnapshotSummaryCredits(this.clientID);
      const ans = await this.advisorService.getEstateSnapshotRequestsByMonth(this.companyId, {
        mode: 'monthly',
        withDetail: false,
      });
      const ansAct: any = await this.advisorService.getActivity();
      if (ansAct && ansAct.length > 0) {
        this.hasAgreement = true;
      }


      this.monthRequests = ans;
      this.creditsInformation.hasCredits = hasCredits;
      this.creditsInformation.remaining = remaining;
      this.creditsInformation.availableFreePackages = availableFreePackages;
      this.creditsInformation.freePackages = freePackages;
      this.hasBetaAccess = hasBetaAccess;

      if (this.creditsInformation.remaining === 0 || (availableFreePackages === 0 && isOnFreeTrialPeriod && hasBetaAccess)) {
        this.model.cliendIDReviewDocuments = 'No';
      }
    } catch(e) {
      console.log(e);
    }
  }

  async getAdvisorData() {
    try {
      const data: any = await this.advisorService.getAdvisorDataPromise();

      this.companyId = data.companyId;
      this.isAdvisorOnTrial =  (data.isOnFreeTrialPeriod != undefined) ? data.isOnFreeTrialPeriod : true;
      this.hasBetaAccess = localStorage.getItem('betaAccess').includes("estateSnapshot");
    } catch(e) {
      console.log(e);
    }
  }

  async changeSummary(event: any) {

    let showPrice: boolean = !(this.isAdvisorOnTrial || this.isEarlyTester);


    if (event.value === 'Yes' && !this.hasAgreement) {
      // if (event.value === 'Yes') {
      const dialogRef = this.dialog.open(FirstEstateRequestDialogComponent, {
        width: '804px',
        panelClass: 'modal-dialog-questionnaire-estate',
        data: {
          showPrice: showPrice
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response) {
          this.model.cliendIDReviewDocuments = 'No';
        } else {
          this.hasAgreement = true;
        }
      })
    }

    // If there is a previous snapshot && there is any uploaded documents, reset the uploaders.
    if (
      event.value === 'Yes' &&
      (this.fileUploaders.some(uploaderOptions => {return uploaderOptions.status != UPLOADER_STATUS.INITIAL}))
    ){
      this.fileUploaders.map(uploaderOptions => {

        if(uploaderOptions.isComponentAlive){
          uploaderOptions.componentRef.resetUploader();
        }
      })
      //Reset the multidocument uploader
      this.resetUploader();
      this.openNoticeUploadDocuments();
    }

  }

  /**
   * @name sortBy
   * @description Sorts the client list by the term provided.
   * @param term Can be Name, Type, and Date added
   */

  isTryingToRunInstantWithNoPlan(): boolean {

    let instantWithNoPlan;

    if(this.model.clientIDHasEstatePlan === 'No' && this.model.clientIDTypeOfAnalysis === 'Instant analysis' || this.model.clientIDHasEstatePlan === '' && this.model.clientIDTypeOfAnalysis === 'Instant analysis') {
      instantWithNoPlan = true
    } else {
      instantWithNoPlan = false
    }
    return instantWithNoPlan;
  }

  /**
   * Conditios under the Submit button is disabled
   * Documentos que no son validos o
   */
  isSubmitDisabled(): boolean{
    let isDisabled =
    this.model.cliendIDReviewDocuments == 'Yes' &&
    !(this.fileUploaders.some(uploaderOptions => {return uploaderOptions.status == UPLOADER_STATUS.FILE_VALID})  || this.submittedDocuments);

    return isDisabled;
  }

  openNoticeUploadDocuments(){

      const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-review-extractions',
        width: '55vw',
        data: {
          title: 'Notice',
          titleClass: 'h1 fp-font-bold',
          body:
            `<b>Please re-upload your documents.</b><br><br>

             Per our security policy, we permanently delete all documents from our server once the required information is extracted.
             <br/> This means, if you uploaded a Will or Trust in the past, we CANNOT generate a Snapshot or recommendations from it.
             <br/> Please upload the documents again, and request a snapshot through the Snapshot Tab. <br/><br/>
            `,
          actionButtons: [
            {
              text: "Agree",
              class: 'button-primary mat-elevation-z4',
              action: () => {
                dialogRef.close();
              }
            }
          ]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        ;
      })
  }

}


interface CreditsInformation {
  hasCredits: boolean,
  remaining: number,
  availableFreePackages: number,
  freePackages: number,
}
