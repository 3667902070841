import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit, OnChanges {

  @Input() public method: any;
  @Input() public selected: any;
  @Input() showControls: any;
  @Input() public black: boolean = false;

  @Output() public selectMethod: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    console.log(this.method);
  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(changes);
  }

  updateSelectedCard() {
    this.selectMethod.emit(this.method.stripeId);
  }

}
