import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-table-estate',
  templateUrl: './main-table-estate.component.html',
  styleUrls: ['./main-table-estate.component.scss']
})
export class MainTableEstateComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() isPrintable: boolean = false;
  @Input() showIcons: boolean = false;
  @Input() showScenarios: boolean = false;
  @Input() titleTable: string;
  @Input() scenarioSelectedInput: string;
  @Input() scenario: any;
  @Input() type: any;
  @Output() scenarioSelectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToFacts: EventEmitter<any> = new EventEmitter<any>();

  public scenariosArray: any[] = [
    {
      title: 'Scenario One',
      key: 'scenario1'
    },
    {
      title: 'Scenario Two',
      key: 'scenario2'
    },
    {
      title: 'Scenario Three',
      key: 'scenario3'
    }
  ];

  public scenarioColors = {
    today : '',
    scenario1 : '#AD6AB8',
    scenario2 : '#8CB986',
    scenario3 : '#78AFDD',
  };

  public scenarioSelectedLocal: string = 'scenario1';
  public scenarioSelectedLabel: string = 'Scenario One';
  public isAssumedAnualGrowthRateEnabled: any;
  public window: any;

  constructor (
    public router: Router
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes.scenarioSelectedInput){
      if(changes.scenarioSelectedInput.currentValue != 'today' && changes.scenarioSelectedInput.currentValue != 'projected'){
        this.scenarioSelectedLocal = changes.scenarioSelectedInput.currentValue;
        // console.log('this.scenarioSelectedLocal**', this.scenarioSelectedLocal);
      }
    }
    if(changes.data){
      this.isAssumedAnualGrowthRateEnabled = changes.data.currentValue[0].isAssumedAnualGrowthRateEnabled;
    }

  }

  ngOnInit(): void {
    this.window = window;
    console.log('dataMainTable**', this.data);
    console.log('scenarioSelectedInput**', this.scenarioSelectedInput);
  }

  selectScenario(scenario){
    this.scenarioSelectedLocal = scenario.key;
    this.scenarioSelectedLabel = scenario.title;
    this.scenarioSelectedEmitter.emit(this.scenarioSelectedLocal);
  }

  goToFacts_(){
    console.log('vamos a facts');
    this.router.navigate(['advisor/assets/estate-lab-2/facts']);
    this.goToFacts.emit('goToFacts');
  }

}
