import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import { AdvisorService } from '../../../shared/services/advisor.service';
import { DeleteClientDialogData } from '../clients.interfaces';

// import { HttpClient } from '@angular/common/http';
// Delete Client Dialog
@Component({
  selector: 'delete-client-dialog',
  templateUrl: 'delete-client-dialog.html',
})

export class DeleteClientDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  public isDeletingClient: boolean = false;

  constructor(
    // private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<DeleteClientDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DeleteClientDialogData,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {
    
  }

  banishClientFromExistence() {

    this.isDeletingClient = true;

    this.advisorService.deleteClient(this.data.client)
      .subscribe(
        response => {
          
          this.dialogRef.close('deleted');
          this.isDeletingClient = false;
          this.snackBarSuccess.open("Client deleted successfully", "Ok", {
            duration: 3000,
            verticalPosition: "top",
            panelClass: "success-snackbar"
          });

        },
        error => {
          console.log(error);
          this.dialogRef.close('error');
          this.isDeletingClient = false;
          this.snackBarError.open("Can't delete client. [" + error.error.message + ']', "", {
            duration: 6000,
            panelClass: 'error-snackbar'
          });
        }
      );

  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
