import { Questionnaire } from '../Questionnaire.model';
import { DataTransformation } from '../DataTransformation.class';
import { utilsCalculateAge } from 'app/shared/helpers/utils';

export class general_questions extends Questionnaire {


   // *** Questionnaire header *** //
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;

   // *** Personal information *** //
   public clientIDFirstName: string = ''; // TODO: See with Barbier if this is needed
   public clientIDLastName: string = '';  // TODO: See with Barbier if this is needed

   public clientIDGender: string = '';
   public clientIDAge: number = 0;
   public clientIDBirthday: Date = new Date();
   public clientIDLivingState: string = '';
   public clientIDAddress: string = ''; // TODO: This is PII and we should get rid of it
   public clientIDRetiredOrNotWorking: string = '';
   public clientIDOccupation: string = '';
   public clientIDNetWorth: number = 0;

   // *** Family information *** //
   public clientIDMaritalStatus: string = '';

   // --> Spouse block
   public clientIDSpouseName: string = '';
   public clientIDSpouseUsesLastName: string = '';
   public clientIDSpouseLastName: string = '';
	 public clientIDSpouseGender: string = '';
   public clientIDSpouseBirthday: Date = new Date();
   public clientIDSpouseAge: number = 0;
   public clientIDSpouseNetWorth: number = 0;
   public clientIDSpouseRetired: string = '';
   public clientIDSpouseHasABusiness: string = '';
   public clientIDSpouseAdressSame: string = '';
   public clientIDSpouseNonResidentAlien: string = '';

   // --> Children block
   public clientIDChildren: string = '';

   public clientIDChildOneName: string = '';
   public clientIDChildOneBirthday: Date = new Date();
   public clientIDChildOneAge: number = 0;

   public clientIDChildTwoName: string = '';
   public clientIDChildTwoBirthday: Date = new Date();
   public clientIDChildTwoAge: number = 0;

   public clientIDChildThreeName: string = '';
   public clientIDChildThreeBirthday: Date = new Date();
   public clientIDChildThreeAge: number = 0;

   public clientIDChildFourName: string = '';
   public clientIDChildFourBirthday: Date = new Date();
   public clientIDChildFourAge: number = 0;

   public clientIDChildFiveName: string = '';
   public clientIDChildFiveBirthday: Date = new Date();
   public clientIDChildFiveAge: number = 0;

   public clientIDChildrenCount: number = 0; // Internal (py does not need this)

   // --> Grandchildren block
   public clientIDGrandchildren: string = '';
   public clientIDGrandchildOneName: string = '';
   public clientIDGrandChildOneBirthday: Date = new Date();
   public clientIDGrandchildOneAge: number = 0;

   public clientIDGrandchildTwoName: string = '';
   public clientIDGrandChildTwoBirthday: Date = new Date();
   public clientIDGrandchildTwoAge: number = 0;

   public clientIDGrandchildThreeName: string = '';
   public clientIDGrandChildThreeBirthday: Date = new Date();
   public clientIDGrandchildThreeAge: number = 0;

   public clientIDGrandchildFourName: string = '';
   public clientIDGrandChildFourBirthday: Date = new Date();
   public clientIDGrandchildFourAge: number = 0;

   public clientIDGrandchildFiveName: string = '';
   public clientIDGrandChildFiveBirthday: Date = new Date();
   public clientIDGrandchildFiveAge: number = 0;

   public clientIDGrandchildSixName: string = '';
   public clientIDGrandChildSixBirthday: Date = new Date();
   public clientIDGrandchildSixAge: number = 0;

   public clientIDGrandChildrenCount: number = 0; // Internal (counter)

   // --> Additional family block
   public clientIDHasMoreFamilyMembers: string = ''; // Internal

   public clientIDFatherName: string = '';
   public clientIDMotherName: string = '';
   public clientIDSiblingOneName: string = ''
   public clientIDSiblingTwoName: string = ''
   public clientIDSiblingThreeName: string = ''
   public clientIDSiblingFourName: string = ''

   // --> Additional family questions
   public clientIDHasFamilyWithDisability: string = '';
   public clientIDFamilyAStudent: string = '';

   // *** Asset information *** //

   // --> Real Estate block
   ///// --> Primary residence
   // public clientIDRealEstateNickname_1: string = '';
   public cliendIDRealEstateType_1: string = 'Primary Residence';
   public cliendIDRealEstateValue_1: number = 0;
   public clientIDRealEstateLocation_1: string = '';
   public clientIDCostBasisOfProperty_1: number = 0;
   public clientIDPropertyTitling_1: string = '';
   public clientIDPropertyNickname_1: string = '';
   public clientIDPrimaryResidence: string = ''; //Just for Primary Residence
   public clientIDLoansAgainstProperty_1: string = '';
   public clientIDCurrentLoanBalance_1: number = 0;
   public cliendIDRealEstateValue_1_Required: any = false;

   /// --> Additional properties
   public clientIDOutisdePrimaryResidence: string = '';

   ///// --> Additional One
   // public clientIDRealEstateNickname_2: string = '';
   public cliendIDRealEstateType_2: string = '';
   public cliendIDRealEstateValue_2: number = 0;
   public clientIDRealEstateLocation_2: string = '';
   public clientIDCostBasisOfProperty_2: number = 0;
   public clientIDPropertyTitling_2: string = '';
   public clientIDPropertyNickname_2: string = '';
   public clientIDLoansAgainstProperty_2: string = '';
   public clientIDCurrentLoanBalance_2: number = 0;
   public cliendIDRealEstateValue_2_Required: any = false;
   ///// --> Additional Two
   // public clientIDRealEstateNickname_3: string = '';
   public cliendIDRealEstateType_3: string = '';
   public cliendIDRealEstateValue_3: number = 0;
   public clientIDRealEstateLocation_3: string = '';
   public clientIDCostBasisOfProperty_3: number = 0;
   public clientIDPropertyTitling_3: string = '';
   public clientIDPropertyNickname_3: string = '';
   public clientIDLoansAgainstProperty_3: string = '';
   public clientIDCurrentLoanBalance_3: number = 0;
   public cliendIDRealEstateValue_3_Required: any = false;
   ///// --> Additional Three
   // public clientIDRealEstateNickname_4: string = '';
   public cliendIDRealEstateType_4: string = '';
   public cliendIDRealEstateValue_4: number = 0;
   public clientIDRealEstateLocation_4: string = '';
   public clientIDCostBasisOfProperty_4: number = 0;
   public clientIDPropertyTitling_4: string = '';
   public clientIDPropertyNickname_4: string = '';
   public clientIDLoansAgainstProperty_4: string = '';
   public clientIDCurrentLoanBalance_4: number = 0;
   public cliendIDRealEstateValue_4_Required: any = false;
  ///// --> Additional Four
  // public clientIDRealEstateNickname_4: string = '';
  public cliendIDRealEstateType_5: string = '';
  public cliendIDRealEstateValue_5: number = 0;
  public clientIDRealEstateLocation_5: string = '';
  public clientIDCostBasisOfProperty_5: number = 0;
  public clientIDPropertyTitling_5: string = '';
  public clientIDPropertyNickname_5: string = '';
  public clientIDLoansAgainstProperty_5: string = '';
  public clientIDCurrentLoanBalance_5: number = 0;
  public cliendIDRealEstateValue_5_Required: any = false;
  ///// --> Additional Five
  // public clientIDRealEstateNickname_6: string = '';
  public cliendIDRealEstateType_6: string = '';
  public cliendIDRealEstateValue_6: number = 0;
  public clientIDRealEstateLocation_6: string = '';
  public clientIDCostBasisOfProperty_6: number = 0;
  public clientIDPropertyTitling_6: string = '';
  public clientIDPropertyNickname_6: string = '';
  public clientIDLoansAgainstProperty_6: string = '';
  public clientIDCurrentLoanBalance_6: number = 0;
  public cliendIDRealEstateValue_6_Required: any = false;
  ///// --> Additional Six
  // public clientIDRealEstateNickname_7: string = '';
  public cliendIDRealEstateType_7: string = '';
  public cliendIDRealEstateValue_7: number = 0;
  public clientIDRealEstateLocation_7: string = '';
  public clientIDCostBasisOfProperty_7: number = 0;
  public clientIDPropertyTitling_7: string = '';
  public clientIDPropertyNickname_7: string = '';
  public clientIDLoansAgainstProperty_7: string = '';
  public clientIDCurrentLoanBalance_7: number = 0;
  public cliendIDRealEstateValue_7_Required: any = false;
  ///// --> Additional Seven
  // public clientIDRealEstateNickname_8: string = '';
  public cliendIDRealEstateType_8: string = '';
  public cliendIDRealEstateValue_8: number = 0;
  public clientIDRealEstateLocation_8: string = '';
  public clientIDCostBasisOfProperty_8: number = 0;
  public clientIDPropertyTitling_8: string = '';
  public clientIDPropertyNickname_8: string = '';
  public clientIDLoansAgainstProperty_8: string = '';
  public clientIDCurrentLoanBalance_8: number = 0;
  public cliendIDRealEstateValue_8_Required: any = false;
  ///// --> Additional Seven
  // public clientIDRealEstateNickname_9: string = '';
  public cliendIDRealEstateType_9: string = '';
  public cliendIDRealEstateValue_9: number = 0;
  public clientIDRealEstateLocation_9: string = '';
  public clientIDCostBasisOfProperty_9: number = 0;
  public clientIDPropertyTitling_9: string = '';
  public clientIDPropertyNickname_9: string = '';
  public clientIDLoansAgainstProperty_9: string = '';
  public clientIDCurrentLoanBalance_9: number = 0;
  public cliendIDRealEstateValue_9_Required: any = false;
  ///// --> Additional Seven
  // public clientIDRealEstateNickname_10: string = '';
  public cliendIDRealEstateType_10: string = '';
  public cliendIDRealEstateValue_10: number = 0;
  public clientIDRealEstateLocation_10: string = '';
  public clientIDCostBasisOfProperty_10: number = 0;
  public clientIDPropertyTitling_10: string = '';
  public clientIDPropertyNickname_10: string = '';
  public clientIDLoansAgainstProperty_10: string = '';
  public clientIDCurrentLoanBalance_10: number = 0;
  public cliendIDRealEstateValue_10_Required: any = false;


   public clientIDHasRealEstate_Count: number = 0; // Internal (counter)

   // --> 529 plan block
   public clientIDHas529Plan: string = '';

   public clientIDHas529Plan_Nickname_1: string = '';
   public clientIDHas529Plan_AccountValue_1: number = 0;
   public clientIDHas529Plan_Custodian_1: string = '';
   public clientIDHas529Plan_Beneficiaries_1: any;

   public clientIDHas529Plan_Nickname_2: string = '';
   public clientIDHas529Plan_AccountValue_2: number = 0;
   public clientIDHas529Plan_Custodian_2: string = '';
   public clientIDHas529Plan_Beneficiaries_2: any;

   public clientIDHas529Plan_Nickname_3: string = '';
   public clientIDHas529Plan_AccountValue_3: number = 0;
   public clientIDHas529Plan_Custodian_3: string = '';
   public clientIDHas529Plan_Beneficiaries_3: any;

  public clientIDHas529Plan_Nickname_4: string = '';
  public clientIDHas529Plan_AccountValue_4: number = 0;
  public clientIDHas529Plan_Custodian_4: string = '';
  public clientIDHas529Plan_Beneficiaries_4: any;

  public clientIDHas529Plan_Nickname_5: string = '';
  public clientIDHas529Plan_AccountValue_5: number = 0;
  public clientIDHas529Plan_Custodian_5: string = '';
  public clientIDHas529Plan_Beneficiaries_5: any;

  public clientIDHas529Plan_Nickname_6: string = '';
  public clientIDHas529Plan_AccountValue_6: number = 0;
  public clientIDHas529Plan_Custodian_6: string = '';
  public clientIDHas529Plan_Beneficiaries_6: any;

  public clientIDHas529Plan_Nickname_7: string = '';
  public clientIDHas529Plan_AccountValue_7: number = 0;
  public clientIDHas529Plan_Custodian_7: string = '';
  public clientIDHas529Plan_Beneficiaries_7: any;

  public clientIDHas529Plan_Nickname_8: string = '';
  public clientIDHas529Plan_AccountValue_8: number = 0;
  public clientIDHas529Plan_Custodian_8: string = '';
  public clientIDHas529Plan_Beneficiaries_8: any;

  public clientIDHas529Plan_Nickname_9: string = '';
  public clientIDHas529Plan_AccountValue_9: number = 0;
  public clientIDHas529Plan_Custodian_9: string = '';
  public clientIDHas529Plan_Beneficiaries_9: any;

  public clientIDHas529Plan_Nickname_10: string = '';
  public clientIDHas529Plan_AccountValue_10: number = 0;
  public clientIDHas529Plan_Custodian_10: string = '';
  public clientIDHas529Plan_Beneficiaries_10: any;


   public clientIDHas529Plan_Count: number = 0; // Internal (counter)

   // --> Retirement accounts block
   public clientIDHasTraditionalIRA: string = '';
   public clientIDHasRoth: string = '';
   public clientIDHasAccessTo401KPlan: string = '';
   public clientIDHasAccessTo457Plan: string = '';

   /// --> Traditional IRA
   public clientIDHasTraditionalIRA_Nickname_1: string = '';
   public clientIDHasTraditionalIRA_AccountValue_1: number = 0;
   public clientIDHasTraditionalIRA_Custodian_1: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_1: any;

   public clientIDHasTraditionalIRA_Nickname_2: string = '';
   public clientIDHasTraditionalIRA_AccountValue_2: number = 0;
   public clientIDHasTraditionalIRA_Custodian_2: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_2: any;

   public clientIDHasTraditionalIRA_Nickname_3: string = '';
   public clientIDHasTraditionalIRA_AccountValue_3: number = 0;
   public clientIDHasTraditionalIRA_Custodian_3: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_3: any;

   public clientIDHasTraditionalIRA_Nickname_4: string = '';
   public clientIDHasTraditionalIRA_AccountValue_4: number = 0;
   public clientIDHasTraditionalIRA_Custodian_4: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_4: any;

   public clientIDHasTraditionalIRA_Nickname_5: string = '';
   public clientIDHasTraditionalIRA_AccountValue_5: number = 0;
   public clientIDHasTraditionalIRA_Custodian_5: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_5: any;

   public clientIDHasTraditionalIRA_Nickname_6: string = '';
   public clientIDHasTraditionalIRA_AccountValue_6: number = 0;
   public clientIDHasTraditionalIRA_Custodian_6: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_6: any;

   public clientIDHasTraditionalIRA_Nickname_7: string = '';
   public clientIDHasTraditionalIRA_AccountValue_7: number = 0;
   public clientIDHasTraditionalIRA_Custodian_7: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_7: any;

   public clientIDHasTraditionalIRA_Nickname_8: string = '';
   public clientIDHasTraditionalIRA_AccountValue_8: number = 0;
   public clientIDHasTraditionalIRA_Custodian_8: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_8: any;

   public clientIDHasTraditionalIRA_Nickname_9: string = '';
   public clientIDHasTraditionalIRA_AccountValue_9: number = 0;
   public clientIDHasTraditionalIRA_Custodian_9: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_9: any;

   public clientIDHasTraditionalIRA_Nickname_10: string = '';
   public clientIDHasTraditionalIRA_AccountValue_10: number = 0;
   public clientIDHasTraditionalIRA_Custodian_10: string = '';
   public clientIDHasTraditionalIRA_Beneficiaries_10: any;

   public clientIDHasTraditionalIRA_Count: number = 0; // Internal

   /// --> Roth IRA
   public clientIDHasRoth_Nickname_1: string = '';
   public clientIDHasRoth_AccountValue_1: string = '';
   public clientIDHasRoth_AccountTitle_1: string = '';
   public clientIDHasRoth_Beneficiaries_1: any;

   public clientIDHasRoth_Nickname_2: string = '';
   public clientIDHasRoth_AccountValue_2: string = '';
   public clientIDHasRoth_AccountTitle_2: string = '';
   public clientIDHasRoth_Beneficiaries_2: any;

   public clientIDHasRoth_Nickname_3: string = '';
   public clientIDHasRoth_AccountValue_3: string = '';
   public clientIDHasRoth_AccountTitle_3: string = '';
   public clientIDHasRoth_Beneficiaries_3: any;

   public clientIDHasRoth_Nickname_4: string = '';
   public clientIDHasRoth_AccountValue_4: string = '';
   public clientIDHasRoth_AccountTitle_4: string = '';
   public clientIDHasRoth_Beneficiaries_4: any;

   public clientIDHasRoth_Nickname_5: string = '';
   public clientIDHasRoth_AccountValue_5: string = '';
   public clientIDHasRoth_AccountTitle_5: string = '';
   public clientIDHasRoth_Beneficiaries_5: any;

   public clientIDHasRoth_Nickname_6: string = '';
   public clientIDHasRoth_AccountValue_6: string = '';
   public clientIDHasRoth_AccountTitle_6: string = '';
   public clientIDHasRoth_Beneficiaries_6: any;

   public clientIDHasRoth_Nickname_7: string = '';
   public clientIDHasRoth_AccountValue_7: string = '';
   public clientIDHasRoth_AccountTitle_7: string = '';
   public clientIDHasRoth_Beneficiaries_7: any;

   public clientIDHasRoth_Nickname_8: string = '';
   public clientIDHasRoth_AccountValue_8: string = '';
   public clientIDHasRoth_AccountTitle_8: string = '';
   public clientIDHasRoth_Beneficiaries_8: any;

   public clientIDHasRoth_Nickname_9: string = '';
   public clientIDHasRoth_AccountValue_9: string = '';
   public clientIDHasRoth_AccountTitle_9: string = '';
   public clientIDHasRoth_Beneficiaries_9: any;

   public clientIDHasRoth_Nickname_10: string = '';
   public clientIDHasRoth_AccountValue_10: string = '';
   public clientIDHasRoth_AccountTitle_10: string = '';
   public clientIDHasRoth_Beneficiaries_10: any;


   public clientIDHasRoth_Count: number = 0; // Internal

   /// --> 401(k)
   public clientIDHasAccessTo401KPlan_Nickname_1: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_1: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_1: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_1: any;

   public clientIDHasAccessTo401KPlan_Nickname_2: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_2: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_2: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_2: any;

   public clientIDHasAccessTo401KPlan_Nickname_3: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_3: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_3: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_3: any;

   public clientIDHasAccessTo401KPlan_Nickname_4: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_4: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_4: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_4: any;

   public clientIDHasAccessTo401KPlan_Nickname_5: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_5: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_5: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_5: any;

   public clientIDHasAccessTo401KPlan_Nickname_6: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_6: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_6: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_6: any;

   public clientIDHasAccessTo401KPlan_Nickname_7: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_7: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_7: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_7: any;

   public clientIDHasAccessTo401KPlan_Nickname_8: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_8: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_8: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_8: any;

   public clientIDHasAccessTo401KPlan_Nickname_9: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_9: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_9: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_9: any;

   public clientIDHasAccessTo401KPlan_Nickname_10: string = '';
   public clientIDHasAccessTo401KPlan_AccountValue_10: number = 0;
   public clientIDHasAccessTo401KPlan_AccountTitle_10: string = '';
   public clientIDHasAccessTo401KPlan_Beneficiaries_10: any;

   public clientIDHasAccessTo401KPlan_Count: number = 0; // Internal

   /// --> 457 Plan
   public clientIDHasAccessTo457Plan_Nickname_1: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_1: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_1: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_1: any;

   public clientIDHasAccessTo457Plan_Nickname_2: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_2: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_2: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_2: any;

   public clientIDHasAccessTo457Plan_Nickname_3: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_3: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_3: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_3: any;

   public clientIDHasAccessTo457Plan_Nickname_4: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_4: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_4: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_4: any;

   public clientIDHasAccessTo457Plan_Nickname_5: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_5: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_5: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_5: any;

   public clientIDHasAccessTo457Plan_Nickname_6: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_6: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_6: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_6: any;

   public clientIDHasAccessTo457Plan_Nickname_7: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_7: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_7: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_7: any;

   public clientIDHasAccessTo457Plan_Nickname_8: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_8: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_8: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_8: any;

   public clientIDHasAccessTo457Plan_Nickname_9: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_9: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_9: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_9: any;

   public clientIDHasAccessTo457Plan_Nickname_10: string = '';
   public clientIDHasAccessTo457Plan_AccountValue_10: number = 0;
   public clientIDHasAccessTo457Plan_AccountTitle_10: string = '';
   public clientIDHasAccessTo457Plan_Beneficiaries_10: any;

   public clientIDHasAccessTo457Plan_Count: number = 0; // Internal

   /// --> Brokerage account
   public clientIDHasBrokerageAcct: string = '';
   ///// --> Brokerage account details
   public clientIDHasBrokerageAcct_Nickname_1: string = '';
   public clientIDHasBrokerageAcct_HowIsTitled_1: string = '';
   public clientIDHasBrokerageAcct_AccountBalance_1: number = 0;
   public clientIDHasBrokerageAcct_Beneficiaries_1: any;

   public clientIDHasBrokerageAcct_Nickname_2: string = '';
   public clientIDHasBrokerageAcct_HowIsTitled_2: string = '';
   public clientIDHasBrokerageAcct_AccountBalance_2: number = 0;
   public clientIDHasBrokerageAcct_Beneficiaries_2: any;

   public clientIDHasBrokerageAcct_Nickname_3: string = '';
   public clientIDHasBrokerageAcct_HowIsTitled_3: string = '';
   public clientIDHasBrokerageAcct_AccountBalance_3: number = 0;
   public clientIDHasBrokerageAcct_Beneficiaries_3: any;

   public clientIDHasBrokerageAcct_Nickname_4: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_4: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_4: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_4: any;

  public clientIDHasBrokerageAcct_Nickname_5: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_5: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_5: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_5: any;

  public clientIDHasBrokerageAcct_Nickname_6: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_6: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_6: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_6: any;

  public clientIDHasBrokerageAcct_Nickname_7: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_7: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_7: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_7: any;

  public clientIDHasBrokerageAcct_Nickname_8: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_8: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_8: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_8: any;

  public clientIDHasBrokerageAcct_Nickname_9: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_9: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_9: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_9: any;

  public clientIDHasBrokerageAcct_Nickname_10: string = '';
  public clientIDHasBrokerageAcct_HowIsTitled_10: string = '';
  public clientIDHasBrokerageAcct_AccountBalance_10: number = 0;
  public clientIDHasBrokerageAcct_Beneficiaries_10: any;

   public clientIDHasBrokerageAcct_Count: number = 0; // Internal

   // --> Business
   public clientIDHasABusiness: string = '';
   ///// --> Business details
   public clientIDBusinessName_1: string = '';
   public clientIDBusinessEntity_1: string = '';
   public clientIDBusinessLocation_1: string = '';
   public clientIDBusinessValue_1: number = 0;
   public clientIDBusinessOwnershipPercentage_1: number = 0;

   public clientIDBusinessName_2: string = '';
   public clientIDBusinessEntity_2: string = '';
   public clientIDBusinessLocation_2: string = '';
   public clientIDBusinessValue_2: number = 0;
   public clientIDBusinessOwnershipPercentage_2: number = 0;

   public clientIDBusinessName_3: string = '';
   public clientIDBusinessEntity_3: string = '';
   public clientIDBusinessLocation_3: string = '';
   public clientIDBusinessValue_3: number = 0;
   public clientIDBusinessOwnershipPercentage_3: number = 0;

  public clientIDBusinessName_4: string = '';
  public clientIDBusinessEntity_4: string = '';
  public clientIDBusinessLocation_4: string = '';
  public clientIDBusinessValue_4: number = 0;
  public clientIDBusinessOwnershipPercentage_4: number = 0;

  public clientIDBusinessName_5: string = '';
  public clientIDBusinessEntity_5: string = '';
  public clientIDBusinessLocation_5: string = '';
  public clientIDBusinessValue_5: number = 0;
  public clientIDBusinessOwnershipPercentage_5: number = 0;

  public clientIDBusinessName_6: string = '';
  public clientIDBusinessEntity_6: string = '';
  public clientIDBusinessLocation_6: string = '';
  public clientIDBusinessValue_6: number = 0;
  public clientIDBusinessOwnershipPercentage_6: number = 0;

  public clientIDBusinessName_7: string = '';
  public clientIDBusinessEntity_7: string = '';
  public clientIDBusinessLocation_7: string = '';
  public clientIDBusinessValue_7: number = 0;
  public clientIDBusinessOwnershipPercentage_7: number = 0;

  public clientIDBusinessName_8: string = '';
  public clientIDBusinessEntity_8: string = '';
  public clientIDBusinessLocation_8: string = '';
  public clientIDBusinessValue_8: number = 0;
  public clientIDBusinessOwnershipPercentage_8: number = 0;

  public clientIDBusinessName_9: string = '';
  public clientIDBusinessEntity_9: string = '';
  public clientIDBusinessLocation_9: string = '';
  public clientIDBusinessValue_9: number = 0;
  public clientIDBusinessOwnershipPercentage_9: number = 0;

  public clientIDBusinessName_10: string = '';
  public clientIDBusinessEntity_10: string = '';
  public clientIDBusinessLocation_10: string = '';
  public clientIDBusinessValue_10: number = 0;
  public clientIDBusinessOwnershipPercentage_10: number = 0;


   public clientIDBusiness_Count: number = 0; // Internal

   // --> Life Insurance
   public clientIDHasLTCInsurance: string = '';
   ///// --> Insurance details
   public clientIDHasLTCInsurance_Nickname_1: string = '';
   public clientIDHasLTCInsurance_Owner_1: string = '';
   public clientIDHasLTCInsurance_DeathBenefit_1: number = 0;
   public clientIDHasLTCInsurance_Beneficiaries_1: any;

   public clientIDHasLTCInsurance_Nickname_2: string = '';
   public clientIDHasLTCInsurance_Owner_2: string = '';
   public clientIDHasLTCInsurance_DeathBenefit_2: number = 0;
   public clientIDHasLTCInsurance_Beneficiaries_2: any;

   public clientIDHasLTCInsurance_Nickname_3: string = '';
   public clientIDHasLTCInsurance_Owner_3: string = '';
   public clientIDHasLTCInsurance_DeathBenefit_3: number = 0;
   public clientIDHasLTCInsurance_Beneficiaries_3: any;

  public clientIDHasLTCInsurance_Nickname_4: string = '';
  public clientIDHasLTCInsurance_Owner_4: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_4: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_4: any;

  public clientIDHasLTCInsurance_Nickname_5: string = '';
  public clientIDHasLTCInsurance_Owner_5: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_5: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_5: any;

  public clientIDHasLTCInsurance_Nickname_6: string = '';
  public clientIDHasLTCInsurance_Owner_6: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_6: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_6: any;

  public clientIDHasLTCInsurance_Nickname_7: string = '';
  public clientIDHasLTCInsurance_Owner_7: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_7: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_7: any;

  public clientIDHasLTCInsurance_Nickname_8: string = '';
  public clientIDHasLTCInsurance_Owner_8: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_8: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_8: any;

  public clientIDHasLTCInsurance_Nickname_9: string = '';
  public clientIDHasLTCInsurance_Owner_9: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_9: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_9: any;

  public clientIDHasLTCInsurance_Nickname_10: string = '';
  public clientIDHasLTCInsurance_Owner_10: string = '';
  public clientIDHasLTCInsurance_DeathBenefit_10: number = 0;
  public clientIDHasLTCInsurance_Beneficiaries_10: any;

   public clientIDHasLTCInsurance_Count: number = 0; // Internal

   // --> Student debt
   public clientIDHasStudentLoan: string = '';
   // HasStudentLoan details
   public clientIDHasStudentLoan_Nickname_1: string = '';
   public clientIDHasStudentLoan_LoanAmount_1: number = 0;
   public clientIDHasStudentLoan_WhoLoanIsIt_1: string = '';
   public clientIDHasStudentLoan_InterestRate_1: number = 0;
   public clientIDHasStudentLoan_LenderName_1: string = '';

   public clientIDHasStudentLoan_Nickname_2: string = '';
   public clientIDHasStudentLoan_LoanAmount_2: number = 0;
   public clientIDHasStudentLoan_WhoLoanIsIt_2: string = '';
   public clientIDHasStudentLoan_InterestRate_2: number = 0;
   public clientIDHasStudentLoan_LenderName_2: string = '';

   public clientIDHasStudentLoan_Nickname_3: string = '';
   public clientIDHasStudentLoan_LoanAmount_3: number = 0;
   public clientIDHasStudentLoan_WhoLoanIsIt_3: string = '';
   public clientIDHasStudentLoan_InterestRate_3: number = 0;
   public clientIDHasStudentLoan_LenderName_3: string = '';

  public clientIDHasStudentLoan_Nickname_4: string = '';
  public clientIDHasStudentLoan_LoanAmount_4: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_4: string = '';
  public clientIDHasStudentLoan_InterestRate_4: number = 0;
  public clientIDHasStudentLoan_LenderName_4: string = '';

  public clientIDHasStudentLoan_Nickname_5: string = '';
  public clientIDHasStudentLoan_LoanAmount_5: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_5: string = '';
  public clientIDHasStudentLoan_InterestRate_5: number = 0;
  public clientIDHasStudentLoan_LenderName_5: string = '';

  public clientIDHasStudentLoan_Nickname_6: string = '';
  public clientIDHasStudentLoan_LoanAmount_6: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_6: string = '';
  public clientIDHasStudentLoan_InterestRate_6: number = 0;
  public clientIDHasStudentLoan_LenderName_6: string = '';

  public clientIDHasStudentLoan_Nickname_7: string = '';
  public clientIDHasStudentLoan_LoanAmount_7: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_7: string = '';
  public clientIDHasStudentLoan_InterestRate_7: number = 0;
  public clientIDHasStudentLoan_LenderName_7: string = '';

  public clientIDHasStudentLoan_Nickname_8: string = '';
  public clientIDHasStudentLoan_LoanAmount_8: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_8: string = '';
  public clientIDHasStudentLoan_InterestRate_8: number = 0;
  public clientIDHasStudentLoan_LenderName_8: string = '';

  public clientIDHasStudentLoan_Nickname_9: string = '';
  public clientIDHasStudentLoan_LoanAmount_9: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_9: string = '';
  public clientIDHasStudentLoan_InterestRate_9: number = 0;
  public clientIDHasStudentLoan_LenderName_9: string = '';

  public clientIDHasStudentLoan_Nickname_10: string = '';
  public clientIDHasStudentLoan_LoanAmount_10: number = 0;
  public clientIDHasStudentLoan_WhoLoanIsIt_10: string = '';
  public clientIDHasStudentLoan_InterestRate_10: number = 0;
  public clientIDHasStudentLoan_LenderName_10: string = '';


   public clientIDHasStudentLoan_Count: number = 0; // Internal

   // *** STRANDED *** //
   public clientIDRealEstateList: string = '';
   public clientIDAdditionalInformation: string = '';

   // *** Assets active positions *** arrays as coma strings

   public activePositions_property: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_plan529Account: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_tradIRAAccount: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_rothAccount: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_plan401KAccount: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_plan457Account: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_brokerage: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_business: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_lifeInsurance: string = 'false, false, false, false, false, false, false, false, false, false';
   public activePositions_studentLoan: string = 'false, false, false, false, false, false, false, false, false, false';

   //Extra variables
   //Active assets
   public clientIDactiveAddAssets: boolean = false;

   // Custom prospect questions
   public clientIDCustomQuestion1: string = '';
   public clientIDCustomQuestion2: string = '';
   public clientIDCustomQuestion3: string = '';
   public clientIDCustomQuestion4: string = '';
   public clientIDCustomQuestion5: string = '';
   public clientIDCustomQuestion6: string = '';
   public clientIDCustomQuestion7: string = '';
   public clientIDCustomQuestion8: string = '';
   public clientIDCustomQuestion9: string = '';
   public clientIDCustomQuestion10: string = '';

   constructor() {
      super();
      this._classInitialState = Object.assign({}, this);
   }

   getQName(): string {
      return 'general';
   }

   getHasChildren(): string{
      return this.clientIDChildren;
   }

   getAttributesToClearCommas(): string[] {
      return [
        'clientIDNetWorth',
        'clientIDSpouseNetWorth',
        'cliendIDRealEstateValue_1',
        'clientIDCostBasisOfProperty_1',
        'clientIDPropertyWorth_1',
        'clientIDCurrentLoanBalance_1',
        'cliendIDRealEstateValue_2',
        'clientIDCostBasisOfProperty_2',
        'clientIDPropertyWorth_2',
        'clientIDCurrentLoanBalance_2',
        'cliendIDRealEstateValue_3',
        'clientIDCostBasisOfProperty_3',
        'clientIDPropertyWorth_3',
        'clientIDCurrentLoanBalance_3',
        'cliendIDRealEstateValue_4',
        'clientIDCostBasisOfProperty_4',
        'clientIDPropertyWorth_4',
        'clientIDCurrentLoanBalance_4',
        'cliendIDRealEstateValue_5',
        'clientIDCostBasisOfProperty_5',
        'clientIDPropertyWorth_5',
        'clientIDCurrentLoanBalance_5',
        'cliendIDRealEstateValue_6',
        'clientIDCostBasisOfProperty_6',
        'clientIDPropertyWorth_6',
        'clientIDCurrentLoanBalance_6',
        'cliendIDRealEstateValue_7',
        'clientIDCostBasisOfProperty_7',
        'clientIDPropertyWorth_7',
        'clientIDCurrentLoanBalance_7',
        'cliendIDRealEstateValue_8',
        'clientIDCostBasisOfProperty_8',
        'clientIDPropertyWorth_8',
        'clientIDCurrentLoanBalance_8',
        'cliendIDRealEstateValue_9',
        'clientIDCostBasisOfProperty_9',
        'clientIDPropertyWorth_9',
        'clientIDCurrentLoanBalance_9',
        'cliendIDRealEstateValue_10',
        'clientIDCostBasisOfProperty_10',
        'clientIDPropertyWorth_10',
        'clientIDCurrentLoanBalance_10',
        'clientIDHas529Plan_AccountValue_1',
        'clientIDHas529Plan_AccountValue_2',
        'clientIDHas529Plan_AccountValue_3',
        'clientIDHas529Plan_AccountValue_4',
        'clientIDHas529Plan_AccountValue_5',
        'clientIDHas529Plan_AccountValue_6',
        'clientIDHas529Plan_AccountValue_7',
        'clientIDHas529Plan_AccountValue_8',
        'clientIDHas529Plan_AccountValue_9',
        'clientIDHas529Plan_AccountValue_10',
        'clientIDHasTraditionalIRA_AccountValue_1',
        'clientIDHasTraditionalIRA_AccountValue_2',
        'clientIDHasTraditionalIRA_AccountValue_3',
        'clientIDHasTraditionalIRA_AccountValue_4',
        'clientIDHasTraditionalIRA_AccountValue_5',
        'clientIDHasTraditionalIRA_AccountValue_6',
        'clientIDHasTraditionalIRA_AccountValue_7',
        'clientIDHasTraditionalIRA_AccountValue_8',
        'clientIDHasTraditionalIRA_AccountValue_9',
        'clientIDHasTraditionalIRA_AccountValue_10',
        'clientIDHasAccessTo401KPlan_AccountValue_1',
        'clientIDHasAccessTo401KPlan_AccountValue_2',
        'clientIDHasAccessTo401KPlan_AccountValue_3',
        'clientIDHasAccessTo401KPlan_AccountValue_4',
        'clientIDHasAccessTo401KPlan_AccountValue_5',
        'clientIDHasAccessTo401KPlan_AccountValue_6',
        'clientIDHasAccessTo401KPlan_AccountValue_7',
        'clientIDHasAccessTo401KPlan_AccountValue_8',
        'clientIDHasAccessTo401KPlan_AccountValue_9',
        'clientIDHasAccessTo401KPlan_AccountValue_10',
        'clientIDHasAccessTo457Plan_AccountValue_1',
        'clientIDHasAccessTo457Plan_AccountValue_2',
        'clientIDHasAccessTo457Plan_AccountValue_3',
        'clientIDHasAccessTo457Plan_AccountValue_4',
        'clientIDHasAccessTo457Plan_AccountValue_5',
        'clientIDHasAccessTo457Plan_AccountValue_6',
        'clientIDHasAccessTo457Plan_AccountValue_7',
        'clientIDHasAccessTo457Plan_AccountValue_8',
        'clientIDHasAccessTo457Plan_AccountValue_9',
        'clientIDHasAccessTo457Plan_AccountValue_10',
        'clientIDHasBrokerageAcct_AccountBalance_1',
        'clientIDHasBrokerageAcct_AccountBalance_2',
        'clientIDHasBrokerageAcct_AccountBalance_3',
        'clientIDHasBrokerageAcct_AccountBalance_4',
        'clientIDHasBrokerageAcct_AccountBalance_5',
        'clientIDHasBrokerageAcct_AccountBalance_6',
        'clientIDHasBrokerageAcct_AccountBalance_7',
        'clientIDHasBrokerageAcct_AccountBalance_8',
        'clientIDHasBrokerageAcct_AccountBalance_9',
        'clientIDHasBrokerageAcct_AccountBalance_10',
        'clientIDBusinessValue_1',
        'clientIDBusinessValue_2',
        'clientIDBusinessValue_3',
        'clientIDBusinessValue_4',
        'clientIDBusinessValue_5',
        'clientIDBusinessValue_6',
        'clientIDBusinessValue_7',
        'clientIDBusinessValue_8',
        'clientIDBusinessValue_9',
        'clientIDBusinessValue_10',
        'clientIDHasLTCInsurance_DeathBenefit_1',
        'clientIDHasLTCInsurance_DeathBenefit_2',
        'clientIDHasLTCInsurance_DeathBenefit_3',
        'clientIDHasLTCInsurance_DeathBenefit_4',
        'clientIDHasLTCInsurance_DeathBenefit_5',
        'clientIDHasLTCInsurance_DeathBenefit_6',
        'clientIDHasLTCInsurance_DeathBenefit_7',
        'clientIDHasLTCInsurance_DeathBenefit_8',
        'clientIDHasLTCInsurance_DeathBenefit_9',
        'clientIDHasLTCInsurance_DeathBenefit_10',
        'clientIDHasStudentLoan_LoanAmount_1',
        'clientIDHasStudentLoan_LoanAmount_2',
        'clientIDHasStudentLoan_LoanAmount_3',
        'clientIDHasStudentLoan_LoanAmount_4',
        'clientIDHasStudentLoan_LoanAmount_5',
        'clientIDHasStudentLoan_LoanAmount_6',
        'clientIDHasStudentLoan_LoanAmount_7',
        'clientIDHasStudentLoan_LoanAmount_8',
        'clientIDHasStudentLoan_LoanAmount_9',
        'clientIDHasStudentLoan_LoanAmount_10',
        'clientIDHasStudentLoan_InterestRate_1',
        'clientIDHasStudentLoan_InterestRate_2',
        'clientIDHasStudentLoan_InterestRate_3',
        'clientIDHasStudentLoan_InterestRate_4',
        'clientIDHasStudentLoan_InterestRate_5',
        'clientIDHasStudentLoan_InterestRate_6',
        'clientIDHasStudentLoan_InterestRate_7',
        'clientIDHasStudentLoan_InterestRate_8',
        'clientIDHasStudentLoan_InterestRate_9',
        'clientIDHasStudentLoan_InterestRate_10',
         ];
   }

   getDataTransformations(): DataTransformation[] {
      return [
         // Transform any negative nethworth to positive
         new DataTransformation(
            [
               // 'clientIDNetWorth',
               // 'clientIDSpouseNetWorth',
            ],
            (data: any) => { return Math.abs(data); }
         )
      ]
   }

   private calculateAge(DOB: Date = this.clientIDBirthday): number {
      return utilsCalculateAge(DOB);
   }

   protected beforeToSaveFormat() {

      this.clientIDAge = this.calculateAge(this.clientIDBirthday);
      this.clientIDSpouseAge = this.clientIDSpouseName.trim() ? this.calculateAge(this.clientIDSpouseBirthday) : 0;
      this.clientIDChildOneAge =  this.clientIDChildOneName.trim() ?  this.calculateAge(this.clientIDChildOneBirthday) : 0;
      this.clientIDChildTwoAge = this.clientIDChildTwoName.trim() ? this.calculateAge(this.clientIDChildTwoBirthday) : 0;
      this.clientIDChildThreeAge = this.clientIDChildThreeName.trim() ? this.calculateAge(this.clientIDChildThreeBirthday) : 0;
      this.clientIDChildFourAge = this.clientIDChildFourName.trim() ? this.calculateAge(this.clientIDChildFourBirthday) : 0;
      this.clientIDChildFiveAge = this.clientIDChildFiveName.trim() ? this.calculateAge(this.clientIDChildFiveBirthday) : 0;
      this.clientIDGrandchildOneAge = this.clientIDGrandchildOneName.trim() ? this.calculateAge(this.clientIDGrandChildOneBirthday) : 0;
      this.clientIDGrandchildTwoAge = this.clientIDGrandchildTwoName.trim() ? this.calculateAge(this.clientIDGrandChildTwoBirthday) : 0;
      this.clientIDGrandchildThreeAge = this.clientIDGrandchildThreeName.trim() ? this.calculateAge(this.clientIDGrandChildThreeBirthday) : 0;
      this.clientIDGrandchildFourAge = this.clientIDGrandchildFourName.trim() ? this.calculateAge(this.clientIDGrandChildFourBirthday) : 0;
      this.clientIDGrandchildFiveAge = this.clientIDGrandchildFiveName.trim() ? this.calculateAge(this.clientIDGrandChildFiveBirthday) : 0;
      this.clientIDGrandchildSixAge = this.clientIDGrandchildSixName.trim() ? this.calculateAge(this.clientIDGrandChildSixBirthday) : 0;
      this.clientIDSpouseLastName = (this.clientIDSpouseLastName || '')
      // Only positive networths. Data type is and need to remain as string at this pont
      // clientIDNetWorth = String(this.clientIDNetWorth).replace("-", "");
   }

   protected beforeMappingLoad(args: any[]) {
    args['clientIDSpouseLastName'] = (args['clientIDSpouseLastName'] || '')
   }



}


