import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-relation-table-pdf',
  templateUrl: './relation-table-pdf.component.html',
  styleUrls: ['./relation-table-pdf.component.scss']
})
export class RelationTablePdfComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() data: any;
  @Input() dictionary: any;
  @Input() stylesComparison: boolean = false;

  constructor() { }

  ngOnInit(): void {
    console.log('headers table**', this.headers);
    console.log('data table**', this.data);
    console.log('dictionary table**', this.dictionary);
  }

}
