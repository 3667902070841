import { Questionnaire } from '../Questionnaire.model';

export class life_questions extends Questionnaire {

    //Policy
    public clientIDLIPolicy: string = '';
    public clientIDLIInsuranceCompany: string = '';
    public clientIDKindOfPolicy: string = '';

   //Extractions
    public clientIDWantsToInputPolicyDataLI: string = 'Yes';
    public clientIDClientInsured: string = '';
    public clientIDAnnually: string = '';
    public clientIDLevelPremium: string = '';
    public clientIDPreferredPremium: string = '';
    public clientIDDeathRider: string = '';
    public clientIDDismembermentRider: string = '';
    public clientIDLTCRider: string = '';
    public clientIDLTCWaiver: string = '';
    public clientIDAcceleratedDeathRider: string = '';
    public clientIDWaiverPremium: string = '';
    public clientIDGuaranteedRenewable: string = '';
    public clientIDGuaranteedConversion : string = '';
    public clientIDPolicyLoanRate: string = '';
    public clientIDCashValue: string = '';
    public clientIDPolicyRateReturn: string = '';
    public clientIDGuaranteedInsurability: string = '';
    public clientIDAdvancedBenefit: string = '';
    public clientIDCommonDisaster: string = '';

    public clientIDComclientIDSpouseInsuredmonDisaster: string = '';

    // Questions

    public clientIDSpouseInsured: string = '';
    public clientIDPast40: string = '';
    public clientIDReview: string = '';
    public clientIDMajorEvent: string = '';
    public clientIDMedCondition: string = '';
    public clientIDMoreInsuranceEmployer: string = '';
    public clientIDNeedAdditionalCoverage: string = '';
    public clientIDHealthClass: string = '';
    public clientIDCoverageMoreThanNeed: string = '';
    public clientIDTermNotLong: string = '';
    public clientIDPolicyLapseBefore: string = '';
    public clientIDPolicyLapseAfter : string = '';
    public clientIDBeneficialExchange: string = '';
    public clientIDPurchaseBroker: string = '';
    public clientIDImportantBenefits: string = '';
    public clientIDHealthStatus: string = '';
    public clientIDHadHealthProblem: string = '';
    public clientIDMoreSuitable: string = '';
    public clientIDChangeSituation: string = '';
    public clientIDCarrierLiquidity: string = '';
    public clientIDYoungBeneficiaries: string = '';
    public clientIDMortgageBreadwinner: string = '';
    public clientIDCashEmergency: string = '';
    public clientIDEstateTax: string = '';
    public clientIDEducationFundingST: string = '';
    public clientIDEducationFundingLT: string = '';
    public clientIDLastExpenses: string = '';
    public clientIDIncomeNeedFunding: string = '';
    public clientIDLargeEstateTax: string = '';
    public clientIDFaceAmountExceeds: string = '';
    public clientIDDisabilityUnmanageable: string = '';
    public clientIDPurchasingNeedMore: string = '';
    public clientIDOwnerAndInsured: string = '';
    public clientIDHardPayPremium: string = '';
    public clientIDReviewInvestmentOptions: string = '';
    public clientIDSurrenderCashValue: string = '';
    public clientIDPolicyDividends: string = '';
    public clientIDPolicyProceeds: string = '';
    public clientIDTemporarilyNeedMore: string = '';
    public clientIDSmokerRateNoMore: string = '';
    public clientIDPolicyYear: number = 0;

    //
    public clientIDPolicyPremium: number = 0;
    public clientIDPolicyOwner: string = '';
    public clientIDPolicyInsuredPerson: string = '';
    public clientIDPolicyDeathAmount: number = 0;
    public clientIDPolicyPlanCashValue: number = 0;

   // Progress Bar
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;


   constructor() {
      super();
      this._classInitialState = Object.assign({}, this);
   }

   getQName(): string {
      return 'life';
   }

   getIgnoredAttributesForSave():string[]{
      return [];

   }

   getAttributesToClearCommas(): string[] {
      return [
         'clientIDPolicyPremium',
         'clientIDPolicyDeathAmount',
         'clientIDPolicyPlanCashValue'
      ];
   }
}
