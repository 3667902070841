import { Questionnaire } from '../Questionnaire.model';

export class medical_questions extends Questionnaire {

      public clientIDHasMedicalInsurance: string = '';
      public clientIDAccessToGroupHealthInsurance: string= '';
      // public clientIDAccesstoCOBRAorStateContinuation: string= '';
      public clientIDAccesstoMedicare: string= '';
      public clientIDFertilityIssuesOrSpecialNeeds: string= '';
      public clientIDHasALifePartner: string= '';
      public clientIDAnyChoiceofNetworks: string= '';
      public clientIDYoungFamilyNoMedConditions: string= '';
      public clientIDHighTaxBracket: string= '';
      public clientIDBigMedicalExpenses: string= '';
      public clientIDNeedForSpecialDoctorsOrFacilities: string= '';
      public clientIDLivesInNewJersey: string= '';
      public clientIDOnePersonBusinessOrInBusinessTogether: string= '';
      public clientIDGroupTefraCoverageAvailableToClientOrSpouse: string= '';
      public clientIDDeluxeCoverage: string= '';
      public clientIDMedicalAdvantagePlans: string= '';
      public clientIDDropsGroupCoverageDueToQualificationForMedicare: string= '';
      public clientIDHighDeductibleSupplementPlansMedigap: string= '';
      public clientIDOnlyHaveOneCoveredChildInCollege: string= '';
      public clientIDEmployerGroupCoverage: string= '';
      public clientIDEmployerOffersCoverageForDepedentChildren: string= '';
      public clientIDMarketplaceCoverage: string= '';
      public clientIDHasSpecialNeedsLikeFertility: string= '';
      public clientIDHasChronicKidneyFailure: string= '';
      public clientIDSituationIsNotLifeThreatening: string= '';
      public clientIDClientHasForeignHealthExpenses: string= '';
      public clientIDClientHasOutOfNetworkCoverageEspeciallyWithLargerBill: string= '';
      public clientIDPlanRequiresAuthorizationToSeeSpecialists: string= '';
      public clientIDMajorProceedureOrSurgery: string= '';
      public clientIDMentalHealthOutpatient: string= '';
      public clientIDMentalHealthInpatient: string= '';
      public clientIDHealthcareLowerCostAlternatives: string= '';
      public clientIDHRAEmployer: string= '';
      public clientIDHRAEmployee: string= '';
      public clientIDHRAEmployee2: string= '';
      public clientIDFullTimeMoreEmployees: string= '';
      public clientIDFullTimeFewerEmployees: string= '';
      public clientID50FTE: string= '';
      public clientID25FTE: string= '';
      public clientIDNonWeddedPartnersGroupCoverage: string= '';
      public clientIDPartnerNewJobHealthInsurance: string= '';
      public clientIDTerminateCoverageVoluntarily: string= '';
      public clientIDNonWedEmployee: string= '';
      public clientIDNonWedEmployer: string= '';
      public clientIDElectCovernonWed: string= '';
      public clientIDContributingSpousHealth: string= '';
      public clientIDProofNonWed: string= '';
      public clientIDHealthRequireContribution: string= '';
      public clientIDEmployer100FTE: string= '';
      //New questions via Barbier... balalalala
      public clientIDSHOPApplication: string= '';
      public clientIDPartnerDependentYourHealthInsurance: string= '';
      public clientIDNetworkNational: string= '';
      public clientIDChildMoveGovmtPlan: string= '';
      public clientIDProfessionalReferral: string= '';
      public clientIDPregnantMissedEnroll: string= '';
      public clientIDPregnantOneYearAnother: string= '';
      public clientIDDrugRebates: string= '';
      public clientIDLossGroupCoverage: number = 0;
      public clientIDPregnantOrPlanning: string= '';
      public clientIDFamilySpecialNeedsMentalHealth: string= '';
      public clientIDManyMedicalPlans: string= '';
      public clientIDEmployer250Employees: string= '';
      public clientIDAnesthesiologist: string= '';

      // Progress Bar
      public progressBar: number = 0;
      public progressCompleted: boolean = false;
      public progressTab: number = 0;

      constructor() {
         super()
      }

      getQName(): string {
         return 'medical';
      }
}