import { hidden } from '@angular-devkit/core/src/terminal';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { getRandomStr } from 'app/shared/helpers/utils';
import Chart from 'chart.js';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { duration } from 'moment';

@Component({
  selector: "app-graph-estate",
  templateUrl: "./graph-estate.component.html",
  styleUrls: ["./graph-estate.component.scss"],
})
export class GraphEstateComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: any;
  @Input() idGraph: any;
  @Input() orientation: string;
  @Input() isPrintable: boolean;

  public chart: any;

  public dataSets: any[] = [];
  public dataColors: any[] = [];
  public dataStrokeColors: any[] = [];
  public dataLabels: any[] = [];
  public showGraph: boolean = false;
  public isLabelVisible: boolean = false;
  public idCanvas: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes)
    if (changes.data) {
      console.log("dataChanged in graph**", changes.data);
      this.data = changes.data.currentValue;
      if (this.data) {
        this.prepareDataToChart();
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.data) {
        this.prepareDataToChart();
      }
    }, 300);
  }

  ngOnInit(): void {
    this.idCanvas = getRandomStr(10);
  }

  prepareDataToChart() {
    this.showGraph = false;
    this.dataSets = [];
    this.dataColors = [];
    this.dataLabels = [];

    if (this.data) {
      // console.log('this.dataGraph**', this.data);
      this.data.data.map((item) => {
        this.dataSets.push(item.percentage);
        this.dataColors.push(item.color);
        this.dataStrokeColors.push(item.strokeColor);
        this.dataLabels.push(item.label + " (%)");
      });
      let dataToChart = {};
      dataToChart = {
        datasets: [
          {
            label: "$",
            data: this.dataSets,
            backgroundColor: this.dataColors,
            borderColor: this.dataStrokeColors,
            borderWidth: 1
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: this.dataLabels,
      };
      this.createChart(dataToChart);
    }
    // console.log('datasetGraph**', this.idGraph, this.dataColors, this.dataSets, this.dataLabels);
  }

  createChart(data: any) {
    const ctx = document.getElementById(this.idCanvas);

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: data,
      options: {
        cutoutPercentage: 70,
        /* title:{
            text:'******',
            display: true,
            fontColor: '#FFFFFF',
            fontSize: 16,
            fontStyle: 'bold',
            padding: 0,
            fontFamily: 'Open Sans',
            position: 'bottom'
          }, */
        legend: {
          display: false,
        },
        animation: {
          onComplete: () => {
            this.isLabelVisible = true;
          },
        },
        animations: {
          duration : 0
        }
      },
    });

    // console.log('chart***', this.chart);
  }
}
