import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "ng2-validation";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AdvisorService} from "../../../shared/services/advisor.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

export interface AdvisorSettingsData {
    fullName: string;
    email: string;
    confirmEmail: string;
    advisorPassword: string;
    confirmPassword: string;
    advisorPhoto: string;
    phone: string;
    officePhone: string;
}

@Component({
    selector: 'app-security',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.scss'],
})
export class SecurityComponent implements OnInit {

    public enabled2FA: boolean;
    public advisorData;
    public disabledBtn: boolean = false;
    public disabledActivateBtn: boolean = false;
    public passLevel0: boolean = false;
    public passLevel1: boolean = false;
    public passLevel2: boolean = false;
    public passLevel3: boolean = false;
    public passLevel4: boolean = false;
    public lowerCaseLetter;
    public upperCaseLetter;
    public specialCharacter;
    public least8Characters;
    private pwd;
    public phone: string;
    public phoneWithoutCode: any = 0;

    //Form group initializers

    constructor(
        private router: Router,
        private http: HttpClient,
        public snackBarSuccess: MatSnackBar,
        public snackBarError: MatSnackBar,
        private advisorService: AdvisorService,
    ) {

    }

    /*** Profile info ***/
    password = new FormControl('', [
        Validators.pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&-_.])[A-Za-z\d$@$!%*?&-_.]/),
        Validators.minLength(8),
        Validators.required
    ]);
    confirmPassword = new FormControl('', [CustomValidators.equalTo(this.password), Validators.required]);

    updateAdvisorInfoForm = new FormGroup({
        currentPassword: new FormControl('', Validators.required),
        newPassword: this.password,
        confirmPassword: this.confirmPassword,
    });


    twoStepForm = new FormGroup({
        code: new FormControl(''),
        phone: new FormControl(this.phoneWithoutCode, Validators.required),
        hasEnabled2FA: new FormControl(false)
    });
    async ngOnInit() {
      await this.getAdvisorData().then(()=>{
        if (typeof this.phone !== 'undefined') {
          this.phoneWithoutCode = this.phone.substring(2, 12);
        } else {
          this.phoneWithoutCode = null;
        }
        this.twoStepForm.controls['phone'].setValue(this.phoneWithoutCode);
      });
    }

    async updateAdvisorInfo() {

        let saveAdvisorData = this.updateAdvisorInfoForm.value;
        let twoFactorAuthData = this.twoStepForm.value;

        if (this.enabled2FA) twoFactorAuthData.hasEnabled2FA = false;

        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        let fullPhoneNumber = '+1' + twoFactorAuthData.phone;

        let body = new URLSearchParams();
        body.set('currentPassword', saveAdvisorData.currentPassword);
        body.set('password', saveAdvisorData.newPassword);
        // body.set('officePhone', saveAdvisorData.newPhone);
        body.set('phone', fullPhoneNumber);
        body.set('hasEnabled2FA', twoFactorAuthData.hasEnabled2FA);


        this.http.put<any>(`${environment.apiAdvisorAdvisorProfile}`, body.toString(), options)
            .subscribe(() => {
                    let snackBarRef = this.snackBarSuccess.open("Information updated, the app will reload to apply changes", "Ok", {
                        duration: 10000,
                        panelClass: 'success-snackbar'
                    });

                    this.router.navigate(['/advisor/clients']);

                    setTimeout(() => {
                        this.reload();
                    }, 3000);

                    snackBarRef.onAction().subscribe(() => this.reload());

                    this.advisorService.getAdvisorData()
                        .subscribe(
                            data => {
                                this.advisorData = data;
                                this.advisorData.hasEnabled2FA === true ? this.enabled2FA = true : this.enabled2FA = false;
                            },
                            error => {
                                console.log(error);
                            });

                },
                error => {
                    console.log(error);
                    this.snackBarError.open("Cannot update your data. " + error.error.message, "Dismiss", {
                        duration: 4000,
                        panelClass: 'error-snackbar'
                    });
                }
            );
    }

    reload() {
        window.location.reload();
    }

    passwordChanged() {
        let numSec = 0;
        this.passLevel0 = false;
        this.passLevel1 = false;
        this.passLevel2 = false;
        this.passLevel3 = false;
        this.passLevel4 = false;

        const upperCase = new RegExp("(?=.*[A-Z])");
        const lowerCase = new RegExp("(?=.*[a-z])");
        const spacialCharacter = new RegExp("(?=.*[$@#!%*?&])");

        this.pwd = (<HTMLInputElement>document.getElementById("newPassword")).value;

        upperCase.test(this.pwd) ? this.upperCaseLetter = true : this.upperCaseLetter = false;
        lowerCase.test(this.pwd) ? this.lowerCaseLetter = true : this.lowerCaseLetter = false;
        spacialCharacter.test(this.pwd) ? this.specialCharacter = true : this.specialCharacter = false;
        this.pwd.length >= 8 ? this.least8Characters = true : this.least8Characters = false;

        let securityPass = {
          upper: this.upperCaseLetter,
          lower: this.lowerCaseLetter,
          special: this.specialCharacter,
          least8: this.least8Characters
        }
        Object.values(securityPass).forEach(value => value == true ? numSec++ : '');

        switch (numSec) {
            case 0:
                this.passLevel0 = false;
                this.passLevel1 = false;
                break;
            case 1:
                this.passLevel0 = true;
                this.passLevel1 = true;
                break;
            case 2:
                this.passLevel1 = true;
                this.passLevel2 = true;
                break;
            case 3:
                this.passLevel1 = true;
                this.passLevel3 = true;
                break;
            case 4:
                this.passLevel1 = true;
                this.passLevel4 = true;
                break;
        }
    }

    enableButton($event, text) {
        if (text === 'disable') {
            if ($event.checked == true) {
                
                this.disabledBtn = true;
            } else {
                
                this.disabledBtn = false;
            }
        }

        if (text === 'activate') {
            if ($event.checked == true) {
                
                this.disabledActivateBtn = true;
            } else {
                
                this.disabledActivateBtn = false;
            }
        }

    }

    getAdvisorData() {
        return new Promise((resolve, reject)=>{
          this.advisorService.getAdvisorData()
            .subscribe(
              data => {

                this.advisorData = data;
                
                this.phone = this.advisorData.phone;

                if (this.advisorData.hasEnabled2FA === true) this.enabled2FA = true;

                let today = new Date();
                let endTrialDate = new Date(this.advisorData.freeTrialExpiresOn);
                const diffTime = endTrialDate.getTime() - today.getTime();

                resolve('Success');
              },
              error => {
                console.error(error);
                this.snackBarError.open("Cannot get your data. " + error.error.message, "Dismiss", {
                  duration: 9000,
                  panelClass: 'error-snackbar'
                });
                reject('error');
              });
        })
    }
}
