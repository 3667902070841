import { FileUploaderExtended } from "app/views/questionnaires/FileUploaderExtended.model";
import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ENVIRONMENT_URL_PRODUCTION,
  urlHelperIsInEnvironment,
} from "app/shared/helpers/url.helper";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";
import { FileUploader } from "ng2-file-upload";
import { estate_questions } from "../../q-estate/estate.model";
import { QuestionnaireController } from "../../Questionnaire.controller";
import { CONST_ABSOLUTES } from "../../Questionnaire.constants";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EstatePlanningUploadDialogComponent } from "app/shared/components/estate-planning-upload-dialog/estate-planning-upload-dialog.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { AppSocketIoService } from "app/shared/services/socket.io.service";
import { FileExtractionsResponse } from "../../file-uploader-component/file-uploader-component.component";
import { EstateSuccessDialogComponent } from "../../../../../../estate-success-dialog/estate-success-dialog.component";
import { delay_ms, readFromStoragedObject } from "app/shared/helpers/utils";
import { initPendo } from "app/shared/helpers/pendo.helper";
import { FirstEstateRequestDialogComponent } from "app/shared/components/first-estate-request-dialog/first-estate-request-dialog.component";
import { DialogGenericNoticeComponent } from "app/shared/components/dialog-generic-notice/dialog-generic-notice.component";
import { log } from "console";
import { ClientDataService } from "app/shared/services/client-data-service";

@Component({
  selector: "app-estate-hub",
  templateUrl: "./estate-hub.component.html",
})
export class EstateHubComponent implements OnInit, OnChanges {
  @ViewChild("will", { static: false }) willFile;

  public model: estate_questions = new estate_questions();
  public spouseModel: estate_questions = new estate_questions();
  public tabs: Array<any> = [
    "Upload documents",
    "Estate Snapshot Request",
    "Questionnaires",
  ];
  public tabSelected: number = 0;
  public CONSTANTS = { CONST_ABSOLUTES };
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean;
  public response: any;
  public clientData: any;
  public isAdvisor: boolean;
  public isProduction: boolean;
  public isEarlyTester: boolean = false;
  public loadedStoragedQuestionnaireData: any;
  public areaSelecterFromUrl: string = "estate";
  public props: any = [];
  public hasSpouse: boolean = false;
  public spouseBasicInfo: any;
  public environment = environment;
  public hasGeneralQuest: Boolean = true;
  public disabledRequestSnapshot: Boolean = false;
  public uploaderArray: FileUploaderExtended[] = [];
  public willReady: Boolean = true;
  public revocableReady: Boolean = true;
  public toggleSearchBar: boolean = false;
  public hasAccessToRecs: boolean = false;
  public companyType: string = "legacy";
  public modalUploadingDocs: MatDialogRef<DialogGenericNoticeComponent>;
  public trustMaritalSituation: "Single" | "Joint" = "Single";
  public radioToHide = "none";
  public spouseName: string = "";
  public errorDetected: boolean = false;

  // CLient
  public clientUploadedFilesInfo: any = {
    clientIDWill: null,
    clientIDRevocableTrust: null,
    clientIDFiles: null,
    date: null,
    index: 1,
  };
  public isLoadingClientFiles: boolean = true;
  public clientRequestBody: RequestBody = {
    clientId: "",
    activityType: "estateFileStatus",
    area: "estate",
    activity: {
      clientIDWill: null,
      clientIDRevocableTrust: null,
      clientIDFiles: [],
      requestSnapshot: false,
    },
  };
  public clientLastUpload: any = {
    clientIDWill: null,
    clientIDRevocableTrust: null,
    clientIDFiles: null,
    requestSnapshot: false,
  };
  public clientUploaders: any = {};
  public loadedFilesClient: any = {
    clientIDWill: false,
    clientIDRevocableTrust: false,
  };

  // Spouse
  public spouseUploadedFilesInfo: any = {
    clientIDWill: null,
    clientIDRevocableTrust: null,
    clientIDFiles: null,
    date: null,
    index: 2,
  };
  public isLoadingSpouseFiles: boolean = true;
  public spouseRequestBody: RequestBody = {
    clientId: "",
    activityType: "estateFileStatus",
    area: "estate",
    activity: {
      clientIDWill: null,
      clientIDRevocableTrust: null,
      clientIDFiles: [],
      requestSnapshot: false,
    },
  };
  public spouseLastUpload: any = {
    clientIDWill: null,
    clientIDRevocableTrust: null,
    clientIDFiles: [],
    requestSnapshot: false,
  };
  public spouseUploaders: any = {};
  public loadedFilesSpouse: any = {
    clientIDWill: false,
    clientIDRevocableTrust: false,
  };

  public filesUploadedChecklist: any = {
    client: {},
    spouse: {},
  };

  public testUploader: any;

  public requestSnapshot: boolean = false;
  public clientID: string;
  public requestStatus: string = "noFiles";
  public requestedSnapshot: Boolean = false;
  public isUploading = false;

  // Credits
  public creditsInformation: CreditsInformation = {
    hasCredits: false,
    remaining: 0,
    availableFreePackages: 0,
    freePackages: 0,
    limit: 0,
    daily: {},
  };
  public companyId: string;
  public monthRequests: any = [];
  public hasAgreement: Boolean = false;
  public isAdvisorOnTrial: Boolean = false;
  public show: string = "free";
  public showRed: boolean = false;

  public enableEstate: Boolean = true;
  public trialAndBetaValidation: Boolean = true;
  public noTrialAndBetaValidation: Boolean = true;
  public isSnapshotReady: Boolean = false;
  public sentActivity: Boolean = false;

  constructor(
    public authService: AuthenticationService,
    public qController: QuestionnaireController,
    private router: Router,
    private advisorService: AdvisorService,
    public dialog: MatDialog,
    public http: HttpClient,
    private socketService: AppSocketIoService,
    public snackBarSuccess: MatSnackBar,
    public clientDataService: ClientDataService,
  ) {}

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  addFiles() {
    this.willFile.nativeElement.click();
  }

  ngOnInit() {
    this.hasAccessToRecs =
      this.authService.currentRoleHasAccessTo("planningopps");

    if (this.router.url.includes("s=3")) {
      this.tabSelected = 2;
    }
    this.clientData = JSON.parse(sessionStorage.getItem("currentClient"));
    let sessionToken = localStorage.getItem("sessionToken");
    this.clientID = this.authService.isAdvisor()
      ? this.clientData.clientId
      : localStorage.getItem("userId");
    this.isAdvisor = this.authService.isAdvisor();
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    this.isEarlyTester =
      localStorage.getItem("betaAccess").includes("willSummaryEarlyAccess") ||
      localStorage.getItem("betaAccess").includes("estateSnapshot")
        ? true
        : false;
    if (this.clientData.isMarried === "Married") {
      this.hasSpouse = true;
      this.spouseName = this.clientData.spouse + " ";
      this.spouseName = this.spouseName.concat(
        this.clientData.spouseUsesOwnLastName === "Yes"
          ? this.clientData.spouseLastName
          : this.clientData.lastName
      );

      //this.spouseName = this.clientData.spouse //+ " " + (this.clientData.spouseUsesOwnLastName === 'Yes') ?  this.clientData.spouseLastName : this.clientData.lastName ;
      console.log("clientData", this.clientData, this.clientData.spouse);
      console.log("spouseName", this.spouseName);
    }
    if (!this.hasSpouse) {
      this.radioToHide = "Joint";
    }

    //Update Trust Single/Joint status
    this.advisorService
      .getEstateFilesFromClient(this.clientID)
      .then((response) => {
        let spouseHasTrustDoc: boolean =
          response[1] != undefined &&
          response[1]["data"] != undefined &&
          response[1]["data"]["clientIDRevocableTrust"] != undefined &&
          response[1]["data"]["clientIDRevocableTrust"];

        if (this.hasSpouse && !spouseHasTrustDoc) {
          this.trustMaritalSituation = "Joint";
        }
      });

    // If in the GQ there is no spouseUsesOwnLastName value, set the value to No.
    if (!Boolean(this.clientData.spouseUsesOwnLastName)) {
      this.clientData.spouseUsesOwnLastName = "No";
    }
    this.getHubSubquestionnaires();
    this.getEstateFilesStored(this.clientData.clientId, true)
      .then((response: any) => {})
      .catch((e) => console.log(e));
    this.getAdvisorData().then(() => this.getEstateSnapshotCredits());
    this.getStateSnapshotMetadata();
    this.enableEstate = !this.isAdvisorOnTrial || this.isAdvisorOnTrial;
    this.trialAndBetaValidation = this.isAdvisorOnTrial;
    this.noTrialAndBetaValidation = !this.isAdvisorOnTrial;
    this.hasGeneralQuest = readFromStoragedObject(
      "advisorPreferences",
      "hasGeneralQuest"
    );

    this.pendoInit();
    
    this.validateIfTrial()
    

  }

  /**
   * The function `validateIfTrial` checks if a user is on a trial period based on data stored in local
   * storage and updates certain properties accordingly.
   */
  validateIfTrial(){
    try {
      const onTrial = JSON.parse(localStorage.getItem("advisorInfo")).onTrial
      if(onTrial){
        this.requestSnapshot = true
        this.disabledRequestSnapshot = true
      }
    } catch (error) {
      console.log(error);
    }
  }

  subscribeToFilesListening(fileID) {
    this.socketService.listenToAreaExtractions.push(fileID);
    this.socketService.notificationFileExtractions.subscribe(
      (extractionResponse: any) => {
        let extractionMeta: FileExtractionsResponse =
          extractionResponse.metadata;
        // let componentResponse = data.filter(extractionResponse => { return extractionResponse.fileId == fileID })[0];

        if (
          extractionMeta.fileID == fileID &&
          extractionMeta.validationStatus === "COMPLETE"
        ) {
          if (fileID === "clientIDWill") {
            this.willReady = true;
            this.snackBarSuccess.open(
              "Your Will extractions are ready.",
              "Dismiss",
              {
                duration: 9000,
                verticalPosition: "bottom",
                panelClass: "success-snackbar",
              }
            );
          } else if (fileID === "clientIDRevocableTrust") {
            this.revocableReady = true;
            this.snackBarSuccess.open(
              "Your Revocable Trust extractions are ready.",
              "Dismiss",
              {
                duration: 9000,
                verticalPosition: "bottom",
                panelClass: "success-snackbar",
              }
            );
          }
          if (this.willReady && this.revocableReady) {
            this.requestStatus = "noFiles";
          }
        }
      }
    );
  }

  async selectTab(index: number) {
    this.tabSelected =
      !this.enableEstate && index === 1 ? this.tabSelected : index;

    if (
      this.clientRequestBody.activity.clientIDFiles.length <= 20 &&
      this.spouseRequestBody.activity.clientIDFiles.length <= 20
    ) {
      if (index === 0) {
        this.getEstateSnapshotCredits();
        await this.getEstateFilesStored(this.clientData.clientId)
          .then(() => {})
          .catch((e) => console.log(e));
        if (this.requestStatus !== "hasFiles") {
          this.getStateSnapshotMetadata();
        }
      } else if (index === 1) {
        if (
          this.requestSnapshot &&
          !this.hasAgreement &&
          this.companyType === "legacy"
        ) {
          this.handleValue({ value: true });
        }
        this.getEstateSnapshotCredits();
      }
    } else {
      this.tabSelected = 0;
    }
  }

  async getExtractionResults() {
    try {
      let everythingReady = true;
      const clientAns: any = await this.qController.loadExtractionsResults(
        this.clientData.clientId,
        "estate",
        String(1)
      );
      if (this.clientUploadedFilesInfo.clientIDWill) {
        everythingReady =
          everythingReady &&
          (clientAns.clientIDWill === "EMPTY" ||
            clientAns.clientIDWill === "COMPLETE" ||
            clientAns.clientIDWill === "INVALID");
        this.subscribeToFilesListening("clientIDWill");
        this.willReady = false;
      }
      if (this.clientUploadedFilesInfo.clientIDRevocableTrust) {
        everythingReady =
          everythingReady &&
          (clientAns.clientIDRevocableTrust === "EMPTY" ||
            clientAns.clientIDRevocableTrust === "COMPLETE" ||
            clientAns.clientIDRevocableTrust === "INVALID");
        this.subscribeToFilesListening("clientIDRevocableTrust");
        this.revocableReady = false;
      }
      if (this.hasSpouse) {
        const spouseAns: any = await this.qController.loadExtractionsResults(
          this.clientData.clientId,
          "estate",
          String(2)
        );
        if (
          this.spouseUploadedFilesInfo.clientIDWill &&
          spouseAns.clientIDWill
        ) {
          everythingReady =
            everythingReady &&
            (spouseAns.clientIDWill === "EMPTY" ||
              spouseAns.clientIDWill === "COMPLETE" ||
              spouseAns.clientIDWill === "INVALID");
        }
        if (
          this.spouseUploadedFilesInfo.clientIDRevocableTrust &&
          spouseAns.clientIDRevocableTrust
        ) {
          everythingReady =
            everythingReady &&
            (spouseAns.clientIDRevocableTrust === "EMPTY" ||
              spouseAns.clientIDRevocableTrust === "COMPLETE" ||
              spouseAns.clientIDRevocableTrust === "INVALID");
        }
      }
      if (everythingReady && this.requestStatus !== "hasFiles") {
        this.requestStatus = "noFiles";
      } else if (!everythingReady && this.requestStatus !== "hasFiles") {
        this.requestStatus = "validating";
      }
    } catch (e) {}
  }

  async getHubSubquestionnaires() {
    try {
      const subQuestionnaires =
        await this.advisorService.getHubSubQuestionnares(
          this.clientID,
          this.areaSelecterFromUrl
        );
      this.props = subQuestionnaires;

      // If no questionnaires in these areas are present create the first one automagically
      if (this.props.length === 0) {
        this.openDialog();
      }
      if (this.areaSelecterFromUrl === "estate") {
        await this.validateMaritalStatus();
      }
    } catch (e) {
      console.log("Error getting Hub Questionnaires from Estate: ", e);
    }
  }

  deleteMultiquest(areaId: number, autoCheck: Boolean = false) {
    // Try to apply erase styles to deleted row
    // document.getElementById(this.props[1]._id).style.opacity = '0.35';
    // document.getElementById(this.props[1]._id).style.cursor = 'not-allowed';

    this.advisorService
      .deleteHubSubQuestionnares(
        this.areaSelecterFromUrl,
        this.clientID,
        areaId
      )
      .then((result) => {
        this.props = result;

        this.getHubSubquestionnaires();
      });
  }

  async validateMaritalStatus() {
    let isMarried = readFromStoragedObject('currentClient', 'isMarried', 'Session');
    console.log('validateMaritalStatus', isMarried);
    if (this.props.length === 1 && isMarried === "Married") {
      this.openDialog();
    } else if (this.props.length === 2 && isMarried !== "Married") {
      this.deleteMultiquest(2, true);
    } else if (this.props.length === 0 && isMarried === "Married") {
      setTimeout(() => {
        this.openDialog();
      }, 500);
    }
  }

  async openDialog() {
    if (this.isAssetLimited()) {
      return;
      //Open modal no more space
    }
    await this.addArea();
    return;
  }

  isAssetLimited(): boolean {
    let isMarried = JSON.parse(
      sessionStorage.getItem("currentClient")
    ).isMarried;
    if (
      this.areaSelecterFromUrl == "disability" ||
      this.areaSelecterFromUrl == "ltc" ||
      this.areaSelecterFromUrl == "estate"
    ) {
      if (isMarried === "Married") {
        if (this.props.length >= 2) return true;
      } else if (this.props.length >= 1) {
        return true;
      }
    }
    return false;
  }

  async addArea() {
    try {
      let result;

      if (this.props.length == 0) {
        result = this.clientData.name;
      } else if (this.props.length == 1) {
        result = this.clientData.spouse;
      }

      this.props = await this.advisorService.addHubSubQuestionnares(
        this.clientID,
        this.areaSelecterFromUrl,
        result
      );
    } catch (e) {}
  }

  async getEstateSnapshotCredits() {
    try {
      const {
        availableFreePackages,
        hasCredits,
        remaining,
        freePackages,
        isOnFreeTrialPeriod,
      }: any = await this.advisorService.getEstateSnapshotSummaryCredits(
        this.clientID
      );
      const ans2 = await this.advisorService.getCompanyCreditsV2(
        this.companyId,
        "estate"
      );
      console.log("ANS 2: ", ans2);
      const creditsAns = ans2["estate"];
      this.companyType = creditsAns.uploadModelType;
      const ans = await this.advisorService.getEstateSnapshotRequestsByMonth(
        this.companyId,
        {
          mode: "monthly",
          withDetail: false,
        }
      );
      const ansAct: any = await this.advisorService.getActivity();
      if (ansAct && ansAct.length > 0) {
        this.hasAgreement = true;
      }
      this.monthRequests = ans;
      this.creditsInformation.hasCredits = creditsAns?.remaining > 0;
      this.creditsInformation.remaining = creditsAns?.remaining;
      this.creditsInformation.limit = creditsAns?.total;
      this.creditsInformation.daily = creditsAns?.daily;
      this.creditsInformation.availableFreePackages =
        creditsAns?.free?.remaining;
      this.creditsInformation.freePackages = creditsAns?.free.total;

      console.log(this.creditsInformation);
      console.log("Trial: ", this.trialAndBetaValidation);
      if (
        this.creditsInformation.availableFreePackages > 0 &&
        this.creditsInformation.remaining !== 0
      ) {
        this.show = "free";
      } else if (
        this.creditsInformation.availableFreePackages === 0 &&
        !this.isAdvisorOnTrial
      ) {
        this.show = "count";
        if (this.creditsInformation.remaining === 0) {
          this.showRed = true;
        }
      } else if (
        this.creditsInformation.availableFreePackages === 0 &&
        this.trialAndBetaValidation
      ) {
        this.show = "free";
        if (this.creditsInformation.availableFreePackages === 0) {
          this.showRed = true;
        }
      }

      if (this.creditsInformation.daily.remaining === 0) {
        this.model.cliendIDReviewDocuments = "No";
        this.requestSnapshot = false;
        this.disabledRequestSnapshot = true;
      }
    } catch (e) {
      console.log(e);
    }
  }

  getStateSnapshotMetadata() {
    this.advisorService
      .getEstateSnapshotMetaData(this.clientID)
      .then((response: any) => {
        this.isSnapshotReady =
          response.status != "Not requested" && response.status != "Ready";
        let isFirstTimeSnapshot: boolean = response.status === "Not requested";
        if (this.isSnapshotReady || isFirstTimeSnapshot) {
          this.requestSnapshot = false;
        }
        // Display "You'll be able to upload new documents once the requested Estate Snapshot it's done."
        // <
        if (this.isSnapshotReady) {
          this.requestStatus = "requested";
        }
        if (this.requestStatus !== "requested") {
          this.getExtractionResults();
        }
      });

    /*  No waring "No subir docs":

        Not requested
        Ready

    */
  }

  handleValue({ value }) {
    this.requestSnapshot = value;

    let showPrice: boolean = !(this.isAdvisorOnTrial || this.isEarlyTester);

    if (value && !this.hasAgreement && this.companyType === "legacy") {
      // if (event.value === 'Yes') {
      const dialogRef = this.dialog.open(FirstEstateRequestDialogComponent, {
        width: "804px",
        panelClass: "modal-dialog-questionnaire-estate",
        data: {
          showPrice: showPrice,
        },
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.hasAgreement = true;
        } else {
          this.requestSnapshot = false;
        }
      });
    }
  }

  continueFromBottom() {
    if (!this.enableEstate) {
      this.requestSnapshot = false;
      this.openConfirmationModal(false);
    } else {
      this.tabSelected = 1;
    }
  }

  async changeSummary(event: any) {
    let showPrice: boolean = !(this.isAdvisorOnTrial || this.isEarlyTester);

    if (event.value === "Yes" && !this.hasAgreement) {
      // if (event.value === 'Yes') {
      const dialogRef = this.dialog.open(FirstEstateRequestDialogComponent, {
        width: "804px",
        panelClass: "modal-dialog-questionnaire-estate",
        data: {
          showPrice: showPrice,
        },
      });

      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.hasAgreement = true;
        }
      });
    }
  }

  openEstateSnapshotModal(
    estateRequested: Boolean = true,
    isError: boolean = false,
    error: any = null
  ) {
    let dialogRef;
    const commonData = {
      title: estateRequested
        ? "We have received your request! "
        : "We are processing your documents",
      advise: estateRequested
        ? "The Estate Snapshot is typically delivered between 3-5 business days but could take longer depending on case length and complexity."
        : "It can take up to 5 minutes to receive your extractions.",
    };
    const errorData = {
      title: "Error",
      advise: isError ? error.message : "",
      error: true,
    };
    dialogRef = this.dialog.open(EstateSuccessDialogComponent, {
      data: isError ? errorData : commonData,
      panelClass: `modal-dialog-questionnaire-estate`,
      width: "804px",
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === "to opportunities") {
        this.resetRequestBody();
        if (this.hasSpouse) {
          this.resetRequestBody(true);
        }
        await this.getEstateFilesStored(this.clientData.clientId, true);
        this.tabSelected = 2;
      } else if (result === "to retry") {
        this.submitFiles();
      } else {
        this.router.navigate(["hub"]);
      }
    });
  }

  async getAdvisorData() {
    try {
      const data: any = await this.advisorService.getAdvisorDataPromise();
      this.companyId = data.companyId;
      this.isAdvisorOnTrial =
        data.isOnFreeTrialPeriod != undefined ? data.isOnFreeTrialPeriod : true;
    } catch (e) {
      console.log(e);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  resetRequestBody(spouse: boolean = false) {
    this[spouse ? "spouseRequestBody" : "clientRequestBody"] = {
      clientId: "",
      activityType: "estateFileStatus",
      area: "estate",
      activity: {
        clientIDWill: null,
        clientIDRevocableTrust: null,
        clientIDFiles: [],
        requestSnapshot: false,
        ...(!spouse ? { jointOrSingle: this.trustMaritalSituation } : {}),
      },
    };
  }

  openConfirmationModal(e: any) {
    const dialogConfig = new MatDialogConfig();
    const aux = {
      ...this.clientRequestBody.activity,
      requestSnapshot: this.requestSnapshot,
    };

    let estateRequested = aux.requestSnapshot;
    delete aux.requestSnapshot;

    dialogConfig.data = {
      client: {
        will: this.clientRequestBody.activity.clientIDWill,
        trust: this.clientRequestBody.activity.clientIDRevocableTrust,
        files: this.clientRequestBody.activity.clientIDFiles,
      },
      spouse: {
        will: this.spouseRequestBody.activity.clientIDWill,
        trust: this.spouseRequestBody.activity.clientIDRevocableTrust,
        files: this.spouseRequestBody.activity.clientIDFiles,
      },
      snapshot: {
        requested: estateRequested,
      },
    };
    dialogConfig.panelClass = "modal-dialog-questionnaire-estate";
    dialogConfig.width = "80vw";

    const dialogRef = this.dialog.open(
      EstatePlanningUploadDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data) {
          this.submitFiles();
        }
      },
      (error) => {}
    );
  }

  async requestEstateSnapshot(index) {
    try {
      let options = {
        headers: new HttpHeaders().set(
          "Content-Type",
          "application/x-www-form-urlencoded"
        ),
      };
      const res = await this.http
        .post<any>(
          `${environment.apiRequestSummary}/?idcli=${this.clientID}&index=${index}`,
          {},
          options
        )
        .toPromise();
    } catch (e) {
      if (e.status === 409) {
        localStorage.setItem("estate-error", "Yes");
      }
      throw e;
    }
  }

  async submitFiles() {
    this.openModalUploadingDocs();
    try {
      this.isUploading = true;
      this.authService.refreshTokenJob.reset(); // Reset the renew token timer
      await this.authService
        .renewToken()
        .toPromise()
        .then(async (renewTokenResponse) => {
          await delay_ms(500); // Wait for everything to settle down
          // this.loadedFiles.clientIDWill = false;
          // this.loadedFiles.clientIDRevocableTrust = false;
          console.log("REVISANDO SI TIENE ARCHIVOS EL CLIENTE");
          if (Object.keys(this.clientUploaders).length > 0) {
            console.log("SI TIENE ARCHIVOS EL CLIENTE");
            await this.handleClientAndSpouseSumit();
          }
          if (this.hasSpouse && Object.keys(this.spouseUploaders).length > 0) {
            await this.handleClientAndSpouseSumit(
              "spouseRequestBody",
              "spouseUploaders",
              2
            );
          }
          await this.consumeFileUploader();
          this.requestStatus = "noFiles";
        });
    } catch (error) {
      console.log("Error");
      this.isUploading = false;
      this.modalUploadingDocs.close();
      this.errorDetected = true;
      this.showRenewError();
    }
  }

  showRenewError() {
    let dialogRef;
    dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      data: {
        title:
          '<div class="flex items-center"><img width="52px" src="https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/00_General/33_Warning.svg" alt="Warning"><label class="ml-12">File processing error</label></div>',
        body: '<p class="mt-12 fz-2">An error occurred while processing your files. Please refresh the page and upload your files again. If the issue persists please <a href="mailto:support@fpalpha.com">contact support</a>.</p>',
        actionButtons: [
          {
            text: "Try again",
            class: "button-primary",
            action: () => {
              dialogRef.close();
              this.router.navigate(["/hub"]);
            },
          },
        ],
        buttonsContainerStyles: {
          display: "flex",
          "flex-direction": "row-reverse",
          "margin-top": "12px",
        },
      },
      panelClass: `modal-dialog-questionnaire-estate-error`,
      width: "50vw",
    });
    dialogRef.afterClosed().subscribe(async (result) => {});
  }

  public activityForRequest: any = {};
  public activityForRequestSpouse: any = {};

  async handleClientAndSpouseSumit(
    whoIs: string = "clientRequestBody",
    whoIsUploaders: string = "clientUploaders",
    index: number = 1
  ) {
    console.log("Index en el manejo de archivos: ", index);
    try {
      const auxBody: any = {
        activity: {},
      };
      this[whoIs].activity.requestSnapshot = this.requestSnapshot;
      Object.keys(this[whoIs]).forEach((key) => {
        if (key === "activity") {
          Object.keys(this[whoIs].activity).forEach((innerKey) => {
            auxBody.activity[
              innerKey === "clientIDFiles" ? "clientIDOtherEstate" : innerKey
            ] =
              innerKey === "clientIDFiles"
                ? this[whoIs].activity[innerKey].map(({ name }) => name)
                : innerKey === "requestSnapshot"
                ? this[whoIs].activity[innerKey]
                : this[whoIs].activity[innerKey]
                ? this[whoIs].activity[innerKey].name
                : null;
          });
        } else {
          auxBody[key] = this[whoIs][key];
        }
      });

      auxBody.activity.index = index;
      if (index === 1) {
        auxBody.activity.jointOrSingle = this.trustMaritalSituation;
        this.activityForRequest = auxBody;
      } else {
        this.activityForRequestSpouse = auxBody;
      }

      let onUploaderError = (item, response, status, headers) => {
        console.log("onUploaderError detected");
        this.errorDetected = true;
        if (this.modalUploadingDocs) {
          this.modalUploadingDocs.close();
        }
        this.showRenewError();
      };

      Object.keys(this[whoIsUploaders]).forEach(async (key) => {
        if (this[whoIsUploaders][key]) {
          //Assign an error behavior to the uploder
          this[whoIsUploaders][key].onErrorItem = onUploaderError;
          //On complete uploader, consume the next uploader
          this[whoIsUploaders][key].onCompleteAll = async () => {
            await this.consumeFileUploader();
          };
          //
          this.uploaderArray.push(this[whoIsUploaders][key]);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async consumeFileUploader() {
    if (this.errorDetected) {
      return;
    }
    let nextUploader = this.uploaderArray.shift();
    if (!this.sentActivity) {
      this.sentActivity = true;
      if (
        this.activityForRequest.activity &&
        this.activityForRequest.activity.index === 1 &&
        this.activityForRequest.clientId
      ) {
        await this.advisorService.postEstateFilesFromClient(
          this.activityForRequest
        );
      }
      if (
        this.activityForRequestSpouse.activity &&
        this.activityForRequestSpouse.activity.index === 2 &&
        this.activityForRequestSpouse.clientId
      ) {
        await this.advisorService.postEstateFilesFromClient(
          this.activityForRequestSpouse
        );
      }
    }
    if (nextUploader) {
      //Use current session token
      console.log("Update uploader to use current session token");
      nextUploader.refreshTokenUrl(localStorage.getItem("sessionToken"));

      nextUploader.options.queueLimit == undefined
        ? nextUploader.uploadAllFiles()
        : nextUploader.uploadAll();
    } else {
      this.modalUploadingDocs.close();
      this.isUploading = false;
      console.log("ACTIVIty: ", this.activityForRequest);
      const creditsActivity: any = {
        ...(this.activityForRequest ? this.activityForRequest : {}),
        activity: {
          uploadType: "upload",
        },
        activityType: "uploadCredit",
        area: "estate",
        clientId: this.clientID,
      };
      if (
        this.activityForRequest.activity &&
        this.activityForRequest.activity.index === 1 &&
        this.activityForRequest.clientId
      ) {
        creditsActivity.activity.documents = {
          ...creditsActivity.activity.documents,
          [this.activityForRequest.activity.index]: {
            ...this.activityForRequest.activity,
          },
        };
      }
      if (
        this.activityForRequestSpouse.activity &&
        this.activityForRequestSpouse.activity.index === 2 &&
        this.activityForRequestSpouse.clientId
      ) {
        creditsActivity.activity.documents = {
          ...creditsActivity.activity.documents,
          [this.activityForRequestSpouse.activity.index]: {
            ...this.activityForRequestSpouse.activity,
          },
        };
      }
      if (this.requestSnapshot) {
        creditsActivity.activity.uploadType = "snapshot";
      }
      console.log("ACTIVIDAD DE CREDITOS: ", creditsActivity);
      if (this.requestSnapshot && this.requestStatus !== "requested") {
        if (
          Object.keys(this.clientUploaders).length > 0 ||
          Object.keys(this.spouseUploaders).length > 0
        ) {
          await this.requestEstateSnapshot(1);
        }

        if (
          this.hasSpouse &&
          (Object.keys(this.clientUploaders).length > 0 ||
            Object.keys(this.spouseUploaders).length > 0)
        ) {
          await this.requestEstateSnapshot(2);
        }
        console.log(
          "ACTIVIDAD DEL REQUEST 1: ",
          this.activityForRequest,
          this.activityForRequestSpouse
        );
        console.log(
          "ACTIVIDAD DEL REQUEST 2: ",
          this.activityForRequest,
          this.activityForRequestSpouse
        );
        await this.advisorService.postEstateActivityFromClient(creditsActivity);
        this.getEstateSnapshotCredits();
        this.openEstateSnapshotModal();
        this.sentActivity = false;
      } else {
        console.log("CREDITS ACTIVITY: ", creditsActivity);
        await this.advisorService.postEstateActivityFromClient(creditsActivity);
        this.openEstateSnapshotModal(false, false);
        this.sentActivity = false;
      }

      //
    }
  }

  setFilesToUser({
    index,
    fileKey,
    uploader: { uploader, ...otherUploader },
    trustStatus,
  }) {
    this.testUploader = otherUploader;
    if (index === 1) {
      this.trustMaritalSituation = trustStatus || this.trustMaritalSituation;
      this.setFiles("clientRequestBody", "clientUploaders", fileKey, uploader);
    } else {
      this.setFiles("spouseRequestBody", "spouseUploaders", fileKey, uploader);
    }
  }

  setJoinOrSingle(information) {
    this.trustMaritalSituation = information.trustStatus || "Joint";
    this.clientUploadedFilesInfo.jointOrSingle = this.trustMaritalSituation;
  }

  setFiles(whoIsRequest, whoIsUploaders, fileKey, uploader) {
    this[whoIsRequest].clientId = this.clientData.clientId;
    if (fileKey === "clientIDFiles") {
      this[whoIsRequest].activity[fileKey] = uploader.queue.map(
        (element) => element.file.rawFile
      );
    } else {
      this[whoIsRequest].activity[fileKey] = uploader.queue[0].file.rawFile;
    }
    this[whoIsUploaders][fileKey] = uploader;
    if (whoIsUploaders.includes("spouse")) {
      this.filesUploadedChecklist["spouse"][fileKey] = false;
    } else {
      this.filesUploadedChecklist["client"][fileKey] = false;
    }
    if (
      this.spouseRequestBody.activity.clientIDFiles.length > 0 ||
      this.spouseRequestBody.activity.clientIDWill ||
      this.spouseRequestBody.activity.clientIDRevocableTrust ||
      this.clientRequestBody.activity.clientIDFiles.length > 0 ||
      this.clientRequestBody.activity.clientIDWill ||
      this.clientRequestBody.activity.clientIDRevocableTrust
    ) {
      this.requestStatus = "hasFiles";
    } else {
      this.requestStatus = "noFiles";
    }
  }

  async getEstateFilesStored(clientId: string, firstTime = false) {
    try {
      const ans: any = await this.advisorService.getEstateFilesFromClient(
        clientId
      );
      if (ans) {
        ans.forEach(({ date, data: { index, ...infoUser } }) => {
          if (index === 1) {
            if (infoUser.jointOrSingle) {
              this.trustMaritalSituation = infoUser.jointOrSingle;
            }
            console.log("HGEY: ", this.trustMaritalSituation);
            this.clientUploadedFilesInfo = {
              ...infoUser,
              date,
              index,
            };
          } else {
            this.spouseUploadedFilesInfo = {
              ...infoUser,
              date,
              index,
            };
          }
          if (firstTime) {
            this.requestSnapshot = infoUser.requestSnapshot;
          }
        });
      }
      this.isLoadingClientFiles = false;
    } catch (e) {
      throw new Error(e);
    }
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  openModalUploadingDocs() {
    console.log("Hellooo");
    this.modalUploadingDocs = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: "modal-uploading-files",
      width: "450px",
      data: {
        titleClass: "title",
        title: "Loading Documents...",
        body: `<strong>Please wait</strong><br><br>
              <img width="55px" src="/assets/images/fp-alpha-blue.gif" />`,
        actionButtons: [],
      },
    });
  }

  /**
   * @name pendoInit
   * @description Initializes the Pendo script if a token was provided. This script reads the props set on advisorInfo inside Local storage.
   * @param none
   */
  pendoInit() {
    console.log("Initializing Pendo on Estate Hub...💿");
    initPendo();
  }

  openSecurityPolicy() {
    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: false,
      panelClass: "modal-dialog-white-no-padding",
      width: "60vw",
      data: {
        title: "Security policy ",
        buttonsContainerClass: "buttons",
        body:
          "Per our security policy, we permanently delete all documents from our server once the required information is extracted. This means, " +
          "if you uploaded a Will or Trust in the past, we CANNOT generate a Snapshot or recommendations from it.<br>" +
          "Please upload the documents again, and request a snapshot through the Snapshot Tab.",
        actionButtons: [
          {
            text: "Understood",
            class: "button-secondary",
            style: "min-height: 40px; min-width: 110px",
            action: () => {
              dialogRef.close();
            },
          },
        ],
      },
    });
  }

  firstInfo() {
    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: false,
      panelClass: "modal-dialog-white-no-padding",
      width: "60vw",
      data: {
        title: "Important notice! ",
        buttonsContainerClass: "buttons",
        body:
          "We cannot read documents that are password-protected. <br> You can remove the protection and upload the document " +
          "or send the document securely to support@fpalpha.com (including the password) so we can upload it for you. <br><br>" +
          "For best results, upload a native pdf of your documents. (We can also read scans, photographs or screenshots of paper " +
          "statements, but prefer native pdf’s if available.) Click here to see an example.",
        actionButtons: [
          {
            text: "Got it!",
            class: "button-secondary",
            style: "min-height: 40px; min-width: 110px",
            action: () => {
              dialogRef.close();
            },
          },
        ],
      },
    });
  }
}

interface RequestBody {
  clientId: string;
  activityType: string;
  area: string;
  activity: {
    clientIDWill: File;
    clientIDRevocableTrust: File;
    clientIDFiles: Array<File>;
    requestSnapshot: boolean;
    jointOrSingle?: string;
  };
}

interface CreditsInformation {
  hasCredits: boolean;
  remaining: number;
  availableFreePackages: number;
  freePackages: number;
  limit: number;
  daily: any;
}
