//Angular
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

//RX
import { map, take } from 'rxjs/operators';

//Project
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardClient implements CanActivate {
    public authToken;

    constructor(private router: Router, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if(this.authService.isSessionUserTypeValid('client')){
            return true
        } else{
            this.router.navigate(['/client/signin']);
            return false;
        }
        
    }
}