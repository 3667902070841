import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentDetailDialogComponent } from 'app/shared/components/payment-detail-dialog/payment-detail-dialog.component';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AppSocketIoService } from 'app/shared/services/socket.io.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  public subscriptionInvoices: any = [];

  public invoicesForm: FormGroup;
  public submitting = false;
  public currentMonth: any;


  constructor(
    public formBuilder: FormBuilder,
    private advisorService: AdvisorService,
    public dialog: MatDialog,
    public snackBarError: MatSnackBar,
    public snackBarSuccess: MatSnackBar,
    private socketService: AppSocketIoService
  ) {
    this.invoicesForm = this.formBuilder.group({
      invoices: this.formBuilder.array([]),
    });
   }

  ngOnInit(): void {
    this.getSubscriptionInvoices();
    this.socketService.notificationInvoicePaid.subscribe((data: any) => {
      if (data.metadata.paid === true) {
        let message = '';
        if (data.metadata.paymentMethodType === 'wire' || data.metadata.paymentMethodType === 'check') {
          message = 'Your scheduled payments are set. Please check your e-mail for more information.';
        } else {
          message = 'Invoice paid successfully.';
        }
        this.snackBarSuccess.open(message, 'Dismiss', {
          duration: 10000,
          panelClass: 'success-snackbar',
        });
      } else {
        this.snackBarError.open(`Error while paying this invoice. Please try again later or contact support.`, 'Dismiss', {
          duration: 10000,
          panelClass: 'error-snackbar',
        });
        this.subscriptionInvoices.find(i => i._id === data.metadata.invoiceId).paymentStage = undefined;
      }
    });
  }

  get invoices() {
    return this.invoicesForm.get('invoices') as FormArray;
  }

  async downloadInvoice (id, title) {
    try {
      const ans: any = await this.advisorService.downloadInvoice(id)
      
      saveAs(ans, `${title}.pdf`);
    } catch(e) {
      console.log(e);
      this.snackBarError.open(
        "There was an error on payment process.",
        "Dismiss",
        {
          duration: 9000,
          verticalPosition: "top",
          panelClass: "error-snackbar"
        }
      );
    }
  }

  async getSubscriptionInvoices() {
    this.advisorService.getSubscriptionInvoices()
      .subscribe(data => {
        
        if (data !== 'NO_STRIPE') {
          this.subscriptionInvoices = data.invoices;
          this.subscriptionInvoices.forEach((invoice, index) => {
            this.invoices.push(this.formBuilder.group({
              paymentPlan: [invoice.paymentPlan || '', Validators.required],
              paymentMethodType: [invoice.paymentMethod ||'', Validators.required],
            }));
          });
          this.submitting = false;
        }
      });
      const aux: any = JSON.parse(localStorage.getItem('advisorInfo'));
    this.currentMonth = await this.advisorService.getCurrentMonthEstate(aux.companyId);
  }

  showInvoiceDetail (index = -1) {
    if (index == -1) {
      
      this.dialog.open(PaymentDetailDialogComponent, {
        panelClass: 'modal-dialog-review',
        width: '70%',
        data: {
          data: {
            title: 'Estate Snapshot - Current month',
            items: this.currentMonth.requests,
            total: this.currentMonth.total,
          },
        }
      });
    } else {
      
    const dialogRef = this.dialog.open(PaymentDetailDialogComponent, {
      panelClass: 'modal-dialog-review',
      width: '70%',
      data: {
        data: this.subscriptionInvoices[index],
        download: () => this.downloadInvoice(this.subscriptionInvoices[index]._id, this.subscriptionInvoices[index].title),
      }
    });
    }

  }

  async payInvoice(id, index) {
    try {
      this.submitting = true;
      
      const { paymentPlan, paymentMethodType } = this.invoices.value[index];
      
      await this.advisorService.payInvoice(id, paymentPlan, paymentMethodType);
      
      this.getSubscriptionInvoices();
    } catch(e) {
      console.log(e);
      this.snackBarError.open(
        "There was an error on payment process.",
        "Dismiss",
        {
          duration: 9000,
          verticalPosition: "top",
          panelClass: "error-snackbar"
        }
      );
    }
  }

}
