import { _collapseOp } from "./utils";

abstract class StorageController{

  public data: any;

  constructor(
    public storageType: ('LOCAL'|'SESSION'),
    public persistAfterSession: boolean = true
    ){

    }

}

class AdvisorPreferences extends StorageController{

  public data: AdvisorPreferences;

  constructor(private _data: AdvisorPreferences){
    super('LOCAL');
    this.data = _data;
    this.disclaimer = _collapseOp(_data.disclaimer, 'Default disclaimer');
  }

}

interface AdvisorPreferences{

  disclaimer: string;
  hasGeneralQuest: boolean;
  notifications: boolean;
  saveHistory: boolean;
  sendAccessToClient: boolean;
  shareClients: boolean;
  shareClientsWith: string[];
  showActionsOnReport: boolean;
  syncTaskmanagerAndRedtail: boolean;
  syncTaskmanagerAndSalesforce: boolean;
  visibleTaskManager: boolean;

}

export function isDisabledAdvisorFeature(feature: ('prospect' | 'clientPortal')): boolean{
  let disabledFeatures = JSON.parse(localStorage.getItem('dFeatures'));
  let isDisabled: boolean = disabledFeatures.includes(feature);
  return isDisabled;
}
