import { Questionnaire } from '../Questionnaire.model';

export class divorce_questions extends Questionnaire {

    public clientIDCurrentState: string = '';
    public clientIDYearsMarriage: number = 0;
    public clientIDMaritalHome: string = '';
    public clientIDHasPrenup: string = '';
    public clientIDHasPostnup: string = '';
    public clientIDSpouseSupport: string = '';
    public clientIDAnticipateDivorce: string = '';
    public clientIDMonitorAccounts: string = '';
   //  public clientIDDivisionAssets: string = '';
    public clientIDHiddenAssets: string = '';
    public clientIDModifyChildSupport: string = '';
    public clientIDClaimTaxBenefit: string = '';
    public clientIDAnticipateInheritance: string = '';
    public clientIDHasLifeInsurance: string = '';
    public clientIDNewIRA: string = '';
    public clientIDWithdrawalNeeded: string = '';
    public clientIDCOBRACoverage: string = '';
    public clientIDResidenceCapGainExclusion: string = '';
    public clientIDAgreementEnded: string = '';
    public clientIDDivorceDecree: string = '';
    public clientIDTrackSupport: string = '';
    public clientIDPaidExHealth: string = '';
    public clientIDUpdatePolicies: string = '';
    public clientIDHasJointAccounts: string = '';
    public clientIDSplitJointAccounts: string = '';
    public clientIDWishPrenup: string = '';
    public clientIDSecondMarriage: string = '';
    public clientIDDiscussedPrenup: string = '';
    public clientIDSpouseJoin: string = '';
    public clientIDCombinedAGI203: string = '';
    public clientIDParticipateWorkRetirement: string = '';
   //  public clientIDMoreToIRAs: string = '';
    public clientIDFundIrrTrust: string = '';
    public clientIDCommingleAssets: string = '';
    public clientIDTaxEffectMarriage: string = '';
    public clientIDSignificantAssets: string = '';
    public clientIDHighEarner: string = '';
    public clientIDPrivateBusiness: string = '';
    public clientIDPriorProtection: string = '';
    public clientIDPreMarrySituation: string = '';
    public clientIDBusinessPriorMarry: string = ''; 
    public clientIDDebtPriorMarry: string = ''; 
    public clientIDPlanCollege: string = ''; 
    public clientIDIncomeDisparity: string = ''; 
    public clientIDLaterInLife: string = ''; 
    public clientIDSameHouse: string = ''; 
    public clientIDRetireSavings: string = ''; 
    public clientIDOtherAssets: string = ''; 
    public clientIDWorkAndRetirement: string = ''; 
    public clientIDHealthCare: string = ''; 
    public clientIDEstateDocumentsInPlace: string = '';
    public clientIDEstateDocuments: string = ''; 
    public clientIDEstateChanges: string = ''; 
    public clientIDSpousesWealth: string = '';
    public clientIDLivedCommunity: string = '';
    public clientIDTitledCommunity: string = '';
    public clientIDRefinanceMortgage: string = '';
    public clientIDAffordExpenses: string = '';
    public clientIDAgreeHomeTime: string = '';
    public clientIDHomeBigWealth: string = '';
    public clientIDHomePurchase: string = '';
    public clientIDExpensesAndMortgage: string = '';
    public clientIDLienHELOC: string = '';
    public clientIDPromissoryNote: string = '';
    public clientIDLegalFees: string = '';
    public clientIDOwnsLifeInsurance: string = '';
    public clientIDOutstandingLoans: string = '';
    public clientIDCarryForwarsSchedD: string = '';
    public clientIDPrepayTaxes: string = '';
    public clientIDTaxCalculation: string = '';
    public clientIDEduSavingPlan: string = '';
    public clientIDEduNotUsed: string = '';
    public clientIDEduRequirements: string = '';
    public clientIDSpouseInsured: string = '';
    public clientIDLINewBeneficiaries: string = '';
    public clientIDSpouseOnMortgage: string = '';
    public clientIDLifeInsurancePayor: string = '';
    public clientIDLISpouseSupport: string = '';
    public clientIDLIDependent: string = '';
    public clientIDLIValue: string = '';
    public clientIDLIRidPolicy: string = '';
    public clientIDDetailsAgreement: string = '';
    public clientIDAssetBalance: string = '';
    public clientIDFinPlanChanged: string = '';
    public clientIDReviewPlan: string = '';
    public clientIDAdvisorCommunication: string = '';
    public clientIDRiskProfiles: string = '';
    public clientIDMixAssets: string = '';
    public clientIDGoals: string = '';
    public clientIDAspiraions: string = '';
    public clientIDTimeHorizon: string = '';
    public clientIDRetirementOutlook: string = '';
    public clientIDSSPlan: string = '';
    public clientIDOtherMarriage: string = '';
    public clientIDChangedLivingSituation: string = '';
    public clientIDHasDisabilityInsurance: string = '';
    public clientIDDisabBeneficiary: string = '';
    public clientIDTaxEffectDivorce: string = '';
    public clientIDSeparatedMonths: string = '';
    public clientIDReduceExpense: string = '';
    public clientIDWithdrawPortfolio: string = '';
    public clientIDAffectCredit: string = '';
    public clientIDRemarry: string = '';
    public clientIDCloselHeldBusiness: string = '';
    public clientIDHighBusiness: string = '';
    public clientIDMinorityOwner: string = '';
    public clientIDChildPreviousMarriage: string = '';
    public clientIDChildSupportTerms: string = '';
    public clientID529Plans: string = '';
    public clientID529Owner: string = '';
    public clientIDChangeBeneficiary: string = '';
    public clientIDDuplicateStatements: string = '';
    public clientIDFundsUnused: string = '';
    public clientIDSpousalIndividualBenefit: string = '';
    // public clientIDEstateDocuments: string = '';
    public clientIDReviewdBeneficiaries: string = '';
    public clientIDAnticipatesDivisionOfWorkplaceRetPlan: string = '';
    public clientIDIsInSameWorkplaceInsurancePlan: string = '';
    public clientIDDivorcePolicy: string= 'No';
    public clientIDQDRODone: string = '';
    public clientIDQDROAssetsStillInPlan: string = '';
    public clientIDKeepPreviousIRA: string = '';
    public clientIDBigPremaritalIRA: string = '';

   // Progress Bar
   public progressBar: number = 0;
   public progressCompleted: boolean = false;
   public progressTab: number = 0;


   constructor() {
      super()
   }

   getQName(): string {
      return 'divorce';
   }

   getIgnoredAttributesForSave():string[]{
      return [''];
      
   }

   getAttributesToClearCommas(): string[] {
      return [
         
      ];
   }
}
