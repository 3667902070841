import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";
import { InsuranceServiceService } from "../insurance-service.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AddPropertyDialogComponent } from "../../hub-multi-area/add-property-dialog/add-property-dialog.component";
import {
  FOUND_AREAS,
  FoundAreasObject,
  HIDE_TABS_FROM_SECTIONS,
} from "../insurance-constants";
@Component({
  selector: "app-insurance-list",
  templateUrl: "./insurance-list.component.html",
  styleUrls: ["./insurance-list.component.scss"],
})
export class InsuranceListComponent implements OnInit, OnDestroy {
  @Output() public selectedCardIndex = new EventEmitter<string>();

  public clientData: any;
  public clientHasAnswered: any;
  public clientID: any;
  public area: string = "home";
  public observable: Subscription;
  public insuranceList: any = [];
  public requestStatus: string = "Not requested";
  public uploading: boolean = false;
  public buttonTitle: string = "";

  constructor(
    private authService: AuthenticationService,
    private advisorService: AdvisorService,
    private insuranceHubService: InsuranceServiceService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.uploading = true;
    this.area = this.insuranceHubService.getType();
    this.buttonTitle = this.area === "life" ? "policy" : this.area;
    this.clientData = JSON.parse(sessionStorage.getItem("currentClient"));
    this.clientHasAnswered = JSON.parse(
      sessionStorage.getItem("clientHasAnsweredQuestionnaires")
    );

    // Get client data
    this.clientID = this.authService.isAdvisor()
      ? this.clientData.clientId
      : localStorage.getItem("userId");
    // this.getListInformation();
    this.insuranceHubService.getDataFromService(this.area);
    this.observable = this.insuranceHubService.getList.subscribe((list) => {
      this.insuranceList = list;
    });
    if (!HIDE_TABS_FROM_SECTIONS.includes(this.area)) {
      this.getSnapshotStatus();
    } else {
      this.uploading = false;
    }
  }

  setSelectedCardIndex(index: any) {
    this.selectedCardIndex.emit(index);
  }

  ngOnDestroy(): void {
    this.observable.unsubscribe();
  }

  async getSnapshotStatus() {
    try {
      const ans: any = await this.advisorService.statusSnapshotData(
        this.clientID,
        this.area
      );
      const { status }: any = ans;
      if (
        status === "Pending" ||
        status === "In review" ||
        status === "On Hold"
      ) {
        this.requestStatus = "validating";
      } else {
        this.requestStatus = status;
      }
      // this.loadingInformation = false;
      this.uploading = false;
    } catch (e) {
      console.log(e);
      this.uploading = false;
    }
  }

  public foundArea: FoundAreasObject = FOUND_AREAS;

  async addItemToList() {
    const dialogRef = this.dialog.open(AddPropertyDialogComponent, {
      width: "250px",
      data: {
        mainObjectDescription: this.foundArea[this.area].mainObjectDescription,
        title: "",
        area: this.area,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const ans = await this.advisorService.addHubSubQuestionnares(
        this.insuranceHubService.clientId,
        this.area,
        result
      );
      await this.insuranceHubService.saveAnsweredFormQuestions(
        this,
        true,
        ans[ans?.length - 1].index
      );
      await this.insuranceHubService.getDataFromService(this.area);
    });
  }
}
