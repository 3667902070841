import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { nFormatter } from "../utils";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";

@Component({
  selector: "app-roth-conversion-override-modal",
  templateUrl: "./roth-conversion-override-modal.component.html",
  styleUrls: ["./roth-conversion-override-modal.component.scss"],
})
export class RothConversionOverrideModalComponent implements OnInit {
  public overrideIncomeForm: FormGroup = new FormGroup({
    age: new FormControl(""),
    amount: new FormControl(""),
  });
  public ammountHashMap: Map<any, any> = new Map();
  public originalAmmountHashMap: Map<any, any> = new Map();
  public years: any = [];
  public barChartData: ChartDataSets[] = [];
  public barChartDataLabels: any[] = [];
  public barChartLegend = true;
  public barChartType: ChartType = "bar";
  public modified: boolean = false;
  public modifiedAmount: boolean = false;
  public barChartColors: any[] = [
    {
      borderColor: "#4BAF50",
      backgroundColor: "#4BAF50",
    },
  ];
  public barChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            "$" +
            Number(tooltipItem.yLabel)
              .toFixed(0)
              .replace(/./g, function (c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0
                  ? "," + c
                  : c;
              })
          );
        },
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            fontColor: "rgb(200, 200, 200)",
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#C8C8C8",
            callback: (value, index, values) => {
              return `$${nFormatter(value, 1)}`;
            },
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<RothConversionOverrideModalComponent>
  ) {}

  ngOnInit(): void {
    console.log("DATA DE MODAL: ", this.data);

    this.years = this.data.basePeriods.map(
      ({ age, original, modified, year }, index) => {
        this.ammountHashMap.set(year, modified);
        this.originalAmmountHashMap.set(year, original);
        this.barChartData.push(modified);
        this.barChartDataLabels.push(age);
        return {
          value: year,
          label: age,
        };
      }
    );
    this.barChartData = [
      {
        data: Array.from(this.ammountHashMap.values()),
        label: "Income",
        backgroundColor: "#4BAF50",
        hoverBackgroundColor: "#4BAF50",
        borderColor: "#4BAF50",
        hoverBorderColor: "#4BAF50",
        fill: false,
      },
    ];
    this.overrideIncomeForm.get("age").setValue(this.years[0].value);
    this.overrideIncomeForm
      .get("amount")
      .setValue(this.ammountHashMap.get(this.years[0].value));
    this.overrideIncomeForm.get("amount").valueChanges.subscribe((value) => {
      this.modifiedAmount = true;
    });
  }

  handleAgeChange(e: any) {
    this.overrideIncomeForm.get("age").setValue(e.value);
    this.overrideIncomeForm
      .get("amount")
      .setValue(this.ammountHashMap.get(e.value));
    this.modifiedAmount = false;
  }

  setAmountToYear(value: any) {
    this.ammountHashMap.set(this.overrideIncomeForm.value.age, Number(value));
    this.modified = true;
  }

  resetContent() {
    this.ammountHashMap = new Map(this.originalAmmountHashMap.entries());
    this.overrideIncomeForm
      .get("amount")
      .setValue(this.ammountHashMap.get(this.overrideIncomeForm.value.age));
    this.modified = false;
    this.modifiedAmount = false;
    this.updateChart();
  }

  apply() {
    this.setAmountToYear(Number(this.overrideIncomeForm.value.amount));
    this.updateChart();
    this.modifiedAmount = false;
  }

  updateChart() {
    this.barChartData = [
      {
        data: Array.from(this.ammountHashMap.values()),
        label: "Income",
        backgroundColor: "#4BAF50",
        hoverBackgroundColor: "#4BAF50",
        borderColor: "#4BAF50",
        hoverBorderColor: "#4BAF50",
        fill: false,
      },
    ];
  }

  save() {
    const conversionAmountTransformed = Object.fromEntries(
      this.ammountHashMap.entries()
    );
    this.dialog.close({
      ...(this.modified ? { conversionAmountTransformed } : {}),
    });
  }
}
