import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { nFormatter } from "../utils";

@Component({
  selector: "app-roth-year-graph-modal",
  templateUrl: "./roth-year-graph-modal.component.html",
  styleUrls: ["./roth-year-graph-modal.component.scss"],
})
export class RothYearGraphModalComponent implements OnInit {
  public graphData: any;
  public tooltips: any;
  public data2: any;
  public displayedColumns: string[] = [
    "year",
    "age",
    "taxBracket",
    "noConversion",
    "withConversion",
    "savingsImpact",
  ];
  public formatter: any = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  public cleanedData: any;
  public key: string = "traditionalIRAValueEOY";
  public conversionGraphColors: any = {
    backgroundColor: "rgba(74, 122, 255, 0.5)",
    hoverBackgroundColor: "rgba(74, 122, 255, 0.5)",
    borderColor: "rgba(74, 122, 255, 1)",
    hoverBorderColor: "rgba(74, 122, 255, 1)",
  };
  public noConversionGraphColors: any = {
    backgroundColor: "rgba(255, 147, 30, 0.5)",
    hoverBackgroundColor: "rgba(255, 147, 30, 0.5)",
    borderColor: "rgba(255, 147, 30, 0.5)",
    hoverBorderColor: "rgba(255, 147, 30, 0.5)",
  };
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: any[] = [];
  public lineChartLegend = true;
  public lineChartType: ChartType = "line";
  public lineChartColors: any[] = [
    this.conversionGraphColors,
    this.noConversionGraphColors,
  ];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: this.labelFormatter,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            fontColor: "rgb(200, 200, 200)",
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#C8C8C8",
            callback: (value, index, values) => {
              return `$${nFormatter(value, 1)}`;
            },
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
    colours: [
      {
        fillColor: "rgba(33, 139, 253, 1)",
        strokeColor: "rgba(33, 139, 253, 1)",
        highlightFill: "rgba(33, 139, 253, 1)",
        highlightStroke: "rgba(33, 139, 253, 1)",
        backgroundColor: "rgba(33, 139, 253, 1)",
        borderColor: "rgba(33, 139, 253, 1)",
      },
      {
        fillColor: "rgba(215, 234, 255, 1)",
        strokeColor: "rgba(215, 234, 255, 1)",
        highlightFill: "rgba(215, 234, 255, 1)",
        highlightStroke: "rgba(215, 234, 255, 1)",
        backgroundColor: "rgba(215, 234, 255, 1)",
        borderColor: "rgba(215, 234, 255, 1)",
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<RothYearGraphModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tooltips = data.tooltips;
    this.data2 = data.graphData;
    this.cleanedData = this.data2.map(
      ({ age, year, conversion, noConversion }) => {
        return {
          age,
          year,
          originalConversion: {
            ...conversion,
          },
          originalNoConversion: {
            ...noConversion,
          },
          conversion: {
            traditionalIRAValueEOY: this.formatter.format(
              conversion.traditionalIRAValueEOY
            ),
            taxableAcctEOY: this.formatter.format(conversion.taxableAcctEOY),
            taxesPaidDueToTaxableAcctWithdrawal: this.formatter.format(
              conversion.taxesPaidDueToTaxableAcctWithdrawal
            ),
            conversionAmount: this.formatter.format(
              conversion.conversionAmount
            ),
            taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal:
              this.formatter.format(
                conversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal
              ),
            marginalIncomeTaxRateString: conversion.marginalIncomeTaxRateString,
          },
          noConversion: {
            traditionalIRAValueEOY: this.formatter.format(
              noConversion.traditionalIRAValueEOY
            ),
            taxableAcctEOY: this.formatter.format(noConversion.taxableAcctEOY),
            taxesPaidDueToTaxableAcctWithdrawal: this.formatter.format(
              noConversion.taxesPaidDueToTaxableAcctWithdrawal
            ),
            taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal:
              this.formatter.format(
                noConversion.taxableIncomeWithRMDAndWithdrawalFromIRAAndTaxablePortionFromTaxableAccountWithdrawal
              ),
            marginalIncomeTaxRateWithMedicareTaxString:
              noConversion.marginalIncomeTaxRateWithMedicareTaxString,
          },
        };
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.buildGraphData();
  }

  changeKey(key) {
    this.key = key;
    this.buildGraphData();
  }

  buildGraphData() {
    this.lineChartLabels = [];
    this.lineChartData = [];
    const auxConversion = [];
    const auxNoConversion = [];
    this.cleanedData.forEach(
      ({ age, originalConversion, originalNoConversion }) => {
        this.lineChartLabels.push(age);
        if (this.key === "conversionAmount") {
          auxConversion.push(originalConversion[this.key]);
        } else {
          auxConversion.push(originalConversion[this.key]);
          auxNoConversion.push(originalNoConversion[this.key]);
        }
      }
    );
    this.lineChartData.push({
      data: auxConversion,
      label: "Conversion",
      pointRadius: 3,
      pointBackgroundColor: "rgba(74, 122, 255, 1)",
      pointBorderColor: "#ffffff",
    });
    this.lineChartData.push({
      data: auxNoConversion,
      label: "No conversion",
      pointRadius: 3,
      pointBackgroundColor: "rgba(255, 147, 30, 0.5)",
      pointBorderColor: "#ffffff",
    });
  }

  labelFormatter(tooltipItem, data) {
    return (
      "$" +
      Number(tooltipItem.yLabel)
        .toFixed(0)
        .replace(/./g, function (c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );
  }
}
