import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { general_questions } from '../q-general/general.model';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { property_questions } from './property.model';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
  selector: 'app-q-property',
  templateUrl: './q-property.component.html',
  styleUrls: ['./q-property.component.scss']
})
export class QPropertyComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('propertyform', { static: true }) propertyform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: property_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;

  public bulletColor;

  public generalData: general_questions = new general_questions();

  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    private currentClientService: ClientService
  ) { }

  ngOnInit() {

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Initialize the questionnaire model
    this.model = new property_questions();

    //Get context, clientHasAnswered?
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansProperty;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
      console.log('DATA: ', data);
      if (data?.personalInformation) {
        const answers = data?.personalInformation?.data;
        answers.forEach(({ name, answer }) => {
          this.generalData[name] = answer === 'undefined' || !answer ? '' : answer
        })
      }
    })

    // if(this.generalData.clientIDPrimaryResidence === "Own"){
    //   this.model.clientIDHomewoner == 'Yes';
    // }else if(this.generalData.clientIDPrimaryResidence === "Rent"){
    //   this.model.clientIDHomewoner == 'No';
    // }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'property', this.model).then(response => {
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  async ngAfterViewInit() {
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {

    this.qController.submitForm(this, this.model, this.clientData.clientId, 'property', saveForLater, null, silentSave);

  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };


      this.http.get(`${this.pdfUrl}${'property'}`, options)
      .subscribe(blob => {
        saveAs(blob, 'property-questionnaire.pdf');
      });

  }
}
