import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'multi-table-component',
  templateUrl: 'multi-table.component.html',
  styleUrls: ['./multi-table.component.scss']
})

export class MultiTableDataComponent implements OnInit{

  @Input() data: MultiTableDataInterface | any;
  @Input() pdfColor: any;

  public internalData: MultiTableDataInterface | any;
  public fontSizeDivs;

  public dataTemplate: MultiTableDataInterface = {
    componentType: '',
    id: '',
    title: '',
    columns: [[{headers: [''], rows: [{text: ['']}], subtitle: ''}]]
  }

  constructor() {
  }

  ngOnInit() {
    this.data = this.dataTemplate;
  }

  /**
   *
   * @param changes
   * On data change, make internall calculations to display columns, lines and colors.
   */
  ngOnChanges(changes: SimpleChanges) {

    if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }

    // Copy the incoming data into a new object to not modify the original data source.
    this.internalData = JSON.parse(JSON.stringify(changes.data.currentValue))
  }

  generateCanvas(width): HTMLElement {

    //Creo div container
    var div = document.createElement('div');
    div.id = 'container';
    div.className = 'border pad';


    div.style.width = width + 'px';

    //Obtengo elemento de extractions y hago una copia
    const content: Element = document.getElementById('table-full-container');
    var content2 = <HTMLElement> content.cloneNode(true);

    //Se lo agrego al div container
    div.appendChild(content2);

    //Cambio color a negro de tr
    let title: NodeListOf<HTMLElement> = content2.querySelectorAll('tr');
    for(var i = 0; i<title.length;i++){
      title[i].style.setProperty("color", "black", "important");
      title[i].style.fontSize = '12px';
    }

    //Cambio font size de notas
    let notes: NodeListOf<HTMLElement> = content2.querySelectorAll('p');
    for(var i = 0; i<notes.length;i++){
      notes[i].style.setProperty("color", "black", "important");
      notes[i].style.fontSize = '10px';
    }


    return content2;

  }

}

interface MultiTableDataInterface {
    componentType?: string,
    id: string,
    title?: string,
    columns?: any;
    tables?: { headers?: string[], rows?: {text: string[]}[], subtitle?: string }[],
  }
