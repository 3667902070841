import {taxfull_questions} from './taxfull-model';
import {CONST_IRAS} from '../Questionnaire.constants';

export interface FieldMetadataInterface {

  tabSection: string, // Tab where it belongs
  subsection: (string | null) // A section inside the tab, like sch C
  description:((isAdvisor, model?: taxfull_questions)=>string), // The main question
  type: ('SELECT' | 'MONEY' | 'LABEL' | 'RADIO_GROUP' | 'SCH_B' | 'FLAG_ONLY'), // The type of field
  sourceForSelect?: any, // If the type is select, the source of data is specified
  availableInYears: number[], // Filter the question depending upon the selected year
  isExtraction?: boolean, // Applies a filter to retrieve the data from the back after the extractions are ready
  linesByYear?: Map<number, string>, // In case of extractions, the correlation between the year and the line wording
  displayRuleFunction?: ((model: taxfull_questions, instance?: any) => boolean) // Function that displays or hide the field

}

export interface SectionsCatalogInterface {
  id: string,
  name: string,
  subsections?: SubSectionsCatalogInterface[],
  displayRuleFunction?: ((model: taxfull_questions, instance?: any) => boolean)
}
export interface SubSectionsCatalogInterface extends SectionsCatalogInterface{
  isExpandedRuleFunction?: ((model: taxfull_questions, instance?: any) => boolean)
}

export const TAX_SECTIONS_CATALOG: SectionsCatalogInterface[] = [
  {
    id: 'extractions',
    name: 'Tax return input',
    subsections: [
      {
        id: 'required', name: 'Required info',
        isExpandedRuleFunction: (model, instance) => {
          return !instance.clientData.hasGeneralQuest
        }
      },
      {
        id: 'form1040', name: 'Form 1040',
        isExpandedRuleFunction: (model, instance) => {
          return true
        }
      },
      {
        id: 'sch1', name: 'Schedule 1',
        isExpandedRuleFunction: (model, instance) => {
          console.log('HOLA MODELO: ', model.clientHasSchedule1 === 'Yes', instance.showSchOne);
          return (model.clientHasSchedule1 === 'Yes' || instance.showSchOne)
        }
      },
      {
        id: 'sch2', name: 'Schedule 2',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasSchedule2 === 'Yes' )
        }
      },
      {
        id: 'sch3', name: 'Schedule 3',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasSchedule3 === 'Yes')
        }
      },
      {
        id: 'schA', name: 'Schedule A',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleA === 'Yes')
        }
      },
      {
        id: 'schB1', name: 'Schedule B part 1',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleB === 'Yes')
        }
      },
      {
        id: 'schB2', name: 'Schedule B part 2',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleB === 'Yes')
        }
      },
      {
        id: 'schC', name: 'Schedule C',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleC === 'Yes')
        }
      },
      {
        id: 'schD', name: 'Schedule D',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleD === 'Yes')
        }
      },
      {
        id: 'schE', name: 'Schedule E',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleE === 'Yes')
        }
      },
      {
        id: 'schSE', name: 'Schedule SE',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasScheduleSE === 'Yes')
        }
      },
      /* {id: 'schE', name: 'Schedule E'}, */ //Labeled as others
      /* {id: 'schSE', name: 'Schedule SE'}, */ //Labeled as others
      /* {id: 'form8995', name: 'Form 8995'}, */ //Labeled as others
      {
        id: 'form8582', name: 'Form 8582',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasForm8582 === 'Yes')
        },
        displayRuleFunction: (model, instance) => {
          return true; //!instance.isProduction;
        },
      },
      {
        id: 'form8995', name: 'Form 8995',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasForm8995 === 'Yes')
        }
      },
      {
        id: 'form8995a', name: 'Form 8995 A',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasForm8995A === 'Yes')
        }
      },
      /* {id: 'form1099', name: 'Form 1099'}, */ //Labeled as others
      {
        id: 'form5498', name: 'Form 5498',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasForm5498 === 'Yes')
        }
      },

      /* {id: 'form8880', name: 'Form 8880'}, */ //Labeled as others
      /* {id: 'form8880', name: 'Form 8880'}, */ //Labeled as others
      {id: 'form709', name: 'Form 709',
        isExpandedRuleFunction: (model, instance) => {
          return (model.clientHasForm709 === 'Yes')
        }},
      /* {id: 'form2441', name: 'Form 2441'},*/ //Labeled as others
      {
        id: 'others', name: 'Others',
        isExpandedRuleFunction: (model, instance) => {
          return true
        }
      },
    ],
    displayRuleFunction: (model, instance) => true
  },
  {
    id: 'ira',
    name: 'IRA',
    subsections: [],
    //displayRuleFunction: (model, instance) => instance.clientHasBusiness === 'Yes' && model.clientIDTypeOfAnalysis === 'Indepth analysis' || model.scheduleCDetected === 'Yes' && model.clientIDTypeOfAnalysis === 'Indepth analysis'
    displayRuleFunction: (model, instance) =>  (model.clientIDTypeOfAnalysis === 'Indepth analysis') && (instance.clientHasBusiness === 'Yes' || model.scheduleCDetected === 'Yes')
  },
  {
    id: 'income',
    name: 'Income',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'businessOwner',
    name: 'Business Owner',
    subsections: [],
    //displayRuleFunction: (model, instance) => instance.clientHasBusiness === 'Yes' && model.clientIDTypeOfAnalysis === 'Indepth analysis' || model.scheduleCDetected === 'Yes' && model.clientIDTypeOfAnalysis === 'Indepth analysis'
    displayRuleFunction: (model, instance) => ((model.clientIDTypeOfAnalysis === 'Indepth analysis') && ( model.scheduleCDetected === 'Yes' || instance.clientHasBusiness === 'Yes'))
  },
  {
    id: 'charityAndGifts',
    name: 'Charity and gifts',
    subsections: [],
    displayRuleFunction: (model, instance) =>  model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'investment',
    name: 'Investment',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis' && model.clientIDOwnAnyStocks === 'Yes'
  },
  {
    id: 'employer',
    name: 'Employer',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis' && !instance.clientIsRetired
  },
  {
    id: 'retirement',
    name: 'Retirement',
    subsections: [
      {id: 'common', name: ''},
      {id: 'underAge59', name: 'Under age 59 ½ and wish to:',  displayRuleFunction: (model, instance) => {return instance.clientAge < 60 && !instance.clientIsRetired}},
      {id: 'underAge72', name: 'Under age 72, but continue to work for an employer in which is not an owner of more than 5% of the company (directly or through attribution)',
        displayRuleFunction: (model, instance) => {return instance.clientAge >= 72 && !instance.clientIsRetired}
      },
    ],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'socialSecurity',
    name: 'Social Security',
    subsections: [],
    displayRuleFunction: (model, instance)=> model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.clientAge > 50 || (model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Married Filing Jointly')
  },
  {
    id: 'family',
    name: 'Family',
    subsections: [],
    displayRuleFunction: (model, instance)=> model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.clientMaritalStatus === 'Married' || model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.clientHasChildren ==='Yes'
  },
  {
    id: 'children',
    name: 'Children',
    subsections: [],
    displayRuleFunction: (model, instance)=> model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.showChildrenSection
  },
  {
    id: 'tuition',
    name: 'Tuition',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'lending',
    name: 'Lending',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'other',
    name: 'Other',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis'
  },
  {
    id: 'advisor',
    name: 'Advisor section',
    subsections: [],
    displayRuleFunction: (model, instance) => model.clientIDTypeOfAnalysis === 'Indepth analysis' && instance.isAdvisor
  }
]

export const FieldsMetadata: Map<string, FieldMetadataInterface> = new Map([
  /*START TAB EXTRACTIONS*/
  /*START FORM 1040 QUESTIONS*/
  ['livingState',
    {
      tabSection: 'extractions',
      subsection: 'required',
      description:(isAdvisor)=>{ return isAdvisor ? 'In which state does the client live?': 'In which state do you live?'},
      type: 'SELECT',
      sourceForSelect: 'CONST_STATES_KEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, ''], [2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => {
        return !instance.clientData.hasGeneralQuest
      }
    }
  ],
  ['wages',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Wages'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 1'], [2020, 'Form 1040, Line 1'], [2021, 'Form 1040, Line 1'], [2022, 'Form 1040, Line 1a'], [2023, 'Form 1040, Line 1a'] ]),
      displayRuleFunction: (q, instance) => { return true }
    }
  ],
  [ 'householdWages',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Household employee wages'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1b'], [2023, 'Form 1040, Line 1b'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'tipIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Tip income'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1c'], [2023, 'Form 1040, Line 1c'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'medicalWaiverPayments',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Medicaid waiver payments'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1d'], [2023, 'Form 1040, Line 1d'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'taxableDependentBenefits',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Taxable dependent care benefits'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1e'], [2023, 'Form 1040, Line 1e'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'employerAdoptionBenefits',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Employer-provided adoption benefits'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1f'], [2023, 'Form 1040, Line 1f'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'wagesFrom8919',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Wages from form 8919'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1g'], [2023, 'Form 1040, Line 1g'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'otherEarnedIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Other earned income'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1h'], [2023, 'Form 1040, Line 1h'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  [ 'sum1a1h',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Add lines 1a through 1h'},
      type: 'MONEY',
      availableInYears:[2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2022, 'Form 1040, Line 1z'], [2023, 'Form 1040, Line 1z'] ]),
      displayRuleFunction: (q)=>{ return true}
    }
  ],
  ['taxExemptInterest',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Tax-exempt interest'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 2a'], [2020, 'Form 1040, Line 2a'], [2021, 'Form 1040, Line 2a'], [2022, 'Form 1040, Line 2a'], [2023, 'Form 1040, Line 2a'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['taxInterest',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Taxable interest'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 2b'], [2020, 'Form 1040, Line 2b'], [2021, 'Form 1040, Line 2b'], [2022, 'Form 1040, Line 2b'], [2023, 'Form 1040, Line 2b'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['qualifiedDividends',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Qualified dividends'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 3a'], [2020, 'Form 1040, Line 3a'], [2021, 'Form 1040, Line 3a'], [2022, 'Form 1040, Line 3a'], [2023, 'Form 1040, Line 3a'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['totalDividends',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Ordinary dividends'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 3b'], [2020, 'Form 1040, Line 3b'], [2021, 'Form 1040, Line 3b'], [2022, 'Form 1040, Line 3b'], [2023, 'Form 1040, Line 3b'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['iraDistribution',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'IRA distributions'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 4a'], [2020, 'Form 1040, Line 4a'], [2021, 'Form 1040, Line 4a'], [2022, 'Form 1040, Line 4a'], [2023, 'Form 1040, Line 4a'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['taxableIRA',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'IRA distributions taxable amount'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 4b'], [2020, 'Form 1040, Line 4b'], [2021, 'Form 1040, Line 4b'], [2022, 'Form 1040, Line 4b'], [2023, 'Form 1040, Line 4b'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['pension',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Pensions and annuities'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 4c'], [2020, 'Form 1040, Line 5a'], [2021, 'Form 1040, Line 5a'], [2022, 'Form 1040, Line 5a'], [2023, 'Form 1040, Line 5a'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['taxablePension',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Pensions and annuities taxable amount.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 4d'], [2020, 'Form 1040, Line 5b'], [2021, 'Form 1040, Line 5b'], [2022, 'Form 1040, Line 5b'], [2023, 'Form 1040, Line 5b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['socialSecurityBenefits',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Social security benefits.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 5a'], [2020, 'Form 1040, Line 6a'], [2021, 'Form 1040, Line 6a'], [2022, 'Form 1040, Line 6a'], [2023, 'Form 1040, Line 6a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['socialSecurity',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Social security benefits taxable amount.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 5b'],  [2020, 'Form 1040, Line 6b'], [2021, 'Form 1040, Line 6b'], [2022, 'Form 1040, Line 6b'], [2023, 'Form 1040, Line 6b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['capitalGainOrLoss',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Capital gain or (loss).'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 6'], [2020, 'Form 1040, Line 7'], [2021, 'Form 1040, Line 7'], [2022, 'Form 1040, Line 7'], [2023, 'Form 1040, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Other income.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 7a'], [2020, 'Form 1040, Line 8'], [2021, 'Form 1040, Line 8'], [2022, 'Form 1040, Line 8'], [2023, 'Form 1040, Line 8'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Total income.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 7b'], [2020, 'Form 1040, Line 9'], [2021, 'Form 1040, Line 9'], [2022, 'Form 1040, Line 9'], [2023, 'Form 1040, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['adjustedIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Adjustments to income.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 8a'], [2020, 'Form 1040, Line 10c'], [2021, 'Form 1040, Line 10'], [2022, 'Form 1040, Line 10'], [2023, 'Form 1040, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['adjustedGrossIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Adjusted gross income.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 8b'], [2020, 'Form 1040, Line 11'], [2021, 'Form 1040, Line 11'], [2022, 'Form 1040, Line 11'], [2023, 'Form 1040, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['standardDeductions',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Standard deductions or itemized deductions.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 9'], [2020, 'Form 1040, Line 12'], [2021, 'Form 1040, Line 12a'], [2022, 'Form 1040, Line 12'], [2023, 'Form 1040, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['charitableContributions1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Charitable contributions'},
      type: 'MONEY',
      availableInYears: [2020, 2021],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 10b'], [2021, 'Form 1040, Line 12b']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sumStandardAndCharitable',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Standard deductions and charitable contributions'},
      type: 'MONEY',
      availableInYears: [2021],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 1040, Line 12c']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qbIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Qualified business income deductions'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 10'], [2020, 'Form 1040, Line 13'], [2021, 'Form 1040, Line 13'], [2022, 'Form 1040, Line 13'], [2023, 'Form 1040, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sumDeductions',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Sum of deductions'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 11a'], [2020, 'Form 1040, Line 14'], [2021, 'Form 1040, Line 14'], [2022, 'Form 1040, Line 14'], [2023, 'Form 1040, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxableIncome',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Taxable income.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 11b'], [2020, 'Form 1040, Line 15'], [2021, 'Form 1040, Line 15'], [2022, 'Form 1040, Line 15'], [2023, 'Form 1040, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxes',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Taxes'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 12a'], [2020, 'Form 1040, Line 16'], [2021, 'Form 1040, Line 16'], [2022, 'Form 1040, Line 16'], [2023, 'Form 1040, Line 16'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line17_1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Amount from schedule 2 line 3'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 17'], [2021, 'Form 1040, Line 17'], [2022, 'Form 1040, Line 17'], [2023, 'Form 1040, Line 17'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line18_1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Add lines 16 and 17'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 18'], [2021, 'Form 1040, Line 18'], [2022, 'Form 1040, Line 18'], [2023, 'Form 1040, Line 18'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qualifyingDependentCreditClaimed',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Nonrefundable child tax credit'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 19'], [2021, 'Form 1040, Line 19'], [2022, 'Form 1040, Line 19'], [2023, 'Form 1040, Line 19'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line20_1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Amount from schedule 3 line 8'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 20'], [2021, 'Form 1040, Line 20'], [2022, 'Form 1040, Line 20'], [2023, 'Form 1040, Line 20'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line21_1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Add lines 19 and 20'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 21'], [2021, 'Form 1040, Line 21'], [2022, 'Form 1040, Line 21'], [2023, 'Form 1040, Line 21'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line22_1040',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Substract line 21 from line 18'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 22'], [2021, 'Form 1040, Line 22'], [2022, 'Form 1040, Line 22'], [2023, 'Form 1040, Line 22'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherTaxesWithSelfEmployment',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Other taxes'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 23'], [2021, 'Form 1040, Line 23'], [2022, 'Form 1040, Line 23'], [2023, 'Form 1040, Line 23'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalTax',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Total tax.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Form 1040, Line 16'], [2020, 'Form 1040, Line 24'], [2021, 'Form 1040, Line 24'], [2022, 'Form 1040, Line 24'], [2023, 'Form 1040, Line 24'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['federalIncomeTaxWithheld',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Federal income tax withheld from Form(s) W-2, 1099 and others'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 25d'], [2021, 'Form 1040, Line 25d'], [2022, 'Form 1040, Line 25d'], [2023, 'Form 1040, Line 25d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['estimatedTaxPaymentsAmountForLastYear',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return '2021 estimated tax payments and amount applied from 2020 return'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 26'], [2021, 'Form 1040, Line 26'], [2022, 'Form 1040, Line 26'], [2023, 'Form 1040, Line 26'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['earnedIncomeCredit',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Earned income credit (EIC)'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 27'], [2021, 'Form 1040, Line 27'], [2022, 'Form 1040, Line 27'], [2023, 'Form 1040, Line 27'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalChildTaxCredit',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Additional child tax credit'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 28'], [2021, 'Form 1040, Line 28'], [2022, 'Form 1040, Line 28'], [2023, 'Form 1040, Line 28'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['americanOpportunityCreditTaken',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'American opportunity credit from Form 8863, line 8'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 29'], [2021, 'Form 1040, Line 29'], [2022, 'Form 1040, Line 29'], [2023, 'Form 1040, Line 29'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['recoveryRebateCredit',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Recovery rebate credit.'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 30'], [2021, 'Form 1040, Line 30'], [2022, 'Form 1040, Line 30'], [2023, 'Form 1040, Line 30'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['amountfromSchedule3line15',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ?  'Amount from Schedule 3, line 15' : 'Amount from Schedule 3, line 13'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 31'], [2021, 'Form 1040, Line 31'], [2022, 'Form 1040, Line 31'], [2023, 'Form 1040, Line 31'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalOtherPayments',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Add lines 27 through 31. These are your total other payments and refundable credits'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 32'], [2021, 'Form 1040, Line 32'], [2022, 'Form 1040, Line 32'], [2023, 'Form 1040, Line 32'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalPayments',
    {
      tabSection: 'extractions',
      subsection: 'form1040',
      description:(isAdvisor)=>{ return 'Add lines 25d, 26, and 32. These are your total payments'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Form 1040, Line 33'], [2021, 'Form 1040, Line 33'], [2022, 'Form 1040, Line 33'], [2023, 'Form 1040, Line 33'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END FORM 1040 QUESTIONS*/

  /*START SCH-1 QUESTIONS*/
  ['taxableRefunds',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Taxable refunds'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule 1, Line 1'], [2020, 'Schedule 1, Line 1'], [2021, 'Schedule 1, Line 1'], [2022, 'Schedule 1, Line 1'], [2023, 'Schedule 1, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['alimonyReceived',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Alimony received'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 2a'], [2020, 'Schedule 1, Line 2a'], [2021, 'Schedule 1, Line 2a'], [2022, 'Schedule 1, Line 2a'], [2023, 'Schedule 1, Line 2a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['businessIncome',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Business income/loss'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 3'], [2020, 'Schedule 1, Line 3'], [2021, 'Schedule 1, Line 3'], [2022, 'Schedule 1, Line 3'], [2023, 'Schedule 1, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherGainsOrLosses',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Other gains or losses'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 4'], [2020, 'Schedule 1, Line 4'], [2021, 'Schedule 1, Line 4'], [2022, 'Schedule 1, Line 4'], [2023, 'Schedule 1, Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['rentalRealEstate',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Rental real estate'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 5'], [2020, 'Schedule 1, Line 5'], [2021, 'Schedule 1, Line 5'], [2022, 'Schedule 1, Line 5'], [2023, 'Schedule 1, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['farmIncome',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Farm income'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 6'], [2020, 'Schedule 1, Line 6'], [2021, 'Schedule 1, Line 6'], [2022, 'Schedule 1, Line 6'], [2023, 'Schedule 1, Line 6'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['unemployementCompensation',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Unemployment compensation'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 7'], [2020, 'Schedule 1, Line 7'], [2021, 'Schedule 1, Line 7'], [2022, 'Schedule 1, Line 7'], [2023, 'Schedule 1, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncomeSchedule1',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Other income'},
      type: 'MONEY',
      availableInYears: [2019, 2020],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 8'], [2020, 'Schedule 1, Line 8']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8a',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Net operating loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8a'], [2022, 'Schedule 1, Line 8a'], [2023, 'Schedule 1, Line 8a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8b',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Gambling income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8b'], [2022, 'Schedule 1, Line 8b'], [2023, 'Schedule 1, Line 8b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8c',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Cancellation of debt'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8c'], [2022, 'Schedule 1, Line 8c'], [2023, 'Schedule 1, Line 8c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8d',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Foreign earned income exclusion from Form 2555'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8d'], [2022, 'Schedule 1, Line 8d'], [2023, 'Schedule 1, Line 8d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8e',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Income from Form 8853': 'Taxable health saving account distribution'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8e'], [2022, 'Schedule 1, Line 8e'], [2023, 'Schedule 1, Line 8e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8f',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Income from Form 8889' :'Alaska permanent fund dividends'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8f'], [2022, 'Schedule 1, Line 8f'], [2023, 'Schedule 1, Line 8f'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8g',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Alaska permanent fund dividends': 'Jury duty pay'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8g'], [2022, 'Schedule 1, Line 8g'], [2023, 'Schedule 1, Line 8g'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8h',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Jury duty pay': 'Prizes and awards'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8h'], [2022, 'Schedule 1, Line 8h'], [2023, 'Schedule 1, Line 8h'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8i',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Prizes and awards':'Activity not engaged in profit income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8i'], [2022, 'Schedule 1, Line 8i'], [2023, 'Schedule 1, Line 8i'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8j',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Activity not engaged in profit income':'Stock options'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8j'], [2022, 'Schedule 1, Line 8j'], [2023, 'Schedule 1, Line 8j'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8k',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Stock options': 'Income from rental of personal property'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8k'], [2022, 'Schedule 1, Line 8k'], [2023, 'Schedule 1, Line 8k'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8l',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Income from rental of personal property':'Olympic and paralympic medals and USOC prize money'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8l'], [2022, 'Schedule 1, Line 8l'], [2023, 'Schedule 1, Line 8l'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8m',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Olympic and paralympic medals and USOC prize money': 'Section 951(a) inclusion'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8m'], [2022, 'Schedule 1, Line 8m'], [2023, 'Schedule 1, Line 8m'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8n',
    {
      tabSection: 'extractions',

      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Section 951(a) inclusion' : 'Section 951A(a) inclusion'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8n'], [2022, 'Schedule 1, Line 8n'], [2023, 'Schedule 1, Line 8n'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8o',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Section 951A(a) inclusion ': 'Section 461(l) excess business loss adjustment'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8o'], [2022, 'Schedule 1, Line 8o'], [2023, 'Schedule 1, Line 8o'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8p',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor, model)=>{ return model.clientIDTaxYear === '2022' ? 'Section 461(l) excess business loss adjustment': 'Taxable distributions from and ABLE account'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8p'], [2022, 'Schedule 1, Line 8p'], [2023, 'Schedule 1, Line 8p'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8q',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Taxable distributions from and ABLE account'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule 1, Line 8q'], [2023, 'Schedule 1, Line 8q'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8r',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Scholarship and fellowship grants not reported on W-2'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule 1, Line 8r'], [2023, 'Schedule 1, Line 8r'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8s',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Nontaxable amount of Medicaid waiver payments'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule 1, Line 8s'], [2023, 'Schedule 1, Line 8s'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8t',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Pension or annuity from a nonquialified deferred compensation plan'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule 1, Line 8t'], [2023, 'Schedule 1, Line 8t'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8u',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Wages earned while incarcerated'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule 1, Line 8u'], [2023, 'Schedule 1, Line 8u'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherIncome8z',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Other income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 8z'], [2022, 'Schedule 1, Line 8z'], [2023, 'Schedule 1, Line 8z'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],

  ['additionalIncomeTotal',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Total other income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 9'], [2022, 'Schedule 1, Line 9'], [2023, 'Schedule 1, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sum1to9',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Combine lines 1 through 7 and 9'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 10'], [2022, 'Schedule 1, Line 10'], [2023, 'Schedule 1, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['educatorExpenses',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Educator expenses'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 10'], [2020, 'Schedule 1, Line 10'], [2021, 'Schedule 1, Line 11'], [2022, 'Schedule 1, Line 11'], [2023, 'Schedule 1, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['certainBusinessExpenses',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Certain business expenses'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 11'],  [2020, 'Schedule 1, Line 11'], [2021, 'Schedule 1, Line 12'], [2022, 'Schedule 1, Line 12'], [2023, 'Schedule 1, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['healthSavings',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Health savings'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 12'], [2020, 'Schedule 1, Line 12'], [2021, 'Schedule 1, Line 13'], [2022, 'Schedule 1, Line 13'], [2023, 'Schedule 1, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['movingExpenses',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Moving expenses'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 13'], [2020, 'Schedule 1, Line 13'], [2021, 'Schedule 1, Line 14'], [2022, 'Schedule 1, Line 14'], [2023, 'Schedule 1, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['deductibleOfSelfEmployment',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Deductible part of self-employment'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2019, 'Schedule 1, Line 14'], [2020, 'Schedule 1, Line 14'], [2021, 'Schedule 1, Line 15'], [2022, 'Schedule 1, Line 15'], [2023, 'Schedule 1, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['selfEmployedSEP',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Self-employment SEP, SIMPLE and qualified plans'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 15'], [2020, 'Schedule 1, Line 15'], [2021, 'Schedule 1, Line 16'], [2022, 'Schedule 1, Line 16'], [2023, 'Schedule 1, Line 16'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['selfEmployedHealthInsurance',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Self-employment health insurance deduction'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 16'], [2020, 'Schedule 1, Line 16'], [2021, 'Schedule 1, Line 17'], [2022, 'Schedule 1, Line 17'], [2023, 'Schedule 1, Line 17'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['penaltyEarlyWithdrawal',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Penalty on early withdrawl or savings'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2019, 'Schedule 1, Line 17'], [2020, 'Schedule 1, Line 17'], [2021, 'Schedule 1, Line 18'], [2022, 'Schedule 1, Line 18'], [2023, 'Schedule 1, Line 18'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['alimonyPaid',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Alimony paid'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 18a'], [2020, 'Schedule 1, Line 18a'], [2021, 'Schedule 1, Line 19a'], [2022, 'Schedule 1, Line 19a'], [2023, 'Schedule 1, Line 19a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['iraDeduction',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'IRA deduction'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 19'], [2020, 'Schedule 1, Line 19'], [2021, 'Schedule 1, Line 20'], [2022, 'Schedule 1, Line 20'], [2023, 'Schedule 1, Line 20'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['studentLoanInterestDeduction',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Student loan interest deduction'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 20'], [2020, 'Schedule 1, Line 20'], [2021, 'Schedule 1, Line 21'], [2022, 'Schedule 1, Line 21'], [2023, 'Schedule 1, Line 21'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['forFutureUseSch1',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Reserved for future use'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 22'], [2022, 'Schedule 1, Line 22'], [2023, 'Schedule 1, Line 22'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['archerMSADeduction',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Archer MSA deduction'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 23'], [2022, 'Schedule 1, Line 23'], [2023, 'Schedule 1, Line 23'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['juryDuty',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Jury duty pay'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24a'], [2022, 'Schedule 1, Line 24a'], [2023, 'Schedule 1, Line 24a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['deductibleExpenses',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Deductible expenses related to income reported on line 8k'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24b'], [2022, 'Schedule 1, Line 24b'], [2023, 'Schedule 1, Line 24b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['nontaxableAmountOlympicMedals',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Nontaxable amount of the value of Olympic and Paralympic medals'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24c'], [2022, 'Schedule 1, Line 24c'], [2023, 'Schedule 1, Line 24c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['reforestationAmortization',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Reforestation amortization and expenses'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24d'], [2022, 'Schedule 1, Line 24d'], [2023, 'Schedule 1, Line 24d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['repaymentOfUnemployedBenefits',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Repayment of supplemental unemployment benefits under the trade act of 1974'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24e'], [2022, 'Schedule 1, Line 24e'], [2023, 'Schedule 1, Line 24e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['contributionsToSection501',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Contributions to section 501(c)(18)(D) pension plans'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24f'], [2022, 'Schedule 1, Line 24f'], [2023, 'Schedule 1, Line 24f'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['contributionsToSection403',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Contributions by certain chaplains to section 403(b) plans'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24g'], [2022, 'Schedule 1, Line 24g'], [2023, 'Schedule 1, Line 24g'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['attorneyFeesDiscriminationClaims',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Attorney fees and court costs for actions involving discrimination claims '},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24h'], [2022, 'Schedule 1, Line 24h'], [2023, 'Schedule 1, Line 24h'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['attorneyFeesIRSHelp',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Attorney fees and court costs in connection with and award from the IRS'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24i'], [2022, 'Schedule 1, Line 24i'], [2023, 'Schedule 1, Line 24i'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['housingDeduction',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Housing deduction from Form 2555'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24j'], [2022, 'Schedule 1, Line 24j'], [2023, 'Schedule 1, Line 24j'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['excessDeductions',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Excess deductions of section 67(e) expenses from Schedule K-1'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24k'], [2022, 'Schedule 1, Line 24k'], [2023, 'Schedule 1, Line 24k'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherAdjustmens',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Other adjustments'},
      type: 'MONEY',
      availableInYears: [ 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 24z'], [2022, 'Schedule 1, Line 24z'], [2023, 'Schedule 1, Line 24z'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalOtherAdjustments',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Total other adjustments'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 1, Line 25'], [2022, 'Schedule 1, Line 25'], [2023, 'Schedule 1, Line 25'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['adjustmentToIncome',
    {
      tabSection: 'extractions',
      subsection: 'sch1',
      description:(isAdvisor)=>{ return 'Adjustments to income'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 1, Line 22'], [2020, 'Schedule 1, Line 22'], [2021, 'Schedule 1, Line 26'], [2022, 'Schedule 1, Line 26'], [2023, 'Schedule 1, Line 26'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END SCH-1 QUESTIONS*/

  /*START SCH-2 QUESTIONS*/
  /*['clientHasSchedule2',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor, model)=>{ return 'Is there a Schedule 2 on tax return'},
      type: 'RADIO_GROUP',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Is there a Schedule 2 on tax return?'], [2022, 'Is there a Schedule 2 on tax return?'], [2023, 'Is there a Schedule 2 on tax return?'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasBusiness === 'Yes' || instance.scheduleCDectected === 'Yes' || q.clientHasScheduleC === 'Yes';
      }
    }
  ],*/
  ['alternativeMinTax',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Alternative minimun tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 1'], [2022, 'Schedule 2, Line 1'], [2023, 'Schedule 2, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['excessAdvPremiumTaxCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Excess advance premium tax credit repayment'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 2'], [2022, 'Schedule 2, Line 2'], [2023, 'Schedule 2, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sum1and2',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Add lines 1 and 2'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 3'], [2022, 'Schedule 2, Line 3'], [2023, 'Schedule 2, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['selfEmploymentTaxSch3',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Self-employment tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 4'], [2022, 'Schedule 2, Line 4'], [2023, 'Schedule 2, Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['socialSecurityTip',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Social security and Medicare tax on unreported tip income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 5'], [2022, 'Schedule 2, Line 5'], [2023, 'Schedule 2, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['uncollectedSocialSecurity',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Uncollected social security and Medicare on wages'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 6'], [2022, 'Schedule 2, Line 6'], [2023, 'Schedule 2, Line 6'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAdditionalSocialSecurity',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Total additional social security and Medicare '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 7'], [2022, 'Schedule 2, Line 7'], [2023, 'Schedule 2, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalTaxOnIRA',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax on IRAs '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 8'], [2022, 'Schedule 2, Line 8'], [2023, 'Schedule 2, Line 8'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['householdEmploymentTaxes',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Household employment taxes'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 9'], [2022, 'Schedule 2, Line 9'], [2023, 'Schedule 2, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['repaymentFirstTimeBuyer',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Repayment on first time homebuyer credit'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 10'], [2022, 'Schedule 2, Line 10'], [2023, 'Schedule 2, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalMedicare',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional Medicare tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 11'], [2022, 'Schedule 2, Line 11'], [2023, 'Schedule 2, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['netInvestmentIncomeTax',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Net investment income tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 12'], [2022, 'Schedule 2, Line 12'], [2023, 'Schedule 2, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['uncollectedSocialSecurityOnTips',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Uncollected social security and Medicare or RRTA tax on tips'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 13'], [2022, 'Schedule 2, Line 13'], [2023, 'Schedule 2, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['interestOnTax',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Interest on tax due on installment income from the sale of certain residential lots'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 14'], [2022, 'Schedule 2, Line 14'], [2023, 'Schedule 2, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['interestOnDeferredTax',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Interest on the deferred tax on gain from certain installment sales with a sales price over $150,000'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 15'], [2022, 'Schedule 2, Line 15'], [2023, 'Schedule 2, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['recaptureLowIncome',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Recapture of low-income housing credit. Attach Form 8611 '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 16'], [2022, 'Schedule 2, Line 16'], [2023, 'Schedule 2, Line 16'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['recaptureOtherCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Recapture of other credits'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17a'], [2022, 'Schedule 2, Line 17a'], [2023, 'Schedule 2, Line 17a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['recaptureFederalMortage',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Recapture of federal mortgage subsidy'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17b'], [2022, 'Schedule 2, Line 17b'], [2023, 'Schedule 2, Line 17b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalTaxHSA',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax on HSA distributions'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17c'], [2022, 'Schedule 2, Line 17c'], [2023, 'Schedule 2, Line 17c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalTaxHSAdidntRemainElegibleIndividual',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax on an HSA because you didn\'t remain an eligible individual'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17d'], [2022, 'Schedule 2, Line 17d'], [2023, 'Schedule 2, Line 17d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalTaxArcherMSA',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax on Archer MSA distributions'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17e'], [2022, 'Schedule 2, Line 17e'], [2023, 'Schedule 2, Line 17e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['additionalTaxMedicareAdvantageMSA',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax on Medicare Advantage MSA distributions'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17f'], [2022, 'Schedule 2, Line 17f'], [2023, 'Schedule 2, Line 17f'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['recaptureOfCharitableContributions',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Recapture of a charitable contribution deduction related to a fractional interest in tangible personal property'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17g'], [2022, 'Schedule 2, Line 17g'], [2023, 'Schedule 2, Line 17g'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['incomeFromNonqualifiedCompesationPlan',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Income you received from a nonqualified deferred compensation plan that fails to meet the requirements of section 409A'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17h'], [2022, 'Schedule 2, Line 17h'], [2023, 'Schedule 2, Line 17h'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['incomeFromNonqualifiedCompesationPlan457A',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Compensation you received from a nonqualified deferred compensation plan described in section 457A'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17i'], [2022, 'Schedule 2, Line 17i'], [2023, 'Schedule 2, Line 17i'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['section72ExcessBenefitsTax',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Section 72(m)(5) excess benefits tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17j'], [2022, 'Schedule 2, Line 17j'], [2023, 'Schedule 2, Line 17j'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['goldenParachutePayments',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Golden parachute payments '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17k'], [2022, 'Schedule 2, Line 17k'], [2023, 'Schedule 2, Line 17k'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxAccumulationDistributionOfTrusts',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Tax on accumulation distribution of trusts'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17l'], [2022, 'Schedule 2, Line 17l'], [2023, 'Schedule 2, Line 17l'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['exciseTaxOnInsiderStock',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Excise tax on insider stock compensation from an expatriated corporation'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17m'], [2022, 'Schedule 2, Line 17m'], [2023, 'Schedule 2, Line 17m'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['lookbackInterestSection167or460',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Look-back interest under section 167(g) or 460(b) from Form 8697 or 8866'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17n'], [2022, 'Schedule 2, Line 17n'], [2023, 'Schedule 2, Line 17n'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxNonEffectivelyConnectedIncome',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Tax on non-effectively connected income for any part of the year you were a nonresident alien'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17o'], [2022, 'Schedule 2, Line 17o'], [2023, 'Schedule 2, Line 17o'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['interestForm8621line16f',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Any interest from Form 8621, line 16f'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17p'], [2022, 'Schedule 2, Line 17p'], [2023, 'Schedule 2, Line 17p'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['interestForm8621line24',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Any interest from Form 8621, line 24'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17q'], [2022, 'Schedule 2, Line 17q'], [2023, 'Schedule 2, Line 17q'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['anyOtherTaxes',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Any other taxes'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 17z'], [2022, 'Schedule 2, Line 17z'], [2023, 'Schedule 2, Line 17z'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAdditionalTaxes',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Total additional taxes'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 18'], [2022, 'Schedule 2, Line 18'], [2023, 'Schedule 2, Line 18'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['reservedForFutureUse',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Additional tax from Schedule 8812'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 19'], [2022, 'Schedule 2, Line 19'], [2023, 'Schedule 2, Line 19'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['section965',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Section 965 net tax liability installment from Form 965-A '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 20'], [2022, 'Schedule 2, Line20 '], [2023, 'Schedule 2, Line20 '] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalOtherTaxes',
    {
      tabSection: 'extractions',
      subsection: 'sch2',
      description:(isAdvisor)=>{ return 'Total other taxes'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 2, Line 21'], [2022, 'Schedule 2, Line 21'], [2023, 'Schedule 2, Line 21'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],

  /*END SCH-2 QUESTIONS*/

  /*START SCH-3 QUESTIONS*/
  ['foreignTax',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Foreign tax'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 1'], [2020, 'Schedule 3, Line 1'], [2021, 'Schedule 3, Line 1'], [2022, 'Schedule 3, Line 1'], [2023, 'Schedule 3, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditForChildCare',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Credit for child/dependent care.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 2'], [2020, 'Schedule 3, Line 2'], [2021, 'Schedule 3, Line 2'], [2022, 'Schedule 3, Line 2'], [2023, 'Schedule 3, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['educationCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Education credits.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 3'], [2020, 'Schedule 3, Line 3'], [2021, 'Schedule 3, Line 3'], [2022, 'Schedule 3, Line 3'], [2023, 'Schedule 3, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['retiramentSavings',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Retirement savings contributions credit.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 4'], [2020, 'Schedule 3, Line 4'], [2021, 'Schedule 3, Line 4'], [2022, 'Schedule 3, Line 4'], [2023, 'Schedule 3, Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['energyCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Residential energy credits.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 5'], [2020, 'Schedule 3, Line 5'], [2021, 'Schedule 3, Line 5'], [2022, 'Schedule 3, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Other nonrefundable credits.'},
      type: 'MONEY',
      availableInYears: [2019, 2020],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 6'], [2020, 'Schedule 3, Line 6']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['schedule3line5a',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Residential clean energy credit.'},
      type: 'MONEY',
      availableInYears: [2023],
      isExtraction: true,
      linesByYear: new Map([[2023, 'Schedule 3, Line 5a']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['schedule3line5b',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Energy efficient home improvement credit.'},
      type: 'MONEY',
      availableInYears: [2023],
      isExtraction: true,
      linesByYear: new Map([[2023, 'Schedule 3, Line 5b']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['generalBusinessCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'General business credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6a'], [2022, 'Schedule 3, Line 6a'], [2023, 'Schedule 3, Line 6a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditForPriorYearMinimunTax',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Credit for prior year minimum tax'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6b'], [2022, 'Schedule 3, Line 6b'], [2023, 'Schedule 3, Line 6b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['adoptionCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Adoption credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6c'], [2022, 'Schedule 3, Line 6c'], [2023, 'Schedule 3, Line 6c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditForEldery',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Credit for elderly or disabled'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6d'], [2022, 'Schedule 3, Line 6d'], [2023, 'Schedule 3, Line 6d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['alternativeMotorVehicleCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Alternative motor vehicle credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6e'], [2022, 'Schedule 3, Line 6e'], [2023, 'Schedule 3, Line 6e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qualifiedPlugInMotorVehicleCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Qualified plug-in motor vehicle credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6f'], [2022, 'Schedule 3, Line 6f'], [2023, 'Schedule 3, Line 6f'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['mortgageInterestCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Mortgage interest credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6g'], [2022, 'Schedule 3, Line 6g'], [2023, 'Schedule 3, Line 6g'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['columbiaFirstTimeBuyer',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'District of Columbia first-time homebuyer credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6h'], [2022, 'Schedule 3, Line 6h'], [2023, 'Schedule 3, Line 6h'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qualifiedElectricVehicleCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Qualified electric vehicle credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6i'], [2022, 'Schedule 3, Line 6i'], [2023, 'Schedule 3, Line 6i'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['alternativeFuelVehicleCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Alternative fuel vehicle refueling property credit'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6j'], [2022, 'Schedule 3, Line 6j'], [2023, 'Schedule 3, Line 6j'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditToHoldersTaxCreditsBonds',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Credit to holders of tax credit bonds'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6k'], [2022, 'Schedule 3, Line 6k'], [2023, 'Schedule 3, Line 6k'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['amountOnForm8978',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Amount on Form 8978, line 14'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6l'], [2022, 'Schedule 3, Line 6l'], [2023, 'Schedule 3, Line 6l'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['schedule3line6m',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Credit for previously owned clean vehicles'
      },
      type: 'MONEY',
      availableInYears: [2023],
      isExtraction: true,
      linesByYear: new Map([[2023, 'Schedule 3, Line 6m'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherNonRefundableCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description: (isAdvisor) => {
        return 'Other nonrefundable credits'
      },
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 6z'], [2022, 'Schedule 3, Line 6z'], [2023, 'Schedule 3, Line 6z'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['nonrefundableCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Total other non refundable credits'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 7'], [2022, 'Schedule 3, Line 7'], [2023, 'Schedule 3, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['estimatedTax',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Add lines 1 through 5 and 7'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 8'], [2022, 'Schedule 3, Line 8'], [2023, 'Schedule 3, Line 8'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['netPremiumTaxCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Net premium tax credit'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 8'], [2020, 'Schedule 3, Line 8'], [2021, 'Schedule 3, Line 9'], [2022, 'Schedule 3, Line 9'], [2023, 'Schedule 3, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['requestForExtension',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Amount paid with request for extension to file'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 9'], [2020, 'Schedule 3, Line 9'], [2021, 'Schedule 3, Line 10'], [2022, 'Schedule 3, Line 10'], [2023, 'Schedule 3, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['excessSocialSecurity',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Excess social security and tier 1 RRTA tax withheld'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([ [2019, 'Schedule 3, Line 10'], [2020, 'Schedule 3, Line 10'], [2021, 'Schedule 3, Line 11'], [2022, 'Schedule 3, Line 11'], [2023, 'Schedule 3, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditForFederalTaxFuels',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Credit for federal tax on fuels'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule 3, Line 11'],  [2020, 'Schedule 3, Line 11'], [2021, 'Schedule 3, Line 12'], [2022, 'Schedule 3, Line 12'], [2023, 'Schedule 3, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form2439',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Form 2439'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13a'], [2022, 'Schedule 3, Line 13a'], [2023, 'Schedule 3, Line 13a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qualifiedSickCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Qualified sick and family leave credits from Schedule Ha and Form 7202 before April 1'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13b'], [2022, 'Schedule 3, Line 13b'], [2023, 'Schedule 3, Line 13b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['healthCoverageCredit',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Health coverage tax credit from Form 8885'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13c'], [2022, 'Schedule 3, Line 13c'], [2023, 'Schedule 3, Line 13c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditForRepayment',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Credit for repayment of amounts included in income from earlier years'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13d'], [2022, 'Schedule 3, Line 13d'], [2023, 'Schedule 3, Line 13d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['futureUse13e',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Reserved for future use'},
      type: 'MONEY',
      availableInYears: [2021, 2022],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13e'], [2022, 'Schedule 3, Line 13e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['deferredAmount965',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Deferred amount of net 965 tax liability '},
      type: 'MONEY',
      availableInYears: [2021, 2022],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13f'], [2022, 'Schedule 3, Line 13f'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['creditChildDependent2441',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Credit for child and dependent care expenses from Form 2441'},
      type: 'MONEY',
      availableInYears: [2021, 2022],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13g'], [2022, 'Schedule 3, Line 13g'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['qualifiedSickCreditsSchH',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Qualified sick and family leave credits from Schedule Ha and Form 7202 after March 31'},
      type: 'MONEY',
      availableInYears: [2021, 2022],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13h'], [2022, 'Schedule 3, Line 13h'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['otherPaymentsOrRefundableCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Other payments or refundable credits'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 13z'], [2022, 'Schedule 3, Line 13z'], [2023, 'Schedule 3, Line 13z'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['refundableCredits',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Total other payments or refundable credits'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 14'], [2022, 'Schedule 3, Line 14'], [2023, 'Schedule 3, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sum9to14',
    {
      tabSection: 'extractions',
      subsection: 'sch3',
      description:(isAdvisor)=>{ return 'Add lines 9 through 12 and 14'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule 3, Line 15'], [2022, 'Schedule 3, Line 15'], [2023, 'Schedule 3, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END SCH-3 QUESTIONS*/

  /*START SCH-A QUESTIONS*/
  ['medicalExpenses',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Medical and dental expenses.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 1'], [2020, 'Schedule A, Line 1'], [2021, 'Schedule A, Line 1'], [2022, 'Schedule A, Line 1'], [2023, 'Schedule A, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['medicalExpenses2',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Multiply line 2 by 7.5%.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 3'], [2020, 'Schedule A, Line 3'], [2021, 'Schedule A, Line 3'], [2022, 'Schedule A, Line 3'], [2023, 'Schedule A, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['medicalExpenses3',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Line 3 minus line 1.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 4'], [2020, 'Schedule A, Line 4'], [2021, 'Schedule A, Line 4'], [2022, 'Schedule A, Line 4'], [2023, 'Schedule A, Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid5a',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'State and local income taxes or general sales taxes'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 5a'], [2020, 'Schedule A, Line 5a'], [2021, 'Schedule A, Line 5a'], [2022, 'Schedule A, Line 5a'], [2023, 'Schedule A, Line 5a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid5b',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'State and local real estate taxes'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 5b'], [2020, 'Schedule A, Line 5b'], [2021, 'Schedule A, Line 5b'], [2022, 'Schedule A, Line 5b'], [2023, 'Schedule A, Line 5b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid5c',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'State and local personal property taxes'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 5c'], [2020, 'Schedule A, Line 5c'], [2021, 'Schedule A, Line 5c'], [2022, 'Schedule A, Line 5c'], [2023, 'Schedule A, Line 5c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid5d',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Taxes you paid.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 5d'], [2020, 'Schedule A, Line 5d'], [2021, 'Schedule A, Line 5d'], [2022, 'Schedule A, Line 5d'], [2023, 'Schedule A, Line 5d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid5e',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Enter the smaller of line 5d or $10,000'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 5e'], [2020, 'Schedule A, Line 5e'], [2021, 'Schedule A, Line 5e'], [2022, 'Schedule A, Line 5e'], [2023, 'Schedule A, Line 5e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['taxesYouPaid',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Deductions allowed for taxes paid.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 7'], [2020, 'Schedule A, Line 7'], [2021, 'Schedule A, Line 7'], [2022, 'Schedule A, Line 7'], [2023, 'Schedule A, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['mortgageInterests',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Home mortgage interest and points reported to you on Form 1098.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 8a'], [2020, 'Schedule A, Line 8a'], [2021, 'Schedule A, Line 8a'], [2022, 'Schedule A, Line 8a'], [2023, 'Schedule A, Line 8a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['mortgageInterestsNotReported',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Home mortgage interest not reported to you on Form 1098'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 8b'], [2020, 'Schedule A, Line 8b'], [2021, 'Schedule A, Line 8b'], [2022, 'Schedule A, Line 8b'], [2023, 'Schedule A, Line 8b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['pointsNotReported',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Points not reported to you on Form 1098'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 8c'], [2020, 'Schedule A, Line 8c'], [2021, 'Schedule A, Line 8c'], [2022, 'Schedule A, Line 8c'], [2023, 'Schedule A, Line 8c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['mortgageInsurancePremiums',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Mortgage insurance premiums'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 8d'],  [2020, 'Schedule A, Line 8d'], [2021, 'Schedule A, Line 8d'], [2022, 'Schedule A, Line 8d'], [2023, 'Schedule A, Line 8d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['sumOfLines8',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Add lines 8a through 8d'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 8e'], [2020, 'Schedule A, Line 8e'], [2021, 'Schedule A, Line 8e'], [2022, 'Schedule A, Line 8e'], [2023, 'Schedule A, Line 8e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['investmentInterest',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Investment interest.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 9'], [2020, 'Schedule A, Line 9'], [2021, 'Schedule A, Line 9'], [2022, 'Schedule A, Line 9'], [2023, 'Schedule A, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['interestYouPaid',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Interest you paid (total).'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 10'], [2020, 'Schedule A, Line 10'], [2021, 'Schedule A, Line 10'], [2022, 'Schedule A, Line 10'], [2023, 'Schedule A, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['giftsByCash',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Gifts by cash or check.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 11'], [2020, 'Schedule A, Line 11'], [2021, 'Schedule A, Line 11'], [2022, 'Schedule A, Line 11'], [2023, 'Schedule A, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['giftsByLand',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Other than by cash or check.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 12'], [2020, 'Schedule A, Line 12'], [2021, 'Schedule A, Line 12'], [2022, 'Schedule A, Line 12'], [2023, 'Schedule A, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['giftsToCharity',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Gifts to charity (total).'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 14'], [2020, 'Schedule A, Line 14'], [2021, 'Schedule A, Line 14'], [2022, 'Schedule A, Line 14'], [2023, 'Schedule A, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['casualtyLosses',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Casualty and theft losses.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 15'], [2020, 'Schedule A, Line 15'], [2021, 'Schedule A, Line 15'], [2022, 'Schedule A, Line 15'], [2023, 'Schedule A, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['summationsItemizedDeductions',
    {
      tabSection: 'extractions',
      subsection: 'schA',
      description:(isAdvisor)=>{ return 'Total itemized deductions.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule A, Line 17'],  [2020, 'Schedule A, Line 17'], [2021, 'Schedule A, Line 17'], [2022, 'Schedule A, Line 17'], [2023, 'Schedule A, Line 17'] ]),
      displayRuleFunction: (q, instance) => { return true }
    }
  ],
  /*END SCH-A QUESTIONS*/


  /*START SCH-B QUESTIONS*/

  /*
  scheduleB_1: {description:'Schedule B. Part I - Interest', line: ''},//
  scheduleb_2: {description:'Schedule B. Part I - Interest', line: 'Schedule B, Line 2'},//
  scheduleb_4: {description:'Schedule B. Part I - Interest', line: 'Schedule B, Line 4'},//
  scheduleB_5: {description:'Schedule B. Part II - Ordinary Dividends', line: ''},//
  scheduleb_6: {description:'Schedule B. Part II - Interest', line: 'Schedule B, Line 6'},//
  */

  ['scheduleB_1',
    {
      tabSection: '',
      subsection: '',
      description:(isAdvisor)=>{ return 'Schedule B. Part I - Interest'},
      type: 'FLAG_ONLY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],

  ['scheduleb_1_0',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_1',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_2',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_3',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_4',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_5',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_6',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_7',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_8',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_9',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_10',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_11',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_12',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_13',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_1_14',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 1'], [2021, 'Schedule B, Line 1'], [2022, 'Schedule B, Line 1'], [2023, 'Schedule B, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],

  ['scheduleb_2',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return 'Add the amounts on line 1.'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 2'], [2021, 'Schedule B, Line 2'], [2022, 'Schedule B, Line 2'], [2023, 'Schedule B, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_3',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return 'Attach from 8815.'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 3'], [2021, 'Schedule B, Line 3'], [2022, 'Schedule B, Line 3'], [2023, 'Schedule B, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_4',
    {
      tabSection: 'extractions',
      subsection: 'schB1',
      description:(isAdvisor)=>{ return 'Substract line 3 from line 2.'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 4'], [2021, 'Schedule B, Line 4'], [2022, 'Schedule B, Line 4'], [2023, 'Schedule B, Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleB_5',
    {
      tabSection: '',
      subsection: '',
      description:(isAdvisor)=>{ return 'Schedule B. Part II - Ordinary Dividends'},
      type: 'FLAG_ONLY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_0',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_1',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_2',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_3',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_4',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_5',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_6',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_7',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_8',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_9',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_10',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_11',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_12',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_13',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_5_14',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return ''},
      type: 'SCH_B',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 5'], [2021, 'Schedule B, Line 5'], [2022, 'Schedule B, Line 5'], [2023, 'Schedule B, Line 5'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleb_6',
    {
      tabSection: 'extractions',
      subsection: 'schB2',
      description:(isAdvisor)=>{ return 'Add the amounts on line 5.'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule B, Line 6'], [2021, 'Schedule B, Line 6'], [2022, 'Schedule B, Line 6'], [2023, 'Schedule B, Line 6'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END SCH-B QUESTIONS*/

  /*START SCH-C QUESTIONS*/
  ['clientHasScheduleC',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Is there a Schedule C on the tax return?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['grossReceipts',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Gross Receipts'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 1'], [2021, 'Schedule C, Line 1'], [2022, 'Schedule C, Line 1'], [2023, 'Schedule C, Line 1'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['returns',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Returns'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 2'], [2021, 'Schedule C, Line 2'], [2022, 'Schedule C, Line 2'], [2023, 'Schedule C, Line 2'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['line3SchC',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Substract line 2 from line 1'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 3'], [2021, 'Schedule C, Line 3'], [2022, 'Schedule C, Line 3'], [2023, 'Schedule C, Line 3'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['costOfGoods',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Cost of Goods'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 4'], [2021, 'Schedule C, Line 4'], [2022, 'Schedule C, Line 4'], [2023, 'Schedule C, Line 4'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['grossProfit',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Gross Profit'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 5'], [2021, 'Schedule C, Line 5'], [2022, 'Schedule C, Line 5'], [2023, 'Schedule C, Line 5'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['otherIncomeSchC',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Other Income'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 6'], [2021, 'Schedule C, Line 6'], [2022, 'Schedule C, Line 6'], [2023, 'Schedule C, Line 6'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['grossIncome',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Gross Income'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 7'], [2021, 'Schedule C, Line 7'], [2022, 'Schedule C, Line 7'], [2023, 'Schedule C, Line 7'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['advertising',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Advertising'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 8'], [2021, 'Schedule C, Line 8'], [2022, 'Schedule C, Line 8'], [2023, 'Schedule C, Line 8'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['carExpenses',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Car Expenses'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 9'], [2021, 'Schedule C, Line 9'], [2022, 'Schedule C, Line 9'], [2023, 'Schedule C, Line 9'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['commissions',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Commissions'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 10'], [2021, 'Schedule C, Line 10'], [2022, 'Schedule C, Line 10'], [2023, 'Schedule C, Line 10'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['contractLabor',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Contract Labor'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 11'], [2021, 'Schedule C, Line 11'], [2022, 'Schedule C, Line 11'], [2023, 'Schedule C, Line 11'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['depletion',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Depletion'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 12'], [2021, 'Schedule C, Line 12'], [2022, 'Schedule C, Line 12'], [2023, 'Schedule C, Line 12'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['depreciation',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Depreciation'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 13'], [2021, 'Schedule C, Line 13'], [2022, 'Schedule C, Line 13'], [2023, 'Schedule C, Line 13'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['employeeBenefit',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Employee Benefit'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 14'], [2021, 'Schedule C, Line 14'], [2022, 'Schedule C, Line 14'], [2023, 'Schedule C, Line 14'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['insurance',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Insurance'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 15'], [2021, 'Schedule C, Line 15'], [2022, 'Schedule C, Line 15'], [2023, 'Schedule C, Line 15'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['interestMortgage',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Interest Mortgage'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 16a'], [2021, 'Schedule C, Line 16a'], [2022, 'Schedule C, Line 16a'], [2023, 'Schedule C, Line 16a'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  // ['',
  //   {
  //     tabSection: 'extractions',
  //     subsection: 'schC',
  //     description:(isAdvisor)=>{ return ''},
  //     type: 'MONEY',
  //     availableInYears: [2020, 2021, 2022, 2023],
  //     isExtraction: true,
  //     linesByYear: new Map([[2020, 'Schedule C, Line 1'], [2021, 'Schedule C, Line 1'], [2022, 'Schedule C, Line 1'], [2023, 'Schedule C, Line 1'] ]),
  //     displayRuleFunction: (q) => {
  //       return true
  //     }
  //   }
  // ],
  ['interestOther',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Interest Other'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 16b'], [2021, 'Schedule C, Line 16b'], [2022, 'Schedule C, Line 16b'], [2023, 'Schedule C, Line 16b'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['legal',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Legal and professional services'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 17'], [2021, 'Schedule C, Line 17'], [2022, 'Schedule C, Line 17'], [2023, 'Schedule C, Line 17'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['officeExpenses',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Office Expenses'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 18'], [2021, 'Schedule C, Line 18'], [2022, 'Schedule C, Line 18'], [2023, 'Schedule C, Line 18'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['pensionPlans',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Pension Plans'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 19'], [2021, 'Schedule C, Line 19'], [2022, 'Schedule C, Line 19'], [2023, 'Schedule C, Line 19'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['vehiclesMachinery',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Vehicles Machinery'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 20a'], [2021, 'Schedule C, Line 20a'], [2022, 'Schedule C, Line 20a'], [2023, 'Schedule C, Line 20a'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['otherBusinessProperty',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Other Business Property'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 20b'], [2021, 'Schedule C, Line 20b'], [2022, 'Schedule C, Line 20b'], [2023, 'Schedule C, Line 20b'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['repairs',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Repairs'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 21'], [2021, 'Schedule C, Line 21'], [2022, 'Schedule C, Line 21'], [2023, 'Schedule C, Line 21'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['supplies',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Supplies'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 22'], [2021, 'Schedule C, Line 22'], [2022, 'Schedule C, Line 22'], [2023, 'Schedule C, Line 22'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['taxesAndLicenses',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Taxes and Licenses'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 23'], [2021, 'Schedule C, Line 23'], [2022, 'Schedule C, Line 23'], [2023, 'Schedule C, Line 23'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['travel',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Travel'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 24a'], [2021, 'Schedule C, Line 24a'], [2022, 'Schedule C, Line 24a'], [2023, 'Schedule C, Line 24a'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['meals',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Meals'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 24b'], [2021, 'Schedule C, Line 24b'], [2022, 'Schedule C, Line 24b'], [2023, 'Schedule C, Line 24b'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['utilities',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Utilities'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 25'], [2021, 'Schedule C, Line 25'], [2022, 'Schedule C, Line 25'], [2023, 'Schedule C, Line 25'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['wagesLessEmployeeCredits',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Wages less employee credits'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 26'], [2021, 'Schedule C, Line 26'], [2022, 'Schedule C, Line 26'], [2023, 'Schedule C, Line 26'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['otherExpenses_schC',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Other Expenses'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 27a'], [2021, 'Schedule C, Line 27a'], [2022, 'Schedule C, Line 27a'], [2023, 'Schedule C, Line 27a'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['totalExpenses',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Total Expenses'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 28'], [2021, 'Schedule C, Line 28'], [2022, 'Schedule C, Line 28'], [2023, 'Schedule C, Line 28'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['tentativeProfit',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Tentative Profit'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Schedule C, Line 29'], [2021, 'Schedule C, Line 29'], [2022, 'Schedule C, Line 29'], [2023, 'Schedule C, Line 29'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['expensesForHomeBusiness',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Expenses for business use of your home'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule C, Line 30'], [2020, 'Schedule C, Line 30'], [2021, 'Schedule C, Line 30'], [2022, 'Schedule C, Line 30'], [2023, 'Schedule C, Line 30'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['netProfitOrLoss',
    {
      tabSection: 'extractions',
      subsection: 'schC',
      description:(isAdvisor)=>{ return 'Net profit or (loss) from business'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule C, Line 31'],  [2020, 'Schedule C, Line 31'], [2021, 'Schedule C, Line 31'], [2022, 'Schedule C, Line 31'], [2023, 'Schedule C, Line 31'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  /*END SCH-C QUESTIONS*/

  /*START SCH-D QUESTIONS*/
  ['shortTermLossCarryover',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Short term capital loss carryover.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule D, Line 6'], [2020, 'Schedule D, Line 6'], [2021, 'Schedule D, Line 6'], [2022, 'Schedule D, Line 6'], [2023, 'Schedule D, Line 6'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['netShortTermCapitalGains',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Net short-term capital gain or (loss).'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule D, Line 7'], [2020, 'Schedule D, Line 7'], [2021, 'Schedule D, Line 7'], [2022, 'Schedule D, Line 7'], [2023, 'Schedule D, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleD13',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Capital gain distributions.'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule D, Line 13'], [2023, 'Schedule D, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['longTermLossCarryover',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Long term capital loss carryover.'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule D, Line 14'], [2020, 'Schedule D, Line 14'], [2021, 'Schedule D, Line 14'], [2022, 'Schedule D, Line 14'], [2023, 'Schedule D, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['netLongTermCapitalGains',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Net long-term capital gain (or loss).'},
      type: 'MONEY',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Schedule D, Line 15'], [2020, 'Schedule D, Line 15'], [2021, 'Schedule D, Line 15'], [2022, 'Schedule D, Line 15'], [2023, 'Schedule D, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['scheduleD16',
    {
      tabSection: 'extractions',
      subsection: 'schD',
      description:(isAdvisor)=>{ return 'Combine lines 7 and 15'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule D, Line 16'], [2023, 'Schedule D, Line 16'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END SCH-D QUESTIONS*/

  /*START SCH-E QUESTIONS*/
  ['clientHasScheduleE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor, model)=>{ return 'Is there a Schedule E on ' + model.clientIDTaxYear +' tax return'},
      type: 'RADIO_GROUP',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Is there a Schedule E on tax return?'], [2022, 'Is there a Schedule E on tax return?'], [2023, 'Is there a Schedule E on tax return?'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasBusiness === 'Yes' || instance.scheduleCDectected === 'Yes' || q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['totalAmounts23a',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total amounts reported on line 3 for all rental properties'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 23a'], [2022, 'Schedule E, Line 23a'], [2023, 'Schedule E, Line 23a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAmounts23b',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total amounts reported on line 4 for all royalty properties'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 23b'], [2022, 'Schedule E, Line 23b'], [2023, 'Schedule E, Line 23b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAmounts23c',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total amounts reported on line 12 for all properties'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 23c'], [2022, 'Schedule E, Line 23c'], [2023, 'Schedule E, Line 23c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAmounts23d',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total amounts reported on line 18 for all properties'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 23d'], [2022, 'Schedule E, Line 23d'], [2023, 'Schedule E, Line 23d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalAmounts23e',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total amounts reported on line 20 for all properties'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 23e'], [2022, 'Schedule E, Line 23e'], [2023, 'Schedule E, Line 23e'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['incomeSchE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 24'], [2022, 'Schedule E, Line 24'], [2023, 'Schedule E, Line 24'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['lossesSchE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Losses'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 25'], [2022, 'Schedule E, Line 25'], [2023, 'Schedule E, Line 25'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalRentalRealEstate',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total rental real estate and royalty income or loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 26'], [2022, 'Schedule E, Line 26'], [2023, 'Schedule E, Line 26'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line30schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Add columns (h) and (k)'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 30'], [2022, 'Schedule E, Line 30'], [2023, 'Schedule E, Line 30'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line31schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Add columns (g), (i) and (j)'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 31'], [2022, 'Schedule E, Line 31'], [2023, 'Schedule E, Line 31'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line32schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total partnership and S corporation income or loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 32'], [2022, 'Schedule E, Line 32'], [2023, 'Schedule E, Line 32'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line35schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Add columns (d) and (f) '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 35'], [2022, 'Schedule E, Line 35'], [2023, 'Schedule E, Line 35'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line36schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Add columns (c) and (e)'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 36'], [2022, 'Schedule E, Line 36'], [2023, 'Schedule E, Line 36'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line37schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total estate and trust income or loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 37'], [2022, 'Schedule E, Line 37'], [2023, 'Schedule E, Line 37'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line39schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Combine columns (d) and (e)'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 39'], [2022, 'Schedule E, Line 39'], [2023, 'Schedule E, Line 39'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line40schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Net farm rental income or loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 40'], [2022, 'Schedule E, Line 40'], [2023, 'Schedule E, Line 40'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line41schE',
    {
      tabSection: 'extractions',
      subsection: 'schE',
      description:(isAdvisor)=>{ return 'Total income or loss'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule E, Line 41'], [2022, 'Schedule E, Line 41'], [2023, 'Schedule E, Line 41'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END SCH-E QUESTIONS*/

  /*START SCH-SE QUESTIONS*/
  ['clientHasScheduleSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor, model)=>{ return 'Is there a Schedule SE on ' + model.clientIDTaxYear + ' tax return'},
      type: 'RADIO_GROUP',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Is there a Schedule SE on tax return?'], [2022, 'Is there a Schedule SE on tax return?'], [2023, 'Is there a Schedule SE on tax return?'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasBusiness === 'Yes' || instance.scheduleCDetected === 'Yes' || q.clientHasScheduleC === 'Yes';
      }
    }
  ],
  ['netFarmProfitOrLoss',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Net farm profit or (loss) from Schedule F'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 1a'], [2022, 'Schedule SE, Line 1a'], [2023, 'Schedule SE, Line 1a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['conservationReserveProgramPayments',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Amount of Conservation Reserve Program payments included on Schedule F'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 1b'], [2022, 'Schedule SE, Line 1b'], [2023, 'Schedule SE, Line 1b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['netProfitOrLossFromSchC',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Net profit or (loss) from Schedule C, line 31'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 2'], [2022, 'Schedule SE, Line 2'], [2023, 'Schedule SE, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['combinelines1a1b2',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Combine lines 1a, 1b, and 2'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 3'], [2022, 'Schedule SE, Line 3'], [2023, 'Schedule SE, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line4aSchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'If line 3 is more than zero, multiply line 3 by 92.35% (0.9235). Otherwise, enter amount from line 3'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 4a'], [2022, 'Schedule SE, Line 4a'], [2023, 'Schedule SE, Line 4a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line4bSchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'If you elect one or both of the optional methods, enter the total of lines 15 and 17 '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 4b'], [2022, 'Schedule SE, Line 4b'], [2023, 'Schedule SE, Line 4b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line4cSchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Combine lines 4a and 4b.'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 4c'], [2022, 'Schedule SE, Line 4c'], [2023, 'Schedule SE, Line 4c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['churchEmployeeIncome',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Enter your church employee income from Form W-2'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 5a'], [2022, 'Schedule SE, Line 5a'], [2023, 'Schedule SE, Line 5a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line5bSchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Multiply line 5a by 92.35% '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 5b'], [2022, 'Schedule SE, Line 5b'], [2023, 'Schedule SE, Line 5b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['addLines4c5b',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Add lines 4c and 5b '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 6'], [2022, 'Schedule SE, Line 6'], [2023, 'Schedule SE, Line 6'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['maximumAmountofCombinedWages',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Maximum amount of combined wages and self-employment earnings subject to social security tax '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 7'], [2022, 'Schedule SE, Line 7'], [2023, 'Schedule SE, Line 7'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalSocialSecurityWages',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Total social security wages and tips'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 8a'], [2022, 'Schedule SE, Line 8a'], [2023, 'Schedule SE, Line 8a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['unreportedTips',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Unreported tips subject to social security tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 8b'], [2022, 'Schedule SE, Line 8b'], [2023, 'Schedule SE, Line 8b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['wagesSubjectToSocialSecurity',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Wages subject to social security tax from Form 8919, line 10'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 8c'], [2022, 'Schedule SE, Line 8c'], [2023, 'Schedule SE, Line 8c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['add8a8b8c',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Add lines 8a, 8b, and 8c '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 8d'], [2022, 'Schedule SE, Line 8d'], [2023, 'Schedule SE, Line 8d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line9SchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Subtract line 8d from line 7'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 9'], [2022, 'Schedule SE, Line 9'], [2023, 'Schedule SE, Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line10SchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Multiply the smaller of line 6 or line 9 by 12.4%'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 10'], [2022, 'Schedule SE, Line 10'], [2023, 'Schedule SE, Line 10'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['line11SchSE',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Multiply line 6 by 2.9%'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 11'], [2022, 'Schedule SE, Line 11'], [2023, 'Schedule SE, Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['selfEmploymentTax',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Self-employment tax'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 12'], [2022, 'Schedule SE, Line 12'], [2023, 'Schedule SE, Line 12'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['deductionForOneHalfOfSelfemployment',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return 'Deduction for one-half of self-employment tax.'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Schedule SE, Line 13'], [2022, 'Schedule SE, Line 13'], [2023, 'Schedule SE, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],


  /*START OTHER QUESTIONS*/
  ['hasChildren',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Does the client have dependent children?',
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      linesByYear: new Map([[2019, ''], [2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasChildren === 'Yes'
      }
    }
  ],
  ['clientHasForm1099',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Does the client has a Form 1099',
      type: 'RADIO_GROUP',
      availableInYears: [ 2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 1099'], [2021, 'Form 1099'], [2022, 'Form 1099'], [2023, 'Form 1099'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasChildren === 'Yes'
      }
    }
  ],
  ['clientHasForm8880',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Does the client has a Form 8880?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 8880'], [2021, 'Form 8880'], [2022, 'Form 8880'], [2023, 'Form 8880'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientHasChildren === 'Yes'
      }
    }
  ],
  ['clientHasForm2441',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor, model) => 'Is there a Form 2441 on ' + model.clientIDTaxYear  + ' tax return?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 2441'], [2021, 'Form 2441'], [2022, 'Form 2441'], [2023, 'Form 2441'] ]),
      displayRuleFunction: () => true,
    }
  ],
  ['line3form2441',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Qualified child and dependent care expenses.',
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 2441, Line 3'], [2021, 'Form 2441, Line 3'], [2022, 'Form 2441, Line 3'], [2023, 'Form 2441, Line 3']]),
      displayRuleFunction: (q, instance) => true,
    }
  ],
  ['clientHasForm1120',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Is there a Form 1120 on tax return?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 1120'], [2021, 'Form 1120'], [2022, 'Form 1120'], [2023, 'Form 1120'] ]),
      displayRuleFunction: () => true,
    }
  ],
  ['clientHasForm1120S',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Does the client have an interest in one or more pass-through entities as either a shareholder of an S corporation, a partner in a partnership, or a beneficiary of a trust?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 1120 S'], [2021, 'Form 1120 S'], [2022, 'Form 1120 S'], [2023, 'Form 1120 S']]),
      displayRuleFunction: () => true,
    }
  ],
  ['clientHasForm8863',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Does the client pay tuition for himself or a qualified dependent?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 8863'], [2021, 'Form 8863'], [2022, 'Form 8863'], [2023, 'Form 8863']]),
      displayRuleFunction: () => true,
    }
  ],
  ['clientHasForm8839',
    {
      tabSection: 'extractions',
      subsection: 'others',
      description: (isAdvisor) => 'Will the client adopt a child this year?',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, 'Form 8839'], [2021, 'Form 8839'], [2022, 'Form 8839'], [2023, 'Form 8839']]),
      displayRuleFunction: () => true,
    }
  ],
  /*['netProfitOrLoss_se',
    {
      tabSection: 'extractions',
      subsection: 'schSE',
      description:(isAdvisor)=>{ return ''},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Schedule E, Line 2'], [2023, 'Schedule E, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],*/
  /*END SCH-SE QUESTIONS*/

  /*START FORM 8995 QUESTIONS*/

  ['clientHasForm8995',
    {
      tabSection: 'extractions',
      subsection: 'form8995',
      description:(isAdvisor)=>{ return 'Client has Form 8995'},
      type: 'RADIO_GROUP',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8995'], [2022, 'Form 8995'], [2023, 'Form 8995'] ]),
      displayRuleFunction: (q) => {
        // return (q.clientHasForm8995A === 'No')
        return true
      }
    }
  ],
  ['totalQualifiedBusinessIncome',
    {
      tabSection: 'extractions',
      subsection: 'form8995',
      description:(isAdvisor)=>{ return 'Total qualified business income'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8995, Line 4'], [2022, 'Form 8995, Line 4'], [2023, 'Form 8995, Line 4'] ]),
      displayRuleFunction: (q) => {
        return (q.clientHasForm8995A === 'No' && q.clientHasForm8995 === 'Yes')
      }
    }
  ],
  ['qualifiedBusinessIncomeDeduction',
    {
      tabSection: 'extractions',
      subsection: 'form8995',
      description:(isAdvisor)=>{ return 'Qualified business income deduction'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8995, Line 15'], [2022, 'Form 8995, Line 15'], [2023, 'Form 8995, Line 15'] ]),
      displayRuleFunction: (q) => {
       return (q.clientHasForm8995A === 'No' && q.clientHasForm8995 === 'Yes')
      }
    }
  ],
  ['totalReit',
    {
      tabSection: 'extractions',
      subsection: 'form8995',
      description:(isAdvisor)=>{ return 'Total qualified REIT dividends and PTP (loss) carryforward.'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8995, Line 17'], [2022, 'Form 8995, Line 17'], [2023, 'Form 8995, Line 17'] ]),
      displayRuleFunction: (q) => {
       return (q.clientHasForm8995A === 'No' && q.clientHasForm8995 === 'Yes')
      }
    }
  ],
  ['clientHasForm8995alert',
    {
      tabSection: 'extractions',
      subsection: 'form8995',
      description:(isAdvisor)=>{ return 'This section is disabled because you have a form 8995 A'},
      type: 'LABEL',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, ''], [2023, '']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  /*END FORM 8995 QUESTIONS*/

  /*START FORM 8995 A QUESTIONS*/
  ['clientHasForm8995A',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Does the client has Form 8995-A?'},
      type: 'RADIO_GROUP',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A'], [2023, 'Form 8995 A']]),
      displayRuleFunction: (q) => {
        return true//(q.clientHasForm8995 === 'No')
      }
    }
  ],
  ['form8995A27',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Total qualified business income'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 27'], [2023, 'Form 8995 A, Line 27']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A28',
  {
    tabSection: 'extractions',
    subsection: 'form8995a',
    description:(isAdvisor)=>{ return 'Qualified REIT dividends and publicly traded partnership (PTP) income or (loss)'},
    type: 'MONEY',
    availableInYears: [2022, 2023],
    isExtraction: true,
    linesByYear: new Map([[2022, 'Form 8995 A, Line 28'], [2023, 'Form 8995 A, Line 28']]),
    displayRuleFunction: (q) => {
      return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
    }
  }
],
  ['form8995A29',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Qualified REIT dividends and PTP (loss) carryforward from prior years'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 29'], [2023, 'Form 8995 A, Line 29']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A30',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Total qualified REIT dividends and PTP income'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 30'], [2023, 'Form 8995 A, Line 30']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A31',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'REIT and PTP component. Multiply line 30 by 20%'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 31'], [2023, 'Form 8995 A, Line 31']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A32',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Qualified business income deduction before the income limitation'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 32'], [2023, 'Form 8995 A, Line 32']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A33',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Taxable income before qualified business income deduction'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 33'], [2023, 'Form 8995 A, Line 33']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A34',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Net capital gain'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 34'], [2023, 'Form 8995 A, Line 34']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A35',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Subtract line 34 from line 33'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 35'], [2023, 'Form 8995 A, Line 35']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A36',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Income limitation. Multiply line 35 by 20%'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 36'], [2023, 'Form 8995 A, Line 36']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A37',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'Qualified business income deduction before the domestic production activities deduction (DPAD)'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 37'], [2023, 'Form 8995 A, Line 37']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A38',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'DPAD under section 199A(g) allocated from an agricultural or horticultural cooperative'},
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8995 A, Line 38'], [2023, 'Form 8995 A, Line 38']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
      }
    }
  ],
  ['form8995A39',
  {
    tabSection: 'extractions',
    subsection: 'form8995a',
    description:(isAdvisor)=>{ return 'Qualified business income deduction'},
    type: 'MONEY',
    availableInYears: [2022, 2023],
    isExtraction: true,
    linesByYear: new Map([[2022, 'Form 8995 A, Line 39'], [2023, 'Form 8995 A, Line 39']]),
    displayRuleFunction: (q) => {
      return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
    }
  }
],
['form8995A40',
  {
    tabSection: 'extractions',
    subsection: 'form8995a',
    description:(isAdvisor)=>{ return 'Total qualified REIT dividends and PTP (loss) carryforward.'},
    type: 'MONEY',
    availableInYears: [2022, 2023],
    isExtraction: true,
    linesByYear: new Map([[2022, 'Form 8995 A, Line 40'], [2023, 'Form 8995 A, Line 40']]),
    displayRuleFunction: (q) => {
      return q.clientHasForm8995 === 'No' && q.clientHasForm8995A === 'Yes'
    }
  }
],
  ['clientHasForm8995Aalert',
    {
      tabSection: 'extractions',
      subsection: 'form8995a',
      description:(isAdvisor)=>{ return 'This section is disabled because you have a form 8995'},
      type: 'LABEL',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, ''], [2023, '']]),
      displayRuleFunction: (q) => {
        return q.clientHasForm8995 === 'Yes'
      }
    }
  ],
  /*END FORM 8995 A QUESTIONS*/

  /*START FORM 1099 QUESTIONS*/
  ['reiteligibleDeduction',
    {
      tabSection: 'extractions',
      subsection: 'others', //form1099
      description:(isAdvisor)=>{ return 'Reiteligible Deduction'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 1099, Line 1'], [2022, 'Form 1099, Line 1'], [2023, 'Form 1099, Line 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END FORM 1099 QUESTIONS*/

  /*START FORM 5498 QUESTIONS*/
  ['contributionsMade',
    {
      tabSection: 'extractions',
      subsection: 'form5498',
      description:(isAdvisor)=>{ return 'Contributions Made.'},
      type: 'MONEY',
      availableInYears: [2021],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 5498, Line 1']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['currentContributionToWorkplaceRetirementPlanSEP',
    {
      tabSection: 'extractions',
      subsection: 'form5498',
      description:(isAdvisor)=>{ return 'Current Contribution to Workplace Retirement Plan SEP.'},
      type: 'MONEY',
      availableInYears: [2021],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 5498, Line 8']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['currentContributionToWorkplaceRetirementPlanSIMPLE',
    {
      tabSection: 'extractions',
      subsection: 'form5498',
      description:(isAdvisor)=>{ return 'Current Contribution to Workplace Retirement Plan SIMPLE.'},
      type: 'MONEY',
      availableInYears: [2021],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 5498, Line 9']]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END FORM 5498 QUESTIONS*/

  /*START FORM 8880 QUESTIONS*/
  ['contributionMade457_1',
    {
      tabSection: 'extractions',
      subsection: 'others', //form8880
      description:(isAdvisor)=>{ return 'Contribution made to plan 457'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8880, Line 2'], [2022, 'Form 8880, Line 2'], [2023, 'Form 8880, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['contributionMade457_2',
    {
      tabSection: 'extractions',
      subsection: 'others', //form8880
      description:(isAdvisor)=>{ return 'Spouse contribution made to plan 457'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 8880, Line 2'], [2022, 'Form 8880, Line 2'], [2023, 'Form 8880, Line 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END FORM 8880 QUESTIONS*/

  /*START FORM 709 QUESTIONS*/
  ['creditForForeignGift',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Credit for foreign gift taxes '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 13'], [2022, 'Form 709, Line 13'], [2023, 'Form 709, Line 13'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalCreditsGift',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Total credits. Add lines 12 and 13'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 14'], [2022, 'Form 709, Line 14'], [2023, 'Form 709, Line 14'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['balanceGifts',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Balance. Subtract line 14 from line 6'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 15'], [2022, 'Form 709, Line 15'], [2023, 'Form 709, Line 15'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['generationSkippingTransferTaxes',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Generation-skipping transfer taxes '},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 16'], [2022, 'Form 709, Line 16'], [2023, 'Form 709, Line 16'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['totalTaxGifts',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Total tax. Add lines 15 and 16 .'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 17'], [2022, 'Form 709, Line 17'], [2023, 'Form 709, Line 17'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['generationSkippingTransferTaxesPrepaid',
    {
      tabSection: 'extractions',
      subsection: 'form709',
      description:(isAdvisor)=>{ return 'Gift and generation-skipping transfer taxes prepaid with extension of time to file'},
      type: 'MONEY',
      availableInYears: [2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, 'Form 709, Line 18'], [2022, 'Form 709, Line 18'], [2023, 'Form 709, Line 18'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END FORM 709 QUESTIONS*/

  /*END TAB EXTRACTIONS*/

  /*START TAB IRA*/
  ['clientIDIfIsSelfEmployedOrBusinessOwnerAndExpectsIncomeOf200000ForNextThreeYears',
    {
      tabSection: 'ira',
      subsection: null,
      description: (isAdvisor) => { return `${isAdvisor ? 'Does the client' : 'Do you'} expect ${isAdvisor ? 'their' : 'your'} annual income to exceed $200,000 for the next three years?`},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      linesByYear: new Map([]),
      displayRuleFunction: (q, instance) => true,
    }
  ],
  ['clientIDContributingToSEPIRA',
    {
      tabSection: 'ira',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Is the client' : 'Are you'} contributing to a SEP IRA or a SIMPLE IRA?`},
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021],
      isExtraction: true,
      linesByYear: new Map([[2019, ''], [2020, ''], [2021, '']]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  /*END TAB IRA*/


  /*START TAB INCOME*/
  /*START INCOME*/
  ['clientIDIncomeExpectationsFollowingYear',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the client' : 'Do you'} anticipate a decrease or increase in income in the next year?`},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_TAX_SALARY_INCREMENTS',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2021, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDExpectsToHaveWayLowerIncomeGoingForward',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the client' : 'Do you'} anticipate ${isAdvisor ? 'his/her' : 'your'} income will be significantly lower going forward?`},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Significantly enough to change his/her current tax bracket'], [2021, 'Significantly enough to change his/her current tax bracket'], [2022, 'Significantly enough to change his/her current tax bracket'], [2023, 'Significantly enough to change his/her current tax bracket'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientIDIncomeExpectationsFollowingYear === 'Decrease';
      }
    }
  ],
  ['clientIDExpectsToHaveLargerIncomeGoingForward',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client anticipate his/her income will be significantly higher going forward?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Significantly enough to change his/her current tax bracket'], [2021, 'Significantly enough to change his/her current tax bracket'], [2022, 'Significantly enough to change his/her current tax bracket'], [2023, 'Significantly enough to change his/her current tax bracket'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientIDIncomeExpectationsFollowingYear === 'Increase';
      }
    }
  ],
  ['clientID2018Income',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `How much ${isAdvisor ? 'does the client' : 'do you'} expect ${isAdvisor ? 'his/her' : 'your'} adjusted gross income will be in the next year?`},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientIDIncomeExpectationsFollowingYear === 'Increase' || q.clientIDIncomeExpectationsFollowingYear === 'Decrease'
      }
    }
  ],
  ['clientIDIncomeREITorQPTP',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the client' : 'Do you'} have income from a REIT or Qualified Publicly Traded Partnership?`},
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Client 1'], [2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientIDFillingStatus === 'Married';
      }
    }
  ],
  ['clientIDIncomeREITorQPTP_2',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the spouse' : 'Does your spouse'} have income from a REIT or Qualified Publicly Traded Partnership?`},
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, 'Client 2'],[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly';
      }
    }
  ],
  ['clientIDHasQuestionsAboutTaxRecordRetention',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the client' : 'Do you'} have questions about proper tax record retention and documentation guidelines?`},
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDOwnAnyStocks',
    {
      tabSection: 'income',
      subsection: null,
      description:(isAdvisor)=>{ return `${isAdvisor ? 'Does the client' : 'Do you'} own any stocks, bonds, mutual funds or collectibles such as gold?`},
      type: 'RADIO_GROUP',
      availableInYears: [2019, 2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2019, ''], [2020, ''], [2021, ''], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END TAB INCOME*/
  /*END INCOME*/


  /*START TAB BUSINESS OWNER*/
  ['clientPrincipalAssetIsEmpoyees', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Is the client' : 'Are you'} in one of the following industries: health, law, accounting, actuarial science, performing arts, consulting, athletics, financial services, investing and investment management, trading, dealing in certain assets or any trade or business in which the principal asset is the reputation or skill of one or more of its employees?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: () => true,
  }],
  ['clientIDBusinessProvideProfessionalConsultingServices', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Does the client\'s' : 'Does your'}  business provide professional or consulting services?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: () => true,
  }],
  ['clientIDWorksAsAProfessionalInYourOwnSCorporation', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Does the client' : 'Do you'}  work as a professional in ${isAdvisor? 'his/her' : 'your'} own corporation and have taxable income of less than $315,000 (married filing jointly or $157,500, single, head-of-household
      or married filing separately)?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => instance.clientIDNetWorth < 157500 || instance.joinIncome < 31500 || instance.clientHasSCorp === 'S Corp',
  }],
  ['clientIDWorksInOwnBusinessWhichRetainsIncome', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Does the client' : 'Do you'} work for ${isAdvisor? 'his/her' : 'your'} own company? If so, does that company retain or reinvest most of its income?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => q.clientHasScheduleC === 'Yes' && instance.clientHasForm1120S === 'Yes',
  }],
  ['clientIDEmployerDomestic',
    {
      tabSection: 'businessOwner',
      subsection: null,
      description: (isAdvisor) => `${isAdvisor ? 'Is the client' : 'Are you'} the employer of any of the following:`,
      type: 'RADIO_GROUP',
      sourceForSelect: 'CREAR GRUPO',
      isExtraction: false,
      availableInYears: [2019, 2020, 202, 2022, 2023],
      linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => q.clientHasScheduleC === 'Yes' && instance.clientHasForm1120S === 'Yes',
    }
  ],
  ['clientIDSelfEmployedOrSCorp', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Is the client' : 'Are you'} self-employed or operate a business as an S corporation?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, 'Client 1'], [2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1']]),
    displayRuleFunction: (q, instance) => q.clientHasScheduleC === 'Yes' && instance.clientHasForm1120S === 'Yes',
  }],
  ['clientIDSelfEmployedOrSCorp_2', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor ? 'Is the spouse' : 'Is your spouse'} self-employed or operate a business as an S corporation?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, 'Client 2'], [2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2']]),
    displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
  }],
  ['clientIDBusinessProvidesMoreThan1OtherBusinessActivity', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `In addition to providing services, ${isAdvisor ? 'does the client\'s' : 'does your'} business entity provide more than one other business activity?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDValuableAssetsToSellIn5YearsOrMore', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `Does the corporation have highly appreciated assets that are expected to be sold, but not within the next five years?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDFormingBusinessLeverageRealEstate', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Is the client' : 'Are you'} forming a business with substantial leverage or that will own real estate?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDFormingInvestorsCTrustForeign', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Is the client' : 'Are you'} forming a business in which one or more investors is a C corp, trust or foreign person or that will have ownership interests that are not identical?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDFutureExpectedLosses', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client\'s' : 'Does your'} business anticipate losses in future years?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDHomeOfficeSqft', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client' : 'Do you'} have a home office of no more than 300 square feet?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDHomeOffice', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `Is it the primary place for ${isAdvisor? 'his/her' : 'your'} business, used for storage or to meet clients?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => q.clientIDHomeOfficeSqft === 'Yes',
  }],
  ['clientIDClubOrg', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `Are dues to country clubs or similar organizations being incurred for business?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDClubOrg', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client' : 'Do you'} maintain a retirement plan for employees requiring withholding from employee wages?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDSelfEMployedAlone', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Is the client' : 'Are you'} self-employed and have no employees other than ${isAdvisor? 'himself/herself' : 'yourself'}?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => q.clientIDMantainRetPlanWithholdWage === 'No',
  }],
  ['clientIDExpensesMealsEntertain', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client\'s' : 'Does your'} business require expenses such as meals and entertainment?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDHiredEmployees', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Has the client' : 'Have you'} hired new employees during the current year?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => q.clientIDSelfEMployedAlone === 'No',
  }],
  ['clientIDIncomeProfession', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client' : 'Do you'} generate income from the profession in ${isAdvisor? 'his/her' : 'your'} own business or S corp?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDCapitalizingBusiness', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Is the client' : 'Are you'} contemplating capitalizing a business with debt as well as equity?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => instance.clientHasBusiness === 'Yes' || q.scheduleCDetected === 'Yes',
  }],
  ['clientIDCCorpCapitalizingBusiness', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Is the client' : 'Are you'} contemplating capitalizing a business structured as a C corporation with debt as well as equity?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => q.clientIDCapitalizingBusiness === 'Yes',
  }],
  ['clientIDCarPersonalBusiness', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'The client' : 'You'} uses a car for business purposes, how much of its usage is allocated to the business?`,
    type: 'SELECT',
    sourceForSelect: 'CONST_CAR_BUSINESS_PURPOSE',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  ['clientIDExpenseCarriedFuture', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Did the client' : 'Did you'} generate current-year expenses, deductions or losses that will be carried over to future years?`,
    type: 'RADIO_GROUP',
    sourceForSelect: 'CONST_ABSOLUTES_UNSURE_TAX_ONLY',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],
  /*['clientIDEmployeePayrollTaxes', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client\'s' : 'Does your'} business have employees and payroll taxes?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],*/
  /*['clientIDEmployeeCovidExpenses', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'Does the client' : 'Do you'} have made payments to employees to cover their expenses for groceries, rent, utilities, medical payments or other costs related to COVID-19?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],*/
  /*['clientIDEmployeeCovidWages', {
    tabSection: 'businessOwner',
    subsection: null,
    description: (isAdvisor) => `${isAdvisor? 'the client\'s' : 'your'} business paid on leave employees related to COVID-19?`,
    type: 'RADIO_GROUP',
    isExtraction: false,
    availableInYears: [2019, 2020, 2021, 2022, 2023],
    linesByYear: new Map([[2019, ''],[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
    displayRuleFunction: (q, instance) => true,
  }],*/
  /*END TAB BUSINESS OWNER*/


  /*START TAB CHARITY AND GIFTS*/
  /*START CHARITY AND GIFTS*/
  ['clientIDHasQuestionsAboutCharitableGifting',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have questions about charitable gifting and other gifts made to others?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDWillingToDonate5000',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client' : 'you') + ' be willing to donate more than $5,000 in one year to charity for a tax benefit?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDCharitableGiftsTicketsorMembership',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' intend to make a charitable gift that includes tickets to a charitable event or membership dues?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDHouseOrFarmToCharity',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have a property '+ (isAdvisor ? 'they' : 'you') + ' wish to give to charity upon ' + (isAdvisor ? 'his/her ' : '') + 'death?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_HOUSE_OR_FARM',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDGiftToPayEducation',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to make a gift to be used to pay for future education?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDGiftToDisabled',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to make a gift to a disabled individual?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDFinanciallySupportsToAdultWhoIsOver18',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' support an adult who is not a full-time student between the ages of 18-24?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDFinanciallySupportsToAdultWhoIsOver18_2',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' support an adult who is not a full-time student between the ages of 18-24?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  /*['clientID2020ItemizedCharitableDonations',
    {
      tabSection: 'charityAndGifts',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' plan on making charitable donations?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],*/
  /*END CHARITY AND GIFTS*/
  /*END TAB CHARITY AND GIFTS*/

  /*START TAB INVESTMENT*/
  ['clientIDShortTermCapGainAndUnrealizedLosses',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have net short-term capital gains for the year?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDShortTermCapGainAndUnrealizedLosses_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have net short-term capital gains for the year?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDNetLossesCapGainLosses',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have net losses on all capital gain and loss transactions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDNetLossesCapGainLosses_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have net losses on all capital gain and loss transactions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDHasUnrealizedLosses',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have unrealized losses on securities ' + (isAdvisor? 'he/she' : 'you') + ' currently own?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDHasUnrealizedLosses_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have unrealized losses on securities ' + (isAdvisor? 'he/she' : 'you') + ' currently own?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDSellAtLoss',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client' : 'you') + ' sell securities at a loss?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDSellAtLoss_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the spouse' : 'your spouse') + ' sell securities at a loss?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDLongTimeHorizon',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have a long investment time horizon, have gains that ' + (isAdvisor? 'he/she' : 'you') + ' wish to realize without having to pay tax on the gains and want the chance to avoid capital gains tax on future appreciation?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDLongTimeHorizon_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have a long investment time horizon, have gains that ' + (isAdvisor? 'he/she' : 'you') + ' wish to realize without having to pay tax on the gains and want the chance to avoid capital gains tax on future appreciation?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDHasOldTradeBusinessStock',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' own stock issued after 8/10/93 in a corporation engaged in an active trade or business that has not had assets of more than $50 million at any time?'},
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDHasOldTradeBusinessStock_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' own stock issued after 8/10/93 in a corporation engaged in an active trade or business that has not had assets of more than $50 million at any time?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDLittleValueNonLiquidAssets',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have investments in non-liquid assets that have little value but are not worthless at year-end?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDLittleValueNonLiquidAssets_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have investments in non-liquid assets that have little value but are not worthless at year-end?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientHasForm1040SchedCLineG',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have an investment in a business that ' + (isAdvisor? 'he/she' : 'you') + ' don\'t actively work in or rental activity that generates a loss?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientHasForm1040SchedCLineG_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have an investment in a business that ' + (isAdvisor? 'he/she' : 'you') + ' don\'t actively work in or rental activity that generates a loss?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDHasInvestmentIncome',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have investment income?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDHasInvestmentIncome_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have investment income?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDInvestmentInterestExpenseCarryover',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have an investment interest expense carryover?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDInvestmentInterestExpenseCarryover_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have an investment interest expense carryover?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDPurchaseInvestmentNotSold',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Did the client' : 'Did you') + ' purchase an investment or business property that is not yet sold or fully depreciated?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDPurchaseInvestmentNotSold_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Did the spouse' : 'Did your spouse') + ' purchase an investment or business property that is not yet sold or fully depreciated?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  ['clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' anticipate gains from real estate, artwork, or private business in the near future?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return true}
    }
  ],
  ['clientIDPlansToHaveGainsOrLossesFromRealEstateArtworkETCInNearFuture_2',
    {
      tabSection: 'investment',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' anticipate gains from real estate, artwork, or private business in the near future?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly',
    }
  ],
  /*END TAB INVESTMENT*/


  /*START TAB EMPLOYER*/
  ['clientIDEmployerDependentCareBenefit',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'employer provide child and dependent care benefits?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => {return (instance.clientHasChildren === 'Yes')}
    }
  ],
  ['clientIDEmployerDependentCareBenefit_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'spouse\'s employer provide child and dependent care benefits?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (instance.clientHasChildren === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDEmployerHealthCareBenefit',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'employer provide for health-care benefits?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => {return (instance.clientMaritalStatus === 'Married')}
    }
  ],
  ['clientIDEmployerHealthCareBenefit_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'spouse\'s employer provide for health-care benefits?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}

    }
  ],
  ['clientIDEmployerFSA',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'employer provide benefits under a flexible spending account (FSA)?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDEmployerFSA_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'spouse\'s employer provide benefits under a flexible spending account (FSA)?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {
        return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'
      }
    }
  ],
  ['clientIDEmployerProvidesStockOptions',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does ' + (isAdvisor ? 'the client\'s ' : 'your ') + 'employer provide'+ (isAdvisor ? 'him/her' : 'you') + 'with stock options as part of' + (isAdvisor ? 'him/her' : 'your')},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDStocOptCompPack',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client ' : 'Do you ') + 'have stock options as part of a compensation package, and can'+ (isAdvisor ? ' he/she ' : ' you ') + 'elect to exercise the options prior to their becoming vested?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes')}
    }
  ],
  ['clientIDStocOptCompPack_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse ' : 'Does your spouse ') + 'have stock options as part of a compensation package, and can'+ (isAdvisor ? ' he/she ' : ' you ') + 'elect to exercise the options prior to their becoming vested?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDStockOptCorpEmployer',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client ' : 'Do you ') + 'have stock options or restricted stock from a corporate employer that has a Section 83(i) plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes')}
    }
  ],
  ['clientIDStockOptCorpEmployer_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse ' : 'Does your spouse ') + 'have stock options or restricted stock from a corporate employer that has a Section 83(i) plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDISOEmployer',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have an incentive stock option (ISO) granted to' + (isAdvisor ? ' his/her ' : ' your ') + 'by' + (isAdvisor ? ' his/her ' : ' your ') + 'employer?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes')}
    }
  ],
  ['clientIDISOEmployer_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have an incentive stock option (ISO) granted to' + (isAdvisor ? ' his/her ' : ' your ') + 'by' + (isAdvisor ? ' his/her ' : ' your ') + 'employer?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' &&instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly' )}
    }
  ],
  ['clientIDExerciseISO',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Can'+ (isAdvisor ? ' the client ' : ' you ') +'exercise an incentive stock option (ISO) granted' + (isAdvisor ? ' him/her ' : ' your ') + 'by'+  (isAdvisor ? ' his/her ' : ' your ') +'employer?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDISOEmployer === 'Yes')}
    }
  ],
  ['clientIDExerciseISO_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Can'+ (isAdvisor ? ' the spouse ' : ' your spouse ') +'exercise an incentive stock option (ISO) granted' + (isAdvisor ? ' him/her ' : ' your ') + 'by'+ + (isAdvisor ? ' his/her ' : ' your ') +'employer?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDISOEmployer === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDEmployerProvidesStockOptions',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does '+ (isAdvisor ? ' the client ' : ' you ') +'employer provide' + (isAdvisor ? ' him/her ' : ' your ') + 'with stock options as part of'+ + (isAdvisor ? ' his/her ' : ' your ') +' compensation?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDISOEmployer === 'Yes')}
    }
  ],
  ['clientIDExcersiceISOPrior',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Can'+ (isAdvisor ? ' the client ' : ' you ') +'elect to exercise an ISO before to the date of vesting?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDExerciseISO === 'Yes')}
    }
  ],
  ['clientIDExcersiceISOPrior_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return 'Can'+ (isAdvisor ? ' the spuse ' : ' your spouse ') +'elect to exercise an ISO before to the date of vesting?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDExerciseISO === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDTerminateEmployISO',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client ' : 'Are you ') + 'considering terminating employment from an employer that has given'+ (isAdvisor ? ' he/she ' : ' you ') + 'incentive stock options?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {
        return q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDISOEmployer === 'Yes'
      }
    }
  ],
  ['clientIDTerminateEmployISO_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse ' : 'Is your spouse ') + 'considering terminating employment from an employer that has given'+ (isAdvisor ? ' he/she ' : ' you ') + 'incentive stock options?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientIDEmployerProvidesStockOptions === 'Yes' && q.clientIDISOEmployer === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'
      }
    }
  ],
  ['clientIDVestProperty',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' entitled to vest in property by working for a period of time, or until the occurrence of a future event?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes')}
    }
  ],
  ['clientIDVestProperty_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spoue') + ' entitled to vest in property by working for a period of time, or until the occurrence of a future event?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes')}
    }
  ],
  ['clientIDIndependentContractorISO',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' an independent contractor to a corporation with a stock option plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDIndependentContractorISO_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' an independent contractor to a corporation with a stock option plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return (q.clientIDEmployerProvidesStockOptions === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly' )}
    }
  ],
  ['clientIDHasOptionToChooseHighDeductibleMedicalPlan',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have the option to choose a high deductible medical plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDIsOnHighDeductibleMedicanPlan',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' on the high deductible medical plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' maximizing permitted contributions to a Health Savings Account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {
        return q.clientIDIsOnHighDeductibleMedicanPlan === 'Yes' || q.clientIDIsOnHighDeductibleMedicanPlan_2 === 'Yes'
      }
    }
  ],
  ['clientIDIsMaximizingPermittedContributionstoHealthSavingsAccount_2',
    {
      tabSection: 'employer',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' maximizing permitted contributions to a Health Savings Account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {
        return q.clientIDIsOnHighDeductibleMedicanPlan === 'Yes' || q.clientIDIsOnHighDeductibleMedicanPlan_2 === 'Yes' && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')
      }
    }
  ],
  /*END TAB EMPLOYER*/

  /*START TAB RETIREMENT*/
  ['clientIDAboutToRetire',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' about to retire?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired) }
    }
  ],
  ['clientIDAboutToRetire_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' about to retire?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDHasAccessToWorkplaceRetirementPlan',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have access to a workplace retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return !instance.clientIsRetired }
    }
  ],
  ['clientIDHasAccessToWorkplaceRetirementPlan_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have access to a workplace retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return !instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly' }
    }
  ],
  ['clientIDIsMaximizingContributions',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you')+ ' maximizing ' + (isAdvisor ? 'his/her' : 'your') +' contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes') }
    }
  ],
  ['clientIDIsMaximizingContributions_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse')+ ' maximizing ' + (isAdvisor ? 'his/her' : 'your') +' contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDHasOrSpouseAccessToRoth401k',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have access to a 401(k)?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes') }
    }
  ],
  ['clientIDPlanPermitsToMakeAfterTaxContributionsTo401k',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Does the plan permit after-tax contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasOrSpouseAccessToRoth401k === 'Me') }
    }
  ],
  ['clientIDPlanPermitsToMakeAfterTaxContributionsTo401k_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Spouse. Does the plan permit after-tax contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasOrSpouseAccessToRoth401k === 'Me' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDHasAccessToFlexibleSpendingAccount',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client\'s' : 'Does your') + ' employer offer a flexible spending account (FSA) to which ' + (isAdvisor ? ' he/she is' : 'you are') + ' able to contribute?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired) }
    }
  ],
  ['clientIDHasAccessToFlexibleSpendingAccount_2"',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client spouse\'s' : 'Does your spouse\'s') + ' employer offer a flexible spending account (FSA) to which ' + (isAdvisor ? 'he/she is' : 'you are') + ' able to contribute?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'], [2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client\'s' : 'Does your') + ' employer matches ' + (isAdvisor ? 'his/her' : 'your') + ' contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasAccessToFlexibleSpendingAccount === 'Yes') }
    }
  ],
  ['clientIDIsParticipatingInFSAAndMatchingContributionsToExpectedHealthExpenses_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client\'s spouse\'s' : 'Does your spouse\'s') + ' employer matches ' + (isAdvisor ? 'his/her' : 'your') + ' contributions?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasAccessToFlexibleSpendingAccount === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDWorksForNonProfitOrGovernmentalEntity',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' work for a nonprofit or a government entity?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired) }
    }
  ],
  ['clientIDWorksForNonProfitOrGovernmentalEntity_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' work for a nonprofit or a government entity?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'
      ) }
    }
  ],
  ['clientIDHasAccessTo457Plan',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have access to a 457 plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && q.clientIDWorksForNonProfitOrGovernmentalEntity === 'Yes') }
    }
  ],
  ['clientIDHasAccessTo457Plan_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have access to a 457 plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && q.clientIDWorksForNonProfitOrGovernmentalEntity === 'Yes') }
    }
  ],
  ['clientIDIsMaximizingContributionsOn457Plan',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' maximizing ' + (isAdvisor ? 'his/her' : 'your') + ' contributions to a 457 plan if available?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasAccessTo457Plan === 'Yes' || q.clientIDHasAccessTo457Plan_2 === 'Yes') }
    }
  ],
  ['clientIDIsMaximizingContributionsOn457Plan_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' maximizing ' + (isAdvisor ? 'his/her' : 'your') + ' contributions to a 457 plan if available?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasAccessTo457Plan === 'Yes' || q.clientIDHasAccessTo457Plan_2 === 'Yes') && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDHasTraditionalOrRolloverIRA',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you')+' have a traditional IRA, rollover IRA, or inherited IRA account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.isRetired) }
    }
  ],
  ['clientIDHasTraditionalOrRolloverIRA_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse')+' have a traditional IRA, rollover IRA, or inherited IRA account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.isRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'
      ) }
    }
  ],
  ['clientIDWithdrawNoPenalty',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Withdraw funds from a qualified retirement plan or IRA without a penalty?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDWithdrawNoPenalty_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Withdraw funds from a qualified retirement plan or IRA without a penalty?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'
      ) }
    }
  ],
  ['clientIDDistPrincipalResidence',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Take a distribution from an IRA to buy the first principal residence for '+ (isAdvisor ? 'himself/herself' : 'you') + ', ' + (isAdvisor ? 'his/her' : 'your') + ' spouse, ' + (isAdvisor ? 'his/her' : 'your') + ' child or grandchild?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDDistPrincipalResidence_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Take a distribution from an IRA to buy the first principal residence for '+ (isAdvisor ? 'himself/herself' : 'you') + ', ' + (isAdvisor ? 'his/her' : 'your') + ' spouse, ' + (isAdvisor ? 'his/her' : 'your') + ' child or grandchild?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDDistMedExpenses',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Take a distribution from a qualified retirement plan or IRA to cover unreimbursed medical expenses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDDistMedExpenses_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Take a distribution from a qualified retirement plan or IRA to cover unreimbursed medical expenses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDDistHealthInsurance',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Take a distribution from a qualified retirement plan or IRA to cover health insurance while unemployed?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDDistHealthInsurance_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Take a distribution from a qualified retirement plan or IRA to cover health insurance while unemployed?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDDistEducExpense',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Take a distribution from an IRA to cover qualified higher education expenses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDDistEducExpense_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Take a distribution from an IRA to cover qualified higher education expenses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDTakeDist',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Take a distribution from a Roth IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60) }
    }
  ],
  ['clientIDTakeDist_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge59',
      description:(isAdvisor)=>{ return 'Spouse. Take a distribution from a Roth IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientAge < 60 && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientID70WorkNoOwner',
    {
      tabSection: 'retirement',
      subsection: 'underAge72',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' over age 72; but continue to work for an employer in which ' + (isAdvisor ? 'he/she is' : 'you are') + 'not an owner of more than 5% of the company (directly or through attribution) with a qualified plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDTaxYear === '2019' && instance.clientAge >= 72 && !instance.clientIsRetired ) }
    }
  ],
  ['clientID70WorkNoOwner_2',
    {
      tabSection: 'retirement',
      subsection: 'underAge72',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' over age 72; but continue to work for an employer in which ' + (isAdvisor ? 'he/she is' : 'you are') + 'not an owner of more than 5% of the company (directly or through attribution) with a qualified plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDTaxYear === '2019' && instance.clientAge >= 72 && !instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDExpensesIRA',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'How are expenses of ' + (isAdvisor ? 'the client\'s' : 'your') +' IRAs and Roth IRAs paid?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_IRAS',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes') }
    }
  ],
  ['clientIDExpensesIRA_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'How are expenses of ' + (isAdvisor ? 'the client\'s spouse'  : 'your spouse') +' IRAs and Roth IRAs paid?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_IRAS',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDBorrowFromRetPlan',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering borrowing from' + (isAdvisor ? 'his/her' : 'your') + ' retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDBorrowFromRetPlan_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' considering borrowing from' + (isAdvisor ? 'his/her' : 'your') + ' retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDConsideringContributionIRA',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering making a contribution for the year to one of the following?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_CONTRIB',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired) }
    }
  ],
  ['clientIDConsideringContributionIRA_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' considering making a contribution for the year to one of the following?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_CONTRIB',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  /*['clientIDIncomeHighToRothIRA',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Is ' + (isAdvisor ? 'the client\'s' : 'your') + ' income too high to make a contribution to a Roth IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired) }
    }
  ],
  ['clientIDIncomeHighToRothIRA_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Is' + (isAdvisor ? 'the client\'s spouse\'s' : 'your spouse\'s') + ' income too high to make a contribution to a Roth IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],*/
  ['clientIDContributionNonWorkingSpouseIRA',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client' : 'you') + ' make an IRA contribution to ' + (isAdvisor ? 'his/her' : 'your') + ' non-working spouse\'s IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientSpouseIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDContributionNonWorkingSpouseIRA_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client\'s spouse' : 'your spouse') + ' make an IRA contribution to ' + (isAdvisor ? 'his/her' : 'your') + ' non-working spouse\'s IRA?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientIsRetired && !instance.clientSpouseIsRetired && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDIRAInvestCollectibles',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering having ' + (isAdvisor ? 'his/her' : 'your') + ' IRA invest in collectibles?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes') }
    }
  ],
  ['clientIDIRAInvestCollectibles_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' considering having ' + (isAdvisor ? 'his/her' : 'your') + ' IRA invest in collectibles?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDIRAInvestMasterLtdPartner',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering having ' + (isAdvisor ? 'his/her' : 'your') + ' IRA invest in master limited partnerships or other unincorporated entities operating a trade or business?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientHasBusiness === 'Yes' || q.scheduleCDetected === 'Yes') }
    }
  ],
  ['clientIDIRAInvestMasterLtdPartner_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' considering having ' + (isAdvisor ? 'his/her' : 'your') + ' IRA invest in master limited partnerships or other unincorporated entities operating a trade or business?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientHasBusiness === 'Yes' || q.scheduleCDetected === 'Yes') && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDTrusteeOwnRetPlan',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to be the trustee of ' + (isAdvisor ? 'his/her' : 'your') + ' own retirement account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes' || q.clientIDHasOrSpouseAccessToRoth401k === 'Yes' || q.clientIDHasAccessTo457Plan === 'Yes') }
    }
  ],
  ['clientIDTrusteeOwnRetPlan_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client\'s spouse ' : 'Does your spouse') + ' wish to be the trustee of ' + (isAdvisor ? 'his/her' : 'your') + ' own retirement account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes' || q.clientIDHasOrSpouseAccessToRoth401k === 'Yes' || q.clientIDHasAccessTo457Plan === 'Yes') && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDIRAInvestIliquidAssets',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to make an investment in illiquid assets in '+ (isAdvisor ? 'his/her' : 'your') + ' retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes' || q.clientIDHasOrSpouseAccessToRoth401k === 'Yes' || q.clientIDHasAccessTo457Plan === 'Yes') }
    }
  ],
  ['clientIDIRAInvestIliquidAssets_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client\'s spouse' : 'Does your spouse') + ' wish to make an investment in illiquid assets in '+ (isAdvisor ? 'his/her' : 'your') + ' retirement plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasAccessToWorkplaceRetirementPlan === 'Yes' || q.clientIDHasOrSpouseAccessToRoth401k === 'Yes' || q.clientIDHasAccessTo457Plan === 'Yes') && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDSEPSameRulesPSP',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering the differences between setting up a SEP-IRA and a 401(k) plan or other qualified plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDSelfEmployedOrSCorp === 'Yes') }
    }
  ],
  ['clientIDSEPSameRulesPSP_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' considering the differences between setting up a SEP-IRA and a 401(k) plan or other qualified plan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDSelfEmployedOrSCorp === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDSetRAForYearEnded',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client' : 'you') + ' set up a retirement account for a year that has already ended?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDSetRAForYearEnded_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client\'s spouse' : 'your spouse') + ' set up a retirement account for a year that has already ended?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDDistDeathDisab',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Are distributions the result of the death or disability of the employee from whose account distributions are coming?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes') }
    }
  ],
  ['clientIDDistDeathDisab_2',
    {
      tabSection: 'retirement',
      subsection: 'common',
      description:(isAdvisor)=>{ return 'Spouse. Are distributions the result of the death or disability of the employee from whose account distributions are coming?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDHasTraditionalOrRolloverIRA === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  /*['clientIDRMDRetPlan',
    {
      tabSection: 'retirement',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' considering taking a required minimum distribution?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],*/
  /*END TAB RETIREMENT*/


  /*START TAB SOCIAL SECURITY*/
  ['clientIDPayMedicare',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client': 'Do you') + ' pay for Medicare Part B?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDIsOnMedicare ==='Yes' && instance.clientAge >= 62) }
    }
  ],
  ['clientIDPayMedicare_2',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse': 'Does your spouse') + ' pay for Medicare Part B?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (q.clientIDIsOnMedicare ==='Yes' && instance.clientAge >= 62) && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDMoreMinimumMedicare',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client': 'Do you') + ' pay more than the minimum for Medicare Part B based on income of a spouse who has died or from whom ' + (isAdvisor ? 'he/she': 'you') + ' divorced, or ' + (isAdvisor ? 'he/she': 'you') + ' and ' + (isAdvisor ? 'his/her': 'your') + ' spouse\'s income dropped due to termination of employment, pension income, loss of income producing property, etc…?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return (true) }
    }
  ],
  ['clientIDMoreMinimumMedicare_2',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse': 'Does your spouse') + ' pay more than the minimum for Medicare Part B based on income of a spouse who has died or from whom ' + (isAdvisor ? 'he/she': 'you') + ' divorced, or ' + (isAdvisor ? 'he/she': 'you') + ' and ' + (isAdvisor ? 'his/her': 'your') + ' spouse\'s income dropped due to termination of employment, pension income, loss of income producing property, etc…?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDOutBD',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return 'Upon turning age 65, should ' + (isAdvisor ? 'the client': 'you') + ' opt out of Medicare Parts B and D?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && (instance.clientAge == 63 || instance.clientAge == 64)) }
    }
  ],
  ['clientIDOutBD_2',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return 'Spouse. Upon turning age 65, should ' + (isAdvisor ? 'the client': 'you') + ' opt out of Medicare Parts B and D?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && (instance.clientAge == 63 || instance.clientAge == 64)) && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly')}
    }
  ],
  ['clientIDWorkProvideMedicare',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client': 'Are you') + ' currently working for an employer that provides medical coverage that is primary to Medicare?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && q.clientIDOutBD == 'Yes') }
    }
  ],
  ['clientIDWorkProvideMedicare_2',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse': 'Is your spouse') + ' currently working for an employer that provides medical coverage that is primary to Medicare?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (!instance.clientIsRetired && instance.clientIDOutBD == 'Yes') && (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDSSBenDefer',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return 'Upon reaching Social Security age (66), should Social Security benefits be deferred to as late as age 70?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientAge >= 60 && instance.clientAge <=70) }
    }
  ],
  ['clientIDSSBenDefer_2',
    {
      tabSection: 'socialSecurity',
      subsection: null,
      description:(isAdvisor)=>{ return 'Spouse. Upon reaching Social Security age (66), should Social Security benefits be deferred to as late as age 70?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientAge >= 60 && instance.clientAge <=70) && (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint')}
    }
  ],
  /*END TAB SOCIAL SECURITY*/


  /*START TAB FAMILY*/
  ['clientIDChildrenCreditAllowed',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have one or more qualifying children for child credit, and is the taxes owed this year excluding child tax credit less than the credit amount?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes') }
    }
  ],
  ['clientIDChildrenCreditAllowed_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have one or more qualifying children for child credit, and is the taxes owed this year excluding child tax credit less than the credit amount?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDSellingExchangeProperty',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' contemplating selling or exchanging property with a family member or other related party?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (true) }
    }
  ],
  ['clientIDSellingExchangeProperty_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' contemplating selling or exchanging property with a family member or other related party?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDQualifyingExpensesIncurred',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have a qualifying dependent for whom expenses are incurred in the year for his or her care?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (true) }
    }
  ],
  ['clientIDQualifyingExpensesIncurred_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have a qualifying dependent for whom expenses are incurred in the year for his or her care?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDNewChildCredit2000',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have a new child who is ' + (isAdvisor? 'his/her' : 'your') + ' dependent for who ' + (isAdvisor? 'he/she' : 'you') +' can get a child credit of $2,000?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes') }
    }
  ],
  ['clientIDNewChildCredit2000_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have a new child who is ' + (isAdvisor? 'his/her' : 'your') + ' dependent for who ' + (isAdvisor? 'he/she' : 'you') +' can get a child credit of $2,000?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDIncomeNetInvestmentTaxLowChildIncome',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' generating income subject to the 3.8% net investment income tax and have minor children whose income is under $200,000?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes') }
    }
  ],
  ['clientIDIncomeNetInvestmentTaxLowChildIncome_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' generating income subject to the 3.8% net investment income tax and have minor children whose income is under $200,000?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDSavingsLowRateChildHighInterest',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' earning a low rate on savings and ' + (isAdvisor? 'does he/she' : 'do you') + ' have children borrowing at higher rates of interest?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes') }
    }
  ],
  ['clientIDSavingsLowRateChildHighInterest_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' earning a low rate on savings and ' + (isAdvisor? 'does he/she' : 'do you') + ' have children borrowing at higher rates of interest?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDHaveBusinessChildWOrk',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' self-employed or run a business in corporate form and have children who can work for the business?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasBusiness === 'Yes' && instance.clientHasChildren === 'Yes' || model.scheduleCDetected === 'Yes' && instance.clientHasChildren === 'Yes') }
    }
  ],
  ['clientIDHaveBusinessChildWOrk_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' self-employed or run a business in corporate form and have children who can work for the business?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasBusiness === 'Yes' && instance.clientHasChildren === 'Yes' || model.scheduleCDetected === 'Yes' && instance.clientHasChildren === 'Yes') && (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDSelfEmployChildAbleWork',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Ae you') + ' self-employed and have children under age 18 who are able to work for ' + (isAdvisor ? 'him/her' : 'you') + '?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientHasBusiness == 'Yes' && (instance.clientIDChildOneAge < 18  || instance.clientIDChildTwoAge < 18  || instance.clientIDChildThreeAge < 18)) }
    }
  ],
  ['clientIDSelfEmployChildAbleWork_2',
    {
      tabSection: 'family',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' self-employed and have children under age 18 who are able to work for ' + (isAdvisor ? 'him/her' : 'you') + '?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientHasChildren === 'Yes' && instance.clientHasBusiness == 'Yes' && (instance.clientIDChildOneAge < 18  || instance.clientIDChildTwoAge < 18  || instance.clientIDChildThreeAge < 18)) && (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  /*END TAB FAMILY*/


  /*START TAB CHILDREN*/
  ['clientIDHasAccessThroughEmployerToDependentCareAccount',
    {
      tabSection: 'children',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have access through ' + (isAdvisor? 'his/her' : 'your') + ' employer to a dependent care account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientIDChildOneAge < 13 && !instance.clientIsRetired || instance.clientIDChildTwoAge < 13 && !instance.clientIsRetired || instance.clientIDChildThreeAge < 13 && !instance.clientIsRetired) }
    }
  ],
  ['clientIDHasAccessThroughEmployerToDependentCareAccount_2',
    {
      tabSection: 'children',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have access through ' + (isAdvisor? 'his/her' : 'your') + ' employer to a dependent care account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (instance.clientIDChildOneAge < 13 && !instance.clientIsRetired || instance.clientIDChildTwoAge < 13 && !instance.clientIsRetired || instance.clientIDChildThreeAge < 13 && !instance.clientIsRetired) && (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  ['clientIDIsMaximizzingContributionsDependentCareAccount',
    {
      tabSection: 'children',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' maximizing contributions to a dependent care account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'],[2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (model, instance) => { return (model.clientIDHasAccessThroughEmployerToDependentCareAccount === 'Yes' || model.clientIDHasAccessThroughEmployerToDependentCareAccount_2 === 'Yes') }
    }
  ],
  ['clientIDIsMaximizzingContributionsDependentCareAccount_2',
    {
      tabSection: 'children',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' maximizing contributions to a dependent care account?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (model, instance) => { return (model.clientIDHasAccessThroughEmployerToDependentCareAccount === 'Yes' || model.clientIDHasAccessThroughEmployerToDependentCareAccount_2 === 'Yes') && (instance.clientMaritalStatus === 'Married' && model.clientIDFillingStatus === 'Joint') }
    }
  ],
  /*END TAB CHILDREN*/


  /*START TAB TUITION*/
  ['clientIDWillBePayingForTuitionOfChildrenOrGrandchildren',
    {
      tabSection: 'tuition',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' saving for, or paying, college or private-school tuition for ' + (isAdvisor ? 'himself/herself' : 'yourself') + ' or a dependent?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDWillBePayingForPrivateTuitionOfChildrenOrGrandchildren',
    {
      tabSection: 'tuition',
      subsection: null,
      description:(isAdvisor)=>{ return 'Will ' + (isAdvisor ? 'the client' : 'you') + ' be paying for private-school tuition of children, grandchildren or anyone else?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {return (q.clientIDWillBePayingForTuitionOfChildrenOrGrandchildren === 'Yes')}
    }
  ],
  ['clientIDIsTheirTuitionFundedFullyFundedThrough529PlanAccounts',
    {
      tabSection: 'tuition',
      subsection: null,
      description:(isAdvisor)=>{ return 'Is their tuition fully funded through 529 plan accounts?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => {return (q.clientIDWillBePayingForTuitionOfChildrenOrGrandchildren === 'Yes')}
    }
  ],
  /*END TAB TUITION*/

  /*START TAB LENDING*/
  ['clientIDPayStudentForDeductionJointly',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' pay student loan interest or tuition for which ' + (isAdvisor? 'he/she' : 'you') + ' would get a deduction or credit on a return filed jointly with spouse?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['clientIDPayStudentForDeductionJointly_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' pay student loan interest or tuition for which ' + (isAdvisor? 'he/she' : 'you') + ' would get a deduction or credit on a return filed jointly with spouse?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['clientIDNotgiftButLoan',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' interested in giving someone money, not as a gift, but as a loan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDNotgiftButLoan_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the spouse' : 'Is your spouse') + ' interested in giving someone money, not as a gift, but as a loan?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDLoanForResidence',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to make a loan that will be used to buy a residence?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['clientIDLoanForResidence_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' wish to make a loan that will be used to buy a residence?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => {return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDForgiveLoanAsGift',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client wish to forgive a loan made to someone as a gift?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDForgiveLoanAsGift_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return 'Spouse. Does the client wish to forgive a loan made to someone as a gift?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDPayStudentLoanInterestsOtherIncurred',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' pay student loan interest of someone who was a dependent at the time the debt was incurred?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDPayStudentLoanInterestsOtherIncurred_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' pay student loan interest of someone who was a dependent at the time the debt was incurred?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDInterestDebtBuyInv',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have interest expense on debt used to buy investments?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDInterestDebtBuyInv_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have interest expense on debt used to buy investments?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly') }
    }
  ],
  ['clientIDBorrowInvestPersonal',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' borrow to invest and for personal uses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDBorrowInvestPersonal_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' borrow to invest and for personal uses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDBorrowBuySecuritiesInvestLtdPart',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' borrow to buy securities or invest in partnerships?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDBorrowBuySecuritiesInvestLtdPart_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' borrow to buy securities or invest in partnerships?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDInvestmentIncomePayPersonalDebt',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have investment income and pay interest on personal debt?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDInvestmentIncomePayPersonalDebt_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have investment income and pay interest on personal debt?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDInterestExpenseInvestmentMunicipal',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have interest expense on debt used to buy investments and own municipal bonds or a municipal bond fund?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientIDInterestDebtBuyInv === 'Yes'}
    }
  ],
  ['clientIDInterestExpenseInvestmentMunicipal_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the spouse' : 'Does your spouse') + ' have interest expense on debt used to buy investments and own municipal bonds or a municipal bond fund?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientIDInterestDebtBuyInv === 'Yes' && instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDBadDebtLosses',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Has the client' : 'Have you') + ' experienced bad-debt losses or losses from worthless securities?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDBadDebtLosses_2',
    {
      tabSection: 'lending',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Has the spouse' : 'Have your spouse') + ' experienced bad-debt losses or losses from worthless securities?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],

  /*END TAB LENDING*/

  /*START TAB OTHER*/
  ['clientIDDoYouHaveAnInterestInRentalProperty',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have an interest in rental property?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDWorthlessInvestments',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return 'Will '+ (isAdvisor ? 'the client' : 'you') +' client have investments that will be worthless by the end of the year?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDSpouseUnknowledgeableOtherIncomeOrReporting',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return 'Client. Is concerned about how the other spouse is reporting income?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['clientIDSpouseUnknowledgeableOtherIncomeOrReporting_2',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return 'Spouse. Is concerned about how the client is reporting income?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['clientIDMoveToLowerTaxState',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' live in a state with high income tax rates and plan to retire or move to a state with lower or no income tax, or is it a year of particularly high income where ' + (isAdvisor ? 'he/she is' : 'you are') + ' in a higher bracket?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDABLEAccountAGI',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have a disability?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDBuyElectirCar',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' wish to buy an electric car?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDSubjectToAMT',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does it appear that ' + (isAdvisor ? 'the client' : 'you')+' will be subject to alternative minimum tax (AMT)?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDTheftCasualtyLosses',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have theft and casualty losses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDPayTaxPreparationPlanning',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' pay for tax preparation and planning services?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDUSInvestmentIncomeLimitedIncome',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have investment income of $3,500 or less and limited total income?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDSeparateMAGI',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' married, have joint income of more than $250,000 and are subject to the tax on investment income, but one spouse has income of less than $125,000?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you')  + ' or ' + (isAdvisor ? 'his/her' :'your') + ' spouse a non-resident alien or ' + (isAdvisor ? 'does he/she' : 'do you') + ' have financial accounts outside the U.S. during the year?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDForeignResident',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' a bona fide resident of a foreign country or '+ (isAdvisor ? 'was he/she' : 'were you') + ' present in a foreign country for 330 days during any 12-consecutive-month period?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['clientIDIncomeForeignAssets',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' generate income from foreign accounts or foreign securities not owned within U.S. brokerage accounts?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['clientIDForeignBalance',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' maintain a bank or brokerage account outside the U.S. in which the balance at any time during the year exceeds $10,000, or ' + (isAdvisor ? 'does he/she' : 'do you') + ' have signature authority over a non-U.S. account owned by another during the year?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['clientIDMarriedAlien',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' married to a non-resident alien?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => { return (instance.clientMaritalStatus === 'Married' && q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['clientIDImproveNoResidential',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Is the client' : 'Are you') + ' making improvements to non-residential real estate?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDInvestmentExpenses',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' incur investment expenses?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDHasLTMedInsurance',
    {
      tabSection: 'other',
      subsection: null,
      description:(isAdvisor)=>{ return (isAdvisor ? 'Does the client' : 'Do you') + ' have long-term care insurance?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],

  /*END TAB OTHER*/

  /*START TAB ADVISOR*/
  ['advisorIDYearToDateRealizedGains',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'What are the client’s current year short-term realized gains?'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Are the investment management fees being deducted from the client\'s retirement account and non-retirement account separately?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['clientIDInvManagerManagesClientsIRAAndManagementFeesDeductedProrataBasis_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Spouse. Are the investment management fees being deducted from the client\'s retirement account and non-retirement account separately?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDRealizedLongTermCapitalGains',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Current year realized long-term capital gains:'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDUnrealizedLongTermCapitalGains',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client have unrealized long-term capital gains?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDUnrealizedLongTermCapitalGains_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the spouse have unrealized long-term capital gains?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['advisorIDUnrealizedLongTermCapitalGainsAmount',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Unrealized long-term capital gains'},
      type: 'MONEY',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.advisorIDUnrealizedLongTermCapitalGains === 'Yes') }
    }
  ],
  ['advisorIDUnrealizedLongTermCapitalLosses',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client have unrealized long-term capital losses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDUnrealizedLongTermCapitalLosses_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the spouse have unrealized long-term capital losses?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['advisorIDClientsHasSeparatelyManagedTaxableInvAccount',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client have a separately managed taxable investment account (SMA)?'},
      type: 'RADIO_GROUP',
      sourceForSelect: 'CONST_ABSOLUTES_NOTSURE',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, 'SMA: a portfolio of assets managed by a RIA.'], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDClientsHasSeparatelyManagedTaxableInvAccount_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the spouse have a separately managed taxable investment account (SMA)?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, 'SMA: a portfolio of assets managed by a RIA.'], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['advisorIDOneSpouseWorks',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does only one spouse work?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' }
    }
  ],
  ['advisorIDClientHasSubstantialPortfolioLargerThanNeeds',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client have a substantial portfolio larger than his or her expenses or needs?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDClientHasSubstantialPortfolioLargerThanNeeds_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the spouse have a substantial portfolio larger than his or her expenses or needs?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  ['clientIDDualResident',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Is the client taxable in the U.S. as a dual resident in the year?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['clientIDResidentAlien',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Is the client a resident alien who will become a non-resident alien in the future?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, ''], [2021, ''], [2022, ''], [2023, ''] ]),
      displayRuleFunction: (q) => { return (q.clientIDIsNonResidentAlienOrHaveFinancialAccountOutsideUS === 'Yes') }
    }
  ],
  ['advisorIDDoesClientHasMunicipalBonds',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the client have municipal bonds?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 1'], [2021, 'Client 1'], [2022, 'Client 1'], [2023, 'Client 1'] ]),
      displayRuleFunction: (q) => { return true }
    }
  ],
  ['advisorIDDoesClientHasMunicipalBonds_2',
    {
      tabSection: 'advisor',
      subsection: null,
      description:(isAdvisor)=>{ return 'Does the spouse have municipal bonds?'},
      type: 'RADIO_GROUP',
      availableInYears: [2020, 2021, 2022, 2023],
      isExtraction: false,
      linesByYear: new Map([[2020, 'Client 2'],[2021, 'Client 2'], [2022, 'Client 2'], [2023, 'Client 2'] ]),
      displayRuleFunction: (q, instance) => { return instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly'}
    }
  ],
  /*END TAB ADVISOR*/


  /*START TAB FORM 8582*/
  ['clientHasForm8582',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Does the client has Form 8582?'
      },
      type: 'RADIO_GROUP',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582'], [2023, 'Form 8582'] ]),
      displayRuleFunction: (q, instance) => {
        return true; //instance.clientMaritalStatus === 'Married' && q.clientIDFillingStatus === 'Married Filing Jointly' && q.clientIDTaxYear == '2022'
      }
    }
  ],
  ['form8582SectionOne1a',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Activities with net income'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 1a'], [2023, 'Form 8582, Line 1a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne1b',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Activities with net loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 1b'], [2023, 'Form 8582, Line 1b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne1c',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Prior years\' unallowed losses'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 1c'], [2023, 'Form 8582, Line 1c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne1d',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Combine lines 1a, 1b, and 1c'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 1d'], [2023, 'Form 8582, Line 1d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne2a',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Activities with net income'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 2a'], [2023, 'Form 8582, Line 2a'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne2b',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Activities with net loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 2b'], [2023, 'Form 8582, Line 2b'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne2c',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Prior years\' unallowed losses'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 2c'], [2023, 'Form 8582, Line 2c'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne2d',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Combine lines 2a, 2b, and 2c'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 2d'], [2023, 'Form 8582, Line 2d'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionOne3',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Combine lines 1d and 2d'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Line 3'], [2023, 'Form 8582, Line 3'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionTwo4',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Enter the smaller of the loss on line 1d or the loss on line 3'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part II Line 4'], [2023, 'Form 8582, Part II Line 4'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionTwo8',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Home mortgage interes not reported to you on Form 1098'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part II Line 8'], [2023, 'Form 8582, Part II Line 8'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionTwo9',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Enter the smaller of line 4 or line 8'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part II Line 9'], [2023, 'Form 8582, Part II Line 9'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionThree11',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return 'Total losses allowed from all passive activities'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, ' Form 8582, Part III Line 11'], [2023, ' Form 8582, Part III Line 11'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionSevena',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return '(a) Loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part 7 (a)'], [2023, 'Form 8582, Part 7 (a)'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionSevenc',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return '(c) Unallowed loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part 7 (c)'], [2023, 'Form 8582, Part 7 (c)'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionEighta',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return '(a) Loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part 8 (a)'], [2023, 'Form 8582, Part 8 (a)'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionEightb',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return '(b) Unallowed loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part 8 (b)'], [2023, 'Form 8582, Part 8 (b)'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  ['form8582SectionEightc',
    {
      tabSection: 'extractions',
      subsection: 'form8582',
      description: (isAdvisor) => {
        return '(c) Allowed loss'
      },
      type: 'MONEY',
      availableInYears: [2022, 2023],
      isExtraction: true,
      linesByYear: new Map([[2022, 'Form 8582, Part 8 (c)'], [2023, 'Form 8582, Part 8 (c)'] ]),
      displayRuleFunction: (q) => {
        return true
      }
    }
  ],
  /*END TAB FORM 8582 */

])
