import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// import { CloseDialogAtLogOut } from '../../helpers/CloseDialogAtLogOut.class';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-calculator-dialog',
  templateUrl: './calculator-dialog.component.html',
})
export class CalculatorDialogComponent extends CloseDialogAtLogOut implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CalculatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
  ) {
    super(dialogRef, authService);
   }

    closeDialog() {
      this.dialogRef.close();
    }

    isString(item) {
      return typeof item === 'string';
    }

    isNotString(item) {
      return typeof item !== 'string';
    }


  ngOnInit() {
  }

}
