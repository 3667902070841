import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estate-snapshot-submit-button',
  templateUrl: './estate-snapshot-submit-button.component.html',
  styleUrls: ['./estate-snapshot-submit-button.component.scss']
})
export class EstateSnapshotSubmitButtonComponent implements OnInit {

  @Input() public status: string = 'noFiles';

  @Output() public updateTab = new EventEmitter<any>();
  @Output() public submitForm = new EventEmitter<any>();

  constructor() { }

  updateTabInformation() {
    this.updateTab.emit(0);
  }

  submit() {
    this.submitForm.emit(true);
  }

  ngOnInit() {
    
  }

}
