import { Component, OnInit } from '@angular/core';
import { isDisabledAdvisorFeature } from 'app/shared/helpers/storageControllers';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { deleteOneFromArrayOfObjs } from 'app/shared/helpers/utils';
import {AuthenticationService} from '../../../shared/services/auth/authentication.service';

@Component({
  selector: 'app-sidebar-side-settings',
  templateUrl: './sidebar-side-settings.component.html',
  styleUrls: ['./sidebar-side-settings.component.scss']
})
export class SidebarSideSettingsComponent implements OnInit {

  public isProduction: boolean;

  public menuItems: any = [
    {
      key: 'profile',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/01_account_profile.svg",
      name: "Your profile",
      state: "/settings/profile",
      tooltip: "Profile",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'company',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/08_account_team.svg",
      name: "Company",
      state: "/settings/company",
      tooltip: "Company",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'preferences',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/06_account_preferences.svg",
      name: "Preferences",
      state: "/settings/preferences",
      tooltip: "Preferences",
      type: "link",
      isProdEnabled: true,
    },
    /*{
      key: 'recommendations',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/04_sb_areas.svg",
      name: "Recommendations",
      state: "/settings/recommendations",
      tooltip: "Recommendations",
      type: "link",
      isProdEnabled: false,
    },*/
    {
      key: 'reports',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/01_Side_bar/09_sb_reports.svg",
      name: "Reports",
      state: "/settings/reports",
      tooltip: "Reports",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'security',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/03_account_security.svg",
      name: "Security",
      state: "/settings/security",
      tooltip: "Security",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'notifications',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/02_account_notifications.svg",
      name: "Notifications",
      state: "/settings/notifications",
      tooltip: "Notifications",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'billing',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/04_account_payment.svg",
      name: "Billing",
      state: "/settings/billing",
      tooltip: "Billing",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'prospect',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/00_General/24_g_prospect.svg",
      name: "Prospect Accelerator",
      state: "/settings/prospect",
      tooltip: "prospect",
      type: "link",
      isProdEnabled: true,
    },
    {
      key: 'external-providers-interactions',
      icon: "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/03_Account/05_account_integrations.svg",
      name: "Integrations",
      state: "/settings/external-providers-interactions",
      tooltip: "Integrations",
      type: "link",
      isProdEnabled: true,
    }
  ];

  public restrictedItems = []

  constructor(
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
    const aux = this.authService.getCurrentRoleRestrictions();
    let url = [];
    aux.map(restrictedUrl => {
      url = restrictedUrl.split(':');
      if (url[0] === 'settings'){
        this.restrictedItems.push(url[1]);
      }
    });

    if (this.restrictedItems.includes('prospectaccelerator')){
      let idx = this.restrictedItems.indexOf('prospectaccelerator');
      this.restrictedItems[idx] = 'prospect';
    }

    //Load restrictions from advisor disabled Feature
    let disabledFeatures: any = JSON.parse(localStorage.getItem('dFeatures'));
    console.log('disabledFeatures', disabledFeatures, typeof disabledFeatures);
    if(disabledFeatures != null && disabledFeatures.length > 0){
      disabledFeatures.map(feature => {
        console.log('disabled feature:', feature);
        this.restrictedItems.push(feature);
      })
    }

    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    // Fins and delete elements from the menu that are NOT active for the current advisor.

    let disabledElements: string[] = [];
    if(isDisabledAdvisorFeature('prospect')){ disabledElements.push('prospect') };

    disabledElements.forEach(keyToDelete => {
      deleteOneFromArrayOfObjs(this.menuItems, 'key', keyToDelete);
    })

  }

}
