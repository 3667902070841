const CONST_ABSOLUTES = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No',  }
];

const CONST_ABSOLUTES_UNSURE = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No' },
    { value: 'nolose', display: 'Not sure' },
];

const CONST_ABSOLUTES_UNSURE_TAX_ONLY = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No' },
    { value: 'notsure', display: 'Not sure' },
];

const CONST_ABSOLUTES_NOTSURE = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No' },
    { value: 'notsure', display: 'Not sure' },
];

const CONST_ABSOLUTES_NA = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No' },
    { value: 'No', display: 'N/A' },
];

const CONST_ABSOLUTES_PROSPECT = [
    { value: 'Yes', display: 'Yes' },
    { value: 'No', display: 'No' },
    { value: 'notsure', display: 'I’d rather not say' },
];

const CONST_BOOLEANS = [
    { value: 'True', display: 'Yes' },
    { value: 'False', display: 'No' }
];

const CONST_GENDERS = [
    { value: 'Male', display: 'Male' },
    { value: 'Female', display: 'Female' }
];

const CONST_GENDERS_V2 = [
    { value: 'Male', display: 'Male' },
    { value: 'Female', display: 'Female' },
    { value: 'Other', display: 'Prefer Not to Say' }
];

const CONST_STATES = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];

const CONST_STATES_KEY = [
    {key: 'AL', value: "Alabama"},
    {key: 'AK', value: "Alaska"},
    {key: 'AS', value: "American Samoa"},
    {key: 'AZ', value: "Arizona"},
    {key: 'AR', value: "Arkansas"},
    {key: 'CA', value: "California"},
    {key: 'CO', value: "Colorado"},
    {key: 'CT', value: "Connecticut"},
    {key: 'DE', value: "Delaware"},
    {key: 'DC', value: "District Of Columbia"},
    {key: 'FM', value: "Federated States Of Micronesia"},
    {key: 'FL', value: "Florida"},
    {key: 'GA', value: "Georgia"},
    {key: 'GU', value: "Guam"},
    {key: 'HI', value: "Hawaii"},
    {key: 'ID', value: "Idaho"},
    {key: 'IL', value: "Illinois"},
    {key: 'IN', value: "Indiana"},
    {key: 'IA', value: "Iowa"},
    {key: 'KS', value: "Kansas"},
    {key: 'KY', value: "Kentucky"},
    {key: 'LA', value: "Louisiana"},
    {key: 'ME', value: "Maine"},
    {key: 'MH', value: "Marshall Islands"},
    {key: 'MD', value: "Maryland"},
    {key: 'MA', value: "Massachusetts"},
    {key: 'MI', value: "Michigan"},
    {key: 'MN', value: "Minnesota"},
    {key: 'MS', value: "Mississippi"},
    {key: 'MO', value: "Missouri"},
    {key: 'MT', value: "Montana"},
    {key: 'NE', value: "Nebraska"},
    {key: 'NV', value: "Nevada"},
    {key: 'NH', value: "New Hampshire"},
    {key: 'NJ', value: "New Jersey"},
    {key: 'NM', value: "New Mexico"},
    {key: 'NY', value: "New York"},
    {key: 'NC', value: "North Carolina"},
    {key: 'ND', value: "North Dakota"},
    {key: 'MP', value: "Northern Mariana Islands"},
    {key: 'OH', value: "Ohio"},
    {key: 'OK', value: "Oklahoma"},
    {key: 'OR', value: "Oregon"},
    {key: 'PA', value: "Pennsylvania"},
    {key: 'PR', value: "Puerto Rico"},
    {key: 'RI', value: "Rhode Island"},
    {key: 'SC', value: "South Carolina"},
    {key: 'SD', value: "South Dakota"},
    {key: 'TN', value: "Tennessee"},
    {key: 'TX', value: "Texas"},
    {key: 'UT', value: "Utah"},
    {key: 'VT', value: "Vermont"},
    {key: 'VI', value: "Virgin Islands"},
    {key: 'VA', value: "Virginia"},
    {key: 'WA', value: "Washington"},
    {key: 'WV', value: "West Virginia"},
    {key: 'WI', value: "Wisconsin"},
    {key: 'WY', value: "Wyoming"}
];

const CONST_STATES_KEY_WITH_INTERNATIONAL = [
    ... CONST_STATES_KEY,
    {key: 'In', value: "International"}
]

const CONST_MARRIAGESTATUS = [
    { value: 'Single' },
    { value: 'Married' },
    { value: 'Widowed' },
    { value: 'Divorced' },
    { value: 'Domestic Partner' },
];

const CONST_HOME_OWNRENT = [
    { value: 'Owned' },
    { value: 'Rented' }
];

const CONST_AUTOCOMPANIES = [
    "N/A",
    "Allstate",
    "Amica",
    "Chubb",
    "Erie",
    "Fireman’s Fund",
    "Geico",
    "Kemper Preferred",
    "Liberty Mutual",
    "Metlife",
    "One Choice",
    "Progressive",
    "Pure",
    "Safeco",
    "State Farm",
    "The Hartford",
    "Travelers",
    "USAA",
    "Other"
];

const CONST_HOMEINSURANCECOMPANIES = [
    "N/A",
    "AIG",
    "Allstate",
    "Amica",
    "Argo Group",
    "Chubb",
    "Citizens",
    "Erie",
    "Fireman’s Fund",
    "Geico",
    "Kemper Preferred",
    "Liberty Mutual",
    "Metlife",
    "Narragansett Bay",
    "One Choice",
    "Progressive",
    "Pure",
    "State Farm",
    "Travelers",
    "Universal Property P&C",
    "USAA",
    "Wells Fargo",
    "Other"
];

const CONST_AUTOPOLICYVENDORS = [
    "Employer",
    "Professional Association",
    "Alumni Association",
    "None of the above"
];

const CONST_DISABILITYPOLICYVENDORS = [
    "Association",
    "Employer",
    "Insurance company"
    // "None of the above"
];

const CONST_HOME_INSURANCECOMPANIES = [
    "AIG",
    "Allstate",
    "Amica",
    "Argo Group",
    "Chubb",
    "Citizens",
    "Erie",
    "Fireman’s Fund",
    "Geico",
    "Kemper Preferred",
    "Liberty Mutual",
    "Metlife",
    "Narragansett Bay",
    "One Choice",
    "Progressive",
    "Pure",
    "State Farm",
    "Travelers",
    "Universal Property P&C",
    "USAA",
    "Wells Fargo",
    "Other"
];

const CONST_HOME_AUTOCOMPANIES = [
    "AIG",
    "Allstate",
    "Amica",
    "Chubb",
    "Erie",
    "Fireman’s Fund",
    "Geico",
    "Kemper Preferred",
    "Liberty Mutual",
    "Metlife",
    "One Choice",
    "Progressive",
    "Pure",
    "Safeco",
    "State Farm",
    "The Hartford",
    "Travelers",
    "USAA",
    "Other",
    "N/A"
];

const CONST_DISABILITY_COMPANIES = [
    "AIG",
    "Allstate",
    "American Family Insurance",
    "Amica",
    "Geico",
    "Liberty Mutual",
    "MetLife",
    "Nationwide",
    "Northwestern Mutual",
    "Progressive",
    "State Farm",
    "The Hanover",
    "The Hartford",
    "Other",
];

const CONST_LI_COMPANIES = [
    "AIG",
    "Allstate",
    "American Family Insurance",
    "Amica",
    "Geico",
    "Liberty Mutual",
    "MassMutual",
    "MetLife",
    "Nationwide",
    "Northwestern Mutual",
    "Progressive",
    "Securian",
    "State Farm",
    "The Hanover",
    "The Hartford",
    "Other",
];

const CONST_LTC_INSURANCE_COMPANIES = [
    "Genworth",
    "John Hancock",
    "MedAmerica",
    "Mutual of Omaha",
    "Metlife",
    "MassMutual",
    "Other"
];

const CONST_HOME_BUILDINGS = [
    "House",
    "Apartment",
    "Condo",
    "Co-op"
];

const CONST_FILLING_STATUSES = [
    { value: "Single", display: "Single" },
    { value: "Joint", display: "Joint" },
    { value: "Other", display: "Other" }
];

const CONST_FILLING_TAX_GENDERS = [
    { value: "Male", display: "Male" },
    { value: "Female", display: "Female" },
    { value: "Couple", display: "Couple" }
];

const CONST_TAX_SALARY_INCREMENTS = [
    { value: "Increase", display: "Increase" },
    { value: "Decrease", display: "Decrease" },
    { value: "No", display: "No change" }
];

const CONST_STUDENT_LOAN_STATUS = [
    'Deferred',
    'Forbearance',
    'In repayment',
    'In default',
    'Loan originated',
    'In school or grace period',
    'In grace period',
    'In military grace',
    'In post-deferment grace period',
    'Disbursed, not yet in repayment',
    'Other'
];

const CONST_STUDENT_LOAN_TYPE = [
    "Direct Stafford Subsidized",
    "Direct Stafford Unsubsidized",
    "Direct PLUS for Graduate or Professional Students",
    "Direct PLUS",
    "Direct Consolidated Unsubsidized",
    "Direct Consolidated Subsidized",
    "Direct PLUS Consolidated",
    "PLUS",
    "PLUS Consolidated",
    "FFEL Stafford Subsidized",
    "FFEL Stafford Unsubsidized",
    "FFEL PLUS for Graduate or Professional Students",
    "FFEL PLUS",
    "FFEL Consolidated Unsubsidized",
    "FFEL Consolidated Subsidized",
    "FFEL PLUS Consolidated",
    "Perkins",
    "Other"
];

const CONST_MONTHS = [
    { value: "1", display: "January" },
    { value: "2", display: "February" },
    { value: "3", display: "March" },
    { value: "4", display: "April" },
    { value: "5", display: "May" },
    { value: "6", display: "June" },
    { value: "7", display: "July" },
    { value: "8", display: "August" },
    { value: "9", display: "September" },
    { value: "10", display: "October" },
    { value: "11", display: "November" },
    { value: "12", display: "December" }
];

const CONST_GENDER_TITLE = [
    { value: 'Mr.', display: 'Mr.' },
    { value: 'Ms.', display: 'Ms.' },
    { value: 'Mrs.', display: 'Mrs.' },
    { value: 'Mx.', display: 'Mx.' },
];

const CONST_EMPLOYMENT_STATUS = [
    { value: 'Employed', display: 'Employed' },
    { value: 'Between Jobs', display: 'Between Jobs' },
    { value: 'Self Employed', display: 'Self Employed' },
    { value: 'Retired', display: 'Retired' },
    { value: 'Homemaker', display: 'Homemaker' }
];

const CONST_REAL_STATE = [
   'Primary Residence',
   'Commercial Property',
   'Land',
   'Residential Property for Investment',
   'Other'
];

const CONST_REAL_STATE_TYPES = [
    'Primary Residence',
    'Investment Property',
    'Land',
    'Other'
 ];

const CONST_ENTER_INFORMATION = [
    { value: 'Uploading Statements', display: 'Uploading Statements' },
    { value: 'Logging in via secure web', display: 'Self Employed' },
    { value: 'Manually Entering', display: 'Manually Entering' },
];

const CONST_TERMS = [
    'Number of years',
    'Fixed/variable',
    'Interest rate'
 ];

 const CONST_RETIREMENT_ACCOUNT = [
    'Traditional IRA',
    'Roth IRA',
    'Defined Benefit Plan',
    '401(k)',
    'SEP IRA',
    'SIMPLE IRA',
    '457 Plan',
    'Stock Options',
    'RSU',
    'Defined Contribution Plan'
 ];

 const CONST_RETIREMENT_ACCOUNT_TYPES = [
    'Traditional IRA',
    'Roth IRA',
    'Inherited IRA/Beneficiary IRA',
    '401(k)',
    'Roth 401(k)',
    '457 Plan',
    '403 (b)',
    'Other'
 ];

 const CONST_NON_RETIREMENT_ACCOUNT_TYPES = [
    'Brokerage/Taxable',
    'Bank/Savings/Checking',
    'Other',
 ];

 const CONST_BUSINESS = [
    'C Corp',
    'S Corp',
    'Sole Proprietor',
    'LLC',
    'LLP',
    'NPO',
    'NGO',
    'Other'
 ];

 const CONST_BUSINESS_TYPES = [
    'LLC',
    'S Corp',
    'C Corp',
    'Sole Proprietorship',
    'Partnership'
 ];

 const CONST_INSURANCE = [
     'Whole Life Insurance',
     'Term Life Insurance',
     'Home Insurance',
     'Auto Insurance',
     'LTC',
     'Annuities',
     'Universal Life',
     'Other'
 ]

 const CONST_LIFE_INSURANCE = [
     'Term',
     'Whole/Cash value',
 ]

 const CONST_COLLEGE_SAVINGS_TYPES= [
     '529 Plan',
     'Coverdell',
     'UTMA/UGMA',
     'Other',
 ]

 const CONST_LOAN_LIABILITY_TYPES= [
     'Student Loan',
     'Personal Loan',
     'Home Loan',
     'Car Loan',
     'Other',
 ]

const CONST_CUSTODIAN_TITLED = [
    'Client',
    'Jointly',
    'Corporation',
    'Trust'
]

const CONST_INTANGILE_PROPERTY = [
    'Books',
    'Films',
    'Music',
    'Art',
    'Patent'
]

const CONST_PERIODICITY = [
    'Annual',
    'Monthly'
]

const CONST_TYPE_ANNUITY = [
    'Fixed',
    'Indexed',
    'Variable'
]

const CONST_INVESTMENTMANAGED = [
    'Self-directed',
    'Professionally managed',
    'Both'
]

const CONST_INVESTMENTOPTIONS = [
    'Stocks',
    'Bonds',
    'Mutual funds',
    'Private equity',
    'Hedge fund',
    'ETFs',
    'Futures',
    'Options',
    'Startups',
    'Notes',
    'REITs',
    'MLPs',
    'Other'
]

const CONST_INVESTMENTRATE = [
   "1",
   "2",
   "3",
   "4",
   "5",
   "6",
   "7",
   "8",
   "9",
   "10"
]

const CONST_INVESTMENTMANAGEDCAHSFLOW = [
    'Checking',
    'Savings',
    'CDs',
    'Other'
]

const CONST_INCOMEOPTIONS = [
    '401(k)',
    '403(b)',
    '457(b)',
    'Traditional IRA',
    'Roth IRA',
    'SEP IRA',
    'SIMPLE IRA',
    'Defined Benefit Plan',
    'Defined Contribution Plan',
    'Profit Sharing Plan',
]

const CONST_ANTICIPATECHANGESRETIREMENT = [
    'No',
    'Moving to another state or country',
    'Getting a part-time job',
    'Buying a vacation home',
    'Buying a boat',
    'Buying a plane'
]

const CONST_LIFESTYLE = [
'Maintain',
'Be more lavish',
'Downsize'
]

const CONST_HEALTHCONDITIONRATE = [
'Healthy',
'Minor issue(s)',
'Major issue(s)'
]

const DEBIT = [
    'Mortgage',
    'Auto loan',
    'HELOC',
    'Credit cards',
    'Personal loan',
    'Student loan',
    'Other',
]

const CONST_CAR_BUSINESS_PURPOSE = [
    {
        value: 'Less than 50%', key: 'Less than 50%',
    },
    {
        value: 'More than 50%', key: 'More than 50%',
    },
    {
        value: '100%', key: '100%'
    }
]

const CONST_HOUSE_OR_FARM = [
  {value: 'House or farm', display: 'House or farm'},
  {value: 'Other property', display: 'Other property'},
  {value: 'None', display: 'None'}
]

const CONST_IRAS = [
  { value: "By IRA/Roth IRA account", display: "By IRA account" },
  { value: "By the beneficiary", display: "By the owner" }
];

 const CONST_CONTRIB =[
   { value: "IRA", display: "IRA" },
   { value: "Roth IRA", display: "Roth IRA" },
   { value: "No", display: "No" },
 ]

 const CONST_FILLING_STATUSES_2 = [
    { value: "Single", display: "Single" },
    { value: "Married Filing Jointly", display: "Married Filing Jointly" },
    { value: "Married Filing Separately", display: "Married Filing Separately" },
    { value: "Head of Household", display: "Head of Household" },
    { value: "Qualifying Widow(er)", display: "Qualifying Widow(er)" },
  ];
  

export {
    CONST_ABSOLUTES,
    CONST_ABSOLUTES_UNSURE,
    CONST_ABSOLUTES_NOTSURE,
    CONST_ABSOLUTES_PROSPECT,
    CONST_ABSOLUTES_UNSURE_TAX_ONLY,
    CONST_ABSOLUTES_NA,
    CONST_BOOLEANS,
    CONST_GENDERS,
    CONST_MARRIAGESTATUS,
    CONST_STATES,
    CONST_AUTOCOMPANIES,
    CONST_HOMEINSURANCECOMPANIES,
    CONST_AUTOPOLICYVENDORS,
    CONST_DISABILITYPOLICYVENDORS,
    CONST_HOME_INSURANCECOMPANIES,
    CONST_HOME_AUTOCOMPANIES,
    CONST_LI_COMPANIES,
    CONST_DISABILITY_COMPANIES,
    CONST_HOME_BUILDINGS,
    CONST_FILLING_STATUSES,
    CONST_FILLING_TAX_GENDERS,
    CONST_TAX_SALARY_INCREMENTS,
    CONST_STUDENT_LOAN_STATUS,
    CONST_STUDENT_LOAN_TYPE,
    CONST_MONTHS,
    CONST_LTC_INSURANCE_COMPANIES,
    CONST_HOME_OWNRENT,
    CONST_GENDER_TITLE,
    CONST_EMPLOYMENT_STATUS,
    CONST_REAL_STATE,
    CONST_REAL_STATE_TYPES,
    CONST_ENTER_INFORMATION,
    CONST_TERMS,
    CONST_RETIREMENT_ACCOUNT,
    CONST_RETIREMENT_ACCOUNT_TYPES,
    CONST_NON_RETIREMENT_ACCOUNT_TYPES,
    CONST_BUSINESS,
    CONST_BUSINESS_TYPES,
    CONST_INSURANCE,
    CONST_LIFE_INSURANCE,
    CONST_COLLEGE_SAVINGS_TYPES,
    CONST_LOAN_LIABILITY_TYPES,
    CONST_CUSTODIAN_TITLED,
    CONST_INTANGILE_PROPERTY,
    CONST_PERIODICITY,
    CONST_TYPE_ANNUITY,
    CONST_INVESTMENTMANAGED,
    CONST_INVESTMENTOPTIONS,
    CONST_INVESTMENTRATE,
    CONST_INVESTMENTMANAGEDCAHSFLOW,
    CONST_INCOMEOPTIONS,
    CONST_ANTICIPATECHANGESRETIREMENT,
    CONST_LIFESTYLE,
    CONST_HEALTHCONDITIONRATE,
    DEBIT,
    CONST_GENDERS_V2,
    CONST_STATES_KEY,
    CONST_STATES_KEY_WITH_INTERNATIONAL,
    CONST_CAR_BUSINESS_PURPOSE,
    CONST_HOUSE_OR_FARM,
    CONST_IRAS,
    CONST_CONTRIB,
    CONST_FILLING_STATUSES_2
};
