import { Component, Input, OnInit } from '@angular/core';
import { getRandomStr, readFromStoragedObject, scaleCanvas } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { balanceSheetCutter } from '../balance-sheet/components/balanceSheetCutter';
import { updateFamilyGraph } from '../../estate-snapshot-v3/family-box/updateFamilyGraph';
import { ESettingsService } from 'app/shared/services/e-settings.service';
import { PdfFormattedPageConfig } from '../../pdf-formatted-page/pdf-formatted-page.component';
import { divideFamilyGraph } from '../../estate-snapshot-v3/estate-snapshot-report/divideFamilyGraph';
import html2canvas from 'html2canvas';
import { CONST_STATES_KEY } from 'app/views/questionnaires/Questionnaire.constants';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  @Input() pdfDataInput: any;

  public estateLabData: any;
  public clientId
  public familyResponse;
  public responseStatusStrategies
  public graphData
  public graphDataFirstDeath
  public graphDataPdfArray;
  public headersRelationAssets: string[] = ['Beneficiaries', 'Relationship', 'Assets Received'];
  public dictionaryRelationAssets: any = [
    'beneficiary',
    'relationship',
    'total'
  ];
  // taxes
  public taxesStrategies
  public lastExemptionFederal
  public lastExemptionFederalSpouse
  public exemptionFederal
  public exemptionState
  public withStrategies
  public currentScenario: number = 1;
  //disclaimer
  public fullDisclaimer: any;
  public formattedPagerConfig: PdfFormattedPageConfig = new PdfFormattedPageConfig();
  private sections: any = {};
  public isLoading: boolean = false;
  public renderReady:boolean = false; //Tell to puppeteer to start with the pdf conversion.
  public balanceDataFormatted: any;
  public statesArray = CONST_STATES_KEY;
  public livingState: any;

  constructor(
    public advisorService: AdvisorService,
    private settingsServive: ESettingsService,
    public clientService: ClientDataService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    //formattedPagerConfig init default values
    this.formattedPagerConfig.title = "";
    this.formattedPagerConfig.subtitle = "";
    this.formattedPagerConfig.footerNote = "See full disclaimer at the end";

    let onPdfDataResponse = async (response) => {
      this.estateLabData = response;
      console.log('response balancee', response);
      let dataBalance = this.estateLabData.components.find(section => section.sectionId  === 'BALANCE_SHEET');

      let familyData = this.estateLabData.components.find(section => section.sectionId  === 'FAMILY_TREE');
      console.log('familyData response', familyData);
      this.livingState = familyData.livingState;
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.formattedPagerConfig.title = response.client.fullName;
      this.formattedPagerConfig.logoURL = response.client.logo;
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.loadFamilyData(familyData);

      if(dataBalance) {
        console.log('Data por procesar', JSON.parse(JSON.stringify(dataBalance)));
        // this.balanceDataFormatted = await balanceSheetCutter(dataBalance.data[0]);
        setTimeout(() => {
          this.balanceDataFormatted =  balanceSheetCutter(dataBalance.data[0]);
          this.isLoading = false;
        }, 500);
      }else{
        //if dataBalance is undefined
        this.isLoading = false;
      }
      console.log('this.isLoading en true', this.isLoading);
      console.log('this.isLoading en false', this.isLoading);

      setTimeout(()=>{
        this.generateCanvases();
      }, 1000);
    }

    //Load the data from the input or make a request to the backend.
    if(this.pdfDataInput){
      onPdfDataResponse(this.pdfDataInput);
    }else{ //Para el preview de el tab de report (solo dev)
      console.log('aqui en datos de plataforma')
      this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
      this.advisorService.getExternalEstateLab(this.clientId, [1,2,3]).then((response: any) => {
        onPdfDataResponse(response);
      },error =>{
        console.log('Error in getExternalBalanceSheet', error);
        this.isLoading = false;
      })
    }
  }

  /* async ngOnInitOriginal(): Promise<void> {
    this.isLoading = true;
    //formattedPagerConfig init default values
    this.formattedPagerConfig.title = "";
    this.formattedPagerConfig.subtitle = "";
    this.formattedPagerConfig.footerNote = "";


    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    this.advisorService.getExternalEstateLab(this.clientId, [1,2,3]).then((response: any) => {
      this.estateLabData = response;
      console.log('response balancee', response);
      let dataBalance = this.estateLabData.components.find(section => section.sectionId  === 'BALANCE_SHEET');

      let familyData = this.estateLabData.components.find(section => section.sectionId  === 'FAMILY_TREE');
      console.log('familyData response', familyData);
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.formattedPagerConfig.title = response.client.fullName;
      this.formattedPagerConfig.logoURL = response.client.logo;
      this.fullDisclaimer = response.disclaimer.data.paragraph;
      this.loadFamilyData(familyData);

      if(dataBalance) {
        console.log('Data por procesar', JSON.parse(JSON.stringify(dataBalance)));
        setTimeout(() => {
          this.balanceDataFormatted = balanceSheetCutter(dataBalance.data[0]);
        }, 700);
      }
      this.isLoading = false;

      setTimeout(()=>{
        // this.isLoading = false;
        this.generateCanvases();
      }, 600);


    },error =>{
      console.log('Error in getExternalBalanceSheet', error);
      this.isLoading = false;
    })

    this.loadScenario(1, 'today');
    await this.getTaxes()
    this.loadScenarioPDF()
  } */

  async generateCanvases(){
    console.log('generateCanvases start');
    let canvasPromises: Promise<any>[] = [];
    let keys = [
      'todayView',
      'firstDeath',
      'secondDeath'
    ]

    let ids = [];

    [1,2,3].map(num=>{
      ids.push(...keys.map(key=>{return key+'_'+num}))
    })

    console.log('generateCanvases keys', ids);

    ids.map(id=>{
      console.log('generateCanvases element', document.getElementById(id));
      let canvasPromise = html2canvas(document.getElementById(id), { scale: 2, useCORS: true }).then(canvas=>{
        scaleCanvas(canvas, 960, 648);
        document.getElementById(id+'_canvas').appendChild(canvas);
      });
      canvasPromises.push(canvasPromise)
    })

    await Promise.all(canvasPromises);
  setTimeout(()=> {
    this.renderReady = true;
    console.log('generateCanvases in report', this.renderReady, canvasPromises);
  }, 2000);
  }

  async loadFamilyData(existentData?){
    let familyData = existentData;
    familyData.data.map((section: any, idx) => {
      console.log('section family', section);
      familyData.data[idx] = updateFamilyGraph(section);

      let familyGraphUpdated =  [{
        title: '',
        data: familyData.data[idx],
        uid: getRandomStr(12),
        type: 'GRAPH_MIX',
      }]
      if(familyGraphUpdated){
        // this.familyResponse =  divideFamilyGraph(familyGraphUpdated);
      }
    });
    this.familyResponse = familyData;
    console.log('this.familyResponse updated', this.familyResponse);
  }

  getSection(sectionNumber: number, type: ('TAXES' | 'GRAPH')){
    let sectionKey = `${type}:${sectionNumber}`;
    if(this.sections[sectionKey] === undefined){
      if(this.estateLabData){
        this.sections[sectionKey] = this.estateLabData.components.find(section => section?.sectionId  === `${type}:${sectionNumber}`);
      }
    }
    // console.log('buscando...', this.sections[sectionKey]);
    return this.sections[sectionKey];
  }

  findState(keyState: string): string{
    let state: any = this.statesArray.find(element => element.key === keyState);
    return state ? state.value : 'State not found';
  }
}
