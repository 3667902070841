import { beneficiaries } from "./facts/dataFacts";

export const filterButtonsItems = [
    {
        label: 'All',
        key: 'all',
        class: 'fp-button button-primary-toggle',
        activeClass: 'active',
    },
    {
        label: 'Scenario One',
        key: '1',
        class: 'filter-button pink',
        activeClass: 'pink-active',
    },
    {
        label: 'Scenario Two',
        key: '2',
        class: 'filter-button green',
        activeClass: 'green-active',
    },
    {
        label: 'Scenario Three',
        key: '3',
        class: 'filter-button blue',
        activeClass: 'blue-active',
    },
];

export const strategiesDummy = {
    wills: [
        {
            name: 'John\'s Will',
            status: 'inPlace',
            statusLabel: 'In place',
            details: [],
            scenario: 1,
        },
        {
            name: 'Jane Will',
            status: 'attention',
            statusLabel: 'In place - Needs attention',
            details: [
                'Need to connect assets to beneficiaries',
            ],
            scenario: 2,
        },
        {
            name: 'John\'s Will',
            status: 'forPlanning',
            statusLabel: 'For Planning',
            details: [],
            scenario: 3,
        },
        {
            name: 'John\'s Will',
            status: 'forPlanning',
            statusLabel: 'For Planning',
            details: [],
            scenario: null,
        }
    ],
    trusts: [
        {
            name: 'John\'s Trust',
            status: 'inPlace',
            statusLabel: 'In place',
            details: [],
            scenario: 1,
        },
        {
            name: 'Jane Trust',
            status: 'attention',
            statusLabel: 'In place - Needs attention',
            details: [
                'Need to connect assets to beneficiaries',
            ],
            scenario: 3,
        },
    ],
    contracts: [
        {
            name: 'John\'s Contract',
            status: 'inPlace',
            statusLabel: 'In place',
            details: [],
            scenario: 1,
        },
        {
            name: 'John\'s Contract',
            status: 'inPlace',
            statusLabel: 'In place',
            details: [],
            scenario: 3,
        },
    ]
}

export const relationshipCatalog = [
    /* {
      value: 'Client',
      label: 'Client'
    },*/
    {
      'value': "Co-Client",
      label: 'Co-Client'
    },
    {
      'value': "Child",
      label: 'Child'
    },
    {
      'value': "Sibling",
      label: 'Sibling'
    },
    {
      'value': "Father",
      label: 'Father'
    },
    {
      'value': "Mother",
      label: 'Mother'
    },
    {
      'value': "Grandchild",
      label: 'Grandchild'
    },
    {
        value: "Mother-in-law",
        label: "Mother-in-law",
    },
    {
        value: "Father-in-law",
        label: "Father-in-law",
    },
    {
        value:"Brother-in-law",
        label:"Brother-in-law",
    },
    {
        value: "Sister-in-law",
        label: "Sister-in-law",
    },
    {
        value: "Family Member",
        label: "Family Member",
    },
    {
        value: "Other",
        label: "Other",
    },
    {
        value: "Client",
        label: 'Client'
    }
  ];

const buildFamilyToSave = (beneficiary, _id = null, otherData = null) => {
    return {
        ...(_id ? { _id } : {}),
        name: beneficiary?.name,
        data: {
            ...(otherData ? { ...otherData } : {}),
            type: "family",
            relationship: beneficiary?.relationship,
            _index: otherData?._index || otherData?.data?._index || null,
        },
    };
};

const buildLiabilityToSave = (liability, _id = null, otherData = null) => {
    console.log('Construyendo esto', otherData);
    const storagedClientId = JSON.parse(sessionStorage.getItem('currentClient'));
    let state = storagedClientId?.livesIn;
    let ownershipDistribution = {
        ...otherData?.data?.ownershipDistribution,
        fullName: otherData?.data?.ownershipDistribution?.fullName.split(' (')[0],
        stringName: otherData?.data?.ownershipDistribution?.fullName.split(' (')[0],
    };
    return {
        ...(_id ? { _id } : {}),
        name: liability?.name,
        data: {
            beneficiaries: "",
            beneficiariesDistribution: [],
            isProbate: false,
            lenderName: null,
            name: "Student Loan",
            ownershipType: "contact",
            source: "estate_lab",
            state: state || '',
            _id: "student_loan",
            ...(otherData ? { ...otherData?.data, ownershipDistribution } : {}),
            status: otherData?.data?.status ? 'In Place' : 'For Planning',
            interestRate: liability?.interestRate,
            loanAmount: liability?.loanAmount,
            _index: otherData?._index || otherData?.data?._index || null,
        },
    };
};

const buildBeneficiaryToEdit = (data) => {
    return {
        name: data?.name,
        relationship: data?.relationship,
        _index: data?._index,
    };
}

const buildLiabilityToEdit = (data) => {
    return {
        name: data?.name,
        loanAmount: data?.loanAmount,
        interestRate: data?.interestRate,
        wholoanisit: data?.ownershipDistribution?._id,
        status: data?.status && data?.status === 'For Planning' ? false : data?.status && data?.status === 'In Place' ? true : true,
        _index: data?._index,
    };
}

const buildPersonalPropertyToEdit = (data) => {
    return {
        name: data?.name,
        value: data?.value,
        ownership: data?.ownershipDistribution?._id,
        status: data?.status && data?.status === 'For Planning' ? false : data?.status && data?.status === 'In Place' ? true : true,
        _index: data?._index,
    };
};

const buildRealEstateToEdit = (data) => {
    console.log('DATA: ', data);
    return {
        name: data?.originalName,
        marketValue: data?.marketValue,
        ownership: data?.ownershipDistribution?._id,
        state: data?.state,
        type: data?.name,
        costBasis: data?.costBasis,
        status: data?.status && data?.status === 'For Planning' ? false : data?.status && data?.status === 'In Place' ? true : true,
        _index: data?._index,
    }
};

const buildPersonalPropertyToSave = (personalProperty, _id = null, otherInfo = null) => {
    const storagedClientId = JSON.parse(sessionStorage.getItem('currentClient'));
    let state = storagedClientId?.livesIn;
    console.log(personalProperty, otherInfo);
    return {
        name: personalProperty?.name,
        ...(_id ? { _id } : {}),
        data: {
            source: 'estate_lab',
            ownershipType: 'contact',
            name: 'Personal property',
            state: "Washington",
            ...(otherInfo?.data ? otherInfo?.data : otherInfo),
            value: personalProperty?.value || personalProperty?.value,
            status: otherInfo?.data?.status ? 'In Place' : 'For Planning',
            _id: 'personal_property',
            _index: otherInfo?._index || otherInfo?.data?._index || null,
        }
    }
}

const buildRealEstateToSave = (realEstate, _id = null, otherInfo = null) => {
    const storagedClientId = JSON.parse(sessionStorage.getItem('currentClient'));
    let state = storagedClientId?.livesIn;
    console.log('OTHER INFO: ', otherInfo);
    return {
        ...(_id ? { _id } : {}),
        name: realEstate?.name,
        data: {
            source: 'estate_lab',
            ownershipType: 'contact',
            name: 'Primary Residence',
            beneficiaries: '',
            beneficiariesDistribution: [],
            currentLoanBalance: '0',
            isProbate: true,
            loansAgainstProperty: '',
            ownedOrRented: '',
            ...(otherInfo ? otherInfo?.data : {}),
            value: realEstate?.marketValue,
            marketValue: realEstate?.marketValue,
            state: realEstate?.state,
            status: otherInfo?.data?.status ? 'In Place' : 'For Planning',
            costBasis: realEstate?.costBasis,
            _id: 'real_estate',
            _index: otherInfo?._index || otherInfo?.data?._index || null,
        }
    }
}

const buildAccountToEdit = (tradIra, _id = null, otherInfo = null) => {
    console.log('Trad IRA: ', tradIra);
    const aux: any = {};
    if (tradIra?.beneficiariesDistribution?.length) {
        aux.beneficiaries = tradIra?.beneficiariesDistribution?.map((beneficiary) => ({
            beneficiary: beneficiary?._id,
            percentage: beneficiary?.percentage,
        }));
    }
    if (tradIra?.contingentBeneficiariesDistribution?.length) {
        aux.contingentBeneficiaries = tradIra?.contingentBeneficiariesDistribution?.map((beneficiary) => ({
            contingentBeneficiary: beneficiary?._id,
            contingentPercentage: beneficiary?.percentage,
        }));
    }
    console.log('AUX: ', aux, tradIra);
    console.log('Aux before send: ', {
        name: tradIra?.name,
        ...(tradIra?.nameOfInsured ? { nameOfInsured: tradIra?.nameOfInsured } : {} ),
        ...(tradIra?.value ? { value: typeof tradIra?.value == 'string' ? parseInt(tradIra?.value.replace(',', ''), 10) : tradIra?.value } : {}),
        ...(tradIra?.balance ? { value: typeof tradIra?.balance == 'string' ? parseInt(tradIra?.balance.replace(',', ''), 10) : tradIra?.balance } : {}),
        ...(tradIra?.deathBenefit ? {deathBenefit: typeof tradIra?.deathBenefit == 'string' ? parseInt(tradIra?.deathBenefit.replace(',', ''), 10) : tradIra?.deathBenefit } : {}),
        ...(tradIra?.ownershipDistribution?._id || tradIra?.accountTitleDistribution?._id ? { ownership: tradIra?.ownershipDistribution?._id || tradIra?.accountTitleDistribution?._id } : {}),
        ...(aux),
        _index: tradIra?._index,
        status: tradIra?.status && tradIra?.status === 'For Planning' ? false : tradIra?.status && tradIra?.status === 'In Place' ? true : true,
    });
    return {
        name: tradIra?.name,
        ...(tradIra?.nameOfInsured ? { nameOfInsured: tradIra?.nameOfInsured } : {} ),
        ...(tradIra?.value ? { value: typeof tradIra?.value == 'string' ? parseInt(tradIra?.value.replace(',', ''), 10) : tradIra?.value } : {}),
        ...(tradIra?.balance ? { value: typeof tradIra?.balance == 'string' ? parseInt(tradIra?.balance.replace(',', ''), 10) : tradIra?.balance } : {}),
        ...(tradIra?.deathBenefit ? {deathBenefit: typeof tradIra?.deathBenefit == 'string' ? parseInt(tradIra?.deathBenefit.replace(',', ''), 10) : tradIra?.deathBenefit } : {}),
        ...(tradIra?.ownershipDistribution?._id || tradIra?.accountTitleDistribution?._id ? { ownership: tradIra?.ownershipDistribution?._id || tradIra?.accountTitleDistribution?._id } : {}),
        ...(aux),
        _index: tradIra?._index,
        status: tradIra?.status && tradIra?.status === 'For Planning' ? false : tradIra?.status && tradIra?.status === 'In Place' ? true : true,
    };
};

const buildTraditionalIraToSave = (tradIra, _id = null, otherInfo = null) => {
    const storagedClientId = JSON.parse(sessionStorage.getItem('currentClient'));
    let state = storagedClientId?.livesIn;
    console.log('DATA: ', tradIra, otherInfo)
    return {
        ...(_id ? { _id } : {}),
        name: tradIra?.name,
        data: {
            source: 'estate_lab',
            ownershipType: 'contact',
            state: state || '',
            name: otherInfo?.data?.typeToSave ||'Traditional IRA',
            status: "In Place",
            beneficiaries: '',
            beneficiariesDistribution: [],
            currentLoanBalance: '0',
            isProbate: false,
            ownedOrRented: '',
            ...(otherInfo ? otherInfo?.data : {}),
            ...(tradIra?.value ? { value: tradIra?.value } : {} ),
            ...(tradIra?.deathBenefit ? { deathBenefit: tradIra?.deathBenefit } : {} ),
            ...(tradIra?.nameOfInsured ? { nameOfInsured: tradIra?.nameOfInsured } : {} ),
            _id: otherInfo?.data?.idToSave || 'traditional_ira',
            _index: otherInfo?._index || otherInfo?.data?._index || null,
        }
    }
}

const buildCharityToEdit = (charity, _id = null) => {
    return {
        ...( _id ? { _id } : {}),
        name: charity?.name || '',
        description: charity?.description || '',
        status: charity?.status && charity?.status === 'For Planning' ? false : charity?.status && charity?.status === 'In Place' ? true : true,
    }
};

const buildCharityToSave = (charity, _id = null, otherData = null) => {
    return {
        ...( _id ? { _id } : {}),
        name: charity?.name,
        data: {
            source: 'estate_lab',
            name: 'Charity',
            status: charity?.status ? 'In Place' : 'For Planning',
            description: charity?.description || '',
            _id: 'charities',
            _index: otherData?._index || otherData?.data?._index || null,
        }
    }
};

export const methodToReadResponse = {
    beneficiaries: buildBeneficiaryToEdit,
    liabilities: buildLiabilityToEdit,
    personalProperty: buildPersonalPropertyToEdit,
    realEstate: buildRealEstateToEdit,
    traditionalIRA: buildAccountToEdit,
    rothIRA: buildAccountToEdit,
    '401K': buildAccountToEdit,
    brokerageAccount: buildAccountToEdit,
    '457Plan': buildAccountToEdit,
    charities: buildCharityToEdit,
    life_insurance: buildAccountToEdit,
    '529_plan': buildAccountToEdit,
}

export const methodsToFormatAndSave = {
    beneficiaries: buildFamilyToSave,
    liabilities: buildLiabilityToSave,
    personalProperty: buildPersonalPropertyToSave,
    realEstate: buildRealEstateToSave,
    traditionalIRA: buildTraditionalIraToSave,
    rothIRA: buildTraditionalIraToSave,
    '401K': buildTraditionalIraToSave,
    brokerageAccount: buildTraditionalIraToSave,
    '457Plan': buildTraditionalIraToSave,
    charities: buildCharityToSave,
    life_insurance: buildTraditionalIraToSave,
    '529_plan': buildTraditionalIraToSave,
};


export const prepareAditionalData = ({ category, ...otherProps }) => {
    const auxBody: any = {};
    const storagedClientId = JSON.parse(sessionStorage.getItem('currentClient'));
    const typeToSave = {
        traditionalIRA: 'Traditional IRA',
        rothIRA: 'Roth IRA',
        '401K': '401(K) Plan',
        brokerageAccount: 'Brokerage Account',
        '457Plan': '457 Plan',
        life_insurance: 'Life Insurance',
        '529_plan': '529 Plan'
    };
    const idsToSave = {
        traditionalIRA: 'traditional_ira',
        rothIRA: 'roth_ira',
        '401K': '401k_plan',
        brokerageAccount: 'brockerage_account',
        '457Plan': '457_plan',
        life_insurance: 'ltc_insurance',
        '529_plan': '529_plan'
    };
    if (category === 'liabilities') {
        console.log('Other props Liabilities: ', otherProps);
        otherProps.options.wholoanisit.forEach(({ value, label }, index) => {
            if (value == otherProps?.wholoanisit) {
                console.log('Label: ', label);
                const finalLabel = label.split(' (')[0];
                auxBody.wholoanisit = `${finalLabel} [${otherProps?.originalOptions?.wholoanisit[index]?.relation === 'Self' ? 'Client' : otherProps?.originalOptions?.wholoanisit[index]?.relation}]`;
                auxBody.ownershipDistribution = {
                    _id: value,
                    fullName: label,
                    stringName: label,
                    percentage: 100,
                };
            }
        });
        auxBody.status = otherProps?.status;
        return auxBody;
    } else if (category === 'personalProperty') {
        otherProps.options.ownership.forEach(({ value, label }, index) => {
            if (value == otherProps?.ownership) {
                console.log('Label: ', label);
                const finalLabel = label.split(' (')[0];
                auxBody.ownership = `${finalLabel} [${otherProps?.originalOptions?.ownership[index]?.relation === 'Self' ? 'Client' : otherProps?.originalOptions?.ownership[index]?.relation}]`;
                auxBody.ownershipDistribution = {
                    _id: value,
                    fullName: label,
                    stringName: label,
                    percentage: 100,
                };
            }
        });
        auxBody.status = otherProps?.status;
        return auxBody;
    } else if (category === 'realEstate') {
        otherProps.options.ownership.forEach(({ value, label }, index) => {
            if (value == otherProps?.ownership) {
                console.log('Label: ', label);
                const finalLabel = label.split(' (')[0];
                auxBody.ownership = `${finalLabel} [${otherProps?.originalOptions?.ownership[index]?.relation === 'Self' ? 'Client' : otherProps?.originalOptions?.ownership[index]?.relation}]`;
                auxBody.ownershipDistribution = {
                    _id: value,
                    fullName: label,
                };
            }
        });
        auxBody.name = otherProps?.type;
        auxBody.status = otherProps?.status;
        return auxBody;
    } else if (category === 'traditionalIRA' || category === 'rothIRA' || category === '401K' || category === 'brokerageAccount' || category === '457Plan' || category === 'life_insurance' || category === '529_plan') {
        console.log('Other trad: ', otherProps);
        auxBody._index = otherProps?._index;
        auxBody.status = otherProps?.status ? 'In Place' : 'For Planning';
        let accountTitleObj: any = {};
        if (otherProps?.options?.accountTitle) {
            accountTitleObj = otherProps?.options?.accountTitle.filter(({ value }) => value === otherProps?.ownership)[0];
        }
        if (otherProps?.options?.howIsTitled) {
            accountTitleObj = otherProps?.options?.howIsTitled.filter(({ value }) => value === otherProps?.ownership)[0];
        }
        if (otherProps?.options?.ownership) {
            accountTitleObj = otherProps?.options?.ownership.filter(({ value }) => value === otherProps?.ownership)[0];
        }
        if (otherProps?.options?.howIsTitled) {
            accountTitleObj = otherProps?.options?.howIsTitled.filter(({ value }) => value === otherProps?.ownership)[0];
        }
        console.log('ACCOUNT OBJ: ', accountTitleObj);
        auxBody.ownership = accountTitleObj?.label;
        auxBody.ownershipDistribution = {
            fullName: accountTitleObj?.label,
            _id: accountTitleObj?.value,
        };
        auxBody.beneficiaries = [];
        auxBody.beneficiariesDistribution = [];
        otherProps?.beneficiaries?.forEach(({ beneficiary: beneficiaryId, percentage }) => {
            const infoData = otherProps?.originalOptions?.beneficiaries?.filter(({ _id: innerId }) => innerId === beneficiaryId)[0];
            if (infoData) {
                auxBody.beneficiaries.push(`${infoData?.name} [${infoData.relation === 'Self' ? 'Client' : infoData?.relation}]`);
                auxBody.beneficiariesDistribution.push({
                    fullName: infoData?.name,
                    percentage,
                    _id: infoData?._id,
                });
            }
        });
        if (otherProps?.contingentBeneficiaries) {
            console.log('Entré al contingent', otherProps?.contingentBeneficiaries);
            auxBody.contingentBeneficiaries = [];
            auxBody.contingentBeneficiariesDistribution = [];
            otherProps?.contingentBeneficiaries?.forEach(({ contingentBeneficiary: beneficiaryId, contingentPercentage: percentage }) => {
                console.log('BENEFI: ', beneficiaryId);
                const infoData = otherProps?.originalOptions?.beneficiaries?.filter(({ _id: innerId }) => {
                    console.log(innerId, beneficiaryId);
                    return innerId === beneficiaryId;
                })[0];
                console.log('LA INFO DATA: ', infoData);
                if (infoData) {
                    auxBody.contingentBeneficiaries.push(`${infoData?.name} [${infoData.relation === 'Self' ? 'Client' : infoData?.relation}]`);
                    auxBody.contingentBeneficiariesDistribution.push({
                        fullName: infoData?.name,
                        percentage,
                        _id: infoData?._id,
                    });
                }
            });
            if (auxBody?.contingentBeneficiaries) {
                auxBody.contingentBeneficiaries = auxBody?.contingentBeneficiaries.join(',');
            }
            console.log('Terminé al contingent');

        }
        auxBody.beneficiaries = auxBody?.beneficiaries.join(',');
        console.log('AUX BODY: ', auxBody);
        auxBody.idToSave = idsToSave[category];
        auxBody.typeToSave = typeToSave[category];
        return auxBody;
    } else if (category === 'contract') {
        console.log('Es un contrato', otherProps);
        const selectedAsset = otherProps?.options?.asset?.filter(({ value }) => value === otherProps?.asset)[0];
        auxBody.nickname = `${storagedClientId?.name}'s ${selectedAsset?.label}`;
        auxBody.status = otherProps?.status || 'For Planning';
        auxBody.scenarios = otherProps?.scenarios || ['1', '2', '3'];
        auxBody.needsAttention = false;
        auxBody.addons = {
            source: 'estate_lab',
        };
    } else if (category === 'gift') {
        console.log(otherProps);
        let isBeneficiary = false;
        let selectedAsset = otherProps?.options?.asset?.filter(({ value }) => value === otherProps?.assets)[0];
        if (!selectedAsset) {
            selectedAsset = otherProps?.options?.beneficiaries?.filter(({ value }) => value === otherProps?.assets)[0];
            if (selectedAsset) {
                isBeneficiary = true;
            }
        }
        console.log('Asset selected: ', selectedAsset, otherProps);
        auxBody.nickname = `${storagedClientId?.name}'s ${selectedAsset?.label} Gift`;
        auxBody.status = otherProps?.status || 'For Planning';
        auxBody.scenarios = otherProps?.scenarios || ['1', '2', '3'];
        auxBody.needsAttention = false;
        auxBody.addons = {
            source: 'estate_lab',
            amount: otherProps?.amount || 0,
            total: (otherProps?.amount || 0) * (otherProps?.years || 0) || 0,
            beneficiary: selectedAsset?.value,
            beneficiary_type: isBeneficiary ? 'beneficiaries' : 'charities',
            years: otherProps?.years,
        }
    }
    return auxBody;
}


const readContractResponse =  (contract) => {
    console.log(contract);
    const auxObj: any = {};
    auxObj.assets = contract?.assets ? contract?.assets?.map((asset) => {
        const auxBeneficiaries = asset?.beneficiaries?.filter((ben) => ben.addons.category === 'primaryBeneficiary') || [];
        const auxContingent = asset?.beneficiaries?.filter((ben) => ben.addons.category === 'contingentBeneficiary') || [];
        console.log(auxBeneficiaries);
        console.log(auxContingent);
        return {
            asset: asset?.asset?._id,
            beneficiaries: auxBeneficiaries.length === 0
                ? [{ beneficiary: '', percentage: '' }]
                : auxBeneficiaries?.map((beneficiary) => (
                    {
                        beneficiary: beneficiary?.contact?._id,
                        percentage: beneficiary?.percentage
                    }
                )) || [{ beneficiary: '', percentage: '' }],
            contingentBeneficiaries: auxContingent?.length === 0
                ? [{ contingentBeneficiary: '', contingentPercentage: '' }]
                : auxContingent.map((beneficiary) => (
                {
                    contingentBeneficiary: beneficiary?.contact?._id,
                    contingentPercentage: beneficiary?.percentage
                }
                )) || [{ contingentBeneficiary: '', contingentPercentage: '' }],
        }
    }) : [
        {
            asset: '',
            beneficiaries: [
                {
                    beneficiary: '',
                    percentage: '',
                },
            ],
            contingentBeneficiaries: [
                {
                    contingentBeneficiary: '',
                    contingentPercentage: '',
                },
            ],
        }
    ];
    // if (!auxObj?.assets?.beneficiaries || auxObj?.assets?.beneficiaries?.length === 0) {
    //     auxObj.assets.beneficiaries = [{ beneficiary: '', percentage: '' }]
    // }
    // if (!auxObj?.assets?.contingentBeneficiaries || auxObj?.assets?.contingentBeneficiaries?.length === 0) {
    //     auxObj.assets.contingentBeneficiaries = [{ contingentBeneficiary: '', contingentPercentage: '' }]
    // }

    console.log('AUX OBJ: ', auxObj);
    return auxObj;
};

const buildContractBody = (contract, originalInfo, auxInfo, options) => {
    console.log('Contract para construir', contract, originalInfo, options, auxInfo);
    const auxAssets = [];
    contract?.assets?.forEach((asset) => {
        const auxObj: any = {};
        auxObj.assetId = asset?.asset;
        auxObj.addons = {
            isProbate: false,
        };
        auxObj.beneficiaries = [];
        asset?.beneficiaries?.forEach((beneficiary) => {
            if (beneficiary.beneficiary) {
                auxObj.beneficiaries.push({
                    contactId: beneficiary.beneficiary,
                    percentage: beneficiary.percentage,
                    addons: {
                        category: 'primaryBeneficiary',
                    },
                });
            }
        });
        asset?.contingentBeneficiaries?.forEach((beneficiary) => {
            if (beneficiary.contingentBeneficiary) {
                auxObj.beneficiaries.push({
                    contactId: beneficiary.contingentBeneficiary,
                    percentage: beneficiary.contingentPercentage,
                    addons: {
                        category: 'contingentBeneficiary',
                    },
                });
            }
        });

        auxAssets.push(auxObj);
    });
    const finalAns = {
        ...auxInfo,
        ...(originalInfo?.status && originalInfo?.status !== 'For Planning' ? { status: originalInfo?.status } : { status: 'For Planning' }),
        scenarios: originalInfo?.scenarios,
        assets: auxAssets,
    };
    console.log('FINAL ANS: ', finalAns);
    return finalAns;
};

const readWillResponse = (will) => {
    console.log('Will para leer', will);
    const auxObj: any = {};
    auxObj.assets = will?.assets ? will?.assets?.map((asset) => {
        const auxBeneficiaries = asset?.beneficiaries?.filter((ben) => ben.addons.category === 'primaryBeneficiary') || [];
        const auxContingent = asset?.beneficiaries?.filter((ben) => ben.addons.category === 'contingentBeneficiary') || [];
        return {
            asset: asset?.asset?._id,
            beneficiaries: auxBeneficiaries.length === 0
                ? [{ beneficiary: '', percentage: '' }]
                : auxBeneficiaries?.map((beneficiary) => (
                    {
                        beneficiary: beneficiary?.contact?._id,
                        percentage: beneficiary?.percentage
                    }
                )) || [{ beneficiary: '', percentage: '' }],
            contingentBeneficiaries: auxContingent?.length === 0
                ? [{ contingentBeneficiary: '', contingentPercentage: '' }]
                : auxContingent.map((beneficiary) => (
                {
                    contingentBeneficiary: beneficiary?.contact?._id,
                    contingentPercentage: beneficiary?.percentage
                }
                )) || [{ contingentBeneficiary: '', contingentPercentage: '' }],
        }
    }) : [
        {
            asset: '',
            beneficiaries: [
                {
                    beneficiary: '',
                    percentage: '',
                },
            ],
            contingentBeneficiaries: [
                {
                    contingentBeneficiary: '',
                    contingentPercentage: '',
                },
            ],
        }
    ];

    auxObj.ownerId = will?.owner?._id || '';
    auxObj.pourOverWill = will?.addons?.pourOverWill || 'Yes';
    auxObj.trust = will?.addons?.pourOverWill_trustId || '';
    return auxObj;
};

const readGiftResponse = (gift) => {
    return {
        gift: [
            {
            assets: gift?.addons?.beneficiary,
            amount: gift?.addons?.amount,
            total: gift?.addons?.total || 0,
            years: gift?.addons?.years || 0,}
        ]
    }
};

const buildGiftBody = (gift, originalInfo, options, auxInfo) => {
    console.log('Gift para construir', gift, originalInfo, options, auxInfo);
    const finalAns = {
        ...options,
        ...(originalInfo?.status && originalInfo?.status !== 'For Planning' ? { status: originalInfo?.status } : { status: 'For Planning' }),
        assets: [],
        scenarios: originalInfo?.scenarios,
    };
    console.log('FINAL ANS: ', finalAns);
    return finalAns;
};

export const strategiesReadMethods = {
    contract: readContractResponse,
    will: readWillResponse,
    gift: readGiftResponse,
};

export const strategiesbuildMethods = {
    contract: buildContractBody,
    will: buildContractBody,
    gift: buildGiftBody,
};

export const HasValue = (something: any, model) => {
    let okValue = true;
    const notRequired = ['trust'];
    Object.keys(model).forEach((key) => {
      if (!notRequired.includes(key)) {
        if (Array.isArray(model[key])) {
          model[key].forEach((arrElem) => {
            Object.keys(arrElem).forEach((innerKey) => {
              if (Array.isArray(arrElem[innerKey])) {
                if (arrElem[innerKey][0]?.beneficiary || arrElem[innerKey][0]?.beneficiary === '') {
                  const aux = arrElem[innerKey][0]?.freePercentage === 0;
                  okValue = okValue && aux;
                } else {
                  let aux = true;
                  if (arrElem[innerKey][0]?.contingentBeneficiary !== '') {
                    aux = arrElem[innerKey][0]?.freePercentage === 0;
                  }
                  okValue = okValue && aux;
                }
              } else {
                if (innerKey === 'asset' && !arrElem[innerKey] && model?.pourOverWill === 'No') {
                    model.block_asset = true;
                } else if (innerKey === 'asset' && arrElem[innerKey]) {
                    delete model.block_asset;
                }
                okValue = okValue && ((innerKey !== 'hasInPlace' && innerKey !== 'status') ? !!arrElem[innerKey] : true);
              }
            });
          })
        } else {
          if (key !== 'saveEnabled' && !key.includes('block')) {
            okValue = okValue && !!model[key];
          }
        }
      }
    });
    model.saveEnabled = okValue;
    if (model?.pourOverWill === 'Yes') {
        model.saveEnabled = true && !model?.block_beneficiaries && !model?.block_contingentBeneficiaries && !model.block_asset;
    }
  }
