import { maritalStatusesWithCoClient } from 'app/views/questionnaires/general-questionnaire/templates/personal-information/utils';
import { QuestionnaireResponse } from 'app/shared/model/questionnaires/Questionnaires.model';

export const getFullName = (data: any)=>{
    if (data) {
        const dataSource = data['personalInformation'].data;
        const dataInObject = {}
        dataSource.forEach( (item)=> { dataInObject[item.name] = item.answer  } )
        const selectedMaritalStatus = dataInObject['clientIDMaritalStatus']
        const requiresCoClient = maritalStatusesWithCoClient.includes(selectedMaritalStatus);
        const hasCoClient = requiresCoClient;
        const hasCoClientSameLastname = dataInObject['clientIDSpouseUsesSameLastName'] != 'No';
        const clientName = dataInObject['clientIDFirstName'];
        const clientLastname = dataInObject['clientIDLastName'];
        const coClientName = dataInObject['clientIDSpouseName'];
        const coClientLastname = dataInObject['clientIDSpouseLastName'];
        const isCoClientEmpty = hasCoClient && (coClientName.length > 0);
        let composedName = '';
        if (!hasCoClient || !isCoClientEmpty) {
          composedName = `${clientName} ${clientLastname}`;
        }
        if (hasCoClient && isCoClientEmpty) {
          if (hasCoClientSameLastname) {
            composedName = `${clientName} & ${coClientName} ${clientLastname}`;
          }
          if (!hasCoClientSameLastname) {
            composedName = `${clientName} ${clientLastname} & ${coClientName} ${coClientLastname}`;
          }
        }
        return composedName; 
    }
    return ''
}

export const getNumberOfChilds = (data: any)=>{
  if(data){
    return data.assets.data.filter((item)=>{
      return item.type == 'Children'
    })[0].data.length;
  }
}

export const getBeneficiaries = (data: QuestionnaireResponse)=>{
  if (data) {
      const relatives = []
      data['assets'].data.forEach((item, index)=>{
        item.data.forEach((arg) => {
          relatives.push(arg )
        })
      })
      return relatives; 
  }
  return []
}

export const getParents = (data: any)=>{
  if (data) {
      const fields = data['assets'].fields.filter((item) => {
        return item.field == 'parents'
      })[0]['options'];
      return fields; 
  }
  return []
}

export const getBeneficiaryRelations = (data: any)=>{
  if (data) {
      const fields = data['assets'].fields.filter((item) => {
        return item.field == 'relations'
      })[0]['options'];
      return fields; 
  }
  return []
}