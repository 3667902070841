import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { ArrayOfObjsAminusB, deleteOneFromArrayOfObjs } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import {
  Asset,
  Contact,
  DocumentFactory,
  ESTATEDOC_CONTRACT,
  ESTATEDOC_TRUST,
  ESTATEDOC_WILL,
  EstateDocument,
  EstateDocumentAsset,
  EstateDocumentAssetBeneficiary,
  EstateLabMapper,
  Gift,
} from './estate-lab.models';

export const LIST_OF_TRUST_BUTTONS = [
  {key: 'ILIT',         template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'ILIT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/01_estatlab_ILIT.svg'},
  {key: 'LTR',          template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Living Trust Revocable', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/02_estatlab_LivinnTrust.svg'},
  {key: 'LTI',          template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Living Trust Irrevocable', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/03_estatlab_LivinnTrustIRR.svg'},
  {key: 'GRATGRUT',     template:'typeA', hasTrustTerm: true,  assetHasAddons: false, title: 'GRAT/GRUT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/04_estatlab_GRAT-GRUT.svg'},
  {key: 'CRATCRUT',     template:'typeA', hasTrustTerm: true,  assetHasAddons: true,  title: 'CRT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/05_estatlab_CRAT_CRUT.svg'},
  {key: 'IDGT',         template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'IDGT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/06_estatlab_IDGT.svg'},
  {key: 'QPRT',         template:'typeA', hasTrustTerm: true,  assetHasAddons: false, title: 'QPRT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/07_estatlab_QPRT.svg'},
  {key: 'DAF',          template:'typeA', hasTrustTerm: false, assetHasAddons: true,  title: 'DAF', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/08_estatlab_DAF.svg'},
  {key: 'DT',           template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Dynasty Trust', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/09_estatlab_DynastyTrst.svg'},
  {key: 'MTAB',         template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Marital Trust A & B', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/14_estatlab_MaritalAB.svg'},
  // {key: 'MTB',          template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Marital Trust (B Trust)', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/11_estatlab_MaritalB.svg'},
  {key: 'SLAT',         template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'SLAT', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/12_estatlab_SLAT.svg'},
  {key: 'TESTAMENTARY', template:'typeA', hasTrustTerm: false, assetHasAddons: false, title: 'Testamentary', image:'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/09_Trust/13_estatlab_Testamentary.svg'},
]

@Component({
  selector: 'app-estate-lab-modal',
  templateUrl: './estate-lab-modal.component.html',
  styleUrls: ['./estate-lab-modal.component.scss'],
})
export class EstateLabModalComponent extends CloseDialogAtLogOut implements OnInit {

  public listOfTrustsButtons = LIST_OF_TRUST_BUTTONS;
  public listOfAssets: Asset[] = [];
  public listOfContacts: Contact[] = [];
  public currentClientId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<EstateLabModalComponent>,
    public advisorService: AdvisorService,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);
   }

  ngOnInit() {

    this.currentClientId = JSON.parse(sessionStorage.getItem('currentClient')).clientId;

    // If the current model is a Trust, set asset category as trust
    let foundTrustButton = LIST_OF_TRUST_BUTTONS.find( button => button.title == this.data.sectionTitle);
    if(Boolean(foundTrustButton)) this.data.assetCategory = 'trust';

    // Require the right assets by the current Trust type if on Trust context
    let assetForTrustType: string = Boolean(foundTrustButton) ? foundTrustButton.key : null;

    this.loadAssets(assetForTrustType);

    EstateLabMapper.GetContactsList(this.currentClientId, this.advisorService).then(response => {
      this.listOfContacts = response;
    })

    if(this.data.sectionTitle == 'Will Builder'){
      this.advisorService.getEstateLabData(this.currentClientId, this.data.scenario).then(response => {
        this.data.currentDocument = DocumentFactory.create(ESTATEDOC_WILL).loadDataFrom({
          ...response,
          scenario: this.data.scenario,
        });
      })
    }else if(this.data.sectionTitle == 'By Contract'){
      this.advisorService.getEstateLabData(this.currentClientId, this.data.scenario).then(response => {
        this.data.currentDocument = DocumentFactory.create(ESTATEDOC_CONTRACT).loadDataFrom({
          ...response,
          scenario: this.data.scenario,
        });
      })
    }else if(this.data.sectionTitle == 'Gift Strategy'){
      this.advisorService.getEstateLabData(this.currentClientId, this.data.scenario).then(response => {
        this.data.currentGifts = response.giftStrategy.items ? response.giftStrategy.items : [];
      })
    }else if(this.data.assetCategory == 'trust'){
      if(Boolean(foundTrustButton)){
        this.onTrustBuilderButtonClick(foundTrustButton.key);
      }
    }

  }

  loadAssets(trustType?: string){
        EstateLabMapper.GetAssetList(this.currentClientId, this.advisorService, this.data.assetCategory, this.data.scenario, trustType).then(response => {
          this.listOfAssets = response;
        })
  }

  closeModal(closeData: string){
    this.dialogRef.close(closeData);
  }

  addNewGift(){
    let newGift: Gift = {
      id: '',
      amount: 0,
      contactId: '',
      fullName: '',
      numOfYears: 0
    }

    this.data.currentGifts.push(newGift);
  }

  onTrustBuilderButtonClick(trustKeyValue: string){

    let buttonData = LIST_OF_TRUST_BUTTONS.find(button => button.key == trustKeyValue );
    this.advisorService.getEstateLabData(this.currentClientId, this.data.scenario).then(response => {
      this.data.currentDocument = DocumentFactory.create(ESTATEDOC_TRUST, buttonData.key).loadDataFrom({
        ...response,
        scenario: this.data.scenario,
      });
    })

    this.loadAssets(buttonData.key);

    this.data.sectionTitle = buttonData.title;
    this.data.trustFormType = buttonData.template
    this.data.trustButtonData = buttonData;
  }

  addBeneficiary(document:EstateDocument, assetId: string, type:string){
    document.addBeneficiaryToAsset(assetId, type);
  }

  addAsset(document:EstateDocument){
    document.addAsset();
  }

  deleteBeneficiary(document:EstateDocument, assetId: string, beneficiaryId: string){
    let foundAsset = document.assets.find(asset => asset.id == assetId);
    if(foundAsset){
      deleteOneFromArrayOfObjs(foundAsset.beneficiaries, 'id', beneficiaryId);
    }
  }

  deleteAsset(document:EstateDocument, assetId: string){
      deleteOneFromArrayOfObjs(document.assets, 'id', assetId);
  }

  deleteGift(document:EstateDocument, assetId: string){
    deleteOneFromArrayOfObjs(this.data.currentGifts, 'id', assetId);
}


  getUniqueFromList(arrayA: any[], arrayB: any[], id: string = 'id'): any[]{
    let result = ArrayOfObjsAminusB(arrayA, arrayB, id);
    return result
  }

  isUniqueInObjList(objList: any[], elem: any, compareValue: string): boolean{
    return Boolean (objList.find(obj => obj[compareValue] == elem[compareValue]))
  }


  getClassLiElemDisabled(objList: any[], elem: any, compareValue: string = 'id'){
    return {
      'listElementDisabled' : this.isUniqueInObjList(objList, elem, compareValue)
    }
  }

  getObjFromArrayById(array: any[], id: string, keyId: string = 'id'): any {
    let foundObj = array.find(obj => obj[keyId] == id);
    return foundObj != undefined ? foundObj : {}
  }

  assetPercentageAvailable(asset: EstateDocumentAsset, beneficiary: EstateDocumentAssetBeneficiary, category?: string){
    let percentageSum: number = 0;
    let percentageAvailable: number = 0;

    asset.beneficiaries
    .filter(beneficiary => category ? beneficiary.addons.category == category: true)
    .map(innerBeneficiary => {
      if(innerBeneficiary.id != beneficiary.id){
        percentageSum += innerBeneficiary.percentage;
      }

    })
    if(percentageSum > 100) percentageSum = 100;

    percentageAvailable = 100 - percentageSum;

    // Don't allow a negative.
    if(beneficiary.percentage < 0) beneficiary.percentage = 0;

    // Don't allow a number bigger than the available amount.
    if(beneficiary.percentage > percentageAvailable) beneficiary.percentage = percentageAvailable;
  }

  deleteExecutor(key: any) {
    delete this.data.currentDocument.addons[key];
  }

  checkSum(asset: EstateDocumentAsset){

    let percentageSum: number = 0;
    let percentageAvailable: number = 0;
    asset.beneficiaries.map(innerBeneficiary => {
        percentageSum += innerBeneficiary.percentage;
    })

    percentageAvailable = 100 - percentageSum;

    if(percentageAvailable == 0){
      return true
    }else{
      return false
    }
  }

  async saveEstateLabDoc(saveCondition?: string){

    try {
      if(!saveCondition){
        await this.data.currentDocument.save(this.advisorService, this.currentClientId);
        //EstateLabMapper.saveDocument(this.advisorService, this.currentClientId, this.data.currentDocument);
      }else if(saveCondition == 'gift'){
        await EstateLabMapper.saveGifts(this.advisorService, this.currentClientId, this.data.currentGifts, this.data.scenario);
      }
      this.closeModal('');
    } catch(e) {
      console.log(e);
    }
  }

  isSaveButtonEnabled(): boolean{
    let response: boolean = true;
    if(this.data.currentDocument){
      if (this.data.currentDocument.docType === 'DAF' || this.data.currentDocument.docType === 'CRATCRUT') {
        return this.data.currentDocument.assetsHasValue();
      }
      response = response && this.data.currentDocument.areAllAssets100PercentAssigned();
      // Trust term is required on tempalteB
      if(this.data.trustFormType == 'typeB'){
        response = response && Boolean(this.data.currentDocument.addons.years);
      }
    }
    return response

    //this.data.sectionTitle != 'Gift Strategy'
  }

  assetBeneficiariesByCategory(asset: EstateDocumentAsset, category: string): EstateDocumentAssetBeneficiary[]{
      return asset.beneficiaries.filter(beneficiary => beneficiary.addons.category == category)
  }

  calculateValue(porcentage, value){
    let s = (porcentage * value) / 100
    return s
  }

  assetSelectBehaviour(assetId: string){
    let foundAsset = this.data.currentDocument.assets.find(asset => asset.id === assetId);
    if(foundAsset){
      if(this.data.trustButtonData && (this.data.trustButtonData.key == 'DAF' || this.data.trustButtonData.key == 'CRATCRUT')){
        if(this.assetBelongsToCategory(assetId, 'financial')) {
          foundAsset.addons.percentageToContribute = 100;
        }
      }
    }
  }

  assetBelongsToCategory(assetId: string, category: string): boolean{
    let response: boolean = false;
    let foundAsset = this.listOfAssets.find(asset => asset.id == assetId);
    if(foundAsset){
      let categoryList: string[] = foundAsset.type.split(' ');
      response = categoryList.includes(category);
    }

    return response;
  }
}

interface DialogData {
  sectionTitle: string,
  currentDocument?: EstateDocument,
  trustFormType?: string,
  assetCategory?: string,
  currentGifts?: Gift[],
  trustButtonData?: any,
  scenario: any,
}
