import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moneyguide-clients',
  templateUrl: './moneyguide-clients.component.html',
  styleUrls: ['./moneyguide-clients.component.css']
})
export class MoneyguideClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
