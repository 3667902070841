export interface Lines{
  assetId: string
  title: string,
  type: ('text' | 'number' | 'money' | 'stepperNumber' | 'staticText' | 'year' | 'status' | 'state' | 'sstb' | 'subsection' | 'dynamic' | 'name' | 'addButton'),
  isEditable?: boolean,
  alwaysVisible?: boolean,
  value?: (string | number),
  defaultValue?: (string | number),
  group?: string,
  showOnlyGains?: Boolean,
  calculatorId?: string,
  revertNumber?: boolean,
  isNegative?: true
}

export interface CalculatorSection {
  id: string,
  title: string,
  visible?: boolean;
  lines: Lines[],
}

export interface Calculators {
  id: string,
  title: string,
  sections?: CalculatorSection[],
}

export interface calculatorTransformable{
  currentDataToStandarCalcData(): any
}

export const CALCULATORS_CATALOG: Calculators[] = [
  {
    id: 'scheduleACalculator',
    title: 'Schedule A: Itemized Deductions Projections',
    sections: [
      {
        visible: true,
        id: 'itemizedDeductions',
        title: 'Schedule A: Itemized Deductions Projections',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'medicalDentalExpenses', title:'Medical + Dental Expenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'agi', title: 'AGI', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'seventyFiveOfAgi',title:'7.5% of AGI', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'excessOver75OfAgi',title:'Excess over 7.5% of AGI', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'stateAndLocalTaxes', title: 'State and Local Taxes', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'otherTaxes', title: 'Other Taxes', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalNonFederalTaxes', title: 'Total Non-Federal Taxes', type:'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'homeMortgageIntIns', title: 'Home Mortgage Int + Ins', type:'money', alwaysVisible: false, isEditable: true },
          {assetId: 'investmentInterest', title: 'Investment Interest', type:'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalInterestPaid', title: 'Total Interest Paid', type:'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'giftsByCashOrCheck', title: 'Gifts by Cash or Check', type: 'money', calculatorId:'charitableContributionCalculator', revertNumber: false, alwaysVisible: false, isEditable: true },
          {assetId: 'giftsOtherThanCashOrCheck', title: 'Gifts Other than Cash or Check', calculatorId:'charitableContributionCalculator', revertNumber: false, type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'carryoverFromLastYear', title: 'Carryover from Last Year', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalGiftsToCharity', title: 'Total Gifts to Charity', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'casualtyAndTheftLosses', title: 'Casualty and Theft Losses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'otherItemizedDeductions', title: 'Other Itemized Deductions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalItemizedDeductions', title: 'Total Itemized Deductions', type: 'money', alwaysVisible: false, isEditable: true },
        ],
      },
      {
        visible: true,
        id: 'standard',
        title:'Standard vs Itemized Determination',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'standard', title:'Standard', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'itemized', title: 'Itemized', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'standardOrItemized',title:'Standard or Itemize?', type: 'text', alwaysVisible: true, isEditable: false },
          {assetId: 'deductionToFlowTo1040Pg1',title:'Deduction to flow to 1040 pg 1', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
    ]
  },
  {
    id: 'scheduleBCalculator',
    title: 'Schedule B: Interest and Ordinary Dividends',
    sections: [
      {
        visible: true,
        id: 'interest',
        title: 'Part I: Interest',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'subtotal', title: 'Subtotals', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'excludableInterest', title: 'Excludable Interest', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'includableInterest', title: 'Includable Interest (net) on 1040', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'ordinaryDividends',
        title: 'Part II: Ordinary Dividends',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'subtotal', title: 'Subtotals', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'includableDividends', title: 'Includable Dividends', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
    ]
  },
  {
    id: 'scheduleCCalculator',
    title: 'Schedule C: Profit or Loss from Business',
    sections: [
      {
        visible: true,
        id: 'income',
        title: 'Part I: Income',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'grossReceipts', title: 'Gross Receipts', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'returnsAndAllowances', title: 'Returns and Allowances', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'grossReceiptsNet', title: 'Gross Receipts Net', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'costOfGoodsSold', title: 'Cost of Goods Sold', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'grossProfit', title: 'Gross Profit', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'otherIncome', title: 'Other Income', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'grossIncome', title: 'Gross Income', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'expenses',
        title: 'Part II: Expenses',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'advertising', title: 'Advertising', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'carAndTruckExpenses', title: 'Car and Truck Expenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'commissionsAndFees', title: 'Commissions and Fees', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'contractLabor', title: 'Contract Labor', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'depletion', title: 'Depletion', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'depreciationAndSec179Expense', title: 'Depreciation and Sec.179 Expense', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'employeeBenefitPrograms', title: 'Employee Benefit Programs', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'insurance', title: 'Insurance', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'mortgageInterest', title: 'Mortgage Interest', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'otherInterest', title: 'Other Interest', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'legalAndProfessionalServices', title: 'Legal and Professional Services', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'officeExpense', title: 'Office Expense', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'pensionAndProfitSharingPlans', title: 'Pension and Profit Sharing Plans', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'rentLeaseFromVehiclesMachineryAndEquipment', title: 'Rent/Lease from Vehicles, Machinery and Equipment', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'rentLeaseFromOtherBusinessProperty', title: 'Rent/Lease from Other Business Property', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'repairsAndMaintenance', title: 'Repairs and Maintenance', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'supplies', title: 'Supplies', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'taxesAndLicenses', title: 'Taxes and Licenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'travel', title: 'Travel', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'deductibleMeals', title: 'Deductible Meals', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'utilities', title: 'Utilities', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'wages', title: 'Wages', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'otherExpenses', title: 'Other Expenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalExpenses', title: 'Total Expenses', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'tentativeProfitOrLoss', title: 'Tentative Profit or Loss', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'homeOfficeDeduction', title: 'Home Office Deduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netProfitOrLossToSch1', title: 'Net Profit or Loss', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
    ]
  },
  {
    id: 'scheduleDCalculator',
    title: 'Schedule D: Capital Gains and Losses',
    sections: [
      {
        visible: true,
        id: 'stcgl', /* 'shortTermCapitalGains'*/
        title: 'Part 1: Short-Term Capital Gains and Losses',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'bLossesNotReported', title: '1099-B Losses not reported on 8949', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxATransactions', title: 'Total 8949 Box A Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxBTransactions', title: 'Total 8949 Box B Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxCTransactions', title: 'Total 8949 Box C Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'shortTermGainFromforms', title: 'Short-Term Gain from forms 6252, 4684, 6781 and 8824.', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'shortTermGainLossFromPassthroughEntities', title: 'Short-Term Gain/(Loss) from Passthrough Entities', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'shortTermCapitalLossCarryover', title: 'Short-Term Capital Loss Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netShortTermCapitalGainOrLoss', title: 'Net Short-Term Capital Gain (or Loss)', type: 'staticText', alwaysVisible: true, showOnlyGains: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'ltcgl', /* 'longTermCapitalGainAndLoses' */
        title: 'Part 2: Long-Term Capital Gains and Losses',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'bGainsAndLossesNotReportedOn8949', title: '1099-B Losses not reported an 8949', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxDTransactions', title: 'Total 8949 Box D Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxETransactions', title: 'Total 8949 Box E Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'total8949BoxFTransactions', title: 'Total 8949 Box F Transactions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'gainsFrom4794Part1Forms', title: 'Gains from 4794 Part I. Forms 2439 and 6252 LT Gains, LT Gain (or Loss) from Forms 4684, 6781 and 8824', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netLongTermGainOrLossfromPassthroughEntities', title: 'Net Long-Term Gain or Loss from Passtrough Entities', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'capitalGainDistributions', title: 'Capital Gain Distributions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'longTermCapitalLossCarryover', title: 'Long-Term Capital Loss Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netLongTermCapitalGainOrLoss', title: 'Net Long-Term Capital Gain (or Loss)', type: 'staticText', showOnlyGains: true, alwaysVisible: true, isEditable: false },
        ],
      },
      {
        visible: true,
        id: 'summary',
        title: 'Part 3: Summary',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'net', title: 'Net', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'gainWorksheetIfApplicable', title: '28% Gain Worksheet (if applicable)', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'unrecapturedSec1250GainWorksheet', title: 'Unrecaptured Sec 1250 Gain Worksheet', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'lesserOfNetCapitalLossOr3K', title: 'Lesser of net Capital Loss', type: 'staticText', alwaysVisible: true, showOnlyGains: true, isEditable: false },
        ],
      }
    ]
  },
  {
    id: 'scheduleECalculator',
    title: 'Schedule E',
    sections: [
      {
        visible: true,
        id: 'rentalRealEstateOrRoalties',
        title: 'Part I: Income or Loss from Rental Real Estate or Royalties',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'totalOfAllAmountsOnLine3ForRentalProperties', title: 'Total Rent Received for all Rental Properties', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalOfAllAmountsOnLine4ForRoyaltyProperties', title: 'Total Royalties Received for all Rental Properties', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalOfAllAmountsOnLine12ForAllProperties', title: 'Total Mortgage Interest for all Rental Properties', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalOfAllAmountsOnLine18ForAllProperties', title: 'Total Depreciation Expense for all Rental Properties', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalOfAllAmountsOnLine20ForAllProperties', title: 'Total Expenses for All Properties', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'income', title: 'Income', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'lossesMustBeNegative', title: 'Losses (must be negative)', type:'money', alwaysVisible: true, isEditable: true, isNegative: true },
          {assetId: 'totalRentalRealEstateAndRoyaltyIncomeLoss', title: 'Total Rental Real Estate and Royalty Income (Loss)', type:'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
      {
        visible: true,
        id: 'partnershipsOrCoportations',
        title:'Part II: Income or Loss from Partnerships or S Corporations',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'passiveAndNonpassiveIncomeOrLossFromSchK1', title: 'Passive and Nonpassive Income or Loss from Sch K-1', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'form4582NonpassiveK1LossFormMustBeNegative', title: 'Form 4582, Nonpassive K-1 loss, Form (must be negative) 4582 Sec 179 Expense Deduction', type: 'money', alwaysVisible: false, isEditable: true, isNegative: true},
          {assetId: 'totalPartnershipAndSCorporationIncomeOrLoss', title: 'Total Partnership and S Corporation Income or (Loss)', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
      {
        visible: true,
        id: 'estatesAndTrusts',
        title:'Part III: Income or Loss from Estates and Trusts',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'incomeFromSchK1', title: 'Income from Sch K-1', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'deductionOrLossFromForm8582K1MustBeNegative', title: 'Deduction or Loss from Form 8582 & K-1 (must be negative)', type: 'money', alwaysVisible: false, isEditable: true, isNegative: true },
          {assetId: 'totalEstateAndTrustIncomeOrLoss', title: 'Total Estate and Trust Income or (Loss)', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      },
      {
        visible: true,
        id: 'remics',
        title:'Part IV: Income or Loss from REMICs',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'subtotal', title: 'Subtotal (of income and loss from Sch Q lines 3b and lb)', type: 'money', alwaysVisible: true, isEditable: true },
        ],
      },
      {
        visible: true,
        id: 'summary',
        title:'Part V: Summary',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'netFarmRentalIncomeLossFromForm4835', title: 'Net Farm Rental Income/(Loss) from Form 4835', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalIncomeOrLoss', title: 'Total Income or (Loss)', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'reconciliationOfFarmingAndFishingIncome', title: 'Reconciliation of Farming and Fishing Income', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'reconciliationForRealEstateProfessionals', title: 'Reconciliation for Real Estate Professionals', type: 'money', alwaysVisible: false, isEditable: true },
        ],
      },
      {
        visible: true,
        id: 'selfEmploymentIncome',
        title:'SE Input: Self-Employment Income',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'selfEmploymentIncomeFromNonPassivePartnership', title: 'Self-Employment Income from non-passive partnership K-1s (box 14a)', type: 'money', alwaysVisible: false, isEditable: true },
        ],
      },
    ]
  },
  {
    id: 'scheduleOneCalculator',
    title: 'Schedule I Projections',
    sections: [
      {
        visible: true,
        id: 'additionsToIncome',
        title: 'Part 1: Additions to Income',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'taxableRefundsCreditsStateLocalTaxOffsets', title: 'Taxable Refunds, Credits, State & Local Tax Offsets', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'alimonyReceived', title: 'Alimony Received', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'schCBusinessIncomeLoss', title: 'Sch C Business Income/(Loss)', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'otherGainsLosses', title: 'Other Gains and Losses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'schEIncome', title: 'Sch E Income', type: 'money', calculatorId:'scheduleECalculator', revertNumber: false, alwaysVisible: false, isEditable: true },
          {assetId: 'farmIncomeLoss', title: 'Form Income/(Loss)', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'unemploymentCompensation', title: 'Unemployment Compensation', type: 'money', alwaysVisible: false, isEditable: true },
          /*button Other Adjustments*/
          {assetId: 'otherAdjustments', title: 'Other Adjustments', type: 'subsection', alwaysVisible: true, isEditable: false },
          {assetId: 'netOperatingLoss', title: 'Net Operating Loss', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'gamblingIncome', title: 'Gambling Income', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'cancellationOfDebt', title: 'Cancellation Gambling Income on of Debt', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'foreignEarnedIncomeExclusion', title: 'Foreign Earned Income Exclusion from Form 2555', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'taxableHsaDistribution', title: 'Taxable HSA Distribution', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'alaskaPermanentFundsDividends', title: 'Alaska Permanent Funds Dividends', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'juryDutyPay', title: 'Jury Duty Pay', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'prizesAndAwards', title: 'Prizes and Awards', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'activityNotEngagedInForProfitIncome', title: 'Activity not engaged in for profit income', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'stockOptions', title: 'Stock Options', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'personalPropertyRentalIncome', title: 'Personal Property Rental Income', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'olympicAndParalympicMedals', title: 'Olympic and Paralympic Medals and USOC Prize Money', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'section951AInclusion', title: 'Section 951(a) Inclusion', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'section951AAInclusion', title: 'Section 951A(a) Inclusion', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'section461LExcessBusinessLoss', title: 'Section 461 (l) excess business loss', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'taxableAbleDistributions', title: 'Taxable ABLE Distributions', type: 'money', alwaysVisible: false, group: 'otherAdjustments', isEditable: true },
          {assetId: 'otherIncome', title: 'Other Income', type: 'staticText', alwaysVisible: false, group: 'otherAdjustments', isEditable: false },
          {assetId: 'totalOtherOtherIncome', title: 'Total "Other" Other Income', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'totalOtherIncome', title: 'Total Other Income', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'adjustmentsToIncome',
        title: 'Part 2: Adjustments to Income',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'educatorExpenses', title: 'Educator Expenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'certainFeeBasedExpensesForm2106', title: 'Certain fee based expenses Form 2106', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'hsaDeduction', title: 'HSA Deduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'armedForcesMovingExpenses', title: 'Armed Forces Moving Expenses', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'deductiblePartOfSeTax', title: 'Deductible Part of SE Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'seSepSimpleAndQualPlans', title: 'SE SEP. SIMPLE and Qual. Plans', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'seHealthInsurance', title: 'SE Health Insurance', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'earlySavingsWithdrawalPenalty', title: 'Early Savings Withdrawal Penalty', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'alimonyPaid', title: 'Alimony Paid', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'iraDeduction', title: 'IRA Deduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'studentLoanIntDeduction', title: 'Student Loan Int Deduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'archerMsaDeduction', title: 'Archer MSA Deduction', type: 'money', alwaysVisible: false, isEditable: true },
          //button to collapse Other Adjustments
          {assetId: 'otherAdjustments_subgroup', title: 'Other Additional Taxes', type: 'subsection', alwaysVisible: true, isEditable: false },
          {assetId: 'juryDutyPay', title: 'Jury Duty Pay', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'personalPropertyRentalDeductions', title: 'Personal Property Rental Deductions', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'nontaxableAmountOfUsocAndParalympicPrizeMoney', title: 'Nontaxable amount of USOC and Paralympic Prize Money', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'reforestationAmortizationAndExpenses', title: 'Reforestation Amortization and Expenses', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'repaymentOfSupplementalEmploymentBenefits', title: 'Repayment of Supplemental Employment Benefits', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'pensionPlans', title: '501(c)(18)(D) Pension Plans', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'contributionsByCertainChaplainstoSection403BPlans', title: 'Contributions by Certain Chaplains to Section 403(b) Plans', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'attorneyFeesCourtCostsForCertainUnlawful', title: 'Attorney Fees + Court Costs for Certain Unlawful Discrimination Suits', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'attorneyFeesCourtCostsInconnectionWithAlerting', title: 'Attorney Fees + Court Costs in connection with alerting the IRS about a violation of its tax law', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'housingDeductionForm2555', title: 'Housing Deduction Form 2555', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'excess67ESchK1Deductions', title: 'Excess 67(e) Sch K-I Deductions', type: 'money', alwaysVisible: false, group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'otherAdjustments', title: 'Other Adjustments', type: 'money', alwaysVisible: false,  group:'otherAdjustments_subgroup', isEditable: true },
          {assetId: 'totalOtherAdjustments', title: 'Total Other Adjustments', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'adjustmentsToIncome', title: 'Adjustments to Income', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      }
    ]
  },
  {
    id: 'scheduleTwoCalculator',
    title: 'Schedule 2 - Additional Taxes',
    sections: [
      {
        visible: true,
        id: 'tax',
        title: 'Part 1: Tax',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'alternativeMinimumTax', title: 'Alternative Minimum Tax', calculatorId:'form6251AMTCalculator', revertNumber: false, type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'excessAdvancePremiumTaxCreditRepayment', title: 'Excess Advance Premium Tax Credit Repayment', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'additionalTaxesPaidSubtotal', title: 'Additional Taxes Paid Subtotal', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'otherTaxes',
        title: 'Part 2: Other Taxes',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'sETax', title: 'S/E Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'socialSecurityMedicareTaxonUnreportedTipIncome', title: 'Social Security + Medicare Tax on Unreported Tip Income', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'uncollectedSocialSecurityMedicareTaxOnWages', title: 'Uncollected Social Security + Medicare Tax on Wages', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'totalAdditionalSocialSecurityandMedicareTaxes', title: 'Total Additional Social Security and Medicare Taxes', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'additionalTaxOnIrAsOrOtherTaxFavoredAccounts', title: 'Additional Tax on IRAs or Other Tax Favored Accounts', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'householdEmploymentTaxes', title: 'Household Employment Taxes', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'repaymentOf1StTimeHomebuyerCredit', title: 'Repayment of 1st Time Homebuyer Credit', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'additionalMedicareTax', title: 'Additional Medicare Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netInvestmentIncomeTax', title: 'Net Investment Income Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'uncollectedSocialSecurityAndMedicareOrRrtaTaxOnTipsOrGroupTermLifeInsuranceFromFormW2Box12', title: 'Uncollected social security and Medicare or RRTA tax on tips or group-term life insurance from Form W-2, box 12', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'interestOnTaxDueOnInstallmentIncome', title: 'Interest on Tax Due on Installment Income', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'interestOnDeferredTaxOnGainFromCertainInstallmentSales', title: 'Interest on Deferred Tax on Gain from certain Installment Sales', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'recaptureOfLowIncomeHousingCredit', title: 'Recapture of Low Income Housing Credit', type: 'money', alwaysVisible: false, isEditable: true },
          //button to collapse other additional Taxes
          {assetId: 'otherAdditionalTaxesSub', title: 'Other Additional Taxes', type: 'subsection', alwaysVisible: true, isEditable: false },
          {assetId: 'recaptureOfOtherCredits', title: 'Recapture of Other Credits', type: 'money', alwaysVisible: false, group: 'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'recaptureOfFederalMortgageSubsidy', title: 'Recapture of federal mortgage subsidy', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'additionalTaxOnHsaDistributions', title: 'Additional Tax on HSA Distributions', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'additionalTaxOnHsaForNotRemainingAnEligibleIndividual', title: 'Additional tax on HSA for not remaining an eligible individual', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'additionalTaxOnArcherMsaDistributions', title: 'Additional Tax on Archer MSA Distributions', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'additionalTaxOnMedicareMsaDistributions', title: 'Additional Tax on Medicare MSA Distributions', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'recaptureOfACharitableContributionDeductionRelatedToAFractionalInterestInTangiblePersonalProperty', title: 'Recaptured of a charitable contribution deduction related to a fractional interest in tangible personal property', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'incomeReceivedFromNonSection409ADeferredCompensationPlan', title: 'Income received from non section 409A deferred compensation plan', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'incomeReceivedFromSection457ADeferredCompensationPlan', title: 'Income received from section 457A deferred compensation plan', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'section72M5ExcessBenefitsTax', title: 'Section 72(m)(5) excess benefits tax', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'goldenParachutePayments', title: 'Golden Parachute Payments', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'taxOnAccumulationDistributionOfTrusts', title: 'Tax on accumulation distribution of trusts', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'exciseTaxOnInsiderStockCompFromAnExpatriatedCorporation', title: 'Excise tax on insider stock comp from an expatriated corporation', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'lookBackInterest', title: 'Look-back interest', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'taxOnNonEci', title: 'Tax on non-ECI', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'form8621InterestLine16F', title: 'Form 8621 Interest Line 16f', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'form8621InterestLine24', title: 'Form 8621 Interest Line 24', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'anyOtherTaxes', title: 'Any Other Taxes', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'totalOtherAdditionalTaxes', title: 'Total Other Additional Taxes', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'schedule8812', title: 'Schedule 8812', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'section965NetTaxLiability', title: 'Section 965 net tax liability', type: 'money', alwaysVisible: false, group:'otherAdditionalTaxesSub', isEditable: true },
          {assetId: 'totalOtherTaxes', title: 'Total Other Taxes', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      }
    ]
  },
  {
    id: 'scheduleThreeCalculator',
    title: 'Schedule 3 - Additional Credits & Payments',
    sections: [
      {
        visible: true,
        id: 'nonRefundableCredits',
        title: 'Part 1: Nonrefundable Credits',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'foreignTax', title: 'Foreign Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'childDependentCare', title: 'Child & Dependent Care', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'education', title: 'Education', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'retirementSavingsContributions', title: 'Retirement Savings Contributions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'residentialEnergy', title: 'Residential Energy', type: 'money', alwaysVisible: false, isEditable: true },
          // {assetId: 'farmIncome', title: 'Farm Income/(Loss)', type: 'money', alwaysVisible: false, isEditable: true },
          //button other additional taxes
          {assetId: 'otherNonrefundableCreditsSub', title: 'Other Nonrefundable Credits', type: 'subsection', alwaysVisible: true, isEditable: false },
          {assetId: 'generalBusiness', title: 'General Business', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'priorYearMinimumTax', title: 'Prior Year Minimum Tax', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'adoption', title: 'Adoption', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'elderlyOrDisabled', title: 'Elderly or Disabled', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'alternativeMotorVehicle', title: 'Alternative Motor Vehicle', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'qualifiedPlugInMotorVehicle', title: 'Qualified Plug-in Motor Vehicle', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'mortgageInterest', title: 'Mortgage Interest', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'dcFirstTimeHomebuyer', title: 'DC First-Time Homebuyer', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'qualifiedElectricVehicle', title: 'Qualified Electric Vehicle', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'alternativeFuelVehicleRefuelingProperty', title: 'Alternative Fuel Vehicle Refueling Property', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'taxCreditBondholder', title: 'Tax Credit Bondholder', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'form8978Amount', title: 'Form 8978 Amount', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'otherNonrefundableCredits', title: 'Other Nonrefundable Credits', type: 'money', alwaysVisible: false, group: 'otherNonrefundableCreditsSub', isEditable: true },
          {assetId: 'otherOtherNonrefundableCredits', title: '"Other" Other Nonrefundable Credit', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'totalNonrefundableCredits', title: 'Total Nonrefundable Credits', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'otherPaymentsAndRefundablePayments',
        title: 'Part 2: Other Payments and Refundable Credits',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'netPremiumTax', title: 'Net Premium Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'amountPaidWithRequestForExtensionToFile', title: 'Amount Paid with Request for Extension to File', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'excessSocialSecurityAndTier1RrtaTaxWithheld', title: 'Excess Social Security and Tier 1 RRTA Tax Withheld', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'federalFuelTax', title: 'Federal Fuel Tax', type: 'money', alwaysVisible: false, isEditable: true },
          //other payments button
          {assetId: 'otherPayments', title: 'Other Payments or Refundable Credits', type: 'subsection', alwaysVisible: true, isEditable: false },
          {assetId: 'form2439', title: 'Form 2439', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'qualifiedSickAndFamilyLeavePreMarch312021', title: 'Qualified sick and Family Leave Pre March 31, 2021', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'healthCoverageTaxCredit', title: 'Health Coverage Tax Credit', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'repaymentOfAmountsIncludedInIncomeFromEarlierYears', title: 'Repayment of Amounts Included in Income from Earlier Years', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'deferredAmountSec965Liability', title: 'Deferred Amount Sec. 965 Liability', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'childAndDependentCareExpenses', title: 'Child and Dependent Care Expenses', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'qualifiedSickAndFamilyLeavePostMarch312021', title: 'Qualified Sick and Family Leave Post March 31, 2021', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'otherPaymentsOrRefundableCredits', title: 'Other Payments or Refundable Credits', type: 'money', alwaysVisible: false, group: 'otherPayments', isEditable: true },
          {assetId: 'otherOtherPaymentsAndRefundableCredits', title: '"Other” Other Payments and Refundable Credits', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'totalOtherPaymentsAndRefundableCredits', title: 'Total Other Payments and Refundable Credits', type: 'staticText', alwaysVisible: true, isEditable: false },
        ],
      }
    ]
  },
  {
    id: 'qbidCalculator',
    title: 'QBID Calculator',
    sections: [
      {
        visible: true,
        id: 'qbid',
        title: 'QBID Calculator',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'filingStatus', title: 'Filing Status', type: 'text', alwaysVisible: false, isEditable: false },
          {assetId: 'taxableIncomePreQbi', title: 'Taxable Income Pre QBI', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'cutOffBeginning', title: 'Cut-Off Beginning (Income threshold)', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'cutOffEnd', title: 'Cut-Off End (Income threshold)', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'qbiBracket', title: 'QBI Bracket', type: 'text', alwaysVisible: false, isEditable: false },
          {assetId: 'ofQbidDisallowed', title: '% of QBID Disallowed', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'qbi', title: 'QBI', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'twentyOfQbi', title: '20% of QBI', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'allocableW2Wages', title: 'Allocable of share W-2 Wages', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'fiftyOfWages', title: '50% of wages', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'twentyfiveOfWages', title: '25% of wages', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'allocableUbia', title: 'Allocable UBIA', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'twoOfUbia', title: '2.5% of UBIA', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'twentyfiveOfWages25OfUbia', title: '25% of Wages + 2.5% of UBIA', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'greaterOf50OfWagesor2525Ubia', title: 'Greater of 50% of Wages or 25% + 2.5% UBIA', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'twentyVsWageUbiaLimit', title: '20% vs Wage/UBIA limit', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'phasedInReduction', title: 'Phased-in Reduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'qbiPrePatronReduction', title: 'QBI Pre-Patron Reduction', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'patronReduction', title: 'Patron Reduction', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'postPatronQbi', title: 'Post-Patron QBI', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'qualReitDivsPtpIncome', title: 'Qual. REIT Divs + PTP Income', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'reitPtpLossCarryforward', title: 'REIT + PTP Loss Carryforward', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'reitPtpSubtotal', title: 'REIT + PTP Subtotal', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'twentyOfReitPtp', title: '20% of REIT + PTP', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'qbidBeforeIncomeLim', title: 'QBID before income lim.', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'netCapitalGain', title: 'Net Capital Gain', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'line33Line34', title: 'Line 33 - Line 34', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'twentyLimit', title: '20% Limit', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'qbidPreDpad', title: 'QBID Pre-DPAD', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'aDpad', title: '199A DPAD', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'aDpadAllowed', title: '199A DPAD Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'totalQbid', title: 'Total QBID', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'reitPtpDivLossCarryforward', title: 'REIT + PTP Div Loss Carryforward', type: 'staticText', alwaysVisible: true, isEditable: false },

          /*{assetId: 'name', title:'', type: 'name', alwaysVisible: true},
          {assetId: 'filingStatus', title: 'Filing Status', type: 'status', alwaysVisible: false},
          {assetId: 'taxableIncomePreQbi', title: 'Taxable Income Pre QBI', type: 'staticText', alwaysVisible: false},
          {assetId: 'cutOffBeginning', title: 'Cut-Off Beginning', type: 'staticText', alwaysVisible: false},
          {assetId: 'cutOffEnd', title: 'Cut-Off End', type: 'staticText', alwaysVisible: false},
          {assetId: 'sstbNonSstbIfAboveTheCutOff', title: 'SSTB/Non-SSTB If Above the Cut-Off', type: 'sstb', alwaysVisible: false},
          {assetId: 'qbiBracket', title: 'QBI Bracket', type: 'text', alwaysVisible: false},
          {assetId: 'ofQbidDisallowed', title: '% of QBID Disallowed', type: 'staticText', alwaysVisible: false},
          {assetId: 'qbi', title: 'QBI', type: 'money', alwaysVisible: false},
          {assetId: 'qbiDisallowed', title: 'QBI Disallowed', type: 'money', alwaysVisible: false},
          {assetId: 'qualReitDivs', title: 'Qual. REIT Divs', type: 'money', alwaysVisible: false},
          {assetId: 'ptpIncome', title: 'PTP Income', type: 'money', alwaysVisible: false},
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true},
          {assetId: 'twentyOfTotal', title: '20% of Total', type: 'staticText', alwaysVisible: true},
          {assetId: 'twentyOfTaxableIncomeNetCapitalGain', title: '20% of Taxable income - Net Capital Gain', type: 'staticText', alwaysVisible: true},
          {assetId: 'lesserOfTwo', title: 'Lesser of Two', type: 'staticText', alwaysVisible: true}*/
         ]
      },
    ]
  },
  {
    id: 'charitableContributionCalculator',
    title: 'Charitable Contributions Calculator',
    sections: [
      {
        visible: true,
        id: 'cashContributions',
        title: 'Cash Contributions - Qualified Charity',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'py30CharitableContributionBucketCarryover', title: 'PY 30% Charitable Contribution Bucket Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'adjTotal', title: 'Adj Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'thirtyOfAgiLimit', title: '30% of AGI Limit', type: 'staticText', alwaysVisible: false, isEditable: false }, //type: 'money'
          {assetId: 'deductionAllowed', title: 'Deduction Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'carryoverToNextYear', title: 'Carryover to Next Year', type: 'staticText', alwaysVisible: true, isEditable: false },
         ]
      },
      {
        visible: true,
        id: 'sixtyAgiLimited',
        title: 'Cash Contributions - 60% AGI Limited Contributions',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'py60CharitableContributionBucketCarryover', title: 'PY 60% Charitable Contribution Bucket Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'adjTotal', title: 'Adj Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'sixtyOfAgiLimit', title: '60% of AGI Limit', type: 'staticText', alwaysVisible: false, isEditable: false }, //type: 'money'
          {assetId: 'deductionAllowed', title: 'Deduction Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'carryoverToNextYear', title: 'Carryover to Next Year', type: 'staticText', alwaysVisible: true, isEditable: false },
          ]
      },
      {
        visible: true,
        id: 'noncashContributions',
        title: 'Capital Gain Contributions',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'py30CapitalGainCarryover', title: 'PY 30% Charitable Contribution Bucket Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'adjTotal', title: 'Adj Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'thirtyOfAgiLimit', title: '30% of AGI Limit', type: 'staticText', alwaysVisible: false, isEditable: false }, //type: 'money'
          {assetId: 'deductionAllowed', title: 'Deduction Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'carryoverToNextYear', title: 'Carryover to Next Year', type: 'staticText', alwaysVisible: true, isEditable: false },
          ]
      },
      {
        visible: true,
        id: 'fiftyQualifiedOrgsCategory',
        title: '50% Qualified Orgs (Category I)',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'py50CharitableContributionBucketCarryover', title: 'PY 50% Charitable Contribution Bucket Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'adjTotal', title: 'Adj Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'fiftyOfAgiLimit', title: '50% of AGI Limit', type: 'staticText', alwaysVisible: false, isEditable: false }, //type: 'money'
          {assetId: 'deductionAllowed', title: 'Deduction Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'carryoverToNextYear', title: 'Carryover to Next Year', type: 'staticText', alwaysVisible: true, isEditable: false },
          ]
      },
      {
        visible: true,
        id: 'twentyQualifiedOrgsCategory',
        title: '20% Qualified Orgs (Category 2)',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: '', title: '', type: 'addButton', alwaysVisible: false, value: '', isEditable: false },
          {assetId: 'total', title: 'Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'py20CharitableContributionBucketCarryover', title: 'PY 20% Charitable Contribution Bucket Carryover', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'adjTotal', title: 'Adj Total', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'twentyOfAgiLimit', title: '20% of AGI Limit', type: 'staticText', alwaysVisible: false, isEditable: false }, //type: 'money'
          {assetId: 'deductionAllowedUnder20Rule', title: 'Deduction Allowed under 20% rule', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'lesserOfAllowed', title: 'Lesser of Allowed', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'carryoverToNextYear', title: 'Carryover to Next Year', type: 'staticText', alwaysVisible: true, isEditable: false },
          ]
      },
    ]
  },
  {
    id: 'form6251AMTCalculator',
    title: 'Form 6251: AMT',
    sections: [
      {
        visible: true,
        id: 'partOne',
        title: 'Part I: Alternative Minimum Taxable Income',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'federalTaxableIncome', title: 'Federal Taxable Income', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'itemizedTaxesOrStdDeduction', title: 'Itemized Taxes or Std Deduction', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'taxRefundSch1', title: 'Tax Refund Sch 1', calculatorId:'scheduleOneCalculator', revertNumber: false, type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'investmentInterestExpense', title: 'Investment Interest Expense', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'depletion', title: 'Depletion', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'netOperatingLossFromSch1', title: 'Net Operating Loss from Sch 1', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'alternativeTaxNol', title: 'Alternative Tax NOL', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'interestFromSpecPrivateActivityBondsExemptFromRegularTax', title: 'Interest from Spec. Private Activity Bonds Exempt from Regular Tax"', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'qualifiedSmallBusinessStock', title: 'Qualified Small Business Stock', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'exerciseOfIncentiveStockOptions', title: 'Exercise of Incentive Stock Options', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'estatesAndTrustsSchK1Form1041Box12CodeA', title: 'Estates and Trusts Sch K-1 (Form 1041) Box 12 Code A', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'dispositionOfProperty', title: 'Disposition of Property', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'depreciationOfAssetsPlacedInServicePost1986', title: 'Depreciation Disposition of Property of Assets Placed in Service Post-1986', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'passiveActivities', title: 'Passive Activities', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'lossLimitations', title: 'Loss Limitations', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'circulationCosts', title: 'Circulation Costs', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'longTermContracts', title: 'Long-Term Contracts', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'miningCosts', title: 'Mining Costs', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'researchAndExperimentalCosts', title: 'Research and Experimental Costs', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'incomeFromCertainPre1987InstallmentSales', title: 'Income from Certain Pre-1987 Installment Sales', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'intangibleDrillingCostsPreference', title: 'Intangible Drilling Costs Preference', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'otherAdjustments', title: 'Other Adjustments', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'alternativeMinimumTaxableIncome', title: 'Alternative Minimum Taxable Income', type: 'staticText', alwaysVisible: true, isEditable: false },
        ]
      },
      {
        visible: true,
        id: 'partTwo',
        title: 'Part II: Alternative Minimum Tax (AMT)',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'exemption', title: 'Exemption', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'amtiLessExemption', title: 'AMTI Less Exemption', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'partIiiLine40Calculations', title: 'Part III Line 40 Calculations', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'amtForeignTaxCredit', title: 'AMT Foreign Tax Credit', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'tentativeMinimumTax', title: 'Tentative Minimum Tax', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'tax', title: 'Tax', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'misc', title: 'Misc', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'amt', title: 'AMT', type: 'staticText', alwaysVisible: true, isEditable: false },

        ]
      },
      {
        visible: true,
        id: 'partThree',
        title: 'Part III: Tax Computation Using Max Cap Gains Rates',
        lines: [
          {assetId: 'name', title:'', type: 'name', alwaysVisible: true, isEditable: false },
          {assetId: 'amtiLessExemptionFromPartI', title: 'Line 12. AMTI Less Exemption (from Part I)', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'qualDividendsCapGainsWorksheetLine4', title: 'Qual. Dividends & Cap. Gains Worksheet Line 4 or Sch D worksheet Line 13. If Filing Form 2555 see instructions', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'unrecapSec1250Gain', title: 'Line 14. Unrecap. Sec. 1250 Gain', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'smallerOfLines1314OrSchD', title: 'Line 15. Smaller of Lines 13 + 14 or Sch D Tax worksheet Line 13', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'smallerOfLine12Or15', title: 'Line 16. Smaller of Line 12 or 15', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'line16Line12', title: 'Line 17. Line 12 - Line 16', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'ifLine17Is199900OrLess99950O', title: 'Line 18. If line 17 is $199,900 or less ($99,950 or less if married filing separately), multiply line 17 by 26% (0.26). Otherwise, multiply line 17 by 28% (0.28) and subtract $3,998 ($1,999 if married filing separately) from the result', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'amountBasedOnFilingStatus', title: 'Line 19. Amount Based on Filing Status', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'line5OfQualDivCapGainWorksheet', title: 'Line 20. Line 5 of Qual. Div & Cap. Gain Worksheet or Line 14 of Sch D Worksheet or Line 15 of Page 1040', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'line19Line20', title: 'Line 21. Line 19 - Line 20', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'smallerOfLine12Or13', title: 'Line 22. Smaller of Line 12 or 13', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'smallerOfLine21Or22', title: 'Line 23. Smaller of Line 21 or 22', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'line22Line23', title: 'Line 24. Line 22 - Line 23', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'amountBasedOnFilingStatus25', title: 'Line 25. Amount Based on Filing Status', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'amountFromLine21', title: 'Line 26. Amount from Line 21', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'line5OfQualDivCapGainWorksheetlLine21', title: 'Line 27. Line 5 of Qual. Div & Cap. Gain Worksheet or Line 21 of Sch D Worksheet or Line 15 of Page 1040', type: 'money', alwaysVisible: false, isEditable: true },
          {assetId: 'line26Line27', title: 'Line 28. Line 26 + Line 27', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'line28Line25', title: 'Line 29. Line 25 - Line 28', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'smallerOfLine24OrLine29', title: 'Line 30. Smaller of Line 24 or Line 29', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'multiplyLine30By15', title: 'Line 31. Multiply Line 30 by 15%', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'addLines2330', title: 'Line 32. Add Lines 23 & 30', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'line32Line22', title: 'Line 33. Line 22 - Line 32', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'multiplyLine33By20', title: 'Line 34. Multiply Line 33 by 20%', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'addLines173233', title: 'Line 35. Add Lines 17 + 32 + 33', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'subtractLine35FromLine12', title: 'Line 36. Subtract Line 35 from Line 12', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'multiplyLine36By25', title: 'Line 37. Multiply Line 36 by 25%', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'lines18313437', title: 'Line 38. Lines 18 + 31 + 34 + 37', type: 'staticText', alwaysVisible: false, isEditable: false },
          {assetId: 'amountBasedOnFilingStatus39', title: 'Line 39. Amount Based on Filing Status', type: 'staticText', alwaysVisible: true, isEditable: false },
          {assetId: 'smallerOfLine38Or39', title: 'Line 40. Smaller of Line 38 or 39', type: 'staticText', alwaysVisible: false, isEditable: false },
        ]
      },
    ]
  },
]

export const CONST_FILING_STATUS = [
  {value: 'Single'},
  {value: 'Married Filing Jointly'},
  {value: 'Married Filing Separately'},
  {value: 'Head of Household'},
  {value: 'Qualifying Widow(er)'}
]
