import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-snapshot-family-flowchart',
  templateUrl: './snapshot-family-flowchart.component.html',
  styleUrls: ['./snapshot-family-flowchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnapshotFamilyFlowchartComponent implements OnInit {

  @Input() tree: any = [];

  constructor() {

  }

  public tree_ = [
    {
      relation: 'Client',
      name: 'Enrique',
      lastname: 'Alvarez',
      gender: 'he',
      relations: [
        {
          relation: 'Father',
          name: 'Alberto',
          lastname: 'Alvarez',
          gender: 'he',
        },
        {
          relation: 'Mother',
          name: 'Silvia',
          lastname: 'Barajas',
          gender: 'she',
        },
        {
          relation: 'Brother',
          name: 'Fernando',
          lastname: 'Alvarez',
          gender: 'he',
        }
      ]
    },
    {
      relation: 'Spouse',
      name: 'Sugeyli',
      lastname: 'Velasco',
      gender: 'she',
      relations: [
        {
          relation: 'Father in law',
          name: 'Javier',
          lastname: 'Velasco',
          gender: 'he',
          relations: [
            {
              relation: 'Sister in law',
              name: 'Astrid',
              lastname: 'Velasco'
            },
            {
              relation: 'Sister in law',
              name: 'Danitze',
              lastname: 'Velasco'
            },
            {
              relation: 'Sister in law',
              name: 'Javier',
              lastname: 'Velasco',
              gender: 'he',
            }
          ]
        },
        {
          relation: 'Mother in law',
          name: 'Irene',
          lastname: 'Vazquez',
          gender: 'she',
        },
        {
          relation: 'Brother in law',
          name: 'Jair',
          lastname: 'Velasco',
          gender: 'he',
        }
      ]
    },
    {
      relation: 'Ex Spouse',
      name: 'Rick',
      lastname: 'Pequeñito',
      gender: 'he',
    }
  ]
  public example = {
    me: {
      name: 'Me',
      gender: '',
      relations: {
        father: {
          name: 'Alberto Alvarez',
          gender: 'he',
        },
        mother: {
          name: 'Elideth Barajas',
          gender: 'she'
        },
        brother: {
          name: 'Fernando Alvarez',
          gender: 'he',
        },
        friend: {
          name: 'Luisenciado',
          gender: 'he',
          relations: {
            friend: {
              name: 'Rick',
              gender: 'he',
            },
          },
        },
      },
    },
  };


  public tree2_ = [
    {
      relation: 'Father',
      name: 'Enrique',
      lastname: 'Alvarez',
      gender: 'he',
      relations: [
        {
          relation: 'ex Spouse',
          name: 'Jeremy',
          lastname: 'Withmore',
          gender: 'he',
          relations: []
        },
        {
          relation: 'Client',
          name: 'Joe',
          lastname: 'Alvarez',
          gender: 'he',
          relations: [
            {
              relation: 'Child',
              name: 'Sugeyli',
              lastname: 'Alvarez',
              gender: 'she',
              relations: []
            },
            {
              relation: 'Child',
              name: 'Jair',
              lastname: 'Alvarez',
              gender: 'he',
              relations: []
            }
          ]
        },
        {
          relation: 'Spouse',
          name: 'Sara',
          lastname: 'Hill',
          gender: 'she',
          relations: [

          ]
        },
      ]
    },
    {
      relation: 'Mother',
      name: 'Luisa',
      lastname: 'Jil',
      gender: 'she',
      relations: []
    },

    /* {
      relation: 'Client',
      name: 'Enrique',
      lastname: 'Alvarez',
      gender: 'he',
      relations: [
        {
          relation: 'Father',
          name: 'Alberto',
          lastname: 'Alvarez',
          gender: 'he',
        },
        {
          relation: 'Mother',
          name: 'Silvia',
          lastname: 'Barajas',
          gender: 'she',
        },
        {
          relation: 'Brother',
          name: 'Fernando',
          lastname: 'Alvarez',
          gender: 'he',
        }
      ]
    },
    {
      relation: 'Spouse',
      name: 'Sugeyli',
      lastname: 'Velasco',
      gender: 'she',
      relations: [
        {
          relation: 'Father in law',
          name: 'Javier',
          lastname: 'Velasco',
          gender: 'he',
          relations: [
            {
              relation: 'Sister in law',
              name: 'Astrid',
              lastname: 'Velasco'
            },
            {
              relation: 'Sister in law',
              name: 'Danitze',
              lastname: 'Velasco'
            },
            {
              relation: 'Sister in law',
              name: 'Javier',
              lastname: 'Velasco',
              gender: 'he',
            }
          ]
        },
        {
          relation: 'Mother in law',
          name: 'Irene',
          lastname: 'Vazquez',
          gender: 'she',
        },
        {
          relation: 'Brother in law',
          name: 'Jair',
          lastname: 'Velasco',
          gender: 'he',
        }
      ]
    },
    {
      relation: 'Ex Spouse',
      name: 'Rick',
      lastname: 'Pequeñito',
      gender: 'he',
    }*/
  ]

  ngOnInit() {
    this.tree = this.tree2_;
  }

}
