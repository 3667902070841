import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-detail-dialog',
  templateUrl: './payment-detail-dialog.component.html',
  styleUrls: ['./payment-detail-dialog.component.scss']
})
export class PaymentDetailDialogComponent implements OnInit {
  public invoice: any;
  public items: any;
  public method: any;

  constructor(
    public dialogRef: MatDialogRef<PaymentDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.invoice = data.data;
    this.method = data.download;
  }

  ngOnInit() {
    this.buildItems();
  }

  buildItems() {
    const aux = this.invoice.items.map(({ description, quantity, amount }) => {
      if (this.invoice.title.includes('Estate')) {
        if (typeof description === 'string' && !description.includes('{')) {
          const [advisor, prevClient] = description.split(' - ');
          const [client, prevDate] = prevClient.replace(')', '').split(' (');
          const [date, ...otherDesc] = prevDate.split(' ');
          const desc = otherDesc.join(' ');
          return {
            description: `${client} ${desc}`,
            advisor,
            date,
            price: amount,
            quantity,
          }
        } else if (typeof description === 'string' && description.includes('{')) {
          const { product, advisor, date } = JSON.parse(description);
          return {
            description: product,
            advisor,
            date,
            price: amount,
            quantity: 1,
          }
        } else {
          const { product, advisor, date } = description;
          return {
            description: product,
            advisor,
            date,
            price: amount,
            quantity: 1,
          }
        }
      } else {
        const auxdate = new Date(this.invoice.creationDate);
        return {
          description,
          date: auxdate,
          price: amount,
          quantity,
        }
      }
    });
    this.items = aux;
  }


  close() {
    this.dialogRef.close();
  }

}
