import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-bar-chart-horizontal',
  templateUrl: './bar-chart-horizontal.component.html',
  styleUrls: ['./bar-chart-horizontal.component.scss']
})
export class BarChartHorizontalComponent implements OnInit, AfterViewInit {

  @Input() data: GraphDataInterface | any;
  @Input() pdfColor: any;
  @Input() pdfColorQBI: any;

  @ViewChild('canvasbarchart',{static:false}) canvases: HTMLCanvasElement;
  public fullCanvas;
  public labelColor;
  public label;

  public cdRef: ChangeDetectorRef;

  /**
   * internalData.
   * Is a copy for the inputed data, for each change on data a copy is made.
   * Serves to not edit the comon source of the data, in case other components require acces to the orginal data.
   */
  public internalData: GraphDataInterface | any;

  public someColumnHasLabelOuter: boolean = true;


  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {   }


  ngAfterViewInit(){

    this.fullCanvas = <HTMLElement> this.canvases['nativeElement'] as HTMLElement;

    if(this.pdfColor && this.fullCanvas){

      this.labelColor = this.fullCanvas.getElementsByClassName('column-label') as HTMLCollectionOf<HTMLElement>;
      this.label = this.fullCanvas.getElementsByClassName('label') as HTMLCollectionOf<HTMLElement>;

      setTimeout(()=>{
        for(var i = 0; i<this.labelColor.length;i++){
          this.labelColor.item(i).style.setProperty("color", "white", "important");
          this.labelColor.item(i).style.setProperty("font-size", "1.25rem", "important");
        }

        for(var i = 0; i<this.label.length;i++){
          this.label.item(i).style.lineHeight = '1';
        }
      }, 1000)

    }
  }


    // All changes to input data will be processed here.
    ngOnChanges(changes: SimpleChanges) {

      if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }


      this.internalData = JSON.parse(JSON.stringify(changes.data.currentValue))
      //this.internalData = JSON.parse(JSON.stringify(changes.data && changes.data.currentValue || {}))

      if(this.pdfColor && this.fullCanvas){

        this.labelColor = this.fullCanvas.getElementsByClassName('column-label') as HTMLCollectionOf<HTMLElement>;
        this.label = this.fullCanvas.getElementsByClassName('label') as HTMLCollectionOf<HTMLElement>;

        setTimeout(()=>{
          for(var i = 0; i<this.labelColor.length;i++){
            this.labelColor.item(i).style.setProperty("color", "white", "important");
            this.labelColor.item(i).style.setProperty("font-size", "1.25rem", "important");
          }

          for(var i = 0; i<this.label.length;i++){
            this.label.item(i).style.lineHeight = '1';
          }
        }, 2000)

        if(typeof this.internalData.columns !== 'undefined'){
          this.internalData.columns.map(column =>{
            column.sections.map(section=>{
              switch(section.color){
                case '#0259B6': section.color = '#12294B'; break;
                case '#026DDE': section.color = '#3F5DAA'; break;
                case '#218BFD': section.color = '#6580BF'; break;
                case '#5DABFD': section.color = '#8C9BBF'; break;
                case '#9ACAFE': section.color = '#C3C5D6'; break;
                case '#D7EAFF': section.color = '#0181bf'; break;
              }
            })
          })
        }

        if(typeof this.internalData.labels !== 'undefined'){
          this.internalData.labels.map(label =>{
            switch(label.color){
              case '#0259B6': label.color = '#12294B'; break;
              case '#026DDE': label.color = '#3F5DAA'; break;
              case '#218BFD': label.color = '#6580BF'; break;
              case '#5DABFD': label.color = '#8C9BBF'; break;
              case '#9ACAFE': label.color = '#C3C5D6'; break;
              case '#D7EAFF': label.color = '#0181bf'; break;
            }
          })
        }

      }


      // Detect if some column has an outer label. For style propouses
      if(Boolean(this.internalData.columns)){
        this.internalData.columns.map(column => {
          this.someColumnHasLabelOuter = this.someColumnHasLabelOuter && Boolean(column.columnLabelOuter);
        });
      }

    }


}

interface GraphDataInterface {

  componentType?: string,
  id?: string,
  valueBottom: number,
  valueTop: number,
  lineIndicator?: {value: number, legend: string} | null,
  rullers: string[],
  sideNote: string,
  labels: { legend: string, color: string }[],
  columns:
    {
      legendBottom: string,
      legendTop?: string | null ,
      sections: { percentage?: number | null, legend: string, color: string, annotation?: string | null }[],
      columnLabel?: string,
      columnLabelOuter?: string
    }[]

}
