import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';

import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { FileUploaderOptions } from '../file-uploader-component/file-uploader-component.component';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_UNSURE,
  CONST_DISABILITY_COMPANIES,
  CONST_DISABILITYPOLICYVENDORS,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { uploadersValidator, validateAndSubmit, Validations } from '../Questionnaire.validations';
import { disability_questions } from './disability.model';

@Component({
  selector: "app-q-disability",
  templateUrl: "./q-disability.component.html",
  styleUrls: ["./q-disability.component.scss"]
})
export class QDisabilityComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: disability_questions;
  public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public typeOfBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;
  public clientIsRetired: string;
  public isSaveForLater: boolean = false;

  public haveFilePDF: string;
  public hasBaseDropZoneOver: boolean = false;
  public whoAnswered: string;

  public answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_DISABILITY_COMPANIES, CONST_DISABILITYPOLICYVENDORS };
  public validations = Validations;
  public fileUploaderArray: FileUploadersArrayHelper;
  public disabilityPolicyUploaderOptions: FileUploaderOptions;
  public disabilityGroupPolicyUploaderOptions: FileUploaderOptions;

  public extractionResults: any[] = [];

  public isAdvisor: boolean = false;
  public assetName: string = '';

  public ntabs: number;
  public progress: number;
  public progressnumber: number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;


  public bulletColor;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  disabilityPremium = ["Pre tax", "After tax"];

  income = [
    { value: 'Increase', display: 'Increase' },
    { value: 'Decrease', display: 'Decrease' },
    { value: 'notsure', display: 'Not sure' },
  ];


  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();
    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.typeOfBusiness = this.clientData.typeOfBusiness;
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientIsRetired = this.clientData.isRetired;

    //Initialize the questionnaire model
    this.model = new disability_questions();

    //Load session storage data from questionnare
    let loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('disability');
    let index = loadedStoragedQuestionnaireData.index != null ? loadedStoragedQuestionnaireData.index : 1;
    this.assetName = (loadedStoragedQuestionnaireData.name != null) ? loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = this.assetName;


    //Is the first time here? Show modal
    /*let isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    if (isFirstTimeHere === 'disability') {
      this.dialog.open(DialogFirstTime, {
        panelClass: 'modal-dialog-questionnaire',
        height: '250px',
        width: '500px',
        data: {hasUploader: false}
      });
    }*/

    //Load extraction results for file uploaders components
    this.qController.loadExtractionsResults(this.clientData.clientId, 'disability', String(index)).then(result => {
      this.extractionResults = result;
    });

    //By default the client has the input policy by file
    if (!this.isAdvisor) this.model.clientIDWantsToInputPolicyDataDisability = 'No';

    //Initialize File Uploader Component Options
    this.disabilityPolicyUploaderOptions = new FileUploaderOptions(
      {
        fileID: 'clientIDDisabilityPolicy',
        fileVal: this.model.clientIDDisabilityPolicy,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'disability'),
        documentReadableName: 'individual policy',
        actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'disability', 1, 'clientIDDisabilityPolicy', ()=>{this.model.clientIDWantsToInputPolicyDataDisability = 'Yes'} )} }
      }
    );

    this.disabilityGroupPolicyUploaderOptions = new FileUploaderOptions(
      /*  'clientIDDisabilityPolicy2',
       this.model.clientIDDisabilityPolicy2,
       this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'disability'),
       'group policy' */
      {
        fileID: 'clientIDDisabilityPolicy2',
        fileVal: this.model.clientIDDisabilityPolicy2,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'disability'),
        documentReadableName: 'group policy',
        actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'disability', 1, 'clientIDDisabilityPolicy2', ()=>{this.model.clientIDWantsToInputPolicyDataDisability = 'Yes'} )} }
      }
    );

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansDisability;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, the load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'disability', this.model, index).then(response => {
        
        // Wait for the data before starting the autosave function
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }


    //For new questionnaires and clients tha don't have a business, set business related questions to No.
    if (!JSON.parse(sessionStorage.clientHasAnsweredQuestionnaires).ansDisability && JSON.parse(sessionStorage.currentClient).hasBusiness == "No") {
      this.model.clientIDSmallBusiness = 'No';
      this.model.clientIDPlansReturnToWork = 'No';
      this.model.clientIDClientHasBusinessAndPartners = 'No';
      this.model.clientIDDailyBusinessExpenses = 0;
      this.model.clientIDIsKeyEmployeeofBusiness = 'No';
      this.model.clientIDExpensesMonthlyBasis = 'No';
      this.model.clientIDAblePayMonthlyDisabilityEvent = 'No';
    }


    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };

  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {

    this.submit(true);
  }

  showUploader(prop: string) {
    this.model[prop] = '';
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {


    if (this.model.clientIDInForceCoverage === 'No') this.model.clientIDWantsToInputPolicyDataDisability = 'Yes';
    if (this.model.clientIDPolicyThroughAssociation === 'Yes') this.model.clientIDBelongsToProfessionalAssociation = 'Yes';

    if (this.model.clientIDNumberOfPolicies === 'One') {
      this.model.clientIDHasPolicyOne = 'Yes';
      this.model.clientIDHasSecondPolicy = 'No';
    }

    if (this.model.clientIDNumberOfPolicies === 'Two') {
      this.model.clientIDHasPolicyOne = 'Yes';
      this.model.clientIDHasSecondPolicy = 'Yes';
    }

    if (this.clientHasBusiness === 'Yes') {
      this.model.clientIDClientHasBusinessAndPartners = this.typeOfBusiness;
    }

    validateAndSubmit(
      [
        uploadersValidator(this, [this.disabilityPolicyUploaderOptions])
      ],
      () => { this.qController.submitForm(this, this.model, this.clientData.clientId, 'disability', saveForLater, null, silentSave) },
      saveForLater
    )
  }

  onAnyUploaderReset($event: string) {
    if ($event == 'clientIDDisabilityPolicy') this.model.clientIDDisabilityInsuranceCompany = '';
    //Reset the policy selector to force the selection of one.
    this.model[$event] = '';
    this.extractionResults[$event] = '';
  }

  countTabs(tabs: number) {
    this.progress = 100 / tabs;
  }

  nextTab(index: number) {
    this.model.progressTab = index + 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  previousTab(index: number) {
    this.model.progressTab = index - 1;
    this.targetEl.nativeElement.scrollIntoView();
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
    if (this.model.progressCompleted == true) {
      this.model.progressBar = 100;
    } else {
      this.model.progressBar = Math.round(tabChangeEvent.index * this.progress);
    }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab = tabChangeEvent.index;
  }

  ngOnDestroy() {
    this.qController.deleteStoragedQuestionnareDatByArea('disability');
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    this.http.get(`${this.pdfUrl}${'disability'}/?options=${this.model.clientIDInForceCoverage}`, options)
      .subscribe(blob => {
        saveAs(blob, 'disability-questionnaire.pdf');
      });
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

}
