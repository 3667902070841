import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-hub-navbar',
  templateUrl: './hub-navbar.component.html',
  styleUrls: ['./hub-navbar.component.scss']
})
export class HubNavbarComponent implements OnInit {

  public clientHasAnswered: any;
  public isAdvisor: boolean = true;
  public toggleSearchBar = false;
  public hasAccessToRecs = false;
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isAdvisor = this.authService.isAdvisor();
  }

}
