import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-estate',
  templateUrl: './table-estate.component.html',
  styleUrls: ['./table-estate.component.scss']
})
export class TableEstateComponent implements OnInit {

  @Input() data: any;
  @Input() type: string;
  @Input() isPrintable: boolean = false;


  public headers: any;


  constructor() { }

  ngOnInit(): void {
    this.data = JSON.parse(JSON.stringify(this.data));
    console.log('dataTableEstate', this.type, this.data);
    let total = this.data.data.find(item => item.label === 'Total');
    if(this.type === 'horizontal' && !total){
      let totalRow = {
        amount: 0,
        percentage: 0,
        label: 'Total',
        strokeColor: 'transparent',
        color: 'transparent'
      };
      this.data.data.map((item: any) => {
        totalRow['percentage']+=item.percentage;
        totalRow['amount']+=item.amount;
      });
      this.data.data.push(totalRow);
    }
  }

  sortDataByHeaders(){}

}
