import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarUtil } from 'app/shared/helpers/snackbar.util';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { environment } from 'environments/environment';


declare var Stripe: any;

@Component({
  selector: 'app-add-method',
  templateUrl: './add-method.component.html',
  styleUrls: ['./add-method.component.scss']
})
export class AddMethodComponent implements OnInit {

  public stripe: any;
  public stripeElements: any;
  public cardCvcExists = false;
  public cardExists = false;
  public cardExpiryExists = false;
  public isChangingPaymentMethod = false;

  @Input() subscriptionInformation: any;
  @Output() onEnd: EventEmitter<any> = new EventEmitter(); 

  @ViewChild('cardNumber', { static: true }) cardNumber: ElementRef;
  @ViewChild('cardExpiry', { static: true }) cardExpiry: ElementRef;
  @ViewChild('cardCvc', { static: true }) cardCvc: ElementRef;
  @ViewChild('cardCountry', { static: true }) cardCountry: ElementRef;
  @ViewChild('cardName', { static: true }) cardName: ElementRef;
  @ViewChild('cardZip', { static: true }) cardZip: ElementRef;

  constructor(
    private advisorService: AdvisorService,
    public snackbarUtil: SnackbarUtil,
    public snackBarError: MatSnackBar,
    public snackBarSuccess: MatSnackBar,
  ) { }

  ngOnInit(): void {
    console.log(this.subscriptionInformation);
    this.stripe = Stripe(environment.stripeKey);
    this.stripeElements = this.stripe.elements();

    const classes = {
      base: 'stripe-input',
    };

    const style = {
      base: {
        color: 'white',
      }
    };

    const cardInput = this.stripeElements.create('cardNumber', { style, classes, showIcon: true, });
    cardInput.mount(this.cardNumber.nativeElement);

    const cardExpiry = this.stripeElements.create('cardExpiry', { style, classes });
    cardExpiry.mount(this.cardExpiry.nativeElement);

    const cardCvc = this.stripeElements.create('cardCvc', { style, classes });
    cardCvc.mount(this.cardCvc.nativeElement);

    cardInput.on("change", event => {
      
      if (event.complete) {
        // enable payment button
        this.cardExists = true;
      } else if (event.error) {
        // show event.error.message to customer
        this.cardExists = false;
        
      } else {
        this.cardExists = false;
      }
    });
    cardExpiry.on("change", event => {
      
      if (event.complete) {
        // enable payment button
        this.cardExpiryExists = true;
      } else if (event.error) {
        // show event.error.message to customer
        
        this.cardExpiryExists = false;
      } else {
        this.cardExpiryExists = false;
      }
    });
    cardCvc.on("change", event => {
      
      if (event.complete) {
        // enable payment button
        this.cardCvcExists = true;
      } else if (!event.complete) {
        // show event.error.message to customer
        
        this.cardCvcExists = false;
      } else {
        this.cardCvcExists = false;
      }
    });
  }

  async changePaymentMethod() {
    this.isChangingPaymentMethod = true;
    
  
    const cardData = {
      name: this.cardName.nativeElement.value,
      address_country: this.cardCountry.nativeElement.value,
      address_zip: this.cardZip.nativeElement.value,
    };

    let tokenResult;
    try {
      tokenResult = await this.stripe.createToken(
        this.stripeElements.getElement('cardNumber'),
        cardData
      );
    }
    catch (e) {
      console.log(e);
      return this.snackBarError.open('The information is invalid.', 'Dismiss', {
        duration: 9000,
        verticalPosition: 'top',
        panelClass: 'error-snackbar'
      });
    }

    const cardToken = tokenResult.token.id;

    let stripeResponse;
    try {
       stripeResponse = await this.advisorService.addPaymentMethod(cardToken);
    }
    catch (e) {

      this.isChangingPaymentMethod = false;
      return this.snackBarError.open('ERROR: ' + e.error.message, 'Dismiss', {
        duration: 9000,
        verticalPosition: 'bottom',
        panelClass: 'error-snackbar'
      });
    }

    this.snackBarSuccess.open('Your payment method has been changed successfully.', 'Dismiss', {
      duration: 9000,
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });

    this.isChangingPaymentMethod = false;
    this.onEnd.emit({
      view: 'payment',
      getMethods: true,
    })
 
    this.stripeElements.getElement('cardNumber').clear();
    this.stripeElements.getElement('cardCvc').clear();
    this.stripeElements.getElement('cardExpiry').clear();
    
    this.cardName.nativeElement.value = '';
    this.cardCountry.nativeElement.value = '';
    this.cardZip.nativeElement.value = '';
    this.cardCvcExists = false;
    this.cardExpiryExists = false;
    this.cardExists = false;
  }

  changeView(view: string) {
    this.onEnd.emit({
      view: view,
      getMethods: false,
    })
  }
}
