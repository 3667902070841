import { Injectable, Inject } from "@angular/core";
import { WINDOW } from "./window.provider";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AdvisorService } from "./../services/advisor.service";

@Injectable()
export class APInterceptor implements HttpInterceptor {
  constructor(
    @Inject(WINDOW) private window: Window,
    private advisorService: AdvisorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(
      request.clone({
        url: this.advisorService.dynamicIPAddress(this.window, request.url)
      })
    );
  }
}
