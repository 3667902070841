import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { DialogConfirm } from 'app/shared/components/dialog-confirm/dialog-confirm.component';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { CustomValidators } from 'ng2-validation';

import { environment } from '../../../../environments/environment';
import { fpAnimations } from '../../../shared/animations/fp-animations';
import { Area, NO_GENERAL_QUEST_AREAS, PROSPECT_AREAS } from '../../../shared/helpers/areas.catalog';
import { AddClientDialogData } from '../clients.interfaces';
import { integrationStatusByRoute } from './utils';
import { ClientService } from 'app/shared/services/client/client.service';
import { ClientDataService } from 'app/shared/services/client-data-service';

//Angular
//3rd Party
//Project
@Component({
  selector: 'add-new-client-dialog',
  templateUrl: './add-new-client-dialog.html',
  styleUrls: ['./add-new-client-dialog.component.scss'],
  animations: fpAnimations,
})

export class AddNewClientDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  @Input() integrations: any[] = [];

  addClientForm: FormGroup;
  addClientFormTax: FormGroup;
  private createNewClient = environment.apiCreateClient;
  public advisorData: any;
  public advisorPreferences: any;
  public  advisorWantToSendAccessToClient: boolean;
  public areasInDisplayFormat: any[] = [];
  public isChecked: boolean = false;
  public hasClientSide: boolean;
  public temporalPassword: string;
  public mailExistsAlready: boolean;
  private validateClientEmail = environment.apiValidateEmail;
  public prospectAreaNames: string[] = PROSPECT_AREAS.map(area => { return area.id });
  public areasToDisplay: Area[] = [];
  public isAtLeastOneAreaSelected: boolean = false;
  public allowGeneralQuestForTaxPlanning: boolean = true;


  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<AddNewClientDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: AddClientDialogData,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private router: Router,
    public currentClientService: ClientService,
    //@Inject() isProspect: boolean = false,
    public clientDataService: ClientDataService
  ) {
    super(dialogRef, authService);
   }

  ngOnInit() {

    this.areasToDisplay = this.data.isProspect ? PROSPECT_AREAS : Area.getActiveAreas();




    this.temporalPassword = AddNewClientDialog.generateTempPassword();

    const email = new FormControl('', [Validators.required, Validators.email]);
    const confirmEmail = new FormControl('', [Validators.required, CustomValidators.equalTo(email)]);

    let autoGeneratedFormControlsByArea = this.areasToDisplay.map(area => {
      return { [area.id]: new FormControl(false) };
    });


    this.addClientForm = new FormGroup(
      // Concatenate objects into one with Object.assign and pass it to FormGroup instanciation.
      Object.assign({ //First object
        clientIDFirstName: new FormControl('', Validators.required),
        clientIDLastName: new FormControl('', Validators.required),
        clientIDMiddleName: new FormControl(''),
        email: email,
        confirmEmail: confirmEmail,
        id: new FormControl(''),
        isProspect: new FormControl(false),
        checkboxmail: new FormControl(false),
        checkboxallareas: new FormControl(false),
        shareWithCompany: new FormControl(false)
      }, // Multiple objects, one for each area in AREAS
        ...autoGeneratedFormControlsByArea
      )
    );

    this.addClientFormTax = new FormGroup(
      Object.assign({
        clientTaxFirstName: new FormControl('', Validators.required),
        clientTaxMiddleName: new FormControl(''),
        clientTaxLastName: new FormControl('', Validators.required),
        sharedWithCompany: new FormControl(false, Validators.required),
        isProspect: new FormControl(false),
        fulltax: new FormControl(true),
      })
    );

    this.hasClientSide = JSON.parse(sessionStorage.getItem('hasClientSide'));

    //Need this to know what areas to hide from selection
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorData = data;
          this.advisorPreferences = this.advisorData.settings;
          this.advisorWantToSendAccessToClient = this.advisorPreferences.sendAccessToClient;

          if(!this.advisorPreferences.hasGeneralQuest){
            this.areasToDisplay = NO_GENERAL_QUEST_AREAS;
            this.addClientForm.controls['fulltax'].setValue(true);
            this.checkIfAreaSelected();
            this.allowGeneralQuestForTaxPlanning = false;
            email.clearValidators();
            confirmEmail.clearValidators();
          }
          if (this.advisorData.settings.generateRandomEmail){
            email.clearValidators();
            confirmEmail.clearValidators();
          }
          },
        error => {
          console.log(error);
        });





    // Load the areas and transform into a list format
    this.areasInDisplayFormat = Area.getAreasInDisplayArrayFormatFixedColumn(this.areasToDisplay, 2, false);
  }

  goToIntegration(integration){
    this.router.navigate(['/settings/external-providers-interactions', integration.name, integrationStatusByRoute[integration.status]]);
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          console.log(data)
        },
        error => {
          console.log(error);
        });
  }

  selectAllAreas(event) {

    this.isChecked = event.checked;
    //[disabled]="advisorData['hide'+(area.id).charAt(0).toUpperCase()+(area.id).slice(1)]"
    this.areasToDisplay.forEach(area => {
      if(this.advisorData['hide'+(area.id).charAt(0).toUpperCase()+(area.id).slice(1)] !== true){
        this.addClientForm.controls[area.id].setValue(this.isChecked);
      }
    });
    if(this.isChecked === true){
      this.isAtLeastOneAreaSelected = true;
    }else{
      this.isAtLeastOneAreaSelected = false;
    }

  }

  hasIntegrations(){
    let hasIntegrations = false;
    this.integrations.forEach((item) => {
      return hasIntegrations = hasIntegrations || item.active
    })
    return hasIntegrations
  }

  hasExpiredIntegrations(){
    let hasIntegrations = false;
    this.integrations.forEach((item) => {
      return hasIntegrations = hasIntegrations || (item.status == 'SessionExpired')
    })
    return hasIntegrations
  }


  checkIfAreaSelected(){
    this.isAtLeastOneAreaSelected = false;
    this.areasToDisplay.forEach(area => {
      if(this.addClientForm.controls[area.id].value == true){
        this.isAtLeastOneAreaSelected = true;
      };
    });
  }

  printConditionals(){
    //addClientForm.invalid || !isAtLeastOneAreaSelected || (advisorPreferences?.hasGeneralQuest && mailExistsAlready)

  }



  save(){
    if (true) {
    // if (!this.advisorPreferences.hasGeneralQuest) {
      this.dialogRef.close(this.addClientForm.value);
      const addClientData = this.addClientForm.value;

      /*
        clientIDFirstName: addClientDataTax.clientTaxFirstName,
        clientIDMiddleName: addClientDataTax.clientTaxMiddleName,
        clientIDLastName: addClientDataTax.clientTaxLastName,
        shareWithCompany: addClientDataTax.shareWithCompany,
        isProspect: false,
        password: this.temporalPassword,
        hasGeneralQuest: this.allowGeneralQuestForTaxPlanning,
      */

      let newClientData = {
        clientIDFirstName: addClientData.clientIDFirstName,
        clientIDLastName: addClientData.clientIDLastName,
        isProspect: false,
        password: this.temporalPassword,
        shareWithCompany: addClientData.shareWithCompany,
        hasGeneralQuest: this.advisorPreferences.hasGeneralQuest,
      }
      if(!this.advisorPreferences.generateRandomEmail){
        newClientData['email'] = addClientData.email;
        newClientData['checkboxmail'] = addClientData.checkboxmail;
        newClientData['id'] = addClientData.id;
      }else{
        newClientData['clientIDMiddleName'] = addClientData.clientIDMiddleName;
      }
      // Autocomplete values of areas.
      this.areasToDisplay.forEach(area => {
        newClientData[area.id] =  addClientData[area.id];
      });



      AddNewClientDialog.createNewClient(this.http, newClientData, this.data.isProspect).then(
        response => {
          if(this.data.isProspect) {
            const dialogRef = this.dialog.open(DialogConfirm, {
              data: "Please share this url with your prospect: \n\n" + location.origin + '/prospect/form/?cli=' + response.idHash
            });
          } else {
            this.advisorService.getClientListV2(0, 10).toPromise().then(async (response) => {
              await this.currentClientService.initClient(response.clients[0].id)
              await this.clientDataService.setFullCurrentClient(response.clients[0]);
              if(this.advisorPreferences.hasGeneralQuest){
                this.router.navigate(['/questionnaire-general']);
              }
            })
            .catch(e => console.log(e));
          }
        },
        error => {
          this.snackBarError.open("Something went wrong! " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
    }
    /*else{
      this.dialogRef.close(this.addClientFormTax.value);
      const addClientDataTax = this.addClientFormTax.value;

      let newClientData = {
        clientIDFirstName: addClientDataTax.clientTaxFirstName,
        clientIDMiddleName: addClientDataTax.clientTaxMiddleName,
        clientIDLastName: addClientDataTax.clientTaxLastName,
        shareWithCompany: addClientDataTax.shareWithCompany,
        isProspect: false,
        password: this.temporalPassword,
        hasGeneralQuest: this.allowGeneralQuestForTaxPlanning,
      }
      // Autocomplete values of areas.
      this.areasToDisplay.forEach(area => {
        newClientData[area.id] =  addClientDataTax[area.id];
      });



      AddNewClientDialog.createNewClient(this.http, newClientData, this.data.isProspect).then(
        response => {
          if(this.data.isProspect) {
            const dialogRef = this.dialog.open(DialogConfirm, {
              data: "Please share this url with your prospect: \n\n" + location.origin + '/prospect/form/?cli=' + response.idHash
            });
          }
        },
        error => {
          this.snackBarError.open("Something went wrong! " + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
    }*/
  }

  static generateTempPassword(): string {
    return Math.random()
      .toString(36)
      .substr(2, 11);
  }

  /**
   * Register a new client in the backend. Returns client : Promise <any>
   * @name createNewClient
   * @property httpClientInstance: HttpClient. Instance of an http client.
   * @property clientData: Object. An object that contains the client data that will be passed as a request.
   * @property setCreateClientUrlTo. Overrides the default url and it changes for the specifieded one.
   */

  static async createNewClient(httpClientInstance: HttpClient, clientData: any, isProspect: boolean, setCreateClientUrlTo: string = null): Promise <any>{



    // Build the body of the post query with clientData.
    let body = new URLSearchParams();
    for (let prop in clientData) {
      body.set(prop, clientData[prop]);
    }

    // Register the new client to the corresponding endpoint
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    let newClientEndpointUrl: string = setCreateClientUrlTo ? setCreateClientUrlTo : (isProspect ? environment.prospectAdvisor : environment.apiCreateClient);
    let newClientResponse: any = await httpClientInstance.post<any>(newClientEndpointUrl, body.toString(), options).toPromise();

    if (isProspect){



    }

    return newClientResponse;

  }

  onSearchInputChange(e) {
    let searchValue = e.srcElement.value;
    this.doEmailValidation(searchValue);
  }

  doEmailValidation(searchValue: string) {


    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = new URLSearchParams();
    body.set('email', searchValue);

    this.http.post<any>(this.validateClientEmail, body.toString(), options).subscribe(
      success => {
        this.mailExistsAlready = false;
      },
      error => {
        this.mailExistsAlready = true;
        console.log(error, 'ERROR. didEmailExist', this.mailExistsAlready);
      }
    )

  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
