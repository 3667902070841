import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { MatDialogRef } from '@angular/material/dialog';


export abstract class CloseDialogAtLogOut{

  constructor(
    private _dialogRef: MatDialogRef<any>,
    private _authService: AuthenticationService
  ){
    this._authService.sessionClosed.subscribe( response =>{
      console.log('Logout detected. Closing modal');
      this._dialogRef.close();
    })
  }


}
