import { Questionnaire } from '../Questionnaire.model';

export class Student extends Questionnaire {

    //Manual input
    public clientIDWantsToInputPolicyDataStudentDebt: string = 'Yes';
    public clientIDcurrentBalance: number = 0;
    public clientIDdisbursementDateYear: number = 0;
    public clientIDdisbursementDateMonth: string = '';
    public clientIDbalanceOctober2007: number = 0;
    public clientIDbalanceJuly2014: number = 0;
    public clientIDLoanStatus: string = '';
    public clientIDMonthsQualifyEmployment: number = 0;
    public clientIDHasBeen501Employee: string = "";
    public clientIDStartDateMonthsQualifyEmployment: Date = new Date(2000, 1, 1); // Jan 1, 1950
    public clientIDEndDateMonthsQualifyEmployment: Date = new Date(2000, 1, 1); // Jan 1, 1950
    public clientIDMonthsNotRepayment: number = 0;
    public clientIDPSLFCummulativeMatchMonths: number = 0;
    public clientIDRepaymentPlanIDR: string = '';
    // Main quest
    public clientIDNotClientLoan: string = '';
    public clientIDDiplomaWhenEnrolled: string = '';
    public clientIDClientDisabled: string = '';
    public clientIDSchoolClosed: string = '';
    public clientIDClientVeteran: string = '';
    public clientIDBorrowerPHF: string = '';
    public clientIDmonthlyStandardRepayment: number = 0;
    public clientIDmonthlyOldIBR: number = 0;
    public clientIDmonthlyICR: number = 0;
    public clietIDLoanType: string = '';
    //
    public clientIDEmployment: string = '';
    //Are you in the following circumstances?
    //public clientIDCircumnstance1: string = '';
    public clientIDCircumnstance2: string = '';
    public clientIDCircumnstance3: string = '';
    public clientIDCircumnstance4: string = '';
    //Documents
    public clientIDStudentPolicy: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    // Multiple assets
    public clientIDAssetName: string = '';

    //DUMB request
    public wantsToShowStartDateOrNot: string = '';
    public wantsToShowEndDateOrNot: string = '';

    constructor() {
        super();
        this._classInitialState = Object.assign({}, this);
    }

    getIgnoredAttributesForSave() {
        return [
            'clientIDStudentPolicy'
        ];
    }

    getAttributesToClearCommas() {
        return [
            'clientIDcurrentBalance',
            'clientIDbalanceOctober2007',
            'clientIDbalanceJuly2014',
            'clientIDmonthlyStandardRepayment',
            'clientIDmonthlyOldIBR',
            'clientIDmonthlyICR',
        ];
    }

    beforeToSaveFormat() {
        this.clientIDMonthsQualifyEmployment = this.calculateMonthsByStudentRule();
    }

    getQName(): string {
        return 'student';
    }

    calculateMonthsByStudentRule(): number{
        
        let startDate = new Date("2007, 10, 1");
        let monthsElapsed: number = 0;

        if(this.wantsToShowStartDateOrNot === 'N/A'){
          this.clientIDStartDateMonthsQualifyEmployment = new Date();
          this.clientIDEndDateMonthsQualifyEmployment = new Date();
        }else if(this.wantsToShowEndDateOrNot === 'N/A'){
          this.clientIDEndDateMonthsQualifyEmployment = new Date();
        }

        startDate = this.clientIDStartDateMonthsQualifyEmployment > startDate ? this.clientIDStartDateMonthsQualifyEmployment : startDate;
        monthsElapsed = this.calculateMonthsPassedGivenDate(startDate, this.clientIDEndDateMonthsQualifyEmployment);
        this.clientIDMonthsQualifyEmployment = monthsElapsed;
        return monthsElapsed;
    }

    calculateMonthsPassedGivenDate(startDate: Date, endDate: Date = new Date()): number {
        let months: number = 0;
        let d2 = endDate;
        months = (d2.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth() + 1;
        months += d2.getMonth();
        
        return (months <= 0 || isNaN(months) ) ? 0 : months;
    }
}
