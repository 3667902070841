import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-first-estate-request-dialog',
  templateUrl: './first-estate-request-dialog.component.html',
  styleUrls: ['./first-estate-request-dialog.component.scss']
})
export class FirstEstateRequestDialogComponent implements OnInit {

  public companyInfo: any;
  public priceTier: string = '$49';

  constructor(
    public dialogRef: MatDialogRef<FirstEstateRequestDialogComponent>,
    public advisorService: AdvisorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  ngOnInit() {

    this.companyInfo = JSON.parse(localStorage.getItem('companyInfo'));

    let newPricingDate: Date = new Date('2023-05-08T00:00:01.706Z');
    let companyCreationDate: Date = new Date('2022-01-01T00:00:01.706Z'); //new Date(this.companyInfo.creationDate);

    this.priceTier = (this.companyInfo.company === 'Robert W. Baird & Co.') ? '$69' : (companyCreationDate > newPricingDate) ? '$99' : '$49';
    if (this.data?.price) {
      this.priceTier = this.data?.price;
    }

  }

  async accept() {
    try {
      const ans = await this.advisorService.acceptAgreement(this.data?.typeOfAgreement);
      this.dialogRef.close(true);
    } catch(e) {
      console.log(e);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

}
