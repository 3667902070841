//Angular
import { Injectable, HostListener } from '@angular/core';
import { Router } from '@angular/router';

//RX
import { BehaviorSubject } from 'rxjs';
// import { Observable } from "rxjs";
// import { of } from "rxjs";

//Project
import { getQueryParam } from '../helpers/url.helper';

interface ILayoutConf {
  navigationPos?: string;   // side, top
  sidebarStyle?: string;    // full, compact, closed
  dir?: string;             // ltr, rtl
  layoutInTransition?: boolean;
  isMobile?: boolean,
  notSupportedRes?: boolean,
  useBreadcrumb?: boolean,
  breadcrumb?: string,      // simple, title
  topbarFixed?: boolean
}
interface ILayoutChangeOptions {
  duration?: number,
  transitionClass?: boolean
}
interface IAdjustScreenOptions {
  browserEvent?: any,
  route?: string
}

@Injectable()
export class LayoutService {
  public layoutConf: ILayoutConf;
  public layoutConfSubject: any;
  public layoutConf$;
  public isMobile: boolean;
  public notSupportedRes: boolean;
  public currentRoute: string;

  constructor(
    private router: Router
  ) {
    this.layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
    this.layoutConf$ = this.layoutConfSubject.asObservable();
    this.setAppLayout();
  }

  setAppLayout() {
    this.layoutConf = {
      "navigationPos": "side",
      "sidebarStyle": "full-menu",
      "dir": "ltr",
      "useBreadcrumb": true,
      "topbarFixed": false,
      "breadcrumb": "title"
    }

    this.setLayoutFromQuery();

  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    let duration = opt.duration || 250;
    if (!opt.transitionClass) {
      this.layoutConf = Object.assign(this.layoutConf, lc);
      return this.layoutConfSubject.next(this.layoutConf);
    }

    this.layoutConf = Object.assign(this.layoutConf, lc, { layoutInTransition: true });
    this.layoutConfSubject.next(this.layoutConf);

    setTimeout(() => {
      this.layoutConf = Object.assign(this.layoutConf, { layoutInTransition: false });
      this.layoutConfSubject.next(this.layoutConf);
    }, duration);
  }

  setLayoutFromQuery() {
    let layoutConfString = getQueryParam('layout');
    try {
      this.layoutConf = JSON.parse(layoutConfString);
    } catch (e) { }
  }

  adjustLayout(options: IAdjustScreenOptions = {}) {
    let sidebarStyle: string;

    this.isMobile = this.isSm();
    this.currentRoute = options.route || this.currentRoute;
    sidebarStyle = this.isMobile ? 'closed' : 'full-menu';


    this.publishLayoutChange({
      isMobile: this.isMobile,
      sidebarStyle: sidebarStyle,
    });
  }

  isSm() {
    return window.matchMedia(`(max-width: 959px)`).matches;
  }

}
