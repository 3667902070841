import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-features-modal',
  templateUrl: './estate-features-modal.component.html',
  styleUrls: ['./estate-features-modal.component.scss']
})
export class EstateFeaturesModalComponent implements OnInit {

  public clientHasAnswered: any;
  public clientHasAssetProperties: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EstateFeaturesModalComponent>,
    private router: Router,
  ) {}

  ngOnInit() {
    let areasWithRecsReady = JSON.parse(sessionStorage.getItem('areasWithRecommendationsReady'));
    this.clientHasAnswered = (areasWithRecsReady['estate'] != undefined && areasWithRecsReady['estate'] === true);
    let currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientHasAssetProperties = currentClient.hasAssetProperties;
  }

  closeModal() {
    this.dialogRef.close('closed!');
  }

  navigateTo(route){
    this.router.navigate([route]);
    this.closeModal();
  }

}
