import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

import { environment } from '../../../../environments/environment';
import { fpAnimations } from '../../../shared/animations/fp-animations';
import { Area } from '../../../shared/helpers/areas.catalog';
import { AdvisorService } from '../../../shared/services/advisor.service';
import { ClientDataService } from 'app/shared/services/client-data-service';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
    selector: 'areas-dialog',
    templateUrl: 'areas-dialog.html',
    animations: fpAnimations,
    styleUrls: ['./areas-dialog.scss']
})

export class AreasDialog extends CloseDialogAtLogOut implements OnInit {

    @ViewChild(MatButton, { static: false }) submitButton: MatButton;

    addAreaForm: FormGroup;
    public advisorData: any;
    public availableAreas: any[] = Area.getActiveAreas(); // Select the areas to be displayed from areas catalog
    public areasInDisplayFormat: any[]; /*  = Area.getAreasInDisplayArrayFormatFixedColumn(this.availableAreas); //Areas selected transformed to an apropitated iterable for hmtl display */
    public model: any;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private advisorService: AdvisorService,
        public dialogRef: MatDialogRef<AreasDialog>,
        public snackBarSuccess: MatSnackBar,
        public snackBarError: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthenticationService,
        private currentClientService: ClientService
    ) {
        super(dialogRef, authService);
        this.areasInDisplayFormat = Area.getAreasInDisplayArrayFormatFixedColumn(this.availableAreas);

    }

    ngOnInit() {

        // Initalize form model
        this.model = Area.mapAreasToObject(
            (area) => {
                return { [area.id]: false }
            },
            this.availableAreas
        );


        // Deactivate the previously active areas from the current client.
        let subscribedAreas: any[] = this.data.areas;

        for (let areaSubscription in subscribedAreas) {
            let area = subscribedAreas[areaSubscription].nameOfAreaSimple;
            let displayed: boolean = subscribedAreas[areaSubscription].isDisplayed;
            if (this.model.hasOwnProperty(area)) {
                this.model[area] = displayed;

            }

        }


        // Initialize form controls by iterate over the desired available areas
        let formControls = Area.mapAreasToObject((area) => {
            return { [area.id]: new FormControl(this.model[area.id]) }
        });

        this.addAreaForm = new FormGroup(formControls);



        //Get advisor data to verify that the advisor has permission to activate a specific area.
        this.advisorService.getAdvisorData()
            .subscribe(
                data => {
                    this.advisorData = data;

                },
                error => {
                    console.log(error);
                });
    }

    addArea() {



        //Load client ID form local storage
        let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));
        let clientID = storagedClient.clientId;

        this.dialogRef.close(this.addAreaForm.value);
        // this.submitButton.disabled = true;

        /*         //Load data from form
                for (let prop in this.addAreaForm.value) {
                    this.model[prop] = this.addAreaForm.value[prop];
                } */

        /**
         * saveProp(prop:string):void
         * Update an specific user prop. http verb PUT
         * @param prop
         */
        let saveProp = (prop: string) => {
            let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
            let url = `${environment.apiAdvisorClientProfile}area/${clientID}`;
            let data: any = {}; data[prop] = 'true';

            this.http.post<any>(url, data, options).subscribe(
                success => {

                },
                error => {
                    console.log('Error!!!');
                    this.snackBarError.open("Something wrong happened!.", "Dismiss", {
                        duration: 6000,
                        panelClass: 'error-snackbar'
                    });
                }
            );
        };

        // For each proppertie in the model call update function.
        for (let prop in this.model) {

            if (this.addAreaForm.value[prop] != this.model[prop]) { //If is there a change in data, update
                saveProp(prop);
            }
        }

        setTimeout(()=>{
          this.currentClientService.refreshAll();
        }, 800)



    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
