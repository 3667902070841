import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Area } from "app/shared/helpers/areas.catalog";
import { WINDOW } from "app/shared/helpers/window.provider";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";
import { QuestionnaireController } from "app/views/questionnaires/Questionnaire.controller";

@Component({
  selector: "app-estate-column-element",
  templateUrl: "./estate-column-element.component.html",
  styleUrls: ["./estate-column-element.component.scss"],
})
export class EstateColumnElementComponent implements OnInit {
  @Input() public uploadedFiles: any;
  @Input() public name: string;
  @Input() public otherDocs: boolean = false;
  @Input() public isClient: boolean = true;
  @Input() public userRequestBody: any;
  @Input() public index: number = 1;
  @Input() public requestedSnapshot: any = "hasFiles";
  @Input() public creditsAvailable: number;
  @Input() public showWarning: boolean = false;
  @Input() public isJoint: boolean = false;
  @Input() public radioToHide: string = "none";
  @Input() public trustMaritalSituation: string;

  @Output() public setFilesToUser = new EventEmitter<any>();
  @Output() public setMaritalSituationToUser = new EventEmitter<any>();

  public isProduction: boolean;
  public isEarlyTester: boolean = false;
  public isAdvisorOnTrial: boolean = false;
  public isFirstTabLoad: boolean = true;

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    @Inject(WINDOW) private window: Window
  ) {}

  sendFilesToParent(info) {
    this.setFilesToUser.emit({
      ...info,
      index: this.index,
    });
  }

  onJoinOrSingleChange(info) {
    this.setMaritalSituationToUser.emit({
      ...info,
    });
  }

  ngOnInit() {
    console.log("HOLA: ", this.trustMaritalSituation);
  }

  mergAreaData(areasFromBack: any[] = []) {
    let mergedData = [];
    areasFromBack.forEach((areaFromBack) => {
      let areaFromCatalog = Area.findAreaById(areaFromBack.nameOfAreaSimple);
      mergedData.push(Object.assign(areaFromBack, areaFromCatalog));
    });
    return mergedData;
  }
}

interface CreditsInformation {
  hasCredits: boolean;
  remaining: number;
  availableFreePackages: number;
  freePackages: number;
}
