import {
  Directive,
  Input,
  HostListener,
} from "@angular/core";


@Directive({
  selector: "[popoverContent]"
})
export class PopoverDirective {

  @Input() popoverContent: string | { htmlID: string, isActive: boolean, position?: string };
  public htmlID: string;
  public isActive: boolean;
  public position: string;
  public timeOutEvent: any;
  constructor() { }


  @HostListener('mouseout', ['$event'])
  outside__(event) {
    console.log('PopoverDirective mouseout', event);
    let popover = document.getElementById('popover');
    if (!popover.contains(event.toElement)) {
      this.timeOutEvent = setTimeout(() => {
        popover.innerHTML = '';
        popover.classList.remove('show');
        popover.style.display = 'none';
      }, 1000);
      localStorage.setItem('popover_timeout', this.timeOutEvent);
    }

  }

  @HostListener('mouseover', ['$event'])
  inside__(event) {
    // Stop propagation. Now the event doesn't continue after a one movement enter and leave, wich doesn't rise up the mouse leave
    event.stopImmediatePropagation();
    if (this.timeOutEvent) {
      clearTimeout(this.timeOutEvent);
      this.timeOutEvent = null;
    }

    this.htmlID = typeof (this.popoverContent) === 'string' ? this.popoverContent.toString() : this.popoverContent['htmlID'];
    this.isActive = typeof (this.popoverContent) === 'string' ? true : this.popoverContent['isActive'];
    this.position = typeof (this.popoverContent) === 'string' ? 'default' : this.popoverContent['position'];

    //console.log('htmlID', this.htmlID, 'isActive', this.isActive, this.popoverContent);

    if (!this.isActive) { return; }

    const popover = document.getElementById('popover'); //Div that exists in app.component.html
    const content = document.getElementById(this.htmlID).cloneNode(true);
    const windowLocal = window.innerHeight;
    popover.innerHTML = '';
    popover.style.display = 'block';
    popover.append(content);
    popover.classList.add('show');

    let popoverRect = popover.getBoundingClientRect();
    let sourceRect = event.srcElement.getBoundingClientRect();
    let dataRows = document.querySelectorAll('#data-rows');
    //console.log('getBoundingClientRect', sourceElement, destElement);
    const sum = sourceRect.bottom + popoverRect.height;
    if (sum > windowLocal) {
      const expected = popoverRect.height - (sum - windowLocal);
      if (expected < 250) {
        popover.style.top = `${sourceRect.top - popoverRect.height}px`;
        popover.style.left = `${sourceRect.left - 8 - popoverRect.width}px`;
        popover.style.maxHeight = `350px`;
        popover.style.overflow = 'auto';
        dataRows.forEach((dataRow: HTMLElement) => {
          dataRow.style.overflow = 'auto';
        })
      } else {
        popover.style.height = `${expected}px`;
        dataRows.forEach((dataRow: HTMLElement) => {
          dataRow.style.overflow = 'auto';
          //dataRow.style.borderTop = 'none';
        })
      }
    } else {
      if(this.position === 'taxes'){
        popover.style.top = `${sourceRect.top - (sourceRect.height / 2) - 60}px`
        popover.style.left = `${sourceRect.left + 1}px`;
        console.log('Entra acá taxes', popover.style);

      } else if (this.position === 'bottom') {
        popover.style.top = `${sourceRect.top + sourceRect.height}px`;
        popover.style.left = `${(sourceRect.left + sourceRect.width / 2) - (popoverRect.width / 2)}px`;
      } else if (this.position === 'left') {
        popover.style.top = `${sourceRect.top + sourceRect.height / 2}px`;
        popover.style.left = `${sourceRect.left + sourceRect.width + 1}px`;
      } else if (this.position === 'right') {
        popover.style.top = `${sourceRect.top + (sourceRect.height / 2)}px`
        popover.style.left = `${sourceRect.left + 1}px`;
      } else if (this.position === 'top') {
        popover.style.top = `${sourceRect.top}px`;
        popover.style.left = `${sourceRect.left + sourceRect.width / 2}px`;
      } else {
        popover.style.top = `${sourceRect.top + sourceRect.height / 5}px`;
        popover.style.left = `${sourceRect.left + 25}px`;
      }
      dataRows.forEach((dataRow: HTMLElement) => {
        dataRow.style.overflow = 'auto';
        //dataRow.style.borderTop = 'none';
      })
    }
    // Positions


    //console.log(popover);

  }


}
