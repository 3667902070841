//Angular
import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

//Project
import { environment } from "../../../environments/environment";
import { getEndPointUrlByContext } from "../helpers/url.helper";
import { RothV2ReportDataInterface } from "../components/roth/roth-v2-pdf/roth-v2-pdf.datainterface";
import { Area, AREAS } from "../helpers/areas.catalog";
import { ClientService } from "./client/client.service";
import { advisorTemporalSingleToken, tokenValidationRequest } from "../helpers/utils";

export interface TaskManager {
  area: string;
  deadline: string;
  description: string;
  idRec: string;
  isOnTaskManager: boolean;
  name: string;
  owner: string;
  priority: string;
  subtitle: string;
}

@Injectable({
  providedIn: "root",
})
export class AdvisorService {
  private areaRecommendations: string = environment.apiRecs;
  private chartData: string = environment.apiCharts; // PUAJ
  private outlook: any = environment.apiOutlook;
  private outlookCode: string = environment.apiAdvisorAuthOutlook;
  private clientReport: string = environment.apiReports;
  private isProspect: boolean = false;

  private DEFAULT_PROTOCOL = "https:";
  private DEFAULT_DOMAIN = "api.fpalpha.app";
  private PROD_DOMAIN = "api.fpalpha.com";
  private DEV_DOMAIN = "api.dev.fpalpha.xyz";
  private BETA_DOMAIN = "api.beta.fpalpha.app";

  private DEFAULT_PORT_WITH_DOMAIN = ""; // same as :80

  private clientIDFirstName: string;
  private clientIDLastName: string;
  private clientIDPhoto: string;
  private clientIDAge: string;
  private optLevel: number;
  private email: string;
  private currentIDClient: any;

  private clientIDHasABusiness: string;
  private clientIDBusinessEntity: string;
  private clientIDChildren: string;
  private clientIDGrandChildren: string;
  private clientIDMaritalStatus: string;
  private clientIDLivingState: string;
  private clientIDRetired: string;
  private clientData: any;
  private clientIDChildOneAge: number;
  private clientIDChildTwoAge: number;
  private clientIDChildThreeAge: number;
  private clientIDChildFourAge: number;
  private clientIDChildFiveAge: number;
  private clientIDNetWorth: number;
  private clientIDSpouseName: string;
  private clientIDSpouseLastName: string;
  private clientIDSpouseNetWorth: number;
  private clientIDSpouseRetired: string;
  private clientIDSpouseHasABusiness: string;
  private clientIDSpouseUsesLastName: string;
  private clientIDChildrenCount: number;
  private clientIsImportedFromRedtail: boolean;
  private clientIDSpouseAge: any;
  private prospectProgress: any;
  private hasGeneralQuest: any;
  private hasEstateDemoAccess: any;

  private currentAdvisorID: string;
  public clientServiceData: any;

  @Output() clientEmitter: EventEmitter<any> = new EventEmitter();
  @Output() tokenGenerated = new EventEmitter<any>();
  @Output() updateClientCount = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
  ){}

  dynamicIPAddress(window, dynamicIPAddress: string): string {
    //Localhost at 4400. Local front and local backend
    if (window.location.port === "4400") {
      dynamicIPAddress = dynamicIPAddress.replace("PORT", ":6060");
      dynamicIPAddress = dynamicIPAddress.replace("PROTOCOL", "http:");
      dynamicIPAddress = dynamicIPAddress.replace("DOMAIN", "localhost");
      dynamicIPAddress = dynamicIPAddress.replace("/v1", "");
      //localhost any port
    } else if (window.location.hostname == "app.beta.fpalpha.app" || window.location.port === "4300") {
      dynamicIPAddress = dynamicIPAddress.replace(
        "PORT",
        this.DEFAULT_PORT_WITH_DOMAIN
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "PROTOCOL",
        this.DEFAULT_PROTOCOL
      );
      dynamicIPAddress = dynamicIPAddress.replace("DOMAIN", this.BETA_DOMAIN);
    }else if (
      /127\.0\.0\.1/.test(window.location.hostname) ||
      /localhost/.test(window.location.hostname) ||
      window.location.hostname.includes("ngrok.io")
    ) {
      dynamicIPAddress = dynamicIPAddress.replace(
        "PORT",
        this.DEFAULT_PORT_WITH_DOMAIN
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "PROTOCOL",
        this.DEFAULT_PROTOCOL
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "DOMAIN",
        this.DEFAULT_DOMAIN
      );

      // Testing online (Breakme)
    } else if (
      window.location.hostname == "app.fpalpha.app" ||
      window.location.hostname == "www.app.fpalpha.app"
    ) {
      dynamicIPAddress = dynamicIPAddress.replace(
        "PORT",
        this.DEFAULT_PORT_WITH_DOMAIN
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "PROTOCOL",
        this.DEFAULT_PROTOCOL
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "DOMAIN",
        this.DEFAULT_DOMAIN //testing
      );
      //Production or other cases
    } else if (window.location.hostname == "app.dev.fpalpha.xyz") {
      dynamicIPAddress = dynamicIPAddress.replace(
        "PORT",
        this.DEFAULT_PORT_WITH_DOMAIN
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "PROTOCOL",
        this.DEFAULT_PROTOCOL
      );
      dynamicIPAddress = dynamicIPAddress.replace("DOMAIN", this.DEV_DOMAIN);

    }

     else {
      dynamicIPAddress = dynamicIPAddress.replace(
        "PORT",
        this.DEFAULT_PORT_WITH_DOMAIN
      );
      dynamicIPAddress = dynamicIPAddress.replace(
        "PROTOCOL",
        window.location.protocol
      );
      const subdomain = window.location.hostname.split(".")[0];
      dynamicIPAddress = dynamicIPAddress.replace(
        "DOMAIN",
        window.location.hostname.replace(subdomain, "api")
      );
    }
    return dynamicIPAddress;
  }

  dynamicSocketAddress(window): string {
    return /altfesttaxplanning/.test(window.location.hostname)
      ? "http://socket.altfesttaxplanning.com"
      : "https://socket.fpalpha.app";
  }

  getAdvisorData() {
    return this.http.get<any>(`${getEndPointUrlByContext("Data")}`);
  }

  getCompanyData() {
    return this.http.get<any>(`${environment.apiCompanyData}`);
  }

  getCompanyDataPromise() {
    return this.http.get<any>(`${environment.apiCompanyData}`).toPromise();
  }

  getAdvisorDataPromise() {
    return this.http.get<any>(`${getEndPointUrlByContext("Data")}`).toPromise();
  }

  getPendoDataPromise() {
    return this.http.get<any>(`${environment.apiPendoData}`).toPromise();
  }

  async getClientSideData() {
    return this.http.get<any>(`${getEndPointUrlByContext("Data")}`).toPromise();
  }

  getAdvisorSettings() {
    return this.http.get<any>(
      `${getEndPointUrlByContext("AdvisorProfile")}/info`
    );
  }

  getNotifications(filters: string, status: string) {
    // return this.http.get<any>(`${getEndPointUrlByContext('Notifications')}${idAdvisor}/advisorsnots`);
    return this.http.get<any>(
      `${getEndPointUrlByContext(
        "Notifications"
      )}?type=${filters}&status=${status}`
    );
  }

  getClientList(pages?: number, items?: number) {
    return this.http.get<any>(
      `${environment.apiListClients}/?page=${pages}&items=${items}`
    );
  }

  getClientListV2(pages?: number, items?: number) {
    return this.http.get<any>(
      `${environment.apiListClientsv2}/?page=${pages}&items=${items}`
    );
  }

  client(pages?: number, items?: number) {
    return this.http.get<any>(
      `${environment.apiListClientsv2}/?page=${pages}&items=${items}`
    );
  }

  getTaxStats(idClient: string) {
    return this.http.get<any>(`${environment.apiTaxStats}${idClient}`);
  }

  async getTaxStatsAsync(idClient: string) {
    return this.http
      .get<any>(`${environment.apiTaxStats}${idClient}`)
      .toPromise();
  }

  deleteClient(idClient: string) {
    return this.http.delete(`${environment.apiListClients}/${idClient}`);
  }

  getCurrentClientID() {
    return this.currentIDClient;
  }

  getCurrentClientInfo() {
    return [
      this.clientIDFirstName,
      this.clientIDLastName,
      this.clientIDPhoto,
      this.clientIDAge,
      this.optLevel,
      this.email,
      this.clientIDHasABusiness,
      this.clientIDBusinessEntity,
      this.clientIDMaritalStatus,
      this.clientIDLivingState,
      this.clientIDRetired,
      this.clientIDChildren,
      this.clientIDChildOneAge,
      this.clientIDChildTwoAge,
      this.clientIDChildThreeAge,
      this.clientIDChildFourAge,
      this.clientIDChildFiveAge,
      this.clientIDGrandChildren,
      this.clientIDNetWorth,
      this.clientIDSpouseName,
      this.clientIDSpouseLastName,
      this.clientIDSpouseNetWorth,
      this.clientIDSpouseRetired,
      this.clientIDSpouseHasABusiness,
      this.clientIDSpouseUsesLastName,
      this.clientIDChildrenCount,
      this.clientIsImportedFromRedtail,
      this.clientIDSpouseAge,
      this.hasGeneralQuest,
    ];
  }

  getClientInfoOnFirstTime(idClient: string) {
    return this.http.get<any>(
      `${environment.apiClientFirstTimeLogin}${idClient}`
    );
  }

  getClientDashboardData(idClient: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext("Dashboard")}${idClient}`
    );
  }

  getClientDashboardAdvisorData(idClient: string) {
    return this.http.get<any>(
      `${environment.apiAdvisor}/profileclient/client/v2/${idClient}`
    );
  }

  getClientDashboardClientData(idClient: string) {
    return this.http.get<any>(
      `${environment.apiClientClientProfile}v2/${idClient}`
    );
  }

  getFamilyMapData(idClient: string) {
    return this.http.get<any>(
      `${environment.apiAdvisorV2}/docs/family-map?clientId=${idClient}`
    );
  }

  getClientProfileData(idClient: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext("ClientProfile")}${idClient}`
    );
  }

  getClientQuestionnaire(idClient: string, questionName: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext(
        "Questionnaire"
      )}${idClient}/questionnaire/${questionName}`
    );
  }

  getChartData(idClient: string, area: string) {
    return this.http.get<any>(`${this.chartData}${idClient}/chartjs/${area}`);
  }

  async getClientQuestionnaireAsync(
    clientId: string,
    qName: string,
    index: number = 1
  ) {
    return await this.http
      .get<any>(
        `${getEndPointUrlByContext(
          "Questionnaire"
        )}${clientId}/questionnaire/${qName}?index=${index}`
      )
      .toPromise();
  }

  async getClientQuestionnaireAsyncV2(
    clientId: string,
    qName: string,
    index: number = 1
  ) {
    return this.http
      .get<any>(
        `${getEndPointUrlByContext(
          "Questionnaire"
        )}${clientId}/questionnaire/${qName}?index=${index}`
      )
      .toPromise();
  }

  async glossarySearch(search: string) {
    let options = {
      headers: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    };
    let url = getEndPointUrlByContext("GlossarySearch");
    return await this.http.post(url, "search=" + search, options).toPromise();
  }

  async clientSearch(search: string) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    let url = getEndPointUrlByContext("ClientSearch");
    return await this.http
      .post(url, JSON.stringify({ search: search }), options)
      .toPromise();
  }

  getGeneralQuestAnswers(idClient: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext(
        "Questionnaire"
      )}${idClient}/questionnaire/general`
    );
  }

  getRecommendations(idClient: string, area: string) {
    return this.http.get<any>(
      `${this.areaRecommendations}${idClient}/recommendations/${area}`
    );
  }

  getExtractions(idClient: string, area: string) {
    return this.http.get<any>(
      `${this.areaRecommendations}${idClient}/quickdata/${area}`
    );
  }

  getStrategies(idClient: string, area: string) {
    return this.http.get<any>(
      `${this.areaRecommendations}${idClient}/strategies/${area}`
    );
  }

  //Get historical recommendations (RT)
  getHistoricalRecsDates(idClient: string, area: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext("Historical")}${idClient}/${area}`
    );
  }

  async getHistoricalRecs(
    idClient: string,
    area: string,
    asset: number,
    date: string
  ) {
    return await this.http
      .get<any>(
        `${getEndPointUrlByContext(
          "Historical"
        )}${idClient}/${area}/${asset}?date=${date}`
      )
      .toPromise();
  }

  // Get summary of beneficiaries
  getSummaryBeneficiaries(idClient: string, area: string = "estate") {
    return this.http.get<any>(
      `${this.areaRecommendations}${idClient}/${area}/beneficiaries`
    );
  }

  //Get recommendations on Task Manager
  getRecsOnTaskManager(idClient: string, filters: string) {
    return this.http.get<any>(
      `${getEndPointUrlByContext("TaskManager")}${idClient}/allrecs?${filters}`
    );
  }

  async getTasksFromTaskManager(idClient: string, filter: string, area: any) {
    return await this.http
      .get<any>(
        `${getEndPointUrlByContext("TaskManager")}${idClient}/${filter}/${area}`
      )
      .toPromise();
  }

  getClientData() {
    return this.clientData;
  }

  getOutlookURL() {
    return this.http.get<any>(`${this.outlook}`);
  }

  getReport(idClient: string, areaToDownload: string) {
    return this.http.get<any>(
      `${this.clientReport}${idClient}/download/${areaToDownload}`
    );
  }

  sendOutlookAuthCode(code: string) {
    return this.http.get<any>(`${this.outlookCode}?code=${code}`);
  }

  async getHubSubQuestionnares(idClient: string, area: string) {
    return await this.http
      .get<any[]>(getEndPointUrlByContext("Hub") + area + "/" + idClient)
      .toPromise();
  }

  // Setters
  /* setFullCurrentClient(client: any) {
    this.currentIDClient = client.idClient;
    this.clientIDFirstName = client.clientIDFirstName;
    this.clientIDLastName = client.clientIDLastName;
    this.clientIDPhoto = client.clientIDPhoto;
    this.clientIDAge = client.clientIDAge;
    this.optLevel = client.optLevel;
    this.email = client.email;
    this.clientIDHasABusiness = client.clientIDHasABusiness;
    this.clientIDBusinessEntity = client.clientIDBusinessEntity;
    this.clientIDChildren = client.clientIDChildren;
    this.clientIDChildOneAge = client.clientIDChildOneAge;
    this.clientIDChildTwoAge = client.clientIDChildTwoAge;
    this.clientIDChildThreeAge = client.clientIDChildThreeAge;
    this.clientIDChildFourAge = client.clientIDChildFourAge;
    this.clientIDChildFiveAge = client.clientIDChildFiveAge;
    this.clientIDMaritalStatus = client.clientIDMaritalStatus;
    this.clientIDLivingState = client.clientIDLivingState;
    this.clientIDRetired = client.clientIDRetiredOrNotWorking;
    this.isProspect = client.isProspect;
    this.clientIDGrandChildren = client.clientIDGrandchildren;
    this.clientIDNetWorth = client.clientIDNetWorth;
    this.clientIDSpouseName = client.clientIDSpouseName;
    this.clientIDSpouseLastName = client.clientIDSpouseLastName;
    this.clientIDSpouseNetWorth = client.clientIDSpouseNetWorth;
    this.clientIDSpouseRetired = client.clientIDSpouseRetired;
    this.clientIDSpouseHasABusiness = client.clientIDSpouseHasABusiness;
    this.clientIDSpouseUsesLastName = client.clientIDSpouseUsesLastName;
    this.clientIDChildrenCount = client.clientIDChildrenCount;
    this.clientIsImportedFromRedtail = client.importedFromRedtail;
    this.clientIDSpouseAge = client.clientIDSpouseAge;
    this.prospectProgress = client.prospectProgress;
    this.hasGeneralQuest = client.hasGeneralQuest;
    this.hasEstateDemoAccess =
      client.demoAccess != undefined &&
      client.demoAccess.includes("estateSnapshot");

    let currentClient = {
      clientId: this.currentIDClient,
      name: this.clientIDFirstName,
      lastName: this.clientIDLastName,
      photo: this.clientIDPhoto,
      age: this.clientIDAge,
      level: this.optLevel,
      email: this.email,
      isMarried: this.clientIDMaritalStatus,
      hasChildren: this.clientIDChildren,
      hasGrandChildren: this.clientIDGrandChildren,
      hasBusiness: this.clientIDHasABusiness,
      typeOfBusiness: this.clientIDBusinessEntity,
      isRetired: this.clientIDRetired,
      livesIn: this.clientIDLivingState,
      isProspect: this.isProspect,
      childOneAge: this.clientIDChildOneAge,
      childTwoAge: this.clientIDChildTwoAge,
      childThreeAge: this.clientIDChildThreeAge,
      childFourAge: this.clientIDChildFourAge,
      childFiveAge: this.clientIDChildThreeAge,
      netWorth: this.clientIDNetWorth,
      spouse: this.clientIDSpouseName,
      spouseAge: this.clientIDSpouseAge,
      spouseLastName: this.clientIDSpouseLastName,
      spouseNetWorth: this.clientIDSpouseNetWorth,
      spouseIsRetired: this.clientIDSpouseRetired,
      spouseHasBusiness: this.clientIDSpouseHasABusiness,
      spouseUsesOwnLastName: this.clientIDSpouseUsesLastName,
      childrenCount: this.clientIDChildrenCount,
      importedFromRedtail: this.clientIsImportedFromRedtail,
      comesFromAnyIntegration: client["fromIntegration"] != undefined,
      prospectProgress: this.prospectProgress,
      hasGeneralQuest: this.hasGeneralQuest,
      hasEstateDemoAccess: this.hasEstateDemoAccess,
    };
    currentClient["fullName"] = Boolean(currentClient.isMarried === "Married")
      ? Boolean(currentClient.spouseUsesOwnLastName === "Yes")
        ? currentClient.name +
          " " +
          currentClient.lastName +
          " & " +
          currentClient.spouse +
          " " +
          (currentClient.spouseLastName || "")
        : currentClient.name +
          " & " +
          currentClient.spouse +
          " " +
          currentClient.lastName
      : currentClient.name + " " + currentClient.lastName;

    // Set currentClient object to clientData

    console.log("This is going to the storage", currentClient);

    let assignData = () => {
      this.clientData = currentClient;
      sessionStorage.setItem("currentClient", JSON.stringify(currentClient));
      this.clientEmitter.emit(client);
    };

    assignData();

    let setQuestionnairesInformation = () => {
      this.getClientDashboardData(this.currentIDClient).subscribe((response) => {

          console.log(
            "Response of questionnaires data when client is clicked*",
            response
          );
          let clientHasAnsweredQuestionnaires = Area.mapAreasToObject(
            (area) => {
              return {
                ["ans" + area.id.charAt(0).toUpperCase() + area.id.slice(1)]:
                  response.clientHasAnsweredQuestionnaires[area.id],
              };
            },
            AREAS
          );
          sessionStorage.setItem(
            "clientHasAnsweredQuestionnaires",
            JSON.stringify(clientHasAnsweredQuestionnaires)
          );
        }
      );
    };
    setQuestionnairesInformation();

    if (this.hasGeneralQuest === true) {
      this.getClientQuestionnaireAsync(this.currentIDClient, "general").then(
        (response) => {
          //debugger;
          setTimeout(() => {
            let activePropertiesObj = response.answers.find(
              (line) => line.name === "activePositions_property"
            );
            let activeProperties = activePropertiesObj.answer;
            currentClient["hasAssetProperties"] = activeProperties
              .split(",")
              .some((val) => val === "true");
            assignData();
          }, 2000);
        },
        (error) => {
          assignData();
        }
      );
    }
  } */

 /*setFullCurrentClient_(client: any) {
    this.clientServiceData['clientId'] = client.idClient;
    this.clientServiceData['isProspect'] = client.isProspect;
    this.clientServiceData['importedFromRedtail'] = client.importedFromRedtail;
    this.clientServiceData['comesFromAnyIntegration'] = client["fromIntegration"] != undefined;
    this.clientServiceData['prospectProgress'] = client.prospectProgress;
    this.clientServiceData['hasGeneralQuest'] = client.hasGeneralQuest;
    this.clientServiceData['hasEstateDemoAccess'] = (client.demoAccess != undefined && client.demoAccess.includes("estateSnapshot"));

    sessionStorage.setItem("currentClient", JSON.stringify(this.clientServiceData));

    //Assign questionnaire data
    sessionStorage.setItem("clientHasAnsweredQuestionnaires", JSON.stringify('clientHasAnsweredQuestionnaires'));

  }*/


  setIdAdvisor(idAdvisor) {
    this.tokenGenerated.subscribe((decodedIDAdvisor) => {
      this.currentAdvisorID = decodedIDAdvisor;
    });

    this.tokenGenerated.emit(idAdvisor);
  }

  setClientCount() {
    this.updateClientCount.emit();
  }

  getHubAreas(idClient: string) {
    return this.http.get<any>(`${getEndPointUrlByContext("Hub")}${idClient}`);
  }

  async addHubSubQuestionnares(idClient: string, area: string, name: string) {
    return await this.http
      .post<any>(getEndPointUrlByContext("Hub") + area + "/" + idClient, {
        title: name,
      })
      .toPromise();
  }

  async editHubSubQuestionnares(
    area: string,
    idClient: string,
    index: number,
    name: string
  ) {
    return await this.http
      .put(
        getEndPointUrlByContext("Hub") + area + "/" + index + "/" + idClient,
        { clientIDAssetName: name }
      )
      .toPromise();
  }

  async deleteHubSubQuestionnares(
    area: string,
    idClient: string,
    index: number
  ) {
    return await this.http
      .delete<any>(
        getEndPointUrlByContext("Hub") + area + "/" + index + "/" + idClient
      )
      .toPromise();
  }

  async getQuestionnaireExtractionResults(
    idClient: string,
    area: string,
    index: string
  ) {
    return await this.http
      .get<any>(
        getEndPointUrlByContext("Questionnaire") +
          idClient +
          "/questionnairefiles/" +
          area +
          "?index=" +
          index
      )
      .toPromise();
  }

  async prospectToClient(idClient: string) {
    return await this.http
      .post<any>(getEndPointUrlByContext("ProspectToClient") + idClient, {})
      .toPromise();
  }

  async getProspectReportData(idClient: string) {
    return await this.http
      .get<any>(environment.apiProspectReports + idClient)
      .toPromise();
  }

  async getSummaryExtractions(
    idClient: string,
    area: string,
    index: string = "1"
  ) {
    return await this.http
      .get<any>(
        environment.apiAdvisorSummaryExtractions +
          idClient +
          "/" +
          area +
          "?index=" +
          index
      )
      .toPromise();
  }

  async getSummaryActions(idClient: string, area: string, filter: string) {
    return await this.http
      .get<any>(
        environment.apiAdvisorSummaryActions +
          idClient +
          "/" +
          area +
          "/" +
          filter
      )
      .toPromise();
  }

  async getSummaryGraph(idClient: string, area: string, filter: string) {
    return await this.http
      .get<any>(
        environment.apiAdvisorSummaryGraph +
          idClient +
          "/" +
          area +
          "/" +
          filter
      )
      .toPromise();
  }

  async getTaxProjections(idClient: string) {
    return await this.http
      .get<any>(environment.apiAdvisorProjections + "fulltax" + "/" + idClient)
      .toPromise();
  }

  async getEstateProjections(idClient: string) {
    return await this.http
      .get<any>(environment.apiAdvisorProjections + "estate" + "/" + idClient)
      .toPromise();
  }

  //Integrations

  async retainAreaFile(
    idClient: string,
    idArea: string,
    index: number,
    policyFilename: string,
    type: string = "validation"
  ) {
    return await this.http
      .get<any>(
        getEndPointUrlByContext("Questionnaire") +
          idClient +
          "/manualfixinvalidfile/" +
          idArea +
          "?index=" +
          index +
          "&policyFilename=" +
          policyFilename +
          "&request=" +
          type
      )
      .toPromise();
  }

  getFlowChart(idClient: string) {
    return this.http.get<any>(`${environment.apiFlowChart}${idClient}`);
  }

  // Subscription and payment
  getSubscriptionInformation() {
    return this.http.get<any>(
      `${environment.apiAdvisorSubscriptionInformation}`
    );
  }

  getPaymentMethod() {
    return this.http.get<any>(`${environment.apiAdvisorPaymentMethods}`);
  }

  changePaymentMethod(cardToken) {
    return this.http.post<any>(`${environment.apiAdvisorPaymentMethods}`, {
      cardToken,
    });
  }

  getPaymentMethods() {
    return this.http.get<any>(`${environment.apiAdvisorPaymentMethods}`);
  }

  getPaymentMethodsPromise() {
    return this.http
      .get<any>(`${environment.apiAdvisorPaymentMethods}`)
      .toPromise();
  }

  addPaymentMethod(cardToken: string) {
    return this.http
      .post(environment.apiAdvisorAddPaymentMethod, { cardToken })
      .toPromise();
  }

  payInvoice(id: string, paymentPlan: string, paymentMethodType: string) {
    return this.http
      .post(`${environment.apiAdvisorPayInvoice}/${id}/pay`, {
        paymentPlan,
        paymentMethodType,
      })
      .toPromise();
  }

  downloadInvoice(id: string) {
    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "blob";
      withCredentials?: boolean;
    } = {
      headers: null,
      params: null,
      responseType: "blob",
    };
    return this.http
      .get(`${environment.apiAdvisorInvoiceFile}/${id}/file`, options)
      .toPromise();
  }

  setDefaultPaymentMethod(stripeId: string) {
    return this.http
      .post(`${environment.apiAdvisorPaymentMethods}/default`, { stripeId })
      .toPromise();
  }

  deletePaymentMethod(stripeId: string) {
    return this.http
      .delete(`${environment.apiAdvisorPaymentMethods}/${stripeId}`)
      .toPromise();
  }

  getInvoices() {
    return this.http
      .get<any>(`${environment.apiAdvisorInvoicesRecord}`)
      .toPromise();
  }

  getCurrentMonthEstate(companyId: string) {
    return this.http
      .get<any>(
        `${environment.apiAdvisorV2}/advisors/companies/${companyId}/estate-snapshot/requests?mode=monthly&withDetail=true`
      )
      .toPromise();
  }

  // payInvoice(invoiceId: string, paymentPlan: string, paymentMethodType: string) {
  //   return this.http.post<any>(`${environment.apiAdvisorPayInvoice.replace(':invoiceId', invoiceId)}`, {
  //     paymentPlan,
  //     paymentMethodType,
  //   }).toPromise();
  // }

  // async downloadInvoice(invoiceId) {
  //   return await this.http.get(`${environment.apiAdvisorInvoiceFile.replace(':invoiceId', invoiceId)}`,{responseType: 'blob'});
  // }

  triggerSubscription(password: string) {
    return this.http.post<any>(`${environment.apiAdvisorSubscriptionTrigger}`, {
      password,
    });
  }

  getSubscriptionInvoices() {
    return this.http.get<any>(`${environment.apiAdvisorInvoicesRecord}`);
  }

  startSubscriptionPlan(type: string) {
    return this.http.post<any>(`${environment.apiAdvisorSubscriptionStart}`, {
      selectedPlan: type,
    });
  }

  changeSubcriptionPlan(type: string, password: string) {
    return this.http
      .post<any>(`${environment.apiAdvisorSubscriptionChange}`, {
        type,
        password,
      })
      .toPromise();
  }

  setFirstTimeTutorial(firstTime: boolean) {
    return this.http.put<any>(`${environment.apiFirstTime}`, {
      firstTime: firstTime,
    });
  }

  async getReviewExtractionImage(clientId: string, requestedImage: string) {
    let options = {
      //headers: new HttpHeaders().set('Content-Type', 'image/jpeg').set('Authorization', 'Bearer ' + localStorage.getItem('sessionToken')),
      responseType: "blob" as "blob",
    };
    return this.http
      .get(
        `${environment.apiReviewExtractionImage}/${clientId}/cutoutdownload?file=${requestedImage}`,
        options
      )
      .toPromise();
  }

  async getTimeLineReport(
    clientId: string,
    areas: string,
    startDate: string,
    endDate: string
  ) {
    return await this.http
      .get(
        `${environment.apiTimeLineReport}/${clientId}?areas=${areas}&startDate=${startDate}&endDate=${endDate}`
      )
      .toPromise();
  }

  async getEstateLabContacts(clientId: string) {
    return await this.http
      .get<any>(`${environment.apiEstateLabContacts}/${clientId}`)
      .toPromise();
  }

  async getEstateLabAssets(
    clientId: string,
    scenario: any,
    category?: string,
    subCategory?: string
  ) {
    // List of categories: contract|trust|will
    let response;

    if (category) {
      response = await this.http
        .get<any>(
          `${
            environment.apiEstateLab
          }/category/${category}/available-assets?idcli=${clientId}&scenario=${scenario}${
            subCategory ? "&docType=" + subCategory : ""
          }`
        )
        .toPromise();
    } else {
      response = await this.http
        .get<any>(`${environment.apiEstateLabAssets}/${clientId}`)
        .toPromise();
    }

    return response;
  }

  async getEstateLabData(clientId: string, scenario: any) {
    return await this.http
      .get<any>(
        `${environment.apiEstateLab}?idcli=${clientId}&scenario=${scenario}`
      )
      .toPromise();
  }

  async saveEstateLabDocument(clientId: string, docData: any) {
    return await this.http
      .put<any>(
        `${environment.apiEstateLab}/document?idcli=${clientId}`,
        docData
      )
      .toPromise();
  }

  async saveEstateLabDocumentGiftStrategy(
    clientId: string,
    docData: any,
    scenario: any
  ) {
    return await this.http
      .put<any>(
        `${environment.apiEstateLab}/gift-strategy?idcli=${clientId}&scenario=${scenario}`,
        docData
      )
      .toPromise();
  }

  async genericPost(url: string, body: any = {}) {
    return this.http.post<any>(url, body).toPromise();
  }

  async getClientFilingStatus(clientId: string) {
    return this.http
      .get<any>(
        `${getEndPointUrlByContext("ClientGeneral")}/${clientId}/filing-status`
      )
      .toPromise();
  }

  //idcli: string, year: string="", index: string = '1', area: string = 'fulltax', docName="")
  async setTaxUploaderStatusToSubmitted(
    idcli: string,
    area: string,
    docName = "",
    index: string = "1",
    year: string = ""
  ) {
    return this.http
      .post<any>(`${environment.apiAdvisorEstatusToSubmitted}/${idcli}`, {
        area: area,
        index: index,
        year: year,
        docName: docName,
      })
      .toPromise();
  }

  async getTaxToggleYearQuestions(
    clientId: string,
    year: string,
    filingStatus: string = ""
  ) {
    let body = {
      year: year,
      filingStatus: filingStatus,
    };
    //return await this.http.get(`${environment.apiTaxToggleYear}/${clientId}/toggletax/${year}`).toPromise();
    return await this.http
      .post(`${environment.apiTaxToggleYear}/${clientId}/toggletax`, body)
      .toPromise();
  }

  //New general quest

  async getDataPoints(idClient: string) {
    return await this.http
      .get<any>(`${environment.apiDatapointGeneral}?clientId=${idClient}`)
      .toPromise();
  }

  async getFamilyData(idClient: string) {
    return await this.http
      .get<any>(`${environment.apiFamily}/${idClient}`)
      .toPromise();
  }

  async getAssetsData(idClient: string) {
    return await this.http
      .get<any>(`${environment.apiAssets}/${idClient}`)
      .toPromise();
  }

  // Connected Apps

  getConnectedApps() {
    return this.http.get(`${environment.authConnectedApps}`).toPromise();
  }

  disconnectConnectedApp(connectionId: string) {
    return this.http
      .post(`${environment.disconnectConnectedApp}`, { connectionId })
      .toPromise();
  }

  async setTaxSummaryHiddenElements(clientId: string, elementName: string) {
    let elementsList: string[] = [];
    elementsList.push(elementName);
    let body = {
      components: elementsList,
    };

    return this.http
      .post<any>(
        `${environment.apiAdvisorSummary}/${clientId}/tax-summary/toggle-components`,
        body
      )
      .toPromise();
  }

  async updateTaxYearAndFilingStatus(
    clientId: string,
    year: string,
    filingStatus: string
  ) {
    let body = {
      filingStatus: filingStatus,
    };

    return this.http
      .post<any>(
        `${environment.apiTaxToggleYear}/${clientId}/inicialsave/${year}`,
        body
      )
      .toPromise();
  }

  async getClientById(idClient: string) {
    return this.http
      .get<any>(`${environment.apiAdvisor}/clientlist/clients/${idClient}`)
      .toPromise();
  }

  async getTaxExtractionsAsCSV(idClient: string, year: string) {
    let options = {
      responseType: "text" as "text",
    };
    return this.http
      .get(`${environment.apiAdvisor}/area/${idClient}/csv/${year}`, options)
      .toPromise();
  }

  async acceptAgreement(typeOfAgreement: string = "estate") {
    return this.http
      .post(`${environment.apiAdvisorV2}/advisors/activity`, {
        activityType: `${typeOfAgreement}SnapshotAgreement`,
        activity: {
          accepted: true,
        },
      })
      .toPromise();
  }

  async getActivity(section: string = "estate") {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/activity/${section}SnapshotAgreement`
      )
      .toPromise();
  }

  async getEstateSnapshotData(idClient: string, index: number = 1, getPDfVersion: boolean = false) {
    let options = {};

    if (window.location.port == "4400") {
      return this.http
        .get(
          `http://locahost:8011/estate-snapshot?clientId=${idClient}&index=${Number(index)}&pdfVersion=${getPDfVersion}`,
          options
        )
        .toPromise();
    }

    return this.http
      .get(
        `${
          environment.apiAdvisorV2
        }/docs/estate-snapshot?clientId=${idClient}&index=${Number(index)}&pdfVersion=${getPDfVersion}`,
        options
      )
      .toPromise()
      .catch((error) => {
        console.log(
          `Error. Loading EstateSnapshot data, for client${idClient}, index: ${index}`,
          error
        );
        throw error;
      });
  }

  async getEstateSnapshotMetaData(idClient: string, index: number = 1) {
    let options = {};

    return this.http
      .get(
        `${
          environment.apiAdvisor
        }/area/estate/request-summary?idcli=${idClient}&index=${Number(index)}`,
        options
      )
      .toPromise();
  }

  async getAreaSnapshotMetaData(
    idClient: string,
    index: number = 1,
    area: string
  ) {
    let options = {};

    return this.http
      .get(
        `${
          environment.apiAdvisor
        }/area/${area}/request-summary?idcli=${idClient}&index=${Number(
          index
        )}`,
        options
      )
      .toPromise();
  }

  async getEstateSnapshotSummaryCredits(
    idClient: string,
    index: number = 1,
    version: number = 1
  ) {
    return this.http
      .get(`${environment.apiAdvisor}/area/estate-summary/credits`)
      .toPromise();
  }

  async getEstateSnapshotRequestsByMonth(
    companyId: string,
    params: any,
    index: number = 1,
    version: number = 1
  ) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/companies/${companyId}/estate-snapshot/requests`,
        {
          params,
        }
      )
      .toPromise();
  }

  async getAdvisorCompanyProfile() {
    let options = {};
    return this.http
      .get<any>(
        `${environment.apiAdvisorV2}/advisors/companies/profile`,
        options
      )
      .toPromise();
  }

  async markAllNotificationsAsRead() {
    return this.http
      .post(`${getEndPointUrlByContext("MarkAsReadAllNotifications")}`, "")
      .toPromise();
  }

  async deleteAllNotifications() {
    return this.http
      .post(`${getEndPointUrlByContext("DeleteNotifications")}`, "")
      .toPromise();
  }

  async readANotification(notificationId: string) {
    let url = getEndPointUrlByContext("Notifications");
    return this.http.post(`${url}/read/${notificationId}`, "").toPromise();
  }

  async deleteANotification(notificationId: string) {
    let url = getEndPointUrlByContext("Notifications");
    return this.http.delete(`${url}/${notificationId}/?force=true`).toPromise();
  }

  /**
   *   TAX PROJECTIONS SCENARIOS & CALCULATORS
   */

  async getProjectionsScenarios(idClient: string, mode: string = "full") {
    let options = {};
    return this.http
      .get<any>(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios?mode=${mode}`,
        options
      )
      .toPromise();
  }

  async createProjectionScenario(
    idClient: string,
    mode: "blank" | "reference" | "" = "blank",
    scenario: any
  ) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .post<any>(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios?mode=${mode}`,
        JSON.stringify(scenario),
        options
      )
      .toPromise();
  }

  async deleteProjectionScenario(idClient: string, idScenario: string) {
    return this.http
      .delete(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios?scenario=${idScenario}`
      )
      .toPromise();
  }

  async updateScenario(idClient: string, body: any) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .post(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios`,
        JSON.stringify(body),
        options
      )
      .toPromise();
  }

  async updateScenarioPositions(idClient: string, body: any) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .put<any>(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios/positions`,
        body,
        options
      )
      .toPromise();
  }

  async updateInfoScenario(idClient: string, body: any) {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .put<any>(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/scenarios`,
        body,
        options
      )
      .toPromise();
  }

  async getAllCalculators(idClient: string) {
    return this.http
      .get<any>(
        `${environment.apiAdvisorProjections}fulltax/${idClient}/calculators`
      )
      .toPromise();
  }

  async scenariosSaveAndCalculate(idClient: string, scenarios: any[]) {
    let body = {
      inputs: {
        scenarios,
      },
    };
    return this.http
      .post<any>(
        `${environment.apiAdvisor}/calculator/tax-projections-v2/calculate?idClient=${idClient}&area=fulltax&assetIndex=1`,
        body
      )
      .toPromise();
  }

  async calculatorsSaveAndCalculate(
    idClient: string,
    scenarios: any[],
    calculators: any[],
    overwriteList: any = {}
  ) {
    let body = {
      scenarios,
      calculators,
      overwriteList,
    };
    return this.http
      .post<any>(
        `${environment.apiAdvisor}/projections/fulltax/${idClient}/calculators`,
        body
      )
      .toPromise();
  }

  saveCollapsableElements(
    idClient: string,
    body,
    docReference: string = "projections-scenarios"
  ) {
    return this.http
      .post(
        `${environment.apiAdvisor}/projections/fulltax/${idClient}/${docReference}/toggle-components`,
        body
      )
      .toPromise();
  }

  getCollapsableElements(idClient: string) {
    return this.http
      .get(
        `${environment.apiAdvisor}/projections/fulltax/${idClient}/toggle-components`
      )
      .toPromise();
  }

  /**
   * ESTATE UPLOADERS
   */

  async getEstateFilesFromClient(clientId: string) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/activity/estateFileStatus?clientId=${clientId}`
      )
      .toPromise();
  }

  async postEstateFilesFromClient(body) {
    return this.http
      .post(`${environment.apiAdvisorV2}/advisors/activity`, body)
      .toPromise();
  }

  async postEstateActivityFromClient(body) {
    return this.http
      .post(`${environment.apiAdvisorV2}/advisors/activity`, body)
      .toPromise();
  }

  /**
   * PAYMENT SERVICE
   */

  async userHasPaymentMethod() {
    return this.http
      .get(`${environment.apiAdvisorV2}/advisors/settings/payment-check-up`)
      .toPromise();
  }

  /**
   * ESTATE SNAPSHOT PREVIEW
   */

  async getEstateSnapshotStatus(clientId: string, index = 1) {
    return this.http
      .get(
        `${environment.apiRequestSummary}/?idcli=${clientId}&index=${index}&preventNotification=true`
      )
      .toPromise();
  }

  /**
   * ESTATE SNAPSHOT GET
   */

  async getEstateSnapshot(clientId: string) {
    return this.http
      .get(`${environment.updateSnapshot}?clientId=${clientId}`)
      .toPromise();
  }

  /**
   * ESTATE SNAPSHOT EDIT
   */

  async updateEstateSnapshot(clientId: string, request: any) {
    return this.http
      .put(`${environment.updateSnapshot}/${clientId}`, request)
      .toPromise();
  }

  async getXLSReport(clientId: string, index: number) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/docs/estate-snapshot/excel?clientId=${clientId}&index=${index}`,
        { responseType: "blob" }
      )
      .toPromise();
  }

  // Estate optimize by backend

  async rothConversionOptimizeScenario(
    idClient: string,
    optimize: string,
    body: any
  ) {
    return this.http
      .post(
        `${environment.apiAdvisor}/calculator/roth-conversion-v3/optimize?idClient=${idClient}&optimize=${optimize}`,
        body
      )
      .toPromise();
  }

  // Roth V2 PDF data

  async getRothV2PdfData(idClient: string, scenarioId: number): Promise<any> {
    return this.http
      .get(
        `${environment.apiAdvisor}/calculator/roth-conversion-v3/report?idClient=${idClient}&area=fulltax&assetIndex=1&scenario=${scenarioId}`
      )
      .toPromise();
  }

  async getRothScenariosNames(idClient: string): Promise<any> {
    return this.http
      .get(
        `${environment.apiAdvisor}/calculator/roth-conversion-v3/scenarios?idClient=${idClient}&area=fulltax&assetIndex=1&withNames=true`
      )
      .toPromise();
  }

  async setRothV2PdfData(
    idClient: string,
    scenarioId: number,
    body: RothV2ReportDataInterface
  ): Promise<any> {
    return this.http
      .put(
        `${environment.apiAdvisor}/calculator/roth-conversion-v3/report?idClient=${idClient}&area=fulltax&assetIndex=1&scenario=${scenarioId}`,
        body
      )
      .toPromise();
  }

  async getGeneratedScenarios(idClient: string) {
    return this.http
      .get(
        `${environment.apiAdvisor}/calculator/roth-conversion-v3/scenarios?idClient=${idClient}&area=fulltax&assetIndex=1`
      )
      .toPromise();
  }

  /**
   * Estate Lab 2.0 Balance sheet
   */

  async getEstateLabBalanceSheet(idClient: string) {
    return this.http
      .get(
        `${environment.apiAdvisor}/estate-planning-v2/balance-sheet?idcli=${idClient}`
      )
      .toPromise();
  }

  /**
   * Estate Lab 2.0 life expectancy
   * */

  async getEstateLabLifeExpectancy(
    idClient: string,
    scenario: string | number
  ) {
    return this.http
      .get(
        `${environment.apiAdvisor}/estate-planning-v2/flowchart/settings?idcli=${idClient}&scenario=${scenario}`
      )
      .toPromise();
  }

  async putEstateLabLifeExpectancy(
    idClient: string,
    scenario: string | number,
    body
  ) {
    return this.http
      .put(
        `${environment.apiAdvisor}/estate-planning-v2/flowchart/settings?idcli=${idClient}&scenario=${scenario}`,
        body
      )
      .toPromise();
  }

  /**
   * Estate Lab 2.0 flow chart
   * */

  async getEstateLabFlowChart(
    idClient: string,
    scenarioId: string | number,
    withStrategies: boolean = false,
    isPdfVersion: boolean = false,
    withProjections: boolean = false
  ) {
    return this.http
      .get(`${environment.apiAdvisorV2}/docs/estate-lab?scenario=${scenarioId}&clientId=${idClient}&withStrategies=${withStrategies}&pdfView=${String(isPdfVersion)}&withProjections=${String(withProjections)}`
      )
      .toPromise();
  }

  // Simplify params
  async getInsuranceSnapshotSummaryCredits(
    idClient: string,
    index: number = 1,
    version: number = 1,
    area
  ) {
    return this.http
      .get(`${environment.apiAdvisor}/area/summary/${area}/credits`)
      .toPromise();
  }

  async getInsuranceSnapshotRequestsByMonth(
    companyId: string,
    params: any,
    index: number = 1,
    version: number = 1
  ) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/companies/${companyId}/estate-snapshot/requests`,
        {
          params,
        }
      )
      .toPromise();
  }

  async getHubFilesFromClient(clientId: string, where: any) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/activity/${where}?clientId=${clientId}`
      )
      .toPromise();
  }
  async loadSnapshotData(clientId: string, area, token?: string) {
    let headers = new HttpHeaders({
      "Content-Type": "app/json",
      Authorization: `Bearer ${token}`,
    });

    let options = {};
    if (token != undefined) {
      options = { headers };
    }

    return this.http
      .get(`${environment.apiAdvisorSummary}/${clientId}/${area}`, options)
      .toPromise();
  }

  async loadSnaphotDataEstateV2(clientId: string, token?: string, index: string = '1', getPDfVersion: boolean = false) {
    let headers = new HttpHeaders({
      "Content-Type": "app/json",
      Authorization: `Bearer ${token}`,
    });

    let options = {};
    if (token != undefined) {
      options = { headers };
    }

    return this.http
      .get(`${environment.apiAdvisorV2}/docs/estate-snapshot?clientId=${clientId}&index=${index}&pdfVersion=${getPDfVersion}`, options)
      .toPromise();
  }


  async statusSnapshotData(clientId: string, area) {
    return this.http
      .get(
        `${environment.apiAdvisor}/area/${area}/request-summary?idcli=${clientId}`
      )
      .toPromise();
  }

  async getInsuranceActivity(
    clientId: string,
    area: string = "home",
    filter: string = "latest"
  ) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/activity/${area}FileStatus?clientId=${clientId}&filter=${filter}`
      )
      .toPromise();
  }

  async postInsuranceActivity(
    clientId: string,
    area: string = "home",
    activity
  ) {
    return this.http
      .post(`${environment.apiAdvisorV2}/advisors/activity`, {
        activity, // Like a body
        clientId, //
        activityType: `${area}FileStatus`, //Area
        area, //home or auto
      })
      .toPromise();
  }

  async postCreditsActivity(
    clientId: string,
    area: string = "home",
    activity,
    upload = true
  ) {
    const body = {
      activity: {
        ...activity,
        uploadType: upload ? "upload" : "snapshot",
      }, // Like a body
      clientId, //
      activityType: "uploadCredit", //Area
      area, //home or auto
    };
    return this.http
      .post(`${environment.apiAdvisorV2}/advisors/activity`, body)
      .toPromise();
  }

  async getInsuranceProviders(param = undefined) {
    return this.http
      .get(
        `${environment.apiAdvisor}/hub/insurance-providers${
          param ? `?area=${param}` : ""
        }`
      )
      .toPromise();
  }

  async purchaseCredits(companyId: string, body: any) {
    return this.http
      .post(
        `${environment.apiAdvisorV2}/service/payment/advisor/purchase-credit?companyId=${companyId}`,
        body
      )
      .toPromise();
  }

  async getCreditsPerArea(area) {
    return this.http
      .get(`${environment.apiAdvisorV2}/advisor/upload-credits?area=${area}`)
      .toPromise();
  }

  async getHistoryUploads(
    companyId: string,
    advisorId: string = "",
    area: any = "",
    onlyActive: boolean = true,
    periodStart: string = "",
    periodEnd: string = ""
  ) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/companies/upload-credits/detail`,
        {
          params: {
            companyId,
            ...(advisorId ? { advisorId } : {}),
            area,
            onlyActive: onlyActive ? "true" : "",
            ...(periodStart ? { periodStart } : {}),
            ...(periodEnd ? { periodEnd } : {}),
          },
        }
      )
      .toPromise();
  }

  async getPriceCreditsPerArea(area) {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/service/payment/upload-credits/information?area=${area}`
      )
      .toPromise();
  }

  async getCreditsPaymentDetail(companyId: string = "") {
    return this.http
      .get(`${environment.apiAdvisorV2}/service/payment/advisor/detail`, {
        params: {
          companyId,
        },
      })
      .toPromise();
  }

  async getCompanyCreditsV2(companyId, area = "") {
    return this.http
      .get(
        `${environment.apiAdvisorV2}/advisors/companies/upload-credits?companyId=${companyId}&area=${area}`
      )
      .toPromise();
  }

  async getTaxes(idCli: string, numScenario: number, withStrategies?: any, withProjections:boolean = false) {
    return this.http
      .get(
        `${environment.apiAdvisor}/estate-planning-v2/taxes?idcli=${idCli}&scenario=${numScenario}&withStrategies=${withStrategies}&withProjections=${withProjections}`
      )
      .toPromise();
  }

  async updateTaxes(
    idCli: string,
    numScenario: number,
    firstSpouseFederal?: any,
    secondSpouseFederal?: any,
    withStrategies?: any,
    withProjections?: boolean
  ) {
    return this.http
      .post(
        `${
          environment.apiAdvisor
        }/estate-planning-v2/taxes?idcli=${idCli}&scenario=${numScenario}${
          firstSpouseFederal === 0
            ? "&firstSpousefederalExemption=0"
            : firstSpouseFederal
            ? "&firstSpousefederalExemption=" + firstSpouseFederal
            : ""
        }${
          secondSpouseFederal === 0
            ? "&secondSpousefederalExemption=0"
            : secondSpouseFederal
            ? "&secondSpousefederalExemption=" + secondSpouseFederal
            : ""
        }&withStrategies=${withStrategies}&withProjections=${Boolean(withProjections)}`,
        {}
      )
      .toPromise();
  }

  async userHasSSO(email: string) {
    return this.http
      .get(`${environment.apiAdvisorV2}/advisors/saml/has-sso-login`, {
        params: {
          email,
        },
      })
      .toPromise();
  }

  /**
   * Estate Lab 2.5 Balance sheet
   */

  async getEstateLabBalanceSheetV3(idClient: string, withProjections: boolean = false, scenario: any = 1) {
    return this.http
      .get(
        `${environment.updateEstateLab}/estate-lab/balance-sheet?clientId=${idClient}&scenario=${scenario}&withProjections=${withProjections}`
      )
      .toPromise();
  }

  async getExternalEstateLab(idClient: string, scenarios: number[] = [1], validatedToken?: string) {

    if(validatedToken == undefined){
      let token = await advisorTemporalSingleToken(this.http);
      console.log('getExternalBalanceSheet token', token);
      let tokenResponse = await tokenValidationRequest(token, this.http);
      validatedToken = tokenResponse['access_token'];
    }


    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${validatedToken}`,
      })
    };

    return this.http
      .get(
        `${environment.updateEstateLab}/estate-lab/pdf?clientId=${idClient}&scenarios=${scenarios.join(',')}`,
        options
      )
      .toPromise();
  }
}
