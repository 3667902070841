import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wealthbox-home',
  templateUrl: './wealthbox-home.component.html',
  styleUrls: ['./wealthbox-home.component.css']
})
export class WealthboxHomeComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private advisorService: AdvisorService,
    public snackBarSuccess: MatSnackBar,
    public router: Router,
    public snackBarError: MatSnackBar,) { }

  ngOnInit(): void {
  }

  disconnectWealthbox() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiWealthboxDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Wealthbox disconnected');
          this.snackBarSuccess.open("Your Wealthbox account is now disconnected from FP Alpha", "Ok", {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
          this.router.navigate(['/settings/external-providers-interactions']);
        },
        error => {
          console.log('Error: Wealthbox disconnect');
          this.snackBarError.open("Couldn't disconnect your Wealthbox account. Try again in a moment, if the problem persists contact support." + error.error.message, "Dismiss", {
            duration: 9000,
            panelClass: 'error-snackbar'
          });
        }
      )
  }
}
