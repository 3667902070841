import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { columnsToRenderizeByAssetType } from './utils';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent {

  @Output('handlePrevStep') handlePrevStep = new EventEmitter();
  
  @Output('onFormReady') onFormReady = new EventEmitter<FormGroup>()

  assetsForm: FormGroup;

  columnsToRenderizeInTable = columnsToRenderizeByAssetType;

  columnsWithMinSize = {
    beneficiaries: '210px',
    contingentBeneficiaries: '210px'
  }


  constructor(private formBuilder: FormBuilder) { 
    this.resetAssetsForm()
  }

  emitFormReady(){
    this.onFormReady.emit(this.assetsForm)
  }

  resetAssetsForm() {
    this.assetsForm = this.formBuilder.group({});  
  }

  prevSection(){
    this.handlePrevStep.emit()
  }

}
