import { AdvisorService } from 'app/shared/services/advisor.service';

export const ESTATEDOC_TRUST = "trust";
export const ESTATEDOC_WILL = "will";
export const ESTATEDOC_CONTRACT = "contract";
export const ESTATEDOC_GIFT = "gift";
//export const LIST_OF_TRUSTS = ['ITIL', 'LTR', 'LTI', 'GRATGRUT', 'CRATCRUT', 'IDGT', 'QPRT', 'DAF', 'DT', 'MTA', 'MTB', 'SLAT', 'TESTAMENTARY']

export class EstateDocument {

  readonly DOCUMENT_NAVIGATOR_MAP: string[];
  readonly BENEFICIARY_CATEGORIES: string[];
  readonly BENEFICIARY_DEFAULT_CATEGORY: string;
  readonly BENEFICIARY_CATEGORIES_TITLES: string[];

  constructor(options: EstateDocumentOptions) {
    this.category = options.DOCUMENT_CATEGORY;
    this.DOCUMENT_NAVIGATOR_MAP = options.DOCUMENT_NAVIGATOR_MAP;
    this.BENEFICIARY_CATEGORIES = options.BENEFICIARY_CATEGORIES;
    this.BENEFICIARY_DEFAULT_CATEGORY = options.BENEFICIARY_DEFAULT_CATEGORY;
    this.BENEFICIARY_CATEGORIES_TITLES = options.BENEFICIARY_CATEGORIES_TITLES;
    this.docType = options.DOCUMENT_SUBTYPE ? options.DOCUMENT_SUBTYPE : 'none'
  }

  category: string;
  docType: string;
  assets: EstateDocumentAsset[] = [];
  addons: any = {};
  scenario: any = '';

  public addBeneficiaryToAsset(assetId: string, category?: string, beneficiary: EstateDocumentAssetBeneficiary = null) {
    let foundAsset = this.assets.find(inneerAsset => { return inneerAsset.id == assetId });
    if (beneficiary == null) {
      beneficiary = {
        id: null,
        addons: {},
        percentage: 0
      }
    }

    // Assign a category for the benneficiary if one is set, else set the default one
    if (category) {
      beneficiary.addons['category'] = category;
    } else if (Boolean(this.BENEFICIARY_CATEGORIES) && Boolean(this.BENEFICIARY_DEFAULT_CATEGORY)) {
      beneficiary.addons['category'] = this.BENEFICIARY_DEFAULT_CATEGORY;
    }

    foundAsset.beneficiaries.push(beneficiary)
  }

  public addAsset() {
    let newAsset: EstateDocumentAsset = {
      id: null,
      beneficiaries: [],
      addons: {}
    }
    this.assets.push(newAsset)
  }

  public assetsHasValue(): boolean {
    if (this.docType === 'DAF' || this.docType === 'CRATCRUT') {
        if (this.assets.length >= 0) {
          let allHasValue = true;
          this.assets.forEach(asset => {
            allHasValue = allHasValue && (asset.addons.percentageToContribute >= 0 && asset.addons.percentageToContribute <= 100 && asset.addons.percentageToContribute)
          });
          return allHasValue;
        }
        return false;
    }
  }

  public areAllAssets100PercentAssigned(): boolean {
    if (this.assets.length <= 0 && this.category === 'will') return true;
    if (this.assets.length <= 0) return true;
    let areAllAsetsAssigned = true;
    this.assets.map(assetsAssigned => {
      let assetPercenatgeAssigned: number = 0;
      let assetBeneficiaryIdAssigned: boolean = true;

      // If the current document has categories for the beneficiaries, validate that each category sums to 100%
      if(this.BENEFICIARY_CATEGORIES){
        this.BENEFICIARY_CATEGORIES.map(currentCategory => {
          let categoryAssignedPercentage: number = 0;
          let filtered = assetsAssigned.beneficiaries.filter(beneficiary => beneficiary.addons.category == currentCategory);
          filtered.map(beneficiary => {
            // Sum percentages
            categoryAssignedPercentage += beneficiary.percentage;
            // Each beneficiary already has selecet contact?
            assetBeneficiaryIdAssigned = assetBeneficiaryIdAssigned && Boolean(beneficiary.id);
          })
          areAllAsetsAssigned = areAllAsetsAssigned && (categoryAssignedPercentage == 100 || (currentCategory != this.BENEFICIARY_DEFAULT_CATEGORY && filtered.length == 0));

        })
      }else{ // validate that each beneficiary sums to 100%
        assetsAssigned.beneficiaries.map(assetBeneficiary => {
          // Sum percentages
          assetPercenatgeAssigned += assetBeneficiary.percentage;
          // Each beneficiary already has selecet contact?
          assetBeneficiaryIdAssigned = assetBeneficiaryIdAssigned && Boolean(assetBeneficiary.id);
        })
        areAllAsetsAssigned = areAllAsetsAssigned && (assetPercenatgeAssigned == 100)
      }

      areAllAsetsAssigned = areAllAsetsAssigned && assetBeneficiaryIdAssigned;

    })
    return areAllAsetsAssigned;
  }

  loadDataFrom(response: any, docSubType?: string): EstateDocument {
    if(this.docType  && this.docType != 'none') docSubType = this.docType;

    let docNavigated: any = response;

    // Navigate the object using the navigator
    this.DOCUMENT_NAVIGATOR_MAP.forEach(section => {
      docNavigated = docNavigated[section]
    });

    //Get the first document from the array or find by type (docNavigated should be a document array at this point)
    if (docNavigated) {

      if(this.docType && this.docType != 'none'){
        docNavigated = docNavigated.find(doc => doc.type == docSubType);
      }else{
        docNavigated = docNavigated[0];
      }

    }

    if (docNavigated) {

      this.assets = [];

      this.addons = docNavigated.addons ? docNavigated.addons : {};
      docNavigated.assets.map(responseAsset => {

        let asset: EstateDocumentAsset = {
          id: responseAsset._id,
          beneficiaries: [],
          addons: responseAsset.addons ? responseAsset.addons : {}
        }

        responseAsset.beneficiaries.map(responseBeneficiary => {
          let beneficiary: EstateDocumentAssetBeneficiary = {
            id: responseBeneficiary._id,
            percentage: responseBeneficiary.percentage,
            addons: responseBeneficiary.addons ? responseBeneficiary.addons : {}
          }
          asset.beneficiaries.push(beneficiary);
        })

        this.assets.push(asset)
      })
    }
    this.scenario = response.scenario;
    return this
  }

  async save(advisorService: AdvisorService, clientId: string) {

    let docToSave = JSON.parse(JSON.stringify(this));

    this.assets.map((asset, assetIndex, array) => {
      docToSave.assets[assetIndex]['assetId'] = asset.id;
      delete docToSave.assets[assetIndex]['id'];
      delete docToSave['DOCUMENT_CATEGORY'];
      delete docToSave['DOCUMENT_NAVIGATOR_MAP'];
      delete docToSave['BENEFICIARY_CATEGORIES'];
      delete docToSave['BENEFICIARY_DEFAULT_CATEGORY'];
      delete docToSave['BENEFICIARY_CATEGORIES_TITLES'];

      asset.beneficiaries.map((beneficiary, beneficaryIndex, array) => {
        docToSave.assets[assetIndex].beneficiaries[beneficaryIndex]['contactId'] = beneficiary.id;
        delete docToSave.assets[assetIndex].beneficiaries[beneficaryIndex]['id'];
      })
    });
    if (this.addons.lifetime) {
      delete docToSave.addons.years;
    }
    await advisorService.saveEstateLabDocument(clientId, docToSave);

  }

}

export interface EstateDocumentOptions{
  DOCUMENT_CATEGORY: string,
  DOCUMENT_NAVIGATOR_MAP: string[],
  BENEFICIARY_CATEGORIES?: string[],
  BENEFICIARY_CATEGORIES_TITLES?: string[],
  BENEFICIARY_DEFAULT_CATEGORY?: string,
  DOCUMENT_SUBTYPE?: string,

}



export class Asset {
  id: string;
  areaId: string;
  name: string;
  state: string;
  value: number;
  type: string;

}

export class Beneficiary {
  id: string;
  percentage: number;
  addons: any;
}

export class Contact {
  id: string;
  firstName: string;
  lastName: string;
  type: string;
  relation: string;
}

export interface EstateDocumentAssetBeneficiary {
  id: string,
  percentage: number,
  addons: any
}

export interface EstateDocumentAsset {
  id: string,
  beneficiaries: EstateDocumentAssetBeneficiary[],
  addons: any
}

export interface Gift {
  amount: number,
  fullName: string,
  id: string,
  numOfYears: number,
  contactId: string
}

export class DocumentFactory{

  static create(type: string, subType?: string): EstateDocument {
    let options: EstateDocumentOptions = {
      DOCUMENT_CATEGORY: '',
      DOCUMENT_NAVIGATOR_MAP: []
    };

    if(type == ESTATEDOC_TRUST){

      options.DOCUMENT_CATEGORY = ESTATEDOC_TRUST;
      options.DOCUMENT_NAVIGATOR_MAP = ['nonProbateAssets', 'byTrust', 'documents'];
      options.DOCUMENT_SUBTYPE = subType;
      options.BENEFICIARY_CATEGORIES = ['simpleBeneficiary'];
      options.BENEFICIARY_CATEGORIES_TITLES = ['Beneficiary(ies)'];
      options.BENEFICIARY_DEFAULT_CATEGORY = 'simpleBeneficiary';

      if( subType == 'GRATGRUT' || subType == 'SLAT'){
        options.BENEFICIARY_CATEGORIES = ['incomeBeneficiary', 'remainderBeneficiary'];
        options.BENEFICIARY_CATEGORIES_TITLES = ['Income Beneficiary(ies)', 'Remainder Beneficiary(ies)'];
        options.BENEFICIARY_DEFAULT_CATEGORY = 'incomeBeneficiary';
      }

    } else if(type == ESTATEDOC_CONTRACT){

      options.DOCUMENT_CATEGORY = ESTATEDOC_CONTRACT;
      options.DOCUMENT_NAVIGATOR_MAP = ['nonProbateAssets', 'byContract', 'documents'];
      options.BENEFICIARY_CATEGORIES = ['primaryBeneficiary', 'contingentBenficiary'];
      options.BENEFICIARY_CATEGORIES_TITLES = ['Primary Beneficiary(ies)', 'Contingent Beneficiary(ies)'];
      options.BENEFICIARY_DEFAULT_CATEGORY = 'primaryBeneficiary';

    } else if(type == ESTATEDOC_WILL){

      options.DOCUMENT_CATEGORY = ESTATEDOC_WILL;
      options.DOCUMENT_NAVIGATOR_MAP = ['probateAssets', 'byWill', 'documents'];
      options.BENEFICIARY_CATEGORIES = ['primaryBeneficiary', 'contingentBenficiary'];
      options.BENEFICIARY_CATEGORIES_TITLES = ['Primary Beneficiary(ies)', 'Contingent Beneficiary(ies)'];
      options.BENEFICIARY_DEFAULT_CATEGORY = 'primaryBeneficiary';

    }

    return new EstateDocument(options);

  }
}



export class EstateLabMapper {

  static async GetAssetList(clientId: string, advisorService: AdvisorService, assetCategory: string, scenario: any, subType?: string): Promise<Asset[]> {
    let assets: Asset[] = []
    let response = await advisorService.getEstateLabAssets(clientId, scenario, assetCategory, subType);
    response.map(item => {
      item['id'] = item['_id'];
      delete item['_id'];
      assets.push(item);
    })
    return assets
  }

  static filterAssetByType(type: string){

  }

  static async GetContactsList(clientId: string, advisorService: AdvisorService): Promise<Contact[]> {
    let contacts: Contact[] = []
    let response = await advisorService.getEstateLabContacts(clientId);
    response.map(item => {
      item['id'] = item['_id'];
      delete item['_id'];
      contacts.push(item);
    })
    return contacts
  }


  static async saveGifts(advisorService: AdvisorService, clientId: string, gifts: Gift[], scenario:any) {
    let docToSave: Gift[] = JSON.parse(JSON.stringify(gifts));
    docToSave.map((gift, index) => {
      delete docToSave[index].fullName;
      delete docToSave[index].id;
    })

    let objectToSave = {
      recipients: docToSave
    }

    await advisorService.saveEstateLabDocumentGiftStrategy(clientId, objectToSave.recipients, scenario);
  }

  static async saveDocument(advisorService: AdvisorService, clientId: string, document: EstateDocument){

    let docToSave = JSON.parse(JSON.stringify(document));

    document.assets.map((asset, assetIndex, array) => {
      docToSave.assets[assetIndex]['assetId'] = asset.id;
      delete docToSave.assets[assetIndex]['id'];

      asset.beneficiaries.map((beneficiary, beneficaryIndex, array) => {
        docToSave.assets[assetIndex].beneficiaries[beneficaryIndex]['contactId'] = beneficiary.id;
        delete docToSave.assets[assetIndex].beneficiaries[beneficaryIndex]['id'];
      })
    });

    await advisorService.saveEstateLabDocument(clientId, docToSave);

  }
}
