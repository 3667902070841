import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class IntegrationAvailabilityService {



  constructor(private http: HttpClient) {}

  getIntegrationAvailability() {
    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    };
    return this.http
      .get<any>(
        `${environment.apiIntegrationAvailabilityCheck}`,
        options
      )
  }

 
}
