import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RothCalculator } from "app/shared/classes/roth-calculator";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { nFormatter } from "../utils";

@Component({
  selector: "app-roth-graph-modal",
  templateUrl: "./roth-graph-modal.component.html",
  styleUrls: ["./roth-graph-modal.component.scss"],
})
export class RothGraphModalComponent implements OnInit {
  public dataset: any;
  public inputs: any;
  public variableForm: FormGroup;
  public taxableYearsObj: any = {};
  public originalTaxableYearsObj: any = {};
  public taxableYearsObjModified: Map<any, any> = new Map();
  public resetEnabled: boolean = true;
  public taxableYears: any = [];
  public rothCalculator: RothCalculator;
  public taxableYearsForm: FormGroup;
  public barChartData: ChartDataSets[] = [];
  public barChartDataLabels: any[] = [];
  public barChartLegend = true;
  public barChartType: ChartType = "bar";
  public prevYear: any = 0;
  public modifiedYears: Set<any> = new Set([]);
  public reachLimit: boolean = true;
  public barChartColors: any[] = [
    {
      borderColor: "#4BAF50",
      backgroundColor: "#4BAF50",
    },
  ];
  public barChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return (
            "$" +
            Number(tooltipItem.yLabel)
              .toFixed(0)
              .replace(/./g, function (c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0
                  ? "," + c
                  : c;
              })
          );
        },
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            fontColor: "rgb(200, 200, 200)",
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left",
          gridLines: {
            display: true,
            color: "rgb(62, 64, 66)",
            zeroLineColor: "rgb(242, 242, 242)",
          },
          ticks: {
            beginAtZero: true,
            fontColor: "#C8C8C8",
            callback: (value, index, values) => {
              return `$${nFormatter(value, 1)}`;
            },
          },
        },
      ],
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "March",
          borderColor: "orange",
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: "orange",
            content: "LineAnno",
          },
        },
      ],
    },
  };

  public minYear: any = {};
  public maxYear: any = {};

  constructor(
    private fb: FormBuilder,
    public rothCons: RothCalculator,
    public dialogRef: MatDialogRef<RothGraphModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.taxableYearsForm = this.fb.group({
      year: "",
      value: "",
    });
    this.dataset = data.dataset;
    this.taxableYearsObj = data.taxableYearsObj || {};
    this.taxableYearsObjModified = data.taxableYearsObjModified || new Map();
    this.taxableYears = data.taxableYears || [];
    this.inputs = data.inputs;
    this.variableForm = data.form;
    this.rothCalculator = rothCons;
  }

  ngOnInit() {
    this.setTaxableIncomeToYear(true);
    this.prevYear = this.taxableYears[0].value;
    this.minYear = this.taxableYears[1];
    this.maxYear = this.taxableYears[this.taxableYears.length - 1];
    this.taxableYearsForm.controls.year.setValue(this.taxableYears[1].text);
    this.taxableYearsForm.controls.value.setValue(
      this.taxableYearsObj[this.taxableYears[1].value]
    );
    this.taxableYearsForm
      .get("year")
      .setValidators([
        Validators.required,
        Validators.max(this.maxYear.text),
        Validators.min(this.minYear.text),
      ]);
    this.taxableYearsForm.get("year").updateValueAndValidity();
    this.taxableYearsForm.valueChanges.subscribe((formValues) => {
      //const { year } = value;
      let findYear = this.taxableYears.find(
        (element) => element.text === formValues.year
      );
      if (findYear != undefined) {
        if (this.prevYear !== findYear.value) {
          this.prevYear = findYear.value;
          this.taxableYearsForm.controls.value.setValue(
            this.taxableYearsObj[findYear.value]
          );
        }
      }
    });
  }

  closeIncome() {
    //Return only years
    this.taxableYears = this.taxableYears.map((element) => {
      return element.value;
    });
    this.dialogRef.close({
      dataset: this.dataset,
      taxableYearsObj: this.taxableYearsObj,
      taxableYears: this.taxableYears,
      taxableYearsObjModified: this.taxableYearsObjModified,
    });
  }

  updateValue() {
    const { year, value } = this.taxableYearsForm.value;
  }

  getYear() {
    const { year: displayYear, value } = this.taxableYearsForm.value;
    const foundYear = this.taxableYears.find(
      (element) => element.text === displayYear
    );
    return foundYear ? foundYear.value : new Date().getFullYear();
  }

  resetValue() {
    const year = this.getYear();
    this.taxableYearsForm.controls.value.setValue(
      this.originalTaxableYearsObj[year]
    );
    this.setTaxableIncomeToYear();
  }

  setTaxableIncomeToYear(firstTime: boolean = false) {
    const { year: displayYear, value } = this.taxableYearsForm.value;
    const foundYear = this.taxableYears.find(
      (element) => element.text === displayYear
    );
    console.log("AÑO encontrado: ", foundYear);
    console.log(
      "MODIFICADO ANTES: ",
      Object.fromEntries(this.taxableYearsObjModified)
    );
    const year = foundYear ? foundYear.value : new Date().getFullYear();
    this.taxableYearsObj[year] = value;
    if (!firstTime) {
      this.taxableYearsObjModified.set(year, value);
    }
    const auxObject: any = {};
    Object.keys(this.taxableYearsObj).forEach((key) => {
      if (this.taxableYearsObj[key] || this.taxableYearsObj[key] === 0) {
        auxObject[key] = this.taxableYearsObj[key];
      }
    });
    const dataset: any = {
      ...this.dataset,
      ...(Object.keys(Object.fromEntries(this.taxableYearsObjModified)).length >
      0
        ? {
            taxableIncomePerYear: Object.fromEntries(
              this.taxableYearsObjModified
            ),
          }
        : {}),
    };
    console.log(
      "Modificado: ",
      Object.fromEntries(this.taxableYearsObjModified)
    );
    const {
      _periods: taxablePeriods,
      ssiTax,
      additionalMedicareTax,
    } = this.rothCalculator.exec(dataset, 1, "multi_year");
    /**
     * To acomplish point 4 of Json coments need to replace year -> age
     */
    this.taxableYears = taxablePeriods.map(
      ({
        taxableIncomeBeforeRMD_table,
        taxableIncomeBeforeRMD_graph,
        taxableIncomeBeforeRMD,
        year,
        age,
      }) => {
        this.taxableYearsObj[year] = taxableIncomeBeforeRMD_graph;
        this.originalTaxableYearsObj[year] = taxableIncomeBeforeRMD;
        var obj = {
          value: year,
          text: age,
        };
        return obj;
      }
    );
    const auxBarChartData = [];
    this.taxableYears = taxablePeriods.map(
      ({
        taxableIncomeBeforeRMD_table,
        taxableIncomeBeforeRMD_graph,
        taxableIncomeBeforeRMD,
        year,
        age,
      }) => {
        this.taxableYearsObj[year] = taxableIncomeBeforeRMD_graph;
        this.originalTaxableYearsObj[year] = taxableIncomeBeforeRMD;
        if (
          parseInt(this.originalTaxableYearsObj[year].toFixed(0)) !==
          parseInt(this.taxableYearsObj[year].toFixed(0))
        ) {
          this.taxableYearsObjModified.set(year, taxableIncomeBeforeRMD_graph);
        }
        if (this.barChartData.length === 0) {
          this.barChartDataLabels.push(age);
        }
        auxBarChartData.push(taxableIncomeBeforeRMD_graph);
        var obj = {
          value: year,
          text: age,
        };
        return obj;
      }
    );

    console.log("BAR CHART: ", auxBarChartData);

    const bothAux = [];
    const auxSSITax = [];
    const auxAddMed = [];
    for (let i = 0; i < ssiTax.length; i++) {
      if (ssiTax[i] === additionalMedicareTax[i]) {
        bothAux.push(0);
        auxSSITax.push(null);
        auxAddMed.push(null);
      } else {
        bothAux.push(null);
        auxSSITax.push(ssiTax[i] !== 0 ? 0 : null);
        auxAddMed.push(additionalMedicareTax[i] ? 0 : null);
      }
    }
    this.barChartData = [
      {
        data: auxBarChartData,
        label: "Income",
        backgroundColor: "#4BAF50",
        hoverBackgroundColor: "#4BAF50",
        borderColor: "#4BAF50",
        hoverBorderColor: "#4BAF50",
        fill: false,
      },
    ];
    setTimeout(() => {
      this.verifyReset();
    }, 100);
  }

  verifyReset() {
    const year = this.getYear();
    console.log(this.taxableYearsObj, this.originalTaxableYearsObj);
    if (
      parseInt(this.originalTaxableYearsObj[year].toFixed(0)) !==
      parseInt(this.taxableYearsObj[year].toFixed(0))
    ) {
      this.resetEnabled = true;
    } else {
      this.resetEnabled = false;
    }
  }

  increaseAge(value) {
    const { year, value: valueFromForm } = this.taxableYearsForm.value;
    if (
      year + value >= this.minYear["text"] &&
      year + value <= this.maxYear["text"]
    ) {
      this.reachLimit = false;
      let newVal = (this.taxableYearsForm.value.year += value);
      this.taxableYearsForm.controls.year.setValue(newVal);
    } else {
      this.reachLimit = true;
    }
    this.verifyReset();
  }
}
