import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as html2pdf from 'html2pdf.js';

import { environment } from '../../../../environments/environment';
import { taxfull_questions } from '../../../views/questionnaires/q-taxfull/taxfull-model';
import { QuestionnaireController } from '../../../views/questionnaires/Questionnaire.controller';
import { AdvisorService } from '../../services/advisor.service';
import { ESettingsService } from '../../services/e-settings.service';

@Component({
  selector: 'tax-pyramid-component',
  templateUrl: 'tax-pyramid.component.html',
})

export class TaxPyramidComponent implements OnInit {

  @Input() isPrintable: boolean;

  public showSpinner: boolean = true;

  public clientData: any;
  public clientMaritalStatus: string;
  public clientName: string;
  public clientLastName: string;
  public clientTaxStats;
  public clientId: any;

  private idClient: string;
  public filingStatus: string = '';

    //Capital gains
  public backgroundCapitalGains0 = {background: "none"}
  public backgroundCapitalGains15 = {background: "none"}
  public backgroundCapitalGains20 = {background: "none"}

  public isDataAvailable: boolean = true;


  public taxRates: any;
  public taxRate2019 = {
    row1:{
      single: '$9,700',
      married: '$19,400'
    },
    row2:{
      single: '$39,475',
      married: '$78,400'
    },
    row3:{
      single: '$84,200',
      married: '$168,400'
    },
    row4:{
      single: '$160,725',
      married: '$321,450'
    },
    row5:{
      single: '$204,100',
      married: '$408,200'
    },
    row6:{
      single: '$510,300 +',
      married: '$612,350 +'
    }
  }

  public taxRate2020 = {
    row1:{
      single: '$9,875',
      married: '$19,750'
    },
    row2:{
      single: '$40,125',
      married: '$80,250'
    },
    row3:{
      single: '$85,525',
      married: '$171,050'
    },
    row4:{
      single: '$163,300',
      married: '$326,600'
    },
    row5:{
      single: '$207,350',
      married: '$414,700'
    },
    row6:{
      single: '$518,400 +',
      married: '$622,050 +'
    }
  }

  public taxRate2021 = {
    row1:{
      single: '$9,950',
      married: '$19,900'
    },
    row2:{
      single: '$40,525',
      married: '$81,050'
    },
    row3:{
      single: '$86,375',
      married: '$172,750'
    },
    row4:{
      single: '$164,925',
      married: '$329,850'
    },
    row5:{
      single: '$209,425',
      married: '$418,850'
    },
    row6:{
      single: '$523,600 +',
      married: '$628,300 +'
    }
  }

  public taxRate2022 = {
    row1:{
      single: '$10,275',
      married: '$20,550'
    },
    row2:{
      single: '$41,775',
      married: '$83,550'
    },
    row3:{
      single: '$89,075',
      married: '$178,150'
    },
    row4:{
      single: '$170,050',
      married: '$340,100'
    },
    row5:{
      single: '$215,950',
      married: '$431,900'
    },
    row6:{
      single: '$539,900 +',
      married: '$647,850 +'
    }
  }

  public taxRate2023 = {
    row1:{
      single: '$11,000',
      married: '$22,000'
    },
    row2:{
      single: '$44,725',
      married: '$89,450'
    },
    row3:{
      single: '$95,375',
      married: '$190,750'
    },
    row4:{
      single: '$182,100',
      married: '$364,200'
    },
    row5:{
      single: '$231,250',
      married: '$462,500'
    },
    row6:{
      single: '$578,100 +',
      married: '$693,750 +'
    }
  }

  public taxRate2024 = {
    row1:{
      single: '$11,600',
      married: '$23,200'
    },
    row2:{
      single: '$47,150',
      married: '$94,300'
    },
    row3:{
      single: '$100,525',
      married: '$201,050'
    },
    row4:{
      single: '$191,950',
      married: '$383,900'
    },
    row5:{
      single: '$243,725',
      married: '$487,450'
    },
    row6:{
      single: '$609,350 +',
      married: '$731,200 +'
    }
  }


  public ordinaryIncome: number;
  public capitalGains: any;
  public progressBarCapitalGainsValue: number;
  public style = {top: "0"};
  public arrowSize = {width: "330px"}
  public colorLevel7 = {background: "#682935"}
  public colorLevel6 = {background: "#682935"}
  public colorLevel5 = {background: "#682935"}
  public colorLevel4 = {background: "#682935"}
  public colorLevel3 = {background: "#682935"}
  public colorLevel2 = {background: "#682935"}
  public colorLevel1 = {background: "#682935"}

  public fullTaxData: taxfull_questions = new taxfull_questions();
  public taxYear: string;
  public indexRow = 1;

  public fullDisclaimerData: any;

  constructor(
            private advisorService: AdvisorService,
            private http: HttpClient,
            public snackBarSuccess: MatSnackBar,
            public snackBarError: MatSnackBar,
            public qController: QuestionnaireController,
            public settingsService: ESettingsService
  ) {
    let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    this.idClient = storagedClientID.clientId;
  }

  ngOnInit() {
    //Seteamos valores para evitar que muera la app
    this.taxRates = this.taxRate2019;

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientName = this.clientData.name;
    this.clientLastName = this.clientData.lastName;
    this.clientId = this.clientData.clientId;

    this.loadTaxStatsData();

    this.settingsService.getCompanyData().toPromise().then(response => {
      this.fullDisclaimerData = response;
    })

  }

  async loadTaxStatsData(){
    setTimeout(async() => {
      await this.advisorService.getTaxStatsAsync(this.idClient).then(data => {
        this.clientTaxStats = data;

        if(this.clientTaxStats.ordinaryIncome == 0){
          //Default values when no taxable income or value 0
          this.ordinaryIncome = 0;
          this.style.top = "78%";
          this.arrowSize.width = "180px";
          this.colorLevel1.background = "#9d1e34";
          this.isDataAvailable = false;
        }else{
          this.ordinaryIncome = this.clientTaxStats.ordinaryIncome;
          if(this.clientMaritalStatus == 'Married' || this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){
            this.isDataAvailable = true;
          }else{
            this.isDataAvailable = false;
          }
        }
        this.capitalGains = this.clientTaxStats.capitalGains.description;
        switch(this.capitalGains){
          case '0':
            this.backgroundCapitalGains0.background = '#9d2034';
            this.progressBarCapitalGainsValue = 0;
            break;
          case '15':
            this.backgroundCapitalGains15.background = '#9d2034';
            this.progressBarCapitalGainsValue = 50;
            break;
          case '20':
            this.backgroundCapitalGains20.background = '#9d2034';
            this.progressBarCapitalGainsValue = 100;
            break;
        }

        this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
        let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

           //Load fulltax questionnaire info
           if(clientHasAnswered.ansFulltax){
            this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'fulltax', this.fullTaxData, 1).then(()=>{
              this.taxYear = this.fullTaxData.clientIDTaxYear;

              //clientIDTaxYear 2019 = taxRates 2020
              if(this.taxYear == '2019'){
                this.taxRates = this.taxRate2019;
                this.calculateTaxablePosition2019(this.ordinaryIncome);
              }
              if(this.taxYear == '2020'){
                this.taxRates = this.taxRate2020;
                this.calculateTaxablePosition2020(this.ordinaryIncome);
              }
              if(this.taxYear == '2021'){
                this.taxRates = this.taxRate2021;
                this.calculateTaxablePosition2021(this.ordinaryIncome);
              }
              if(this.taxYear == '2022'){
                this.taxRates = this.taxRate2022;
                this.calculateTaxablePosition2022(this.ordinaryIncome);
              }
              if(this.taxYear == '2023'){
                this.taxRates = this.taxRate2023;
                this.calculateTaxablePosition2023(this.ordinaryIncome);
              }
              if(this.taxYear == '2024'){
                this.taxRates = this.taxRate2024;
                this.calculateTaxablePosition2024(this.ordinaryIncome);
              }
            });
        }
      });
      this.showSpinner = false;
    }, 1000);

  }

  calculateTaxablePosition2019(tax:number){
    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 510300){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 510300 && tax > 204100){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 204100 && tax > 160725){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 160725 && tax > 84200){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 84200 && tax > 39475){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 39475 && tax >9700){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 612350){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 612350 && tax > 408200){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 408200 && tax > 321450){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 321450 && tax > 168400){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 168400 && tax > 78950){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 78950 && tax >19400){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }

  }

  calculateTaxablePosition2020(tax:number){

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 518400){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 518400 && tax > 207351){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 207351 && tax > 163301){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 163301 && tax > 85525){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 85525 && tax > 40126){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 40126 && tax >9876){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 622051){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 622051 && tax > 414701){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 414701 && tax > 326601){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 326601 && tax > 171051){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 171051 && tax > 80251){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 80251 && tax >19751){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }
  }

  calculateTaxablePosition2021(tax:number){

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 523600){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 523600 && tax > 209426){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 209426 && tax > 164926){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 164926 && tax > 86376){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 86376 && tax > 40526){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 40526 && tax > 9951){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 628301){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 628301 && tax > 418851){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 418851 && tax > 329851){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 329851 && tax > 172751){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 172751 && tax > 81051){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 81051 && tax >19901){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }
  }

  calculateTaxablePosition2022(tax:number){

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 539900){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 539900 && tax > 215950){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 215950 && tax > 170050){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 170050 && tax > 89075){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 89075 && tax > 41775){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 41775 && tax > 10275){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 647850){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 647850 && tax > 431900){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 431900 && tax > 340100){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 340100 && tax > 178150){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 178150 && tax > 83550){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 83550 && tax >20550){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }
  }

   calculateTaxablePosition2023(tax:number){

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 578125){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 578125 && tax > 231250){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 231250 && tax > 182100){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 182100 && tax > 95375){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 95375 && tax > 44725){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 44725 && tax > 11000){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 693750){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 693750 && tax > 462500){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 462500 && tax > 364200){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 364200 && tax > 190750){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 190750 && tax > 89450){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 89450 && tax >22000){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }
  }

  calculateTaxablePosition2024(tax:number){

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){

      //SINGLE POSITION
      if(tax > 609350){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 609350 && tax > 243725){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 243725 && tax > 191950){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 191950 && tax > 100525){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 100525 && tax > 47150){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 47150 && tax > 11600){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }

    }else if(this.clientMaritalStatus == 'Married'){

      //MARRIED POSITION
      if(tax > 731200){
        this.style.top = "-2%";
        this.arrowSize.width = "330px";
        this.colorLevel7.background = "#9d1e34";
        this.indexRow = 7;
      } else if(tax <= 731200 && tax > 487450){
        this.style.top = "13%";
        this.arrowSize.width = "305px";
        this.colorLevel6.background = "#9d1e34";
        this.indexRow = 6;
      } else if(tax <= 487450 && tax > 383900){
        this.style.top = "18%";
        this.arrowSize.width = "280px";
        this.colorLevel5.background = "#9d1e34";
        this.indexRow = 5;
      } else if(tax <= 383900 && tax > 201050){
        this.style.top = "33%";
        this.arrowSize.width = "255px";
        this.colorLevel4.background = "#9d1e34";
        this.indexRow = 4;
      } else if(tax <= 201050 && tax > 94300){
        this.style.top = "48%";
        this.arrowSize.width = "230px";
        this.colorLevel3.background = "#9d1e34";
        this.indexRow = 3;
      } else if(tax <= 94300 && tax >23200){
        this.style.top = "63%";
        this.arrowSize.width = "205px";
        this.colorLevel2.background = "#9d1e34";
        this.indexRow = 2;
      } else {
        this.style.top = "78%";
        this.arrowSize.width = "180px";
        this.colorLevel1.background = "#9d1e34";
        this.indexRow = 1;
      }
    }
  }

  generatePyramidCanvas(adjacentHtml: string = null): HTMLElement {

    const content: Element = document.getElementById('pyramid-container');
    var content2 = <HTMLElement> content.cloneNode(true);

    //Line short width
    let oldWidth = parseInt(this.arrowSize.width.slice(0,3));
    let newWidth = oldWidth - 50;

    let changeColor: NodeListOf<HTMLElement> = content2.querySelectorAll('.pyramid-container');
    for(var i = 0; i<changeColor.length;i++){
      changeColor[i].style.color = 'black';
    }
    let changeColor2: NodeListOf<HTMLElement> = content2.querySelectorAll('.content-progress');
    for(var i = 0; i<changeColor2.length;i++){
      changeColor2[i].style.color = 'black';
    }

    if(this.clientMaritalStatus == 'Single' || this.clientMaritalStatus == 'Divorced' || this.clientMaritalStatus == 'Widowed' || this.clientMaritalStatus == 'Domestic Partner'){
      (<HTMLElement> content2.querySelector('#line-single')).style.background = 'black';
      (<HTMLElement> content2.querySelector('#line-single')).style.width = newWidth+'px';
      (<HTMLElement> content2.querySelector('#point-single')).style.borderLeft = '8px solid black';

    }else{
      (<HTMLElement> content2.querySelector('#line-married')).style.background = 'black';
      (<HTMLElement> content2.querySelector('#line-married')).style.width = newWidth+'px';
      (<HTMLElement> content2.querySelector('#point-married')).style.borderRight = '8px solid black';
    }

    if(this.capitalGains == 0){
      (<HTMLElement> content2.querySelector('#capitalGains-0')).style.color = 'white';
      (<HTMLElement> content2.querySelector('#capitalGains-0')).style.background = '#C93939';
    }else if(this.capitalGains == 15){
      (<HTMLElement> content2.querySelector('#capitalGains-15')).style.color = 'white';
      (<HTMLElement> content2.querySelector('#capitalGains-15')).style.background = '#C93939';
    } else if(this.capitalGains == 20) {
      (<HTMLElement> content2.querySelector('#capitalGains-20')).style.color = 'white';
      (<HTMLElement> content2.querySelector('#capitalGains-20')).style.background = '#C93939';
    }else{
      (<HTMLElement> content2.querySelector('#capitalGains-0')).style.color = 'white';
      (<HTMLElement> content2.querySelector('#capitalGains-0')).style.background = '#C93939';
    }

    //Pinto la linea que divide en color negro
    let lineTax: NodeListOf<HTMLElement> = content2.querySelectorAll('#lineTaxBracket');
    for(var i = 0; i<lineTax.length;i++){
      lineTax[i].style.borderBottom = '1px solid black';
    }

    //Pintamos los rows
    (<HTMLElement> content2.querySelector('#pyramid-level-1')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-2')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-3')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-4')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-5')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-6')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-7')).style.background = 'rgba(201,57,57,0.6)';
    (<HTMLElement> content2.querySelector('#pyramid-level-'+this.indexRow)).style.background = '#C93939';

    if (adjacentHtml) content2.insertAdjacentHTML('afterbegin', adjacentHtml);

    return content2;

  }

  async pdfPyramid(){


    const options = {
      margin: 15,
      filename: 'tax-bracket.pdf',
      image: {type: 'jpeg', quality: 1},
      html2canvas: {allowTaint: false,
                    useCORS: true,
                    scale: 4 },
      jsPDF: {orientation: 'portrait'}
    }

    let logo;
    await this.http.get<any>(`${environment.apiAdvisorLogo}`).toPromise()
    .then(
      success => {
        console.log(success)
        logo = success.logo;
      },
      error => {
        console.log('Error!!!');
      }
    );

    let html = `<div style="height: 200px; display: flex; justify-content: space-between;"> <div> <span style="font-size: 24px; color: #3f5daa;">${this.clientName} ${this.clientLastName} </span><br> <span style="font-size: 24px;">Tax bracket / ${this.clientMaritalStatus}</span> </div> <div> <img style="max-width: 180px; max-height: 50px;" #logoImageId src="${logo}"> </div> </div> <div> <span style="margin: 0px!important; display: flex; font-size: 20px;"> <span class="hexColor" style="margin-top: 5px; background-color: #C93939;"></span> Tax Planning </span> </div>`

    let pyramidCanvas = this.generatePyramidCanvas(html);

      let advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
      let disclaimer = advisorPreferences.disclaimer ? advisorPreferences.disclaimer : 'Disclaimer: Information provided is for educational purposes. Your advisor does not provide tax, legal, or accounting advice. In considering this material, you should discuss your individual circumstances with professionals in those areas before making any decisions. Further, your advisor makes no warranties with regard to such information or a result obtained by its use, and disclaims any liability arising out of your use of, or any tax position taken in reliance on, such information.';

    if (this.fullDisclaimerData.settings.isFullDisclaimerActive){
      const fullDisclaimer = <HTMLCanvasElement> document.getElementById('fullDisclaimer');
      const fullDisclaimer2 = <HTMLCanvasElement> fullDisclaimer.cloneNode(true);
      pyramidCanvas.insertAdjacentElement('beforeend', fullDisclaimer2);
    }

      html2pdf().from(pyramidCanvas).set(options).toPdf().get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        let text = pdf.splitTextToSize(disclaimer,pdf.internal.pageSize.getWidth()+170);

        //Set document fonts
        /*pdf.addFileToVFS('value-sans-regular-pro-normal.ttf', font);
        pdf.addFileToVFS('value-sans-medium-pro-bold.ttf', fontBold);
        pdf.addFont('value-sans-regular-pro-normal.ttf', 'value-sans-regular-pro', 'normal');
        pdf.addFont('value-sans-medium-pro-bold.ttf', 'value-sans-medium-pro', 'normal');*/

        for (let i = 1; i <= totalPages - 1; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setFont('value-sans-medium-pro');
          pdf.setTextColor(100);
          pdf.setLineWidth(100)
          for(let j = 0; j < text.length; j++){
            pdf.text(text[j], 15, pdf.internal.pageSize.getHeight() - (30-((j*3)+15)));
          }

        }
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setFont('value-sans-medium-pro');
          pdf.setTextColor(100);
          pdf.setLineWidth(100);
          pdf.text(i.toString(), pdf.internal.pageSize.getWidth()-15, pdf.internal.pageSize.getHeight() - 15);
        }
      }).save();



  }

}
