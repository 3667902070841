import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { pushIntoArrayIfObjectIsUnique, readFromStoragedObject } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';

import { CalcTypeOneComponent } from './calc-type-one/calc-type-one.component';
import { CALCULATORS_CATALOG } from './Calculators.class';

@Component({
  selector: 'app-calculators',
  templateUrl: './calculators.component.html',
  styleUrls: ['./calculators.component.scss']
})
export class CalculatorsComponent implements OnInit, AfterViewInit {

  @Input() calculatorId: string;
  @Input() scenariosLastData;
  @Output() onLoaded = new EventEmitter<any>();
  @Output() goScenarios = new EventEmitter<Boolean>();

  @ViewChildren(CalcTypeOneComponent) typeOneCalculators: QueryList<CalcTypeOneComponent>


  public loading: Boolean = true;
  public loaded = true;
  public arrowButtons: boolean = true;
  public clientId: string = '';
  public calculatorsComponentList: any[] = [];
  public calculatorResponseInitial: any;
  public overwriteListInitial: any;

  public calculatorsArray: any[] = [
    {name:'Schedule A', id:'scheduleACalculator', title: 'Schedule A: Itemized Deductions Projections', isActive: false},
    {name:'Schedule B', id:'scheduleBCalculator', title: 'Schedule B: Interest and Ordinary Dividends', isActive: false},
    {name:'Schedule C', id:'scheduleCCalculator', title: 'Schedule C: Profit or Loss from Business', isActive: false},
    {name:'Schedule D', id:'scheduleDCalculator', title: 'Schedule D: Capital Gains or Losses', isActive: false},
    {name:'Schedule E', id:'scheduleECalculator', title: 'Schedule E', isActive: false},
    {name:'Schedule 1', id:'scheduleOneCalculator', title: 'Schedule 1: Projections', isActive: false},
    {name:'Schedule 2', id:'scheduleTwoCalculator', title: 'Schedule 2: Additional Taxes', isActive: false},
    {name:'Schedule 3', id:'scheduleThreeCalculator', title: 'Schedule 3: Additional Credits & Payments', isActive: false},
    {name:'QBID Calculator', id:'qbidCalculator', title: 'QBID Calculator', isActive: false},
    {name:'Charitable contributions', id:'charitableContributionCalculator', title: 'Charitable Contributions Calculator', isActive: false},
    {name:'Form 6251: AMT', id:'form6251AMTCalculator', title: 'Form 6251: AMT', isActive: false}
  ]

  public selectCalculator: any = {};
  public calculatorResponse: any = {};
  public overwriteListResponse: any = {};
  public scenarioBase: any = {};
  public agi: number[] = [];
  public taxableIncome: number[] = [];
  public tax: number[] = [];
  public filingStatus: string[] = [];
  public qbiPreDeduction: string[] = [];
  public calcQbid: number = 0;
  public ignoredKeysForOverwriteList: string[] = [];

  constructor(
    public advisorService: AdvisorService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    this.getDataFromBack(this.clientId).then(resp1 => {
      this.getScenarioBase(this.clientId).then(resp2 => {
        this.goToCalculator(this.calculatorId);
      })
    })

    //Add to the keys to ignore all the sections that are not off and editable type
    CALCULATORS_CATALOG.map(scenario => {
      scenario.sections.map(section => {
        this.ignoredKeysForOverwriteList.push(...section.lines.filter(line=> line.isEditable === false && line.assetId != '').map(line => section.id + '.' + line.assetId))
      })
    })


  }

  ngAfterViewInit() {

  }

  /**
   * The function `getDataFromBack` is an asynchronous function that retrieves data from the backend,
   * performs some operations on the response, and then emits an event when the data is loaded.
   * @param {string} clientId - The `clientId` parameter is a string that represents the unique
   * identifier of a client. It is used as a parameter to fetch data from the backend server.
   */
  async getDataFromBack(clientId: string) {
    try {
      this.loading = true;
      let backendResponse = await this.advisorService.getAllCalculators(clientId);
      // obtain data of calculators
      const responseCalculators = backendResponse.calculators;

      // obtain data of overwrite
      this.overwriteListInitial = backendResponse.overwriteList;
      this.overwriteListResponse = JSON.parse(JSON.stringify(backendResponse.overwriteList));

      this.calculatorResponse = responseCalculators;
      // inicia autocompletar los nombres de los line en el escenario base
      if (this.calculatorResponse.scheduleBCalculator[0].scenariosData.length > 1){
        this.calculatorResponse.scheduleBCalculator.forEach(section => {
          section.scenariosData[0].lines.forEach((line, index) => {
            line.assetId = section.scenariosData[1].lines[index].assetId;
          })
        });

        this.calculatorResponse.charitableContributionCalculator.forEach(section => {
          section.scenariosData[0].lines.forEach((line, index) => {
            line.assetId = section.scenariosData[1].lines[index].assetId;
          })
        })
      }
      //termina autocompletar

      this.calculatorResponseInitial = JSON.parse(JSON.stringify(responseCalculators));
      this.onLoaded.emit(this.loaded);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } catch(e) {
      console.log('Error: ', e);
      this.loading = false;
    }
  }


  /**
   * The function `scrollHorizontal` scrolls a container element horizontally based on the value of the
   * `id` parameter.
   * @param {string} id - The `id` parameter is a string that represents the direction to move the screen.
   */
  scrollHorizontal(id: string) {
    const calculatorsNames = document.getElementById('calculatorsNames');
    if (id === 'lastCalculator') {
      calculatorsNames.scrollLeft += 330;
    } else if (id === 'firstCalculator') {
      calculatorsNames.scrollLeft -= 330;
    }
  }

  /**
   * The function "goToCalculator" selects a calculator from an array based on its ID and sets it as
   * the active calculator and open it.
   * @param {string} id - The `id` parameter is a string that represents the unique identifier of a
   * calculator.
   */
  goToCalculator(id: string) {
    this.selectCalculator.isActive = false;
    this.selectCalculator = this.calculatorsArray.find(calc => calc.id === id);
    this.selectCalculator.isActive = true;
  }

  /**
   * The function checks if there is data to save and opens a modal to confirm closing if there is,
   * otherwise it navigates to the scenarios page.
   */
  goToScenarios(){
    if(this.isThereDataToSave()){
      this.openModalConfirmClose();
    }else{
      this.goScenarios.emit(true);
    }
  }

  /**
   * The function `openModalConfirmClose` opens a modal dialog box with a confirmation message and
   * three buttons: "Don't save", "Cancel", and "Save".
   * @returns a reference to the dialog that was opened.
   */
  openModalConfirmClose(){
    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-charitable',
      width: '33vw',
      data:{
        title: '',
        body: `<img width="80" src="https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/00_General/33_Warning.svg">
                <p class="fz-32" style="height: 72px;">Save changes before leaving?</p>
                <br>
                <p class="fp-font fz-24 mb-48" style="height: 78px;">If you don’t save, your changes will be lost. </p>`,
        buttonsContainerStyles: {'justify-content' : 'space-between'},
        actionButtons: [
          {
            text: "Don't save",
            class: 'button-secondary',
            action: () => {
              this.goScenarios.emit(true);
              dialogRef.close();
            }
          },
          {
            text: 'Cancel ',
            class: 'button-secondary',
            action: () => {
              dialogRef.close();
            }
          },
          {
            text: 'Save',
            class: 'button-primary',
            action: () => {
              this.saveAndCalculate().then(response => {
                this.goScenarios.emit(true);
                dialogRef.close();
              })

            }
          }
        ]
      }
      }
    );
    return dialogRef;
  }


/**
 * The function normalizes the data from type one calculators and stores it in the calculator response object.
 */
normalizeTypeOneCalculators(){
  if(this.typeOneCalculators.length > 0){
      this.typeOneCalculators.map(typeOneCalc => {
        if (typeOneCalc.calculatorId === 'scheduleBCalculator' || typeOneCalc.calculatorId === 'charitableContributionCalculator'){
          this.calculatorResponse[typeOneCalc.calculatorId] = typeOneCalc.currentDataToStandarCalcDataTypeB();
          //typeOneCalc.buildTypeB(this.calculatorId);
        }else{
          this.calculatorResponse[typeOneCalc.calculatorId] = typeOneCalc.currentDataToStandarCalcData();
        }
      })
    }
}

  /**
   * The function `saveAndCalculate` is an asynchronous function that saves and calculates data for a
   * calculator, with the option to delete specific elements from the overwrite list.
   * @param [deleteFromOverwrite] - An optional parameter that specifies the element to be deleted from
   * the overwrite list. It is an object with the following properties:
   * @returns a promise that resolves to the response from the
   * `advisorService.calculatorsSaveAndCalculate` method.
   */
  async saveAndCalculate(deleteFromOverwrite?: ({calculatorId: any, sectionId: string, rowId: string, scenarioId: string})){
    //Normalize the current calculator
    this.normalizeTypeOneCalculators();

    let responseInitial = JSON.parse(JSON.stringify(this.calculatorResponseInitial));

    let responseFinal = JSON.parse(JSON.stringify(this.calculatorResponse));
    let overwriteList = {};

    Object.keys(responseInitial).map(calculatorId => {
      overwriteList[calculatorId] = this.compareCalculator(responseInitial[calculatorId], responseFinal[calculatorId], this.ignoredKeysForOverwriteList)
    })

    // Calculate overwriteList. Add previous diferences to the current overwrite list
    Object.keys(overwriteList).map(key => {
      //Put the missing old values inside the current overwrite list
      this.overwriteListInitial[key].map(valueInList => {
        pushIntoArrayIfObjectIsUnique(overwriteList[key], valueInList, 'key');
      })
    })

    // delete elements from overwriteList
    if (deleteFromOverwrite !== undefined){
      let overwriteListScenario = overwriteList[deleteFromOverwrite.calculatorId];
      if (overwriteListScenario !== undefined){
        let overwriteListElementIndex = overwriteListScenario.findIndex(element =>element.key === deleteFromOverwrite.sectionId+'.'+deleteFromOverwrite.scenarioId+'.'+deleteFromOverwrite.rowId);
        if (overwriteListElementIndex >= 0){
          overwriteListScenario.splice(overwriteListElementIndex, 1);
        }
      }
    }

    //Filter elements from the overwrite list to the isEditable
    Object.keys(overwriteList).map(calculatorId => {
      overwriteList[calculatorId] = overwriteList[calculatorId].filter(diffLine => {

        let diffLineSection = diffLine.key.split('.')[0];
        let diffLineAssetId = diffLine.key.split('.')[2];

        let calcInCatalog =  CALCULATORS_CATALOG.find(calcInCatalog => calcInCatalog.id == calculatorId);
        if(calcInCatalog === undefined){ return false; } // If there is no match with the calculator, filter the line

        let section = calcInCatalog.sections.find(calcSection => calcSection.id == diffLineSection);
        if(section === undefined){
          console.warn(`NO SECTION FOUND IN: calculator: ${calculatorId}, section: ${diffLineSection}, line: ${diffLineAssetId}`);
          return false;
        } // If there is no match with the section, filter the line

        let isEditable = section.lines.some(catalogLine => {return catalogLine.assetId == diffLineAssetId && catalogLine.isEditable == true});


        return isEditable;
      })
    })




    return this.advisorService.calculatorsSaveAndCalculate(this.clientId, this.scenariosLastData, responseFinal, overwriteList).then(response => {
      this.getDataFromBack(this.clientId).then(response => {
        if(this.typeOneCalculators.length > 0){
          this.typeOneCalculators.map(typeOneCalc => {
            if (typeOneCalc.calculatorId === 'scheduleBCalculator' || typeOneCalc.calculatorId === 'charitableContributionCalculator'){
              this.calculatorResponse[typeOneCalc.calculatorId] = typeOneCalc.currentDataToStandarCalcDataTypeB();
              typeOneCalc.buildTypeB(this.calculatorId);
            }
          })
        }
        this.getScenarioBase(this.clientId);
      })
    })
  }

  /**
   * The function `getScenarioBase` retrieves projections scenarios for a given client ID, sets the
   * first scenario as the base scenario, and passes the response to another function for further
   * processing.
   * @param {string} clientId - The `clientId` parameter is a string that represents the unique
   * identifier of a client. It is used to retrieve projections scenarios for a specific client.
   */
  async getScenarioBase(clientId: string){
    try {
      let responseScenario = await this.advisorService.getProjectionsScenarios(clientId, 'full')
      this.scenarioBase = responseScenario[0];
      this.getDataToCalculators(responseScenario)
    } catch (e) {
      console.log('Error!: ', e);
    }
  }

  /**
   * The function "getDataToCalculators" extracts specific data from a response object and stores it in
   * different arrays.
   * @param {any} response - The `response` parameter is an array of objects. Each object represents a
   * scenario and contains various sections of data.
   */
  getDataToCalculators(response: any){
    response.map(scenario => {
      let section = scenario.sections.find(section => section.id === 'agi');
      this.agi.push(section.data.agi);

      section = scenario.sections.find(section => section.id === 'deductions');
      this.taxableIncome.push(section.data.taxableIncome);
      this.qbiPreDeduction.push(section.data.qbiPreDeduction);

      section = scenario.sections.find(section => section.id === 'summaryInformation');
      this.filingStatus.push(section.data.filingStatus);

      section = scenario.sections.find(section => section.id === 'taxes');
      this.tax.push(section.data.tax);
    })
  }

  /**
   * Performs a comparison between two natural calculators and return an array of differences. Or empty array if there is no difference
   * @param calcA Natural Calc. Initial: or as reference.
   * @param calcB Natural Calc. Changed: with possible changes vs the initial
   * @param ignoreKeys (['section.key']) Ignore certain keys from the comparison
   * @returns <array>{key: string, value1: any, value2: any}
   */
compareCalculator(calcA: any[], calcB: any[], ignoreKeys: string[] = []){

    let changesList = [];

    calcA.map((sections: any, sectionIndex) => {


      sections['scenariosData'].map( (scenario, scenarioIndex) => {

        Object.keys(scenario).map(scenarioKey => {

          //If the current key is listed under ignoreKeys, ignore current and jump to the next element.
          if(ignoreKeys != undefined && ignoreKeys.length > 0 ){
            let ignoreCurrentKey: boolean = ignoreKeys.some(ignoreKey=>{
              let ignoreKeySplited = ignoreKey.split('.');
              return (sections['sectionId'] == ignoreKeySplited[0] && scenarioKey == ignoreKeySplited[1]);
            });
            if(ignoreCurrentKey){ return }
          }

          let valA = calcA[sectionIndex]['scenariosData'][scenarioIndex][scenarioKey];
          let valB = calcB[sectionIndex]['scenariosData'][scenarioIndex][scenarioKey];

          let difference = {
            key: sections['sectionId'] + "." + scenario['scenarioId'] + '.' + scenarioKey,
            value1: '',
            value2: ''
          }

          if(Array.isArray(valB)){
            let arrayHasChanges: boolean = false;
            // Find if there is a difference between the arrays.

            arrayHasChanges = valB.some((objectInArray, objectIndex) => { return Object.keys(objectInArray).some(objectKey => {
                  return ((valA[objectIndex] == undefined) || valA[objectIndex][objectKey] != valB[objectIndex][objectKey]);
              })
            })

            if(arrayHasChanges){ changesList.push(difference) }

          }else if(typeof(valB) === 'object' && valB !== null){

            let nestedObject = valB;

            Object.keys(nestedObject).map(objectKey => {
              // Compare object props
              if(valA[objectKey] != valB[objectKey]){
                difference.key.concat("." + objectKey);
                difference.value1 = valA[objectKey];
                difference.value2 = valB[objectKey];
                changesList.push(difference);
              }
            })


          }else if(valA != valB){
            difference.value1 = valA;
            difference.value2 = valB;
            changesList.push(difference);
          }
        })
      })
    })

    return changesList;

  }

  /**
   * The function "getNextCalculator" navigates to the next calculator based on the provided ID.
   * @param {string} nextId - The `nextId` parameter is a string that represents the identifier of the
   * next calculator.
   */
  getNextCalculator(nextId: string){
    this.goToCalculator(nextId);
  }

  /**
   * The function checks if there are any changes in the calculator data that need to be saved.
   * @returns a boolean value, indicating whether there is changes.
   */
  isThereDataToSave(): boolean{

    let hasChanges: boolean = false;

    Object.keys(this.calculatorResponseInitial).map(calculatorId => {

      let initialCalculator = this.calculatorResponseInitial[calculatorId];
      //let currentCalculator = this.calculatorToNormalizedResponse(calculatorId);
      this.normalizeTypeOneCalculators();

      let calculatorChanges = this.compareCalculator(initialCalculator, this.calculatorResponse[calculatorId], this.ignoredKeysForOverwriteList);

      hasChanges = hasChanges || calculatorChanges.length > 0;

    });

    return hasChanges;
  }


}

