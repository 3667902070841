import { AfterViewInit, Input } from '@angular/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ClusterAction } from '../estate-snapshot-v3/estate-snapshot-report/estate-snapshot-report.component';
import { getRandomStr } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-cluster-action-wrapper',
  templateUrl: './cluster-action-wrapper.component.html',
  styleUrls: ['./cluster-action-wrapper.component.scss']
})
export class ClusterActionWrapperComponent implements OnInit, ClusterAction {

  @Input() action_tags: string[] = [];
  action_event: EventEmitter<any> = new EventEmitter();
  action_allDone: boolean = false;
  action_uid: string;

  constructor() { }
  finalState: any;

  ngOnInit(): void {
    this.action_uid = getRandomStr(9);
  }

  ngAfterViewInit() {

    setTimeout(()=>{
      this.action_allDone = true;
      this.finalState = this;
      console.log('ClusterActionWrapperComponent emit', this.action_uid);
      this.action_event.emit(true);
    }, 300)

  }

}
