import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-confirm-mapping',
  templateUrl: './confirm-mapping.component.html',
  styleUrls: ['./confirm-mapping.component.scss']
})
export class ConfirmMappingComponent  extends CloseDialogAtLogOut implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<any>, 
    private authService: AuthenticationService) {
    super(dialogRef, authService);
  }

  ngOnInit(): void {
  }

}
