import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaymentDetailDialogComponent } from "app/shared/components/payment-detail-dialog/payment-detail-dialog.component";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AppSocketIoService } from "app/shared/services/socket.io.service";
import { environment } from "environments/environment";
import { saveAs } from "file-saver";

declare var Stripe: any;
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  public companyType: string = "legacy";
  public subscriptionInformation: any = null;
  public showCancelSubscriptionConfirmation: boolean = false;
  public showPlanChangeConfirmation: boolean = false;
  public subscriptionCancelPasswordConfirmation: string = "";
  public subscriptionChangePasswordConfirmation: string = "";
  public stripe: any;
  public stripeElements: any;
  public subscriptionChangeType: string = "";
  public isChangingPaymentMethod = false;
  public paymentMethods: any = [];
  public subscriptionInvoices: any[] = [];
  public viewMode = "tab5";
  public activeView = "invoice";
  public paymentPlan = "";
  public paymentMethodType = "";
  public invoicesForm: FormGroup;
  public cardCvcExists = false;
  public cardExists = false;
  public cardExpiryExists = false;
  public submitting = false;
  public currentMonth: any;

  @ViewChild("cardNumber", { static: true }) cardNumber: ElementRef;
  @ViewChild("cardExpiry", { static: true }) cardExpiry: ElementRef;
  @ViewChild("cardCvc", { static: true }) cardCvc: ElementRef;
  @ViewChild("cardCountry", { static: true }) cardCountry: ElementRef;
  @ViewChild("cardName", { static: true }) cardName: ElementRef;
  @ViewChild("cardZip", { static: true }) cardZip: ElementRef;

  constructor(
    private advisorService: AdvisorService,
    public snackBarError: MatSnackBar,
    public snackBarSuccess: MatSnackBar,
    public formBuilder: FormBuilder,
    private socketService: AppSocketIoService,
    public dialog: MatDialog
  ) {
    this.invoicesForm = this.formBuilder.group({
      invoices: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    //Stripe *** Uncomment when ready ***
    this.getPaymentMethods();
    // this.getSubscriptionInformation();
  }

  get invoices() {
    return this.invoicesForm.get("invoices") as FormArray;
  }

  changeTab(tab) {
    this.viewMode = tab;
  }

  changeView(view) {
    this.activeView = view;
  }

  async getPaymentMethods() {
    const { companyId } = JSON.parse(localStorage.getItem("advisorInfo"));
    const ans: any = await this.advisorService.getCompanyCreditsV2(
      companyId,
      "estate"
    );
    this.companyType = ans.estate.uploadModelType;
    this.advisorService.getPaymentMethod().subscribe((data) => {
      const auxMethods = data.paymentMethods.map((method) => {
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const month = parseInt(method.expMonth, 10);
        const year = parseInt(method.expYear, 10);
        if (
          (currentYear === year && currentMonth > month) ||
          currentYear > year
        ) {
          return {
            ...method,
            disabled: true,
          };
        } else {
          return {
            ...method,
            disabled: false,
          };
        }
      });
      this.paymentMethods = auxMethods;
    });
  }

  onEndOutput({ view, getMethods }) {
    this.changeView(view);
    if (getMethods) {
      this.getPaymentMethods();
    }
  }
}
