import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCleaveDirectiveModule } from "ngx-cleave-directive";
import { MatSliderModule } from "@angular/material/slider";
import { MatChipsModule } from "@angular/material/chips";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatListModule } from "@angular/material/list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatOptionModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedModule } from "../../shared/shared.module";

import { FileUploadModule } from "ng2-file-upload";

import { ColorSketchModule } from "ngx-color/sketch";

import { ESettingsComponent } from "./e.settings.component";
import { ESettingsRoutes } from "./e.settings.routing";
import { SidebarSideSettingsComponent } from "./sidebar-side-settings/sidebar-side-settings.component";
import { PreferencesComponent } from "./preferences/preferences.component";
import { CompanyComponent } from "./company/company.component";
import { RecommendationsComponent } from "./recommendations/recommendations.component";
import { ReportsComponent } from "./reports/reports.component";
import { ProfileComponent } from "./profile/profile.component";
import { SecurityComponent } from "./security/security.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { PaymentComponent } from "./payment/payment.component";
import { ProspectComponent } from "./prospect/prospect.component";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxMaskModule } from "ngx-mask";
import { AddMethodComponent } from "./payment/add-method/add-method/add-method.component";
import { MethodsListComponent } from "./payment/methods-list/methods-list/methods-list.component";
import { InvoicesComponent } from "./payment/invoices/invoices/invoices.component";
import { CreditsSectionComponent } from "./payment/credtis-section/credits-section/credits-section.component";
import { ProviderComponent } from './integrations/views/provider/provider.component';
import { SalesforceHomeComponent } from './integrations/templates/providers/salesforce-integration/salesforce-home/salesforce-home.component';
import { SalesforceInstructionsComponent } from './integrations/templates/providers/salesforce-integration/salesforce-instructions/salesforce-instructions.component';
import { SalesforceMapperComponent } from './integrations/templates/providers/salesforce-integration/salesforce-mapper/salesforce-mapper.component';
import { SalesforceClientsComponent } from './integrations/templates/providers/salesforce-integration/salesforce-clients/salesforce-clients.component';
import { RedtailClientsComponent } from './integrations/templates/providers/redtail-integration/redtail-clients/redtail-clients.component';
import { RedtailInstructionsComponent } from './integrations/templates/providers/redtail-integration/redtail-instructions/redtail-instructions.component';
import { RedtailHomeComponent } from './integrations/templates/providers/redtail-integration/redtail-home/redtail-home.component';
import { WealthboxClientsComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-clients/wealthbox-clients.component';
import { WealthboxHomeComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-home/wealthbox-home.component';
import { WealthboxInstructionsComponent } from './integrations/templates/providers/wealthbox-integration/wealthbox-instructions/wealthbox-instructions.component';
import { MoneyguideInstructionsComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-instructions/moneyguide-instructions.component';
import { MoneyguideHomeComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-home/moneyguide-home.component';
import { MoneyguideClientsComponent } from './integrations/templates/providers/moneyguid-integration/moneyguide-clients/moneyguide-clients.component';
import { RiskalyzeClientsComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-clients/riskalyze-clients.component';
import { RiskalyzeHomeComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-home/riskalyze-home.component';
import { RiskalyzeInstructionsComponent } from './integrations/templates/providers/riskalyze-integration/riskalyze-instructions/riskalyze-instructions.component';
import { PrecisefpInstructionsComponent } from './integrations/templates/providers/precisefp-integration/precisefp-instructions/precisefp-instructions.component';
import { PrecisefpHomeComponent } from './integrations/templates/providers/precisefp-integration/precisefp-home/precisefp-home.component';
import { PrecisefpClientsComponent } from './integrations/templates/providers/precisefp-integration/precisefp-clients/precisefp-clients.component';
import { OrionClientsComponent } from './integrations/templates/providers/orion-integration/orion-clients/orion-clients.component';
import { OrionInstructionsComponent } from './integrations/templates/providers/orion-integration/orion-instructions/orion-instructions.component';
import { OrionHomeComponent } from './integrations/templates/providers/orion-integration/orion-home/orion-home.component';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatMenuModule,
    MatStepperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatGridListModule,
    MatOptionModule,
    MatSelectModule,
    MatPaginatorModule,
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(ESettingsRoutes),
    FileUploadModule,
    NgxCleaveDirectiveModule,
    MatSliderModule,
    MatSlideToggleModule,
    ColorSketchModule,
    NgxMaskModule.forRoot(),
    MatRadioModule,
    MatTableModule,
  ],
  declarations: [
    ESettingsComponent,
    SidebarSideSettingsComponent,
    PreferencesComponent,
    CompanyComponent,
    RecommendationsComponent,
    ReportsComponent,
    ProfileComponent,
    SecurityComponent,
    NotificationsComponent,
    PaymentComponent,
    ProspectComponent,
    AddMethodComponent,
    MethodsListComponent,
    InvoicesComponent,
    CreditsSectionComponent,
    ProviderComponent,
    SalesforceHomeComponent,
    SalesforceInstructionsComponent,
    SalesforceMapperComponent,
    SalesforceClientsComponent,
    RedtailClientsComponent,
    RedtailInstructionsComponent,
    RedtailHomeComponent,
    WealthboxClientsComponent,
    WealthboxHomeComponent,
    WealthboxInstructionsComponent,
    MoneyguideInstructionsComponent,
    MoneyguideHomeComponent,
    MoneyguideClientsComponent,
    RiskalyzeClientsComponent,
    RiskalyzeHomeComponent,
    RiskalyzeInstructionsComponent,
    PrecisefpInstructionsComponent,
    PrecisefpHomeComponent,
    PrecisefpClientsComponent,
    OrionClientsComponent,
    OrionInstructionsComponent,
    OrionHomeComponent,
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ESettingsModule {}
