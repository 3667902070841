import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
    selector: 'dialog-confirm',
    templateUrl: 'showUploadGuide.html',
})
export class showUploadGuideComponent extends CloseDialogAtLogOut{

    constructor(
        public dialogRef: MatDialogRef<showUploadGuideComponent>,
        @Inject(MAT_DIALOG_DATA) public data:string,
        private authService: AuthenticationService
        ) {
          super(dialogRef, authService);
         }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
