import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { general_questions } from '../q-general/general.model';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_LTC_INSURANCE_COMPANIES } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { Mortgage } from './mortgage.model';
import { ClientService } from 'app/shared/services/client/client.service';

@Component({
  selector: 'app-q-mortgage',
  templateUrl: './q-mortgage.component.html'
})
export class QMortgageComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;


  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: Mortgage;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public generalQuestData: general_questions = new general_questions();
  public whoAnswered: string;
  public answers: Array<string>[] = [];
  public CONSTANTS = { CONST_ABSOLUTES, CONST_LTC_INSURANCE_COMPANIES, CONST_ABSOLUTES_UNSURE };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;

  public bulletColor;

  // Used for selects
  subjectProperty = ['Single family house', 'Townhouse', 'Warrantable condominium', 'Non warrantable condominium', 'Duplex', 'Manufactured home', 'Land', 'Commercial'];
  propertyDesignation = ['Primary Residency', 'Second Home', 'Investment Property'];
  bankrupcyChapter = ['Chapter 7', 'Chapter 9', 'Chapter 11', 'Chapter 12', 'Chapter 13', 'Chapter 15'];

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    private currentClientService: ClientService,
    @Inject(WINDOW) private window: Window
  ) { }

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    //Is the first time here? Show modal
    // let isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    // if (isFirstTimeHere === 'mortgage') {
    //     this.dialog.open(DialogFirstTime, {
    //         panelClass: 'modal-dialog-questionnaire',
    //         height: '250px',
    //         width: '500px',
    //     });
    // }

    //Initialize the questionnaire model
    this.model = new Mortgage();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansMortgage != null ? clientHasAnswered.ansMortgage : false;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
      console.log('DATA: ', data);
      if (data?.personalInformation) {
        const answers = data?.personalInformation?.data;
        answers.forEach(({ name, answer }) => {
          this.generalQuestData[name] = answer === 'undefined' || !answer ? '' : answer
        })
      }
    })

    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'mortgage', this.model).then(response => {
        
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {
    //Validate FICO
    this.model.clientIDCreditScore = this.validateFICO(this.model.clientIDCreditScore, -20,20);
    

    //Load general data to model
    this.model.clientIDFirstName = this.generalQuestData.clientIDFirstName;
    this.model.clientIDGender = this.generalQuestData.clientIDGender;
    this.model.clientIDAge = this.generalQuestData.clientIDAge;
    this.model.clientIDLivingState = this.generalQuestData.clientIDLivingState;
    this.model.clientIDHasABusiness = this.generalQuestData.clientIDHasABusiness;
    this.model.clientIDMaritalStatus = this.generalQuestData.clientIDMaritalStatus;
    this.model.clientIDSpouseAge = this.generalQuestData.clientIDSpouseAge;

    this.model.clientIDDebtToIncome = this.model.clientIDDebtToIncome;
    this.model.clientIDBorrowingPercentage = this.model.clientIDBorrowingPercentage;

    if (this.model.clientIDPropertyType === "Land" || this.model.clientIDPropertyType === "Commercial") this.model.clientIDPropertyDesignation = 'Investment Property';

    this.qController.submitForm(this, this.model, this.clientData.clientId, 'mortgage', saveForLater, null, silentSave);

  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  validateFICO(fico:number, increment:number, offset:number){
    return Math.ceil((fico - offset) / increment ) * increment + offset;
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

    let mortgageTypePdf = this.model.clientIDKindOfMortgage.substr(0, this.model.clientIDKindOfMortgage.indexOf(' '));

    this.http.get(`${this.pdfUrl}${'mortgage'}/?options=${mortgageTypePdf}`, options)
    .subscribe(blob => {
      saveAs(blob, 'mortgage-questionnaire.pdf');
    });
  }

}
