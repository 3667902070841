import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';


export const COLUMN_HEIGHT_PX = 380;

@Component({
  selector: 'app-bar-chart-vertical',
  templateUrl: './bar-chart-vertical.component.html',
  styleUrls: ['./bar-chart-vertical.component.scss']
})
export class BarChartVerticalComponent implements OnInit, AfterViewInit {

  COLUMN_HEIGHT_PX = COLUMN_HEIGHT_PX;

  @Input() data: GraphDataInterface | any = null;
  @Input() pdfColor: any;
  @Input() secondaryData: GraphDataInterface | any;
  @Input() special: boolean = false;
  @Input() isLoadingPdf: boolean = false;
  @Input() selected: any;
  @Output() storeSelected: any = new EventEmitter<any>();
  @Input() passedToStore: boolean = false;
  @ViewChild('canvasbarchartvertical',{static:false}) canvases: HTMLCanvasElement;
  public fullCanvas;
  public ticks;
  public leftNote;
  public initialTickLegend;
  public lineIndicator;

  /**
   * internalData.
   * Is a copy for the inputed data, for each change on data a copy is made.
   * Serves to not edit the comon source of the data, in case other components require acces to the orginal data.
   */
  public internalData: GraphDataInterface | any;
  public activatedLeft: string = 'ordinary';

  constructor(
    public http: HttpClient
  ) {
  }

  ngOnInit() {
    if (this.selected) {
      this.activatedLeft = this.selected;
      this.setIndicatorPosition(this.activatedLeft === 'taxable');
    }
  }

  toggleActivated(e: any = null) {
    console.log(e);
    this.activatedLeft = e?.value; 
    console.log(this.activatedLeft);
    this.setIndicatorPosition(this.activatedLeft === 'taxable');
    if (this.passedToStore) {
      this.storeSelected.emit('ordinary')
    }
    sessionStorage.setItem('activatedLeft', this.activatedLeft);
  }

  ngAfterViewInit(){
    this.fullCanvas = <HTMLElement> this.canvases['nativeElement'] as HTMLElement;

    if(this.pdfColor && this.fullCanvas){

      this.ticks = this.fullCanvas.getElementsByClassName('tick') as HTMLCollectionOf<HTMLElement>;
      this.leftNote = this.fullCanvas.getElementsByClassName('left-note') as HTMLCollectionOf<HTMLElement>;
      this.initialTickLegend = this.fullCanvas.getElementsByClassName('initialTickLegend') as HTMLCollectionOf<HTMLElement>;
      this.lineIndicator = this.fullCanvas.getElementsByClassName('line-indicator') as HTMLCollectionOf<HTMLElement>;

      setTimeout(()=>{

        for(var i = 0; i<this.ticks.length; i++){
          this.ticks.item(i).style.setProperty("font-size", "0.875rem", "important");
        }

        for(var i = 0; i<this.leftNote.length; i++){
          this.leftNote.item(i).style.setProperty("font-size", "0.875rem", "important");
          this.leftNote.item(i).style.setProperty("color", "#1B7F22", "important");
        }

        for(var i = 0; i<this.initialTickLegend.length; i++){
          this.initialTickLegend.item(i).style.setProperty("font-size", "0.875rem", "important");
        }

        for(var i = 0; i<this.lineIndicator.length; i++){
          this.lineIndicator.item(i).style.setProperty("background-color", "#1B7F22", "important");
        }

      }, 1000)

    }
    if (this.selected) {
      console.log('Hay un selected', this.selected);
      this.activatedLeft = this.selected;
      this.setIndicatorPosition(this.activatedLeft === 'taxable');
    }
  }

  // All changes to input data will be processed here.
  ngOnChanges(changes: SimpleChanges) {
    if (Boolean(changes.selected) && Boolean(changes?.selected?.currentValue)) {
      this.activatedLeft = changes?.selected?.currentValue;
      this.setIndicatorPosition(this.activatedLeft === 'taxable')
    }
    if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }
    // Copy the incoming data into a new object to not modify the original data source.∫
    this.internalData = JSON.parse(JSON.stringify(changes?.data?.currentValue))
    //this.internalData = JSON.parse(JSON.stringify(changes.data && changes.data.currentValue || {}))

    if(this.pdfColor){
      if(typeof this.internalData.columns !== 'undefined'){
        this.internalData.columns.map(column =>{
          column.sections.map(section=>{
            switch(section.color){
              case '#0259B6': section.color = '#12294B'; break;
              case '#026DDE': section.color = '#3F5DAA'; break;
              case '#218BFD': section.color = '#6580BF'; break;
              case '#5DABFD': section.color = '#8C9BBF'; break;
              case '#9ACAFE': section.color = '#C3C5D6'; break;
              case '#D7EAFF': section.color = '#006494'; break;
            }
          })
        })
      }

      if(typeof this.internalData.labels !== 'undefined'){
        this.internalData.labels.map(label =>{
          switch(label.color){
            case '#0259B6': label.color = '#12294B'; break;
            case '#026DDE': label.color = '#3F5DAA'; break;
            case '#218BFD': label.color = '#6580BF'; break;
            case '#5DABFD': label.color = '#8C9BBF'; break;
            case '#9ACAFE': label.color = '#C3C5D6'; break;
            case '#D7EAFF': label.color = '#006494'; break;
          }
        })
      }

    }


    //If there is no data inside the column, add one full transparent section.
    this.setIndicatorPosition(this.activatedLeft === 'taxable');
  }

  setIndicatorPosition(secondary: boolean = true) {
    let lineIndicatorJumpPosition = 0;
    let lineIndicatorJumpPositionInPixels = null;
    const element = secondary ? 'secondaryData' : 'internalData';
    let isThereHighlight: boolean = false;
    this[element]?.columns?.forEach(column => {
      let cumulativeJump = 0;
      let jumpSize = (COLUMN_HEIGHT_PX / column.sections.length); //Natural size if no section.percentage is given
      if(column.sections.length <= 0){
        column.sections.push({ legend: "", color: "rgba(0,0,0,0.0)", annotation: "" })
      }
      column.sections.map((section, index) => {

        let currentSectionHeightInPx = (section.percentage ? (section.percentage * (COLUMN_HEIGHT_PX / 100)) : jumpSize);

        cumulativeJump += currentSectionHeightInPx;

        if(section.isHighlighted === true){ // If the section has the blue indicator, set the line indicator.
          isThereHighlight = true;
          lineIndicatorJumpPosition = index + 1;
          lineIndicatorJumpPositionInPixels = cumulativeJump - (currentSectionHeightInPx * 0.5);
        }

        //Set font color to white for deductions and others
        if( this[element].id == "charitableContributionDeduction"){
          section.fontColor = '#FFF';
        }

      })

      // Display the indicator only if the data contains a line indicator
      if(this[element]?.lineIndicator && this[element]?.lineIndicator.value >= 0){
        this[element].lineIndicator.value = this[element]?.lineIndicator.percentage ?
            (1 - this[element].lineIndicator.percentage ) * COLUMN_HEIGHT_PX
            : isThereHighlight ? //Si se envio la propiedad highlight
                lineIndicatorJumpPositionInPixels : // Utilizar la posicion calculada del segemento
                (1 - (this[element].lineIndicator.value / this[element].valueTop)) * COLUMN_HEIGHT_PX; //else: Calcular usando el valor de la grafica
      }
    });
    // If the line indicator text is a simple amount( starts with $ and any number postive or negative), adjust the text position by adding a new line
    if(this[element]?.lineIndicator != undefined && /^\$(-)?[\d]+/.test(this[element].lineIndicator.legend)){
      this[element].lineIndicator.legend = `\n${this[element].lineIndicator.legend}`;
    }

    if(this[element]?.rulers.length == 1 && this[element]?.rulers[0] == "" ) this[element].rulers = [];
  }

}

// Interface of the acceptable input data.
class GraphDataInterface {

  componentType?: string;
  title?: string;
  id?: string;
  valueBottom: number;
  valueTop: number;
  lineIndicator?: {value: number, legend: string, percentage?: number} | null;
  rulers: string[];
  sideNote: string;
  noDataToDisplay: boolean;
  labels: { legend: string, color: string }[];
  columns:
    {
      legendBottom: string,
      legendTop?: string | null ,
      sections: { percentage?: number | null, legend: string, color: string, fontColor?: string,  annotation?: string | null, isHighlighted?: boolean }[],
    }[]

}
