import { colorHexToRgb, imageFromUrl, imageToB64FromUrlReport } from '../../shared/helpers/utils';
import { imgBackground } from './images.consts';
import {
  FontStyle,
  getImageFromUrlAndFitToBox,
  ImagePDF,
  Margin,
  PageConfiguration,
  PAPER_SIZES,
  Paragraph,
  PdfCore,
} from './PdfCore.class';
import { CONST_DISCLAIMER } from './stringConstants';
import { TemplateReport } from './Template.class';

const marginDefault = 12.7;
const PX_TO_MM = 0.264583333;
const FONT_FULL_DISCLAIMER_TITLE = new FontStyle('value-sans-medium-pro', [54, 90, 189], 16);
const FONT_FULL_DISCLAIMER_BODY = new FontStyle('value-sans-regular-pro', [0, 0, 0], 11);
export class PreviewReportOutput extends PdfCore {

  public logo: ImagePDF;
  public dateOfCreation: string;
  public readonly blueInitial = [67, 84, 161];
  public fontTitle: FontStyle = new FontStyle('value-sans-medium-pro', this.blueInitial, 18);
  public fontSubTitle: FontStyle = new FontStyle('value-sans-medium-pro', [0, 0, 0], 14);
  public fontStandar: FontStyle = new FontStyle('value-sans-regular-pro', [0, 0, 0], 12);
  public fontStandarBold: FontStyle = new FontStyle('value-sans-medium-pro', [0, 0, 0], 12);
  public fontColumnHead = new FontStyle(this.fontStandar.font, this.blueInitial, this.fontStandar.size);
  public readonly afterTitleSpace = 10.2;
  public readonly afterSubTitleSpace = 5;
  public isLogoLoaded: boolean = false;

  constructor(
    public logoUrl: string = null,
    public logoWidthPx: number = 0,
    public logoHeightPx: number = 0,
    paperSize: number[] = PAPER_SIZES.LETTER,
    margin: Margin = new Margin(marginDefault + 15.2, marginDefault, 43, marginDefault)

  ) {
    super(
      new PageConfiguration(
        paperSize,
        margin
      )
    );

    this.isDebugEnabled = false;

    let nowDate = new Date()
    this.dateOfCreation = `${nowDate.getMonth() + 1}/${nowDate.getDate()}/${nowDate.getFullYear()}`;

    let storagedClient = JSON.parse(sessionStorage.getItem('currentClient'));

  }

  async loadLogo(){

    let logoWidth_mm: number = 41;
    let logoHeight_mm: number = 16;

     await getImageFromUrlAndFitToBox(this.logoUrl, logoWidth_mm, logoHeight_mm).then(logo => {

        this.logo = logo;
        this.logo.xPos = this.pageDimensions.width - (logo.width + this.pageDimensions.margin.right);
        this.logo.yPos = marginDefault - 3;
        this.isLogoLoaded =  true;

      })

  }

  public setHeaderFormat() {
    //if (this.logo && this.isDebugEnabled){ this.doc.setFillColor(28, 42, 73); this.doc.rect(this.logo.xPos, this.logo.yPos, 100, 50, 'F'); }
    if (this.logo) this.addImage(this.logo);
  }

  public setFooterFormat() {

    let fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 8);

    // Draw disclaimer
    let advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    let disclaimer: string = advisorPreferences.disclaimer ? advisorPreferences.disclaimer : CONST_DISCLAIMER;
    this.printParagraphv2(new Paragraph(disclaimer, fontFooter, null, marginDefault, 241.4));

    //Print date of creation
    fontFooter.size = 10;

    this.printParagraphv2(new Paragraph('Date of creation: ' + this.dateOfCreation, fontFooter, null, marginDefault, 261.7));

    // Print page numeration
    this.printParagraphv2(new Paragraph(String(this.getPageCount()), fontFooter, null, this.pageDimensions.width - marginDefault - 3, 261.7));

  }

  public setFirstPageFormat(
    template: TemplateReport,
    clientName: string,
    advisorFullName: string = null,
    reportBackgroundImage: any = null,
    reportColor:string = '#1C2A4A') {

      return new Promise(async(resolve, reject) => {

        if(!this.isLogoLoaded){ await this.loadLogo() }

        // Calculate the optimization level
        let sum = 0;
        let count = 0;
        //

        // let config = {
        //   clientName: clientName,
        //   docName: template.fileName,
        //   timeFrame: template.taskManagerTimeFrameInMonths,
        //   optimizationLevel: Math.round(sum / count),
        //   selectedAreasCount: count,
        //   costAndExpenses: 4978, //Hard coded
        //   assigendTasks: 2 //Hard coded
        // }


        let config = {
          clientName: 'Client Name',
          docName: 'File name',
          timeFrame: 12,
          optimizationLevel: 34,
          selectedAreasCount: 5,
          costAndExpenses: 4978, //Hard coded
          assigendTasks: 2 //Hard coded
        }
        /* START ENTERPRISE SETTINGS FEATURES*/
        //Set background image

        let reportBackgroundImageBase64 = reportBackgroundImage ? await imageToB64FromUrlReport(reportBackgroundImage) : imgBackground;

        //let reportBackgroundImageBase64 = imgBackground;



        //Set color
        let finalReportColor = colorHexToRgb(reportColor);
        /* END ENTERPRISE SETTINGS FEATURES*/

        // Add background color as rectangle
        this.doc.setFillColor(finalReportColor.r, finalReportColor.g, finalReportColor.b);
        //this.doc.setDrawColor(255, 0, 0)
        this.doc.rect(0, 0, this.pageDimensions.width, this.pageDimensions.height, 'F');
        // Add right image
        this.addImage(new ImagePDF(reportBackgroundImageBase64, 158, 0));

        let fontBold = new FontStyle('value-sans-medium-pro', [255, 255, 255], 24);
        let fontTitle = new FontStyle('value-sans-medium-pro', [255, 255, 255], 22);
        let fontDate = new FontStyle('value-sans-regular-pro', [255, 255, 255], 14);
        let fontRegular = new FontStyle('value-sans-regular-pro', [255, 255, 255], 20);

        this.printParagraphv2(new Paragraph(config.clientName, fontBold, 145, 12.7, 126.5));
        this.printParagraphv2(new Paragraph(config.docName, fontTitle, 145, 12.7, 157.5));


        fontRegular.size = 16;
        this.printParagraphv2(new Paragraph('Date of creation: ' + this.dateOfCreation, fontDate, null, 12.7, 185));
        if(advisorFullName){
          this.printParagraphv2(new Paragraph(`Report created by: ${advisorFullName}`, fontDate, null));
        }
        // if (template.isThereAnySelectionRelatedToTaskManager()) {
        //   this.printParagraphv2(new Paragraph('Time frame for task manager: ' + (config.timeFrame > 0 ? config.timeFrame + (config.timeFrame == 1 ? ' month' : ' months') : 'all'), fontDate));
        // }

        if (template.displayOptimizationScore && !isNaN(config.optimizationLevel) ) {
          this.printOptimizationLevel(config.optimizationLevel, config.selectedAreasCount);
        }

        // Print logo
        if (this.logo) this.addImage(this.logo);
        resolve(true);
    })
  }

  public printOptimizationLevel(percentage: number, areasCount: number) {
    let x = 12.7;
    let y = 211;
    let r = 16;

    let fontRegular = new FontStyle('value-sans-regular-pro', [255, 204, 0], 32);
    let fontBold = new FontStyle('value-sans-medium-pro', [255, 255, 255], 14);

    //this.doc.setFillColor(255, 204, 0);
    this.doc.setFillColor(255, 255, 255);
    //this.doc.circle(x + r, y + r, r, 'F');
    this.doc.setFillColor(28, 42, 73);
    //this.doc.circle(x + r, y + r, r * .9, 'F');
    if (this.isDebugEnabled) this.doc.rect(x, y, r * 2, r * 2);
    this.printParagraphv2(new Paragraph(percentage + '%', fontRegular, null, x, y/* (x + r * .5), (y + r * .5) */));

    fontRegular.size = 10
    let areasText = ''
    if(areasCount === 1){ areasText = areasCount + ' area'}else{ areasText = areasCount + ' areas'}
    this.printParagraphv2(new Paragraph(areasText, fontRegular, null, x, y + 12));
    //this.doc.circle(170, 20, 25, 'D')
    this.printParagraphv2(new Paragraph('optimization level', fontBold, null, 47.2, y + 1.8));

  }

  static async generatePDFReportData(
    reportData: any,
    template: any,
    clientName: string,
    advisorFullName: string = null,
    reportBackgroundImage?: any,
    reportColor?: any,
    settingsService?: any): Promise<Blob | null> {

    try {


        let logoB64 = await imageToB64FromUrlReport(reportData.logoCompany);
        let logoImage = await imageFromUrl(reportData.logoCompany);



        let reportPDF = new PreviewReportOutput(reportData.logoCompany, logoImage.width, logoImage.height);


        let areasWithRecReady = JSON.parse(sessionStorage.getItem('areasWithRecommendationsReady'));



        await reportPDF.setFirstPageFormat(template, clientName, advisorFullName, reportBackgroundImage, reportColor);
        reportPDF.addFormattedPage();

      let companyData = await settingsService.getCompanyData().toPromise();
      if (companyData.settings.isFullDisclaimerActive) {
        let fontFooter = new FontStyle('value-sans-medium-pro', [96, 96, 96], 10);
        reportPDF.doc.addPage();
        reportPDF.setHeaderFormat();
        // Print page numeration
        reportPDF.printParagraphv2(new Paragraph(String(reportPDF.getPageCount() + 1), fontFooter, null, reportPDF.pageDimensions.width - marginDefault - 3, 261.7));
        reportPDF.currentPosY = reportPDF.pageDimensions.getCanvasCoordinatesStart().y;
        //
        reportPDF.printParagraphv2(new Paragraph(companyData.fullDisclaimer.title, FONT_FULL_DISCLAIMER_TITLE));
        reportPDF.currentPosY += 1;
        reportPDF.printParagraphv2(new Paragraph(companyData.fullDisclaimer.disclaimer, FONT_FULL_DISCLAIMER_BODY));
      }

        return reportPDF.doc.output('blob')
    } catch (error) {
      console.log('top', 'WARNING. Something went wrong, please contact the support team. We are sorry for the inconvenience');
      console.log('Report Error:', error);
      return null;
    }

  }


}


export interface SpecialComplex {
    canvases: any[];
    title?: string;
    extraData?: any;
}
