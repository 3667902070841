import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EstateSnapshot } from "app/shared/components/estate-snapshot/estate-snapshot.component";
import {
  ENVIRONMENT_URL_PRODUCTION,
  urlHelperIsInEnvironment,
} from "app/shared/helpers/url.helper";
import {
  cleanNameForPDF,
  imageFromUrl,
  imageToB64FromUrl,
  readFromStoragedObject,
} from "app/shared/helpers/utils";
import { AdvisorService } from "app/shared/services/advisor.service";
import { ESettingsService } from "app/shared/services/e-settings.service";
import {
  EstateSnapshotReportTemplate,
  PageData,
} from "app/views/reports/estateSnapshot/EstateSnapshotTemplateReport.class";
import { ImagePDF } from "app/views/reports/PdfCore.class";
import { CONST_DISCLAIMER } from "app/views/reports/stringConstants";
import { environment } from "environments/environment";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import * as html2pdf from "html2pdf.js";

import { AuthenticationService } from "../../../../shared/services/auth/authentication.service";
import { EstateFeaturesModalComponent } from "./estate-features-modal/estate-features-modal.component";
import { EditEstateSnapshotComponent } from "app/views/new-summary/areas/estate/edit-estate-snapshot/edit-estate-snapshot.component";
import { RequestEditSnapshot } from "app/shared/model/Edit-Snapshot.model";

@Component({
  selector: "app-estate",
  templateUrl: "./estate.component.html",
  styleUrls: ["./estate.component.scss"],
})
export class EstateComponent implements OnInit, AfterViewInit {
  public clientID: any;
  public clientData: any = JSON.parse(sessionStorage.getItem("currentClient"));
  public clientDataName: any = this.clientData.name;
  public clientDataLastName: any = this.clientData.lastName;
  public clientDataSpouse: any = this.clientData.spouse;
  public clientDataSpouseLastName: any =
    this.clientData.spouseLastName !== "undefined"
      ? this.clientData.spouseLastName
      : this.clientData.lastName;
  public isLoadingPdf: boolean = false;
  public isSnapshotLoading: boolean = true;
  public clientFullName: string = "";
  public disclaimer: string = "";
  public isChecked: boolean = false;
  public numOfDocuments: number;
  public isEarlyTester: boolean = false;
  public companyDataResponse: any;
  public estateSnapshotVersion: number = 1;
  public status: string = "Not requested";
  public isAdvisorOnTrial: boolean = false;
  public hasBetaAccess: boolean = false;
  public isSummaryReady: boolean = true;
  public isDemoClient: boolean = false;
  public estateSnapshotData: any = {};
  public estateSnapshotStatus: any = {};
  public advisorHasBetaAccess: boolean = false;
  public isVisibleDownloadOptions = false;
  public indexOf = 1;
  public hasFeedbackPermission = false;
  public getDownloadOptions = [
    {
      label: "Download Snapshot",
      value: "pdf",
    },
    {
      label: "Download Extractions",
      value: "xls",
    },
  ];

  @ViewChild("stepper", { static: true }) private stepper: MatStepper;
  @ViewChild("estateSnapshotPrintableRender", { static: true })
  private estateSnapshotPrintableRender: EstateSnapshot;

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthenticationService,
    public snackBar: MatSnackBar,
    private advisorServices: AdvisorService,
    private settingsServive: ESettingsService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    let storagedClient = JSON.parse(sessionStorage.getItem("currentClient"));
    this.clientID = this.authService.isAdvisor()
      ? storagedClient.clientId
      : localStorage.getItem("userId");
    this.clientFullName = Boolean(storagedClient.isMarried === "Married")
      ? Boolean(storagedClient.spouseUsesOwnLastName === "Yes")
        ? storagedClient.name +
          " " +
          storagedClient.lastName +
          " & " +
          storagedClient.spouse +
          " " +
          storagedClient.spouseLastName
        : storagedClient.name +
          " & " +
          storagedClient.spouse +
          " " +
          storagedClient.lastName
      : storagedClient.name + " " + storagedClient.lastName;
    let storagedAdvisor = JSON.parse(
      localStorage.getItem("advisorPreferences")
    );
    this.disclaimer = storagedAdvisor.disclaimer
      ? storagedAdvisor.disclaimer
      : CONST_DISCLAIMER;
    this.isDemoClient =
      Boolean(storagedClient.isPrefilled) &&
      storagedClient.name === "John" &&
      storagedClient.lastName === "Adamson";
    localStorage.getItem("betaAccess").includes("estateSnapshot")
      ? (this.isEarlyTester = true)
      : (this.isEarlyTester = false);

    //Load local storage for betaAccess

    let localStorageBetaAcess = JSON.parse(localStorage.getItem("betaAccess"));
    this.advisorHasBetaAccess =
      localStorageBetaAcess != null &&
      localStorageBetaAcess.includes("estateSnapshotPreview");

    this.advisorServices.getAdvisorCompanyProfile().then((response) => {
      this.companyDataResponse = response;
      if (response.estateSnapshotVersion) {
        this.estateSnapshotVersion = response.estateSnapshotVersion;
      }
    });

    this.advisorServices.getAdvisorDataPromise().then((response) => {
      this.isAdvisorOnTrial =
        response.isOnFreeTrialPeriod != undefined
          ? response.isOnFreeTrialPeriod
          : true;
      this.hasBetaAccess =
        localStorage.getItem("betaAccess").includes("estateSnapshot") ||
        this.clientData.hasEstateDemoAccess;
    });
    this.estateSnapshotPrintableRender.onUpdateStatus.subscribe((status) => {
      this.isSummaryReady = status != "Not requested";
      this.status = status;
    });
    this.getDataEstate();
  }

  async getFeedbackPermission() {
    let first = await this.advisorServices.getEstateSnapshotMetaData(
      this.clientID,
      1
    );
    let second = await this.advisorServices.getEstateSnapshotMetaData(
      this.clientID,
      2
    );

    if (this.clientData.isMarried == "Married") {
      if (first && second && first["aiGenerated"] && second["aiGenerated"]) {
        this.hasFeedbackPermission = true;
      } else {
        this.hasFeedbackPermission = false;
      }
    } else {
      if (first && first["aiGenerated"]) {
        this.hasFeedbackPermission = true;
      } else {
        this.hasFeedbackPermission = false;
      }
    }
  }

  sendReview() {
    let mail = "jmartos@fpalpha.com";
    let cc = "support@fpalpha.com";
    let subject = `AI-generated snapshot feedback for ${this.clientFullName}`;
    //@ts-ignore
    subject = subject.replaceAll("&", "%26");
    let body = "";
    window.location.href = `mailto:${mail}?cc=${cc}&subject=${subject}&body=${body}`;
  }

  editEstateSnapshot() {
    let component = this.dialog.open(EditEstateSnapshotComponent, {
      panelClass: "modal-dialog-black",
      width: "100%",
      disableClose: true,
    });
    component.componentInstance.handleOnSave.subscribe(
      (resp: RequestEditSnapshot) => {
        this.updateSnapshotData(resp)
          .then((resp: RequestEditSnapshot | any) => {
            this.estateSnapshotStatus = "";
            this.estateSnapshotPrintableRender.loadData();
            setTimeout(() => {
              this.estateSnapshotStatus = "Ready";
            }, 100);
            if (resp.message) {
              this.snackBar
                .open(resp.message, "OK", {
                  duration: 2000,
                  panelClass: "success-snackbar",
                })
                .afterDismissed()
                .subscribe(() => {
                  if (resp.changesDenied.length > 0) {
                    resp.changesDenied.forEach((element, i) => {
                      setTimeout(() => {
                        this.snackBar.open(element.message, "Dismiss", {
                          duration: 2000,
                          panelClass: "error-snackbar",
                        });
                      }, 2000 * i);
                    });
                  }
                });
              component.close();
            }
          })
          .catch((arg) => {
            component.close();
            this.snackBar.open(arg.message, "Dismiss", {
              duration: 2000,
              panelClass: "error-snackbar",
            });
          });
      }
    );
    component.afterOpened().subscribe(() => {
      this.loadSnapshotData()
        .then((resp: RequestEditSnapshot) => {
          let editedResponse = this.calculateRequiredProps(resp);
          component.componentInstance.snapshotData = editedResponse;
        })
        .catch((arg) => {
          component.close();
          this.snackBar.open(arg.message, "Dismiss", {
            duration: 2000,
            panelClass: "error-snackbar",
          });
        });
    });
  }

  calculateRequiredProps(obj: RequestEditSnapshot) {
    // If the first value of first/lastName is empty,
    // the field is not required
    obj.familyTree.relationships = obj.familyTree.relationships.map((item) => {
      let obj = item;
      obj.firstNameIsRequired = obj.firstName != "";
      obj.lastNameIsRequired = obj.lastName != "";
      return obj;
    });
    obj.familyOther.relationships = obj.familyOther.relationships.map(
      (item) => {
        let obj = item;
        obj.firstNameIsRequired = obj.firstName != "";
        obj.lastNameIsRequired = obj.lastName != "";
        return obj;
      }
    );
    return obj;
  }

  loadSnapshotData() {
    return this.advisorServices.getEstateSnapshot(this.clientID);
  }

  updateSnapshotData(body) {
    return this.advisorServices.updateEstateSnapshot(this.clientID, body);
  }

  getDownloadText() {
    return this.isSnapshotLoading
      ? "Waiting for data "
      : this.isLoadingPdf
      ? "Downloading..."
      : "Download File";
  }

  toogleDownloadOptions() {
    this.isVisibleDownloadOptions = !this.isVisibleDownloadOptions;
  }

  ngAfterViewInit() {
    this.getNumofDocs();
  }

  updateStatusHandler(event: any) {
    this.status = event;
  }

  async getNumofDocs() {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let resp = await this.http
      .get<any>(`${environment.apiGetSummaryStatus}?idcli=${this.clientID}`, {
        headers,
      })
      .toPromise();
    this.numOfDocuments = resp.documents.length;
    // this.numOfDocuments = 0;
  }

  async downloadPdf() {
    this.isLoadingPdf = true;

    let headers = new HttpHeaders().set("Content-Type", "application/json");
    await this.http
      .get<any>(
        `${environment.apiGetSummary}?idcli=${this.clientID}&index=${
          this.isChecked ? 2 : 1
        }`,
        { headers, responseType: "blob" as "json" }
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `Estate Summary - ${this.isChecked ? "Spouse" : "Advisor"}`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });

    this.isLoadingPdf = false;
  }

  async download99Pdf() {
    this.isLoadingPdf = true;

    const fileName = this.clientFullName + " - 995Act_snapshot";

    const options = {
      margin: [15, 15, 13, 15],
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { allowTaint: false, useCORS: true, scale: 4 },
      pagebreak: {
        after: "#pagebreak",
      },
      jsPDF: { orientation: "portrait" },
    };

    let logo;
    await this.http
      .get<any>(`${environment.apiAdvisorLogo}`)
      .toPromise()
      .then(
        (success) => {
          logo = success.logo;
        },
        (error) => {
          console.log("Error!!!");
        }
      );

    const marginDefault = 12.7;
    const PX_TO_MM = 0.264583333;

    let logoB64 = await imageToB64FromUrl(logo);
    let logoImage = await imageFromUrl(logo);

    let ninetyNineAct = await imageFromUrl(
      "https://storage.googleapis.com/fpalpha-assets/fpalpha_sections/Summary/02_Estate_Planning/Summaryof995ActAsProposed_del.jpg"
    );

    let nLogo;
    nLogo = new ImagePDF(logoB64, 167.2, marginDefault);
    // Set the right size for the logo, scale it to fit the space.
    let realWidth = Math.min(logoImage.width, logoImage.height);
    let realHeight = Math.max(logoImage.width, logoImage.height);
    let scale =
      logoImage.width > 0 && logoImage.height > 0
        ? Math.min(154.9 / logoImage.width, 45.5 /* 38.5 */ / logoImage.height)
        : 1;

    nLogo.width = logoImage.width * PX_TO_MM * scale; //41mm -> 154.9 px
    nLogo.height = logoImage.height * PX_TO_MM * scale; //12.912mm -> 45.5 px

    let canvas = <HTMLElement>document.getElementById("snapshot995");

    canvas.style.display = "block";

    let fontSize: NodeListOf<HTMLElement> = canvas.querySelectorAll(".fz-1");
    for (var i = 0; i < fontSize.length; i++) {
      //fontSize[i].style.setProperty("font-size", "1.25rem", "important");
    }

    let outLabelsFontSize: NodeListOf<HTMLElement> =
      canvas.querySelectorAll(".no-outer-labels");
    for (var i = 0; i < outLabelsFontSize.length; i++) {
      outLabelsFontSize[i].style.setProperty(
        "font-size",
        "1.25rem",
        "important"
      );
    }

    let fontPDF: NodeListOf<HTMLElement> = canvas.querySelectorAll(".font-pdf");
    for (var i = 0; i < fontPDF.length; i++) {
      fontPDF[i].style.setProperty("font-size", "1.25rem", "important");
    }

    let pfontPDFTitle: NodeListOf<HTMLElement> =
      canvas.querySelectorAll(".font-title-pdf");
    for (var i = 0; i < pfontPDFTitle.length; i++) {
      pfontPDFTitle[i].style.setProperty("font-size", "1.5rem", "important");
    }

    let realCanvas = await html2canvas(canvas, { scale: 3 });

    /*html2pdf()
     .from(realCanvas, 'canvas')
     .set(options)
     .save();*/

    let advisorPreferences = JSON.parse(
      localStorage.getItem("advisorPreferences")
    );
    let disclaimer = advisorPreferences.disclaimer
      ? advisorPreferences.disclaimer
      : "Disclaimer: Information provided is for educational purposes. Your advisor does not provide tax, legal, or accounting advice. In considering this material, you should discuss your individual circumstances with professionals in those areas before making any decisions. Further, your advisor makes no warranties with regard to such information or a result obtained by its use, and disclaims any liability arising out of your use of, or any tax position taken in reliance on, such information.";

    html2pdf()
      .from(realCanvas, "canvas")
      .set(options)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();

        let text = pdf.splitTextToSize(
          disclaimer,
          pdf.internal.pageSize.getWidth() + 170
        );

        //Set document fonts
        /*pdf.addFileToVFS('value-sans-regular-pro-normal.ttf', font);
      pdf.addFileToVFS('value-sans-medium-pro-bold.ttf', fontBold);
      pdf.addFont('value-sans-regular-pro-normal.ttf', 'value-sans-regular-pro', 'normal');
      pdf.addFont('value-sans-medium-pro-bold.ttf', 'value-sans-medium-pro', 'normal');*/

        for (let i = 1; i <= 1; i++) {
          pdf.setPage(i);
          pdf.addImage(logoImage, "PNG", 155, 10, nLogo.width, nLogo.height);
          pdf.addImage(ninetyNineAct, "JPEG", 10, 35, 190.72, 190.62);

          pdf.setFontSize(8);
          pdf.setFont("value-sans-medium-pro");
          pdf.setTextColor(100);
          pdf.setLineWidth(100);
          for (let j = 0; j < text.length; j++) {
            pdf.text(
              text[j],
              15,
              pdf.internal.pageSize.getHeight() - (30 - (j * 3 + 15))
            );
          }
        }
      })
      .save();

    canvas.style.display = "none";
    this.isLoadingPdf = false;
  }

  async changePDFIndex() {
    this.isChecked = !this.isChecked;
  }

  goBack() {
    this.router.navigate(["/advisor/summary_new"]);
  }

  download(type: string) {
    if (type == "pdf") {
      this.printEstateSnapshotReport();
    }
    if (type == "xls") {
      this.downloadEstateSnapshotExtractions();
    }
  }

  downloadEstateSnapshotExtractions() {
    this.isVisibleDownloadOptions = false;
    this.isLoadingPdf = true;
    this.advisorServices
      .getXLSReport(this.clientID, this.indexOf)
      .then((arg) => {
        this.isLoadingPdf = false;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(arg);
        link.download =
          "FP Alpha - Estate Snapshot extractions for " +
          this.clientFullName +
          ".xlsx";
        link.click();
      })
      .catch((arg) => {
        this.isLoadingPdf = false;
        this.snackBar.open("Unavailable report", "Ok", {
          duration: 7000,
          panelClass: "error-snackbar",
        });
      });
  }

  async printEstateSnapshotReport() {
    this.isVisibleDownloadOptions = false;
    this.isLoadingPdf = true;
    try {
      //let canvases = await this.estateSnapshotPrintableRender.sectionsToCanvas();
      let pagesData: PageData[] =
        await this.estateSnapshotPrintableRender.responseToPagesData();
      if (pagesData.length == 0) {
        throw "No data to be printed";
      }

      let clientsName = readFromStoragedObject('currentClient', 'fullName', 'Session');

      let companyData = await this.settingsServive.getCompanyData().toPromise();
      let fullPageDisclaimer =
        companyData.settings.isFullDisclaimerActive === true
          ? companyData.fullDisclaimer
          : undefined;

      EstateSnapshotReportTemplate.generateReport({
        clientName: clientsName,
        disclaimer: readFromStoragedObject("advisorPreferences", "disclaimer"),
        logoUrl: this.companyDataResponse.logo,
        //canvases: canvases,
        //canvasesTitle: this.estateSnapshotPrintableRender.getSectionsTitle(),
        resolved: {},
        pagesData: pagesData,
        fullPageDisclaimer,
      }).then((report) => {
        saveAs(
          report.doc.output("blob"),
          `${cleanNameForPDF(clientsName)} - Estate Snapshot Report`
        );
        this.isLoadingPdf = false;
      });
    } catch (error) {
      this.isLoadingPdf = false;
      console.log(error);
      this.snackBar.open("Unavailable report", "Ok", {
        duration: 7000,
        panelClass: "error-snackbar",
      });
    }
  }

  openEstateFeaturesModal() {
    const dialogRef = this.dialog.open(EstateFeaturesModalComponent, {});

    dialogRef.afterClosed().subscribe(() => {});
  }

  async getDataEstate(checkSpouse = false) {
    let index = 1;
    if (checkSpouse) {
      index = 2;
      this.indexOf = 2;
    }
    const resp = await this.advisorServices.getEstateSnapshotStatus(
      this.clientID,
      index
    );
    this.estateSnapshotData = resp;

    if (this.estateSnapshotData.status === "Ready") {
      this.estateSnapshotStatus = "Ready";
      this.getFeedbackPermission();
    } else if (
      this.estateSnapshotData.status === "Pending" ||
      this.estateSnapshotData.status === "In review" ||
      this.estateSnapshotData.status === "Peer review" ||
      this.estateSnapshotData.status === "On Hold"
    ) {
      console.log("PREVIEW STATUS: ", this.estateSnapshotData.previewStatus);
      if (!this.advisorHasBetaAccess) {
        // For old view. Need to set advisorHasBetaAccess to true on line 84
        this.estateSnapshotStatus = "oldPending";
      } else if (this.estateSnapshotData.previewStatus === 3) {
        this.estateSnapshotStatus = "pending3";
      } else if (
        this.estateSnapshotData.previewStatus >= 0 &&
        this.estateSnapshotData.previewStatus < 3
      ) {
        this.estateSnapshotStatus = "pending012";
      } else if (this.estateSnapshotData.previewStatus < 0) {
        this.estateSnapshotStatus = "pending-";
      }
    } else if (this.estateSnapshotData.status === "Not requested") {
      if (index === 1 && this.clientData.isMarried !== "Married") {
        this.estateSnapshotStatus = "Not requested";
      } else if (index === 1 && this.clientData.isMarried === "Married") {
        this.getDataEstate(true);
      } else {
        this.estateSnapshotStatus = "Not requested";
      }
    }

    console.log("ESTADO: ", this.estateSnapshotStatus);
  }

  onSnapshotLoaderChange(eventData) {
    this.isSnapshotLoading = eventData === "true";
    console.log("onSnapshotLoaderChange", eventData, this.isSnapshotLoading);
  }
}
