import { Questionnaire } from '../Questionnaire.model';

export class crossborder_questions extends Questionnaire {

    public clientIDCitizenStatus: string = '';
    public clientIDSpouseCitizenStatus: string = '';
    public clientIDLeaveAssets: string = '';
    public clientIDLifetimeGift: string = '';
    public clientIDGiftJointProp: string = '';
    public clientIDMaritalGift: string = '';
    public clientIDTrustNRA: string = '';
    public clientIDForeignEstate: string = '';
    public clientIDForeignTrust: string = '';
    public clientIDBeneficiaryForeign: string = '';
    public clientIDBenefForeignGrant: string = '';
    public clientIDGrantorForeign: string = '';
    public clientIDDistributeToSpouse: string = '';
    public clientIDCreatedQDOT: string = '';
    public clientIDTimelyQDOT: string = '';
    public clientIDRelinquishTax: string = '';
    public clientIDRelinquishDivorce: string = '';
    public clientIDRelinquishCreditor: string = '';
    public clientIDLitigationCreditor: string = '';
    public clientIDNeedAccess: string = '';
    public clientIDRelocate: string = '';
    public clientIDNRALeaveAssets: string = '';
    public clientIDNRATangibleProp: string = '';
    public clientIDNRARealProp: string = '';
    public clientIDJointPropNRA: string = '';
    public clientIDEndJointNRA: string = '';
    public clientIDNRAStock: string = '';
    public clientIDNRANRAObligor: string = '';
    public clientIDNRACopyrights: string = '';
    public clientIDNRAPartnership: string = '';
    public clientIDNRACharity: string = '';
    public clientIDNRACharitable: string = '';
    public clientIDNRAPropTax: string = '';
    public clientIDNRAToUS: string = '';
    public clientIDUSToNRA: string = '';
    public clientIDRetToNRA: string = '';
    public clientIDLeaveToNRASpouse: string = '';
    public clientIDNRAGiftAsset: string = '';
    public clientIDNRABenefLife: string = '';
    public clientIDGiftLifeToNRA: string = '';
    public clientIDDistributeToNRA: string = '';
    public clientIDLeaveQDOT: string = '';
    public clientIDCharitRemain: string = '';
    public clientIDMoveToUS: string = '';
    public clientIDSurviveBecomeUS: string = '';
    public clientIDRenounceUS: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
      super()
    }

    getQName(): string {
      return 'creditor';
    }

    getIgnoredAttributesForSave():string[]{
      return [''];
    }

}