import { Questionnaire } from '../Questionnaire.model'; 

export class Ltc extends Questionnaire {
    
    public clientIDHasLTCInsurancePolicy: string = '';
    //Manual input 
    public clientIDWantsToInputPolicyDataLTC: string = 'Yes';
    public clientIDHasRecentlyBoughtPolicy: string = '';
    public clientIDspouseInPolicy: string = '';
    public clientIDdailyBenefit: number = 0;
    public clientIDhomeHealthCareAssistedLivingFacilityAndNursingHomeCare: string = '';
    public clientIDExpensereimbursement: string = '';
    public clientIDCashReimbursement: string = '';
    public clientIDPolicyReimbursement: string = '';
    public clientIDbenefitPeriod: number = 0;
    public clientIDeliminationPeriod : number = 0;
    public clientIDshareCareRider: string = '';
    public clientIDLTCInsuranceCompany: string = '';
    public clientIDSpouseName: string = '';
    public clientIDsearchNYPartnership: string = '';
    public clientIDsearchCompoundInflationRider: string = '';
    public clientIDsearchInflationRider: string = '';
    public clientIDsearchMonthlyBenefitRider: string = '';
    public clientIDsearchSurvivorshipRider: string = '';
    //
    public clientIDTypeOfInflationRider: string = '';
    public clientIDsearchTailoredInflationRider: string = '';
    public clientIDsearchStepRatedInflationRider: string = '';
    public clientIDsearchDeferredBenefit: string = '';
    public clientIDsearchWaiverEliminationPeriodRider: string = '';
    //
    public clientIDsearchJointWaiverPremium: string = '';
    public clientIDsearchFullRestoration : string = '';
    // public clientIDsearchGuaranteedPurchase: string = '';

    // Main quest
    public clientIDMarriedOrLivingWithPartnerOrSiblings: string = '';
    public clientIDAverageCostOfNursingPerDay: number = 0;
    public clientIDAnnualPremuimCostVsLevelOfHomeHealthCareBenefits: string = '';
    public clientIDNonProfessionalCareYou: string = '';
    public clientIDLTCAccessAssociation: string = '';
    public clientIDAccessOrPreviousHSA: string = '';
    public clientIDNotAffordPremium: string = '';
    public clientIDHighIncomeNoOngoingPremium: string = '';
    public clientIDFamilyIllnesRecovery: string = '';
    public clientIDSpouseHealthDifference: string = '';
    public clientIDHealthStatus: string = '';
    public clientIDNoticeIncreasePremium: string = '';
    public clientIDLIPCashValue: string = '';
    
    //From general quest and client data
    public clientIDFirstName: string = '';
    public clientIDGender: string = '';
    public clientIDAge: number = 0;
    public clientIDLivingState: string = '';
    //public clientIDHomeInsuranceCompany: string = '';
    public clientIDHasABusiness: string = '';
    public clientIDMaritalStatus: string = '';
    public clientIDSpouseAge: number = 0;

    public clientIDVeteran: string = '';

    //Hybrid 
    public clientIDInsurancePreference: string = '';
    public clientIDLateLifeIncap: string = '';
    public clientIDDifficultAcquireLTC: string = '';
    public clientIDNotNeedLTC: string = '';
    public clientIDLTCLargePmt: string = '';
    public clientIDLTCMultiplePmt: string = '';
    public clientIDConcernIncresPremium: string = '';
    public clientIDConcernCarrier: string = '';
    public clientIDAssetBasedLTC: string = '';

    //Documents
    public clientIDLtcPolicy: string = '';

    //Aset name for multiquest areas
    public clientIDAssetName: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
        super();
    }

    getIgnoredAttributesForSave(){
        return [
            'clientIDLtcPolicy'
        ];
    }

    getAttributesToClearCommas(){
        return [
            'clientIDdailyBenefit',
            'clientIDAverageCostOfNursingPerDay'
        ];
    }

    getQName(): string {
        return 'ltc';
    }
}