import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calc-type-two',
  templateUrl: './calc-type-two.component.html',
  styleUrls: ['./calc-type-two.component.scss']
})
export class CalcTypeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
