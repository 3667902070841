// Angular
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

export interface EndTrialDialogData {
  agreed: boolean;
}

@Component({
  selector: 'end-trial-dialog',
  templateUrl: 'end-trial-dialog.html',
})

export class EndTrialDialog extends CloseDialogAtLogOut implements OnInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  constructor(
    public dialogRef: MatDialogRef<EndTrialDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EndTrialDialogData,
    private authService: AuthenticationService,
  ) {
    super(dialogRef, authService);
   }

  ngOnInit() {}

}
