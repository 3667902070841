// Angular
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'external-providers-interactions-import-modal',
  templateUrl: './integrations-import-modal.component.html'
})

export class IntegrationsImportModalComponent extends CloseDialogAtLogOut implements OnInit, AfterViewInit {

  public dataHasInconsitencies: boolean = false;
  public dataHasRequiredMissingFields: boolean = false;
  public dataClientHasAlreadyBeeingImported: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<IntegrationsImportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private authService: AuthenticationService,
    ) {
      super(dialogRef, authService);
    }

  ngOnInit() {

    this.dataHasInconsitencies = this.data.warnings.some(warning => (warning.inconsistencies && warning.inconsistencies.length > 0));
    this.dataHasRequiredMissingFields = this.data.warnings.some(warning => (warning.requiredMissingFields && warning.requiredMissingFields.length > 0));
    this.dataClientHasAlreadyBeeingImported =  this.data.warnings.some(warning => warning.isImported);

  }

  ngAfterViewInit() {

  }

  importMoreClients(){
    this.dialogRef.close('importMore');
  }

  closeModal(){
    this.dialogRef.close('gotit');
  }

} //@end
