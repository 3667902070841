import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceHubRoutingModule } from './insurance-hub-routing.module';
import { InsuranceHubComponent } from './insurance-hub/insurance-hub.component';
import { InsuranceFormComponent } from './insurance-form/insurance-form.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { SharedModule } from 'app/shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InsuranceRequestComponent } from './insurance-request/insurance-request.component';
import { FormsModule } from '@angular/forms';
import { QuestionnairesModule } from '../questionnaires.module';



@NgModule({
  declarations: [
    InsuranceHubComponent,
    InsuranceFormComponent,
    InsuranceListComponent,
    InsuranceRequestComponent,
  ],
  imports: [
    CommonModule,
    InsuranceHubRoutingModule,
    SharedModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    QuestionnairesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InsuranceHubModule { }

