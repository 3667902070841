export const responseExample = {
  "status": "Ready",
  "client": {
      "version": 4,
      "_id": "65f8b6c3d0b1ebdf9c21e85b",
      "fullName": "Deana Goad"
  },
  "components": [
      {
          "sectionId": "DOCUMENT_LIST",
          "sectionType": "DOCUMENT_LIST",
          "sectionTitle": "Estate Document Summary",
          "data": [
              {
                  "section": "Will",
                  "nameDocument": "Last Will Deana Goad 2012.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "THE DEANA R. GOAD REVOCABLE TRUST DATED JANUARY 11, 1999.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Deana Goad inheritance Trust 2017.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Irrev Trust for Deana life.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Irrev Trust for Deana ross life document.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Irrev Trust for Deana.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "REBECCA ELIZABETH JOHNSON TRUST.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust completee.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust note due 11.2022.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust transfer agreement 2.pdf",
                  "date": "1994-06-22T06:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust transfer agreement 3.pdf",
                  "date": "Unknown",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust transfer agreement 4.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust transfer agreement 5.pdf",
                  "date": "1993-06-22T06:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "The Deana Goad 2012 Dynasty Trust transfer agreement.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1998 CHILDREN'S TRUST holdings.pdf",
                  "date": "1999-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1998 CHILDREN'S TRUST notes.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1998 CHILDREN'S TRUST summary.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1998 CHILDREN'S TRUST.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "POA",
                  "nameDocument": "Deana DURABLE General POWER OF ATTORNEY.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Will",
                  "nameDocument": "Last Will Fred Goad 2012.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Fred DURABLE POWER OF ATTORNEY FOR HEALTH CARE  living will.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Goad Family foundation.pdf",
                  "date": "1994-06-22T06:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "THE FRED C. GOAD, JR. REVOCABLE TRUST DATED JANUARY 11, 1999.pdf",
                  "date": "1992-06-22T06:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "Irrev Trust for Frayne life Document.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "edit"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "Irrev Trust for Frayne life.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "Irrev Trust for Frayne.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "orange beach trust 2.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "orange beach trust.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1998 VESTED TRUST.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1999 GST holdings.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Irrevocable",
                  "nameDocument": "THE FRANYE GOAD JOHNSON 1999 GST.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "POA",
                  "nameDocument": "FRED & DEANA GOAD 2017 TRUST.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Healthcare",
                  "nameDocument": "Fred DURABLE General POWER OF ATTORNEY.pdf",
                  "date": "1996-06-22T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              }
          ]
      },
      {
          "sectionId": "FAMILY_TREE",
          "sectionType": "GRAPH",
          "sectionTitle": "Family Tree",
          "data": {
              "nodes": [
                  {
                      "id": "b4f03556-982f-42a2-8b43-7f17e0b621df",
                      "content": {
                          "name": "Deana R. Goad",
                          "relation": "Client"
                      },
                      "metaData": {
                          "class": "parent"
                      }
                  },
                  {
                      "id": "60716e5c-ded5-4be9-9f10-36628f93922b",
                      "content": {
                          "name": "Fred C. Goad  Jr.",
                          "relation": "Co-Client"
                      },
                      "metaData": {
                          "class": "parent"
                      }
                  },
                  {
                      "id": "61749e70-d5c9-4cf6-ba7d-aa62e591cb4b",
                      "content": {
                          "name": "Francis  Bennett Goad Johnson",
                          "relation": "Child"
                      },
                      "metaData": {
                          "class": "child"
                      }
                  }
              ],
              "connections": [
                  {
                      "id": "65671d7e39bb0b644ad3f623",
                      "sourceNodeId": [
                          "65671d7e39bb0b644ad3f623"
                      ],
                      "nodeConnections": [
                          {
                              "pointsToNodeId": "61749e70-d5c9-4cf6-ba7d-aa62e591cb4b"
                          }
                      ]
                  }
              ],
              "grid": {
                  "rowsNodesById": [
                      [
                          "b4f03556-982f-42a2-8b43-7f17e0b621df",
                          "60716e5c-ded5-4be9-9f10-36628f93922b"
                      ],
                      [
                          "61749e70-d5c9-4cf6-ba7d-aa62e591cb4b"
                      ]
                  ]
              }
          }
      },
      {
          "sectionId": "FAMILY_TREE",
          "sectionType": "GRAPH",
          "sectionTitle": "Grandchildren",
          "data": {
              "nodes": [
                  {
                      "id": "5306508d-5b0c-4513-99df-5f8bf1365142",
                      "content": {
                          "name": "Rebecca  Elizabeth  Johnson",
                          "relation": "Grandchild"
                      },
                      "metaData": {
                          "class": "grandchild"
                      }
                  },
                  {
                      "id": "dc2ee0d1-04c9-48dc-b415-6055840a22cf",
                      "content": {
                          "name": "Sarah Ann Johnson",
                          "relation": "Grandchild"
                      },
                      "metaData": {
                          "class": "grandchild"
                      }
                  },
                  {
                      "id": "3a2b188f-06ac-4668-b3ab-7ad91d42f3cc",
                      "content": {
                          "name": "Curtis Nelson Johnson",
                          "relation": "Grandchild"
                      },
                      "metaData": {
                          "class": "grandchild"
                      }
                  }
              ],
              "connections": [],
              "grid": {
                  "rowsNodesById": [
                      [
                          "5306508d-5b0c-4513-99df-5f8bf1365142",
                          "dc2ee0d1-04c9-48dc-b415-6055840a22cf",
                          "3a2b188f-06ac-4668-b3ab-7ad91d42f3cc"
                      ]
                  ]
              }
          }
      },
      {
          "sectionId": "FAMILY_TREE",
          "sectionType": "GRAPH",
          "sectionTitle": "Other",
          "data": {
              "nodes": [
                  {
                      "id": "45f06326-fa0b-4d4c-8956-bcce37c70d1f",
                      "content": {
                          "name": "Jim  Kever",
                          "relation": "Other"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "aac65cf8-b155-4fa0-a272-48c3804ae2a4",
                      "content": {
                          "name": "Michael Jason Ross",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "b1c6752a-d16f-479b-8e81-82da936a444a",
                      "content": {
                          "name": "Deana Ross Haley",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "9295aa30-079d-41f8-bd39-845f821b4af6",
                      "content": {
                          "name": "John Matthew Pirtle",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "d34a5f63-eccb-43bd-9295-59f681e9625a",
                      "content": {
                          "name": "Joe George Jernigan",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "ac0d59f7-15f8-4438-b42f-6decc7862fd5",
                      "content": {
                          "name": "Hayden Alexander Jernigan",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "ca4b3e29-f780-4119-9bbb-c36de3814e69",
                      "content": {
                          "name": "Dennis A. Voith",
                          "relation": "Other"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "716b6e89-be51-47ce-a78a-97c73868bcf5",
                      "content": {
                          "name": "Jim   Aylward",
                          "relation": "Other"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "64119831-51ab-443d-b77f-9f115bbb68fd",
                      "content": {
                          "name": "Surviving   Grantor",
                          "relation": "Family Member"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  },
                  {
                      "id": "9ff51a2a-d186-407f-bb08-328e53f0b9dd",
                      "content": {
                          "name": "Bartt   McCormack ",
                          "relation": "Other"
                      },
                      "metaData": {
                          "class": "other"
                      }
                  }
              ],
              "connections": [],
              "grid": {
                  "rowsNodesById": [
                      [
                          "45f06326-fa0b-4d4c-8956-bcce37c70d1f",
                          "aac65cf8-b155-4fa0-a272-48c3804ae2a4",
                          "b1c6752a-d16f-479b-8e81-82da936a444a",
                          "9295aa30-079d-41f8-bd39-845f821b4af6",
                          "d34a5f63-eccb-43bd-9295-59f681e9625a",
                          "ac0d59f7-15f8-4438-b42f-6decc7862fd5",
                          "ca4b3e29-f780-4119-9bbb-c36de3814e69",
                          "716b6e89-be51-47ce-a78a-97c73868bcf5",
                          "64119831-51ab-443d-b77f-9f115bbb68fd",
                          "9ff51a2a-d186-407f-bb08-328e53f0b9dd"
                      ]
                  ]
              }
          }
      },
      {
          "sectionId": "FIDUCIARIES:WILL",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - Will",
          "data": [
              {
                  "section": "Will",
                  "type": "Client",
                  "title": "The Deana R. Goad's Will",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Fred C. Goad  Jr."
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever",
                              "Nashville Bank & Trust, Nashville, Tennessee"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Legal Guardians",
                          "classA": "clase-1",
                          "textB": [],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ]
              },
              {
                  "section": "Will",
                  "type": "Co-Client",
                  "title": "The Fred C. Goad  Jr.'s Will",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever",
                              "Francis  Bennett Goad Johnson",
                              "Nashville Bank & Trust, Nashville, Tennessee"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Legal Guardians",
                          "classA": "clase-1",
                          "textB": [],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ]
              }
          ]
      },
      {
          "sectionId": "FIDUCIARIES:TRUST",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - Trust",
          "data": [
              {
                  "section": "Revocable",
                  "type": "Client",
                  "title": "The Deana R. Goad Revocable Trust",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad",
                              "Fred C. Goad  Jr."
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever",
                              "Francis  Bennett Goad Johnson",
                              "Nashville Bank & Trust, Nashville, Tennessee"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Revocable",
                  "type": "Co-Client",
                  "title": "Fred C. Goad, Jr. Revocable Trust",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Co-trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad or Fred C. Goad  Jr. (Survivor may continue as sole trustee)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever",
                              "Francis  Bennett Goad Johnson",
                              "Nashville Bank & Trust, Nashville, Tennessee"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Revocable",
                  "type": "Co-Client",
                  "title": "Fred & Deana Goad 2017 Trust",
                  "titleClass": "",
                  "date": "09/08/2017",
                  "rows": [
                      {
                          "textA": "Co-trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad and Fred C. Goad  Jr. (Survivor may continue as sole trustee)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "The Franye Goad Johnson 1998 Children's Trust",
                  "titleClass": "",
                  "date": "09/01/1998",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim  Aylward"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "The Deana Goad 2012 Dynasty Trust",
                  "titleClass": "",
                  "date": "11/09/2012",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever",
                              "Francis  Bennett Goad Johnson"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "Rebecca Elizabeth Johnson Trust",
                  "titleClass": "",
                  "date": "03/31/1997",
                  "rows": [
                      {
                          "textA": "Co-trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad and Francis  Bennett Goad Johnson"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "Fred C. Goad, Jr. Irrevocable Trust Agreement for The Benefit Of Deana Jane Ross ",
                  "titleClass": "",
                  "date": "03/21/1995",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Dennis A. Voith"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "Deana R. Goad Inheritance Trust",
                  "titleClass": "",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Fred C. Goad  Jr."
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Co-Client",
                  "title": "Fred C. Goad, Jr. Irrevocable Trust Agreement for the Benefit of Frances Bennett Goad Johnson",
                  "titleClass": "",
                  "date": "03/21/1995",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Dennis A. Voith"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Co-Client",
                  "title": "The Franye Goad Johnson 2012 Dynasty Trust",
                  "titleClass": "",
                  "date": "11/09/2012",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Fred C. Goad  Jr."
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Francis  Bennett Goad Johnson",
                              "Jim Kever"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Co-Client",
                  "title": "The Franye Goad Johnson 1998 Vested Trust",
                  "titleClass": "",
                  "date": "09/01/1998",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Co-Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Francis  Bennett Goad Johnson and Nations Bank of Tennessee"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Co-Client",
                  "title": "The FGJ Orange Beach Trust ",
                  "titleClass": "",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Fred C. Goad  Jr."
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "section": "Irrevocable",
                  "type": "Co-Client",
                  "title": "The Franye Goad Johnson 1999 Generation Skipping Trust ",
                  "titleClass": "",
                  "date": "01/11/1999",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Jim  Aylward"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": null
              }
          ]
      },
      {
          "sectionId": "FIDUCIARIES:DIRECTIVES",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - POA and Health Care Directive",
          "data": [
              {
                  "section": "POA",
                  "type": "Client",
                  "title": "Power of Attorney - Deana R. Goad",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Agent's name",
                          "classA": "clase-1",
                          "textB": [
                              " Fred C. Goad Jr. (Page 1, Para 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successive agents",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever (Page 1, Para 2)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Is it springing?",
                          "classA": "clase-1",
                          "textB": [
                              "No"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Can the agent make gift and other tax-related decisions?",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Page 1 Last Para , Page 4 Para 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "TN"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ]
              },
              {
                  "section": "POA",
                  "type": "Co-Client",
                  "title": "Power of Attorney - Fred C. Goad  Jr.",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Agent's name",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad (Page 1, Para 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successive agents",
                          "classA": "clase-1",
                          "textB": [
                              "Jim Kever (Page 1, Para 2)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Is it springing?",
                          "classA": "clase-1",
                          "textB": [
                              "No"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Can the agent make gift and other tax-related decisions?",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Page 1 Last Para , Page 4 Para 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "TN"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ]
              },
              {
                  "section": "POA",
                  "type": "Co-Client",
                  "title": "Health Care Directive - Fred C. Goad  Jr.",
                  "titleClass": "",
                  "date": "09/28/2012",
                  "rows": [
                      {
                          "textA": "Health Care POA Agent",
                          "classA": "clase-1",
                          "textB": [
                              "Deana R. Goad (Page 1, Para 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Health Care POA Successive Agents",
                          "classA": "clase-1",
                          "textB": [
                              "Francis Bennett Goad Johnson (Page 1, Para 2)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "HIPAA Paragraph",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Page 1, Last Para)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "TN"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ]
              }
          ]
      }
  ]
}

export const exampleEstateV2 =
{
  "status": "Ready",
  "client": {
      "version": 4,
      "_id": "663519e1b0ab9ead88390348",
      "fullName": "Franz Gaertner"
  },
  "components": [
      {
          "sectionId": "DOCUMENT_LIST",
          "sectionType": "DOCUMENT_LIST",
          "sectionTitle": "Estate Document Summary",
          "isCollapsed": false,
          "data": [
              {
                  "section": "Will",
                  "nameDocument": "Gaertner, Franz J.  - Will.pdf",
                  "date": "2009-09-23T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Will",
                  "nameDocument": "Gaertner, Franz J. - First Codicil to Will.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Client",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Franz J and Erin C Gaertner Revocable Trust Dated September 23.pdf",
                  "date": "2009-09-23T05:00:00.000Z",
                  "type": "Joint",
                  "icon": "pdf"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Gaertner, Franz J. and Eric C. - Revocable Trust Fourth Amendment.pdf",
                  "date": "Unknown",
                  "type": "Joint",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Gaertner, Franz J. and Erin C. - Brokerage Copy of Revocable Trust.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Joint",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Gaertner, Franz J. and Erin C. - Revocable Trust First Amendment.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Joint",
                  "icon": "edit"
              },
              {
                  "section": "Revocable",
                  "nameDocument": "Gaertner, Franz J. and Erin C. - Revocable Trust Second Amendment.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Joint",
                  "icon": "edit"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Franz J. - DGPOA (Carol).pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Franz J. - DGPOA (Fiduciary Partners Trust Co)..pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Franz J. - DGPOA (spouse).pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Healthcare",
                  "nameDocument": "Gaertner, Franz J. - Advance Directive.pdf",
                  "date": "Unknown",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Healthcare",
                  "nameDocument": "Gaertner, Franz J. - HIPAA Authorization..pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Client",
                  "icon": "pdf"
              },
              {
                  "section": "Will",
                  "nameDocument": "Gaertner, Erin C.  - Will.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Will",
                  "nameDocument": "Gaertner, Erin C. - First Codicil to Will..pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "edit"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Erin C. - DGPOA (Carol).pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Erin C. - DGPOA (Fiduciary Partners Trust Co.) copy.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "POA",
                  "nameDocument": "Gaertner, Erin C. - DGPOA (Fiduciary Partners Trust Co.).pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Healthcare",
                  "nameDocument": "Gaertner, Erin C. - Advance Directive.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              },
              {
                  "section": "Healthcare",
                  "nameDocument": "Gaertner, Erin C. - HIPAA Authorization.pdf",
                  "date": "2019-04-11T05:00:00.000Z",
                  "type": "Co-Client",
                  "icon": "pdf"
              }
          ]
      },
      {
          "sectionId": "FAMILY_TREE",
          "sectionType": "GRAPH",
          "sectionTitle": "Family Tree",
          "isCollapsed": false,
          "data": [
              {
                  "nodes": [
                      {
                          "id": "9a8cf993-1215-48b3-bea2-d94caf9e9bab",
                          "content": {
                              "name": "Franz J. Gaertner",
                              "relation": "Client"
                          },
                          "metaData": {
                              "class": "parent"
                          }
                      },
                      {
                          "id": "e3449679-29af-4f65-b663-eb9d661d20d6",
                          "content": {
                              "name": "Erin C. Gaertner",
                              "relation": "Co-Client"
                          },
                          "metaData": {
                              "class": "parent"
                          }
                      },
                      {
                          "id": "3598411a-efd2-4642-ad09-5f6756d55d2e",
                          "content": {
                              "name": "Collin M. Gaertner",
                              "relation": "Child"
                          },
                          "metaData": {
                              "class": "child"
                          }
                      },
                      {
                          "id": "99e695cd-9b24-4560-8c70-710c0acd3fc7",
                          "content": {
                              "name": "Brandin A. Gaertner",
                              "relation": "Child"
                          },
                          "metaData": {
                              "class": "child"
                          }
                      },
                      {
                          "id": "343713eb-2ab1-4cf8-9c5b-4b20e8c832ee",
                          "content": {
                              "name": "Shoshana R. Buss",
                              "relation": "Child"
                          },
                          "metaData": {
                              "class": "child"
                          }
                      },
                      {
                          "id": "00a184a7-2690-47af-b6f6-bc721c2346e8",
                          "content": {
                              "name": "Spencer T. Buss",
                              "relation": "Child"
                          },
                          "metaData": {
                              "class": "child"
                          }
                      }
                  ],
                  "connections": [
                      {
                          "id": "6539f0e3235d8f044d82db5a",
                          "sourceNodeId": [
                              "6539f0e3235d8f044d82db5a"
                          ],
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "3598411a-efd2-4642-ad09-5f6756d55d2e"
                              },
                              {
                                  "pointsToNodeId": "99e695cd-9b24-4560-8c70-710c0acd3fc7"
                              }
                          ]
                      },
                      {
                          "id": "654499c13e79a702d7eb81b1",
                          "sourceNodeId": [
                              "654499c13e79a702d7eb81b1"
                          ],
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "343713eb-2ab1-4cf8-9c5b-4b20e8c832ee"
                              },
                              {
                                  "pointsToNodeId": "00a184a7-2690-47af-b6f6-bc721c2346e8"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "9a8cf993-1215-48b3-bea2-d94caf9e9bab",
                              "e3449679-29af-4f65-b663-eb9d661d20d6"
                          ],
                          [
                              "3598411a-efd2-4642-ad09-5f6756d55d2e",
                              "99e695cd-9b24-4560-8c70-710c0acd3fc7",
                              "343713eb-2ab1-4cf8-9c5b-4b20e8c832ee",
                              "00a184a7-2690-47af-b6f6-bc721c2346e8"
                          ]
                      ]
                  }
              },
              {
                  "nodes": [
                      {
                          "id": "a884a1b4-9c46-48b3-8e6f-a283a6df6b36",
                          "content": {
                              "name": "Kevin  Gaertner",
                              "relation": "Sibling"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      },
                      {
                          "id": "c8fed8ef-d36b-4e14-aeec-fb21b15fc444",
                          "content": {
                              "name": "Tyler  Caswell",
                              "relation": "Family Member"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      },
                      {
                          "id": "4364aae0-7c49-4fdf-9659-650c4c1698a6",
                          "content": {
                              "name": "Paula  Caswell",
                              "relation": "Family Member"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      },
                      {
                          "id": "9fd746b2-8188-4686-9da6-6d7ae3f32830",
                          "content": {
                              "name": "Carol  Sullivan",
                              "relation": "Other"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      },
                      {
                          "id": "87318963-b65d-4979-bccb-a8a9c8d32f3f",
                          "content": {
                              "name": "Robert  Weiderman",
                              "relation": "Other"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      },
                      {
                          "id": "0521f713-f424-480c-8b8d-a34525a3a1cf",
                          "content": {
                              "name": "Surviving Settlor  ",
                              "relation": "Family Member"
                          },
                          "metaData": {
                              "class": "other"
                          }
                      }
                  ],
                  "connections": [],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "a884a1b4-9c46-48b3-8e6f-a283a6df6b36",
                              "c8fed8ef-d36b-4e14-aeec-fb21b15fc444",
                              "4364aae0-7c49-4fdf-9659-650c4c1698a6",
                              "9fd746b2-8188-4686-9da6-6d7ae3f32830",
                              "87318963-b65d-4979-bccb-a8a9c8d32f3f",
                              "0521f713-f424-480c-8b8d-a34525a3a1cf"
                          ]
                      ]
                  }
              }
          ]
      },
      {
          "sectionId": "FIDUCIARIES:WILL",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - Will",
          "isCollapsed": true,
          "data": [
              {
                  "id": "ccf9c453-cad9-4225-9972-155ea441b56c",
                  "section": "Will",
                  "type": "Client",
                  "title": "The Franz J. Gaertner's Will",
                  "titleClass": "",
                  "date": "01/12/2012",
                  "rows": [
                      {
                          "textA": "Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Erin C. Gaertner"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Executors",
                          "classA": "clase-1",
                          "textB": [
                              "U.S. Bank, N.A. "
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Legal Guardians",
                          "classA": "clase-1",
                          "textB": [
                              "Kevin Gaertner"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ]
              },
              {
                  "id": "56777fb8-db2e-4246-a4b3-fea480091507",
                  "section": "Will",
                  "type": "Co-Client",
                  "title": "The Erin C. Gaertner's Will",
                  "titleClass": "",
                  "date": "01/12/2012",
                  "rows": [
                      {
                          "textA": "Executors",
                          "classA": "clase-1",
                          "textB": [
                              "Franz J. Gaertner"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successor Executors",
                          "classA": "clase-1",
                          "textB": [
                              "U.S. Bank, N.A. "
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Legal Guardians",
                          "classA": "clase-1",
                          "textB": [
                              "Tyler Caswell",
                              "Paula Caswell"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ]
              }
          ]
      },
      {
          "sectionId": "FIDUCIARIES:TRUST",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - Trust",
          "isCollapsed": false,
          "data": [
              {
                  "id": "f67420e5-d67a-4a83-a731-d923c45e069e",
                  "section": "Revocable",
                  "type": "Joint",
                  "title": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                  "titleClass": "",
                  "date": "09/23/2009",
                  "rows": [
                      {
                          "textA": "Co-trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Franz J. Gaertner and Erin C. Gaertner (Survivor may continue as sole trustee)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Successor Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Carol Sullivan",
                              "Robert Weiderman"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "id": "8882812b-7434-42bd-b879-73904fa74fd2",
                  "section": "Revocable",
                  "type": "Joint",
                  "title": "Survivor's Trust",
                  "titleClass": "",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Surviving Settlor"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ],
                  "materialInformation": null
              },
              {
                  "id": "7669c1b4-1a7f-4091-9cd4-16786f1e1525",
                  "section": "Irrevocable",
                  "type": "Client",
                  "title": "Pet Trust",
                  "titleClass": "",
                  "rows": [
                      {
                          "textA": "Trustee",
                          "classA": "clase-1",
                          "textB": [
                              "Spencer T. Buss"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      }
                  ],
                  "materialInformation": null
              }
          ]
      },
      {
          "sectionId": "FIDUCIARIES:DIRECTIVES",
          "sectionType": "FIDUCIARIES",
          "sectionTitle": "Fiduciaries - POA and Health Care Directive",
          "isCollapsed": false,
          "data": [
              {
                  "id": "e760abb0-de75-4bcc-b4e8-cdb33389a8a9",
                  "section": "POA",
                  "type": "Client",
                  "title": "Power of Attorney - Franz J. Gaertner",
                  "titleClass": "",
                  "date": "04/11/2019",
                  "rows": [
                      {
                          "textA": "Agent's name",
                          "classA": "clase-1",
                          "textB": [
                              "Erin C. Gaertner (Page 1, Introduction)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successive agents",
                          "classA": "clase-1",
                          "textB": [
                              "N/A"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Is it springing?",
                          "classA": "clase-1",
                          "textB": [
                              "No"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Can the agent make gift and other tax-related decisions?",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Gifts – Page 2 – Clause 3 – a, Taxes – Page 3 – Clause 4 – d)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "FL"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": "Two additional documents - Durable Power of Attornery naming Carol A. Sullivan and Fiduciary Partners Trust Company respectively have also been provided."
              },
              {
                  "id": "a9e64669-9b2d-4e21-9cf8-cfeb125834ec",
                  "section": "POA",
                  "type": "Co-Client",
                  "title": "Power of Attorney - Erin C. Gaertner",
                  "titleClass": "",
                  "date": "04/11/2019",
                  "rows": [
                      {
                          "textA": "Agent's name",
                          "classA": "clase-1",
                          "textB": [
                              "Franz J. Gaertner (Page 1, Introduction)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Successive agents",
                          "classA": "clase-1",
                          "textB": [
                              "N/A"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "Is it springing?",
                          "classA": "clase-1",
                          "textB": [
                              "No"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Can the agent make gift and other tax-related decisions?",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Gifts – Page 2 – Clause 3 – a, Taxes – Page 3 – Clause 4 – d)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "FL"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": "Two additional documents - Durable Power of Attornery naming Carol A. Sullivan and Fiduciary Partners Trust Company respectively have also been provided."
              },
              {
                  "id": "c6a2978b-3550-465d-8600-2be069f4ba90",
                  "section": "POA",
                  "type": "Client",
                  "title": "Health Care Directive - Franz J. Gaertner",
                  "titleClass": "",
                  "date": "04/11/2019",
                  "rows": [
                      {
                          "textA": "Health Care POA Agent",
                          "classA": "clase-1",
                          "textB": [
                              "Erin C. Gaertner (Page 1, Paragraph 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Health Care POA Successive Agents",
                          "classA": "clase-1",
                          "textB": [
                              "Collin M. Gaertner (Page 1, Paragraph 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "HIPAA Paragraph",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Separate, Document)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "FL"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": "A separate document - 'Authorization for use and disclosure of protected health information' has also been provided."
              },
              {
                  "id": "0b496eed-357f-452f-bc71-e3c0c59e4a96",
                  "section": "POA",
                  "type": "Co-Client",
                  "title": "Health Care Directive - Erin C. Gaertner",
                  "titleClass": "",
                  "date": "04/11/2019",
                  "rows": [
                      {
                          "textA": "Health Care POA Agent",
                          "classA": "clase-1",
                          "textB": [
                              "Franz J. Gaertner (Page 1, Paragraph 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      },
                      {
                          "textA": "Health Care POA Successive Agents",
                          "classA": "clase-1",
                          "textB": [
                              "Shoshana R. Buss (Page 1, Paragraph 1)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "HIPAA Paragraph",
                          "classA": "clase-1",
                          "textB": [
                              "Yes (Separate, Document)"
                          ],
                          "classB": "clase-2",
                          "displayBorder": true
                      },
                      {
                          "textA": "State",
                          "classA": "clase-1",
                          "textB": [
                              "FL"
                          ],
                          "classB": "clase-2",
                          "displayBorder": false
                      }
                  ],
                  "materialInformation": "A separate document - 'Authorization for use and disclosure of protected health information' has also been provided."
              }
          ]
      },
      {
          "sectionId": "OVERVIEW",
          "sectionType": "GRAPH",
          "sectionTitle": "Overview",
          "isCollapsed": false,
          "data": [
              {
                  "nodes": [
                      {
                          "id": "a1b1b00d-1f1c-4a76-9dfc-96c50470d3f2",
                          "content": {
                              "containerClass": " border-accent accent-blue-top content-center blue",
                              "content": [
                                  {
                                      "text": "Franz J. Gaertner's Will",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2,
                              "type": "joint"
                          }
                      },
                      {
                          "id": "97f22822-a970-42ce-adec-3994f344a524",
                          "content": {
                              "containerClass": " border-accent accent-blue-top content-center blue",
                              "content": [
                                  {
                                      "text": "Erin C. Gaertner's Will",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2,
                              "type": "joint"
                          }
                      },
                      {
                          "id": "4347f293-fabc-46c5-a7fb-dc6f0024e181",
                          "content": {
                              "containerClass": " content-center invisible",
                              "content": [
                                  {
                                      "text": ""
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "ef427a8f-6ff2-43c3-bd58-a7d842263062",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "Specific Bequest before any death TEST"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "empty"
                          }
                      },
                      {
                          "id": "b65b09ea-40f3-4a49-b0e8-29d670f75845",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Personal Tangible Property"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "100%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "4a21d645-7894-4864-8e5b-38d358f26816",
                          "content": {
                              "containerClass": " content-center invisible",
                              "content": [
                                  {
                                      "text": ""
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "efa708b7-6880-468f-882d-4b8c42ba1bb3",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "Por que ya esta morido"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "empty"
                          }
                      },
                      {
                          "id": "0aa87e26-40d5-4443-91e0-7e0b7b34ac27",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Residuary Estate"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "100%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "ad8ac7f3-be0c-4956-b6d8-6116838df227",
                          "content": {
                              "containerClass": " border-accent accent-orange-top content-center orange",
                              "content": [
                                  {
                                      "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 3,
                              "type": "trust"
                          }
                      },
                      {
                          "id": "335d7ace-c5c5-4574-ba3c-466ea3b5932b",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Survivor's Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2,
                              "type": "trust"
                          }
                      },
                      {
                          "id": "e981423b-36e5-4767-b643-618f7c79b6f0",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "b7b5c80f-626f-4165-9d20-0d60d63e97ab",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Net Income and Principal"
                                  },
                                  {
                                      "text": "Surviving Settlor ",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "ac7505b6-eb65-4d35-95a1-edbf6c32eea2",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "Upon death of surviving spouse"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "35cad8cb-6f1c-4dcd-b382-87c16b5337ae",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Unappointed Residuary Estate"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Settlor's Grandchildren ",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "6bfdd101-65c0-4503-b3b7-7f6a99b912bf",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Marital Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2,
                              "type": "trust"
                          }
                      },
                      {
                          "id": "607cb1b7-f1bc-4ec4-bbb9-d2ea61506b8f",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "3e250e09-51c3-4e02-95af-db89faad7cdb",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Net Income and Principal"
                                  },
                                  {
                                      "text": "Surviving Settlor ",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "8850b2b8-6f94-4b7f-9f45-b11ebf25980d",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "Upon death of surviving spouse"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "6b46687b-a706-47cc-92bf-403a5bf78b02",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Unappointed Residuary Estate"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Settlor's Grandchildren ",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "cca91ae8-256c-4efa-8456-806486f1cf21",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Family Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2,
                              "type": "trust"
                          }
                      },
                      {
                          "id": "50d2bdef-3299-4348-8a8f-2971ad9cdcf5",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "3c78dcb0-5f4e-46ca-89ed-2ac0df869c4e",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Net Income and Principal"
                                  },
                                  {
                                      "text": "Surviving Settlor ",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "",
                                      "class": "separator"
                                  },
                                  {
                                      "text": "Residuary Estate"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Settlor's Grandchildren ",
                                      "class": "bold",
                                      "textB": [
                                          "20%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "bdcb2fd6-1914-4dc9-aed0-be3f13f0c88a",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "Upon death of surviving spouse"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "9eb1c7ad-4c96-4c51-b302-dd370658130a",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Amount calculated as per the Funding formula for the Pet Trust"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "100%"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      },
                      {
                          "id": "7f4f31ce-fc90-43af-bdab-11096075f521",
                          "content": {
                              "containerClass": " content-center invisible",
                              "content": [
                                  {
                                      "text": ""
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1,
                              "type": "trigger"
                          }
                      },
                      {
                          "id": "1ad42bd4-493e-4f48-ac8e-f910e1ab2dde",
                          "content": {
                              "containerClass": "border-simple content-center",
                              "content": [
                                  {
                                      "text": "During the lifetime of Erin Gaertner"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "empty"
                          }
                      },
                      {
                          "id": "9e0a3eae-2c33-4bfc-894c-98afd5250c57",
                          "content": {
                              "containerClass": "border-none",
                              "content": [
                                  {
                                      "text": "Residuary Estate"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold",
                                      "textB": [
                                          "$50"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold",
                                      "textB": [
                                          "$50"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "type": "beneficiaries"
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "4347f293-fabc-46c5-a7fb-dc6f0024e181",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "b65b09ea-40f3-4a49-b0e8-29d670f75845",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "ef427a8f-6ff2-43c3-bd58-a7d842263062",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "b65b09ea-40f3-4a49-b0e8-29d670f75845"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "4a21d645-7894-4864-8e5b-38d358f26816",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "0aa87e26-40d5-4443-91e0-7e0b7b34ac27",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "efa708b7-6880-468f-882d-4b8c42ba1bb3",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "0aa87e26-40d5-4443-91e0-7e0b7b34ac27"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "b7b5c80f-626f-4165-9d20-0d60d63e97ab",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "e981423b-36e5-4767-b643-618f7c79b6f0",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "b7b5c80f-626f-4165-9d20-0d60d63e97ab"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "35cad8cb-6f1c-4dcd-b382-87c16b5337ae",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "ac7505b6-eb65-4d35-95a1-edbf6c32eea2",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "35cad8cb-6f1c-4dcd-b382-87c16b5337ae"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "335d7ace-c5c5-4574-ba3c-466ea3b5932b",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "e981423b-36e5-4767-b643-618f7c79b6f0"
                              },
                              {
                                  "pointsToNodeId": "ac7505b6-eb65-4d35-95a1-edbf6c32eea2"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "3e250e09-51c3-4e02-95af-db89faad7cdb",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "607cb1b7-f1bc-4ec4-bbb9-d2ea61506b8f",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "3e250e09-51c3-4e02-95af-db89faad7cdb"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "6b46687b-a706-47cc-92bf-403a5bf78b02",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "8850b2b8-6f94-4b7f-9f45-b11ebf25980d",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "6b46687b-a706-47cc-92bf-403a5bf78b02"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "6bfdd101-65c0-4503-b3b7-7f6a99b912bf",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "607cb1b7-f1bc-4ec4-bbb9-d2ea61506b8f"
                              },
                              {
                                  "pointsToNodeId": "8850b2b8-6f94-4b7f-9f45-b11ebf25980d"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "3c78dcb0-5f4e-46ca-89ed-2ac0df869c4e",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "50d2bdef-3299-4348-8a8f-2971ad9cdcf5",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "3c78dcb0-5f4e-46ca-89ed-2ac0df869c4e"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "9eb1c7ad-4c96-4c51-b302-dd370658130a",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "bdcb2fd6-1914-4dc9-aed0-be3f13f0c88a",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "9eb1c7ad-4c96-4c51-b302-dd370658130a"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "cca91ae8-256c-4efa-8456-806486f1cf21",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "50d2bdef-3299-4348-8a8f-2971ad9cdcf5"
                              },
                              {
                                  "pointsToNodeId": "bdcb2fd6-1914-4dc9-aed0-be3f13f0c88a"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "ad8ac7f3-be0c-4956-b6d8-6116838df227",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "335d7ace-c5c5-4574-ba3c-466ea3b5932b"
                              },
                              {
                                  "pointsToNodeId": "6bfdd101-65c0-4503-b3b7-7f6a99b912bf"
                              },
                              {
                                  "pointsToNodeId": "cca91ae8-256c-4efa-8456-806486f1cf21"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "a1b1b00d-1f1c-4a76-9dfc-96c50470d3f2",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "ef427a8f-6ff2-43c3-bd58-a7d842263062",
                                  "anchorOrigin": "L",
                                  "anchorDestination": "T"
                              },
                              {
                                  "pointsToNodeId": "efa708b7-6880-468f-882d-4b8c42ba1bb3",
                                  "anchorOrigin": "L",
                                  "anchorDestination": "T"
                              },
                              {
                                  "pointsToNodeId": "97f22822-a970-42ce-adec-3994f344a524",
                                  "anchorOrigin": "R",
                                  "anchorDestination": "L"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": [
                              "a1b1b00d-1f1c-4a76-9dfc-96c50470d3f2",
                              "97f22822-a970-42ce-adec-3994f344a524"
                          ],
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "ad8ac7f3-be0c-4956-b6d8-6116838df227"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "7f4f31ce-fc90-43af-bdab-11096075f521",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "9e0a3eae-2c33-4bfc-894c-98afd5250c57",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "1ad42bd4-493e-4f48-ac8e-f910e1ab2dde",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "9e0a3eae-2c33-4bfc-894c-98afd5250c57"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "a1b1b00d-1f1c-4a76-9dfc-96c50470d3f2",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "1ad42bd4-493e-4f48-ac8e-f910e1ab2dde",
                                  "anchorOrigin": "R",
                                  "anchorDestination": "T"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "a1b1b00d-1f1c-4a76-9dfc-96c50470d3f2",
                              "97f22822-a970-42ce-adec-3994f344a524"
                          ],
                          [
                              "ad8ac7f3-be0c-4956-b6d8-6116838df227"
                          ],
                          [
                              "4347f293-fabc-46c5-a7fb-dc6f0024e181",
                              "4a21d645-7894-4864-8e5b-38d358f26816",
                              "335d7ace-c5c5-4574-ba3c-466ea3b5932b",
                              "6bfdd101-65c0-4503-b3b7-7f6a99b912bf",
                              "cca91ae8-256c-4efa-8456-806486f1cf21",
                              "7f4f31ce-fc90-43af-bdab-11096075f521"
                          ],
                          [
                              "ef427a8f-6ff2-43c3-bd58-a7d842263062",
                              "efa708b7-6880-468f-882d-4b8c42ba1bb3",
                              "e981423b-36e5-4767-b643-618f7c79b6f0",
                              "ac7505b6-eb65-4d35-95a1-edbf6c32eea2",
                              "607cb1b7-f1bc-4ec4-bbb9-d2ea61506b8f",
                              "8850b2b8-6f94-4b7f-9f45-b11ebf25980d",
                              "50d2bdef-3299-4348-8a8f-2971ad9cdcf5",
                              "bdcb2fd6-1914-4dc9-aed0-be3f13f0c88a",
                              "1ad42bd4-493e-4f48-ac8e-f910e1ab2dde"
                          ],
                          [
                              "b65b09ea-40f3-4a49-b0e8-29d670f75845",
                              "0aa87e26-40d5-4443-91e0-7e0b7b34ac27",
                              "b7b5c80f-626f-4165-9d20-0d60d63e97ab",
                              "35cad8cb-6f1c-4dcd-b382-87c16b5337ae",
                              "3e250e09-51c3-4e02-95af-db89faad7cdb",
                              "6b46687b-a706-47cc-92bf-403a5bf78b02",
                              "3c78dcb0-5f4e-46ca-89ed-2ac0df869c4e",
                              "9eb1c7ad-4c96-4c51-b302-dd370658130a",
                              "9e0a3eae-2c33-4bfc-894c-98afd5250c57"
                          ]
                      ]
                  }
              }
          ]
      },
      {
          "sectionId": "DETAIL:WILL",
          "sectionType": "GRAPH",
          "sectionTitle": "Detail",
          "isCollapsed": false,
          "data": [
              {
                  "dataType": "graph",
                  "nodes": [
                      {
                          "id": "7e8d2d02-0f56-441f-9cfc-cefe613f40d2",
                          "content": {
                              "containerClass": " border-accent accent-blue-top content-center blue",
                              "content": [
                                  {
                                      "text": "Franz J. Gaertner's Will",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2
                          }
                      },
                      {
                          "id": "c3ab6c4f-48e4-490d-85ac-da667ee15d0e",
                          "content": {
                              "containerClass": " border-accent accent-orange-top content-center orange",
                              "content": [
                                  {
                                      "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "c3ab6c4f-48e4-490d-85ac-da667ee15d0e",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "7e8d2d02-0f56-441f-9cfc-cefe613f40d2",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "c3ab6c4f-48e4-490d-85ac-da667ee15d0e"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "7e8d2d02-0f56-441f-9cfc-cefe613f40d2"
                          ],
                          [
                              "c3ab6c4f-48e4-490d-85ac-da667ee15d0e"
                          ]
                      ]
                  }
              },
              {
                  "dataType": "MaterialInfo",
                  "generalStyle": "accent-blue",
                  "title": "Material information",
                  "rows": [
                      "The Last Will and Testament of Franz J. Gaertner was executed on 01/12/2012. The Codicil to the Will was executed on 04/11/2019. Franz has appointed Erin as the Personal Representative, and if she is unable or unwilling to serve for any reason, then he appoints U.S. Bank, N.A. or its successors as the Personal Representative. (Page 1, Article I - A)",
                      "Upon Franz's death, the Personal Representative shall distribute all Tangible Personal Property to Erin, if she survives him. If she does not, then to his children, and Erin's children who survive him to be divided among them as they may agree. Such property shall be distributed after distributions of the same have been made in accordance with a Dispositive Memorandum that may have been left by Franz at his death. (Page 2 of the Codicil, Article III - A)",
                      "Franz gives the residue of his estate to the Trustees of the Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009. If for any reason such distribution of the residue of the estate is ineffective, then he gives the residue to the Personal representative to be held as Trustee of a testamentary trust in accordance with the terms of the Revocable Trust as shall be incorporated in the Will by reference.  (Page 2 of the Codicil, Article III - B)",
                      "The Will also contains provisions for 'Payment of Expenses and Taxes' in Article II."
                  ]
              },
              {
                  "dataType": "graph",
                  "nodes": [
                      {
                          "id": "4a333094-522d-41ea-a24c-f8c3bcffa170",
                          "content": {
                              "containerClass": " border-accent accent-blue-top content-center blue",
                              "content": [
                                  {
                                      "text": "Erin C. Gaertner's Will",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2
                          }
                      },
                      {
                          "id": "97be0a85-2c2e-4f3d-9fb0-cea4faace0ac",
                          "content": {
                              "containerClass": " border-accent accent-orange-top content-center orange",
                              "content": [
                                  {
                                      "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "97be0a85-2c2e-4f3d-9fb0-cea4faace0ac",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "4a333094-522d-41ea-a24c-f8c3bcffa170",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "97be0a85-2c2e-4f3d-9fb0-cea4faace0ac"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "4a333094-522d-41ea-a24c-f8c3bcffa170"
                          ],
                          [
                              "97be0a85-2c2e-4f3d-9fb0-cea4faace0ac"
                          ]
                      ]
                  }
              },
              {
                  "dataType": "MaterialInfo",
                  "generalStyle": "accent-blue",
                  "title": "Material information",
                  "rows": [
                      "The Last Will and Testament of Erin C. Gaertner was executed on 01/12/2012. The Codicil to the Will was executed on 04/11/2019. Erin has appointed Franz as the Personal Representative, and if he is unable or unwilling to serve for any reason, then she appoints U.S. Bank, N.A. or its successors as the Personal Representative. (Page 1, Article I - A)",
                      "Upon Erin's death, the Personal Representative shall distribute all Tangible Personal Property to Franz, if he survives her. If he does not, then to her children, and Franz's children who survive her to be divided among them as they may agree. Such property shall be distributed after distributions of the same have been made in accordance with a Dispositive Memorandum that may have been left by Erin at her death. (Page 2 of the Codicil, Article III - A)",
                      "Erin gives the residue of her estate to the Trustees of the Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009. If for any reason such distribution of the residue of the estate is ineffective, then she gives the residue to the Personal representative to be held as Trustee of a testamentary trust in accordance with the terms of the Revocable Trust as shall be incorporated in the Will by reference.  (Page 2 of the Codicil, Article III - B)",
                      "The Will also contains provisions for 'Payment of Expenses and Taxes' in Article II."
                  ]
              }
          ]
      },
      {
          "sectionId": "DETAIL:TRUST",
          "sectionType": "GRAPH",
          "sectionTitle": "Detail",
          "isCollapsed": false,
          "data": [
              {
                  "dataType": "graph",
                  "nodes": [
                      {
                          "id": "04edbe68-d1f9-42d1-99dc-37d3397fb624",
                          "content": {
                              "containerClass": " annotation accent-orange-top orange",
                              "content": [
                                  {
                                      "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Signed 05/03/242024",
                                      "class": ""
                                  },
                                  {
                                      "text": "Co-trustees",
                                      "class": "",
                                      "textB": [
                                          "Franz J. Gaertner and Erin C. Gaertner (Survivor may continue as sole trustee)"
                                      ],
                                      "classB": "bold"
                                  },
                                  {
                                      "text": "",
                                      "class": "separator"
                                  },
                                  {
                                      "text": "Successor Trustees",
                                      "class": "",
                                      "textB": [
                                          "Carol Sullivan",
                                          "Robert Weiderman"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2
                          }
                      },
                      {
                          "id": "091b2315-5984-462f-91dc-27cd117ead71",
                          "content": {
                              "containerClass": "border-simple border-accent content-center",
                              "content": [
                                  {
                                      "text": "Upon the death of the first Settlor"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "0de9e45f-9a6b-4582-98e5-47c14f827e86",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Survivor's Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "3d5b32ff-1ae4-4547-b01a-bffab28b6ad2",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Marital Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "190a98dc-30c8-416d-a169-e81942f0e3cc",
                          "content": {
                              "containerClass": " border-accent accent-orange-left content-center orange",
                              "content": [
                                  {
                                      "text": "Family Trust",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "0de9e45f-9a6b-4582-98e5-47c14f827e86",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "3d5b32ff-1ae4-4547-b01a-bffab28b6ad2",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "190a98dc-30c8-416d-a169-e81942f0e3cc",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "091b2315-5984-462f-91dc-27cd117ead71",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "0de9e45f-9a6b-4582-98e5-47c14f827e86"
                              },
                              {
                                  "pointsToNodeId": "3d5b32ff-1ae4-4547-b01a-bffab28b6ad2"
                              },
                              {
                                  "pointsToNodeId": "190a98dc-30c8-416d-a169-e81942f0e3cc"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "04edbe68-d1f9-42d1-99dc-37d3397fb624",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "091b2315-5984-462f-91dc-27cd117ead71"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "04edbe68-d1f9-42d1-99dc-37d3397fb624"
                          ],
                          [
                              "091b2315-5984-462f-91dc-27cd117ead71"
                          ],
                          [
                              "0de9e45f-9a6b-4582-98e5-47c14f827e86",
                              "3d5b32ff-1ae4-4547-b01a-bffab28b6ad2",
                              "190a98dc-30c8-416d-a169-e81942f0e3cc"
                          ]
                      ]
                  }
              },
              {
                  "dataType": "MaterialInfo",
                  "generalStyle": "accent-orange",
                  "title": "Material information",
                  "rows": [
                      "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009. It was restated in its entirety on 07/21/2023.  Franz and Erin are the Settlors and the initial Trustees. Collin and Brandin are Franz's children and not the biological or adopted children of Erin C. Gaertner. But for the purposes of this trust, Collin and Brandin will be considered to be the children of Erin. Shoshana and Spencer are Erin's children and not the biological or adopted children of Erin C. Gaertner. But for the purposes of this trust, they will be considered to be the children of Franz. (Page 2-1, Article Two)",
                      "During the Settlor's lifetimes, they may direct the Trustee to distribute as much of the net income and principal as they consider for their unrestricted use and benefit, even to the exhaustion of all trust property. (Page 1-3, Article One - Section 1.05 (d))",
                      "At the time of the death of Erin, the Settlors anticipate that the Trust will own an interest in residential real estate located at 21400 Knighton Run, Estero, Florida 33928. In such case, even if Franz survives Erin, and if at least one of Erin's parents, Erling and Janet Carpenter, is living, the Trustee shall continue to hold the Property in trust so long as Erin's parents, or the survivor of them, desire during their lifetime and have not abandoned the Property. This property shall be governed in accordance with the provisions contained in Article Six - Section 6.01. (Page 6-1, Article Six - Section 6.01)",
                      "When the first Settlor dies, the Trustee shall distribute to the Surviving Settlor outright and free of trust, any real property, including buildings and improvements used by them, as their Florida homestead. The Surviving Settlor may choose to leave said property in the Survivor's Trust at his or her sole and absolute discretion. (Page 6-2, Article Six - Section 6.02)",
                      "When each Settlor dies, they may leave a Memorandum directing the disposition of their Tangible Personal Property. The Trustee shall distribute any of the deceased Settlor's remaining tangible personal property not disposed of by a written memorandum to the Survivor's Trust. If both Settlors are deceased, then the Trustee shall distribute the property outright and free of trust to the beneficiaries named as they agree among themselves. (Page 6-2, Article Six - Section 6.03 and 6.04)",
                      "When the first Settlor dies, the Trustee shall allocate all of the deceased Settlor's remaining trust property to the Marital Share to be disposed of as the Marital Trust and any property disclaimed by the Surviving Settlor to the Non-Marital Share, to be administered as the Family Trust. If the surviving Settlor disclaims his or her interest in any portion of the Non-Marital Share, the Trustee shall dispose of the disclaimed interest as though the surviving Settlor had predeceased the deceased Settlor. (Page 7-1, Article Seven - Section 7.01 and 7.02)",
                      "The Trust document also contains provisions for : 'Remote Contingent Distribution' in Article Thirteen; 'Distributions to Underage and Incapacitated Beneficiaries' in Article Fourteen; 'Retirement Plans and Life Insurance Policies' in Article Fifteen; 'Maximum Term for Trusts' in Article Eighteen - Section 18.01; 'Spendthrift Provision' in Article Eighteen - Section 18.02; and 'Survivorship Presumption' in Article Eighteen - Section 18.03.\nSeparate documents have been provided - 'Declaration naming Preneed Guardian' and 'Warranty Deed'."
                  ]
              },
              {
                  "dataType": "graph",
                  "nodes": [
                      {
                          "id": "417cb6bd-48f0-4882-8442-b048e28336ef",
                          "content": {
                              "containerClass": " annotation accent-orange-top orange",
                              "content": [
                                  {
                                      "text": "Survivor's Trust",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Signed 05/03/242024",
                                      "class": ""
                                  },
                                  {
                                      "text": "Trustees",
                                      "class": "",
                                      "textB": [
                                          "Surviving Settlor"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2
                          }
                      },
                      {
                          "id": "ce2ad976-7738-4601-8e86-40f9f2ffa1f6",
                          "content": {
                              "containerClass": " border-accent content-center invisible",
                              "content": [
                                  {
                                      "text": ""
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "5020f1b4-e360-4633-9c05-b3c24dbe7b37",
                          "content": {
                              "containerClass": "border-simple border-accent content-center",
                              "content": [
                                  {
                                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "449c44f7-551b-4f8e-8eb1-ab743139e97a",
                          "content": {
                              "containerClass": "border-none border-accent",
                              "content": [
                                  {
                                      "text": "Net Income and Principal  - All of the net income at least quarter annually and as much of the principal as he or she requests and as much of the principal as the Trustee determines necessary [DURING THE SURVIVING SETTLOR'S LIFETIME]"
                                  },
                                  {
                                      "text": "Surviving Settlor ",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "1c5dc559-8e5c-4f55-9759-189b7982cbea",
                          "content": {
                              "containerClass": " border-accent content-center invisible",
                              "content": [
                                  {
                                      "text": ""
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "f6b74f15-cbfc-441c-aa84-c0aab4ae76c0",
                          "content": {
                              "containerClass": "border-simple border-accent content-center",
                              "content": [
                                  {
                                      "text": "Upon death of surviving spouse"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      },
                      {
                          "id": "f4c91cea-f698-48cb-b56b-573069ead75e",
                          "content": {
                              "containerClass": "border-none border-accent",
                              "content": [
                                  {
                                      "text": "Unappointed Residuary Estate 20% - (Share to be held in a separate Trust Share) [UPON THE DEATH OF THE SURVIVING SETTLOR]"
                                  },
                                  {
                                      "text": "Shoshana R. Buss",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Collin M. Gaertner",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Spencer T. Buss",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Brandin A. Gaertner",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "",
                                      "class": "separator"
                                  },
                                  {
                                      "text": "Unappointed Residuary Estate 20% - [UPON THE DEATH OF THE SURVIVING SETTLOR]"
                                  },
                                  {
                                      "text": "Settlor's Grandchildren ",
                                      "class": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 1
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "ce2ad976-7738-4601-8e86-40f9f2ffa1f6",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "449c44f7-551b-4f8e-8eb1-ab743139e97a",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "5020f1b4-e360-4633-9c05-b3c24dbe7b37",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "449c44f7-551b-4f8e-8eb1-ab743139e97a"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "1c5dc559-8e5c-4f55-9759-189b7982cbea",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "f4c91cea-f698-48cb-b56b-573069ead75e",
                          "nodeConnections": []
                      },
                      {
                          "sourceNodeId": "f6b74f15-cbfc-441c-aa84-c0aab4ae76c0",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "f4c91cea-f698-48cb-b56b-573069ead75e"
                              }
                          ]
                      },
                      {
                          "sourceNodeId": "417cb6bd-48f0-4882-8442-b048e28336ef",
                          "nodeConnections": [
                              {
                                  "pointsToNodeId": "5020f1b4-e360-4633-9c05-b3c24dbe7b37"
                              },
                              {
                                  "pointsToNodeId": "f6b74f15-cbfc-441c-aa84-c0aab4ae76c0"
                              }
                          ]
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "417cb6bd-48f0-4882-8442-b048e28336ef"
                          ],
                          [
                              "ce2ad976-7738-4601-8e86-40f9f2ffa1f6",
                              "1c5dc559-8e5c-4f55-9759-189b7982cbea"
                          ],
                          [
                              "5020f1b4-e360-4633-9c05-b3c24dbe7b37",
                              "f6b74f15-cbfc-441c-aa84-c0aab4ae76c0"
                          ],
                          [
                              "449c44f7-551b-4f8e-8eb1-ab743139e97a",
                              "f4c91cea-f698-48cb-b56b-573069ead75e"
                          ]
                      ]
                  }
              },
              {
                  "dataType": "MaterialInfo",
                  "generalStyle": "accent-orange",
                  "title": "Material information",
                  "rows": [
                      "After the first Settlor dies, the surviving Settlor's interest in any community property and the surviving Settlor's separate trust property will be referred to as the surviving Settlor's trust property. The surviving Settlor's trust property will be referred to as the Survivor's Trust. (Page 5-1, Article Five)",
                      "The surviving Settlor may appoint all or any portion of the principal and undistributed income remaining in the Survivor's Trust at the surviving Settlor's death among one or more persons or entities, including the creditors of the surviving Settlor's estate. The surviving Settlor has the exclusive right to exercise this general power of appointment (Page 8-1, Article Eight - Section 8.07)"
                  ]
              },
              {
                  "dataType": "graph",
                  "nodes": [
                      {
                          "id": "b0a1f962-7698-4c50-9425-9f21d1a09c11",
                          "content": {
                              "containerClass": " annotation accent-orange-top orange",
                              "content": [
                                  {
                                      "text": "Pet Trust",
                                      "class": "bold"
                                  },
                                  {
                                      "text": "Signed 05/03/242024",
                                      "class": ""
                                  },
                                  {
                                      "text": "Trustees",
                                      "class": "",
                                      "textB": [
                                          "Spencer T. Buss"
                                      ],
                                      "classB": "bold"
                                  }
                              ]
                          },
                          "metaData": {
                              "class": "box-node",
                              "pathBlocker": true,
                              "widthInNodes": 2
                          }
                      }
                  ],
                  "connections": [
                      {
                          "sourceNodeId": "b0a1f962-7698-4c50-9425-9f21d1a09c11",
                          "nodeConnections": []
                      }
                  ],
                  "grid": {
                      "rowsNodesById": [
                          [
                              "b0a1f962-7698-4c50-9425-9f21d1a09c11"
                          ]
                      ]
                  }
              },
              {
                  "dataType": "MaterialInfo",
                  "generalStyle": "accent-orange",
                  "title": "Material information",
                  "rows": [
                      "Currently, the Settlors have a Coton De Tulear named Nugget and a Bolo-ton (Bolognese/Coton De Tulear mix) named Pebbles. This Pet Trust is created with Nugget and Pebbles in mind but shall be applicable to any of the pets living at the surviving spouse's death. ",
                      "Spencer T. Buss shall act as Caregiver to the pets. If Spencer T. Buss is unable or unwilling to act as Caregiver, the Trustee shall find another Caregiver among the surviving friends and family members Spencer T. Buss, or any successor Caregiver, may permanently adopt any of the pets that he or she so chooses. (Page 11-1, Article Eleven - Section 11.02 (c))",
                      "Trustee shall determine the amount to fund this Pet Trust by subtracting the youngest pet's age at the surviving spouse's death from their presumed life expectancy of twenty (20) years, then multiplying that difference in number of years by Seven Thousand Five Hundred Dollars ($7,500). The Trustee shall allocate that amount to be held in this trust share to cover all expenses associated with the pets. (Page 11-1, Article Eleven - Section 11.02 (d))",
                      "The Trustee shall also distribute any and all income and principal as the Trustee deems necessary for the pets' health, maintenance and support. Such expenses shall include, but are not limited to veterinary bills, food, raw meats, organic vegetables, organic bones, treats, toys, and grooming and boarding expenses. Following the death of the pets, the Trustee shall distribute any remaining balance of the Pet Trust in accordance with the Articles that follow Article Eleven. If the Settlors have no pets living at the time of the surviving spouse's death, then this distribution will lapse, and this property instead will be distributed under the other provisions of this trust. (Page 11-2, Article Eleven - Section 11.02 (e) and (f)))"
                  ]
              }
          ]
      },
      {
          "sectionId": "COMMENTS",
          "sectionType": "COMMENTS",
          "sectionTitle": "Advisor Comments",
          "isCollapsed": false,
          "data": {
              "title": "Advisor Comments",
              "paragraph": "estos son los comentatrios"
          }
      }
  ]
}
