import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Component({
  selector: 'app-add-property-dialog',
  templateUrl: './add-property-dialog.component.html',
  styleUrls: ['./add-property-dialog.component.scss']
})
export class AddPropertyDialogComponent extends CloseDialogAtLogOut{


  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  symbolsOnly(control: FormControl) {
    if ( !/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,./]*$/.test(control.value)) {
      return { 'symbols': true };
    }
    return null;
  }

  nameControl = new FormControl('', [
      Validators.required,
      this.noWhitespaceValidator,
      this.symbolsOnly
  ]);

  matcher = new MyErrorStateMatcher();

  public assetText: string  = '';

  constructor(
    public dialogRef: MatDialogRef<AddPropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
    }

    ngOnInit(): void {
      
      this.assetText = 'Add new ' + this.data.mainObjectDescription;
      if(this.data.area === 'disability' || this.data.area === 'ltc' || this.data.area === 'estate'){
        this.assetText = (this.data.items == 0) ? 'Please add you first name:' : "Please add your spouse's name:";
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
