//Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'modal-advisor',
  templateUrl: './modal-advisor.component.html',
  styleUrls: ['./modal-advisor.component.scss'],
})
export class ModalAdvisorSubmittedComponent implements OnInit {

    public advisorProperties: any = {};
    public formattedPhoneNumber: string = '';

    public advisorLogo = '';
    public advisorName = '';
    public advisorCompany = '';
    public advisorEmail = '';
    public meetingLink = '';


    constructor(
        public dialogRef: MatDialogRef<ModalAdvisorSubmittedComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any
    ) {}

    ngOnInit() {

        this.advisorProperties = JSON.parse(localStorage['advisorResponse']);


        if('phone' in this.advisorProperties && this.advisorProperties.phone != "" && this.advisorProperties.phone != undefined && this.advisorProperties.phone != "undefined"){
            let phonePartOne   =  this.advisorProperties.phone.substring(0, 3);
            let phonePartTwo   =  this.advisorProperties.phone.substring(3, 6);
            let phonePartThree =  this.advisorProperties.phone.substring(6, 10);
            this.formattedPhoneNumber = `(${phonePartOne}) ${phonePartTwo}-${phonePartThree}`;
        }

        this.advisorLogo = this.advisorProperties.companyLogo || 'https://storage.googleapis.com/fpalpha-assets/fpalphalogo.png';
        this.advisorName = this.advisorProperties.fullName;
        this.advisorCompany = this.advisorProperties.companyName;
        this.advisorEmail = this.advisorProperties.email;
        this.meetingLink = this.advisorProperties.leadMeetingLink || '';

    }


}
