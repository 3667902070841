import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { saveAs } from 'file-saver';

import { Area } from '../../../shared/helpers/areas.catalog';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE } from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { medical_questions } from './medical.model';


@Component({
  selector: 'app-q-medical',
  templateUrl: './q-medical.component.html'
})
export class QMedicalComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: false }) tabRef;
  @ViewChild('identityform', { static: false }) identityform;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;
  public auxModel: any;
  public model: medical_questions;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public clientHasBusiness: string;
  public clientMaritalStatus: string;
  public clientHasChildren: string;
  public clientIsRetired: string;
  public clientAge: number;
  public clientIDChildOneAge: number;
  public clientIDChildTwoAge: number;
  public clientIDChildThreeAge: number;

  public  whoAnswered: string;
  public  answers: Array<string>[] = [];

  public CONSTANTS = { CONST_ABSOLUTES_UNSURE, CONST_ABSOLUTES };
  public validations = Validations;
  public isAdvisor: boolean = false;

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public toggleSearchBar: boolean = false;

  public bulletColor;

  constructor(
    private http: HttpClient,
    private router: Router,
    public  snackBarSuccess: MatSnackBar,
    public  snackBarError: MatSnackBar,
    public  authService: AuthenticationService,
    public qController: QuestionnaireController,

    ) {}

  ngOnInit() {

    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.clientHasBusiness = this.clientData.hasBusiness;
    this.clientHasChildren = this.clientData.hasChildren;
    this.clientIsRetired = this.clientData.isRetired;
    this.clientAge = this.clientData.age;
    this.clientIDChildOneAge = this.clientData.childOneAge;
    this.clientIDChildTwoAge = this.clientData.childTwoAge;
    this.clientIDChildThreeAge = this.clientData.childThreeAge;

    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';

    
    

    //Initialize the questionnaire model
    this.model = new medical_questions();

    //Get context, clientHasAnswered?
    let clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));
    this.clientHasAnsweredQuestionnaire = clientHasAnswered.ansMedical;

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    //Load questionnarie data, if clientHasAnswered
    if(this.clientHasAnsweredQuestionnaire){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'medical', this.model).then(response => {
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    if(typeof this.tabRef !== "undefined"){
      this.ntabs = parseInt(this.tabRef._tabs.length);
      this.countTabs(this.ntabs);
    }
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentSave: boolean = false) {

    if (this.clientHasBusiness === 'Yes') this.model.clientIDHRAEmployer = 'Yes';
    if (this.clientAge >= 65) this.model.clientIDAccesstoMedicare = 'Yes';
    if (this.clientMaritalStatus === 'Married' || this.clientMaritalStatus === 'Domestic Partnership') this.model.clientIDHasALifePartner = 'Yes';

    this.qController.submitForm(this, this.model, this.clientData.clientId, 'medical', saveForLater, null, silentSave);
  }

  ngAfterViewInit() {
    if (this.clientMaritalStatus === 'Married' || this.clientMaritalStatus === 'Domestic Partnership') this.model.clientIDHasALifePartner = 'Yes';
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {
    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };


      this.http.get(`${this.pdfUrl}${'medical'}/?options=${this.model.clientIDHasMedicalInsurance}`, options)
      .subscribe(blob => {
        saveAs(blob, 'medical-questionnaire.pdf');
      });

  }

}
