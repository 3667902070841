import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

const COLOR_OVER_WEB = '#CC3939';
const COLOR_OVER_PDF = '#D94141';
const COLOR_INPHASEOUT_WEB = '#F5B862';
const COLOR_INPHASEOUT_PDF = '#FFB040';
const COLOR_UNDER_WEB = '#62CC7C';
const COLOR_UNDER_PDF = '#47CC69';
const COLOR_PHASEOUT_WEB = '#20568C';
const COLOR_PHASEOUT_PDF = '#06498C';


@Component({
  selector: 'app-bar-chart-phase',
  templateUrl: './bar-chart-phase.component.html',
  styleUrls: ['./bar-chart-phase.component.scss']
})
export class BarChartPhaseComponent implements OnInit, AfterViewInit {

  @Input() data: GraphDataInterface | any = {};
  @Input() pdfColor: any = false;

  @ViewChild('canvasbarchartphase',{static:false}) canvases: HTMLCanvasElement;
  public fullCanvas;
  public footerRow;

  /**
   * internalData.
   * Is a copy for the inputed data, for each change on data a copy is made.
   * Serves to not edit the comon source of the data, in case other components require acces to the orginal data.
   */

  public internalData: GraphDataInterface | any;
  public hrMarginTop: number =  35; //From sass: margin-top: $column-header-height + $column-header-bottom-margin;

  constructor(

  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(){

    this.fullCanvas = <HTMLElement> this.canvases['nativeElement'] as HTMLElement;

    if(this.pdfColor && this.fullCanvas){

      this.footerRow = this.fullCanvas.getElementsByClassName('footer-row') as HTMLCollectionOf<HTMLElement>;

      setTimeout(()=>{

        for(var i = 0; i<this.footerRow.length; i++){
          //this.footerRow.item(i).style.setProperty("font-size", "0.875rem", "important");
          this.footerRow.item(i).style.lineHeight = '1';
        }

      }, 1000)

    }

  }

  calculatePhaseLinePosition(): number{
    let oneHundredPercentage = this.data.valueTop - this.data.valueBottom;
    let percentagePosition = (this.data.lineIndicator.value * 100) / oneHundredPercentage;
    return (100 - percentagePosition);
  }

  /**
   *
   * @param changes
   * On data change, make internall calculations to display columns, lines and colors.
   */
  ngOnChanges(changes: SimpleChanges) {

    if(!Boolean(changes.data) || !Boolean(changes.data.currentValue)){ return; }

    // Copy the incoming data into a new object to not modify the original data source.
    this.internalData = JSON.parse(JSON.stringify(changes.data.currentValue))
    //this.internalData = JSON.parse(JSON.stringify(changes.data && changes.data.currentValue || {}))

    if(this.pdfColor){

      this.footerRow = this.fullCanvas.getElementsByClassName('footer-row') as HTMLCollectionOf<HTMLElement>;
      setTimeout(()=>{
        for(var i = 0; i<this.footerRow.length; i++){
          this.footerRow.item(i).style.lineHeight = '1';
        }
      }, 2000)

      if(typeof this.internalData.columns !== 'undefined'){
        this.internalData.columns.map(column =>{
          column.sections.map(section=>{
            switch(section.color){
              case '#0259B6': section.color = '#12294B'; break;
              case '#026DDE': section.color = '#3F5DAA'; break;
              case '#218BFD': section.color = '#6580BF'; break;
              case '#5DABFD': section.color = '#8C9BBF'; break;
              case '#9ACAFE': section.color = '#C3C5D6'; break;
              case '#D7EAFF': section.color = '#D9DCEF'; break;
            }
          })
        })
      }

    }

    // Start modifications
    let columns: any[] = this.internalData.columns;
    let linePosition = this.calculatePhaseLinePosition();
    columns.map((column, index) => {
      let sections: any[] = column.sections;
      sections.map((section, index2)=> {
        // Initial  values
        let columnInitialAdjust = {percentage: 0, color: "rgba(255, 255, 255, 0)"}
        let columnEndingAdjust = {percentage: 0, color: "rgba(255, 255, 255, 0)"}
        let lineStyle = {top: 0, color: 'yellow' }

        if(section.displacement <= 0){
          columnInitialAdjust.percentage = 100 - section.percentage;
        }else{
          columnInitialAdjust.percentage = 100 - (section.displacement + section.percentage);
          columnEndingAdjust.percentage = 100 - (columnInitialAdjust.percentage + section.percentage);
        }
        lineStyle.top = linePosition;

        if(this.pdfColor){
          lineStyle.color = linePosition <= columnInitialAdjust.percentage ? COLOR_OVER_PDF : linePosition > columnInitialAdjust.percentage && linePosition < columnInitialAdjust.percentage + section.percentage ? COLOR_INPHASEOUT_PDF : COLOR_UNDER_PDF;
        }else{
          lineStyle.color = linePosition <= columnInitialAdjust.percentage ? COLOR_OVER_WEB : linePosition > columnInitialAdjust.percentage && linePosition < columnInitialAdjust.percentage + section.percentage ? COLOR_INPHASEOUT_WEB : COLOR_UNDER_WEB;
        }

        this.internalData.columns[index].sections.unshift(columnInitialAdjust);
        this.internalData.columns[index].sections.push(columnEndingAdjust);
        this.internalData.columns[index].lineStyle = lineStyle;
      })
    })
    this.internalData.labels = [
      { legend: "Phase Out", color: this.pdfColor ? COLOR_PHASEOUT_PDF :  COLOR_PHASEOUT_WEB},
      { legend: "Over", color: this.pdfColor ? COLOR_OVER_PDF :  COLOR_OVER_WEB},
      { legend: "In phase out", color: this.pdfColor ? COLOR_INPHASEOUT_PDF :  COLOR_INPHASEOUT_WEB },
      { legend: "Under", color: this.pdfColor ? COLOR_UNDER_PDF :  COLOR_UNDER_WEB }
    ]
  }
}

interface GraphDataInterface {

  componentType?: string,
  id?: string,
  valueBottom: number,
  valueTop: number,
  lineIndicator?: {value: number, legend: string} | null,
  rullers: string[],
  sideNote: string,
  labels: { legend: string, color: string }[],
  columns:
    {
      legendBottom: string,
      legendTop?: string | null ,
      sections: { percentage?: number | null, legend: string, color: string, annotation?: string | null }[],
      lineStyle: {top: number, color: string }
    }[]
  footNotes?: string[]

}
