import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import { environment } from '../../../../environments/environment';
import { Area } from '../../../shared/helpers/areas.catalog';
import { WINDOW } from '../../../shared/helpers/window.provider';
import { AuthenticationService } from '../../../shared/services/auth/authentication.service';
import { FileUploaderOptions } from '../file-uploader-component/file-uploader-component.component';
import { FileUploadersArrayHelper } from '../FileUploadersArrayHelper';
import { general_questions } from '../q-general/general.model';
import {
  CONST_ABSOLUTES,
  CONST_ABSOLUTES_NA,
  CONST_ABSOLUTES_UNSURE,
  CONST_LTC_INSURANCE_COMPANIES,
} from '../Questionnaire.constants';
import { QuestionnaireController } from '../Questionnaire.controller';
import { Validations } from '../Questionnaire.validations';
import { Ltc } from './ltc.model';
import { ClientService } from 'app/shared/services/client/client.service';

// import { DialogFirstTime } from '../dialog-firstTime/dialog-firstTime.component';

@Component({
  selector: 'app-q-ltc',
  templateUrl: './q-ltc.component.html',
})
export class QLtcComponent implements AfterViewChecked, OnInit {

  @ViewChild('tabRef', { static: true }) tabRef;
  @ViewChild('target', { static: true }) targetEl: ElementRef;

  public model: Ltc;
    public auxModel: any;
  public clientHasAnsweredQuestionnaire: boolean;
  public clientData: any;
  public generalQuestData: general_questions = new general_questions();
  public clientMaritalStatus: string;
  public whoAnswered: string;
  public answers: Array<string>[] = [];
  public CONSTANTS = { CONST_ABSOLUTES, CONST_ABSOLUTES_UNSURE, CONST_ABSOLUTES_NA, CONST_LTC_INSURANCE_COMPANIES };
  public fileUploaderArray: FileUploadersArrayHelper;
  public validations = Validations;
  public ltcPolicyUploaderOptions: FileUploaderOptions;
  public extractionResults: any[] = [];
  public isAdvisor: boolean = false;
  public propName: string = '';

  public ntabs:number;
  public progress:number;
  public progressnumber:number = 0;
  public selected: number;
  public progresscompleted: boolean = false;
  public bulletColor;
  public toggleSearchBar: boolean = false;
  public clientIsRetired: boolean = false;
  //PDF url
  public pdfUrl: string = environment.apiPDFQuestionnaire;

  preference = [
    { value: "Better value", display: "Better value for your insurance premiums in the event long-term custodial care is needed" },
    { value: "A guarantee", display: "A guarantee of receiving at least your insurance premiums back in the event no long-term care is needed" }
  ];

  constructor(
    private http: HttpClient,
    private router: Router,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    public qController: QuestionnaireController,
    public authService: AuthenticationService,
    // public dialog: MatDialog,

    @Inject(WINDOW) private window: Window,
    private currentClientService: ClientService
  ) { }

  ngOnInit() {
    this.isAdvisor = this.authService.isAdvisor();

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientMaritalStatus = this.clientData.isMarried;
    this.isAdvisor ? this.whoAnswered = 'Advisor' : this.whoAnswered = 'Client';
    if (this.clientData.isRetired === 'Yes') this.clientIsRetired = true;

    //Is the first time here? Show modal
    // let isFirstTimeHere = JSON.parse(sessionStorage.getItem('isFirstTime'));
    // if (isFirstTimeHere === 'ltc') {
    //     this.dialog.open(DialogFirstTime, {
    //         panelClass: 'modal-dialog-questionnaire',
    //         height: '250px',
    //         width: '500px',
    //     });
    // }

    //Initialize the questionnaire model
    this.model = new Ltc();

    //Load session storage data from questionnare
    let loadedStoragedQuestionnaireData = this.qController.getStoragedQuestionnareDatByArea('ltc');
    let index = loadedStoragedQuestionnaireData.index != null ? loadedStoragedQuestionnaireData.index : 1;
    this.propName = (loadedStoragedQuestionnaireData.name != null) ? loadedStoragedQuestionnaireData.name : '1';
    this.model.clientIDAssetName = loadedStoragedQuestionnaireData.name;
    

    //Load extraction results for file uploaders components
    this.qController.loadExtractionsResults(this.clientData.clientId, 'ltc', String(index)).then(result => {
      this.extractionResults = result;
    });

    this.ltcPolicyUploaderOptions = new FileUploaderOptions(
     /*  'clientIDLtcPolicy',
      this.model.clientIDLtcPolicy,
      this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'ltc', false, index),
      'policy',
      () => { if (this.model.clientIDWantsToInputPolicyDataLTC == 'Yes' || !this.ltcPolicyUploaderOptions.isComponentAlive) return true; else return this.ltcPolicyUploaderOptions.status == 'validatedValid' } */
      {
        fileID: 'clientIDLtcPolicy',
        fileVal: this.model.clientIDLtcPolicy,
        uploader: this.qController.fileUploaderFactoryAutoUpload(this.window, this.clientData.clientId, 'ltc', false, index),
        documentReadableName: 'policy',
        validationRule: () => { if (this.model.clientIDWantsToInputPolicyDataLTC == 'Yes' || !this.ltcPolicyUploaderOptions.isComponentAlive) return true; else return this.ltcPolicyUploaderOptions.status == 'validatedValid' },
        actionsAfterResponse: { validatedVoid : ()=>{ this.qController.openInavlidDocDialog(this, this.clientData.clientId, 'ltc', index, 'clientIDLtcPolicy', ()=>{this.model.clientIDWantsToInputPolicyDataLTC = 'Yes'} )} }
      }
      );

    // Declare the auto save logic
    let commonAutoSaveFunction = () =>{
      console.log("AUTO SAVE");
      this.submit(true, true);
    }

    // Get the general-quest data and fill it to the model.
    this.currentClientService.data.generalQuestionnaire.subscribe((data) => {
      console.log('DATA: ', data);
      if (data?.personalInformation) {
        const answers = data?.personalInformation?.data;
        answers.forEach(({ name, answer }) => {
          this.generalQuestData[name] = answer === 'undefined' || !answer ? '' : answer
        })
      }
    })
    if(loadedStoragedQuestionnaireData.answered){ // The questionnaire has been answered, then load the data
      this.qController.loadQuestionnaireDataAsync(this.clientData.clientId, 'ltc', this.model, index).then(response => {
        
        
        this.auxModel = {
          ...(this.auxModel ? { ...this.auxModel } : {}),
          ...this.model,
        };
        // Wait for the data before starting the autosave function
        this.qController.initAutoSave2(commonAutoSaveFunction);
      })
    }else{
      //Start autosave
      this.qController.initAutoSave2(commonAutoSaveFunction);
    }

    this.bulletColor = Area.findAreaById(this.model.getQName()).colorHex;
    this.auxModel = {
      ...(this.auxModel ? { ...this.auxModel } : {}),
      ...this.model,
    };
  }

  ngAfterViewChecked(): void {
    this.ntabs = parseInt(this.tabRef._tabs.length);
    this.countTabs(this.ntabs);
  }

  onSaveForLater() {
    this.submit(true);
  }

  submit(saveForLater: boolean = false, silentModeActived: boolean = false) {
    //Load general data to model
    this.model.clientIDFirstName = this.generalQuestData.clientIDFirstName;
    this.model.clientIDGender = this.generalQuestData.clientIDGender;
    this.model.clientIDAge = this.generalQuestData.clientIDAge;
    this.model.clientIDLivingState = this.generalQuestData.clientIDLivingState;
    this.model.clientIDSpouseName = this.generalQuestData.clientIDSpouseName;
    this.model.clientIDHasABusiness = this.generalQuestData.clientIDHasABusiness;
    this.model.clientIDMaritalStatus = this.generalQuestData.clientIDMaritalStatus;
    this.model.clientIDSpouseAge = this.generalQuestData.clientIDSpouseAge;

    if (this.model.clientIDPolicyReimbursement === 'Expense reinbursement') {
      this.model.clientIDExpensereimbursement = 'Yes';
      this.model.clientIDCashReimbursement = 'No';
    } else if (this.model.clientIDPolicyReimbursement === 'Cash reinbursement') {
      this.model.clientIDExpensereimbursement = 'No';
      this.model.clientIDCashReimbursement = 'Yes';
    } else if (this.model.clientIDPolicyReimbursement === '') {
      this.model.clientIDExpensereimbursement = 'No';
      this.model.clientIDCashReimbursement = 'No';
    } else { }

    //Inflation Riders conversion

    //'Simple', 'Compound', 'Tailored', 'Step rated', 'Deferred benefit option', 'Guaranteed purchase option', 'None'

    if (this.model.clientIDTypeOfInflationRider === 'Simple') {
      this.model.clientIDsearchInflationRider = 'Yes';
      this.model.clientIDsearchCompoundInflationRider = 'No';
      this.model.clientIDsearchTailoredInflationRider = 'No';
      this.model.clientIDsearchStepRatedInflationRider = 'No';
      this.model.clientIDsearchDeferredBenefit = 'No';
    } else if (this.model.clientIDTypeOfInflationRider === 'Compound') {
      this.model.clientIDsearchInflationRider = 'No';
      this.model.clientIDsearchCompoundInflationRider = 'Yes';
      this.model.clientIDsearchTailoredInflationRider = 'No';
      this.model.clientIDsearchStepRatedInflationRider = 'No';
      this.model.clientIDsearchDeferredBenefit = 'No';
    } else if (this.model.clientIDTypeOfInflationRider === 'Tailored') {
      this.model.clientIDsearchInflationRider = 'No';
      this.model.clientIDsearchCompoundInflationRider = 'No';
      this.model.clientIDsearchTailoredInflationRider = 'Yes';
      this.model.clientIDsearchStepRatedInflationRider = 'No';
      this.model.clientIDsearchDeferredBenefit = 'No';
    } else if (this.model.clientIDTypeOfInflationRider === 'Step rated') {
      this.model.clientIDsearchInflationRider = 'No';
      this.model.clientIDsearchCompoundInflationRider = 'No';
      this.model.clientIDsearchTailoredInflationRider = 'No';
      this.model.clientIDsearchStepRatedInflationRider = 'Yes';
      this.model.clientIDsearchDeferredBenefit = 'No';
    } else if (this.model.clientIDTypeOfInflationRider === 'Deferred benefit option') {
      this.model.clientIDsearchInflationRider = 'No';
      this.model.clientIDsearchCompoundInflationRider = 'No';
      this.model.clientIDsearchTailoredInflationRider = 'No';
      this.model.clientIDsearchStepRatedInflationRider = 'No';
      this.model.clientIDsearchDeferredBenefit = 'Yes';
    } else if (this.model.clientIDTypeOfInflationRider === 'None' || this.model.clientIDTypeOfInflationRider === '') {
      this.model.clientIDsearchInflationRider = 'No';
      this.model.clientIDsearchCompoundInflationRider = 'No';
      this.model.clientIDsearchTailoredInflationRider = 'No';
      this.model.clientIDsearchStepRatedInflationRider = 'No';
      this.model.clientIDsearchDeferredBenefit = 'No';
    }

    this.qController.verifyFileUploadersOptionsAreValidAndCallSubmit(
      [this.ltcPolicyUploaderOptions],
      () => this.qController.submitForm(this, this.model, this.clientData.clientId, 'ltc', saveForLater, null, silentModeActived),
      this
    );

  }

  ngAfterViewInit() {
  }

  countTabs(tabs:number){
    //tabs+=1;
    this.progress = 100/tabs;
  }

  nextTab(index:number){
    this.model.progressTab = index+1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index+1;
  }

  previousTab(index:number){
    this.model.progressTab = index-1;
    this.targetEl.nativeElement.scrollIntoView();
    //this.selected = index-1;
  }

  public tabChangedSelect(tabChangeEvent: MatTabChangeEvent): void {
	  //this.selected = tabChangeEvent.index;
	  this.model.progressTab = tabChangeEvent.index;
	  //this.progressnumber = Math.round(tabChangeEvent.index *this.progress);
	  if(this.model.progressCompleted == true){
	    this.model.progressBar = 100;
	  }else{
	    this.model.progressBar = Math.round(tabChangeEvent.index *this.progress);
	  }
  }

  public tabChangedFocus(tabChangeEvent: MatTabChangeEvent): void {
    this.model.progressTab= tabChangeEvent.index;
    //this.selected = tabChangeEvent.index;
  }

  toggleSearch() {
    this.toggleSearchBar = !this.toggleSearchBar;
  }

  ngOnDestroy(){
    this.qController.deleteStoragedQuestionnareDatByArea('ltc');
    // sessionStorage.removeItem('isFirstTime');
    this.qController.stopAutoSave();
  }

  generatePDFQuestionnaire() {

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: null,
      params: null,
      responseType: 'blob'
    };

      this.http.get(`${this.pdfUrl}${'ltc'}/?options=${this.model.clientIDHasLTCInsurancePolicy}`, options)
      .subscribe(blob => {
        saveAs(blob, 'ltc-questionnaire.pdf');
      });
  }

}
