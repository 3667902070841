// Angular
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

// Rx
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Project
import { AuthenticationService } from '../services/auth/authentication.service';
import { urlPrependRootContext } from '../helpers/url.helper'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err.status);
            console.log(err);
            if (err.status === 401 || err.status === 403) {
                console.log('Error 401 | 403. Force session logout');
                this.authenticationService.logout(null, 'Error 401 | 403. Force session logout').then();
                /* this.router.navigate([urlPrependRootContext('/signin')]); */
            }else if(err.error.message == "SESSION_HAS_FINISHED"){
                console.log('Error finished session');
                sessionStorage.clear();
                localStorage.clear();
                this.router.navigate([urlPrependRootContext('/signin')]);
            } else if (err.status === 440) { //Session token has expired
                console.log("Session epxpired by backend. Logging out");
                this.router.navigate([urlPrependRootContext('/signin')]);
            } else if (err.status === 0) {
                console.log('Martin and Paty broke something');
            }

            const error = err.error.message || err.statusText;
            // Pass the complete error, in order to be properly managed in other parts
            return throwError(err);
        }))
    }
}
