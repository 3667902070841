// Angular
import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Rx
import { filter } from 'rxjs/operators';
import { Subscription } from "rxjs";

// 3rd parties
import PerfectScrollbar from 'perfect-scrollbar';

// Project
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from '../../services/theme.service';
import { AdvisorService } from '../../services/advisor.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { urlPrependRootContext } from '../../helpers/url.helper'
import { getLastUrlSegment } from '../../helpers/url.helper';
import { DialogConfirm } from '../../components/dialog-confirm/dialog-confirm.component';


import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from 'app/shared/helpers/url.helper';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { ClientDataService } from 'app/shared/services/client-data-service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})

export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  public currentClientInfo;
  public currentClient: any;
  public urlWithoutParams;
  public currentClientisProspect: boolean = false;
  public isAdvisor: boolean = false;
  public clientProfileRoute: string;

  private sidebarPS: PerfectScrollbar;
  private menuItemsSub: Subscription;

  public clientDashboardData;
  public clientData: any;

  public isProduction: any;
  public isEarlyTesterSummary: boolean = false;
  public displayClientData: boolean = false;
  private subscriptionClientDataService: Subscription;

  constructor(
    public dialog: MatDialog,
    private navService: NavigationService,
    public themeService: ThemeService,
    public advisorService: AdvisorService,
    public authService: AuthenticationService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private clientDataService: ClientDataService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.urlWithoutParams = getLastUrlSegment(this.router.url);
      this.displayClientData = SidenavComponent.validatePathsToDisplayNormalMenu(this.router.url);
    });
  }

  ngOnInit() {

    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);

    this.isAdvisor = this.authService.isAdvisor();

    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
    });

    this.loadMenuByClientType();

    this.subscriptionClientDataService = this.clientDataService.afterSetFullCurrentClient('sidebar-side').subscribe(client => {
      console.log('afterSetFullCurrentClient. sidebar-side');
      this.loadMenuByClientType();
    });

    this.urlWithoutParams = getLastUrlSegment(this.router.url);

    this.activatedRouter.queryParams
      .subscribe(params => {
        if (params.code) {
          // send GET to back with code
          this.advisorService.sendOutlookAuthCode(params.code).subscribe(answer => {
          },
          error => {
            console.log(error);
          });
        }
      });

    this.clientProfileRoute = urlPrependRootContext('/client-profile');
    this.displayClientData = SidenavComponent.validatePathsToDisplayNormalMenu(this.router.url);

    //Opt level service
    /* let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
    let clientId = storagedClientID && storagedClientID.clientId || '';
    console.log('***Current Client', storagedClientID);
    if (clientId) {
      this.advisorService.getClientDashboardData(clientId).subscribe(dashData => {
        this.clientDashboardData = dashData;

        //Update session storage with correct opt level value
        if(clientId !== ''){
          storagedClientID['level'] = this.clientDashboardData.optLevel;
          sessionStorage.setItem("currentClient", JSON.stringify(storagedClientID));
        }

      },
        error => {
          console.log(error);
        }
      );
    } */

  }


  ngAfterViewChecked(): void {
    this.loadMenuByClientType();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidebarPS = new PerfectScrollbar('#scroll-area', {
        suppressScrollX: true
      });

      let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
      this.currentClient = storagedClientID;
    });
    //this.loadMenuByClientType();
  }

  upgradeToClient() {
    const dialogRef = this.dialog.open(DialogConfirm, {
      data: 'Do you want to upgrade this prospect to client? This action cannot be undone.'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != '') {
        this.currentClient.isProspect = false

        let updatedStatus = this.currentClient;

        this.advisorService.prospectToClient(this.currentClient.clientId).then(response => {

          sessionStorage.currentClient = JSON.stringify(updatedStatus);
          this.router.navigate(['/advisor/dashboard']);

          let snackBarRef = this.snackBarSuccess.open("Prospect successfully upgraded to client", "Ok", {
            duration: 10000,
            panelClass: 'success-snackbar'
          });

        });

      }
    },
    error => {
      console.log(error);
      let snackBarRef = this.snackBarError.open("Can't upgrade prospect, please try again. If problem persists, please contact support.", "Ok", {
        duration: 10000,
        panelClass: 'error-snackbar'
      });
    });

  }

  ngOnDestroy() {
    if(this.sidebarPS) {
      this.sidebarPS.destroy();
    }
    if(this.menuItemsSub) {
      this.menuItemsSub.unsubscribe()
    }
    console.log('unsubscribe sidebar-side');
    this.subscriptionClientDataService.unsubscribe();
  }

  isValidSubAreaRoute(route:string):boolean{
    return (/^areas\/{1}\D+/).test(route);
  }

  private loadMenuByClientType(){

    this.currentClient = JSON.parse(sessionStorage.getItem('currentClient'));
    if(this.currentClient != null){
      this.currentClientisProspect = this.currentClient.isProspect;
      //Load menu by client type: Client | Prospect
      this.menuItems = (this.currentClientisProspect === true) ?
        this.navService.menuItemsCatalog.filter(item => {let name = item.name; return (name == 'Clients' || name == 'Dashboard' || name == 'Reports' || name == 'Prospect questionnaire')})
      :
        this.navService.menuItemsCatalog.filter(item => {return item.name != 'Prospect questionnaire'});

      //Update menu elements for testing and production
      if(this.isProduction){ // Case Production
        this.deleteListedFromMenu([
          //Your list here
        ]);


      }else{ // Case Testing
        this.deleteListedFromMenu([
          //Your list here
        ]);
      }

    }else{
      this.menuItems = this.navService.menuItemsCatalog.filter(item => {return item.name != 'Prospect questionnaire'});
      this.currentClientisProspect = false;
    }

  }

  /**
   * Delete from the current menu, the listed elements by its name
   * @param listedNames
   */
  private deleteListedFromMenu(listedNames: string[]){
    if(listedNames.length > 0){
      listedNames.map(name => {
        let index = this.menuItems.findIndex(item  => item.name === name);
        if(index >= 0) this.menuItems.splice(index, 1);
      })
    }
  }

}
