// Angular
import { Component, OnInit, Input, HostListener } from '@angular/core';

//Project
import { AdvisorService } from '../../services/advisor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { urlPrependRootContext } from '../../helpers/url.helper';
import { ClientService } from 'app/shared/services/client/client.service';
import { ClientDataService } from 'app/shared/services/client-data-service';

@Component({
  selector: 'app-horizontal-search-component',
  templateUrl: './horizontal-search-component.component.html'
})

export class HorizontalSearchComponentComponent implements OnInit {

  @HostListener('document:click', ['$event'])
  clickout() {
    this.searchSections = [];
    (<HTMLInputElement>document.getElementById('search-glossary')).value = ''
  }

  public searchSections: SearchSection[] = [];

  @Input() isWhiteTeme: boolean = false;

  public advisorPreferences: any;
  // public generateRandomEmail: boolean = false;

  constructor(
    private advisorService: AdvisorService,
    public router: Router,
    public currentClientService: ClientService,
    public activeRoute: ActivatedRoute,
    private clientDataService: ClientDataService
  ) { }

  ngOnInit() {

    this.advisorPreferences = JSON.parse(localStorage.getItem('advisorPreferences'));
    /*if (this.advisorPreferences){
      this.generateRandomEmail = this.advisorPreferences['generateRandomEmail'];
      console.log('this.advisorPreferences', this.advisorPreferences, 'generateRandomEmail**', this.generateRandomEmail);
    }*/

    // Initialice the searchSections array to give it an order for results, first clients then glossary definitions.
    // Otherwise the order will be defined with the first search endpoint who returns an answer

    let sectionClients = new SearchSection();
    sectionClients.sectionName = 'Clients';

    let sectionGlossary = new SearchSection();
    sectionGlossary.sectionName = 'Glossary';
    this.advisorPreferences = localStorage.getItem('advisorPreferences');
    this.searchSections = [
      sectionClients,
      sectionGlossary
    ]
  }

  async onSearchInputChange(e) {
    let searchValue = e.srcElement.value;
    if(String(searchValue).length > 1)
    await this.searchAction(searchValue);
  }

  focusOut(e) {
    this.searchSections = [];
    (<HTMLInputElement>document.getElementById('search-glossary')).value = ''
  }

  async searchAction(searchValue: string) {

    // searchGlossary(searchValue: string) : SearchSection. Define glossary search function
    let searchGlossary = async (searchValue: string) => {
      let section = new SearchSection();
      section.sectionName = 'Glossary';
      await this.advisorService.glossarySearch(searchValue).then(
        (response: any[]) => {
          response.forEach(glosaryTerm => {
            section.definitions.push(new SearchResult(glosaryTerm.title, glosaryTerm.definition, 'https://storage.googleapis.com/fpalpha-assets/iconos/01_Platform/00_general_actions/13_glossary_g.svg'))
          });
        }
      );
      return section;
    }

    let commonOnClientClickBehaviour = (clientId: string) => {
      this.advisorService.getClientById(clientId).then(async clientResponse => {
        await this.clientDataService.setFullCurrentClient(clientResponse.client);
        console.log('commonOnClientClickBehaviour ya seeteo cosas')
        this.router.navigate([urlPrependRootContext('/dashboard')]);
      })
    }

    // searchClient(searchValue: string) : SearchSection. Define the client search function
    let searchClient = async (searchValue: string) => {
      let section = new SearchSection();
      section.sectionName = 'Clients';
      await this.advisorService.clientSearch(searchValue).then(
        (response: any[]) => {
          response.forEach(client => {
            section.definitions.push(new SearchResult(
              client.fullName, //client.clientIDFirstName + ' ' + client.clientIDLastName,
              client.email, // client.email,
              client.photo, //client.clientIDPhoto,
              // On click, search for the selected client and stablish it as current.
              async () => {
                await this.currentClientService.initClient(client._id)
                commonOnClientClickBehaviour(client._id)
              })
            )
          });
        }
      );
      return section;


    }

    // Call the search functions
    await searchClient(searchValue).then(result => this.updateSearchResults(result));
    await searchGlossary(searchValue).then(result => this.updateSearchResults(result));

  }

  /**
   * updateSearchResults
   * @param SearchSection
   * Updates the inner list of result given the response of an endpoint as a SearchSection
   */
  updateSearchResults(SearchSection: SearchSection) {
    let indexCurrentSection = this.searchSections.findIndex(inSection => inSection.sectionName == SearchSection.sectionName);
    if (indexCurrentSection >= 0) {
      this.searchSections[indexCurrentSection] = SearchSection;
    } else {
      this.searchSections.push(SearchSection);
    }

    //this.searchSections[indexCurrentSection] = SearchSection;
  }

  onClickableResult(result: SearchResult){
    if(result.buttonAction != undefined) result.buttonAction();
  }

}

class SearchSection {
  public sectionName: string;
  public definitions: SearchResult[] = [];
}

class SearchResult {
  constructor(
    public textSectionA: string,
    public textSectionB: string,
    public image_url?: string,
    public buttonAction?: any // A function to invoke when click, if null no button behaviour or style is set for the search response element
  ) { }
}
