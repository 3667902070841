import { env } from 'process';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
// Angular
import { Injectable, Inject, Output, EventEmitter } from "@angular/core";
import { WINDOW } from "../helpers/window.provider";

// Project
import * as io from "socket.io-client";
import { AdvisorService } from "./../services/advisor.service";
import { NOTIFY_STYLE_ALERT, NOTIFY_STYLE_ERROR, NOTIFY_STYLE_SUCCESFULL, correctNotificationStyle } from '../components/notifications/notifications.constants';
import { getSideByContextInPriority, SIDE_ADVISOR } from "../helpers/url.helper";
import { AuthenticationService } from './auth/authentication.service';
import { SocketFP } from './SocketFP.class';
import { environment } from 'environments/environment.prod';
import { SequentialJobsDispatcher } from '../helpers/SequentialJobsTimerDispatcher';
import { SocketIoWrapper } from './socketIoWrapper.class';
import { readFromStoragedObject } from '../helpers/utils';

@Injectable({
  providedIn: "root"
})
export class AppSocketIoService {
  // The client instance of socket.io
  private socket: SocketIOClient.Socket | SocketFP;
  // The file uploader set this variable to filter by the currentt visible questionnare/area
  public listenToAreaExtractions: string[] = [];
  public httpSocketUrl = this.advisorService.dynamicIPAddress(this.window, environment.apiSocket2);
  public alternativeSocketUrl = this.advisorService.dynamicIPAddress(this.window, environment.apiAdvisorNotifications) + '/pull';


  @Output() notificationGenerated = new EventEmitter<any>();
  @Output() notificationFileExtractions = new EventEmitter<any>();
  @Output() notificationClientsImportReady = new EventEmitter<any>();
  @Output() notificationClientsImportReadyFinal = new EventEmitter<any>();
  @Output() notificationClientSharedWithMe = new EventEmitter<any>();
  @Output() notificationNewLeadReceived = new EventEmitter<any>();
  @Output() notificationSalesforceClientsImportReady = new EventEmitter<any>();
  @Output() notificationSalesforceClientsImportReadyFinal = new EventEmitter<any>();
  @Output() notificationInvoicePaid = new EventEmitter<any>();

  constructor(
    @Inject(WINDOW) private window: Window,
    private advisorService: AdvisorService,
    public http: HttpClient,
    public snackBar: MatSnackBar
  ) {



    SocketIoWrapper.onUnstableBehaviour = ()=>{

      this.snackBar.open("It seems that your connection to FP Alpha was lost.  We're trying to reconnect.", "", {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'error-snackbar-full'

      });
      // Call the endpoint that register a socket unstable event.
      this.http.post<any>(`${environment.apiAdvisor}/homepage/socket/disconnection`, {})
      .toPromise().then(response => {
        console.log(response);
      });

      // Shift the socket mode
      this.shiftSocketMode();

     };

     SocketFP.onConnectionAction = ()=>{

      this.snackBar.dismiss();

      if(localStorage.getItem('socketSilentMode') !== 'true'){

        this.snackBar.open("Connection reestablished using an alternate method.", "", {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: 'success-snackbar',
          duration: 3000
        });

      }

     }

  }

  public shiftSocketMode(){

    this.socketClose();
    let currentSocketMode = localStorage.getItem('socketType');
    console.log(`Socket Shift. Prev state: ${currentSocketMode} New state: ${currentSocketMode == 'HTTP' ? 'WS' : 'HTTP'}`);
    localStorage.setItem('socketType', currentSocketMode == 'HTTP' ? 'WS' : 'HTTP');
    this.socket = null;
    this.initSocketAndSubscribeToDefaultEvents();

  }

  /**
   * Get the uploaded files validation response
   */

  getFilesValidationResponses(response: SocketResponse) {
    console.log("File validation response: ", response);
    let currentClientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    // Emit notification only if it is listed in the area list.
    let currentDocumentIndex = sessionStorage.getItem(response.metadata.area + 'QuestIndex');
    if (this.listenToAreaExtractions.includes(response.metadata.fileID) && response.clientId === currentClientId && (currentDocumentIndex == undefined ? true : currentDocumentIndex === response.metadata.indexAsset) ){
      this.notificationFileExtractions.emit(response);
    }
    //this.notificationGenerated.emit(response);
  }

  getNotifications(data: any) {
    console.log('A new notification has arrived: ', data);
    const ans = correctNotificationStyle(data); // Validate and make corrections with the interaction style inside the data
    //Update sessionStorage areas with recs ready

    if (data['interactionStyle'] == NOTIFY_STYLE_SUCCESFULL || data.metadata['interactionStyle'] == NOTIFY_STYLE_SUCCESFULL) {
      this.updateAreasWithRecsInLocalStorage(data);

    }
    console.log('Emit event for notifications');
    this.notificationGenerated.emit(data); // Emit notification interaction
  }

  /**
   * Redtail(?) events
   */

  onClientsImportReady(data: any) {
    console.log('Notification. Clients import ready. ', data);
    this.notificationGenerated.emit(data);
    this.notificationClientsImportReady.emit(data);
  }

  onClientsImportReadyFinal(data: any) {
    console.log('Notification. Clients import ready. ', data);
    this.notificationGenerated.emit(data); // Emit notification interaction
    this.notificationClientsImportReadyFinal.emit(data); // Clients import ready, notify
  }

  /**
   * Salesforce events
   */
  onSalesforceClientsImportReady(data: any) {
    console.log('Notification. Salesforce clients import ready. ', data);
    this.notificationGenerated.emit(data); // Emit notification interaction
    this.notificationSalesforceClientsImportReady.emit(data); // Clients import ready, notify
  }

  onSalesforceClientsImportReadyFinal(data: any) {
    console.log('Notification. Salesforce clients import ready. ', data);
    this.notificationGenerated.emit(data);
    this.notificationSalesforceClientsImportReadyFinal.emit(data);
  }

  /**
   * Other events
   */
  onClientSharedWithMe(data: any) {
    console.log('Notification. A client was shared with you. ', data);
    this.notificationGenerated.emit(data); // Emit notification interaction
    this.notificationClientSharedWithMe.emit(data); // Clients shared, notify
  }

  onLeadReceived(data: any) {
    console.log('Notification. Here comes a new prospect. ', data);
    this.notificationGenerated.emit(data);
    this.notificationNewLeadReceived.emit(data);
  }

  onInvoicePaid(data: any) {
    console.log('Notification. Invoice paid.', data);
    this.notificationInvoicePaid.emit(data);
  }

  onEstateSnapshotStatusChange(data: any) {
    console.log('Notification. Estate Snapshot status change. ', data);
    this.notificationGenerated.emit(data);
  }

  onClientQuestionnaireAnswered(data: any){
    console.log('Client has answered the questionnaire', data);
    this.notificationGenerated.emit(data);
  }


  initSocketAndSubscribeToDefaultEvents() {

    // Listen to notifications only for advisor side
    if (getSideByContextInPriority() == SIDE_ADVISOR && this.isLocalStorageSessionStablished()) {

      let currentSocketMode = localStorage.getItem('socketType');
      console.log('SOCKET MODE: ' + currentSocketMode);

      // Initialize the current selected socket type
      this.socket = (currentSocketMode == 'WS') ? SocketIoWrapper.getInstance().socket : SocketFP.init(this.http, this.alternativeSocketUrl, SequentialJobsDispatcher.getInstance());

      this.socket.on('FileExtractionComplete', response => {
        this.getFilesValidationResponses(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('fpalpha:Recs', response => {
        this.getNotifications(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('clientsImportReady', response => {
        this.onClientsImportReady(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('clientsImportReadyFinal', response => {
        this.onClientsImportReadyFinal(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('salesforceClientsImportReady', response => {
        this.onSalesforceClientsImportReady(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('salesforceClientsImportReadyFinal', response => {
        this.onSalesforceClientsImportReadyFinal(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('NEW_SHARED_CLIENT', response => {
        this.onClientSharedWithMe(response)
        this.deleteSoketMesage(response);
      });
      this.socket.on('newLead', response => {
        this.onLeadReceived(response)
        this.deleteSoketMesage(response);
      });
      this.socket.on('invoicePaid', response => {
        this.onInvoicePaid(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('EstateSnapshot', response => {
        this.onEstateSnapshotStatusChange(response);
        this.deleteSoketMesage(response);
      });
      this.socket.on('fpalpha:Quest', response => {
        this.onClientQuestionnaireAnswered(response)
        this.deleteSoketMesage(response);
      });

    } else {
      console.log("ERROR. No local session available, can't connect to socket");
    }

  }

  /**
   * Delete a notification that resides in the backend queue,
   * only if the isFlushable property inside the response, is true.
   * @param socketResponse
   */
  deleteSoketMesage(socketResponse: SocketResponse){
    if(socketResponse.isFlushable){
      this.http.delete<any>(`${this.httpSocketUrl}/${socketResponse._id}`).subscribe();
     }
  }

  updateAreasWithRecsInLocalStorage(data?) {
    console.log('Updating Areas with Recs in local storage');
    let client = JSON.parse(sessionStorage.getItem('currentClient'));
    if (client && data.clientId === client.clientId) {
      console.log('Update areasWithRecommendationsReady on current client')
      this.advisorService.getClientDashboardData(client.clientId).toPromise().then(dashData => {
        console.log('Upadted areas with recs:', dashData.recommendationsAreReady);
        sessionStorage.setItem('areasWithRecommendationsReady', JSON.stringify(dashData.recommendationsAreReady));
      })
    }
  }

  socketClose() {
    if (Boolean(this.socket)) {
      this.socket.disconnect();
      console.log('socket closed', this.socket);
    } else {
      console.log('Undefined socket. Unable to close socket');
    }
  }

  isSocketOn(): boolean {
    return this.socket != undefined ? this.socket.connected : false
  }

  vertifySocketOrReconnect() {
    if (!this.isSocketOn() && getSideByContextInPriority() == SIDE_ADVISOR) {
      console.log("Socket is OFF. Reconnecting");
      this.initSocketAndSubscribeToDefaultEvents();
    } else {
      console.log("Socket is ON");
    }
  }

  isLocalStorageSessionStablished(): boolean {
    return Boolean(window.localStorage.getItem('sessionToken')) && Boolean(window.localStorage.getItem('userId'));
  }

}

class FileExtractionsResponse {

  constructor(
    public fileID: string,
    public validationStatus: string,
    public fileVal: string = '',
    public number: number = 0,
    public clientId?: string
  ) { }
}

export interface SocketResponse {
  _id: string;
  title: string;
  tag: string;
  owner: 'advisor' | 'client' | 'both';
  description: string;
  clientId: string;
  advisorId: string;
  whenCreated: Date;
  priority: 'High' | 'Medium' | 'Low'; //
  metadata?: any;
  isFlushable: boolean;
  status: 'pending' | 'opened';
  area: string

}
