import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-confirm-v2',
  templateUrl: './upload-confirm-v2.component.html',
  styleUrls: ['./upload-confirm-v2.component.scss']
})
export class UploadConfirmV2Component implements OnInit {

  public acceptedConditions: any = '';
  public insuranceFiles: any = [];
  public umbrellaFiles: any = [];
  constructor(
    public dialogRef: MatDialogRef<UploadConfirmV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('ATA: ', data);
    if (data?.keys && data?.files && Object.keys(data?.files).length > 0) {
      Object.keys(data?.files).forEach((uploaderKey) => {
        let uploader: any;
        if (data?.keys?.insurance && data?.files[uploaderKey][data?.keys?.insurance]) {
          uploader = data?.files[uploaderKey][data?.keys?.insurance]?.uploader;
          const file = uploader?.queue[0];
          this.insuranceFiles.push({
            ...data?.files[uploaderKey][data?.keys?.insurance],
            size: file?.file.size,
            name: file?.file?.name,
          });
        }
        if (data?.keys?.umbrella && data?.files[uploaderKey][data?.keys?.umbrella]) {
          uploader = data?.files[uploaderKey][data?.keys?.umbrella]?.uploader;
          const file = uploader?.queue[0];
          this.umbrellaFiles.push({
            ...data?.files[uploaderKey][data?.keys?.umbrella],
            size: file?.file.size,
            name: file?.file?.name,
          });
        }
      });
    }
  }

  ngOnInit() {
    console.log(this.insuranceFiles);
    console.log(this.umbrellaFiles);
  }


  closeModal() {
    this.dialogRef.close();
  }

  submitForm() {
    this.dialogRef.close(true);
  }

}
