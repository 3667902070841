//Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatButton} from '@angular/material/button';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { getEndPointUrlByContext, urlPrependRootContext } from '../../../shared/helpers/url.helper';

// Project
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public forgotPass: string = environment.apiAdvisorForgotPass;
  public userEmail: string;
  public hasRequestedNewPassword: boolean = false;

  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  constructor(
    private http: HttpClient,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(document.body, 'forgot-pass-page');
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'forgot-pass-page');
  }

  submitEmail() {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };
    let body = `email=${this.userEmail.toLowerCase()}`;

    this.http.post<any>(getEndPointUrlByContext('ForgotPass'), body, options)
      .subscribe(
        response => {
          this.hasRequestedNewPassword = true;
        },
        error => {
          console.log('Nay!', error);
          this.hasRequestedNewPassword = true;
        }
      );
  }

  returnToSignIn(){
    this.router.navigate([urlPrependRootContext('/signin')]);
  }
}
