import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'change-property-name-dialog',
  templateUrl: './change-property-name-dialog.component.html'
})
export class ChangePropertyDialogComponent extends CloseDialogAtLogOut {

  public assetText: string  = '';

  constructor(
    public dialogRef: MatDialogRef<ChangePropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
    }

    ngOnInit(): void {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
