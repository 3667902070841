import { Injectable } from '@angular/core';
import { DataPipe, DataManagerSubjects } from '../../model/DataPipe.model';
import { DataManager } from '../../classes/DataManager';
import { Router } from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';
import { tap, map, timeout, delay } from 'rxjs/operators';
import { ClientDataService } from '../client-data-service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  dataManager: DataManager;

  private _data: DataManagerSubjects;

  private NO_CLIENT_FOUNDED_URL = '../advisor/clients';

  private REFRESH_DELAY_THRESHOLD = 500;

  public loaded: boolean = false;

  get data(): DataManagerSubjects {
    // if you want to get data properties before call to initClient,
    // this force the client to init without client id (the DataManager is ready to handle a call
    // without idClient because looks for it in the session storage)
    if (!this._data) {
      this.initClient()
    }
    return this._data;
  }

  set data(value: DataManagerSubjects) {
    this._data = value;
  }

  constructor(public router: Router) { }

  setDataManager(dataManager: DataManager){
    this.dataManager = dataManager;
  }

  initClient(clientId?: string){
    //debugger;
    console.log('initClient', clientId);
    try {
      ClientDataService.unsubscribe();
      let response = this.dataManager.initManager(clientId).then(r=>{
        this.loaded = true;
      })
      this.data = this.dataManager.getData();
      return response;

      //this prop is just to simplify the access to data

    } catch (error) {
      console.error(error)
      /* this.router.navigate([this.NO_CLIENT_FOUNDED_URL]) */
    }
  }

  async getProperty( propExecutor: (data:any) => unknown, pipeSource: string ){
    const d = this.data;
    return await this.dataManager.createObservableProperty(propExecutor, pipeSource)
  }

  refreshAll(){
    timer(this.REFRESH_DELAY_THRESHOLD).subscribe(() => {
      this.dataManager.updateAll()
    });
  }

}
