import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UploadHistoryDialogComponent } from "../../dialogs/upload-history-dialog/upload-history-dialog.component";
import { PurchaseModalComponent } from "../purchase-modal/purchase-modal/purchase-modal.component";
import { AdvisorService } from "app/shared/services/advisor.service";

@Component({
  selector: "questionnaire-credit-box",
  templateUrl: "./questionnaire-credit-box.component.html",
  styleUrls: ["./questionnaire-credit-box.component.scss"],
})
export class QuestionnaireCreditBoxComponent implements OnInit, OnChanges {
  public creditsAvailable: number = 0;
  @Input() hasCredits: any = false;
  @Input() type: string = "auto";
  @Input() small: boolean = false;
  @Output() readyToSubmit = new EventEmitter();

  public isAdvisorOnTrial = false;
  public color = "green-1";
  public classForNumber: string = "";
  public textClass: string = "";
  public companyId: string;
  public uploadModelType: string;
  public loading: boolean = false;
  public historyTitles: any = {
    home: "Home and Umbrella",
    auto: "Auto",
    estate: "Estate",
    fulltax: "Tax",
  };
  public creditsUsed: number = 0;
  constructor(
    private dialog: MatDialog,
    private advisorService: AdvisorService
  ) {}

  ngOnInit(): void {
    this.getCreditsInformation();
  }
  buildClassForNumber() {
    console.log("Available credits", this.creditsAvailable);
    if (this.creditsAvailable < 5 && this.creditsAvailable > 1) {
      this.color = "yellow";
    } else if (this.creditsAvailable < 2) {
      this.color = "red";
    } else {
      this.color = "is-ok";
    }
    if (this.color === "red" && this.creditsAvailable < 2) {
      this.textClass = `questionnaire-credit-box${
        this.small ? "-small" : ""
      }--main-text fp-font-bold block color-red`;
    } else {
      this.textClass = `questionnaire-credit-box${
        this.small ? "-small" : ""
      }--main-text fp-font-bold block`;
    }
    return `questionnaire-credit-box${
      this.small ? "-small" : ""
    }--main-text--counter color-${this.color}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.classForNumber = this.buildClassForNumber();
  }

  /**
   * @name openUploadHistory
   * @description Opens a dialog to display the upload history with specific data about advisor uploaded docs to the platform
   */
  async openUploadHistory() {
    try {
      const dialogRef = this.dialog.open(UploadHistoryDialogComponent, {
        panelClass: "modal-dialog-white",
        width: "70vw",
        height: "80vh",
        maxHeight: "800px",
        data: {
          title: `History of ${this.historyTitles[this.type]} Uploads`,
          credits: this.creditsAvailable,
          creditsClass: "text-home-insurance",
          creditsUsed: this.creditsUsed,
          area: this.type,
          companyId: this.companyId,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        console.log("Se cerró");
      });
    } catch (e) {
      console.log(e);
    }
  }

  isInfinity(amount) {
    return typeof amount == "number"
      ? amount === Infinity
      : amount == "Infinity";
  }

  async getCreditsInformation() {
    try {
      console.log("Credits info:");
      if (!this.companyId) {
        await this.getAdvisorData();
      }
      console.log(this.companyId);
      const ans = await this.advisorService.getCompanyCreditsV2(
        this.companyId,
        this.type
      );
      this.creditsAvailable = ans[this.type]?.remaining;
      Object.keys(ans[this.type]).forEach((key) => {
        if (typeof ans[this.type][key] === "object") {
          this.creditsUsed += ans[this.type][key].used;
        }
      });
      this.classForNumber = this.buildClassForNumber();
      this.loading = false;
      if (this.readyToSubmit) {
        this.readyToSubmit.emit(true);
      }
    } catch (e) {
      console.log(e);
      this.loading = false
      if (this.readyToSubmit) {
        this.readyToSubmit.emit(false);
      }
    }
  }

  /**
   * @name getAdvisorData
   * @description Gets advisor data from the Advisor Service
   */
  async getAdvisorData() {
    try {
      this.loading = true;
      const data: any = await this.advisorService.getAdvisorDataPromise();
      this.companyId = data.companyId;
      data.isOnFreeTrialPeriod
        ? (this.isAdvisorOnTrial = true)
        : (this.isAdvisorOnTrial = false);
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
  }
}
