import { getRandomStr } from "app/shared/helpers/utils";

export function divideFamilyGraph(familyGraph: any) {
  let familyPages = [];

  let addPage = () =>{
    let page = {
      title: familyGraph[0].title,
      data: [],
      uid: getRandomStr(12),
      type: 'GRAPH_MIX',
    }
    familyPages.push(page);
  }
  addPage();

  let concatFamilyData = (section) => {
    familyPages[familyPages.length - 1]['data'].push(section);
  }

  // console.log('familyGraph to divide**', JSON.parse(JSON.stringify(familyGraph)));
  let rowsAvailable = 5;
  let rowsByFamilySection = 0;
  familyGraph[0].data.map((sectionFamily: any, idx) => {
    rowsByFamilySection = sectionFamily.grid.rowsNodesById.length;
    if(rowsByFamilySection <= 5){
      console.log('entra en el if rows**', rowsByFamilySection);
      console.log('rowsAvailable**', rowsAvailable);
      if(rowsAvailable >= 0 && (rowsAvailable >= rowsByFamilySection)) {
        concatFamilyData(sectionFamily);
        rowsAvailable -= sectionFamily.grid.rowsNodesById.length;
      }else{
        let copyOfSection = JSON.parse(JSON.stringify(sectionFamily));
        let copyOfGridNodes = JSON.parse(JSON.stringify(copyOfSection.grid.rowsNodesById))
        if(rowsAvailable > 0) {
          let gridToConcat = copyOfGridNodes.splice(0, rowsAvailable);
          sectionFamily.grid.rowsNodesById = gridToConcat;
          concatFamilyData(JSON.parse(JSON.stringify(sectionFamily)));
        }
        sectionFamily.grid.rowsNodesById = copyOfGridNodes;
        addPage();
        concatFamilyData(JSON.parse(JSON.stringify(sectionFamily)));
        rowsAvailable = 5;
        rowsAvailable -= sectionFamily.grid.rowsNodesById.length;
        // console.log('rowsAvailable after concat***', rowsAvailable);
      }
    }else{
      //numero de secciones de 5 rows (lo que entra en una pagina)
      let subsectionsRows = Math.ceil(sectionFamily.grid.rowsNodesById.length/5);
      let copyOfSection = JSON.parse(JSON.stringify(sectionFamily));
      let copyOfGridNodes = JSON.parse(JSON.stringify(copyOfSection.grid.rowsNodesById))
      for(let i = 0; i < subsectionsRows; i++){
        //secciones de 5 rows
        let dataGrid = copyOfGridNodes.splice(0, 5);
        let actualRows = familyPages[familyPages.length - 1]['data'].grid?.rowsNodesById?.length ? familyPages[familyPages.length - 1]['data'].grid?.rowsNodesById?.length : 0;

        if(!(actualRows + dataGrid.length <= 5)){
          actualRows = 0;
          addPage();
        }else{
          actualRows += dataGrid.length;
        }

        // console.log('index**', i, 'copyOfSection**', copyOfSection, 'dataGrid**', dataGrid);
        familyPages[familyPages.length - 1]['data'] = JSON.parse(JSON.stringify(copyOfSection));
        familyPages[familyPages.length - 1]['data'].grid.rowsNodesById = JSON.parse(JSON.stringify(dataGrid));
      }
    }
  })

  console.log('family pages**', JSON.parse(JSON.stringify(familyPages)));
  return familyPages;

}
