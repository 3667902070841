import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { readFromStoragedObject } from 'app/shared/helpers/utils';
import { AdvisorService } from 'app/shared/services/advisor.service';

import { CalculatorsComponent } from './calculators/calculators.component';
import { ScenariosComponent } from './scenarios/scenarios.component';
import { WarningSaveBeforeComponent } from './scenarios/warning-save-before/warning-save-before.component';

@Component({
  selector: 'app-tax-projections',
  templateUrl: './tax-projections.component.html',
  styleUrls: ['./tax-projections.component.scss']
})
export class TaxProjectionsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('calculatorsView', { static: false }) calculatorsView: CalculatorsComponent;
  @ViewChild('scenariosView', { static: false }) scenariosView: ScenariosComponent;
  @ViewChild('scenariosPrintableView', { static: true }) private scenariosPrintableView: ScenariosComponent;
  @Output() onScenarioDataChangeRelay = new EventEmitter<boolean>();
  // @ViewChild('scenarios©View', { static: false }) scenariosView: ScenariosComponent;

  public isLoading: boolean = false;

  public areScenariosVisible: boolean = true;
  public calculator: string;
  public scenariosData: any;
  public scenariosDataOnDestroy: any;
  public clientId: any;
  public activateRedirection: boolean = false;
  public isScenariosDirty: boolean = false;
  public subscription: any;
  public collapsedElements: Set<any> = new Set([]);
  public flagToGetCollapsed: boolean = false;
  public scenarioHasChanges: boolean = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public advisorService: AdvisorService,
  ) {}

  ngOnInit() {

    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');

    this.subscription = this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationStart && this.isScenariosDirty) {
        if (!this.activateRedirection && this.router.url.includes('tax-projections2.0') && !event.url.includes('tax-projections2.0') && !event.url.includes('signin')) {

          this.router.navigate(['/advisor/assets/tax-projections2.0']);
          let nextRoute = event.url;
          this.openSaveBefore(nextRoute);
        } else {

          this.router.navigate([event.url]);
        }
      } else if (!this.isScenariosDirty) {
        this.activateRedirection = true;
        this.subscription.unsubscribe();

        setTimeout(() => {
          this.router.navigate([event.url]);
        }, 100);
      }
    });
  }

  /**
   * The function toggles the visibility of a scenario based on its index.
   * @param idx is the index of the scenario to toggle visibility for.
   */
  toggleFromVisibleScenarios({idx, value}) {
    if (idx || idx === 0) {
      if (this.collapsedElements.has(idx)) {
        this.collapsedElements.delete(idx);
      } else {
        this.collapsedElements.add(idx);
      }
    }

    this.flagToGetCollapsed = !this.flagToGetCollapsed;
  }

    /**
   * Open modal to can save or ignore the changes before realized
   * @param nextUrl is the next url to navigate
   */
     openSaveBefore(nextUrl: string): void {
      const dialogRef = this.dialog.open(WarningSaveBeforeComponent, {
        disableClose: true,
        panelClass: 'modal-dialog-with-form',
        width: '724px',
        minHeight: '495px',
        data: { clientId: this.clientId }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === 'saveAll') {
          this.subscription.unsubscribe();
          this.saveAndCalculate();
          this.router.navigate([nextUrl]);

        } else if (data === 'notSave') {
          this.subscription.unsubscribe();
          this.activateRedirection = true;
          this.router.navigate([nextUrl]);
        }else if (data === 'cancel') {
        }
      });
    }

  onScenariosDestroy(data){

    this.scenariosDataOnDestroy = data;
  }


  ngAfterViewInit() {
  }

  /**
   * The function toggles the visibility of scenarios.
   */
  toggleScenariosCalculators() {
    this.areScenariosVisible = !this.areScenariosVisible;
  }

  getOnLoaded(isLoaded: any) {

  }

  /**
   * The function "goTo" sets the value of the "calculator" property to the provided "calculatorId" and
   * hides the scenarios and show the calculator selected
   * @param {string} calculatorId - The `calculatorId` parameter is a string that represents the ID of a calculator.
   */
  goTo(calculatorId: string){
    this.calculator = calculatorId;
    this.areScenariosVisible = false
  }

  /**
   * The function "goToScenarios" sets the visibility of scenarios based on a boolean input.
   * @param {boolean} scenarios - The "scenarios" parameter is a boolean value that determines if the scenarios should be visible or not.
   */
  goToScenarios(scenarios: boolean){
    this.areScenariosVisible = scenarios;
  }

  /**
   * The function markScenariosAsDirty sets the isScenariosDirty property based on the value of the event parameter.
   * @param event - The event parameter is a boolean value that indicates whether the scenarios should be marked as dirty or not.
   */
  markScenariosAsDirty(event){
    this.isScenariosDirty = event;
  }

  /**
   * The function saves and calculates scenarios using the advisor service.
   */
  saveAndCalculate(){
    if(this.scenariosView){
      this.scenariosDataOnDestroy = this.scenariosView.currentScenariosToSaveFormat();
    }
    this.advisorService.scenariosSaveAndCalculate(this.clientId, this.scenariosDataOnDestroy).then(response => {

    }).catch(error => {
      console.warn("Couldn't save and calculate scenarios: ", error);
    })
  }

  ngOnDestroy(): void {
    if (!this.isScenariosDirty) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * The function emits a boolean value when the scenario data changes.
   * @param {boolean} value - The value parameter is a boolean value that represents the new value of the scenario data.
   */
  onScenarioDataChange(value: boolean){
    this.onScenarioDataChangeRelay.emit(value);
  }
}
