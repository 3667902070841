import { GraphMap } from "../../graph-tool/GraphTool.class";

export const TEST_ESTATE_SNAPSHOT = {
  nodesType: "FlowChartBox",
  nodes: [
    {
      id: "1",
      content: {
        containerClass: "border-accent accent-blue-top content-center",
        content: [{ text: "Michael Marcus's Will", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "2",
      content: {
        containerClass: "border-accent accent-blue-top content-center",
        content: [{ text: "Tobi K. Marcus's Wil", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "3",
      content: {
        containerClass: "border-accent accent-orange-top content-center",
        content: [{ text: "Marcus Family Trust", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "4",
      content: {
        containerClass: "border-simple content-center",
        content: [{ text: "Upon the death of one Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "5",
      content: {
        containerClass: "border-accent accent-orange-left content-left",
        content: [{ text: "Survivor's Trust", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "6",
      content: {
        containerClass: "border-accent accent-orange-left content-center",
        content: [{ text: "Marital Trust", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "7",
      content: {
        containerClass: "border-accent accent-orange-left content-center",
        content: [{ text: "Bypass Trust", class: "bold" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "8",
      content: {
        containerClass: "border-simple",
        content: [{ text: "During the lifetime of Surviving Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "9",
      content: {
        containerClass: "border-simple",
        content: [{ text: "Upon the death of one Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "10",
      content: {
        containerClass: "border-simple",
        content: [{ text: "During the lifetime of Surviving Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "11",
      content: {
        containerClass: "border-simple",
        content: [{ text: "Upon the death of one Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "12",
      content: {
        containerClass: "border-simple",
        content: [{ text: "During the lifetime of Surviving Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "13",
      content: {
        containerClass: "border-simple",
        content: [{ text: "Upon the death of one Settlor" }],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "14",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Net Income and Principal " },
          { text: "Surviving Settlor", class: "bold" },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "15",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Personal Tangible Property" },
          { text: "Phoebe G. Marcus", class: "bold" },
          { text: "Josua W. Marcus", class: "bold" },
          { text: "", class: "separator" },
          { text: "Residuary Estate" },
          {
            text: "Phoebe G. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
          {
            text: "Josua W. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "16",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Net Income and Principal " },
          { text: "Surviving Settlor", class: "bold" },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "17",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Residuary Estate" },
          {
            text: "Phoebe G. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
          {
            text: "Josua W. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "18",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Net Income and Principal " },
          { text: "Surviving Settlor", class: "bold" },
          { text: "Josua W. Marcus", class: "bold" },
          { text: "Phoebe G. Marcus", class: "bold" },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "19",
      content: {
        containerClass: "border-none",
        content: [
          { text: "Residuary Estate" },
          {
            text: "Phoebe G. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
          {
            text: "Josua W. Marcus",
            class: "bold",
            textB: ["50%"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
  ],
  connections: [
    {
      sourceNodeId: "1",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "2",
          anchorOrigin: "R",
          anchorDestination: "L",
          metaData: {
            class: "solid",
          },
        },
      ],
    },
    {
      sourceNodeId: ["1", "2"],
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "3",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "solid",
          },
        },
      ],
    },
    {
      sourceNodeId: "3",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "4",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "4",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "5",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "dashed",
          },
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "6",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "dashed",
          },
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "7",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "dashed",
          },
        },
      ],
    },
    {
      sourceNodeId: "5",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "8",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "9",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "6",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "10",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "11",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "7",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "12",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "13",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "8",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "14",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "9",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "15",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "10",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "16",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "11",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "17",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "12",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "18",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "13",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "19",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
  ],
  grid: {
    maxGridCols: 6,
    rowsNodesById: [
      ["1", "2"],
      ["3"],
      ["4"],
      ["5", "6", "7"],
      ["8", "9", "10", "11", "12", "13"],
      ["14", "15", "16", "17", "18", "19"],
    ],
  },
};

export const TEST_ESTATE_SNAPSHOT_FIDUCIARIES = {
  nodesType: "FlowChartBox",
  nodes: [
    {
      id: "1",
      content: {
        containerClass: "annotation accent-blue-top",
        content: [
          { text: "Michael E. Marcus", class: "bold" },
          { text: "Signed  01/03/2019", class: "date" },
          { text: "", class: "separator-invisible" },
          {
            text: "Co-trustees",
            class: "textSimple",
            textB: [
              "Michael Marcus and Tobi K. Marcus (Survivor may continue as sole trustee)",
            ],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "Successor Trustee",
            class: "textSimple",
            textB: ["David Klein"],
            classB: "bold",
          },
          {
            text: "Successor Co-trustees",
            class: "textSimple",
            textB: [
              "Northern Trust Bank Of California",
              "Brian Marcus and Beth Klein Seligman",
            ],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
  ],
  connections: [],
  grid: {
    rowsNodesById: [["1"]],
  },
};

export const TEST_FAMILY_GRAPH = {
  nodesType: "FamilyBox",
  nodes: [
    {
      id: "1",
      content: {
        relation: "Client",
        title: "Client",
        name: "Franz J. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "a",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "b",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "2",
      content: {
        relation: "Co-Client",
        title: "Co-Client",
        name: "Erin C. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "3",
      content: {
        relation: "Child",
        title: "Child",
        name: "Colin M. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "4",
      content: {
        relation: "Child",
        title: "Child",
        name: "Brandin A. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "5",
      content: {
        relation: "Child",
        title: "Child",
        name: "Mike A. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "6",
      content: {
        relation: "Child",
        title: "Child",
        name: "Dylan A. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "7",
      content: {
        relation: "Child",
        title: "Child",
        name: "Shoshana R. Buss",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "8",
      content: {
        relation: "Child",
        title: "Child",
        name: "Spencer T. Buss",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "9",
      content: {
        relation: "Grandchild",
        title: "Grandchild",
        name: "Colin M. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "10",
      content: {
        relation: "Grandchild",
        title: "Grandchild",
        name: "Liam Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "11",
      content: {
        relation: "Grandchild",
        title: "Grandchild",
        name: "Daniel Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "12",
      content: {
        relation: "Grandchild",
        title: "Grandchild",
        name: "Spencer T. Buss",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "13",
      content: {
        relation: "Grandchild",
        title: "Grandchild",
        name: "Dwayne T. Buss",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "14",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "15",
      content: {
        relation: "Child",
        title: "Child",
        name: "Shoshana R. Buss",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "16",
      content: {
        relation: "Child",
        title: "Child",
        name: "Shoshana R. Buss 2",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "17",
      content: {
        relation: "Child",
        title: "Child",
        name: "Mike Buss 2",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    /* {
      id: "14",
      content: {
        relation:'Other',
        title:'Family Member',
        name: 'Michael Buss'
      },
      metaData: {
        class: "box-node",
       pathBlocker: false,
      },
    },
    {
      id: "15",
      content: {
        relation:'Other',
        title:'Other',
        name: 'Spencer T. Buss'
      },
      metaData: {
        class: "box-node",
       pathBlocker: false,
      },
    } */
  ],
  connections: [
    {
      sourceNodeId: "1",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "2",
          anchorOrigin: "R",
          anchorDestination: "L",
        },
      ],
    },
    {
      sourceNodeId: "1",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "3",
          anchorOrigin: "L",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "4",
          anchorOrigin: "L",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "9",
          anchorOrigin: "L",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "10",
          anchorOrigin: "L",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "15",
          anchorOrigin: "L",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: ["1", "2"],
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "5",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "6",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: ["1", "2"],
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "16",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "2",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "7",
          anchorOrigin: "R",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "8",
          anchorOrigin: "R",
          anchorDestination: "T",
        },
      ],
    },
    {
      sourceNodeId: "2",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "17",
          anchorOrigin: "R",
          anchorDestination: "T",
        },
      ],
    },
    /* {
      sourceNodeId: "4",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "9",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "10",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ]
    },
    {
      sourceNodeId: "5",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "11",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ]
    },
    {
      sourceNodeId: "8",
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "12",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "13",
          anchorOrigin: "B",
          anchorDestination: "T",
        },
      ]
    }, */
  ],
  grid: {
    maxGridCols: 6,
    rowsNodesById: [
      ["1", "2"],
      ["3", "4", "15", "5", "6", "16", "7", "17", "8"],
    ],
    rowsMetadata: [
      {
        style: {
          display: "flex",
          "align-items": "center",
        },
      },
      {
        style: {
          display: "flex",
          "align-items": "center",
        },
      },
      {
        style: {
          display: "flex",
          "align-items": "center",
        },
      },
      {
        style: {
          display: "flex",
          "align-items": "center",
        },
      },
    ],
  },
};

export const TEST_FAMILY_GRAPH_2 = {
  nodesType: "FamilyBox",
  nodes: [
    {
      id: "1",
      content: {
        type: "Client",
        title: "Client",
        name: "Franz J. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "2",
      content: {
        type: "Co-Client",
        title: "Co-Client",
        name: "Erin C. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "3",
      content: {
        type: "Child",
        title: "Child",
        name: "Colin M. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "4",
      content: {
        type: "Child",
        title: "Child",
        name: "Arthur M. Gaertner",
      },
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "a",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "b",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "c",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "d",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "e",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "f",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "g",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "h",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
    {
      id: "i",
      content: {},
      metaData: {
        class: "box-node",
        pathBlocker: false,
      },
    },
  ],
  connections: [
    {
      sourceNodeId: ["a", "b"],
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "1",
          anchorOrigin: "R",
          anchorDestination: "L",
        },
      ],
      isForced: true
    },
    {
      sourceNodeId: ["a", "b"],
      nodeConnections: [
        {
          type: "INDIRECT",
          pointsToNodeId: "3",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "solid",
          },
        },
        {
          type: "INDIRECT",
          pointsToNodeId: "4",
          anchorOrigin: "B",
          anchorDestination: "T",
          metaData: {
            class: "solid",
          },
        },
      ],
    },
  ],
  grid: {
    rowsNodesById: [
      ["a", "b", "1", "2", "c", "d"],
      ["3", "4", "f", "g", "h", "i"]
    ],
  },
  rowsMetadata: [
    {
      style: {
        display: "flex",
        "align-items": "center",
      },
    },
    {
      style: {
        display: "flex",
        "align-items": "center",
      },
    },
  ]
}

export const TEST_FAMILY_GRAPH_3 =
{
  "nodesType": "FamilyBox",
  "nodes": [
      {
          "id": "1",
          "content": {
              "type": "Client",
              "title": "Client",
              "name": "Franz J. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "a",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "b",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "2",
          "content": {
              "type": "Co-Client",
              "title": "Co-Client",
              "name": "Erin C. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "3",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Colin M. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "4",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Brandin A. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "5",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Mike A. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "6",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Dylan A. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "7",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Shoshana R. Buss"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "8",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Spencer T. Buss"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "9",
          "content": {
              "type": "Grandchild",
              "title": "Grandchild",
              "name": "Colin M. Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "10",
          "content": {
              "type": "Grandchild",
              "title": "Grandchild",
              "name": "Liam Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "11",
          "content": {
              "type": "Grandchild",
              "title": "Grandchild",
              "name": "Daniel Gaertner"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "12",
          "content": {
              "type": "Grandchild",
              "title": "Grandchild",
              "name": "Spencer T. Buss"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "13",
          "content": {
              "type": "Grandchild",
              "title": "Grandchild",
              "name": "Dwayne T. Buss"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "14",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "15",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Shoshana R. Buss"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "16",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Shoshana R. Buss 2"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "17",
          "content": {
              "type": "Child",
              "title": "Child",
              "name": "Mike Buss 2"
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "eqDlxTAlGt",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "amLYNzZe73",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": false
          }
      },
      {
          "id": "F0efU357RL",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "k79UXTZmWc",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "XZt0mZLhBr",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "pDIPn3MK5p",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "YwNZP6UQDw",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "7FI22DonCa",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "dMWxLbfy42",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "tkpEtfHs9W",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      },
      {
          "id": "4Ub5VopQEa",
          "content": {},
          "metaData": {
              "class": "box-node",
              "pathBlocker": true
          }
      }
  ],
  "connections": [
      {
          "sourceNodeId": "1",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "2",
                  "anchorOrigin": "R",
                  "anchorDestination": "L"
              },
              {
                "type": "INDIRECT",
                "pointsToNodeId": "3",
                "anchorOrigin": "L",
                "anchorDestination": "T"
            },
          ]
      },
      {
          "sourceNodeId": ["eqDlxTAlGt", "amLYNzZe73"],
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "3",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "4",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "9",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "10",
                  "anchorOrigin": "l",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "15",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "sourceNodeId": [
              "1",
              "2"
          ],
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "5",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "6",
                  "anchorOrigin": "B",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "sourceNodeId": [
              "1",
              "2"
          ],
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "16",
                  "anchorOrigin": "B",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "sourceNodeId": "2",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "7",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              },
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "8",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              }
          ]
      },
      {
          "sourceNodeId": "2",
          "nodeConnections": [
              {
                  "type": "INDIRECT",
                  "pointsToNodeId": "17",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              }
          ]
      }
  ],
  "grid": {
      "maxGridCols": 6,
      "rowsNodesById": [
          [
              "eqDlxTAlGt",
              "amLYNzZe73",
              "1",
              "2",
              "F0efU357RL",
              "k79UXTZmWc"
          ],
          [
              "3",
              "4",
              "5",
              "6",
              "7",
              "8"
          ],
          [
              "9",
              "10",
              "16",
              "XZt0mZLhBr",
              "17",
              "pDIPn3MK5p"
          ],
          [
              "15",
              "YwNZP6UQDw",
              "7FI22DonCa",
              "dMWxLbfy42",
              "tkpEtfHs9W",
              "4Ub5VopQEa"
          ]
      ],
      "rowsMetadata": [
          {
              "style": {
                  "display": "flex",
                  "align-items": "center"
              }
          },
          {
              "style": {
                  "display": "flex",
                  "align-items": "center"
              }
          },
          {
              "style": {
                  "display": "flex",
                  "align-items": "center"
              }
          },
          {
              "style": {
                  "display": "flex",
                  "align-items": "center"
              }
          }
      ]
    }
};

export const TEST_ESTATE_SNAPSHOT_PATY_F = {
  nodesType: "FlowChartBox",
  nodes: [
    {
      id: "443aa8a2-f215-4299-904d-db2b8a41347a",
      content: {
        containerClass: "border-simple",
        content: [
          {
            text: "Deana R. Goad's Will",
            class: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
        widthInNodes: 3
      },
    },
    {
      id: "142ba2e1-f6cb-49ac-8761-9b462835a6fc",
      content: {
        containerClass: "border-simple",
        content: [
          {
            text: "The Deana R. Goad Revocable Trust",
            class: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "1d8ae763-cfd1-415e-b592-498c46d6c880",
      content: {
        containerClass: "border-simple top undefined",
        content: [
          {
            text: "Upon the death of Deana if Fred survives ",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "0febb32e-b813-4d4b-8959-c9f9a03053cb",
      content: {
        containerClass: "invisible",
        content: [
          {
            text: "",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "246e62b8-d95a-4747-9269-c8b4402101b6",
      content: {
        containerClass: "border-simple undefined undefined",
        content: [
          {
            text: "Specific Bequests upon the death of Deana",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "c15df661-1e57-4d22-b443-624bfd12e44b",
      content: {
        containerClass: "border-none undefined undefined",
        content: [
          {
            text: "Personal Tangible Property",
          },
          {
            text: "Fred C. Goad  Jr.",
            class: "bold",
            textB: ["100%"],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "Cash Bequest",
          },
          {
            text: "Michael Jason Ross",
            class: "bold",
            textB: ["$250000"],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "$150,000 and Deana's interest in 132 Villanova Circle, Forney Texas\t",
          },
          {
            text: "Deana Ross Haley",
            class: "bold",
            textB: ["100%"],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "Cash Bequest",
          },
          {
            text: "John Matthew Pirtle",
            class: "bold",
            textB: ["$250000"],
            classB: "bold",
          },
          {
            text: "Joe George Jernigan",
            class: "bold",
            textB: ["$50000"],
            classB: "bold",
          },
          {
            text: "Hayden Alexander Jernigan",
            class: "bold",
            textB: ["$200000"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "e45b0486-d245-4c42-9646-6eea47bd86da",
      content: {
        containerClass: "border-simple",
        content: [
          {
            text: "Bypass Trust (Deana R. Goad Revocable Trust)",
            class: "bold",

          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "2ca3eb63-7b6e-4ec6-a42f-72423816bc4b",
      content: {
        containerClass: "border-simple undefined undefined",
        content: [
          {
            text: "During the lifetime of Fred",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "0f29f8ae-b9c8-46b4-aa38-9fb9628457de",
      content: {
        containerClass: "border-none undefined undefined",
        content: [
          {
            text: "Net Income and Principal",
          },
          {
            text: "Fred C. Goad  Jr.",
            class: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "5726d005-6e40-49ee-afa8-d8b4d14e0913",
      content: {
        containerClass: "border-simple undefined undefined",
        content: [
          {
            text: "Upon the death of Fred",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "e4ab262a-2804-4940-85e9-7cc4935065b4",
      content: {
        containerClass: "border-none undefined undefined",
        content: [
          {
            text: "Residuary Estate\t(unappointed)",
          },
          {
            text: "The Goad Family Foundation ",
            class: "bold",
            textB: ["33.33%"],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "Residuary Estate\t(unappointed)",
          },
          {
            text: "Francis  Bennett Goad Johnson",
            class: "bold",
            textB: ["66.66%"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "bb685d2a-d1a9-4263-948f-22549ce4f551",
      content: {
        containerClass: "border-simple",
        content: [
          {
            text: "Marital Trust (Deana R. Goad Revocable Trust)\t",
            class: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,

      },
    },
    {
      id: "b290e8ba-092c-4297-b46d-cc7300dc99fe",
      content: {
        containerClass: "border-simple undefined undefined",
        content: [
          {
            text: "During the lifetime of Fred",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "0a7d576d-047c-44b6-9be8-2f206dd6c64e",
      content: {
        containerClass: "border-none undefined undefined",
        content: [
          {
            text: "Net Income and Principal",
          },
          {
            text: "Fred C. Goad  Jr.",
            class: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "30feab93-80f8-42e3-ac0d-9ee54377ebfc",
      content: {
        containerClass: "border-simple undefined undefined",
        content: [
          {
            text: "Upon the death of Fred",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
    {
      id: "bc578ad5-9b19-45c3-851c-8fbe59dc7552",
      content: {
        containerClass: "border-none undefined undefined",
        content: [
          {
            text: "Residuary Estate\t(unappointed)",
          },
          {
            text: "The Goad Family Foundation ",
            class: "bold",
            textB: ["33.33%"],
            classB: "bold",
          },
          {
            text: "",
            class: "separator",
          },
          {
            text: "Residuary Estate\t(unappointed)",
          },
          {
            text: "Francis  Bennett Goad Johnson",
            class: "bold",
            textB: ["66.66%"],
            classB: "bold",
          },
        ],
      },
      metaData: {
        class: "box-node",
        pathBlocker: true,
      },
    },
  ],
  connections: [
    {
      sourceNodeId: "0febb32e-b813-4d4b-8959-c9f9a03053cb",
      nodeConnections: [],
    },
    {
      sourceNodeId: "c15df661-1e57-4d22-b443-624bfd12e44b",
      nodeConnections: [],
    },
    {
      sourceNodeId: "246e62b8-d95a-4747-9269-c8b4402101b6",
      nodeConnections: [
        {
          pointsToNodeId: "c15df661-1e57-4d22-b443-624bfd12e44b",
        },
      ],
    },
    {
      sourceNodeId: "0f29f8ae-b9c8-46b4-aa38-9fb9628457de",
      nodeConnections: [],
    },
    {
      sourceNodeId: "2ca3eb63-7b6e-4ec6-a42f-72423816bc4b",
      nodeConnections: [
        {
          pointsToNodeId: "0f29f8ae-b9c8-46b4-aa38-9fb9628457de",
        },
      ],
    },
    {
      sourceNodeId: "e4ab262a-2804-4940-85e9-7cc4935065b4",
      nodeConnections: [],
    },
    {
      sourceNodeId: "5726d005-6e40-49ee-afa8-d8b4d14e0913",
      nodeConnections: [
        {
          pointsToNodeId: "e4ab262a-2804-4940-85e9-7cc4935065b4",
        },
      ],
    },
    {
      sourceNodeId: "e45b0486-d245-4c42-9646-6eea47bd86da",
      nodeConnections: [
        {
          pointsToNodeId: "2ca3eb63-7b6e-4ec6-a42f-72423816bc4b",
        },
        {
          pointsToNodeId: "5726d005-6e40-49ee-afa8-d8b4d14e0913",
        },
      ],
    },
    {
      sourceNodeId: "0a7d576d-047c-44b6-9be8-2f206dd6c64e",
      nodeConnections: [],
    },
    {
      sourceNodeId: "b290e8ba-092c-4297-b46d-cc7300dc99fe",
      nodeConnections: [
        {
          pointsToNodeId: "0a7d576d-047c-44b6-9be8-2f206dd6c64e",
        },
      ],
    },
    {
      sourceNodeId: "bc578ad5-9b19-45c3-851c-8fbe59dc7552",
      nodeConnections: [],
    },
    {
      sourceNodeId: "30feab93-80f8-42e3-ac0d-9ee54377ebfc",
      nodeConnections: [
        {
          pointsToNodeId: "bc578ad5-9b19-45c3-851c-8fbe59dc7552",
        },
      ],
    },
    {
      sourceNodeId: "bb685d2a-d1a9-4263-948f-22549ce4f551",
      nodeConnections: [
        {
          pointsToNodeId: "b290e8ba-092c-4297-b46d-cc7300dc99fe",
        },
        {
          pointsToNodeId: "30feab93-80f8-42e3-ac0d-9ee54377ebfc",
        },
      ],
    },
    {
      sourceNodeId: "1d8ae763-cfd1-415e-b592-498c46d6c880",
      nodeConnections: [
        {
          pointsToNodeId: "246e62b8-d95a-4747-9269-c8b4402101b6",
        },
        {
          pointsToNodeId: "e45b0486-d245-4c42-9646-6eea47bd86da",
        },
        {
          pointsToNodeId: "bb685d2a-d1a9-4263-948f-22549ce4f551",
        },
      ],
    },
    {
      sourceNodeId: "142ba2e1-f6cb-49ac-8761-9b462835a6fc",
      nodeConnections: [
        {
          pointsToNodeId: "1d8ae763-cfd1-415e-b592-498c46d6c880",
        },
      ],
    },
    {
      sourceNodeId: "443aa8a2-f215-4299-904d-db2b8a41347a",
      nodeConnections: [
        {
          pointsToNodeId: "142ba2e1-f6cb-49ac-8761-9b462835a6fc",
        },
      ],
    },
  ],
  grid: {
    rowsNodesById: [
      ["443aa8a2-f215-4299-904d-db2b8a41347a"],
      ["142ba2e1-f6cb-49ac-8761-9b462835a6fc"],
      ["1d8ae763-cfd1-415e-b592-498c46d6c880"],
      [
        "0febb32e-b813-4d4b-8959-c9f9a03053cb",
        "e45b0486-d245-4c42-9646-6eea47bd86da",
        "bb685d2a-d1a9-4263-948f-22549ce4f551",
      ],
      [
        "246e62b8-d95a-4747-9269-c8b4402101b6",
        "2ca3eb63-7b6e-4ec6-a42f-72423816bc4b",
        "5726d005-6e40-49ee-afa8-d8b4d14e0913",
        "b290e8ba-092c-4297-b46d-cc7300dc99fe",
        "30feab93-80f8-42e3-ac0d-9ee54377ebfc",
      ],
      [
        "c15df661-1e57-4d22-b443-624bfd12e44b",
        "0f29f8ae-b9c8-46b4-aa38-9fb9628457de",
        "e4ab262a-2804-4940-85e9-7cc4935065b4",
        "0a7d576d-047c-44b6-9be8-2f206dd6c64e",
        "bc578ad5-9b19-45c3-851c-8fbe59dc7552",
      ],
    ],
  },
};

export const TEST_EXAMPLE_ES_2 = {
  "nodesType": "FlowChartBox",
  "nodes": [
    {
      "id": "60b71dc9-eb65-4c0f-bc0d-dc1119ab6d8b",
      "content": {
        "containerClass": " border-accent accent-blue-top content-center blue",
        "content": [
          {
            "text": "Franz J. Gaertner's Will",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "5ba44a50-8994-48e5-a51a-81b7e6e21629",
      "content": {
        "containerClass": " border-accent accent-blue-top content-center blue",
        "content": [
          {
            "text": "Erin C. Gaertner's Will",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "4047d02a-12e6-44d8-a4c6-93fec35d78d7",
      "content": {
        "containerClass": " content-center invisible",
        "content": [
          {
            "text": ""
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": false
      }
    },
    {
      "id": "033dfe02-7811-409b-8003-01ec90854a98",
      "content": {
        "containerClass": " content-center invisible",
        "content": [
          {
            "text": ""
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": false
      }
    },
    {
      "id": "c028bf8d-a808-4bea-a5fa-9c11d45bedb9",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "Specific Bequest before any death TEST"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "7b9a9940-3ece-4202-a17b-1a959ed7e353",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Personal Tangible Property"
          },
          {
            "text": "Collin M. Gaertner",
            "class": "bold",
            "textB": ["100%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "80074f85-9763-41d6-a22d-e6bf53a71dcd",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "Por que ya esta morido"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "20171d97-8e6b-407c-9ae1-8f5aed2db9fe",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Residuary Estate"
          },
          {
            "text": "Shoshana R. Buss",
            "class": "bold",
            "textB": ["100%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "1becc733-1d43-41dc-be07-af178acadc57",
      "content": {
        "containerClass": " border-accent accent-orange-top content-center orange",
        "content": [
          {
            "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "101bb6f9-d371-4f36-bd10-3d941db8c00f",
      "content": {
        "containerClass": " border-accent accent-orange-left content-center orange",
        "content": [
          {
            "text": "Survivor's Trust",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "6e001392-3cce-4f4c-b2f8-809f2a774177",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "During the lifetime and upon the death of the Surviving Settlor"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "4ae47035-7cff-4027-8add-56859758cd2a",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Net Income and Principal"
          },
          {
            "text": "Surviving Settlor ",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "0d812c3c-b38d-46ff-814c-e1df428ca789",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "Upon death of surviving spouse"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "2b1af18c-6ee4-4741-a4b0-542adbf2b55f",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Unappointed Residuary Estate"
          },
          {
            "text": "Shoshana R. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Collin M. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Spencer T. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Brandin A. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Settlor's Grandchildren ",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "fff20783-e7eb-4522-b33d-83183cbdb307",
      "content": {
        "containerClass": " border-accent accent-orange-left content-center orange",
        "content": [
          {
            "text": "Marital Trust",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "bd586c69-f5d5-4b0e-9fde-f7e3ce3325a0",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "During the lifetime and upon the death of the Surviving Settlor"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "07ed6f3c-80f6-4959-8eb4-2b75a976444f",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Net Income and Principal"
          },
          {
            "text": "Surviving Settlor ",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "55da74f2-41d8-408a-8c94-6673c4d2817d",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "Upon death of surviving spouse"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "dd0d86f0-47ba-4390-8d93-3aa552816a4d",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Unappointed Residuary Estate"
          },
          {
            "text": "Shoshana R. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Collin M. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Spencer T. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Brandin A. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Settlor's Grandchildren ",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "24d6ee49-60db-439a-92bd-ca841dd6c218",
      "content": {
        "containerClass": " border-accent accent-orange-left content-center orange",
        "content": [
          {
            "text": "Family Trust",
            "class": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "e5144c90-4002-4f87-8c97-f9eb11573c04",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "During the lifetime and upon the death of the Surviving Settlor"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "3a9dc589-db32-49ac-b9c2-8a67af1121aa",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Net Income and Principal"
          },
          {
            "text": "Surviving Settlor ",
            "class": "bold"
          },
          {
            "text": "Collin M. Gaertner",
            "class": "bold"
          },
          {
            "text": "Brandin A. Gaertner",
            "class": "bold"
          },
          {
            "text": "Shoshana R. Buss",
            "class": "bold"
          },
          {
            "text": "Spencer T. Buss",
            "class": "bold"
          },
          {
            "text": "",
            "class": "separator"
          },
          {
            "text": "Residuary Estate"
          },
          {
            "text": "Shoshana R. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Collin M. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Spencer T. Buss",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Brandin A. Gaertner",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          },
          {
            "text": "Settlor's Grandchildren ",
            "class": "bold",
            "textB": ["20%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "742ce2a1-454d-4011-9770-ac9131df3864",
      "content": {
        "containerClass": "border-simple content-center",
        "content": [
          {
            "text": "Upon death of surviving spouse"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    },
    {
      "id": "63513c29-2785-4cc2-8cbb-1bc178686c6a",
      "content": {
        "containerClass": "border-none",
        "content": [
          {
            "text": "Amount calculated as per the Funding formula for the Pet Trust"
          },
          {
            "text": "Spencer T. Buss",
            "class": "bold",
            "textB": ["100%"],
            "classB": "bold"
          }
        ]
      },
      "metaData": {
        "class": "box-node",
        "pathBlocker": true
      }
    }
  ],
  "connections": [
    {
      "sourceNodeId": "7b9a9940-3ece-4202-a17b-1a959ed7e353",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "c028bf8d-a808-4bea-a5fa-9c11d45bedb9",
      "nodeConnections": [
        {
          "pointsToNodeId": "7b9a9940-3ece-4202-a17b-1a959ed7e353"
        }
      ]
    },
    {
      "sourceNodeId": "20171d97-8e6b-407c-9ae1-8f5aed2db9fe",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "80074f85-9763-41d6-a22d-e6bf53a71dcd",
      "nodeConnections": [
        {
          "pointsToNodeId": "20171d97-8e6b-407c-9ae1-8f5aed2db9fe"
        }
      ]
    },
    {
      "sourceNodeId": "033dfe02-7811-409b-8003-01ec90854a98",
      "nodeConnections": [
        {
          "pointsToNodeId": "c028bf8d-a808-4bea-a5fa-9c11d45bedb9"
        },
        {
          "pointsToNodeId": "80074f85-9763-41d6-a22d-e6bf53a71dcd"
        }
      ]
    },
    {
      "sourceNodeId": "4047d02a-12e6-44d8-a4c6-93fec35d78d7",
      "nodeConnections": [
        {
          "pointsToNodeId": "033dfe02-7811-409b-8003-01ec90854a98"
        }
      ]
    },
    {
      "sourceNodeId": "4ae47035-7cff-4027-8add-56859758cd2a",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "6e001392-3cce-4f4c-b2f8-809f2a774177",
      "nodeConnections": [
        {
          "pointsToNodeId": "4ae47035-7cff-4027-8add-56859758cd2a"
        }
      ]
    },
    {
      "sourceNodeId": "2b1af18c-6ee4-4741-a4b0-542adbf2b55f",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "0d812c3c-b38d-46ff-814c-e1df428ca789",
      "nodeConnections": [
        {
          "pointsToNodeId": "2b1af18c-6ee4-4741-a4b0-542adbf2b55f"
        }
      ]
    },
    {
      "sourceNodeId": "101bb6f9-d371-4f36-bd10-3d941db8c00f",
      "nodeConnections": [
        {
          "pointsToNodeId": "6e001392-3cce-4f4c-b2f8-809f2a774177"
        },
        {
          "pointsToNodeId": "0d812c3c-b38d-46ff-814c-e1df428ca789"
        }
      ]
    },
    {
      "sourceNodeId": "07ed6f3c-80f6-4959-8eb4-2b75a976444f",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "bd586c69-f5d5-4b0e-9fde-f7e3ce3325a0",
      "nodeConnections": [
        {
          "pointsToNodeId": "07ed6f3c-80f6-4959-8eb4-2b75a976444f"
        }
      ]
    },
    {
      "sourceNodeId": "dd0d86f0-47ba-4390-8d93-3aa552816a4d",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "55da74f2-41d8-408a-8c94-6673c4d2817d",
      "nodeConnections": [
        {
          "pointsToNodeId": "dd0d86f0-47ba-4390-8d93-3aa552816a4d"
        }
      ]
    },
    {
      "sourceNodeId": "fff20783-e7eb-4522-b33d-83183cbdb307",
      "nodeConnections": [
        {
          "pointsToNodeId": "bd586c69-f5d5-4b0e-9fde-f7e3ce3325a0"
        },
        {
          "pointsToNodeId": "55da74f2-41d8-408a-8c94-6673c4d2817d"
        }
      ]
    },
    {
      "sourceNodeId": "3a9dc589-db32-49ac-b9c2-8a67af1121aa",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "e5144c90-4002-4f87-8c97-f9eb11573c04",
      "nodeConnections": [
        {
          "pointsToNodeId": "3a9dc589-db32-49ac-b9c2-8a67af1121aa"
        }
      ]
    },
    {
      "sourceNodeId": "63513c29-2785-4cc2-8cbb-1bc178686c6a",
      "nodeConnections": []
    },
    {
      "sourceNodeId": "742ce2a1-454d-4011-9770-ac9131df3864",
      "nodeConnections": [
        {
          "pointsToNodeId": "63513c29-2785-4cc2-8cbb-1bc178686c6a"
        }
      ]
    },
    {
      "sourceNodeId": "24d6ee49-60db-439a-92bd-ca841dd6c218",
      "nodeConnections": [
        {
          "pointsToNodeId": "e5144c90-4002-4f87-8c97-f9eb11573c04"
        },
        {
          "pointsToNodeId": "742ce2a1-454d-4011-9770-ac9131df3864"
        }
      ]
    },
    {
      "sourceNodeId": "1becc733-1d43-41dc-be07-af178acadc57",
      "nodeConnections": [
        {
          "pointsToNodeId": "101bb6f9-d371-4f36-bd10-3d941db8c00f"
        },
        {
          "pointsToNodeId": "fff20783-e7eb-4522-b33d-83183cbdb307"
        },
        {
          "pointsToNodeId": "24d6ee49-60db-439a-92bd-ca841dd6c218"
        }
      ]
    },
    {
      "sourceNodeId": "60b71dc9-eb65-4c0f-bc0d-dc1119ab6d8b",
      "nodeConnections": [
        {
          "pointsToNodeId": "4047d02a-12e6-44d8-a4c6-93fec35d78d7"
        },
        {
          "pointsToNodeId": "1becc733-1d43-41dc-be07-af178acadc57"
        }
      ]
    },
    {
      "sourceNodeId": "5ba44a50-8994-48e5-a51a-81b7e6e21629",
      "nodeConnections": [
        {
          "pointsToNodeId": "4047d02a-12e6-44d8-a4c6-93fec35d78d7"
        },
        {
          "pointsToNodeId": "1becc733-1d43-41dc-be07-af178acadc57"
        }
      ]
    }
  ],
  "grid": {
    "rowsNodesById": [
      ["60b71dc9-eb65-4c0f-bc0d-dc1119ab6d8b", "5ba44a50-8994-48e5-a51a-81b7e6e21629"],
      ["4047d02a-12e6-44d8-a4c6-93fec35d78d7", "1becc733-1d43-41dc-be07-af178acadc57"],
      [
        "033dfe02-7811-409b-8003-01ec90854a98",
        "101bb6f9-d371-4f36-bd10-3d941db8c00f",
        "fff20783-e7eb-4522-b33d-83183cbdb307",
        "24d6ee49-60db-439a-92bd-ca841dd6c218"
      ],
      [
        "c028bf8d-a808-4bea-a5fa-9c11d45bedb9",
        "80074f85-9763-41d6-a22d-e6bf53a71dcd",
        "6e001392-3cce-4f4c-b2f8-809f2a774177",
        "0d812c3c-b38d-46ff-814c-e1df428ca789",
        "bd586c69-f5d5-4b0e-9fde-f7e3ce3325a0",
        "55da74f2-41d8-408a-8c94-6673c4d2817d",
        "e5144c90-4002-4f87-8c97-f9eb11573c04",
        "742ce2a1-454d-4011-9770-ac9131df3864"
      ],
      [
        "7b9a9940-3ece-4202-a17b-1a959ed7e353",
        "20171d97-8e6b-407c-9ae1-8f5aed2db9fe",
        "4ae47035-7cff-4027-8add-56859758cd2a",
        "2b1af18c-6ee4-4741-a4b0-542adbf2b55f",
        "07ed6f3c-80f6-4959-8eb4-2b75a976444f",
        "dd0d86f0-47ba-4390-8d93-3aa552816a4d",
        "3a9dc589-db32-49ac-b9c2-8a67af1121aa",
        "63513c29-2785-4cc2-8cbb-1bc178686c6a"
      ]
    ]
  }
}

export const TEST_EXAMPLE_MATERIAL_INFORMATION_TRUST ={
  "nodesType": "FlowChartBox",
  "nodes": [
    {
        "id": "0d050551-1d4a-4fd8-a64a-803ade9bdd31",
        "content": {
            "containerClass": "annotation accent-orange-top content-centerd",
            "content": [
                {
                    "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                    "class": "bold"
                },
                {
                    "text": "Signed 05/03/2024",
                    "class": ""
                },
                {
                  "text": "",
                  "class": "separator"
                },
                {
                    "text": "Co-trustees",
                    "class": "",
                    "textB": [
                        "Franz J. Gaertner, Erin C. Gaertner"
                    ],
                    "classB": "bold"
                },
                {
                    "text": "Successor Trustees",
                    "class": "",
                    "textB": [
                        "Carol Sullivan",
                        "Robert Weiderman"
                    ],
                    "classB": "bold"
                }
            ]
        },
        "metaData": {
            "class": "box-node",
            "pathBlocker": true,
            widthInNodes: 2
        }
    },
    {
        "id": "8ffe095c-49cb-4739-98f4-2e7983fe9a63",
        "content": {
            "containerClass": "border-simple content-center",
            "content": [
                {
                    "text": "Upon the death of the first Settlor"
                }
            ]
        },
        "metaData": {
            "class": "box-node",
            "pathBlocker": true
        }
    },
    {
        "id": "6860b3d5-3502-4e52-a6c9-d61f57c58785",
        "content": {
            "containerClass": " border-accent accent-orange-left content-center orange",
            "content": [
                {
                    "text": "Survivor's Trust",
                    "class": "bold"
                }
            ]
        },
        "metaData": {
            "class": "box-node",
            "pathBlocker": true
        }
    },
    {
        "id": "e5d0b16f-c83a-4c43-9289-940b3a9a29d2",
        "content": {
            "containerClass": " border-accent accent-orange-left content-center orange",
            "content": [
                {
                    "text": "Marital Trust",
                    "class": "bold"
                }
            ]
        },
        "metaData": {
            "class": "box-node",
            "pathBlocker": true
        }
    },
    {
        "id": "fb29d40b-c6e6-48d4-8aa8-86923713639a",
        "content": {
            "containerClass": " border-accent accent-orange-left content-center orange",
            "content": [
                {
                    "text": "Family Trust",
                    "class": "bold"
                }
            ]
        },
        "metaData": {
            "class": "box-node",
            "pathBlocker": true
        }
    }
],
"connections": [
    {
        "sourceNodeId": "6860b3d5-3502-4e52-a6c9-d61f57c58785",
        "nodeConnections": []
    },
    {
        "sourceNodeId": "e5d0b16f-c83a-4c43-9289-940b3a9a29d2",
        "nodeConnections": []
    },
    {
        "sourceNodeId": "fb29d40b-c6e6-48d4-8aa8-86923713639a",
        "nodeConnections": []
    },
    {
        "sourceNodeId": "8ffe095c-49cb-4739-98f4-2e7983fe9a63",
        "nodeConnections": [
            {
                "pointsToNodeId": "6860b3d5-3502-4e52-a6c9-d61f57c58785"
            },
            {
                "pointsToNodeId": "e5d0b16f-c83a-4c43-9289-940b3a9a29d2"
            },
            {
                "pointsToNodeId": "fb29d40b-c6e6-48d4-8aa8-86923713639a"
            }
        ]
    },
    {
        "sourceNodeId": "0d050551-1d4a-4fd8-a64a-803ade9bdd31",
        "nodeConnections": [
            {
                "pointsToNodeId": "8ffe095c-49cb-4739-98f4-2e7983fe9a63"
            }
        ]
    }
],
"grid": {
    "rowsNodesById": [
        [
            "0d050551-1d4a-4fd8-a64a-803ade9bdd31"
        ],
        [
            "8ffe095c-49cb-4739-98f4-2e7983fe9a63"
        ],
        [
            "6860b3d5-3502-4e52-a6c9-d61f57c58785",
            "e5d0b16f-c83a-4c43-9289-940b3a9a29d2",
            "fb29d40b-c6e6-48d4-8aa8-86923713639a"
        ]
    ]
}
}

export const TEST_EXAMPLE_3 = {
  "dataType": "graph",
  nodesType: "FlowChartBox",
  "nodes": [
      {
          "id": "62a71a4c-7244-4570-a1d8-47c2ed39f383",
          "content": {
              "containerClass": " border-accent accent-blue-top content-center blue",
              "content": [
                  {
                      "text": "Franz J. Gaertner's Will",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 2,
              "type": "joint"
          }
      },
      {
          "id": "0b4d00b4-54b1-4964-b9fd-3e0dd4bc448f",
          "content": {
              "containerClass": " border-accent accent-blue-top content-center blue",
              "content": [
                  {
                      "text": "Erin C. Gaertner's Will",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 2,
              "type": "joint"
          }
      },
      {
          "id": "7d329813-1a30-4801-82d3-0cd22ba3a629",
          "content": {
              "containerClass": " content-center invisible",
              "content": [
                  {
                      "text": ""
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 1,
              "type": "trigger"
          }
      },
      {
          "id": "b684c8c6-0b19-4cd2-95e4-6b1119de1946",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Specific Bequest before any death TEST"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "empty"
          }
      },
      {
          "id": "2370c5db-532e-4077-9143-64787af0959a",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Personal Tangible Property"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold",
                      "textB": [
                          "100%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "fe9b22b5-0447-49ae-b6db-030568b6b290",
          "content": {
              "containerClass": " content-center invisible",
              "content": [
                  {
                      "text": ""
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 1,
              "type": "trigger"
          }
      },
      {
          "id": "e7d82a08-7977-4b43-ac11-2a344fbe1e84",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Por que ya esta morido"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "empty"
          }
      },
      {
          "id": "72ece178-7aae-489f-9895-12c6f02e09fe",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Residuary Estate"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold",
                      "textB": [
                          "100%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "efcf5447-9fe6-485b-9903-a81b93bc59c7",
          "content": {
              "containerClass": " content-center invisible",
              "content": [
                  {
                      "text": ""
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 1,
              "type": "trigger"
          }
      },
      {
          "id": "60953379-1d2d-47e1-ba69-eeddbfcc1017",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Specific Bequest before any death TEST"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "empty"
          }
      },
      {
          "id": "4aa1521e-2baf-4c40-8388-f67b19f3ab80",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Personal Tangible Property"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold",
                      "textB": [
                          "100%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "c59cd1b3-e81b-415c-ae17-e8f2cd8f400e",
          "content": {
              "containerClass": " content-center invisible",
              "content": [
                  {
                      "text": ""
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 1,
              "type": "trigger"
          }
      },
      {
          "id": "ea8d0fde-3b89-4260-89d2-1f3301225d91",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Por que ya esta morido"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "empty"
          }
      },
      {
          "id": "a9b80f5e-886e-46e8-a74c-438515fa27b6",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Residuary Estate"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold",
                      "textB": [
                          "100%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "6da1ef56-7c27-40a5-8179-ca9cd3468452",
          "content": {
              "containerClass": " border-accent accent-orange-top content-center orange",
              "content": [
                  {
                      "text": "The Franz J. and Erin C. Gaertner Revocable Trust dated September 23, 2009",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 3,
              "type": "trust"
          }
      },
      {
          "id": "4bdef487-e0f6-42b4-9cd9-95ebbf93ce39",
          "content": {
              "containerClass": " border-accent accent-orange-left content-center orange",
              "content": [
                  {
                      "text": "Survivor's Trust",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 2,
              "type": "trust"
          }
      },
      {
          "id": "17fb610b-703c-4cfb-bdc3-df56f2290ca3",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "36473f0f-6023-41c2-b3c4-7c1436265991",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Net Income and Principal"
                  },
                  {
                      "text": "Surviving Settlor ",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "28a9b760-0dd2-4c14-91f1-4f90b5f05538",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Upon death of surviving spouse"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "0ec49de1-f58b-476c-b151-35a21fb48b21",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Unappointed Residuary Estate"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Brandin A. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Settlor's Grandchildren ",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "7799b086-a2d6-420c-823b-75fbac803cd9",
          "content": {
              "containerClass": " border-accent accent-orange-left content-center orange",
              "content": [
                  {
                      "text": "Marital Trust",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 2,
              "type": "trust"
          }
      },
      {
          "id": "49997680-ce9a-4a53-9f2e-87c3ca08f885",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "94517c18-5948-4711-8819-985bbec1644a",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Net Income and Principal"
                  },
                  {
                      "text": "Surviving Settlor ",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "230621fe-24b7-449d-9826-0d08466dcfa7",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Upon death of surviving spouse"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "7e6f2f60-da2a-46f7-bfe0-1366849a7158",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Unappointed Residuary Estate"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Brandin A. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Settlor's Grandchildren ",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "860ea5f6-b150-4d30-a69d-c934a8d8e686",
          "content": {
              "containerClass": " border-accent accent-orange-left content-center orange",
              "content": [
                  {
                      "text": "Family Trust",
                      "class": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 2,
              "type": "trust"
          }
      },
      {
          "id": "5ddfd2c2-7137-4de4-ab31-044ffeb5bd39",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "During the lifetime and upon the death of the Surviving Settlor"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "ac406ad2-25fc-4aa0-ba54-6dd6aa5dd203",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Net Income and Principal"
                  },
                  {
                      "text": "Surviving Settlor ",
                      "class": "bold"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold"
                  },
                  {
                      "text": "Brandin A. Gaertner",
                      "class": "bold"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold"
                  },
                  {
                      "text": "",
                      "class": "separator"
                  },
                  {
                      "text": "Residuary Estate"
                  },
                  {
                      "text": "Shoshana R. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Collin M. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Brandin A. Gaertner",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Settlor's Grandchildren ",
                      "class": "bold",
                      "textB": [
                          "20%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "2017512c-4109-4e68-9d30-8567154f30ee",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "Upon death of surviving spouse"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "trigger"
          }
      },
      {
          "id": "14f7471c-fff2-41fe-bea3-8b8f3bb9f869",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Amount calculated as per the Funding formula for the Pet Trust"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold",
                      "textB": [
                          "100%"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      },
      {
          "id": "f875aa52-2ae7-4de1-84b2-4d9dec9d8c81",
          "content": {
              "containerClass": " content-center invisible",
              "content": [
                  {
                      "text": ""
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "widthInNodes": 1,
              "type": "trigger"
          }
      },
      {
          "id": "377cb70d-f5a9-431d-89d5-081a7ade1409",
          "content": {
              "containerClass": "border-simple content-center",
              "content": [
                  {
                      "text": "During the lifetime of Erin Gaertner"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "empty"
          }
      },
      {
          "id": "47bfdc56-4bec-45d4-bbcb-8aeefd5a3a66",
          "content": {
              "containerClass": "border-none",
              "content": [
                  {
                      "text": "Residuary Estate"
                  },
                  {
                      "text": "Brandin A. Gaertner",
                      "class": "bold",
                      "textB": [
                          "$50"
                      ],
                      "classB": "bold"
                  },
                  {
                      "text": "Spencer T. Buss",
                      "class": "bold",
                      "textB": [
                          "$50"
                      ],
                      "classB": "bold"
                  }
              ]
          },
          "metaData": {
              "class": "box-node",
              "pathBlocker": true,
              "type": "beneficiaries"
          }
      }
  ],
  "connections": [
      {
          "sourceNodeId": "7d329813-1a30-4801-82d3-0cd22ba3a629",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "2370c5db-532e-4077-9143-64787af0959a",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "b684c8c6-0b19-4cd2-95e4-6b1119de1946",
          "nodeConnections": [
              {
                  "pointsToNodeId": "2370c5db-532e-4077-9143-64787af0959a"
              }
          ]
      },
      {
          "sourceNodeId": "fe9b22b5-0447-49ae-b6db-030568b6b290",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "72ece178-7aae-489f-9895-12c6f02e09fe",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "e7d82a08-7977-4b43-ac11-2a344fbe1e84",
          "nodeConnections": [
              {
                  "pointsToNodeId": "72ece178-7aae-489f-9895-12c6f02e09fe"
              }
          ]
      },
      {
          "sourceNodeId": "efcf5447-9fe6-485b-9903-a81b93bc59c7",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "4aa1521e-2baf-4c40-8388-f67b19f3ab80",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "60953379-1d2d-47e1-ba69-eeddbfcc1017",
          "nodeConnections": [
              {
                  "pointsToNodeId": "4aa1521e-2baf-4c40-8388-f67b19f3ab80"
              }
          ]
      },
      {
          "sourceNodeId": "c59cd1b3-e81b-415c-ae17-e8f2cd8f400e",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "a9b80f5e-886e-46e8-a74c-438515fa27b6",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "ea8d0fde-3b89-4260-89d2-1f3301225d91",
          "nodeConnections": [
              {
                  "pointsToNodeId": "a9b80f5e-886e-46e8-a74c-438515fa27b6"
              }
          ]
      },
      {
          "sourceNodeId": "36473f0f-6023-41c2-b3c4-7c1436265991",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "17fb610b-703c-4cfb-bdc3-df56f2290ca3",
          "nodeConnections": [
              {
                  "pointsToNodeId": "36473f0f-6023-41c2-b3c4-7c1436265991"
              }
          ]
      },
      {
          "sourceNodeId": "0ec49de1-f58b-476c-b151-35a21fb48b21",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "28a9b760-0dd2-4c14-91f1-4f90b5f05538",
          "nodeConnections": [
              {
                  "pointsToNodeId": "0ec49de1-f58b-476c-b151-35a21fb48b21"
              }
          ]
      },
      {
          "sourceNodeId": "4bdef487-e0f6-42b4-9cd9-95ebbf93ce39",
          "nodeConnections": [
              {
                  "pointsToNodeId": "17fb610b-703c-4cfb-bdc3-df56f2290ca3"
              },
              {
                  "pointsToNodeId": "28a9b760-0dd2-4c14-91f1-4f90b5f05538"
              }
          ]
      },
      {
          "sourceNodeId": "94517c18-5948-4711-8819-985bbec1644a",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "49997680-ce9a-4a53-9f2e-87c3ca08f885",
          "nodeConnections": [
              {
                  "pointsToNodeId": "94517c18-5948-4711-8819-985bbec1644a"
              }
          ]
      },
      {
          "sourceNodeId": "7e6f2f60-da2a-46f7-bfe0-1366849a7158",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "230621fe-24b7-449d-9826-0d08466dcfa7",
          "nodeConnections": [
              {
                  "pointsToNodeId": "7e6f2f60-da2a-46f7-bfe0-1366849a7158"
              }
          ]
      },
      {
          "sourceNodeId": "7799b086-a2d6-420c-823b-75fbac803cd9",
          "nodeConnections": [
              {
                  "pointsToNodeId": "49997680-ce9a-4a53-9f2e-87c3ca08f885"
              },
              {
                  "pointsToNodeId": "230621fe-24b7-449d-9826-0d08466dcfa7"
              }
          ]
      },
      {
          "sourceNodeId": "ac406ad2-25fc-4aa0-ba54-6dd6aa5dd203",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "5ddfd2c2-7137-4de4-ab31-044ffeb5bd39",
          "nodeConnections": [
              {
                  "pointsToNodeId": "ac406ad2-25fc-4aa0-ba54-6dd6aa5dd203"
              }
          ]
      },
      {
          "sourceNodeId": "14f7471c-fff2-41fe-bea3-8b8f3bb9f869",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "2017512c-4109-4e68-9d30-8567154f30ee",
          "nodeConnections": [
              {
                  "pointsToNodeId": "14f7471c-fff2-41fe-bea3-8b8f3bb9f869"
              }
          ]
      },
      {
          "sourceNodeId": "860ea5f6-b150-4d30-a69d-c934a8d8e686",
          "nodeConnections": [
              {
                  "pointsToNodeId": "5ddfd2c2-7137-4de4-ab31-044ffeb5bd39"
              },
              {
                  "pointsToNodeId": "2017512c-4109-4e68-9d30-8567154f30ee"
              }
          ]
      },
      {
          "sourceNodeId": "6da1ef56-7c27-40a5-8179-ca9cd3468452",
          "nodeConnections": [
              {
                  "pointsToNodeId": "4bdef487-e0f6-42b4-9cd9-95ebbf93ce39"
              },
              {
                  "pointsToNodeId": "7799b086-a2d6-420c-823b-75fbac803cd9"
              },
              {
                  "pointsToNodeId": "860ea5f6-b150-4d30-a69d-c934a8d8e686"
              }
          ]
      },
      {
          "sourceNodeId": "62a71a4c-7244-4570-a1d8-47c2ed39f383",
          "nodeConnections": [
              {
                  "pointsToNodeId": "b684c8c6-0b19-4cd2-95e4-6b1119de1946",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "pointsToNodeId": "e7d82a08-7977-4b43-ac11-2a344fbe1e84",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "pointsToNodeId": "60953379-1d2d-47e1-ba69-eeddbfcc1017",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "pointsToNodeId": "ea8d0fde-3b89-4260-89d2-1f3301225d91",
                  "anchorOrigin": "L",
                  "anchorDestination": "T"
              },
              {
                  "pointsToNodeId": "0b4d00b4-54b1-4964-b9fd-3e0dd4bc448f",
                  "anchorOrigin": "R",
                  "anchorDestination": "L"
              }
          ]
      },
      {
          "sourceNodeId": [
              "62a71a4c-7244-4570-a1d8-47c2ed39f383",
              "0b4d00b4-54b1-4964-b9fd-3e0dd4bc448f"
          ],
          "nodeConnections": [
              {
                  "pointsToNodeId": "6da1ef56-7c27-40a5-8179-ca9cd3468452"
              }
          ]
      },
      {
          "sourceNodeId": "f875aa52-2ae7-4de1-84b2-4d9dec9d8c81",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "47bfdc56-4bec-45d4-bbcb-8aeefd5a3a66",
          "nodeConnections": []
      },
      {
          "sourceNodeId": "377cb70d-f5a9-431d-89d5-081a7ade1409",
          "nodeConnections": [
              {
                  "pointsToNodeId": "47bfdc56-4bec-45d4-bbcb-8aeefd5a3a66"
              }
          ]
      },
      {
          "sourceNodeId": "62a71a4c-7244-4570-a1d8-47c2ed39f383",
          "nodeConnections": [
              {
                  "pointsToNodeId": "377cb70d-f5a9-431d-89d5-081a7ade1409",
                  "anchorOrigin": "R",
                  "anchorDestination": "T"
              }
          ]
      }
  ],
  "grid": {
      "rowsNodesById": [
          [
              "62a71a4c-7244-4570-a1d8-47c2ed39f383",
              "0b4d00b4-54b1-4964-b9fd-3e0dd4bc448f"
          ],
          [
              "6da1ef56-7c27-40a5-8179-ca9cd3468452"
          ],
          [
              "7d329813-1a30-4801-82d3-0cd22ba3a629",
              "fe9b22b5-0447-49ae-b6db-030568b6b290",
              "efcf5447-9fe6-485b-9903-a81b93bc59c7",
              "c59cd1b3-e81b-415c-ae17-e8f2cd8f400e",
              "4bdef487-e0f6-42b4-9cd9-95ebbf93ce39",
              "7799b086-a2d6-420c-823b-75fbac803cd9",
              "860ea5f6-b150-4d30-a69d-c934a8d8e686",
              "f875aa52-2ae7-4de1-84b2-4d9dec9d8c81"
          ],
          [
              "b684c8c6-0b19-4cd2-95e4-6b1119de1946",
              "e7d82a08-7977-4b43-ac11-2a344fbe1e84",
              "60953379-1d2d-47e1-ba69-eeddbfcc1017",
              "ea8d0fde-3b89-4260-89d2-1f3301225d91",
              "17fb610b-703c-4cfb-bdc3-df56f2290ca3",
              "28a9b760-0dd2-4c14-91f1-4f90b5f05538",
              "49997680-ce9a-4a53-9f2e-87c3ca08f885",
              "230621fe-24b7-449d-9826-0d08466dcfa7",
              "5ddfd2c2-7137-4de4-ab31-044ffeb5bd39",
              "2017512c-4109-4e68-9d30-8567154f30ee",
              "377cb70d-f5a9-431d-89d5-081a7ade1409"
          ],
          [
              "2370c5db-532e-4077-9143-64787af0959a",
              "72ece178-7aae-489f-9895-12c6f02e09fe",
              "4aa1521e-2baf-4c40-8388-f67b19f3ab80",
              "a9b80f5e-886e-46e8-a74c-438515fa27b6",
              "36473f0f-6023-41c2-b3c4-7c1436265991",
              "0ec49de1-f58b-476c-b151-35a21fb48b21",
              "94517c18-5948-4711-8819-985bbec1644a",
              "7e6f2f60-da2a-46f7-bfe0-1366849a7158",
              "ac406ad2-25fc-4aa0-ba54-6dd6aa5dd203",
              "14f7471c-fff2-41fe-bea3-8b8f3bb9f869",
              "47bfdc56-4bec-45d4-bbcb-8aeefd5a3a66"
          ]
      ]
  }
}


export const TEST_UPDATED =
  {
      "title": "Family Map",
      "nodes": [
          {
              "id": "7bb8bfbf-9365-40a1-93dc-611129d367a3",
              "content": {
                  "name": "Manuel  Fernandez",
                  "relation": "Client"
              },
              "metaData": {
                  "class": "client"
              }
          },
          {
              "id": "de92bc60-1802-4f5e-be1c-387a2b9dd7ac",
              "content": {
                  "name": "Carmen  Fernandez",
                  "relation": "Co-Client"
              },
              "metaData": {
                  "class": "client deceased"
              }
          },
          {
              "id": "05219336-bf3c-4031-a555-320d1c2e7209",
              "content": {
                  "name": "Nuria  Sousa",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child"
              }
          },
          {
            "id": "05219336-bf3c-4031-a555-320d1c2e72092",
            "content": {
                "name": "Nuria  Sousa 2",
                "relation": "Child"
            },
            "metaData": {
                "class": "child"
            }
        },
          {
              "id": "f230db76-a907-4fc2-8ee1-b355a4f31288",
              "content": {
                  "name": "Jose Manuel Fernandez",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child"
              }
          },
          {
              "id": "f01da151-3b9e-4bd8-bbb5-81ad78a0cdf9",
              "content": {
                  "name": "Eduardo  Fernandez",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child"
              }
          },
          {
              "id": "62c45bb1-88a7-44b7-a659-2244fbe08c24",
              "content": {
                  "name": "Carmen  Fernandez",
                  "relation": "Child"
              },
              "metaData": {
                  "class": "child"
              }
          }
      ],
      "connections": [
          {
              "id": "7bb8bfbf-9365-40a1-93dc-611129d367a3",
              "sourceNodeId": [
                  "7bb8bfbf-9365-40a1-93dc-611129d367a3"
              ],
              "nodeConnections": [
                  {
                      "pointsToNodeId": "de92bc60-1802-4f5e-be1c-387a2b9dd7ac",
                      "anchorOrigin": "R",
                      "anchorDestination": "L"
                  },
                  {
                      "pointsToNodeId": "05219336-bf3c-4031-a555-320d1c2e7209",
                      "anchorOrigin": "L",
                      "anchorDestination": "T"
                  },
                  {
                    "pointsToNodeId": "05219336-bf3c-4031-a555-320d1c2e72092",
                    "anchorOrigin": "L",
                    "anchorDestination": "T"
                }
              ]
          },
          {
              "id": "7bb8bfbf-9365-40a1-93dc-611129d367a3de92bc60-1802-4f5e-be1c-387a2b9dd7ac",
              "sourceNodeId": [
                "7bb8bfbf-9365-40a1-93dc-611129d367a3",
                  "de92bc60-1802-4f5e-be1c-387a2b9dd7ac"
              ],
              "nodeConnections": [
                  {
                      "pointsToNodeId": "f230db76-a907-4fc2-8ee1-b355a4f31288",
                      "anchorOrigin": "B",
                      "anchorDestination": "T"
                  }
              ]
          },
          {
            "id": "7bb8bfbf-9365-40a1-93dc-611129d367a3de92bc60-1802-4f5e-be1c-387a2b9dd7ac",
            "sourceNodeId": [
                "de92bc60-1802-4f5e-be1c-387a2b9dd7ac"
            ],
            "nodeConnections": [
                {
                    "pointsToNodeId": "f01da151-3b9e-4bd8-bbb5-81ad78a0cdf9",
                    "anchorOrigin": "B",
                    "anchorDestination": "T"
                },
                {
                    "pointsToNodeId": "62c45bb1-88a7-44b7-a659-2244fbe08c24",
                    "anchorOrigin": "B",
                    "anchorDestination": "T"
                }
            ]
        },
      ],
      "grid": {
          "rowsNodesById": [
              [
                  "7bb8bfbf-9365-40a1-93dc-611129d367a3",
                  "de92bc60-1802-4f5e-be1c-387a2b9dd7ac"
              ],
              [
                  "05219336-bf3c-4031-a555-320d1c2e7209",
                  "05219336-bf3c-4031-a555-320d1c2e72092",
                  "f230db76-a907-4fc2-8ee1-b355a4f31288",
                  "f01da151-3b9e-4bd8-bbb5-81ad78a0cdf9",
                  "62c45bb1-88a7-44b7-a659-2244fbe08c24"
              ]
          ]
      }
  }

  export const TEST_UPDATED_2 = {
    title: "Others",
    nodes: [
      {
        id: "47fc98cb-68a8-46b6-92b2-dd0eceb66167",
        content: {
          name: "Cindy  Stanton",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "75786563-dc6c-4575-a7a2-01b4a16e935d",
        content: {
          name: "Kyle  Koppenhoefer",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "20e66849-849a-431c-9b36-ad3a399bb4e1",
        content: {
          name: "Rachele  Russell",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "f1017e28-3aa0-4d53-bcc2-cc04ff615f59",
        content: {
          name: "Tim  Stanton",
          relation: "Nephew",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "fb6c73b6-65db-41e2-8818-b781893cb00a",
        content: {
          name: "Amanda  Stanton",
          relation: "Niece",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5",
        content: {
          name: "Cory  Stanton",
          relation: "Nephew",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "7571250b-fdd2-4d5b-abf6-adc40ce1db32",
        content: {
          name: "Lily  Stanton",
          relation: "Niece",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "c94c2a1e-eff6-4e24-88be-df795aa7f159",
        content: {
          name: "Jean Ann Skiles",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "6556cf7f-0c3e-490c-883c-ab100a05605f",
        content: {
          name: "Pam  Conway",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "a90d25fe-ee96-4d7f-a89c-095d55ecb96e",
        content: {
          name: "Jon  Stanton",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "EGrayufWT5",
        content: {
          name: "Pam  Conway",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "aUbVZ6eLul",
        content: {
          name: "Pam  Conway",
          relation: "Other",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "fb6c73b6-65db-41e2-8818-b781893cb00a_",
        content: {
          name: "Amanda  Stanton",
          relation: "Niece",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5_",
        content: {
          name: "Cory  Stanton",
          relation: "Nephew",
        },
        metaData: {
          class: "other box-node",
        },
      },
      {
        id: "3",
        content: {},
        metaData: {
          class: "box-node",
        },
      },
      {
        id: "4",
        content: {},
        metaData: {
          class: "box-node",
        },
      },
      {
        id: "5",
        content: {},
        metaData: {
          class: "box-node",
        },
      },
      {
        id: "6",
        content: {},
        metaData: {
          class: "box-node",
        },
      },
    ],
    connections: [],
    grid: {
      rowsNodesById: [
        [
          "47fc98cb-68a8-46b6-92b2-dd0eceb66167",
          "75786563-dc6c-4575-a7a2-01b4a16e935d",
          "20e66849-849a-431c-9b36-ad3a399bb4e1",
          "f1017e28-3aa0-4d53-bcc2-cc04ff615f59",
          "fb6c73b6-65db-41e2-8818-b781893cb00a",
          "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5",
        ],
        [
          "7571250b-fdd2-4d5b-abf6-adc40ce1db32",
          "c94c2a1e-eff6-4e24-88be-df795aa7f159",
          "6556cf7f-0c3e-490c-883c-ab100a05605f",
          "a90d25fe-ee96-4d7f-a89c-095d55ecb96e",
          "EGrayufWT5",
          "aUbVZ6eLul",
        ],
        [
          "fb6c73b6-65db-41e2-8818-b781893cb00a_",
          "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5_",
          "3",
          "4",
          "5",
          "6",
        ],
        [
          "47fc98cb-68a8-46b6-92b2-dd0eceb66167",
          "75786563-dc6c-4575-a7a2-01b4a16e935d",
          "20e66849-849a-431c-9b36-ad3a399bb4e1",
          "f1017e28-3aa0-4d53-bcc2-cc04ff615f59",
          "fb6c73b6-65db-41e2-8818-b781893cb00a",
          "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5",
        ],
        [
          "7571250b-fdd2-4d5b-abf6-adc40ce1db32",
          "c94c2a1e-eff6-4e24-88be-df795aa7f159",
          "6556cf7f-0c3e-490c-883c-ab100a05605f",
          "a90d25fe-ee96-4d7f-a89c-095d55ecb96e",
          "EGrayufWT5",
          "aUbVZ6eLul",
        ],
        [
          "fb6c73b6-65db-41e2-8818-b781893cb00a_",
          "a1fa70ad-e13e-4da2-bf5d-47a69ff086a5_",
          "3",
          "4",
          "5",
          "6",
        ],
        ["EGrayufWT5", "aUbVZ6eLul", "3", "4", "5", "6"],
        ["EGrayufWT5", "aUbVZ6eLul", "3", "4", "5", "6"],
        ["EGrayufWT5", "aUbVZ6eLul", "3", "4", "5", "6"],
      ],
      rowsMetadata: [
        {
          style: {
            display: "flex",
            "align-items": "center",
          },
        },
        {
          style: {
            display: "flex",
            "align-items": "center",
          },
        },
        {
          style: {
            display: "flex",
            "align-items": "center",
          },
        },
      ],
    },
    dataType: "graph",
    nodesType: "FamilyBox",
  };

