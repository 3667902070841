import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from '../../../../shared/helpers/CloseDialogAtLogOut.class';
import { readFromStoragedObject } from '../../../../shared/helpers/utils';
import { AdvisorService } from '../../../../shared/services/advisor.service';
import { AuthenticationService } from '../../../../shared/services/auth/authentication.service';

@Component({
  selector: 'app-warning-delete-scenario-modal',
  templateUrl: './warning-delete-scenario-modal.component.html',
  styleUrls: ['./warning-delete-scenario-modal.component.scss']
})
export class WarningDeleteScenarioModalComponent extends CloseDialogAtLogOut implements OnInit {

  public clientId = '';

  constructor(
    public dialogRef: MatDialogRef<WarningDeleteScenarioModalComponent>,
    private advisorService: AdvisorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);

  }

  ngOnInit() {
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');

  }

  /**
   * The closeDialog function closes a dialog and optionally returns a status.
   * @param {string} [status] - The status parameter is an optional string that represents the status
   * of the dialog. It can be used to pass information about the result or outcome of the dialog back
   * to the component that opened it.
   */
  closeDialog(status?: string) {
    this.dialogRef.close(status);
  }

  /**
   * The function `deleteScenario()` deletes a scenario using the `advisorService`. Is necessary provides idClient and scenarioId to can use the service `deleteProjectionScenario`.
   * After closes the modal.
   * @param clientId - The client id parameter
   * @param scenarioId - The scenario id parameter
   */
  deleteScenario() {
    this.advisorService.deleteProjectionScenario(this.data.clientId, this.data.scenarioId).then(resp => {
      this.closeDialog('scenarioDeleted!');
    });
  }

}
