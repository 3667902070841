// Angular
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

// Rx
import { from, Observable, Observer } from 'rxjs';

// Project
import { AuthenticationService } from '../services/auth/authentication.service'
import { delay } from './utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService
    ) { }

    setTokenHeader(request): any{
      let sessionToken = localStorage.getItem('sessionToken');
      if (sessionToken) {
          this.resetAutoExpireJobs(request);
          request = request.clone({
              setHeaders: {
                  Authorization: `Bearer ${sessionToken}`
              }
          });
      }
      return request;
    }

    private async handleTokenRenewal(request: HttpRequest<any>, next: HttpHandler){

      console.log('inside handleTokenRenewal');
      await delay(3000);
      request = this.setTokenHeader(request);
      return next.handle(request).toPromise();

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(request.url.includes('estate-snapshot') && window.location.href.includes('external-snapshot-preview')){
            return next.handle(request);
        }

        // add authorization header with jwt token if available external-snapshot-preview
        if (!(request.url.includes('signin') || request.url.includes('dynamics') )) {

          //

          if(Boolean(localStorage.getItem('renewingToken'))){
            return from(this.handleTokenRenewal(request, next));
          }else{
            request = this.setTokenHeader(request);
            return next.handle(request);
          }

        }
        // Signin or others doesn't require token header, let them continue
        return next.handle(request);

    }

    // Reset Autoexpire Jobs on any  request except token renew
    resetAutoExpireJobs(request: HttpRequest<any>){

        if(!(request.url.includes('/homepage/renew') || request.url.includes('/askSocket') || request.url.includes('/notifications/pull') /* ||  request.url.includes('saveLater=true') || request.url.includes('/dashboard/clients/') */ )){
          this.authService.autoExpireAlertJob.reset();
          this.authService.autoExpireJob.reset();
        }

    }
}
