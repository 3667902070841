import { Asset, Beneficiary, Field } from 'app/shared/model/questionnaires/Questionnaires.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { validateDate } from '../../family/utils';

export const columnsToShowAsCurrency = ['value','cost_basis','cash_value'];

export const columnsToShowAsBeneficiary = ['beneficiaries','contingentBeneficiaries'];

export const columnsToShowAsPercent = ['ownership_percentage', 'interest_rate', 'growthrate'];

export const columnsToShowAsColorIndicator= ['status'];

export const columnsToShowWithDotInAssets= ['nickname'];

export const columnsToRenderizeByAssetType = {
    real_estate: [
        'nickname', 'subtype', 'owner', 'value', 'location', 'cost_basis', 'edit_icon'
    ],
    business: [
        'owner', 'nickname', 'subtype', 'location', 'ownership_percentage', 'value', 'edit_icon'
    ],
    retirement:[
        'nickname', 'subtype', 'value', 'owner', 'beneficiaries', 'contingentBeneficiaries', 'edit_icon'
    ],
    non_retirement:[
        'nickname', 'subtype', 'value', 'owner', 'beneficiaries', 'contingentBeneficiaries', 'edit_icon'
    ],
    life_insurance:[
        'nickname', 'owner', 'whose_life_is_insured', 'subtype', 'cash_value', 'value', 'beneficiaries', 'contingentBeneficiaries', 'edit_icon'
    ],
    college_savings:[
        'nickname', 'subtype', 'owner', 'value', 'beneficiaries', 'contingentBeneficiaries', 'edit_icon'
    ],
    loans_liabilities: [
        'nickname', 'owner', 'subtype', 'real_estate_loands_liabilities', 'value', 'interest_rate', 'edit_icon'
    ],
}

export const columnsToRenderizeByAssetTypeEstateLab = {
    ...columnsToRenderizeByAssetType,
    assets: [
        'status', 'nickname', 'value', 'subtype', 'beneficiaries', 'contingentBeneficiaries', 'owner', 'growthrate', 'edit_button'
    ],
    real_estate: [
        'status', 'nickname', 'subtype', 'owner', 'value', 'location', 'cost_basis', 'growthrate', 'edit_button'
    ],
    business: [
        'status', 'owner', 'nickname', 'subtype', 'location', 'ownership_percentage', 'value', 'growthrate', 'edit_button'
    ],
    retirement:[
        'status', 'nickname', 'subtype', 'value', 'owner', 'beneficiaries', 'contingentBeneficiaries', 'growthrate', 'edit_button'
    ],
    non_retirement:[
        'status', 'nickname', 'subtype', 'value', 'owner', 'beneficiaries', 'contingentBeneficiaries', 'growthrate', 'edit_button'
    ],
    college_savings:[
        'status', 'nickname', 'subtype', 'owner', 'value', 'beneficiaries', 'contingentBeneficiaries','growthrate', 'edit_button'
    ],
    charities: [
        'status', 'nickname', 'subtype', 'edit_button'
    ],
    life_insurance:[
        'status', 'nickname', 'owner', 'whose_life_is_insured', 'subtype', 'cash_value', 'value', 'beneficiaries', 'contingentBeneficiaries', 'growthrate', 'edit_button'
    ],
    loans_liabilities: [
        'status', 'nickname', 'owner', 'subtype', 'real_estate_loands_liabilities', 'value', 'interest_rate', 'edit_button'
    ],
    personal_property: [
        'status', 'nickname', 'owner', 'value', 'growthrate', 'edit_button'
    ],
    children: [
        'fullname', 'birthdate', 'parents', 'fullTimeCollageStudent', 'hasDisability', 'edit_button'
    ],
    grandchildren: [
        'fullname', 'birthdate', 'edit_button'
    ],
    beneficiaries: [
        'fullname', 'relation', 'edit_button'
    ],
}

export const assetsCatalog = {
    real_estate: 
    {
        label: 'Real Estate',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'nickname': {
                key: 'nickname',
                label: 'Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'subtype': {
                key: 'subtype',
                label: 'Real Estate Type',
                type: "select",
                errors: []
            },
            'owner': {
                key: 'owner',
                label: 'Owner/Title',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'value': {
                key: 'value',
                label: 'Market Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
            'location': {
                key: 'location',
                label: 'Location',
                type: "select",
                errors: []
            },
        
            'cost_basis': {
                key: 'cost_basis',
                label: 'Cost Basis',
                type: "currency",
                errors: []
            },
        
        },
    },
    business: 
    {
        label: 'Business',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'owner': {
                key: 'owner',
                label: 'Owner/Title', 
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            }, 
            'nickname': {
                key: 'nickname',
                label: 'Name', 
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            }, 
            'subtype': {
                key: 'subtype',
                label: 'Structure', 
                type: "select",
                errors: []
            }, 
            'location': {
                key: 'location',
                label: 'Location', 
                type: "select",
                errors: []
            }, 
            'ownership_percentage': {
                key: 'ownership_percentage',
                label: 'Ownership Percentage', 
                type: "percentage",
                errors: [
                    {
                        key: 'percentagePattern' ,
                        message: ' Invalid field'
                    }
                ]
            }, 
            'value': {
                key: 'value',
                label: 'Fair Market Value', 
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
        },
    },
    retirement:{
        label: 'Retirement',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'nickname' : {
                key: 'nickname',
                label: 'Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
             'subtype' : {
                key: 'subtype',
                label: 'Account Type',
                type: "select",
                errors: []
             },
             'value' : {
                key: 'value',
                label: 'Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
             },
             'owner' : {
                key: 'owner',
                label: 'Owner/Title',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
             },
             'beneficiaries' : {
                key: 'beneficiaries',
                label: 'Beneficiaries',
                type: "multiselect",
                errors: []
             },
             'contingentBeneficiaries' : {
                key: 'contingentBeneficiaries',
                label: 'Contingent Beneficiaries',
                type: "multiselect",
                errors: []
             },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            }
        },
    },
    non_retirement:{
        label: 'Non-retirement',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'nickname' : {
                key: 'nickname',
                label: 'Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
             'subtype' : {
                key: 'subtype',
                label: 'Account Type',
                type: "select",
                errors: []
             },
             'value' : {
                key: 'value',
                label: 'Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
             },
             'owner' : {
                key: 'owner',
                label: 'Owner/Title',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
             },
             'beneficiaries' : {
                key: 'beneficiaries',
                label: 'Beneficiaries',
                type: "multiselect",
                errors: []
             },
             'contingentBeneficiaries' : {
                key: 'contingentBeneficiaries',
                label: 'Contingent Beneficiaries',
                type: "multiselect",
                errors: []
             },
             'growthrate': {
                 key: 'growthrate',
                 label: 'Assumed annual growth rate',
                 type: "percentage",
                 errors: [
                     {
                         key: 'percentageWithNegativesPattern' ,
                         message: ' Invalid field'
                     }
                 ]
             }
        },
    },
    life_insurance:{
        label: 'Life Insurance',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'nickname': {
                key: 'nickname',
                label: 'Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'owner': {
                key: 'owner',
                label: 'Policy Owner',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'whose_life_is_insured': {
                key: 'whose_life_is_insured',
                label: 'Whose Life is Insured?',
                type: "select",
                errors: []
            },
            'subtype': {
                key: 'subtype',
                label: 'Type',
                type: "select",
                errors: []
            },
            'cash_value': {
                key: 'cash_value',
                label: 'Cash Value',
                type: "currency",
                errors: []
            },
            'value': {
                key: 'value',
                label: 'Death Benefit Amount',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'beneficiaries': {
                key: 'beneficiaries',
                label: 'Beneficiaries',
                type: "multiselect",
                errors: []
            },
            'contingentBeneficiaries': {
                key: 'contingentBeneficiaries',
                label: 'Contingent Beneficiaries',
                type: "multiselect",
                errors: []
            },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            }

        },
    },
    college_savings:{
        label: 'College Savings',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: []
            },
            'nickname': {
                key: 'nickname',
                label: 'Plan Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'subtype': {
                key: 'subtype',
                label: 'Type',
                type: "select",
                errors: []
            },
            'owner': {
                key: 'owner',
                label: 'Custodian',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'value': {
                key: 'value',
                label: 'Account Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'beneficiaries': {
                key: 'beneficiaries',
                label: 'Beneficiaries',
                type: "multiselect",
                errors: []
            },
            'contingentBeneficiaries': {
                key: 'contingentBeneficiaries',
                label: 'Contingent Beneficiaries',
                type: "multiselect",
                errors: []
            },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            }
        },
    },
    loans_liabilities: 
    {
        label: 'Loans/Liabilities',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'nickname':{
                key: 'nickname',
                label: 'Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'owner':{
                key: 'owner',
                label: 'Loan Owner',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'subtype':{
                key: 'subtype',
                label: 'Type',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'real_estate_loands_liabilities':{
                key: 'real_estate_loands_liabilities',
                label: 'Real Estate',
                type: "select",
                errors: []
            },
            'value':{
                key: 'value',
                label: 'Loan Amount',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'interest_rate':{
                key: 'interest_rate',
                label: 'Interest Rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
            'growthrate': {
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            }
        },
    },
    assets: {
        label: 'Assets',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'nickname':{
                key: 'nickname',
                label: 'Name',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'value':{
                key: 'value',
                label: 'Value',
                type: "number",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'subtype':{
                key: 'subtype',
                label: 'Type',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'beneficiaries':{
                key: 'beneficiaries',
                label: 'Beneficiaries',
                type: "multiselect",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'contingentBeneficiaries':{
                key: 'contingentBeneficiaries',
                label: 'Contingent Beneficiaries',
                type: "multiselect",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'owner':{
                key: 'owner',
                label: 'Owner',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'growthrate':{
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
        }
    },
    charities: {
        label: 'Charities',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'nickname':{
                key: 'nickname',
                label: 'Name',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'subtype': {
                key: 'subtype',
                label: 'Description',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'owner': {
                key: 'owner',
                label: 'Custodian',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'value': {
                key: 'value',
                label: 'Account Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'growthrate':{
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
        }
    },
    personal_property: {
        label: 'Personal property',
        assets: {
            'status':{
                key: 'status',
                label: 'Status',
                type: "toggle",
                toggleValues: [
                    'For Planning',
                    'In Place'
                ],
                toggleDisable: 'In Place',
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'nickname':{
                key: 'nickname',
                label: 'Property Nickname',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'owner': {
                key: 'owner',
                label: 'Owner/Title',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'growthrate':{
                key: 'growthrate',
                label: 'Assumed annual growth rate',
                type: "percentage",
                errors: [
                    {
                        key: 'percentageWithNegativesPattern' ,
                        message: ' Invalid field'
                    }
                ]
            },
            'value': {
                key: 'value',
                label: 'Account Value',
                type: "currency",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
        }
    },
    beneficiaries: {
        label: 'Beneficiaries',
        assets: {
            'fullname':{
                key: 'fullname',
                label: 'Name',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'relation':{
                key: 'relation',
                label: 'Relationship',
                type: "select-with-open-field",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            }
        }
    },
    children: {
        label: 'Children',
        assets: {
            'fullname':{
                key: 'fullname',
                label: 'Name',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'birthdate':{
                key: 'birthdate',
                label: 'Birthdate',
                type: "calendar",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'parents':{
                key: 'parents',
                label: 'Parents',
                type: "select",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'fullTimeCollageStudent':{
                key: 'fullTimeCollageStudent',
                label: 'Full time college student',
                type: "radio",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'hasDisability':{
                key: 'hasDisability',
                label: 'Has Disability',
                type: "radio",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
        }
    },
    grandchildren: {
        label: 'Grandchildren',
        assets: {
            'fullname':{
                key: 'fullname',
                label: 'Name',
                type: "text",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
            'birthdate':{
                key: 'birthdate',
                label: 'Birthdate',
                type: "calendar",
                errors: [
                    {
                        key: 'required' ,
                        message: ' Required field'
                    }
                ]
            },
        }
    }
 }


export const assetColorClasses = {
    'real_estate': 'real-estate',
    'business': 'business',
    'life_insurance': 'life-insurance',
    'loans_liabilities': 'loans',
    'retirement':'retirement',
    'non_retirement':'non-retirement',
    'college_savings':'college-savings',
    'personal_property':'personal-property'
}

export const assetToTable = (assets: Asset[], assetType: string, columns)=>{
    const table = []
    assets.forEach((asset, index) => {
        const row = {}
        const assetKeys = Object.keys(asset)
        assetKeys.forEach(assetKey => {
            if(columns[assetType].includes(assetKey)){
                row[assetKey] = asset[assetKey]
            }
            if(typeof asset[assetKey] == 'object' && asset[assetKey] != null){
                const internalKeys = Object.keys(asset[assetKey])
                internalKeys.forEach((internalKey) => {
                    if(columns[assetType].includes(internalKey)){
                        if(!row[internalKey] && row[internalKey] !== 0){
                            row[internalKey] = asset[assetKey][internalKey]   
                        }
                    }
                })
            }
            if (assetKey == 'subtype' && asset[assetKey] == 'Term') {
                row['cash_value'] = 'N.A.'
            }
        });
        if ( assetType == 'assets' && row['beneficiaries'].length == 0) {
            row['beneficiaries'] = [{
                beneficiaryId: '',
                percentage:    0,
                value: 'No beneficiaries',
            }]
        }
        if (assetType == 'assets' && row['contingentBeneficiaries'].length == 0) {
            row['contingentBeneficiaries'] = [{
                beneficiaryId: '',
                percentage:    0,
                value: 'No beneficiaries',
            }]
        }
        if (row['real_estate_loands_liabilities']== undefined && assetType == 'loans_liabilities') {
            row['real_estate_loands_liabilities'] = 'N.A.'
        }
/*         if (columns['assets'] && assetType == 'assets') {
            row['beneficiaries'] = 'N.A.'
        } */
        table.push(row)
    })  
    return table
}

export const getBeneficiariesFromAsset = (beneficiaries: Beneficiary[])=>{
    if (beneficiaries == undefined) {
        return 
    }
    return beneficiaries.map((beneficiary: Beneficiary) => {
        return beneficiary.value
    })
}

export const getWhoIsInsured = (insuredId: string)=>{
    const curentClientId = JSON.parse(sessionStorage.getItem('currentClient')).clientId
    const currentClientName = JSON.parse(sessionStorage.getItem('currentClient')).fullName

    if (insuredId == undefined) {
        return ''
    }
    return insuredId == curentClientId ? 'Client' : 'Co-client'
}

export const getRealEstateAssetName = (realEstateAssets: Asset[], idToFind: string)=>{
    if (idToFind == undefined) {
        return ''
    }
    if (idToFind.length < 6) {
        return idToFind
    }
    return realEstateAssets.filter((asset: Asset) => {
        return asset._id == idToFind;
      })[0]?.nickname || ''
}

export const getFormFromAsset = (asset: Asset|any, assetType: string, columns)=>{
    if (asset == undefined) {
        return new FormGroup({});
    }
    const emptyAsset = getAssetEmptyTemplate(assetType, columns)
    const form: FormGroup = new FormGroup({});
    Object.keys(emptyAsset).forEach((key) => {
      if(columns[assetType].includes(key) && asset[key] !== undefined){
        if (key === 'beneficiaries' || key === 'contingentBeneficiaries') {
            const beneficiariesValues = asset[key].map((item) => {
                return item.beneficiaryId
            })
            form.addControl(key, new FormControl(beneficiariesValues || "", getValidators(assetsCatalog[assetType].assets[key].errors)));
        }
        else{
            if (key == 'birthdate') {
                form.addControl(key, new FormControl(validateDate(asset[key]) || "", getValidators(assetsCatalog[assetType].assets[key].errors)));
            }
            else{
                form.addControl(key, new FormControl(asset[key] || "", getValidators(assetsCatalog[assetType].assets[key].errors)));
            }
        }
      }
      if(typeof asset[key] == 'object' && asset[key] != null){
        if (emptyAsset[key]) {
            const internalKeys = Object.keys(emptyAsset[key])
            internalKeys.forEach((internalKey) => {
                if(columns[assetType].includes(internalKey)){
                    form.addControl(internalKey, new FormControl(asset[key][internalKey] || "", getValidators(assetsCatalog[assetType].assets[internalKey].errors)));
                }
            })
        } 
      }
    })
    return form;
}

export const getValidators = (errors: any[])=> {
    if (errors == undefined || !errors.length) {
        return [];
    }
    return errors.map((error) => {
        if(error.key == 'percentagePattern'){
            return Validators.pattern(/^(100(\.0{1,2})?|[1-9]?[0-9](\.[0-9]{1,2})?)%$/)
        }
        if(error.key == 'percentageWithNegativesPattern'){
            return Validators.pattern(/^(-?100(\.0{1,2})?|(-?[1-9]?[0-9](\.[0-9]{1,2})?))%$/)
        }
        return Validators[error.key]
    })
}


export const dataVsFieldsRelationships = {
    real_estate_loands_liabilities: 'real_estate_loands_liabilities',
    owner_title: 'owner',
    beneficiaries: 'beneficiaries',
    contingentBeneficiaries: 'beneficiaries',
    whose_life_is_insured: 'real_estate_loands_liabilities',
    relation: 'relation',
}

export const getDropdownOptionsFromRequest = (fields: Field[], assetType: string)=>{
    if (fields == undefined) {
        return {}
    }
    const dropdowns = {};
    fields.forEach((key) => {
      dropdowns[dataVsFieldsRelationships[key.field]] = key.options
      if (key.field == 'beneficiaries') {
        dropdowns['contingentBeneficiaries'] = key.options
        dropdowns['beneficiaries'] = dropdowns['beneficiaries'].filter((item) => {
            return  item.validAssets.includes(assetType)
        })
        dropdowns['contingentBeneficiaries'] = dropdowns['contingentBeneficiaries'].filter((item) => {
            return  item.validAssets.includes(assetType)
        })
      }
      if (key.field == 'real_estate_loands_liabilities') {
        dropdowns['whose_life_is_insured'] = key.options
      }
      if (key.field == 'owner_title') {
        dropdowns['owner'] = dropdowns['owner'].filter((item) => {
            return  item.validAssets.includes(assetType)
        })
      }
      if (key.field == 'owner_title' && assetType == 'college_savings') {
        dropdowns['owner'] = dropdowns['whose_life_is_insured'].map((item) => {
            return {
                key:item['relation'],
                value:item['relation'],
                text:item['text'],
            }
        })
      }

    })
    return dropdowns;
}



export const assetFormToAsset = (form :FormGroup, originalAsset: Asset | any, assetType: string) => {
    if (form == undefined) {
        {}
    }
    const assetWithNewProps = {
        addons: {
            // number;
            cost_basis: form.controls['cost_basis']?.value,
            // string;
            source: form.controls['source']?.value,
            // string,
            location: form.controls['location']?.value,
            // number,
            ownership_percentage: form.controls['ownership_percentage']?.value,
            // number
            cash_value: form.controls['cash_value']?.value,
            // string,
            whose_life_is_insured: form.controls['whose_life_is_insured']?.value,
            // string,
            real_estate_loands_liabilities: form.controls['real_estate_loands_liabilities']?.value,
            // string
            interest_rate: form.controls['interest_rate']?.value,
            // string
            description: form.controls['description']?.value,
        },
        // string;
        nickname: form.controls['nickname']?.value,
        // string;
        fullname: form.controls['fullname']?.value,
        // string;
        relation: form.controls['relation']?.value,
        // string;
        type: assetType,
        // string;
        subtype: form.controls['subtype']?.value,
        // number;
        value: form.controls['value']?.value || 0,
        // string;
        owner: form.controls['owner']?.value || 'Client',
        // string;
        status: form.controls['status']?.value,
        // OwnerData;
/*         ownerData: form.controls['ownerData'].value, */
        // Beneficiary[];
        beneficiaries: form.controls['beneficiaries'] ? form.controls['beneficiaries'].value.map((item, index) => {
            return {
                "beneficiaryId": item,
                percentage: form.controls['beneficiariesPercentages']? form.controls['beneficiariesPercentages'].value[index] : ''
            }
        }): [] , 
        // Beneficiary[];
        contingentBeneficiaries: form.controls['contingentBeneficiaries'] &&
            form.controls['contingentBeneficiaries'].value !== '' ? form.controls['contingentBeneficiaries'].value.map((item, index) => {
            return {
                "beneficiaryId": item,
                percentage: form.controls['contingentBeneficiariesPercentages']? form.controls['contingentBeneficiariesPercentages'].value[index] : ''
            }
        }): [] ,
        // string[];
        growthrate: form.controls['growthrate']?.value
    }

    const compoundAsset = {
        ...originalAsset,
        ...assetWithNewProps
    }

    compoundAsset.addons = {
        ...originalAsset.addons,
        ...assetWithNewProps.addons
    }
    
    return removeUndefinedProperties(compoundAsset);
}

export const removeUndefinedProperties = (obj: any)=>{
    for (const key in obj) {
        if (obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          obj[key] = removeUndefinedProperties(obj[key]);
        }
    }
    return obj;
}

export const getAssetEmptyTemplate = (assetType: string, columns) => {
    return {
        addons: {
            // number;
            cost_basis: columns[assetType].includes('cost_basis') ? 0: undefined,
            // string;
            source: columns[assetType].includes('source') ? '': undefined,
            // string
            location: columns[assetType].includes('location') ? '': undefined,
            // number
            ownership_percentage: columns[assetType].includes('ownership_percentage') ? 0: undefined,
            // number
            cash_value: columns[assetType].includes('cash_value') ? 0: undefined,
            // string
            whose_life_is_insured: columns[assetType].includes('whose_life_is_insured') ? '': undefined,
            // string
            real_estate_loands_liabilities: columns[assetType].includes('real_estate_loands_liabilities') ? '': undefined,
            // string
            interest_rate: columns[assetType].includes('interest_rate') ? '': undefined,
            // string[];
            parents: columns[assetType].includes('parents') ? '': undefined,
            // string[];
            fullTimeCollageStudent: columns[assetType].includes('fullTimeCollageStudent') ? '': undefined,
            // string[];
            hasDisability: columns[assetType].includes('hasDisability') ? '': undefined,
        },
        // string;
        nickname: columns[assetType].includes('nickname') ? '': undefined,
        // string;
        fullname: columns[assetType].includes('fullname')? '': undefined,
        // string;
        relation: columns[assetType].includes('relation')? '': undefined,
        // string;
        type: columns[assetType].includes('type') ? '': undefined,
        // string;
        subtype: columns[assetType].includes('subtype') ? '': undefined,
        // number;
        value: columns[assetType].includes('value') ? 0: undefined,
        // string;
        owner: columns[assetType].includes('owner') ? '': undefined,
        // string;
        status: columns[assetType].includes('status') ? '': undefined,
        // OwnerData;
        ownerData: columns[assetType].includes('ownerData') ? {}: undefined,
/*         // Beneficiary[]; */
        beneficiaries: columns[assetType].includes('beneficiaries') ? []: undefined,
        // Beneficiary[];
        contingentBeneficiaries: columns[assetType].includes('contingentBeneficiaries') ? []: undefined,
        // string[];
        growthrate: columns[assetType].includes('growthrate') ? '0': undefined,
        // string[];
        birthdate: columns[assetType].includes('birthdate') ? '': undefined,
    }
}
