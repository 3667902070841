import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  MagicStickyTableInterface,
  MagicStickyTable_EXAMPLE,
  MagicStickyTableColumn,
} from "./magicStickyTable.interface";
import { Key } from "protractor";
import { COLUMNS_KEYS, DICTIONARY_COLUMNS_TOOLTIP } from "./dictionaryColumns";

@Component({
  selector: "magic-sticky-table",
  templateUrl: "./magic-sticky-table.component.html",
  styleUrls: ["./magic-sticky-table.component.scss"],
})
export class MagicStickyTableComponent implements OnInit {
  @Input() print: boolean = false;
  @Input() dataTable: MagicStickyTableInterface;
  @Input() platformView: boolean = false;
  @Output() columnSelected: EventEmitter<any> = new EventEmitter<any>();

  public middleColumns: string[] = [];
  public activeTooltip: boolean = false;
  public tooltipByColumn = DICTIONARY_COLUMNS_TOOLTIP;
  public key: string = "Taxes Paid";
  public columnsKeys: any = COLUMNS_KEYS;

  constructor() {}

  ngOnInit(): void {
    if (this.dataTable == undefined) {
      this.dataTable = MagicStickyTable_EXAMPLE;
    }
    this.selectColumn("Taxes Paid");
    console.log("dataMagicTable**", this.dataTable, this.tooltipByColumn);
    if (this.dataTable) {
      this.getMiddleColumns();
    }
  }

  getMiddleColumns() {
    this.middleColumns = Object.keys(this.dataTable.columns).filter((key) => {
      return !(
        this.dataTable.fixedToTheLeft.includes(key) ||
        this.dataTable.fixedToTheRight.includes(key)
      );
    });
    console.log("this.middleColumns**", this.middleColumns);
  }

  columnClicked(column) {
    this.columnSelected.emit(column);
  }

  filterColumnByCategory(category): string[] {
    return Object.keys(this.dataTable.columns).filter(
      (key) => this.dataTable.columns[key].category === category
    );
  }

  enableTooltip() {
    this.activeTooltip = !this.activeTooltip;
  }

  selectColumn(key: string) {
    this.key = key;
    this.columnSelected.emit(this.columnsKeys[this.key]);
  }
}
