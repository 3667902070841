//Angular
import { Component, OnInit, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";

// Project
import { AdvisorService } from '../../services/advisor.service';
import { environment } from '../../../../environments/environment';
import { ShareClientDialogData } from '../../../views/clients/clients.interfaces';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-share-client-multiple',
  templateUrl: './share-client-multiple.component.html',
  styleUrls: ['./share-client-multiple.component.scss']
})
export class ShareClientMultipleDialog extends CloseDialogAtLogOut implements OnInit, AfterViewInit {

  @ViewChild(MatButton, { static: false }) submitButton: MatButton;

  shareClientForm: FormGroup;

  public advisorDropdown = new FormControl();
  public advisorList: any;
  public loading: boolean = false;
  public disabled: boolean = true;

  displayedColumns: string[] = ['select','fullName', 'email'];
  dataSource = new MatTableDataSource([]);
  selection = new SelectionModel(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private advisorService: AdvisorService,
    public dialogRef: MatDialogRef<ShareClientMultipleDialog>,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ShareClientDialogData,
    private authService: AuthenticationService
    ) {
      super(dialogRef, authService);
     }

  ngOnInit() {

    this.loading = true;

    this.http.get<any>(`${environment.apiAllAdvisorsFromFirm}`).subscribe(
      response => {
        this.advisorList = response.advisors;
        if(this.data.sharedBetween) {
          this.data.sharedBetween.map(shared => {
            let indexToDelete = this.advisorList.findIndex(arrElement => {
              return arrElement.fullName === shared.fullName
            });

            if(indexToDelete >= 0) {
              this.advisorList.splice(indexToDelete, 1);
            }

            this.dataSource.data = this.advisorList;
          });
        } else {
          this.dataSource.data = this.advisorList;
        }

        this.loading = false;

      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );

  }

  ngAfterViewInit(){
    //Paginator
    this.dataSource.paginator = this.paginator;
  }

  shareClient(row){
      this.selection.selected.length > 0 ? this.disabled = false : this.disabled = true;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  share() {
    this.dialogRef.close(this.selection.selected);

    //Share with one advisor
    if(this.data.id){
      //Get the needed data for the post
      let id = this.data.id;
      let sharingEmailList = [];

      this.selection.selected.forEach(element => {
        sharingEmailList.push(element.email);
      });

      let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

      let bodyData = {
        'emailList' : sharingEmailList
      }

      this.http.post<any>(`${environment.apiShareClient}${id}`, bodyData, options)
        .subscribe(
          success => {
            this.snackBarSuccess.open("Client shared succesfully with " + sharingEmailList, "Ok", {
              duration: 3000,
              panelClass: 'success-snackbar'
            });
          },
          error => {
            this.snackBarError.open("Cannot share this client. " + error.error.message, "Dismiss", {
              duration: 9000,
              panelClass: 'error-snackbar'
            });
          }
        );
    }else{
      //Share with firm
    }


  }

  onNoClick(): void {
    this.dialogRef.close('cancelled');
  }

}
