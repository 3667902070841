import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { IntegrationMapperService } from 'app/shared/services/integration/integration-mapper.service';


@Component({
  selector: 'app-confirm-leaving',
  templateUrl: './confirm-leaving.component.html',
  styleUrls: ['./confirm-leaving.component.scss']
})
export class ConfirmLeavingComponent extends CloseDialogAtLogOut implements OnInit {

  handleSaveMapping =  new EventEmitter<any>()

  constructor(
    private integrationMapperService: IntegrationMapperService,
    public snackBarSuccess: MatSnackBar,
    public snackBarError: MatSnackBar,
    private dialogRef: MatDialogRef<any>, 
    private authService: AuthenticationService) {
    super(dialogRef, authService);
  }

  ngOnInit(): void {
  }

  saveMapping(){
    const isValidMapping =  this.integrationMapperService.currentMapping && this.integrationMapperService.currentMapping.length
    if (!isValidMapping) {
      return
    }
    this.integrationMapperService.saveForm(this.integrationMapperService.currentMapping).subscribe(
      (success) => {
        this.dialogRef.close(true)
        this.snackBarSuccess.open(success.message, "Ok", {
          duration: 3000,
          panelClass: 'success-snackbar'
        });
      },
      (error) => {
        this.dialogRef.close(false)
        console.log("Error!!!");
        this.snackBarError.open(
          error.error.message,
          "Dismiss",
          {
            duration: 9000,
            panelClass: "error-snackbar",
          }
        );
      })
  }

}
