import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvisorService } from 'app/shared/services/advisor.service';

@Component({
  selector: 'app-purchase-modal',
  templateUrl: './purchase-modal.component.html',
  styleUrls: ['./purchase-modal.component.scss']
})
export class PurchaseModalComponent implements OnInit {

  public counter: number = 10;
  public pricePerCredit: number = 0;
  public total: number = this.counter * this.pricePerCredit;
  public step: number = 1;
  public paymentMethods: any;
  public hasPaymentMethods: boolean = false;
  public selectedCard: string = '';
  public loading: boolean = false;
  public failed: boolean = false;
  public type: string;
  public companyId: string;
  public paymentResponse: any;
  public creditsAvailable: number = 0;
  public titles: any = {
    estate: 'Estate',
    home: 'Home',
    auto: 'Auto'
  }
  public section: any = {
    estate: 'Estate Planning',
    home: 'Home Insurance',
    auto: 'Auto Insurance',
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<PurchaseModalComponent>,
    private advisorService: AdvisorService
    ) {
      this.type = data?.type || 'estate';
      this.creditsAvailable = data?.creditsAvailable;
    }

  ngOnInit(): void {
    this.getPaymentMethods();
    this.getAdvisorData();
    this.getPrice();
  }

  async getPrice() {
    try {
      const ans: any = await this.advisorService.getPriceCreditsPerArea(this.type);
      this.pricePerCredit = ans[this.type];
      this.total = this.pricePerCredit * this.counter;
    } catch(e) {
      console.log(e);
    }
  }

  async getPaymentMethods() {
    try {
      const ans = await this.advisorService.getPaymentMethodsPromise();
      this.paymentMethods = ans?.paymentMethods;
      this.selectedCard = this.paymentMethods.filter(({ isDefault }) => !!isDefault)[0]?.stripeId;
      this.hasPaymentMethods = this.paymentMethods?.length > 0;
    } catch(e) {
      console.log(e);
    }
  }

  async getAdvisorData() {
    try {
      const data: any = await this.advisorService.getAdvisorDataPromise();
      this.companyId = data.companyId;
    } catch(e) {
      console.log(e);
    }
  }


  async purchaseCredits() {
    try {
      const requestBody = {
        area: this.type,
        totalPayment: this.total,
        totalCredits: this.counter,
        stripeId: this.selectedCard,
      };

      const paymentResponse: any = await this.advisorService.purchaseCredits(this.companyId, requestBody);
      this.loading = false;
      if (paymentResponse?.principal?.status === 'failed') {
        this.failed = true;
      } else {
        this.failed = false;
      }
      this.paymentResponse = paymentResponse?.principal;
    } catch(e) {
      this.failed = true;
    }
  }

  goBack() {
    this.loading = false;
    this.failed = false;
    this.step = 2;
  }

  addOrReduce(reduce: boolean = false) {
    if (reduce) {
      this.counter -= 10;
    } else if (this.counter < 90) {
      this.counter += 10;
    }

    this.total = this.pricePerCredit * this.counter; 
  }

  close() {
    this.dialog.close();
  }

  purchase() {
    console.log('Hay que comprar en el método');
  }


  selectCard(e: any) {
    console.log(e);
    this.selectedCard = e;
  }


  async continue() {
    if (this.step === 2) {
      console.log('Ya hay que comprar');
      this.loading = true;
      this.step += 1;
      await this.purchaseCredits();
    }
    this.step += 1;
  }

}
