import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { relativesCatalog } from '../../templates/family/utils';
import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AddChildComponent extends CloseDialogAtLogOut implements OnInit {

  @Output() handleOnSave: EventEmitter<any> = new EventEmitter()

  @Output() handleOnDelete: EventEmitter<any> = new EventEmitter()

  @Output() handleOnClose: EventEmitter<any> = new EventEmitter()

  @Input() theme: string = 'general-questionnaire-theme';

  @Input() childDataForm: FormGroup;

  @Input() validRelationships: any[];

  @Input() relationshipType: string = '';

  @Input() relationshipName: string = '';

  @Input() title: string = '';

  @Input() canBeDeleted: boolean = true;

  minDateForBirthdate: Date;

  maxDateForBirthdate: Date;

  form: FormGroup;

  childrenCatalog = relativesCatalog.children;

  isNewRelative: boolean = true;

  constructor( private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddChildComponent>,
    private authService: AuthenticationService) { 
    super(dialogRef, authService);
  }

  ngOnInit(): void {
    this.initBirthRanges();
    this.initForm()
  }

  initForm() {
    if(this.childDataForm){
      this.form  = this.childDataForm
      this.childrenCatalog = relativesCatalog[this.relationshipType] 
      this.isNewRelative = (this.childDataForm.status === "VALID");
    }
  }

  get formControlNames(): string[] {
    return Object.keys(this.form.controls);
  }

  patchForm(){

  }

  initBirthRanges() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth()
    const currentDay = new Date().getDate()
    this.minDateForBirthdate = new Date(currentYear - 120, 0, 1);
    this.maxDateForBirthdate = new Date();
  }

  save(){
    this.handleOnSave.next(this.form)
  }

  delete(){
    this.handleOnDelete.next(this.form)
  }
  compareArrays = (arr1: any, arr2: any): boolean => {
    if (!Array.isArray(arr1)) {
      arr1 = [arr1];
    }
    if (!Array.isArray(arr2)) {
      arr2 = [arr2];
    }
  
    if (arr1 === arr2) {
      return true;
    }
    if (arr1 == null || arr2 == null) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; ++i) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }
}
