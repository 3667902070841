import { CommaFormatedNumberToJSNumber, DataTransformation, NumberToDate } from './DataTransformation.class';

export abstract class Questionnaire {

    public afterMappingLoad: any = null;
    protected _classInitialState: any = null;

    abstract getQName(): string;

    populateWithArrayOfObjects(args: any[]) {

        let itemsProcessed = 0;

        //Do transformation to args data before mapping to the current model.
        this.beforeMappingLoad(args);
        args.forEach((obj, index, array) => {

            let propName: string = obj.name;
            if (propName.includes('clientIDHasLTCInsurance_Beneficiaries') || propName.includes('clientIDHas529Plan_Beneficiaries_') || propName.includes('clientIDHasTraditionalIRA_Beneficiaries_') || propName.includes('clientIDHasBrokerageAcct_Beneficiaries_') || propName.includes('clientIDHasAccessTo457Plan_Beneficiaries_') || propName.includes('clientIDHasAccessTo401KPlan_Beneficiaries_') || propName.includes('clientIDHasRoth_Beneficiaries_')) {
                obj.answer = obj.answer.split(',');
                this[propName] = {};
            }
            //Load data value to the model

            if (this.hasOwnProperty(propName) && (typeof (this[propName]) != 'function') && (obj.answer != undefined || obj.answer != 'undefined')) {
                this[propName] = obj.answer;
            } else {
            }

            if (++itemsProcessed == array.length) {
                //Assure the data type in the loaded model
                if (this._classInitialState != null) this.applyDataTypeCorrectionsOnInstance(this._classInitialState, this);
                //Execute after mapping load callback
                if(typeof this.afterMappingLoad === 'function') this.afterMappingLoad(this);
            }
        });

        

    }

    toSaveFormat(whoAnswered: string = 'Advisor'): string {
        this.beforeToSaveFormat();
        let newModel: any = {};
        //Call the clear comma function to assure fields with commas are converted to valid numbers formats
        this.executeDataTransformations();
        //For each propertie, transform to save format
        for (let prop in this) {
            if (this.hasOwnProperty(prop) && (typeof (this[prop]) != 'function') && !this.getIgnoredAttributesForSave().includes(prop) && prop != 'afterMappingLoad' && prop != '_classInitialState') {
                // If a string value contains null char, then the backend needs it as the string 'undefined'
                let answer = (String(this[prop]) == '') ? 'undefined' : this[prop];
                // Clear the not value to No
                if (answer == 'nolose') answer = 'No';

                let tipo = (answer == 'Yes' || answer == 'No') ? 'bool' : (typeof (answer) == 'number' || !isNaN(Number(answer))) ? 'number' : 'string';
                newModel[prop] = { "answer": ((typeof (answer) == 'number' || !isNaN(Number(answer))) ? Number(answer) : String(answer)), "whoAnswered": whoAnswered, type: tipo }
            }
        }
        
        
        return JSON.stringify(newModel)
    }

    protected getIgnoredAttributesForSave(): string[] {
        return [];
    }

    protected getAttributesToClearCommas(): string[] {
        return [];
    }

    // ***************************************************************

    protected getDataTransformations(): DataTransformation[] {
        return []
    }

    private executeDataTransformations() {
        let questDataTransformations: DataTransformation[] = [];
        questDataTransformations.push( new DataTransformation(this.getAttributesToClearCommas(), CommaFormatedNumberToJSNumber) );
        questDataTransformations.push(... this.getDataTransformations());

        questDataTransformations.forEach(dataTransformation => {
            dataTransformation.data.forEach(dataKey => {
                if (this.hasOwnProperty(dataKey)) {
                    // 
                    this[dataKey] = dataTransformation.transformationRule(this[dataKey]);
                }
            })
        });
    }

    // ***************************************************************

    protected beforeToSaveFormat() {
        ;
    }

    protected beforeMappingLoad(args: any[]) {
        ;
    }

    loadSubmodelFromRawArgs(args: any[], submodelClass: any, localSubmodelArrayProp: any[], quantityOfSubmodelsRawProp: string) {
        let findPropertieByName = (name: string) => {
            
            let finding = args.find(obj => obj.name == name);
            return finding != undefined ? finding.answer : undefined;
        }
        let quantityOfSubmodels = findPropertieByName(quantityOfSubmodelsRawProp);
        quantityOfSubmodels = quantityOfSubmodels != undefined ? quantityOfSubmodels : 0;
        for (let i = 1; i <= quantityOfSubmodels; i++) {
            let submodel = new submodelClass();
            for (let prop in submodel) {
                let propValue: any = findPropertieByName(prop + String(i));
                if (typeof typeof (this._classInitialState[prop]) === 'number'){
                    propValue = Number(propValue);
                    
                }
                submodel[prop] = propValue;
            }
            this.applyDataTypeCorrectionsOnInstance(new submodelClass(), submodel);
            localSubmodelArrayProp.push(submodel);
        }
    }

    saveSubmodelArrayToModelProp(submodelInstances: any[]) {
        let i: number = 1;
        submodelInstances.forEach(submodelInstance => {
            for (let prop in submodelInstance) {
                this[prop + String(i)] = submodelInstance[prop];
            }
            i++;
        });
    }

    private applyDataTypeCorrectionsOnInstance(pristineInstance: any, loadedInstance: any){

        //Verify that both objects are of the same type
        if(true /* pristineInstance.constructor.name === loadedInstance.constructor.name */){

            for (let propName in pristineInstance) {
                let typeOfPristineValue = typeof pristineInstance[propName];
                if (pristineInstance[propName] instanceof Date) {
                    loadedInstance[propName] = NumberToDate(loadedInstance[propName], pristineInstance[propName]);
                }else if (typeOfPristineValue === 'number') {
                    loadedInstance[propName] = Number (loadedInstance[propName]);
                } else if (typeOfPristineValue === 'string' && loadedInstance[propName] == "undefined") {
                    loadedInstance[propName] = '';
                }
            };

        }
    }

    public static updateModelFromModel(destinationQuestionnaire:Questionnaire, sourceQuestionnaire: Questionnaire, filter: string[]){
        filter.map(attributeName => {
            if(destinationQuestionnaire[attributeName] != undefined && sourceQuestionnaire[attributeName] != undefined){
              destinationQuestionnaire[attributeName] = sourceQuestionnaire[attributeName];
            }
        });
    }

}
