import { InsuranceResponse } from "../../components/insurance-snapshot/dataInsurance";

export const testingData_home: InsuranceResponse = {
  "platformContent": [
      {
          "title": "home 1",
          "type": "asset",
          "sections": [
              {
                  "sectionID": "policySummary",
                  "backgroundImageUrl": "/assets/images/HomeImage.png",
                  "pdf_printInNewPage": true,
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Policy Summary",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Insurance Provider",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Geico",
                                          "class": "font-small-bold-black",
                                          "imageUrl": "https://storage.googleapis.com/fpalpha-assets/pc_insurance/pc_insurance/Geico.png"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Name Insured",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "John Johnson",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Policy Period",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "03/23/22",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": " - ",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": "03/23/23",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Mailing Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Street #, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Premium",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$1,180",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,500",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "Per Covered Loss",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Location Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Street #, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "rows": [
                              {
                                  "isSingleBlock": true,
                                  "leftText": [
                                      {
                                          "class": "title-yellow",
                                          "value": "Property"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "class": "font-small-bold-black",
                                          "value": "The Property section of the insurance policy is protection for the items you own if damaged by a covered loss."
                                      }
                                  ]
                              },
                              {
                                  "isSingleBlock": true,
                                  "leftText": [
                                      {
                                          "class": "title-yellow",
                                          "value": "Property"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "class": "font-small-bold-black",
                                          "value": "The Liability section of the insurance policy provides protection against claims brought against you by a third party. This coverage applies to bodily injury and property damage that you are held legally responsible for incurring."
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "property",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Property",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Dwelling Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$477,003",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Contents Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$238,500",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Loss of Use Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$146,000",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "$1,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Additional Structures Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$47,700",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,500",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "Per Covered Loss",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Earthquake Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$20,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Flood Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$100,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Sump Pump Overflow Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$4,997",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Water/Drain backup Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$4,000",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "personal_liability__personal_inland_marine_insurance",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Personal Liability",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Liability Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$500,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Aggregate Limit of Liability",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$500,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Medical Payments",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$499,997",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "columnTitle": [
                              {
                                  "value": "Personal Inland Marine Insurance",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Jewelry Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$5,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Jewelry Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$5,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Silverware Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Jewelry Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$5,001",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "mortgagee_lienholders__other_coverages",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Mortgagee/Lienholder(s)",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Name",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Loan Company",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Address, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Loan Number",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "123459876",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "columnTitle": [
                              {
                                  "value": "Other coverages",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Name Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$15,000",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
      },
      {
          "title": "home 2.5",
          "type": "asset",
          "sections": [
              {
                  "sectionID": "policySummary",
                  "backgroundImageUrl": "/assets/images/HomeImage.png",
                  "pdf_printInNewPage": true,
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Policy Summary",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Insurance Provider",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "AAA",
                                          "class": "font-small-bold-black",
                                          "imageUrl": "https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/pc_insurance/Amica.png"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Name Insured",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "John Johnson",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Policy Period",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "03/23/22",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": " - ",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": "03/23/23",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Mailing Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Street #, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Premium",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$1,233",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,500",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "Per Covered Loss",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Location Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Street #, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "rows": [
                              {
                                  "isSingleBlock": true,
                                  "leftText": [
                                      {
                                          "class": "title-yellow",
                                          "value": "Property"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "class": "font-small-bold-black",
                                          "value": "The Property section of the insurance policy is protection for the items you own if damaged by a covered loss."
                                      }
                                  ]
                              },
                              {
                                  "isSingleBlock": true,
                                  "leftText": [
                                      {
                                          "class": "title-yellow",
                                          "value": "Property"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "class": "font-small-bold-black",
                                          "value": "The Liability section of the insurance policy provides protection against claims brought against you by a third party. This coverage applies to bodily injury and property damage that you are held legally responsible for incurring."
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "property",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Property",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Dwelling Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Contents Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Loss of Use Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$1,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Additional Structures Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$1,000",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Property Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$400",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "Per Covered Loss",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Sump Pump Overflow Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$2,000",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Water/Drain backup Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$199",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "personal_liability__personal_inland_marine_insurance",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Personal Liability",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Liability Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$30",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Aggregate Limit of Liability",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$40",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Medical Payments",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$50",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "columnTitle": [
                              {
                                  "value": "Personal Inland Marine Insurance",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Jewelry Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$21",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Fine Art Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$32",
                                          "class": "number-green"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Silverware Limit/Deductible",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$29",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "mortgagee_lienholders__other_coverages",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Mortgagee/Lienholder(s)",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Name",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "holis",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "mi casa",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Loan Number",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "213123231",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "columnTitle": [
                              {
                                  "value": "Other coverages",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Name Limit",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$212",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "$229",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
      },
      {
          "title": "Umbrella 1",
          "type": "umbrella",
          "sections": [
              {
                  "sectionID": "policySummary",
                  "backgroundImageUrl": "/assets/images/UmbrellaImage.png",
                  "pdf_printInNewPage": true,
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Policy Summary",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Insurance Provider",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "AAA",
                                          "class": "font-small-bold-black",
                                          "imageUrl": "https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/pc_insurance/Amica.png"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Name Insured",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "John Johnson",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Policy Period",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "03/23/22",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": " - ",
                                          "class": "font-small-bold-black"
                                      },
                                      {
                                          "value": "03/23/23",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Mailing Address",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "Street #, Cupertino, CA 12345",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Premium",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$1,180",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "umbrella_policy_limit",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Umbrella Policy Limit",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Aggregate Limit of Liability",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$900",
                                          "class": "number-green"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              },
              {
                  "sectionID": "underlying_limits",
                  "columns": [
                      {
                          "columnTitle": [
                              {
                                  "value": "Underlying Limits",
                                  "class": "title-yellow"
                              }
                          ],
                          "rows": [
                              {
                                  "leftText": [
                                      {
                                          "value": "Personal Liability",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$300",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "blah blah blah blah blah balh",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              },
                              {
                                  "leftText": [
                                      {
                                          "value": "Automobile Liability",
                                          "class": "name-row"
                                      }
                                  ],
                                  "rightText": [
                                      {
                                          "value": "$500",
                                          "class": "number-green"
                                      },
                                      {
                                          "value": "blah blah blah blah blah balhblah blah blah blah blah balhblah blah blah blah blah balh",
                                          "class": "font-small-bold-black"
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
      }
  ],
  "reportContent": [
      {
          "sectionID": "header",
          "backgroundImageUrl": "/assets/images/HomeImage.png",
          "pdf_printInNewPage": true,
          "columns": [
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "/assets/images/UmbrellaIcon.png"
                      },
                      {
                          "value": "Policy ",
                          "class": "title-yellow"
                      },
                      {
                          "value": "#123987",
                          "class": "title-yellow"
                      }
                  ],
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Premium",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$1,180",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Policy Period",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "03/23/22",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": " - ",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": "03/23/23",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Liability Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$500,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Aggregate Limit of Liability",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$500,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Mailing Address",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "Street #, Cupertino, CA 12345",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      }
                  ]
              },
              {
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Dwelling Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$477,003",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Contents Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$238,500",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Loss of Use Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$146,000",
                                  "class": "number-green"
                              },
                              {
                                  "value": "$1,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Additional Structures Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$47,700",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Earthquake Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$20,000",
                                  "class": "number-green"
                              }
                          ]
                      }
                  ]
              },
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "https://storage.googleapis.com/fpalpha-assets/pc_insurance/pc_insurance/Geico.png"
                      }
                  ],
                  "rows": []
              }
          ]
      },
      {
          "sectionID": "header",
          "backgroundImageUrl": "/assets/images/HomeImage.png",
          "pdf_printInNewPage": false,
          "columns": [
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "https://storage.googleapis.com/fpalpha-assets/pc_insurance/HomeIcon.png"
                      },
                      {
                          "value": "Policy ",
                          "class": "title-yellow"
                      },
                      {
                          "value": "#123989",
                          "class": "title-yellow"
                      }
                  ],
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Premium",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$1,233",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Policy Period",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "03/23/22",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": " - ",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": "03/23/23",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Liability Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$30",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Aggregate Limit of Liability",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$40",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Mailing Address",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "Street #, Cupertino, CA 12345",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      }
                  ]
              },
              {
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Dwelling Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$2,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Contents Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$2,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Loss of Use Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$1,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Additional Structures Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$1,000",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Sump Pump Overflow Limit",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$2,000",
                                  "class": "number-green"
                              }
                          ]
                      }
                  ]
              },
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/pc_insurance/Amica.png"
                      }
                  ],
                  "rows": []
              }
          ]
      },
      {
          "sectionID": "header",
          "backgroundImageUrl": "/assets/images/UmbrellaImage.png",
          "pdf_printInNewPage": true,
          "columns": [
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "https://storage.googleapis.com/fpalpha-assets/pc_insurance/UmbrellaIcon.png"
                      },
                      {
                          "value": "Policy ",
                          "class": "title-yellow"
                      },
                      {
                          "value": "#123988",
                          "class": "title-yellow"
                      }
                  ],
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Premium",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "$1,180",
                                  "class": "number-green"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Policy Period",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "03/23/22",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": " - ",
                                  "class": "font-small-bold-black"
                              },
                              {
                                  "value": "03/23/23",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Name Insured",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": [
                              {
                                  "value": "John Johnson",
                                  "class": "font-small-bold-black"
                              }
                          ]
                      }
                  ]
              },
              {
                  "rows": [
                      {
                          "leftText": [
                              {
                                  "value": "Personal Liability",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": []
                      },
                      {
                          "leftText": [
                              {
                                  "value": "Automobile Liability",
                                  "class": "name-row"
                              }
                          ],
                          "rightText": []
                      }
                  ]
              },
              {
                  "columnTitle": [
                      {
                          "value": "",
                          "imageUrl": "https://storage.googleapis.com/fpalpha-production-public/fpalpha-assets/pc_insurance/Amica.png"
                      }
                  ],
                  "rows": []
              }
          ]
      }
  ]
}
