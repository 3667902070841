export interface MagicStickyTableInterface {
  columns: MagicStickyTableColumnsContent;
  categories?: string[];
  fixedToTheLeft: string[]; //columnName
  fixedToTheRight: string[]; //columnName
  content: MagicStickyTableColumnsContent;
}

export interface MagicStickyTableColumn {
  columnName: string;
  category?: string;
  width?: number;
  widthPdf?: number;
  class?: string;
}

interface MagicStickyTableColumnsContent {
  [columnName: string]: any;
}

export const MagicStickyTable_EXAMPLE: MagicStickyTableInterface = {
  columns: {
    Age: {
      columnName: "Age",
      width: 80,
      widthPdf: 80,
    },
    Year: {
      columnName: "Year",
      width: 80,
      widthPdf: 80,
    },
    "Net Portfolio Balance": {
      columnName: "Net Portfolio Balance",
      category: "Balances - End of year",
      width: 160,
      widthPdf: 262,
    },
    "Traditional IRA Balance": {
      columnName: "Traditional IRA Balance",
      category: "Balances - End of year",
      width: 144,
      widthPdf: 262,
    },
    "Roth IRA Balance": {
      columnName: "Roth IRA",
      category: "Balances - End of year",
      width: 144,
      widthPdf: 262,
    },
    "Hypothetical Taxable Account": {
      columnName: "Hypothetical Taxable Account",
      category: "Balances - End of year",
      width: 160,
      widthPdf: 262,
    },
    Withdrawals: {
      columnName: "Withdrawals",
      category: "Balances - End of year",
      width: 160,
      widthPdf: 262,
    },
    "Taxes Paid": {
      columnName: "Taxes Paid",
      category: "Conversion details",
      width: 128,
      widthPdf: 272,
    },
    "Conversion Amount": {
      columnName: "Conversion Amount",
      category: "Conversion details",
      width: 144,
      widthPdf: 160,
    },
    "Required Minimum Distribution (RMD)": {
      columnName: "Required Minimum Distribution (RMD)",
      category: "Conversion details",
      width: 200,
      widthPdf: 230,
    },
    "Income Amount": {
      columnName: "Income Amount",
      category: "Conversion details",
      width: 155,
      widthPdf: 272,
    },
    Medicare: {
      columnName: "Medicare",
      category: "Conversion details",
      width: 144,
      widthPdf: 304,
    },
    "Value to Heir": {
      columnName: "Value to Heir",
      width: 160,
      widthPdf: 304,
    },
    "Marginal Tax Rate": {
      columnName: "Marginal Tax Rate",
      width: 144,
      widthPdf: 272,
    },
  },
  categories: ["Balances - End of year", "Conversion details"],
  fixedToTheLeft: ["Age", "Year"],
  fixedToTheRight: ["Value to Heir", "Marginal Tax Rate"],
  content: {
    Age: [
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
      [1],
      [2],
      [3],
    ],
    Year: [
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
      [4],
      [5],
      [6],
    ],
    "Net Portfolio Balance": [
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
    ],
    "Traditional IRA Balance": [
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
      [3, 4],
      [5, 6],
      [7, 8],
    ],
    "Roth IRA Balance": [
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
      [0, 0],
      [2, 22],
      [3, 33],
    ],
    "Hypothetical Taxable Account": [
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
      [12, 13],
      [14, 15],
      [16, 17],
    ],
    Withdrawals: [
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
    ],
    "Taxes Paid": [
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
    ],
    "Conversion Amount": [
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
    ],
    "Income Amount": [
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
    ],
    Medicare: [
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
      [22, 23],
      [24, 25],
      [26, 27],
    ],
    "Value to Heir": [
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
      [32, 33],
      [34, 35],
      [36, 37],
    ],
    "Marginal Tax Rate": [
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
      [42, 43],
      [44, 45],
      [46, 47],
    ],
  },
};
