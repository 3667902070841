import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionnaireManagerService } from 'app/shared/services/questionnaire-manager/questionnaire-manager.service';
import { GeneralQuestionnaireService } from 'app/shared/services/questionnaires/general-questionnaire.service';
import { maritalStatusesWithCoClient } from './templates/personal-information/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LeavingConfirmationComponent } from './dialogs/leaving-confirmation/leaving-confirmation.component';
import { PersonalInformationComponent } from './templates/personal-information/personal-information.component';

@Component({
  selector: "app-general-questionnaire",
  templateUrl: "./general-questionnaire.component.html",
  styleUrls: ["./general-questionnaire.component.scss"],
})
export class GeneralQuestionnaireComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(PersonalInformationComponent) personalInformationComponent: PersonalInformationComponent;

  composedClientName: string = "";

  maritalStatusesWithCoClient = maritalStatusesWithCoClient;

  isLoading: boolean = true;

  isSaveDisabled = false;

  isTabEnabled = false;

  isLeaveModalEnabled = false;

  constructor(
    public questionnaireManagerService: QuestionnaireManagerService,
    public generalQuestionnaireService: GeneralQuestionnaireService,
    public snackBar: MatSnackBar,
    public router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {

  }
  
  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
  }

  changeDisabledSaveButton(event){
    this.isSaveDisabled = event
  }

  activateLoader(){
    this.cdr.detectChanges();
    this.isLoading = true
  }

  goToNextStep() {
    this.activateLoader()
    this.tabGroup.selectedIndex += 1
    this.cdr.detectChanges();
  }

  goToPrevStep() {
    this.isLoading = true
    this.tabGroup.selectedIndex -= 1
    this.cdr.detectChanges();
  }

  save(){
    if (this.tabGroup.selectedIndex == 0) {
      this.personalInformationComponent.sendQuestionnaire()
    }
    this.router.navigate(['hub'])
  }

  enableTabs(event){
    this.isTabEnabled = event;
  }

  enableOnLeaveModal(event){
      this.isLeaveModalEnabled = event
  }

  goBackToData(){
    if (this.isLeaveModalEnabled) {
      const dialogRef = this.dialog.open(LeavingConfirmationComponent, {
        width: '600px',
        panelClass: 'general-questionnaire-theme',
      })
      dialogRef.componentInstance.isSaveDisabled = this.isSaveDisabled;
      dialogRef.componentInstance.handleOnSave.subscribe((resp) => {
        if(resp){
          this.save()
        }
      })
      dialogRef.componentInstance.handleOnLeave.subscribe((resp) => {
        if(resp){
          this.router.navigate(['hub'])
        }
      })
    }
    else{
      this.router.navigate(['hub'])
    }
  }
  
  loadForm(form) {
    this.isLoading = false
    if (this.tabGroup.selectedIndex == 0) {
      this.composedClientName = this.getClientName(form)
    }
  }

  getClientName(form: FormGroup) {
    const selectedMaritalStatus = form['clientMaritalStatus']
    const requiresCoClient = this.maritalStatusesWithCoClient.includes(selectedMaritalStatus);
    const hasCoClient = requiresCoClient;
    const hasCoClientSameLastname = form['coClientSameLastname'] != 'No';
    const clientName = form['clientFirstName'];
    const clientLastname = form['clientLastName'];
    const coClientName = form['coClientFirstName'];
    const coClientLastname = form['coClientLastName'];
    const isCoClientEmpty = hasCoClient && (coClientName.length > 0);
    let composedName = '';
    if (!hasCoClient || !isCoClientEmpty) {
      composedName = `${clientName} ${clientLastname}`;
    }
    if (hasCoClient && isCoClientEmpty) {
      if (hasCoClientSameLastname) {
        composedName = `${clientName} & ${coClientName} ${clientLastname}`;
      }
      if (!hasCoClientSameLastname) {
        composedName = `${clientName} ${clientLastname} & ${coClientName} ${coClientLastname}`;
      }
    }
    return composedName;
  }

}
