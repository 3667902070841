//Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ModalAdvisorSubmittedComponent } from '../modal-advisor/modal-advisor.component';

@Component({
  selector: 'prospect-area-description',
  templateUrl: './prospect-area-description.component.html',
  styleUrls: ['./prospect-area-description.component.scss'],
})
export class ProspectAreaDescription implements OnInit {

  public areaScore: number;
  public area: string;
  public title: string;
  public areaPoints: string[];
  public statusColor: string;

  constructor(private route: ActivatedRoute, public dialog: MatDialog,) {}

  ngOnInit() {

    this.area = this.route.snapshot.paramMap.get("area");

    this.route.queryParams
      .subscribe(params => {
        this.title = params.title;
        
      }
    );

    if(this.title.includes('Tax')){
      this.areaPoints = [
        'If you expect staying in the lower tax brackets, consider realizing long-term capital gains to take advantage of the special 0% capital gains tax rate. If you are in a similar tax position in 2021, consider realizing long-term capital gains to take advantage of the special 0% long-term capital gains tax rate that is in effect for these lowest tax brackets. Realizing too many long-term gains could subject a portion of the gains to tax.',
        'If you expect to be in a similar tax situation in the current tax year, consider making a Roth IRA contribution. Roth IRA contributions are not tax deductible, but the distributions can be tax- and penalty-free if the following conditions are met: 1. Avoid early withdrawals before age of 59 1/2, and 2. Distributions are made after a five-year holding period. Revisit the contribution amount in early March 2022.',
        'Because you have a business and your income is less $326,600, you may be eligible for 20% qualified business income (QBI) deduction. You could take the 20% on qualified business income (QBI) deduction on qualified business income for next year. If the business is a service business, then the 20% break will apply to the lesser of the qualified business income or the taxable income minus capital gains.'
      ]
      this.areaScore = 48;
    }

    if(this.title.includes('Estate')){
      this.areaPoints = [
        'Consider qualified terminable interest property (QTIP) trust arrangement for each spouse whereby the survivor must get all of the trust income while alive at least annually, allow the trustee to pay the trust principal to the survivor if and as needed, but the plan of the first decedent controls the property when the surviving spouse dies, thereby protecting the interests of the children of each spouse over two deaths.',
        'If you have signed a prenuptial agreement, make certain it is being followed, if not, inquire if signing a post-nuptial agreement should be considered.',
        'If you intend to have your partners take over your business upon your death, consider a buy/sell. If you want other partners to have control but your family to receive fair value for your business interest, you will need a succession plan. If liquidity is needed to buy partners out, consider funding with life insurance. Evaluate the differences between an entity purchase (in which the business owns a policy insuring the life of each owner and the business buys the other owner out) versus a cross-purchase (individuals own a policy on each owner). A cross-purchase plan can have significant tax advantages over an entity purchase plan, particularly in giving the purchasing owners a higher basis in their business interest because they, and not the entity, bought the interest of the deceased owner.'
      ]
      this.areaScore = 24;
    }

    if(this.title.includes('Home')){
      this.areaPoints = [
        'If extended replacement cost is not included in your policy, consider getting it. If the home’s insured value is incorrect, or the reconstruction costs rise in the event of a disaster, extended replacement offers protection. Extended replacement cost is often available as a rider, but could have a cap (e.g., 110% to 150% of the insured value).',
        'If your base deductible is lower than $10,000, see if a higher deductible is available and offers value. If your policy has a large loss deductible waiver, which waives the deductible for large claims, changing to a  higher deductible becomes less risky. Price out deductible options and do a break-even analysis: (additional deductible you would take / annual premium savings = number of years of not submitting a claim to break  even). The more expensive the home, the more a high-deductible policy could make financial sense.',
        'If your net worth is greater than the umbrella coverage included in policy, you should consider raising liability coverage. Umbrella and excess liability coverage provide additional protection beyond the underlying home and any auto policy limits, should you be a defendant in a lawsuit after getting into an accident on the road, someone falling in your home, etc. Determining the amount of liability coverage to obtain is not an exact science. Some insurance experts argue it should be at least as high as one’s net worth.'
      ]
      this.areaScore = 70;
    }


    if(Number(this.areaScore) > 1 && Number(this.areaScore) <= 9.9999) this.statusColor = 'danger-bar';
    if(Number(this.areaScore) >= 10 && Number(this.areaScore) <= 19.9999) this.statusColor = 'warn-bar-10';
    if(Number(this.areaScore) >= 20 && Number(this.areaScore) <= 29.9999) this.statusColor = 'warn-bar-20';
    if(Number(this.areaScore) >= 30 && Number(this.areaScore) <= 39.9999) this.statusColor = 'warn-bar-30';
    if(Number(this.areaScore) >= 40 && Number(this.areaScore) <= 49.9999) this.statusColor = 'warn-bar-40';
    if(Number(this.areaScore) >= 50 && Number(this.areaScore) <= 59.9999) this.statusColor = 'warn-bar';
    if(Number(this.areaScore) >= 60 && Number(this.areaScore) <= 69.9999) this.statusColor = 'ok-bar-60';
    if(Number(this.areaScore) >= 70 && Number(this.areaScore) <= 79.9999) this.statusColor = 'ok-bar-70';
    if(Number(this.areaScore) >= 80 && Number(this.areaScore) <= 89.9999) this.statusColor = 'ok-bar-80';
    if(Number(this.areaScore) >= 90 && Number(this.areaScore) <= 99) this.statusColor = 'ok-bar-90';
    if(Number(this.areaScore) === 100) this.statusColor = 'ok-bar';
  }

  contactMeModal(){
    const dialogRef = this.dialog.open(ModalAdvisorSubmittedComponent, {
      disableClose: false,
      panelClass: 'modal-dialog-questionnaire',
      height: '490px',
      width: '750px',
    });
  }


}
