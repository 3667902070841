import { Questionnaire } from '../Questionnaire.model';

export class creditor_questions extends Questionnaire {

    public clientIDInterestCreditProtection: string = '';
    public clientIDBusinessAnEntity: string = '';
    public clientIDCreditorPursueBusiness: string = '';
    public clientIDCreatingLLC: string = '';
    public clientIDCorpFormalities: string = '';
    public clientIDLLCSoleMember: string = '';
    public clientIDLLCPurpose: string = '';
    public clientIDOwnSCorp: string = '';
    public clientIDHasUmbrella: string = '';
    public clientIDInvestRealEstate: string = '';
    public clientIDTitleAssets: string = '';
    public clientIDHasStudentLoan: string = '';
    public clientIDAnnuities: string = '';
    public clientIDQualiRetAsset: string = '';
    public clientIDInheritIRA: string = '';
    public clientIDIRASeeThroughConduit: string = '';
    public clientIDOwnRealEstate: string = '';
    public clientIDNYApartment: string = '';
    public clientIDOwnsTBE: string = '';
    public clientIDHighRisk: string = '';
    public clientIDIntendMarry: string = '';
    public clientIDHasRevTrust: string = '';
    public clientIDIsIlliquid: string = '';
    public clientIDLawsuitLiab: string = '';
    public clientIDDebtCC: string = '';
    public clientIDDebtStudent: string = '';
    public clientIDUnpaidTax: string = '';
    public clientIDWorryRollOver: string = '';
    public clientIDContactByCollector: string = '';
    public clientIDHighRiskBusiness: string = '';

    // Progress Bar
    public progressBar: number = 0;
    public progressCompleted: boolean = false;
    public progressTab: number = 0;

    constructor() {
      super()
    }

    getQName(): string {
      return 'creditor';
    }

    getIgnoredAttributesForSave():string[]{
      return [''];
    }

}