import { Component, OnInit } from '@angular/core';
import { IntegrationStepService } from 'app/shared/services/integration/integration-step.service';

@Component({
  selector: 'integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  constructor(
    public integrationStepService: IntegrationStepService) {}

  ngOnInit() {
  }
}
