import { getImageFromUrlAndFitToBox } from './../../../reports/PdfCore.class';
import { Area } from 'app/shared/helpers/areas.catalog';
import { FontStyle, Margin, PageConfiguration, PAPER_SIZES, Paragraph, PdfCore, ImagePDF } from 'app/views/reports/PdfCore.class';
import { CONST_DISCLAIMER } from 'app/views/reports/stringConstants';

const FONT_FOOTER = new FontStyle('value-sans-regular-pro', [85, 85, 85],10);
const FONT_TITTLE = new FontStyle('value-sans-medium-pro', [255, 147, 30], 24);
const FONT_SUBTITLE = new FontStyle('value-sans-regular-pro', [0, 0, 0], 18);
const FONT_BODY = new FontStyle('value-sans-regular-pro', [0, 0, 0], 12);
const FONT_AREA = new FontStyle('value-sans-medium-pro', [0, 0, 0], 16);
const FONT_SECTION_NAME = new FontStyle('value-sans-medium-pro', [94, 94, 96], 10);

const TITLE_AREA_SCORE = {
  'Tax Planning':'Tax Wellness Score',
  'Estate Planning':'Estate Wellness Score',
  'Home Insurance':'Home Insurance Wellness Score',
  'Auto Insurance':'Auto Insurance Wellness Score',
  'Disability Insurance':'Disability Insurance Wellness Score',
  'Long Term Care':'Long Term Care Wellness Score',
  'Life Insurance':'Life Insurance Wellness Score',
  'Investment and Retirement':'Investment and Retirement Wellness Score',
}


export class ProspectReport extends PdfCore {


  constructor(
    public logoPdfImage: ImagePDF,
    public wellnessCardPdfImage: ImagePDF,
    public contactCardPdfImage: ImagePDF,
    public disclaimer: string
  ){
    super(new PageConfiguration(PAPER_SIZES.LETTER, new Margin(32, 12.7, 40, 12.7)));
    this.disclaimer = Boolean(this.disclaimer) ? this.disclaimer : CONST_DISCLAIMER;
    this.isDebugEnabled = false;
  }

  public setHeaderFormat() {
    this.logoPdfImage.xPos = this.pageDimensions.getCanvasCoordinatesEnd().x - this.logoPdfImage.width;
    this.logoPdfImage.yPos = 12.7;
    this.addImage(this.logoPdfImage);
  }

  public setFooterFormat() {
    FONT_FOOTER.lineSpacing = 1;
    this.printParagraphv2(new Paragraph(this.disclaimer, FONT_FOOTER, null, this.pageDimensions.getCanvasCoordinatesStart().x, 244.5));
  }

  public async printAreaRecs(area: Area, recs: any[], areaScore: number, areaScoreTitle: string, areaScoreColor: number[]){

    let areaNameLineHeight = 14;

    let areaImage = await getImageFromUrlAndFitToBox(area.iconColorUrl, 8, 8);
    // The nexts blocks needs to be called after the await, why?

    if(this.remainingVerticalSpace()  < areaNameLineHeight){
      this.addFormattedPage();
    }
    areaImage.xPos = this.pageDimensions.margin.left;
    areaImage.yPos = this.currentPosY - 1;
    this.addImage(areaImage);
    this.printParagraphv2(new Paragraph(`${TITLE_AREA_SCORE[area.name]} ${areaScore}%/100%`, FONT_AREA, null, this.pageDimensions.margin.left + 11, this.currentPosY));

    // Print score bar
    //this.currentPosY += 2;
    this.printScoreBar(areaScore, areaScoreColor, areaScoreTitle);
    this.currentPosY += 3;

    if(area.name === 'Investment and Retirement'){
      let successScore = JSON.parse(localStorage.getItem('probabilityOfSuccess'));
      

    }

    // Print recs
    recs.map(rec => {
      this.printParagraphv2(new Paragraph(rec.description, FONT_BODY));
      this.currentPosY += 5;
    })
    this.currentPosY += 7;

  }

  public printRec(rec: string){

  }

  static async generate(reportData: reportData): Promise<ProspectReport>{

    //Give format & dimensions to images
    let wellnessCardPdfImage = await getImageFromUrlAndFitToBox(reportData.wellnessCardUrl, 87, 47);
    let contactCardPdfImage = await getImageFromUrlAndFitToBox(reportData.contactCardUrl, 87, 47);
    let logoPdfImage = await getImageFromUrlAndFitToBox(reportData.logoUrl, 40, 15);

    // Instanciate the new report
    let report = new ProspectReport(logoPdfImage, wellnessCardPdfImage, contactCardPdfImage, reportData.disclaimer);
    // Build report

    report.addFormatToPage();
    report.currentPosY = report.pageDimensions.getCanvasCoordinatesStart().y;
    report.printParagraphv2(new Paragraph(reportData.clientsName, FONT_TITTLE));
    report.printParagraphv2(new Paragraph('Financial plan consultation', FONT_SUBTITLE));

    // Add wellness score card
    // Card 88x51.5; 12.5, 63

    report.doc.setDrawColor(242, 242, 242);
    report.doc.setLineWidth(0.5);
    report.doc.roundedRect(12.7, 63, 88, 51.5, 2, 2, 'S');


    wellnessCardPdfImage.xPos = 10 + 3.5;
    wellnessCardPdfImage.yPos = 63 + 3.5;
    report.addImage(wellnessCardPdfImage);

    // Add contact card
    // Card 88x51.5; 108, 63

    report.doc.setDrawColor(242, 242, 242);
    report.doc.setLineWidth(0.5);
    report.doc.roundedRect(108, 63, 88, 51.5, 2, 2, 'S');

    contactCardPdfImage.xPos = 108 + 3.5;
    contactCardPdfImage.yPos = 63 + 3.5;
    report.addImage(contactCardPdfImage);

    //Print recs
    report.currentPosY = 123;
    report.printParagraphv2(new Paragraph(`Here are some of the ${reportData.numberOfRecs} recommendations we identified for you`, FONT_SECTION_NAME));
    report.currentPosY += 5;

    await Promise.all(reportData.recomendations.map(async(recsArea)=>{
      await report.printAreaRecs(recsArea.area, recsArea.recs, recsArea.areaScore, recsArea.areaScoreTitle, recsArea.areaScoreColor);
    }));

    return report;

  }

  printScoreBar(percentage, color: number[], text: string ){

    const maxWidth = this.pageDimensions.width / 2;
    const barHeight = 5;
    const FONT = new FontStyle('value-sans-medium-pro', color, 10);

    percentage = percentage / 100;

    let prevDrawColor = this.doc.getDrawColor();
    let prevFillColor = this.doc.getFillColor();

    this.printParagraphv2(new Paragraph(text, FONT));
    this.currentPosY += 1;

    this.doc.setLineWidth(0.25);
    this.doc.setFillColor(228, 228, 228);
    this.doc.roundedRect(this.pageDimensions.getCanvasCoordinatesStart().x, this.currentPosY, maxWidth, barHeight, 2, 2, 'F');
    this.doc.setFillColor(...color);
    this.doc.roundedRect(this.pageDimensions.getCanvasCoordinatesStart().x, this.currentPosY, maxWidth * percentage, barHeight, 2, 2, 'F');
    this.currentPosY += barHeight;

    this.doc.setDrawColor(prevDrawColor);
    this.doc.setFillColor(prevFillColor);

  }

}

interface reportData{
  logoUrl: string;
  wellnessCardUrl: string;
  contactCardUrl: string;
  disclaimer: string,
  recomendations: ({area: Area, recs: string[], areaScore: number, areaScoreTitle: string, areaScoreColor: number[]}[]),
  clientsName: string,
  numberOfRecs: number
}
