import { Component, Input, OnInit } from '@angular/core';
import { MaterialInfoInterface, TEST_DATA_1 } from './material-info.interface';
import { getRandomStr } from 'app/shared/helpers/utils';

@Component({
  selector: 'app-material-info',
  templateUrl: './material-info.component.html',
  styleUrls: ['./material-info.component.scss']
})
export class MaterialInfoComponent implements OnInit {

   @Input() platformView: boolean = false;
   @Input() data: MaterialInfoInterface;
   @Input() htmlID: string;

  constructor() {
    if(this.htmlID == undefined){
      this.htmlID = getRandomStr(9);
    }
   }

  ngOnInit(): void {  }

}
