import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { REGEX_PDF } from "app/shared/components/file-uploader-v2/file-uploader-v2.component";
import { SnackbarUtil } from "app/shared/helpers/snackbar.util";
import { WINDOW } from "app/shared/helpers/window.provider";
import { AdvisorService } from "app/shared/services/advisor.service";
import { FileUploaderOptions } from "app/views/questionnaires/file-uploader-component/file-uploader-component.component";
import { FileUploaderExtended } from "app/views/questionnaires/FileUploaderExtended.model";
import { QuestionnaireController } from "app/views/questionnaires/Questionnaire.controller";
import { environment } from "environments/environment";

@Component({
  selector: "app-estate-uploader",
  templateUrl: "./estate-uploader.component.html",
  styleUrls: ["./estate-uploader.component.scss"],
})
export class EstateUploaderComponent implements OnInit, OnChanges {
  @ViewChild("fileElement", { static: false }) fileElement;
  @Input() public documentType: string;
  @Input() public fileID: string;
  @Input() public name: string;
  @Input() public allowMultiple: boolean;
  @Input() public defaultValue: any = null;
  @Input() public previousData: any = null;
  @Input() public date: any = null;
  @Input() public disabled: any = false;
  @Input() public index: number = 1;
  @Input() public subType: string = "";
  @Input() public showWarning: boolean = false;
  @Input() public trust: boolean = false;
  @Input() public status: string;
  @Input() public isJoint: boolean = false;
  @Input() public radioToHide: string = "none";

  @Output() public updateUploaderStatus = new EventEmitter<any>();
  @Output() public onRadioButtonChange = new EventEmitter<any>();

  public uploader: FileUploaderOptions;
  public clientData: any;
  public localStatus: string = "";

  private batchUpload = environment.apiFilesUpload;
  private uploadUrl: string;
  public dragging: Boolean = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    public qController: QuestionnaireController,
    public advisorService: AdvisorService,
    private snackbarUtil: SnackbarUtil
  ) {}

  addFiles() {
    this.fileElement.nativeElement.click();
  }

  handleChangeFile(event) {
    this.uploader.uploader.clearQueue();
    this.uploader.uploader.addToQueue(event);
  }

  logQueue(e: FileList | any = null) {
    const allowedUnits = ["Bytes", "KB", "MB"];
    if (e && !this.allowMultiple) {
      const file = e.item(0);
      if (!REGEX_PDF.test(file.name)) {
        this.snackbarUtil.error(
          "Only PDF files are allowed. Please, try again.",
          "Dismiss"
        );
      } else {
        this.handleChangeFile(e);
      }
    }

    if (this.allowMultiple && e) {
      const notUpload = [];
      let notUploadBySize = false;
      for (let i = 0; i < e.length; i++) {
        const file = e.item(i);
        if (!REGEX_PDF.test(file.name)) {
          this.snackbarUtil.error(
            "Only PDF files are allowed. Please, try again.",
            "Dismiss"
          );
          notUpload.push(file.name);
        } else {
          const { size, unit }: any = this.getFileSize(file?.size, true);
          if (
            !allowedUnits.includes(unit) ||
            (allowedUnits.includes(unit) && unit === "MB" && size > 200)
          ) {
            console.log("Not allowed: ", file.name);
            notUploadBySize = true;
            notUpload.push(file.name);
          }
        }
      }
      console.log(this.uploader.uploader.queue.length);
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file: any = this.uploader.uploader.queue[i]?.file;
        if (notUpload.includes(file?.name)) {
          this.removeFromQueue(i);
        }
      }
      if (notUploadBySize) {
        this.snackbarUtil.error(
          "Some files won't be uploaded. Upload file size limit is up to 200 MB. Try with a different file.",
          "Dismiss"
        );
      }
    }

    if (!this.allowMultiple) {
      const individualFile = this.uploader.uploader.queue[0];
      if (individualFile) {
        const { size, unit }: any = this.getFileSize(
          individualFile?.file?.size,
          true
        );
        if (
          !allowedUnits.includes(unit) ||
          (allowedUnits.includes(unit) && unit === "MB" && size > 200)
        ) {
          this.snackbarUtil.error(
            "File upload limit is up to 200 MB. Try with a different file.",
            "Dismiss"
          );
          this.removeFromQueue(0);
        }
      }
    }
    if (this.allowMultiple && !e) {
      const notUpload = [];
      let notUploadBySize = false;
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file = this.uploader.uploader.queue[i]?.file;
        if (!REGEX_PDF.test(file.name)) {
          this.snackbarUtil.error(
            "Only PDF files are allowed. Please, try again.",
            "Dismiss"
          );
          notUpload.push(file.name);
        } else {
          const { size, unit }: any = this.getFileSize(file?.size, true);
          if (
            !allowedUnits.includes(unit) ||
            (allowedUnits.includes(unit) && unit === "MB" && size > 200)
          ) {
            notUploadBySize = true;
            notUpload.push(file.name);
          }
        }
      }
      for (let i = 0; i < this.uploader.uploader.queue.length; i++) {
        const file: any = this.uploader.uploader.queue[i]?.file;
        if (notUpload.includes(file?.name)) {
          this.removeFromQueue(i);
        }
      }
      if (notUploadBySize) {
        this.snackbarUtil.error(
          "Some files won't be uploaded. Upload file size limit is up to 200 MB. Try with a different file.",
          "Dismiss"
        );
      }
    }
    this.updateUploaderStatus.emit({
      uploader: this.uploader,
      fileKey: this.fileID,
      trustStatus: this.localStatus,
    });
  }

  onRadioChange(event) {
    console.log(event);
    this.onRadioButtonChange.emit({
      trustStatus: event.value,
    });
  }

  ngOnInit() {
    let sessionToken = localStorage.getItem("sessionToken");
    this.clientData = JSON.parse(sessionStorage.getItem("currentClient"));
    this.uploadUrl = `${this.batchUpload}/estate/${this.clientData.clientId}?index=${this.index}&policyFileName=clientIDOtherEstate&nameOfInsuranceCompany=1`;

    this.uploadUrl = this.advisorService.dynamicIPAddress(
      this.window,
      this.uploadUrl
    );
    this.uploader = new FileUploaderOptions({
      fileID: this.fileID,
      fileVal: "No",
      uploader:
        this.fileID === "clientIDFiles"
          ? new FileUploaderExtended({
              url: this.uploadUrl,
              authTokenHeader: "Authorization",
              authToken: `Bearer ${sessionToken}`,
              itemAlias: "clientIDFiles",
            })
          : this.qController.fileUploaderFactoryAutoUpload(
              this.window,
              this.clientData.clientId,
              "estate",
              false,
              this.index,
              this.fileID === "clientIDFiles"
                ? "cientIDOtherEstate"
                : this.fileID,
              this.allowMultiple ? 20 : 1,
              true
            ),
      documentReadableName: this.documentType,
      validationRule: () => {
        return true; /* return this.uploaderWill.uploader.queue.length <= 0 ? true : this.uploaderWill.status == UPLOADER_STATUS.FILE_VALID */
      },
      actionsAfterResponse: {
        validatedVoid: () => {
          this.qController.openInavlidDocDialog(
            this,
            this.clientData.clientId,
            "estate",
            this.index,
            this.fileID,
            () => {}
          );
        },
        validating: () => {},
      },
      ignoreIsComponentAlive: true,
    });
    if (this.defaultValue && this.fileID !== "clientIDFiles") {
      this.uploader.uploader.addToQueue([this.defaultValue]);
    } else if (this.defaultValue) {
      this.uploader.uploader.addToQueue(this.defaultValue);
    }
    this.localStatus = this.status;
  }

  getFileSize(size, internal = false) {
    let auxSize;
    let unit;
    if (size < 1000) {
      auxSize = size;
      unit = "Bytes";
    } else if (size < 1000 * 1000) {
      auxSize = size / 1000;
      unit = "KB";
    } else if (size < 1000 * 1000 * 1000) {
      auxSize = size / 1000 / 1000;
      unit = "MB";
    } else {
      auxSize = size / 1000 / 1000 / 1000;
      unit = "GB";
    }
    if (internal) {
      return { size: parseInt(auxSize, 10), unit };
    }
    return `${parseFloat(auxSize).toFixed(1)} ${unit}`;
  }

  fileOverBase(e: any) {
    this.dragging = e;
  }

  removeFromQueue(index) {
    this.uploader.uploader.removeFromQueue(index);
    this.logQueue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("CHANGES: ", changes);
    if(changes.isJoint){
      this.isJoint = changes.isJoint.currentValue;
    }
    if(changes.status){
      this.onRadioButtonChange.emit({
        trustStatus: changes.status.currentValue,
      });
      this.status = changes.status.currentValue;
      this.localStatus = this.status;
    }
  }
}
