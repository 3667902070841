import { FieldMetadataInterface } from "./../../../views/questionnaires/q-tax/q-tax.metadata";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { CloseDialogAtLogOut } from "app/shared/helpers/CloseDialogAtLogOut.class";
import {
  urlHelperIsInEnvironment,
  ENVIRONMENT_URL_PRODUCTION,
} from "app/shared/helpers/url.helper";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";
import { FullValidationFlag } from "app/views/questionnaires/q-taxfull/taxfull-model";
import { CONST_STATES_KEY } from "../../../views/questionnaires/Questionnaire.constants";

class ReviewExtractionsDialogData {
  public modelRef: any;
  public flagKey: string;
  public listOfValidationFlagsRef: FullValidationFlag[];
  public isNextYearSelected?: boolean = false;
  public yearSelected?: number = 2020;
  public hasScheduleB: boolean = false;
}

/**
 * form1040_1_1.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/81928cae-11c4-47b6-bf9d-73886d5a1dea"}
form1040_1_3.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/ae41031e-cd8b-4855-9338-b62d539fd184"}
form1040_2_1.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/8dd09a24-0f6c-4a7f-881c-24229b12ac83"}
schedule1.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/ba60d2e4-8820-4d41-af6f-d0e30b6114d4"}
scheduleA.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/f40021d5-bdf4-44b5-b975-32e8ea29eefe"}
scheduleC.jpg: SafeUrlImpl {changingThisBreaksApplicationSecurity: "blob:http://localhost:4200/4178f51c-0b58-4fca-be08-3544b2c67303"}
scheduleD.jpg:
 */

@Component({
  selector: "app-review-extractions-dialog",
  templateUrl: "./review-extractions-dialog.component.html",
  styleUrls: ["./review-extractions-dialog.component.scss"],
})
export class ReviewExtractionsDialogComponent
  extends CloseDialogAtLogOut
  implements OnInit
{
  public size: any;
  public currentFlagIndex: number = -1;
  public currentFlag: FullValidationFlag = {
    keyFlag: "",
    isActive: false,
    url: "",
    metadata: {},
  };
  public currentFlagLoadedImage: any = "assets/images/spinner-blue.svg";
  public currentModelValCopy: any;
  public static imageBuffer: any[] = [];
  public currentFlagLineText: string = "";
  public sizes: any = {
    "form1040_1_1_val.jpg": 192,
    "form1040_1_1.jpg": 192,
    "form1040_1_2.jpg": 300,
    "form1040_1_2_vale.jpg": 300,
    "form1040_1_3.jpg": 397,
    "form1040_1_3_val.jpg": 397,
    "form1040_1__val.jpg": 397,
    "form1040_2_1.jpg": 192,
    "form1040_2_1_val.jpg": 192,
    "form1040_2_1_valsr.jpg": 192,
    "schedule1.jpg": 164,
    "schedule1_page2.jpg": 164,
    "schedule3.jpg": 186,
    "scheduleA.jpg": 230,
    "scheduleC.jpg": 250,
    "scheduleD_val.jpg": 162,
    "scheduleD.jpg": 162,
    "scheduleA_val.jpg": 230,
    "scheduleC_val.jpg": 250,
    "state.jpg": 550,
    "cutfor12a_val.jpg": 296,
    "scheduleb_2_1_val.jpg": 800,
    "scheduleb_2_2_val.jpg": 800,
    "scheduleb_2_2_valFlag.jpg": 550,
    "schedule1_valSR.jpg": 192,
    "form8582_page1.jpg": 462,
    "form8582_page2.jpg": 562,
    "schedule1_lines8.jpg": 164,
    "scheduleE_page2.jpg": 182,
    "scheduleE_2.jpg": 182,
    "scheduleSE_internalLines.jpg": 192,
  };
  public CONSTANTS = { CONST_STATES_KEY };
  public scheduleBTextInputs_1: any = [];
  public scheduleBAmpuntInputs_1: any = [];
  public scheduleBTextInputs_5: any = [];
  public scheduleBAmpuntInputs_5: any = [];
  public loadScheduleB: boolean = false;
  public scheduleBActive: number = 1;
  public isProd: boolean = urlHelperIsInEnvironment[ENVIRONMENT_URL_PRODUCTION];
  public loadingImage: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReviewExtractionsDialogData,
    public dialogRef: MatDialogRef<ReviewExtractionsDialogComponent>,
    public advisorService: AdvisorService,
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private http: HttpClient
  ) {
    super(dialogRef, authService);
  }

  ngOnInit() {
    this.loadFlagFromListByKey(this.data.flagKey);
    // console.logthis.currentFlag);
    if (this.data.hasScheduleB) {
      Object.keys(this.data.modelRef).forEach((key) => {
        if (
          key.includes("scheduleb_5") &&
          key.includes("_amount") &&
          !key.includes("flag_") &&
          this.scheduleBAmpuntInputs_5?.length < 15
        ) {
          const [, , indexStr, type] = key.split("_");
          const index = parseInt(indexStr, 10);
          this.scheduleBAmpuntInputs_5.push(key);
        }
        if (
          key.includes("scheduleb_5") &&
          key.includes("_text") &&
          !key.includes("flag_") &&
          this.scheduleBTextInputs_5?.length < 15
        ) {
          const [, , indexStr, type] = key.split("_");
          const index = parseInt(indexStr, 10);
          this.scheduleBTextInputs_5.push(key);
        }
        if (
          key.includes("scheduleb_1") &&
          key.includes("_amount") &&
          !key.includes("flag_") &&
          this.scheduleBAmpuntInputs_1?.length < 15
        ) {
          const [, , indexStr, type] = key.split("_");
          const index = parseInt(indexStr, 10);
          this.scheduleBAmpuntInputs_1.push(key);
        }
        if (
          key.includes("scheduleb_1") &&
          key.includes("_text") &&
          !key.includes("flag_") &&
          this.scheduleBTextInputs_1?.length < 15
        ) {
          const [, , indexStr, type] = key.split("_");
          const index = parseInt(indexStr, 10);
          this.scheduleBTextInputs_1.push(key);
        }
        if (key.includes("scheduleb_1") || key.includes("scheduleb_5")) {
          console.log(key);
        }
      });
      console.log(
        "FINAL",
        this.scheduleBAmpuntInputs_1,
        this.scheduleBTextInputs_1,
        this.scheduleBAmpuntInputs_5,
        this.scheduleBTextInputs_5
      );
    }
    console.log("Modelo del inicio: ", this.data.modelRef);
  }

  toggleMagnify() {
    if (document.getElementById("img-magnifier-glass")) {
      document.getElementById("img-magnifier-glass").remove();
    } else {
      setTimeout(() => {
        console.log(this.size);
        this.magnify(
          "myimage",
          1.5,
          this.currentFlagLoadedImage.changingThisBreaksApplicationSecurity,
          this.size
        );
      }, 100);
    }
  }

  async loadFlagFromListByKey(key: string) {
    this.loadingImage = true;
    let flagSearch = this.data.listOfValidationFlagsRef.find(
      (flag) => flag.keyFlag == key
    );
    if (flagSearch) {
      //Handle sch. B extractions
      if (
        flagSearch.keyFlag === "scheduleB_1" ||
        flagSearch.keyFlag === "scheduleB_5"
      ) {
        this.loadScheduleB = true;
        if (flagSearch.keyFlag === "scheduleB_1") {
          this.scheduleBActive = 1;
        } else if (flagSearch.keyFlag === "scheduleB_5") {
          this.scheduleBActive = 5;
        }
      } else {
        this.loadScheduleB = false;
        this.scheduleBActive = 1; // Set back to initial value
        if (
          flagSearch.keyFlag === "scheduleb_2" ||
          flagSearch.keyFlag === "scheduleb_4"
        ) {
          this.scheduleBActive = 2;
        }
      }
      this.currentFlag = flagSearch;
      this.currentFlagIndex = this.data.listOfValidationFlagsRef.findIndex(
        (flag) => flag.keyFlag == key
      );
      this.currentModelValCopy = this.data.modelRef[key];
      this.currentFlagLoadedImage = "assets/images/spinner-blue.svg";
      //this.currentFlagLineText =  (this.data.isNextYearSelected && this.currentFlag.metadata.lineNextYear) ? this.currentFlag.metadata.lineNextYear : this.currentFlag.metadata.line;
      this.currentFlagLineText = this.currentFlag.metadata.linesByYear.get(
        this.data.yearSelected
      );
      //Load Image
      console.log(this.currentFlag);
      let currentClient = JSON.parse(sessionStorage.getItem("currentClient"));
      //let blobImage = await this.getImageFromBufferOrUpdate(this.currentFlag.url, currentClient.clientId);
      let { image: blobImage, size } = await this.getImageFromBufferOrUpdate(
        this.currentFlag.url,
        currentClient.clientId
      );
      this.size = size;
      if (blobImage) {
        this.currentFlagLoadedImage = blobImage;
        var img, glass, w, h, bw, magnifierContainer;
        img = document.getElementById("myimage");
        if (this.currentFlag.keyFlag === "livingState") {
          img.style.width = `${this.size}px`;
        } else {
          if (img.style != undefined) {
            img.style.width = "";
          }
        }
        if (document.getElementById("img-magnifier-glass")) {
          setTimeout(() => {
            this.magnify(
              "myimage",
              1.5,
              this.currentFlagLoadedImage.changingThisBreaksApplicationSecurity,
              this.size
            );
          }, 100);
        }
        this.loadingImage = false;
      }
    } else {
    }
  }

  loadPrevNextFlag(direction: number = 1) {
    if (this.data.listOfValidationFlagsRef.length > 0) {
      let nextIndex =
        direction >= 0
          ? this.currentFlagIndex + 1 <
            this.data.listOfValidationFlagsRef.length
            ? this.currentFlagIndex + 1
            : 0
          : this.currentFlagIndex == 0
          ? this.data.listOfValidationFlagsRef.length - 1
          : this.currentFlagIndex - 1;

      this.loadFlagFromListByKey(
        this.data.listOfValidationFlagsRef[nextIndex].keyFlag
      );
    } else {
      this.dialogRef.close();
    }
  }

  approveCurrentFlag() {
    this.data.modelRef[this.currentFlag.keyFlag] = this.currentModelValCopy;
    this.currentFlag.isActive = false;
    this.data.modelRef.saveFlag(this.currentFlag);
    this.loadPrevNextFlag(1);
    if (!this.isAnyFlagActiveOnListOfValidationFlags()) {
      console.log(this.data.modelRef);
      this.dialogRef.close();
    }
  }

  async getImageFromBufferOrUpdate(url: string, clientId: string) {
    // if(ReviewExtractionsDialogComponent.imageBuffer[url] == undefined){
    //   let blobImage = await this.advisorService.getReviewExtractionImage(clientId, url);
    //   ReviewExtractionsDialogComponent.imageBuffer[url] = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blobImage));
    //   console.log(ReviewExtractionsDialogComponent.imageBuffer);
    // }
    let blobImage;
    try {
      blobImage = await this.advisorService.getReviewExtractionImage(
        clientId,
        url
      );
    } catch (response) {
      blobImage = await this.http
        .get("/assets/images/Imagenotavailable.png", {
          responseType: "blob" as "blob",
        })
        .toPromise();
    }

    ReviewExtractionsDialogComponent.imageBuffer[url] =
      this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blobImage));
    return {
      image: ReviewExtractionsDialogComponent.imageBuffer[url],
      size: this.sizes[url],
    };
  }

  static deleteBufferedImages() {
    ReviewExtractionsDialogComponent.imageBuffer = [];
  }

  isAnyFlagActiveOnListOfValidationFlags(): boolean {
    let isAnyFlagOn: boolean = false;
    this.data.listOfValidationFlagsRef.map((flag) => {
      isAnyFlagOn = isAnyFlagOn || flag.isActive;
    });
    return isAnyFlagOn;
  }

  magnify(imgID, zoom, src, size) {
    var img, glass, w, h, bw, magnifierContainer;
    if (document.getElementById("img-magnifier-glass")) {
      document.getElementById("img-magnifier-glass").remove();
    }
    img = document.getElementById(imgID);

    setTimeout(() => {
      if (document.getElementById("img-magnifier-glass")) {
        document.getElementById("img-magnifier-glass").remove();
      }
      img.addEventListener("load", () => {
        console.log(`IMAGE WIDTH AFTER LOADED: ${img.width}`);
      });
      /* Create magnifier glass: */

      magnifierContainer = document.getElementById("imgMagnifierContainer");
      magnifierContainer.style.width = size + "px";
      glass = document.createElement("DIV");
      glass.setAttribute("class", "img-magnifier-glass");
      glass.setAttribute("id", "img-magnifier-glass");
      // glass.style.position = 'absolute';
      // glass.style.border = '2px solid #000';
      // glass.style.cursor = 'none';
      // glass.style.width = '100px';
      // glass.style.height = '100px';

      /* Insert magnifier glass: */
      img.parentElement.insertBefore(glass, img);

      glass.style.backgroundImage = "url('" + src + "')";
      glass.style.backgroundRepeat = "no-repeat";
      glass.style.backgroundSize =
        size * zoom + "px " + img.height * zoom + "px";
      bw = 3;
      w = this.loadScheduleB ? 0 : glass.offsetWidth / 2;
      h = glass.offsetHeight / 2;
      /* Execute a function when someone moves the magnifier glass over the image: */
      glass.addEventListener("mousemove", moveMagnifier);
      img.addEventListener("mousemove", moveMagnifier);

      /*and also for touch screens:*/
      glass.addEventListener("touchmove", moveMagnifier);
      img.addEventListener("touchmove", moveMagnifier);
      function moveMagnifier(e) {
        var pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        /* Get the cursor's x and y positions: */
        pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;
        /* Prevent the magnifier glass from being positioned outside the image: */
        if (x > size - w / zoom) {
          x = size - w / zoom;
        }
        if (x < w / zoom) {
          x = w / zoom;
        }
        if (y > img.height - h / zoom) {
          y = img.height - h / zoom;
        }
        if (y < h / zoom) {
          y = h / zoom;
        }
        /* Set the position of the magnifier glass: */
        glass.style.left = x - w + "px";
        glass.style.top = y - h + "px";
        /* Display what the magnifier glass "sees": */
        glass.style.backgroundPosition =
          "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
      }

      function getCursorPos(e) {
        var a,
          x = 0,
          y = 0;
        e = e || window.event;
        /* Get the x and y positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
    }, 200);
  }
}
