import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-estate-snapshot-status',
  templateUrl: './estate-snapshot-status.component.html',
  styleUrls: ['./estate-snapshot-status.component.scss']
})
export class EstateSnapshotStatusComponent implements OnInit {

  @Input() status: string = 'Not requested';

  constructor() { }

  ngOnInit() {
  }

}
