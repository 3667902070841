import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CloseDialogAtLogOut } from 'app/shared/helpers/CloseDialogAtLogOut.class';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-calculator-modify-year-amount-modal',
  templateUrl: './calculator-modify-year-amount-modal.component.html',
  styleUrls: ['./calculator-modify-year-amount-modal.component.scss']
})
export class CalculatorModifyYearAmountModalComponent extends CloseDialogAtLogOut implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CalculatorModifyYearAmountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
  ) {
    super(dialogRef, authService);
  }

    closeDialog() {
      this.dialogRef.close();
    }

    isString(item) {
      return typeof item === 'string';
    }

    isNotString(item) {
      return typeof item !== 'string';
    }


  ngOnInit() {
  }
}
