export const MINUTES_TO_MS = 60000;

export class SequentialJobsTimerDispatcher {
    public listOfSequentialWorks: SequentialJob[];
    intervalCounter: any;

    constructor() {
        this.intervalCounter = setInterval(() => {
            this.listOfSequentialWorks.forEach(job => {
                if (job.isExecutionTime()) job.callbackFunction();
            })
        }, 100);
    }

    stopAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
            job.isActive = false;
            job.clearLocalStorageVariable();
        });
    }

    stopOneJob(jobLocalStorageVariable:string) : boolean{
        let foundJob = this.listOfSequentialWorks.find(job => job.localStorageVariable == jobLocalStorageVariable);
        if(foundJob){
            foundJob.isActive = false;
            foundJob.clearLocalStorageVariable();
            return true;
        }
        return false;
    }

    restartAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
            job.reset();
            job.isActive = true;
        });
    }

    resumeAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
            if(job.remainingTimeInSec <= 0) job.reset(); // Reset only the jobs that are new or are expired.
            job.isActive = true;
        });
    }

    updateOrInstertSequentialJob(newJob: SequentialJob){
        // Get jobs position if exist in the array, return -1 if it dosnt exist
        let newJobPositionOnArray = this.listOfSequentialWorks.findIndex(currentJob => currentJob.localStorageVariable == newJob.localStorageVariable);

        // Insert job in the jobs array
        if(newJobPositionOnArray >= 0){
            this.listOfSequentialWorks[newJobPositionOnArray] = newJob;
        }else{
            newJobPositionOnArray = this.listOfSequentialWorks.push(newJob) - 1;
        }

        // Start the new job
        this.listOfSequentialWorks[newJobPositionOnArray].reset();
        this.listOfSequentialWorks[newJobPositionOnArray].isActive = true;

    }
}

export class SequentialJob {
    public isActive: boolean = false;
    public remainingTimeInSec: number = 0;
    private remaining: number = 0;
    constructor(
        public intervalInSeconds: number,
        public callbackFunction: (() => void),
        public localStorageVariable: string,
    ) {
        if(!this.localStorageVariable) throw new Error('A local storage variable must be set');
        let newJobFutureExecutionTime = localStorage[this.localStorageVariable];
        if(newJobFutureExecutionTime !== undefined){
          this.remaining = localStorage[this.localStorageVariable] - +new Date();
          this.remainingTimeInSec = Math.floor(this.remaining / 1000);
        };
    }

    reset() {
      localStorage[this.localStorageVariable] = +new Date() + this.intervalInSeconds
    }

    isExecutionTime(): boolean {
        if (!this.isActive) return false;
        if (!localStorage.getItem(this.localStorageVariable)) this.reset();
        this.remaining = localStorage[this.localStorageVariable] - +new Date();
        this.remainingTimeInSec = Math.floor(this.remaining / 1000);
        if (this.remaining <= 0) {
            this.reset();
            return true;
        }
        return false;
    }

    clearLocalStorageVariable() {
        if (localStorage.getItem(this.localStorageVariable)) localStorage.removeItem(this.localStorageVariable);
    }
};

export class SequentialJobsDispatcher {

    public static instance: SequentialJobsDispatcher;
    public listOfSequentialWorks: SequentialJob[] = [];
    intervalCounter: any;

    private constructor() {}

    public static getInstance(): SequentialJobsDispatcher{
        if(!SequentialJobsDispatcher.instance){
            SequentialJobsDispatcher.instance = new SequentialJobsDispatcher();
            SequentialJobsDispatcher.instance.intervalCounter = setInterval(() => {
                SequentialJobsDispatcher.instance.listOfSequentialWorks.forEach(job => {
                    if (job.isExecutionTime()) job.callbackFunction();
                })
            }, 100);
        }
        return SequentialJobsDispatcher.instance;
    }


    stopAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
          this.stopOneJob(job.localStorageVariable);
        });
    }

    stopOneJob(jobLocalStorageVariable:string) : boolean{
        let foundJob = this.listOfSequentialWorks.find(job => job.localStorageVariable == jobLocalStorageVariable);
        let foundJobIndex = this.listOfSequentialWorks.findIndex(job => job.localStorageVariable == jobLocalStorageVariable);
        if(foundJob){
            foundJob.isActive = false;
            foundJob.clearLocalStorageVariable();
            this.listOfSequentialWorks.splice(foundJobIndex, 1); //Delete the job from the list
            return true;
        }
        return false;
    }

    restartAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
            job.reset();
            job.isActive = true;
        });
    }

    //1689015087704
    //1689015115455
    resumeAllJobs() {
        this.listOfSequentialWorks.forEach(job => {
            if(job.remainingTimeInSec <= 0) job.reset(); // Reset only the jobs that are new or are expired.
            job.isActive = true;
        });
    }

    updateOrInstertSequentialJob(newJob: SequentialJob){ // Occurs once
        // Get jobs position if exist in the array, return -1 if it dosnt exist
        let newJobPositionOnArray = this.listOfSequentialWorks.findIndex(currentJob => currentJob.localStorageVariable == newJob.localStorageVariable);

        // Insert job in the jobs array
        if(newJobPositionOnArray >= 0){
            this.listOfSequentialWorks[newJobPositionOnArray] = newJob;
        }else{
            newJobPositionOnArray = this.listOfSequentialWorks.push(newJob) - 1;
        }

        // Start the new job
        let newJobFutureExecutionTime = localStorage[newJob.localStorageVariable];
        if(newJobFutureExecutionTime === undefined || newJobFutureExecutionTime < +new Date()){
          this.listOfSequentialWorks[newJobPositionOnArray].reset();
        };

        this.listOfSequentialWorks[newJobPositionOnArray].isActive = true;

    }
}
