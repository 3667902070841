import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileUploaderExtended } from '../FileUploaderExtended.model';
import { showUploadGuideComponent } from './showUploadGuide.component';


@Component({

  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() fileID: string = "";
  @Input() fileVal: string = "";
  @Input() uploader: FileUploaderExtended;
  @Input() documentReadableName: string = "policy";
  @Output() onUploaderReset: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFileLoad = new EventEmitter<string>();
  @Output() onFileLoadChange = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  _onFileLoad() {
    this.onFileLoad.emit(this.fileID);
    this.onFileLoadChange.emit(this.fileID);
  }

  _onFileDelete() {
    this.onFileLoad.emit(this.fileID);
    this.onFileLoadChange.emit(this.fileID);
  }

  resetUploader() {
    let _fileVal = this.fileVal;
    this.fileVal = '';
    this.uploader.clearQueue();
    this.onUploaderReset.emit(_fileVal);
  }

  showUploaderGuideModal() {
    
    const dialogRef = this.dialog.open(showUploadGuideComponent, {
      data: "message"
    });
  }

}
