// Angular
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

//Project
import { AdvisorService } from '../../../../../shared/services/advisor.service';
import { urlHelperIsInEnvironment, ENVIRONMENT_URL_PRODUCTION } from '../../../../../shared/helpers/url.helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationStepService } from 'app/shared/services/integration/integration-step.service';
import { INTEGRATION_STEPS } from 'app/shared/services/integration/utils';
import { IntegrationAvailabilityService } from 'app/shared/services/integration/integration-availability.service';
import { InfoIntegrations } from 'app/shared/model/Integration-Availability.model';

@Component({
  selector: 'integrations-home',
  templateUrl: './integrations-home.component.html',
  styleUrls: ['./integrations-home.component.scss']
})

export class IntegrationsHomeComponent implements OnInit {
  @Output() integrationActive = new EventEmitter();

  public advisorData;
  public redtailIntegrated: boolean = false;
  public salesforceIntegrated: boolean = false;
  public wealthboxIntegrated: boolean = false;
  public riskalyzeIntegrated: boolean = false;
  public isRiskalyzeTester: boolean;
  public moneyGuideIntegrated: boolean = false;
  public location: any;
  public isProduction: boolean;
  public connectedApps: any[];
  public seeingIntegration: boolean = false;
  public comeFromLogin: boolean = false;
  public currentIntegration: string = '';
  public moneyGuideId: string = '';
  //
  public precisefpIntegrated: boolean = false;
  public isPreciseFPTester: boolean;
  //Orion
  public  orionIntegrated: boolean = false;
  public  isOrionTester: boolean;

  public integrations = [
    {
      name:'redtail',
      label: 'Redtail',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Redtail-logo.png',
      active: false,
      status: ''
    },
    {
      name:'salesforce',
      label: 'Salesforce',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Salesforce.png',
      active: false,
      status: ''
    },
    {
      name: 'wealthbox',
      label: 'Wealthbox',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Wealthbox.png',
      active: false,
      status: ''
    },
    {
      name: 'moneyguide',
      label: 'MoneyGuide',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/MoneyGuide.png',
      active: false,
      status: ''
    },
    {
      name: 'riskalyze',
      label: 'Nitrogen',
      img: 'https://storage.googleapis.com/fpalpha-assets/Integrations_Logos/Nitrogen.png',
      active: false,
      status: ''
    },
    {
      name: 'precisefp',
      label: 'PreciseFP',
      img: 'assets/images/precisefp.svg',
      active: false,
      status: ''
    },
    {
      name: 'orion',
      label: 'Orion',
      img: 'assets/images/orion.jpg',
      active: false,
      status: ''
    }
  ]
  integrationStatusByRoute = {
    "ReadyToImport" : '',
    "MapperNotConfigured" : 'mapper',
    "NoAuth" : 'instructions',
    "SessionExpired" : 'instructions',
    "Error" : 'instructions',
    "NoActions": 'instructions'
  }

  constructor(private advisorService: AdvisorService,
              private http: HttpClient,
              public snackBarWarning: MatSnackBar,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private integrationAvailabilityService: IntegrationAvailabilityService,
              public integrationStepService: IntegrationStepService) {
                this.activeRoute.queryParams.subscribe(params => {
                  if(params['currentIntegration']){
                    this.comeFromLogin = true;
                    this.currentIntegration = params['currentIntegration']
                  }
                });
               }

  @ViewChild('stepper', { static: false }) private stepper: MatStepper;

  ngOnInit() {
    this.integrationStepService.isLoaded = false
    //Know if it's production or not
    let location = window.location.hostname;
    this.isProduction = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION);
    location === 'localhost' ? this.location = 'localhost:4200' : this.location = location;

    this.getAdvisorData();
    this.getConnectedApps();
    this.getIntegrationAvailability();

    //We are going to let this users test the new stuff (RT)
    //this.isProduction && riskalyzeEarlyAccess.includes(localStorage.getItem('userId')) ? this.isRiskalyzeTester = true : this.isRiskalyzeTester = false;
    localStorage.getItem('betaAccess').includes('riskalyzeEarlyAccess') ? this.isRiskalyzeTester = true : this.isRiskalyzeTester = false;
    localStorage.getItem('betaAccess').includes('precisefpEarlyAccess') ? this.isPreciseFPTester = true : this.isPreciseFPTester = false;
    localStorage.getItem('betaAccess').includes('orionEarlyAccess') ? this.isOrionTester = true : this.isOrionTester = false;
    this.updateLocalStorage()

  }

  ngAfterViewInit(){
    if(this.comeFromLogin){
      this.goForward(this.currentIntegration);
    }
  }

  getIntegrationAvailability(){
    this.integrationAvailabilityService.getIntegrationAvailability().subscribe(({infoIntegrations}: {infoIntegrations: InfoIntegrations}) => {
      Object.keys(infoIntegrations).forEach((item, i) => {
        this.integrations.forEach((arg, j) => {
          if(item == arg.name){
            this.integrations[j].active = infoIntegrations[item].isActive
            this.integrations[j].status = infoIntegrations[item].status
          }
        })
      })
    })
  }

  goToIntegration(integration){
    if (integration.status === '' || this.integrationStatusByRoute[integration.status] == '') {
      this.router.navigate(['/settings/external-providers-interactions', integration.name]);
    }
    else{
      this.router.navigate(['/settings/external-providers-interactions', integration.name, this.integrationStatusByRoute[integration.status]] );
    }
  }

  getConnectionStatus(integration: any){
    if (integration.active) {
      return 'Manage';
    }
    if (integration.status == 'SessionExpired') {
      return 'Reconnect';
    }
    else{
      return 'Connect';
    }
  }

  getAdvisorData() {
    this.advisorService.getAdvisorData()
      .subscribe(
        data => {
          this.advisorData = data;
          this.advisorData.integrations.redtail === true ? this.redtailIntegrated = true : this.redtailIntegrated = false;
          this.advisorData.integrations.salesforce === true ? this.salesforceIntegrated = true : this.salesforceIntegrated = false;
          this.advisorData.integrations.wealthbox === true ? this.wealthboxIntegrated = true : this.wealthboxIntegrated = false;
          this.advisorData.integrations.riskalyze === true ? this.riskalyzeIntegrated = true: this.riskalyzeIntegrated = false;
          //this.advisorData.external-providers-interactions.moneyguide === true ? this.moneyGuideIntegrated = true: this.moneyGuideIntegrated = false;
          this.precisefpIntegrated = (this.advisorData.integrations.precisefp === true);
          this.orionIntegrated = (this.advisorData.integrations.orion === true);
          

          let wealthboxSessionExpiresAt = new Date(this.advisorData._integrations.wealthbox ? this.advisorData._integrations.wealthbox.sessionExpiresAt : null);
          let currentDate = new Date();

          if(this.advisorData.integrations.wealthbox && (wealthboxSessionExpiresAt < currentDate)){
            this.disconnectWealthbox();
          }

        },
        error => {
          console.log(error);
        });
  }

  updateLocalStorage(){
    this.advisorService.getAdvisorData()
      .toPromise()
      .then(
        data => {
          this.integrationStepService.isLoaded = true
          localStorage.setItem("external-providers-interactions", JSON.stringify(data.integrations));
        }
      )
  }

  getConnectedApps() {
    this.integrationStepService.isLoaded = false
    this.advisorService.getConnectedApps().then((data: any) => {
      this.connectedApps = data.connectedApps || [];
      this.moneyGuideIntegrated = this.connectedApps.some(app => {
        return app.oauthApp[0].name === 'MoneyGuide Pro'
      })

      let moneyGuide = this.connectedApps.find(app=> app.name === 'MoneyGuide Pro');
      if(moneyGuide){
        this.moneyGuideId = moneyGuide._id;
      }

      this.integrationStepService.isLoaded = true
    });
  }

  disconnectConnectedApp(connectedAppId: string) {
    this.advisorService.disconnectConnectedApp(connectedAppId).then(data => {
      this.getConnectedApps();
    }).catch(err => {
      window.alert('Error disconnecting from application.');
    });
  }

  launchConnectedApp(appUrl: string) {
    window.open(appUrl);
  }

  goForward(integration) {
    this.seeingIntegration = true;
    this.integrationActive.emit(this.seeingIntegration);

    if(integration == 'redtail'){
        this.stepper.selectedIndex = 1;
    }
    if(integration == 'salesforce'){
        this.stepper.selectedIndex = 2;
    }
    if(integration == 'wealthbox'){
        this.stepper.selectedIndex = 3;
    }
    if(integration == 'riskalyze'){
      this.stepper.selectedIndex = 4;
    }
    if(integration == 'moneyguide'){
      this.stepper.selectedIndex = 5;
    }
    if(integration == 'precisefp'){
      this.stepper.selectedIndex = 6;
    }
    if(integration == 'orion'){
      this.stepper.selectedIndex = 7;
    }

    //this.stepper.next();
  }

  disconnectWealthbox() {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = new URLSearchParams();

    this.http.post<any>(`${environment.apiWealthboxDisconnectAccount}`, body, options)
      .subscribe(
        success => {
          console.log(success, 'Wealthbox disconnected');
          this.snackBarWarning.open("Your Wealthbox session has expired. Please reconnect for importing more clients.", "Ok", {
            duration: 7000,
            panelClass: 'alert-snackbar'
          });

          this.wealthboxIntegrated = false;
        },
        error => {
          console.log('Error: Wealthbox disconnect');
        }
      )
  }

}

