import { initPendo } from "app/shared/helpers/pendo.helper";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { InsuranceServiceService } from "../insurance-service.service";
import { Router } from "@angular/router";
import { homeInsuranceCompanies } from "../insurance-constants";
import { AdvisorService } from "app/shared/services/advisor.service";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { AddPropertyDialogComponent } from "../../hub-multi-area/add-property-dialog/add-property-dialog.component";
import { DialogConfirm } from "app/shared/components/dialog-confirm/dialog-confirm.component";
import { SnackbarUtil } from "app/shared/helpers/snackbar.util";

@Component({
  selector: "app-insurance-form",
  templateUrl: "./insurance-form.component.html",
  styleUrls: ["./insurance-form.component.scss"],
})
export class InsuranceFormComponent implements OnInit {
  public model: any = {
    haveHomeInsurance: "",
  };
  public type: any;
  public selectOptions: any = {
    home: homeInsuranceCompanies,
    auto: homeInsuranceCompanies,
  };
  public list = [];
  public lastActivity: any = {};
  public clientData: any;
  public clientID: string;
  public titles: any = {
    home: ["Home Policy", "Umbrella Policy"],
    auto: ["Auto Policy"],
    life: ["Life Policy"],
  };
  public firstPolicy: boolean = true;
  public firstUmbrella: boolean = true;
  public disabledAddUmbrella: boolean = false;
  public companyType: string = "legacy";
  public uploaderVariables: any = {
    home: {
      insurance: {
        documentType: "Home Policy",
        policyFileName: "clientIDHomePolicy",
        nameOfInsuranceCompany: "clientIDHomeInsuranceCompany",
        clientIDPolicy: "clientIDHomePolicy",
      },
      textClass: "text-home-insurance",
    },
    auto: {
      insurance: {
        documentType: "Auto Policy of ",
        policyFileName: "clientIDAutoPolicy",
        nameOfInsuranceCompany: "clientIDAutoInsuranceCompany",
        clientIDPolicy: "clientIDAutoPolicy",
      },
      textClass: "text-auto-insurance",
    },
  };
  public umbrellasIndexes: any = {};
  // TODO -- hardcoded ony for test purposes
  public requestStatus: string = "requested";
  public listObserver: any;
  public loadingInformation: boolean = true;
  public auxFiles: any;
  public creditsInformation: any;

  public indexOfItems: any[] = [];
  public uploading: boolean = false;
  public areFilesUnderReview: boolean = false;

  constructor(
    public insuranceService: InsuranceServiceService,
    private router: Router,
    private advisorService: AdvisorService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private snackbar: SnackbarUtil
  ) {}

  ngOnInit(): void {
    this.uploading = true;
    this.type = this.insuranceService.getType();
    this.clientData = JSON.parse(sessionStorage.getItem("currentClient"));
    this.clientID = this.authService.isAdvisor()
      ? this.clientData.clientId
      : localStorage.getItem("userId");
    this.model = {};
    this.getInformation();
    this.auxFiles = this.insuranceService.files;
    this.advisorService.getInsuranceProviders().then((res: any) => {
      this.selectOptions[this.type] = res.map(({ showName }) => showName);
    });
    this.advisorService
      .getCompanyDataPromise()
      .then((response) => (this.companyType = response.uploadModel.type));
    this.listObserver = this.insuranceService.getList.subscribe(
      (currentList) => {
        this.auxFiles = this.insuranceService.files;
        this.list = currentList;
        this.model.files = {};
        let indexes = [];
        this.indexOfItems = [];
        if (currentList.length > 0) {
          this.firstPolicy = false;
        } else {
          this.firstPolicy = true;
        }
        currentList?.forEach((element, index) => {
          //console.log('Element*', index, ' - ', element);
          indexes.push(element.index);
          this.indexOfItems.push(element.index);
          this.model.files[element.index] =
            this.type === "home"
              ? {
                  clientIDHomeInsuranceCompany: "",
                  clientIDClientUmbrellaInsuranceCompany: "",
                  clientIDClientHasUmbrellaDocument: "",
                  clientIDHomePolicy: "",
                  clientIDAssetName: element.name,
                }
              : {
                  clientIDAutoInsuranceCompany: "",
                  clientIDAutoPolicy: "",
                  clientIDAssetName: element.name,
                };
          if (this.auxFiles && Object.keys(this.auxFiles).length > 0) {
            Object.keys(this.auxFiles).forEach((fileKey) => {
              this.model.files[fileKey] = this.auxFiles[fileKey];
              if (this.auxFiles[fileKey].clientIDClientHasUmbrellaDocument) {
                this.firstUmbrella = false;
                this.umbrellasIndexes[fileKey] = this.auxFiles[fileKey];
              }
            });
          }
          this.verifyItems(this.list);
          this.loadingInformation = false;
        });
        //console.log('this.model.files*', this.model.files);

        console.log("currentList", currentList);

        currentList.map((fileInfo) => {
          this.areFilesUnderReview =
            this.areFilesUnderReview || fileInfo.status === "UNDER_REVIEW";
        });
      }
    );
    this.getSnapshotStatus();
    this.insuranceService._credits.subscribe((credits) => {
      this.creditsInformation = credits;
    });
    // console.log('MODELO: ', this.model.clientIDClientHasHomeInsurance, this.model.clientIDWantsToInputPolicyDataHome);
  }

  verifyItems(currentList) {
    const existentIndexes = [];
    currentList?.forEach((element) => {
      existentIndexes.push(element?.index);
    });
    Object.keys(this.model.files).forEach((key) => {
      if (
        !existentIndexes.includes(Number(key)) &&
        !existentIndexes.includes(key)
      ) {
        delete this.model.files[key];
      }
    });
  }

  async getSnapshotStatus() {
    if (this.type !== "life") {
      try {
        this.uploading = true;
        const ans: any = await this.advisorService.statusSnapshotData(
          this.clientID,
          this.type
        );
        const { status }: any = ans;
        this.verifyItems(this.list);
        if (
          status === "Pending" ||
          status === "In review" ||
          status === "On Hold"
        ) {
          this.requestStatus = "Requested"; //'validating'
        } else {
          this.requestStatus = status;
        }
        setTimeout(() => (this.loadingInformation = false), 100);
        // this.loadingInformation = false;
        console.log(this.requestStatus);
        this.uploading = false;
      } catch (e) {
        this.uploading = false;
        console.log(e);
      }
    }
  }

  show() {}

  updateOnChange(event: any) {
    this.insuranceService.setModelData(this.model);
  }

  ngOnDestroy(): void {
    this.listObserver.unsubscribe();
  }

  sendFilesToParent(event: any) {
    this.model.files[Number(event.index)][event.fileKey] = event.uploader;
    this.insuranceService.setModelData(this.model);
  }

  async getInformation(updateUmbrellas = true) {
    try {
      this.uploading = true;
      const activityAns = await this.advisorService.getInsuranceActivity(
        this.clientID,
        this.type
      );
      this.lastActivity =
        activityAns[0]?.data && !activityAns[0]?.data.accepted
          ? { ...activityAns[0]?.data, date: activityAns[0].date }
          : "";
      Object.keys(this.lastActivity).forEach((key) => {
        this.model.files[key] = {
          ...(this.model.files[key] || {}),
          ...this.lastActivity[key],
        };
        if (
          this.lastActivity[key]?.clientIDClientHasUmbrellaDocument &&
          updateUmbrellas
        ) {
          this.umbrellasIndexes[key] = {
            ...(this.model.files[key] || {}),
            ...this.lastActivity[key],
          };
          if (Object.keys(this.umbrellasIndexes).length > 0) {
            this.firstUmbrella = false;
          } else {
            this.firstUmbrella = true;
          }
        }
      });
      if (Object.keys(this.model.files).length > 0) {
        this.firstPolicy = false;
      } else {
        this.firstPolicy = true;
      }
      this.verifyItems(this.list);
      delete this.model.files.date;
      this.uploading = false;
    } catch (e) {
      this.uploading = false;
      console.log(e);
    }
  }

  public foundArea: any = {
    home: {
      id: "home",
      name: "Home Insurance",
      isActive: true,
      colorHex: "#F2B620",
      isMultiple: true,
      iconUrl:
        "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/03_areas_home.svg",
      iconColorUrl:
        "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg",
      mainObjectDescription: "property",
    },
    auto: {
      id: "auto",
      name: "Auto Insurance",
      isActive: true,
      colorHex: "#CC521E",
      isMultiple: true,
      iconUrl:
        "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg",
      iconColorUrl:
        "https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg",
      mainObjectDescription: "car",
    },
  };

  async addItemToList() {
    const dialogRef = this.dialog.open(AddPropertyDialogComponent, {
      width: "250px",
      data: {
        mainObjectDescription: this.foundArea[this.type].mainObjectDescription,
        title: "",
        area: this.type,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log("Este es el result*", result);
      const ans = await this.advisorService.addHubSubQuestionnares(
        this.insuranceService.clientId,
        this.type,
        result
      );
      await this.insuranceService.saveAnsweredFormQuestions(
        this,
        true,
        ans[ans?.length - 1].index
      );
      await this.insuranceService.getDataFromService(this.type);
      this.disabledAddUmbrella = false;
      this.updateOnChange({});
    });
  }

  async addUmbrellaToList_() {
    if (this.type === "home") {
      let auxHomes = Object.keys(this.model.files);

      let auxUmbrellasObjectListIndex: string[] = Object.keys(
        this.umbrellasIndexes
      );

      let umbrellaIndexToBeAdded = auxUmbrellasObjectListIndex.length;

      let availableUmbrellasInHomeIndexes = auxHomes.filter(
        (key) =>
          this.model.files[key].clientIDClientHasUmbrellaDocument === "" &&
          this.model.files[key].clientIDClientUmbrellaInsuranceCompany === ""
      );

      console.log(
        "availableUmbrellasHomeIndexes",
        availableUmbrellasInHomeIndexes
      );

      //Current homes has unasigned umbrellas and the umbrellas spaces is the same
      if (
        auxUmbrellasObjectListIndex.length <
        availableUmbrellasInHomeIndexes.length
      ) {
        umbrellaIndexToBeAdded = availableUmbrellasInHomeIndexes
          .map((index, naturalIndex) => naturalIndex)
          .shift();
      } else if (
        availableUmbrellasInHomeIndexes.length <
        auxUmbrellasObjectListIndex.length
      ) {
        umbrellaIndexToBeAdded = availableUmbrellasInHomeIndexes.length;
      } else if (
        availableUmbrellasInHomeIndexes.length >
        auxUmbrellasObjectListIndex.length
      ) {
      }

      if (
        this.model.files[
          auxHomes[umbrellaIndexToBeAdded] ||
            `${auxHomes[umbrellaIndexToBeAdded]}`
        ]
      ) {
        this.umbrellasIndexes[auxHomes[umbrellaIndexToBeAdded]] =
          this.model.files[
            auxHomes[umbrellaIndexToBeAdded] ||
              `${auxHomes[umbrellaIndexToBeAdded]}`
          ];
        auxHomes = Object.keys(this.model.files);
        if (
          auxHomes?.length < auxUmbrellasObjectListIndex?.length ||
          auxHomes?.length === auxUmbrellasObjectListIndex?.length
        ) {
          this.disabledAddUmbrella = true;
          this.firstUmbrella = false;
        } else {
          this.disabledAddUmbrella = false;
          this.firstUmbrella = false;
        }
      }
      this.updateOnChange({});
    }
  }

  async addUmbrellaByIndex(umbrellaIndexToBeAdded: string) {
    this.firstUmbrella = false;
    this.umbrellasIndexes[umbrellaIndexToBeAdded] =
      this.model.files[umbrellaIndexToBeAdded];
  }

  deleteConfirmation(index) {
    // console.log('Index to delete*', index);
    const dialogRef = this.dialog.open(DialogConfirm, {
      data: "The questionnaire and all the related data (recommendations) will be deleted. Are you sure you want to remove this asset? This is an irreversible action.",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteItem(Number(index));
      }
    });
  }

  async deleteUmbrella(index) {
    try {
      delete this.umbrellasIndexes[index];
      this.model.files[index].clientIDClientHasUmbrellaDocument = "";
      this.model.files[index].clientIDClientUmbrellaInsuranceCompany = "";
      this.snackbar.success("Umbrella deleted correctly", "Ok");
      this.updateActivityFileStatusByIndex(index);
      //await this.insuranceService.getDataFromService(this.type);
      //await this.getInformation(false);
      console.log("this.model.files", this.model.files);
      if (Object.keys(this.umbrellasIndexes).length > 0) {
        this.firstUmbrella = false;
      } else {
        this.firstUmbrella = true;
        this.disabledAddUmbrella = false;
      }

      let filesObjectKeys = Object.keys(this.model.files);
      let availableUmbrellas = filesObjectKeys.filter(
        (key) =>
          this.model.files[key].clientIDClientHasUmbrellaDocument === "" &&
          this.model.files[key].clientIDClientUmbrellaInsuranceCompany === ""
      );
      console.log(
        "availableUmbrellas",
        availableUmbrellas,
        availableUmbrellas.length
      );
      if (availableUmbrellas.length > 0) {
        this.disabledAddUmbrella = false;
      }
    } catch (e) {
      console.log(e);
      this.snackbar.error("An error ocurred. Try again later", "Dismiss");
    }
  }

  async deleteUmbrella_(index) {
    try {
      delete this.umbrellasIndexes[index];
      this.model.files[index].clientIDClientHasUmbrellaDocument = "";
      this.model.files[index].clientIDClientUmbrellaInsuranceCompany = "";
      this.snackbar.success("Umbrella deleted correctly", "Ok");
      await this.insuranceService.getDataFromService(this.type);
      await this.getInformation(false);
      if (Object.keys(this.umbrellasIndexes).length > 0) {
        this.firstUmbrella = false;
      } else {
        this.firstUmbrella = true;
        this.disabledAddUmbrella = false;
      }
    } catch (e) {
      console.log(e);
      this.snackbar.error("An error ocurred. Try again later", "Dismiss");
    }
  }

  async deleteItem(index) {
    try {
      await this.advisorService.deleteHubSubQuestionnares(
        this.type,
        this.clientID,
        index
      );
      delete this.model.files[index];
      delete this.model.files[`${index}`];
      this.indexOfItems = this.indexOfItems.filter(
        (element) => element !== index
      );
      // console.log('this.indexOfItems after*', JSON.parse(JSON.stringify(this.indexOfItems)));

      await this.insuranceService.getDataFromService(this.type);
      await this.getInformation();
      this.snackbar.success("Policy deleted correctly", "Ok");
    } catch (e) {
      console.log(e);
      this.snackbar.error("An error ocurred. Try again later", "Dismiss");
    }
  }

  async updateActivityFileStatusByIndex(resourceIndex: string) {
    let insuranceActivityResponse =
      await this.advisorService.getInsuranceActivity(this.clientID, "home");
    let insuranceActivityData = insuranceActivityResponse[0]["data"];

    let fileIndexesInResponse = Object.keys(insuranceActivityData);

    if (insuranceActivityData[resourceIndex] != undefined) {
      insuranceActivityData[resourceIndex] = this.model.files[resourceIndex];
      console.log(
        "updateActivityFileStatusByIndex. Update Activity file:",
        insuranceActivityData
      );

      await this.advisorService.postInsuranceActivity(
        this.clientID,
        insuranceActivityResponse["area"],
        insuranceActivityData
      );
    } else {
      console.log("updateActivityFileStatusByIndex. Nothing to update");
    }
  }
}
