import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdvisorService } from 'app/shared/services/advisor.service';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';

@Component({
  selector: 'app-upload-history-dialog',
  templateUrl: './upload-history-dialog.component.html',
  styleUrls: ['./upload-history-dialog.component.scss']
})
export class UploadHistoryDialogComponent implements OnInit {

  public modalInfo: any;
  public dataSource: any = []; 
  public displayedColumns: any = ['document', 'advisor', 'date'];
   public clientData: any;
  public clientHasAnswered: any;
  public clientID: any;
  public area: string = 'estate';
  public resourceId: any;
  public creditsInfo: any;
  public creditsUsed: number = 0;
  public uploadData: any;
  public loading: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadHistoryDialogComponent>,
    private advisorService: AdvisorService,
    private authService: AuthenticationService
  ) {
    if (this.data.title) {
      this.modalInfo = {...this.data};
    }
    this.area = this.data.area;
    this.creditsUsed = this.data.creditsUsed;
   }

  ngOnInit(): void {
    console.log('🧨🧨🧨 DATATATA',this.data)

    this.clientData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.clientHasAnswered = JSON.parse(sessionStorage.getItem('clientHasAnsweredQuestionnaires'));

    // Get client data
    this.clientID = this.authService.isAdvisor() ? this.clientData.clientId : localStorage.getItem('userId');
    this.getPreviousUploads();
  }

  async getPreviousUploads() {
    try {
      
      const ans = await this.advisorService.getHistoryUploads(this.data.companyId, null, this.area);
      console.log('🐣🐣🐣PREVIOUS', ans);
      this.uploadData = ans[this.area].map(({ advisor, date, client, documents }) => ({
        document: [...documents],
        advisor,
        date,
        client,
      }));
      console.log(this.uploadData);
      this.dataSource = this.uploadData;
      this.loading = false;
    } catch(e) {
      this.loading = false
    }
  }

  close() {
    this.dialogRef.close();
  }

}
