import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CloseDialogAtLogOut } from "app/shared/helpers/CloseDialogAtLogOut.class";
import { AuthenticationService } from "app/shared/services/auth/authentication.service";

@Component({
    selector: 'dialog-confirm',
    templateUrl: 'dialog-confirm.html',
})
export class DialogConfirmSend extends CloseDialogAtLogOut {

    public hasAccessToRecommendations: boolean = false

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmSend>,
        @Inject(MAT_DIALOG_DATA) public data:any,
        private authService: AuthenticationService,
        ) {
          super(dialogRef, authService);
          this.hasAccessToRecommendations = this.authService.currentRoleHasAccessTo('planningopps')
        }

        ngOnDestroy () {
            if (localStorage.getItem('estate-error')) {
                localStorage.removeItem('estate-error');
            }
        }

    onConfirmNHub(): void {
        this.dialogRef.close('to hub');
    }

    onConfirmNAreas(): void {
        this.dialogRef.close('to areas');
    }

    onConfirmSummary():void {
        this.dialogRef.close('to summary');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
