import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[percentageMask]'
})
export class PercentageMaskDirective {
    
  constructor(private el: ElementRef, private control: NgControl) { }

  ngOnInit() {
    setTimeout(() => {
      this.initializeValue();  
    }, 1500);
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/%/g, ''); // Remove existing percentage signs
    value = this.addPercentageSign(value);
    this.control.control.setValue(value, { emitEvent: false });

    // Set cursor position to before the percentage sign
    setTimeout(() => {
      input.selectionStart = value.length - 1;
      input.selectionEnd = value.length - 1;
    }, 0);
  }

  private initializeValue() {
    const initialValue = String(this.control.control.value);
    if (initialValue && !initialValue.includes('%')) {
      const valueWithPercentage = this.addPercentageSign(initialValue);
      this.control.control.setValue(valueWithPercentage, { emitEvent: false });
      
      // Set cursor position to before the percentage sign
      const input = this.el.nativeElement;
      setTimeout(() => {
        input.selectionStart = valueWithPercentage.length - 1;
        input.selectionEnd = valueWithPercentage.length - 1;
      }, 0);
    }
  }

  private addPercentageSign(value: string): string {
    if (value === '') return '';
    return `${value}%`;
  }

}
