import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogGenericNoticeComponent } from 'app/shared/components/dialog-generic-notice/dialog-generic-notice.component';
import { Observable } from 'rxjs';

import { readFromStoragedObject } from '../../../../shared/helpers/utils';
import { AdvisorService } from '../../../../shared/services/advisor.service';
import { CONST_MARRIAGESTATUS } from '../../../questionnaires/Questionnaire.constants';
import { CALCULATORS_CATALOG, calculatorTransformable, Lines } from '../Calculators.class';
import { CalculatorsComponent } from '../calculators.component';
import { scenarioData } from './../../scenarios/Scenario.class';

@Component({
  selector: 'app-calc-type-one',
  templateUrl: './calc-type-one.component.html',
  styleUrls: ['./calc-type-one.component.scss']
})
export class CalcTypeOneComponent implements OnInit, calculatorTransformable {

  @Input() calculatorId: string;
  @Input() responseData: any;
  @Input() parentOnLoad: Observable<any>;
  @Input() agi: number;
  @Input() calcQBID: number;
  @Input() taxableIncome: number;
  @Input() filingStatus: string;
  @Input() parent: CalculatorsComponent;
  @Input() overwriteListFromParent: any;
  @Input() qbiPreDeduction: any;
  @Input() medicalDentalExpenses: any;
  @Input() tax: number;


  @Output() goToCalculatorId = new EventEmitter<string>();

  public CONSTANTS = {CONST_MARRIAGESTATUS};
  public clientId: string = ''
  public catalogCalculator: any;
  public dataProcessed: any;
  public sstbVariables: string[] = ['SSTB', 'Non-SSTB', 'N/A']
  public noTransformTypeOrId: string[] = ['name', 'addButton', 'text', 'staticText', 'subsection']
  public overwriteListKeys: any[] = [];

  constructor(
    public advisorService: AdvisorService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.overwriteListFromParent[this.calculatorId].map(element => {
      this.overwriteListKeys.push(element.key);
    })
    // find calculator data from response
    this.catalogCalculator = CALCULATORS_CATALOG.find(sectionCalculator => sectionCalculator.id === this.calculatorId)
    // read client id from session storage
    this.clientId = readFromStoragedObject('currentClient', 'clientId', 'Session');
    // dataProcessed is assigned by build function
    this.dataProcessed = this.build(this.responseData[this.calculatorId], this.catalogCalculator);

    //if calculatorId is SchB or charitable, execute buildTypeB function
    if (this.calculatorId === 'scheduleBCalculator' || this.calculatorId === 'charitableContributionCalculator'){
      this.buildTypeB(this.calculatorId);
    }
    //In those cases there are data that must be assigned depending on the calculator selected

      this.dataProcessed.sections[0].scenariosData.forEach((scenario, index) => {
        if (index > 0) {
          if(this.calculatorId === 'scheduleACalculator'){

            let scenarioData = scenario.find(line => line.assetId === 'agi');
            scenarioData.value = this.agi[index - 1];
            scenarioData = scenario.find(line => line.assetId === 'seventyFiveOfAgi');
            scenarioData.value = this.agi[index - 1] * 0.075;

            //excessOver75OfAgi = Max(medicalDentalExpenses, seventyFiveOfAgi)
            let medicalDentalExpenses = scenario.find(line => line.assetId === 'medicalDentalExpenses');
            let seventyFiveOfAgi = scenario.find(line => line.assetId === 'seventyFiveOfAgi');
            scenarioData = scenario.find(line => line.assetId === 'excessOver75OfAgi');
            let excessOver75OfAgi = medicalDentalExpenses.value - seventyFiveOfAgi.value;
            scenarioData.value = Math.max(excessOver75OfAgi, 0);


          }else if(this.calculatorId === 'form6251AMTCalculator'){

            let scenarioData = scenario.find(line => line.assetId === 'federalTaxableIncome');
            scenarioData.value = this.taxableIncome[index - 1];

          }else if (this.calculatorId === 'qbidCalculator'){

            let scenarioData = scenario.find(line => line.assetId === 'filingStatus');
            scenarioData.value = this.filingStatus[index - 1];
            let scenarioData2 = scenario.find(line => line.assetId === 'taxableIncomePreQbi');
            scenarioData2.value = this.agi[index - 1] - this.qbiPreDeduction[index - 1];


          }
        }
      })

      if(this.calculatorId === 'form6251AMTCalculator'){
        this.dataProcessed.sections[1].scenariosData.forEach((scenario, index) => {
          if (index > 0) { // jump the labels column
            let tax = scenario.find(line => line.assetId === 'tax');
            tax.value = this.tax[index - 1];
          }
        })
      }

    if (this.calculatorId === 'charitableContributionCalculator'){
      this.dataProcessed.sections.forEach(section => {
        section.scenariosData.forEach((scenario, index) => {
          if (index > 0){
            if (section.id === 'cashContributions'){
              let aux = scenario.find(line => line.assetId === 'thirtyOfAgiLimit')
              aux.value = this.agi[index - 1] * 0.3 ;
            }
            if (section.id === 'sixtyAgiLimited'){
              let aux = scenario.find(line => line.assetId === 'sixtyOfAgiLimit')
              aux.value = this.agi[index - 1] * 0.6 ;
            }
            if (section.id === 'noncashContributions'){
              let aux = scenario.find(line => line.assetId === 'thirtyOfAgiLimit')
              aux.value = this.agi[index - 1] * 0.3 ;
            }
            if (section.id === 'fiftyQualifiedOrgsCategory'){
              let aux = scenario.find(line => line.assetId === 'fiftyOfAgiLimit')
              aux.value = this.agi[index - 1] * 0.5 ;
            }
            if (section.id === 'twentyQualifiedOrgsCategory'){

              let aux = scenario.find(line => line.assetId === 'twentyOfAgiLimit')
              aux.value = this.agi[index - 1] * 0.2 ;
            }
          }
        })
      })
    }


    this.getCollapsableElements();
  }



  /**
   * Transform structure of a scenario "natural" (response of back) in a structure of scenario artificial (model to show in html)
   * sections catalog is used to can create the new structure
   * @param response is provided by backend
   * @param catalog is the catalog to create a new structure
   * @return calcFinal this is the variable that allows show data in html
   */
  build(response: any, catalog: any){
    let calcFinal = JSON.parse(JSON.stringify(catalog));
    calcFinal.sections.map(finalSection => {
      finalSection['scenariosData'] = []
      let responseSection = response.find( _section => _section.sectionId === finalSection.id);

      if (responseSection){
        responseSection.scenariosData.map((scenarioData, index) => {
          let arrayLines = []
          finalSection.lines.map(lineFinal => {
            let auxLine = JSON.parse(JSON.stringify(lineFinal));
            auxLine['value'] = scenarioData[lineFinal.assetId];
            auxLine['type'] = this.noTransformTypeOrId.includes(lineFinal.type)? auxLine.type : (index === 0 ? 'staticText': auxLine.type);
            auxLine['scenarioId'] = scenarioData.scenarioId
            if (auxLine.type === 'subsection'){
              auxLine.value = '';
              auxLine.type = '';
            }
            if (lineFinal.hasOwnProperty('calculatorId')){
              auxLine.revertNumber = this.isInOverwriteList(responseSection.sectionId + '.' + auxLine.scenarioId + '.' + auxLine.assetId);

            }
            arrayLines.push(auxLine);
          })
          finalSection['scenariosData'].push(arrayLines);
        })
      }else{
        console.log('Section not find: ', finalSection.id);
      }
    })
    this.buildLabels(calcFinal);
    return calcFinal;
  }

  /**
   * create a scenario artificial to show text labels in the beginning of the row
   * @param calcFinal is the data where new scenario will be added
   */
  buildLabels(calcFinal: any){
    calcFinal.sections.map(section => {
      let arrayLines = []
      section.lines.map(lineFinal => {
        let auxLine = JSON.parse(JSON.stringify(lineFinal));
        auxLine['value'] = lineFinal.title
        auxLine['type'] = lineFinal.type==='subsection' ? lineFinal.type : 'nameRow'
        if (auxLine['type'] === 'name'){
          auxLine['value'] = ''
        }
        arrayLines.push(auxLine);
      })
      section['scenariosData'].unshift(arrayLines);
    })
  }

  /**
   * It allows show or hide a section in a calculator
   * @param idx is the index of section
   */
  async setSectionVisible(idx: number){

    this.dataProcessed.sections[idx].visible = !this.dataProcessed.sections[idx].visible;
    this.dataProcessed.sections[idx].scenariosData[0].forEach((line) => {
        if (line.type === 'subsection') {
          this.setSubsectionVisible(line, this.dataProcessed.sections[idx].visible);
        }
      }
    );
    await this.advisorService.saveCollapsableElements(this.clientId, {
      components: [`${this.calculatorId}_${idx}`],
    }, 'projections-calculators');
  }

  /**
   * The function retrieves collapsible elements from an advisor service and hides specific components
   * based on the received data.
   */
  async getCollapsableElements() {
    try {
      const { projectionsCalculators: { hiddenComponents } }: any = await this.advisorService.getCollapsableElements(this.clientId);

      const hidenComponentsIds = [];
      hiddenComponents.map((element) => {
        const [calculatorId, idx] = element.split('_');
        if (calculatorId === this.calculatorId) {
          hidenComponentsIds.push(parseInt(idx, 10));
        }
      });
      hidenComponentsIds.forEach((hiddenElement) => {
        this.dataProcessed.sections[parseInt(hiddenElement, 10)].visible = false;
        this.dataProcessed.sections[parseInt(hiddenElement, 10)].scenariosData[0].forEach((line) => {
          if (line.type === 'subsection') {
            this.setSubsectionVisible(line, this.dataProcessed.sections[parseInt(hiddenElement, 10)].visible);
          }
        }
      );
      });
    } catch(e) {
      console.log('ERROR: ', e);
    }
  }

  /**
   * do scroll horizontal in view
   * @param id is used by the conditional to do scroll, it's provided by html element
   */
  scrollHorizontal(id: string) {
    const calculatorScenarios = document.getElementById('calculatorScenarios');
    if (id === 'lastScenario'){
      calculatorScenarios.scrollLeft += 370;
    } else if (id === 'firstScenario'){
      calculatorScenarios.scrollLeft -= 370;
    }
  }

  /**
   * It allows show or hide a section in a calculators
   * @param data is the index of section
   * @param isSectionVisible is a conditional to hide or show a subsection
   */
  setSubsectionVisible(data: Lines, isSectionVisible: Boolean = true){
    if (isSectionVisible) {
      data.alwaysVisible = !data.alwaysVisible;
    } else {
      data.alwaysVisible = false;
    }

  }

  /**
   * It allows change the icon to show or hide information about the subsection
   * @param data is the data of section, we use group to set visibility
   */
  subsectionIsVisible(data, section): boolean{
    if (data.group){
      let line = section.scenariosData[0].find(line => line.assetId === data.group)
      if (!line) return false
      return line.alwaysVisible
    }
    return true;
  }

  /**
   * It allows show or hide a subsection in a calculators Sch-1, Sch-2 and Sch-3
   * @param section is the data of section, we use group to set visibility
   */
  setStatusSubsection(section: any){
    section.scenariosData.forEach(scenario => {
      scenario.forEach(row => {
        if (row.hasOwnProperty('group')){
          row.alwaysVisible = !row.alwaysVisible.value
        }
      })
    })
  }


  /**
   * This function build a calculator to Sch B or Charitable
   * Access the part of the array that contains the lines and iterate over that array to build the view
   * @param calculatorId is the id of the calculator
   */
  buildTypeB(calculatorId: string){
    let sectionNewLines = []
    this.responseData[calculatorId].forEach(section => {
      sectionNewLines[section.sectionId] = [[]]
      section.scenariosData.forEach((scenario, index) => {
        let newLines = []
        scenario.lines.map(line => {
          let auxLine = {
            assetId: line.assetId,
            title: '',
            type: index === 0 ?'staticText' :'money',
            value: line.value,
            alwaysVisible: false,
            nonErasable: line.nonErasable === true ? true: false,
            line: true
          }
          newLines.push(auxLine)
        })
        sectionNewLines[section.sectionId].push(newLines);
      })

      let dataSection = this.dataProcessed.sections.find(section_ => section_.id === section.sectionId);
      let idxButton = dataSection.scenariosData[1].findIndex(line => line.type === 'addButton');
      dataSection.scenariosData.map((scenario, index) => {
        if (index === 0) {
          sectionNewLines[section.sectionId][0] = JSON.parse(JSON.stringify(sectionNewLines[section.sectionId][1]))
          sectionNewLines[section.sectionId][0].forEach((line, lineIndex) => {
            // By the Backend team request, the base scenario labels need to be constructed using the first scenario data.
            line.value = section.scenariosData[1].lines[lineIndex].assetId;  //Old:line.assetId
            line.assetId = section.scenariosData[1].lines[lineIndex].assetId;
            line.type = 'nameRow'
          })
        }
        if (idxButton >= 0){
          scenario.splice(idxButton, 0, ...sectionNewLines[section.sectionId][index]);
        }
      })
    })
  }

  /**
   * Build an object that contains the same structure that response backend
   * This is used to send data to backend
   * @return payload, this is an object with all data
   */
  currentDataToStandarCalcData() {


    //Copiar desde el response, iterar y actualizar valores con los transformed.

    if(this.responseData[this.calculatorId] === undefined) return [];

    let payload = JSON.parse(JSON.stringify(this.responseData[this.calculatorId]));

    payload.map((section, idxSection) => {
      let inProcessedDataSection = this.dataProcessed.sections[idxSection];
      section.scenariosData.map((scenario, idxScenario)=> {
        Object.keys(scenario).map(scenarioKey => {
          let scenarioFromProcessed = inProcessedDataSection.scenariosData[idxScenario + 1].find(_scenario => _scenario.assetId == scenarioKey);
          if(scenarioFromProcessed){
            scenario[scenarioKey] = scenarioFromProcessed.value
          }else{

          }
        })
      })
    })

    return payload;

  }


  /**
   * Build an object that contains the same structure that response backend
   * In this case is necessary iterate the array to can build a line section
   * This is used to send data to backend
   * @return payload, this is an object with all data
   */
  currentDataToStandarCalcDataTypeB() {
    if(this.responseData[this.calculatorId] === undefined) return [];
    let payload = JSON.parse(JSON.stringify(this.responseData[this.calculatorId]));
    payload.forEach(section => {
      section.scenariosData.forEach(scenario => {
        scenario.lines = [];
      })
    })
    payload.map((section) => {
      let sectionTransformed = this.dataProcessed.sections.find(section_ => section_.id === section.sectionId);
      sectionTransformed.scenariosData.forEach((scenario, index) => {
        if (index > 0){
          scenario.map(line => {
            if (section.scenariosData[index - 1].hasOwnProperty(line.assetId)){
              section.scenariosData[index - 1][line.assetId] = line.value;
            }else{
              if (line.assetId != '' && line.value != undefined){
                // let findInLines = section.scenariosData[index - 1].lines.find(line_ => line_.assetId === line.assetId);
                // if (findInLines){
                //   findInLines.value = line.value
                // }else{
                  section.scenariosData[index - 1].lines.push({
                    assetId: line.assetId,
                    value: line.value,
                    nonErasable: line.nonErasable === true? true: false
                   })
                //}
              }
            }
          })
        }
      })
    })
    return payload;
  }

  ngOnDestroy(){
    if (this.calculatorId === 'scheduleBCalculator' || this.calculatorId === 'charitableContributionCalculator'){
      this.responseData[this.calculatorId] = this.currentDataToStandarCalcDataTypeB()
    }else{
      this.responseData[this.calculatorId] = this.currentDataToStandarCalcData();
    }
  }

  /**
   * With this function is possible add a new row in the calculators Sch b and charitable contributions
   * @param lineId is the type of input of the new row
   * @param sectionId is the section where the new row will be added
   */
  addRow(lineId: string = 'money', sectionId: string){

    let title: string = ''

    let addRow_ = ()=>{

      let dataSection = this.dataProcessed.sections.find(section_ => section_.id === sectionId);
      let idxButton = dataSection.scenariosData[1].findIndex(line => line.type === 'addButton');
      dataSection.scenariosData.map((scenario, index_) => {

        let line = {
          assetId: title,
          type: (index_ === 0) ? 'nameRow' : (index_ === 1) ? 'staticText': 'money',
          alwaysVisible: false,
          value: (index_ === 0) ? title : 0,
          line: true,
          nonErasable: false
        }

        if (idxButton >= 0){
          scenario.splice(idxButton, 0, line);
        }

      })
      console.log('dataSection: ', dataSection)
    }

    // Pop up input

    const dialogRef = this.dialog.open(DialogGenericNoticeComponent, {
      disableClose: true,
      panelClass: 'modal-dialog-black',
      width: '55vw',
      data: {
        title: 'Add row',
        body:
        `Name of row`,
        inputFields:[{ type: '', key: 'rowTitle', text: '', class: '' }],
        buttonsContainerClass: 'separate-buttons',
        actionButtons: [
          {
            text: "Cancel",
            class: 'button-secondary',
            action: () => {
              dialogRef.close();
            }

          },
          {
            text: "Add row",
            class: 'button-primary',
            action: () => {
              title = (dialogRef.componentInstance.data.outputFieldsData['rowTitle'] !== undefined? dialogRef.componentInstance.data.outputFieldsData['rowTitle'] : '')
              if (title.trim() !== '') {
                addRow_();
              }
              dialogRef.close();
            },
            disable: 'rowTitle'

          }
        ]
      }
    });
  }

  /**
   * The function deletes a row from a specific section and scenario in the dataProcessed object. It's used in calc type A
   * @param idxSection - The index of the section in the dataProcessed.sections array that contains the scenariosData array.
   * @param idxScenario - The parameter `idxScenario` represents the index of the scenario within the section.
   * @param idxRow - The `idxRow` parameter represents the index of the row that you want to delete  within a specific scenario.
   */
  deleteRow(idxSection, idxScenario, idxRow){
    this.dataProcessed.sections[idxSection].scenariosData.forEach(scenario => {
      scenario.splice(idxRow, 1);
    });
  }

  /**
   * The function "goToCalculator" emits an event with the calculatorId as a parameter and show these calculator.
   * @param {string} calculatorId - The calculatorId parameter is a string that represents the unique
   * identifier of a calculator.
   */
  goToCalculator(calculatorId: string){
    this.goToCalculatorId.emit(calculatorId);
  }

  /**
   * The function `deleteFromOverwrite` takes an optional parameter `deleteFromOverwrite` and tries to
   * find a scenarioId based on the provided calculatorId, sectionId, and scenarioIdx, and then calls
   * the `saveAndCalculate` function with the calculated scenarioId.
   * @param [deleteFromOverwrite] - The `deleteFromOverwrite` parameter is an optional object that
   * contains the following properties:
   * calculatorI, sectionId, rowId and scenarioIdx
   */
  deleteFromOverwrite(deleteFromOverwrite?: ({calculatorId: any, sectionId: string, rowId: string, scenarioIdx: number})){
    try{
      let scenarioId = this.responseData[deleteFromOverwrite.calculatorId].find(section => section.sectionId === deleteFromOverwrite.sectionId).scenariosData[deleteFromOverwrite.scenarioIdx - 1]['scenarioId'];
      this.parent.saveAndCalculate({calculatorId: deleteFromOverwrite.calculatorId, sectionId: deleteFromOverwrite.sectionId, rowId: deleteFromOverwrite.rowId, scenarioId: scenarioId})
    }catch(e){
      console.log('Scenario not found: ', e);
    }

  }

  /**
   * The function checks if a given key is present in the overwriteListKeys array.
   * @param {string} key - The `key` parameter is a string that represents the key you want to check if
   * it exists in the `overwriteListKeys` array.
   * @returns a boolean value.
   */
  isInOverwriteList(key: string): boolean{
    return this.overwriteListKeys.find(_key => _key === key) !== undefined;
  }

  /**
   * The function mustBeNegative takes in three parameters (idx, i, j) and checks if the value at the
   * specified index in the dataProcessed object is positive, and if so, converts it to a negative value.
   * @param idx - The parameter `idx` represents the index of the section in the `dataProcessed` object.
   * @param i - The parameter `i` represents the index of the scenario data within the `scenariosData` array.
   * @param j - The parameter "j" in the function "mustBeNegative" represents the index of the innermost array in the "scenariosData" array.
   */
  mustBeNegative(idx, i, j){
    //this.dataProcessed.sections[idx].scenariosData[i][j].value;
    if (this.dataProcessed.sections[idx].scenariosData[i][j].value > 0){
      this.dataProcessed.sections[idx].scenariosData[i][j].value = (-1 * this.dataProcessed.sections[idx].scenariosData[i][j].value);
      console.log('negative his.dataProcessed.sections[idx].scenariosData[i][j].value*', this.dataProcessed.sections[idx].scenariosData[i][j].value);
    }
    console.log('Negative**', this.dataProcessed.sections[idx].scenariosData[i][j]);
  }
}
