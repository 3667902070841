import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Area } from 'app/shared/helpers/areas.catalog';
import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from 'app/shared/helpers/url.helper';
import { getRandomInt } from 'app/shared/helpers/utils';
import { CONST_DISCLAIMER } from 'app/views/reports/stringConstants';
import { environment } from 'environments/environment.prod';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

import { ProspectReport } from './report.class';

@Component({
  selector: 'app-submitted-message',
  templateUrl: './submitted-message.component.html',
  styleUrls: ['./submitted-message.component.scss']
})
export class SubmittedMessageComponent implements OnInit {

  public advisorProperties: any = {};
  public formattedPhoneNumber: string;
  public recomendations: ({area: Area, recs: string[]}[]) = [];
  public score: number = 0;
  public isLoadingPdf: boolean = false;
  public prospectResponse: any = {};
  public numberOfRecs: number = getRandomInt(30, 80);

  constructor(
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.advisorProperties = JSON.parse(localStorage['advisorResponse']);

    if(Boolean(this.advisorProperties.phone)){
      let phonePartOne   =  this.advisorProperties.phone.substring(2, 5);
      let phonePartTwo   =  this.advisorProperties.phone.substring(5, 8);
      let phonePartThree =  this.advisorProperties.phone.substring(8, 12);
      this.formattedPhoneNumber = `(${phonePartOne}) ${phonePartTwo}-${phonePartThree}`;
    }


    this.loadProspectsRecs();
  }

  loadProspectsRecs(){
    this.prospectResponse = JSON.parse(localStorage.getItem('prospectResponse'));

    let baseURl: string = urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION) ? 'https://api.fpalpha.com' : 'https://api.fpalpha.app';

    this.http.get<any>(environment.prospectAdvisor + this.prospectResponse.idHash).subscribe(prospectResponse => {
      this.prospectResponse.clientIDFirstName = prospectResponse.clientIDFirstName;
      this.prospectResponse.clientIDLastName = prospectResponse.clientIDLastName;
    });

    this.http.get(`${baseURl}/v1/advisor/prospect/lead-report/${this.prospectResponse.idHash}`).subscribe(response => {
      //Load score
      this.score = response['score'];
      // Transform the response into a new format
      let areaRecs = response['recommendations'];
      Object.keys(areaRecs).map(areaId => {
        this.recomendations.push({
          area: Area.findAreaById(areaId),
          recs: areaRecs[areaId]
        })
      });

    })
  }

  async downloadReport(){

    this.isLoadingPdf = true

    let canvasScore =  await html2canvas(document.getElementById('scoreCard'), { scale: 2, useCORS: true });
    let canvasContact =  await html2canvas(document.getElementById('contactCard'), { scale: 2, useCORS: true });

    let report = await ProspectReport.generate({
      logoUrl: this.advisorProperties.companyLogo,
      wellnessCardUrl: canvasScore.toDataURL(),
      contactCardUrl: canvasContact.toDataURL(),
      disclaimer: CONST_DISCLAIMER,
      recomendations: this.recomendations,
      clientsName: this.prospectResponse.clientIDFirstName + ' ' + this.prospectResponse.clientIDLastName,
      numberOfRecs: this.numberOfRecs
    });

    saveAs(report.doc.output('blob'), `${this.prospectResponse.clientIDFirstName} report`);

    this.isLoadingPdf = false;
  }

}


