import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { assetsCatalog, columnsToShowAsCurrency, columnsToShowAsBeneficiary, columnsToShowAsPercent, getBeneficiariesFromAsset, getRealEstateAssetName, getFormFromAsset, getDropdownOptionsFromRequest, assetFormToAsset, assetToTable, getAssetEmptyTemplate, columnsToShowAsColorIndicator, columnsToShowWithDotInAssets, assetColorClasses, columnsToRenderizeByAssetType, columnsToRenderizeByAssetTypeEstateLab } from 'app/views/questionnaires/general-questionnaire/templates/assets/utils';
import { CurrencyPipe, PercentPipe, KeyValue } from '@angular/common';
import { Asset, QuestionnaireResponse, AssetGroup } from 'app/shared/model/questionnaires/Questionnaires.model';
import { AddAssetComponent } from 'app/views/questionnaires/general-questionnaire/dialogs/add-asset/add-asset.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CONST_STATES_KEY_WITH_INTERNATIONAL, CONST_REAL_STATE_TYPES, CONST_BUSINESS_TYPES, CONST_RETIREMENT_ACCOUNT, CONST_RETIREMENT_ACCOUNT_TYPES, CONST_NON_RETIREMENT_ACCOUNT_TYPES, CONST_LIFE_INSURANCE, CONST_COLLEGE_SAVINGS_TYPES, CONST_LOAN_LIABILITY_TYPES } from 'app/views/questionnaires/Questionnaire.constants';
import { MatSort } from '@angular/material/sort';
import { QuestionnaireHubService } from 'app/shared/services/questionnaires/questionnaire-hub.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GeneralQuestionnaireService } from 'app/shared/services/questionnaires/general-questionnaire.service';
import { MatTableDataSource } from '@angular/material/table';
import { childFormToRelative } from 'app/views/questionnaires/general-questionnaire/templates/family/utils';
import { ClientService } from 'app/shared/services/client/client.service';
import { LocalStorageManager } from 'app/shared/classes/LocalStorageManager';

@Component({
  selector: 'app-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {

  @Output('onFormReady') onFormReady = new EventEmitter<FormGroup>()

  @Input() assetTabsType: string = 'general_questionnaire';

  @Input() columnsToRenderize;

  @Input() columnsWithMinSize = {}

  @Input() theme: string = 'general-questionnaire-theme';

  private _assetsToAttach: any;
  private _relationsToAttach: any;

  @Input()
  get assetsToAttach(): any {
    return this._assetsToAttach;
  }

  set assetsToAttach(value: any) {
    if (!this.deepEqual(this._assetsToAttach , value)) {
      this.initAssets()
    }
    this._assetsToAttach = value;
  }

  @Input()
  get relationsToAttach(): any {
    return this._relationsToAttach;
  }

  set relationsToAttach(value: any) {
    if (!this.deepEqual(this._relationsToAttach , value)) {
      this.initAssets()
    }
    this._relationsToAttach = value;
  }

  @Input() assetsToGroup;

  @Input() key = '';

  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  assetsForm: FormGroup;

  hasPreviousGQData: boolean = false;

  tables: any = {};

  rawResponse: QuestionnaireResponse;

  public assetsCatalogs = assetsCatalog;

  curentClientId;

  questionnaireHub: QuestionnaireHubService;

  relationshipsFromCatalogs = {}

  public isLoading = true;

  constructor(
    public currencyPipe: CurrencyPipe,
    public dialog: MatDialog,
    public generalQuestionnaireService: GeneralQuestionnaireService,
    public snackBar: MatSnackBar,
    public currentClientService: ClientService,
    public percentPipe: PercentPipe) { }

  ngOnInit(): void {
    const localStorage = new LocalStorageManager();
    this.curentClientId = localStorage.getItem('clientIDX');
    console.log('curentClientId', this.curentClientId);
  }

  ngAfterViewInit(): void {
    this.initAssets()
  }

  deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      if (!this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  initAssets(){
    this.questionnaireHub = new QuestionnaireHubService(this.snackBar, this.theme);
    this.tables = {}

    const config = {}

    console.log('initAssets this.curentClientId', this.curentClientId);

    if(this.curentClientId){
      this.questionnaireHub.getAnswers(this.generalQuestionnaireService, 'assets', this.assetTabsType, this.curentClientId).subscribe((response: QuestionnaireResponse) => {

        const attachments = this.assetsToAttach;
        const rawResponseWithAttachments = {
          ...response,
          data: {
            ...response.data,
            ... attachments
          }
        }

        this.onFormReady.emit(this.assetsForm)
        this.isLoading = false;

        this.rawResponse = rawResponseWithAttachments;
        Object.keys(rawResponseWithAttachments.data).forEach((assetGroup, index) => {
          if(rawResponseWithAttachments.data[assetGroup].length>0){
            this.tables[assetGroup] = (this.getTableByAssetType(assetGroup))
          }
          else{
            this.tables[assetGroup] = {}
          }
        })

        this.hasPreviousGQData = true;

        setTimeout(() => {
          console.log(this.sorts)
        }, 1000);

      })
    }

  }

  getTableByAssetType(assetType: string) {

    let table = assetToTable(this.rawResponse.data[assetType], assetType, this.columnsToRenderize)

    let matTable = new MatTableDataSource(
      table
    )

    let displayedColumns= this.columnsToRenderize[assetType]

    return {
      table: matTable,
      columns: displayedColumns
    }
  }

  getWhoIs(arg){
    const options = this.rawResponse.fields.find((item) => {
      return item.field == "real_estate_loands_liabilities"
    })['options']
    const val = options.find((opt) => {
      return opt.value == arg
    })
    if (val) {
      return val['text']
    }
    return ''
  }

  renderProperty(key: string, prop:any, item:any){
    if (columnsToShowAsCurrency.includes(key)) {
      if (typeof prop === 'number') {
        return this.currencyPipe.transform(prop, '$', 'symbol', '0.0-0');
      }
      else{
        return prop || ''
      }
    }
    if(columnsToShowAsBeneficiary.includes(key)) {
      return getBeneficiariesFromAsset(prop)
    }
    if(columnsToShowAsPercent.includes(key)) {
      return this.percentPipe.transform(prop/100, '1.0-2')
    }
    if(key == 'whose_life_is_insured'){
      return this.getWhoIs(prop)
    }
    if(key == 'real_estate_loands_liabilities'){
      const realEstateData = this.rawResponse.data['real_estate'] || this.rawResponse.data['assets'].filter((item) => {
        return item.type == "real_estate";
      })
      return getRealEstateAssetName(
        realEstateData,
        prop
      )
    }
    return prop
  }

  formatTooltip(data, key){
    if (data && columnsToShowAsBeneficiary.includes(key)) {
      return data.slice(3, data.length+1).join(', ');
    }
    return ''
  }

  hasTooltip(column, element){
    return columnsToShowAsBeneficiary.includes(column) && element.length>3
  }

  getCustomClasses(key: string, prop:any, assetGroup:string, index: number){
    const classes = []
    const asset = this.rawResponse.data[assetGroup][index].type
    if(columnsToShowAsColorIndicator.includes(key)) {
      classes.push(prop === 'In Place' ? 'cell-green' : 'cell-yellow');
    }
    if(columnsToShowWithDotInAssets.includes(key) && (assetColorClasses[asset] && this.rawResponse.data['assets'] )) {
      const dotColorClass = assetColorClasses[asset]
      classes.push('cell-dot')
      classes.push(dotColorClass)
    }
    return classes;
  }

  getCustomStyles(key: string, prop:any, assetGroup:string, index: number){
    const styles = {}
    if(this.columnsWithMinSize[key]) {
      styles['min-width'] = this.columnsWithMinSize[key]
    }
    return styles;
  }

  getTypeSelectorClasses( assetGroup:string, index: number){
    const classes = []
    const asset = this.rawResponse.data[assetGroup][index].type
    const dotColorClass = assetColorClasses[asset]
    classes.push('cell-dot')
    classes.push(dotColorClass)
    return classes;
  }

  addBothParentsOption(options){
    const bothParents = {
      key: [],
      relation: '',
      text : ''
    }
    const newOptions = [...options]
    options.forEach((option) => {
      bothParents.key.push(option.key)
      bothParents.text += bothParents.text == '' ?
        `${option.text.split(' ')[0]}` :
        ` and ${option.text.split(' ')[0]}`
    })
    newOptions.push(bothParents)
    return newOptions;
  }

  openAsset(assetGroup: string, indexOfAsset: number){

    const dependencies = {
      cash_value : {
        dependency : 'subtype',
        onDifferentValue: 'Whole/Cash value',
        action: 'disabled'
      },
      real_estate_loands_liabilities: {
        dependency : 'subtype',
        onDifferentValue: 'Home Loan',
        action: 'disabled'
      },
      contingentBeneficiaries: {
        dependency : 'beneficiaries',
        isEmpty: [],
        action: 'disabled'
      }
    }

    if (assetGroup == 'life_insurance') {
      dependencies['growthrate'] = {
        dependency : 'cash_value',
        onSameValue: 0,
        action: 'disabled'
      }
      dependencies['beneficiaries'] = {
        dependency : 'owner',
        onSelectValue: true,
        action: 'disableSameValue'
      }
    }

    const crossFields = {
      contingentBeneficiaries: {
        dependency : 'beneficiaries',
        action: 'remove'
      }
    }

    const assetToOpen:Asset = this.rawResponse.data[assetGroup][indexOfAsset]
    const form = getFormFromAsset(assetToOpen, assetToOpen.type.toLowerCase(), this.columnsToRenderize)
    if (this.relationsToAttach && this.relationsToAttach['parents'] && this.relationsToAttach['parents'].length == 2) {
      const relativesWithBothParents = this.addBothParentsOption(this.relationsToAttach['parents'])
      this.relationsToAttach['parents'] = relativesWithBothParents;
    }

    const percentages = {
      beneficiaries : assetToOpen.beneficiaries ? assetToOpen.beneficiaries.map((item) => {
        return item.percentage
      }) : [],
      contingentBeneficiaries: assetToOpen.contingentBeneficiaries ? assetToOpen.contingentBeneficiaries.map((item) => {
        return item.percentage
      }) : [],
    }
    const dialogRef = this.dialog.open(AddAssetComponent, {
      width: '1260px',
      disableClose: true,
      panelClass: this.theme,
    })
    let cantDeleteMessage = 'You cannot delete this asset because it is being used in the Estate Lab.  You must first remove the asset from '
    if (!assetToOpen['dependency'].canDelete) {
      assetToOpen['dependency'].dependencies.forEach((item,  index) => {
        if(index == 0){
          cantDeleteMessage += item.name_where_is_using
        }
        else{
          if(index == assetToOpen['dependency'].dependencies.length-1){
            cantDeleteMessage += (' and ' + item.name_where_is_using)  
          }
          else{
            cantDeleteMessage += (', ' + item.name_where_is_using)  
          }
        }
      })
      cantDeleteMessage += '.'
    }
    dialogRef.componentInstance.dataForm = form;
    dialogRef.componentInstance.assetType = assetToOpen.type.toLowerCase();
    dialogRef.componentInstance.columnsToRenderize = this.columnsToRenderize;
    dialogRef.componentInstance.theme = this.theme;
    dialogRef.componentInstance.percentages = percentages;
    dialogRef.componentInstance.beneficiariesHasPercentages = this.assetTabsType == 'estate_lab'
    dialogRef.componentInstance.title = `Edit ${assetsCatalog[assetToOpen.type.toLowerCase()].label}`;
    dialogRef.componentInstance.validRelationships = {
      ...getDropdownOptionsFromRequest(this.rawResponse.fields, assetToOpen.type.toLowerCase()),
      ...this.getLocalCatalogs(assetToOpen.type.toLowerCase()),
      ...this.relationsToAttach
    }
    dialogRef.componentInstance.dependencies = dependencies;
    dialogRef.componentInstance.crossFields = crossFields;
    dialogRef.componentInstance.canBeDeleted = assetToOpen['dependency'].canDelete;
    dialogRef.componentInstance.cantDeleteMessage = cantDeleteMessage;
    dialogRef.componentInstance.crossCheckRelationships = [
      'beneficiaries',
      'contingentBeneficiaries'
    ]
    dialogRef.componentInstance.handleOnSave.subscribe((child) => {
      this.isLoading = true;
      if (assetToOpen.type.toLowerCase() == 'beneficiaries'
        || assetToOpen.type.toLowerCase() == 'children'
        || assetToOpen.type.toLowerCase() == 'grandchildren') {
        const lowerType = assetToOpen.type.toLowerCase() == 'beneficiaries' ? 'beneficiary' : assetToOpen.type.toLowerCase()
        const relative = childFormToRelative(child,  lowerType)
        relative["relativeId"] = assetToOpen['_id']
        this.questionnaireHub.putAnswers(this.generalQuestionnaireService, 'family', relative, this.curentClientId).subscribe((response) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetToOpen.type.toLowerCase())
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
          setTimeout(() => {
            this.initAssets()
          }, 2400);
        })
      }
      else{
        const asset = assetFormToAsset(child, assetToOpen, assetToOpen.type.toLowerCase())
        this.questionnaireHub.putAnswers(this.generalQuestionnaireService, 'assets', asset, this.curentClientId).subscribe((response: QuestionnaireResponse) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetToOpen.type.toLowerCase())
          this.initAssets()
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
        })
      }
    })
    dialogRef.componentInstance.handleOnDelete.subscribe((assetFromModal: FormGroup) => {
      this.isLoading = true;
      const assetToDelete = assetToOpen;
      if (assetToOpen.type.toLowerCase() == 'beneficiaries'
        || assetToOpen.type.toLowerCase() == 'children'
        || assetToOpen.type.toLowerCase() == 'grandchildren') {
        const lowerType = assetToOpen.type.toLowerCase() == 'beneficiaries' ? 'beneficiary' : assetToOpen.type.toLowerCase()
        const relative = childFormToRelative(assetFromModal, lowerType)
        relative["relativeId"] = assetToOpen['_id']
        this.questionnaireHub.deleteAnswers(this.generalQuestionnaireService, 'family', relative, this.curentClientId).subscribe((response) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetToOpen.type.toLowerCase())
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
          setTimeout(() => {
            this.initAssets()
          }, 2400);
        })
      }
      else{
        this.questionnaireHub.deleteAnswers(this.generalQuestionnaireService, 'assets', assetToDelete, this.curentClientId).subscribe((response) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetToOpen.type.toLowerCase())
          this.initAssets()
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
        })
      }
    })
  }

  addAsset(assetType: string){

    const dependencies = {
      cash_value : {
        dependency : 'subtype',
        onDifferentValue: 'Whole/Cash value',
        action: 'disabled'
      },
      real_estate_loands_liabilities: {
        dependency : 'subtype',
        onDifferentValue: 'Home Loan',
        action: 'disabled'
      },
      contingentBeneficiaries: {
        dependency : 'beneficiaries',
        isEmpty: [],
        action: 'disabled'
      }
    }


    if (assetType == 'life_insurance') {
      dependencies['growthrate'] = {
        dependency : 'cash_value',
        onSameValue: 0,
        action: 'disabled'
      }
      dependencies['beneficiaries'] = {
        dependency : 'owner',
        onSelectValue: true,
        action: 'disableSameValue'
      }
    }

    const crossFields = {
      contingentBeneficiaries: {
        dependency : 'beneficiaries',
        action: 'remove'
      }
    }

    const emptyAsset = getAssetEmptyTemplate(assetType, this.columnsToRenderize)
    const dialogRef = this.dialog.open(AddAssetComponent,{
      width: '1260px',
      disableClose: true,
      panelClass: this.theme,
    })
    const form = getFormFromAsset(emptyAsset, assetType, this.columnsToRenderize)


    const percentages = {
      beneficiaries : [],
      contingentBeneficiaries: [],
    }
    if (this.relationsToAttach && this.relationsToAttach['parents'] && this.relationsToAttach['parents'].length == 2) {
      const relativesWithBothParents = this.addBothParentsOption(this.relationsToAttach['parents'])
      this.relationsToAttach['parents'] = relativesWithBothParents;
    }
    dialogRef.componentInstance.dataForm = form;
    dialogRef.componentInstance.assetType = assetType;
    dialogRef.componentInstance.columnsToRenderize = this.columnsToRenderize;
    dialogRef.componentInstance.theme = this.theme;
    dialogRef.componentInstance.percentages = percentages;
    dialogRef.componentInstance.beneficiariesHasPercentages = this.assetTabsType == 'estate_lab'
    dialogRef.componentInstance.title = `${assetsCatalog[assetType].label}`;
    dialogRef.componentInstance.validRelationships = {
      ...getDropdownOptionsFromRequest(this.rawResponse.fields, assetType),
      ...this.getLocalCatalogs(assetType),
      ...this.relationsToAttach
    }
    dialogRef.componentInstance.dependencies = dependencies;
    dialogRef.componentInstance.crossFields = crossFields;

    dialogRef.componentInstance.handleOnSave.subscribe((asset) => {
      this.isLoading = true;
      const newAsset = assetFormToAsset(asset, emptyAsset, assetType);
      if (assetType == 'beneficiaries'
        || assetType == 'children'
        || assetType == 'grandchildren') {
        const lowerType = assetType == 'beneficiaries' ? 'beneficiary' : assetType
        const relative = childFormToRelative(asset, lowerType)
        this.questionnaireHub.postAnswers(this.generalQuestionnaireService, 'family', relative, null, this.curentClientId).subscribe((response) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetType)
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
          setTimeout(() => {
            this.initAssets()
          }, 2400);
        })
      }
      else{
        this.questionnaireHub.postAnswers(this.generalQuestionnaireService, 'assets', newAsset, this.assetTabsType, this.curentClientId).subscribe((response: QuestionnaireResponse) => {
          const indexOfAssetGroup = Object.keys(this.columnsToRenderize).findIndex(el => el==assetType)
          this.initAssets()
          this.changeTab({index: indexOfAssetGroup})
          this.currentClientService.refreshAll()
        })

      }
    })
  }

  changeTab(event){
    
    setTimeout(() => {
      this.updateSort(event.index)
    }, 1000);

  }

  updateSort(indexOfAssetGroup){
    const assetGroup = Object.keys(this.tables)[indexOfAssetGroup]
    if (this.tables[assetGroup] && this.tables[assetGroup].table) {
      this.tables[assetGroup].table.sort = this.sorts['_results'][indexOfAssetGroup]
    }
  }

  getLocalCatalogs(assetType: string) {
    if (assetType == 'real_estate') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_REAL_STATE_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        })
      }
    }
    if (assetType == 'business') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_BUSINESS_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        })
      }
    }
    if (assetType == 'retirement') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_RETIREMENT_ACCOUNT_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        })
      }
    }
    if (assetType == 'non_retirement') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_NON_RETIREMENT_ACCOUNT_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        })
      }
    }
    if (assetType == 'life_insurance') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_LIFE_INSURANCE.map((item) => {
          return {
            text: item,
            value: item
          }
        }),
      }
    }
    if (assetType == 'college_savings') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_COLLEGE_SAVINGS_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        })
      }
    }
    if (assetType == 'loans_liabilities') {
      return{
        location: CONST_STATES_KEY_WITH_INTERNATIONAL.map((item) => {
          return {
            text: item.value,
            value: item.value
          }
        }),
        subtype: CONST_LOAN_LIABILITY_TYPES.map((item) => {
          return {
            text: item,
            value: item
          }
        }),
        real_estate_loands_liabilities: this.getRealEstateFromRawResponse(),
      }
    }
  }

  getRealEstateFromRawResponse(){
    if (this.rawResponse.data['assets']) {
      return this.rawResponse.data['assets'].filter((item) => {
        return item.type == 'real_estate'
      }).map((item) => {
        return {
          text: item.nickname,
          value: item._id
        }
      })
    }
    if (this.rawResponse.data['real_estate']) {
      return this.rawResponse.data['real_estate'].map((item) => {
        return {
          text: item.nickname,
          value: item._id
        }
      })
    }
    return []
  }

  getKeysInOriginalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

}
