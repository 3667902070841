import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'family-box',
  templateUrl: './family-box.component.html',
  styleUrls: ['./family-box.component.scss']
})
export class FamilyBoxComponent implements OnInit {

  @Input() dataBox: any;
  @Input() metaData: any;
  @Input() platformView: boolean = false;
  @Input() id: string;

  constructor() { }

  ngOnInit(): void {
    console.log('dataBox family**', this.dataBox,'meta**', this.metaData);
  }

}
