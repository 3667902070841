import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-animated-loader",
  templateUrl: "./animated-loader.component.html",
  styleUrls: ["./animated-loader.component.scss"],
})
export class AnimatedLoaderComponent implements OnInit {
  @Input() estateLab: boolean = false;
  @Input() background: string = '';

  public textIndex: number = 0;

  @Input() text: string[] = [
    "Please wait while we optimize your Roth conversion scenario. This may take a few minutes....",
    "Remember, when you are finished you can download the output of the Roth simulator into a client friendly pdf report!",
    "Remember, you can optimize for three different variables - client max benefit, max value to heirs or minimize taxes!",
  ];
  public textToShow: string = this.text[0];

  constructor() {}

  ngOnInit() {
    if (!this.estateLab) {
      this.changeText();
    } else {
      this.textToShow = "Please wait until your report is generated";
    }
  }

  changeText() {
    setTimeout(() => {
      this.textIndex < this.text.length - 1
        ? this.textIndex++
        : (this.textIndex = 0);
      this.textToShow = this.text[this.textIndex];
      this.changeText();
    }, 8000);
  }
}
