import { Subscription } from 'rxjs';
// Angular
import { Component, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

// Rx
import { filter } from 'rxjs/operators';

// Project
import { AdvisorService } from '../../services/advisor.service';
import { getLastUrlSegment, isTheUrlSubsectionAnyOf } from '../../helpers/url.helper';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ClientDataService } from 'app/shared/services/client-data-service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})

export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Input() clientIsProspect: boolean = false;

  public currentClientInfo;
  public urlWithoutParams;
  public isAdvisor: boolean = false;
  public isProspect: boolean = false;
  public displayNormalMenu: boolean = false;
  public restrictedItems: any = [];
  private subscriptionClientDataService: Subscription;

  constructor(
      private router: Router,
      public contactDialog: MatDialog,
      public bottomSheet: MatBottomSheet,
      public advisorService: AdvisorService,
      private activatedRouter: ActivatedRoute,
      public authService: AuthenticationService,
      private clientDataService: ClientDataService
    ) {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.urlWithoutParams = getLastUrlSegment(this.router.url);
        this.displayNormalMenu = SidenavComponent.validatePathsToDisplayNormalMenu(this.router.url);
      });



    }

  ngOnInit() {
    //Tour
    const aux = this.authService.getCurrentRoleRestrictions();
    const ans = new Set([]);
    aux.forEach((element, index) => {
      const [section,] = element.split(':');
      console.log(section, element);
      ans.add(section);
      console.log(ans);
      if (index === aux.length - 1) {
        this.restrictedItems = Array.from(ans);
        console.log(this.restrictedItems);
      }
    })
    this.isAdvisor = this.authService.isAdvisor();
    let clientLocalData = JSON.parse(sessionStorage.getItem('currentClient'));
    this.isProspect =  clientLocalData ? clientLocalData.isProspect : false;

    this.subscriptionClientDataService = this.clientDataService.afterSetFullCurrentClient('sidenav').subscribe(client => {
      console.log('afterSetFullCurrentClient. sidenav component')
      this.currentClientInfo = client
    });

    this.displayNormalMenu = SidenavComponent.validatePathsToDisplayNormalMenu(this.router.url);

    this.urlWithoutParams = getLastUrlSegment(this.router.url);


  }

  ngAfterViewInit() {
    setTimeout(() => {
      let storagedClientID = JSON.parse(sessionStorage.getItem('currentClient'));
      this.currentClientInfo = storagedClientID;
    })
  }

  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }

  isValidSubAreaRoute(route:string):boolean{
    return (/^areas\/{1}\D+/).test(route);
  }


  static validatePathsToDisplayNormalMenu(url: string): boolean {
    let theList = [
      {side: 'advisor', section: 'dashboard'},
      {side: 'advisor', section: 'areas'},
      {side: 'advisor', section: 'taskmanager'},
      {side: 'advisor', section: 'client-profile'},
      {side: 'advisor', section: 'reports'},
      {side: 'advisor', section: 'summary'},
      {side: 'advisor', section: 'summary_new'},
      {side: 'advisor', section: 'assets'},
      {side: 'advisor', section: 'complex-reports'},
      {side: 'client', section: 'dashboard'},
      {side: 'client', section: 'taskmanager'},
      {side: 'client', section: 'client-profile'},
      {side: 'hub'},
    ]

    return isTheUrlSubsectionAnyOf(url, theList);

  }

  ngOnDestroy() {

    console.log('unsubscribe sidenav');
    this.subscriptionClientDataService.unsubscribe();
  }


}
