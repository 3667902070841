import { Area } from 'app/shared/helpers/areas.catalog';
import { colorHexToRgb } from 'app/shared/helpers/utils';
import {
  FontStyle,
  ImagePDF,
  Margin,
  PageConfiguration,
  PAPER_SIZES,
  Paragraph,
  PdfCore,
} from 'app/views/reports/PdfCore.class';
import { CONST_DISCLAIMER } from 'app/views/reports/stringConstants';

import { getImageFromUrlAndFitToBox } from './../../../reports/PdfCore.class';

const FONT_FOOTER = new FontStyle('value-sans-regular-pro', [85, 85, 85],10);
const FONT_TITTLE = new FontStyle('value-sans-medium-pro', [255, 147, 30], 24);
const FONT_SUBTITLE = new FontStyle('value-sans-regular-pro', [0, 0, 0], 18);
const FONT_BODY = new FontStyle('value-sans-regular-pro', [0, 0, 0], 12);
const FONT_AREA = new FontStyle('value-sans-medium-pro', [0, 0, 0], 12);
const FONT_SECTION_NAME = new FontStyle('value-sans-medium-pro', [94, 94, 96], 10);


export class ProspectReport extends PdfCore {


  constructor(
    public logoPdfImage: ImagePDF,
    public wellnessCardPdfImage: ImagePDF,
    public contactCardPdfImage: ImagePDF,
    public disclaimer: string
  ){
    super(new PageConfiguration(PAPER_SIZES.LETTER, new Margin(32, 12.7, 40, 12.7)));
    this.disclaimer ? this.disclaimer : CONST_DISCLAIMER;
    this.isDebugEnabled = false;
  }

  public setHeaderFormat() {
    this.logoPdfImage.xPos = this.pageDimensions.getCanvasCoordinatesEnd().x - this.logoPdfImage.width;
    this.logoPdfImage.yPos = 12.7;
    this.addImage(this.logoPdfImage);
  }

  public setFooterFormat() {
    FONT_FOOTER.lineSpacing = 1;
    this.printParagraphv2(new Paragraph(this.disclaimer, FONT_FOOTER, null, this.pageDimensions.getCanvasCoordinatesStart().x, 244.5));
  }

  public printAreaRecs(area: Area, recs: any[]){
    // Draw the area color square.
    if(this.remainingVerticalSpace() < 1){
      this.addFormattedPage();
    }
      let areaColorInRGB = colorHexToRgb(area.colorHex);
      this.doc.setFillColor(areaColorInRGB.r, areaColorInRGB.g, areaColorInRGB.b);
      this.doc.roundedRect(this.pageDimensions.margin.left, this.currentPosY + 1, 2, 2, 0.5, 0.5, 'F');
      // Print area name
      this.printParagraphv2(new Paragraph(area.name, FONT_AREA, null, this.pageDimensions.margin.left + 5, this.currentPosY));
      this.currentPosY += 5;
      // Print recs
      recs.map(rec => {
        this.printParagraphv2(new Paragraph(rec.description, FONT_BODY));
        this.currentPosY += 5;
      })
  }

  public printRec(rec: string){

  }

  static async generate(reportData: reportData): Promise<ProspectReport>{

    //Give format & dimensions to images
    let wellnessCardPdfImage = await getImageFromUrlAndFitToBox(reportData.wellnessCardUrl, 78, 40);
    let contactCardPdfImage = await getImageFromUrlAndFitToBox(reportData.contactCardUrl, 87, 47);
    let logoPdfImage = await getImageFromUrlAndFitToBox(reportData.logoUrl, 40, 15);

    // Instanciate the new report
    let report = new ProspectReport(logoPdfImage, wellnessCardPdfImage, contactCardPdfImage, reportData.disclaimer);
    // Build report

    report.addFormatToPage();
    report.currentPosY = report.pageDimensions.getCanvasCoordinatesStart().y;
    report.printParagraphv2(new Paragraph(reportData.clientsName, FONT_TITTLE));
    report.printParagraphv2(new Paragraph('Financial plan consultation', FONT_SUBTITLE));

    // Add wellness score card
    // Card 88x51.5; 12.5, 63

    report.doc.setDrawColor(242, 242, 242);
    report.doc.setLineWidth(0.5);
    report.doc.roundedRect(12.7, 63, 88, 51.5, 2, 2, 'S');


    wellnessCardPdfImage.xPos = 12 + 3.5;
    wellnessCardPdfImage.yPos = 63 + 3.5;
    report.addImage(wellnessCardPdfImage);

    // Add contact card
    // Card 88x51.5; 108, 63

    report.doc.setDrawColor(242, 242, 242);
    report.doc.setLineWidth(0.5);
    report.doc.roundedRect(108, 63, 88, 51.5, 2, 2, 'S');

    contactCardPdfImage.xPos = 108 + 3.5;
    contactCardPdfImage.yPos = 63 + 3.5;
    report.addImage(contactCardPdfImage);

    //Print recs
    report.currentPosY = 123;
    report.printParagraphv2(new Paragraph(`Here are some of the ${reportData.numberOfRecs} recommendations we identified for you`, FONT_SECTION_NAME));
    report.currentPosY += 5;
    reportData.recomendations.map(recsArea =>{
      report.printAreaRecs(recsArea.area, recsArea.recs);
      report.currentPosY += 7;
    })

    return report;

  }

}

interface reportData{
  logoUrl: string;
  wellnessCardUrl: string;
  contactCardUrl: string;
  disclaimer: string,
  recomendations: ({area: Area, recs: string[]}[]),
  clientsName: string,
  numberOfRecs: number
}
