import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type GeneralDialogV2Props = {
  title: string;
  subtitle?: string;
  success?: boolean;
  error?: boolean;
  header: {
    disclaimer?: string;
    headerAction: (a: any) => void;
    buttonLabel: string;
  },
  footer: {
    disclaimer?: string;
    buttonLabel?: string;
    footerAction: (a:any) => void;
    additionalLinks?: string;
  }
}

@Component({
  selector: 'app-general-dialog-v2',
  templateUrl: './general-dialog-v2.component.html',
  styleUrls: ['./general-dialog-v2.component.scss']
})
export class GeneralDialogV2Component implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public props: any,
    public dialog: MatDialogRef<GeneralDialogV2Component>
  ) { }

  ngOnInit(): void {
    console.log(this.props);
  }

}
