import { ENVIRONMENT_URL_PRODUCTION, urlHelperIsInEnvironment } from "./url.helper";

export const AREAS: Area[] = [
    {
        id: 'general',
        name: 'General Questionnaire',
        isActive: false,
        colorHex: '#FFFFFF',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'fulltax',
        name: 'Tax Planning',
        isActive: true,
        colorHex: '#C93939',
        isMultiple: false,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/02_area_tax.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/02_Tax.svg',
        mainObjectDescription: ''
    },
    {
        id: 'fulltaxnewengine',
        name: 'Tax Planning NE',
        isActive: !urlHelperIsInEnvironment(ENVIRONMENT_URL_PRODUCTION), //Change to TRUE to enable testing
        colorHex: '#C93939',
        isMultiple: false,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/02_area_tax.svg',
        mainObjectDescription: '',
    },
    {
        id: 'estate',
        name: 'Estate Planning',
        isActive: true,
        colorHex: '#AC8E68',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/01_area_estate.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/01_Estate.svg',
        mainObjectDescription: 'person'
    },
    {
        id: 'home',
        name: 'Home Insurance',
        isActive: true,
        colorHex: '#F2B620',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/03_areas_home.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/03_Home.svg',
        mainObjectDescription: 'property'
    },
    {
        id: 'auto',
        name: 'Auto Insurance',
        isActive: true,
        colorHex: '#CC521E',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/04_areas_auto.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/04_Auto.svg',
        mainObjectDescription: 'car'
    },
    {
        id: 'disability',
        name: 'Disability Insurance',
        isActive: true,
        colorHex: '#CB8693',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/06_areas_disability.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/05_Disability.svg',
        mainObjectDescription: 'questionnaire'
    },
    {
        id: 'medical',
        name: 'Medical Insurance',
        isActive: true,
        colorHex: '#E36476',
        isMultiple: false,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/09_areas_medical.svg',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'identity',
        name: 'Identity Theft',
        isActive: true,
        colorHex: '#B37B58',
        isMultiple: false,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/07_areas_identity.svg',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'student',
        name: 'Student Debt',
        isActive: true,
        colorHex: '#63A38A',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/10_ares-student.svg',
        iconColorUrl: '',
        mainObjectDescription: 'loan'
    },
    {
        id: 'ltc',
        name: 'Long Term Care',
        isActive: true,
        colorHex: '#7E4751',
        isMultiple: true,
        iconUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/04_Area/08_areas_ltc.svg',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/07_LTC.svg',
        mainObjectDescription: 'questionnaire'
    },
    {
        id: 'mortgage',
        name: 'Mortgage',
        isActive: true,
        colorHex: '#10C18B',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'life',
        name: 'Life Insurance',
        isActive: true,
        colorHex: '#E5BABF',
        isMultiple: true,
        iconUrl: '',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/06_Life.svg',
        mainObjectDescription: ''
    },
    {
        id: 'divorce',
        name: 'Divorce Planning',
        isActive: true,
        colorHex: '#77635B',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'property',
        name: 'Property Tax',
        isActive: true,
        colorHex: '#A52139',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'expense',
        name: 'Expense Reduction',
        isActive: false,
        colorHex: '#006E2C',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'elder',
        name: 'Elder Care',
        isActive: true,
        colorHex: '#43307A',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'creditor',
        name: 'Creditor Protection',
        isActive: true,
        colorHex: '#4195A5',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'crossborder',
        name: 'Cross Border Planning',
        isActive: true,
        colorHex: '#D88B64',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'investment',
        name: 'Investment and Retirement',
        isActive: false,
        colorHex: '#D88B64',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: 'https://storage.googleapis.com/fpalpha-assets/iconos/00_Platform/10_Prospects/08_RetirementInvestment.svg',
        mainObjectDescription: ''
    },
    /*{
        id: 'retirement',
        name: 'Retirement',
        isActive: false,
        colorHex: '#D88B64',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },*/
    // ,
    // {
    //     id: 'investment',
    //     name: 'Investment and Retirement',
    //     isActive: true,
    //     colorHex: '#D88B64',
    //     isMultiple: false,
    //     iconUrl: '',
    //     iconColorUrl: '',
    //     mainObjectDescription: ''
    // },
    {
        id: 'basictax',
        name: 'Basic Tax Planning',
        isActive: false,
        colorHex: '#C93939',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },
    {
        id: 'basicestate',
        name: 'Basic Estate Planning',
        isActive: false,
        colorHex: '#AC8E68',
        isMultiple: false,
        iconUrl: '',
        iconColorUrl: '',
        mainObjectDescription: ''
    },

];

export class Area {

    static getActiveAreas(): any[] {

        return AREAS.filter(area => {
            return (area.isActive)
        });
    }

    static mapAreasToObject(mappingCallbackAreaToObject, areasArray: any[] = AREAS): any {

        let areasMappedToObjects: any[] = areasArray.map(area => {
            return mappingCallbackAreaToObject(area);
        });

        return Object.assign({}, ...areasMappedToObjects);
    }

    static getAreasInDisplayArrayFormat(chunkSize: number, completeWithEmpties: boolean = false, areas = Area.getActiveAreas()): any[] {
        let arrayFormated = [];
        let i = 0;
        let j = 0;
        areas.forEach(element => {
            if (i == 0) arrayFormated.push([]);
            if (i < chunkSize) {
                arrayFormated[j].push(element);
                i++;
            }
            if (i == chunkSize) { i = 0; j++; }
        });

        if (completeWithEmpties && i < chunkSize) {
            j--;
            //Only the last superior array could be incomplete
            for (let k = 0; k < chunkSize; k++) {
                if (arrayFormated[j][k] == undefined) arrayFormated[j][k] = {};
            }
        }

        return arrayFormated;
    }

    static getAreasInDisplayArrayFormatFixedColumn(areas = Area.getActiveAreas(), columns: number = 3, excludeUnactive:boolean = true): any[] {
        let arrayFormated = [[], [], []];
        let i = 0;

        areas.forEach(element => {
            if(!excludeUnactive || element.isActive){
                arrayFormated[i++].push(element);
                if (i == columns) { i = 0; }
            }
        });

        return arrayFormated;
    }

    static findAreaById(id: string) {
        return AREAS.filter(area => { return (area.id == id) })[0];
    }

    static getAreasByIds(areas: string[], ): Area[] {
        return areas.map(areaId => {
            return Area.findAreaById(areaId);
        });
    }

    constructor(
        public id: string,
        public name: string,
        public isMultiple: boolean,
        public isActive: boolean,
        public colorHex: string,
        public iconUrl: string,
        public mainObjectDescription: string,
        public iconColorUrl?: string,
    ) { }

};

export const PROSPECT_AREAS: Area[] = Area.getAreasByIds(['estate', 'fulltax', 'auto', 'home', 'ltc', 'life', 'disability', 'investment', /*'retirement'*/]); //'divorce',
export const NO_GENERAL_QUEST_AREAS: Area[] = Area.getAreasByIds([ 'fulltax', 'estate']);
