import { Questionnaire } from '../Questionnaire.model';

//Sub class

export class auto {
   public clientIDCarValue: number = 0;
   public clientIDCarAge: number = 0;
   public clientIDWouldRepairCar: string = '';
}

export class auto_questions extends Questionnaire {

  public clientIDAssetName: string = '';
  public clientIDCarsOwned: number = 0;  
  
  public clientIDWantsToInputPolicyData: string = 'Yes';

  public clientIDClientHasAutoInsurance: string = 'Yes';
  public clientIDAutoInsuranceCompany: string = '';
  public clientIDAutoPolicy: string = '';
  public clientIDHomeInsuranceCompany: string = '';

  public clientIDtotalPremiumAuto: number = 0;
  public clientIDbodilyInjuryPerPerson: number = 0;
  public clientIDbodilyInjuryPerOcurrence: number = 0;
  public clientIDpropertyDamagePerOccurrence:number = 0;
  public clientIDHasBasicPersonalInjuryProtection: string = ''; 
  public clientIDbasicPersonalInjuryProtection: number = 0;
  public clientIDsearchMedicalPaymentsPerPersonPerOccurrence: number = 0;
  public clientIDsearchOptionalBasicEconomicLossOBEL: string = '';
  public clientIDsupplementaryUninsured: string = '';
  public clientIDcoverageOneCollision: string = '';
  public clientIDPartDdeductible: number = 0;
  public clientIDcoverageOtherThanCollision: string = '';
  public clientIDdeductibleOtherThanCollision : number = 0;
  public clientIDgapInsurance: string = '';
  public clientIDleaseGap: string = '';
  public clientIDtowingLabor: string = '';
  public clientIDemergencyRoadsideAssistance: string = '';
  public clientIDrentalCarReinbursment: string = '';
  public clientIDglassCoverage: string = '';
  public clientIDGlassDeductible: number = 0;
  public clientIDlossPayeeAuto: string = '';
  public clientIDlessor: string = '';
  public clientIDOEMendorsement: string = ''; 
  public clientIDmultipleAutoPolicies: string = '';
  public clientIDgarageState: string = '';
  public clientIDClientHasUmbrellaDocument: string = '';
  public clientIDUmbrellaTrust: string = '';
  public clientIDJointOwnersAuto: string = '';
  public clientIDJointOwnersUmbrella: string = '';

   constructor() {
      super();
      this._classInitialState = Object.assign({}, this);
  }

   getQName(): string {
      return 'auto';
   }

   getIgnoredAttributesForSave(): string[] {
      return ['clientIDAutoPolicy', 'cars'];
   }

   getAttributesToClearCommas(): string[] {
      return [
        'clientIDtotalPremiumAuto',
        'clientIDbodilyInjuryPerPerson',
        'clientIDbodilyInjuryPerOcurrence',
        'clientIDpropertyDamagePerOccurrence',
        'clientIDbasicPersonalInjuryProtection',
        'clientIDsearchMedicalPaymentsPerPersonPerOccurrence',
        'clientIDPartDdeductible',
        'clientIDdeductibleOtherThanCollision',
        'clientIDGlassDeductible',
      ];
   }

   public getExtractionsAtributes(): string[] {
      return [
          'clientIDtotalPremiumAuto',
          'clientIDbodilyInjuryPerPerson',
          'clientIDbodilyInjuryPerOcurrence',
          'clientIDpropertyDamagePerOccurrence',
          'clientIDHasBasicPersonalInjuryProtection ',
          'clientIDbasicPersonalInjuryProtection',
          'clientIDsearchMedicalPaymentsPerPersonPerOccurrence',
          'clientIDsearchOptionalBasicEconomicLossOBEL',
          'clientIDsupplementaryUninsured',
          'clientIDcoverageOneCollision',
          'clientIDPartDdeductible',
          'clientIDcoverageOtherThanCollision',
          'clientIDdeductibleOtherThanCollision ',
          'clientIDgapInsurance',
          'clientIDleaseGap',
          'clientIDtowingLabor',
          'clientIDemergencyRoadsideAssistance',
          'clientIDrentalCarReinbursment',
          'clientIDglassCoverage',
          'clientIDGlassDeductible',
          'clientIDlossPayeeAuto',
          'clientIDlessor',
          'clientIDOEMendorsement ',
          'clientIDmultipleAutoPolicies',
          'clientIDgarageState',
          'clientIDClientHasUmbrellaDocument',
          'clientIDUmbrellaTrust',
          'clientIDJointOwnersAuto',
          'clientIDJointOwnersUmbrella'
      ]
   }  


   beforeToSaveFormat() {
      /* let index: number = 1;
      this.cars.forEach(car => {
         for (let prop in car) {
            this[prop + String(index)] = car[prop];
         }
         index++;
      }); */
      //this.saveSubmodelArrayToModelProp(this.cars);
   }

   beforeMappingLoad(args: any[]) {
      //this.loadSubmodelFromRawArgs(args, auto, this.cars, 'clientIDCarsOwned');
   }

}